/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Request from './Request'
import ConfirmationButtons from './ConfirmationButtons'
import Copy from './Copy'


class ConfigTargetToken extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {state: "view", token: ""}
        this.completeRegenerateTargetToken = this.completeRegenerateTargetToken.bind(this)
        this.regenerateTargetToken = this.regenerateTargetToken.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    completeRegenerateTargetToken () {
        this.setState({submitting: true});
        const data = {
            id: this.props.target.id,
            created: this.props.target.created,
            regenerate: true
        };
        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({error: "Unable to edit target.", submitting: false});
            } else {
                Analytics.event("RegeneratedTargetToken");
                this.setState({state: "regenerated-target-token", token: data.token, submitting: false});
            }
        });
    }

    regenerateTargetToken () {
        Analytics.event("RegenerateTargetToken");
        this.setState({state: "regenerate-target-token"});
    }

    cancel () {
        this.setState({state:"view", token: ""})
    }

    render () {
        if (this.state.state === "view") {
            return (
                <div>
                    <button className='btn-cancel' onClick={this.regenerateTargetToken}>Regenerate target token</button>
                </div>
            );
        } else if (this.state.state === "regenerate-target-token") {
            return (
                <div style={{"maxWidth":"600px"}}>
                    <h4><b>IMPORTANT WARNING</b></h4>
                    <p>If you regenerate the target token for this target then the old (existing) target token will become invalidated. If you are currently using the existing target token in an automated upload process then requests will start failing. If you proceed to regenerate the target token you must replace the old invalid token in your scripts with the newly generated one. If you do not wish to do this click the back button.</p>
                    <ConfirmationButtons
                        confirm={this.completeRegenerateTargetToken}
                        cancel={this.cancel}
                        confirmLabel="Regenerate"
                        cancelLabel="Cancel"
                    />
                </div>
            );
        } else if (this.state.state === "regenerated-target-token") {
            return (
                <div style={{"maxWidth":"600px"}}>
                    <h4 className="">Regenerated</h4>
                    <p>The target token has been regenerated.</p>
                    <span className="font15">New target token </span><Copy text={this.state.token}/>
                    <br/>
                    <div><textarea className='tr-text-area' rows="10" cols="40" value={this.state.token} readOnly/></div>
                    <p>The target token is required to be sent every time you post results using the results API. Please ensure you replace the old token, if you were using it anywhere in automated scripts, with this new one.</p>
                    <p>This is the only time the target token will be displayed. Copy it now and keep it safe. You can regenerate the target token again from project configuration.</p>
                    <button type="button" className="btn btn-confirm mt-3" onClick={this.cancel}>Done</button>
                    <br/>
                </div>
            );
        } else {
            return <div>Invalid state</div>
        }
    }
};

export default ConfigTargetToken