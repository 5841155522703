/*global */
import React, { Component } from 'react';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class AffiliateDashboardPayments extends Component {
    constructor() {
        super();
        this.state = {payments: [], esk: undefined};
        this.payments = this.payments.bind(this);
    }

    componentDidMount () {
        this.payments();
    }

    payments () {
        if (this.props.id === undefined) {
            return;
        }
        Request.get("/affiliate-payments", {id: this.props.id, esk: this.state.esk, all: true}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                let payments = this.state.payments;
                for (let i = 0; i < data.payments.length; i++) {
                    payments.push(data.payments[i]);
                }
                this.setState({payments: payments, esk: data.esk});
            }
        });
    }

    render () {
        if (this.state.payments === undefined) {
            return <div></div>
        } else {
            let data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Time", minWidth: "200px"},
                    {value: "Amount", minWidth: "100px"},
                    {value: "Paid"},
                ],
                rows: []
            }
            let paidUSD = 0;
            let owedUSD = 0;
            for (let i = 0; i < this.state.payments.length; i++) {
                let payment = this.state.payments[i];
                if (payment.currency === 'usd') {
                    if (payment.paid === true) {
                        paidUSD += payment.amount;
                    } else {
                        owedUSD += payment.amount;
                    }
                }
                data.rows.push(
                    {
                        cols: [
                            {value: payment.id},
                            {value: TimeFormatted.timeformatted(payment.time, true)},
                            {value: (payment.amount / 100.0).toFixed(2) + " " + payment.currency},
                            {value: payment.paid ? "Paid" : "Not paid"}
                        ]
                    }
                );
            }
            if (data.rows.length === 0) {
                return (
                    <div>
                        <h4 className="neutral3">Payments</h4>
                        <p>No payments yet</p>
                    </div>
                ); 
            } else {
                var formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
                  
                return (
                    <div>
                        <h4 className="neutral3">Payments</h4>
                        {<span className="font14 neutral4">{"USD Owed: " + formatter.format(owedUSD/100.0) + " | Paid out: " + formatter.format(paidUSD/100.0)}</span>}
                        <TabularData data={data}/>
                    </div>
                );
            }
        }
    }
};

export default AffiliateDashboardPayments;