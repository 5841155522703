/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsResults extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Viewing Results Guide</title>
                    <meta name="description" content="Learn how to view test results effectively on Tesults."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/Lqg97_VZEoM?si=1EhVq_X7eg03lu9r" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Click <b>Results</b> from the menu to view results.</p>
                <p>The results view includes key information about the latest test run for each target (test job) and easy navigation to previous test runs.</p>
                <p>Test suites are grouped together and can be traversed quickly by expanding and collapsing suites to get a comprehensive understanding of the entire test run. Click on a test case to bring up test case details. Drill down into details, assign failing test cases to team members, comment and view images and files associated with the test case.</p>
                <img src="https://www.tesults.com/files/docs/results-2.png" alt="" className="width100"/>
            </div>
        );
    }
}

export default DocsResults;