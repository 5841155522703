/*global */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import DocsTestFramework from './DocsTestFramework'
import Box from './Box'

class DocsWdio extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>WebdriverIO test reporting with Tesults.</title>
            <meta name="description" content="Learn how to handle WebdriverIO test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>Powering up your WebdriverIO tests with Tesults reporting is easy. This page explains it all. If you have any questions please reach out to the Tesults team. If you are not using WebdriverIO view the <NavLink to="/docs/javascript" target="_blank" className="site-link-primary2 no-break">Node.js and JavaScript</NavLink> docs for information about integrating with a lower level library.</p>
            <p>This package requires Node.js 14+ and can be used with WebdriverIO when using the <b>Mocha</b>, <b>Jasmine</b> and <b>Cucumber</b> frameworks.</p>
        </div>

        const installation = 
        <div>
            <pre className="docsCode">
                npm install wdio-tesults-service --save
            </pre>
            <Box
                bg={"neutral8bg"}
                content={<p>Please note that wdio-tesults-service is the now the standard way to integrate with WebdriverIO. The older wdio-tesults-reporter has been deprecated and is no longer supported or recommended.</p>}
            />
        </div>

        const configuration =
        <div>
            <p>In your WebdriverIO wdio.conf.js file add the Tesults reporter along with the options object:</p>
            <pre className="docsCode"> 
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;// ...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;services: [<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;['tesults',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;target: 'token'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;],<br/>
            &nbsp;&nbsp;// ...<br/>
            {'}'}
            </pre>
            <pre className="primary2 docsArgsCode pl-0">target<span className="neutral7 ml-5">Required</span></pre>
            <p>You must provide your target token to push results to Tesults. If this arg is not provided the service does not attempt upload, effectively disabling it. You receive a target token when creating a project or new target and can regenerate one at anytime from the configuration menu. Find out more about <NavLink to="/docs/target" target="_blank" className="tr-link-primary4 no-break">targets</NavLink></p>
            <p>To support <b>multiple reporting targets</b>, you can either <b>create multiple wdio.conf.js</b> or rather than pass the target token as a string, retrieve it from <b>a variable</b> that is set from a command line argument or continuous integration system.</p>
            <pre className="docsCode"> 
            const target = () {'=>'} {'{'} // target token from command line arg {'}'}<br/>
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;// ...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;services: [<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;['tesults',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;target: target()<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;],<br/>
            &nbsp;&nbsp;// ...<br/>
            {'}'}
            </pre>
            <Box
                bg="neutral8bg"
                title={<h4>Basic configuration complete</h4>}
                content={
                <div>
                    <p>At this point, if you run tests, results data will be uploaded to Tesults</p>
                    <pre className='docsCode'>
                        npx wdio run ./wdio.conf.js
                    </pre>
                </div>
                }
            />
        </div>

        const enhancedReporting = 
            <div>
                <p>Use the Tesults service to report additional properties for each test case. To begin doing this, require the service in your test spec file:</p>
                <pre className='docsCode'>
                    const tesultsService = require('wdio-tesults-service').default<br/>
                    // Alternatively: import tesultsService  from 'wdio-tesults-service/worker.js'
                </pre>
                <p>Report custom fields, test steps and attach files to test cases using the Tesult service functions: description, custom, step, and file:</p>
                <h4>description</h4>
                <p>Add a description of the test case for reporting purposes.</p>
                <pre className='docsCode'>
                tesultsService.<code className="accentc6">description</code>("some description here")
                </pre>
                <h4>custom</h4>
                <p>Add a custom field and value to the test case for reporting purposes.</p>
                <pre className='docsCode'>
                tesultsService.<code className="accentc6">custom</code>("Some custom field", "Some custom value")<br/>
                </pre>
                <h4>file</h4>
                <p>Associate a file to the test case in order to upload it for reporting.</p>
                <pre className='docsCode'>
                tesultsService.<code className="accentc6">file</code>("/absolute/path/to/file/screenshot.png")<br/>
                </pre>
                <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
                <h4>step</h4>
                <p>Add test steps to the test case for reporting. Each step is an object with a name and result (one of [pass, fail, unknown]). You can also add the optional fields description and reason (failure reason in case of step fail).</p>
                <pre className='docsCode'>
                tesultsService.<code className="accentc6">step</code>({'{'}<br/>
                &nbsp;&nbsp;<code className="accentb4">name</code>: "First step",<br/>
                &nbsp;&nbsp;<code className="accentb4">result</code>: "pass"<br/>
                {'}'})<br/>
                tesultsService.<code className="accentc6">step</code>({'{'}<br/>
                &nbsp;&nbsp;<code className="accentb4">name</code>: "Second step",<br/>
                &nbsp;&nbsp;<code className="accentb4">description</code>: "Second step description",<br/>
                &nbsp;&nbsp;<code className="accentb4">result</code>: "fail"<br/>
                &nbsp;&nbsp;<code className="accentb4">reason</code>: "Error line 203 of test.js"<br/>
                {'}'})<br/>
                </pre>
            </div>

        const example = 
            <div>
                <p>A complete example:</p>
                <pre className='docsCode'>
                const tesultsService = require('wdio-tesults-service').default<br/>
                describe('Test suite', () {'=>'} {'{'}<br/>
                &nbsp;&nbsp;it('Test case', async () {'=>'} {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;// ...<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsService.description("some description here")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsService.custom("Some custom field", "Some custom value")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsService.step({'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: "First step",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: "pass"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'}'})<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsService.step({'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: "Second step",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: "pass"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'}'})<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsService.file("/absolute/path/to/file/screenshot.png")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;// ...<br/>
                &nbsp;&nbsp;{'}'});<br/>
                {'}'});
                </pre>
            </div>

        const build = 
        <div>
            <p>Report build information by passing through data as options in wdio.conf.js</p>
            <pre className="docsCode"> 
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;// ...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;services: [<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;['tesults',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;target: 'token',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;build:{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: '1.0.0',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: 'pass', // one of [pass, fail, unknown]<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;description: 'build description',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;reason: 'build failure reason' // if result is fail<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;],<br/>
            &nbsp;&nbsp;// ...<br/>
            {'}'}
            </pre>
            <h4>Build properties</h4>
            <pre className="docsArgsCode">name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <hr/>
            <pre className="docsArgsCode">result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <hr/>
            <pre className="docsArgsCode">description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <hr/>
            <pre className="docsArgsCode">reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <br/>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                enhancedReporting={enhancedReporting}
                build={build}
                example={example}
                integration="wdio"
            />
        );
    }
}

export default DocsWdio;
