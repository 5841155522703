/*global*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Request from './Request';
import Copy from './Copy';
import Cache from './Cache';
import MemberAdd from './MemberAdd';
import Analytics from './Analytics';
import Context from './Context';

class ShareSSOLoginLink extends Component {
    static contextType = Context;

    constructor()  {
        super();
        this.state = ({id: undefined, display: true, role: 1});
        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.sso = this.sso.bind(this);
        this.projectRole = this.projectRole.bind(this);
        this.project = this.project.bind(this)
        this.upgrade = this.upgrade.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.projectId !== this.props.projectId) {
            this.sso();
        }
    }

    componentDidMount () {
        let projectInviteDisplay = Cache.getPreference(Cache.preference.projectInviteDisplay);
        if (projectInviteDisplay === undefined) {
            Cache.setPreference(Cache.preference.projectInviteDisplay, true);
        } else {
            if (projectInviteDisplay === false) {
                this.setState({display: false});
            }
        }
        this.sso();
    }

    sso () {
        Request.get("/ssoForRef", {ref: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({id: undefined});
            } else {
                if (data.sso !== undefined) {
                    this.setState({id: data.sso.id});
                } else {
                    this.setState({id: undefined}, this.projectRole);
                }
            }
        });
    }

    projectRole () {
        Request.get("/projectRole", {id: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({role: 1});
            } else {
                this.setState({role: data.role});
            }
        });
    }

    toggleDisplay () {
        Analytics.event("ShareSSOLoginLinkToggle");
        Cache.setPreference(Cache.preference.projectInviteDisplay, this.state.display === true? false: true);
        this.setState({display: this.state.display === true ? false: true});
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    upgrade () {
        let project = this.project()
        if (project !== undefined) {
            if (project.plan !== undefined) {
                if (project.plan.name === "free-v1") {
                    if (this.props.full !== true) {
                        return <span></span>
                    }
                    return (
                        <div className='p-4'>
                            <NavLink to="/config/project/plan"><button type="button" className='btn-upgrade width100'>Upgrade</button></NavLink>
                        </div>
                    )
                }
            }
        }
        return <span></span>
    }

    render () {
        if (this.state.id === undefined) {
            if (this.state.role < 3) {
                return <span></span>
            } else {
                return (
                    <div>
                        <MemberAdd projectId={this.props.projectId} full={this.props.full}/>
                        {this.upgrade()}
                    </div>
                );
            }
        } else {
            if (this.props.full !== undefined) {
                if (this.props.full !== true) {
                    return <span></span>
                }
            }
            if (this.state.display !== true) {
                return (
                    <div style={{"display:":"flex", "flexDirection":"column", "maxWidth":"220px"}} className="p-4">
                        <div style={{"display":"flex"}} className="mb-2">
                            <div>
                                <span className="font12 primary7">Project invite link</span>
                            </div>
                            <div style={{"marginLeft":"auto", "marginTop":"-4px"}}>
                                <button onClick={this.toggleDisplay} className="btn-transparent btn-close-primary7-sm pt-0 mt-0 neutral7border">
                                    <span className="font12 primary7">+</span>
                                </button>
                            </div>
                        </div>
                        {this.upgrade()}
                    </div>
                )
            }
            let link = "https://www.tesults.com/login/" + this.state.id;
            let url = <a className="tr-link-primary7 wrapforce font12" target="_blank" rel="noopener noreferrer" href={link}>{link}</a>;
            return (
                <div className="pl-3 pr-3">
                <div style={{"display:":"flex", "flexDirection":"column", "maxWidth":"220px"}} className="p-4 primary8border standardborder solidborder mt-5 mb-5">
                    <div style={{"display":"flex"}} className="mb-2">
                        <div className="primary7 font14 bold">Project invite link</div>
                        <div style={{"marginLeft":"auto", "marginTop":"-4px", "marginRight":"-4px"}}>
                            <button onClick={this.toggleDisplay} className="btn-transparent btn-close-primary7-sm pt-0 mt-0">
                                &times;
                            </button>
                        </div>
                    </div>
                    <div style={{"display":"flex"}}>
                        <div className="mr-4">{url}</div>
                        <div className="primary7" style={{"marginLeft":"auto", "marginTop":"-4px", "marginRight":"-12px"}}>
                            <div className="menu-item-circle">
                                <Copy text={link}/>
                            </div>
                        </div>
                    </div>
                </div>
                {this.upgrade()}
                </div>
            );
        }
    }
}

export default ShareSSOLoginLink;
