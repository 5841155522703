/*global */
import React from 'react';
import { useParams } from "react-router-dom";
import App from './App';

function TRLHandler (props) {
    let { org, project, target, run, testcase} = useParams();
    let trlView = "results" // default
    const path = window.location.pathname;
    if (org && project) {
        if (path.endsWith("/dashboard")) {
            trlView = "status"
        }
        if (path.endsWith("/results")) {
            trlView = "results"
        }
        if (path.endsWith("/supplemental")) {
            trlView = "supplemental"
        }
    }
    return (
        <App results={true} trlOrg={org} trlProject={project} trlTarget={target} trlRun={run} trlCase={testcase} trlView={trlView} match={props.match} location={props.location} history={props.history}/>
    );
};

export default TRLHandler;