/*global*/
import React, { Component } from 'react';
import Assignee from './Assignee'
import SelectOverlay from './SelectOverlay'
import Request from './Request'
import Analytics from './Analytics';
import Spinner from './Spinner';

class TargetMaintainers extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedMemberIndex: 0,
            state: "view",
            submitting: false
        }
        this.errorCheck = this.errorCheck.bind(this)
        this.renderView = this.renderView.bind(this)
        this.renderEdit = this.renderEdit.bind(this)
        this.selectedMemberChange = this.selectedMemberChange.bind(this)
        this.selectMember = this.selectMember.bind(this)
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
    }

    errorCheck () {
        if (this.props.targetIndex === undefined) {
            return true
        }
        if (this.props.targets === undefined) {
            return true
        }
        if (this.props.context !== "results" && this.props.context !== "supplemental") {
            return true
        }
        if (Array.isArray(this.props.targets) !== true) {
            return true
        }
        if (this.props.targetIndex >= this.props.targets.length) {
            return true
        }
        return false
    }

    selectedMemberChange (index) {
        this.setState({selectedMemberIndex: index})
    }

    selectMember () {
        let options = []
        if (this.props.members !== undefined) {
            for (const member of this.props.members) {
                options.push(
                    {
                        id: member.id,
                        name: member.firstName + " " + member.lastName,
                        firstName: member.firstName,
                        lastName: member.lastName,
                        aext: member.aext
                    }
                )
            }
        }

        return (
            <SelectOverlay 
                overlay={this.props.overlay} 
                messageOverlay={this.props.messageOverlay}
                valueChange={this.selectedMemberChange} 
                type="member" 
                label="name"
                options={options}
                index={this.state.selectedMemberIndex}
                members={this.props.members}
            />
        )
    }

    add () {
        let target = this.props.targets[this.props.targetIndex]
        if (this.props.members !== undefined) {
            if (this.state.selectedMemberIndex < this.props.members.length) {
                let member = this.props.members[this.state.selectedMemberIndex]
                let maintainers = target.maintainers
                if (maintainers === undefined) {
                    maintainers = []
                }
                let alreadyMaintainer = false
                for (let i = 0; i < maintainers.length; i++) {
                    if (maintainers[i] === member.id) {
                        alreadyMaintainer = true
                    }
                }
                if (alreadyMaintainer !== true) {
                    maintainers.push(member.id)
                    let data = {
                        id: target.id,
                        created: target.created,
                        maintainers: maintainers
                    };
            
                    this.setState({submitting: true})
                    Request.post("/target-maintainers", data, (err, data) => {
                        if (err) {
                            this.setState({submitting: false, confirmation: {success: undefined, failure: "Unable to update maintainers"}});
                        } else {
                            Analytics.event("EditedTargetMaintainers");
                            let target = data.target
                            if (target.rev === undefined) {
                                target.rev = 0
                            } else {
                                target.rev = target.rev + 1;
                            }
                            this.setState({submitting: false, confirmation: {success: undefined, failure: undefined}}, () => {this.props.targetUpdate(target)});
                        }
                    });
                }
            }
        }
    }

    remove (maintainerUserId) {
        let target = this.props.targets[this.props.targetIndex]
        let index = -1
        if (target.maintainers !== undefined) {
            if (Array.isArray(target.maintainers)) {
                for (let i = 0; i < target.maintainers.length; i++) {
                    if (target.maintainers[i] === maintainerUserId) {
                        index = i
                    }
                }
            }
        }
        if (index > -1) {
            let maintainers = target.maintainers
            maintainers.splice(index, 1)
            let data = {
                id: target.id,
                created: target.created,
                maintainers: maintainers
            };
    
            this.setState({submitting: true})
            Request.post("/target-maintainers", data, (err, data) => {
                if (err) {
                    this.setState({submitting: false, confirmation: {success: undefined, failure: "Unable to update maintainers"}});
                } else {
                    Analytics.event("EditedTargetMaintainers");
                    let target = data.target
                    if (target.rev === undefined) {
                        target.rev = 0
                    } else {
                        target.rev = target.rev + 1;
                    }
                    this.setState({submitting: false, confirmation: {success: undefined, failure: undefined}}, () => {this.props.targetUpdate(target)});
                }
            });
        }
    }

    renderView () {
        let membersHash = {}
        if (this.props.members !== undefined) {
            if (this.props.members !== undefined) {
                if (Array.isArray(this.props.members)) {
                    for (let i = 0; i < this.props.members.length; i++) {
                        membersHash[this.props.members[i].id] = this.props.members[i]
                    }
                }
            }
        }
        let target = this.props.targets[this.props.targetIndex]
        let maintainers = []
        if (target.maintainers !== undefined) {
            if (Array.isArray(target.maintainers)) {
                for (let i = 0; i < target.maintainers.length; i++) {
                    let user = membersHash[target.maintainers[i]]
                    if (user !== undefined) {
                        maintainers.push(<Assignee key={i} user={user} overlay={this.props.overlay} clickToDisplay={true}/>)
                    }
                }   
            }
        }

        if (maintainers.length === 0) {
            if (this.props.trl) { return <div className="font14 neutral4">None assigned</div>}
            return (
                <div className='font14 neutral4'>
                    <button className='btn-transparent font14 neutral4' onClick={() => {this.setState({state: "edit"})}}>
                        <span style={{"textDecoration": "underline dotted"}}>Add a maintainer for this target</span>
                    </button>
                </div>
            )
        } else {
            return (
                <div className='font14 neutral4 width100'>
                    <div className='font14 neutral4 mb-2'>
                        {
                            this.props.trl !== true ?
                            <button className='btn-transparent font14 neutral4' onClick={() => {this.setState({state: "edit"})}}>
                                <span style={{"textDecoration": "underline dotted"}}>Edit</span>
                            </button>
                            :
                            <span></span>
                        }
                    </div>
                    <div style={{"display":"flex", "flexWrap":"wrap"}}>
                        {maintainers}
                    </div>
                </div>
            )
        }
    }

    renderEdit () {
        if (this.props.role < 2) {
            return (
                <div className="pl-4 pr-4 pt-3 pb-3 standardborder solidborder neutral7border whitebg">
                    <div className='font14 neutral4'>
                        <div className='mr-3'>
                            <button className='btn-transparent font14 neutral4' onClick={() => {this.setState({state: "view"})}}>
                                <span style={{"textDecoration": "underline dotted"}}>Close</span>
                            </button>
                        </div>
                        <div>Ask a project administrator to update your role to moderator or higher to edit target maintainers.</div>
                    </div>
                </div>
            )
        }

        let membersHash = {}
        if (this.props.members !== undefined) {
            if (this.props.members !== undefined) {
                if (Array.isArray(this.props.members)) {
                    for (let i = 0; i < this.props.members.length; i++) {
                        membersHash[this.props.members[i].id] = this.props.members[i]
                    }
                }
            }
        }
        let target = this.props.targets[this.props.targetIndex]
        let maintainers = []
        if (target.maintainers !== undefined) {
            if (Array.isArray(target.maintainers)) {
                for (let i = 0; i < target.maintainers.length; i++) {
                    let maintainerId = target.maintainers[i]
                    if (membersHash[maintainerId] !== undefined) {
                        maintainers.push(
                            <div key={i} style={{"display":"flex", "alignItems":"center"}}>
                                <div className="mb-1 mr-3">
                                    <Assignee user={membersHash[maintainerId]} overlay={this.props.overlay} clickToDisplay={true}/>
                                </div>
                                <div>
                                    <button className='btn-transparent neutral3' disabled={this.state.submitting ? true : false} onClick={() => {this.remove(maintainerId)}}>Remove</button>
                                </div>
                            </div>    
                        )
                    }
                }   
            }
        }
        return (
            <div className="pl-4 pr-4 pt-3 pb-3 standardborder solidborder neutral7border whitebg width100">
                <div className='font14 neutral4' style={{"display":"flex"}}>
                    <div className='mr-3'>
                        Maintainers
                        <button className='ml-5 btn-transparent font14 neutral4' onClick={() => {this.setState({state: "view"})}}>
                                <span style={{"textDecoration": "underline dotted"}}>Close</span>
                        </button>
                    </div>
                    <div>
                        {this.state.submitting ? <Spinner/> : <span></span>}
                    </div>
                </div>
                <div className='font14 neutral4'>
                    <p>Help others understand who maintains the test cases reporting to this target.</p>
                </div>
                <div>
                    {maintainers}
                </div>
                <hr className="dividor-light"/>
                <div style={{"display":"flex", "flexWrap":"wrap", "alignItems":"center"}} className="mb-4">
                    <div>
                        {this.selectMember()}
                    </div>
                    <div style={{"paddingTop":"14px"}}>
                        <button className="btn-select" disabled={this.state.submitting ? true : false} onClick={this.add}>Add</button>
                    </div>
                </div>
            </div>
        )
    }

    render () {
        if (this.errorCheck() === true) {
            return <span></span>
        }
        if (this.state.state === "view") {
            return this.renderView()
        } else if (this.state.state === "edit") {
            return this.renderEdit()
        } else {
            return <div className='font14 neutral4'>Invalid state</div>
        }
    }   
}

export default TargetMaintainers;