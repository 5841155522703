/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';
import Notice from './Notice';

class DocsCSharp extends Component {
    render() {
        const helmet =
        <Helmet>
            <title>C# test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle C# test reporting with Tesults."/>
        </Helmet>

        const plugins =
        <div className='mt-5 mb-5'>
            <Notice type="information" content={
            <div>
                <b>Using NUnit?</b> If you use the <a href="https://nunit.org/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">NUnit test framework</a> no code is necessary to integrate, click <NavLink to="/docs/nunit3" target="_blank" className="tr-link-primary4 no-break no-break">here</NavLink> to view documentation for the <NavLink to="/docs/nunit3" target="_blank" className="tr-link-primary4 no-break no-break">Tesults NUnit extension</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using the Visual Studio Unit Testing Framework (MS Test)?</b> If you use the <a href="https://docs.microsoft.com/en-us/visualstudio/test/getting-started-with-unit-testing?view=vs-2019" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Visual Studio Unit Testing Framework (MSTest)</a> take a look at documentation for <NavLink to="/docs/vsunittf" target="_blank" className="tr-link-primary4 no-break no-break">integrating with Tesults</NavLink> and ignore the instructions below.
            </div>}/>
        </div>
        const installation =
        <div>
            <p>The package is hosted on The NuGet Gallery: <a className="tr-link-primary4 no-break" href="https://www.nuget.org/packages/tesults/">https://www.nuget.org/packages/tesults/</a></p>
            <p>Run this command in Package Manager Console.</p>
            <pre className="docsCode">
                <code className="accentb4">Install-Package tesults</code>
            </pre>
        </div>

        const configuration =
        <p>No additional configuration is required once the package has been installed.</p>

        const usage = 
        <div>
            <p>Upload results using the Upload method in the Results class. This is the single point of contact between your code and Tesults.</p>
            <pre className="docsCode">
                <code className="accentb4">Tesults.Results.Upload(data);</code>
            </pre>
            <p className="mt-3">The Upload method returns a response indicating success or failure:</p>
            <pre className="docsCode">
                var response = <code className="accentb4">Tesults.Results.Upload(data);</code><br/><br/>

                {'//'} The response value is a dictionary with four keys.<br/><br/>

                {'//'} Value for key "success" is a bool, true if successfully uploaded, false otherwise.<br/>
                Console.WriteLine("Success: " + response["success"]);<br/><br/>

                {'//'} Value for key "message" is a string, useful to check if the upload was unsuccessful.<br/>
                Console.WriteLine("Message: " + response["message"]);<br/><br/>

                {'//'} Value for key "warnings" is List&lt;string&gt;, if non empty there may be file upload issues.<br/>
                Console.WriteLine("Warnings: " + ((List&lt;string&gt;)response["warnings"]).Count);<br/><br/>

                {'//'} Value for key "errors" is List&lt;string&gt;, if success is true this will be empty.<br/>
                Console.WriteLine("Errors: " + ((List&lt;string&gt;)response["errors"]).Count);<br/>
            </pre>
        </div>

        const example =
        <div>
            <p className="mt-3">The data param passed to Upload is a Dictionary&lt;string, object&gt; containing your results data. Here is a complete example showing how to populate data with your build and test results and complete upload to Tesults with a call to Tesults.Results.Upload(data):</p>
            <i><small>Complete example:</small></i>
            <br/>
            <pre className="docsCode">
                <code className="accentc6">{'//'} Required namespaces:</code><br/>
                {'//'} using System;<br/>
                {'//'} using System.Collections.Generic;<br/><br/>
                
                <code className="accentc6">{'//'} Create a list to hold your test case results.</code><br/>
                var testCases = new List&lt;Dictionary&lt;string, object&gt;&gt;();<br/><br/>

                <code className="accentc6">
                {'//'} Each test case is a dictionary. Usually you would<br/>
                {'//'} create these in a loop from whatever data objects your<br/>
                {'//'} test framework provides.<br/>
                </code>
                var testCase1 = new Dictionary&lt;string, object&gt;();<br/>
                testCase1.Add("name", "Test 1");<br/>
                testCase1.Add("desc", "Test 1 Description");<br/>
                testCase1.Add("suite", "Suite A");<br/>
                testCase1.Add("result", "pass");<br/><br/>

                testCases.Add(testCase1);<br/><br/>

                var testCase2 = new Dictionary&lt;string, object&gt;();<br/>
                testCase2.Add("name", "Test 2");<br/>
                testCase2.Add("desc", "Test 2 Description");<br/>
                testCase2.Add("suite", "Suite A");<br/>
                testCase2.Add("result", "pass");<br/><br/>
                
                <code className="accentc6">{'//'} (Optional) For a paramaterized test case:</code><br/>
                var parameters = new Dictionary&lt;string, object&gt;();<br/>
                parameters.Add("param1", "value1");<br/>
                parameters.Add("param2", "value2");<br/>
                testCase2.Add("params", parameters);<br/><br/>

                <code className="accentc6">{'//'} (Optional) Custom fields start with an underscore:</code><br/>
                testCase2.Add("_CustomField", "Custom field value");<br/><br/>

                testCases.Add(testCase2);<br/><br/>

                var testCase3 = new Dictionary&lt;string, object&gt;();<br/>
                testCase3.Add("name", "Test 3");<br/>
                testCase3.Add("desc", "Test 3 Description");<br/>
                testCase3.Add("suite", "Suite B");<br/>
                testCase3.Add("result", "fail");<br/>
                testCase3.Add("reason", "Assert fail in line 203 of example.cs");<br/><br/>

                <code className="accentc6">{'//'} (Optional) Add start and end time for test:</code><br/>
                <code className="accentc6">{'//'} In this example, start is offset to 60 seconds earlier</code><br/> 
                <code className="accentc6">{'//'} but it should be set to current time when the test starts</code><br/>
                testCase3.Add("start", (DateTime.Now.Ticks / TimeSpan.TicksPerMillisecond) - 60000);<br/>
                testCase3.Add("end", DateTime.Now.Ticks / TimeSpan.TicksPerMillisecond);<br/><br/>

                <code className="accentc6">{'//'} Optional for file uploading</code><br/>
                var files = new List&lt;string&gt;();<br/>
                files.Add(@"full/path/to/file/log.txt");<br/>
                files.Add(@"full/path/to/file/screencapture.png");<br/>
                files.Add(@"full/path/to/file/metrics.xls");<br/>
                testCase3.Add("files", files);<br/><br/>

                <code className="accentc6">{'//'} Optional for adding test case steps</code><br/>
                var steps = new List&lt;Dictionary&lt;string, object&gt;&gt;();<br/><br/>
                var step1 = new Dictionary&lt;string, object&gt;();<br/>
                step1.Add("name", "Step 1");<br/>
                step1.Add("desc", "Step 1 Description");<br/>
                step1.Add("result", "pass");<br/>
                steps.Add(step1);<br/><br/>

                var step2 = new Dictionary&lt;string, object&gt;();<br/>
                step2.Add("name", "Step 2");<br/>
                step2.Add("desc", "Step 2 Description");<br/>
                step2.Add("result", "fail");<br/>
                step2.Add("reason", "Assert fail in line 203 of example.cs");<br/>
                steps.Add(step2);<br/><br/>

                testCase3.Add("steps", steps);<br/><br/>
                
                testCases.Add(testCase3);<br/><br/>

                <code className="accentc6">{'//'} The results dictionary will contain the test cases.</code><br/>
                var results = new Dictionary&lt;string, object&gt;();<br/>
                results.Add("cases", testCases);<br/><br/>

                <code className="accentc6">{'//'} Finally a dictionary to contain all of your results data.</code><br/>
                var data = new Dictionary&lt;string, object&gt;();<br/>
                data.Add("target", "token");<br/>
                data.Add("results", results);<br/><br/>

                <code className="accentc6">{'//'} Complete the upload.</code><br/>
                var response = <code className="accentb4">Tesults.Results.Upload(data);</code><br/><br/>

                {'//'} The response value is a dictionary with four keys.<br/><br/>

                {'//'} Value for key "success" is a bool, true if successfully uploaded, false otherwise.<br/>
                Console.WriteLine("Success: " + response["success"]);<br/><br/>

                {'//'} Value for key "message" is a string, useful to check if the upload was unsuccessful.<br/>
                Console.WriteLine("Message: " + response["message"]);<br/><br/>

                {'//'} Value for key "warnings" is List&lt;string&gt;, if non empty there may be file upload issues.<br/>
                Console.WriteLine("Warnings: " + ((List&lt;string&gt;)response["warnings"]).Count);<br/><br/>

                {'//'} Value for key "errors" is List&lt;string&gt;, if success is true this will be empty.<br/>
                Console.WriteLine("Errors: " + ((List&lt;string&gt;)response["errors"]).Count);<br/>
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your target token. If you have lost your token you can regenerate one from the config menu. The cases array should contain your test cases. You would usually add these by looping through the test case objects you currently have in your build and test scripts.</p>
            <p>The API library makes use of generics rather than providing classes with specific properties so that the package does not require updating often as and when the Tesults service adds fields.</p>
        </div>

        return (
            <DocsLanguage
                language="csharp"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsCSharp;