/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsAPIToken extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - API Token Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults API tokens."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select API Token.</p>
                <p>To be able to use the Tesults API and authenticate requests an API token is required.</p>
                <p>Click the 'Create API token' button to generate an API token. Keep the token secret and use it for all of your Tesults API requests.</p>
                <p>Click the 'Delete' button to delete an API token if it is no longer required.</p>
                <Link target="_blank" to="/docs/api/overview" className="primary4">Learn more about the Tesults API</Link>
                <p>Tesults is primarily utilized for analysis and reporting of test results data from within the Tesults interface. However there are situations where programmatic access to results data is useful, for example, when making decisions based on test results in a continuous integration or deployment pipeline. The Tesults API helps you do that.</p>
            </div>
        );
    }
}

export default DocsAPIToken;