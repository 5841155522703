/*global*/
import React, { Component } from 'react';

class TargetGroupItem extends Component {
    render () {
        if (this.props.item === undefined || this.props.item === null) {
            return <span></span>
        } else {
            return (
                <div style={{"display":"flex"}} className="neutral7bg rounderborder font14 p-3 m-3">
                    <div>
                        {this.props.item}
                    </div>
                    <div>
                        <button className="btn-transparent" onClick={() => this.props.remove(this.props.item)}>
                            <img src="/img/close-outline.svg" width="16" height="16" alt=""/>
                        </button>
                    </div>
                </div>
            )
        }
    }    
}

export default TargetGroupItem;