/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';

class DocsPHP extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>PHP test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle PHP test reporting with Tesults."/>
        </Helmet>

        const plugins = 
        <Box 
            bg="neutral8bg neutral1 mb-3"
            title={<h4>Limitations</h4>}
            content={<p>Unlike the C#, Java, Python, Ruby, Node.js and Go API Libraries, the PHP wrapper does not include automated file uploads. Use this for uploading results without files. If you are using PHP for build and automated test scripts and interested in a full fledged library please get in contact and we will provide you with one.</p>}
        />

        const installation =
        <p>The PHP API wrapper is one file and it is not available via a package manager such as Composer, a .php file is available for download directly here: <a className='tr-link-primary4 no-break no-break' href="https://www.tesults.com/tesults.php">tesults.php</a></p>

        const configuration = 
        <pre className="docsCode">
            <code className="accentb4">require 'tesults.php'</code>
        </pre>

        const usage = 
        <div>
        <p>Upload your test results using the results method.</p>
        <pre className="docsCode">
            <code className="accentb4">$res = results($data);</code><br/>
            {'//'} $res is an Associative Array.<br/>
            {'//'} Value for key 'success' is a Boolean.<br/>
            {'//'} True if results successfully uploaded, false otherwise.<br/>
            echo $res["success"] ? 'true' : 'false';<br/>
            echo "\n";<br/>
            {'//'} Value for key "message" is a String.<br/>
            {'//'} If success is false, check message to see why upload failed.<br/>
            echo $res["message"];<br/>
            echo "\n";
        </pre>
        </div>

        const example = 
        <div>
            <p className="mt-3">The $data param for results is an Associative Array containing your test results in the form:</p>
            <pre className="docsCode">
                &lt;?php<br/>
                &nbsp;&nbsp;require 'tesults.php';<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;$data = array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'target' => 'token',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'results' => array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"cases" => array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name" => "Test 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc" => "Test 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite" => "Suite A",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result" => "pass"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name" => "Test 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc" => "Test 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite" => "Suite B",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result" => "fail",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"reason" => "Assert fail in line 203 of example.php"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"_CustomField" => "Custom field value",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"params" => array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"param1" => "value1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"param2" => "value2"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"steps" => array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name" => "Step 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc" => "Step 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result" => "pass"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name" => "Step 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc" => "Step 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result" => "fail"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"reason" => "Assert fail in line 203 of example.php"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;array(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name" => "Test 3",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc" => "Test 3 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite" => "Suite A",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"start" => round(microtime(true) * 1000) - 60000,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"end" => round(microtime(true) * 1000),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result" => "pass"<br/>                    
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;);<br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;$res = results($data);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;echo $res["success"] ? 'true' : 'false';<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;echo "\n";<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;echo $res["message"];<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;echo "\n";<br/>
                ?&gt;
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu. The cases Array should contain your test cases. You would usually add these by looping through the test case objects you currently have in your build and test scripts.</p>
            <p>Each test case added to cases must have a name and a result. The result value must be one of 'pass', 'fail' or 'unknown'. All other fields are optional: desc, suite, params, steps, reason and custom fields (prefixed with underscore).</p>
            <p>The 'params' value is for use by parameterized test cases and is an Associative Array containing the parameter names and values.</p>
        </div>


        return (
            <DocsLanguage
                language="php"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsPHP;