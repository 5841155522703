/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Request from './Request';
import Confirmation from './Confirmation';
import MultiTextListInput from './MultiTextListInput';
import MultiTextList from './MultiTextList';

class AffiliateConfig extends Component {
    constructor () {
        super();
        // valid views are: view, add, edit, delete
        this.state = {view: "view", affiliates: [], confirmation: {success: undefined, failure: undefined}, disabledButtons: false, selectedAffiliateIndex: undefined, newAffiliateName: undefined, newAffiliateSources: []};
        this.affiliates = this.affiliates.bind(this);
        this.addNewAffiliate = this.addNewAffiliate.bind(this);
        this.addNewAffiliateConfirm = this.addNewAffiliateConfirm.bind(this);
        this.affiliateNameChange = this.affiliateNameChange.bind(this);
        this.sourcesSync = this.sourcesSync.bind(this);
        this.editSources = this.editSources.bind(this);
        this.deleteAffiliate = this.deleteAffiliate.bind(this);
        this.deleteAffiliateConfirm = this.deleteAffiliateConfirm.bind(this);
        this.updateSources = this.updateSources.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount () {
        this.affiliates();
    }

    affiliates () {
        Request.get("/affiliates", undefined, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                this.setState({affiliates: data.affiliates});
            }
        });
    }

    cancel () {
        this.setState({newAffiliateName: undefined, newAffiliateSources: [""], selectedAffiliateIndex: undefined, view: "view", confirmation: {success: undefined, failure: undefined}});
    }

    addNewAffiliate () {
        this.setState({newAffiliateName: undefined, view: "add", selectedAffiliateIndex: undefined});
    }

    addNewAffiliateConfirm () {
        this.setState({disabledButtons: true});
        Request.post("/affiliate-add", {name: this.state.newAffiliateName, sources: this.state.newAffiliateSources}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error adding affiliate"}, disabledButtons: false});
            } else {
                if (data.unavailable === true) {
                    this.setState({confirmation:{success: undefined, failure: "This custom link is unavailable. Please try another or leave it empty for Tesults to generate a random link for you."}, disabledButtons: false});
                } else {
                    let affiliates = this.state.affiliates;
                    affiliates.push(data.affiliate);
                    this.setState({affiliates: affiliates, disabledButtons: false, newAffiliateSources: [""], selectedAffiliateIndex: undefined, view: "view", confirmation: {success: undefined, failure: undefined}});
                }
            }
        });
    }

    affiliateNameChange (e) {
        if (/[^0-9a-zA-Z]/.test(e.target.value) !== true) { // Check for invalid characters, only support a-z and 0-9
            this.setState({newAffiliateName: e.target.value.toLowerCase()});
        }
    }

    sourcesSync (list) {
        if (list.length === 0) {
            list = [""];
        }
        this.setState({newAffiliateSources:list});
    }

    editSources (index) {
        this.setState({view: "edit", selectedAffiliateIndex: index});
    }

    deleteAffiliate (index) {
        this.setState({view: "delete", selectedAffiliateIndex: index});
    }

    deleteAffiliateConfirm () {
        this.setState({disabledButtons: true});
        let affiliate = this.state.affiliates[this.state.selectedAffiliateIndex];
        Request.post("/affiliate-delete", {id: affiliate.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error deleting affiliate"}, disabledButtons: false});
            } else {
                this.setState({disabledButtons: false, newAffiliateSources: [""], selectedAffiliateIndex: undefined, view: "view"}, this.affiliates);
            }
        });
    }

    deleteAffiliateCancel (index) {
        this.setState({disabledButtons: true});
        let affiliate = this.state.affiliates[index];
        Request.post("/affiliate-delete-cancel", {id: affiliate.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error deleting affiliate"}, disabledButtons: false});
            } else {
                this.setState({disabledButtons: false, newAffiliateSources: [""], selectedAffiliateIndex: undefined, view: "view"}, this.affiliates);
            }
        });
    }

    updateSources () {
        this.setState({disabledButtons: true});
        let affiliate = this.state.affiliates[this.state.selectedAffiliateIndex];
        Request.post("/affiliate-sources", {id: affiliate.id, sources: this.state.newAffiliateSources}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error updating affiliate sources"}, disabledButtons: false});
            } else {
                let affiliates = this.state.affiliates;
                affiliates[this.state.selectedAffiliateIndex] = data.affiliate;
                this.setState({affiliates: affiliates, disabledButtons: false, newAffiliateSources: [""], selectedAffiliateIndex: undefined, view: "view"});
            }
        });
    }

    renderAdd () {
        return (
            <div style={{"minHeight": "100vh"}}>
                <h1 className="neutral4">Configuration</h1>
                <h2 className="neutral2">Create new affiliate link</h2>
                <div className="mb-5" style={{"maxWidth":"800px"}}>
                    <h4 className="neutral2">Sources</h4>
                    <p className="neutral4 font15">To maintain high standards for the Tesults Affiliate Program and ensure suitably interested audiences are being directed by affiliates we ask for sources of traffic to be listed. The affiliate link will be an in 'unapproved' status until a member of the Tesults team reviews this information. You can start using the affiliate link immediately, even if it is unapproved, but payments will only be paid out to approved affiliates.</p>
                    <p className="neutral4 font15">Please provide top-level web domains, YouTube channels, social media pages and anywhere else where you intend to use the affiliate link. You do not need to list specific pages, a top-level domain is enough. We use this information to check that the traffic will be coming from technology, software engineering, testing or other sources where there may be an interest in Tesults from the audience. Sources information can be updated later so for now just input current intended usage.</p>
                    <p className="neutral4 font15">For anything that is not a domain, please indicate the site or app, e.g. input 'Twitter: @twitter-profile-name'.</p>
                    <MultiTextListInput list={[""]} sync={this.sourcesSync}/>
                </div>
                <div className="pt-3" style={{"maxWidth":"800px"}}>
                    <h4 className="neutral2">Custom Link (Optional)</h4>
                    <p className="neutral4 font15">You may choose a custom link. If you leave this empty Tesults will generate a random link for you using a universally unique identifer, resulting in a link that looks like this: https://www.tesults.com/ref/84e32bbf-b926-4e9a-a7bb-66aa91562655. If you prefer, you can have a custom link for yourself by typing in text below. The text must consist of letters and numbers only (a-z, 0-9). The link would be of the format: https://www.tesults.com/ref/custom</p>
                    <input className="tr-input" onChange={this.affiliateNameChange} value={this.state.newAffiliateName}/>
                </div>
                <div className="mt-4 mb-4">
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
                <div className="mt-2 mb-5">
                    <button className="btn-confirm" onClick={this.addNewAffiliateConfirm} disabled={this.state.disabledButtons}>Create affiliate link</button>
                </div>
            </div>
        );
    }

    renderEdit () {
        if (this.state.selectedAffiliateIndex === undefined) {
            return <div>No affiliate selected</div>
        }
        if (this.state.selectedAffiliateIndex >= this.state.affiliates.length) {
            return <div>Invalid affiliates selected</div>
        }
        let currentSources = this.state.affiliates[this.state.selectedAffiliateIndex].sources;
        if (currentSources === undefined) {
            currentSources = [""];
        }
        return (
            <div>
                <h2 className="neutral4">Edit affiliate link sources</h2>
                <div>
                    <h4 className="neutral3">Sources</h4>
                    <MultiTextListInput list={currentSources} sync={this.sourcesSync}/>
                </div>
                <div className="mt-5 mb-5">
                    <div style={{"display":"flex"}}> 
                        <div className="mr-3">
                            <button className="btn-confirm" onClick={this.updateSources} disabled={this.state.disabledButtons}>Save updated sources</button>
                        </div>
                        <div>
                            <button className="btn-cancel" onClick={this.cancel} disabled={this.state.disabledButtons}>Cancel</button>
                        </div>
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }

    renderDelete () {
        if (this.state.selectedAffiliateIndex === undefined) {
            return <div>No affiliate selected</div>
        }
        if (this.state.selectedAffiliateIndex >= this.state.affiliates.length) {
            return <div>Invalid affiliates selected</div>
        }
        let affiliate = this.state.affiliates[this.state.selectedAffiliateIndex];
        return (
            <div style={{"display":"flex", "flexDirection":"column"}} className="width100 mb-5">
                <div className="mb-3">
                    {<div><b>Referral id:</b> {affiliate.id}</div>}
                </div>
                <div className="mb-3">
                    {<div><b>Referral url:</b> <a href={"/ref=" + affiliate.id}>{"https://www.tesults.com/ref/" + affiliate.id}</a></div>}
                </div>
                <div className="mb-3">
                    {<div><b>Sources:</b></div>}
                    <MultiTextList list={affiliate.sources}/>
                </div>
                <div className="mb-3">
                    <p>Please confirm you want to delete this referral link. If you delete it, your referrals will not be attributed to you (the affiliate).</p>
                    <p>If this is a newly created link that has not been used, or only been used to visit the site without any sign up conversion, it will be deleted immediately. If at least one sign up has taken place using the link, the link will transition into a pending deletion state and a member of the team at Tesults will get in touch to confirm in writing that you do want to proceed with the deletion. While pending deletion you can opt to cancel the deletion.</p>
                </div>
                <div className="mb-3">
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className="mr-3">
                            <button className="btn-cancel" onClick={this.cancel}>Cancel</button>
                        </div>
                        <div className="mr-3">
                            <button className="btn-cancel" onClick={() => this.deleteAffiliateConfirm()}>Delete confirm</button>
                        </div>
                        <div className="mr-3">
                            {affiliate.pendingDelete === true ? <span className="neutral4 font12">Pending delete</span> : <span></span>}
                        </div>
                        <div>
                            {affiliate.approved === true ? <span className="neutral4 font12">Approved</span> : <span className="neutral4 font12">Unapproved</span>}
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
            </div>
        );
    }

    renderView () {
        let affiliateData = [];
        if (this.state.affiliates.length === 0) {
            affiliateData = <p>You have not created any affiliate links yet.</p>
        } else {
            for (let i = 0; i < this.state.affiliates.length; i++) {
                let affiliate = this.state.affiliates[i];
                if  (affiliate !== undefined) {
                    affiliateData.push(
                        <div style={{"display":"flex", "flexDirection":"column"}} className="width100 mb-5">
                            <div className="mb-3">
                                {i + 1}
                            </div>
                            <div className="mb-3">
                                {<div><b>Referral id:</b> {affiliate.id}</div>}
                            </div>
                            <div className="mb-3">
                                {<div><b>Referral url:</b> <a href={"/ref=" + affiliate.id}>{"https://www.tesults.com/ref/" + affiliate.id}</a></div>}
                            </div>
                            <div className="mb-3">
                                {<div><b>Sources:</b></div>}
                                <MultiTextList list={affiliate.sources}/>
                            </div>
                            <div className="mb-3">
                                <div style={{"display":"flex", "alignItems":"center"}}>
                                    <div className="mr-3">
                                        <button className="btn-cancel" onClick={() => this.editSources(i)}>Edit sources</button>
                                    </div>
                                    <div className="mr-3">
                                        {
                                            affiliate.pendingDelete === true ?
                                            <button className="btn-cancel" onClick={() => this.deleteAffiliateCancel(i)}>Cancel delete</button>
                                            :
                                            <button className="btn-cancel" onClick={() => this.deleteAffiliate(i)}>Delete link</button>
                                        }
                                    </div>
                                    <div className="mr-3">
                                        {affiliate.pendingDelete === true ? <span className="neutral4 font12">Pending delete</span> : <span></span>}
                                    </div>
                                    <div>
                                        {affiliate.approved === true ? <span className="neutral4 font12">Approved</span> : <span className="neutral4 font12">Unapproved</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }
        return (
            <div style={{"minHeight": "100vh"}}>
                <div style={{"display":"flex", "alignItems":"center"}}>
                    <div>
                        <h1 className="neutral1">Configuration</h1>
                    </div>
                    <div className="mobile-hide" style={{"marginLeft":"auto", "marginRight":"41px"}}>
                        <button className="select" onClick={() => window.location.reload()}>
                            <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                        </button>
                    </div>
                </div>
                <div>
                    {affiliateData}
                </div>
                <div className="mt-5">
                    <button className="btn-confirm" onClick={this.addNewAffiliate} disabled={this.state.disabledButtons}>Create affiliate link</button>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }

    render () {
        if (this.props.loggedIn !== true) {
            return (
                <div style={{"minHeight": "100vh"}}>
                    <h1 className="neutral1">Tesults affiliate program</h1>
                    <p>Please <NavLink to="/login?iref=affiliate" className="tr-link-primary4">sign in</NavLink>. If you do not have a Tesults account please <NavLink to="/register?iref=affiliate" className="tr-link-primary4">sign up</NavLink></p>
                </div>
            );    
        } else if (this.state.view === "view") {
            return this.renderView();
        } else if (this.state.view === "add") {
            return this.renderAdd();
        } else if (this.state.view === "edit") {
            return this.renderEdit();
        } else if (this.state.view === "delete") {
            return this.renderDelete();
        } else {
            return <p>Invalid state</p>
        }
    }
};

export default AffiliateConfig;