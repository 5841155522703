/*global */
import React, { Component } from 'react';
import Request from './Request';
import Copy from './Copy';
import Loading from './Loading';
import Cache from './Cache'
import Context from './Context'

class ConfigAPIToken extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {apiTokens: [], project: undefined};
        this.apiTokens = this.apiTokens.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
    };

    componentDidMount () {
        this.apiTokens();
    }

    apiTokens () {
        let project = undefined;
        try {
            project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        } catch (err) {
            return
        }
        this.setState({loading: true});
        Request.get("/api-tokens", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({loading: false, project: project, apiTokens: data.tokens, esk: data.lek});
            }
        });
    }

    create () {
        this.setState({loading: true});
        Request.post("/api-token-create", {id: this.state.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let token = data.token;
                let tokens = this.state.apiTokens;
                tokens.push(token);
                this.setState({loading: false, apiTokens: tokens, esk: data.lek});
            }
        });
    }

    delete (tokenDelete) {
        this.setState({loading: true});
        Request.post("/api-token-delete", {id: this.state.project.id, token: tokenDelete}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let token = data.token;
                let tokens = this.state.apiTokens;
                for (let i = 0; i < tokens.length; i++) {
                    if (tokens[i].token === token) {
                        tokens.splice(i, 1);
                    }
                }
                this.setState({loading: false, apiTokens: tokens, esk: data.lek});
            }
        });
    }

    render () {
        if (this.state.loading === true) {
            return <Loading/>
        }
        let apiTokens = [];
        let message = <div className="mt-5 mb-4 neutral3 font15">
                        <h4>API token</h4>
                        <p className="neutral4">Keep this token secret and use it for Tesults API requests.</p>
                    </div>
        let create = <span></span>
        if (this.state.apiTokens.length > 0) {
            for (let i = 0; i < this.state.apiTokens.length; i++) {
                apiTokens.push(
                    <div style={{"display":"flex"}} className="whitebg font14 p-3 m-3 width100">
                        <div style={{"flex": "4"}} className="mr-3">
                            <div className="wrapforce">
                                {this.state.apiTokens[i].token}
                            </div>
                        </div>
                        <div style={{"flex": "1"}}>
                            <Copy text={this.state.apiTokens[i].token}/>
                        </div>
                        <div style={{"flex": "1"}}>
                            <div className="wrap">
                                API:&nbsp;
                                {this.state.apiTokens[i].api}
                            </div>
                        </div>
                        <div style={{"flex": "2"}}>
                            <button className="btn-cancel" onClick={() => this.delete(this.state.apiTokens[i].token)}>
                                Delete
                            </button>
                        </div>
                    </div>
                );
            }
        }
        if (this.state.apiTokens.length === 0) {
            message = <div className="mt-5 mb-4 neutral3 font15">No API tokens created. To utilize the Tesults API create an API token.</div>
            create = <button className="btn-confirm" onClick={this.create}>Create API token</button>
        }
        return (
            <div>
                {message}
                {apiTokens}
                {create}
            </div>
        );
    }
}

export default ConfigAPIToken;