/*global */
import React, { Component } from 'react';
import CaseGroup from './CaseGroup';

class SupplementalFlaky extends Component {
    render() {
        let flaky = [];
        if (this.props.cases !== undefined) {
            this.props.cases.forEach(function (c) {
                if (c.flaky !== undefined) {
                    if (c.flaky === true) {
                        /*flaky.push(
                            <Case 
                            key={flaky.length}
                            overlay={this.props.overlay}
                            selectCase={this.props.selectCase} 
                            projects={this.props.projects} 
                            projectIndex={this.props.projectIndex} 
                            targets={this.props.targets} 
                            targetIndex={this.props.targetIndex} 
                            runs={this.props.runs} 
                            runIndex={this.props.runIndex} 
                            case={c}
                            persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                            trl={this.props.trl}/>
                            
                        )*/
                        flaky.push(c);
                    }
                }
            });
        }

        /*if (flaky.length === 0) {
            flaky.push(<div key={flaky.length}>
                        <p>No flaky test cases.</p>
                    </div>)
        }*/
        
        return <div>
                    <CaseGroup
                        cases={flaky}
                        selectCase={this.props.selectCase} 
                        projects={this.props.projects}
                        projectIndex={this.props.projectIndex}
                        targets={this.props.targets} 
                        targetIndex={this.props.targetIndex} 
                        runs={this.props.runs} 
                        runIndex={this.props.runIndex} 
                        /*context={context} */
                        /*assignee={assignee} */
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.props.multiselect}
                        /*multiselectGroup={multiselectGroup}*/
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        //checked={this.state.multiselect[c.num] === true ? true : false}
                        members={this.props.members}
                        trl={this.props.trl}
                        //saving={saving}
                        titleOverride={"Flaky tests"}
                        /*duration={duration}
                        intervalStart={start}
                        intervalEnd={end}*/
                        rawResultMaps={this.props.rawResultMaps}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        side={this.props.side}
                    />
            </div>
    }
};

export default SupplementalFlaky;