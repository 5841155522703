/*global */
import React, { Component } from 'react';
import DocsFilesStructure from './DocsFilesStructure';

class DocsFiles extends Component {
    render() {
        let helperFunction = <span></span>
        if (this.props.language === "nodejs") {
            helperFunction = 
            <pre className="docsCode">
            const tesults = require('tesults');<br/>
            const fs = require('fs');<br/>
            const temp = '/files-temp-dir';<br/><br/>

            function filesForTestSync (suite, testCase) {'{'}<br/>
            &nbsp;&nbsp;let filePaths = [];<br/>
            &nbsp;&nbsp;let dir =  temp + "/" + suite + "/" + testCase;<br/>
            &nbsp;&nbsp;let files = [];<br/>
            &nbsp;&nbsp;try {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;files = fs.readdirSync(dir);<br/>
            &nbsp;&nbsp;{'}'} catch (error) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp; {'//'} dir may not exist<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;files.forEach(function (file) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;if (file !== ".DS_Store") {'{'} {'//'} Exclude os files<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;filePaths.push(dir + "/" + file);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;}<br/>
            &nbsp;&nbsp;{'}'});<br/>
            &nbsp;&nbsp;return filePaths;<br/>
            {'}'}<br/><br/>
            {'//'}testCase.files = filesForTestSync(testCase.suite, testCase.name);
            </pre>
        } 
        if (this.props.language === "python") {
            helperFunction =
            <pre className="docsCode">
            import sys<br/>
            import os<br/>
            import tesults<br/>
            tempDir = "/files-temp-dir"<br/><br/>
            
            def filesForTest (suite, name):<br/>
            &nbsp;&nbsp;files = []<br/>
            &nbsp;&nbsp;path = os.path.join(os.path.dirname(os.path.realpath(__file__)), tempDir, suite, name)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;if os.path.isdir(path):<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for dirpath, dirnames, filenames in os.walk(path):<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for file in filenames:<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if file != '.DS_Store': # Exclude os files<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;files.append(os.path.join(path, file))<br/>
            &nbsp;&nbsp;return files<br/><br/>
            {'#'}testCase['files'] = filesForTest(testCase['suite'], testCase['name'])
            </pre>
        }
        if (this.props.language === "java") {
            helperFunction =
            <pre className="docsCode">
            import java.io.File;<br/>
            import java.nio.file.Path;<br/>
            import java.nio.file.Paths;<br/>
            import java.util.ArrayList;<br/>
            import java.util.HashMap;<br/>
            import java.util.List;<br/>
            import java.util.Map;<br/><br/>

            final static String tempDir = "/files-temp-dir";<br/><br/>

            public static List&lt;String&gt; filesForTest (String suite, String name) {'{'}<br/>
            &nbsp;&nbsp;List&lt;String&gt; filePaths = new ArrayList&lt;String&gt;();<br/><br/>

            &nbsp;&nbsp;Path path = (Path) Paths.get(tempDir, suite, name);<br/>
            &nbsp;&nbsp;String fullPath = path.toString();<br/><br/>

            &nbsp;&nbsp;File dir = new File(fullPath);<br/><br/>

            &nbsp;&nbsp;for (final File file : dir.listFiles()) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;if (!file.getName().equals((".DS_Store"))) {'{'} {'//'} Exclude os files<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;filePaths.add(file.getPath());<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/><br/>

            &nbsp;&nbsp;return filePaths;<br/>
            {'}'}<br/><br/>

            {'//'}testCase.put("files", filesForTest((String) testCase.get("suite"), (String) testCase.get("name")));
            </pre>
        }

        if (this.props.language === "kotlin") {
            helperFunction =
            <pre className="docsCode">
            import java.io.File<br/>
            import java.nio.file.Path<br/>
            import java.nio.file.Paths<br/>
            import java.util.ArrayList<br/>
            import java.util.HashMap<br/><br/>

            internal val tempDir = "/files-temp-dir"<br/><br/>

            fun filesForTest(suite: String, name: String): List&lt;String&gt; {'{'}<br/>
            &nbsp;&nbsp;val filePaths = ArrayList&lt;String&gt;()<br/><br/>

            &nbsp;&nbsp;val path = Paths.get(tempDir, suite, name) as Path<br/>
            &nbsp;&nbsp;val fullPath = path.toString()<br/><br/>

            &nbsp;&nbsp;val dir = File(fullPath)<br/><br/>

            &nbsp;&nbsp;for (file in dir.listFiles()!!) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;if (file.name != ".DS_Store") {'{'} {'//'} Exclude os files <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;filePaths.add(file.path)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/><br/>

            &nbsp;&nbsp;return filePaths<br/>
            {'}'}<br/><br/>

            {'//'}testCase.put("files", filesForTest(testCase["suite"] as String, testCase["name"] as String))
            </pre>
        }

        if (this.props.language === "ruby") {
            helperFunction = 
            <pre className="docsCode">
            require 'tesults'<br/><br/>

            def filesForTest (suite, testCase)<br/>
            &nbsp;&nbsp;temp = '/temp-files-dir';<br/>
            &nbsp;&nbsp;filePaths = []<br/>
            &nbsp;&nbsp;searchDir = File.join(temp, suite, testCase, "*")<br/>
            &nbsp;&nbsp;files = []<br/>
            &nbsp;&nbsp;begin<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;files = Dir[searchDir]<br/>
            &nbsp;&nbsp;rescue<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;# dir may not exist<br/>
            &nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;files.each {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;|file|<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;if file != ".DS_Store" # Exclude os files<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;filePaths.push(file)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;return filePaths;<br/>
            end<br/><br/>

            #testCase[:files] = filesForTest(testCase[:suite], testCase[:name])
            </pre>
        }

        if (this.props.language === "swift") {
            return <span></span>
        }

        if (this.props.language === "go") {
            helperFunction = 
            <pre className="docsCode">
            import (<br/>
            &nbsp;&nbsp;"fmt"<br/>
            &nbsp;&nbsp;"io/ioutil"<br/>
            &nbsp;&nbsp;"path"<br/>
            &nbsp;&nbsp;"strconv"<br/><br/>

            &nbsp;&nbsp;"./tesults"<br/>
            )<br/><br/>

            func filesForTest(suite string, name string) []string {'{'}<br/>
            &nbsp;&nbsp;tempDir := "/temp-files-dir"<br/>
            &nbsp;&nbsp;dir := path.Join(tempDir, suite, name)<br/>
            &nbsp;&nbsp;var filePaths []string<br/>
            &nbsp;&nbsp;files, err := ioutil.ReadDir(dir)<br/>
            &nbsp;&nbsp;if err != nil {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'//'} dir may not exist<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;for _, f := range files {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;if f.Name() != ".DS_Store" {'{'} {'//'} Exclude os files<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;filePaths = append(filePaths, path.Join(dir, f.Name()))<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;return filePaths<br/>
            {'}'}<br/><br/>

            {'/*'}<br/>
            testCase: = map[string]interface{}{'{'}<br/>
            &nbsp;&nbsp;"name":   "Test 1",<br/>
            &nbsp;&nbsp;"desc":   "Test 1 description",<br/>
            &nbsp;&nbsp;"suite":  "Suite A",<br/>
            &nbsp;&nbsp;"result": "pass",<br/>
            &nbsp;&nbsp;"files":  filesForTest("Suite A", "Test 1"),<br/>
            {'}'}<br/>
            {'*/'}
            </pre>
        }

        if (this.props.language === "csharp") {
            helperFunction = 
            <pre className="docsCode">
            using System;<br/>
            using System.IO;<br/><br/>

            static List&lt;string&gt; filesForTest (string suite, string name) {'{'}<br/>
            &nbsp;&nbsp;var tempDir = @"C:\temp-files-dir";<br/>
            &nbsp;&nbsp;var filePaths = new List&lt;string&gt;();<br/>
            &nbsp;&nbsp;var dir = Path.Combine(tempDir, suite, name);<br/>
            &nbsp;&nbsp;try<br/>
            &nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;var files = Directory.GetFiles(dir);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;foreach(var file in files)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;filePaths.Add(file);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;catch (Exception)<br/>
            &nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'//'} Test may not have files<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;return filePaths;<br/>
            {'}'}<br/><br/>

            {'/*'}<br/>
            var testCase1 = new Dictionary&lt;string, object&gt;();<br/>
            testCase1.Add("name", "Test 1");<br/>
            testCase1.Add("desc", "Test 1 Description");<br/>
            testCase1.Add("suite", "Suite A");<br/>
            testCase1.Add("result", "pass");<br/>
            testCase1.Add("files", filesForTest("Suite A", "Test 1"));<br/>
            {'*/'}
            </pre>
        }

        if (this.props.language === "php" || 
            this.props.language === "javascript" ||
            this.props.language === "go") {
            return <span></span>
        }

        return (
            <div className="mb-3">
                <h2>Files generated by tests</h2>
                <p>The example above demonstrates how to upload files for each test case. In practice you would generate the array of file paths for each test case programatically.</p>
                <p>To make this process simpler we suggest you write a helper function to extract files for each test case and this can be easily achieved by following a couple of simple conventions when testing.</p>
                <p>1. Store all files in a temporary directory as your tests run. After Tesults upload is complete you can delete the temporary directory or overwrite it on the next test run.</p>
                <p>2. Within this temporary directory create subdirectories for each test case so that files for each test case are easily mapped to a particular test case.</p>
                <DocsFilesStructure/>
                <p>Then all your helper function needs to do is take the test name and/or suite as parameters and return an array of files for that particular test case.</p>
                {helperFunction}
                <p>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</p>
            </div>
        );
    }
}

export default DocsFiles;