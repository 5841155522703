
/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';
import DocsFilesStructure from './DocsFilesStructure';

class DocsNightwatch extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Nightwatch test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Nightwatch test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <p>If you are not using the <a className="site-link-primary2 no-break" target="_blank" href="https://nightwatchjs.org" rel="noopener noreferrer">Nightwatch</a> test framework but are using JavaScript/Node.js view the <NavLink to="/docs/nodejs" target="_blank" className="site-link-primary2 no-break">Node.js</NavLink> docs instead for integrating with a lower-level library.</p>

        const installation = 
        <div>
            <p>Tesults supplies a reporter specifically designed for Nightwatch to make integration quick and easy.</p>
            <p>Install nightwatch-tesults:</p>
            <pre className="docsCode">
                npm install nightwatch-tesults --save
            </pre>
        </div>

        const configuration = 
        <div>
            <p>In your nightwatch.conf.js file, under <i>test_settings</i> and any environment for which you are testing add a  property called <i>tesults</i> (in this example the <i>default</i> environment is being used):</p>
            <span className="font14 neutral4">nightwatch.conf.js</span>
            <br/>
            <pre className='docsCode'>
            module.exports = {'{'}<br/>
            &nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;test_settings: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;default: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;globals: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">tesults</code>: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">target</code>: <code className="accentb4">token</code><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;...<br/>
            {'}'}
            </pre>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="site-link-primary2">configuration menu</a>.</p>
            <p>Then run your tests as usual and use the reporter parameter to use nightwatch-tesults:</p>
            <pre className="docsCode">
            npx nightwatch tests --reporter nightwatch-tesults
            </pre>
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point nightwatch-tesults will push results to Tesults after your tests complete. The target arg must be supplied in the nightwatch.conf.js file otherwise reporting will be disabled.</p>}
            />
            <h2>Files generated by tests</h2>
            <p>Any files attached during a test using the built-in Nightwatch screenshot mechanism (enabled in nightwatch.conf.js) will be automatically uploaded to Tesults for reporting.</p>
            <span className='font12 neutral4'>Example of enabling Nightwatch screenshots on failure. Any files attached in this way in your tests will be automatically uploaded to Tesults.</span>
            <br/>
            <pre className='docsCode'>
            module.exports = {'{'}<br/>
            &nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;test_settings: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;default: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;screenshots: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enabled: true<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;path: 'screens'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on_failure: true<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;...<br/>
            {'}'}
            </pre>
            <div className='bold'>Other files</div>
            <p>To upload other files, make use of the <i>files</i> property in the <i>tesults</i> object in the nightwatch.conf.js file. The value for this property is an absolute path to a directory where you store all of your test generated files during at test run. This directory can be temporary, after the test run has completed and files uploaded it can be deleted or overwritten.</p>
            <details>
                <summary className='underline_dotted'>These other files must be stored here under a specific directory structure.</summary>
                <div>
                    <DocsFilesStructure/>
                </div>
            </details>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h2>Handling multiple test jobs with targets</h2>
            <p>For supporting multiple test jobs Tesults suggests creating multiple targets, as outlined in the <NavLink to="/docs/structure" target="_blank" className="site-link-primary2">project structuring advice</NavLink> you can specify different target tokens for different environments within your nightwatch.conf.js file or even have multiple configuration files.</p>
            <h2>Full parameter list</h2>
            <pre className='docsCode'>
            module.exports = {'{'}<br/>
            &nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;test_settings: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;default: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;globals: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">tesults</code>: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">target</code>: <code className="accentb4">token</code>,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">build_name</code>: <code className="accentb4">"1.0.0"</code>,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">build_result</code>: <code className="accentb4">"pass"</code>,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">build_desc</code>: <code className="accentb4">"Build description"</code>,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">build_reason</code>: <code className="accentb4">"Build failure reason"</code>,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="primary4">files</code>: <code className="accentb4">"/full/path/to/temp/files/directory"</code><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;...<br/>
            {'}'}
            </pre>
            <h2>Target</h2>
            <pre className="primary2 docsArgsCode pl-0">target<span className="neutral7 ml-5">Required</span></pre>
            <p>Required arg to upload to Tesults, if this arg is not provided the reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">build_name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">build_result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">build_desc<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">build_reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">files<span className="neutral7 ml-5">Optional</span></pre>
            <p>Absolute path to the folder containing additional files to upload. The directory structure must match the described layout in the 'Other files' section above.</p>
        </div>

        return (
            <div>
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                build={build}
                integration="nightwatch"
            />
            </div>
        );
    }
}

export default DocsNightwatch