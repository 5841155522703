/*global*/
import React, { PureComponent } from 'react';
import Constants from './Constants';
import Copy from './Copy';

// Shares RSP (results specific path) links
class Share extends PureComponent {
    render() {
        const view = this.props.view
        const projects = this.props.projects;
        const targets = this.props.targets;
        const runs = this.props.runs;
        const projectIndex = this.props.projectIndex;
        const targetIndex = this.props.targetIndex;
        const runIndex = this.props.runIndex;
        
        const baseUrl = Constants.baseUrl;
        
        let projectUrl = <div></div>
        let targetUrl = <div></div>
        let runUrl = <div></div>
        let caseUrl = <div></div>
        let listUrl = <div></div>
        let manualUrl = <div></div>

        if (view === "list") {
            if (this.props.list !== undefined) {
                let lurl = baseUrl + "/lists/lsp/" + this.props.list.id + "/" + this.props.list.created;
                listUrl = 
                <div>
                    <div className="font14 bold neutral2 mb-2">Link to list <Copy text={lurl}/></div>
                    <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={lurl}>{lurl}</a></div>
                </div>
                if (this.props.selectedCase !== undefined) {
                    let curl = Constants.baseUrl + "/lists/lsp/" + this.props.list.id + "/" + this.props.list.created + "/" + this.props.selectedCase.num;
                    caseUrl = 
                    <div>
                        <hr/>
                        <div className="font14 bold neutral2 mb-2">Link to test case <Copy text={curl}/></div>
                        <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={curl}>{curl}</a></div>
                    </div>
                }
            } else {
                return (
                    <div>
                    </div>
                );
            }
        } if (view === "manual") {
            if (this.props.manual !== undefined) {
                let murl = baseUrl + "/manual/msp/" + this.props.manual.id + "/" + this.props.manual.created;
                manualUrl = 
                <div>
                    <div className="font14 bold neutral2 mb-2">Link to run <Copy text={murl}/></div>
                    <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={murl}>{murl}</a></div>
                </div>
                if (this.props.selectedCase !== undefined) {
                    let curl = Constants.baseUrl + "/manual/msp/" + this.props.manual.id + "/" + this.props.manual.created + "/" + this.props.selectedCase.num;
                    caseUrl = 
                    <div>
                        <hr/>
                        <div className="font14 bold neutral2 mb-2">Link to test case <Copy text={curl}/></div>
                        <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={curl}>{curl}</a></div>
                    </div>
                }
            } else {
                return (
                    <div>
                    </div>
                );
            }
        } else if (view !== undefined) {
            if (projectIndex < projects.length) {
                let purl = baseUrl + "/results/rsp/view/" + view + "/project/" + projects[projectIndex].id;
                let public_project = <span></span>
                if (projects[projectIndex].public) {
                    if (this.props.org) {
                        const public_purl = baseUrl + "/" + this.props.org.name + "/" + projects[projectIndex].name
                        public_project =
                        <div>
                            <div className="font14 bold neutral2 mb-2">Public link <Copy text={public_purl}/></div>
                            <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={public_purl}>{public_purl}</a></div>
                        </div>
                    }
                }
                projectUrl =
                <div>
                    <div className="font14 bold neutral2 mb-2">Link to project <Copy text={purl}/></div>
                    <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={purl}>{purl}</a></div>
                    {public_project}
                </div>
                if (targetIndex < targets.length && view !== "status") {
                    let turl = baseUrl + "/results/rsp/view/" + view + "/target/" + targets[targetIndex].id + "-" + targets[targetIndex].created;
                    let public_target = <span></span>
                    let public_turl = undefined
                    if (projects[projectIndex].public) {
                        if (this.props.org) {
                            if (targets[targetIndex].public) {
                                public_turl = baseUrl + "/" + this.props.org.name + "/" + projects[projectIndex].name + "/" + targets[targetIndex].name
                                public_target =
                                <div>
                                    <div className="font14 bold neutral2 mb-2">Public link <Copy text={public_turl}/></div>
                                    <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={public_turl}>{public_turl}</a></div>
                                </div>
                            }
                        }
                    }
                    targetUrl = 
                    <div>
                        <hr/>
                        <div className="font14 bold neutral2 mb-2">Link to project and target <Copy text={turl}/></div>
                        <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={turl}>{turl}</a></div>
                        {public_target}
                    </div>
                    if (runIndex < runs.length && view !== "status") {
                        let rurl = baseUrl + "/results/rsp/view/" + view + "/run/" + runs[runIndex].id + "-" + runs[runIndex].created;
                        if (this.props.isRunArchive === true) {
                            rurl = baseUrl + "/results/rsp/view/" + view + "/run-archive/" + this.props.runArchive.id + "-" + this.props.runArchive.created
                        }
                        let public_rurl = undefined
                        let public_run = <span></span>
                        if (public_turl === undefined) {
                            // Run not public
                        } else {
                            public_rurl = public_turl + "/" + runs[runIndex].created
                            public_run =
                            <div>
                                <div className="font14 bold neutral2 mb-2">Public link <Copy text={public_rurl}/></div>
                                <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={public_rurl}>{public_rurl}</a></div>
                            </div>
                        }
                        runUrl = 
                        <div>
                            <hr/>
                            <div className="font14 bold neutral2 mb-2">Link to test run <Copy text={rurl}/></div>
                            <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={rurl}>{rurl}</a></div>
                            {public_run}
                        </div>

                        if (this.props.selectedCase !== undefined) {
                            let curl = Constants.baseUrl + "/results/rsp/view/" + this.props.view + "/case/" + this.props.runs[this.props.runIndex].id + "-" + this.props.runs[this.props.runIndex].created + "-" + this.props.selectedCase.num;
                            if (this.props.isRunArchive === true) {
                                curl = Constants.baseUrl + "/results/rsp/view/" + this.props.view + "/case-archive/" + this.props.runArchive.id + "-" + this.props.runArchive.created + "-" + this.props.selectedCase.num;
                            }
                            let public_curl = undefined
                            let public_case = <span></span>
                            if (public_rurl === undefined) {
                                // Case not public
                            } else {
                                public_curl = public_rurl + "/" + this.props.selectedCase.num
                                public_case =
                                <div>
                                    <div className="font14 bold neutral2 mb-2">Public link <Copy text={public_curl}/></div>
                                    <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={public_curl}>{public_curl}</a></div>
                                </div>
                            }
                            caseUrl = 
                            <div>
                                <hr/>
                                <div className="font14 bold neutral2 mb-2">Link to test case <Copy text={curl}/></div>
                                <div className="mb-2"><a className="tr-link-dark wrapforce font14" href={curl}>{curl}</a></div>
                                {public_case}
                            </div>
                        }
                    }
                }
            } else {
                return (
                    <div>
                    </div>
                );
            }
        }

        return (
            <div style={{"width": "320px"}} className="p-5">
                <div style={{"display":"flex", "flexDirection":"column"}}>
                    {listUrl}
                    {manualUrl}
                    {projectUrl}
                    {targetUrl}
                    {runUrl}
                    {caseUrl}
                </div>
            </div>
        );
    }
}

export default Share;