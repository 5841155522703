/*global */
import React, { Component } from 'react';

class DocsTitle extends Component {
    constructor () {
        super();
        this.state = {title: "Documentation"};
    }

    componentDidMount () {
        let docGroup = this.props.docGroup;
        let doc = this.props.doc;
        let title = "Documentation";
        if (doc === "getting-started") {
            title = "Getting Started";
        } else if (doc === "project") {
            title = "Project Configuration";
        } else if (doc === "targets") {
            title = "Target Configuration";
        } else if (doc === "target-order") {
            title = "Target Order Configuration";
        } else if (doc === "build-consolidation") {
            title = "Consolidate parallel test runs with build consolidation";
        } else if (doc === "result-interpretation") {
            title = "Result interpretation";
        } else if (doc === "preferences") {
            title = "Preferences";
        } else if (doc === "notifications") {
            title = "Notifications";
        } else if (doc === "integrations") {
            title = "Integrations";
        } else if (doc === "api-token") {
            title = "API Token";
        } else if (doc === "team-members") {
            title = "Team Members";
        } else if (doc === "sso") {
            title = "Single sign-on";
        } else if (doc === "organization") {
            title = "Organization Configuration";
        } else if (doc === "audit-logs") {
            title = "Audit Logs";
        } else if (doc === "plan") {
            title = "Plan Configuration";
        } else if (doc === "payment-details") {
            title = "Payment Details";
        } else if (doc === "account-country") {
            title = "Account Country";
        } else if (doc === "invoices") {
            title = "Invoices";
        } else if (doc === "user") {
            title = "Profile Configuration";
        } else if (doc === "results" && docGroup !== "api") {
            title = "Results";
        } else if (doc === "supplemental") {
            title = "Supplemental";
        } else if (doc === "dashboard") {
            title = "Dashboard";
        } else if (doc === "diff") {
            title = "Diff";
        } else if (doc === "release-checklists") {
            title = "Release Checklists";
        } else if (doc === "tasks") {
            title = "Tasks";
        } else if (doc === "lists") {
            title = "Test Lists";
        } else if (doc === "manual") {
            title = "Manual Test Runs";
        } else if (doc === "target") {
            title = "Targets";
        } else if (doc === "structure") {
            title = "How to structure your Tesults project";
        }  else if (doc === "support-policy") {
            title = "Support";
        } else if (doc === "refund-policy") {
            title = "Cancel and Refund";
        } else if (doc === "fair-billing-policy") {
            title = "Fair Billing Policy";
        } else if (doc === "privacy") {
            title = "Privacy";
        } else if (doc === "security") {
            title = "Security";
        } else if (doc === "resultsapi") {
            title = "/results";
        } else if (doc === "junit-xml") {
            title = "JUnit XML Format";
        } else if (doc === "xunit") {
            title = "xUnit test reporting";
        }  else if (doc === "csharp") {
            title = "C# test reporting";
        } else if (doc === "python") {
            title = "Python test reporting";
        } else if (doc === "nodejs") {
            title = "Node.js test reporting";
        } else if (doc === "java") {
            title = "Java test reporting";
        } else if (doc === "ruby") {
            title = "Ruby test reporting";
        } else if (doc === "swift") {
            title = "Swift test reporting";
        } else if (doc === "kotlin") {
            title = "Kotlin test reporting";
        } else if (doc === "php") {
            title = "PHP test reporting";
        } else if (doc === "go") {
            title = "Go test reporting";
        } else if (doc === "javascript") {
            title = "JavaScript & Node.js";
        } else if (doc === "others") {
            title = "Other Languages";
        } else if (doc === "terms") {
            title = "Terms of Service";
        } else if (doc === "vendor-onboarding") {
            title = "Tesults Vendor Onboarding and Due Diligence";
        }  else if (doc === "exp") {
            title = "";
        } else if (doc === "junit5") {
            title = "JUnit 5 test reporting";
        } else if (doc === "junit4") {
            title = "JUnit 4 test reporting";
        } else if (doc === "testng") {
            title = "TestNG test reporting";
        } else if (doc === "pytest") {
            title = "pytest test reporting";
        } else if (doc === "robot") {
            title = "Robot Framework test reporting";
        } else if (doc === "vsunittf") {
            title = "Visual Studio Unit Testing Framework (MSTest)";
        } else if (doc === "nunit3") {
            title = "NUnit 3 test reporting";
        } else if (doc === "mocha") {
            title = "Mocha test reporting";
        } else if (doc === "jasmine") {
            title = "Jasmine test reporting";
        } else if (doc === "playwright") {
            title = "Playwright test reporting";
        } else if (doc === "jest") {
            title = "Jest test reporting";
        } else if (doc === "wdio") {
            title = "WebdriverIO test reporting";
        } else if (doc === "codeceptjs") {
            title = "CodeceptJS test reporting";
        } else if (doc === "nightwatch") {
            title = "Nightwatch test reporting";
        } else if (doc === "waffle") {
            title = "Waffle smart contracts test reporting";
        } else if (doc === "postman") {
            title = "Postman reporting";
        }  else if (doc === "protractor") {
            title = "Protractor test reporting";
        } else if (doc === "cypress") {
            title = "Cypress test reporting";
        } else if (doc === "testcafe") {
            title = "TestCafe test reporting";
        } else if (doc === "xctest") {
            title = "XCTest test reporting";
        } else if (doc === "espresso") {
            title = "Espresso test reporting";
        } else if (doc === "rspec") {
            title = "RSpec test reporting";
        } else if (doc === "test-framework-developers") {
            title = "Test framework developers";  
        } else if (doc === "tesults-json-data-standard") {
            title = "Tesults JSON data standard";  
        } else if (doc === "github-actions") {
            title = "Github Actions";
        }  else if (doc === "slack") {
            title = "Slack";
        } else if (doc === "msteams") {
            title = "MS Teams";
        } else if (doc === "mattermost") {
            title = "Mattermost";
        } else if (doc === "pagerduty") {
            title = "PagerDuty";
        } else if (doc === "selenium") {
            title = "Selenium";
        } else if (doc === "pricing") {
            title = "Pricing";
        } else if (doc === "developer-integrations" || doc === "developer-integrations-upwork") {
            title = "Developer Integrations"
        }
        if (docGroup === "api") {
            if (doc === "overview") {
                title = "Tesults API Overview";
            } else if (doc === "auth") {
                title = "Tesults API Authentication";
            } else if (doc === "targets") {
                title = "Tesults API Targets";
            } else if (doc === "results") {
                title = "Tesults API Results";
            }
        }
        this.setState({title: title});
    }

    render() {
        return <h1>{this.state.title}</h1>
    }
}

export default DocsTitle;