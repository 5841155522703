/*global*/
import React, { Component } from 'react';

class SelectSide extends Component {
    constructor () {
        super();        
        this.activate = this.activate.bind(this);
    }

    componentDidMount () {
        if (this.props.sideOpen === true) {
            this.activate();
        }
    }

    componentDidUpdate () {
        if (this.props.sideOpen === true) {
            this.activate();
        }
    }

    activate () {
        if (this.props.onClick !== undefined) {
            this.props.onClick();
        }
        this.props.side(this.props.content, this.props.onClose);
    }

    render () {
        if (this.props.sideOpen === true) {
            return <span></span>
        } else {
            return (
                <button className="select" onClick={this.activate}>
                    {
                        this.props.selectContent !== undefined ?
                        <div>
                            {this.props.selectContent}
                        </div>
                        :
                        <span>
                            {/*{this.props.image}
                            &nbsp;
                            {this.props.label}*/}
                        </span>
                    }
                </button>
            );
        }
    }
};

export default SelectSide;