/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';
import Notice  from './Notice'

class DocsEspresso extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Espresso test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Espresso test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <div>
            <p>Using the <a className="site-link-primary2" target="_blank" href="https://developer.android.com/training/testing/espresso" rel="noopener noreferrer">Espresso</a> test framework? Follow the instructions below to report results. If you are not using Espresso please see the <NavLink to="/docs/java" target="_blank" className="site-link-primary2">Java</NavLink> docs for integrating with a lower-level library.</p>
            <Notice type="information" content={<span>An alternative way to report results if you do not want to follow the instructions below is to utilize the <NavLink to="/docs/junit-xml" target="_blank" className="tr-link-primary4 no-break">JUnit XML output from Espresso</NavLink> but the method below is recommended because you can also upload files that way.</span>}/>
        </div>

        const installation = 
        <div>
            <p>Espresso uses the JUnit 4 test framework for writing tests by default and the instructions here assume that is the case for your Espresso tests.</p>
            <p>Add the following dependency in your build.gradle file:</p>
            <pre className='docsCode'>
                dependencies {'{'}<br/>
                    &nbsp;&nbsp;...<br/>
                    &nbsp;&nbsp;implementation 'com.tesults:tesults:1.0.2'<br/>
                    &nbsp;&nbsp;...<br/>
                {'}'}
            </pre>
        </div>

        const configuration = 
        <div>
            <h4 className="mt-5">TesultsListener</h4>
            <p>Create a new Java file called TesultsListener.java in your test package. Copy and paste the code below into the new file you have created. To <b>upload files</b> uncomment the files section and provide paths to where your generated files are mapped for each test case.</p>
            <p>This listener extends the JUnit4 RunListener to listen to test events as your tests run. This way results data can be generated and then uploaded. Only two methods need to be implemented: <b>testFinished</b> and <b>testRunFinished</b> finished.</p>
            
            <pre className="docsCode">
                import org.junit.runner.Description;<br/>
                import org.junit.runner.Result;<br/>
                import org.junit.runner.notification.Failure;<br/>
                import org.junit.runner.notification.RunListener;<br/><br/>

                import java.util.ArrayList;<br/>
                import java.util.HashMap;<br/>
                import java.util.List;<br/>
                import java.util.Map;<br/><br/>

                import com.tesults.tesults.*;<br/><br/>

                public class TesultsListener extends RunListener {'{'}<br/>
                &nbsp;&nbsp;Map&lt;String, Integer&gt; testCaseIndices = new HashMap&lt;String, Integer&gt;();<br/>
                &nbsp;&nbsp;List&lt;Map&lt;String,Object&gt;&gt; testCases = new ArrayList&lt;Map&lt;String, Object&gt;&gt;();<br/>
                &nbsp;&nbsp;Map&lt;Integer, Long&gt; times = new HashMap&lt;Integer, Long&gt;();<br/><br/>

                &nbsp;&nbsp;@Override<br/>
                &nbsp;&nbsp;public void testStarted(Description description) throws Exception {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;times.put(description.hashCode(), System.currentTimeMillis());<br/>
                &nbsp;&nbsp;{'}'}<br/><br/>

                &nbsp;&nbsp;@Override<br/>
                &nbsp;&nbsp;public void testFinished(Description description) throws Exception {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;super.testFinished(description);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; testCase = new HashMap&lt;String, Object&gt;();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("name", description.getMethodName());<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("desc", description.getMethodName());<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("suite", description.getClassName());<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("result", "pass");<br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("start", times.get(description.hashCode()));<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("end", System.currentTimeMillis());<br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;{'//'} (Optional) For uploading files:<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'//'}List&lt;String&gt; files = new ArrayList&lt;String&gt;();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'//'}files.add("/Users/admin/Desktop/img1.png");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'//'}files.add("/Users/admin/Desktop/img2.png");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'//'}testCase.put("files", files);<br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;testCases.add(testCase);<br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;testCaseIndices.put(description.getDisplayName(), testCases.size() - 1);<br/>
                &nbsp;&nbsp;{'}'}<br/><br/>

                &nbsp;&nbsp;@Override<br/>
                &nbsp;&nbsp;public void testRunFinished(Result result) throws Exception {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;super.testRunFinished(result);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;List&lt;Failure&gt; failures = result.getFailures();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;for(Failure failure : failures){'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;int index = testCaseIndices.get(failure.getDescription().getDisplayName());<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; testCase = testCases.get(index);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.put("result", "fail");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.put("reason", failure.getMessage());<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;{'//'} Map&lt;String, Object&gt; to hold your test results data.<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; data = new HashMap&lt;String, Object&gt;();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;data.put("target", "token");<br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; results = new HashMap&lt;String, Object&gt;();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;results.put("cases", testCases);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;data.put("results", results);<br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;{'//'} Upload<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Tesults results upload...");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; response = Results.upload(data);<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("success: " + response.get("success"));<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("message: " + response.get("message"));<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("warnings: " + ((List&lt;String&gt;) response.get("warnings")).size());<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("errors: " + ((List&lt;String&gt;) response.get("errors")).size());<br/>

                &nbsp;&nbsp;{'}'}<br/>
                }
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
            <p className="mt-3">The upload method returns a response of type Map&lt;String, Object&gt; that you can use to check whether the upload was a success.</p>
            <p>Value for key "success" is a Boolean: true if results successfully uploaded, false otherwise.</p>
            <p>Value for key "message" is a String: if success is false, check message to see why upload failed.</p>
            <p>Value for key "warnings" is a List&lt;String&gt;, if size is not zero there may be issues with file uploads.</p>
            <p>Value for key "errors" is a List&lt;String&gt;, if "success" is true then this will be empty.</p>
            <h4>Register TesultsListener</h4>
            <p>Create a new AndroidManifest.xml at the root of your Espresso tests folder. The name of the file must be AndroidManifest.xml. This file will then show up under the manifests folder in the IDE. If it does not, please ensure you have created this file at the root of your Espresso tests folder (at the same level as the Java folder). Please note, this is not the same AndroidManifest.xml that Android Studio generates for your application, this will be a new AndroidManifest.xml for your tests package.</p>
            <pre className='docsCode'>
            &lt;?xml version="1.0" encoding="utf-8"?&gt;<br/>
            &lt;manifest xmlns:android="http://schemas.android.com/apk/res/android"<br/>
            &nbsp;&nbsp;xmlns:tools="http://schemas.android.com/tools"<br/>
            &nbsp;&nbsp;package="com.example.myapplication"&gt;<br/><br/>

            &nbsp;&nbsp;&lt;instrumentation<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;android:name="androidx.test.runner.AndroidJUnitRunner"<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;android:targetPackage="com.example.myapplication"<br/>
            &nbsp;&nbsp;&gt;<br/>
            &nbsp;&nbsp;&lt;meta-data<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;android:name="listener"<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;android:value="com.example.myapplication.TesultsListener" /&gt;<br/>
            &nbsp;&nbsp;&lt;/instrumentation&gt;<br/>
            &lt;/manifest&gt;
            </pre>

            <p>Finally ensure internet permissions are set in your application's AndroidManifest.xml.  Without this, Tesults will not be able to be reached and you will see an error about internet permissions under Logcat in Android Studio.</p>
            <pre className="docsCode">
                &lt;uses-permission android:name="android.permission.INTERNET" /&gt;<br/>
                &lt;uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" /&gt;
            </pre>

            <p>Now run your tests and results will be uploaded to Tesults to the target that maps to the token supplied.</p>
        </div>

        const build = 
        <div>
            You can submit build information by adding a build case to the TesultsListener. This is a test case with the suite set to the value '[build]'.
            <p>Example</p>
            <pre className='docsCode'>
                Map&lt;String, Object&gt; buildCase = new HashMap&lt;String, Object&gt;();<br/>
                buildCase.put("name", "1.0.0");<br/>
                buildCase.put("desc", "build description");<br/>
                buildCase.put("suite", "[build]");<br/>
                buildCase.put("result", "pass");<br/><br/>
                <code className="accentc6">{'//'} Add to the same array test cases are added to in TesultsListener.java</code><br/>
            </pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                build={build}
                integration="espresso"
            />
        );
    }
}

export default DocsEspresso