/*global */
import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import Request from './Request';
import TabularData from './TabularData';
import Confirmation from './Confirmation';
import Context from './Context';
import Cache from './Cache';

class AuditLogs extends Component {
    static contextType = Context;

    constructor () {
        super()
        this.state = {
            auditLogs: [], 
            esk: undefined, 
            confirmation: {success: undefined, failure: undefined},
            userDetails: {}
        }
        this.auditLogs = this.auditLogs.bind(this)
        this.userDetails = this.userDetails.bind(this)
    }

    componentDidMount () {
        this.auditLogs()
    }

    auditLogs () {
        let projects = this.context.projects;
        if (projects !== undefined) {
            let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
            if (projectIndex === undefined) {
                projectIndex = 0
            }
            let project = undefined;
            if (projectIndex < projects.length) {
                project = projects[projectIndex];
                if (project.plan.name !== "free-v1") {
                    Request.get("/audit-logs", {id: project.id, esk: this.state.esk}, (err, data) => {
                        if (err) {
                            this.setState({confirmation: {success: undefined, failure: "Error fetching data"}});
                        } else {
                            let auditLogs = this.state.auditLogs
                            if (auditLogs === undefined) {
                                auditLogs = []
                            }
                            data.auditLogs.forEach((auditLog) => {
                                auditLogs.push(auditLog)
                            })
                            let userDetails = this.state.userDetails
                            Object.keys(data.userDetails).forEach((userId) => {
                                userDetails[userId] = data.userDetails[userId]
                            })
                            this.setState({
                                auditLogs: auditLogs,
                                userDetails: userDetails,
                                esk: data.lek
                            });
                        }
                    });   
                }
            }    
        }
    }

    userDetails (userId) {
        let details = this.state.userDetails[userId]
        if (details === undefined) {
            return userId
        } else {
            return details.firstName + " " + details.lastName + " (" + details.email  + ")"
        }
    }

    render () {
        let projects = this.context.projects;
        if (projects !== undefined) {
            let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
            if (projectIndex === undefined) {
                projectIndex = 0
            }
            let project = undefined;
            if (projectIndex < projects.length) {
                project = projects[projectIndex];
                if (project.plan.name === "free-v1") {
                    return (
                        <div>
                            <div className="font14 neutral4">
                                Upgrade your project to Standard or Plus to enable audit logs. Audit logs are unavailable on a free project.                        
                            </div>
                            <div>
                                <button type="button" className='btn-cancel mt-4 mb-5' onClick={this.props.cancel}>Back</button>
                            </div>
                        </div>
                    )
                }
            }    
        }
        
        let data = {
            headers: [
                {value: "Time", minWidth: "200px"},
                {value: "User", minWidth: "200px"},
                {value: "Event", minWidth: "100px"},
                {value: "Message"}
            ],
            rows: []
        }
        for (let i = 0; i < this.state.auditLogs.length; i++) {
            let auditLog = this.state.auditLogs[i]
            data.rows.push(
                {
                    cols: [
                        {value: <TimeFormatted dt={auditLog.created} includeYear={true}/>},
                        {value: this.userDetails(auditLog.user)},
                        {value: auditLog.event},
                        {value: auditLog.message}
                    ]
                }
            );
        }
        let loadMore = <span></span>
        if (this.state.esk !== undefined) {
            loadMore = <button className="btn-cancel mt-3 mb-3" onClick={this.auditLogs}>Load more</button>
        }
        return (
            <div>
                <button type="button" className='btn-cancel mb-3' onClick={this.props.cancel}>Back</button>
                <TabularData data={data}/>
                {loadMore}
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        )
    }
};

export default AuditLogs;