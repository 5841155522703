/*global Cookies*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Analytics from './Analytics';
import ListsMain from './ListsMain';
import ListsSide from './ListsSide';
import ManualCaseEdit from './ManualCaseEdit';
import Cache from './Cache';
import Context from './Context';
import Request from './Request';
import ResultsHeader5 from './ResultsHeader5';
import ResultsHeader11 from './ResultsHeader11';
import Unauthenticated from './Unauthenticated';
import Header from './Header';
const papa = require('papaparse');

class Lists extends Component {
    static contextType = Context;

    constructor() {
        super();
        // Start check lsp - checks lists url includes lists specific path (lsp)
        let lspId = undefined;
        let lspCreated = undefined;
        let lspCase = undefined;
        let lsp = window.location.pathname.split("/");
        lsp.reverse(); // reversed so that in order pop can occur
        lsp.pop(); // pops ""
        lsp.pop(); // pops "lists"
        if (lsp.pop() === "lsp") {
            lspId = lsp.pop();
            lspCreated = lsp.pop();
            lspCase = lsp.pop();
        }
        // End check lsp
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
        this.state = {
            state: "groupTree", 
            sort: "suite", 
            projects: [], 
            projectIndex: projectIndex, 
            lists: [], 
            listIndex: 0, 
            esk: undefined, 
            listCases: [], 
            importListCases:[], 
            importListIndex: 0, 
            importCases: [], 
            targets: [], 
            targetIndex: 0, 
            run: undefined, 
            editCase: null, 
            listEdit: false,
            label: "", 
            group: undefined, 
            loading: false, 
            error: "", 
            loggedIn: false, 
            role: 1, 
            lspId: lspId, 
            lspCreated: lspCreated, 
            lspCase: lspCase,
            yOffset: 0,
            multiselect: undefined,
            multiEdit: false,
            saving: false,
            confirmation: {success: undefined, failure: undefined},
            mobileListActions: false,
            multiselectClearRequest: 0,
            collapseAll: false,
            updatedCaseHash: undefined
        };
        this.scrollToYOffset = this.scrollToYOffset.bind(this);
        this.projectChange = this.projectChange.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.getLists = this.getLists.bind(this);
        this.getTargets = this.getTargets.bind(this);
        this.loadMoreLists = this.loadMoreLists.bind(this);
        this.getListCases = this.getListCases.bind(this);
        this.getImportListCases = this.getImportListCases.bind(this);
        this.listChange = this.listChange.bind(this);
        this.createList = this.createList.bind(this);
        this.createListConfirm = this.createListConfirm.bind(this);
        this.selectList = this.selectList.bind(this);
        this.cancel = this.cancel.bind(this);
        this.changeLabel = this.changeLabel.bind(this);
        this.groupSelected = this.groupSelected.bind(this);
        this.addCase = this.addCase.bind(this);
        this.addCaseToSuiteFunc = this.addCaseToSuiteFunc.bind(this);
        this.importTarget = this.importTarget.bind(this);
        this.importCsv = this.importCsv.bind(this);
        this.importCsvCases = this.importCsvCases.bind(this);
        this.importCsvConfirm = this.importCsvConfirm.bind(this);
        this.importConfirm = this.importConfirm.bind(this);
        this.importList = this.importList.bind(this);
        this.importListChange = this.importListChange.bind(this);
        this.importListConfirm = this.importListConfirm.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.saveCase = this.saveCase.bind(this);
        this.saveCases = this.saveCases.bind(this);
        this.editCase = this.editCase.bind(this);
        this.deleteCase = this.deleteCase.bind(this);
        this.duplicateCase = this.duplicateCase.bind(this);
        this.updateCase = this.updateCase.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.deleteListCancel = this.deleteListCancel.bind(this);
        this.deleteList = this.deleteList.bind(this);
        this.sortChange = this.sortChange.bind(this);
        this.duplicateList = this.duplicateList.bind(this);
        this.duplicateListConfirm = this.duplicateListConfirm.bind(this);
        this.generateTestCases = this.generateTestCases.bind(this);
        this.generatedTestCasesSave = this.generatedTestCasesSave.bind(this);
        this.deleteListSuite = this.deleteListSuite.bind(this);
        this.listEdit = this.listEdit.bind(this);
        this.getProjectRole = this.getProjectRole.bind(this);
        this.listEditNameAndGroup = this.listEditNameAndGroup.bind(this);
        this.listEditNameAndGroupConfirm = this.listEditNameAndGroupConfirm.bind(this);
        this.createdListGroup = this.createdListGroup.bind(this);
        this.deletedListGroup = this.deletedListGroup.bind(this);
        this.backToTreeList = this.backToTreeList.bind(this);
        this.allListsUpdated = this.allListsUpdated.bind(this);
        this.multiEdit = this.multiEdit.bind(this);
        this.multiselectUpdate = this.multiselectUpdate.bind(this);
        this.multiEditConfirm = this.multiEditConfirm.bind(this);
        this.multiCaseUpdate = this.multiCaseUpdate.bind(this);
        this.action = this.action.bind(this);
        this.stateControl = this.stateControl.bind(this);
        this.lspIdAndCreated = this.lspIdAndCreated.bind(this);
        this.setGroupProxy = this.setGroupProxy.bind(this);
        this.setEditSuite = this.setEditSuite.bind(this);
        this.caseShow = this.caseShow.bind(this);
        this.caseHide = this.caseHide.bind(this);
        this.toggleMobileListActions = this.toggleMobileListActions.bind(this);
        this.toggleCollapseAll = this.toggleCollapseAll.bind(this)
        this.searchChange = this.searchChange.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0);
        const user = Cookies.getJSON("truser");
        if (user === undefined || user === null) {
            this.setState({loggedIn: false});
        } else {
            this.setState({loggedIn: true}, () => this.getProjects());
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.state === "view") {
            this.scrollToYOffset();
        }
        this.stateControl();
    }

    caseShow () {
        // TODO - update from Bootstrap
        /*
        $('#manualCaseModal').modal('show')
        $('#manualCaseModal').on('hidden.bs.modal', () => this.caseHide());
        */
    }

    caseHide() {
        let path = window.location.pathname;
        if (path.endsWith("/edit-case") || path.endsWith("/add") || path.endsWith("/add-to-suite")) {
            this.setState({manualCase: null}/*, () => this.props.history.goBack()*/);
        } else {
            this.setState({manualCase: null});
        }
    }

    lspIdAndCreated () {
        // Checks if list url includes list specific path (lsp)
        let lspId = undefined;
        let lspCreated = undefined;
        //let lspCase = undefined;
        let lsp = window.location.pathname.split("/");
        lsp.reverse(); // reversed so that in order pop can occur
        lsp.pop(); // pops ""
        lsp.pop(); // pops "lists"
        if (lsp.pop() === "lsp") {
            lspId = lsp.pop();
            lspCreated = lsp.pop();
            //lspCase = lsp.pop();
        }
        if (lspId !== undefined && lspCreated !== undefined) {
            return {id: lspId, created: parseInt(lspCreated, 10)};
        } else {
            return undefined;
        }
    }

    action (path) {
        if (path.endsWith("/add")) {
           return true;
        }
        if (path.endsWith("/create-list")) {
            return true;
        }
        if (path.endsWith("/edit-case")) {
            return true;
        }
        if (path.endsWith("/edit-list-name")) {
            return true;
        }
        if (path.endsWith("/import-cases-from-target")) {
            return true;
        }
        if (path.endsWith("/import-cases-from-csv")) {
            return true;
        }
        if (path.endsWith("/import-cases-from-list")) {
            return true;
        }
        if (path.endsWith("/duplicate-list")) {
            return true;
        }
        if (path.endsWith("/generate-test-cases")) {
            return true;
        }
        if (path.endsWith("/add-to-suite")) {
            return true;
        }
        return false;
    }

    stateControl () {
        let path = window.location.pathname;
        if (path === "/lists" && this.state.state !== "groupTree") {
            this.setState({state: "groupTree", search: undefined});
        } else if (path.startsWith("/lists/lsp") && path.endsWith("/add") && this.state.state !== "edit") {
            let editCase = this.state.editCase;
            if (editCase !== undefined && editCase !== null) {
                editCase.suite = undefined;
                this.setState({editCase: editCase}, () => this.addCase());
            } else {
                this.addCase();
            }
        } else if (path.startsWith("/lists/lsp") && path.endsWith("/add-to-suite") && this.state.state !== "edit") {
            this.addCase();
        } else if (path.endsWith("/lists/create-list") && this.state.state !== "createList") {
            this.createList();
        } else if (path.endsWith("/edit-case") && this.state.state !== "edit") {
            this.setState({state: "edit"});
        } else if (path.endsWith("/edit-list-name") && this.state.state !== "listNameAndGroupEdit") {
            this.listEditNameAndGroup();
        } else if (path.endsWith("/import-cases-from-target") && this.state.state !== "importTarget") {
            this.setState({state: "importTarget"});
            this.importTarget();
        } else if (path.endsWith("/import-cases-from-csv") && this.state.state !== "importCsv") {
            this.setState({state: "importCsv"});
            this.importCsv();
        } else if (path.endsWith("/import-cases-from-list") && this.state.state !== "importList") {
            this.setState({state: "importList"});
            this.importList();
        } else if (path.endsWith("/duplicate-list") && this.state.state !== "duplicateList") {
            this.duplicateList();
        } else if (path.endsWith("/generate-test-cases") && this.state.state !== "generateTestCases") {
            this.generateTestCases();
        }  else if (path.startsWith("/lists/lsp") && this.state.state !== "view" && this.action(path) === false) {
            let lspObj = this.lspIdAndCreated();
            let index = 0;
            for (let i = 0; i < this.state.lists.length; i++) {
                let list = this.state.lists[i];
                if (list.id === lspObj.id && parseInt(list.created,10) === lspObj.created) {
                    index = i;
                }
            }
            this.setState({state: "view", search: undefined, listIndex: index}, () => {
                this.props.sideOverlay(undefined);
                this.getListCases()
            });
        }
    }

    scrollToYOffset () {
        if (this.state.yOffset !== 0) {
            setTimeout(function () {
                window.scrollTo({top: this.state.yOffset, left: 0, behavior: 'auto'});
                this.setState({yOffset: 0});
            }.bind(this),100);
        }
    }

    projectChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.projectIndex, index);
        this.setState({ projectIndex: index, state: "view", lists: [], esk: undefined, listIndex: 0, listCases: []}, () => this.getLists());
    }

    getProjectRole(project) {
        this.setState({loading: true});
        Request.get("/projectRole", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({role: 1, usage: undefined, loading: false});
            } else {
                this.setState({role: data.role, usage: undefined, loading: false}, () => this.getLists());
            }
        });
    }

    getProjects () {
        this.setState({loading: true, lists: [], listIndex: 0, esk: undefined});
        Cache.request(this.context, Cache.data.projects, {}, (err, projects) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                if (this.state.lspId !== undefined) {
                    for (let i = 0; i < projects.length; i++) {
                        let p = projects[i];
                        if (p.id === this.state.lspId) {
                            projectIndex = i;
                        }
                    }
                }
                if (projectIndex >= projects.length) {
                    projectIndex = 0;
                }
                this.setState({projects: projects, projectIndex: projectIndex, loading: false});
                if (projects.length > 0) {
                    if (projectIndex < projects.length) {
                        if (projects[projectIndex].status === "active") {
                            this.getProjectRole(projects[projectIndex]);
                        }
                    }
                }
            }
        });
    }

    listChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.listIndex, index);
        this.setState({listIndex: index, state: "view", listCases: []}, () => this.getListCases());
    }

    importListChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.importListIndex, index);
        this.setState({importListIndex: index, importListCases: []}, () => this.getImportListCases());
    }

    getLists () {
        if (this.state.role < 2) {
            return;
        }
        this.setState({loading: true});
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        if (project === undefined) {
            return;
        }
        let id = project.id;
        let esk = this.state.esk;
        Request.get("/getAllLists", {id: id, esk: esk}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                //let lists = this.state.lists; // when changed from /getLists
                let lists = [];
                data.lists.forEach(function (list) {
                    lists.push(list);
                });

                // Sort alphabetically
                lists.sort(function (l1, l2) {
                    if(l1.label < l2.label) { 
                        return -1; 
                    }
                    if(l1.label > l2.label) { 
                        return 1; 
                    }
                    return 0;
                });

                let state = "groupTree";
                let listIndex = this.state.listIndex;
                if (this.state.lspCreated !== undefined) {
                    for (let i = 0; i < lists.length; i++) {
                        let l = lists[i];
                        if (l.created === this.state.lspCreated) {
                            listIndex = i;
                            state = "view";
                        }
                    }
                }

                //this.setState({lists: lists, esk: data.esk, loading: false}, () => this.getListCases()); // when changed from /getLists
                this.setState({state: state, search: undefined, lists: lists, listIndex: listIndex, esk: undefined, loading: false}, () => this.getListCases());
            }
        });
    }

    loadMoreLists () {
        this.getLists();
    }

    getImportListCases () {
        if (this.state.importListIndex >= this.state.lists.length) {
            return;
        }
        let list = this.state.lists[this.state.importListIndex];
        if (list !== undefined) {
            this.setState({loading: true});
            Request.get("/listCases", {id: list.id, created: list.created}, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    let listCases = [];
                    data.cases.forEach(function (c) {
                        listCases.push(c);
                    });
                    this.setState({importListCases: listCases, loading: false});
                }
            });
        }
    }

    getListCases () {
        if (this.state.listIndex >= this.state.lists.length) {
            return;
        }
        let list = this.state.lists[this.state.listIndex];
        if (list !== undefined) {
            Request.get("/listCases", {id: list.id, created: list.created}, (err, data) => {
                if (err) {

                } else {
                    let listCases = [];
                    data.cases.forEach(function (c) {
                        listCases.push(c);
                    });
                    this.setState({listCases: listCases});
                }
            });
        }
    }

    getTargets (project) {
        window.scrollTo(0,0);
        this.setState({loading: true});
        Cache.request(this.context, Cache.data.targets, {id: project.id}, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({targets: targets, targetIndex: 0, loading: false}, 
                () => this.getCasesForLatestRun());
            }
        });
    }

    getCasesForLatestRun () {
        if (this.state.targetIndex >= this.state.targets.length) {
            return;
        }
        let target = this.state.targets[this.state.targetIndex];
        this.setState({loading: true});
        Request.get("/runlatest", {id: target.id, created: target.created}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let run = data.run;
                if (run === undefined) {
                    this.setState({importCases: [], loading: false});
                } else {
                    Request.get("/cases", {id: run.id, created: run.created, version: run.version, keyPrefix: run.keyPrefix}, (err, data) => {
                        if (err) {
                            this.setState({loading: false});
                        } else {
                            let cases = data.cases;
                            if (cases === undefined) {
                                this.setState({importCases: [], loading: false, run: run});
                            } else {
                                this.setState({importCases: data.cases, loading: false, run: run});
                            }
                        }
                    });
                }
            }
        });
    }
    
    targetChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.targetIndex, index);
        this.setState({ targetIndex: index, importCases: []}, () => this.getCasesForLatestRun());
    }

    selectList(index) {
        this.setState({state: "view", listIndex: index});
    }

    createList () {
        window.scrollTo(0,0);
        Analytics.event("CreateList");
        this.setState({state: "createList"});
    }

    changeLabel (e) {
        this.setState({label: e.target.value});
    }

    setEditSuite (suite) {
        this.setState({editSuite: suite});
    }

    groupSelected (index) {
        index = parseInt(index, 10);
        if (this.state.groupSelectPaths !== undefined) {
            if (index === 0) {
                this.setState({group: undefined});
            } else {
                if (index < this.state.groupSelectPaths.length) {
                    let path = this.state.groupSelectPaths[index];
                    this.setState({group: path});
                }
            }
        }
    }

    addCase () {
        const yOffset = window.pageYOffset;
        window.scrollTo(0,0);
        Analytics.event("ListAddCase");
        let suite = undefined;
        let editCase = this.state.editCase;
        if (editCase !== undefined && editCase !== null) {
            suite = editCase.suite;
        }
        this.setState({state: "edit", yOffset: yOffset, editCase: {name: "", suite: suite, result: "pass", files: [], newFilesData: [], removeFiles:[]}});
    }

    addCaseToSuiteFunc (suite) {
        const yOffset = window.pageYOffset;
        window.scrollTo(0,0);
        Analytics.event("ListAddCaseToSuite");
        this.setState({yOffset: yOffset, editCase: {name: "", suite: suite, result: "pass", files: [], newFilesData: [], removeFiles:[]}}, () => {this.props.history.push(window.location.pathname + "/add-to-suite");});
    }

    editCase (c) {
        const yOffset = window.pageYOffset;
        Analytics.event("ListEditCase");
        let list = this.state.lists[this.state.listIndex];
        this.setState({editCase: c, yOffset: yOffset}, () => this.props.history.push("/lists/lsp/" + list.id + "/" + list.created + "/edit-case"));
    }

    importTarget () {
        window.scrollTo(0,0);
        this.setState({state: "importTarget"});
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        this.getTargets(project, "importTarget");
    }

    importCsv () {
        window.scrollTo(0,0);
        Analytics.event("ImportCsv");
        this.setState({state: "importCsv", importCases: [], loading: false});
    }

    importCsvCases(cases) {
        window.scrollTo(0,0);
        this.setState({importCases: cases});
    }

    importCsvConfirm () {
        window.scrollTo(0,0);
        Analytics.event("ImportCsvConfirm");
        let importCases = this.state.importCases;
        importCases.forEach(function (c) {
            delete c.files;
        });
        this.saveCases(importCases);
    }

    importConfirm() {
        window.scrollTo(0,0);
        Analytics.event("ImportConfirm");
        let importCases = this.state.importCases;
        importCases.forEach(function (c) {
            delete c.files;
        });
        this.saveCases(importCases);
    }

    importList () {
        window.scrollTo(0,0);

        let index = 0;
        let selectedIndex = -1;
        this.state.lists.forEach(function (list) {
            if (list.groupProxy !== true) {
                if (selectedIndex === -1) {
                    selectedIndex = index;
                }
            }
            index += 1;
        });
        if (selectedIndex === -1) {
            selectedIndex = 0;
        }

        this.setState({state: "importList", importListIndex: selectedIndex}, () => this.getImportListCases());
    }

    importListConfirm () {
        window.scrollTo(0,0);
        Analytics.event("ImportListConfirm");
        this.saveCases(this.state.importListCases);
    }

    exportToCsv () {
        //this.state.label - name
        //this.state.listCases - cases
        window.scrollTo(0,0);
        Analytics.event("ExportListCsv");
        const csvString = papa.unparse(this.state.listCases);
        let list = this.state.lists[this.state.listIndex];
        const file = new Blob([csvString], {type: 'text/csv'});
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = list.label + ".csv";
        element.click();
    }

    duplicateList() {
        window.scrollTo(0,0);
        Analytics.event("DuplicateList");
        this.setState({state: "duplicateList"});
    }

    generateTestCases () {
        window.scrollTo(0,0);
        Analytics.event("GenerateTestCases");
        this.setState({state: "generateTestCases"});
    }

    sortChange (value) {
        this.setState({sort: value});
    }

    deleteCase (caseData) {
        let list = this.state.lists[this.state.listIndex];
        let listUrl = "/lists/lsp/" + list.id + "/" + list.created;
        this.props.history.push(listUrl + '/rev0'); // necessary for refresh

        Request.post("/listCaseDelete", {id: list.id, created: list.created, caseData: caseData}, (err, data) => {
            if (err) {
                this.setState({state: "view", error: "Unable to delete case."});
            } else {
                Analytics.event("ListCaseDeleted");
                let listCases = this.state.listCases;
                let removeIndex = -1;
                for (let i = 0; i < listCases.length; i++) {
                    let c = listCases[i];
                    if (c.num === caseData.num) {
                        removeIndex = i;
                    }
                }

                if (removeIndex !== -1) {
                    listCases.splice(removeIndex, 1);
                }
                this.setState({state: "view", error: "", listCases: listCases}, () => { this.props.history.push(listUrl); this.props.sideOverlay(undefined);});
            }
        });
    }

    duplicateCase (caseData) {
        caseData.name = caseData.name + " (Duplicate)";
        this.saveCases([caseData]);
    }

    updateCase (caseData) {
        let list = this.state.lists[this.state.listIndex];
        let listUrl = "/lists/lsp/" + list.id + "/" + list.created;

        this.props.history.push(listUrl + '/rev0'); // necessary for refresh

        this.setState({confirmation: {success: "Saving changes", failure: undefined}, saving: true});
        Request.post("/listCaseUpdate", {id: list.id, created: list.created, caseUpdate: caseData}, (err, data) => {
            if (err) {
                this.setState({state: "view", error: "Unable to update case.", saving: false, confirmation: {success: undefined, failure: "Unable to update case"}, loading: false});
            } else {
                Analytics.event("ListCaseUpdated");
                /*let listCases = this.state.listCases;
                for (let i = 0; i < listCases.length; i++) {
                    let c = listCases[i];
                    if (c.num === caseData.num) {
                        listCases[i] = caseData;
                    }
                }*/
                this.caseHide();
                this.setState({state: "view", error: "", listCases: data.cases, saving: false, confirmation: {success: undefined, failure: undefined}}, () => { this.props.history.push(listUrl); this.props.sideOverlay(undefined);});
            }
        });
    }

    multiCaseUpdate (data) {
        Analytics.event("ListMultiCaseUpdate");
        let list = this.state.lists[this.state.listIndex];
        Request.post("/listMultiCaseUpdate", {id: list.id, created: list.created, caseNums: data.caseNums, field: data.field, value: data.value}, (err, data) => {
            if (err) {
                this.setState({state: "view", error: "Unable to update cases."});
            } else {
                Analytics.event("ListMultiCaseUpdated");
                this.setState({state: "view", error: "", listCases: data.cases, multiselectClearRequest: this.state.multiselectClearRequest + 1});
            }
        });
    }

    saveCase (caseData) {
        let path = window.location.pathname;
        if (path.endsWith("/edit-case")) {
            this.updateCase(caseData);
            this.props.displayNotification("Test case " + caseData.name + " updated");
        } else {
            this.saveCases([caseData]);
        }
    }

    saveCases (caseData) {
        let list = this.state.lists[this.state.listIndex];
        let listUrl = "/lists/lsp/" + list.id + "/" + list.created;

        this.props.history.push(listUrl + '/rev0'); // necessary for refresh
        
        this.setState({closeCase: false, updatedCaseHash: undefined, confirmation: {success: "Saving changes", failure: undefined}, saving: true});
        
        Request.post("/listCasesAdd", {id: list.id, created: list.created, cases: caseData}, (err, data) => {
            if (err) {
                if (err.error === undefined) {
                    this.setState({state: "view", saving: false, confirmation: {success: undefined, failure: err}, error: err, closeCase: false});
                } else {
                    this.setState({state: "view", saving: false, confirmation: {success: undefined, failure: err.error.message}, error: err.error.message, closeCase: false});
                }
                
            } else {
                Analytics.event("ListCaseSaved");
                this.caseHide();
                this.setState({state: "view", error: "", updatedCaseHash: data.updatedCaseHash, saving: false, confirmation: {success: undefined, failure: undefined}, importCases: [], listCases: data.cases, closeCase: true}, () => { this.props.history.push(listUrl); this.props.sideOverlay(undefined);} );
                if (caseData.length === 1) {
                    this.props.displayNotification("Test case " + caseData[0].name + " added");
                } else {
                    this.props.displayNotification(caseData.length + " test cases added");
                }
            }
        });
    }

    backToTreeList () {
        this.props.history.push('/lists')
        this.setState({state: "groupTree", lspCase: undefined}, () => this.getLists());
    }

    cancel () {
        this.setState({state: "view", lspCase: undefined});
    }
    
    createListConfirm (label) {
        window.scrollTo(0,0);
        this.setState({loading: true});
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let group = this.state.group;
        let id = project.id;
        this.props.overlay(undefined)
        Request.post("/listCreate", {id: id, label: label, group: group}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create new test list. " + err, loading: false});
            } else {
                Analytics.event("ListCreated");
                let lists = this.state.lists;
                lists.push(data.list);
                let url = "/lists/lsp/" + data.list.id + "/" + data.list.created;
                this.setState({state: "view", error: undefined, loading: false, lists: lists, listIndex: lists.length - 1, listCases: []}, () => this.props.history.push(url));
            }
        });
    }

    createdListGroup (list) {
        // a new list group has been created
        let lists = this.state.lists;
        lists.unshift(list);
        this.setState({lists: lists});
    }

    deletedListGroup (groupProxy) {
        // a new list group has been created
        let lists = this.state.lists;
        let removeIndex = -1;
        for (let i = 0; i < lists.length; i++) {
            let list = lists[i];
            if (list.id === groupProxy.id && list.created === groupProxy.created) {
                removeIndex = i;
            }
        }
        if (removeIndex > -1) {
            lists.splice(removeIndex, 1);
        }
        this.setState({lists: lists});
    }

    setGroupProxy (groupProxy) {
        if (groupProxy === undefined) {
            this.setState({group: undefined});
        } else {
            this.setState({group: groupProxy.group});
        }
    }

    duplicateListConfirm () {
        window.scrollTo(0,0);
        this.setState({loading: true});
        let label = this.state.label;
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let created = this.state.lists[this.state.listIndex].created;
        let group = this.state.lists[this.state.listIndex].group;
        let id = project.id;
        Request.post("/listDuplicate", {id: id, label: label, created: created, group: group}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create new duplicate test list.", loading: false});
            } else {
                Analytics.event("ListDuplicated");
                let lists = this.state.lists;
                lists.push(data.list);
                this.setState({state: "view", error: undefined, loading: false, lists: lists, listIndex: lists.length - 1, listCases: []}, this.props.history.push("/lists/lsp/" + data.list.id + "/" + data.list.created));
            }
        });
    }

    generatedTestCasesSave (caseData) {
        window.scrollTo(0,0);
        this.saveCases(caseData);
    }

    deleteListCancel () {
        this.setState({deleteListConfirm: false});
    }

    deleteList () {
        if (this.state.deleteListConfirm !== true) {
            this.setState({deleteListConfirm: true});
            return;
        }

        if (this.state.listIndex >= this.state.lists.length) {
            return;
        }

        window.scrollTo(0,0);
        let list = this.state.lists[this.state.listIndex];
        this.setState({loading: true});
        Request.post("/listDelete", {id: list.id, created: list.created}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to delete test list.", loading: false, deleteListConfirm: false});
            } else {
                Analytics.event("ListDeleted");
                let lists = this.state.lists;
                lists.splice(this.state.listIndex, 1);
                this.setState({state: "groupTree", error: undefined, loading: false, lists: lists, listIndex: 0, listCases: [], deleteListConfirm: false}, () => this.props.history.push("/lists"));
            }
        });
    }

    listEdit () {
        let edit = this.state.listEdit;
        if (edit === true) {
            this.setState({listEdit: false});
        } else {
            this.setState({listEdit: true});
        }
    }

    listEditNameAndGroup () {
        if (this.state.listIndex >= this.state.lists.length) {
            return;
        }
        
        let paths = [];
        paths.push(["--- No group ---"]);
        let pathsDict = {};
        const pathsDictKey = function (group) {
            let key = '';
            group.forEach(function (g) {
                key += g + "-";
            });
            return key;
        }

        if (this.state.lists !== undefined) {
            this.state.lists.forEach(function (list) {
                if (list.group !== undefined) {
                    let key = pathsDictKey(list.group);
                    if (pathsDict[key] === undefined) {
                        paths.push(list.group);
                        pathsDict[key] = list.group.length;
                    }
                }
            });
        }

        let list = this.state.lists[this.state.listIndex];
        window.scrollTo(0,0);
        Analytics.event("ListNameAndGroupEdit");
        this.setState({state: "listNameAndGroupEdit", groupSelectPaths: paths, label: list.label, group: list.group === undefined ? [] : list.group});
    }

    listEditNameAndGroupConfirm () {
        window.scrollTo(0,0);
        this.setState({loading: true});
        let label = this.state.label;
        let group = this.state.group;
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let created = this.state.lists[this.state.listIndex].created;
        let id = project.id;
        Request.post("/listUpdate", {id: id, created: created, label: label, group: group}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to update test list.", loading: false});
            } else {
                Analytics.event("ListUpdated");
                let updatedList = data.list;
                let lists = this.state.lists;
                let listUrl = "/lists/lsp";
                let found = false
                for (let i = 0; i < lists.length; i++) {
                    let list = lists[i];
                    if (list.id === updatedList.id && list.created === updatedList.created) {
                        lists[i] = updatedList;
                        listUrl += "/" + list.id + "/" + list.created;
                        found = true
                    }
                }
                if (found !== true) {
                    listUrl += "/" + updatedList.id + "/" + updatedList.created
                }
                this.setState({state: "view", error: undefined, loading: false, lists: lists}, () => this.props.history.push(listUrl));
            }
        });
    }

    deleteListSuite (suite) {
        let list = this.state.lists[this.state.listIndex];
        this.setState({loading: true});
        Request.post("/listSuiteDelete", {id: list.id, created: list.created, suite: suite}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to delete test list suite.", loading: false});
            } else {
                Analytics.event("DeletedListSuite");
                this.setState({state: "view", error: undefined, loading: false, listCases: data.cases});    
            }
        });
    }

    allListsUpdated (lists) {
        this.setState({lists: lists});
    }

    multiEdit (enable) {
        this.setState({multiEdit: enable});
    }

    multiselectUpdate (multiselect) {
        this.setState({multiselect: multiselect});
    }

    multiEditConfirm (field, value) {
        if (field === undefined || value === undefined) {
            return;
        }
        Analytics.event("ListMultiEditConfirm" + field.charAt(0).toUpperCase() + field.substring(1));
        let data = {caseNums:[], field: field, value: value};
        let multiselect = this.state.multiselect;
        if (multiselect === undefined) {
            return;
        }
        Object.keys(multiselect).forEach(function (key) {
            if (multiselect[parseInt(key, 10)] === true) {
                data.caseNums.push(parseInt(key, 10));
            }
        });
        
        if (data.caseNums.length > 0) {
            this.multiCaseUpdate(data);
        }
    }

    toggleMobileListActions () {
        this.setState({mobileListActions: this.state.mobileListActions === true ? false: true});
    }

    toggleCollapseAll () {
        this.setState({collapseAll: this.state.collapseAll ? false : true})
    }

    searchChange (value) {
        this.setState({search: value});
    }

    render () {
        //let listCase = <span></span>
        if (this.state.loggedIn !== true) {
            return <Unauthenticated id={this.state.lspId} history={this.props.history}/>
        }
        const path = window.location.pathname;
        if (this.state.state === "edit" && (path.endsWith("/add") || path.endsWith("/add-to-suite") || path.endsWith("/edit-case"))) {
            const list = this.state.lists[this.state.listIndex];
            const listUrl = "/lists/lsp/" + list.id + "/" + list.created;
            this.props.sideOverlay(
            <ManualCaseEdit 
                type="list" 
                overlay={this.props.overlay}
                sideOverlay={this.props.sideOverlay}
                messageOverlay={this.props.messageOverlay}
                lists={this.state.lists} 
                listIndex={this.state.listIndex} 
                data={this.state.editCase}
                saveCase={this.saveCase} 
                cancel={this.cancel} 
                cases={this.state.listCases}
                yOffset={this.state.yOffset} 
                caseShow={this.caseShow}
                confirmation={this.state.confirmation}
                saving={this.state.saving}
                history={this.props.history}
                cancelUrl={listUrl}
            />)
        }
        const title = undefined;
        const side = <ListsSide 
                        state={this.state.state} 
                        loading={this.state.loading}
                        projects={this.context.projects} 
                        projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                        projectChange={this.projectChange}
                        lists={this.state.lists}
                        listIndex={this.state.listIndex}
                        listChange={this.listChange}
                        listCases={this.state.listCases}
                        createList={this.createList}
                        createListConfirm={this.createListConfirm}
                        cancel={this.cancel}
                        error={this.state.error}
                        addCase={this.addCase}
                        importTarget={this.importTarget}
                        importCsv={this.importCsv}
                        importCsvCases={this.importCsvCases}
                        importCsvConfirm={this.importCsvConfirm}
                        importConfirm={this.importConfirm}
                        exportToCsv={this.exportToCsv}
                        editCaseFunc={this.editCase}
                        saveCase={this.saveCase}
                        editCase={this.editCase}
                        updateCase={this.updateCase}
                        deleteCase={this.deleteCase}
                        duplicateCase={this.duplicateCase}
                        editCaseDetail={this.state.editCase}
                        importCases={this.state.importCases}
                        targets={this.state.targets}
                        targetIndex={this.state.targetIndex}
                        run={this.state.run}
                        targetChange={this.targetChange}
                        changeLabel={this.changeLabel}
                        groupSelected={this.groupSelected}
                        esk={this.state.esk}
                        loadMoreLists={this.loadMoreLists}
                        deleteListCancel={this.deleteListCancel}
                        deleteList={this.deleteList}
                        sort={this.state.sort}
                        onSortChange={this.sortChange}
                        duplicateList={this.duplicateList}
                        duplicateListConfirm={this.duplicateListConfirm}
                        generateTestCases={this.generateTestCases}
                        generatedTestCasesSave={this.generatedTestCasesSave}
                        deleteListSuite={this.deleteListSuite}
                        listEditFunc={this.listEdit}
                        listEdit={this.state.listEdit}
                        loggedIn={this.state.loggedIn}
                        role={this.state.role}
                        deleteListConfirm={this.state.deleteListConfirm}
                        listEditNameAndGroup={this.listEditNameAndGroup}
                        listEditNameAndGroupConfirm={this.listEditNameAndGroupConfirm}
                        createdListGroup={this.createdListGroup}
                        deletedListGroup={this.deletedListGroup}
                        label={this.state.label}
                        group={this.state.group}
                        backToTreeList={this.backToTreeList}
                        groupSelectPaths={this.state.groupSelectPaths}
                        allListsUpdated={this.allListsUpdated}
                        importList={this.importList}
                        importListConfirm={this.importListConfirm}
                        getImportListCases={this.getImportListCases}
                        importListCases={this.state.importListCases}
                        importListIndex={this.state.importListIndex}
                        importListChange={this.importListChange}
                        multiEdit={this.state.multiEdit}
                        multiEditFunc={this.multiEdit}
                        multiEditConfirm={this.multiEditConfirm}
                        multiselectUpdate={this.multiselectUpdate}
                        setGroupProxy={this.setGroupProxy}
                        setEditSuite={this.setEditSuite}
                        overlay={this.props.overlay}
                        />

        const main = <ListsMain
                        history={this.props.history}
                        updatedCaseHash={this.state.updatedCaseHash}
                        state={this.state.state} 
                        loading={this.state.loading}
                        projects={this.context.projects} 
                        projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                        projectChange={this.projectChange}
                        lists={this.state.lists}
                        listIndex={this.state.listIndex}
                        lspCaseNum={this.state.lspCase}
                        listChange={this.listChange}
                        listCases={this.state.listCases}
                        createList={this.createList}
                        createListConfirm={this.createListConfirm}
                        cancel={this.cancel}
                        error={this.state.error}
                        addCase={this.addCase}
                        importTarget={this.importTarget}
                        importCsv={this.importCsv}
                        importCsvCases={this.importCsvCases}
                        importCsvConfirm={this.importCsvConfirm}
                        importConfirm={this.importConfirm}
                        exportToCsv={this.exportToCsv}
                        editCase={this.state.editCase}
                        saveCase={this.saveCase}
                        addCaseToSuiteFunc={this.addCaseToSuiteFunc}
                        editCaseFunc={this.editCase}
                        updateCase={this.updateCase}
                        deleteCase={this.deleteCase}
                        duplicateCase={this.duplicateCase}
                        editCaseDetail={this.state.editCase}
                        importCases={this.state.importCases}
                        targets={this.state.targets}
                        targetIndex={this.state.targetIndex}
                        run={this.state.run}
                        targetChange={this.targetChange}
                        changeLabel={this.changeLabel}
                        groupSelected={this.groupSelected}
                        esk={this.state.esk}
                        loadMoreLists={this.loadMoreLists}
                        deleteListCancel={this.deleteListCancel}
                        deleteList={this.deleteList}
                        sort={this.state.sort}
                        onSortChange={this.sortChange}
                        duplicateList={this.duplicateList}
                        duplicateListConfirm={this.duplicateListConfirm}
                        generateTestCases={this.generateTestCases}
                        generatedTestCasesSave={this.generatedTestCasesSave}
                        deleteListSuite={this.deleteListSuite}
                        listEditFunc={this.listEdit}
                        listEdit={this.state.listEdit}
                        loggedIn={this.state.loggedIn}
                        role={this.state.role}
                        deleteListConfirm={this.state.deleteListConfirm}
                        listEditNameAndGroup={this.listEditNameAndGroup}
                        listEditNameAndGroupConfirm={this.listEditNameAndGroupConfirm}
                        createdListGroup={this.createdListGroup}
                        deletedListGroup={this.deletedListGroup}
                        label={this.state.label}
                        group={this.state.group}
                        backToTreeList={this.backToTreeList}
                        groupSelectPaths={this.state.groupSelectPaths}
                        allListsUpdated={this.allListsUpdated}
                        importList={this.importList}
                        importListConfirm={this.importListConfirm}
                        getImportListCases={this.getImportListCases}
                        importListCases={this.state.importListCases}
                        importListIndex={this.state.importListIndex}
                        importListChange={this.importListChange}
                        multiEdit={this.state.multiEdit}
                        multiselectClearRequest={this.state.multiselectClearRequest}
                        multiEditFunc={this.multiEdit}
                        multiEditConfirm={this.multiEditConfirm}
                        multiselectUpdate={this.multiselectUpdate}
                        setGroupProxy={this.setGroupProxy}
                        setEditSuite={this.setEditSuite}
                        sideContent={side}
                        overlay={this.props.overlay}
                        messageOverlay={this.props.messageOverlay} 
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        sideOpen={this.props.sideOpen}
                        collapseAll={this.state.collapseAll}
                        onSearchChange={this.searchChange}
                        search={this.state.search}
                        />
        

        let pageTitle = "Tesults - Lists";
        if (this.state.state !== "groupTree") {
            if (this.state.lists !== undefined && this.state.listIndex !== undefined) {
                if (this.state.listIndex < this.state.lists.length) {
                    let list = this.state.lists[this.state.listIndex];
                    pageTitle = "Tesults - " + list.label;
                }
            }
        }

        let mobileListActions = <span></span>
        if (this.state.mobileListActions === true) {
            mobileListActions = side;
        }
        const mobileListActionsButton = <button onClick={this.toggleMobileListActions} className="mt-3 btn-confirm">{this.state.mobileListActions === true ? "Hide list actions" : "Show list actions"}</button>

        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content="Learn how to use Tesults Lists for effective test case management."/>
                </Helmet>
                {
                    this.state.state === "view"  ?
                    <Header
                        type="header-double"
                        header1={
                            <ResultsHeader5
                                state={this.state.state}
                                lists={this.state.lists}
                                listIndex={this.state.listIndex}
                                listCases={this.state.listCases}
                            />
                        }
                        header2={
                            <ResultsHeader11
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                sortChange={this.sortChange}
                                collapseAll={this.state.collapseAll}
                                toggleCollapseAll={this.toggleCollapseAll}
                                onSearchChange={this.searchChange}
                                cases={this.state.listCases}
                            />
                        }
                    />
                    :
                    <Header
                        type="header-single"
                        header1={
                            <ResultsHeader5
                                state={this.state.state}
                                lists={this.state.lists}
                                listIndex={this.state.listIndex}
                                listCases={this.state.listCases}
                            />
                        }
                    />
                }
                
                <div className="app-main-margin">
                    <div className="desktop-hide">
                        <div className="list-actions-padding">
                            {mobileListActions}    
                            {mobileListActionsButton}
                        </div>
                    </div>
                    {main}
                </div>
                {/*listCase*/}
            </div>
        );
    }
};

export default Lists;