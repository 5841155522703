/*global */
import React, { Component } from 'react';

import Constants from './Constants';

class Result extends Component {
    render () {
        let result = "unknown"
        if (this.props.result) {
            result = this.props.result.toLowerCase()
        }

        const pass = () => {
            if (this.props.map && this.props.rawResult) {
                if (this.props.map[this.props.rawResult] === "pass") {
                    return true;
                } else {
                    if (this.props.runMetadata) {
                        if (this.props.runMetadata.integrationName === "playwright-tesults-reporter" && Constants.isVersionGreaterThanOrEqualTo(this.props.runMetadata.integrationVersion, "1.2.0")) {
                            if (this.props.case["_Expected Status"] !== "passed") {
                                if (this.props.case["_Expected Status"] === this.props.rawResult) {
                                    return true;
                                }   
                            }
                        }
                    }
                }
                return false;
            } else {
                return result === "pass" ? true : false
            }
        }

        const fail = () => {
            if (this.props.map && this.props.rawResult) {
                return this.props.map[this.props.rawResult] === "fail" ? true : false
            } else {
                return result === "fail" ? true : false
            }
        }

        let label = "Unknown"
        let background = "accentb7bg"
        let color = "accentb4"
        let icon = "/img/tesults-logo-unknown.svg"

        if (pass()) {
            label = "Pass"
            background = "accentc7bg"
            color = "accentc4"
            icon = "/img/tesults-logo-pass.svg"
        } else {
            if (fail()) {
                label = "Fail"
                background = "accenta7bg"
                color = "accenta4"
                icon = "/img/tesults-logo-fail.svg"
            }
        }
        
        if (this.props.map && this.props.rawResult) {
            label = this.props.rawResult
        }
        
        return (
            <div style={{"display":"flex", "alignItems": "center", "maxWidth": "100px", "height":"24px", "borderRadius":"4px"}} className={color + ' ' + background}>
                <div style={{"paddingLeft":"6px"}} className="mr-2">
                    <img style={{"marginTop":"5px"}} src={icon} width="16" height="16"/>
                </div>
                <div style={{"paddingRight":"6px"}} className='font13 overflow-ellipsis-no-wrap'>
                    {label}
                </div>
            </div>
        );
    }
}

export default Result;