/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import SetupAssistant from './SetupAssistant';
import DocsProxy from './DocsProxy';
import DocsBuildConsolidation from './DocsBuildConsolidation';
import DocsDynamic from './DocsDynamic';
import DocsTestFramework from './DocsTestFramework';

class DocsJUnit4 extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>JUnit 4 test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle JUnit 4 test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>If you are not using JUnit 4, view the <NavLink to="/docs/java" className="tr-link-primary4 no-break no-break" target="_blank">Java</NavLink> docs for information about integrating with a lower level library.</p>
            <p>We recommend you run your JUnit 4 tests using <code>junit-vintage-engine</code> in JUnit 5 as outlined here: <a className="site-link-primary2" href="https://junit.org/junit5/docs/current/user-guide/#migrating-from-junit4" target="_blank" rel="noopener noreferrer">https://junit.org/junit5/docs/current/user-guide/#migrating-from-junit4</a>.</p>
            <p>This is a great way to run your existing JUnit 4 tests while moving towards JUnit 5, because you do not need to make changes to your tests. Simply add the junit-vintage-engine as a dependency, examples here for Gradle and Maven, but for full details visit the link above.</p>
            <span className="neutral4 font14">Gradle: build.gradle</span>
            <br/>
            <pre className="docsCode">
            dependencies {'{'}<br/>
            &nbsp;&nbsp;testImplementation("junit:junit:4.13")<br/>
            &nbsp;&nbsp;testRuntimeOnly("org.junit.vintage:junit-vintage-engine:5.6.2")<br/>
            {'}'}
            </pre>
            <br/>
            <span className="neutral4 font14">Maven: pom.xml</span>
            <br/>
            <pre className="docsCode">
            {'<dependency>'}<br/>
            &nbsp;&nbsp;{'<groupId>org.junit.vintage</groupId>'}<br/>
            &nbsp;&nbsp;{'<artifactId>junit-vintage-engine</artifactId>'}<br/>
            &nbsp;&nbsp;{'<version>5.6.2</version>'}<br/>
            &nbsp;&nbsp;{'<scope>test</scope>'}<br/>
            {'</dependency>'}
            </pre>
            <p>You can then make use of the Tesults JUnit 5 extension to push test results data to Tesults. The only caveat is that the extension will work best with JUnit 4 tests if your tests are in a package, which is easy to add if they are not already, this avoids an issue with how the junit-vintage-engine reporting test suite names.</p>
            <p>Once you are running your JUnit 4 tests using the JUnit 5 provided junit-vintage-engine <b>continue following the instructions for setting up Tesults with JUnit 5</b>: <a className="site-link-primary2" href="https://www.tesults.com/docs/junit5" target="_blank" rel="noopener noreferrer">https://www.tesults.com/docs/junit5</a>.</p>
            <p>Alternatively, if you do not want to run your JUnit 4 tests using the junit-vintage-engine as suggested above then to integrate with Tesults you need to setup a listener and register it within your JUnit 4 project. <a className="site-link-primary2" data-toggle="collapse" href="#junit4code" aria-expanded="false" aria-controls="junit4code">View instructions for this alternative integration method.</a></p>
            <div className="collapse" id="junit4code">
                <hr/>
                <h4 className="mt-5">JUnit 4 Listener</h4>
                <p>As outlined above, we do not recommend this method but it may be useful in specific situations.</p>
                <h4 className="mt-5">Installation</h4>
                <p>The Tesults Java API Library is available from the JCenter and Maven Central repositories or as a JAR you can download from here.</p>
                <h4 className="mt-3">Gradle</h4>
                <p>If you are using Gradle add this dependency snippet to your build.gradle file:</p>
                <pre className="docsCode">
                dependencies {'{'}<br/>
                &nbsp;&nbsp;compile 'com.tesults:tesults:1.0.2'<br/>
                {'}'}<br/>
                </pre>
                <p>Also ensure you have the JCenter or Maven Central repository referenced in your build.gradle file.</p>
                <h4 className="mt-3">Maven</h4>
                <p>If you are using Maven add this dependency snippet to your pom.xml file:</p>
                <pre className="docsCode">
                {'<'}dependency{'>'}<br/>
                    &nbsp;&nbsp;{'<'}groupId{'>'}com.tesults{'<'}/groupId{'>'}<br/>
                    &nbsp;&nbsp;{'<'}artifactId{'>'}tesults{'<'}/artifactId{'>'}<br/>
                    &nbsp;&nbsp;{'<'}version{'>'}1.0.2{'<'}/version{'>'}<br/>
                {'<'}/dependency{'>'}<br/>
                </pre>
                <h4 className="mt-3">JAR</h4>
                <p>Alternatively a JAR is available for download directly here: <a href="https://www.tesults.com/tesults.jar">tesults.jar</a> (compatible with Java 7+)</p>
                <h4 className="mt-5">Configuration</h4>
                <p>Make the tesults.jar available as a library and then import the com.tesults.tesults package in your code:</p>
                <pre className="docsCode">
                    import com.tesults.tesults.*;
                </pre>
                <h4 className="mt-5">TesultsListener</h4>
                <p>Tesults recommends extending the JUnit4 RunListener to listen to test events as your tests run. This way results data can be generated and then uploaded. Only two methods need to be implemented: <b>testFinished</b> and <b>testRunFinished</b> finished.</p>
                <p>Tesults provides the <b>TesultsListener</b> below, ready to be copied and pasted for use with your system. Use as is or edit. To <b>upload files</b> uncomment the files section and provide paths to where your generated files are mapped for each test case. If you require help with this contact help@tesults.com and we will write this for you.</p>
                
                <pre className="docsCode">
                    import com.sun.org.apache.xpath.internal.operations.Bool;<br/>
                    import org.junit.runner.Description;<br/>
                    import org.junit.runner.Result;<br/>
                    import org.junit.runner.notification.Failure;<br/>
                    import org.junit.runner.notification.RunListener;<br/><br/>

                    import java.util.ArrayList;<br/>
                    import java.util.HashMap;<br/>
                    import java.util.List;<br/>
                    import java.util.Map;<br/><br/>

                    import com.tesults.tesults.*;<br/><br/>

                    public class TesultsListener extends RunListener {'{'}<br/>
                    &nbsp;&nbsp;Map&lt;String, Integer&gt; testCaseIndices = new HashMap&lt;String, Integer&gt;();<br/>
                    &nbsp;&nbsp;List&lt;Map&lt;String,Object&gt;&gt; testCases = new ArrayList&lt;Map&lt;String, Object&gt;&gt;();<br/><br/>

                    &nbsp;&nbsp;@Override<br/>
                    &nbsp;&nbsp;public void testFinished(Description description) throws Exception {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;super.testFinished(description);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; testCase = new HashMap&lt;String, Object&gt;();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("name", description.getMethodName());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("desc", description.getMethodName());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("suite", description.getClassName());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;testCase.put("result", "pass");<br/><br/>

                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'} (Optional) For uploading files:<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'}List&lt;String&gt; files = new ArrayList&lt;String&gt;();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'}files.add("/Users/admin/Desktop/img1.png");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'}files.add("/Users/admin/Desktop/img2.png");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'}testCase.put("files", files);<br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;testCases.add(testCase);<br/><br/>

                    &nbsp;&nbsp;&nbsp;&nbsp;testCaseIndices.put(description.getDisplayName(), testCases.size() - 1);<br/>
                    &nbsp;&nbsp;{'}'}<br/><br/>

                    &nbsp;&nbsp;@Override<br/>
                    &nbsp;&nbsp;public void testRunFinished(Result result) throws Exception {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;super.testRunFinished(result);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;List&lt;Failure&gt; failures = result.getFailures();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;for(Failure failure : failures){'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;int index = testCaseIndices.get(failure.getDescription().getDisplayName());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; testCase = testCases.get(index);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.put("result", "fail");<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.put("reason", failure.getMessage());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/><br/>

                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'} Map&lt;String, Object&gt; to hold your test results data.<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; data = new HashMap&lt;String, Object&gt;();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;data.put("target", "token");<br/><br/>

                    &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; results = new HashMap&lt;String, Object&gt;();<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;results.put("cases", testCases);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;data.put("results", results);<br/><br/>

                    &nbsp;&nbsp;&nbsp;&nbsp;{'//'} Upload<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;Map&lt;String, Object&gt; response = Results.upload(data);<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("success: " + response.get("success"));<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("message: " + response.get("message"));<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("warnings: " + ((List&lt;String&gt;) response.get("warnings")).size());<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("errors: " + ((List&lt;String&gt;) response.get("errors")).size());<br/>

                    &nbsp;&nbsp;{'}'}<br/>
                    }
                </pre>
                <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
                <p className="mt-3">The upload method returns a response of type Map&lt;String, Object&gt; that you can use to check whether the upload was a success.</p>
                <p>Value for key "success" is a Boolean: true if results successfully uploaded, false otherwise.</p>
                <p>Value for key "message" is a String: if success is false, check message to see why upload failed.</p>
                <p>Value for key "warnings" is a List&lt;String&gt;, if size is not zero there may be issues with file uploads.</p>
                <p>Value for key "errors" is a List&lt;String&gt;, if "success" is true then this will be empty.</p>
                <br/>
                <p>In order to use a RunListener you must register it with the test runner. If you are not already using a test runner, it's easy to add one:</p>
                <pre className="docsCode">
                public static void main(String [ ] args) {'{'}<br/>
                &nbsp;&nbsp;JUnitCore core= new JUnitCore();<br/>
                &nbsp;&nbsp;core.addListener(new TesultsListener());<br/>
                &nbsp;&nbsp;core.run(TestSuiteA.class);<br/>
                {'}'}
                </pre>
                <p>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</p>
                <hr/>
                <DocsBuildConsolidation/>
                <DocsDynamic/>
                <DocsProxy/>
                <SetupAssistant/>
            </div>
        </div>


        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                integration="junit4"
            />
        );
    }
}

export default DocsJUnit4;