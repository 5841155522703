/*global*/
import React, { Component } from 'react';

class ResultsHeader3 extends Component {
    render () {
        window.scrollTo(0, 0);
        let targetCount = 0;
        if (this.props.targets !== undefined) {
            targetCount = this.props.targets.length;
            if (this.props.group !== undefined) {
                if (this.props.group !== "All") {
                    let count = 0
                    for (let i = 0; i < this.props.targets.length; i++) {
                        let target = this.props.targets[i]
                        if (target.group !== undefined) {
                            if (Array.isArray(target.group)) {
                                for (let j = 0; j < target.group.length; j++) {
                                    let g = target.group[j]
                                    if (g === this.props.group) {
                                        count += 1
                                    }
                                }
                            }
                        }
                    }
                    targetCount = count
                }
            }
        }
        return (
            <div className="results-header3">
                <div className="results-header3-title">
                    <span className="header-title">Dashboard</span>
                </div>
                <div className="results-header3-targets">
                    <div className="target-pass-rate">
                        <div className="target-pass-rate-left">
                            <div className="target-pass-percent target-pass-percent-blue" style={{"lineHeight":"34px"}}>
                                {targetCount}
                            </div>
                        </div>
                        <div className="target-pass-rate-right">
                            <div className="target-pass-cases" style={{"lineHeight":"34px"}}>
                                Targets
                            </div>
                        </div>
                    </div>
                </div>
                <div className="results-header3-gap">
                    
                </div>
                <div className="results-header3-actions">
                    <div style={{"display": "flex"}}>
                        <div style={{"flex": "1"}}>
                            {/*<img src="/img/share.svg" width="19" height="19" alt=""/>*/}
                            {/*<button className='btn-transparent' onClick={this.props.diffView}>Diff</button>*/}
                        </div>
                        <div style={{"flex": "1"}}>
                            <button className="select" onClick={() => window.location.reload()}>
                                <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                            </button>
                        </div>
                        
                        <div style={{"flex": "1"}}>
                            {/*<img src="/img/ellipsis.svg" width="19" height="19" alt=""/>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader3;