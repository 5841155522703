/*global*/
import React, { Component } from 'react';

class SelectOption extends Component {
    constructor () {
        super()
        this.ref = React.createRef()
    }

    componentDidUpdate () {
        if (this.ref.current !== undefined && this.ref.current !== null) {
            if (this.props.selected === true) {
                this.ref.current.scrollIntoView();    
            }
        }
    }

    render() {
        let className = ""
        if (this.props.selected === true) {
            className = "neutral8bg"
        }
        return (
            <div ref={this.ref} className={className}>
                {this.props.content}
            </div>
        )
    }
}

export default SelectOption