/*global */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class ConfigPreferencesMenu extends Component {
    render () {
        return (
            <div>
                <h3 className='mb-5'>Preferences</h3>
                <h4>Reporting</h4>
                <NavLink className="tr-link-primary4" to="/config/project/preferences-reporting-case-display-attributes">Test case display attributes</NavLink>
            </div>
        )
    }
}

export default ConfigPreferencesMenu