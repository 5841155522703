/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';
import Notice from './Notice';

class DocsNUnit3 extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>NUnit test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle NUnit test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <p>If you are not using NUnit but are using C# for your tests view the <NavLink to="/docs/csharp" target="_blank" className="site-link-primary2">C# documentation</NavLink>.</p>

        const installation = 
        <div>
            <p>The package is hosted in NuGet Gallery. Run this command in Package Manager Console.</p>
            <pre className="docsCode">
                Install-Package Tesults.NUnit
            </pre>
            <p>Alternatively install Tesults.NUnit using the GUI in Visual Studio.</p>
        </div>

        const configuration = 
        <div>
            <details>
                <summary className='underline_dotted'><span><b>.NET (.NET Core) Instructions</b></span></summary>
                <div>
                <br/>
                <p>Additional configuration is required for NUnit to recognize the Tesults NUnit extension. A file with the extension .addins must be created, the name doesn't matter, we call it tesults.addins here. This file contains just one line: 'Tesults.NUnit.dll'. This references the Tesults NUnit extension made available following the NuGet package installation.</p>
                <span className="neutral4">tesults.addins</span>
                <br/>
                <pre className='docsCode pre-wrap-break-word'>
                Tesults.NUnit.dll
                </pre>
                <br/>
                <p>Next create a file with .runsettings extension. Again the name does not matter and we call it test.runsettings here. Include your Tesults target token in this file:</p>
                <span className="neutral4">test.runsettings</span>
                <br/>
                <pre className='docsCode pre-wrap-break-word'>
                    &lt;?xml version="1.0" encoding="utf-8"?&gt;<br/>
                    &lt;RunSettings&gt;<br/>
                    &lt;TestRunParameters&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-target" value="token" /&gt;<br/>
                    &lt;/TestRunParameters&gt;<br/>
                    &lt;/RunSettings&gt;<br/>
                </pre>
                <br/>
                <p>Ensure tesults.addins, test.runsettings, Tesults.NUnit.dll (the package installed from NuGet) and the .dll containing your tests are all in the same directory (generally your build output directory). We suggest you add tesults.addins and test.runsettings to your Visual Studio project and set these files to be copied to your build output directory automatically. Now you can run your tests and NUnit will recognize the Tesults listener and upload test results data to Tesults:</p>
                <pre className="docsCode pre-wrap-break-word">
                    dotnet test &lt;Tests-containing&gt;.dll -s test.runsettings
                </pre>
                <Box
                    bg="neutral8bg neutral1 mb-3"
                    title={<h4>Basic configuration complete</h4>}
                    content={
                        <div>
                            <p>At this point the Tesults.NUnit extension will push results to Tesults. <b>The Tesults.NUnit extension requires the tesults-target arg be supplied</b> in the test.runsettings file to indicate which target to use otherwise the extension is effectively disabled and you will see this message in the console output 'Tesults disabled. No tesults-target param supplied.'</p>
                            <p>Example:</p>
                            <pre className="docsCode">
                            dotnet test &lt;Tests-containing&gt;.dll -s test.runsettings
                            </pre>
                            <p>The 'token' in test.runsettings should be replaced with your Tesults target token. Tesults target tokens can be copied on project creation, new target creation or can be regenerated from the configuration menu.</p>
                        </div>
                    }
                />
                <p>You can use multiple test.runsettings files to report results to different Tesults targets if required.</p>
                </div>
            </details>
            <br/>
            <details>
                <summary className='underline_dotted'><span><b>.NET Framework Instructions</b></span></summary>
                <div>
                <p>Install the NUnit.ConsoleRunner NuGet package (along with NUnit and Tesults.NUnit packages).</p>
                <pre className="docsCode">
                    Install-Package NUnit.ConsoleRunner
                </pre>
                <p>Additional configuration is required for NUnit to recognize the Tesults NUnit extension. A file with the extension .addins must be created, the name doesn't matter, you can call it tesults.addins. This file must be added to the NUnit.ConsoleRunner package. Open your test project in Explorer and navigate to packages > NUnit.ConsoleRunner > tools. This directory contains the nunit3-console.dll and others. This is where the tesults.addins file must be added. The contents of the file must provide the path to Tesults.NUnit.dll, where the path is relative to the addins file, in most cases the contents would be:</p>
                <h4 className="neutral4">tesults.addins</h4>
                <pre className="docsCode">
                    ../../Tesults.NUnit.1.1.0/lib/net472/Tesults.NUnit.dll
                </pre>
                <p>If you are experiencing difficulties with this step, please contact us and we will help guide you. Unfortunately this is a necessary step for registering extensions with NUnit. Please also note that this extension is intended for use with the NUnit.ConsoleRunner, and not the Visual Studio NUnit3TestAdapter, because the test adapter does not recognize extensions. You will need to run your tests with the NUNIT3-CONSOLE command if you want result to upload to Tesults, whether locally or within your build and continuous integration system.</p>
                
                <pre className="primary2 docsArgsCode pl-0">tesults-target<span className="neutral7 ml-5">Required</span></pre>
                <p>A target token must be provided to upload to Tesults, if this arg is not provided Tesults.NUnit does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
                <p><b>Inline method</b></p>
                <pre className="docsCode pre-wrap-break-word">NUNIT3-CONSOLE ./bin/Release/tests.dll --tp tesults-target=eyJ0eXAiOiJ</pre>
                <p>In this case, the target token is supplied directly or inline in the commandline args. This is the simplest approach.</p>
                <p><b>Key method</b></p>
                <pre className="docsCode pre-wrap">NUNIT3-CONSOLE ./bin/Release/tests.dll --tp tesults-target=target1</pre>
                <p>In this case, Tesults.NUnit will automatically look up the value of the token based on a key value from a configuration file. The config file should be a typical .config file for C# and the path must be passed in using the tesults-config arg. See below for details.</p>
                <hr/>
                <pre className="primary2 docsArgsCode pl-0">tesults-config<span className="neutral7 ml-5">Optional (.NET Framework)</span></pre>
                <p>Example</p>
                <pre className="docsCode pre-wrap-break-word">NUNIT3-CONSOLE [inputfiles] [options] --tp tesults-config=C:\path-to-config</pre>
                <p>Example config file:</p>
                <pre className="docsCode pre-wrap">
                &lt;?xml version="1.0" encoding="utf-8" ?&gt;<br/>
                &lt;configuration&gt;<br/>
                &lt;appSettings&gt;<br/>
                &nbsp;&nbsp;&lt;add key="target1" value="eyJ0eXAiOiJKV1..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="target2" value="eyJ0eXAiOiJKV2..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="tesults-files" value="C:\path-to\files"/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="tesults-build-name" value="1.0.0"/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="tesults-build-desc" value="build description here"/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="tesults-build-result" value="pass"/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="tesults-build-reason" value="reason here"/&gt;<br/>
                &lt;/appSettings&gt;<br/>
                &lt;/configuration&gt;
                </pre>
                <p>Or something more descriptive about the targets:</p>
                <pre className="docsCode pre-wrap">
                &lt;?xml version="1.0" encoding="utf-8" ?&gt;<br/>
                &lt;configuration&gt;<br/>
                &lt;appSettings&gt;<br/>
                &nbsp;&nbsp;&lt;add key="web-qa-env" value="eyJ0eXAiOiJKV1..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="web-staging-env" value="eyJ0eXAiOiJKV12..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="web-prod-env" value="eyJ0eXAiOiJKV13..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="ios-qa-env" value="eyJ0eXAiOiJKV14..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="ios-staging-env" value="eyJ0eXAiOiJKV15..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="ios-prod-env" value="eyJ0eXAiOiJKV16..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="android-qa-env" value="eyJ0eXAiOiJKV17..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="android-staging-env" value="eyJ0eXAiOiJKV18..."/&gt;<br/>
                &nbsp;&nbsp;&lt;add key="android-prod-env" value="eyJ0eXAiOiJKV19..."/&gt;<br/>
                &lt;/appSettings&gt;<br/>
                &lt;/configuration&gt;
                </pre>
                <p>The config file can contain values not just for target look up but for all of the args below too. If the same arg is provided in both the command and in a config file the command arg takes priority.</p>
                <Box
                    bg="neutral8bg neutral1 mb-3"
                    title={<h4>Basic configuration complete</h4>}
                    content={
                        <div>
                            <p>At this point the Tesults.NUnit extension will push results to Tesults when you run the NUNIT3-CONSOLE command. <b>The Tesults.NUnit extension requires the tesults-target arg be supplied to indicate which target to use</b> otherwise the extension is effectively disabled and you will see this message in the console output 'Tesults disabled. No tesults-target param supplied.' See above for complete details.</p>
                            <p>Example:</p>
                            <pre className="docsCode">
                            NUNIT3-CONSOLE ./bin/Release/tests.dll --tp tesults-target=token --tp tesults-config=C:\path-to-config
                            </pre>
                            <p>The 'token' should be replaced with your Tesults target token. Tesults target tokens can be copied on project creation, new target creation or can be regenerated from the configuration menu.</p>
                        </div>
                    }
                />
                </div>
            </details>
        </div>

        const files = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-files<span className="neutral7 ml-5">Optional</span></pre>          
            <p>Provide the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <details>
                <summary className='underline_dotted'><span><b>.NET (.NET Core) Instructions</b></span></summary>
                <div>
                <br/>
                <pre className="docsCode pre-wrap-break-word">
                    dotnet test &lt;Tests-containing&gt;.dll -s test.runsettings
                </pre>
                <p>Where the test.runsettings file includes the tesults-files parameter:</p>
                <span className="neutral4">test.runsettings</span>
                <br/>
                <pre className='docsCode pre-wrap-break-word'>
                    &lt;?xml version="1.0" encoding="utf-8"?&gt;<br/>
                    &lt;RunSettings&gt;<br/>
                    &lt;TestRunParameters&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-target" value="token" /&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-files" value="C:\path-to-files\temporary" /&gt;<br/>
                    &lt;/TestRunParameters&gt;<br/>
                    &lt;/RunSettings&gt;<br/>
                </pre>
                <br/>
                <p>Ensure tesults.addins is also present in the same directory as the test containing dll so that NUnit recognizes the Tesults listener. As before this file contains just one line:</p>
                <span className="neutral4">tesults.addins</span>
                <br/>
                <pre className='docsCode pre-wrap-break-word'>
                Tesults.NUnit.dll
                </pre>
                <br/>
                </div>
            </details>
            <br/>
            <details>
                <summary className='underline_dotted'><span><b>.NET Framework Instructions</b></span></summary>
                <div>
                    <br/>
                    <pre className="docsCode pre-wrap-break-word">NUNIT3-CONSOLE [inputfiles] [options] --tp tesults-files=C:\path-to-files\temporary</pre>
                </div>
            </details>
            <p>This is one area where Tesults.NUnit is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Within this temporary directory create subdirectories matching the test case name.</p>
            <p>The suite name matches the [TestFixture] and test case name matches the [Test] method name.</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-desc<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <hr/>
            <details>
                <summary className='underline_dotted'><span><b>.NET (.NET Core) Instructions</b></span></summary>
                <div>
                <br/>
                <pre className="docsCode pre-wrap-break-word">
                    dotnet test &lt;Tests-containing&gt;.dll -s test.runsettings
                </pre>
                <p>Where the test.runsettings file includes the build parameters:</p>
                <span className="neutral4">test.runsettings</span>
                <br/>
                <pre className='docsCode pre-wrap-break-word'>
                    &lt;?xml version="1.0" encoding="utf-8"?&gt;<br/>
                    &lt;RunSettings&gt;<br/>
                    &lt;TestRunParameters&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-target" value="token" /&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-build-name" value="1.0.0" /&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-build-desc" value="Build description" /&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-build-result" value="pass" /&gt;<br/>
                    &nbsp;&nbsp;&lt;Parameter name="tesults-build-reason" value="reason here if build has failed" /&gt;<br/>
                    &lt;/TestRunParameters&gt;<br/>
                    &lt;/RunSettings&gt;<br/>
                </pre>
                <br/>
                <p>Ensure tesults.addins is also present in the same directory as the test containing dll so that NUnit recognizes the Tesults listener. As before this file contains just one line:</p>
                <span className="neutral4">tesults.addins</span>
                <br/>
                <pre className='docsCode pre-wrap-break-word'>
                Tesults.NUnit.dll
                </pre>
                </div>
            </details>
            <br/>
            <details>
                <summary className='underline_dotted'><span><b>.NET Framework Instructions</b></span></summary>
                <div>
                <br/>
                    <pre className="docsCode pre-wrap-break-word">NUNIT3-CONSOLE [inputfiles] [options]--tp tesults-build-name=1.0.0 tesults-build-result=pass</pre>
                </div>
            </details>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                build={build}
                integration="nunit3"
            />
        );
    }
}

export default DocsNUnit3;