/*global*/
import React, { Component } from 'react';
import Request from './Request';
import Confirmation from './Confirmation';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class ErrorsAdmin extends Component {
    constructor () {
        super();
        this.state = {
            errors: [],
            collapse: false,
            confirmation: {success: undefined, failure: undefined}
        };
        this.collapse = this.collapse.bind(this);
        this.errors = this.errors.bind(this);
    }

    componentDidMount() {
        this.errors();
    }

    errors () {
        let params = undefined;
        if (this.state.cursor !== undefined) {
            params = {cursor: this.state.cursor};
        }
        Request.get('/errors-admin', params, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}});
            } else {
                const existingErrors = this.state.errors;
                const newErrors = data.errors;
                for (const e of newErrors) {
                    existingErrors.push(e);
                }
                this.setState({confirmation: {success: undefined, failure: undefined}, errors: existingErrors, cursor: data.cursor});
            }
        });
    }

    collapse () {
        this.setState({collapse: this.state.collapse ? false : true})
    }

    render () {
        let data = {
            headers: [
                {value: "Time", minWidth: "100px"},
                {value: "Results", minWidth: "400px"},
                {value: "Metadata", minWidth: "100px"}
            ],
            rows: []
        }
        
        for (const error of this.state.errors) {
            data.rows.push(
                {
                    cols: [
                        {value: <TimeFormatted dt={error.time}/>},
                        {value: error.data[0]},
                        {value: error.data[1]}
                    ]
                }
            );
        }

        return (
            <div className="mb-4 width100">
                <h4 className="neutral3">Errors</h4>
                <button className="select" onClick={this.collapse}>
                    {this.state.collapse === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                </button>
                {this.state.collapse ? <div>...</div> : <TabularData data={data}/>}
                {this.state.cursor === undefined ? <div></div> : <button className="btn-cancel" onClick={this.errors}>Load more errors</button>}
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ErrorsAdmin;