/*global*/
import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import AboutMain from './AboutMain'

class About extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - About</title>
                    <meta name="description" content="Learn about Tesults and the mission, vision and team."/>
                </Helmet>
                <div>
                    <AboutMain/>
                </div>
            </div>
        );
    }
};

export default About;