/*global Cookies*/
import React, { Component } from 'react';

class MenuItem extends Component {
    constructor () {
        super();
        this.state = {active: undefined, icon: undefined, label: undefined, action: undefined};
        this.setup = this.setup.bind(this);
    }

    componentDidMount () {
        this.setup();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.active !== prevProps.active) || this.props.selectedProject !== prevProps.selectedProject) {
            this.setup();
        }
    }

    setup () {
        //if (this.state.label !== undefined) {
            //return; // Already setup
        //}
        if (this.props.type === "dashboard") {
            let path = "/results/rsp/view/status";
            if (this.props.selectedProject !== undefined) {
                path += "/project/" + this.props.selectedProject.id;
            }
            if (this.props.trl) {
                path = "/" + this.props.trlOrg + "/" + this.props.trlProject + "/dashboard"
            }
            this.setState(
                {
                    active: this.props.active === "status" ? true : false,
                    icon: {
                        image: "/img/menu-dashboard.svg",
                        width: "20",
                        height: "35"
                    },
                    label: "Dashboard",
                    action: "results",
                    path: path
                }
            );
        } else if (this.props.type === "results") {
            let path = "/results/rsp/view/results"
            if (this.props.selectedProject !== undefined) {
                path += "/project/" + this.props.selectedProject.id;
            }
            if (this.props.trl) {
                path = "/" + this.props.trlOrg + "/" + this.props.trlProject + "/results"
            }
            this.setState(
                {
                    active: this.props.active === "results" ? true : false,
                    icon: {
                        image: "/img/menu-results.svg",
                        width: "12",
                        height: "20"
                    },
                    label: "Results",
                    action: "results",
                    path: path
                }
            );
        } else if (this.props.type === "supplemental") {
            let path = "/results/rsp/view/supplemental"
            if (this.props.selectedProject !== undefined) {
                path += "/project/" + this.props.selectedProject.id;
            }
            if (this.props.trl) {
                path = "/" + this.props.trlOrg + "/" + this.props.trlProject + "/supplemental"
            }
            this.setState(
                {
                    active: this.props.active === "supplemental" ? true : false,
                    icon: {
                        image: "/img/menu-supplemental.svg",
                        width: "17",
                        height: "21"
                    },
                    label: "Supplemental",
                    action: "results",
                    path: path
                }
            );
        } if (this.props.type === "diff") {
            let path = "/results/rsp/view/diff";
            if (this.props.selectedProject !== undefined) {
                path += "/project/" + this.props.selectedProject.id;
            }
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "diff" ? true : false,
                    icon: {
                        image: "/img/menu-diff.svg",
                        width: "24",
                        height: "24"
                    },
                    label: "Diff",
                    action: "results",
                    path: path
                }
            );
        } else if (this.props.type === "tasks") {
            let path = "/tasks"
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "tasks" ? true : false,
                    icon: {
                        image: "/img/menu-tasks.svg",
                        width: "17",
                        height: "32"
                    },
                    label: "Tasks",
                    action: "tasks",
                    path: path
                }
            );
        } else if (this.props.type === "runs") {
            let path = "/manual"
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "manual" ? true : false,
                    icon: {
                        image: "/img/menu-runs.svg",
                        width: "20",
                        height: "22"
                    },
                    label: "Runs",
                    action: "manual",
                    path: path
                }
            );
        } else if (this.props.type === "lists") {
            let path = "/lists"
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "lists" ? true : false,
                    icon: {
                        image: "/img/menu-lists.svg",
                        width: "18",
                        height: "35"
                    },
                    label: "Lists",
                    action: "lists",
                    path: path
                }
            );
        } else if (this.props.type === "release-checklists") {
            let path = "/release-checklists"
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "release-checklists" ? true : false,
                    icon: {
                        image: "/img/menu-release-checklists.svg",
                        width: "18",
                        height: "35"
                    },
                    label: "Release Checklists",
                    action: "release-checklists",
                    path: path
                }
            );
        } else if (this.props.type === "docs") {
            this.setState(
                {
                    active: this.props.active === "docs" ? true : false,
                    icon: {
                        image: "/img/menu-docs.svg",
                        width: "16",
                        height: "20"
                    },
                    label: "Documentation",
                    action: "docs",
                    path: "/docs"
                }
            );
        } else if (this.props.type === "configuration") {
            let path = "/config"
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "config" ? true : false,
                    icon: {
                        image: "/img/menu-settings.svg",
                        width: "20",
                        height: "21"
                    },
                    label: "Configuration",
                    action: "configuration",
                    path: path
                }
            );
        } else if (this.props.type === "feedback") {
            let path = "/feedback"
            this.setState(
                {
                    active: this.props.active === "feedback" ? true : false,
                    icon: {
                        image: "/img/menu-feedback2.svg",
                        width: "20",
                        height: "20"
                    },
                    label: "Feedback & Help",
                    action: "feedback",
                    path: path
                }
            );
        } else if (this.props.type === "notifications") {
            let path = "/notifications"
            if (this.props.trl) {
                path = "unavailable-public"
            }
            this.setState(
                {
                    active: this.props.active === "notifications" ? true : false,
                    icon: {
                        image: "/img/menu-notifications.svg",
                        width: "17",
                        height: "20"
                    },
                    label: "Notifications",
                    action: "notifications",
                    path: path,
                    count: 0
                }
            );
        }
        else if (this.props.type === "account") {
            let image = undefined;
            const user = Cookies.getJSON("truser");
            if (user !== undefined) {
                if (user.aext !== undefined) {
                    let src = '/user/avatar/pdla/' + user.id + '/' + user.aext;
                    image = <img src={src} className="circle32 neutral4border" alt="" style={{"marginTop":"-8px"}}/>
                }
            }

            let path = "/config/profile"
            if (this.props.trl) {
                path = "unavailable-public"   
            }
            this.setState(
                {
                    active: this.props.active === "account" ? true : false,
                    icon: {
                        image: "/img/menu-account.svg",
                        width: "12",
                        height: "21"
                    },
                    image: image,
                    label: "Account",
                    action: "account",
                    path: path
                }
            );
        }
        else if (this.props.type === "tesults") {
            let path = undefined
            if (this.props.trl) {
                path = "/"
            }
            let stateObj = {
                active: false,
                icon: {
                    image: "/img/menu-tesults-logo.svg",
                    width: "22",
                    height: "22"
                },
                label: "Tesults",
                action: undefined,
                path: path
            }
            this.setState(stateObj);
        }
    }

    render () {
        if (this.state.active === undefined) {
            return <span></span>
        }
        let menuItemClass = (this.props.full === true ? "menu-item " : "menu-item-min ") + ((this.state.active === true && this.props.full === true) ? "menu-item-active" : "");
        let menuItemCircleClass = "menu-item-circle " + (this.state.active === true ? "menu-item-circle-active" : "");
        if (this.state.action === undefined) {
            menuItemClass = "menu-item-inactive";
            menuItemCircleClass = "menu-item-circle-inactive";
        }
        let notifications = <span></span>
        if (this.state.count !== undefined) {
            if (this.state.count > 0) {
                let countText = this.state.count;
                if (countText > 9) {
                    countText = <span>9<sup>+</sup></span>
                }
                notifications =
                <div className="circle24-notifications-blue">
                    {countText}
                </div>
            }
        }

        let image = <img alt="" src={this.state.icon.image} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
        if (this.state.image !== undefined) {
            image = this.state.image
        }
        
        if (this.props.full === true) {
            if (this.state.path === "unavailable-public") {
                return (
                    <div className={menuItemClass} style={{"cursor":"not-allowed"}}>
                        <div className={menuItemCircleClass}>
                            <div className={this.props.iconnopad === true ? "menu-item-icon-work-around" : "menu-item-icon"}>
                                <abbr className="tr-abbr" title="Unavailable in public view">
                                    {image}
                                    {notifications}
                                </abbr>
                            </div>
                        </div>
                        <div className="menu-item-label">
                            {this.state.label}
                        </div>
                    </div>
                )
            }
            return (
                <a  href={this.state.path} 
                    className="menu-item-link"
                    onClick={(e) => {
                        if (this.props.active === "results" && this.props.type === "supplemental") {
                            e.preventDefault();
                            this.props.supplementalView();
                        }
                        if (this.props.active === "supplemental" && this.props.type === "results") {
                            e.preventDefault();
                            this.props.resultsView();
                        }
                    }}
                >
                <div className={menuItemClass}>
                    <div className={menuItemCircleClass}>
                        <div className={this.props.iconnopad === true ? "menu-item-icon-work-around" : "menu-item-icon"}>
                            {image}
                            {notifications}
                        </div>
                    </div>
                    <div className="menu-item-label">
                        {this.state.label}
                    </div>
                </div>
                </a>
            );
        } else {
            if (this.state.path === "unavailable-public") {
                return (
                    <div className={menuItemClass} style={{"cursor":"not-allowed"}}>
                    <div className={menuItemCircleClass}>
                            <div className={this.props.iconnopad === true ? "menu-item-icon-work-around" : "menu-item-icon"}>
                                <abbr className="tr-abbr" title="Unavailable in public view">
                                    {image}
                                    {notifications}
                                </abbr>
                            </div>
                        </div>
                    </div>
                )
            }
            return (
                <a href={this.state.path} 
                    className="menu-item-link"
                    onClick={(e) => {
                        if (this.props.active === "results" && this.props.type === "supplemental") {
                            e.preventDefault();
                            this.props.supplementalView();
                        }
                        if (this.props.active === "supplemental" && this.props.type === "results") {
                            e.preventDefault();
                            this.props.resultsView();
                        }
                    }}
                >
                <div className={menuItemClass}>
                    <div className={menuItemCircleClass}>
                        <div className={this.props.iconnopad === true ? "menu-item-icon-work-around" : "menu-item-icon"}>
                            <abbr className="tr-abbr" title={this.state.label}>
                                {image}
                                {notifications}
                            </abbr>
                        </div>
                    </div>
                </div>
                </a>
            );
        }
    }
};

export default MenuItem;