/*global Cookies */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ManualMain from './ManualMain'
import ManualSide from './ManualSide'
import Analytics from './Analytics';
import ManualCaseEdit from './ManualCaseEdit';
import Context from './Context';
import Request from './Request';
import Cache from './Cache';
import ResultsHeader6 from './ResultsHeader6';
import Unauthenticated from './Unauthenticated';
import ResultsHeader12 from './ResultsHeader12';
import Header from './Header';
const moment = require('moment');

class Manual extends Component {
    static contextType = Context;

    constructor () {
        super();
        let state = "runs";
        let mspId = undefined;
        let mspCreated = undefined;
        let mspCaseNum = undefined;
        let mspObj = this.mspIdAndCreated();
        if (mspObj !== undefined) {
            mspId = mspObj.id;
            mspCreated = mspObj.created;
            mspCaseNum = mspObj.caseNum;
            state = "view";
        }
        
        let loading = true;

        const user = Cookies.getJSON("truser");
        if ((user === undefined || user == null)) {
            state = null;
            loading = false;
        }

        let time = moment().format("MMM D h:mm:ss a");
        this.state = {
            state: state,
            sort: "suite",
            projects: [],
            targets: [],
            projectIndex: 0,
            targetIndex: 0,
            loading: loading,
            manualRunsInProgress: [],
            manualRunsInProgressIndex: 0,
            manualCasesInProgress: [],
            defaultLabel: time,
            label: time,
            closeCase: false,
            error: undefined,
            submitted: false,
            archived: false,
            editCase: undefined,
            lists: [], 
            listIndex: 0, 
            esk: undefined, // list esk
            listCases: [],
            assignee: "all",
            assignSelectedAssignee: undefined,
            assignSaveShow: false,
            loggedIn: false,
            role: 1,
            mspId: mspId,
            mspCreated: mspCreated,
            mspCaseNum: mspCaseNum,
            yOffset: 0,
            multiselect: undefined,
            multiEdit: false,
            saveToListEnabled: true,
            saveToLists: [],
            saveToListId: undefined,
            savingCases: {},
            confirmation: {failure: undefined, success: undefined},
            updateListEnabled: false,
            mobileRunActions: false,
            multiselectClearRequest: 0,
            collapseAll: false,
            updatedCaseHash: undefined
        };

        this.scrollToYOffset = this.scrollToYOffset.bind(this);
        this.projectChange = this.projectChange.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.getTargets = this.getTargets.bind(this);
        this.getManualRunsInProgress = this.getManualRunsInProgress.bind(this);
        this.getManualCasesInProgress = this.getManualCasesInProgress.bind(this);

        this.createNew = this.createNew.bind(this);
        this.createNewConfirm = this.createNewConfirm.bind(this);
        this.editCaseFunc = this.editCaseFunc.bind(this);
        this.addNewCase = this.addNewCase.bind(this);
        this.addCaseToSuite = this.addCaseToSuite.bind(this);
        this.edit = this.edit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.loadMoreCases = this.loadMoreCases.bind(this);
        this.saveCase = this.saveCase.bind(this);
        this.saveCases = this.saveCases.bind(this);
        this.filesUpload = this.filesUpload.bind(this);
        this.filesRemove = this.filesRemove.bind(this);
        this.startSubmit = this.startSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.confirmArchive = this.confirmArchive.bind(this);
        this.archive = this.archive.bind(this);
        this.labelChange = this.labelChange.bind(this);
        this.editLabel = this.editLabel.bind(this);
        this.editLabelConfirm = this.editLabelConfirm.bind(this);
        this.runChange = this.runChange.bind(this);
        this.import = this.import.bind(this);
        this.importSelectedConfirm = this.importSelectedConfirm.bind(this);
        this.importConfirm = this.importConfirm.bind(this);
        this.listChange = this.listChange.bind(this);
        this.getLists = this.getLists.bind(this);
        this.loadMoreLists = this.loadMoreLists.bind(this);
        this.getListCases = this.getListCases.bind(this);
        this.deleteRunCancel = this.deleteRunCancel.bind(this);
        this.deleteRun = this.deleteRun.bind(this);
        this.deleteCase = this.deleteCase.bind(this);
        this.duplicateCase = this.duplicateCase.bind(this);
        this.sortChange = this.sortChange.bind(this);
        this.markAll = this.markAll.bind(this);
        this.markAllDone = this.markAllDone.bind(this);
        this.markAllUndone = this.markAllUndone.bind(this);
        this.members = this.members.bind(this);
        this.assigneeChange = this.assigneeChange.bind(this);
        this.assignSelectedAssigneeChange = this.assignSelectedAssigneeChange.bind(this);
        this.assignSelected = this.assignSelected.bind(this);
        this.assignCase = this.assignCase.bind(this);
        this.assignSuite = this.assignSuite.bind(this);
        this.assignSave = this.assignSave.bind(this);
        this.getProjectRole = this.getProjectRole.bind(this);
        this.backToRuns = this.backToRuns.bind(this);
        this.multiEdit = this.multiEdit.bind(this);
        this.multiselectUpdate = this.multiselectUpdate.bind(this);
        this.multiEditConfirm = this.multiEditConfirm.bind(this);
        this.getSaveToLists = this.getSaveToLists.bind(this);
        this.saveToListToggle = this.saveToListToggle.bind(this);
        this.saveToListChange = this.saveToListChange.bind(this);
        this.updateListToggle = this.updateListToggle.bind(this);
        this.stateControl = this.stateControl.bind(this);
        this.mspIdAndCreated = this.mspIdAndCreated.bind(this);
        this.action = this.action.bind(this);
        this.currentRunPath = this.currentRunPath.bind(this);
        this.setEditSuite = this.setEditSuite.bind(this);
        this.caseHide = this.caseHide.bind(this);
        this.toggleMobileRunActions = this.toggleMobileRunActions.bind(this);
        this.markDone = this.markDone.bind(this);
        this.duplicateRun = this.duplicateRun.bind(this)
        this.duplicateRunConfirm = this.duplicateRunConfirm.bind(this)
        this.toggleCollapseAll = this.toggleCollapseAll.bind(this)
        this.searchChange = this.searchChange.bind(this)
    }

    componentDidMount () {
        window.scrollTo(0,0);
        if (this.state.state === "runs" || this.state.state === "view") {
            const user = Cookies.getJSON("truser");
            if (user === undefined || user === null) {
                this.setState({loggedIn: false});
            } else {
                this.setState({loggedIn: true}, () => this.getProjects());
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.state === "view") {
            this.scrollToYOffset();
        }
        this.stateControl();
    }

    mspIdAndCreated () {
        // Checks if manual url includes manual specific path (msp)
        let mspId = undefined;
        let mspCreated = undefined;
        let mspCaseNum = undefined;
        let msp = window.location.pathname.split("/");
        msp.reverse(); // reversed so that in order pop can occur
        msp.pop(); // pops ""
        msp.pop(); // pops "manual"
        if (msp.pop() === "msp") {
            mspId = msp.pop();
            mspCreated = msp.pop();
            mspCaseNum = msp.pop();
        }
        if (mspId !== undefined && mspCreated !== undefined) {
            return {id: mspId, created: parseInt(mspCreated, 10), caseNum: mspCaseNum};
        } else {
            return undefined;
        }
    }

    action (path) {
        if (path.endsWith("/add")) {
           return true;
        }
        if (path.endsWith("/add-to-suite")) {
           return true;
        }
        if (path.endsWith("/import")) {
           return true;
        }
        if (path.endsWith("/edit-label")) {
            return true;
        }
        if (path.endsWith("/duplicate-run")) {
            return true;
        }
        if (path.endsWith("/edit-case")) {
            return true;
        }
        if (path.endsWith("/submit")) {
            return true;
        }
        if (path.endsWith("/archive")) {
            return true;
        }
        return false;
    }

    stateControl () {
        let path = window.location.pathname;
        if (path === "/manual" && this.state.state !== "runs") {
            this.setState({state: "runs", archived: false});
        } else if (path.startsWith("/manual/msp") && path.endsWith("/submit") && (this.state.state !== "submit" && this.state.state !== "startSubmit")) {
            this.startSubmit();
        } else if (path.startsWith("/manual/msp") && path.endsWith("/create-archive") && (this.state.state !== "createArchive" && this.state.state !== "confirmArchive")) {
            this.confirmArchive();
        } else if (path.startsWith("/manual/msp") && path.endsWith("/edit-case") && this.state.state !== "edit") {
            this.setState({state: "edit"});
        } else if (path.startsWith("/manual/msp") && path.endsWith("/edit-label") && this.state.state !== "editLabel") {
            this.editLabel();
        } else if (path.startsWith("/manual/msp") && path.endsWith("/duplicate-run") && this.state.state !== "duplicate-run") {
            this.duplicateRun()
        } else if (path.startsWith("/manual/msp") && path.endsWith("/import") && this.state.state !== "import") {
            this.import();
        } else if (path.startsWith("/manual/msp") && path.endsWith("/add") && this.state.state !== "edit") {
            this.addNewCase();
        } else if (path.startsWith("/manual/msp") && path.endsWith("/add-to-suite") && this.state.state !== "edit") {
            this.setState({state: "edit"});
        } else if (path.startsWith("/manual/msp") && path.endsWith("/archive") && this.state.state !== "confirmArchive") {
            this.setState({state: "confirmArchive"});
        } else if (path.startsWith("/manual/archive") && this.state.state !== "archive") {
            this.setState({state: "archive"});
        } else if (path.startsWith("/manual/msp") && this.state.state !== "view" && this.action(path) === false) {
            let mspObj = this.mspIdAndCreated();
            let index = 0;
            for (let i = 0; i < this.state.manualRunsInProgress.length; i++) {
                let run = this.state.manualRunsInProgress[i];
                if (run.id === mspObj.id && run.created === mspObj.created) {
                    index = i;
                }
            }
            this.setState({state: "view", manualRunsInProgressIndex: index}, () => this.getManualCasesInProgress());
        } else if (path.startsWith("/manual/create-run") && this.state.state !== "create") {
            this.createNew();
        }
    }

    caseHide() {
        let path = window.location.pathname;
        if (path.endsWith("/edit-case") || path.endsWith("/add") || path.endsWith("/add-to-suite")) {
            this.setState({manualCase: null});
        } else {
            this.setState({manualCase: null});
        }
    }

    scrollToYOffset () {
        if (this.state.yOffset !== 0) {
            setTimeout(function () {
                window.scrollTo({top: this.state.yOffset, left: 0, behavior: 'auto'});
                this.setState({yOffset: 0});
            }.bind(this),100);
        }
    }

    projectChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.projectIndex, index);
        if (this.state.state === "view" || this.state.state === "runs") {
            this.setState({
                    projectIndex: index, 
                    targets: [], 
                    targetIndex: 0,
                    lists: [],
                    listIndex: 0,
                    listCases: [],
                    esk: undefined,
                    manualRunsInProgress:[], 
                    manualRunsInProgressIndex: 0,
                    manualCasesInProgress: [],
                    manualCasesInProgressIndex: 0,
                }, () => this.getProjectRole(this.context.projects[index]));
        } else if (this.state.state === "edit") {
            
        }
    }

    setEditSuite (suite) {
        this.setState({editSuite: suite});
    }

    runChange(index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.manualRunInProgressIndex, index);
        this.setState({state: "view", confirmation: {success: undefined, failure: undefined}, manualRunsInProgressIndex: index, manualCasesInProgress: [], manualCasesInProgressIndex: 0}, () => this.getManualCasesInProgress());
    }

    targetChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.targetIndex, index);
        this.setState({targetIndex: index});
    }

    labelChange (text) {
        window.scrollTo(0,0);
        this.setState({label: text});
    }

    getProjectRole(project) {
        this.setState({loading: true});
        Request.get("/projectRole", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({role: 1, usage: undefined, loading: false});
            } else {
                this.setState({role: data.role, usage: undefined, loading: false}, () => this.getSaveToLists());
            }
        });
    }

    getProjects () {
        this.setState({loading: true});
        Cache.request(this.context, Cache.data.projects, {}, (err, projects) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                if (projectIndex >= projects.length) {
                    projectIndex = 0
                }
                if (this.state.mspId !== undefined) {
                    for (let i = 0; i < projects.length; i++) {
                        let p = projects[i];
                        if (p.id === this.state.mspId) {
                            projectIndex = i;
                        }
                    }
                }
                if (projectIndex < projects.length) {
                    this.setState({projects: projects, projectIndex: projectIndex, loading: false}, () => this.getProjectRole(projects[projectIndex]));
                } else {
                    this.setState({projects: projects, projectIndex: projectIndex, loading: false});
                }
            }
        });
    }

    getTargets (project) {
        let manualRunInProgress = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];

        this.setState({submitted: false, archived: false, loading: true})
        Cache.request(this.context, Cache.data.targets, {id: manualRunInProgress.id}, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let targetIndex = Cache.getPreference(Cache.preference.targetIndex);
                this.setState({state: "submit", confirmation: {success: undefined, failure: undefined}, targets: targets, targetIndex: targetIndex, loading: false}, 
                () => {
                    this.setState({state: "submit"});
                });
            }
        });
    }

    listChange (index) {
        window.scrollTo(0,0);
        Cache.setPreference(Cache.preference.listIndex, index);
        this.setState({listIndex: index, state: "import", confirmation: {success: undefined, failure: undefined}, listCases: []}, () => this.getListCases());
    }

    getLists () {
        this.setState({loading: true});
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        if (project === undefined) {
            this.setState({view: "import", lists: [], loading: false});
            return;
        }
        let id = project.id;
        Request.get("/getAllLists", {id: id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let lists = [];
                for (let i = 0; i < data.lists.length; i++) {
                    let list = data.lists[i];
                    if (list.groupProxy !== true) {
                        lists.push(list);
                    }
                }
                this.setState({view: "import", lists: lists, esk: data.esk, loading: false}, 
                    () => {
                        this.getListCases();
                        this.setState({view: "import", lists: lists, esk: data.esk, loading: false});
                    });
            }
        });
    }

    loadMoreLists () {
        window.scrollTo(0,0);
        this.getLists();
    }

    getSaveToLists () {
        this.setState({loading: true});
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let id = project.id;
        Request.get("/getAllLists", {id: id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let saveToListId = this.state.saveToListId;
                let saveToListIdFound = false;
                let lists = [];
                for (let i = 0; i < data.lists.length; i++) {
                    let list = data.lists[i];
                    if (list.groupProxy !== true) {
                        lists.push(list);
                        if (this.state.saveToListId === (list.id + "-" + list.created)) {
                            saveToListIdFound = true;
                        }
                    }
                }
                if (saveToListIdFound !== true) {
                    if (lists.length > 0) {
                        saveToListId = lists[0].id + "-" + lists[0].created;
                    }
                }
                this.setState({saveToLists: lists, saveToListId: saveToListId, loading: false}, () => this.members());
            }
        });
    }

    getListCases () {
        let list = this.state.lists[this.state.listIndex];
        if (list !== undefined) {
            this.setState({loading: true});
            Request.get("/listCases", {id: list.id, created: list.created}, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    let listCases = [];
                    data.cases.forEach(function (c) {
                        listCases.push(c);
                    });
                    this.setState({view: "import", listCases: listCases, loading: false});
                }
            });
        }
    }

    currentRunPath () {
        const run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        return "/manual/msp/" + run.id + "/" + run.created;
    }

    createNew () {
        window.scrollTo(0,0);
        Analytics.event("ManualRunCreate");
        let time = moment().format("MMM D h:mm:ss a");
        this.setState({state: "create", confirmation: {success: undefined, failure: undefined}, defaultLabel: time, label: time});
    }

    createNewConfirm () {
        window.scrollTo(0,0);
        this.setState({loading: true});
        Request.post("/manualRunCreate", {project: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id, label: this.state.label}, (err, data) => {
            if (err) {
                let error = err;
                if (error === undefined) {
                    error = "Unable to create a run";
                }
                this.setState({confirmation: {failure: error, success: undefined}, loading: false});
            } else {
                Analytics.event("ManualRunCreated");
                let manualRuns = this.state.manualRunsInProgress;
                manualRuns.unshift(data.manual);
                this.setState({confirmation: {failure: undefined, success: "Run created"}, loading: false, manualRunsInProgressIndex: 0, manualCasesInProgress: [], manualCasesInProgressIndex: 0});
                this.props.history.push("/manual/msp/" + data.manual.id + "/" + data.manual.created);
            }
        });
    }

    editLabel () {
        if (this.state.manualRunsInProgressIndex < this.state.manualRunsInProgress.length) {
            let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
            this.setState({state: "editLabel", confirmation: {success: undefined, failure: undefined}, defaultLabel: run.label, label: run.label});
        }
    }

    editLabelConfirm () {
        window.scrollTo(0,0);
        let run = undefined;
        if (this.state.manualRunsInProgressIndex < this.state.manualRunsInProgress.length) {
            run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        } else {
            return;
        }
        this.setState({loading: true});
        Request.post("/manualRunLabelUpdate", {id: run.id, created: run.created, label: this.state.label}, (err, data) => {
            if (err) {
                this.setState({confirmation: {failure: "Unable to update label", success: undefined}, loading: false});
            } else {
                Analytics.event("ManualRunLabelUpdated");
                let manualRuns = this.state.manualRunsInProgress;
                manualRuns[this.state.manualRunsInProgressIndex] = data.run;
                this.setState({confirmation: {success: "Label updated", failure: undefined}, loading: false, manualRunInProgress: manualRuns});
            }
        });
    }

    addNewCase () {
        const yOffset = window.pageYOffset;
        //window.scrollTo(0,0);
        Analytics.event("ManualAddNewCase");
        this.setState({state: "edit", confirmation: {success: undefined, failure: undefined}, saveToListEnabled: true, yOffset: yOffset, editCase: {name: "", result: "pass", files: [], newFilesData: [], removeFiles:[], manualComplete: false}});
    }

    addCaseToSuite (suite) {
        const yOffset = window.pageYOffset;
        //window.scrollTo(0,0);
        Analytics.event("ManualAddCaseToSuite");
        this.setState({state: "edit", confirmation: {success: undefined, failure: undefined}, saveToListEnabled: true, yOffset: yOffset, editCase: {name: "", result: "pass", suite: suite, files: [], newFilesData: [], removeFiles:[], manualComplete: false}}, () => {this.props.history.push(window.location.pathname + "/add-to-suite");});
    }

    editCaseFunc (c) {
        const yOffset = window.pageYOffset;
        //window.scrollTo(0,0);
        Analytics.event("ManualEditCase");
        let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        this.setState({confirmation: {success: undefined, failure: undefined}, saveToListEnabled: false, selectedCase: null, selectedCaseRaw: null, editCase: c, yOffset: yOffset},
        this.props.history.push("/manual/msp/" + run.id + "/" + run.created + "/edit-case"))
    }

    deleteCase (caseData) {
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        Request.post("/manualCaseDelete", {id: project.id, created: run.created, caseData: caseData}, (err, data) => {
            if (err) {
                this.setState({state: "view", confirmation: {failure: "Failed to delete case", success: undefined}});
            } else {
                Analytics.event("ManualCaseDeleted");
                let manualCases = this.state.manualCasesInProgress;
                let removeIndex = -1;
                for (let i = 0; i < manualCases.length; i++) {
                    let c = manualCases[i];
                    if (c.num === caseData.num) {
                        removeIndex = i;
                    }
                }
                if (removeIndex !== -1) {
                    manualCases.splice(removeIndex, 1);
                }
                this.setState({state: "view", confirmation: {failure: undefined, success: "Case deleted successfully"}, manualCasesInProgress: manualCases, selectedCase: null, selectedCaseRaw: null, editCase: null}, () => {this.props.sideOverlay(undefined)});
            }
        });
    }

    duplicateCase (caseData) {
        caseData.name = caseData.name + " (Duplicate)";
        delete caseData.num;
        this.setState({saveToListEnabled: false}, () => this.saveCase(caseData))
    }

    edit (index) {
        window.scrollTo(0,0);
        this.setState({
            manualRunsInProgressIndex: index, 
            targets: [], 
            targetIndex: 0, 
            manualCasesInProgress:[], 
            manualCasesInProgressIndex: index,
            state: "edit",
            confirmation: {success: undefined, failure: undefined}
        }, () => this.getManualCasesInProgress());
    }

    backToRuns () {
        this.props.history.push('/manual');
        this.setState({state: "runs", multiEdit: false, archived: false, confirmation: {failure: undefined, success: undefined}, mspCaseNum: undefined});
    }

    cancel () {
        let refreshProjects = this.state.submitted;
        if (refreshProjects === true) {
            this.setState({state: "view", multiEdit: false, closeCase: true, submitted: false, archived: false, confirmation: {failure: undefined, success: undefined}, manualRunsInProgress: [], manualCasesInProgress:[], manualCasesInProgressIndex:0}, this.getProjects());
        } else {
            this.setState({state: "view", multiEdit: false, closeCase: true, submitted: false, archived: false, confirmation: {failure: undefined, success: undefined}});
        }
    }

    deleteRunCancel () {
        this.setState({deleteRunConfirm: false, confirmation: {failure: undefined, success: undefined}});
    }

    deleteRun () {
        if (this.state.deleteRunConfirm !== true) {
            this.setState({deleteRunConfirm: true});
            return;
        }
        window.scrollTo(0,0);
        if (this.state.manualRunsInProgressIndex >= this.state.manualRunsInProgress.length) {
            return;
        } else {
            this.setState({loading: true});
            let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
            Request.post("/manualRunDelete", {id: run.id, created: run.created}, (err, data) => {
                if (err) {
                    this.setState({confirmation: {failure: "Failed to delete run", success: undefined}, loading: false, deleteRunConfirm: false});
                } else {
                    let manualRunsInProgress = this.state.manualRunsInProgress;
                    manualRunsInProgress.splice(this.state.manualRunsInProgressIndex, 1);
                    this.setState({manualRunsInProgress: manualRunsInProgress, manualRunsInProgressIndex: 0, deleteRunConfirm: false, loading: false, confirmation: {failure: undefined, success: "Run deleted"}}, () => this.getManualCasesInProgress());
                    this.props.history.push("/manual");
                }
            });
        }
    }

    loadMoreCases () {
        window.scrollTo(0,0);
        this.getManualCasesInProgress();
    }

    getManualRunsInProgress () {
        let project = undefined;
        if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
            project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        } else {
            return;
        }

        this.setState({loading: true});
        let manualRunsInProgress = this.state.manualRunsInProgress;

        Request.get("/manualRunsInProgress", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {failure: "Failed to fetch runs in progress", success: undefined}, loading: false});
            } else {
                data.manualRunsInProgress.forEach(function (mrip) {
                    manualRunsInProgress.push(mrip);
                });
                let runIndex = 0;
                let savedIndex = Cache.getPreference(Cache.preference.manualRunInProgressIndex);
                if (savedIndex !== undefined && savedIndex !== null) {
                    if (savedIndex < manualRunsInProgress.length) {
                        runIndex = savedIndex;
                    }
                }
                if (this.state.mspCreated !== undefined) {
                    for (let i = 0; i < data.manualRunsInProgress.length; i++) {
                        let m = data.manualRunsInProgress[i];
                        if (m.created === this.state.mspCreated) {
                            runIndex = i;
                        }
                    }
                }
                this.setState({manualRunsInProgress: manualRunsInProgress, manualRunsInProgressIndex: runIndex, loading: false, confirmation: {failure: undefined, success: undefined}}, () => this.getManualCasesInProgress());
            }
        });
    }

    getManualCasesInProgress () {
        let project = undefined;
        if (this.context.projects === undefined) {
            return;
        }
        if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
            project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        } else {
            return;
        }

        if (this.state.manualRunsInProgressIndex >= this.state.manualRunsInProgress.length) {
            return;
        }

        let created = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex].created;

        Request.get("/manualCasesInProgress", {id: project.id, created: created}, (err, data) => {
            if (err) {
                this.setState({confirmation: {failure: "Failed to fetch cases in progress", success: undefined}});
            } else {
                this.setState({manualCasesInProgress: data.manualCasesInProgress, confirmation: {failure: undefined, success: undefined}});
            }
        });
    }

    filesRemove (removeFiles, caseData, currentCaseNum, index, warnings, callback) {
        // Implement
        if (removeFiles === undefined) {
            callback(undefined);
            return;
        }

        if (index >= removeFiles.length) {
            // files remove complete
            if (warnings.length > 0) {
                let warningsString = "";
                warnings.forEach(function (w) {
                    warningsString += w + " "
                });
                callback(warningsString);
            } else {
                callback(undefined);
            }
            return;
        }
        
        let manualRunInProgress = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        let file = removeFiles[index];
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("DELETE", "/manual/files/" + manualRunInProgress.id + "/" + manualRunInProgress.created + "/" + currentCaseNum + "/" + file, true);
        xmlHttpRequest.onreadystatechange = function() {
            if(xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
                if (xmlHttpRequest.status !== 200) {
                    warnings.push(file);
                } else {
                    //Analytics.event("TargetAvatarEdited");
                }
                this.filesRemove(removeFiles, caseData, currentCaseNum, index + 1, warnings, callback);
            }
        }.bind(this)
        xmlHttpRequest.send();
    }

    filesUpload (newFilesData, caseData, currentCaseNum, index, warnings, callback) {
        if (newFilesData === undefined) {
            callback(undefined);
            return;
        }

        if (index >= newFilesData.length) {
            // files upload complete
            if (warnings.length > 0) {
                let warningsString = "";
                warnings.forEach(function (w) {
                    warningsString += w + " "
                });
                callback(warningsString);
            } else {
                callback(undefined);
            }
            return;
        }
        
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let manualRunInProgress = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        let file = newFilesData[index];
        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open("POST", "/manual/files/" + project.id + "/" + manualRunInProgress.created + "/" + currentCaseNum + "/" + file.name, true);
        xmlHttpRequest.setRequestHeader("Content-Type", file.type);
        xmlHttpRequest.onreadystatechange = function() {
            if(xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
                if (xmlHttpRequest.status === 200) {
                    Analytics.event("ManualFileUpload");
                    this.filesUpload(newFilesData, caseData, currentCaseNum, index + 1, warnings, callback);
                } else {
                    warnings.push(file);
                    this.filesUpload(newFilesData, caseData, currentCaseNum, index + 1, warnings, callback);
                }
            }
        }.bind(this)
        xmlHttpRequest.send(file);
    }

    saveCase (caseData) {
        let savingCases = this.state.savingCases;
        savingCases[caseData.num] = true;
        this.setState({loading: false, closeCase: false, savingCases: savingCases, saving: true});

        // Organize files
        let removeFiles = caseData.removeFiles;
        if (removeFiles === undefined) {
            removeFiles = [];
        }
        let newFilesData = caseData.newFilesData;
        if (newFilesData === undefined) {
            newFilesData = [];
        }
        let files = caseData.files; // Existing files

        let filesDict = {};
        if (files !== undefined) {
            files.forEach(function (f) {
                filesDict[f] = f;
            });
        }
        
        removeFiles.forEach(function (f) {
            filesDict[f] = undefined;
        });

        files = [];
        
        Object.keys(filesDict).forEach(function (key) {
            let f = filesDict[key];
            if (f !== undefined) {
                files.push(f);
            }
        });
        newFilesData.forEach(function (file) {
            if (filesDict[file] === undefined) {
                files.push(file.name);
            }
        });

        // manaul save metadata
        const user = Cookies.getJSON("truser");
        if (user !== undefined && user !== null) {
            if (caseData.metadata === undefined) {
                caseData.metadata = {};
            }
            if (caseData.metadata.editHistory === undefined) {
                caseData.metadata.editHistory = [];
            }
            caseData.metadata.editHistory.push(
                {
                    time: Date.now().toString(),
                    user: user.id === "" ? "-" : user.id,
                    firstName: user.firstName === "" ? "-" : user.firstName,
                    lastName: user.lastName === "" ? "-" : user.lastName
                }
            );
        }

        caseData.files = files;
        delete caseData.removeFiles;
        delete caseData.newFilesData;

        this.setState({confirmation: {success: "Saving changes", failure: undefined}, updatedCaseHash: undefined}); 
        Request.post("/manualCaseSave", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id, created: this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex].created, data: caseData}, (err, data) => {
            if (err) {
                this.setState({saving: false, confirmation: {failure: err, success: undefined}, loading: false, closeCase: false});
            } else {
                // Remove files
                let removeError = undefined;
                this.filesRemove(removeFiles, caseData, data.currentCaseNum, 0, [], function (err) {
                    if (err) {
                        removeError = err;
                    }
                    // Add new files
                    this.filesUpload(newFilesData, caseData, data.currentCaseNum, 0, [], function (err) {
                        let failure = undefined;
                        let success = "Case saved"
                        if (err) {
                            failure = "Case saved but failed to upload files: " + err;
                            success = undefined;
                            if (removeError !== undefined) {
                                failure += ", unable to remove files: " + removeError;
                            }
                        }
                        if (this.state.saveToListEnabled === true) {
                            let saveToListId = this.state.saveToListId;
                            const splitIndex = saveToListId.lastIndexOf("-");
                            const id = saveToListId.substring(0, splitIndex);
                            const created = saveToListId.substring(splitIndex + 1);
                            Request.post("/listCasesAdd", {id: id, created: created, cases: [caseData]}, (err, data2) => {
                                if (err) {
                                    let savingCases = this.state.savingCases;
                                    savingCases[caseData.num] = undefined;
                                    let errorMessage = "Failed to save case";
                                    if (err.error !== undefined) {
                                        errorMessage = err.error.message;
                                    }
                                    this.setState({state: "view", updatedCaseHash: data.updatedCaseHash, saving: false, confirmation: {failure: errorMessage, success: undefined}, loading: false, closeCase: false, savingCases: savingCases});
                                } else {
                                    Analytics.event("ListCaseSaved");
                                    Analytics.event("ManualCaseSaved");
                                    let savingCases = this.state.savingCases;
                                    savingCases[caseData.num] = undefined;
                                    this.caseHide()
                                    this.setState({state: "view", updatedCaseHash: data.updatedCaseHash, saving: false, multiselect: undefined, confirmation: {failure: failure, success: success}, manualCasesInProgress: data.manualCasesInProgress, loading: false, closeCase: true, savingCases: savingCases}, 
                                        () => {
                                            this.props.history.push(this.currentRunPath());
                                            this.props.sideOverlay(undefined);
                                            this.setState({state: "view"});
                                    });
                                }
                            });
                        } else if (this.state.updateListEnabled === true && caseData.importList !== undefined) {
                            let updateCase = {};
                            let listId = undefined;
                            let listCreated = undefined;
                            Object.keys(caseData).forEach(function (key) {
                                updateCase[key] = caseData[key];
                                updateCase.num = caseData.importList.num;
                                listId = caseData.importList.id;
                                listCreated = caseData.importList.created;
                                delete updateCase.importList;
                            });
                            Request.post("/listCaseUpdate", {id: listId, created: listCreated, caseUpdate: updateCase}, (err, data2) => {
                                if (err) {
                                    // Ignore list case update error
                                    Analytics.event("ManualCaseSaved");
                                    let savingCases = this.state.savingCases;
                                    savingCases[caseData.num] = undefined;
                                    this.setState({state: "view", updatedCaseHash: data.updatedCaseHash, saving: false, savingCases: savingCases, multiselect: undefined, confirmation: {failure: failure, success: success}, manualCasesInProgress: data.manualCasesInProgress, loading: false, closeCase: true});
                                    this.props.history.push(this.currentRunPath());
                                } else {
                                    Analytics.event("ListCaseUpdated");
                                    Analytics.event("ManualCaseSaved");
                                    let savingCases = this.state.savingCases;
                                    savingCases[caseData.num] = undefined;
                                    this.setState({state: "view", updatedCaseHash: data.updatedCaseHash, saving: false, savingCases: savingCases, multiselect: undefined, confirmation: {failure: failure, success: success}, manualCasesInProgress: data.manualCasesInProgress, loading: false, closeCase: true}, () => this.caseHide());
                                    this.props.history.push(this.currentRunPath());
                                }
                            });
                        } else {
                            Analytics.event("ManualCaseSaved");
                            let savingCases = this.state.savingCases;
                            savingCases[caseData.num] = undefined;
                            this.caseHide();
                            this.setState({state: "view", updatedCaseHash: data.updatedCaseHash, saving: false, savingCases: savingCases, multiselect: undefined, confirmation: {failure: failure, success: success}, manualCasesInProgress: data.manualCasesInProgress, loading: false, closeCase: true}, 
                                () => {
                                    this.props.history.push(this.currentRunPath());
                                    this.props.sideOverlay(undefined);
                                    this.setState({state: "view"});
                                });
                            
                        }
                    }.bind(this));
                }.bind(this));
            }
        });
    }

    startSubmit () {
        this.setState({state: "startSubmit"});
        window.scrollTo(0,0);
        this.getTargets();
    }

    submit () {
        window.scrollTo(0,0);
        let manualRunsInProgress = this.state.manualRunsInProgress;
        let manualRunInProgress = manualRunsInProgress[this.state.manualRunsInProgressIndex];
        let target = this.state.targets[this.state.targetIndex];
        this.setState({loading: true});
        Request.post("/manualSubmit", {id: manualRunInProgress.id, created: manualRunInProgress.created, target: target.created}, (err, data) => {
            if (err) {
                this.setState({state: "view", confirmation: {failure: "Unable to submit results", success: undefined}, loading: false, closeCase: false});
            } else {
                Analytics.event("ManualRunSubmitted");
                manualRunsInProgress.splice(this.state.manualRunsInProgressIndex, 1); // remove submitted run
                this.setState({loading: false, confirmation: {success: "Submitted", failure: undefined}, manualRunsInProgress: manualRunsInProgress, manualRunsInProgressIndex: 0, submitted: true},
                () => {
                    this.setState({submitted: true});
                });
            }
        });
    }

    confirmArchive () {
        this.setState({state: "confirmArchive", archived: false});
        window.scrollTo(0,0);
    }

    archive () {
        window.scrollTo(0,0);
        let manualRunsInProgress = this.state.manualRunsInProgress;
        let manualRunInProgress = manualRunsInProgress[this.state.manualRunsInProgressIndex];
        this.setState({loading: true});
        Request.post("/manualArchive", {id: manualRunInProgress.id, created: manualRunInProgress.created}, (err, data) => {
            if (err) {
                this.setState({state: "view", confirmation: {failure: "Unable to archive", success: undefined}, loading: false, closeCase: false});
            } else {
                Analytics.event("ManualArchiveSubmitted");
                manualRunsInProgress.splice(this.state.manualRunsInProgressIndex, 1); // remove submitted run
                this.setState({loading: false, confirmation: {success: "Archived", failure: undefined}, manualRunsInProgress: manualRunsInProgress, manualRunsInProgressIndex: 0, archived: true},
                () => {
                    this.setState({archived: true});
                });
            }
        });
    }

    import () {
        window.scrollTo(0,0);
        this.setState({state: "import", multiEdit: true, confirmation: {success: undefined, failure: undefined}}, () => this.getLists());
    }

    saveCases (cases, update) {
        this.setState({closeCase: false});
        Request.post("/manualCasesSave", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id, created: this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex].created, cases: cases, update: update}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err}, closeCase: false});
            } else {
                Analytics.event("ManualCasesSaved");
                this.setState({confirmation: {success: "Saved", failure: undefined}, multiselect: undefined, manualCasesInProgress: data.manualCasesInProgress, closeCase: true, assignSelected: false, multiselectClearRequest: this.state.multiselectClearRequest + 1}, () => { this.props.history.push(this.currentRunPath()); this.props.sideOverlay(undefined);});
            }
        });
    }

    importSelectedConfirm () {
        Analytics.event("ManualImportSelectedConfirm");
        let cases = [];
        let multiselect = this.state.multiselect;
        if (multiselect === undefined) {
            return;
        }
        let list = this.state.lists[this.state.listIndex];
        this.state.listCases.forEach(function (c) {
            if (multiselect[c.num] === true) {
                c.manualComplete = false;
                c.importList = {id: list.id, created: list.created, num: c.num};
                cases.push(c);
            }
        })
        this.saveCases(cases);
    }

    importConfirm () {
        Analytics.event("ManualImportConfirm");
        let cases = this.state.listCases;
        let list = this.state.lists[this.state.listIndex];
        cases.forEach(function (c) {
            c.manualComplete = false;
            c.importList = {id: list.id, created: list.created, num: c.num};
        })
        this.saveCases(cases);
    }

    sortChange (value) {
        this.setState({sort: value});
    }

    markAll (done) {
        let project = undefined;
        if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
            project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        } else {
            return;
        }

        if (this.state.manualRunsInProgressIndex >= this.state.manualRunsInProgress.length) {
            return;
        }

        this.setState({loading: true});
        let created = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex].created;

        Request.post("/manualCasesMarkAll", {id: project.id, created: created, done: done}, (err, data) => {
            if (err) {
                this.setState({confirmation: {failure: "Unable to fetch cases in progress", success: undefined}, loading: false});
            } else {
                this.setState({confirmation: {success: undefined, failure: undefined}, manualCasesInProgress: data.manualCasesInProgress, loading: false}, 
                    () => {
                        this.props.history.push(this.currentRunPath());
                        this.props.sideOverlay(undefined);
                        this.setState({state: "view"});
                    });
            }
        });
    }

    markAllDone () {
        Analytics.event("ManualMarkAllDone");
        this.markAll(true);
    }

    markAllUndone () {
        Analytics.event("ManualMarkAllUndone");
        this.markAll(false);
    }

    markDone (caseData, done) {
        Analytics.event("ManualMarkDone");
        caseData.manualComplete = done;
        this.setState({saveToListEnabled: false}, () => this.saveCase(caseData))
    }

    members () {
        if (this.state.role < 2) {
            return;
        }

        if (this.context.projects === undefined || Cache.getPreference(Cache.preference.projectIndex) === undefined) {
            return;
        }
        
        if (Cache.getPreference(Cache.preference.projectIndex) >= this.context.projects.length) {
            return;
        }

        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        
        this.setState({loading: true});
        Request.get("/members", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({members: [], loading: false});
            } else {
                let memberId = undefined;
                if (data.members !== undefined) {
                    if (data.members.length > 0) {
                        memberId = data.members[0].id;
                    }
                }
                this.setState({members: data.members, assignSelectedAssignee: memberId, loading: false});
                if (project.status === "active") {
                    this.getManualRunsInProgress();
                }
            }
        });
    }

    assigneeChange (assignee) {
        this.setState({assignee: assignee});
    }

    assignSelectedAssigneeChange (assignee) {
        this.setState({assignSelectedAssignee: assignee});
    }

    assignSelected () {
        Analytics.event("ManualAssignSelectedConfirm");
        let cases =  this.state.manualCasesInProgress;
        let multiselect = this.state.multiselect;
        if (multiselect === undefined) {
            return;
        }
        let change = false;

        for (let i = 0; i < cases.length; i++) {
            let c = cases[i];
            if (multiselect[c.num] === true) {
                c.manualAssignee = this.state.assignSelectedAssignee;
                change = true;
            }
        }
    
        if (change === true) {
            this.saveCases(cases, true);
        }
    }

    assignCase (ac) {
        let assignee = this.state.assignee;
        let cases = this.state.manualCasesInProgress;
        cases.forEach(function (c) {
            if (c.num === ac.num) {
                if (c.manualAssignee === undefined) {
                    c.manualAssignee = assignee;
                } else {
                    if (c.manualAssignee === assignee) {
                        delete c.manualAssignee;
                    } else {
                        c.manualAssignee = assignee;
                    }
                }
            }
        });
        Analytics.event("ManualAssignCase");
        this.setState({manualCasesInProgress: cases, assignSaveShow: true});
    }

    assignSuite (s) {
        let assignee = this.state.assignee;
        let cases = this.state.manualCasesInProgress;
        cases.forEach(function (c) {
            if (c.suite === s) {
                if (c.manualAssignee === undefined) {
                    c.manualAssignee = assignee;
                } else {
                    if (c.manualAssignee === assignee) {
                        delete c.manualAssignee;
                    } else {
                        c.manualAssignee = assignee;
                    }
                }
            }
        });
        Analytics.event("ManualAssignSuite");
        this.setState({manualCasesInProgress: cases, assignSaveShow: true});
    }

    assignSave () {
        this.setState({loading: true});
        Request.post("/manualCasesAssign", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id, created: this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex].created, cases: this.state.manualCasesInProgress}, (err, data) => {
            if (err) {
                this.setState({confirmation: {failure: "Failed to save manual cases assignment", success: undefined}, loading: false});
            } else {
                Analytics.event("ManualAssignSaved");
                this.setState({confirmation: {success: "Assigned", failure: undefined}, manualCasesInProgress: data.manualCasesInProgress, assignSaveShow: false, loading: false});
            }
        });
    }

    multiEdit (enable) {
        this.setState({multiEdit: enable});
    }

    multiselectUpdate (multiselect) {
        this.setState({multiselect: multiselect, confirmation: {success: undefined, failure: undefined}});
    }

    multiEditConfirm (field, value) {
        if (field === undefined || value === undefined) {
            return;
        }
        Analytics.event("ManualMultiEditConfirm" + field.charAt(0).toUpperCase() + field.substring(1));
        let multiselect = this.state.multiselect;
        if (multiselect === undefined) {
            return;
        }

        let updated = false;
        let cases = this.state.manualCasesInProgress;
        if (field === "delete") {
            let keep = [];
            for (let i = 0; i < cases.length; i++) {
                let c = cases[i];
                if (multiselect[c.num] !== true) {
                    keep.push(c);
                }
            }
            cases = keep;
            updated = true;
        } else {
            for (let i = 0; i < cases.length; i++) {
                let c = cases[i];
                if (multiselect[c.num] === true) {
                    c[field] = value;
                    updated = true;
                }
            }
        }
        
        if (updated === true) {
            this.saveCases(cases, true);
        }
    }

    saveToListToggle () {
        if (this.state.saveToListEnabled === true) {
            this.setState({saveToListEnabled: false});
        } else {
            this.setState({saveToListEnabled: true});
        }
    }

    updateListToggle () {
        if (this.state.updateListEnabled === true) {
            this.setState({updateListEnabled: false});
        } else {
            this.setState({updateListEnabled: true});
        }
    }

    saveToListChange (e) {
        Cache.setPreference(Cache.preference.saveToListId, e.target.value);
        this.setState({saveToListId: e.target.value});
    }
    
    toggleMobileRunActions () {
        this.setState({mobileRunActions: this.state.mobileRunActions === true ? false: true});
    }

    duplicateRun () {
        if (this.state.manualRunsInProgressIndex < this.state.manualRunsInProgress.length) {
            let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
            this.setState({state: "duplicate-run", confirmation: {success: undefined, failure: undefined}, run: run});
        }
    }

    duplicateRunConfirm () {
        window.scrollTo(0,0);
        this.setState({loading: true});
        let label = this.state.label;
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
        let created = run.created
        let id = project.id
        Request.post("/manualRunDuplicate", {id: id, label: label, created: created}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create new duplicate test run.", loading: false});
            } else {
                Analytics.event("RunDuplicated");
                let runs = this.state.manualRunsInProgress
                runs.push(data.run);
                this.setState({state: "view", error: undefined, loading: false, manualRunInProgress: runs, manualRunsInProgressIndex: runs.length - 1, manualCasesInProgress: []}, () => {this.props.history.push("/manual"); this.props.history.go();});
            }
        });
    }

    toggleCollapseAll () {
        this.setState({collapseAll: this.state.collapseAll ? false : true})
    }

    searchChange (value) {
        this.setState({search: value});
    }

    render () {
        if (this.state.loggedIn !== true) {
            return <Unauthenticated id={this.state.mspId} history={this.props.history}/>
        }
        const path = window.location.pathname;
        if (this.state.state === "edit" && (path.endsWith("/add") || (path.endsWith("/add-to-suite")) || path.endsWith("/edit-case"))) {
            let manualRun = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
            const runPath = this.currentRunPath();
            this.props.sideOverlay(
                <ManualCaseEdit 
                    type="manual" 
                    data={this.state.editCase} 
                    saveCase={this.saveCase}
                    members={this.state.members}
                    cancel={this.cancel} 
                    cases={this.state.listCases}
                    manualRunId={manualRun.id}
                    manualRunCreated={manualRun.created}
                    saveToListEnabled={this.state.saveToListEnabled}
                    saveToLists={this.state.saveToLists}
                    saveToListToggle={this.saveToListToggle}
                    saveToListId={this.state.saveToListId}
                    saveToListChange={this.saveToListChange}
                    saving={this.state.saving}
                    updateListEnabled={this.state.updateListEnabled}
                    updateListToggle={this.updateListToggle}
                    confirmation={this.state.confirmation}
                    yOffset={this.state.yOffset}
                    overlay={this.props.overlay} 
                    messageOverlay={this.props.messageOverlay}
                    side={this.props.side}
                    history={this.props.history}
                    sideOverlay={this.props.sideOverlay}
                    sideOpen={this.props.sideOpen}
                    cancelUrl={runPath}
                />
            );
        }
        const title = undefined;
        let freeProject = false;
        if (this.state.projects !== undefined && this.state.projectIndex !== undefined) {
            let projects = this.context.projects;
            if (projects !== undefined) {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                if (projectIndex === undefined) {
                    projectIndex = 0
                }
                if (projectIndex < projects.length) {
                    let project = projects[projectIndex]
                    if (project.plan.name === "free-v1") {
                        freeProject = true;
                    }
                }
            }
        }
        const side = <ManualSide 
                        state={this.state.state} 
                        projects={this.context.projects} 
                        projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                        projectChange={this.projectChange}
                        targets={this.state.targets} 
                        targetIndex={this.state.targetIndex} 
                        targetChange={this.targetChange} 
                        manualRunsInProgress={this.state.manualRunsInProgress} 
                        manualRunsInProgressIndex={this.state.manualRunsInProgressIndex}
                        loading={this.state.loading}
                        createNew={this.createNew}
                        createNewConfirm={this.createNewConfirm}
                        defaultLabel={this.state.defaultLabel}
                        labelChange={this.labelChange}
                        label={this.state.label}
                        editLabel={this.editLabel}
                        editLabelConfirm={this.editLabelConfirm}
                        edit={this.edit}
                        cancel={this.cancel}
                        error={this.state.error}
                        loadMoreCases={this.loadMoreCases}
                        manualCasesInProgress={this.state.manualCasesInProgress}
                        saveCase={this.saveCase}
                        closeCase={this.state.closeCase}
                        startSubmit={this.startSubmit}
                        submit={this.submit}
                        submitted={this.state.submitted}
                        archive={this.archive}
                        archived={this.state.archived}
                        editCase={this.state.editCase}
                        editCaseFunc={this.editCaseFunc}
                        addNewCase={this.addNewCase}
                        addCaseToSuite={this.addCaseToSuite}
                        runChange={this.runChange}
                        import={this.import}
                        importSelectedConfirm={this.importSelectedConfirm}
                        importConfirm={this.importConfirm}
                        listChange={this.listChange}
                        getLists={this.getLists}
                        loadMoreLists={this.loadMoreLists}
                        getListCases={this.getListCases}
                        lists={this.state.lists}
                        listIndex={this.state.listIndex}
                        esk={this.state.esk}
                        listCases={this.state.listCases}
                        deleteRun={this.deleteRun}
                        deleteCase={this.deleteCase}
                        duplicateCase={this.duplicateCase}
                        sort={this.state.sort}
                        onSortChange={this.sortChange}
                        markAllDone={this.markAllDone}
                        markAllUndone={this.markAllUndone}
                        markDone={this.markDone}
                        members={this.state.members}
                        assignee={this.state.assignee}
                        assigneeChange={this.assigneeChange}
                        assignSelectedAssignee={this.state.assignSelectedAssignee}
                        assignSelectedAssigneeChange={this.assignSelectedAssigneeChange}
                        assignSelected={this.assignSelected}
                        assignCase={this.assignCase}
                        assignSuite={this.assignSuite}
                        assignSave={this.assignSave}
                        assignSaveShow={this.state.assignSaveShow}
                        loggedIn={this.state.loggedIn}
                        role={this.state.role}
                        deleteRunConfirm={this.state.deleteRunConfirm}
                        deleteRunCancel={this.deleteRunCancel}
                        backToRuns={this.backToRuns}
                        multiEdit={this.state.multiEdit}
                        multiEditFunc={this.multiEdit}
                        multiEditConfirm={this.multiEditConfirm}
                        multiselectUpdate={this.multiselectUpdate}
                        saveToListEnabled={this.state.saveToListEnabled}
                        saveToLists={this.state.saveToLists}
                        saveToListToggle={this.saveToListToggle}
                        saveToListId={this.state.saveToListId}
                        saveToListChange={this.saveToListChange}
                        confirmation={this.state.confirmation}
                        setEditSuite={this.setEditSuite}
                        updateListEnabled={this.state.updateListEnabled}
                        updateListToggle={this.updateListToggle}
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        sideOpen={this.props.sideOpen}
                        freeProject={freeProject}
                        />
        const main = <ManualMain 
                        history={this.props.history}
                        updatedCaseHash={this.state.updatedCaseHash}
                        state={this.state.state} 
                        projects={this.context.projects} 
                        projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                        projectChange={this.projectChange}
                        targets={this.state.targets} 
                        targetIndex={this.state.targetIndex} 
                        targetChange={this.targetChange} 
                        manualRunsInProgress={this.state.manualRunsInProgress}
                        manualRunsInProgressIndex={this.state.manualRunsInProgressIndex}
                        loading={this.state.loading}
                        createNew={this.createNew}
                        createNewConfirm={this.createNewConfirm}
                        defaultLabel={this.state.defaultLabel}
                        label={this.state.label}
                        labelChange={this.labelChange}
                        editLabel={this.editLabel}
                        editLabelConfirm={this.editLabelConfirm}
                        edit={this.edit}
                        cancel={this.cancel}
                        error={this.state.error}
                        loadMoreCases={this.loadMoreCases}
                        manualCasesInProgress={this.state.manualCasesInProgress}
                        saveCase={this.saveCase}
                        closeCase={this.state.closeCase}
                        startSubmit={this.startSubmit}
                        submit={this.submit}
                        submitted={this.state.submitted}
                        archive={this.archive}
                        archived={this.state.archived}
                        editCase={this.state.editCase}
                        editCaseFunc={this.editCaseFunc}
                        addNewCase={this.addNewCase}
                        addCaseToSuite={this.addCaseToSuite}
                        runChange={this.runChange}
                        import={this.import}
                        importSelectedConfirm={this.importSelectedConfirm}
                        importConfirm={this.importConfirm}
                        listChange={this.listChange}
                        getLists={this.getLists}
                        loadMoreLists={this.loadMoreLists}
                        getListCases={this.getListCases}
                        lists={this.state.lists}
                        listIndex={this.state.listIndex}
                        esk={this.state.esk}
                        listCases={this.state.listCases}
                        deleteRun={this.deleteRun}
                        deleteCase={this.deleteCase}
                        duplicateCase={this.duplicateCase}
                        sort={this.state.sort}
                        onSortChange={this.sortChange}
                        markAllDone={this.markAllDone}
                        markAllUndone={this.markAllUndone}
                        markDone={this.markDone}
                        members={this.state.members}
                        assignee={this.state.assignee}
                        assigneeChange={this.assigneeChange}
                        assignSelectedAssignee={this.state.assignSelectedAssignee}
                        assignSelectedAssigneeChange={this.assignSelectedAssigneeChange}
                        assignSelected={this.assignSelected}
                        assignCase={this.assignCase}
                        assignSuite={this.assignSuite}
                        assignSave={this.assignSave}
                        assignSaveShow={this.state.assignSaveShow}
                        loggedIn={this.state.loggedIn}
                        role={this.state.role}
                        deleteRunConfirm={this.state.deleteRunConfirm}
                        deleteRunCancel={this.deleteRunCancel}
                        backToRuns={this.backToRuns}
                        multiEdit={this.state.multiEdit}
                        multiselectClearRequest={this.state.multiselectClearRequest}
                        multiEditFunc={this.multiEdit}
                        multiEditConfirm={this.multiEditConfirm}
                        multiselectUpdate={this.multiselectUpdate}
                        saveToListEnabled={this.state.saveToListEnabled}
                        saveToLists={this.state.saveToLists}
                        saveToListToggle={this.saveToListToggle}
                        saveToListId={this.state.saveToListId}
                        saveToListChange={this.saveToListChange}
                        savingCases={this.state.savingCases}
                        confirmation={this.state.confirmation}
                        setEditSuite={this.setEditSuite}
                        saving={this.state.saving}
                        updateListEnabled={this.state.updateListEnabled}
                        updateListToggle={this.updateListToggle}
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        sideOpen={this.props.sideOpen}
                        sideContent={side}
                        mspId={this.state.mspId}
                        mspCreated={this.state.mspCreated}
                        mspCaseNum={this.state.mspCaseNum}
                        duplicateRunConfirm={this.duplicateRunConfirm}
                        collapseAll={this.state.collapseAll}
                        search={this.state.search}
                        />

        let pageTitle = "Tesults - Manual";
        if (this.state.state !== "runs") {
            if (this.state.manualRunsInProgress !== undefined && this.state.manualRunsInProgressIndex !== undefined) {
                if (this.state.manualRunsInProgressIndex < this.state.manualRunsInProgress.length) {
                    let run = this.state.manualRunsInProgress[this.state.manualRunsInProgressIndex];
                    pageTitle = "Tesults - " + run.label;
                }
            }
        }

        let mobileRunActions = <span></span>
        if (this.state.mobileRunActions === true) {
            mobileRunActions = side;
        }
        const mobileRunActionsButton = <button onClick={this.toggleMobileRunActions} className="mt-3 btn-confirm">{this.state.mobileRunActions === true ? "Hide run actions" : "Show run actions"}</button>
        
        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content="Learn how to use Tesults Runs for effective test case management and manual testing."/>
                </Helmet>
                {
                    (this.state.state === "view" || this.state.state === "edit")  ?
                    <Header
                        type="header-double"
                        header1={
                            <ResultsHeader6
                                state={this.state.state}
                                runs={this.state.manualRunsInProgress}
                                runIndex={this.state.manualRunsInProgressIndex}
                                runCases={this.state.manualCasesInProgress}
                            />
                        }
                        header2={
                            <ResultsHeader12
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                sortChange={this.sortChange}
                                collapseAll={this.state.collapseAll}
                                toggleCollapseAll={this.toggleCollapseAll}
                                onSearchChange={this.searchChange}
                                cases={this.state.manualCasesInProgress}
                            />
                        }
                    />
                    :
                    <Header
                        type="header-single"
                        header1={
                            <ResultsHeader6
                                state={this.state.state}
                                runs={this.state.manualRunsInProgress}
                                runIndex={this.state.manualRunsInProgressIndex}
                                runCases={this.state.manualCasesInProgress}
                            />
                        }
                    />
                }
                <div className="app-main-margin">
                    <div className="desktop-hide">
                        <div className="run-actions-padding">
                            <div className="run-side">
                                {mobileRunActions}    
                            </div>
                            {mobileRunActionsButton}
                        </div>
                    </div>
                    {main}
                </div>
            </div>
        );
    }
};

export default Manual;