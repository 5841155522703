/*global*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom' 
import Request from './Request'
import Context from './Context'
import Cache from './Cache'
import TimeFormatted from './TimeFormatted'

class RunArchiveSelect extends Component {
    static contextType = Context;

    constructor () {
        super()
        this.ref = React.createRef();
        this.state = ({ 
            archivedRuns: [],
            id: undefined,
            created: undefined,
            cursor: undefined
        })
        this.init = this.init.bind(this)
        this.archivedRuns = this.archivedRuns.bind(this)
        this.contentError = this.contentError.bind(this)
        this.project = this.project.bind(this)
        this.archivedRunRender = this.archivedRunRender.bind(this)
        this.archivedRunsRender = this.archivedRunsRender.bind(this)
    }

    componentDidMount () {
        if (this.props.view === "results") {
            this.init()
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.targets !== undefined && prevProps.targets !== undefined) {
            if (this.props.targets.length !== prevProps.targets.length) {
                this.init()
                return
            }
        }
        if (this.props.rev !== prevProps.rev) {
            this.init()
            return
        }
    }

    init () {
        let initData = {id: undefined, created: undefined, runCreated: undefined, archivedRuns: [], cursor: undefined}
        let project = this.project()
        if (project !== undefined) {
            initData.id = project.id
        }
        if (this.props.targets !== undefined) {
            if (this.props.targetIndex !== undefined) {
                if (this.props.targetIndex < this.props.targets.length) {
                    initData.created = this.props.targets[this.props.targetIndex].created
                }
            }
        }
        this.setState(initData, this.archivedRuns)
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }
    
    contentError () {
        
    }

    archivedRuns () {
        Request.get("/run-archives", {id: this.state.id, created: this.state.created, cursor: this.state.cursor}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                if (err.error !== undefined) {
                    this.setState({error: err.error.message});
                }
            } else {
                let archivedRuns = this.state.archivedRuns
                for (let i = 0; i < data.data.runArchives.length; i++) {
                    archivedRuns.push(data.data.runArchives[i])
                }
                this.setState({error: "", archivedRuns: archivedRuns, cursor: data.data.cursor})
            }
        });
    }

    archivedRunRender (archivedRun) {
        return (
            <div key={archivedRun.created}>
                <Link target="_blank" to={"/results/rsp/view/results/run-archive/" + archivedRun.id + "-" + archivedRun.created}>
                    <span className="font14"><TimeFormatted dt={archivedRun.created} fromNow={false} includeYear={true}/></span>
                </Link>
            </div>
        )
    }

    archivedRunsRender () {
        let archivedRuns = []
        for (let i = 0; i < this.state.archivedRuns.length; i++) {
            const archivedRun = this.state.archivedRuns[i]
            if (archivedRun !== undefined && archivedRun !== null) {
                archivedRuns.push(this.archivedRunRender(archivedRun))
            }
        }
        if (this.state.cursor !== undefined) {
            archivedRuns.push(<div key="load-more"><button className='btn-cancel' onClick={this.archivedRuns}>Load more</button></div>)
        }
        return archivedRuns
    }

    render () {
        if (this.props.view !== "results") {
            return <span></span>
        }
        return (
            <div className="neutral7 font14">
                {this.archivedRunsRender()}
            </div>
        )        
    }
};

export default RunArchiveSelect