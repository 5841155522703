/*global */
import React, { Component } from 'react';

class Notice extends Component {
    constructor () {
        super()

    }

    render () {
        let backgroundClass
        let borderClass
        let iconSource
        const standardClass = "m-3 p-3 solidborder radius16border"

        switch (this.props.type) {
            case "information":
                // something
                backgroundClass = ""
                borderClass = "primary4border"
                iconSource = "/img/notice-info-icon.svg"
                break
            case "error":
                backgroundClass = ""
                borderClass = "accenta4border"
                iconSource = "/img/notice-error-icon.svg"
                break
            case "warning":
                backgroundClass = ""
                borderClass = "accentb4border"
                iconSource = "/img/notice-warning-icon.svg"
                break
            case "success":
                backgroundClass = ""
                borderClass = "accentc4border"
                iconSource = "/img/notice-success-icon.svg"
                break
            case "general":
                backgroundClass = "neutral8bg"
                borderClass = "neutral8border"
                iconSource = "/img/notice-general-icon.svg"
                break
            case "general-without-icon":
                backgroundClass = "neutral8bg"
                borderClass = "neutral8border"
                break
            case "feedback":
                backgroundClass = "whitebg"
                borderClass = "whiteborder"
                break
            default: 
                // something
        }

        if (iconSource === undefined) {
            return ( 
                <div className={standardClass + " " + backgroundClass + " " + borderClass}>
                    {this.props.content}
                </div>
            );
        } else {
            return ( 
                <div className={standardClass + " " + backgroundClass + " " + borderClass} style={{"display":"flex"}}>
                    <div style={{"width":"40px"}}>
                        <img src={iconSource} width="32" height="32" alt="note"/>
                    </div>
                    <div style={{"flex":"1"}}>
                        {this.props.content}
                    </div>
                </div>
            );
        }
    }
};

export default Notice;