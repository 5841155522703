/*global */
import React, { Component } from 'react';

class MultiTextListInput extends Component {
    constructor () {
        super();
        this.state = {list: [""]};
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.save = this.save.bind(this);
        this.standardInput = this.standardInput.bind(this);
        this.textChange = this.textChange.bind(this);
    }

    componentDidMount () {
        if (this.props.list !== undefined) {
            this.setState({list: this.props.list});
        }
    }

    add () {
        let list = this.state.list;
        list.push("");
        this.setState({list: list});
    }

    remove (index) {
        let list = this.state.list;
        list.splice(index, 1);
        this.setState({list: list});
    }

    save () {
        this.props.sync(this.state.list);
    }

    textChange (e, index) {
        let list = this.state.list;
        let listInput = list[index];
        listInput = e.target.value;
        list[index] = listInput;
        this.setState({list: list}, () => this.save());
    }

    standardInput (index) {
        return (
            <div className="mb-3">
                <input className="tr-input" value={this.state.list[index]} onChange={(e) => {this.textChange(e, index)}}/>
            </div>
        )
    }

    render () {
        let inputs = [];
        for (let i = 0; i < this.state.list.length; i++) {
            inputs.push(this.standardInput(i));
        }
        return (
            <div>
                {inputs}
                <button className="btn-cancel" onClick={this.add}>Add more</button>
            </div>
        )
    }
};

export default MultiTextListInput;