/*global*/
import React, { Component } from 'react';

class SideToggle extends Component {
    render () {
        if (this.props.sideOverlay) {
            return <span></span>
        }
        let style = {"position":"relative", "top": "20px", "left":"-12px", "zIndex":"3"}
        if (!this.props.side || this.props.sideClosePreference) {
            style = {"position":"relative", "top": "20px", "left":"-28px", "zIndex":"3"}
        }
        return (
            <div style={style}>
                <button className='btn-grow circle24-no-border' type="button" onClick={this.props.sideToggle}>
                    {
                        (this.props.side && this.props.sideClosePreference !== true) ?
                        <img alt="toggle" style={{"position":"relative","left":"-6px", "top":"-1px"}} src="/img/side-close.svg" width="24" height="24"/>
                        :
                        <img alt="toggle" style={{"position":"relative","left":"-6px", "top":"-1px", "transform": "rotate(180deg)"}} src="/img/side-close.svg" width="24" height="24"/>
                    }
                    
                </button>
            </div>
        )
        /*if (this.props.open === true) {
            return (
                <div style={{"padding": "50px 20px 50px 20px"}}>
                    <div>
                        <button 
                            onClick={() => 
                                { 
                                    if (this.props.onClose !== undefined) {
                                        this.props.onClose();
                                    }
                                    this.props.side(undefined)
                                }
                            } 
                            className="btn-transparent btn-close btn-close-side-positioning"
                            >
                                &times;
                        </button>
                    </div>
                    <div>
                        {this.props.content}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }*/
    }
};

export default SideToggle