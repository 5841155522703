/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsIntegrations extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Integrations Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults integrations."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Integrations.</p>
                <h2>Slack</h2>
                <p>If your team uses both Slack and Tesults consider adding Tesults results notification messages to your Slack channels.</p>
                <p>Slack notification messages contain summary details of the latest test results and build status. The messages also contain links to detailed results pages, the supplemental view and overall project status.</p>
                <img src="/img/slack-image-edited.png" alt="Slack" className='width75'/>
                <p>If your team uses Slack throughout the day you can now be notified of new results within your channel and go directly to detailed Tesults pages by following the button links in the message.</p>
                <p>Click the 'Add to Slack' button to authorize Tesults to post messages to your Slack channels. You are able to configure which of your Slack channels you want to receive results notifications for.</p>
                <p>Tesults will post messages only when new results are generated and available.</p>
                <p><Link className="primary4" to="/docs/slack">More information about Slack integration</Link></p>
                <h2>PagerDuty</h2>
                <p>If your team uses both PagerDuty and Tesults consider adding Tesults as a source for triggering and resolving PagerDuty incidents.</p>
                <p>Setup each of your project targets to generate incidents for specific PagerDuty services when test failures occur.</p>
                <p>To get started, login to your PagerDuty account at <Link target="_blank" to="https://www.pagerduty.com">https://www.pagerduty.com</Link>. There are two options you can use to get setup.</p>
                <hr/>
                <h4>Option 1</h4>
                <p>Create PagerDuty integrations yourself within the PagerDuty interface and paste your integration key in the Tesults configuration menu.</p>
                <h4>Option 2</h4>
                <p>Have Tesults request authorization to create an integration on your behalf in your PagerDuty account.</p>
                <hr/>
                <img src="https://www.tesults.com/files/docs/pagerduty-configuration.png" alt="Pagerduty" className='width75'/>
                <p>Pagerduty incidents can be targetted to specific targets so that there is granular control over what processes and systems trigger and resolve incidents and which teams are notified.</p>
                <p><Link className="primary4" to="/docs/pagerduty">More information about PagerDuty integration</Link></p>
                <h2>Webhooks (MS Teams, Mattermost)</h2>
                <p>Configure webhooks to receive a message from Tesults everytime new results are generated for the project or a specific target. This is done by simply pasting a webhook URL into the input field provided in the configuration menu and selecting whether this should apply at the project or target scope.</p>
                <p>You can set exactly when you want notifications to be generated based on rules under the 'Notifications' configuration panel.</p>
                <img src="/img/mattermost-1.png" className="width75 mb-5" alt="Webhooks"/>
                <p><Link className="primary4" to="/docs/msteams">More information about MS Teams integration</Link></p>
                <p><Link className="primary4" to="/docs/mattermost">More information about Mattermost integration</Link></p>
                <h2>Jira</h2>
                <p>If your team uses both Jira and Tesults consider adding Jira integration to your Tesults project.</p>
                <p>Integration will enable you to create and update Jira issues with the click of a button from failing test cases in Tesults. Tesults can also automatically update Jira issue status based on new test results.</p>
                <p>Click the 'Integrate Jira' button to start the integration flow.</p>
                <p>Once configuration is complete, when you open up test case details you will be able to create a Jira issue for a test case with a single click of a button.</p>
                <img src="https://www.tesults.com/files/docs/jira-configuration.png" alt="Jira" className='width75'/>
                <p>You can set Jira issues to be automatically closed when a test case passes.</p>
                <p><Link className="primary4" to="/docs/jira">More information about Jira integration</Link></p>
            </div>
        );
    }
}

export default DocsIntegrations;