/*global */
import React, { Component } from 'react';
import DemoMain from './DemoMain'
import DemoSide from './DemoSide'

class Demo extends Component {
    render () {
        return (
            <div className="whitebg">
                <h5 className="mb-3 text-uppercase bold">Upload API Demo</h5>
                <hr style={{"marginLeft":"-20px", "marginRight":"-20px"}}/>
                <DemoMain/>
                <DemoSide/>
            </div>
        );
    }
};

export default Demo;