/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsTestNG extends Component {
    render() {
        const helmet = 
            <Helmet>
                <title>Tesults - TestNG Test Framework</title>
                <meta name="description" content="Learn how to use Tesults with the TestNG framework for effective test reporting."/>
            </Helmet>

        const introduction =
        <p>This a reference guide for setting up Tesults with your TestNG tests. If you are not using TestNG, view the <NavLink to="/docs/java" target="_blank" className="tr-link-primary4 no-break">Java</NavLink> docs for information about integrating with a lower level library.</p>

        const installation =
        <div>
            <p>The Tesults TestNG Listener extension makes setup easy. Add one dependecy to your project. The extension is available from the JCenter and Maven Central repositories.</p>
            <h4 className="mt-4">Gradle</h4>
            <span className="neutral4 font14">build.gradle</span>
            <br/>
            <pre className="docsCode">
            repositories {'{'}<br/>
            &nbsp;&nbsp;mavenCentral()<br/>
            &nbsp;&nbsp;jcenter()<br/>
            {'}'}<br/><br/>
            dependencies {'{'}<br/>
            &nbsp;&nbsp;implementation '<code className="accentb4">com.tesults.testng:tesults-testng:1.2.0</code>'<br/>
            {'}'}<br/>
            </pre>
            <h4 className="mt-3">Maven</h4>
            <span className="neutral4 font14">pom.xml</span>
            <br/>
            <pre className="docsCode">
            {'<'}dependency{'>'}<br/>
                &nbsp;&nbsp;{'<'}groupId{'>'}<code className="accentb4">com.tesults.testng</code>{'<'}/groupId{'>'}<br/>
                &nbsp;&nbsp;{'<'}artifactId{'>'}<code className="accentb4">tesults-testng</code>{'<'}/artifactId{'>'}<br/>
                &nbsp;&nbsp;{'<'}version{'>'}<code className="accentb4">1.2.0</code>{'<'}/version{'>'}<br/>
            {'<'}/dependency{'>'}<br/>
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Once the dependency above has been specified your results data will be pushed to Tesults if you run your TestNG tests. <b>By default the Tesults TestNG Listener will be disabled</b>. To enable the listener you must provide the tesultsTarget argument with your target token to upload results. There are also other arguments that you can provide to report more detailed information, outlined below.</p>
            <pre className="primary2 docsArgsCode pl-0">tesultsTarget<span className="neutral7 ml-5">Required</span></pre>
            <p>The tesultsTarget and all other configuration values must be provided as <b>JVM system properties</b>. How you provide JVM system properties depends on whether you are invoking your tests from the command line, an IDE, or using Gradle or Maven. Here are examples:</p>
            <h4 className="mt-3">Option 1: Command line</h4>
            <pre className="docsCode">
            java <code className="accentb4">-DtesultsTarget=token</code> -cp "jar-path/testng.jar:test-classes-path" org.testng.TestNG testng.xml
            </pre>
            <h4 className="mt-3">Option 2: Gradle</h4>
            <span className="neutral4"><i>build.gradle</i></span>
            <br/>
            <pre className="docsCode">
            test {'{'}<br/>
                &nbsp;&nbsp;useTestNG()<br/>
                &nbsp;&nbsp;<code className="accentb4">systemProperty "tesultsTarget", 'token'</code><br/>
            {'}'}
            </pre>
            <h4 className="mt-3">Option 3: Maven</h4>
            <p>Use the Maven Surefire plugin.</p>
            <span className="neutral4"><i>pom.xml</i></span>
            <br/>
            <pre className="docsCode">
            {'<build>'}<br/>
            &nbsp;&nbsp;{'<plugins>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'<plugin>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<groupId>org.apache.maven.plugins</groupId>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<artifactId>maven-surefire-plugin</artifactId>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<version>2.22.0</version>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<configuration>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<systemPropertyVariables>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">{'<tesultsTarget>token</tesultsTarget>'}</code><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</systemPropertyVariables>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</configuration>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'</plugin>'}<br/>
            &nbsp;&nbsp;{'</plugins>'}<br/>
            {'</build>'}
            </pre>
            <h4 className="mt-3">Option 4: IDE</h4>
            <p>You can also provide JVM system properties through IDE functionality. Eclipse and IntelliJ provide built in ways to specificy these properties.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">-tesultsConfig<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to provide a path to a configuration properties file</p>
            <pre className="docsCode pre-wrap-break-word"><code className="accentb4">-DtesultsConfig</code>=/full-path/tesults.properties</pre>
            <p>The properties file is useful to store several Tesults target tokens that can then be automatically be looked up by supplying an easy to remember target name. This allows you to supply only the tesultsTarget (with a label rather than the token itself) and tesultsConfig as JVM system properties. The properties file can contain all other args to avoid having to pass them as in as system properties args.</p>
            <pre className="docsCode pre-wrap"><code className="accentb4">-DtesultsTarget</code>=target1 <code className="accentb4">-DtesultsConfig</code>=/full-path/tesults.properties</pre>
            <p>In this case, the Tesults TestNG Listener will automatically look up the value of the token based on the property provided from a configuration file.</p>
            <p>Here is the corresponding tesults.properties file:</p>
            <pre className="docsCode pre-wrap">
            target1 = eyJ0eXAiOiJ...<br/>
            target2 = ...<br/>
            target3 = ...<br/>
            target4 = ...<br/>
            </pre>
            <p>Or something more descriptive about the targets:</p>
            <pre className="docsCode pre-wrap">
            web-qa-env = eyJ0eXAiOiJ...<br/>
            web-staging-env = ...<br/>
            web-prod-env = ...<br/><br/>
            ios-qa-env = eyJ0eXAiOiK...<br/>
            ios-staging-env = ...<br/>
            ios-prod-env = ...<br/><br/>
            android-qa-env = eyJ0eXAiOiL...<br/>
            android-staging-env = ...<br/>
            android-prod-env = ...<br/>
            </pre>
            <Box
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point Tesults TestNG Listener will push results data to Tesults when your tests run as long as the tesultsTarget system property is supplied to indicate which target to use.</p>}
            />
            <div className='mt-5'>
                <h2>Enhanced reporting</h2>
                <h3>Custom fields and values</h3>
                <p>Ensure you have version 1.1.0 + of the tesults-testng library installed and use the built in <b>custom</b> method to report custom properties.</p>
                <p>To do this, inject the Method parameter into your test and then call the custom method on TesultsListener passing in Method and the name of the custom field and the value.</p>
                <p>Example:</p>
                <pre className="docsCode">
                import com.tesults.testng.TesultsListener;<br/>
                import org.testng.annotations.*;<br/>
                import java.lang.reflect.Method;<br/>
                public class TestSuiteA {'{'}<br/>
                &nbsp;&nbsp;@Test<br/>
                &nbsp;&nbsp;public void Test1 (Method method) {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.custom(method, "Custom field name", "Custom field value")</code>;<br/>
                &nbsp;&nbsp;{'}'}<br/>
                {'}'}
                </pre>
                <p>If you already injecting another parameter, add Method as another parameter at the end:</p>
                <pre className="docsCode">
                @Test<br/>
                public void Test1 (ITestContext context, Method method) {'{'}<br/>
                {'}'}
                </pre>
                <h3>Steps</h3>
                <p>Ensure you have version 1.2.0 + of the tesults-testng library installed and use the built in <b>step</b> method to report custom properties.</p>
                <p>To do this, inject the Method parameter into your test and then call the step method on TesultsListener passing in Method and a step.</p>
                <p>Example:</p>
                <pre className="docsCode">
                import com.tesults.testng.TesultsListener;<br/>
                import org.testng.annotations.*;<br/>
                import java.lang.reflect.Method;<br/>
                public class TestSuiteA {'{'}<br/>
                &nbsp;&nbsp;@Test<br/>
                &nbsp;&nbsp;public void Test1 (Method method) {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;Map{'<'}String, Object{'>'} step = new HashMap{'<'}String, Object{'>'}();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step.put("name", "Step 1");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step.put("desc", "Step 1 description");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step.put("result", "pass");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.step(method, step);</code><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;Map{'<'}String, Object{'>'} step2 = new HashMap{'<'}String, Object{'>'}();<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step2.put("name", "Step 2");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step2.put("desc", "Step 2 description");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step2.put("result", "fail");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;step2.put("reason", "Failure in test due to...");<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.step(method, step2);</code><br/>
                &nbsp;&nbsp;{'}'}<br/>
                {'}'}
                </pre>
                <p>If you already injecting another parameter, add Method as another parameter at the end:</p>
                <pre className="docsCode">
                @Test<br/>
                public void Test1 (ITestContext context, Method method) {'{'}<br/>
                {'}'}
                </pre>
            </div>
        </div>

        const files = 
        <div>
            <div>
                There are two ways to include files generated by tests.
            </div>
            <h4 className="accentc4 bold">Recommended</h4>
            <div>
            <p>The recommended approach is to ensure you have version 1.1.0 + of the tesults-testng library installed and then use the built in <b>file</b> method.</p>
            <p>To do this, inject the Method parameter into your test and then call the file method on TesultsListener passing in Method and the full path to the file.</p>
            <p>Example:</p>
            <pre className="docsCode">
            import com.tesults.testng.TesultsListener;<br/>
            import org.testng.annotations.*;<br/>
            import java.lang.reflect.Method;<br/>
            public class TestSuiteA {'{'}<br/>
            &nbsp;&nbsp;@Test<br/>
            &nbsp;&nbsp;public void Test1 (Method method) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.file(method, "/full/path/to/file/log.txt")</code>;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.file(method, "/full/path/to/file/screenshot.png")</code>;<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
            </pre>
            <p>If you already injecting another parameter, add Method as another parameter at the end:</p>
            <pre className="docsCode">
            @Test<br/>
            public void Test1 (ITestContext context, Method method) {'{'}<br/>
            {'}'}
            </pre>
            </div>
            <h4 className="accenta4 bold">Not Recommended</h4>
            <p>This second method is no longer recommended but can be used if desired.</p>
            <pre className="primary2 docsArgsCode pl-0">tesultsFiles<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsFiles=/Users/admin/Desktop/temporary</pre>
            <p>This is one area where the Tesults TestNG Listener is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>The default behavior of the Tesults TestNG Listener is to set the class name as the test suite. Also be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p>You can utilize a function such as this one to create a file at a path that meets this requirement:</p>
            <pre className="docsCode">
            public File createFile (String suite, String test, String file) {'{'}<br/>
            &nbsp;&nbsp;String temp = "/Users/admin/Desktop/temp"; // tesultsFiles arg value<br/>
            &nbsp;&nbsp;String path = temp + File.separator + suite;<br/>
            &nbsp;&nbsp;path = path + File.separator + test;<br/>
            &nbsp;&nbsp;path = path + File.separator + file;<br/>
            &nbsp;&nbsp;File f = new File(path);<br/>
            &nbsp;&nbsp;File dir = f.getParentFile();<br/>
            &nbsp;&nbsp;if (dir != null) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;dir.mkdirs();<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;try {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;f.createNewFile();<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return f;<br/>
            &nbsp;&nbsp;{'}'} catch (IOException ex) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("saveFile error: " + ex.getMessage());<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return null;<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildName=buildname<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildName=1.0.0</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildResult=result<span className="neutral7 ml-5">Optional</span></pre>            
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildResult=pass</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildDesc=description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildDesc=added new feature</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildReason=reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildReason=build error line 201 somefile.py</pre>
            <h4 className="mt-5">No Test Suites</h4>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsNoSuites<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this flag to stop automatic setting of the class name as the test suite.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsNoSuites</pre>
            <h4 className="mt-5">How TestNG test cases are reported in Tesults</h4>
        </div>

        const example = 
        <div>
            <p>This example TestNG test file includes one class with three test functions. Tesults would report the suite as <b>TestSuiteA</b>, the test cases would be reported as the names of the functions, <b>test1</b>, <b>test2</b> and <b>test3</b>. The last test is parameterized and Tesults would report two separate test cases for this, it would pick up the test file for test1 along with the descriptions for test 1 and 3. All tests would be reported as passes except for test2 which would be a fail and the reason for the failure would include the assert message.</p>
            <span className="neutral4"><i>TestNG Tests Example: TestSuiteA.java</i></span>
            <br/>
            <pre className="docsCode">
            import java.io.File;<br/>
            import java.io.IOException;<br/>
            import org.testng.Assert;<br/>
            import org.testng.annotations.*;<br/><br/>

            public class TestSuiteA {'{'}<br/>
            &nbsp;&nbsp;@Test (description = "test1 description")<br/>
            &nbsp;&nbsp;public void test1() {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;createFile("TestSuiteA", "test1", "hey.log");<br/>
            &nbsp;&nbsp;{'}'}<br/><br/>

            &nbsp;&nbsp;@Test<br/>
            &nbsp;&nbsp;public void test2() {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Assert.fail("This message will appear as the test failure reason.");<br/>
            &nbsp;&nbsp;{'}'}<br/><br/>

            &nbsp;&nbsp;@DataProvider(name = "values")<br/>
            &nbsp;&nbsp;public Object[][] createData() {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return new Object[][] {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'} "Summer", "Hot" {'}'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'} "Winter", "Cold"{'}'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'};<br/>
            &nbsp;&nbsp;{'}'}<br/><br/>

            &nbsp;&nbsp;@Test (dataProvider = "values", description = "test3 description")<br/>
            &nbsp;&nbsp;public void test3(String season, String feels) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("the season is " + season);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;System.out.print("feels: " + feels);<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}<br/>
            </pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                build={build}
                example={example}
                integration="testng"
            />
        );
    }
}

export default DocsTestNG;