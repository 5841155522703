import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import Duration from './Duration';

class Time extends Component {
    render () {
        let interval = <span></span>
        let duration = <span></span>
        let gap = <span></span>
        let run = <span></span>
        let c = {duration: this.props.duration, start: this.props.intervalStart, end: this.props.intervalEnd};
        let derivedDuration = undefined;
        if (this.props.run !== undefined) {
            run = 
            <div className="case-time-run">
                <img src="/img/time.svg" alt="" style={{"verticalAlign":"middle"}} className="mr-2"  width="16" height="16"/><span className="font14">{TimeFormatted.fromNowRun(this.props.run)} <img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"-4px"}} width="40" height="20" preserveAspectRatio="none"/></span>
            </div>
        }
        if (this.props.duration !== undefined) {
            duration = 
            <div className="case-time-duration">
                <span><img src="/img/duration.svg" alt="" style={{"verticalAlign":"middle"}} className="mr-2" width="16" height="16"/> <Duration durationOnly={true} c={c}/></span>
            </div>
        }
        if (this.props.intervalStart !== undefined || this.props.intervalEnd !== undefined) {
            if (this.props.intervalEnd === undefined) {
                interval = 
                <div className="case-time-interval">
                    <span><img src="/img/time.svg" alt="" style={{"verticalAlign":"middle"}} className="mr-2"  width="16" height="16"/> {TimeFormatted.timeformatted(this.props.intervalStart)}</span>
                </div>
            } else if (this.props.intervalStart === undefined) {
                interval = 
                <div className="case-time-interval">
                    <span><img src="/img/time.svg" alt="" style={{"verticalAlign":"middle"}} className="mr-2"  width="16" height="16"/> {TimeFormatted.timeformatted(this.props.intervalEnd)}</span>
                </div>
            } else {
                interval = 
                <div className="case-time-interval">
                    <span><img src="/img/time.svg" alt="" style={{"verticalAlign":"middle"}} className="mr-2"  width="16" height="16"/> {TimeFormatted.timeformatted(this.props.intervalStart)} - {TimeFormatted.timeformatted(this.props.intervalEnd)}</span>
                </div>
                if (this.props.duration === undefined) {
                    derivedDuration = this.props.intervalEnd - this.props.intervalStart;
                    let c = {duration: derivedDuration, start: this.props.intervalStart, end: this.props.intervalEnd};
                    duration = 
                    <div className="case-time-duration">
                        <span><img src="/img/duration.svg" alt="" style={{"verticalAlign":"middle"}} className="mr-2" width="16" height="16"/> <Duration durationOnly={true} c={c}/></span>
                    </div>
                }
            }
        }
        if ((this.props.duration !== undefined || derivedDuration !== undefined) && this.props.interval !== undefined) {
            gap = 
            <div className="case-time-gap">
                &nbsp;
            </div>
        }
        let content = 
        <div className="case-time">
            {duration}
            {gap}
            {interval}
            {run}
        </div>
        /*if (this.props.context === "diff") {
            content = 
            <div>
                {duration}
                {gap}
                {interval}
                {run}
            </div>
        }*/
        return content     
    }
}

export default Time;