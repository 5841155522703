/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsGithubActions extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Github Actions</title>
                    <meta name="description" content="Learn about Github Actions and Tesults"/>
                </Helmet>
                <p>Running tests and reporting results within a continuous integration (CI) is a common task. If you use Github Actions and your workflow includes a job with a step that runs your tests, you may need to check the result to determine whether the pipeline is a success or a failure.</p>
                <p>Some test frameworks automatically fail the process if the tests do not all pass. Others do not. For the latter case Tesults provides the <b>Tesults API results check</b> Action in the Github Actions Marketplace to check results and usage is demonstrated below. <a target="_blank" className='tr-link-primary4 no-break' href="https://github.com/marketplace/actions/tesults-api-results-check">https://github.com/marketplace/actions/tesults-api-results-check</a> </p>
                <p>In the case of the Playwright test framework for example the step will report results to Tesults and then Playwright will automatically fail the job if all test cases do not pass. In this case you do not need to do anything else.
                </p>
                <pre className='docsCode'>
                name: Playwright Tests<br/>
                on:<br/>
                push:<br/>
                &nbsp;&nbsp;branches: [ main, master ]<br/>
                pull_request:<br/>
                &nbsp;&nbsp;branches: [ main, master ]<br/>
                jobs:<br/>
                test:<br/>
                &nbsp;&nbsp;timeout-minutes: 60<br/>
                &nbsp;&nbsp;runs-on: ubuntu-latest<br/>
                &nbsp;&nbsp;steps:<br/>
                &nbsp;&nbsp;- uses: actions/checkout@v3<br/>
                &nbsp;&nbsp;- uses: actions/setup-node@v3<br/>
                &nbsp;&nbsp;with:<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;node-version: 18<br/>
                &nbsp;&nbsp;- name: Install dependencies<br/>
                &nbsp;&nbsp;run: npm ci<br/>
                &nbsp;&nbsp;- name: Install Playwright Browsers<br/>
                &nbsp;&nbsp;run: npx playwright install --with-deps<br/>
                &nbsp;&nbsp;- name: Run Playwright tests<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;env:<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TARGET: {'${{ secrets.TARGET }}'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BUILD: {"${{ format('{0} {1}', 'Build ', github.run_number) }}"}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;run: npx playwright test<br/>
                </pre>
                <p>In the case above this assumes the playwright.config.js file contains reporter parameters like this:</p>
                <pre className='docsCode'>
                    reporter: [['playwright-tesults-reporter',<br/>
                    {"{'tesults-target': process.env.TARGET"},<br/>
                    {"'tesults-build-name': process.env.BUILD,"}<br/>
                    {"'tesults-build-result': 'pass'}"}]],
                </pre>
                <p>This all works well for the Playwright test framework because it handles returning the appropriate process code for failing the job.</p>
                <p>In the case where the test framework does not automatically fail the job, after tests have run and results data has been uploaded to Tesults, you would want to check results via the Tesults API, and then determine whether to fail the job or not.</p>
                <p>To make this easy for Github Actions Tesults has made the <b>Tesults API results check</b> Action available in the Github Actions Marketplace: 
                    <a target="_blank" className='tr-link-primary4 no-break' href="https://github.com/marketplace/actions/tesults-api-results-check">https://github.com/marketplace/actions/tesults-api-results-check</a>
                </p>
                <p>Using this action is demonstrated below.</p>
                <pre className='docsCode'>
                - name: Check<br/>
                id: check<br/>
                uses: tesults/tesults-api-results-check@1.0.6<br/>
                with:<br/>
                &nbsp;&nbsp;target: {"${{ secrets.TARGET }}"}<br/>
                &nbsp;&nbsp;build:{" ${{ format('{0} {1}', 'Build ', github.run_number) }}"}<br/>
                &nbsp;&nbsp;api_token: {"${{ secrets.API_TOKEN }}"}<br/>
                </pre>
                <p>The target, build and api_tokens are all required.</p>
                <ol>
                    <li><b>target</b> - the id of the target (available from the Tesults configuration menu (https://www.tesults.com/config) and Tesults API)</li>
                    <li><b>build</b> - the build name, this is self generated and should be unique for the test run, we recommend simply using github.run_number</li>
                    <li><b>api_token</b> - the api token for the Tesults project, generated from the Tesults configuration menu (https://www.tesults.com/config)</li>
                </ol>
                <p>This action will automatically fail the job if the pass rate is not 100%, however if you need the number of passes and total for any reason, the action outputs these values and they can be examined.</p>
                <pre className='docsCode'>
                    # Use the output from the `Check` step<br/>
                    - name: Get the number of passes and total<br/>
                    run: echo "{"${{ steps.check.outputs.pass }} pass out of ${{ steps.check.outputs.total }} total"}"<br/>
                </pre>
                <p>View the output in the Actions tab on Github.</p>
                <img src="/img/github-actions-doc-pic.png" className='width50' alt="github-actions-output"/>
            </div>
        );
    }
}

export default DocsGithubActions