/*global*/
import Request from './Request';

const Analytics = {
    event: (function (event, props) {
        if (process.env.NODE_ENV === "production") {
            if (props === undefined) {
                props = {};
            }
            props["$referring_domain"] = document.referrer;
            props["$browser"] = navigator.userAgent;
            props["$screen_width"] = window.screen.width;
            props["$screen_height"] = window.screen.height;
            Request.post("/analytics", {event: event, props: props});
        } else {
            return;
        }
    })
}

export default Analytics;