/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Slack from './Slack'
import PagerDuty from './PagerDuty'
import Webhooks from './Webhooks'
import Jira from './Jira'
import Request from './Request'

class ConfigIntegrations extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {state: null, project: undefined, targets: undefined}
        this.slack = this.slack.bind(this)
        this.pagerduty = this.pagerduty.bind(this)
        this.jira = this.jira.bind(this)
        this.removeSlack = this.removeSlack.bind(this)
        this.webhooks = this.webhooks.bind(this)
        this.cancel = this.cancel.bind(this)
        this.project = this.project.bind(this)
        this.targets = this.targets.bind(this)
    }

    componentDidMount () {
        this.targets()
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    targets () {
        window.scrollTo(0,0);
        let project = this.project()
        if (project === undefined) { return }
        this.setState({loading:true});
        Cache.request(this.context, Cache.data.targets, {id: project.id }, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({targets: targets, loading: false}, () => {
                    if (this.props.location !== undefined) {
                        if (this.props.location.pathname.startsWith("/config/project/slack-dev")) {
                            this.slack(true)
                        } else if (this.props.location.pathname.startsWith("/config/project/slack")) {
                            this.slack()
                        } else if (this.props.location.pathname.startsWith("/config/project/pagerduty")) {
                            this.pagerduty()
                        } else if (this.props.location.pathname.startsWith("/config/project/webhooks")) {
                            this.webhooks()
                        } else if (this.props.location.pathname.startsWith("/config/project/jira")) {
                            this.jira()
                        }
                    }
                });
            }
        });
    }

    cancel () {
        this.setState({state: null})
    }

    slack(dev) {
        if (dev === true) {
            Analytics.event("SlackDev");
            this.props.history.replace({ pathname: "/config/project/slack-dev"});
            this.setState({state: "slack", confirmation: {success: undefined, failure: undefined}, targets: this.state.targets});
        } else {
            Analytics.event("Slack");
            this.props.history.replace({ pathname: "/config/project/slack"});
            this.setState({state: "slack", confirmation: {success: undefined, failure: undefined}, targets: this.state.targets});
        }
    }

    pagerduty () {
        Analytics.event("PagerDuty");    
        this.props.history.replace({ pathname: "/config/project/pagerduty"});
        this.setState({state: "pagerduty", confirmation: {success: undefined, failure: undefined}, targets: this.state.targets});
    }

    jira () {
        Analytics.event("Jira");
        this.props.history.replace({ pathname: "/config/project/jira"});
        this.setState({state: "jira", confirmation: {success: undefined, failure: undefined}, targets: this.state.targets});
    }

    webhooks () {
        Analytics.event("Webhooks");
        this.props.history.replace({ pathname: "/config/project/webhooks"});
        this.setState({state: "webhooks"});
    }

    removeSlack() {
        Analytics.event("SlackRemove");
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        Request.post("/slackremove", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to remove Slack workspace"}});
            } else {
                this.setState({state: "configureProject", confirmation: {success: undefined, failure: undefined}});
            }
        });
    }

    render () {
        let project = this.project()
        if (this.state.state === "slack") {
            return <Slack dev={(this.props.option === "slack-dev") ? true : false} project={project} targets={this.state.targets} removeSlack={this.removeSlack} back={this.cancel} error={this.state.error} code={this.props.code}/>
        } else if (this.state.state === "pagerduty") {
            return <PagerDuty project={project} targets={this.state.targets} back={this.cancel} error={this.state.error} code={this.props.code}/>
        } else if (this.state.state === "webhooks") {
            return <Webhooks project={project} targets={this.state.targets} back={this.cancel} error={this.state.error}/>
        } else if (this.state.state === "jira") {
            return <Jira project={project} targets={this.state.targets} back={this.cancel} error={this.state.error} code={this.props.code}/>
        } else {
            return (
                <div>
                    <div className="tr-link-dark" onClick={this.slack}>Slack</div>
                    <div className="tr-link-dark" onClick={this.pagerduty}>PagerDuty</div>
                    <div className="tr-link-dark no-break" onClick={this.webhooks}>Webhooks (MS Teams, Mattermost)</div>
                    <div className="tr-link-dark" onClick={this.jira}>Jira</div>
                </div>
            );
        }
    }
};

export default ConfigIntegrations