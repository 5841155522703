/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';
import Cache from './Cache'
import Context from './Context'
import Constants from './Constants'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class ConfigOrgName extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {edit: false, name: "", confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.edit = this.edit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.cancel = this.cancel.bind(this)
        this.save = this.save.bind(this)
        
    }

    componentDidMount () {
        if (this.state.name === "") {
            this.init()
        }
    }

    init () {
        if (this.context !== undefined) {
            if (this.context.projects !== undefined) {
                if (Cache.preference.projectIndex !== undefined) {
                    let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
                    if (project !== undefined) {
                        this.setState({project: project, loading: true});
                        Request.get("/org", {org: project.org, loading: false}, (err, data) => {
                            if (err) {
                                this.setState({org: {}, name: "-", error: "Unable to fetch organization", loading: false});
                            } else {
                                this.setState({org: data.org, name: data.org.name, loading: false});
                            }
                        });               
                    }
                }
            }
        }
    }

    edit () {
        this.setState({edit: true})
    }

    onChange (e) {
        this.setState({name: e.target.value,  confirmation: {success: undefined, failure: undefined},})
    }

    cancel () {
        let name = ""
        try {
            name = this.state.org.name
        } catch (err) {
            name = ""
        }
        this.setState({edit: false, name: name,  confirmation: {success: undefined, failure: undefined},})
    }

    save () {
        this.setState({loading: true});
        Request.post("/orgName", {org: this.state.project.org, name: this.state.name, project: this.state.project.id}, (err, data) => {
            if (err) {
                this.setState({org: {}, error: "Unable to change organization name", loading: false});
            } else {
                this.setState({org: data.org, error: data.err, confirmation: {success: "Updated name", failure: undefined}, loading: false});
            }
        });
    }
    
    render () {
        return (
            <div>
                <div className="mb-1">
                    <label className="neutral4 font14">Organization name</label>
                </div>
                <div className='inputButtons'>
                    <div className="mb-4 flex-1">
                        {
                            this.state.edit ?
                            <input type="text" className="tr-input" value={this.state.name} onChange={this.onChange}/>
                            :
                            <input type="text" className="tr-input" value={this.state.name} onChange={this.onChange} disabled/>
                        }
                        
                    </div>
                    <div className='flex-1'>
                    {
                        this.state.edit ?
                        <ConfirmationButtons 
                            confirm={this.save}
                            cancel={this.cancel}
                            confirmLabel="Update"
                            cancelLabel="Cancel"
                        />
                        :
                        <button className='btn-cancel' onClick={this.edit}>Edit</button>
                    }
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigOrgName