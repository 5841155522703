/*global Cookies*/
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Constants from './Constants';
import Loading from './Loading';
import Analytics from './Analytics';
import Request from './Request';

class InviteRegisterMain extends Component {
    constructor () {
        Analytics.event("InviteRegister");
        super();
        const user = Cookies.getJSON("truser");
        let state = "loggedIn";
        if (user === undefined || user == null) {
            state = null;
        }
        this.state = {state: state, firstName: "", lastName: "", password : "", error: "", submitting: false};
        this.firstNameChange = this.firstNameChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.register = this.register.bind(this);
    }

    firstNameChange (e) {
        this.setState({firstName: e.target.value});
    }

    lastNameChange (e) {
        this.setState({lastName: e.target.value});
    }

    passwordChange (e) {
        this.setState({password: e.target.value});
    }

    results () {
        window.location.href = Constants.baseUrl + "/results";
    }

    register () {
        this.setState({submitting: true});
        // validate here
        // nothing
        Request.post("/inviteregister", {firstName: this.state.firstName, lastName: this.state.lastName, password: this.state.password, ic: this.props.ic}, (err, data) => {
            if (err) {
                this.setState({error: err, submitting: false});
            } else {
                Analytics.event("InviteRegister");
                this.setState({state: "complete", submitting: false});
            }
        });
    }

    render () {
        if (this.state.state === "loggedIn") {
            return (
                <div style={{"maxWidth":"600px", "marginLeft":"auto", "marginRight":"auto"}} className="standardborder whitebg mt-5 p-5">
                    <p>You are logged in. View <NavLink to="/results" className="neutral4Link">results</NavLink>.</p>
                </div>  
            );
        } else if (this.state.state === "complete") {
            return (
                <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                    <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                        <div style={{"flex":"1"}}>
                            <h1 className="mb-5" style={{"fontWeight":"800"}}>Invited Sign Up</h1>
                        </div>
                        <div style={{"flex":"1"}}>
                            <p>Welcome! View <NavLink to="/results" className="neutral4Link">results</NavLink>.</p>
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.submitting === true) {
            return <Loading/>
        } else {
            return (
                <div>
                    <form id="register" className="text-left" onSubmit={this.register}>
                        <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                            <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                <div style={{"flex":"1"}}>
                                    <h1 className="mb-5" style={{"fontWeight":"800"}}>Invited Sign Up</h1>
                                </div>
                                <div style={{"flex":"1", "display":"flex"}}>
                                    <div style={{"flex":"1"}} className="mb-3">
                                        <label className="neutral4 font14 mb-0" htmlFor="firstName">First name</label>
                                        <br/>
                                        <input type="text" className="tr-input-width-100 neutral8bg" id="firstName" name="firstName" onChange={this.firstNameChange} required/>
                                    </div>
                            
                                    <div style={{"width":"40px"}}>&nbsp;</div>

                                    <div style={{"flex":"1"}} className="mb-3">
                                        <label className="neutral4 font14 mb-0" htmlFor="lastName">Last name</label>
                                        <br/>
                                        <input type="text" className="tr-input-width-100 neutral8bg" id="lastName" name="lastName" onChange={this.lastNameChange} required/>
                                    </div>
                                </div>
                                <div style={{"flex":"1"}}>
                                    <div className="mb-3">
                                        <label className="neutral4 font14 mb-0" htmlFor="password">Create password</label>
                                        <br/>
                                        <input type="password" placeholder='8+ characters and at least one number' className="tr-input-width-100 neutral8bg" id="password" name="password" onChange={this.passwordChange} required/>
                                    </div>
                                </div>
                                <div style={{"flex":"1"}}>
                                    <div className="mb-3">
                                        <p className="accenta1" id="error"><small>{this.state.error.toString()}</small></p>
                                        <button type="submit" className="btn-confirm-index" disabled={this.state.submitting}>Get started</button>
                                        <p className="mt-2 neutral4"><small>No time limit on free trial</small></p>
                                        <p className="mt-5 neutral4"><small>By continuing, you agree to the <NavLink to="/docs/terms" target="_blank" className="neutral4 no-break">Tesults terms of service</NavLink>.</small></p>
                                        <p className="mt-3 font14"><span>Already a member?</span> <Link className="tr-link-primary4" to="/login">Sign in</Link></p>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </form>
                </div>  
            ); 
        }
    }  
}

export default InviteRegisterMain;