/*global*/
import React, { Component } from 'react';
import Request from './Request';

class BlogSide extends Component {
    constructor() {
        super();
        this.state = {posts: [], cursor: undefined};
        this.getPosts = this.getPosts.bind(this);
    }

    componentDidMount() {
        this.getPosts(undefined, 11);
    }

    getPosts (cursor, limit) {
        Request.get("/blogposts", {cursor: cursor, limit: limit}, (err, data) => {
            if (err) {
                // do nothing
            } else {
                this.setState({posts: this.state.posts.concat(data.posts), cursor: JSON.stringify(data.lek)});
            }
        });
    }

    render () {
        if (this.state.posts.length === 0) {
            return <div>No posts</div>
        } else {
            let posts = [];
            for (let i = 0; i < this.state.posts.length; i++) {
                let post = this.state.posts[i];
                posts[i] = <a key={i} href={"/blog/" + post.postId} className="tr-link-blog">
                                <div className='flex-row'  style={{"alignItems":"center"}}>
                                    <div className='mr-5'><img src={"https://www.tesults.com/blogfiles" + post.titleImage} alt={post.titleImage} width="128"/></div>
                                    <div>{post.title}</div>
                                </div>
                            </a>
            }
            let content = [];
            for (let i = 0; i < posts.length; i++) {
                content.push(
                    <div key={i} class="mb-4"> 
                        {posts[i]}
                    </div>
                )
            }

            let loadMore = <span></span>
            if (this.state.cursor !== undefined) {
                loadMore = <button className="btn-cancel" onClick={() => this.getPosts(this.state.cursor, 11)}>Load more</button>
            }

            return (
                <div style={{"display":"flex", "flex-direction":"column"}}>
                    <div style={{"paddingTop":"60px"}}>
                        <a href="/" className="tr-link-neutral1">
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                {/*<div style={{"marginTop":"4px"}}>
                                    <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                                </div>*/}
                                <div className="neutral2">
                                    &nbsp;<span className="bolder font16 primary4">&larr; Exit Blog</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <h4 className="mb-3 neutral4" style={{"paddingTop":"21px"}}>Latest Posts</h4>
                    {content}
                    <div class="mt-4">
                        {loadMore}
                    </div>
                </div>
            );
        }
    }
}

export default BlogSide;