/*global */
import React, { Component } from 'react';
import './Loading.css';

class Loading extends Component {
    render() {
        return (
                <div className="loadingCenter">
                    <img className="loading1" src="/img/testCase-fail.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading2" src="/img/testCase-pass.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading3" src="/img/testCase-fail.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading4" src="/img/testCase-pass.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading5" src="/img/testCase-fail.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading6" src="/img/testCase-pass.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading7" src="/img/testCase-fail.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading8" src="/img/testCase-pass.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading9" src="/img/testCase-fail.svg" alt="" width="20" height="20"/>
                    &nbsp;
                    <img className="loading10" src="/img/testCase-pass.svg" alt="" width="20" height="20"/>
                </div>
        );
    }
}

export default Loading;