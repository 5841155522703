/*global*/
import React, { Component } from 'react';

class MessageOverlay extends Component {
    render () {
        if (this.props.display === false) {
            return <span></span>
        }
        let divClass = "message-overlay";
        if (this.props.full !== true) {
            divClass = "message-overlay-min-menu";
        }
        if (this.props.mobileMenu === true) {
            divClass = "message-overlay-mobile-menu";
        }
        if (this.props.type === "results-update") {
            return (
                <div className={divClass}>
                    <div style={{"display":"flex", "alignItems":"center", "height":"100%"}} className="pl-5 pr-5">
                        <div style={{"flex":"2"}}>
                            <img style={{"marginTop":"4px"}} src="/img/refresh-white.svg" alt="" width="20" height="20"/>
                        </div>
                        <div style={{"flex":"8"}}>
                            <span className="white">New Results Available</span>
                        </div>
                        <div style={{"flex":"1"}}>
                            
                        </div>
                        <div style={{"flex":"2"}}>
                            <button className="btn-confirm font14" onClick={this.props.action}>Refresh</button>
                        </div>
                        <div style={{"flex":"2"}}>
                            <button className="btn-transparent font14 neutral7" onClick={this.props.dismiss}>Dismiss</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <span></span>
        }
    }
}

export default MessageOverlay;