/*global */
import React, { Component } from 'react';
import SelectOverlay from './SelectOverlay';
import CaseGroup from './CaseGroup';

class CaseEnhancedAnalysis extends Component {
    constructor () {
        super();
        this.state = {
            periodOptions: [],
            periodIndex: 0,
            caseData: undefined,
            manifestVersion: undefined,
            caseBatchVersion: undefined
        }
        this.init = this.init.bind(this);
        this.periodChange = this.periodChange.bind(this);
        this.renderPeriodResults = this.renderPeriodResults.bind(this);
        this.renderFailuresInPeriod = this.renderFailuresInPeriod.bind(this);
    }

    componentDidMount () {
        this.init();
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.props.data !== prevProps.data) {
    //         this.init();
    //     }
    // }

    init () {
        let days = 0;

        if (this.props.project) {
            if(this.props.project.plan.name === "free-v1") {
                return;
            }
            days = this.props.project.plan.history / (1000 * 60 * 60 * 24);
        } else if (this.props.projects && this.props.projectIndex !== undefined && this.props.projectIndex < this.props.projects.length) {
            const project = this.props.projects[this.props.projectIndex];
            if (project.plan.name === "free-v1") {
                return;
            }
            days = project.plan.history / (1000 * 60 * 60 * 24);
        }

        if (this.props.data && this.props.type) {
            const periodOptions = [];
            const caseManifest = this.props.data["caseManifest"];
            if (caseManifest) {
                if (this.props.type === "case-detail" && this.props.selectedCase) {
                    if (caseManifest.hashes[this.props.selectedCase.hash]) {
                        const batch = caseManifest.hashes[this.props.selectedCase.hash].batch;
                        const caseBatch = this.props.data["caseBatch" + batch];
                        if (caseBatch) {
                            const caseData = caseBatch.cases[this.props.selectedCase.hash];
                            if (caseData) {
                                for (let i = 0; i < caseData.results.length; i++) {
                                    periodOptions.push({label: (i === 0 ? "Aggregated results for today (or the latest day results received)" : "Aggregated results over last " + (i + 1) + " days results received"), value: i});
                                }
                                this.setState({periodOptions: periodOptions, caseData: caseData, caseBatchVersion: caseBatch.version, periodIndex: Math.min(7, caseData.results.length - 1)});
                            }
                        }
                    }
                }
                if (this.props.type === "supplemental") {
                    for (let i = 0; i < caseManifest.results.length; i++) {
                        periodOptions.push({label: (i === 0 ? "Across test runs for today (or the latest day results received)" : "Across test runs over last " + (i + 1) + " days results received"), value: i});
                    }
                    this.setState({periodOptions: periodOptions, periodIndex: Math.min(7, caseManifest.results.length - 1)});
                }
                if (this.props.type === "failures-in-period") {
                    for (let i = 0; i < days; i++) {
                        periodOptions.push({label: (i === 0 ? "Most failures today" : "Most failures over last " + (i + 1) + " days"), value: i});
                    }
                    this.setState({periodOptions: periodOptions, periodIndex: Math.min(7, caseManifest.results.length - 1)});
                }
            }
        }
    }
    
    /**
     * 
     * @param {*} resultDate 
     * @param {*} minDate 
     * @returns true if result should be included, false if not. The result should be included if resultDate is a day that is on the minDate or later.
     */
    includeResult (resultDate, minDate) {
        if (resultDate.getFullYear() !== minDate.getFullYear()) {
            if (resultDate.getFullYear() < minDate.getFullYear()) {
                return false;
            }
        }
        if (resultDate.getMonth() !== minDate.getMonth()) {
            if (resultDate.getMonth() < minDate.getMonth()) {
                return false;
            }
        }
        if (resultDate.getDate() < minDate.getDate()) {
            return false;
        }

        return true;
    }

    periodChange (value) {
        this.setState({periodIndex: value});
    }

    renderPeriodResults () {
        if (this.props.view !== "results" && this.props.view !== "supplemental") {
            return <></>
        }
        if (this.props.project.plan.name === "free-v1") {
            return (
                <div className='neutral8bg standardborder neutral7border neutral4' style={{"padding":"12px"}}>
                    Upgrade your project for enhanced analysis capabilities.
                </div>
            )
        }

        let total = 0;
        let pass = 0;
        let fail = 0;
        let unknown = 0;
        for (let i = 0; i <= this.state.periodIndex; i++) {
            if (this.props.type === "case-detail") {
                if (this.state.caseBatchVersion === 1 && this.state.caseData) {
                    const results = this.state.caseData.results[i];
                    pass += results[0];
                    fail += results[1];
                    unknown += results[2];
                    total += results[3];
                }
            }
            if (this.props.data && this.props.type === "supplemental") {
                const caseManifest = this.props.data["caseManifest"];
                if (caseManifest && caseManifest.version === 1) {
                    const results = caseManifest.results[i]
                    pass += results[0];
                    fail += results[1];
                    unknown += results[2];
                    total += results[3];
                }
            }
        }
        if (total === 0) {
            return <></>
        }
        return (
            <div className='neutral8bg standardborder neutral7border' style={{"padding":"12px"}}>
                <div className='flex-row'>
                    <div style={{"flex": "6"}}>
                        <SelectOverlay 
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            type="case-enhanced-analysis-period"
                            label="label" 
                            value="value"
                            options={this.state.periodOptions} 
                            index={this.state.periodIndex}
                            valueChange={this.periodChange} 
                            defaultValue={this.state.periodIndex}
                            active={this.props.active}
                        />
                    </div>
                </div>
                <div className='flex-row'>
                    <div className='font14 neutral4' style={{"flex": "1"}}>
                        <div className='flex-row' style={{"alignItems":"baseline"}}>
                            <div className="circle12 accentc4bg accentc4border" alt="" style={{"marginTop":"-8px"}}/>
                            <div>&nbsp;&nbsp;{pass}</div>
                        </div>
                    </div>
                    <div className='font14 neutral4' style={{"flex": "1"}}>
                        <div className='flex-row' style={{"alignItems":"baseline"}}>
                            <div className="circle12 accenta4bg accenta4border" alt="" style={{"marginTop":"-8px"}}/>
                            <div>&nbsp;&nbsp;{fail}</div>
                        </div>
                    </div>
                    <div className='font14 neutral4' style={{"flex": "1"}}>
                        <div className='flex-row' style={{"alignItems":"baseline"}}>
                            <div className="circle12 accentb4bg accentb4border" alt="" style={{"marginTop":"-8px"}}/>
                            <div>&nbsp;&nbsp;{unknown}</div>
                        </div>
                    </div>
                    <div className='font14 neutral4' style={{"flex": "1"}}>
                        {total} total
                    </div>
                </div>
            </div>
        )
    }

    renderFailuresInPeriod () {
        if (this.props.project && this.props.project.plan && this.props.project.plan.name === "free-v1") {
            return (
                <div className='neutral8bg standardborder neutral7border neutral4' style={{"padding":"12px"}}>
                    Upgrade your project for enhanced analysis capabilities.
                </div>
            )
        }

        const casesEA = [...this.props.cases];
        const oneDayDurationMilliseconds = 24 * 60 * 60 * 1000;
        const minDate = new Date(Date.now() - (this.state.periodIndex * oneDayDurationMilliseconds));

        if (this.props.data) {
            const caseManifest = this.props.data["caseManifest"];
            if (caseManifest) {
                for (const c of casesEA) {
                    let failures = 0;
                    if (caseManifest.hashes[c.hash]) {
                        const batch = caseManifest.hashes[c.hash].batch;
                        const caseBatch = this.props.data["caseBatch" + batch];
                        if (caseBatch) {
                            const caseData = caseBatch.cases[c.hash];
                            if (caseData) {
                                for (let i = 0; i < caseData.results.length; i++) {
                                    const results = caseData.results[i] // [p1,f1, u1, t1, updated] in version 1
                                    if (this.includeResult(new Date(results[4]), minDate)) {
                                        failures += results[1]
                                    }
                                }
                            }
                        }
                    }
                    c.failuresEA = failures;
                }
            }    
        }

        // Sort by most failures
        casesEA.sort((a, b) => {return b.failuresEA - a.failuresEA});
        const casesEAFiltered = casesEA.filter((testCase) => { if (testCase.failuresEA > 0) return testCase});
        
        return (
            <div>
                <div className='flex-column'>
                    <div className='whitebg standardborder neutral7border' style={{"flex": "1", "padding": "12px"}}>
                        <SelectOverlay 
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            type="case-enhanced-analysis-period"
                            label="label" 
                            value="value"
                            options={this.state.periodOptions} 
                            index={this.state.periodIndex}
                            valueChange={this.periodChange}
                            defaultValue={this.state.periodIndex}
                            active={this.props.active}
                        />
                    </div>
                    <CaseGroup
                        cases={casesEAFiltered}
                        context={this.props.context}
                        selectCase={this.props.selectCase} 
                        selectedCase={this.props.selectedCase} 
                        projects={this.props.projects}
                        projectIndex={this.props.projectIndex}
                        targets={this.props.targets} 
                        targetIndex={this.props.targetIndex} 
                        runs={this.props.runs} 
                        runIndex={this.props.runIndex} 
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.state.multiselect}
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        members={this.props.members}
                        trl={this.props.trl}
                        titleOverride={"Top failing test cases"}
                        collapseAll={this.props.collapseAll}
                        invalidateCollapseAll={this.props.invalidateCollapseAll}
                        rawResultMaps={this.props.rawResultMaps}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        periodIndex={this.state.periodIndex}
                    />
                </div>
            </div>
        )
    }

    renderCaseDetailFailures () {
        if (this.props.project.plan.name === "free-v1") {
            return <></> // Upgrade your project for enhanced analysis capabilities.
        }

        const periodIndex = this.props.periodIndex !== undefined ? this.props.periodIndex : 6; // Default to 7 days if not set
        const oneDayDurationMilliseconds = 24 * 60 * 60 * 1000;
        const minDate = new Date(Date.now() - (periodIndex * oneDayDurationMilliseconds));

        if (this.props.data) {
            const caseManifest = this.props.data["caseManifest"];
            if (caseManifest) {
                let failures = 0;
                if (caseManifest.hashes[this.props.selectedCase.hash]) {
                    const batch = caseManifest.hashes[this.props.selectedCase.hash].batch;
                    const caseBatch = this.props.data["caseBatch" + batch];
                    if (caseBatch) {
                        const caseData = caseBatch.cases[this.props.selectedCase.hash];
                        if (caseData) {
                            for (let i = 0; i < caseData.results.length; i++) {
                                const results = caseData.results[i] // [p1,f1, u1, t1, updated] in version 1
                                if (this.includeResult(new Date(results[4]), minDate)) {
                                    failures += results[1]
                                }
                            }
                        }
                    }
                }

                return <span className={"font12 pl-1 pr-1 standardborder " + (failures > 0 ? "accenta8bg accenta3" : "accentc8bg accentc3")}>{failures + " fails over " + (periodIndex + 1) + " day" + (periodIndex > 0 ? "s" : "")}</span>
            }    
        }

        return <></>
    }
    
    render () {
        switch (this.props.type) {
            case "case-detail":
            case "supplemental":
                try {
                    return this.renderPeriodResults();
                } catch (error) {
                    return <></>
                }
            case "failures-in-period":
                return this.renderFailuresInPeriod();
            case "case-detail-failures":
                return this.renderCaseDetailFailures();
            default: 
                return <></>
        }
    }
}

export default CaseEnhancedAnalysis;