/*global */
import React, { Component } from 'react';
import Time from './Time';
import RunArchive from './RunArchive'

class CaseListHeader extends Component {
    render () {
        let secondRow = <span></span>
        if (this.props.cost !== undefined) {
            // second row required
            secondRow = 
                <div className="case-list-sub-header ml-5 mr-3 mb-4">
                    {this.props.cost}
                </div>
        }
        let content = <div></div>
        if (this.props.context === "diff") {
            content = 
            <div>
                <div className={"case-list-header-build"}>
                    {this.props.build}
                </div>
                <div className={"case-list-header-time mt-3"}>
                    {this.props.context !== "tasks" ? 
                    <Time context={this.props.context} duration={this.props.duration} intervalStart={this.props.intervalStart} intervalEnd={this.props.intervalEnd}/>
                    : this.props.taskCaseListHeader
                    }
                </div>  
                <div className="case-list-header-collapse-all">
                    <div className={"case-list-header-collapse"}>
                        <button className="select" onClick={this.props.toggleCollapseAll}>
                            {this.props.collapseAll === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                        </button>    
                    </div>
                    <div className={"case-list-header-title"}>
                        <span className="neutral4">{this.props.collapseAll === true ? "Expand All" : "Collapse All"}</span>
                    </div>
                </div>
                {secondRow}
            </div>
        } else {
            content =
            <div className="list-margin">
                <div className="case-list-header mb-4">
                    <div className={"case-list-header-build" + (this.props.context === "results" ? " dividor-light-case-list-header" : "")}>
                        {this.props.build}
                    </div>
                    <div className={"case-list-header-time"  + (this.props.context === "results" ? " dividor-light-case-list-header" : "")}>
                        {this.props.context !== "tasks" ? 
                        <Time duration={this.props.duration} intervalStart={this.props.intervalStart} intervalEnd={this.props.intervalEnd}/>
                        : this.props.taskCaseListHeader
                        }
                    </div>
                    <div className="case-check-box-select-all">
                        {this.props.multiselectAll}
                    </div>
                    <div className={"case-list-header-gap"}>
                        
                    </div>
                    {
                        (this.props.context === "results" || this.props.context === "list" || this.props.context === "manual") ?
                        <div className="case-list-header-collapse-all"></div>
                        :
                        <div className="case-list-header-collapse-all">
                            <div className={"case-list-header-collapse"}>
                                <button className="select" onClick={this.props.toggleCollapseAll}>
                                    {this.props.collapseAll === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                                </button>    
                            </div>
                            <div className={"case-list-header-title"}>
                                <span className="neutral4">{this.props.collapseAll === true ? "Expand All" : "Collapse All"}</span>
                            </div>
                        </div>
                    }
                    <div>
                        <RunArchive 
                            overlay={this.props.overlay}
                            context={this.props.context}
                            targets={this.props.targets} 
                            targetIndex={this.props.targetIndex} 
                            runs={this.props.runs} 
                            runIndex={this.props.runIndex}
                            isRunArchive={this.props.isRunArchive}
                            runArchive={this.props.runArchive}
                            runArchiveBuildCase={this.props.runArchiveBuildCase}
                            runArchiveCases={this.props.runArchiveCases}
                            runArchiveDeleted={this.props.runArchiveDeleted}
                            revUpdate={this.props.revUpdate}
                        />
                    </div>
                </div>
                {secondRow}
            </div>
        }
        return content
    }
};

export default CaseListHeader;