/*global */
import React, { Component } from 'react';
const moment = require('moment');
//import TimeFormatted from './TimeFormatted';

class ScatterChart extends Component {
    constructor()  {
        super();
        this.state = ({tooltipPt: undefined, chart: { width: 100, height: 40}});
        this.drawChart = this.drawChart.bind(this);
        this.pointHover = this.pointHover.bind(this);
        this.pointExit = this.pointExit.bind(this);
        this.pointClick = this.pointClick.bind(this);
        this.dataPtColor = this.dataPtColor.bind(this);
        this.chartPt = this.chartPt.bind(this);
        this.labelTrim = this.labelTrim.bind(this);
        this.addDataPt = this.addDataPt.bind(this);
        this.addAxisLabel = this.addAxisLabel.bind(this);
        this.addGuideLine = this.addGuideLine.bind(this);
        this.addXAxis = this.addXAxis.bind(this);
        this.addYAxis = this.addYAxis.bind(this);
        this.dataRange = this.dataRange.bind(this);
    }

    pointHover (event) {
        const type = event.target.attributes.getNamedItem('data-type').value;
        if (type === "label") {
            let xVal = parseFloat(event.target.attributes.getNamedItem('x').value);
            let yVal = parseFloat(event.target.attributes.getNamedItem('y').value);
            let datum = event.target.attributes.getNamedItem('data-datum').value;
            if (this.state.tooltipPt === undefined) {
                this.setState({tooltipPt: {x: xVal, y: yVal, datum: datum, type: type}});
            }
        } else if (type === "point") {
            let xVal = parseFloat(event.target.attributes.getNamedItem('cx').value);
            let yVal = parseFloat(event.target.attributes.getNamedItem('cy').value);
            let datum = event.target.attributes.getNamedItem('data-datum').value;
            if (this.state.tooltipPt === undefined) {
                this.setState({tooltipPt: {x: xVal, y: yVal, datum: datum, type: type}});
            }
        }
    }

    pointClick (event) {

    }

    pointExit () {
        this.setState({tooltipPt: undefined});
    }

    dataPtColor (seriesNum) {
        switch(seriesNum) {
            case 0:
            return "#51A7F9"; // neutral1
            case 1:
            return "#5cb85c"; // success
            case 2:
            return "#FF3167"; // fail
            case 3:
            return "#f0ad4e"; // unknown
            case 4:
            return "#000000"; // black
            default:
            return "neutral4"; // neutral4 - should not be returned as limit is set to 5 for series
        }
    }

    // dataPt = {x: <>, y: <>}
    // dataRange = {x1: <>, x2: <>, y1: <>, y2: <>}
    // chartRange = {x1: <>, x2: <>, y1: <>, y2: <>}
    chartPt (dataPt, dataRange, chartRange) {
        let pt = {};
        pt.x = (dataPt.x - dataRange.x1) * (chartRange.x2 - chartRange.x1) / (dataRange.x2 - dataRange.x1) + chartRange.x1;
        pt.y = (dataPt.y - dataRange.y1) * (chartRange.y2 - chartRange.y1) / (dataRange.y2 - dataRange.y1) + chartRange.y1;
        return pt;
    }

    // dataPt = {x: <>, y: <>}
    // dataRange = {x1: <>, x2: <>, y1: <>, y2: <>}
    // chartRange = {x1: <>, x2: <>, y1: <>, y2: <>}
    addDataPt (dataPt, dataRange, chartRange, datum, color, key) {
        let pointHover = this.pointHover;
        let pointExit = this.pointExit;
        let pointClick = this.pointClick;
        let pt = this.chartPt(dataPt, dataRange, chartRange);
        //console.log(pt);
        return <circle key={"dataPoint" + key} cx={pt.x} cy={pt.y} r="0.25" data-type="point" data-datum={JSON.stringify(datum)} style={{"stroke": color, "fill": color}} className="lineChartStroke lineChartPoint" onMouseEnter={pointHover} onMouseLeave={pointExit} onClick={pointClick}/>;
    }

    labelTrim (text, numChars) {
        text = text.toString();
        if (numChars === undefined) {
            numChars = 4;
        }
        if (text.length > numChars) {
            text = text.substring(0, numChars);
            text = text + "...";
        }
        return text;
    }

    addAxisLabel (dataPt, text, color, numChars) {
        let pointHover = this.pointHover;
        let pointExit = this.pointExit;
        let pointClick = this.pointClick;
        return <text key={"axisLabel-" + dataPt.x + dataPt.y} x={dataPt.x} y={dataPt.y} data-type="label" data-datum={text} fontFamily="Montserrat" fontSize="1.1" style={{"stroke": color, "fill": color}} className="lineChartStroke" onMouseEnter={pointHover} onMouseLeave={pointExit} onClick={pointClick}>{this.labelTrim(text, numChars)}</text>;
    }

    addGuideLine (from, to, color) {
        return <line x1={from.x} y1={from.y} x2={to.x} y2={to.y} style={{"stroke": color, "strokeWidth": "0.025", "fill": color}} className="lineChartStroke" key={"guideline-" + from.x + " " + from.y + " " + to.x + " " + to.y}/>;
    }

    addXAxis (field, dataRange, chartRange) {
        let svg = [];
        const color = this.dataPtColor(0);
        const format = this.props.format[field];

        svg.push(this.addAxisLabel ({x: 0, y: this.state.chart.height - 0.2}, field, color, 6));
        if (format === "number") {
            for (let i = 0; i <= 1.0; i += 0.1) {
                let dataPt = {x: (i * (chartRange.x2 - chartRange.x1)) + chartRange.x1 , y: this.state.chart.height - 0.2};
                let text = (i * (dataRange.x2 - dataRange.x1)) + dataRange.x1;
                svg.push(this.addAxisLabel(dataPt, text, color, 6));
                svg.push(this.addGuideLine({x: dataPt.x, y: chartRange.y1}, {x: dataPt.x, y: chartRange.y2}, color));
            }
        } else if (format === "time") {
            for (let i = 0; i <= 1.0; i += 0.1) {
                let dataPt = {x: (i * (chartRange.x2 - chartRange.x1)) + chartRange.x1 , y: this.state.chart.height - 0.2};
                let text = moment(((i * (dataRange.x2 - dataRange.x1)) + dataRange.x1)).format("DD MMM YYYY h:mm:ss a");
                svg.push(this.addAxisLabel(dataPt, text, color, 6));
                svg.push(this.addGuideLine({x: dataPt.x, y: chartRange.y1}, {x: dataPt.x, y: chartRange.y2}, color));
            }
        } else if (format === "string") {
            let data = [];
            this.props.data.forEach(function (datum) {
                if (datum[field] !== undefined && datum[field] !== "") {
                    data.push(datum[field]);
                }
            });
            data.sort();
            const interval = parseInt((data.length / 10), 10);
            for (let index = 0; index < data.length; index += interval) {
                let dataPt = {x: ((index/data.length) * (chartRange.x2 - chartRange.x1)) + chartRange.x1, y: this.state.chart.height - 0.2};
                let text = data[index];
                if (text !== undefined) {
                    svg.push(this.addAxisLabel(dataPt, text, color, 6));
                    svg.push(this.addGuideLine({x: dataPt.x, y: chartRange.y1}, {x: dataPt.x, y: chartRange.y2}, color));
                }
            }
        }

        return svg;
    }

    addYAxis (field, dataRange, chartRange, seriesNum) {
        // assumes limit of 2 y axis selection
        let svg = [];
        const color = this.dataPtColor(seriesNum);
        const format = this.props.format[field];

        if (seriesNum === 0) {
            svg.push(<line x1={chartRange.x1} y1={chartRange.y1} x2={chartRange.x1} y2={chartRange.y2} style={{"stroke": color, "strokeWidth": "0.1", "fill": color}} className="lineChartStroke" key={"yAxis-series" + seriesNum}/>);
            svg.push(this.addAxisLabel ({x: chartRange.x1, y: this.state.chart.height * 0.03}, field, color, 20));
            if (format === "number") {
                for (let i = 0; i <= 1.0; i += 0.1) {
                    let dataPt = {x: 0, y: (i * (chartRange.y2 - chartRange.y1)) + chartRange.y1};
                    let text = (i * (dataRange.y2 - dataRange.y1)) + dataRange.y1;
                    svg.push(this.addAxisLabel(dataPt, text, color, 6));
                    svg.push(this.addGuideLine({x: chartRange.x1, y: dataPt.y}, {x: chartRange.x2, y: dataPt.y}, color));
                }    
            } else if (format === "time") {
                for (let i = 0; i <= 1.0; i += 0.1) {
                    let dataPt = {x: 0, y: (i * (chartRange.y2 - chartRange.y1)) + chartRange.y1};
                    let text = moment(((i * (dataRange.y2 - dataRange.y1)) + dataRange.y1)).format("DD MMM YYYY h:mm:ss a");
                    svg.push(this.addAxisLabel(dataPt, text, color, 6));
                    svg.push(this.addGuideLine({x: chartRange.x1, y: dataPt.y}, {x: chartRange.x2, y: dataPt.y}, color));
                }
            } else if (format === "string") {
                let data = [];
                this.props.data.forEach(function (datum) {
                    data.push(datum[field]);
                });
                data.sort();
                const interval = parseInt((data.length / 10), 10);
                for (let index = 0; index < data.length; index += interval) {
                    let dataPt = {x: 0, y: ((index/data.length) * (chartRange.y2 - chartRange.y1)) + chartRange.y1};
                    let text = data[index];
                    if (text !== undefined) {
                        svg.push(this.addAxisLabel(dataPt, text, color, 6));
                        svg.push(this.addGuideLine({x: chartRange.x1, y: dataPt.y}, {x: chartRange.x2, y: dataPt.y}, color));
                    }
                }
            }
        }

        if (seriesNum === 1) {
            svg.push(<line x1={chartRange.x2} y1={chartRange.y1} x2={chartRange.x2} y2={chartRange.y2} style={{"stroke": color, "strokeWidth": "0.1", "fill": color}} className="lineChartStroke" key={"yAxis-series" + seriesNum}/>);
            svg.push(this.addAxisLabel({x: chartRange.x2 - 3, y: this.state.chart.height * 0.03}, field, color));
            if (format === "number") {
                for (let i = 0; i <= 1.0; i += 0.1) {
                    let dataPt = {x: chartRange.x2 + 1 , y: (i * (chartRange.y2 - chartRange.y1)) + chartRange.y1};
                    let text = (i * (dataRange.y2 - dataRange.y1)) + dataRange.y1;
                    svg.push(this.addAxisLabel(dataPt, text, color, 4));
                }
            } else if (format === "time") {
                for (let i = 0; i <= 1.0; i += 0.1) {
                    let dataPt = {x: chartRange.x2 + 1 , y: (i * (chartRange.y2 - chartRange.y1)) + chartRange.y1};
                    let text = moment((i * (dataRange.y2 - dataRange.y1)) + dataRange.y1).format("DD MMM YYYY h:mm:ss a");
                    svg.push(this.addAxisLabel(dataPt, text, color, 4));
                }
            } else if (format === "string") {
                let data = [];
                this.props.data.forEach(function (datum) {
                    if (datum[field] !== undefined && datum[field] !== "") {
                        data.push(datum[field]);
                    }
                });
                data.sort();
                const interval = parseInt((data.length / 10), 10);
                for (let index = 0; index < data.length; index += interval) {
                    let dataPt = {x: chartRange.x2 + 1, y: ((index/data.length) * (chartRange.y2 - chartRange.y1)) + chartRange.y1};
                    let text = data[index];
                    if (text !== undefined) {
                        svg.push(this.addAxisLabel(dataPt, text, color, 4));
                    }
                }
            }
        }
        
        return svg;
    }

    tooltip () {
        if (this.state.tooltipPt !== undefined) {
            let pt = this.state.tooltipPt;
            let svg = [];
            const type = this.state.tooltipPt.type;

            if (type === "point") {
                let y = pt.y - 5;
                if (pt.y < 30) {
                    y = pt.y + 5;
                }
        
                let x = pt.x + 2;
                if (pt.x > 50) {
                    x = pt.x - 20;
                }

                const datum = JSON.parse(this.state.tooltipPt.datum);
                const field = datum["tesults-field"];
                const series = datum["tesults-series"];
                const xVal = datum[field];
                const yVal = datum[series];
                svg.push(<text x={x} y={y} fontFamily="Montserrat" fontSize="1.25" className="lineChartStroke" key="tooltipField">{field + ": " + xVal}</text>);   
                svg.push(<text x={x} y={y + 2} fontFamily="Montserrat" fontSize="1.25" className="lineChartStroke" key="tooltipSeries">{series + ": " + yVal}</text>);
            } else if (type === "label") {
                let x = pt.x;
                let y = pt.y;
                if (y > this.state.chart.height * 0.95) {
                    // x-axis label
                    y = y - 5;
                    if (x > this.state.chart.width * 0.9) {
                        x = x - 20;
                    }
                } else {
                    // y-axis label, left or right
                    if (x > 50) {
                        // right axis
                        x = x - 10;
                    } else {
                        // left axis
                        x = x + 5;
                    }
                }
               
                const val = this.state.tooltipPt.datum;
                svg.push(<text x={x} y={y} fontFamily="Montserrat" fontSize="1.25" className="lineChartStroke" key="tooltipField">{val}</text>);   
            }

            
            
            return svg;
        } else {
            return undefined;
        }
    }

    dataRange (field, yAxis) {
        let data = this.props.data;
        let format = this.props.format;
        let min = undefined;
        let max = undefined;
        if (format[field] === "string") {
            min = 0;
            max = data.length - 1;
        } else if (format[field] === "number") {
            let yRange = this.props.yRanges[field];
            if (yAxis === true) {
                min = yRange.minSelected;
                max = yRange.maxSelected;
            } else {
                min = yRange.min;
                max = yRange.max;
            }
            
            if (min === max) {
                min = max - 1;
            }
            /*
            data.forEach(function (datum) {
                let val = Number(datum[field]);
                if (isNaN(val) === false) {
                    if (min === undefined && max === undefined) {
                        min = val;
                        max = val;
                    } else {
                        if (val < min) {
                            min = val;
                        }
                        if (val > max) {
                            max = val;
                        }
                    }
                }
            });*/
        } else if (format[field] === "time") {
            data.forEach(function (datum) {
                let val = moment(datum[field]);
                if (val.isValid() === false) {
                    console.log("Invalid date time");
                }

                if (isNaN(val) === false) {
                    if (min === undefined && max === undefined) {
                        min = val;
                        max = val;
                    } else {
                        if (val < min) {
                            min = val;
                        }
                        if (val > max) {
                            max = val;
                        }
                    }
                }
            });
        }

        return {min: min, max: max};
    }

    drawChart () {
        let svg = [];
        let key = 0;

        // these values are in percentages
        const yMax = 0.05 * this.state.chart.height;
        const yMin = 0.95 * this.state.chart.height;
        const xMax = 0.95 * this.state.chart.width;
        const xMin = 0.05 * this.state.chart.width;

        let chartRange = {x1: xMin, x2: xMax, y1: yMin, y2: yMax};

        svg.push(<line x1={xMin} y1={yMin} x2={xMax} y2={yMin} style={{"strokeWidth": "0.1"}} className="lineChartStroke lineChartDefaultColor" key={key++}/>);

        // process data points
        let data = this.props.data;
        let format = this.props.format;
        let dataRange = this.dataRange;
        let addDataPt = this.addDataPt;
        let selected = this.props.selected;
        let dataPtColor = this.dataPtColor;
        let addXAxis = this.addXAxis;
        let addYAxis = this.addYAxis;
        if (this.props.selected !== undefined) {
            if (selected.x !== undefined) {
                selected.x.forEach(function (field) {
                    // determine data range for field
                    let fieldDataRange = dataRange(field, false);
                    let fieldStringX = fieldDataRange.min;
                    const fieldStringInterval = (fieldDataRange.max - fieldDataRange.min) / fieldDataRange.max;
                    let seriesNum = 0;
                    if (selected.y !== undefined) {
                        selected.y.forEach(function (series) {
                            fieldStringX = fieldDataRange.min;
                            let color = dataPtColor(seriesNum);
                            let seriesDataRange = dataRange(series, true);
                            let range = {x1: fieldDataRange.min, x2: fieldDataRange.max, y1: seriesDataRange.min, y2: seriesDataRange.max};
                            if (seriesNum === 0) {
                                svg.push(addXAxis(field, range, chartRange));
                            }
                            svg.push(addYAxis(series, range, chartRange, seriesNum));


                            // plot data
                            let seriesStringValue = {};
                            
                            if (format[series] === "string") {
                                let seriesdata = [];
                                data.forEach(function (datum) {
                                    seriesdata.push(datum[series]);
                                });
                                seriesdata.sort();
                                let index = 0;
                                seriesdata.forEach(function (datum) {
                                    if (seriesStringValue[datum] === undefined) {
                                        seriesStringValue[datum] = [index];
                                    } else {
                                        seriesStringValue[datum].push(seriesdata[index]);
                                    }
                                    index += 1;
                                });
                            }

                            data.forEach(function (datum) {
                                let valid = true;
                                let dataPt = {};
                                if (format[field] === "string") {
                                    dataPt.x = fieldStringX;
                                    fieldStringX += fieldStringInterval;
                                } else if (format[field] === "number") {
                                    dataPt.x =  Number(datum[field]);
                                    if (isNaN(dataPt.x)) {
                                        valid = false;
                                    }
                                } else if (format[field] === "time") {
                                    dataPt.x = moment(datum[field]);
                                    if (dataPt.x.isValid() === false) {
                                        console.log("Invalid date time");
                                    }
                                }

                                if (format[series] === "string") {
                                    dataPt.y = seriesStringValue[datum[series]].shift();
                                } else if (format[series] === "number") {
                                    dataPt.y =  Number(datum[series]);
                                    if (isNaN(dataPt.y)) {
                                        valid = false;
                                    }
                                    if (dataPt.y < seriesDataRange.min && dataPt.y > seriesDataRange.max) {
                                        valid = false;
                                    }
                                } else if (format[series] === "time") {
                                    dataPt.y = moment(datum[series]);
                                    if (dataPt.y.isValid() === false) {
                                        console.log("Invalid date time");
                                    }
                                }
                                
                                if (valid === false) {
                                    // Unable to plot
                                } else {
                                    // dataPt = {x: <>, y: <>}
                                    // dataRange = {x1: <>, x2: <>, y1: <>, y2: <>}
                                    // chartRange = {x1: <>, x2: <>, y1: <>, y2: <>}
                                    let tooltipDatum = datum;
                                    tooltipDatum["tesults-field"] = field;
                                    tooltipDatum["tesults-series"] = series;
                                    svg.push(addDataPt(dataPt, range, chartRange, tooltipDatum, color, key));
                                    key++;
                                }
                            });

                            seriesNum += 1;
                        });
                    }
                });
            }
        }

        let tooltip = this.tooltip();
        if (tooltip !== undefined) {
            svg.push(tooltip);
        }

        return svg;
    }

    render () {
        let width = this.state.chart.width + "%";
        let height = this.state.chart.height + "%";
        let viewBox = "0 0 " + this.state.chart.width + " " + this.state.chart.height;
        let chart = this.drawChart();
        let datum = <br/>;
        if (this.state.tooltipPt !== undefined) {
            if (this.state.tooltipPt.type === "point") {
                try {
                    datum = JSON.parse(this.state.tooltipPt.datum);
                } catch (err) {
    
                }
                let datumString = [];
                for (var key in datum) {
                    if (key !== "tesults-field" && key !== "tesults-series") {
                        datumString.push(key);
                        datumString.push(": ");
                        datumString.push(datum[key]);
                        datumString.push(", ");
                    }
                }
                datumString.pop();
                datum = datumString.join('');
            }
        }
        return (
            <div>
                <svg style={{"width": {width}, "height": {height}}} viewBox={viewBox}>
                    {chart}
                </svg>
                <div className="mt-3">
                    <small>{datum}</small>
                </div>
            </div>
        );
    }
}

export default ScatterChart;