/*global*/
import React, { Component } from 'react';
import Constants from './Constants';
import TaskCase from './TaskCase';
import TimeFormatted from './TimeFormatted';
import Analytics from './Analytics';
import CsvData from './CsvData';
import LogView from './LogView';
import Comments from './Comments';
import Bugs from './Bugs';
import CaseDetailField from './CaseDetailField';
import CasePriority from './CasePriority';
import Copy from './Copy';
import Duration from './Duration';
import Cost from './Cost';
import Currency from './Currency';
import Request from './Request';
import Project from './Project';
import Target from './Target';
import SelectOverlay from './SelectOverlay';
import Assignee from './Assignee';
import Result from './Result'
import IconLabel from './IconLabel'
import {utilsRawResultMapMap, utilsRawResultMapMapForTask} from './Utils'
import StatView from './StatView'
import ReactJson from 'react-json-view';
import CaseEnhancedAnalysis from './CaseEnhancedAnalysis';

class CaseDetail extends Component {
    constructor () {
        super();
        this.ref = React.createRef();
        this.state = {imageEnlarged: null, chartEnabled: {}, textFileView: {}, edit: false, editedResult: undefined, resultChangeReason:"", updatedCase: undefined, changeBy: "", width: window.innerWidth, height: window.innerHeight, mobile: (window.innerWidth < 980 ? true: false), collapseAll: false};
        this.caseHistory = this.caseHistory.bind(this);
        this.imageClick = this.imageClick.bind(this);
        this.chartEnable = this.chartEnable.bind(this);
        this.textFileView = this.textFileView.bind(this);
        this.edit = this.edit.bind(this);
        this.editComplete = this.editComplete.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.resultChange = this.resultChange.bind(this);
        this.resultChangeReason = this.resultChangeReason.bind(this);
        this.getChangeBy = this.getChangeBy.bind(this);
        this.rspCaseLink = this.rspCaseLink.bind(this);
        this.resizeEvent = this.resizeEvent.bind(this);
        this.caseDetailClick = this.caseDetailClick.bind(this);
        this.selectOverlayActive = this.selectOverlayActive.bind(this);
        this.toggleCollapseAll = this.toggleCollapseAll.bind(this)
        this.playwrightTraceViewOneClick = this.playwrightTraceViewOneClick.bind(this);
    }

    componentDidMount () {
        window.addEventListener('resize', this.resizeEvent);
        this.getChangeBy();
        Analytics.event("CaseDetail"); 
    }
   
    componentWillUnmount() {
       window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
        let mobile = false;
        if (window.innerWidth <= 980) { // for mobile
            mobile = true;
        }
        this.setState({ width: window.innerWidth, height: window.innerHeight, mobile: mobile});
    }

    caseDetailClick () {
        if (this.state.selectOverlayActive === true) {
            this.props.overlay(undefined);
            this.setState({selectOverlayActive:false});
        }
    }

    selectOverlayActive () {
        this.setState({selectOverlayActive: true});
    }

    getChangeBy () {
        let selectedCase = this.props.selectedCase;
        if (this.state.updatedCase !== undefined) {
            selectedCase = this.state.updatedCase;
        }
        if (selectedCase.resultChangeBy !== undefined) {
            if (this.props.projects !== undefined) {
                let project = this.props.projects[this.props.projectIndex];
                Request.get("/resultchangeby", {project: project.id, user: selectedCase.resultChangeBy}, (err, data) => {
                    if (err) {
                        this.setState({error: "Unable to get change by."});
                    } else {
                        this.setState({changeBy: data.firstName + " " + data.lastName});
                    }
                });
            }
        }
    }

    edit () {
        let selectedCase = this.props.selectedCase;
        if (this.state.updatedCase !== undefined) {
            selectedCase = this.state.updatedCase;
        }

        let editedResult = "";
        if (selectedCase.result.toLowerCase() !== "pass") {
            editedResult = "pass";
        } else if (selectedCase.result.toLowerCase() !== "fail") {
            editedResult = "fail";
        } else if (selectedCase.result.toLowerCase() !== "unknown") {
            editedResult = "unknown";
        }
        this.setState({edit: true, editedResult: editedResult});
    }

    editComplete () {
        // api call to update result
        let selectedCase = this.props.selectedCase;
        if (this.state.updatedCase !== undefined) {
            selectedCase = this.state.updatedCase;
        }

        let run = this.props.runs[this.props.runIndex];
        let project = this.props.projects[this.props.projectIndex];
        let target = this.props.targets[this.props.targetIndex];

        let data = {
            projectId: project.id,
            targetCreated: target.created,
            runId: run.id,
            runCreated: run.created,
            runVersion: run.version,
            runKeyPrefix: run.keyPrefix,
            caseNum: selectedCase.num,
            batch: selectedCase.batch,
            resultChange: this.state.editedResult,
            resultChangeReason: this.state.resultChangeReason
        };
        Request.post("/resultchange", data, (err, data) => {
            if (err) {
                this.setState({error: "Unable to edit test result."});
            } else {
                Analytics.event("ChangedTestResult");
                let updatedCase = data.updatedCase;
                this.setState({updatedCase: updatedCase, edit: false});
                this.getChangeBy();
            }
        });
    }

    cancelEdit () {
        this.setState({edit: false});
    }

    resultChange (e) {
        this.setState({editedResult: e.target.value});
    }

    resultChangeReason (e) {
        this.setState({resultChangeReason: e.target.value});
    }

    caseHistory () {
        if (this.props.isRunArchive === true) {
            return <div></div>
        }
        let selectedCase = this.props.selectedCase;
        if (this.state.updatedCase !== undefined) {
            selectedCase = this.state.updatedCase;
        }
        let runs = this.props.runs;
        let runIndex = this.props.runIndex;
        let cases = this.props.cases;
        if (selectedCase === null) {
            return (<div></div>);
        }

        let map = undefined
        if (this.props.view === "tasks") {
            map = utilsRawResultMapMapForTask(selectedCase, this.props.rawResultMaps)
        } else {
            map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)
        }
        
        let relevantCases = [];
        let index = runIndex;
        let num = 0;
        while (index < runs.length && num < 10) {
            let searchCases = cases[index];
            let run = runs[index];
            let runMetadata = undefined;
            if (run) {
                runMetadata = {
                    integrationName: run.integrationName,
                    integrationVersion: run.integrationVersion,
                    testFramework: run.testFramework,
                }
            }
            searchCases.forEach(function (c) {
                if (c.suite === selectedCase.suite && c.name === selectedCase.name && c.params === selectedCase.params) {
                    //const time = (new Date(parseInt(run.created, 10))).toLocaleString();
                    const time = <TimeFormatted dt={run.created}/>
                    const build = run.build === undefined ? "" : " Build: " + run.build;
                    let img = <Result result={c.result} rawResult={c.rawResult} map={map} runMetadata={runMetadata} case={c}/>
                    relevantCases.push({time: time, build: build, img: img, k: time + build});
                }
            });
            num += 1;
            index += 1;
        }
        
        let ret = [];

        let relNum = 0;
        relevantCases.forEach(function (c) {
            ret.push(
                <div className="row mb-1" key={relNum}>
                    <div className="col-6 col-sm-9 col-md-9 col-lg-9 wrap align-self-center">
                        {c.time} {c.build}
                    </div>
                    <div className="col-6 col-sm-3 col-md-3 col-lg-3 pr-3 text-right wrap align-self-center">
                        {c.img}
                    </div>
                </div>
            )
            relNum += 1;
        });
        
        return ret;
    }

    imageClick (url) {
        if (this.state.imageEnlarged === url) {
            this.setState({imageEnlarged: null});
        } else {
            this.setState({imageEnlarged: url});
        }
    }

    chartEnable (file) {
        let chartEnabled = this.state.chartEnabled;
        if (chartEnabled[file] === undefined) {
            chartEnabled[file] = false;
        } else if (chartEnabled[file] === true) {
            Analytics.event("ChartClose");
            chartEnabled[file] = false;
        } else if (chartEnabled[file] === false) {
            Analytics.event("ChartView");
            chartEnabled[file] = true;
        }
        
        this.setState({chartEnabled: chartEnabled});
    }

    textFileView (file) {
        let fileView = this.state.textFileView;
        if (fileView[file] === undefined) {
            fileView[file] = false;
        } else if (fileView[file] === true) {
            Analytics.event("LogClose");
            fileView[file] = false;
        } else if (fileView[file] === false) {
            Analytics.event("LogView");
            fileView[file] = true;
        }
        
        this.setState({textFileView: fileView});
    }

    rspCaseLink () {
        if (this.props.view !== undefined && this.props.view !== "manual" && this.props.view !== "manual-archive" && this.props.view !== "list" && this.props.view !== "import" && this.props.view !== "tasks" && this.props.trl !== true && this.props.view !== "release-checklist") {
            let curl = Constants.baseUrl + "/results/rsp/view/" + (this.props.view === "diff" ? "results" : this.props.view) + "/case/" + this.props.runs[this.props.runIndex].id + "-" + this.props.runs[this.props.runIndex].created + "-" + this.props.selectedCase.num;
            return <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Case Link</span>} value={<span><small>{curl}</small> <Copy text={curl}/></span>} darkMode={this.props.darkMode}/>
        }
    }

    toggleCollapseAll () {
        this.setState({collapseAll: this.state.collapseAll ? false : true})
    }

    playwrightTraceViewOneClick (source) {
        this.setState({playwrightTraceViewOneClickButtonDisabled: true});
        const project = this.props.projects[this.props.projectIndex];
        Request.post("/playwright-trace-view-one-click", {source: source, id: project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, playwrightTraceViewOneClickButtonDisabled: false});
            } else {
                this.setState({loading: false, playwrightTraceViewOneClickButtonDisabled: false});
                window.open(data.url, "_blank");
            }
        });
    }

    render () {
        let selectedCase = this.props.selectedCase;
        if (this.state.updatedCase !== undefined) {
            selectedCase = this.state.updatedCase;
        }
        let failureReason = <span></span>;
        let editButtonClass = "btn btn-white ml-3 mt-1 mb-1";
        let headerFontColor = "neutral1";
        let bgHeaderClass = "modal-header accenta3borderheader";
        let bgFooterClass = "modal-footer accenta3borderfooter flex-wrap";
        if (selectedCase.result.toLowerCase() === "fail") {
            failureReason = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Failure Reason</span>} value={selectedCase.reason} darkMode={this.props.darkMode}/>
        } else if (selectedCase.result.toLowerCase() === "unknown") {
            failureReason = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Failure Reason</span>} value={selectedCase.reason} darkMode={this.props.darkMode}/>
            headerFontColor = "neutral1";
            bgHeaderClass = "modal-header accentb2borderheader";
            bgFooterClass = "modal-footer accentb2borderfooter flex-wrap";
        } else if (selectedCase.result.toLowerCase() === "pass") {
            bgHeaderClass = "modal-header accentc3borderheader";
            bgFooterClass = "modal-footer accentc3borderfooter flex-wrap";
        }
        if (selectedCase.manualComplete !== undefined) {
            if (selectedCase.manualComplete === false || selectedCase.manualComplete === "false") {
                bgHeaderClass = "modal-header whitebg";
                bgFooterClass = "modal-footer white8bg flex-wrap";
            }
        }
        bgHeaderClass += this.props.darkMode === true ? " darkbg" : "";
        let params = <span></span>;
        if (selectedCase.params !== undefined) {
            if (selectedCase.params !== undefined) {
                if (selectedCase.params !== undefined) {
                    let paramString = "";
                    for (let param in selectedCase.params) {
                        if (selectedCase.params.hasOwnProperty(param)) {
                            paramString += param + " = " + selectedCase.params[param] + ", ";
                        }
                    }
                    if (paramString !== "") {
                        paramString = paramString.substr(0, paramString.length - 2);
                    }
                    params = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label="Parameters" value={<span>{paramString}</span>} darkMode={this.props.darkMode}/>
                }
            }
        }
        let custom = [];
        let customKey = -1;
        if (selectedCase.custom !== undefined) {
            for (let i = 0; i < selectedCase.custom.length; i += 2) {
                if (i + 1 < selectedCase.custom.length) {    
                    let fieldName = selectedCase.custom[i].substring(1);
                    let fieldValue = selectedCase.custom[i + 1];
                    custom.push(<CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} key={customKey++} label={fieldName} value={fieldValue} darkMode={this.props.darkMode}/>)
                }
            }
        }
        // Custom fields
        Object.keys(selectedCase).forEach(function(key) {
            if (key.charAt(0) === "_") {
                if (selectedCase[key] !== "" && selectedCase[key] !== undefined && selectedCase[key] !== null) {
                    if (typeof selectedCase[key] === 'object') {
                        try {
                            let val = JSON.stringify(selectedCase[key]);
                            selectedCase[key] = val;
                        } catch (err) {
                            if (selectedCase[key] !== undefined && selectedCase[key] !== null) {
                                selectedCase[key] = selectedCase[key].toString();
                            }
                        }
                    }
                    let value = selectedCase[key];
                    try {
                        // Playwright retries parsing
                        if (this.props.runs !== undefined && this.props.runIndex !== undefined) {
                            if (this.props.runIndex < this.props.runs.length) {
                                const run = this.props.runs[this.props.runIndex];
                                if (run.integrationName === "playwright-tesults-reporter" && Constants.isVersionGreaterThanOrEqualTo(run.integrationVersion, "1.2.0")) {
                                    if (key === "_Retries") {
                                        value = JSON.parse(value);
                                        const retries = [];
                                        if (Array.isArray(value)) {
                                            for (let i = 0; i < value.length; i++) {
                                                const retry = value[i];
                                                retry.reason = CaseDetailField.removeAnsiEscapeCode(retry.reason)
                                                retries.push(
                                                    <div style={{"display":"flex"}} key={i}>
                                                        <div className="mb-3" key={i}>
                                                            <img src="/img/tesults-logo-fail-128.png" alt="" width="20" height="20"/>
                                                        </div >
                                                        <div className="mr-3">
                                                            {CaseDetailField.removeAnsiEscapeCode(CaseDetailField.isJSON(retry.reason) ? <ReactJson src={JSON.parse(retry.reason)}/> : retry.reason)}
                                                        </div>  
                                                    </div>
                                                );
                                            }
                                            value = <div>
                                                        <p>{retries.length > 0 ? "Failures from attempts prior to latest retry result:" : ""}</p>
                                                        {retries}
                                                    </div>
                                        }
                                    }
                                }
                            }
                        }
                    } catch (err) {
                        value = selectedCase[key];
                    }
                    custom.push(<CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} key={customKey++} label={key.substring(1)} value={value} darkMode={this.props.darkMode}/>)
                }
            }
        }.bind(this));

        let files = [];
        const num = selectedCase.num;
        const batch = selectedCase.batch;
        let fNum = 0;

        const imageEnlarged = this.state.imageEnlarged;
        const imageClick = this.imageClick;

        let target = undefined;
        let run = undefined;
        let permission = undefined;
        let runMetadata = undefined;
        if (this.props.view === "results" || this.props.view === "supplemental" || this.props.view === "diff") {
            target = this.props.targets[this.props.targetIndex];
            run = this.props.runs[this.props.runIndex];
            permission = "p" + this.props.projects[this.props.projectIndex].dl;

            runMetadata = {
                integrationName: run.integrationName,
                integrationVersion: run.integrationVersion,
                testFramework: run.testFramework,
            }
        } else if (this.props.view === "tasks") {
            target = selectedCase.task.target;
            run = selectedCase.run;
            permission = "p" + selectedCase.task.project.dl;
        }
        let prefix = "";
        if (this.props.view === "manual") {
            permission = "pdl30";
            let project = this.props.projects[this.props.projectIndex]
            if (project.plan !==  undefined) {
                if (project.plan.name !== "free-v1") {
                    permission = "pdl"
                }
            }
            let created = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex].created;
            prefix = project.id + "/" + created;
        } else if (this.props.view === "manual-archive") {
            permission = "pdl";
            let projectId = this.props.manualArchive.id;
            let created = this.props.manualArchive.created;
            prefix = projectId + "/" + created;
        } else if (this.props.isRunArchive === true) { // run-archive
            permission = "pdl";
            target = this.props.targets[this.props.targetIndex]
            run = this.props.runArchive
            prefix = target.id + "-" + target.created + "-" + run.created
        } else if (this.props.view === "import") {
            /*let target = this.props.targets[this.props.targetIndex];
            let run = this.props.run;
            prefix = target.id + "-" + target.created + "-" + run.created;*/
            prefix = "pdl"
        } else if (this.props.view === "list") {
            let project = this.props.projects[this.props.projectIndex]
            prefix = project.id + "/" + this.props.list.id
        } else if (this.props.view === "release-checklist") {

        } else {
            prefix = target.id + "-" + target.created + "-" + run.created;
        }

        let playwright_trace_included = false

        const renderFile = (f, permission, prefix, num) => {
            let preview_url = "/results/files/" + permission + "/" + prefix + "/" + num + "/" + f;
            let url = Constants.baseFileUrl + preview_url;
            let inline_url = Constants.baseUrl + preview_url;
            if (this.props.view === "manual") {
                preview_url = "/manual/files/" + permission + "/" + prefix + "/" + num + "/" + f;
                url = Constants.baseFileUrl + "/manual/files/" + permission + "/" + prefix + "/" + num + "/" + f;
                inline_url = Constants.baseUrl + "/manual/files/" + permission + "/" + prefix + "/" + num + "/" + f;
            }
            if (this.props.view === "manual-archive") {
                preview_url = "/manual-archive/files/" + permission + "/" + prefix + "/" + num + "/" + f;
                url = Constants.baseFileUrl + "/manual-archive/files/" + permission + "/" + prefix + "/" + num + "/" + f;
                inline_url = Constants.baseUrl + "/manual-archive/files/" + permission + "/" + prefix + "/" + num + "/" + f;
            }
            if (this.props.isRunArchive === true) {
                preview_url = "/run-archive/files/" + permission + "/" + prefix + "/" + num + "/" + f
                url = Constants.baseFileUrl + preview_url
                inline_url = Constants.baseUrl + preview_url
            }
            let classNameValue = "caseLink";
            if (imageEnlarged === url) {
                classNameValue = "caseLink img-full-width";
            }
            const downloadButton = <button type="button" className='btn btn-white-short'><img src="/img/download.svg" width="18" height="18"/></button>
            if (Constants.hasFileExtension(url, [".jpg", ".jpeg", ".png"])) {
                files.push(<div key={fNum}><p><a href={url} className="caseLink" download={f}>{f} {downloadButton}</a></p><p><div className={classNameValue} onClick={() => imageClick(url)}><img className="img-case-detail" alt="" src={inline_url} crossOrigin="anonymous"/></div></p></div>)
            } else if (Constants.hasFileExtension(url, [".csv"])) {
                let chartEnabled = this.state.chartEnabled;
                if (chartEnabled[f] === undefined) {
                    chartEnabled[f] = false;
                }

                if (chartEnabled[f] === false) {
                    files.push(<p key={fNum}><a href={url} className="caseLink"  download={f}>{f} {downloadButton}</a>&nbsp;&nbsp;<button className="btn-confirm" onClick={() => this.chartEnable(f)}>Chart</button></p>);
                } else {
                    files.push(
                    <div key={fNum}>
                        <a href={url} className="caseLink"  download={f}>{f} {downloadButton}</a>
                        <br/>
                        <CsvData url={url} name={f} close={this.chartEnable}/>
                    </div>);
                }                    
            } else if (Constants.hasFileExtension(url, [".txt", ".log"])) {
                let textFileViewing = this.state.textFileView;
                if (textFileViewing[f] === undefined) {
                    textFileViewing[f] = false;
                }
                if (textFileViewing[f] === false) {
                    files.push(<p key={fNum}><a href={url} className="caseLink" download={f}>{f} {downloadButton}</a>&nbsp;&nbsp;<button className="btn-confirm" onClick={() => this.textFileView(f)}>View</button></p>)
                } else {
                    files.push(
                        <div key={fNum}>
                            <a href={url} className="caseLink" download>{f} {downloadButton}</a>
                            <br/>
                            <LogView url={preview_url} name={f} close={this.textFileView}/>
                        </div>);
                }
            } else if (Constants.hasFileExtension(url, [".mp4", ".mov"])) {
                files.push(<div key={fNum}><p><a href={url} className="caseLink" download={f}>{f} {downloadButton}</a></p><p><div><video className="width100" controls><source src={url}/></video></div></p></div>) 
            } else if (Constants.hasFileExtension(url, [".html", ".htm"])) {
                files.push(<div key={fNum}><p><a href={url} className="caseLink" download={f}>{f} {downloadButton}</a></p><p><div>&nbsp;&nbsp;<a href={"/file-viewer/html/" + inline_url} className="caseLink" target="_blank"><button className="btn btn-sm btn-confirm">View in browser</button></a></div></p></div>) 
            } else if (Constants.hasFileExtension(url, [".zip"])) {
                let fDisplay = f
                let playwrightTraceViewOneClickButton = <span></span>
                if (f.length > 4) {
                    fDisplay = f.substring(0, f.length - 4);
                }
                if (fDisplay === "trace") {
                    if (run !== undefined) {
                        if (run.integrationName === "playwright-tesults-reporter") {
                            playwright_trace_included = true
                            const trace_file_source = "results/files/" + permission.substr(1) + "/" + prefix + "/" + num + "/" + f;
                            playwrightTraceViewOneClickButton = <button className='btn-cancel' onClick={() => this.playwrightTraceViewOneClick(trace_file_source)} disabled={this.state.playwrightTraceViewOneClickButtonDisabled}><small>View Trace</small></button>
                        }
                    }
                }
                files.push(<div key={fNum}><a href={url} className="caseLink"  download={f}>{fDisplay} {downloadButton}</a>&nbsp;&nbsp;{playwrightTraceViewOneClickButton}</div>)
            } else {                          
                files.push(<p key={fNum}><a href={url} className="caseLink"  download={f}>{f} {downloadButton}</a></p>)
            }
            fNum += 1;
        }

        if (selectedCase.files !== undefined) {
            if (Array.isArray(selectedCase.files)) {
                if (selectedCase.files.length > 0) {
                    selectedCase.files.forEach(function (f) {
                        let prefixParam = prefix
                        if (batch !== undefined) {
                            prefixParam += "-" + batch;
                        }
                        renderFile(f, permission, prefixParam, num)
                    }.bind(this));
                }
            }
        }

        if (selectedCase.refFiles !== undefined) {
            if (Array.isArray(selectedCase.refFiles)) {
                if (selectedCase.refFiles.length > 0) {
                    selectedCase.refFiles.forEach(function (refFile) {
                        let prefixParam = prefix
                        if (refFile.batch !== undefined) {
                            prefixParam += "-" + batch;
                        }
                        renderFile(refFile.file, permission, prefixParam, refFile.num)
                    }.bind(this));
                }
            }
        }

        if (files.length > 0) {
            if (playwright_trace_included === true) {
                files.push(
                    <div className='neutral4' key={"playwright_trace"}>
                        <p>This test case includes a Playwright trace file. Click the button next to the trace link to download it and then view the trace at <a href="https://trace.playwright.dev" className="caseLink"  target="_blank">https://trace.playwright.dev</a> by uploading it. Alternatively click the view trace button to do this in one click.</p>
                    </div>
                )
            }
            files = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Files</span>} value={files} darkMode={this.props.darkMode}/>
        }

        let caseHistoryDiv = <span></span>;
        let task = <span></span>;
        let flaky = <span></span>;
        let bug = <span></span>;
        let cost = <span></span>
        let resultChangeDiv = <span></span>;
        if (selectedCase.resultOriginal !== undefined) {
            resultChangeDiv = <p><small>Original result: {selectedCase.resultOriginal}<br/>Change reason: {selectedCase.resultChangeReason}<br/>Changed by: {this.state.changeBy}</small></p>
        }
        
        let map = undefined
        if (this.props.view === "tasks") {
            map = utilsRawResultMapMapForTask(selectedCase, this.props.rawResultMaps)
        } else {
            map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)
        }
        
        let resultValue = 
        <div>
            <Result result={selectedCase.result} rawResult={selectedCase.rawResult} map={map} runMetadata={runMetadata} case={selectedCase}/>
            {resultChangeDiv}
        </div>
        let result = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Result</span>} value={resultValue} darkMode={this.props.darkMode}/>
        if (this.props.view === "list") {
            result = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Defaut result</span>} value={resultValue} darkMode={this.props.darkMode}/>
        }
        if (this.props.view === "results" || this.props.view === "supplemental") {
            if (selectedCase.suite !== "[build]") {
                caseHistoryDiv = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>History</span>} value={this.caseHistory()} darkMode={this.props.darkMode}/>
                task = <TaskCase mobile={this.state.mobile} trl={this.props.trl} view={this.props.view} project={this.props.projects[this.props.projectIndex]} target={this.props.targets[this.props.targetIndex]} case={selectedCase} darkMode={this.props.darkMode}/>
            }
        } else if (this.props.view === "tasks") {
            caseHistoryDiv = <span></span>;
            task = <TaskCase mobile={this.state.mobile} trl={this.props.trl} view={this.props.view} taskChanged={this.props.taskChanged} project={selectedCase.task.project} target={selectedCase.task.target} case={selectedCase} darkMode={this.props.darkMode}/>
        } else if (this.props.view === "manual" || this.props.view === "manual-archive" || this.props.view === "list") {
            
        } else {
            console.log("Invalid props view.");
        }

        if (this.state.mobile === true) {
            
        }

        let modalDialogClass = "modal-dialog modal-lg-tr";
        if (Object.keys(this.state.chartEnabled).length === 0 && this.state.chartEnabled.constructor === Object) {
            modalDialogClass = "modal-dialog modal-lg";
        }

        let editEnabled = false
        let editButton = <div></div>
        if (this.props.trl !== true) {
            if (this.props.role !== undefined) {
                if (this.props.role >= 2) {
                    // Removed for now: editButton = <button type="button" className={editButtonClass} onClick={() => this.props.editCase(this.props.selectedCaseRaw)}>Edit</button>
                }
            }
        }

        let manualEditHistory = <span></span>
        if (this.props.view === "manual" || this.props.view === "list") {
            if (this.props.trl !== true) {
                editButton = <button type="button" data-dismiss="modal" className={editButtonClass} onClick={() => this.props.editCase(this.props.selectedCaseRaw)}><IconLabel icon="/img/edit-item.svg" label="Edit"/></button>
                editEnabled = true
            }
            
            // manual meta data
            if (selectedCase.metadata !== undefined) {
                if (selectedCase.metadata.editHistory !== undefined) {
                    let editHistoryArray = selectedCase.metadata.editHistory;
                    if (editHistoryArray.length > 0) {
                        let editHistory = editHistoryArray[editHistoryArray.length - 1];
                        manualEditHistory = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Last Edited</span>} value={<span>{TimeFormatted.timeformatted(editHistory.time) + " by " + editHistory.firstName + " " + editHistory.lastName}</span>} darkMode={this.props.darkMode}/>
                    }
                }
            }
        }

        if (this.props.view === "import") {
            editButton = <div></div>
            editEnabled = false
        }

        let deleteEnabled = false
        let deleteButton = <div></div>
        let duplicateEnabled = false
        let duplicateButton = <div></div>
        if (this.props.trl !== true) {
            if (this.props.view === "list" || this.props.view === "manual") {
                deleteButton = <button type="button" data-dismiss="modal" className={editButtonClass} onClick={() => this.props.deleteCase(this.props.selectedCaseRaw)}><IconLabel icon="/img/delete-item.svg" label="Delete"/></button>
                deleteEnabled = true
                duplicateButton = <button type="button" data-dismiss="modal" className={editButtonClass} onClick={() => this.props.duplicateCase(this.props.selectedCaseRaw)}><IconLabel icon="/img/duplicate-alt.svg" label="Duplicate"/></button>
                duplicateEnabled = true
            }
        }

        if (this.props.view === "import") {
            deleteButton = <div></div>
            deleteEnabled = false
        }

        let markEnabled = false
        let markButton = <div></div>
        if (this.props.trl !== true) {
            if (this.props.view === "manual") {
                if (selectedCase.manualComplete !== undefined) {
                    if (selectedCase.manualComplete === true || selectedCase.manualComplete === "true") {
                        markButton = <button type="button" data-dismiss="modal" className={editButtonClass} onClick={() => this.props.markDone(this.props.selectedCaseRaw, false)}><IconLabel icon="/img/close.svg" label="Mark Not Done"/></button>
                        markEnabled = true
                    } else {
                        markButton = <button type="button" data-dismiss="modal" className={editButtonClass} onClick={() => this.props.markDone(this.props.selectedCaseRaw, true)}><IconLabel icon="/img/checkmark.svg" label="Mark Done"/></button>
                        markEnabled = true
                    }
                }
            }
        }
        
        if (selectedCase.flaky !== undefined) {
            if (selectedCase.flaky === true) {
                flaky = <div className="neutral1 mt-3"><img src="/img/flaky-dark-128.png" alt="flaky" width="18" height="18"/> Possible flaky test case. Result has flipped a number of times, see history.</div>
            }
        }

        if (selectedCase.bugs !== undefined) {
            if (selectedCase.bugs.length > 0) {
                bug = <div className="neutral1 mt-3"><img src="/img/bug.svg" alt="bug" width="18" height="18"/> There are associated bugs for this test case.</div>
            }
        }

        if (selectedCase.cost !== undefined) {
            if (selectedCase.cost.value !== 0) {
                cost = <div className="neutral1 mt-3"><img src="/img/currency-dollar.svg" alt="cost" width="18" height="18"/> <Currency currency={selectedCase.cost.currency}/>{selectedCase.cost.value.toFixed(2) + "/" + selectedCase.cost.period + " cost if test failure in production."} </div>
            }
        }

        let steps = <span></span>
        if (selectedCase.steps !== undefined) {
            if (Array.isArray(selectedCase.steps)) {
                let stepsArray = [];
                for (let i = 0; i < selectedCase.steps.length; i++) {
                    let step = selectedCase.steps[i];
                    if (step.name === undefined || step.result === undefined) {
                        stepsArray.push(
                            <div className="mb-3" key={i}>
                                <p className="neutral4">Invalid step reported: at a minimum, a step must have a name and a result.</p>
                            </div>
                        );
                    } else {
                        let custom = [];
                        Object.keys(step).forEach(function (key) {
                            if (key.indexOf("_") === 0) {
                                custom.push(<CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} key={key} label={key.substring(1)} value={step[key]} darkMode={this.props.darkMode}/>);
                            }
                        }.bind(this));
                        let imgResult = "/img/tesults-logo-unknown-128.png";
                        if (step.result.toLowerCase() === "pass") {
                            imgResult = "/img/tesults-logo-pass-128.png";
                        } else if (step.result.toLowerCase() === "fail") {
                            imgResult = "/img/tesults-logo-fail-128.png";     
                        }
                        let start = <span></span>
                        let end = <span></span>
                        let duration = <span></span>
                        if (step.start !== undefined || step.end !== undefined || step.duration !== undefined) {
 
                            if (step.start !== undefined) {
                                if (isNaN(step.start) === false) {
                                    start = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Start</span>} value={<TimeFormatted dt={step.start}/>} darkMode={this.props.darkMode}/>
                                }
                            }
                            
                            if (step.end !== undefined) {
                                if (isNaN(step.end) === false) {
                                    end = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>End</span>} value={<TimeFormatted dt={step.end}/>} darkMode={this.props.darkMode}/>
                                }
                            }
                
                            if (step.duration !== undefined || (step.start !== undefined && step.end !== undefined)) {
                                duration = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Duration</span>} value={<Duration durationOnly={true} c={step}/>} darkMode={this.props.darkMode}/>
                            }
                        }
                        
                        let reason = <span></span>
                        if (step.reason !== undefined) {
                            reason = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Failure Reason</span>} value={step.reason} darkMode={this.props.darkMode}/>
                        }

                        stepsArray.push(
                            <div className="mb-3" key={i}>
                                <div style={{"display":"flex"}}>
                                    <div className="mr-3">
                                        {step.name}
                                    </div>
                                    <div>
                                    <img src={imgResult} alt="" width="20" height="20"/>
                                    </div>
                                </div>
                                <div className="neutral4">
                                    {step.desc}
                                </div>
                                <div>
                                    {reason}
                                </div>
                                <div>
                                    {custom}
                                </div>
                                <div>
                                    {start}
                                </div>
                                <div>
                                    {end}
                                </div>
                                <div>
                                    {duration}
                                </div>
                            </div>
                        );
                    }
                }
            
                steps = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Steps</span>} value={stepsArray} darkMode={this.props.darkMode}/>
            }
        }

        let desc = <span></span>
        if (selectedCase.desc !== undefined && selectedCase.desc !== null && selectedCase.desc !== "") {
            desc = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Description</span>} value={selectedCase.desc} darkMode={this.props.darkMode}/>
        }

        let priority = <span></span>
        if (selectedCase.priority !== undefined) {
            let priorityInt = parseInt(selectedCase.priority, 10);
            if (priorityInt >= 0) {
                priority = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Priority</span>} value={<CasePriority case={selectedCase}/>} darkMode={this.props.darkMode}/>
            }
        }

        let start = <span></span>
        let end = <span></span>
        let duration = <span></span>

        if (selectedCase.start !== undefined || selectedCase.end !== undefined || selectedCase.duration !== undefined) {
 
            if (selectedCase.start !== undefined) {
                if (isNaN(selectedCase.start) === false) {
                    start = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Start</span>} value={<TimeFormatted dt={selectedCase.start}/>} darkMode={this.props.darkMode}/>
                }
            }
            
            if (selectedCase.end !== undefined) {
                if (isNaN(selectedCase.end) === false) {
                    end = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>End</span>} value={<TimeFormatted dt={selectedCase.end}/>} darkMode={this.props.darkMode}/>
                }
            }

            if (selectedCase.duration !== undefined || (selectedCase.start !== undefined && selectedCase.end !== undefined)) {
                duration = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Duration</span>} value={<Duration durationOnly={true} c={selectedCase}/>} darkMode={this.props.darkMode}/>
            }
        }
        
        
        if (this.state.edit === true) { // Aug 10 2022 -> Unclear if this state is still possible with current UI, no edit button in results view.
            let resultEditOptions = [];
            if (selectedCase.result.toLowerCase() !== "pass") {
                resultEditOptions.push(<option key={0} value="pass">Pass</option>);
            }
            if (selectedCase.result.toLowerCase() !== "fail") {
                resultEditOptions.push(<option key={1} value="fail">Fail</option>);
            }
            if (selectedCase.result.toLowerCase() !== "unknown") {
                resultEditOptions.push(<option key={2} value="unknown">Unknown</option>);
            }

            return (
                <div className="modal fade" id={this.props.modalId === undefined ? "myModal" : this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className={modalDialogClass} role="document">
                    <div className="modal-content">
                        <div className={bgHeaderClass}>
                        <div className="modal-title" id="myModalLabel">
                            <h5 className="wrapbtn"><span className={headerFontColor}>{selectedCase.name}</span></h5>
                            
                            <br/>
                            
                            <br/>
                            {flaky}
                            {bug}
                            {cost}
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                        {result}
                        <br/>
                        <span className="neutral3 bold small">EDIT RESULT</span>
                        <br/>
                        <select className="custom-select mb-3 width25" onChange={this.resultChange}>
                            {resultEditOptions}
                        </select>
                        <br/>
                        <span className="neutral3 bold small">EDIT REASON (e.g. false negative):</span>
                        <input type="text" className="form-control mb-3" value={this.state.resultChangeReason} onChange={this.resultChangeReason}/>
                        <p className="font14">The results page must be refreshed to see updated results. It can take up to a minute for team members to see updated results.</p>
                        <button type="button" className="btn-confirm" onClick={this.editComplete}>Confirm Result Change</button>
                        </div>
                        {manualEditHistory}
                        <div className={bgFooterClass}>
                        <button type="button" className={editButtonClass} onClick={this.cancelEdit}>Cancel Edit</button>
                        <button type="button" className={editButtonClass} data-dismiss="modal">Close</button>
                        </div>
                    </div>
                    </div>
                </div>
            );
        } else {
            let projectId = undefined;
            let targetCreated = undefined;
            if (selectedCase.task !== undefined) {
                let id = selectedCase.task.id;
                projectId = id.substring(0, id.lastIndexOf("-"));
                targetCreated = id.substring(id.lastIndexOf("-") + 1);
            } else if (this.props.view === "release-checklist") {

            } else {
                if (this.props.projectIndex < this.props.projects.length) {
                    projectId = this.props.projects[this.props.projectIndex].id;
                }
                if (this.props.view !== "manual-archive") {
                    if (this.props.targetIndex < this.props.targets.length) {
                        targetCreated = this.props.targets[this.props.targetIndex].created;
                    }
                }
            }

            if (this.props.view === "list") {
                targetCreated = this.props.list.created;
                selectedCase.hash = selectedCase.num;
            }

            let assignee = <span></span>
            if (this.props.view === "manual") {
                targetCreated = this.props.manual.created;
                selectedCase.hash = selectedCase.num;
            }
            if (this.props.view === "manual" || this.props.view === "manual-archive") {
                if (selectedCase.manualAssignee !== undefined && this.props.members !== undefined) {
                    let user = undefined;
                    for (let i = 0; i < this.props.members.length; i++) {
                        let member = this.props.members[i];
                        if (member.id === selectedCase.manualAssignee) {
                            user = member;
                        }
                    }
                    assignee = <CaseDetailField collapseAll={this.state.collapseAll} label={<span>Assignee</span>} value={<Assignee user={user} overlay={this.props.overlay}/>}/>
                }
            }

            let suite = <span></span>
            if (selectedCase.suite !== undefined) {
                suite = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Suite</span>} value={<span>{selectedCase.suite}</span>} darkMode={this.props.darkMode}/>
            }

            let rawResult = <span></span>
            if (selectedCase.rawResult !== undefined) {
                rawResult = <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Raw Result</span>} value={<span>{selectedCase.rawResult}</span>} darkMode={this.props.darkMode}/>
            }

            let project = undefined;
            let target = undefined;
            let run = undefined;
            try {
                project = this.props.projects[this.props.projectIndex];
            } catch (err) {
                project = undefined;
            }
            try {
                target = this.props.targets[this.props.targetIndex];
            } catch (err) {
                target = undefined;
            }
            try {
                run = this.props.runs[this.props.runIndex];
            } catch (err) {
                run = undefined;
            }

            return (
                <div className="case-detail" onClick={this.caseDetailClick}>
                    <div className="case-detail-header" ref={this.ref}>
                        <div className='flex-row mt-4'>
                            <div className="case-detail-title">
                                <span className={"wrapbtn " + headerFontColor}>{selectedCase.name}</span>
                            </div>
                            <div className="case-detail-close">
                                <button onClick={() => this.props.sideOverlay(undefined)} className="btn-transparent btn-close">&times;</button>
                            </div>
                        </div>
                        <div className="case-detail-project-and-target">
                            <div className="case-detail-project-and-target-details">
                                <Project projects={this.props.projects} projectIndex={this.props.projectIndex}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    (this.props.targets !== undefined) ? 
                                    <Target targets={this.props.targets} targetIndex={this.props.targetIndex}/>
                                    :
                                    <div></div>
                                }
                            </div>
                            <div className="case-detail-collapse-all mr-3">
                                <button type="button" className='btn btn-white' onClick={this.toggleCollapseAll}>
                                <div style={{"display":"flex", "alignItems":"center"}}>
                                    <div className='mr-3'>
                                        <div style={{"marginTop":"4px"}}>
                                            {this.state.collapseAll === true ? <img src="/img/expand1.svg" alt="" width="18" height="18"/> : <img src="/img/collapse1.svg" alt="" width="18" height="18"/>}
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.collapseAll === true ? "Expand" : "Collapse"}
                                    </div>
                                </div>
                                </button>
                            </div>
                            <div className="case-detail-share">
                                <SelectOverlay
                                    overlay={this.props.overlay}
                                    messageOverlay={this.props.messageOverlay}
                                    type="share"
                                    image={<img src="/img/share-btn.svg" width="40" height="40" alt=""/>} 
                                    content={this.props.share}
                                    contentWidth={240}
                                    active={this.selectOverlayActive}
                                />
                            </div>
                        </div>
                        <div className='case-detail-enhanced-analysis'>
                            <CaseEnhancedAnalysis 
                                overlay={this.props.overlay}
                                messageOverlay={this.props.messageOverlay}
                                type="case-detail"
                                project={project}
                                data={this.props.caseEnhancedAnalysis}
                                selectedCase={selectedCase}
                                active={this.selectOverlayActive}
                                view={this.props.view}
                            />
                        </div>
                        <div className="case-detail-icons">
                            {flaky}
                            {bug}
                            {cost}
                        </div>
                        {
                        (editEnabled === true || deleteEnabled === true || markEnabled === true || duplicateEnabled === true) ? 
                        <div className='flex-row'>
                            {markButton}
                            {editButton}
                            {duplicateButton}
                            {deleteButton}
                        </div>
                        :
                        <div></div>
                    }   
                    </div>           
                    <div className="case-detail-body">
                        {result}
                        {params}
                        {suite}
                        {priority}
                        {desc}
                        {failureReason}
                        <Bugs collapseAll={this.state.collapseAll} selectOverlayActive={this.selectOverlayActive} project={project} target={target} run={run} projectId={projectId} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} mobile={this.state.mobile} trl={this.props.trl} view={this.props.view} case={selectedCase} persistentCaseDataUpdate={this.props.persistentCaseDataUpdate} darkMode={this.props.darkMode}/>
                        <Cost collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} view={this.props.view} case={selectedCase} persistentCaseDataUpdate={this.props.persistentCaseDataUpdate} darkMode={this.props.darkMode}/>
                        {task}
                        {steps}
                        {files}
                        {custom}
                        {start}
                        {end}
                        {duration}
                        {assignee}
                        {rawResult}
                        {caseHistoryDiv}
                        {this.rspCaseLink()}
                        {
                            this.props.view === "import" ?
                            <div></div>
                            :
                            <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Comments</span>} value={<Comments trl={this.props.trl} id={projectId} created={targetCreated} hash={selectedCase.hash} view={this.props.view}/>} darkMode={this.props.darkMode}/>
                        }
                        {manualEditHistory}
                        {/*
                        <div>
                            {target ? 
                                <CaseDetailField collapseAll={this.state.collapseAll} mobile={this.state.mobile} trl={this.props.trl} label={<span>Times run</span>} value={<StatView name={selectedCase.name} id={target.id} created={target.created} hash={selectedCase.hash}/>} darkMode={this.props.darkMode}/>                                
                                :
                                <div></div>
                            }
                        </div>
                        */}
                    </div>
                </div>
            );
        }
    }
}

export default CaseDetail;