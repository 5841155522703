/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';
import Notice from './Notice';

class DocsPython extends Component {
    render() {
        const helmet =
        <Helmet>
            <title>Python test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Python test reporting with Tesults."/>
        </Helmet>

        const plugins =
        <div className='mt-5 mb-5'>
            <Notice type="information" content={
            <div>
                <b>Using pytest?</b> If you use the <a href="https://docs.pytest.org/en/latest/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">pytest test framework</a> no code is necessary to integrate, click <NavLink to="/docs/pytest" target="_blank" className="tr-link-primary4 no-break no-break">here</NavLink> to view documentation for the <NavLink to="/docs/pytest" target="_blank" className="tr-link-primary4 no-break no-break">Tesults pytest plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using Robot Framework?</b> If you use the <a href="https://robotframework.org/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Robot Framework</a> for Python no code is necessary to integrate, click <NavLink to="/docs/robot" target="_blank" className="tr-link-primary4 no-break no-break">here</NavLink> to view documentation for the <NavLink to="/docs/robot" target="_blank" className="tr-link-primary4 no-break no-break">TesultsListener for Robot Framework</NavLink> and ignore the instructions below.
            </div>}/>
        </div>
        const installation =
        <div>
            <p>The Tesults python package is hosted on PyPI: <a className="tr-link-primary4 no-break" href="https://pypi.python.org/pypi/tesults">https://pypi.python.org/pypi/tesults</a> and is compatible with Python 2 and 3.</p>
            <pre className="docsCode">
                <code className="accentb4">pip install tesults</code>
            </pre>
        </div>

        const configuration =
        <pre className="docsCode">
            <code className="accentb4">import tesults</code>
        </pre>

        const usage =
        <div>
            <p>Upload your test results using the results method.</p>
            <pre className="docsCode">
                <code className="accentb4">tesults.results(data)</code>
            </pre>
            <p className="mt-3">This call returns a dictionary indicating success or failure.</p>
            <pre className="docsCode">
                ret = <code className="accentb4">tesults.results(data)</code><br/>
                print ('Success: ' + str(ret['success']))<br/>
                print ('Message: ' + str(ret['message']))<br/>
                print ('Warnings: ' + str(ret['warnings']))<br/>
                print ('Errors: ' + str(ret['errors']))<br/>
                # ret['success'] is a bool, true if results successfully uploaded, false otherwise<br/>
                # ret['message'] is a string, if success is false, check message for reason<br/>
                # ret['warnings'] is an array of strings, if non empty then issue with file upload<br/>
                # ret['errors'] is an array of strings, if success is true this will be empty
            </pre>
        </div>

        const example =
        <div>
            <p className="mt-3">The data param in results is a dictionary containing your build and test results in the form:</p>
            <pre className="docsCode">
                data = {'{'}<br/>
                &nbsp;&nbsp;'target': 'token',<br/>
                &nbsp;&nbsp;'results': {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'cases': [<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'name': 'Test 1',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'desc':'Test 1 description.',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'suite': 'Suite A',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'result': 'pass'<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'name': 'Test 2',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'desc':'Test 2 description.',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'suite': 'Suite A',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'result': 'fail',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'reason': 'Assert fail in line 203, example.py',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'files': ['full/path/to/file/log.txt', 'full/path/to/file/screencapture.png'],<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'_CustomField': 'Custom field value',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'steps': {'['}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'name': 'Step 1',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'desc':'Step 1 description.',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'result': 'pass',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'name': 'Step 2',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'desc':'Step 2 description.',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'result': 'fail',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'reason': 'Assert fail in line 203, example.py',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{']'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'name': 'Test 3',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'desc':'Test 3 description.',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'suite': 'Suite B',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'start': int(round(time.time() * 1000)) - 60000,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'end': int(round(time.time() * 1000)),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'result': 'pass',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'params': {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'param1' : 'value1',<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'param2' : 'value2'<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                &nbsp;&nbsp;{'}'}<br/>
                {'}'}
            </pre>
            <p className="mt-3">The target value, <b>'token'</b>, above should be replaced with your target token. If you have lost your token you can regenerate one in the config menu. The cases array should you contain your test cases. You would usually add these by looping through the test case objects you currently have in your build and test scripts.</p>
            <p>Each test case added to cases must have a name and a result. The result value must be one of 'pass', 'fail' or 'unknown'. All other fields are optional: desc, suite, files, params, steps, reason and custom fields (prefixed with underscore).</p>
            <p>The 'params' value is for use by parameterized test cases and is a dictionary containing the parameter names and values.</p>
            <p>For the above example some imports are required:</p>
            <pre className="docsCode">
            import sys<br/>
            import os<br/>
            import time<br/>
            import tesults<br/>
            </pre>
        </div>

        return (
            <DocsLanguage
                language="python"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsPython;