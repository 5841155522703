/*global */
import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Context from './Context'
import Cache from './Cache'
import Privacy from './Privacy'
import SelectOverlay from './SelectOverlay'
import Box from './Box'

import ConfigTargetName from './ConfigTargetName'
import ConfigTargetDesc from './ConfigTargetDesc'
import ConfigTargetImage from './ConfigTargetImage'
import ConfigTargetDelete from './ConfigTargetDelete'
import ConfigTargetDeleteRun from './ConfigTargetDeleteRun'
import ConfigTargetToken from './ConfigTargetToken'
import ConfigTargetAPI from './ConfigTargetAPI'
import ConfigTargetCreate from './ConfigTargetCreate'
import ConfigTargetRawResultMap from './ConfigTargetRawResultMap'
import StatView from './StatView'
import ConfigReportingCaseDisplayAttributeMap from './ConfigReportingCaseDisplayAttributeMap'

class ConfigTargets extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {targets: [], targetIndex: 0, loading: false}
        this.targets = this.targets.bind(this)
        this.targetChange = this.targetChange.bind(this)
        this.targetAdded = this.targetAdded.bind(this)
        this.targetUpdated = this.targetUpdated.bind(this)
        this.targetState = this.targetState.bind(this)
    }

    componentDidMount () {
        this.targets()
    }

    targets (override) {
        window.scrollTo(0,0);
        let projects = this.context.projects;
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projects === undefined) {
            return
        }
        if (projects.length === 0) {
            return
        }
        if (projectIndex >= projects.length) {
            projectIndex = 0
        }
        let project = projects[projectIndex]
        this.setState({loading:true});
        let params = {id: project.id};
        if (override === true) {
            params.override = true;
        }
        Cache.request(this.context, Cache.data.targets, params, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({targets: targets, loading: false});
            }
        });
    }

    targetChange (value) {
        Cache.setPreference(Cache.preference.targetIndex, value);
        this.setState({targetIndex: value});
    }
    
    targetAdded (target) {
        let targets = this.state.targets
        targets.push(target)
        this.setState({targets: targets})
    }

    targetUpdated (target) {
        let targets = this.state.targets;
        for (let i = 0; i < targets.length; i++) {
            let t = targets[i];
            if (t.id === target.id && t.created === target.created) {
                targets[i] = target;
            }
        }
        this.setState({targets: targets});
    }

    targetState () {
        const states = [
            "editTargetName",
            "target-name",
            "editTargetDesc",
            "target-desc",
            "regenerateTargetToken",
            "regenerate-target-token",
            "targetTokenRegenerated",
            "target-token-regenerated",
            "deleteTarget",
            "delete-target",
            "deleteRun",
            "delete-run",
            "targetAvatar",
            "target-image",
            "targetPrivacy",
            "target-privacy",
            "target-api-edit"
        ];
        return states.includes(this.state.state);
    }

    render () {
        let projects = this.context.projects
        if (projects === undefined) { projects: []}
        // Target config start
        let targetIndex = 0;
        let targetOptions = [];
        this.state.targets.forEach(function (target) {
            targetOptions.push({name: target.name, value: targetIndex, id: target.id, created: target.created, aext: target.aext});
            targetIndex += 1;
        });

        let targetSelect =
        <div>
            <div className="neutral4 font14 mb-3">
                Select a target to configure
            </div>
            <SelectOverlay 
            overlay={this.props.overlay} 
            messageOverlay={this.props.messageOverlay}
            type="target-preview"
            title="Target" 
            label="name" 
            value="value"
            options={targetOptions} 
            index={this.state.targetIndex}
            valueChange={this.targetChange} 
            defaultValue={this.state.targetIndex}
            />
        </div>
        if (targetOptions.length === 0) {
            return (
                <div>
                    <h4>Targets <NavLink to="/docs/target" target="_blank" className="tr-link-primary4 font14 ml-3">What is a target?</NavLink></h4>
                    <p>There are no targets for this project.</p>
                    <br/>
                    <br/>
                    <ConfigTargetCreate targetAdded={this.targetAdded}/>
                </div>
            )
        } else {
            let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
            let createNewTarget = <div onClick={this.createTarget}><button type="button" className="btn btn-confirm" onClick={this.backToConfigureProjects}>Add new target</button></div>
            if (projects[projectIndex].free === true && this.state.role >= 4) {
                displayUpgrade = <UpgradePlan projectPlan={this.projectPlan}/>
            }
        }
        // Target config end  
        let target = undefined
        if (this.state.targetIndex < this.state.targets.length) {
            target = this.state.targets[this.state.targetIndex]
        }
        return (
            <div>
                <div className='mb-5'>
                    <ConfigTargetCreate targetAdded={this.targetAdded}/>
                </div>
                <hr className='dividor-light'/>
                <div style={{"display":"flex", "alignItems":"center"}}>
                    <div>
                        <h4>Configure Existing Target</h4>
                    </div>
                    <div style={{"marginLeft":"auto"}}>
                        <NavLink to="/docs/target" target="_blank" className="tr-link-primary4 font14 ml-3">What is a target?</NavLink>
                    </div>
                </div>
                <div className='mb-5'>
                    {targetSelect}    
                </div>
                <div className='mb-3'><ConfigTargetName target={target} targetUpdated={this.targetUpdated}/></div>
                <div className='mb-3'><ConfigTargetDesc target={target} targetUpdated={this.targetUpdated}/></div>
                <div className='mb-3'><ConfigTargetImage target={target}/></div>
                <div className='mb-4'><ConfigTargetRawResultMap target={target} targetUpdated={this.targetUpdated} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/></div>
                <div className='mb-4'><ConfigReportingCaseDisplayAttributeMap scope="target" target={target} targetUpdated={this.targetUpdated} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/></div>
                <div className='mb-4'><ConfigTargetDelete target={target} targets={this.targets}/></div>
                <div className='mb-4'><ConfigTargetDeleteRun target={target}/></div>
                <div className='mb-4'><ConfigTargetToken target={target}/></div>
                <div className='mb-4'><ConfigTargetAPI target={target} targetUpdated={this.targetUpdated}/></div>
                <div className='mb-5'><Privacy scope="target" target={target} targetUpdated={this.targetUpdated}/></div>
                <div className='mb-5 neutral4 font14'>Target Id: {target.created}</div>
                <br/>
                <br/>
                <br/>
                <div className='mt-5'>
                    {target ? <StatView name={target.name} id={target.id} created={target.created}/> : <div></div>}
                </div>
            </div>
        )
    }
};

export default ConfigTargets