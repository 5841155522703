/*global Cookies*/
import React, { Component } from 'react';

class Collapse extends Component {
    render () {
        return  this.props.collapse === true ?
            <img style={{"marginTop":"4px"}} src="/img/chevron.svg" alt="" width="24" height="24"/>
                        :
            <div style={{"transform": "rotate(90deg)", "marginLeft":"-8px", "marginTop":"4px"}}><img src="/img/chevron.svg" alt="" width="24" height="24"/></div>   
    }
}

export default Collapse