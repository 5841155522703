/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';
import Cache from './Cache'
import Context from './Context'
import Constants from './Constants'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request';
import SelectOverlay from './SelectOverlay';
import { IoTThingsGraph } from 'aws-sdk';

class ConfigTargetRawResultMap extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {edit: false, maps: [], selectedRawResultMap: undefined, esk: undefined, confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.edit = this.edit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.cancel = this.cancel.bind(this)
        this.save = this.save.bind(this)
        this.maps = this.maps.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.target !== undefined && prevProps.target !== undefined) {
            if (this.props.target.created !== prevProps.target.created) {
                this.init()
            }
        }
    }

    init () {
        this.maps()
    }

    maps () {
        if (this.props.target === undefined ) { return }
        this.setState({loading: true})
        Request.get("/raw-result-map-get-maps", {id: this.props.target.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, confirmation: {success: undefined, failure: "Error fetching data"}});
            } else {
                let maps = []
                if (data.maps !== undefined) {
                    maps = data.maps
                }
                let selectedRawResultMap = undefined
                if (this.props.target.rawResultMap) {
                    if (maps) {
                        for (let i = 0; i < maps.length; i++) {
                            let map = maps[i]
                            if (map.created === this.props.target.rawResultMap) {
                                selectedRawResultMap = map
                            }
                        }
                    }
                }
                this.setState({maps: maps, selectedRawResultMap: selectedRawResultMap, esk: data.lek, loading: false});
            }
        });
    }

    edit () {
        this.setState({edit: true})
    }

    onChange (value) {
        if (this.state.maps === undefined) { return }
        let selectedRawResultMap = undefined
        for (let i = 0; i < this.state.maps.length; i++) {
            let map = this.state.maps[i]
            if (map.created === value) {
                selectedRawResultMap = map
            }
        }
        this.setState({selectedRawResultMap: selectedRawResultMap})
    }

    cancel () {
        this.setState({edit: false})
    }

    save () {
        this.setState({confirmation: {success: undefined, failure: undefined}});
        let data = {
            id: this.props.target.id,
            created: this.props.target.created,
            rawResultMapCreated: this.state.selectedRawResultMap ? this.state.selectedRawResultMap.created : -1
        };
        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to edit target"}});
            } else {
                Analytics.event("EditedTargetRawResultMaps");
                this.setState({edit: false, confirmation: {success: "Target results map updated", failure: undefined}}, this.props.targetUpdated(data.target));
            }
        });
    }
    
    render () {
        if (this.props.target === undefined || this.state.maps === undefined) {
            return <div></div>
        }
        let rawResultMapOptions = [{label: "- None -", created: undefined}]
        for (let i = 0; i < this.state.maps.length; i++) {
            let map = this.state.maps[i]
            let option = {
                label: map.label,
                value: map.created,
                img: undefined
            }
            rawResultMapOptions.push(option)
        }

        return (
            <div>
                <div className="mb-1">
                    <label className="neutral4 font14">Target result interpretation map</label>
                </div>
                <div className='inputButtons'>
                    <div className="mb-4 mr-3 flex-1">
                        {
                            this.state.edit ?
                            <SelectOverlay
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                type="generic"
                                title={this.state.selectedRawResultMap ? this.state.selectedRawResultMap.label : "None"}
                                label="label"
                                value="value"
                                options={rawResultMapOptions}
                                valueChange={this.onChange}
                                defaultValue={this.state.selectedRawResultMap ? this.state.selectedRawResultMap.created : -1}
                                moreOptions={this.state.esk ? true: false}
                                getMoreOptions={this.maps}
                            />
                            :
                            <div>{this.state.selectedRawResultMap ? this.state.selectedRawResultMap.label : "None"}</div>
                        }
                        
                    </div>
                    <div className='flex-1'>
                        {
                            this.state.edit ?
                            <ConfirmationButtons 
                                confirm={this.save}
                                cancel={this.cancel}
                                confirmLabel="Update"
                                cancelLabel="Cancel"
                            />
                            :
                            <button className='btn-cancel' onClick={this.edit}>Edit</button>
                        }
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigTargetRawResultMap