/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class ChannelPartner extends Component {
    render () {        
        return (
            <div style={{"minHeight": "100vh", "display":"flex"}}>
                <div style={{"maxWidth":"600px"}}>
                    <h1 className="neutral1">Tesults Channel Partner Program</h1>
                    <div className="mb-5">
                        <img src="/img/tesults-logo-pass-128.png" className="resultspulsepass" width="32" height="32" alt="pass"/>
                        <img src="/img/tesults-logo-fail-128.png" className="resultspulsefail" width="32" height="32" alt="fail"/>
                    </div>
                    <p>
                        <a className="tr-link-primary4" href="https://www.tesults.com" target="_blank">Tesults</a> brings clarity to test results data. 
                        Software engineering teams push results data from across their test systems to Tesults for analysis and reporting. Tesults enables faster go-to-market with high quality releases, 
                        and provides assurance and an audit trail that risk is being managed responsibily. <a className="tr-link-primary4 no-break" href="https://www.tesults.com" target="_blank">See what Tesults can do</a>.
                    </p>
                    <h2 className="neutral1 mt-5 pt-5">Partnership</h2>
                    <p>
                        The Tesults Channel Partner Program helps software engineering teams integrate with <a className="tr-link-primary4" href="https://www.tesults.com" target="_blank">Tesults</a> via 
                        a channel partner, <b>your company</b>.
                    </p>
                    
                    <h2 className="neutral1 mt-5 pt-5">How channel partners are rewarded</h2>
                    <ul>
                        <li>Your business receives <u>50% of the first year of revenue</u> for an annual plan, paid out immediately after the subscription is activated.</li>
                        <li>Your team receives <u>free implementation training</u>.</li>
                        <li>Your team receives <u>free support with integration and follow up</u> questions related to setup and beyond.</li>
                        <li>Most importantly, <u>your clients benefit from having the best test results reporting solution available on the market</u>. <a className="tr-link-primary4" href="https://www.tesults.com" target="_blank">Tesults</a> is better than the competitors in most ways. Tesults is also simpler to install and maintain for the implementer. Your team can install Tesults in as little as five minutes if your clients are using popular test frameworks.</li>
                    </ul>
                    <h2 className="neutral1 mt-5 pt-5">How to enrol</h2>
                    <p>There are no costs and no obligations.</p>
                    <p>
                        The Tesults Channel Partner Program is open to businesses that participate in providing software consultancy services,
                        specifically related to software development and testing. A typical partner would be implementing test automation for
                        their client and as a part of this work setting up test reporting software.
                    </p>
                    <ol>
                        <li className="mb-3">
                            Email <a className="tr-link-primary4 no-break" href="mailto:channel-partner-program@tesults.com">channel-partner-program@tesults.com</a> and tell us you want to enrol as a channel partner.
                        </li>
                        <li className="mb-3">
                            A Tesults team member will get back to you and ask for some basic information about your company. Once this has been reviewed we will get back to you, usually with a confirmation that you are eligible.
                        </li>
                    </ol>
                    <p>
                        Become a channel partner today. We look forward to welcoming you.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
            
        )
    }
}

export default ChannelPartner