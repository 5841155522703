import React, { Component } from 'react';
import Confirmation from './Confirmation';
import ConfirmationButtons from './ConfirmationButtons';

class KeyValue extends Component {
    render () {
        return (
            <div style={{"display":"flex", "flexDirection":"column"}} className="width100 mb-3">
                <div>
                    <div className={"font14 " + (this.props.fontColor !== undefined ? this.props.fontColor : neutral4)}>{this.props.keyLabel + " (" + (this.props.index + 1) + ")"}</div>
                    <div><input className="tr-input-side" value={this.props.kv.key} onChange={(e) => this.props.keyValueChange(this.props.type, this.props.index, "key", e.target.value)}/></div>
                </div>
                <div>
                    <div className={"font14 " + (this.props.fontColor !== undefined ? this.props.fontColor : neutral4)}>{this.props.valueLabel  + " (" + (this.props.index + 1) + ")"}</div>
                    <div><input className="tr-input-side" value={this.props.kv.value} onChange={(e) => this.props.keyValueChange(this.props.type, this.props.index, "value", e.target.value)}/></div>
                </div>
                <div className='mt-2'>
                    <button type="button" className="btn-cancel" onClick={() => this.props.remove(this.props.index)}>{"Remove " + " (" + (this.props.index + 1) + ")"}</button>
                </div>
            </div>
        )
    }

}

export default KeyValue