/*global */
import React, { Component } from 'react';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class AffiliateDashboardVisits extends Component {
    constructor() {
        super();
        this.state = {visits: [], esk: undefined};
        this.visits = this.visits.bind(this);
    }

    componentDidMount () {
        this.visits();
    }

    visits () {
        if (this.props.id === undefined) {
            return;
        }
        Request.get("/affiliate-visits", {id: this.props.id, esk: this.state.esk}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                let visits = this.state.visits;
                for (let i = 0; i < data.visits.length; i++) {
                    visits.push(data.visits[i]);
                }
                this.setState({visits: visits, esk: data.esk});
            }
        });
    }

    render () {
        if (this.state.visits === undefined) {
            return <div></div>
        } else {
            let data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Time", minWidth: "200px"}
                ],
                rows: []
            }
            for (let i = 0; i < this.state.visits.length; i++) {
                let visit = this.state.visits[i];
                data.rows.push(
                    {
                        cols: [
                            {value: visit.id},
                            {value: TimeFormatted.timeformatted(visit.time, true)}
                        ]
                    }
                );
            }
            if (data.rows.length === 0) {
                return (
                    <div>
                        <h4 className="neutral3">Visits</h4>
                        <p>No visits yet</p>
                    </div>
                ); 
            } else {
                let oldestVisit = this.state.visits[this.state.visits.length - 1];
                let oldestVisitTime = TimeFormatted.timeformatted(oldestVisit.time, true);
                return (
                    <div>
                        <h4 className="neutral3">Visits</h4>
                        <div style={{"display":"flex"}}>
                            <div className="mr-3">
                                <span className="font14 neutral4">Since {oldestVisitTime}</span>
                            </div>
                            <div>
                                {
                                    this.state.esk === undefined ?
                                    <span></span>
                                    :
                                    <button className="btn-cancel" onClick={this.visits}>Load more</button>
                                }
                            </div>
                        </div>
                        <TabularData data={data}/>
                    </div>
                );
            }
        }
    }
};

export default AffiliateDashboardVisits;