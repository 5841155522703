/*global */
import React, { Component } from 'react'
import CaseDisplayAttribute from './CaseDisplayAttribute'
import Confirmation from './Confirmation'

class CaseDisplayAttributeMap extends Component {
    constructor () {
        super()
        this.state = {map: {label: "", attribute0: "name", attribute1: undefined, attribute2: undefined, attribute3: undefined, attribute4: "icons", attribute5: "result"}}
        this.attributeChange = this.attributeChange.bind(this)
    }

    componentDidMount () {
        if (this.props.map !== undefined) {
            this.setState({map: this.props.map})
        }
    }

    attributeChange (key, value) {
        let map = this.state.map
        if (map !== undefined) {
            map[key] = value
        }
        this.setState({map: map})
    }

    render () {
        return (
            <div>
                {
                    this.props.mode === "view" ?
                    <div className='mb-5 p-3 neutral7border solidborder rounderborder'>
                        <div className='font15 bold'>{this.state.map.label}</div>
                        <div className='flex-row'>
                            <CaseDisplayAttribute mode={this.props.mode} attribute="attribute0" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            <CaseDisplayAttribute mode={this.props.mode} attribute="attribute1" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            <CaseDisplayAttribute mode={this.props.mode} attribute="attribute2" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            <CaseDisplayAttribute mode={this.props.mode} attribute="attribute3" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            <CaseDisplayAttribute mode={this.props.mode} attribute="attribute4" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            <CaseDisplayAttribute mode={this.props.mode} attribute="attribute5" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                        </div>
                        <div><button className="btn-cancel" onClick={() => {this.props.update(this.props.map)}}>Edit</button></div>
                    </div>
                    :
                    <div>
                        <div className='font15 bold'>Test case display attributes</div>
                        <div className='font14 neutral4'>This is an example of what a test case in the results view looks like.</div>
                        <div><img src="/img/preferences-reporting-case-display-attributes.png" className='width100' alt="case-display-attributes"/></div>
                        <div className='font14 neutral4 mb-5'>You can change the fields that are displayed using an attribute map. The fields in orange can be changed. The fields in blue, the name of the test, icons and result cannot be changed. After creating a map, apply it to either the whole project or specific targets.</div>
                        <div className='font15 bold mb-5'>Attribute map</div>
                    </div>
                }
                <div>
                    {(this.props.mode === "create" || this.props.mode === "edit") ?
                        <div>
                            <div className='mb-3'>
                                <CaseDisplayAttribute mode={this.props.mode} attribute="label" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            </div>
                            <div className='flex-row mb-5'>
                                <CaseDisplayAttribute mode={this.props.mode} attribute="attribute0" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                                <CaseDisplayAttribute mode={this.props.mode} attribute="attribute1" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                                <CaseDisplayAttribute mode={this.props.mode} attribute="attribute2" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                                <CaseDisplayAttribute mode={this.props.mode} attribute="attribute3" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                                <CaseDisplayAttribute mode={this.props.mode} attribute="attribute4" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                                <CaseDisplayAttribute mode={this.props.mode} attribute="attribute5" map={this.state.map} attributeChange={this.attributeChange} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} />
                            </div>
                            <div className='flex-row'>
                                <button className='btn-confirm mr-3' onClick={() => this.props.save(this.state.map)}>Save</button>
                                {this.props.mode === "create" || this.props.mode === "edit" ?
                                    <button className='btn-cancel mr-5' onClick={() => this.props.cancel()}>Cancel</button>
                                    :
                                    <span></span>
                                }
                                {this.props.mode === "edit" ?
                                    <button className='btn-cancel' onClick={() => this.props.deleteConfirm(this.state.map)}>Delete this map</button>
                                    :
                                    <span></span>
                                }
                            </div>
                        </div>
                        :
                        <div></div>
                    }
                </div>
                <div>
                    <Confirmation confirmation={this.props.confirmation}/>
                </div>
            </div>
        )
    }
};

export default CaseDisplayAttributeMap