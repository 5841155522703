/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Checkout from './Checkout'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'
import Loading from './Loading'
import Pricing from './Pricing'
import Notice from './Notice'

class ConfigPlan extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {loading: false, state: "view", currentActiveUsers: 1}
        this.projectPlan = this.projectPlan.bind(this)
        this.projectOwner = this.projectOwner.bind(this)
        this.planSelect = this.planSelect.bind(this)
        this.planChanged = this.planChanged.bind(this)
        this.completeChange = this.completeChange.bind(this)
        this.productSelected = this.productSelected.bind(this)
        this.backToPlanSelect = this.backToPlanSelect.bind(this)
    }

    componentDidMount () {
        if (this.props.role >= 4) {
            this.projectPlan()
        }
    }

    projectPlan () {
        if (this.context.projects === undefined) { return }
        Analytics.event("Plan");
        this.setState({"loading": true})
        Request.get("/plan", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                this.setState({error: "Unable to retrieve plan details, please contact support."});
            } else {
                Request.get("/activeUsersCalculation", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id}, (err, data2) => {
                    let currentActiveUsers = this.state.currentActiveUsers;
                    if (err) {
                        currentActiveUsers = data.plan.activeUsers;
                    } else {
                        // A live current active user count, the plan count is calculated daily.
                        currentActiveUsers = data2.currentActiveUsers;
                    }
                    this.props.history.replace({ pathname: "/config/project/plan"});
                    this.setState({plan: data.plan, currentActiveUsers: currentActiveUsers, confirmation: {success: undefined, failure: undefined}}, () => this.projectOwner());
                });
            }
        });
    }
    
    projectOwner () {
        this.setState({"loading": true})
        Request.get("/projectOwner", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                this.setState({error: "Unable to retrieve project owner."});
            } else {
                this.setState({owner: data.owner});
            }
        });
    }

    planSelect () {
        Analytics.event("PlanSelect");
        this.setState({state: "planselect"});
    }

    productSelected (product) {
        Analytics.event("ProductSelected");
        if (product === "free-v1") {
            this.setState({state: "free", product: product});
        } else if (product === "enterprise") {
            this.setState({state: "enterprise"});
        } else {
            this.setState({state: "paid", product: product});
        }
    }

    planChanged (message) {
        this.setState({state:"planChanged", planChangedMessage: message});
    }

    completeChange () {
        let data = {};
        data.id = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id;
        data.product = this.state.product;
        data.interval =  this.state.interval;
        if (this.state.interval === undefined && this.state.product === "free-v1") {
            data.interval = "year";
        }
        data.activeUsers = this.state.plan.activeUsers;
        data.minUsers = this.state.plan.minUsers;
        this.setState({submitting: true});
        Request.post("/changeProject", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err}, submitting: false});                
            } else {
                Analytics.event("ChangedPlan");
                this.setState({state: "planChanged", submitting: false, planChangedMessage: data.message, confirmation: {success: undefined, failure: undefined}});
            }
        });
    }

    backToPlanSelect () {
        this.props.history.push({ pathname: "/config/project/plan"});
        this.setState({state: "planselect", confirmation: {success: undefined, failure: undefined}});
    }


    render () {
        if (this.props.role < 4) {
            return (
                <div style={{"height":"100vh"}}>
                    <Notice type="information" content="You do not have the appropriate permissions to configure this option. Ask the project owner to assign the Officer (4) role to you if you require access."/>
                    <button type="button" className="btn btn-cancel mt-5 mb-5" onClick={this.props.cancel}>Back</button>
                </div>
            )
        }
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.plan === undefined) {
             return <div>No plan</div>
        }
        let owner = <span></span>
        if (this.state.owner !== undefined) {
            owner = <span className="font15">The owner of this project is {this.state.owner.firstName} {this.state.owner.lastName} and emails about project changes and billing will be sent to {this.state.owner.email}</span>
        }
        let billingSchedule = <span></span>
        if (this.state.plan.billingCycle !== undefined && this.state.plan.billingCycleEnd !== undefined) {
            let period = this.state.plan.billingCycle.charAt(0).toUpperCase()  + this.state.plan.billingCycle.slice(1).toLowerCase();
            let periodEnding = <TimeFormatted dt={this.state.plan.billingCycleEnd}/>
            billingSchedule = <p className="font14">{period} billing with current billing period ending {periodEnding}</p>
        }
        
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        if (this.state.state === "view") {
            let upgradeOverride = false
            if (project.plan !== undefined) {   
                if (project.plan.name === "free-v1") {
                    upgradeOverride = true
                }
            }
            return (
                <div>
                    <div style={{"display":"table", "width": "100%"}}>
                        <div style={{"display":"table-cell", "width": "400px"}}>
                            <Checkout 
                                backToProductSelect={this.backToPlanSelect}
                                project={project}
                                cancel={this.cancel}
                                product={this.state.plan.name}
                                plan={this.state.plan}
                                currentActiveUsers={this.state.currentActiveUsers}
                                complete={this.planChanged}
                                type={"planChange"}
                                details={true}
                                overlay={this.props.overlay}
                                messageOverlay={this.props.messageOverlay}
                            />
                            <div className="mt-4">
                                {owner}
                                {billingSchedule}
                            </div>
                        </div>
                        <div className="p-3" style={{"display":"table-cell"}}>
                            <Checkout 
                                backToProductSelect={this.backToPlanSelect}
                                project={project}
                                cancel={this.cancel}
                                product={this.state.plan.name}
                                plan={this.state.plan}
                                currentActiveUsers={this.state.currentActiveUsers}
                                complete={this.planChanged}
                                type={"planChange"}
                                price={true}
                                overlay={this.props.overlay}
                                messageOverlay={this.props.messageOverlay}
                            />
                            <Confirmation confirmation={this.state.confirmation}/>
                            <ConfirmationButtons
                                type={upgradeOverride ? "upgradeOverride" : ""}
                                confirm={this.planSelect}
                                cancel={this.props.cancel}
                                confirmLabel="Change plan"
                                cancelLabel="Back"
                            />
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.state === "planselect") {
            let owner = <span></span>
            if (this.state.owner !== undefined) {
                owner = <span className="font15">The owner of this project is {this.state.owner.firstName} {this.state.owner.lastName} and emails about project changes and billing will be sent to {this.state.owner.email}</span>
            }
            let billingSchedule = <span></span>
            if (this.state.plan.billingCycle !== undefined && this.state.plan.billingCycleEnd !== undefined) {
                let period = this.state.plan.billingCycle.charAt(0).toUpperCase()  + this.state.plan.billingCycle.slice(1).toLowerCase();
                let periodEnding = <TimeFormatted dt={this.state.plan.billingCycleEnd}/>
                billingSchedule = <p className="font14">{period} billing with current billing period ending {periodEnding}</p>
            }
            let product = <span></span>
            if (this.state.plan.product !== undefined) {
                if (this.state.plan.product === "free-v1") {
                    product = "Free";
                } else if (this.state.plan.product === "standard-v1" || this.state.plan.product === "standard-20240719") {
                    product = "Standard";
                } else if (this.state.plan.product === "plus-v1" || this.state.plan.product === "plus-20240719") {
                    product = "Plus";
                } else if (this.state.plan.product.startsWith("enterprise")) {
                    product = "Enterprise";
                } else {
                    product = "Legacy";
                }
            }       
            return (
                <div>
                    <h2 className="neutral4">Select a plan</h2>
                    {/*product*/}
                    {billingSchedule}
                    {owner}
                    <br/>
                    <br/>
                    <Pricing 
                        currentPlan={this.state.plan}
                        overlay={this.props.overlay}
                        messageOverlay={this.props.messageOverlay}
                        confirm={this.productSelected}
                    />
                    <button type="button" className="btn btn-cancel mt-3" onClick={this.props.cancel}>Back</button>
                    <div className="accenta1" id="error">{this.state.error}</div>
                </div>
            )
        } else if (this.state.state === "free") {
            window.scrollTo(0, 0);
            return (
                <div className="row">
                    <div className="col-12 col-sm-6 col-sm-6 col-lg-6">
                        <h4>Free</h4>
                        <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={this.completeChange} disabled={this.state.submitting}>Confirm</button>
                        <button type="button" className="btn btn-cancel mt-3" onClick={this.backToPlanSelect} disabled={this.state.submitting}>Back to plan select</button>
                        <br/>
                        <br/>
                        <div className="accenta1" id="error">{this.state.error}</div>
                    </div>
                </div>
            )
        } else if (this.state.state === "enterprise") {
            window.scrollTo(0, 0);
            return (
                <div className="row">
                    <div className="col-12 col-sm-6 col-sm-6 col-lg-6">
                        <h4>Enterprise</h4>
                        <p>Thank you for considering Tesults Enterprise. Contact <a className="primary2 bold" href="mailto:enterprise@tesults.com">enterprise@tesults.com</a> and a team member will get right back to you.</p>
                        <button type="button" className="btn btn-cancel mt-3" onClick={this.backToPlanSelect} disabled={this.state.submitting}>Back to plan select</button>
                        <br/>
                        <br/>
                        <div className="accenta1" id="error">{this.state.error}</div>
                    </div>
                </div>
            );
        } else if (this.state.state === "paid") {
            window.scrollTo(0, 0);
            let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
            return (
                <Checkout 
                    backToProductSelect={this.backToPlanSelect}
                    project={project}
                    plan={this.state.plan}
                    currentActiveUsers={this.state.currentActiveUsers}
                    cancel={this.cancel}
                    product={this.state.product}
                    complete={this.planChanged}
                    type={"planChange"}
                    overlay={this.props.overlay}
                    messageOverlay={this.props.messageOverlay}
                />
            )
        } else if (this.state.state === "planChanged") {
            let planChangedMessage = this.state.planChangedMessage;
            return (
                <div>
                    <h4 className="neutral4">Plan Changed</h4>
                    <p className="font14">The plan for this project has been changed successfully.</p>
                    <p className="font14">{planChangedMessage}</p>
                    <button type="button" className="btn-confirm" onClick={this.props.cancel}>Back</button>
                </div> 
            )
        } else {
            return <div>Invalid state</div>
        }
        
    }
};

export default ConfigPlan