/*global */
import React, { Component } from 'react';
import Request from './Request';

class BlogAuthor extends Component {
    constructor (props) {
        super(props);
        this.state = {clientId: undefined};
    }

    componentDidMount () {
        this.setClientId();
    }

    setClientId () {
        Request.get("/client-id", {key: "ADMIN_TOKEN_USER1"}, (err, data) => {
            if (err) {
                this.setState({clientId: undefined, error: "Unable to proceed with Jira integrated"});
            } else {
                this.setState({clientId: data.value});
            }
        })
    }

    render () {
        const style = (this.props.style === undefined) ? {} : this.props.style;
        const bg = (this.props.bg === undefined) ? "whitebg" : this.props.bg;
        if (this.props.author === this.state.clientId) {
            return (
                <div className='mb-5' style={{"flex":"1"}}>
                    <p className='font12'><i>Posted by</i></p>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className='mr-3'>
                            <img src="https://www.tesults.com/blogfiles/authors/ajeet-dhaliwal.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt=""></img>
                        </div>
                        <div>
                            <span className='bold'>Ajeet Dhaliwal</span>
                            <br/>
                            <span>Principal Developer Advocate<br/></span>
                            <a href="/" className="tr-link-neutral1">
                                <div style={{"display":"flex", "alignItems":"center"}}>
                                    <div style={{"marginTop":"4px"}}>
                                        <div class="header-logo-item">
                                            <img alt="" src="https://www.tesults.com/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                                        </div>
                                    </div>
                                    <div className="neutral2">
                                        <div class="header-logo-item">
                                            <span className="bolder font16 primary4">&nbsp; Tesults</span> 
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>
        }
    }
}

export default BlogAuthor