/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CreateProject from './CreateProject'
import ConfigProject from './ConfigProject';
import ConfigProfile from './ConfigProfile';
import ConfigMenu from './ConfigMenu';
import Constants from './Constants';
import Loading from './Loading';
import ResultsHeader7 from './ResultsHeader7';

class Config extends Component {
    constructor () {
        super();
        let loggedIn = true;
        const user = Cookies.getJSON("truser");
        if (user === undefined || user === null) {
            loggedIn = false;
        }
        this.state = {loading: true, loggedIn: loggedIn, state: 'createProject', projectCreation: true, hideMenu: false};

        this.createProject = this.createProject.bind(this);
        this.configProject = this.configProject.bind(this);
        this.configProfile = this.configProfile.bind(this);
    }

    createProject () {
        this.props.history.push({ pathname: "/config/create-project"});
        this.setState({state: 'createProject'});
    }

    configProject () {
        this.props.history.push({ pathname: "/config/project"});
        this.setState({state: 'configureProject'});
    }

    configProfile () {
        this.props.history.push({ pathname: "/config/profile"});
        this.setState({state: 'configureProfile'});
    }

    componentDidMount () {
        window.scrollTo(0,0);
        if (this.state.loggedIn === false) {
            this.setState({loading: false});
        } else {
            let state = "configureProject";
            let path = window.location.pathname;
            if (path === "/configUser" || path === "/configProfile" || path === "/config/profile" || this.props.area === "profile") {
                state = "configureProfile";
            }
            if (path === "/config/create-project") {
                state = "createProject";
            }
            this.setState({state: state, loading: false});
            /*
            Request.get("/projectsConfig", undefined, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    let state = 'createProject';
                    if (data.projects !== undefined) {
                        if (data.projects.length > 0) {
                            state = 'configureProject';
                        }
                    }
                    let path = window.location.pathname;
                    if (path === "/configProject" || this.props.area === "project") {
                        state = "configureProject";
                    }
                    if (path === "/createProject" || this.props.area === "create-project") {
                        state = "createProject";
                    }
                    if (path === "/configUser" || path === "/configProfile" || this.props.area === "profile") {
                        state = "configureProfile";
                    }
                    this.setState({projects: data.projects, projectCreation: data.projectCreation, loading:false, state: state});
                }
            });*/
        }
    }

    componentDidUpdate () {
        if (this.props.location.pathname.startsWith("/config/project/plan") 
            || this.props.location.pathname.startsWith("/config/create-project")
            || this.props.location.pathname.startsWith("/config/project/audit-logs")
            || this.props.location.pathname.startsWith("/config/profile")
            ) {
            if (this.state.hideMenu === false) {
                this.setState({hideMenu: true})
            }
        } else {
            if (this.state.hideMenu === true) {
                this.setState({hideMenu: false})
            }
        }
    }
    
    render () {
        if (this.state.loading === true) {
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Configuration</title>
                        <meta name="description" content="Configure your Tesults project, targets and team members."/>
                    </Helmet>
                    <div className="neutral8bg">
                        <div className="container-fluid pl-0 pr-0 min-height-apply">
                            <div className="card">
                                <div className="card-body">
                                    <Loading/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.loggedIn === false) {
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Configuration</title>
                        <meta name="description" content="Configure your Tesults project, targets and team members."/>
                    </Helmet>
                    <div className="whitebg index-margin">
                        <div className="container-fluid pl-0 pr-0 min-height-apply">
                            <h1>Configuration</h1>
                            <p><NavLink to="/login" className="nounderline primary4">Sign in</NavLink> to view configuration.</p>
                            <p><NavLink to="/" className="nounderline primary4">Return to Tesults.com</NavLink></p>
                        </div>
                    </div>
                </div>
            );
        } else {
            let select = undefined;
            if (this.props.area === undefined) {
                select = Constants.getUrlParameter("select", this.props.location.search);
            }
            
            //const code = Constants.getUrlParameter("code", this.props.location.search); // slack auth usage
            const pathMap = {
                "project" : ["/config", "/config/", "/config/project", "/config/project/project", "/config/project-name", "/config/project-image", "/config/project-privacy"],
                "targets" : ["/config/project/targets", "/config/project/regenerate-target-token", "/config/project/target-api-edit", "/config/project/create-target"],
                "target-order": ["/config/project/target-order"],
                "team" : ["/config/project/members", "/config/project/team"],
                "sso" : ["/config/project/sso"],
                "build-consolidation": ["/config/project/build-consolidation"],
                "result-interpretation": ["/config/project/result-interpretation"],
                "preferences": ["/config/project/preferences"],
                "preferences-reporting-case-display-attributes": ["/config/project/preferences-reporting-case-display-attributes"],
                "notifications" : ["/config/project/notifications"],
                "integrations" : ["/config/project/integrations", "/config/project/slack",  "/config/project/slack-dev", "/config/project/pagerduty", "/config/project/webhooks", "/config/project/jira"],
                "organization": ["/config/project/organization"],
                "audit-logs": ["/config/project/audit-logs"],
                "api-token" : ["/config/project/api-token"],
                "plan" : ["/config/project/plan", "config/project/plan-changed"],
                "payment-details" : ["/config/project/payment-details", "/config/project/payment-edit"],
                "country": ["/config/project/country"],
                "invoices": ["/config/project/invoices"]
            }

            let activeContent = <span></span>
            let title = "Tesults - Configuration";

            if (this.state.state === "configureProject") {
                title = "Tesults - Configure Project"
                if (select === "slack") {
                    title = "Tesults - Notifications on Slack";
                }
                activeContent = <ConfigProject pathMap={pathMap} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={select} location={this.props.location} createProject={this.createProject}/>
            }
            if (this.state.state === "createProject") {
                title = "Tesults - Create Project"
                activeContent = <CreateProject overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} projectCreation={this.state.projectCreation}/>
            }
            if (this.state.state === "configureProfile") {
                title = "Tesults - Configure Profile"
                activeContent = <ConfigProfile props={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history}/>
            }

            if (this.state.hideMenu) {
                return (
                    <div>
                        <Helmet>
                            <title>{title}</title>
                            <meta name="description" content="Configure your Tesults project, targets and team members."/>
                        </Helmet>
                        <div className="whitebg">
                            <ResultsHeader7 title={this.props.title}/>
                            <div className="app-main-margin">
                                <div className='config-main'>
                                    {activeContent}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Helmet>
                            <title>{title}</title>
                            <meta name="description" content="Configure your Tesults project, targets and team members."/>
                        </Helmet>
                        <div className="whitebg">
                            <ResultsHeader7 title={this.props.title}/>
                            <div className="app-main-margin">
                                <div className='config-layout'>
                                    <div className='config-menu-outer'>
                                        <ConfigMenu location={this.props.location} pathMap={pathMap}/>
                                    </div>
                                    <div className='config-main-outer'>
                                        <div className='config-main'>
                                            {activeContent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
};

export default Config;