/*global*/
import React, { Component } from 'react';
import Request from './Request'
import Context from './Context'
import Cache from './Cache'

/**
 * Component used when viewing run for the purpose of archive or unarchive
 * and when viewing run archive for purpose of deleting the archive.
 */

class RunArchive extends Component {
    static contextType = Context;

    constructor () {
        super()
        this.ref = React.createRef();
        this.state = ({ 
            archived: false,
            id: undefined,
            created: undefined,
            runCreated: undefined,
            updating: false
        })
        this.init = this.init.bind(this)
        this.checkArchived = this.checkArchived.bind(this)
        this.contentExceeded = this.contentExceeded.bind(this)
        this.contentError = this.contentError.bind(this)
        this.contentConfirmDelete = this.contentConfirmDelete.bind(this)
        this.archive = this.archive.bind(this)
        this.unarchive = this.unarchive.bind(this)
        this.project = this.project.bind(this)
        this.delete = this.delete.bind(this)
        this.deleteConfirm = this.deleteConfirm.bind(this)
    }

    componentDidMount () {
        if (this.props.context === "results") {
            this.init()
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.runArchive !== prevProps.runArchive) {
            this.init()
        }
    }

    init () {
        let initData = {id: undefined, created: undefined, runCreated: undefined}
        let project = this.project()
        if (project !== undefined) {
            initData.id = project.id
        }
        if (this.props.targets !== undefined) {
            if (this.props.targetIndex !== undefined) {
                if (this.props.targetIndex < this.props.targets.length) {
                    initData.created = this.props.targets[this.props.targetIndex].created
                }
            }
        }
        if (this.props.isRunArchive === true) {
            if (this.props.runArchive !== undefined) {
                initData.runCreated = this.props.runArchive.created
            }
        } else {
            if (this.props.runs !== undefined) {
                if (this.props.runIndex !== undefined) {
                    if (this.props.runIndex < this.props.runs.length) {
                        initData.runCreated = this.props.runs[this.props.runIndex].created
                    }
                }
            }    
        }
        
        this.setState(initData, this.checkArchived)
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    checkArchived () {
        if (this.props.isRunArchive === true) {
            return
        }
        Request.get("/run-archive", {id: this.state.id, created: this.state.created, runCreated: this.state.runCreated}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                this.setState({archived: false});
            } else {
                this.setState({archived: data.data.runArchive === undefined ? false : true})
            }
        });
    }

    contentExceeded () {
        let contentDisplay = (
            <div className='p-5' style={{"width":"200px", "whiteSpace":"normal"}}>
                <div className='font14'>
                    The number of test runs that can be archived for this target has been exceeded. Consider a plan upgrade to increase the number of test runs that can be archived. Alternatively select an archived test run for this target to delete from the side bar and then return and archive this run.
                </div>
            </div>
        )

        if (this.props.overlay !== undefined) {
            this.props.overlay(contentDisplay, this.ref)
        }
    }

    contentError (error) {
        let contentDisplay = (
            <div className='p-5' style={{"width":"200px", "whiteSpace":"normal"}}>
                <div className='font14'>
                    {error === undefined ? "There was an issue completing this request." : error}
                </div>
            </div>
        )

        if (this.props.overlay !== undefined) {
            this.props.overlay(contentDisplay, this.ref)
        }
    }

    contentConfirmDelete () {
        let contentDisplay = (
            <div className='p-5' style={{"width":"200px", "whiteSpace":"normal"}}>
                <div className='font14'>
                    The original test run for this archived test run no longer exists. Deleting this test run archive will permanently delete this data and it cannot be recovered.
                </div>
                <div>
                    <button className='btn-cancel' onClick={this.deleteConfirm}>Confirm delete</button>
                </div>
            </div>
        )

        if (this.props.overlay !== undefined) {
            this.props.overlay(contentDisplay, this.ref)
        }
    }

    archive () {
        this.setState({updating: true})
        Request.post("/run-archive", {id: this.state.id, created: this.state.created, runCreated: this.state.runCreated}, (err, data) => {
            this.setState({updating: false})
            if (err) {
                this.contentError(err)
            } else {
                this.setState({archived: true}, this.props.revUpdate)
            }
        });
    }

    unarchive () {
        this.setState({updating: true})
        Request.post("/run-archive-delete", {id: this.state.id, created: this.state.created, runCreated: this.state.runCreated}, (err, data) => {
            this.setState({updating: false})
            if (err) {
                this.contentError()
            } else {
                this.setState({archived: false}, this.props.revUpdate)
            }
        });
    }

    delete () {
        // Check if the original run for this archive still exists.
        Request.get("/run", {id: this.state.id, created: this.state.created, runCreated: this.state.runCreated}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                this.setState({archived: false});
            } else {
                if (data.run === undefined) {
                    // The original run does not exist, confirm deletion because this data will be lost forever.
                    this.contentConfirmDelete()
                } else {
                    this.deleteConfirm()
                }
            }
        });
    }

    deleteConfirm () {
        Request.post("/run-archive-delete", {id: this.state.id, created: this.state.created, runCreated: this.state.runCreated}, (err, data) => {
            if (err) {
                this.contentError()
            } else {
                this.props.runArchiveDeleted()
            }
        });
    }

    render () {
        if (this.props.context !== "results") {
            return <span></span>
        }
        if (this.state.updating === true) {
            return (
                <div ref={this.ref} className='neutral4 font14'>
                    Updating...
                </div>
            )
        }
        if (this.props.isRunArchive === true) {
            return (
                <div ref={this.ref} className='neutral4 font14 pointer' onClick={this.delete}>
                    Delete this archived run
                </div>
            )
        } else {
            return (
                <div ref={this.ref} className={this.state.archived ? 'neutral7 font14' : 'neutral4 font14 pointer'} onClick={this.state.archived ? this.unarchive : this.archive}>
                    {this.state.archived ? "Archived" : "Archive"}
                </div>
            )
        }
    }
};

export default RunArchive