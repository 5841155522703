/*global */
import React, { Component } from 'react';

class CasePriority extends Component {
    render () {
        if (this.props.case.priority !== undefined) {
            let resultClass = "accentb8";
            let borderClass = "accentb3bg";
            let priorityInt = parseInt(this.props.case.priority);
            if (priorityInt === 0) {
                resultClass = "accenta8";
                borderClass = "accenta2bg accenta2border";
            } else if (priorityInt === 1) {
                resultClass = "accenta8";
                borderClass = "accenta3bg accenta3border";
            } else if (priorityInt === 2) {
                resultClass = "accentb8";
                borderClass = "accentb4bg accentb4border";
            } else if (priorityInt === 3) {
                resultClass = "accentc8";
                borderClass = "accentc3bg accentc3border";
            } else if (priorityInt === 4) {
                resultClass = "accentc8";
                borderClass = "accentc2bg accentc2border";
            } else if (priorityInt === -1) {
                return <span></span>
            }
            return (
                <div style={{"display":"flex", "alignItems":"center"}} className={"circle24 font14 " + resultClass + " " + borderClass}>
                    <div className="width100 text-center">{this.props.case.priority}</div>
                </div>
            );

        } else {
            return <span></span>
        }
    }
};

export default CasePriority;