/*global*/
import React, { Component } from 'react';
import Request from './Request';
import Loading from './Loading';
import Context from './Context'
import Cache from './Cache'

class AutoNotify extends Component {
    static contextType = Context;

    constructor () {
        super()
        this.state = {error: undefined, loading: false, message: "", checked: true}
        this.init = this.init.bind(this)
        this.toggle = this.toggle.bind(this)
        this.save = this.save.bind(this)
        this.project = this.project.bind(this)
    }

    componentDidMount() {
        this.init()
    }

    init () {
        let project = this.project()
        if (project === undefined) { return }
        this.setState({loading: true})
        Request.get("/autoNotify", {project: project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to check auto notify."})
            } else {
                this.setState({loading: false, checked: data.autoNotify})
            }
        })
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    toggle () {
        let checked = this.state.checked;
        if (checked === true) {
            this.setState({checked: false});
        } else {
            this.setState({checked: true});
        }
    }    

    save () {
        let project = this.project()
        if (project === undefined) { return }
        this.setState({loading: true});
        Request.post("/autoNotify", {project: project.id, autoNotify: this.state.checked}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to update auto notify"});
            } else {
                this.setState({loading: false, message: "Auto notification setting updated"});
            }
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        } else {
            return (
                <div className="whitebg p-3 mb-3">
                    <h4 className="neutral1">Automatic notifications:</h4>
                    <label className="autoRefreshSwitch">
                        <input type="checkbox" onChange={this.toggle} checked={this.state.checked}/>
                        <span className="autoRefreshSlider round"></span>
                    </label>
                    <div><span className="accentc4">{this.state.message}</span></div>
                    <div><span className="accenta4">{this.state.error}</span></div>
                    <p>Everytime new test results are generated, notifications are delivered automatically if this setting is enabled. This includes notifications to team members with email notifications enabled and Slack channel notifications.</p>
                    <p>You can choose to disable automatic notifications and have project moderators trigger notifications manually instead from the results view.</p>
                    <button type="button" className="btn btn-confirm mt-3" onClick={() => {this.save()}}>Save</button>
                </div>
            );
        }
    }
}

export default AutoNotify;