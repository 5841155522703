/*global */
import React, { Component } from 'react';
import DocsSideItem from './DocsSideItem'

class DocsSide extends Component {
    constructor() {
        super()
        this.linkClass = this.linkClass.bind(this)
        this.divClass = this.divClass.bind(this)
        this.state = {
            open_product: true,
            open_integrations: true,
            open_configuration: true,
            open_language_libraries: true,
            open_test_frameworks: true,
            open_test_framework_developers: true,
            open_tesults_api: true,
            open_company: true,
            open_policy: true
        }
    }

    componentDidMount () {
        this.calculateCollapsed()
    }

    calculateCollapsed () {
        const product_links = [
            "/docs/getting-started",
            "/docs/results",
            "/docs/supplemental",
            "/docs/dashboard",
            "/docs/lists",
            "/docs/manual",
            "/docs/tasks",
            "/docs/diff",
            "/docs/release-checklists"
        ]

        const integrations_links = [
            "/docs/github-actions",
            "/docs/slack",
            "/docs/msteams",
            "/docs/mattermost",
            "/docs/pagerduty",
            "/docs/jira"
        ]

        const configuration_links = [
            "/docs/structure",
            "/docs/target",
            "/docs/project",
            "/docs/targets",
            "/docs/target-order",
            "/docs/build-consolidation",
            "/docs/result-interpretation",
            "/docs/preferences",
            "/docs/notifications",
            "/docs/integrations",
            "/docs/api-token",
            "/docs/team-members",
            "/docs/sso",
            "/docs/organization",
            "/docs/audit-logs",
            "/docs/plan",
            "/docs/payment-details",
            "/docs/account-country",
            "/docs/invoices"
        ]

        const language_libraries_links = [
            "/docs/java",
            "/docs/csharp",
            "/docs/python",
            "/docs/ruby",
            "/docs/javascript",
            "/docs/kotlin",
            "/docs/swift",
            "/docs/go",
            "/docs/php",
            "/docs/others",
            "/docs/resultsapi",
            "/docs/junit-xml"
        ]

        const test_frameworks_links = [
            "/docs/exp",
            "/docs/junit5",
            "/docs/junit4",
            "/docs/testng",
            "/docs/pytest",
            "/docs/robot",
            "/docs/nunit3",
            "/docs/xunit",
            "/docs/vsunittf",
            "/docs/rspec",
            "/docs/mocha",
            "/docs/jasmine",
            "/docs/jest",
            "/docs/playwright",
            "/docs/wdio",
            "/docs/codeceptjs",
            "/docs/nightwatch",
            "/docs/waffle",
            "/docs/postman",
            "/docs/protractor",
            "/docs/cypress",
            "/docs/testcafe",
            "/docs/xctest",
            "/docs/espresso",
            "/docs/selenium"
        ]

        const test_framework_developer_links = [
            "/docs/test-framework-developers",
            "/docs/tesults-json-data-standard"
        ]

        const tesults_api_links = [
            "/docs/api/overview",
            "/docs/api/auth",
            "/docs/api/targets",
            "/docs/api/results"
        ]

        const company_links = [
            "/contact",
            "/about",
            "/careers",
            "/blog",
            "/featurerequest"
        ]

        const policy_links = [
            "/docs/support-policy",
            "/docs/pricing",
            "/docs/fair-billing-policy",
            "/docs/refund-policy",
            "/docs/privacy",
            "/docs/security",
            "/docs/terms",
            "/docs/vendor-onboarding"
        ]
        
        this.setState(
            {
                open_product: product_links.includes(this.props.location.pathname) ? true : false,
                open_integrations: integrations_links.includes(this.props.location.pathname) ? true : false,
                open_configuration: configuration_links.includes(this.props.location.pathname) ? true : false,
                open_language_libraries: language_libraries_links.includes(this.props.location.pathname) ? true : false,
                open_test_frameworks: test_frameworks_links.includes(this.props.location.pathname) ? true : false,
                open_test_framework_developers: test_framework_developer_links.includes(this.props.location.pathname) ? true : false,
                open_tesults_api: tesults_api_links.includes(this.props.location.pathname) ? true : false,
                open_company: company_links.includes(this.props.location.pathname) ? true : false,
                open_policy: policy_links.includes(this.props.location.pathname) ? true : false,
            }
        )
    }

    linkClass (path) {
        if (this.props.location.pathname.includes(path)) {
            return "tr-link-primary4 no-break"
        } else {
            return "tr-link-plain"
        }
    }

    divClass (path) {
        if (this.props.location.pathname.includes(path)) {
            return "docs-group docs-group-active-item"
        } else {
            return "docs-group"
        }
    }
    
    render () {
        return (
            <div style={{"display":"flex", "flex-direction":"column"}}>
                <div className="mb-4 docsTopPadding">
                    <details open={this.state.open_product}>
                        <summary className='mb-3 pointer'><h4 className='docs-group-header'>Product</h4></summary>
                        <div>
                            <DocsSideItem location={this.props.location} title="Getting started" link="/docs/getting-started"/>
                            <DocsSideItem location={this.props.location} title="Results" link="/docs/results"/>
                            <DocsSideItem location={this.props.location} title="Supplemental" link="/docs/supplemental"/>
                            <DocsSideItem location={this.props.location} title="Dashboard" link="/docs/dashboard"/>
                            <DocsSideItem location={this.props.location} title="Diff" link="/docs/diff"/>
                            <DocsSideItem location={this.props.location} title="Lists" link="/docs/lists"/>
                            <DocsSideItem location={this.props.location} title="Runs" link="/docs/manual"/>
                            <DocsSideItem location={this.props.location} title="Tasks" link="/docs/tasks"/>
                            <DocsSideItem location={this.props.location} title="Release Checklists" link="/docs/release-checklists"/>
                        </div>
                    </details>
                    
                    
                </div>
                <div className="mb-4">
                    <details open={this.state.open_integrations}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Integrations</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="Github Actions" link="/docs/github-actions"/>
                        <DocsSideItem location={this.props.location} title="Slack" link="/docs/slack"/>
                        <DocsSideItem location={this.props.location} title="MS Teams" link="/docs/msteams"/>
                        <DocsSideItem location={this.props.location} title="Mattermost" link="/docs/mattermost"/>
                        <DocsSideItem location={this.props.location} title="PagerDuty" link="/docs/pagerduty"/>
                        <DocsSideItem location={this.props.location} title="Jira" link="/docs/jira"/>
                    </div>
                    </details>
                </div>
                <div className="mb-4">
                    <details open={this.state.open_configuration}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Configuration</h4></summary>
                    <div>
                        <div className='font12 neutral4'>Core Concepts</div>
                        <DocsSideItem location={this.props.location} title="How to structure your project" link="/docs/structure"/>
                        <DocsSideItem location={this.props.location} title="What are targets?" link="/docs/target"/>
                        <hr className='primary8border'/>
                        <div className='font12 neutral4'>Configuration Menu Help</div>
                        <DocsSideItem location={this.props.location} title="Project" link="/docs/project"/>
                        <DocsSideItem location={this.props.location} title="Targets" link="/docs/targets"/>
                        <DocsSideItem location={this.props.location} title="Target Order" link="/docs/target-order"/>
                        <DocsSideItem location={this.props.location} title="Build consolidation" link="/docs/build-consolidation"/>
                        <DocsSideItem location={this.props.location} title="Result interpretation" link="/docs/result-interpretation"/>
                        <DocsSideItem location={this.props.location} title="Preferences" link="/docs/preferences"/>
                        <DocsSideItem location={this.props.location} title="Notifications" link="/docs/notifications"/>
                        <DocsSideItem location={this.props.location} title="Integrations" link="/docs/integrations"/>
                        <DocsSideItem location={this.props.location} title="API Token" link="/docs/api-token"/>
                        <DocsSideItem location={this.props.location} title="Team Members" link="/docs/team-members"/>
                        <DocsSideItem location={this.props.location} title="Single sign-on" link="/docs/sso"/>
                        <DocsSideItem location={this.props.location} title="Organization" link="/docs/organization"/>
                        <DocsSideItem location={this.props.location} title="Audit Logs" link="/docs/audit-logs"/>
                        <DocsSideItem location={this.props.location} title="Plan" link="/docs/plan"/>
                        <DocsSideItem location={this.props.location} title="Payment Details" link="/docs/payment-details"/>
                        <DocsSideItem location={this.props.location} title="Account Country" link="/docs/account-country"/>
                        <DocsSideItem location={this.props.location} title="Invoices" link="/docs/invoices"/>
                    </div>
                    </details>
                </div>
                <div className="mb-4">
                    <details open={this.state.open_language_libraries}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Language Libraries</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="Java" link="/docs/java"/>
                        <DocsSideItem location={this.props.location} title="C#" link="/docs/csharp"/>
                        <DocsSideItem location={this.props.location} title="Python" link="/docs/python"/>
                        <DocsSideItem location={this.props.location} title="Ruby" link="/docs/ruby"/>
                        <DocsSideItem location={this.props.location} title="JavaScript" link="/docs/javascript"/>
                        <DocsSideItem location={this.props.location} title="Kotlin" link="/docs/kotlin"/>
                        <DocsSideItem location={this.props.location} title="Swift" link="/docs/swift"/>
                        <DocsSideItem location={this.props.location} title="Go (Golang)" link="/docs/go"/>
                        <DocsSideItem location={this.props.location} title="PHP" link="/docs/php"/>
                        <DocsSideItem location={this.props.location} title="Others" link="/docs/others"/>
                        <DocsSideItem location={this.props.location} title="REST" link="/docs/resultsapi"/>
                        <DocsSideItem location={this.props.location} title="JUnit XML" link="/docs/junit-xml"/>
                    </div>
                    </details>
                </div>
                <div className="mb-4">
                    <details open={this.state.open_test_frameworks}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Test Frameworks</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="EXP" link="/docs/exp"/>
                        <DocsSideItem location={this.props.location} title="JUnit 5" link="/docs/junit5"/>
                        <DocsSideItem location={this.props.location} title="JUnit 4" link="/docs/junit4"/>
                        <DocsSideItem location={this.props.location} title="TestNG" link="/docs/testng"/>
                        <DocsSideItem location={this.props.location} title="pytest" link="/docs/pytest"/>
                        <DocsSideItem location={this.props.location} title="Robot Framework" link="/docs/robot"/>
                        <DocsSideItem location={this.props.location} title="NUnit 3" link="/docs/nunit3"/>
                        <DocsSideItem location={this.props.location} title="xUnit" link="/docs/xunit"/>
                        <DocsSideItem location={this.props.location} title="MS Test" link="/docs/vsunittf"/>
                        <DocsSideItem location={this.props.location} title="RSpec" link="/docs/rspec"/>
                        <DocsSideItem location={this.props.location} title="Mocha" link="/docs/mocha"/>
                        <DocsSideItem location={this.props.location} title="Jasmine" link="/docs/jasmine"/>
                        <DocsSideItem location={this.props.location} title="Jest" link="/docs/jest"/>
                        <DocsSideItem location={this.props.location} title="Playwright" link="/docs/playwright"/>
                        <DocsSideItem location={this.props.location} title="WebdriverIO" link="/docs/wdio"/>
                        <DocsSideItem location={this.props.location} title="CodeceptJS" link="/docs/codeceptjs"/>
                        <DocsSideItem location={this.props.location} title="Nightwatch" link="/docs/nightwatch"/>
                        <DocsSideItem location={this.props.location} title="Waffle" link="/docs/waffle"/>
                        <DocsSideItem location={this.props.location} title="Postman" link="/docs/postman"/>
                        <DocsSideItem location={this.props.location} title="Protractor" link="/docs/protractor"/>
                        <DocsSideItem location={this.props.location} title="Cypress" link="/docs/cypress"/>
                        <DocsSideItem location={this.props.location} title="TestCafe" link="/docs/testcafe"/>
                        <DocsSideItem location={this.props.location} title="XCTest" link="/docs/xctest"/>
                        <DocsSideItem location={this.props.location} title="Espresso" link="/docs/espresso"/>
                        <DocsSideItem location={this.props.location} title="Selenium" link="/docs/selenium"/>
                    </div>
                    </details>
                </div>
                <div className="mb-4">
                    <details open={this.state.open_tesults_api}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Tesults API</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="Overview" link="/docs/api/overview"/>
                        <DocsSideItem location={this.props.location} title="Authentication" link="/docs/api/auth"/>
                        <DocsSideItem location={this.props.location} title="Targets" link="/docs/api/targets"/>
                        <DocsSideItem location={this.props.location} title="Results" link="/docs/api/results"/>
                    </div>
                    </details>
                </div>
                <div className="mb-4">
                    <details open={this.state.open_test_framework_developers}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Test Framework Devs</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="Test framework devs" link="/docs/test-framework-developers"/>
                        <DocsSideItem location={this.props.location} title="Tesults JSON data standard" link="/docs/tesults-json-data-standard"/>
                    </div>
                    </details>
                </div>
                <div className="mb-4">
                    <details open={this.state.open_company}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Company</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="Contact" link="/contact"/>
                        <DocsSideItem location={this.props.location} title="About" link="/about"/>
                        <DocsSideItem location={this.props.location} title="Careers" link="/careers"/>
                        <DocsSideItem location={this.props.location} title="Blog" link="/blog"/>
                        <DocsSideItem location={this.props.location} title="Feature Request" link="/featurerequest"/>
                    </div>
                    </details>
                </div>
                <div className="mb-5">
                    <details open={this.state.open_policy}>
                    <summary className='mb-3 pointer'><h4 className='docs-group-header'>Policy</h4></summary>
                    <div>
                        <DocsSideItem location={this.props.location} title="Support" link="/docs/support-policy"/>
                        <DocsSideItem location={this.props.location} title="Pricing" link="/docs/pricing"/>
                        <DocsSideItem location={this.props.location} title="Fair Billing" link="/docs/fair-billing-policy"/>
                        <DocsSideItem location={this.props.location} title="Cancel & Refund" link="/docs/refund-policy"/>
                        <DocsSideItem location={this.props.location} title="Privacy" link="/docs/privacy"/>
                        <DocsSideItem location={this.props.location} title="Security" link="/docs/security"/>
                        <DocsSideItem location={this.props.location} title="Terms of Service" link="/docs/terms"/>
                        <DocsSideItem location={this.props.location} title="Vendor Onboarding" link="/docs/vendor-onboarding"/>
                    </div>
                    </details>
                </div>
            </div>
        );
    }
}

export default DocsSide;