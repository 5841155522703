/*global */
import React, { Component } from 'react'
import Linkify from 'linkifyjs/react';
import Box from './Box'
import Request from './Request'
import Constants from './Constants'

class CareersRole extends Component {
    constructor () {
        super()
        this.state = {role: undefined, loading: false}
    }

    componentDidMount () {
        let id = undefined
        if (this.props.match !== undefined) {
            if (this.props.match.params !== undefined) {
                if (this.props.match.params.id !== undefined) {
                    id = this.props.match.params.id
                }
            }
        }
        if (id !== undefined) {
            this.setState({loading: true})
            Request.get("/careers-role", {id: id}, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    this.setState({role: data.role, loading: false});
                }
            });
        } else if (this.props.role !== undefined) {
            this.setState({role: this.props.role})
        }
    }

    render () {
        if (this.state.role === undefined) {
            return <div></div>
        }
        return (
            <div>
                <div className='mb-4'>
                    <Box
                        bg="neutral8bg"
                        style={{"maxWidth":"800px"}}
                        title={<h4>{this.state.role.title}</h4>}
                        content={
                            <div>
                                <h4>ID</h4>
                                <a target="_blank" className='primary4' href={Constants.baseUrl + "/careers/" + this.state.role.id}>{this.state.role.id}</a>
                                <h4>Type</h4>
                                {this.state.role.contract}
                                <h4>Location</h4>
                                {this.state.role.location}
                                <h4>Status</h4>
                                {this.state.role.status}
                                <h4>Details</h4>
                                <Linkify>{this.state.role.detail}</Linkify>
                                <h4>Contact</h4>
                                {this.state.role.contactEmail}
                            </div>
                        }
                    /> 
                </div>
            </div>
        );
    }
}

export default CareersRole