/*global */
import React, { Component } from 'react';

class Box extends Component {
    render () {
        const style = (this.props.style === undefined) ? {} : this.props.style;
        const bg = (this.props.bg === undefined) ? "whitebg" : this.props.bg;
        return (
            <div className={bg + " standardborder p-4"} style={style}>
                {this.props.title}
                {this.props.content}
            </div>
        );
    }
};

export default Box;