/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TesultsLtd extends Component {
    render () {
        return (
            <div className="max-width-800px">
                <div className="mb-4">Tesults is a company registered in England and Wales as Tesults Ltd (No. 13084522), 2 Leman Street, London, E1W 9US, UK.</div>
                <div><Link className="tr-link-primary4" target="_blank" to="/contact">Contact the Tesults team</Link></div>
            </div>
        );
    }
};

export default TesultsLtd;