/*global */
import React, { Component } from 'react'
import Request from './Request'

class StatView extends Component {
    constructor () {
        super();
        this.state = {stat: undefined, type: undefined}
        this.initStat = this.initStat.bind(this)
        this.renderProjectStats = this.renderProjectStats.bind(this)
        this.renderTargetStats = this.renderTargetStats.bind(this)
        this.renderCaseStats = this.renderCaseStats.bind(this)
    }

    componentDidMount () {
        this.initStat()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.id !== prevProps.id || this.props.created !== prevProps.created || this.props.hash !== prevProps.hash) {
            this.initStat()
        }
    }

    initStat () {
        let type = undefined
        if (this.props.id !== undefined) {
            type = "project"
            if (this.props.created !== undefined) {
                type = "target"
                if (this.props.hash !== undefined) {
                    type = "case"
                }
            }
        }

        if (type === undefined || type === "case") {
            this.setState({stat: undefined, type: undefined})
            return
        }

        Request.get("/stats", {id: this.props.id, created: this.props.created, hash: this.props.hash}, (err, data) => {
            if (err) {
                this.setState({stat: undefined, type: undefined})
            } else {
                this.setState({stat: data.stats, type: type})
            }
        })
    }

    renderProjectStats () {
        return (
            <div className='font12 neutral4'>
                <div>{this.props.name ? this.props.name + " statistics:" : "Project statistics:"}</div>
                <div>{"Total test runs submitted: " + this.state.stat.runs}</div>
                <div>{"Total manual runs submitted: " + this.state.stat.runsManual}</div>
            </div>
        )
    }

    renderTargetStats () {
        return (
            <div className='font12 neutral4'>
                <div>{this.props.name ? this.props.name + " statistics:" : "Target statistics:"}</div>
                <div>{"Total test runs submitted: " + this.state.stat.runs}</div>
                <div>{"Total manual runs submitted: " + this.state.stat.runsManual}</div>
                <div>{"Total lists created: " + this.state.stat.listsCreated}</div>
            </div>
        )
    }

    renderCaseStats () {
        let times = 0
        if (this.state.stat) {
            if (this.state.stat.runs) {
                times += parseInt(this.state.stat.runs, 10)
            }
            if (this.state.stat.runsManual) {
                times += parseInt(this.state.stat.runsManual, 10)
            }
        }
        return (
            <div className='font12 neutral4'>
                <div>{"This test case has run " + times + " times"}</div>
            </div>
        )
    }

    render () {
        if (this.state.stat === undefined) { return <div></div> }

        if (this.state.type === "project") { return this.renderProjectStats() }
        else if (this.state.type === "target") { return this.renderTargetStats() }
        else if (this.state.type === "case") { return this.renderCaseStats() }
        else { return <div></div> }
    }
};

export default StatView;