/*global */
import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import CasePriority from './CasePriority';
import Duration from './Duration';
import Currency from './Currency';
import Assignee from './Assignee';
import Result from'./Result'
import Cache from './Cache'
import Context from './Context'
import {utilsRawResultMapMap, utilsRawResultMapMapForTask, utilsIsPassResult, utilsIsFailResult, utilsPreferencesReportingCaseDisplayAttributeMap} from './Utils'
import Checkmark from './Checkmark';
import Constants from './Constants';
import CaseEnhancedAnalysis from './CaseEnhancedAnalysis';
import CasePreview from './CasePreview';

class Case extends Component {
    static contextType = Context;

    constructor() {
        super()
        this.state = { hover: false }
        this.hover = this.hover.bind(this)
        this.project = this.project.bind(this)
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    hover (value) {
        if (this.props.context === "results") {
            this.setState({hover: value});
            if (value === true && this.props.case && this.props.case.desc) {
                this.props.side(<CasePreview case={this.props.case}/>);
            } else if (this.props.targetDetail !== undefined) {
                this.props.side(this.props.targetDetail);
            }  
        }
    }

    render() {
        const c = this.props.case;
        let map = undefined
        let preferencesReportingCaseDisplayAttributeMap = undefined
        if (this.props.context === "tasks") {
            map = utilsRawResultMapMapForTask(c, this.props.rawResultMaps)
        } else {
            map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)
            preferencesReportingCaseDisplayAttributeMap = utilsPreferencesReportingCaseDisplayAttributeMap(this.project(), this.props.targets, this.props.targetIndex, this.props.preferencesReportingCaseDisplayAttributeMaps)
        }
        let runMetadata = undefined;
        if (this.props.runs !== undefined && this.props.runIndex !== undefined) {
            if (this.props.runIndex < this.props.runs.length) {
                const run = this.props.runs[this.props.runIndex];
                runMetadata = {
                    integrationName: run.integrationName,
                    integrationVersion: run.integrationVersion,
                    testFramework: run.testFramework,
                }
            }
        }
        let img = <Result result={c.result} rawResult={c.rawResult} map={map} case={c} runMetadata={runMetadata}/>
        let params = "";
        if (c.params !== undefined) {
            if (c.params !== undefined) {
                let paramString = "";
                for (let param in c.params) {
                    if (c.params.hasOwnProperty(param)) {
                        paramString += param + " = " + c.params[param] + ", ";
                    }
                }
                if (paramString !== "") {
                    paramString = paramString.substr(0, paramString.length - 2);
                }
                params = <span className="neutral4 font12">{" " + paramString}</span>
            }
        }

        let flaky = <span></span>
        if (c.flaky !== undefined) {
            if (c.flaky === true) {
                flaky = <img className="ml-1" src="/img/flaky-dark-128.png" alt="flaky" width="14" height="14"/>
            }
        }
        // Alternate flaky check
        if (this.props.runs !== undefined && this.props.runIndex !== undefined) {
            if (this.props.runIndex < this.props.runs.length) {
                const run = this.props.runs[this.props.runIndex];
                if (run.integrationName !== undefined && run.integrationVersion !== undefined) {
                    if (run.integrationName === "playwright-tesults-reporter" && Constants.isVersionGreaterThanOrEqualTo(run.integrationVersion, "1.2.0")) {
                        if (c["_Retries"] !== undefined) {
                            const retryArray = c["_Retries"];
                            if (Array.isArray(retryArray)) {
                                if (retryArray.length > 0) {
                                    flaky = <img className="ml-1" src="/img/flaky-dark-128.png" alt="flaky" width="14" height="14"/>
                                }
                            }
                        }
                    }
                }
            }
        }

        let bug = <span></span>
        if (c.bugs !== undefined) {
            if (c.bugs.length > 0) {
                bug = <img className="ml-2" src="/img/bug.svg" alt="bug" width="14" height="14"/>
            }
        }

        let multiselect = <span></span>
        if (this.props.multiselectEnabled === true && c !== undefined) {
            multiselect = <input type="checkbox" onChange={() => this.props.multiselectChange(this.props.multiselect[c.num] === true ? false : true, [c.num])} checked={this.props.checked} aria-label="Multiselect"/>
        }

        let auxRow = undefined;
        if (c.manualAssignee !== undefined && c.manualAssignee !== "unassigned" && this.props.members !== undefined) {
            let name = "unknown";
            for (let i = 0; i < this.props.members.length; i++) {
                let member = this.props.members[i];
                if (member.id === c.manualAssignee) {
                    name = member.firstName + " " + member.lastName;
                }
            }

            let caseUpdated = <span></span>
            if (c.updated !== undefined) {
                caseUpdated = <span className="small neutral4 mr-3">Last Updated: <TimeFormatted dt={c.updated} fromNow={true}/></span>
            }

            let cost = <span></span>
            if (c.result !== "pass" && c.cost !== undefined) {
                cost = <span className="small neutral4 mr-3"><img className="ml-2" style={{"marginTop": "-2px"}} src="/img/currency-dollar-light.svg" alt="cost" width="12" height="12"/> <Currency currency={c.cost.currency}/>{c.cost.value.toFixed(2) + "/" + c.cost.period + " cost if test failure in production."}</span>
            }

            auxRow =
            <div className="row">
                <div className="col-12">
                    {caseUpdated}
                    <span className="small neutral4">Assigned to: {name}</span>
                    {cost}
                </div>
            </div>
        } else {
            if (c.result !== "pass" && c.cost !== undefined) {
                let cost = <span className="small neutral4 mr-3"><img style={{"marginTop": "-2px"}} src="/img/currency-dollar-light.svg" alt="cost" width="12" height="12"/> <Currency currency={c.cost.currency}/>{c.cost.value.toFixed(2) + "/" + c.cost.period + " cost if test failure in production."}</span>
                auxRow =
                <div className="row">
                    <div className="col-12">
                        {cost}
                    </div>
                </div>
            }
        }

        let timeRow = <Duration format="case" c={c}/>

        let icon = img
        //resultBg = "whitebg";
        if (this.props.saving === true) {
            icon = <div className="spinner-border neutral7" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
            //resultBg = "whitebg";
        }

        let done = <span></span>
        if (c.manualComplete !== undefined && this.props.context === "manual") {
            if (c.manualComplete === false || c.manualComplete === "false") {
                //resultClass = "card whitebg summaryborder";
                //resultBg = "whitebg";
                icon = <img style={{"borderRadius" : "0px"}} className="nobg" height="24" width="24" alt="" src="/img/block.svg"/>
            } else {
                done = 
                <div style={{"display":"flex", "flexDirection":"column"}}>
                    <div>
                        <img className="ml-3" style={{"marginTop":"-4px"}} src="/img/checkmark-accentc3.svg" alt="expanded" width="16" height="16"/>
                    </div>
                    <div>
                        <span className="accentc3 font12">Done</span>
                    </div>
                </div>
            }
        }
        let complete = <span></span>
        if (c.complete !== undefined && this.props.context === "release-checklist") {
            if (c.complete === true || c.complete === "true") {
                complete = 
                <div style={{"display":"flex", "flexDirection":"column"}}>
                    <div>
                        <img className="ml-3" style={{"marginTop":"-4px"}} src="/img/checkmark-accentc3.svg" alt="expanded" width="16" height="16"/>
                    </div>
                    <div>
                        <span className="accentc3 font12">Complete</span>
                    </div>
                </div>
            }
        }
        if (this.props.context === "list") {
            icon = <img style={{"borderRadius" : "0px"}} className="nobg" height="24" width="24" alt="" src="/img/block.svg"/>
        }

        let caseBorder = "case-border-first";
        if (this.props.caseBorder === true) {
            caseBorder = "case-border";
        }

        let attribute2 = timeRow;
        if (auxRow !== undefined) {
            attribute2 = timeRow; //auxRow; removed until further UI updates
        }
        if (this.props.context === "manual" || this.props.context === "manual-archive") {
            if (c.manualAssignee !== undefined && this.props.members !== undefined) {
                let user = undefined;
                for (let i = 0; i < this.props.members.length; i++) {
                    let member = this.props.members[i];
                    if (member.id === c.manualAssignee) {
                        user = member;
                    }
                }
                attribute2 = <Assignee user={user} overlay={this.props.overlay}/>
            } else {
                attribute2 = <span></span>
            }
        }
        let caseName = c.name;
        let caseSuite = c.suite;
        if (caseName !== undefined && caseName !== null) {
            caseName = caseName.toString();
        }
        if (caseSuite !== undefined && caseSuite !== null) {
            caseSuite = caseSuite.toString();
        }

        /*
        let caseBackground = "caseBackgroundUnknown"
        if (utilsIsPassResult(c, map)) {
            caseBackground = "caseBackgroundPass"
        }
        if (utilsIsFailResult(c, map)) {
            caseBackground = "caseBackgroundFail"
        }*/
        let caseBackground = "caseBackground"

        let attribute0 = <span>{caseName}{params}</span>
        let attribute1 = <span>{caseSuite}</span>
        // attribute 2 defined above
        let attribute3 = <CasePriority case={c}/>
        let attribute4 = <span>{flaky}{bug}{done}</span>
        let attribute5 = <span>{icon}</span>

        if (preferencesReportingCaseDisplayAttributeMap !== undefined) {
            attribute1 = c[preferencesReportingCaseDisplayAttributeMap.attribute1]
            attribute2 = c[preferencesReportingCaseDisplayAttributeMap.attribute2]
            attribute3 = c[preferencesReportingCaseDisplayAttributeMap.attribute3]
        }

        if (this.props.context === "release-checklist") {
            attribute0 = <span>{c.name}</span>
            attribute1 = <span></span>
            attribute1 = <span></span>
            attribute2 = <span></span>
            attribute3 = <span></span>
            attribute4 = <span></span>
            attribute5 = <span>{complete}</span>
        }

        const project = (this.props.projects && this.props.projectIndex !== undefined && this.props.projectIndex < this.props.projects.length) ? this.props.projects[this.props.projectIndex] : undefined;

        return (
            <span className="caseLink" key={c.num}  onMouseEnter={() => this.hover(true)} onMouseLeave={() => this.hover(false)} ref={this.props.updatedCaseRef}>
                    <div class="case-container">
                        <div className="case-check-box">
                            {multiselect}
                        </div>
                        <div className={"case caseDiv " /*+ caseBackground + " "*/ + caseBorder} onClick={() => this.props.selectCase(c)}>
                            <div className={this.state.hover ? "case-name-enhance" : "case-name"}>
                                {attribute0}
                            </div>
                            <div className={this.state.hover ? "case-attribute-hide" : "case-attribute-1"}>
                                {attribute1}
                            </div>
                            <div className={this.state.hover ? "case-attribute-hide" : "case-attribute-2"}>
                                {attribute2}
                            </div>
                            <div className={this.state.hover ? "case-attribute-hide" : "case-attribute-3"}>
                                {attribute3}
                            </div>
                            <div className={"case-attribute-4"}>
                                {this.state.hover && project ? 
                                    <CaseEnhancedAnalysis 
                                        overlay={this.props.overlay}
                                        messageOverlay={this.props.messageOverlay}
                                        type="case-detail-failures"
                                        project={project}
                                        data={this.props.caseEnhancedAnalysis}
                                        selectedCase={this.props.case}
                                        active={this.selectOverlayActive}
                                        periodIndex={this.props.periodIndex}
                                    />
                                    : 
                                    attribute4
                                }
                            </div>
                            <div className="case-icon">
                                {attribute5}
                            </div>
                        </div>
                    </div>
            </span>
        );
    }
};

export default Case;