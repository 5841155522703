import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { createBrowserHistory } from "history";
import Embed from './components/Embed';
import TRLHandler from './components/TRLHandler';
import CRLHandler from './components/CRLHandler';
import App from './components/App';
import Site from './components/Site';
import { Provider } from './components/Context';
import FileViewer from './components/FileViewer';
import RCRLHandler from './components/RCRLHandler'

const updateContext = function (key, value) {
  contextValue[key] = value;
}
let contextValue = {update: updateContext};

const Routes = () => (
  <Provider value={contextValue}>
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Site} history={createBrowserHistory}/>
      <Route exact path="/administrator" component={Site} history={createBrowserHistory}/>
      <Route path="/file-viewer/:type/:url" component={FileViewer} history={createBrowserHistory}/>
      <Route exact path="/ref/:ref" component={Site} history={createBrowserHistory}/>
      <Route exact path="/about" component={Site} history={createBrowserHistory}/>
      <Route exact path="/blog" component={Site} history={createBrowserHistory}/>
      <Route exact path="/blog/:id" component={Site} history={createBrowserHistory}/>
      <Route exact path="/blog-editor" component={Site} history={createBrowserHistory}/>
      <Route exact path="/careers" component={Site} history={createBrowserHistory}/>
      <Route exact path="/careers/:id" component={Site} history={createBrowserHistory}/>
      <Route exact path="/config" component={App}  history={createBrowserHistory}/>
      <Route exact path="/createproject" component={App} history={createBrowserHistory}/>
      <Route exact path="/configProject" component={App} history={createBrowserHistory}/>
      <Route exact path="/config/profile" component={App} history={createBrowserHistory}/>
      <Route exact path="/config/:area" component={CRLHandler} history={createBrowserHistory}/>
      <Route exact path="/config/:area/:option" component={CRLHandler} history={createBrowserHistory}/>
      <Route exact path="/configUser" component={App} history={createBrowserHistory}/>
      <Route exact path="/configProfile" component={App} history={createBrowserHistory}/>
      <Route exact path="/consulting" component={Site} history={createBrowserHistory}/>
      <Route exact path="/contact" component={Site} history={createBrowserHistory}/>
      <Route exact path="/featurerequest" component={Site} history={createBrowserHistory}/>
      <Route exact path="/roadmap" component={Site} history={createBrowserHistory}/>
      <Route exact path="/demo" component={Site} history={createBrowserHistory}/>
      <Route exact path="/docs" component={Site} history={createBrowserHistory}/>
      <Route exact path="/docs/:docGroup/:doc" component={Site} history={createBrowserHistory}/>
      <Route exact path="/docs/:doc" component={Site} history={createBrowserHistory}/>
      <Route exact path="/exp" component={Site} history={createBrowserHistory}/>
      <Route exact path="/embed" component={Embed} history={createBrowserHistory}/>
      <Route exact path="/forgotpassword" component={Site} history={createBrowserHistory}/>
      <Route exact path="/inviteregister" component={Site} history={createBrowserHistory}/>
      <Route exact path="/login" component={Site} history={createBrowserHistory}/>
      <Route exact path="/login/:id" component={Site} history={createBrowserHistory}/>
      <Route exact path="/register" component={Site} history={createBrowserHistory}/>
      <Route exact path="/resetpassword" component={Site} history={createBrowserHistory}/>
      <Route exact path="/results" component={App} history={createBrowserHistory}/>
      <Route path="/results/rsp" component={App} history={createBrowserHistory}/>
      <Route exact path="/verifyemail" component={App} history={createBrowserHistory}/>
      <Route exact path="/tasks" component={App} history={createBrowserHistory}/>
      <Route exact path="/manual" component={App} history={createBrowserHistory}/>
      <Route path="/manual/msp" component={App} history={createBrowserHistory}/>
      <Route exact path="/manual/:state" component={App} history={createBrowserHistory}/>
      <Route exact path="/manual/archive" component={App} history={createBrowserHistory}/>
      <Route exact path="/manual/archive/msp/:id/:created" component={App} history={createBrowserHistory}/>
      <Route exact path="/lists" component={App} history={createBrowserHistory}/>
      <Route path="/lists/lsp" component={App} history={createBrowserHistory}/>
      <Route exact path="/lists/:state" component={App} history={createBrowserHistory}/>
      <Route exact path="/release-checklists" component={RCRLHandler} history={createBrowserHistory}/>
      <Route exact path="/release-checklists/config/:state" component={RCRLHandler} history={createBrowserHistory}/>
      <Route exact path="/release-checklists/:state/:project" component={RCRLHandler} history={createBrowserHistory}/>
      <Route exact path="/release-checklists/:state/:project/:team" component={RCRLHandler} history={createBrowserHistory}/>
      <Route exact path="/release-checklists/:state/:project/:team/:checklist" component={RCRLHandler} history={createBrowserHistory}/>
      <Route exact path="/release-checklists/:state/:project/:team/:checklist/:item" component={RCRLHandler} history={createBrowserHistory}/>
      <Route path="/design" component={App} history={createBrowserHistory}/>
      <Route exact path="/notifications" component={App} history={createBrowserHistory}/>
      <Route exact path="/feedback" component={App} history={createBrowserHistory}/>
      <Route exact path="/email-receive" component={Site} history={createBrowserHistory}/>
      <Route exact path="/email-receive/:id" component={Site} history={createBrowserHistory}/>
      <Route exact path="/affiliate" component={Site} history={createBrowserHistory}/>
      <Route exact path="/affiliate/:view" component={Site} history={createBrowserHistory}/>
      <Route exact path="/affiliate/dashboard/id/:id" component={Site} history={createBrowserHistory}/>
      <Route exact path="/channel-partner" component={Site} history={createBrowserHistory}/>
      <Route exact path="/notfound" component={Site} history={createBrowserHistory}/>
      <Route exact path="/:org" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project/dashboard" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project/results" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project/supplemental" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project/:target" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project/:target/:run" component={TRLHandler} history={createBrowserHistory}/>
      <Route exact path="/:org/:project/:target/:run/:testcase" component={TRLHandler} history={createBrowserHistory}/>
    </Switch>
  </BrowserRouter>
  </Provider>
)

export default Routes;