/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ResultsWhole from './ResultsWhole'

class Results extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Results</title>
                    <meta name="description" content="Tesults test reporting. View test results, analysis, files, data, bugs, financial cost of failures and track and assign failures to team members. Ship high quality builds fast and reduce risk and cost."/>
                </Helmet>
                <ResultsWhole overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} side={this.props.side} sideOpen={this.props.sideOpen} sideOverlay={this.props.sideOverlay} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView} history={this.props.history} resultsSubView={this.props.resultsSubView} resultsView={this.props.resultsView} supplementalView={this.props.supplementalView}/>
            </div>
        );
    }
}

export default Results;