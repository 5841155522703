/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DocsWebhooks extends Component {
    render () {
        let step3Img = <span></span>
        let config = <span></span>
        if (this.props.service === "MS Teams") {
            step3Img = <img src="/img/msteams-1.png" className="width100 mb-5" alt="Webhooks"/>
            config = <p>When creating the webhook within MS Teams we recommend you set <b>Tesults</b> as the name for the webhook and use <a className="site-link-primary2 no-break" href="https://www.tesults.com/files/icons/tesults-logo-centered-2021-05-14-128.png" target="_blank" rel="noopener noreferrer">this image</a> as the icon. A larger version is available <a className="site-link-primary2 no-break" href="https://www.tesults.com/files/icons/tesults-logo-centered-2021-05-14.png" target="_blank" rel="noopener noreferrer">here</a> if required.</p>
        } else if (this.props.service === "Mattermost") {
            step3Img = <img src="/img/mattermost-1.png" className="width100 mb-5" alt="Webhooks"/>
            config = <p>We recommend you set <b>Enable integrations to override usernames</b> and <b>Enable integrations to override profile picture icons</b> to true from the System Console within Mattermost. This will allow the notifications to display the Tesults icon and name, otherwise you will see the generic webhooks icon.</p>
        }

        return (
            <div className="whitebg mt-3">
                <p>Have Tesults send notifications to {this.props.service} when test results are posted by using incoming webhooks.</p>
                <br/>
                <h2 className="mt-3">1. Generate an incoming webhook url from {this.props.service}</h2>
                <p>Generate an incoming webhook url by following the instructions on <a className="site-link-primary2" href={this.props.url} target="_blank" rel="noopener noreferrer">{this.props.service}</a>. Copy the generated webhook url.</p>
                {config}
                <br/>
                <h2 className="mt-3">2. Configure webhooks on Tesults</h2>
                <p>In Tesults, click <Link to="/config" className="tr-link-no-underline"><span className="site-link bold">Configuration</span></Link> from the menu. Under projects click Webhooks.</p>
                <img src="/img/webhooks-config.png" className="width100 mb-5" alt="Webhooks configuration"/>
                <p>Paste the url generated in step 1 under the section for {this.props.service} and save. You can choose to apply the webhook at <b>project scope</b> or <b>target scope</b>. At project scope notifications will be sent to the webhook for all targets that post results within the project. In target scope, notifications can be specified to be sent to different webhooks, usually preferred by larger teams.</p>
                <img src={this.props.img} className="width100 mb-5" alt="Webhooks set url"/>
                <br/>
                <h2 className="mt-3">3. Complete</h2>
                <p>At this point, whenever new results are posted {this.props.service} will receive a notification. The message will contain a summary of results and include links back to Tesults for more detailed analysis.</p>
                {step3Img}
                <hr/>
                <iframe className='youtube' src="https://www.youtube.com/embed/e-29p1NkD9c?si=9ZiDLq40aMF6jzmh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        );
    }
}

export default DocsWebhooks;