/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsTarget extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Target</title>
                    <meta name="description" content="Learn about Tesults targets. Understand what targets are, what they do and how to use them for effective test reporting."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/LtR8S-crKaA?si=9J8e069i8ijmOmxl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Tesults helps you consolidate test results data from across your test jobs. A target maps one to one with a test job and is essentially a 'bucket' for you to upload results data to. A project can have one or many targets.
                </p>
                <p>A target just like a test job corresponds to a set of test run properties, for example:</p>
                <ul>
                <li><b>Build flavours</b> - Debug, Profile, Release</li>
                <li><b>Environments</b> - Development, Staging, UAT, QA, Production</li>
                <li><b>Branches</b> - Main, Feature X, Demo</li>
                <li><b>Platforms/OS</b> - Windows, macOS, Linux, iOS, Android</li>
                <li><b>Browsers</b> - Chrome, Safari, Firefox, Edge</li>
                <li><b>Devices</b> - iPhone 16, Samsung Galaxy S24, Playstation 5, Xbox X|S</li>
                <li><b>Types of tests</b> - Unit, Integration, System, Performance, Load, End-to-end</li>
                </ul>
                <p>
                In order to have test results history and comparisons between different test runs make sense it is important to upload test results to the right target. 
                The name of your targets is entirely up to you and what makes sense for your project. 
                You might create a target called 'Debug', or perhaps combine various properties together such as 'Debug-Windows-Main Branch', 
                and perhaps another called 'Release-Windows-Main Branch' etc.
                </p>
                <p>
                No matter what platforms, build flavors, or branches you have for your project, targets allow you to upload test results 
                in groups that makes sense for your project and team.
                </p>
                <h2 className="mb-4">Examples</h2>
                <h4>Example 1</h4>
                <p>'Team A' is responsible for developing a web application that has a web front-end and a REST back-end. They run UI automated tests for the front-end and API tests for the back-end and run the tests in just one 'test' environment. They will need <b>2 targets</b>:</p>
                <ol>
                    <li>Front-end</li>
                    <li>Back-end</li>
                </ol>
                <h4>Example 2</h4>
                <p>'Team B' works on a mobile app that runs on iOS and Android devices. They have dev and staging environments setup as part of their workflow and run automated tests against both environments and apps. They will require <b>4 targets</b>:</p>
                <ol>
                    <li>iOS-dev</li>
                    <li>iOS-staging</li>
                    <li>Android-dev</li>
                    <li>Android-staging</li>
                </ol>
                <h4>Example 3</h4>
                <p>'Team C' works on a web app and two mobile apps for iOS and Android devices. These apps depend upon three services that provide APIs (A, B, C). They have dev and staging environments setup as part of their workflow and run automated tests against both environments and all apps and services. They will need <b>12 targets</b>:</p>
                <ol>
                    <li>iOS-dev</li>
                    <li>iOS-staging</li>
                    <li>Android-dev</li>
                    <li>Android-staging</li>
                    <li>Web-dev</li>
                    <li>Web-staging</li>
                    <li>API-Service-A-dev</li>
                    <li>API-Service-A-staging</li>
                    <li>API-Service-B-dev</li>
                    <li>API-Service-B-staging</li>
                    <li>API-Service-C-dev</li>
                    <li>API-Service-C-staging</li>
                </ol>
                <h4>Example 4</h4>
                <p>'Team D' runs automated tests for their video app on web (browser), iOS, Android, Playstation 4 and Xbox One in 'dev' and 'staging' environments. These apps are supported by 5 services (A, B, C, D, E) that provide the APIs the clients use. All apps and services have automated tests setup and are tested in both environments as part of their continuous integration. They will need <b>20 targets</b>:</p>
                <ol>
                    <li>Web-dev</li>
                    <li>Web-staging</li>
                    <li>iOS-dev</li>
                    <li>iOS-staging</li>
                    <li>Android-dev</li>
                    <li>Android-staging</li>
                    <li>Playstation4-dev</li>
                    <li>Playstation4-staging</li>
                    <li>XboxOne-dev</li>
                    <li>XboxOne-staging</li>
                    <li>API-Service-A-dev</li>
                    <li>API-Service-A-staging</li>
                    <li>API-Service-B-dev</li>
                    <li>API-Service-B-staging</li>
                    <li>API-Service-C-dev</li>
                    <li>API-Service-C-staging</li>
                    <li>API-Service-D-dev</li>
                    <li>API-Service-D-staging</li>
                    <li>API-Service-E-dev</li>
                    <li>API-Service-E-staging</li>
                </ol>
                <p>In general, the number of the targets you need corresponds to the number of 'test jobs' or 'test pipelines' you have. Do not push all results data to just one or a lower number of targets than you need because you will not benefit from any of the analysis Tesults automatically does around historical trends, regression, failure task tracking, notifications, stats and supplemental analysis. In order for analysis to take place correctly on each test run the data must be comparable and this only makes sense within the context of appropriately utilized targets.</p>
                <p>You can create an unlimited number of targets on Standard and Plus plans.</p>
            </div>
        );
    }
}

export default DocsTarget;