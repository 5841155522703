/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Cache from './Cache'
import Context from './Context'
import Constants from './Constants'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class ConfigProjectImage extends Component {
    static contextType = Context

    constructor () {
        super()
        this.state = {edit: false, removed: false, file: undefined, error: "", message: "", submitting: false, confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.change = this.change.bind(this)
        this.remove = this.remove.bind(this)
        this.save = this.save.bind(this)
        this.fileSelect = this.fileSelect.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    init () {
        if (this.context !== undefined) {
            if (this.context.projects !== undefined) {
                if (Cache.preference.projectIndex !== undefined) {
                    let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
                    this.setState({project: project})
                }
            }
        }
    }

    change () {
        this.setState({edit: true});
    }

    remove () {
        let aext = this.state.project.aext;
        if (aext === undefined) {
            return;
        }
        this.setState({submitting: true, confirmation: {success: "Updating - please wait", failure: undefined}});
        Request.post("/projectAvatarDelete", {id: this.state.project.id, aext: aext}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to remove project image"}, submitting: false});
            } else {
                Analytics.event("ProjectAvatarDeleted");
                this.setState({removed: true, submitting: false, confirmation: {success: "Deleted successfully", failure: undefined}}, () => this.props.projectUpdated(data.project));
            }
        });
    }

    save () {
        if (this.state.file === undefined) {
            this.setState({confirmation: {success: undefined, failure: "Select an image for your project"}});
        } else {
            this.setState({submitting: true, message: "Updating - please wait"});
            var xmlHttpRequest = new XMLHttpRequest();
            xmlHttpRequest.open("POST", "/project/avatar/" + this.state.project.id, true);
            xmlHttpRequest.setRequestHeader("Content-Type", this.state.file.type);
            xmlHttpRequest.onreadystatechange = function() {
                if(xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
                    if (xmlHttpRequest.status === 200) {
                        Analytics.event("ProjectAvatarEdited");
                        this.setState({edit: false, removed: false, message: "", confirmation: {success: "The image has been updated", failure: undefined}, submitting: false});
                    } else {
                        this.setState({confirmation: {success: undefined, failure: "Unable to update project picture"}, message: "", submitting: false});
                    }
                }
            }.bind(this)
            xmlHttpRequest.send(this.state.file);
        }
    }

    fileSelect (e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = (e) => {
            let ext = undefined;
            if (file.type === "image/png") {
                ext = "png";
            }
            if (file.type === "image/jpg") {
                ext = "jpg";
            }
            if (file.type === "image/jpeg") {
                ext = "jpg";
            }
            if (ext === undefined) {
                this.setState({confirmation: {success: undefined, failure: "Your project picture must be a jpg or png file"}});
            } else {
                this.setState({file: file, fileDisplay: e.target.result, ext: ext, confirmation: {success: undefined, failure: undefined}});
            }
        };

        reader.readAsDataURL(file)
    }

    render () {
        let removeButton = <span></span>
        let image = <img src="/img/project-default-128.png" className="img64" alt=""/>
        const project = this.state.project;
        if (project === undefined) {
            return <div></div>
        }
        if (this.state.file !== undefined) {
            let src = this.state.fileDisplay;
            image = <img src={src} className="img64" alt=""/>
        } else if (project.aext !== undefined && this.state.removed === false) {
            let src = '/project/avatar/pdla/' + project.id + '/' + project.aext;
            image = <img src={src} className="img64" alt=""/>
            removeButton = <button type="button" className="btn-cancel" onClick={this.remove} disabled={this.state.submitting}>Delete</button>
                           
        } 

        let choosefile = <span></span>
        let buttonText = "Change";
        let action = this.change;
        let error = this.state.error;
        let message = this.state.message;
        if (this.state.edit === true) {
            choosefile = <input type="file" id="input" className="btn-transparent mt-3" onChange={this.fileSelect}/>
            buttonText = "Confirm";
            action = this.save;
        }

        return (
            <div>
                <label className="neutral4 font14">Project image</label>
                <div style={{"display":"flex"}}>
                    <div style={{"width":"140px"}} className='flex-1'>
                        {image}
                        <div>{choosefile}</div>
                        <div className="pass">{message}</div>
                        <div className="fail">{error}</div>
                    </div>
                    <div className='flex-row-wide-column-narrow flex-1'>
                        <div className='mr-3'>
                            <button type="button" className="btn-confirm" onClick={action} disabled={this.state.submitting}>{buttonText}</button>
                        </div>
                        <div>
                            {removeButton}
                        </div>
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigProjectImage