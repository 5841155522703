/*global */
import React, { Component } from 'react';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class AffiliateDashboardHeader extends Component {
    constructor() {
        super();
        this.state = {affiliate: undefined};
        this.affiliate = this.affiliate.bind(this);
    }

    componentDidMount () {
        this.affiliate();
    }

    affiliate () {
        if (this.props.id === undefined) {
            return;
        }
        Request.get("/affiliate", {id: this.props.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                this.setState({affiliate: data.affiliate});
            }
        });
    }

    render () {
        if (this.state.affiliate === undefined) {
            return <div></div>
        } else {
            const data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Created", minWidth: "200px"},
                    {value: "Approve", minWidth: "100px"},
                    {value: "Link"}
                ],
                rows: [
                    {
                        cols: [
                            {value: this.state.affiliate.id},
                            {value: TimeFormatted.timeformatted(this.state.affiliate.created, true)},
                            {value: this.state.affiliate.approved ? "Approved" : "Unapproved"},
                            {value: <span>{"https://www.tesults.com/ref/" + this.state.affiliate.id}</span>}
                        ]
                    }
                ]
            }
            return (
                <div>
                    <h4 className="neutral3">Affiliate link</h4>
                    <TabularData data={data}/>
                </div>
            );
        }
    }
};

export default AffiliateDashboardHeader;