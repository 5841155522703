/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from './Analytics';
import Request from './Request';
import Context from './Context';
import Cache from './Cache';


class Onboarding extends Component {
    static contextType = Context;
    mounted = false;

    constructor (props) {
        super(props);
        // 10 Onboarding Actions:
        // 1. Create a project 
        // 2. Add at least one team member
        // 3. Rename target
        // 4. Set Target Icon
        // 5. Set Profile Icon
        // 6. Set Project Icon
        // 7. Upload results (automated)
        // 8. Upload results (manual)
        // 9. Create test list
        // 10. Upload results with at least one file
        this.state = {
            checklist: undefined,
            role: 1,
            stats: undefined,
            usage: undefined,
            projectIndex: this.props.projectIndex,
            projects: this.props.projects,
            expand: false
        };

        this.getProjectRoleFunctionality = this.getProjectRoleFunctionality.bind(this);
        this.getProjectUsage = this.getProjectUsage.bind(this);
        this.getProjectStats = this.getProjectStats.bind(this);
        this.expand = this.expand.bind(this);
    }

    componentDidMount () {
        this.mounted = true;
        this.setState({role: this.props.role, projectIndex: Cache.getPreference(Cache.preference.projectIndex), projects: this.context.projects, stats: undefined, usage: undefined, checklist: undefined}, () => this.getProjectRoleFunctionality(this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]));
    }

    componentWillUnmount () {
        this.mounted = false;
    }

    getProjectRoleFunctionality(project) {
        if (project === undefined) {
            return;
        }
        if (this.mounted) {
            this.setState({loading: true});
            Request.get("/projectRole", {id: project.id}, (err, data) => {
                if (err) {
                    if (this.mounted) {
                        this.setState({role: 1, usage: undefined, loading: false});
                    }
                } else {
                    if (this.mounted) {
                        this.setState({role: data.role, usage: undefined, loading: false}, () => this.getProjectUsage(project));
                    }
                }
            });
        }
    }

    getProjectUsage (project) {
        if (project === undefined) {
            return;
        }
        if (this.state.role < 3) {
            return;
        }
        if (this.mounted) {
            this.setState({loading: true});
            Request.get("/projectUsage", {id: project.id}, (err, data) => {
                if (err) {
                    if (this.mounted) {
                        this.setState({usage: undefined, loading: false});
                    }
                } else {
                    let usage = {};
                    usage.targets = {};
                    usage.targets.used = data.targets.used;
                    usage.targets.limit = data.targets.limit;
                    usage.targets.renamed = data.targets.renamed;
                    usage.users = {};
                    usage.users.used = data.users.used;
                    usage.users.limit = data.users.limit;
                    usage.projectIcon = data.projectIcon;
                    usage.targetIcons = {};
                    usage.targetIcons.used = data.targetIcons.used;
                    usage.targetIcons.limit = data.targetIcons.limit;
                    usage.avatarIcon = data.avatarIcon;
                    usage.files = data.fileUsedPercent;
                    usage.data = data.dataUsedPercent;
                    usage.dataReset = data.dataReset;
                    if (this.mounted) {
                        this.setState({usage: usage, loading: false}, () => this.getProjectStats(project));
                    }
                }
            });
        }
    }

    getProjectStats (project) {
        if (project === undefined) {
            return;
        }
        if (this.state.role < 3) {
            return;
        }
        if (this.state.usage === undefined) {
            return;
        }
        if (this.mounted) {
            this.setState({loading: true});
            Request.get("/stats", {id: project.id}, (err, data) => {
                if (err) {
                    if (this.mounted) {
                        this.setState({stats: undefined, loading: false});
                    }
                } else {
                    if (this.state.usage === undefined) {
                        if (this.mounted) {
                            this.setState({loading: false, checklist: undefined});
                        }
                        return;
                    }
    
                    let stats = data.stats;
                    // Create checklist
                    /*
                    projectCreated: false,
                    memberAdded: false,
                    targetNamed: false,
                    targetIcon: false,
                    projectIcon: false,
                    profileIcon: false,
                    resultsUploadAutomated: false,
                    resultsUploadManual: false,
                    listCreated: false,
                    resultUploadFileAutomated: false,
                    resultsUploadFileManual: false,
                    fileUploaded: false
                    */
                    let projectCreated = false;
                    let memberAdded = false;
                    let targetNamed = false;
                    let targetIcon = false;
                    let projectIcon = false;
                    let profileIcon = false;
                    let resultsUploadAutomated = false;
                    let resultsUploadManual = false;
                    let listCreated = false;
                    //let resultUploadFileAutomated = false;
                    //let resultsUploadFileManual = false;
                    let fileUploaded = false;
    
                    projectCreated = true;
                    if (this.state.usage.users.used > 1) {
                        memberAdded = true;
                    }
                    if (this.state.usage.targets.renamed === true) {
                        targetNamed = true;
                    }
                    if (this.state.usage.targetIcons.used > 0) {
                        targetIcon = true;
                    }
                    if (this.state.usage.projectIcon === true) {
                        projectIcon = true;
                    }
                    if (this.state.usage.avatarIcon === true) {
                        profileIcon = true;
                    }
                    if (stats.runs > 0) {
                        resultsUploadAutomated = true;
                    }
                    if (stats.runsManual > 0) {
                        resultsUploadManual = true;
                    }
                    if (stats.listsCreated > 0) {
                        listCreated = true;
                    }
                    if (stats.runsWithFile > 0) {
                        //resultUploadFileAutomated = true;
                        fileUploaded = true;
                    }
                    if (stats.runsManualWithFile > 0) {
                        //resultsUploadFileManual = true;
                        fileUploaded = true;
                    }
    
                    let checklist = {
                        projectCreated: projectCreated,
                        memberAdded: memberAdded,
                        targetNamed: targetNamed,
                        targetIcon: targetIcon,
                        projectIcon: projectIcon,
                        profileIcon: profileIcon,
                        resultsUploadAutomated: resultsUploadAutomated,
                        resultsUploadManual: resultsUploadManual,
                        listCreated: listCreated,
                        fileUploaded: fileUploaded
                    };

                    if (this.mounted) {
                        this.setState({stats: stats, checklist: checklist, loading: false});
                    }
                }
            });
        }
    }

    expand () {
        Analytics.event("OnboardingExpand");
        this.setState({expand: (this.state.expand === true ? false : true)});
    }

    render() {
        if (this.state.loading === true) {
            return (
                <span></span>
            );
        } else if (this.state.checklist === undefined) {
            return (
                <span></span>
            );
        } else {
            /*
            checklist = {
                projectCreated: projectCreated,
                memberAdded: memberAdded,
                targetNamed: targetNamed,
                targetIcon: targetIcon,
                projectIcon: projectIcon,
                profileIcon: profileIcon,
                resultsUploadAutomated: resultsUploadAutomated,
                resultsUploadManual: resultsUploadManual,
                listCreated: listCreated,
                fileUploaded: fileUploaded
            };
            */

            let passImg = <img src="/img/tesults-logo-pass-128.png" width="32" height="32" alt=""/>
            let failImg = <img src="/img/tesults-logo-fail-128.png" width="32" height="32" alt=""/>


            let percent = 10;
            if (this.state.checklist.memberAdded === true) { percent += 10; }
            if (this.state.checklist.targetNamed === true) { percent += 10; }
            if (this.state.checklist.targetIcon === true) { percent += 10; }
            if (this.state.checklist.projectIcon === true) { percent += 10; }
            if (this.state.checklist.profileIcon === true) { percent += 10; }
            if (this.state.checklist.resultsUploadAutomated === true) { percent += 10; }
            if (this.state.checklist.listCreated === true) { percent += 10; }
            if (this.state.checklist.resultsUploadManual === true) { percent += 10; }
            if (this.state.checklist.fileUploaded === true) { percent += 10; }
            percent = percent + "%";

            if (percent === "100%") {
                return (
                    <div></div>
                );
            } else {
                let onboardingContent = <span></span>
                if (this.state.expand === true) {
                    onboardingContent = 
                    <div>
                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{passImg}</div>
                            <div>
                                <div className="font14 bold">1. Project Created</div>
                                <div className="font14">Click 'Create New Project' from <Link className="site-link-underline" target="_blank" to="/config">Configuration</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.memberAdded ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">2. Team Member Added</div>
                                <div className="font14">Select 'Team members' from <Link className="site-link-underline" target="_blank" to="/config">Configuration</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.targetNamed ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">3. Target Renamed from 'Default'</div>
                                <div className="font14">Select 'Target name' from <Link className="site-link-underline" target="_blank" to="/config">Configuration</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.targetIcon ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">4. Target Image Set</div>
                                <div className="font14">Select 'Target image' from <Link className="site-link-underline" target="_blank" to="/config">Configuration</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.projectIcon ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">5. Project Image Set</div>
                                <div className="font14">Select 'Project image' from <Link className="site-link-underline" target="_blank" to="/config">Configuration</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.profileIcon ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">6. Profile Image Set</div>
                                <div className="font14">Select 'Edit Profile Image' from <Link lassName="site-link-underline" target="_blank" to="/config">Configuration</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.resultsUploadAutomated ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">7. Automated Test Results Submitted</div>
                                <div className="font14">Integrate with your test systems. <Link className="site-link-underline" target="_blank" to="/docs">Documentation</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.listCreated ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">8. List Created</div>
                                <div className="font14">Select 'Create new list' from <Link className="site-link-underline" target="_blank" to="/lists">Lists</Link>.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.resultsUploadManual ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">9. Manual Results Submitted</div>
                                <div className="font14">Select <Link className="site-link-underline" target="_blank" to="/manual">Runs</Link> to create a test run. Import test cases from a list.</div>
                            </div>
                        </div>

                        <div className="onboarding-item" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"width":"100px"}}>{this.state.checklist.fileUploaded ? passImg : failImg}</div>
                            <div>
                                <div className="font14 bold">10. Files Submitted</div>
                                <div className="font14">Submit at least one file with either automated or manually submited test results.</div>
                            </div>
                        </div>

                    </div>
                }

                return (
                    <div className="app-content-margin">
                        <h4 className="mb-3 neutral4">Project onboarding {percent} complete</h4>
                        <div className="mb-3 neutral4 font12">Only visible to project administrators with less than 100% onboarding completion</div>
                        <button type="button" onClick={this.expand} className="btn-confirm mb-3">View steps to complete onboarding</button>
                        {onboardingContent}
                    </div>
                );
            }
        }
    }
}

export default Onboarding;