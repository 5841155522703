/*global */
import React, { Component } from 'react'
import Time from './Time'
import PassRate from './PassRate'
import Collapse from './Collapse'
import SuiteAnnotation from './SuiteAnnotation'
import { test } from 'linkifyjs'

class CaseGroupHeader1 extends Component {
    render () {
        let passRate = "";
        let runMetadata = undefined;
        if (this.props.runs !== undefined && this.props.runIndex !== undefined) {
            if (this.props.runIndex < this.props.runs.length) {
                const run = this.props.runs[this.props.runIndex];
                runMetadata = {
                    integrationName: run.integrationName,
                    integrationVersion: run.integrationVersion,
                    testFramework: run.testFramework,
                }
            }
        }

        if (this.props.cases !== undefined) {
            passRate = <PassRate 
                            context={this.props.context}
                            type="group"
                            cases={this.props.cases}
                            prevCases={this.props.prevCases}
                            totalOnly={this.props.totalOnly}
                            percentClass={this.props.percentClass}
                            targets={this.props.targets}
                            targetIndex={this.props.targetIndex}
                            rawResultMaps={this.props.rawResultMaps}
                            runs={this.props.runs}
                            runindex={this.props.runindex}
                            runMetadata={runMetadata}
                        />
        }
        let title = this.props.title;
        if (title !== undefined && title !== null) {
            title = title.toString()
        }
        return (
            <div className="case-group-header case-group-border neutral8bg">
                <div className="case-check-box">
                    {this.props.multiselectGroup}
                </div>
                <div className="case-group-collapse mr-2">
                    <button className="btn-transparent" onClick={this.props.toggleCollapse}>
                        <Collapse collapse={this.props.collapse}/>
                    </button>
                </div>
                <div className="case-group-title">
                    <span className="neutral1 overflow-ellipsis-no-wrap bold" >{title}</span>
                    <SuiteAnnotation
                        suiteName={title}
                        persistentSuiteDataUpdate={this.props.persistentSuiteDataUpdate}
                        persistentSuiteDataIteration={this.props.persistentSuiteDataIteration}
                        persistentSuiteData={this.props.persistentSuiteData}
                    />
                </div>
                <div className="case-group-time">
                    <Time duration={this.props.duration} intervalStart={this.props.intervalStart} intervalEnd={this.props.intervalEnd}/>
                    {
                        this.props.addCaseToSuite === undefined ? "" : <button className="btn-transparent neutral4" onClick={() => this.props.addCaseToSuite(this.props.title)}><img src="/img/add.svg" width="10" height="10" alt="add"/> {this.props.context === "release-checklist" ? "Add item" : "Add test case"}</button> 
                    }
                </div>
                <div className="case-group-pass-rate">
                    {passRate}
                </div>
            </div>
        );
    }
};

export default CaseGroupHeader1;