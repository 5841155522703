/*global */
import React, { Component } from 'react'
import Loading from './Loading'
import Analytics from './Analytics'
import Request from './Request'
import Context from './Context'
import Cache from './Cache'
import Confirmation from './Confirmation'
import RawResultMap from './RawResultMap'
import Notice from './Notice'

class RawResultsMaps extends Component {
    static contextType = Context;

    constructor () {
        super ();
        this.state = {loading: false, maps: [], edit: false, editMap: undefined, esk: undefined, targets: undefined, confirmation: {successs: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.project = this.project.bind(this)
        this.maps = this.maps.bind(this)
        this.edit = this.edit.bind(this)
        this.update = this.update.bind(this)
        this.delete = this.delete.bind(this)
        this.cancelEdit = this.cancelEdit.bind(this)
        this.canDeleteMap = this.canDeleteMap.bind(this)
        this.save = this.save.bind(this)
        this.renderMap = this.renderMap.bind(this)
        this.renderExistingMaps = this.renderExistingMaps.bind(this)
    }
    
    componentDidMount () {
        this.init()
    }

    init () {
        this.targets()
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    targets () {
        let project = this.project()
        if (project === undefined) {
            return
        }
        this.setState({loading: true})
        Cache.request(this.context, Cache.data.targets, {id: project.id}, (err, targets) => {
            if (err) {
                // Ignore
                this.setState({loading: false})
            } else {
                this.setState({targets: targets, loading: false}, this.maps);
            }
        });
    }

    maps () {
        let project = this.project()
        if (project === undefined ) { return }
        this.setState({loading: true})
        Request.get("/raw-result-map-get-maps", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({data: undefined, loading: false, confirmation: {success: undefined, failure: "Error fetching data"}});
            } else {
                let maps = []
                if (data.maps !== undefined) {
                    maps = data.maps
                }
                this.setState({maps: maps, esk: data.lek, loading: false});
            }
        });
    }

    edit (map) {
        this.setState({edit: true, editMap: map})
    }

    cancelEdit () {
        this.setState({edit: false, editMap: undefined, maps: [], confirmation: {success: undefined, failure: undefined}}, () => this.maps())
    }

    canDeleteMap (created) {
        if (this.state.targets === undefined) { return false }
        let can = true
        for (let i = 0; i < this.state.targets.length; i++) {
            let target = this.state.targets[i]
            if (target.rawResultMap === created) {
                can = false
            }
        }
        return can
    }

    delete (created) {
        let project = this.project()
        if (project === undefined ) { return }
        let data = {
            id: project.id,
            created: created
        };
        this.setState({loading: true});
        Request.post("/raw-result-map-delete", data, (err, data) => {
            if (err) {
                this.setState({
                    confirmation: {
                        success: undefined,
                        failure: "Unable to delete results map."
                    },
                    loading: false
                })
            } else {
                Analytics.event("RawResultsMapDeleted");
                this.setState({
                    edit: false,
                    editMap: undefined,
                    confirmation: {
                        success: undefined,
                        error: undefined
                    },
                    loading: false
                }, () => this.maps())
            }
        });
    }

    save (label, map) {
        let project = this.project()
        if (project === undefined ) { return }
        let data = {
            id: project.id,
            label: label,
            map: map
        };
        this.setState({loading: true});
        Request.post("/raw-result-map-create", data, (err, data) => {
            if (err) {
                this.setState({
                    confirmation: {
                        success: undefined,
                        failure: "Unable to save new results map."
                    },
                    loading: false
                })
            } else {
                Analytics.event("RawResultsMapCreated");
                let maps = this.state.maps
                maps.push(data.map)
                this.setState({
                    map: map, 
                    edit: false,
                    editMap: undefined,
                    confirmation: {
                        success: undefined,
                        error: undefined
                    },
                    loading: false
                }, () => this.maps())
            }
        });
    }

    update (label, created, map) {
        let project = this.project()
        if (project === undefined ) { return }
        let data = {
            id: project.id,
            created: created,
            label: label,
            map: map
        };
        this.setState({loading: true});
        Request.post("/raw-result-map-update", data, (err, data) => {
            if (err) {
                this.setState({
                    confirmation: {
                        success: undefined,
                        failure: "Unable to update results map."
                    },
                    loading: false
                })
            } else {
                Analytics.event("RawResultsMapCreated");
                let maps = this.state.maps
                maps.push(data.map)
                this.setState({
                    map: map, 
                    edit: false,
                    editMap: undefined,
                    confirmation: {
                        success: undefined,
                        error: undefined
                    },
                    loading: false
                }, () => this.maps())
            }
        });
    }

    renderMap (map) {
        return (
            <div>
                <h4>{map ? "Edit result map" : "New result map"}</h4>
                <RawResultMap overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} cancel={this.cancelEdit} save={this.save} update={this.update} map={this.state.editMap} confirmation={this.state.confirmation}/>
            </div>
        )
    }

    renderExistingMaps () {
        if (this.state.maps === undefined) {
            return <div>Invalid state</div>
        }
        let maps = <div>No maps</div>
        if (this.state.maps.length > 0) {
            maps = []
            for (let i = 0; i < this.state.maps.length; i++) {
                let map = this.state.maps[i]
                if (map) {
                    maps.push(
                        <div key={i} className="flex-row mb-3" style={{"alignItems":"center"}}>
                            <div className='mr-3 font15 overflow-ellipsis-no-wrap' style={{"width":"200px"}}>
                                {map.label}
                                <br/>
                                <span className='font12 neutral4'>Id: {map.created}</span>
                            </div>
                            <div className='mr-3'>
                                <button className='btn-cancel' onClick={() => this.edit(map)}>View/Edit</button>
                            </div>
                            <div>
                                {
                                    this.canDeleteMap(map.created) ?
                                    <button className='btn-cancel' onClick={() => this.delete(map.created)}>Delete</button>
                                    :
                                    <span></span>
                                }
                            </div>
                        </div>
                    )
                }
            }
        }
        return (
            <div>
                <h4>Result Interpretation</h4>
                <div className='mb-5 font15' style={{"maxWidth":"800px"}}>
                    <p>A test result other than pass or fail is by default interpreted as having 'unknown' status for reporting purposes. This could include tests that are skipped or have any other status other than a passing or failing result.</p>
                    <p>In some cases a team may prefer a result other than pass to be considered a pass for calculating the pass rate. A team may also want status values other than pass, fail or unknown to be surfaced in the reporting interface without having to open test case detail to view this information. In order to support this, a target can be assigned a result interpretation map. Define a result map here and then assign it to one or more targets in target configuration.</p>
                    <p></p>
                    <Notice type="information" content={
                        <div>
                            Not all test framework integrations currently support result interpretation maps. Check the documentation for your integraion to see if it does. Language library or integration authors can support result maps by utilizing the rawResult property alongside the result property.
                        </div>}
                    />
                </div>
                <hr className='dividor-light'/>
                <div className='neutral4 font15'>Maps</div>
                <div className='mt-3 mb-5'>
                    {maps}
                </div>
                <hr className='dividor-light'/>
                <div className='mt-3 mb-3'>
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
                <button className='btn-select' onClick={() => this.edit()}>Create new results mapping</button>
            </div>
        )
    }

    render () {
        if (this.state.loading === true) {
            return (
                <div className="text-center">
                    <Loading/>
                </div>
            );
        }

        if (this.state.edit) {
            return this.renderMap()
        } else {
            return this.renderExistingMaps()
        }
    }
}

export default RawResultsMaps