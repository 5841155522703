/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';
import Notice from './Notice';

class DocsSwift extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Swift test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Swift test reporting with Tesults."/>
        </Helmet>

        const plugins =
        <div className='mt-5 mb-5'>
            <Notice type="information" content={
            <div>
                <a href="https://developer.apple.com/documentation/xctest" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Using XCTest/XCUITest?</a> It is strongly recommended you use the <NavLink to="/docs/xctest" target="_blank" className="tr-link-primary4 no-break no-break">tesults-xctest-observer</NavLink> package for reporting results to Tesults. The <NavLink to="/docs/xctest" target="_blank" className="tr-link-primary4 no-break no-break">tesults-xctest-observer</NavLink> makes it possible to report results with minimal configuration and no code. The documentation here is a lower-level Swift package for test framework authors. If you are using a custom test framework, the documentation here may be useful to you.
            </div>}/>
        </div>
        
        const installation = 
        <div>
            <p>Add the tesults-swift package as a dependency to your project. Search for tesults-swift in Xcode to find the package.</p>
            <img src="/img/tesults-swift-package.png" className='width75' alt="tesults-swift-package"/>
            <p>Ensure you <b>install version 1.0.9 or higher</b>. You can verify the version you have installed by checking package dependencies.</p>
            <img src="/img/tesults-swift-package-dependency-check.png" className='width50' alt="tesults-swift-package-dependency-check"/>
            <p>If you receive a build error about missing dependencies for tests, ensure the Tesults library is added to the <i>Link Binary With Libaries</i> section of <i>Build Phases</i> for the target:</p>
            <img src="/img/xcode-link-tesults.png" className="width50" alt="link-tesults"/>
        </div>

        const configuration =
        <div>
            <p>Make use of the package using an import statement in any file you need it:</p>
            <pre className="docsCode">
                import <code className="accentb4">tesults</code>
            </pre>
        </div>

        const usage =
        <div>
            <p>Upload results using the results function of the Tesults struct. This is the single point of contact between your code and Tesults.</p>
            <pre className="docsCode">
                <code className="accentb4">Tesults().results(data: data)</code>
            </pre>
            <p className="mt-3">The results function is async so use await. The results function returns a ResultsResponse struct with four properties indicating success or failure:</p>
            <pre className="docsCode">
                print("Tesults results upload...")<br/>
                let resultsResponse = await Tesults().results(data: data)<br/>
                print("Success: \(resultsResponse.success)")<br/>
                print("Message: \(resultsResponse.message)")<br/>
                print("Warnings: \(resultsResponse.warnings.count)")<br/>
                print("Errors: \(resultsResponse.errors.count)")
            </pre>
            <p className="mt-3">The results function returns a ResultsResponse type that you can use to check whether the upload was a success.</p>
            <ul>
                <li>Value for key "success" is a Bool: true if results successfully uploaded, false otherwise.</li>
                <li>Value for key "message" is a String: if success is false, check message to see why upload failed.</li>
                <li>Value for key "warnings" is a [String], if count is not zero there may be issues with file uploads.</li>
                <li>Value for key "errors" is a [String], if "success" is true then this will be empty.</li>
            </ul>
        </div>

        const example =
        <div>
            <p>The data param passed to resultss is a Dictionary&lt;String, Any?&gt; containing your results data. Here is a complete example showing how to populate data with your build and test results and then upload it:</p>
            <i><small>Complete example:</small></i>
            <br/>
            <pre className="docsCode">
                <code className="accentc6">{'//'} Required imports:</code><br/>
                import tesults<br/><br/>

                <code className="accentc6">{'//'} Other imports:</code><br/>
                import XCTest<br/>
                @testable import app_under_test<br/><br/>
                
                <code className="accentc6">{'//'} Dictionary to hold test cases data.</code><br/>
                var cases : [Dictionary&lt;String, Any&gt;] = []<br/><br/>

                <code className="accentc6">{'//'} Each test case is a Dictionary&lt;String, Any&gt; too. We use generic types rather than<br/>
                {'//'} concrete helper classes so that if and when the Tesults services adds more fields<br/>
                {'//'} you do not have to update the library.<br/><br/>
                
                {'//'} You would usually add test cases in a loop taking the results from the data objects<br/>
                {'//'} in your build/test scripts. At a minimum you must add a name and result:</code><br/>
                var testCase1 = Dictionary&lt;String, Any&gt;()<br/>
                testCase1["name"] = "Test Case 1"<br/>
                testCase1["result"] = "pass" <code className="accentc6">{'//'} result value must be pass, fail or unknown</code><br/><br/>
                testCase1["suite"] = "Suite A" <code className="accentc6">{'//'} Suite is usually the class name</code><br/>
                testCase1["desc"] = "Test Case 1 description"<br/>

                cases.append(testCase1);<br/><br/>

                var testCase2 = Dictionary&lt;String, Any&gt;()<br/>
                testCase2["name"] = "Test 2"<br/>
                testCase2["desc"] = "Test 2 description"<br/>
                testCase2["suite"] = "Suite B"<br/>
                testCase2["result"] = "pass"<br/><br/>
                
                <code className="accentc6">{'//'} (Optional) Add start and end time for test (in milliseconds since epoch):</code><br/>
                <code className="accentc6">{'//'} In this example, end is set to 100 seconds later</code><br/> 
                
                testCase2["start"] =  Date().timeIntervalSince1970*1000<br/>
                testCase2["end"] = (Date().timeIntervalSince1970 + 100) * 1000<br/><br/>
                <code className="accentc6">{'//'} An optional duration can also be set if a start or end time is unavailable</code><br/> 
                testCase2["duration"] = 100 * 1000<br/><br/>

                <code className="accentc6">{'//'} (Optional) For a paramaterized test case:</code><br/>
                testCase2["params"] = ["Param 1" : "Param 1 Value", "Param 2" : "Param 2 Value"]<br/><br/>

                <code className="accentc6">{'//'} (Optional) Custom fields start with an underscore:</code><br/>
                testCase2["_Custom field"] = "Custom field value"<br/><br/>

                cases.append(testCase2);<br/><br/>

                var testCase3 = Dictionary&lt;String, Any&gt;()<br/>
                testCase3["name"] = "Test 3"<br/>
                testCase3["desc"] = "Test 3 description"<br/>
                testCase3["suite"] = "Suite A"<br/>
                testCase3["result"] = "fail"<br/>
                testCase3["reason"] = "Assert fail in line 203 of example.swift." <code className="accentc6">{'//'} Test failure reason</code><br/><br/>

                <code className="accentc6">{'//'} (Optional) For uploading files:</code><br/>
                testCase3["files"] = ["/full/path/to/file/log.txt", "/full/path/to/file/screenshot.png"]<br/><br/>

                <code className="accentc6">{'//'} (Optional) For providing test steps for a test case:</code><br/>
                testCase3["steps"] = [<br/>
                &nbsp;&nbsp;[<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"name":"Step 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"desc":"Step 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"result":"pass"<br/>
                &nbsp;&nbsp;],<br/>
                &nbsp;&nbsp;[<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"name":"Step 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"desc":"Step 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"result":"fail",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"reason":"Failure reason if result is a fail"<br/>
                &nbsp;&nbsp;]<br/>
                ]<br/><br/>

                cases.append(testCase3);<br/><br/>

                <code className="accentc6">{'//'} Prepare data for upload</code><br/>
                var data = Dictionary&lt;String, Any&gt;()<br/>
                data["target"] = "token" <code className="accentc6">{'//'} The token for the target you wish to upload to</code><br/>
                data["results"] = ["cases" : cases] <code className="accentc6">{'//'} Test case data</code><br/><br/>

                <code className="accentc6">{'//'} Upload</code><br/>
                print("Tesults results upload...")<br/>
                let resultsResponse = await Tesults().results(data: data)<br/>
                print("Success: \(resultsResponse.success)")<br/>
                print("Message: \(resultsResponse.message)")<br/>
                print("Warnings: \(resultsResponse.warnings.count)")<br/>
                print("Errors: \(resultsResponse.errors.count)")<br/>
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
        </div>

        return (
            <DocsLanguage
                language="swift"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsSwift;