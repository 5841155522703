/*global Cookies*/
import React, { Component } from 'react';
import Loading from './Loading';
import Request from './Request';

class PagerDuty extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {state: "view", services: [], selectedService: undefined, target: undefined, error: undefined, loading: false, user: user, saved: true, integrationsEdit: {}, removeConfirm: false, clientId: undefined};
        this.integrations = this.integrations.bind(this);
        this.services = this.services.bind(this);
        this.add = this.add.bind(this);
        this.addConfirm = this.addConfirm.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.serviceChange = this.serviceChange.bind(this);
        this.tesultsFlowIntegration = this.tesultsFlowIntegration.bind(this);
        this.tesultsFlowIntegrationUpdate = this.tesultsFlowIntegrationUpdate.bind(this);
        this.pagerdutysubdomain = this.pagerdutysubdomain.bind(this);
        this.removeFromProject = this.removeFromProject.bind(this);
        this.removeFromProjectConfirm = this.removeFromProjectConfirm.bind(this);
        this.removeFromProjectCancel = this.removeFromProjectCancel.bind(this);
        this.inameChange = this.inameChange.bind(this);
        this.ikChange = this.ikChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.setClientId();
        this.pagerdutysubdomain();
    }

    setClientId () {
        Request.get("/client-id", {key: "PAGERDUTY_CLIENT_ID"}, (err, data) => {
            if (err) {
                this.setState({clientId: undefined, error: "Unable to proceed with PagerDuty integrated"});
            } else {
                this.setState({clientId: data.value});
            }
        })
    }

    serviceChange (e) {
        this.setState({selectedService: e.target.value});
    }

    inameChange (e, target) {
        let integrationsEdit = this.state.integrationsEdit;
        integrationsEdit[target.created].iname = e.target.value;
        this.setState({integrationEdit: integrationsEdit});
    }

    ikChange (e, target) {
        let integrationsEdit = this.state.integrationsEdit;
        integrationsEdit[target.created].ik = e.target.value;
        this.setState({integrationEdit: integrationsEdit});
    }

    pagerdutysubdomain () {
        let data = {id: this.props.project.id};
        this.setState({loading: true});
        Request.get("/pagerdutysubdomain", data, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch PagerDuty subdomain."});
            } else {
                const subdomain = data.subdomain;
                if (subdomain === undefined) {
                    this.setState({loading: false, subdomain: undefined});
                } else {
                    this.setState({loading: false, subdomain: subdomain}, () => this.integrations());
                }
            }
        });
    }

    integrations () {
        this.setState({loading: true});
        Request.post("/pagerdutyintegrations", {id: this.props.project.id, targets: this.props.targets}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch PagerDuty integrations."});
            } else {
                let integrations = data.integrations;
                if (this.state.subdomain === "tesults-flow") {
                    for (let i = 0; i < this.props.targets.length; i++) {
                        let target = this.props.targets[i];
                        if (integrations[target.created] === undefined) {
                            integrations[target.created] = {iname: "", ik: ""};
                        } else {
                            if (integrations[target.created].iname === undefined) {
                                integrations[target.created].iname = "";
                            }
                            if (integrations[target.created].ik === undefined) {
                                integrations[target.created].ik = "";
                            }
                        }
                    }
                }
                let integrationsEdit = {};
                Object.keys(integrations).forEach(function (targetCreated) {
                    let iname = integrations[targetCreated].iname;
                    let ik = integrations[targetCreated].ik;
                    integrationsEdit[targetCreated] = {ik: ik, iname: iname};
                });
                this.setState({integrations: integrations, integrationsEdit: integrationsEdit, loading: false});
            }
        });
    }

    services () {
        this.setState({loading: true});
        Request.get("/pagerdutyservices", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch PagerDuty services."});
            } else {
                let selectedService = undefined;
                if (data.services !== undefined) {
                    if (data.services.length > 0) {
                        selectedService = data.services[0];
                    }
                }
                this.setState({services: data.services, selectedService: selectedService, loading: false});
            }
        });
    }

    add (target) {
        this.setState({state: "add", target: target, selectedService: undefined}, () => this.services());
    }

    addConfirm () {
        this.setState({loading: true});
        Request.post("/pagerdutyaddintegration", {id: this.state.target.id, created: this.state.target.created, service: this.state.selectedService}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to add integration."});
            } else {
                this.setState({loading: false, state: "view"}, () => this.integrations());
            }
        });
    }

    remove (target) {
        this.setState({state: "remove", target: target});
    }

    removeConfirm () {
        this.setState({loading: true});
        Request.post("/pagerdutyremoveintegration", {id: this.state.target.id, created: this.state.target.created}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to remove integration."});
            } else {
                this.setState({loading: false, state: "view"}, () => this.integrations());
            }
        });
    }

    tesultsFlowIntegration () {
        this.setState({loading: true});
        Request.post("/pagerdutytesultsflowsubdomain", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to set Tesults flow integration, ensure automated integration has been disabled first."});
            } else {
                this.setState({loading: false, state: "view", subdomain: "tesults-flow"}, () => this.integrations());
            }
        });
    }

    tesultsFlowIntegrationUpdate (id, created, iname, ik) {
        this.setState({loading: true});
        Request.post("/pagerdutytesultsflowupdate", {id: id, created: created, iname: iname, ik: ik}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to add Tesults flow integration."});
            } else {
                this.setState({loading: false}, () => this.integrations());
            }
        });
    }

    removeFromProject () {
        this.setState({removeConfirm: true});
    }

    removeFromProjectConfirm () {
        this.setState({loading: true});
        Request.post("/pagerdutyremovefromproject", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to add Tesults flow integration."});
            } else {
                this.setState({loading: false, removeConfirm: false}, () => this.pagerdutysubdomain());
            }
        });
    }
    
    removeFromProjectCancel () {
        this.setState({removeConfirm: false});
    }

    render () {
        if (this.state.user === undefined) {
            return (
                <div>
                    <p>If your team uses both PagerDuty and Tesults consider having Tesults trigger incidents in PagerDuty for test failures.</p>
                    <p>New to Tesults? Welcome! <NavLink to="/" className="planInfoLink primary4">This is what Tesults is</NavLink>.</p>
                    <h3 className="mt-3">What does adding Tesults to PagerDuty do?</h3>
                    <p className="mb-3">When test failures occur, especially if you have one target running critical tests to determine that services are up, you can trigger incidents in PagerDuty for test failures so that your team can know immediately and scramble to restore services.</p>
                    <h3 className="mt-3">How do I add Tesults to PagerDuty?</h3>
                    <p><NavLink to="/login" className="planInfoLink neutral4">Login</NavLink> to add Tesults to PagerDuty.</p>
                    <p>After logging in create at least one project on Tesults. Read the <NavLink to="/docs" className="planInfoLink primary4">documentation</NavLink> for detailed steps outlining how this is done.</p>
                    <p>Then return <NavLink to="/config/project/pagerduty" className="planInfoLink primary4">here</NavLink> and you will find an 'Add To PagerDuty' button has appeared. Click the button to authorize Tesults to trigger incidents to your PagerDuty account. You are able to configure which of your targets trigger incidents.</p>
                </div>
            )
        } else if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.subdomain === undefined) {
            return (
                <div className="p-4 whitebg">
                    <h4 className="neutral3">PagerDuty</h4>
                    <div className="row mb-3">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                            <p className="mt-3">If your team uses both PagerDuty and Tesults consider adding Tesults as a source for triggering and resolving PagerDuty incidents.</p>
                            <p>Setup each of your project targets to generate incidents for specific PagerDuty services when test failures occur.</p>
                            <p>To get started, login to your PagerDuty account at <a href="https://www.pagerduty.com" target="_blank" rel="noopener noreferrer">https://www.pagerduty.com</a>. There are two options you can use to get setup:</p> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5>Option 1</h5>
                                    <p>Create PagerDuty integrations yourself within the PagerDuty interface and paste your integration key here.</p>
                                    <button type="button" className="btn-confirm mt-3" onClick={this.tesultsFlowIntegration}>Add PagerDuty integration key</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5>Option 2</h5>
                                    <p>Have Tesults request authorization to create an integration on your behalf in your PagerDuty account:</p>
                                    <a href={"https://app.pagerduty.com/oauth/authorize?client_id=" + this.state.clientId + "&redirect_uri=" + (process.env.NODE_ENV === "production" ? "https://www.tesults.com" : "http://localhost:3001") + "/pagerdutyauth&response_type=code&state=" + this.props.project.id}><button className="btn btn-confirm mt-3">PagerDuty Authorization</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                </div>
            );
        } else if (this.state.subdomain === "tesults-flow") {
            let removeFromProject = <button type="button" className="btn btn-cancel mt-3" onClick={this.removeFromProject}>Remove PagerDuty</button>
            if (this.state.removeConfirm === true) {
                removeFromProject = <div>
                                        <button type="button" className="btn btn-cancel mt-3" onClick={this.removeFromProjectConfirm}>Confirm Remove</button>
                                        <br/>
                                        <button type="button" className="btn btn-cancel mt-3" onClick={this.removeFromProjectCancel}>Cancel</button>
                                    </div>
            }
            let rows = [];
            if (this.state.integrations !== undefined) {
                for (let i = 0; i < this.props.targets.length; i++) {
                    let target = this.props.targets[i];
                    let save = <button type="button" className="btn btn-confirm" onClick={() => {this.tesultsFlowIntegrationUpdate(target.id, target.created, integrationObjEdit.iname, integrationObjEdit.ik)}} disabled>Save</button>
                    let remove = <button type="button" className="btn btn-cancel" onClick={() => {this.tesultsFlowIntegrationUpdate(target.id, target.created)}} disabled>Remove</button>
                    let integrationObj = this.state.integrations[target.created];
                    let integrationObjEdit = this.state.integrationsEdit[target.created];
                    if (integrationObjEdit.iname !== "" && integrationObjEdit.ik !== "") {
                        remove = <button type="button" className="btn btn-cancel" onClick={() => {this.tesultsFlowIntegrationUpdate(target.id, target.created)}}>Remove</button>
                    }
                    if ((integrationObj.iname !== integrationObjEdit.iname) || (integrationObj.ik !== integrationObjEdit.ik)) {
                        save = <button type="button" className="btn btn-confirm" onClick={() => {this.tesultsFlowIntegrationUpdate(target.id, target.created, integrationObjEdit.iname, integrationObjEdit.ik)}}>Save</button>
                    }
                    console.log(integrationObjEdit);
                    console.log(integrationObj);
                    rows.push(
                        <tr key={i}>
                            <td>{target.name}</td>
                            <td><input type="text" onChange={(e) => {this.inameChange(e, target)}} value={integrationObjEdit.iname}/></td>
                            <td><input type="text" onChange={(e) => {this.ikChange(e, target)}} value={integrationObjEdit.ik}/></td>
                            <td>{save}</td>
                            <td>{remove}</td>
                        </tr>
                    );
                }
                return (
                    <div>
                    <h4 className="neutral3">PagerDuty</h4>
                    <div className="row">
                    <div className="col-12 col-sm-8 col-md-7 col-lg-6">
                        <p>Add PagerDuty integration keys for each of your Tesults targets. If test results dip below 100% pass an incident will be triggered, once results return to a 100% pass rate the incident will be resolved.</p>
                    </div>
                    </div>
                    <table className="table">
                    <thead style={{"textAlign":"left"}}>
                        <tr>
                        <th scope="col neutral3">Target</th>
                        <th scope="col neutral3">Integration Name</th>
                        <th scope="col neutral3">Integration Key</th>
                        <th scope="col neutral3"></th>
                        <th scope="col neutral3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                    </table>
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                    <br/>
                    <hr/>
                    {removeFromProject}
                    </div>
                );
            }
            return (
                <div>
                    <h4 className="neutral3">PagerDuty</h4>
                    <br/>
                    <p>Unable to fetch target integration information. If the project does not have any targets, create one first.</p>
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                </div>
            )
        } else { 
            // pagerduty authorization flow
            if (this.state.state === "add") {
                let select = <p>You have not created any services on PagerDuty. You must first create a service on your PagerDuty account.</p>
                let options = [];
                this.state.services.forEach(function (service) {
                    options.push(<p key={options.length}>{service}</p>);
                    options.push(<option key={options.length} value={service}>{service}</option>);
                });
                if (options.length > 0) {
                    select = 
                    <select className="custom-select mb-3 width50" onChange={this.serviceChange}>
                        {options}
                    </select>
                }
                return (
                    <div>
                    <h4 className="neutral3">PagerDuty</h4>
                    <p>Subdomain: {this.state.subdomain}</p>
                    <p>Select the PagerDuty service you want to add an integration to for this target. Tesults will automatically create the integration on PagerDuty:</p>
                    {select}
                    <br/>
                    <button type="button" className="btn btn-confirm mt-3" onClick={() => {this.addConfirm()}}>Integrate with selected service</button>
                    <br/>
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                    </div>
                );
            } else if (this.state.state === "remove") {
                let integration = this.state.integrations[this.state.target.created];
                let currentIntegration = 
                <div>
                    <p>Integration: {integration.integration}</p>
                    <p>Service: {integration.service}</p>
                </div>
                return (
                    <div>
                    <h4 className="neutral3">PagerDuty</h4>
                    <p>Subdomain: {this.state.subdomain}</p>
                    {currentIntegration}
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.removeConfirm()}}>Remove/Delete</button>
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                    </div>
                );
            } else {
                let removeFromProject = <button type="button" className="btn btn-cancel mt-3" onClick={this.removeFromProject}>Remove PagerDuty</button>
                if (this.state.removeConfirm === true) {
                    removeFromProject = <div>
                                        <button type="button" className="btn btn-cancel mt-3" onClick={this.removeFromProjectConfirm}>Confirm Remove</button>
                                        <br/>
                                        <button type="button" className="btn btn-cancel mt-3" onClick={this.removeFromProjectCancel}>Cancel</button>
                                    </div>
                }   
                let rows = [];
                if (this.state.integrations !== undefined) {
                    for (let i = 0; i < this.props.targets.length; i++) {
                        let target = this.props.targets[i];
                        let integrationObj = this.state.integrations[target.created];
                        if (integrationObj !== undefined) {
                            rows.push(
                                <tr key={i}>
                                    <td>{target.name}</td>
                                    <td>{integrationObj.integration}</td>
                                    <td>{integrationObj.service}</td>
                                    <td><button type="button" className="btn btn-cancel" onClick={() => {this.remove(target)}}>Remove</button></td>
                                </tr>
                            );
                        } else {
                            rows.push(
                                <tr key={i}>
                                    <td>{target.name}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><button type="button" className="btn btn-confirm" onClick={() => {this.add(target)}}>Add</button></td>
                                </tr>
                            );
                        }
                    }
                    return (
                        <div>
                        <h4 className="neutral4">PagerDuty</h4>
                        <div className="row">
                            <div className="col-12 col-sm-8 col-md-7 col-lg-6">
                                <p className="mt-3">Subdomain: {this.state.subdomain}</p>
                                <p>Add PagerDuty integrations by target. Select 'Add' to choose one of your PagerDuty services to integrate with. Tesults will automatically create an integration. If test results dip below 100% pass an incident will be triggered, once results return to a 100% pass rate the incident will be resolved.</p>
                            </div>
                        </div>
                        <table className="table">
                        <thead style={{"textAlign":"left"}}>
                            <tr>
                            <th scope="col neutral3">Target</th>
                            <th scope="col neutral3">Integration</th>
                            <th scope="col neutral3">Service</th>
                            <th scope="col neutral3">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                        </table>
                        <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                        <br/>
                        <hr/>
                        {removeFromProject}
                        </div>
                    );
                }
                return (
                    <div>
                        <h4 className="neutral3">PagerDuty</h4>
                        <p>Subdomain: {this.state.subdomain}</p>
                        <br/>
                        <p>Unable to fetch target integration information. If the project does not have any targets, create one first.</p>
                        <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                    </div>
                )
            }
        }
    }
}

export default PagerDuty;