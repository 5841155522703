/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import ConfigProjectName from './ConfigProjectName'
import ConfigProjectImage from './ConfigProjectImage'
import ConfigProjectDelete from './ConfigProjectDelete'
import Privacy from './Privacy'
import Constants from './Constants'
import StatView from './StatView'


class ConfigProjectProject extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.projectUpdated = this.projectUpdated.bind(this)
        this.project = this.project.bind(this)
    }

    projectUpdated (project) {
        let projects = this.context.projects
        for (let i = 0; i < projects.length; i++) {
            let p = projects[i];
            if (p.id === project.id) {
                projects[i] = project;
            }
        }
        this.context.update(Constants.strings.projects, projects);
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    render () {
        let project = this.project()
        return (
            <div>
                <ConfigProjectName projectUpdated={this.projectUpdated}/>
                <ConfigProjectImage projectUpdated={this.projectUpdated}/>
                <Privacy scope="project" projectUpdated={this.projectUpdated}/>
                <hr className='dividor-light mt-5'/>
                <div className='flex-row-wide-column-narrow'>
                    <div style={{"width":"240px"}} className='flex-1'>
                        <button className="btn-confirm mt-3" onClick={this.props.createProject}>Create a new project</button>
                    </div>
                    <div className='flex-1'>
                        <ConfigProjectDelete role={this.props.role} projectUpdated={this.projectUpdated}/>
                    </div>
                </div>
                <div>
                    {/*<Onboarding projects={this.context.projects} projectIndex={Cache.getPreference(Cache.preference.projectIndex)}  role={this.props.role}/>*/}
                </div>
                <br/>
                <br/>
                <br/>
                <div className='mt-5'>
                    {project ? <StatView name={project.name} id={project.id}/> : <div></div>}
                </div>
            </div>
        );
    }
};

export default ConfigProjectProject