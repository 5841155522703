/*global */
import React, { Component } from 'react'

import Constants from './Constants'

// Suffix ends up with two maps because we are extracting the map from a rawResultMap
export const utilsRawResultMapMap = (targets, targetIndex, rawResultMaps) => {
    let map = undefined
    if (targets !== undefined && targetIndex !== undefined) {
        if (targetIndex < targets.length) {
            const target = targets[targetIndex]
            if (target.rawResultMap !== undefined && rawResultMaps !== undefined) {
                for (let i = 0; i < rawResultMaps.length; i++) {
                    let rawResultMap = rawResultMaps[i]
                    if (rawResultMap.created === target.rawResultMap) {
                        map = rawResultMap.map
                    }
                }
            }
        }
    }
    return map
}

export const utilsRawResultMap = (created, rawResultMaps) => {
    if (rawResultMaps !== undefined && created !== undefined) {    
        for (let i = 0; i < rawResultMaps.length; i++) {
            let rawResultMap = rawResultMaps[i]
            if (rawResultMap.created === created) {
                return rawResultMap
            }
        }
    }
    return undefined
}

export const utilsRawResultMapMapForTask = (testCase, rawResultMaps) => {
    let map = undefined
    if (testCase) {
        if (testCase.task) {
            if (testCase.task.target) {
                let rawResultMap = utilsRawResultMap(testCase.task.target.rawResultMap, rawResultMaps) 
                if (rawResultMap) {
                    map = rawResultMap.map
                }
            }
        }
    }
    return map
}

export const utilsTotals = (cases, rawResultMap, runMetadata) => {
    // Modify FE/BE versions together
    let map = undefined
    if (rawResultMap) {
        map = rawResultMap.map
    }

    if (cases === undefined) {
        return {
            pass: 0,
            total: 0,
            passrateText: "",
            buildCase: undefined
        }
    }

    let pass = 0
    let total = 0
    let buildCase = {}

    const passColor = "#5cb85c";
    const failColor = "#FF3167";
    const unknownColor = "#f0ad4e";
    const images = {
        pass: "https://www.tesults.com/img/tesults-logo-pass-128.png",
        fail: "https://www.tesults.com/img/tesults-logo-fail-128.png",
        unknown: "https://www.tesults.com/img/tesults-logo-unknown-128.png",
        none: "https://www.tesults.com/img/block.svg"
    }

    let color = passColor;
    let image = images.pass;

    cases.forEach(function (c) {
        if (c.suite !== "[build]") {
            if (c.result !== undefined) {
                total += 1;
                if (map !== undefined && c.rawResult !== undefined) {
                    if (map[c.rawResult] === "pass") {
                        pass += 1
                    } else {
                        if (runMetadata) {
                            if (runMetadata.integrationName === "playwright-tesults-reporter" && Constants.isVersionGreaterThanOrEqualTo(runMetadata.integrationVersion, "1.2.0")) {
                                if (c["_Expected Status"] !== "passed") {
                                    if (c["_Expected Status"] === c.rawResult) {
                                        pass += 1
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (c.result.toLowerCase() === "pass") {
                        pass += 1;
                    }
                }
            }
        } else {
            buildCase = c
        }
    });

    let passrateText = ""
    if (total !== 0) {
        passrateText = Math.round((pass / total) * 100 * 100) / 100 + "% Pass (" + pass + "/" + total + ")"
    } else {
        if (buildCase.suite === "[build]") {
            if (buildCase.result.toLowerCase() === "fail") {
                passrateText = "BUILD FAIL"
            } else {
                passrateText = "NO TESTS"
            }
        } else {
            passrateText = "NO TESTS"
        }
    }

    return {
        pass: pass,
        total: total,
        passrateText: passrateText,
        buildCase: buildCase
    }
}

const isResult = (testCase, map, resultValue) => {
    if (testCase === undefined) {
        return false
    }
    if (map && testCase.rawResult) {
        if (map[testCase.rawResult] === resultValue) {
            return true
        }
    } else {
        // Case where no map or rawResult, fall back to default map
        if (testCase.result) {
            if (testCase.result.toLowerCase() === resultValue) {
                return true
            }
        }
    }
    return false
}

export const utilsIsPassResult = (testCase, map) => {
    return isResult(testCase, map, "pass")
}

export const utilsIsFailResult = (testCase, map) => {
    return isResult(testCase, map, "fail")
}

export const utilsPreferencesReportingCaseDisplayAttributeMap = (project, targets, targetIndex, maps) => {
    if (maps === undefined) { return undefined }

    let retMap = undefined

    const findMap = (mapCreated) => {
        let retMap = undefined
        for (let i = 0; i < maps.length; i++) {
            let map = maps[i]
            if (map.created === mapCreated) {
                retMap = map
            }
        }
        return retMap
    }

    // Applied at target scope
    if (targets !== undefined && targetIndex !== undefined) {
        if (targetIndex < targets.length) {
            const target = targets[targetIndex]
            if (target.preferencesReportingCaseDisplayAttributeMap !== undefined && maps !== undefined) {
                retMap = findMap(target.preferencesReportingCaseDisplayAttributeMap)
            }
        }
    }
    // Applied at project scope
    if (retMap === undefined && project !== undefined) {
        if (project.preferencesReportingCaseDisplayAttributeMap !== undefined) {
            retMap = findMap(project.preferencesReportingCaseDisplayAttributeMap)
        }
    }
    return retMap
}