/*global */
import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';

class SupplementalBugs extends Component {
    render() {
        let bugsDict = {};
        if (this.props.cases === undefined) {
            return <span></span>
        }
        for (let i = 0; i < this.props.cases.length; i++) {
            let c = this.props.cases[i]
            if (c.bugs !== undefined) {
                for (let j = 0; j < c.bugs.length; j++) {
                    let bug = c.bugs[j];
                    if (bugsDict[bug] === undefined) {
                        bugsDict[bug] = 1;
                    } else {
                        bugsDict[bug] = bugsDict[bug] + 1;
                    }
                }
            }
        }
        let bugs = [];
        Object.keys(bugsDict).forEach(function (key) {
            bugs.push(<div key={bugs.length} className="row">
                            <div className="col-4">
                                <Linkify>{key}</Linkify>
                            </div>
                            <div className="col-8">
                                {bugsDict[key]}
                            </div>
                    </div>);
        });

        if (bugs.length === 0) {
            bugs.push(<div key={bugs.length}>
                        <p className="font15">No associated bugs.</p>
                    </div>)
        }
        
        return <div>
                    <h4 className="neutral1">Associated bugs</h4>
                    {bugs}
                </div>
    }
};

export default SupplementalBugs;