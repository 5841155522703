/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsReleaseChecklists extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Release Checklists</title>
                    <meta name="description" content="Learn about release checklists on Tesults."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/tbYUekcYVnQ?si=IKfKJavVCpU1Ojjq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Click <b>Release Checklists</b> from the menu to access release checklists.</p>
                <p>Release Checklists help you arrange everything you need for a release into a list of action items that can be checked off as you progress. Release Checklists can be reused across each release and save you time by providing organization and structure for each release cycle.</p>
                <p>Select 'Release Checklists' from the main menu. If you've created any release checklists before you'll see them displayed. Create a new checklist and name it. You can choose to populate the new checklist with items from an existing checklist for convenience. By default, a new checklist includes a pre-release, release and post-release group so that you can add items that should take place before, during and after a release. However, you can customize these groups however you want by adding, removing or editing them.</p>
                <p>The items that make up a release checklist can consist of a general item type or they can be test results, automated or manual, that must be reviewed. The general item type can be any task, you can define what it is. </p>
                <p>If you select an item of manual test results type then manual test run and run archive fields are made available so that you can link your manual test runs from the Runs feature for review.</p>
                <p>If you select an item of automated test results type, you will be able to select the target for your project that the release is dependent on.</p>
                <p>Check off completed items on the release checklist as you and your team progress.</p>
                <p>Use release checklists as a repeatable runbook for every release and rest assured you won't miss a thing. Release Checklists are another way Tesults helps you release a high quality build to your customers every time.</p>
                <img src="https://www.tesults.com/files/docs/release-checklists-1.png" alt="Release checklists" className="width100"/>
            </div>
        );
    }
}

export default DocsReleaseChecklists;