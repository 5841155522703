/*global*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import Loading from './Loading';
import Linkify from 'linkifyjs/react';
import Request from './Request';

class TargetNote extends Component {
    constructor () {
        super();
        this.state = {
            submitting: false, 
            note: undefined, 
            noteEdit: undefined, 
            target: undefined, 
            edit: false, 
            rows: 6, 
            minRows: 5,
            maxRows: 1000
        };
        this.textChange = this.textChange.bind(this);
        this.addNote = this.addNote.bind(this);
        this.saveNote = this.saveNote.bind(this);
        this.editNote = this.editNote.bind(this);
        this.discard = this.discard.bind(this);
    }

    componentDidMount () {
        let target = undefined;
        if (this.props.targets !== undefined && this.props.targetIndex !== undefined) {
            if (this.props.targetIndex < this.props.targets.length) {
                target = this.props.targets[this.props.targetIndex];
            }
        }
        if (target !== undefined) {
            this.setState({note: target.note, noteEdit: target.note, target: target});
        }
    }

    textChange(e) {
        const textareaLineHeight = 14;
		const { minRows, maxRows } = this.state;
		
		const previousRows = e.target.rows;
  	    e.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    
        if (currentRows === previousRows) {
    	    e.target.rows = currentRows;
        }
		
		if (currentRows >= maxRows) {
			e.target.rows = maxRows;
			e.target.scrollTop = e.target.scrollHeight;
		}
        
        this.setState({noteEdit: e.target.value, rows: currentRows < maxRows ? currentRows : maxRows});
    }

    addNote () {
        this.setState({edit: true, noteEdit: ""});
    }

    editNote () {
        this.setState({edit: true});
    }

    saveNote () {
        this.setState({submitting: true});
        Request.post("/targetedit", {id: this.state.target.id, created: this.state.target.created, note: this.state.noteEdit}, (err, data) => {
            if (err) {
                this.setState({error: err, submitting: false});
            } else {
                Analytics.event("TargetNoteUpdated");
                if (this.state.noteEdit === "") {
                    this.setState({note: undefined, noteEdit: undefined, submitting: false, edit: false}, () => this.props.syncTargetNote(this.state.noteEdit));
                } else {
                    this.setState({note: this.state.noteEdit, submitting: false, edit: false}, () => this.props.syncTargetNote(this.state.noteEdit));
                }
            }
        });
    }

    discard () {
        let note = this.state.note;
        this.setState({noteEdit: note, edit: false});
    }

    render() {
        if (this.props.view === "status") {
            return <span></span>
        }
        if (this.props.casesLoading === true) {
            return <span></span>
        }
        if (this.state.target === undefined) {
            return <span></span>
        }
        if (this.state.submitting === true) {
            return  (<div className="mt-3 mb-3">
                <Loading/>
            </div>);
        }

        // Note does not exist
        if (this.state.noteEdit === undefined) {
            if (this.props.trl) {
                return (<div></div>)
            }
            return (<div className="tr-link-dark font14" onClick={this.addNote}>Add note for this target</div>)
        }

        // Note exists (this.state.noteEdit !== undefined)
        
        let options = []

        if (this.state.edit === false) {
            if (this.props.trl !== true) {
                options.push(<div key="edit"><span className="tr-link-dark font14" onClick={this.editNote}>Edit note</span></div>)
            }
        } else {
            if (this.state.noteEdit !== this.state.note) {
                options.push(<div key="save"><span className="tr-link-dark font14" onClick={this.saveNote}>Save note</span></div>)
            }
            options.push(<div key="discard"><span className="tr-link-dark font14" onClick={this.discard}>Discard changes</span></div>);
        }
      
        if (this.state.edit === true) {
            return (
                <div>
                    <textarea className="note-input verticalResize solidborder elevation5 pl-3 width100 mb-4" rows={this.state.rows.toString()} value={this.state.noteEdit} onChange={this.textChange} required></textarea>
                    <div>{options}</div>
                </div>
            );
        } else {
            return (
                <div>
                <div className="note-input-read pre-wrap width100 mb-4">
                    <Linkify>
                        {this.state.noteEdit}
                    </Linkify>
                </div>
                <div>{options}</div>
                </div>
            );
        }
    }
}

export default TargetNote;