/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsPlan extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Plan Configuration Guide</title>
                    <meta name="description" content="Learn how to configure a Tesults plan."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Plan.</p>
                <p>Information about the current plan for your project is displayed including features, billy cycles and the number of active and minimum users, along with owner information.</p>
                <p>You may choose to change your plan by clicking the 'Change plan' button. You can also optionally change the number of minimum users to bill for.</p>
            </div>
        );
    }
}

export default DocsPlan;