/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';
import Confirmation from './Confirmation';
import Request from './Request';

class EUVAT extends Component {
    constructor () {
        super();
        this.state = {confirmation: {successful: undefined, failure: undefined}}
        this.vatIdEntered = this.vatIdEntered.bind(this)
    }

    vatIdEntered () {
        const error = "Unable to validate this VAT Identification Number. Please contact us by email at help@tesults.com if you believe this VAT ID is correct.";
        Request.get("/vat-id-check", { id: this.props.vatId}, (err, data) => {
            if (err) {
                this.setState({confirmation: {successful: undefined, failure: err}});
            } else {
                if (data.valid === true) {
                    Analytics.event("VATValid");
                    this.setState({confirmation: {successful: undefined, failure: undefined}}, () => this.props.next());
                } else {
                    Analytics.event("VATFail");
                    this.setState({confirmation: {successful: undefined, failure: error}});
                }
            }
        });
    }
    
    render () {
        return (
            <div>
                <p>For EU based teams we require your business VAT Identification Number for reverse-charge validation. This ensures that as a business buyer you are not charged VAT.</p> 
                <p>If you do not have a VAT Identification Number we are currently unable to provide you with services. If this policy impacts you we would like to know, please get in contact by email at help@tesults.com and let us know you are interested in using Tesults without a VAT Identification Number.</p>
                <br/>
                <div className='font12 neutral4'>VAT ID</div>
                <input type="text" className="tr-input" value={this.props.vatId} onChange={this.props.vatIdChange} required/>
                <Confirmation confirmation={this.state.confirmation}/>
                <br/>
                <button type="button" className="btn btn-confirm mt-3" onClick={this.vatIdEntered}>Next</button>
                <br/>
                <button type="button" onClick={this.props.cancel} className="btn btn-cancel mt-3">Back to configuration</button>
            </div>
        );
    }
};

export default EUVAT;