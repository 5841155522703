/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import ConfigOrgName from './ConfigOrgName'
import Privacy from './Privacy'


class ConfigOrg extends Component {
    static contextType = Context;

    constructor () {
        super();
    }

    render () {
        if (this.props.role < 5) {
            return (
                <div style={{"height":"100vh"}}>
                    <Notice type="information" content="You do not have the appropriate permissions to configure this option. Ask the project owner to assign the Owner (5) role to you if you require access."/>
                    <button type="button" className="btn btn-cancel mt-5 mb-5" onClick={this.props.cancel}>Back</button>
                </div>
            )
        }
        return (
            <div>
                <ConfigOrgName/>
                <Privacy scope="org"/>
            </div>
        );
    }
};

export default ConfigOrg