/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsPostman extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Postman and Newman test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Postman and Newman test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <p>Using <a className="site-link-primary2" target="_blank" href="https://www.postman.com" rel="noopener noreferrer">Postman</a> and <a className="site-link-primary2" target="_blank" href="https://learning.postman.com/docs/running-collections/using-newman-cli/command-line-integration-with-newman/" rel="noopener noreferrer">Newman</a> to run your API tests? Follow the instructions below to report results.</p>

        const installation = 
        <div>
            <p>Install newman-reporter-tesults</p>
            <pre className="docsCode">
                npm install newman-reporter-tesults --save
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Usually you run Postman collections with Newman by specificying the collection to run:</p>
            <pre className="docsCode">
            newman run your_collection.json
            </pre>
            <p>All you need to do is use the -r option to specify newman-reporter-tesults as a reporter to use:</p>
            <pre className="docsCode">
            newman run your_collection.json -r tesults --reporter-tesults-target {'<token>'}
            </pre>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="site-link-primary2">configuration menu</a>.</p>
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Configuration complete</h4>}
                content={<p>When you run your Postman collection with Newman as shown above, results will be submitted to Tesults.</p>}
            />
            <h3>Recommendations</h3>
            <h4>Multiple test jobs</h4>
            <p>Results for different test jobs or collections should be reported to different Tesults targets in order to maintain results history and utilize smart analysis capabilities. In order to do this generate multiple targets on Tesults and then have each of your newman cli commands utilize a different target for each collection you run.</p>
            <pre className="docsCode">
            newman run your_collection_1.json -r tesults --reporter-tesults-target {'<token_1>'}<br/>
            newman run your_collection_2.json -r tesults --reporter-tesults-target {'<token_2>'}<br/>
            newman run your_collection_3.json -r tesults --reporter-tesults-target {'<token_3>'}
            </pre>
            <h4>Postman folders</h4>
            <p>Tesults will use the folder names (groupings) you create within your collection for Test suite names, so it's recommended you breakdown related tests into folders in your collection if you would like a report broken down into test suite sections.</p>
        </div>

        const build = 
        <div>
            <p>You can optionally report build related information.</p>
            <pre className="primary2 docsArgsCode pl-0">reporter-tesults-buildName<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">newman run your_collection.json -r tesults --reporter-tesults-target {'<token>'} --reporter-tesults-buildName 1.0.0</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">reporter-tesults-buildResult<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">newman run your_collection.json -r tesults --reporter-tesults-target {'<token>'} --reporter-tesults-buildName 1.0.0 --reporter-tesults-buildResult pass</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">reporter-tesults-buildDescription<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">newman run your_collection.json -r tesults --reporter-tesults-target {'<token>'} --reporter-tesults-buildName 1.0.0 --reporter-tesults-buildResult pass --reporter-tesults-buildDescription 'A build description'</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">reporter-tesults-buildReason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">newman run your_collection.json -r tesults --reporter-tesults-target {'<token>'} --reporter-tesults-buildName 1.0.0 --reporter-tesults-buildResult fail --reporter-tesults-buildDescription 'A build description' --reporter-tesults-buildReason 'Build failure due to...'</pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                build={build}
                integration="postman"
            />
        );
    }
}

export default DocsPostman