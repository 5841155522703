/*global*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import LoginMain from './LoginMain';

class Login extends Component {
    constructor () {
        super();
        this.id = this.id.bind(this);
    }

    id () {
        let id = undefined;
        if (this.props.location !== undefined) {
            let path = this.props.location.pathname;
            if (path !== "/login") {
                id = path.substring("/login/".length);
            }
        }
        return id;
    }

    error () {
        let err = undefined;
        if (this.props.location !== undefined) {
            if (this.props.location.search !== undefined) {
                err = queryString.parse(this.props.location.search);
            }
        }
        return err;
    }

    render () {
        const id = this.id();
        const err = this.error();
        return (
            <div>
                <Helmet>
                    <title>Tesults - Login</title>
                    <meta name="description" content="Login to Tesults and get back to your test results."/>
                </Helmet>
                <div style={{"display":"flex", "alignItems":"center", "justifyContent":"center"}}>
                    <LoginMain id={id} err={err} location={this.props.location}/>
                </div>
            </div>
        );
    }
};

export default Login;