/*global */
import React, { Component } from 'react';
import Case from './Case';
import CaseDetail from './CaseDetail';
import CaseListHeader from './CaseListHeader';
import CaseGroupHeader2 from './CaseGroupHeader2';
import BuildCase from './BuildCase';
import Analytics from './Analytics';
import Duration from './Duration';
import CaseGroup from './CaseGroup';
import Share from './Share';
import Context from './Context'
import Cache from './Cache'
import {utilsPreferencesReportingCaseDisplayAttributeMap, utilsRawResultMapMap} from './Utils'

class CaseList extends Component {
    static contextType = Context;

    // this.props.context can be one of ['results', 'manual', 'list']
    constructor (props) {
        super(props);
        this.state = {expandAll: true, expanded: [], selectedCase: null, runIndexProp: props.runIndex, multiselect: {}, collapseAll: false, runArchiveDeleted: false, groupSort: undefined};
        this.selectCase = this.selectCase.bind(this);
        this.caseShow = this.caseShow.bind(this);
        this.caseHide = this.caseHide.bind(this);
        this.suiteExpand = this.suiteExpand.bind(this);
        this.allExpand = this.allExpand.bind(this);
        this.collapseIcon = this.collapseIcon.bind(this);
        this.collapseClassName = this.collapseClassName.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.flakyDataSetup = this.flakyDataSetup.bind(this);
        this.setup = this.setup.bind(this);
        this.multiselectChange = this.multiselectChange.bind(this);
        this.toggleCollapseAll = this.toggleCollapseAll.bind(this);
        this.invalidateCollapseAll = this.invalidateCollapseAll.bind(this);
        this.project = this.project.bind(this)
        this.runArchiveDeleted = this.runArchiveDeleted.bind(this)
        this.selectItem = this.selectItem.bind(this)
        this.groupSort = this.groupSort.bind(this)
        this.ref = React.createRef()
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.setup();
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.runIndex !== this.state.runIndexProp) {
            this.setup();
            this.setState({runIndexProp: this.props.runIndex});
        }
        if (this.props.multiselectEnabled !== prevProps.multiselectEnabled) {
            let multiselect = {};
            this.setState({multiselect: multiselect}, () => {this.props.multiselectUpdate(multiselect)});
        }
        if (this.props.multiselectClearRequest !== prevProps.multiselectClearRequest) {
            let multiselect = {};
            this.setState({multiselect: multiselect}, () => {this.props.multiselectUpdate(multiselect)});
        }
        if (this.props.collapseAll !== prevProps.collapseAll) {
            this.setState({collapseAll: this.props.collapseAll})
        }
        if (this.props.runArchiveCases !== undefined && prevProps.runArchiveCases !== undefined) {
            if (this.props.runArchiveCases.length !== prevProps.runArchiveCases.length) {
                this.setup()
            }
        }
        if (this.props.cases !== undefined && prevProps.cases !== undefined) {
            if (this.props.cases.length !== prevProps.cases.length) {
                this.setup()
            }
        }
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    setup () {
        // Start Setup

        // set expanded icons for test suites

        let testCases = undefined;
        let buildCase = undefined;
        let selectedCase = null;
        let expanded = [];
        
        if (this.props.context === "list"
            || this.props.context === "manual"
            || this.props.context === "manual-archive"
            || this.props.context === "import"
            || this.props.context === "tasks"
            || this.props.context === "diff"
            || this.props.context === "release-checklist") {
            testCases = this.props.cases;
        } else {
            if (this.props.isRunArchive === true) {
                // Archived run
                testCases = this.props.runArchiveCases
                buildCase = this.props.runArchiveBuildCase
            } else {
                // Results
                testCases = this.props.cases[this.props.runIndex].slice();
                buildCase = this.props.builds[this.props.runIndex];
            }
        }

        if (testCases === undefined) { return }

        if (this.props.context !== "tasks") {
            let group = null;
            if (testCases.length > 0) {
                group = testCases[0].suite;
            }
            let suiteNum = 0;
            
            expanded[suiteNum] = true;
            
            let urlCaseNum = undefined;
            if (this.props.rsp !== undefined) {
                if (this.props.rsp.caseNum !== undefined) {
                    try {
                        urlCaseNum = parseInt(this.props.rsp.caseNum, 10);
                    } catch (err) {
                        urlCaseNum = undefined;
                    }
                }
            }
            if (this.props.trlCase !== undefined) {
                try {
                    urlCaseNum = parseInt(this.props.trlCase, 10)
                } catch (err) {
                    urlCaseNum = undefined
                }
            }
            if (this.props.lspCaseNum !== undefined) {
                try {
                    urlCaseNum = parseInt(this.props.lspCaseNum, 10);
                } catch (err) {
                    urlCaseNum = undefined;
                }
            }
            if (this.props.mspCaseNum !== undefined) {
                try {
                    urlCaseNum = parseInt(this.props.mspCaseNum, 10);
                } catch (err) {
                    urlCaseNum = undefined;
                }
            }
            testCases.forEach(function (c) {
                if (c.suite !== group) {
                    suiteNum += 1;
                    expanded[suiteNum] = true;
                    group = c.suite;
                }
                if (urlCaseNum !== undefined) {
                    if (c.num === urlCaseNum) {
                        let cDetail = {};
                        cDetail.suite = c.suite;
                        cDetail.num = c.num;
                        cDetail.name = c.name;
                        cDetail.desc = c.desc;
                        cDetail.reason = c.reason;
                        cDetail.result = c.result.charAt(0).toUpperCase() + c.result.slice(1);
                        cDetail.params = c.params;
                        cDetail.hash = c.hash;
                        cDetail.files = c.files;
                        cDetail.flaky = c.flaky;
                        cDetail.steps = c.steps;
                        cDetail.priority = c.priority;
                        cDetail.resultOriginal = c.resultOriginal;
                        cDetail.resultChangeReason = c.resultChangeReason;
                        cDetail.resultChangeBy = c.resultChangeBy;
                        cDetail.resultChangeTime = c.resultChangeTime;
                        cDetail.custom = c.custom;
                        cDetail.manualAssignee = c.manualAssignee;
                        cDetail.manualComplete = c.manualComplete;
                        cDetail.metadata = c.metadata;
                        cDetail.bugs = c.bugs;
                        cDetail.cost = c.cost;
                        cDetail.batch = c.batch;
                        cDetail.start = c.start;
                        cDetail.end = c.end;
                        cDetail.duration = c.duration;
                        cDetail.importList = c.importList;
                        cDetail.rawResult = c.rawResult
                        cDetail.refFiles = c.refFiles
                        cDetail.runArchive = c.runArchive
                        cDetail.manualRun = c.manualRun
                        cDetail.manualRunArchive = c.manualRunArchive


                        if (cDetail.reason !== undefined) {
                            if (cDetail.reason === Object(cDetail.reason)) {
                                cDetail.reason = JSON.stringify(cDetail.reason, null, 2);
                            }
                        }

                        // Custom fields
                        Object.keys(c).forEach(function(key) {
                            if (key.charAt(0) === "_") {
                                if (c[key] !== "" && c[key] !== undefined && c[key] !== null) {
                                    cDetail[key] = c[key];
                                }
                            }
                        });
                        // Modal
                        selectedCase = cDetail;
                    }
                }
            });
            if (urlCaseNum !== undefined && selectedCase === null) {
                if (buildCase !== undefined) {
                    selectedCase = buildCase;
                }
            }
        }
        
        // End Setup

        this.setState({expandAll: true, expanded: expanded}, () => 
        {
            if (selectedCase !== undefined && selectedCase !== null) {
                this.selectCase(selectedCase);
                if (this.props.rspCancel !== undefined) {
                    this.props.rspCancel();
                }
            }
        });

        if (this.props.updatedCaseHash !== undefined && this.ref !== undefined) {
            if (this.ref.current !== undefined && this.ref.current !== null) {
                try {
                    this.ref.current.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
                } catch (err) {
                    // Swallow
                    console.log('err')
                }
                
            }   
        }
    }

    toggleCollapseAll () {
        this.setState({collapseAll: this.state.collapseAll === true ? false: true});
    }

    invalidateCollapseAll () {
        this.setState({collapseAll: undefined});
    }
    
    flakyDataSetup () {
        let passHistory = {};
        let flakyCases = {};
        if (this.props.runs.length - this.props.runIndex >= 10) {
            let limit = this.props.runIndex + 10;
            for (let runIndex = this.props.runIndex; runIndex < limit; runIndex++) {
                if (this.props.cases[runIndex] !== undefined) {
                    let cases = this.props.cases[runIndex].slice();
                    for (let caseIndex = 0; caseIndex < cases.length; caseIndex++) {
                        let c = cases[caseIndex];
                        if (passHistory[c.hash] === undefined) {
                            passHistory[c.hash] = [c.result];
                        } else {
                            let history = passHistory[c.hash];
                            history.push(c.result);
                            passHistory[c.hash] = history;
                        }
                    }
                }
            }
            Object.keys(passHistory).forEach(function (hash) {
                let passArray = passHistory[hash];
                let changes = 0;
                let previous = undefined;
                passArray.forEach(function(result) {
                    if (previous === undefined) {
                        previous = result;
                    } else {
                        if (result !== previous) {
                            changes += 1;
                        }
                        previous = result;
                    }
                });
                const flakyThreshold = 2;
                flakyCases[hash] = changes > flakyThreshold ? true : false;
            });
        }
        return flakyCases;
    }

    scrollToTop () {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    scrollToBottom () {
        window.scrollTo({top: document.body.scrollHeight, left: 0, behavior: 'auto'});
    }

    selectItem (item) {
        this.props.displayItem(item)
    }

    selectCase (c) {
        if (this.props.context === "release-checklist") {
            return this.selectItem(c)
        }
        let cDetail = {};
        cDetail.suite = c.suite;
        cDetail.num = c.num;
        cDetail.name = c.name;
        cDetail.desc = c.desc;
        cDetail.reason = c.reason;
        cDetail.result = c.result.charAt(0).toUpperCase() + c.result.slice(1);
        cDetail.params = c.params;
        cDetail.hash = c.hash;
        cDetail.files = c.files;
        cDetail.flaky = c.flaky;
        cDetail.steps = c.steps;
        cDetail.priority = c.priority;
        cDetail.resultOriginal = c.resultOriginal;
        cDetail.resultChangeReason = c.resultChangeReason;
        cDetail.resultChangeBy = c.resultChangeBy;
        cDetail.resultChangeTime = c.resultChangeTime;
        cDetail.custom = c.custom;
        cDetail.manualAssignee = c.manualAssignee;
        cDetail.manualComplete = c.manualComplete;
        cDetail.metadata = c.metadata;
        cDetail.bugs = c.bugs;
        cDetail.cost = c.cost;
        cDetail.jiraTransitions = c.jiraTransitions;
        cDetail.batch = c.batch;
        cDetail.start = c.start;
        cDetail.end = c.end;
        cDetail.duration = c.duration;
        cDetail.importList = c.importList;
        cDetail.rawResult = c.rawResult
        cDetail.refFiles = c.refFiles

        if (cDetail.reason !== undefined) {
            if (cDetail.reason === Object(cDetail.reason)) {
                cDetail.reason = JSON.stringify(cDetail.reason, null, 2);
            }
        }
        
        // Custom fields
        Object.keys(c).forEach(function(key) {
            if (key.charAt(0) === "_") {
                if (c[key] !== "" && c[key] !== undefined && c[key] !== null) {
                    cDetail[key] = c[key];
                }
            }
        });
        // Task
        cDetail.task = c.task;
        // Run
        cDetail.run = c.run;
        // Modal
        this.props.sideOverlay(
            <CaseDetail 
                view={this.props.context}
                overlay={this.props.overlay}
                messageOverlay={this.props.messageOverlay}
                sideOverlay={this.props.sideOverlay}
                modalId={this.props.modalId} 
                projects={this.props.projects} 
                projectIndex={this.props.projectIndex} 
                targets={this.props.targets} 
                targetIndex={this.props.targetIndex} 
                runs={this.props.runs} 
                runIndex={this.props.runIndex} 
                cases={this.props.cases} 
                selectedCase={cDetail} 
                selectedCaseRaw={c}
                builds={this.props.builds} 
                role={this.props.role} 
                manualRunsInProgress={this.props.manualRunsInProgress} 
                manualRunsInProgressIndex={this.props.manualRunsInProgressIndex}
                taskChanged={this.props.taskChanged}
                caseHidden={this.props.caseHidden}
                persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                darkMode={this.props.darkMode}
                trl={this.props.trl}
                list={this.props.list}
                manual={this.props.manual}
                markDone={this.props.markDone}
                editCase={this.props.editCase}
                deleteCase={this.props.deleteCase}
                duplicateCase={this.props.duplicateCase}
                run={this.props.run}
                manualArchive={this.props.manualArchive}
                share={
                    <Share
                        projects={this.props.projects} 
                        projectIndex={this.props.projectIndex} 
                        targets={this.props.targets} 
                        targetIndex={this.props.targetIndex} 
                        runs={this.props.runs} 
                        runIndex={this.props.runIndex} 
                        selectedCase={cDetail}
                        view={this.props.context}
                        list={this.props.list}
                        manual={this.props.manual}
                        members={this.props.members}
                        org={this.props.org}
                        runArchive={this.props.runArchive}
                        runArchiveBuildCase={this.props.runArchiveBuildCase}
                        runArchiveCases={this.props.runArchiveCases}
                        isRunArchive={this.props.isRunArchive}
                    />
                }
                members={this.props.members}
                rawResultMaps={this.props.rawResultMaps}
                runArchive={this.props.runArchive}
                runArchiveBuildCase={this.props.runArchiveBuildCase}
                runArchiveCases={this.props.runArchiveCases}
                isRunArchive={this.props.isRunArchive}
                caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
            />
        );
    }

    caseShow (cDetail) {
        this.selectCase(cDetail);
    }

    caseHide() {
        if (this.props.context === "list" || this.props.context === "manual" || this.props.context === "manual-archive" || this.props.context === "import" || this.props.context === "release-checklist") {
            this.setState({selectedCase: null, selectedCaseRaw: null});
        } else if (this.props.context === "tasks") {
            this.setState({selectedCase: null}, () => this.props.caseHidden());
        } else {
            this.setState({selectedCase: null});
        }
    }

    suiteExpand (suiteNum) {
        let expanded = this.state.expanded;
        if (expanded[suiteNum]) {
            Analytics.event('CollapseSuite');
            expanded[suiteNum] = false;
        } else {
            Analytics.event('ExpandSuite');
            expanded[suiteNum] = true;
        }
        this.setState({expanded: expanded});
    }

    allExpand () {
        if (this.state.expandAll) {
            let expanded = this.state.expanded;
            for (let i = 0; i < expanded.length; i++) {
                expanded[i] = false;
            }
            Analytics.event('CollapseAll');
            this.setState({expanded: expanded, expandAll: false});
        } else { // allExpanded === false || allExpanded === undefiend
            let expanded = this.state.expanded;
            for (let i = 0; i < expanded.length; i++) {
                expanded[i] = true;
            }
            Analytics.event('ExpandAll');
            this.setState({expanded: expanded, expandAll: true});
        }
    }

    collapseIcon (suiteNum) {
        if (this.state.expanded[suiteNum]) {
            return <div className="collapseIcon"></div>
        } else {
            return <div className="expandIcon"></div>
        }
    }

    collapseClassName (suiteNum) {
        if (this.state.expanded !== undefined) {
            if (this.state.expanded[suiteNum]) {
                return "collapse show";
            } else {
                return "collapse";
            }
        }
    } 

    multiselectChange (select, numArray) {
        if (select === undefined || numArray === undefined) {
            return;
        }
        let multiselect = this.state.multiselect;
        for (let i = 0; i < numArray.length; i++) {
            let num = numArray[i];
            multiselect[num] = select;
        }
        if (this.props.multiselectUpdate !== undefined) {
            this.setState({multiselect: multiselect}, () => {this.props.multiselectUpdate(multiselect)});
        } else {
            this.setState({multiselect: multiselect});
        }
    }

    runArchiveDeleted () {
        this.setState({runArchiveDeleted: true})
    }

    groupSort (field) {
        this.setState({groupSort: field})
    }

    render  () {
        let cases = [];
        let pass = 0;
        let total = 0;
        let costTotal = undefined;
        let selectCase  = this.selectCase;
        let projects = this.props.projects;
        let projectIndex = this.props.projectIndex;
        let targets = this.props.targets;
        let targetIndex = this.props.targetIndex;
        let runs = this.props.runs;
        let runIndex = this.props.runIndex;

        let attribute = this.props.sort;
        let filter = this.props.filter;
        let search = this.props.search;

        if (this.props.context === "tasks") {
            attribute = "task.resolved";
        }

        if (this.props.context === "diff") {
            attribute = "diff"
        }

        if (this.state.runArchiveDeleted === true) {
            return <div className='font15 neutral4'>Run archive deleted</div>
        }

        if (this.props.isRunArchive === true && this.props.runArchive === undefined) {
            return <div className='font15 neutral4'>Run archive not found</div>
        }

        let testCases = undefined;
        let buildCase = undefined;
        if (this.props.context === "list" || this.props.context === "manual"
            || this.props.context === "import" || this.props.context === "tasks"
            || this.props.context === "manual-archive" || this.props.context === "diff"
            || this.props.isRunArchive === true || this.props.context === "release-checklist") {
            if (this.props.isRunArchive === true) {
                testCases = this.props.runArchiveCases
            } else {
                testCases = this.props.cases
            }
        } else {
            testCases = this.props.cases[this.props.runIndex].slice();
            buildCase = this.props.builds[this.props.runIndex];
            let flakyCases = this.flakyDataSetup();
            testCases.forEach(function (c) {
                if (c.hash !== undefined) {
                    if (flakyCases[c.hash] !== undefined) {
                        c.flaky = flakyCases[c.hash];
                    }
                }
            });
        }

        if (testCases === undefined) {
            return (
                <div className='neutral4 font15 solidborder neutral7border p-4 text-center'>
                    
                </div>    
            )
        }

        // multiselect all setup start
        let multiselectAll = <span></span>
        if (this.props.multiselectEnabled === true) {
            let checked = true;
            let numArray = [];
            if (testCases === undefined) {
                checked = false;
            } else {
                testCases.forEach(function (testCase) {
                    numArray.push(testCase.num);
                    if (this.state.multiselect[testCase.num] !== true) {
                        checked = false;
                    }
                }.bind(this));
                if (testCases.length === 0) {
                    checked = false;
                }
            }
            multiselectAll =
                <div style={{"display":"flex", "alignItems":"center"}}>
                    <div className="mr-2">
                        <input type="checkbox" onChange={() => this.multiselectChange(checked === true ? false : true, numArray)} checked={checked} aria-label="Multiselect"/>
                    </div>
                    <div>
                        <span className="neutral4 font14">Select all</span>
                    </div>
                </div>
        }
        // multiselect all setup end

        if (this.props.context !== "tasks") {
            if (attribute === "priority") {
                testCases.forEach(function (c) {
                    if (c.priority === undefined) {
                        c.priority = 1000; // large number for ordering last
                    } else {
                        c.priority = parseInt(c.priority, 10);
                    }
                });
                testCases.sort(function compare (a,b) {
                    if (a.priority < b.priority) {
                        return -1;
                    } else if (a.priority > b.priority) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else {
                testCases.sort(function compare(a,b) {
                    if (a[attribute] < b[attribute]) {
                        return -1;
                    } else if (a[attribute] > b[attribute]) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        }

        // testCases are now ordered by the sort by attribute
        
        // grouping by suite or result
        let group = null;
        if (testCases.length > 0) {
            if (attribute === "suite") {
                testCases.sort(function (a, b) {
                    let al = undefined;
                    let bl = undefined;
                    if (a.suite === undefined) {
                        al = "0";
                    } else {
                        try {
                            al = a.suite.toLowerCase();
                        } catch (err) {
                            al = "0"
                        }
                    }
                    if (b.suite === undefined) {
                        bl = "0";
                    } else {
                        try {
                            bl = b.suite.toLowerCase();
                        } catch (err) {
                            bl = "0";
                        }
                    }
                    if (al < bl) { 
                        return -1; 
                    } else if (al > bl) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                group = testCases[0].suite;
            }
            if (attribute === "result") {
                group = testCases[0].result;
            }
            if (attribute === "task.resolved") {
                group = testCases[0].task.resolved;
            }
            if (attribute === "diff") {
                group = testCases[0].diff;
            }
            if (attribute === "priority") {
                group = testCases[0].priority;
            }
        }

        let suites = [];
        let casesForSuite = [];
        let numsForSuite = [];
        let resolved = [];
        let unresolved = [];
        let passPercent;
        let key = 0;

        let passCases = [];
        let failCases = [];
        let unknownCases = [];

        let diffPass = []
        let diffFail = []
        let diffUnknown = []
        let diffNone = []

        let priorityNone = []
        let priority0 = []
        let priority1 = []
        let priority2 = []
        let priority3 = []
        let priority4 = []
        let priority5 = []

        let start = undefined;
        let end = undefined;
        let duration = undefined;

        let startRun = undefined;
        let endRun = undefined;
        let durationRun = undefined;

        let context = this.props.context;
        let assignee = this.props.assignee;
        let searchExclusionNum = 0;

        testCases.forEach(function (c) {
            if (c.cost !== undefined && c.result !== "pass") {
                if (costTotal === undefined) {
                    costTotal = {};
                }
                if (costTotal[c.cost.currency + "-" + c.cost.period] === undefined) {
                    costTotal[c.cost.currency + "-" + c.cost.period] = 0;
                }
                costTotal[c.cost.currency + "-" + c.cost.period] += c.cost.value;
            }

            let filterpush = true;
            if (search !== undefined && search !== "") {
                try {
                    if (c.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
                        filterpush = false;
                        searchExclusionNum += 1;
                    }
                } catch (err) {
                    filterpush = false;
                }
            } else {
                let rawResultMap = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps);
                
                if (filter !== "none") {
                    if (filter === "pass" && c.result !== "pass") {
                        if (rawResultMap) {
                            if (rawResultMap[c.rawResult] !== "pass") {
                                filterpush = false;
                            }
                        } else {
                            filterpush = false;
                        }
                    }
                    if (filter === "fail" && c.result !== "fail") {
                        if (rawResultMap) {
                            if (rawResultMap[c.rawResult] !== "fail") {
                                filterpush = false;
                            } 
                        }
                        else {
                            filterpush = false;
                        }
                    }
                    if (filter === "unknown") {
                        if (c.result !== "unknown") {
                            if (rawResultMap) {
                                if (rawResultMap[c.rawResult] !== undefined) {
                                    filterpush = false;
                                }
                            }
                            else {
                                filterpush = false;
                            }
                        } else {
                            if (rawResultMap) {
                                if (rawResultMap[c.rawResult] !== undefined) {
                                    filterpush = false;
                                }
                            }
                        }
                    }
                    if (filter === "bugs")  {
                        if (c.bugs === undefined || c.bugs.length === 0) {
                            filterpush = false;
                        } else {
                            filterpush = true;
                        }
                    }
                    else if (filter !== "pass" && filter !== "fail" && filter !== "unknown") {
                        // Specific raw result
                        if (rawResultMap) {
                            if (c.rawResult !== filter) {
                                filterpush = false;
                            }
                        }
                    }
                }
            }
            
            // Add time to run

            if (c.start !== undefined) {
                if (isNaN(c.start) === false) {
                    if (startRun === undefined) {
                        startRun = c.start;
                    } else {
                        if (c.start < startRun) {
                            startRun = c.start;
                        }
                    }
                }
            }

            if (c.end !== undefined) {
                if (isNaN(c.end) === false) {
                    if (endRun === undefined) {
                        endRun = c.end;
                    } else {
                        if (c.end > endRun) {
                            endRun = c.end;
                        }
                    }
                }
            }

            if (c.duration !== undefined) {
                if (isNaN(c.duration) === false) {
                    if (c.start === undefined || c.end === undefined) {
                        // only consider duration if start and end not supplied
                        if (durationRun === undefined) {
                            durationRun = c.duration;
                        } else {
                            durationRun += c.duration;
                        }
                    }
                }
            }

            if (attribute === "suite") {
                if (c.suite !== group) {
                    passPercent = 0;
                    if (total !== 0) {
                        passPercent = Math.floor((pass/total) * 100);
                    }
                    suites.push({suite: group, casesForSuite: casesForSuite, numsForSuite: numsForSuite, passPercent: passPercent, pass: pass, total: total, start: start, end: end, duration: duration});
                    casesForSuite = [];
                    numsForSuite = [];
                    start = undefined;
                    end = undefined;
                    duration = undefined;
                    group = c.suite;
                    total = 0;
                    pass = 0;
                }

                try {
                    if (c.result.toLowerCase() === "pass") {
                        pass += 1;
                    }
                } catch (err) {
                    // Consider a non pass
                }

                // Add time to suite

                if (c.start !== undefined) {
                    if (isNaN(c.start) === false) {
                        if (start === undefined) {
                            start = c.start;
                        } else {
                            if (c.start < start) {
                                start = c.start;
                            }
                        }
                    }
                }

                if (c.end !== undefined) {
                    if (isNaN(c.end) === false) {
                        if (end === undefined) {
                            end = c.end;
                        } else {
                            if (c.end > end) {
                                end = c.end;
                            }
                        }
                    }
                }

                if (c.duration !== undefined) {
                    if (isNaN(c.duration) === false) {
                        if (c.start === undefined || c.end === undefined) {
                            // only consider duration if start and end not supplied
                            if (duration === undefined) {
                                duration = c.duration;
                            } else {
                                duration += c.duration;
                            }
                        }
                    }
                }
    
                total += 1;

                if (filterpush) {
                    casesForSuite.push(c);
                    numsForSuite.push(c.num);
                }
            } else if (attribute === "result") {
                if (c.result !== group) {
                    group = c.result;
                }
                
                if (filterpush) {
                    if (c.result === "pass") {
                        passCases.push(c);    
                    } else if (c.result === "fail") {
                        failCases.push(c);
                    } else {
                        unknownCases.push(c);
                    }
                }
            } else if (attribute === "task.resolved") {
                if (c.task.resolved === true) {
                    resolved.push(c);
                } else {
                    unresolved.push(c)
                }
            } else if (attribute === "name") {
                if (filterpush) {
                    cases.push(
                        <Case 
                            key={"name-" + key++}
                            ref={(this.props.updatedCaseHash === c.hash || this.props.updatedCaseHash === c.num + "") ? this.ref : undefined}
                            overlay={this.props.overlay}
                            selectCase={selectCase} 
                            projects={projects} 
                            projectIndex={projectIndex} 
                            targets={targets} 
                            targetIndex={targetIndex} 
                            runs={runs} 
                            runIndex={runIndex}
                            case={c} 
                            context={context}
                            assignee={assignee} 
                            persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                            persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                            darkMode={this.props.darkMode}
                            multiselect={this.state.multiselect}
                            multiselectChange={this.multiselectChange}
                            multiselectEnabled={this.props.multiselectEnabled}
                            checked={(this.state.multiselect[c.num] === true) ? true : false}
                            trl={this.props.trl}
                            members={this.props.members}
                            rawResultMaps={this.props.rawResultMaps}
                            preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                            caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            targetDetail={this.props.targetDetail}
                        />
                    );
                }
            } else if (attribute === "diff") {
                if (c.diff === "pass") {
                    diffPass.push(c)
                } else if (c.diff === "fail") {
                    diffFail.push(c)
                } else if (c.diff === "unknown") {
                    diffUnknown.push(c)
                } else if (c.diff === "none") {
                    diffNone.push(c)
                }
            } else if (attribute === "priority") {
                if (c.priority === 0) {
                    priority0.push(c)
                } else if (c.priority === 1) {
                    priority1.push(c)
                } else if (c.priority === 2) {
                    priority2.push(c)
                } else if (c.priority === 3) {
                    priority3.push(c)
                } else if (c.priority === 4) {
                    priority4.push(c)
                } else if (c.priority === 5) {
                    priority5.push(c)
                } else {
                    delete c.priority;
                    priorityNone.push(c)
                }
            } else {
                cases.push(
                    <Case 
                        key={"other-" + key++}
                        ref={(this.props.updatedCaseHash === c.hash | this.props.updatedCaseHash === c.num + "") ? this.ref : undefined}
                        overlay={this.props.overlay}
                        selectCase={selectCase} 
                        projects={projects} 
                        projectIndex={projectIndex} 
                        targets={targets} 
                        targetIndex={targetIndex} 
                        runs={runs} 
                        runIndex={runIndex} 
                        case={c} 
                        context={context}
                        assignee={assignee} 
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.state.multiselect}
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        checked={(this.state.multiselect[c.num] === true) ? true : false}
                        trl={this.props.trl}
                        members={this.props.members}
                        rawResultMaps={this.props.rawResultMaps}
                        preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        targetDetail={this.props.targetDetail}
                    />
                );
            }
        }.bind(this));
        
        let build = <span></span>
        if (buildCase !== undefined && buildCase !== null) {
            build = <BuildCase selectCase={selectCase} case={buildCase} targets={this.props.targets} targetIndex={this.props.targetIndex} rawResultMaps={this.props.rawResultMaps}/>
        }

        let scrollToTopLink = <span key={"scrollToTopLink"}></span>
        let scrollToBottomLink = <span key={"scrollToBottomLink"}></span>

        if (testCases !== undefined) {
            if (testCases.length > 7 ) {
                scrollToTopLink = 
                <div className="text-right" key={"scrollToTopLink"}>
                <span onClick={this.scrollToTop} className="nounderline text-right scrollToTop" key="scrollToTopLink">
                </span>
                </div> 

                scrollToBottomLink =
                <span onClick={this.scrollToBottom} className="nounderline" key="scrollToBottomLink">
                    <span className="scrollToBottom"></span>
                </span>
            }
        }

        let groups = [];
        if (attribute === "suite") {
            passPercent = 0;
            if (total !== 0) {
                passPercent = Math.floor((pass/total) * 100);
            }
            if (casesForSuite.length > 0) {
                suites.push({suite: group, casesForSuite: casesForSuite, numsForSuite: numsForSuite, passPercent: passPercent, pass: pass, total: total, start: start, end: end, duration: duration});
            }
            
            let suiteNum = 0;

            let expandAllIcon = <div className="collapseIcon"></div>
            if (this.state.expandAll === false) {
                expandAllIcon = <div className="expandIcon"></div>
            }

            
            cases.push(
            <div key={"key-" + cases.length}>
            <div className="row mb-3" key="expandRow">
                <div className="col-7 col-sm-8 col-md-8 col-lg-8">
                    {build} <Duration context={this.props.context} suite={true} c={{start: startRun, end: endRun, duration: durationRun}}/>
                </div>
                <div className="col-5 col-sm-4 col-md-4 col-lg-4">
                    <div className="row">
                        <div className="d-none d-sm-block col-6 text-right">
                            <a className="nounderline pr-3" data-toggle="collapse" href="#expandAll" aria-expanded="true" aria-controls="none" onClick={() => this.allExpand()}>
                            <span className="neutral3 align-self-baseline font14">{this.state.expandAll === false ? "Expand test suites" : "Collapse test suites"}</span>&nbsp;&nbsp;{expandAllIcon}
                            </a>
                        </div>
                        <div className="d-block d-sm-none col-6 text-right">
                            <a className="nounderline pr-3" data-toggle="collapse" href="#expandAll" aria-expanded="true" aria-controls="none" onClick={() => this.allExpand()}>
                            {expandAllIcon}
                            </a>
                        </div>
                        <div className="col-6 text-right">
                            <h6 className="neutral3 pr-2" style={{"display": "inline"}}>{testCases.length}</h6>
                            {scrollToBottomLink}
                        </div>
                    </div>
                </div>
            </div>
            {multiselectAll}
            </div>
            );
            for (let i = 0; i < suites.length; i++) {
                let s = suites[i];
                // multiselectGroup setup start
                let multiselectGroup = <span></span>
                if (this.props.multiselectEnabled === true) {
                    let checked = true;
                  let numArray = [];
                    if (s.numsForSuite === undefined) {
                        checked = false;
                    } else {
                        s.numsForSuite.forEach(function (num) {
                            numArray.push(num);
                            if (this.state.multiselect[num] !== true) {
                                checked = false;
                            }
                        }.bind(this));
                        if (s.numsForSuite.length === 0) {
                            checked = false;
                        }
                    }
                    multiselectGroup = <input type="checkbox" onChange={() => this.multiselectChange(checked === true ? false : true, numArray)} checked={checked} aria-label="Multiselect"/>
                }
                // multiselect setup end
                
                groups.push(
                    <div key={"suite-" + i}>
                    <div className="width100">
                        <CaseGroup
                            overlay={this.props.overlay}
                            updatedCaseHash={this.props.updatedCaseHash}
                            updatedCaseRef={this.ref}
                            cases={s.casesForSuite}
                            selectCase={selectCase} 
                            projects={projects}
                            projectIndex={projectIndex}
                            targets={targets} 
                            targetIndex={targetIndex} 
                            runs={runs} 
                            runIndex={runIndex} 
                            context={context} 
                            assignee={assignee} 
                            persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                            persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                            persistentSuiteDataUpdate={this.props.persistentSuiteDataUpdate}
                            persistentSuiteDataIteration={this.props.persistentSuiteDataIteration}
                            persistentSuiteData={this.props.persistentSuiteData}
                            darkMode={this.props.darkMode}
                            multiselect={this.state.multiselect}
                            multiselectGroup={multiselectGroup}
                            multiselectChange={this.multiselectChange}
                            multiselectEnabled={this.props.multiselectEnabled}
                            members={this.props.members}
                            trl={this.props.trl}
                            collapseAll={this.state.collapseAll}
                            invalidateCollapseAll={this.invalidateCollapseAll}
                            duration={s.duration}
                            intervalStart={s.start}
                            intervalEnd={s.end}
                            addCaseToSuite={this.props.addCaseToSuite}
                            rawResultMaps={this.props.rawResultMaps}
                            preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                            groupSort={this.state.groupSort}
                            caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                            side={this.props.side}
                            targetDetail={this.props.targetDetail}
                        />
                    </div>
                    </div>
                )
            }
        } else if (attribute === "name") {
            groups.push(
                <div key={"name"}>
                <div className="width100">
                    <CaseGroup
                        overlay={this.props.overlay}
                        updatedCaseHash={this.props.updatedCaseHash}
                        updatedCaseRef={this.ref}
                        cases={testCases}
                        selectCase={selectCase} 
                        projects={projects}
                        projectIndex={projectIndex}
                        targets={targets} 
                        targetIndex={targetIndex} 
                        runs={runs} 
                        runIndex={runIndex} 
                        context={context} 
                        assignee={assignee} 
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.state.multiselect}
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        members={this.props.members}
                        trl={this.props.trl}
                        omitTitle={true}
                        collapseAll={this.state.collapseAll}
                        invalidateCollapseAll={this.invalidateCollapseAll}
                        duration={duration}
                        intervalStart={start}
                        intervalEnd={end}
                        addCaseToSuite={this.props.addCaseToSuite}
                        rawResultMaps={this.props.rawResultMaps}
                        preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                        groupSort={this.state.groupSort}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        side={this.props.side}
                        targetDetail={this.props.targetDetail}
                    />
                </div>
                </div>
            )
        } else if (attribute === "result") {
            groups.push(
                <div key={"result-fail"}>
                <div className="width100">
                    <CaseGroup
                        overlay={this.props.overlay}
                        updatedCaseHash={this.props.updatedCaseHash}
                        updatedCaseRef={this.ref}
                        cases={failCases}
                        selectCase={selectCase} 
                        projects={projects}
                        projectIndex={projectIndex}
                        targets={targets} 
                        targetIndex={targetIndex} 
                        runs={runs} 
                        runIndex={runIndex} 
                        context={context} 
                        assignee={assignee} 
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.state.multiselect}
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        members={this.props.members}
                        trl={this.props.trl}
                        titleOverride="Failures"
                        collapseAll={this.state.collapseAll}
                        invalidateCollapseAll={this.invalidateCollapseAll}
                        duration={duration}
                        intervalStart={start}
                        intervalEnd={end}
                        addCaseToSuite={this.props.addCaseToSuite}
                        rawResultMaps={this.props.rawResultMaps}
                        preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                        groupSort={this.state.groupSort}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        side={this.props.side}
                        targetDetail={this.props.targetDetail}
                    />
                </div>
                </div>
            )
            groups.push(
                <div key={"result-unknown"}>
                <div className="width100">
                    <CaseGroup
                        overlay={this.props.overlay}
                        updatedCaseHash={this.props.updatedCaseHash}
                        updatedCaseRef={this.ref}
                        cases={unknownCases}
                        selectCase={selectCase} 
                        projects={projects}
                        projectIndex={projectIndex}
                        targets={targets} 
                        targetIndex={targetIndex} 
                        runs={runs} 
                        runIndex={runIndex} 
                        context={context} 
                        assignee={assignee} 
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.state.multiselect}
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        members={this.props.members}
                        trl={this.props.trl}
                        titleOverride="Unknown"
                        collapseAll={this.state.collapseAll}
                        invalidateCollapseAll={this.invalidateCollapseAll}
                        duration={duration}
                        intervalStart={start}
                        intervalEnd={end}
                        addCaseToSuite={this.props.addCaseToSuite}
                        rawResultMaps={this.props.rawResultMaps}
                        preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                        groupSort={this.state.groupSort}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        side={this.props.side}
                        targetDetail={this.props.targetDetail}
                    />
                </div>
                </div>
            )
            groups.push(
                <div key={"result-pass"}>
                <div className="width100">
                    <CaseGroup
                        overlay={this.props.overlay}
                        updatedCaseHash={this.props.updatedCaseHash}
                        updatedCaseRef={this.ref}
                        cases={passCases}
                        selectCase={selectCase} 
                        projects={projects}
                        projectIndex={projectIndex}
                        targets={targets} 
                        targetIndex={targetIndex} 
                        runs={runs} 
                        runIndex={runIndex} 
                        context={context} 
                        assignee={assignee} 
                        persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                        darkMode={this.props.darkMode}
                        multiselect={this.state.multiselect}
                        multiselectChange={this.multiselectChange}
                        multiselectEnabled={this.props.multiselectEnabled}
                        members={this.props.members}
                        trl={this.props.trl}
                        titleOverride="Passes"
                        collapseAll={this.state.collapseAll}
                        invalidateCollapseAll={this.invalidateCollapseAll}
                        duration={duration}
                        intervalStart={start}
                        intervalEnd={end}
                        addCaseToSuite={this.props.addCaseToSuite}
                        rawResultMaps={this.props.rawResultMaps}
                        preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                        groupSort={this.state.groupSort}
                        caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                        side={this.props.side}
                        targetDetail={this.props.targetDetail}
                    />
                </div>
                </div>
            )
        } else if (attribute === "task.resolved") {
            groups.push(
                <CaseGroup 
                    key={"unresolved"}
                    updatedCaseHash={this.props.updatedCaseHash}
                    updatedCaseRef={this.ref}
                    overlay={this.props.overlay}
                    cases={unresolved}
                    selectCase={selectCase} 
                    projects={projects}
                    projectIndex={projectIndex}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    context={context} 
                    assignee={assignee} 
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    darkMode={this.props.darkMode}
                    multiselect={this.state.multiselect}
                    multiselectChange={this.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    members={this.props.members}
                    trl={this.props.trl}
                    titleOverride="Unresolved"
                    collapseAll={this.state.collapseAll}
                    invalidateCollapseAll={this.invalidateCollapseAll}
                    duration={duration}
                    intervalStart={start}
                    intervalEnd={end}
                    addCaseToSuite={this.props.addCaseToSuite}
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    side={this.props.side}
                />
            );
            groups.push(
                <CaseGroup
                    key={"resolved"}
                    updatedCaseHash={this.props.updatedCaseHash}
                    updatedCaseRef={this.ref}
                    overlay={this.props.overlay} 
                    cases={resolved}
                    selectCase={selectCase} 
                    projects={projects}
                    projectIndex={projectIndex}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    context={context} 
                    assignee={assignee} 
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    darkMode={this.props.darkMode}
                    multiselect={this.state.multiselect}
                    multiselectChange={this.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    members={this.props.members}
                    trl={this.props.trl}
                    titleOverride="Resolved"
                    collapseAll={this.state.collapseAll}
                    invalidateCollapseAll={this.invalidateCollapseAll}
                    duration={duration}
                    intervalStart={start}
                    intervalEnd={end}
                    addCaseToSuite={this.props.addCaseToSuite}
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    side={this.props.side}
                />
            );
        } else if (attribute === "diff") {
            groups.push(
                <div className='mb-5' key="diff-fail">
                <CaseGroup 
                    updatedCaseHash={this.props.updatedCaseHash}
                    updatedCaseRef={this.ref}
                    overlay={this.props.overlay} 
                    cases={diffFail}
                    selectCase={selectCase} 
                    projects={projects}
                    projectIndex={projectIndex}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    context={context} 
                    assignee={assignee} 
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    darkMode={this.props.darkMode}
                    multiselect={this.state.multiselect}
                    multiselectChange={this.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    members={this.props.members}
                    trl={this.props.trl}
                    titleOverride="Failures present in this run only"
                    collapseAll={this.state.collapseAll}
                    invalidateCollapseAll={this.invalidateCollapseAll}
                    duration={duration}
                    intervalStart={start}
                    intervalEnd={end}
                    addCaseToSuite={this.props.addCaseToSuite}
                    totalOnly={true}
                    percentClass="target-pass-percent-red"
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    side={this.props.side}
                />
                </div>
            );
            groups.push(
                <div className='mb-5' key="diff-unknown">
                <CaseGroup
                    updatedCaseHash={this.props.updatedCaseHash}
                    updatedCaseRef={this.ref}
                    overlay={this.props.overlay} 
                    cases={diffUnknown}
                    selectCase={selectCase} 
                    projects={projects}
                    projectIndex={projectIndex}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    context={context} 
                    assignee={assignee} 
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    darkMode={this.props.darkMode}
                    multiselect={this.state.multiselect}
                    multiselectChange={this.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    members={this.props.members}
                    trl={this.props.trl}
                    titleOverride="Unknowns present in this run only"
                    collapseAll={this.state.collapseAll}
                    invalidateCollapseAll={this.invalidateCollapseAll}
                    duration={duration}
                    intervalStart={start}
                    intervalEnd={end}
                    addCaseToSuite={this.props.addCaseToSuite}
                    totalOnly={true}
                    percentClass="target-pass-percent-orange"
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    side={this.props.side}
                />
                </div>
            );
            groups.push(
                <div className='mb-5' key="diff-pass">
                <CaseGroup
                    updatedCaseHash={this.props.updatedCaseHash}
                    updatedCaseRef={this.ref}
                    overlay={this.props.overlay} 
                    cases={diffPass}
                    selectCase={selectCase} 
                    projects={projects}
                    projectIndex={projectIndex}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    context={context} 
                    assignee={assignee} 
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    darkMode={this.props.darkMode}
                    multiselect={this.state.multiselect}
                    multiselectChange={this.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    members={this.props.members}
                    trl={this.props.trl}
                    titleOverride="Passes present in this run only"
                    collapseAll={this.state.collapseAll}
                    invalidateCollapseAll={this.invalidateCollapseAll}
                    duration={duration}
                    intervalStart={start}
                    intervalEnd={end}
                    addCaseToSuite={this.props.addCaseToSuite}
                    totalOnly={true}
                    percentClass="target-pass-percent-green"
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    side={this.props.side}
                />
                </div>
            );
            groups.push(
                <div className='mb-5' key="diff-none">
                <CaseGroup
                    updatedCaseHash={this.props.updatedCaseHash}
                    updatedCaseRef={this.ref}
                    overlay={this.props.overlay} 
                    cases={diffNone}
                    selectCase={selectCase} 
                    projects={projects}
                    projectIndex={projectIndex}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    context={context} 
                    assignee={assignee} 
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    darkMode={this.props.darkMode}
                    multiselect={this.state.multiselect}
                    multiselectChange={this.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    members={this.props.members}
                    trl={this.props.trl}
                    titleOverride="No result difference between runs"
                    collapseAll={this.state.collapseAll}
                    invalidateCollapseAll={this.invalidateCollapseAll}
                    duration={duration}
                    intervalStart={start}
                    intervalEnd={end}
                    addCaseToSuite={this.props.addCaseToSuite}
                    totalOnly={true}
                    percentClass="target-pass-percent-gray"
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    side={this.props.side}
                />
                </div>
            );
        } else if (attribute === "priority") {
            let priorites = [
                {key: "Priority 0", value: priority0},
                {key: "Priority 1", value: priority1},
                {key: "Priority 2", value: priority2},
                {key: "Priority 3", value: priority3},
                {key: "Priority 4", value: priority4},
                {key: "Priority 5", value: priority5},
                {key: "PriorityNone", value: priorityNone}
            ]

            for (let p = 0; p < priorites.length; p++) {
                let priority = priorites[p]
                groups.push(
                    <div key={"priority-" + priority.key}>
                    <div className="width100">
                        <CaseGroup
                            updatedCaseHash={this.props.updatedCaseHash}
                            updatedCaseRef={this.ref}
                            overlay={this.props.overlay}
                            cases={priority.value}
                            selectCase={selectCase} 
                            projects={projects}
                            projectIndex={projectIndex}
                            targets={targets} 
                            targetIndex={targetIndex} 
                            runs={runs} 
                            runIndex={runIndex} 
                            context={context} 
                            assignee={assignee} 
                            persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                            persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                            darkMode={this.props.darkMode}
                            multiselect={this.state.multiselect}
                            multiselectChange={this.multiselectChange}
                            multiselectEnabled={this.props.multiselectEnabled}
                            members={this.props.members}
                            trl={this.props.trl}
                            titleOverride={priority.key}
                            collapseAll={this.state.collapseAll}
                            invalidateCollapseAll={this.invalidateCollapseAll}
                            duration={duration}
                            intervalStart={start}
                            intervalEnd={end}
                            addCaseToSuite={this.props.addCaseToSuite}
                            rawResultMaps={this.props.rawResultMaps}
                            preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                            groupSort={this.state.groupSort}
                            caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                            side={this.props.side}
                            targetDetail={this.props.targetDetail}
                        />
                    </div>
                    </div>
                )   
            }
        }

        let message = this.props.message;
        if (message === undefined) {
            message = <span></span>
        }

        let costDisplay = undefined;
        if (costTotal !== undefined) {
            if (costTotal['USD-day'] !== undefined) {
                if (costTotal['USD-day'] !== 0) {
                    costDisplay = 
                    <div className="neutral3 font14" style={{"display":"flex", "alignItems":"center"}}>
                        <div className="mr-1">
                            <img src="/img/currency-dollar.svg" width="16" height="16" alt="cost"/> 
                        </div>
                        <div>
                            {"USD $" + costTotal['USD-day'].toFixed(2) + "/day cost if test failure in production"} 
                        </div>
                    </div>
                }
            }
        }

        cases.push(scrollToTopLink);
        
        if (this.props.preRender === true) {
            cases = cases.slice(0, 25);
        }

        let header0 = "Name"
        let header1 = "Suite"
        let header2 = (this.props.context === "manual" || this.props.context === "manual-archive") ? "Assignee" : "Time"
        let header3 = "Priority"
        let header4 = "Icons"
        let header5 = ""
        
        let preferencesReportingCaseDisplayAttributeMap = undefined
        if (this.props.context !== "tasks") {
            preferencesReportingCaseDisplayAttributeMap = utilsPreferencesReportingCaseDisplayAttributeMap(this.project(), this.props.targets, this.props.targetIndex, this.props.preferencesReportingCaseDisplayAttributeMaps)
        }

        if (preferencesReportingCaseDisplayAttributeMap !== undefined) {
            header1 = preferencesReportingCaseDisplayAttributeMap.attribute1
            header2= preferencesReportingCaseDisplayAttributeMap.attribute2
            header3 = preferencesReportingCaseDisplayAttributeMap.attribute3
        }

        if (this.props.context === "release-checklist") {
            header0 = "Name"
            header1 = ""
            header2 = ""
            header3 = ""
            header4 = ""
            header5 = "Status"
        }

        return (
            <div className="case-list">
                <CaseListHeader 
                    toggleCollapseAll={this.toggleCollapseAll}
                    collapseAll={this.state.collapseAll}
                    duration={durationRun}
                    intervalStart={startRun}
                    intervalEnd={endRun}
                    multiselectAll={multiselectAll}
                    context={this.props.context}
                    taskCaseListHeader={this.props.taskCaseListHeader}
                    build={build}
                    cost={costDisplay}
                    overlay={this.props.overlay}
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex}
                    runArchive={this.props.runArchive}
                    runArchiveBuildCase={this.props.runArchiveBuildCase}
                    runArchiveCases={this.props.runArchiveCases}
                    isRunArchive={this.props.isRunArchive}
                    runArchiveDeleted={this.runArchiveDeleted}
                    revUpdate={this.props.revUpdate}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                />
                <CaseGroupHeader2 
                    header0={header0}
                    header1={header1}
                    header2={header2}
                    header3={header3}
                    header4={header4}
                    header5={header5}
                    context={this.props.context}
                    collapse={this.state.collapse}
                    groupSortFunc={this.groupSort}
                    groupSort={this.state.groupSort}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                />
                {groups}
                {
                    (((searchExclusionNum !== total) && (total !== 0)) || this.props.context === "diff" || this.props.context === "tasks") ?  
                    <div className='mb-5'>&nbsp;</div>
                    :
                    <div className='neutral4 font15 solidborder neutral7border p-4 text-center'>
                        No test cases match search term
                    </div>
                }
            </div>
        );
    }
};

export default CaseList;