/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Analytics from './Analytics';
import TesultsLtd from './TesultsLtd';

class AboutMain extends Component {
    constructor () {
        super();
        Analytics.event("About");
    }
    render () {
        return (
            <div className="max-width-800px">
                <h1>About</h1>
                <div className='mb-3'><span className='font15 line-height-1 neutral3'><i>Tesults is a portmanteau of <span className='neutral0 bold'>tes</span>t res<span className='neutral0 bold'>ults</span>.</i></span></div>
                <div><img className='resultspulsepass' src="/img/tesults-logo-pass-128.png" style={{"width":"32px", "height":"32px"}} alt="pass"/><img className='resultspulsefail' src="/img/tesults-logo-fail-128.png" style={{"width":"32px", "height":"32px"}} alt="fail"/></div>
                <h2 className="mt-5 mb-4 bold">Mission</h2>
                <h3 className="neutral3 mt-3 mb-3 bold">To enable software development teams to release high quality builds faster and reduce risk</h3>
                <p>Tesults provides tooling to facilitate the scientific, data driven process of innovation in software developement. Specifically Tesults improves the development, testing and release loop by making it possible to draw appropriate conclusions around the technical health of a project effectively to achieve the right balance between go to market, risk reduction and the highest standards in engineering.</p>
                <p>Automated testing makes it possible to release faster with assurance that a robust high quality product is shipped on every iteration and Tesults is tasked with ensuring teams get the most out of this automation.</p>
                <p>Monitoring results data and making decisions around actions to take on test case failures falls upon the development and test team. Making the right calls about the technical health of a project requires that test results data have clarity and be appropriatly analyzed and that is precisely what Tesults is designed to do.</p>
                <p>Tesults makes this process demonstrably superior and helps teams get the most out of their automated and manual testing. Tesults is intended to be a critical part of your automated testing infrastructure and quality control processes.</p>
                <h2 className="mt-5 mb-4 bold">Vision</h2>
                <h3 className="neutral3 mt-3 mb-3 bold">Bring an end to regression in software development and speed up the innovation loop with assurance and without trepidation</h3>
                <p>Consolidating test results data to make it accessible and visible is critical for maximizing the effectiveness of automated and manual testing. Quality and process improvements, risk reduction and faster releases all become possible when data is available for current and historical test results along with files, artifacts, screen captures, logs and performance data. Having this data available on demand across all projects, applications, platforms, branches and builds flavors makes it possible for teams and organizations to innovate faster, got to market faster and reduce risk with assurance that the technical quality of their product or service is solid.</p>
                <p>Tesults is designed to handle results data from every build and test run. For larger teams with high frequency commits the amount of data generated can be large if automated tests run on every build. Tesults lets your team focus on domain specific work such as writing test hooks, test cases and testable feature code and not have to worry about test results data storage, analysis or reporting and eliminates collaboration issues around quality. Tesults is the test automation dashboard, reporting and analysis tool of choice for high performing engineer teams.  We endeavor to keep improving Tesults so that you can keep innovating faster.</p>
                <hr className='dividor-light mt-5 mb-5'/>
                <h2 className="mt-5 mb-3 bold">Team</h2>
                <p>Tesults consists of a globally distributed remote-first team. The Tesults team includes software engineers and engineering leadership with experience in the biggest tech companies in the world. We are committed to ensuring Tesults is the best way to manage test results data and test cases. We release regularly to enhance the service with better analysis technology and automation workflows to help engineering teams.</p>
                <h2 className="mt-5 mb-3 bold">Data</h2>
                <p>Data is stored in data centers located within the United States <img src="/img/usa-flag.png" alt="USA" height="20"/>. See the 'Data Storage and Security' section in the <NavLink to="/docs/terms" target="_blank" className="nounderline neutral1">Tesults terms of service</NavLink> for more details.</p>
                <h2 className="mt-5 mb-3 bold">Company</h2>
                <div className="mb-5">
                    <TesultsLtd/>
                </div>
            </div>
        );
    }
};

export default AboutMain;