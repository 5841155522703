/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';
import Notice from './Notice';

class DocsJUnit5 extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>JUnit 5 test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle JUnit 5 test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <p>Learn how to report JUnit5 test results with Tesults. If you are not using JUnit5, view the <NavLink to="/docs/java" target="_blank" className="site-link-primary2">Java</NavLink> docs for information about integrating with a lower level library.</p>

        const installation = 
        <div>
            <p>The Tesults Java API Library and JUnit5 extension are available from the JCenter and Maven Central repositories.</p>
            <h4 className="mt-4">Gradle</h4>
            <p>If you are using Gradle add this dependency snippet to your build.gradle file:</p>
            <pre className="docsCode">
            dependencies {'{'}<br/>
            &nbsp;&nbsp;implementation '<code className="accentb4">com.tesults.junit5:tesults-junit5:1.2.0</code>'<br/>
            
            {'}'}<br/>
            </pre>
            <p>Also ensure you have the JCenter or Maven Central repository referenced in your build.gradle file.</p>
            <h4 className="mt-5">Maven</h4>
            <p>If you are using Maven add this dependency snippet to your pom.xml file:</p>
            <pre className="docsCode">
            {'<'}dependency{'>'}<br/>
                &nbsp;&nbsp;{'<'}groupId{'>'}<code className="accentb4">com.tesults.junit5</code>{'<'}/groupId{'>'}<br/>
                &nbsp;&nbsp;{'<'}artifactId{'>'}<code className="accentb4">tesults-junit5</code>{'<'}/artifactId{'>'}<br/>
                &nbsp;&nbsp;{'<'}version{'>'}<code className="accentb4">1.2.0</code>{'<'}/version{'>'}<br/>
            {'<'}/dependency{'>'}<br/>
            </pre>
        </div>    
        
        const configuration = 
        <div>
            <h4>JUnit5 Extensions Enable</h4>
            <p>JUnit5 extensions auto-detection is disabled by default. To <a target="_blank" rel="noopener noreferrer" href="https://junit.org/junit5/docs/current/user-guide/#launcher-api-listeners-custom" className="site-link-primary2">enable automatic extension detection</a> set the junit.jupiter.extensions.autodetection.enabled configuration parameter to true. This can be supplied as a <b>JVM system property</b>.</p>
            <p>Gradle example:</p>
            <pre className="docsCode">
            test {'{'}<br/>
            &nbsp;&nbsp;useJUnitPlatform()<br/>
            &nbsp;&nbsp;<code className="accentb4">systemProperty 'junit.jupiter.extensions.autodetection.enabled','true'</code><br/>
            {'}'}
            </pre>
            <p>Maven example: Use the Maven Surefire plugin.</p>
            <span className="neutral4"><i>pom.xml</i></span>
            <br/>
            <pre className="docsCode">
            {'<build>'}<br/>
            &nbsp;&nbsp;{'<plugins>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'<plugin>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<groupId>org.apache.maven.plugins</groupId>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<artifactId>maven-surefire-plugin</artifactId>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<version>2.22.0</version>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<configuration>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<properties>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<configurationParameters>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">junit.jupiter.extensions.autodetection.enabled = true</code><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</configurationParameters>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</properties>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'<systemPropertyVariables>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">{'<tesultsTarget>token</tesultsTarget>'}</code><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</systemPropertyVariables>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'</configuration>'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'</plugin>'}<br/>
            &nbsp;&nbsp;{'</plugins>'}<br/>
            {'</build>'}
            </pre>
            <pre className="primary2 docsArgsCode pl-0">tesultsTarget<span className="neutral7 ml-5">Required</span></pre>
            <p>Required to upload to Tesults, if this arg is not provided junit5-tesults does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <p><b>Inline method</b></p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsTarget=eyJ0eXAiOiJ...</pre>
            <p>In this case, the target token is supplied directly or inline in the args. This is the simplest approach.</p>
            <p><b>Key method</b></p>
            <pre className="docsCode pre-wrap">-DtesultsTarget=target1</pre>
            <p>In this case, junit5-tesults will automatically look up the value of the token based on the property provided from a configuration file. The path to the configuration file, specifically, a .properties file must be provided using the -DtesultsConfig=path-to-properties-file/tesults.properties, more details below.</p>
            <p>Here is the corresponding tesults.properties file:</p>
            <pre className="docsCode pre-wrap">
            target1 = eyJ0eXAiOiJ...<br/>
            target2 = ...<br/>
            target3 = ...<br/>
            target4 = ...<br/>
            </pre>
            <p>Or something more descriptive about the targets:</p>
            <pre className="docsCode pre-wrap">
            web-qa-env = eyJ0eXAiOiJ...<br/>
            web-staging-env = ...<br/>
            web-prod-env = ...<br/><br/>
            ios-qa-env = eyJ0eXAiOiK...<br/>
            ios-staging-env = ...<br/>
            ios-prod-env = ...<br/><br/>
            android-qa-env = eyJ0eXAiOiL...<br/>
            android-staging-env = ...<br/>
            android-prod-env = ...<br/>
            </pre>
            <pre className="primary2 docsArgsCode pl-0">tesultsConfig<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to provide a path to a configuration properties file</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsConfig=/Users/admin/Desktop/tesults.properties</pre>
            <p>The properties file is useful to store several Tesults target tokens that can then be automatically be looked up by supplying an easy to remember target name. See more details above in the -DtesultsTarget arg description. The properties file can also contain all other args (without the -D prefix) to avoid having to pass them as in as system properties args. For example, the property file could contain TesultsFiles=/Users/admin/Desktop/TestFiles so you do not need to provide it as a system property. Then all you would need to pass as system properties are:<br/>-DtesultsTarget=friendlyTargetName<br/>-DtesultsConfig=/Users/admin/Desktop/tesults.properties.</p>
            <Box 
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={<div><p>At this point tesults-junit5 will have self registered and will push results to Tesults when you run your tests as long as this condition is met:</p><p>junit5-tesults requires the tesultsTarget system property to be supplied to indicate which target to use. See above for complete details.</p></div>}
            />
            <p>Note: <b>if you encounter issues where tests are not being discovered</b> after adding the Tesults JUnit 5 dependency there may be a conflict related to junit-platform-launcher. Try adding this exclusion and try again. If you still encounter issues please contact help@tesults.com:</p>
            <pre className='docsCode'>
            &lt;dependency&gt;<br/>
            &nbsp;&nbsp;&lt;groupId&gt;com.tesults.junit5&lt;/groupId&gt;<br/>
            &nbsp;&nbsp;&lt;artifactId&gt;tesults-junit5&lt;/artifactId&gt;<br/>
            &nbsp;&nbsp;&lt;version&gt;1.2.0&lt;/version&gt;<br/>
            &nbsp;&nbsp;&lt;exclusions&gt;<br/>
            <code className="accentb4">
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;exclusion&gt;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;groupId&gt;org.junit.platform&lt;/groupId&gt;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;artifactId&gt;junit-platform-launcher&lt;/artifactId&gt;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&lt;/exclusion&gt;<br/>
            </code>
            &nbsp;&nbsp;&lt;/exclusions&gt;<br/>
            &lt;/dependency&gt;
            </pre>
            <p><u>Do not</u> add the exclusion above unless you are encountering the issue mentioned about test discoverability.</p>
        </div>

        const files = 
        <div>
            <div>
                There are two ways to include files generated by tests.
            </div>
            <h4 className="accentc4 bold">Recommended</h4>
            <div>
            <p>The recommended approach is to ensure you have version 1.2.0 + of the tesults-junit5 library installed and then use the built in file method.</p>
            <p>To do this, inject the JUnit 5 TestInfo parameter into your test and then call the file method on TesultsListener passing in TestInfo and the full path to the file.</p>
            <p>Example:</p>
            <pre className="docsCode">
            import com.tesults.junit5.TesultsListener;<br/>
            import org.junit.jupiter.api.TestInfo;<br/><br/>
            public class TestSuiteA {'{'}<br/>
            &nbsp;&nbsp;@Test<br/>
            &nbsp;&nbsp;void Test1 (TestInfo testInfo) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.file(testInfo, "/full/path/to/file/log.txt")</code>;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">TesultsListener.file(testInfo, "/full/path/to/file/screenshot.png")</code>;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;assertEquals(1, 1);<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
            </pre>
            </div>
            <h4 className="accenta4 bold">Not Recommended</h4>
            <p>This second method is no longer recommended but can be used if desired.</p>
            <pre className="primary2 docsArgsCode pl-0">tesultsFiles<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsFiles=/Users/admin/Desktop/temporary</pre>
            <p>This is one area where junit5-tesults is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>You can utilize a function such as this one to create a file at a path that meets this requirement:</p>
            <pre className="docsCode">
            public File createFile (String suite, String test, String file) {'{'}<br/>
            &nbsp;&nbsp;String temp = "/Users/admin/Desktop/temp"; // tesultsFiles arg value<br/>
            &nbsp;&nbsp;String path = temp + File.separator + suite;<br/>
            &nbsp;&nbsp;path = path + File.separator + test;<br/>
            &nbsp;&nbsp;path = path + File.separator + file;<br/>
            &nbsp;&nbsp;File f = new File(path);<br/>
            &nbsp;&nbsp;File dir = f.getParentFile();<br/>
            &nbsp;&nbsp;if (dir != null) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;dir.mkdirs();<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;try {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;f.createNewFile();<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return f;<br/>
            &nbsp;&nbsp;{'}'} catch (IOException ex) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("saveFile error: " + ex.getMessage());<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return null;<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
            </pre>
            <p>Please see the tags section for more details on how to provide a suite name and also take a look at the -DtesultsNoSuites flag below. The default behavior of junit5-tesults is to set the class name as the test suite if a test suite is not explicitly provided using a tag. Also be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildName<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildName=1.0.0</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildResult<span className="neutral7 ml-5">Optional</span></pre>            
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildResult=pass</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildDesc<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsBuildDesc=added new feature</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesultsBuildReason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">tesultsBuildReason=build error line 201 somefile.py</pre>
            <h5 className="mt-5">No Test Suites</h5>
            <pre className="primary2 docsArgsCode pl-0">tesultsNoSuites<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this flag to stop automatic setting of the class name as the test suite in the case where a suite is not explicitly supplied using @Tag("suite=SuiteName")</p>
            <pre className="docsCode pre-wrap-break-word">-DtesultsNoSuites</pre>
            <Notice
                type="information"
                content="If you are using Cucumber, the class name is not used as the name of the test suite and the JUnit 5 @Tag is not recognized. Do not set the tesultsNoSuite option when using Cucumber. Instead to set a test suite name add this to the top of your feature file: @suite=Test-Suite-A. Replace 'Test-Suite-A' with whatever you want for the name of the suite, without spaces."
            />
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                build={build}
                integration="junit5"
            />
        );
    }
}

export default DocsJUnit5;