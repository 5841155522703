/*global Cookies*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import Confirmation from './Confirmation';
import Cache from './Cache';
import Request from './Request';

class MemberAdd extends Component {
    constructor () {
        super();
        this.state = {display: true, email: "", confirmAdd: false, confirmation: {success: undefined, failure: undefined}};
        this.addMember = this.addMember.bind(this);
        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.emailChange = this.emailChange.bind(this);
    }

    componentDidMount () {
        let projectInviteDisplay = Cache.getPreference(Cache.preference.projectInviteDisplay);
        if (projectInviteDisplay === undefined) {
            Cache.setPreference(Cache.preference.projectInviteDisplay, true);
        } else {
            if (projectInviteDisplay === false) {
                this.setState({display: false});
            }
        }
    }

    toggleDisplay () {
        Analytics.event("MemberAddToggle");
        Cache.setPreference(Cache.preference.projectInviteDisplay, this.state.display === true? false: true);
        this.setState({display: this.state.display === true ? false: true});
    }

    emailChange (e) {
        this.setState({email: e.target.value, confirmAdd: false, confirmation: {success: undefined, failure: undefined}});
    }

    addMember () {
        Analytics.event("MemberAdd");
        let email = this.state.email.toLowerCase().trim();
        // Domain check
        const user = Cookies.getJSON("truser");
        let userEmail = user.email;
        if (userEmail === undefined) {
            this.setState({confirmation: {success: undefined, failure: "Error adding team member"}});
        } else {
            userEmail = userEmail.toLowerCase().trim();
            const atIndex1 = userEmail.indexOf("@");
            if (atIndex1 !== -1) {
                const domain1 = userEmail.substring(atIndex1);
                const atIndex2 = email.indexOf("@");
                if (atIndex2 !== -1) {
                    const domain2 = email.substring(atIndex2);
                    if (domain1 !== domain2) {
                        if (this.state.confirmAdd !== true) {
                            this.setState({confirmAdd: true, info: "", confirmation: {success: undefined, failure: "This email address has a different domain to yours. Confirm you want to use this email address to add a team member."}});
                            return;
                        }
                    }
                }
            }            
            this.setState({submitting: true, confirmAdd: false, confirmation: {success: undefined, failure: undefined}});
            Request.post("/memberadd", {id: this.props.projectId, email: email}, (err, data) => {
                if (err) {
                    Analytics.event("MemberUnableToAdd");
                    this.setState({confirmation: {success: undefined, failure: "Unable to add team member. Check Configuration to see if this person is already a member."}, submitting: false});
                } else {
                    Analytics.event("MemberAdded");
                    this.setState({email: "", confirmAdd: false, submitting: false, confirmation: {failure: undefined, success: "Team member added. Revoke access and make other changes from Configuration."}});
                }
            });
        }
    }

    render () {
        if (this.props.full !== undefined) {
            if (this.props.full !== true) {
                return <span></span>
            }
        }
        if (this.state.display !== true) {
            return (
                <div style={{"display:":"flex", "flexDirection":"column", "maxWidth":"220px"}} className="p-4">
                    <div style={{"display":"flex"}} className="mb-2">
                        <div>
                            <span className="font12 primary7">Project invite</span>
                        </div>
                        <div style={{"marginLeft":"auto", "marginTop":"-4px"}}>
                            <button onClick={this.toggleDisplay} className="btn-transparent btn-close-primary7-sm pt-0 mt-0 neutral7border">
                                <span className="font12 primary7">+</span>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="pl-3 pr-3">
            <div style={{"display:":"flex", "flexDirection":"column", "maxWidth":"220px"}} className="p-4 primary8border standardborder solidborder mt-5 mb-5">
                <div style={{"display":"flex"}} className="mb-2">
                    <div className="primary7 font14 bold">Project invite</div>
                    <div style={{"marginLeft":"auto", "marginTop":"-4px", "marginRight":"-4px"}}>
                        <button onClick={this.toggleDisplay} className="btn-transparent btn-close-primary7-sm pt-0 mt-0">
                            &times;
                        </button>
                    </div>
                </div>
                <div className="primary7 font14 mb-3">Enter a team member's email address to invite them to the project</div>
                <input type="email" className="tr-input-primary2-transparent mb-3" style={{"maxWidth":"140px"}} value={this.state.email} placeholder="Email" onChange={this.emailChange} required/>
                <button type="button" className="btn-confirm mb-3" onClick={this.addMember} disabled={this.state.submitting}>{this.state.confirmAdd === true ? "Invite confirm" : "Invite"}</button>
                {
                    (this.state.confirmation.success !== undefined || this.state.confirmation.failure !== undefined) ?
                    <div className="whitebg mt-3 rounderborder p-3">
                        <Confirmation confirmation={this.state.confirmation}/>
                    </div>
                    :
                    <span></span>
                }
            </div>
            </div>
        );
    }
};

export default MemberAdd;