/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';

class Copy extends Component {
    constructor () {
        super();
        this.copy = this.copy.bind(this);
    }

    copy () {
        if (this.props.text !== undefined) {
            Analytics.event("Copy-" + this.props.text);
            navigator.clipboard.writeText(this.props.text);
        }
    }

    render () {
        return (
            <img src="/img/edit-copy.svg" width="12" height="12" alt="Copy" className="caseLink" onClick={this.copy}/>
        );
    }
};

export default Copy;