/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import Request from './Request';
import Confirmation from './Confirmation';
import AffiliateDashboardHeader from './AffiliateDashboardHeader';
import AffiliateDashboardVisits from './AffiliateDashboardVisits';
import AffiliateDashboardEvents from './AffiliateDashboardEvents';
import AffiliateDashboardRefs from './AffiliateDashboardRefs';
import AffiliateDashboardPayments from './AffiliateDashboardPayments';
import MultiTextList from './MultiTextList';

class AffiliateDashboard extends Component {
    constructor () {
        super();
        // views: select, id
        this.state = {
            view: "select", 
            affiliates: [], 
            confirmation: {success: undefined, failure: undefined}, 
            disabledButtons: false, 
            selectedAffiliateId: undefined, 
            affiliatedData: []
        };
        this.affiliates = this.affiliates.bind(this);
        this.renderSelect = this.renderSelect.bind(this);
        this.select = this.select.bind(this);
        this.selectedId = this.selectedId.bind(this);
    }

    componentDidMount () {
        if (this.props.loggedIn !== true) {
            return;
        }
        const dashboardPath = "/affiliate/dashboard"
        const dashboardIdPrefix = "/affiliate/dashboard/id/";
        let path = window.location.pathname;
        if (path === dashboardPath) {
            this.select();
        } else if (path.startsWith(dashboardIdPrefix)) {
            let affiliateId = path.substring(dashboardIdPrefix.length);
            this.setState({view: "id", selectedAffiliateId: affiliateId});
        } else {
            this.select();
        }
    }

    affiliates () {
        Request.get("/affiliates", undefined, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                this.setState({affiliates: data.affiliates});
            }
        });
    }

    select () {
        this.props.history.push("/affiliate/dashboard");
        this.setState({view: "select"}, this.affiliates);
    }

    selectedId (affiliateId) {
        if (affiliateId === undefined) {
            affiliateId = this.state.selectedAffiliateId;
        }
        this.props.history.push("/affiliate/dashboard/id/" + affiliateId);
        this.setState({view: "id", selectedAffiliateId: affiliateId});
    }

    renderSelect () {
        let affiliateData = [];
        if (this.state.affiliates.length === 0) {
            affiliateData = <p>You have not created any affiliate links yet.</p>
        } else {
            for (let i = 0; i < this.state.affiliates.length; i++) {
                let affiliate = this.state.affiliates[i];
                if  (affiliate !== undefined) {
                    affiliateData.push(
                        <div style={{"display":"flex", "flexDirection":"column"}} className="width100 mb-5">
                            <div className="mb-3">
                                {i + 1}
                            </div>
                            <div className="mb-3">
                                {<div><b>Referral id:</b> {affiliate.id}</div>}
                            </div>
                            <div className="mb-3">
                                {<div><b>Referral url:</b> <a href={"/ref=" + affiliate.id}>{"https://www.tesults.com/ref/" + affiliate.id}</a></div>}
                            </div>
                            <div className="mb-3">
                                {<div><b>Sources:</b></div>}
                                <MultiTextList list={affiliate.sources}/>
                            </div>
                            <div className="mb-3">
                                <div style={{"display":"flex", "alignItems":"center"}}>
                                    <div className="mr-3">
                                        {affiliate.pendingDelete === true ? <span className="neutral4 font12">Pending delete</span> : <span></span>}
                                    </div>
                                    <div>
                                        {affiliate.approved === true ? <span className="neutral4 font12">Approved</span> : <span className="neutral4 font12">Unapproved</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <button className="btn-confirm" onClick={() => this.selectedId(affiliate.id)}>View data</button>
                            </div>
                        </div>
                    );
                }
            }
        }
        return (
            <div style={{"minHeight": "100vh"}}>
                <div style={{"display":"flex", "alignItems":"center"}}>
                    <div>
                        <h1 className="neutral1">Reports</h1>
                    </div>
                    <div className="mobile-hide" style={{"marginLeft":"auto", "marginRight":"41px"}}>
                        <button className="select" onClick={() => window.location.reload()}>
                            <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                        </button>
                    </div>
                </div>
                <div>
                    {affiliateData}
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }

    render () {
        if (this.props.loggedIn !== true) {
            return (
                <div style={{"minHeight": "100vh"}}>
                    <h1 className="neutral1">Tesults affiliate program</h1>
                    <p>Please <NavLink to="/login?iref=affiliate" className="tr-link-primary4">sign in</NavLink>. If you do not have a Tesults account please <NavLink to="/register?iref=affiliate" className="tr-link-primary4">sign up</NavLink></p>
                </div>
            );    
        } 
        if (this.state.view === "select") {
            return this.renderSelect();
        } else if (this.state.view === "id") {
            return (
                <div style={{"minHeight": "100vh"}}>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                    <div>
                        <h1 className="neutral1">Report</h1>
                    </div>
                    <div className="mobile-hide" style={{"marginLeft":"auto", "marginRight":"41px"}}>
                        <button className="select" onClick={() => window.location.reload()}>
                            <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                        </button>
                    </div>
                    </div>
                    <div className="mb-4">
                        <button className="btn-transparent neutral4" onClick={this.select}>{"<<"} Back to selection</button>
                    </div>
                    <div>
                        <AffiliateDashboardHeader id={this.state.selectedAffiliateId}/>
                    </div>
                    <div>
                        <AffiliateDashboardPayments id={this.state.selectedAffiliateId}/>
                    </div>
                    <div>
                        <AffiliateDashboardRefs id={this.state.selectedAffiliateId}/>
                    </div>
                    <div>
                        <AffiliateDashboardVisits id={this.state.selectedAffiliateId}/>
                    </div>
                    <div>
                        <AffiliateDashboardEvents id={this.state.selectedAffiliateId}/>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{"minHeight": "100vh"}}>
                    <h1 className="neutral1">Reports</h1>
                    <div>
                        <p>Select an option from the menu</p>
                    </div>
                </div>
            );   
        }
    }
};

export default AffiliateDashboard;