/*global Cookies*/
import React, { Component } from 'react'
import Confirmation from './Confirmation'

class SuiteAnnotation extends Component {
    /*
    props:
        suiteName
        persistentSuiteDataUpdate
        persistentSuiteDataIteration
        persistentSuiteData
    */

    constructor () {
        super()
        let user = Cookies.getJSON("truser")
        this.state = {
            state: "view",
            annotation: "",
            editedAnnotation: "",
            changes: false,
            suiteData: undefined,
            confirmation: {success: undefined, failure: undefined},
            readOnly: (user === undefined || user == null) ? true : false
        }
        this.edit = this.edit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.save = this.save.bind(this)
        this.annotationChange = this.annotationChange.bind(this)
        this.renderAnnotation = this.renderAnnotation.bind(this)
        this.renderEditAnnotation = this.renderEditAnnotation.bind(this)
    }

    componentDidMount () {
        if (this.props.persistentSuiteData !== undefined) {
            if (Array.isArray(this.props.persistentSuiteData)) {
                for (let i = 0; i < this.props.persistentSuiteData.length; i++) {
                    const suiteData = this.props.persistentSuiteData[i]
                    if (suiteData.name === this.props.suiteName) {
                        this.setState({annotation: suiteData.annotation, editedAnnotation: suiteData.annotation, suiteData: suiteData})
                    }
                }
            }
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.persistentSuiteData !== undefined && this.state.suiteData === undefined) {
            if (Array.isArray(this.props.persistentSuiteData)) {
                for (let i = 0; i < this.props.persistentSuiteData.length; i++) {
                    const suiteData = this.props.persistentSuiteData[i]
                    if (suiteData.name === this.props.suiteName) {
                        this.setState({annotation: suiteData.annotation, editedAnnotation: suiteData.annotation, suiteData: suiteData})
                    }
                }
            }
        }
    }

    edit () {
        this.setState({state: "edit", confirmation: {success: undefined, failure: undefined}})
    }

   cancel () {
        this.setState({state: "view", confirmation: {success: undefined, failure: undefined}})
    }

    save () {
        let suiteData = this.state.suiteData
        if (suiteData !== undefined) {
            suiteData.annotation = this.state.editedAnnotation
        } else {
            suiteData = {
                name: this.props.suiteName,
                annotation: this.state.editedAnnotation
            }
        }
        this.props.persistentSuiteDataUpdate([suiteData], (err) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to update annotation"}})
            } else {
                this.setState({annotation: suiteData.annotation, editedAnnotation: suiteData.annotation, confirmation: {success: "Annotation saved", failure: undefined}, state: "view"})
            }
        })
    }

    annotationChange (e) {
        this.setState({editedAnnotation: e.target.value, changes: this.state.annotation !== e.target.value ? true : false, confirmation: {success: undefined, failure: undefined}})
    }

    renderAnnotation () {
        return (
            <span>
                { this.state.readOnly ? <span></span>:
                <button className='btn-transparent neutral4 font12 ml-5' onClick={this.edit}>{(this.state.annotation === "" || this.state.annotation === undefined) ? "+ Add annotation" : "Edit annotation"}</button>
                }
                {(this.state.annotation === "" || this.state.annotation === undefined) ? <span></span> :
                <div className='neutral4 font14 mr-3 pre-wrap'>{this.state.annotation}</div>
                }
            </span>
        )
    }

    renderEditAnnotation () {
        return (
            <div>
                <div>
                    <div className='neutral4 font12'>An annotation is used to add additional descriptive information about a test suite for reference purposes.</div>
                    <textarea className="tr-input-no-fixed-dim form-control " cols="50" rows="4" name="annotation" value={this.state.editedAnnotation} onChange={this.annotationChange} required></textarea>
                    <br/>
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
                <div>
                    {this.state.changes ? <span className='neutral4 font12'><button className='btn-transparent neutral4 font12' onClick={this.save}>Save changes</button> | </span> : <span></span>}
                    <span><button className='btn-transparent neutral4 font12' onClick={this.cancel}>Cancel</button></span>
                </div>
            </div>
        )
    }

    render () {
        if (this.state.state === "view") {
            return this.renderAnnotation()
        } else if (this.state.state === "edit") {
            return this.renderEditAnnotation()
        } else {
            return <div></div>
        }
    }
}

export default SuiteAnnotation