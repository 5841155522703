/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsAPIPurpose from './DocsAPIPurpose';
import Notice from './Notice'


class DocsOverview extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - API Overview</title>
                    <meta name="description" content="Learn how to use the Tesults API."/>
                </Helmet>
                <div>
                    <iframe className='youtube' src="https://www.youtube.com/embed/TYb7xmg3WXw?si=WQ-Qjg_xmMBKLa8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p>Tesults is primarily utilized for analysis and reporting of test results data from within the Tesults interface. However there are situations where programmatic access to results data is useful, for example, when making decisions based on test results in a continuous integration or deployment pipeline. The Tesults API helps you do that.</p>
                    <p>Understand how to authenticate and utilize the apis available:</p>
                    <ul>
                        <li><a href="/docs/api/auth" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">Authentication</a></li>
                        <li><a href="/docs/api/targets" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">Targets</a></li>
                        <li><a href="/docs/api/results" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">Results</a></li>
                    </ul>
                    <DocsAPIPurpose/>
                    <Notice type="information" content={<a href="https://www.tesults.com/blog/test-reporting-and-continuous-integration-and-deployment" target="_blank" className="tr-link-primary4">Click to learn how you can use the Tesults API to make CI/CD pipeline decisions</a>}/>                    
                </div>
            </div>
        );
    }
}

export default DocsOverview;