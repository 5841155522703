/*global*/
import React, { Component } from 'react';
import Loading from './Loading';
import Request from './Request';
import Cache from './Cache';
import Context from './Context';

class SSO extends Component {
    static contextType = Context;
    
    constructor () {
        super ();
        this.state = {loading: false, state: null, sso: undefined, id: "", samlLoginEndpoint: "", idpEntityId: "", publicX509Certificate: "", tesultsLogin: true, emailChange: true, projectCreation: true, googleOAuthDomainAccess: [], nextState: null, domainChange: false}
        this.getSSO = this.getSSO.bind(this);
        this.backToStart = this.backToStart.bind(this);
        this.configId = this.configId.bind(this);
        this.configSAML = this.configSAML.bind(this);
        this.configGoogleOAuth = this.configGoogleOAuth.bind(this);
        this.idChange = this.idChange.bind(this);
        this.idConfirm = this.idConfirm.bind(this);
        this.samlLoginEndpointChange = this.samlLoginEndpointChange.bind(this);
        this.idpEntityIdChange = this.idpEntityIdChange.bind(this);
        this.publicX509CertificateChange = this.publicX509CertificateChange.bind(this);
        this.configureSAML = this.configureSAML.bind(this);
        this.enableSAML = this.enableSAML.bind(this);
        this.enableGoogleOAuth = this.enableGoogleOAuth.bind(this);
        this.tesultsPublicKeyDownload = this.tesultsPublicKeyDownload.bind(this);
        this.changeTesultsLogin = this.changeTesultsLogin.bind(this);
        this.changeEmailChange = this.changeEmailChange.bind(this);
        this.changeProjectCreation = this.changeProjectCreation.bind(this);
        this.saveOptions = this.saveOptions.bind(this);
        this.changeGoogleOAuthDomainAccess = this.changeGoogleOAuthDomainAccess.bind(this);
        this.removeGoogleOAuthDomainAccess = this.removeGoogleOAuthDomainAccess.bind(this);
        this.addGoogleOAuthDomainAccess = this.addGoogleOAuthDomainAccess.bind(this);
        this.saveGoogleOAuthDomainAccess = this.saveGoogleOAuthDomainAccess.bind(this);
        this.changeId = this.changeId.bind(this);
        this.project = this.project.bind(this)
    }
    
    componentDidMount () {
        this.getSSO();
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }
    
    
    getSSO () {
        if (this.project() === undefined) { return }
        this.setState({loading: true});
        Request.get("/ssoForRef", {ref: this.project().id}, (err, data) => {
            if (err) {
                this.setState({error: "Error fetching SSO data.", loading: false});
            } else {
                let samlLoginEndpoint = "";
                let idpEntityId = "";
                let publicX509Certificate = "";
                let tesultsLogin = true;
                let emailChange = true;
                let projectCreation = true;
                let googleOAuthDomainAccess = [];
                if (data.sso !== undefined) {
                    if (data.sso.samlLoginEndpoint !== undefined) {
                        samlLoginEndpoint = data.sso.samlLoginEndpoint;
                    }
                    if (data.sso.idpEntityId !== undefined) {
                        idpEntityId = data.sso.idpEntityId;
                    }
                    if (data.sso.publicX509Certificates !== undefined) {
                        if (data.sso.publicX509Certificates.length > 0) {
                            publicX509Certificate = data.sso.publicX509Certificates[0];
                        }
                    }
                    if (data.sso.tesultsLogin !== undefined) {
                        tesultsLogin = data.sso.tesultsLogin;
                    }
                    if (data.sso.emailChange !== undefined) {
                        emailChange = data.sso.emailChange;
                    }
                    if (data.sso.projectCreation !== undefined) {
                        projectCreation = data.sso.projectCreation;
                    }
                    if (data.sso.googleOAuthDomainAccess !== undefined) {
                        googleOAuthDomainAccess = data.sso.googleOAuthDomainAccess;
                    }
                }
                this.setState({ sso: data.sso, 
                                samlLoginEndpoint: samlLoginEndpoint,
                                idpEntityId: idpEntityId,
                                publicX509Certificate: publicX509Certificate,
                                tesultsLogin: tesultsLogin,
                                emailChange: emailChange,
                                projectCreation: projectCreation,
                                googleOAuthDomainAccess: googleOAuthDomainAccess,
                                loading: false
                            });
            }
        });
    }

    backToStart () {
        this.setState({state: null, error: ""});
    }

    configId (nextState) {
        console.log('configId nextState: ' + nextState)
        this.setState({state: "configId", nextState: nextState});
    }

    configSAML () {
        this.setState({state: "configSAML"});
    }

    configGoogleOAuth () {
        this.setState({state: "configGoogleOAuth"});
    }

    idChange (e) {
        this.setState({id: e.target.value});
    }

    validId (id) {
        
        let d = {};
        d['a'] = true;
        d['b'] = true;
        d['c'] = true;
        d['d'] = true;
        d['e'] = true;
        d['f'] = true;
        d['g'] = true;
        d['h'] = true;
        d['i'] = true;
        d['j'] = true;
        d['k'] = true;
        d['l'] = true;
        d['m'] = true;
        d['n'] = true;
        d['o'] = true;
        d['p'] = true;
        d['q'] = true;
        d['r'] = true;
        d['s'] = true;
        d['t'] = true;
        d['u'] = true;
        d['v'] = true;
        d['w'] = true;
        d['x'] = true;
        d['y'] = true;
        d['z'] = true;
        d['0'] = true;
        d['1'] = true;
        d['2'] = true;
        d['3'] = true;
        d['4'] = true;
        d['5'] = true;
        d['6'] = true;
        d['7'] = true;
        d['8'] = true;
        d['9'] = true;
        d['-'] = true;
    
        if (typeof(id) !== 'string'){
            return false;
        }
        for (let i = 0; i < id.length; i++) {
            let c = id.charAt(i);
            if (d[c] === undefined) {
                return false;
            }
        }
        return true;
    }

    idConfirm () {
        if (this.validId(this.state.id) !== true) {
            this.setState({error: 'The id must contain no spaces and consist of lowercase letters, numbers and hypens only (a-z, 0-9, -).'});
            return;
        }
        if (this.state.state === "changeId") {
            this.setState({loading: true});
            Request.post("/ssoIdChange", {id: this.state.id, sso: this.state.sso}, (err, data) => {
                if (err) {
                    this.setState({error: err, loading: false});
                } else {
                    this.setState({sso: data.sso, state: this.state.nextState, id: data.sso.id, loading: false});
                }
            });
        } else {
            if (this.project() === undefined) { return }
            this.setState({loading: true});
            Request.post("/ssoId", {id: this.state.id, projectId: this.project().id}, (err, data) => {
                if (err) {
                    this.setState({error: err, loading: false});
                } else {
                    this.setState({sso: data.sso, state: this.state.nextState, loading: false});
                }
            });
        }
    }

    samlLoginEndpointChange (e) {
        this.setState({samlLoginEndpoint: e.target.value});
    }

    idpEntityIdChange (e) {
        this.setState({idpEntityId: e.target.value});
    }

    publicX509CertificateChange(e) {
        this.setState({publicX509Certificate: e.target.value});
    }

    configureSAML () {
        if (this.project() === undefined) { return }
        this.setState({loading: true});
        Request.post("/samlConfig", {sso: this.state.sso, projectId: this.project().id, samlLoginEndpoint: this.state.samlLoginEndpoint, publicX509Certificate: this.state.publicX509Certificate, idpEntityId: this.state.idpEntityId}, (err, data) => {
            if (err) {
                this.setState({error: "Error updating SSO.", loading: false});
            } else {
                this.setState({sso: data.sso, loading: false});
            }
        });
    }

    enableSAML (enable) {
        this.setState({loading: true});
        Request.post("/samlEnable", {sso: this.state.sso, enable: enable}, (err, data) => {
            if (err) {
                this.setState({error: "Error enabling SSO.", loading: false});
            } else {
                this.setState({sso: data.sso, loading: false});
            }
        });
    }

    configureGoogleOAuth () {
        if (this.project() === undefined) { return }
        this.setState({loading: true});
        Request.post("/googleOAuthConfig", {sso: this.state.sso, projectId: this.project().id}, (err, data) => {
            if (err) {
                this.setState({error: "Error updating SSO.", loading: false});
            } else {
                this.setState({sso: data.sso, loading: false});
            }
        });
    }

    enableGoogleOAuth (enable) {
        this.setState({loading: true});
        Request.post("/googleoauthEnable", {sso: this.state.sso, enable: enable}, (err, data) => {
            if (err) {
                this.setState({error: "Error enabling SSO.", loading: false});
            } else {
                this.setState({sso: data.sso, loading: false});
            }
        });
    }

    saveOptions () {
        this.setState({loading: true});
        Request.post("/ssoOptions", {sso: this.state.sso, tesultsLogin: this.state.tesultsLogin, emailChange: this.state.emailChange, projectCreation: this.state.projectCreation}, (err, data) => {
            if (err) {
                this.setState({error: "Error updating SSO.", loading: false});
            } else {
                let tesultsLogin = true;
                let emailChange = true;
                let projectCreation = true;
                if (data.sso.tesultsLogin !== undefined) {
                    tesultsLogin = data.sso.tesultsLogin;
                }
                if (data.sso.emailChange !== undefined) {
                    emailChange = data.sso.emailChange;
                }
                if (data.sso.projectCreation !== undefined) {
                    projectCreation = data.sso.projectCreation;
                }
                this.setState({sso: data.sso, loading: false, tesultsLogin: tesultsLogin, emailChange: emailChange, projectCreation: projectCreation});
            }
        });
    }
    
    saveGoogleOAuthDomainAccess () {
        this.setState({loading: true});
        let data = {sso: this.state.sso, googleOAuthDomainAccess: this.state.googleOAuthDomainAccess}
        console.log(data)
        Request.post("/googleOAuthDomainAccess", {sso: this.state.sso, googleOAuthDomainAccess: this.state.googleOAuthDomainAccess}, (err, data) => {
            if (err) {
                this.setState({error: "Error updating SSO.", loading: false});
            } else {
                let googleOAuthDomainAccess = [];
                if (data.sso.googleOAuthDomainAccess !== undefined) {
                    googleOAuthDomainAccess = data.sso.googleOAuthDomainAccess;
                }
                this.setState({sso: data.sso, loading: false, googleOAuthDomainAccess: googleOAuthDomainAccess, domainChange: false});
            }
        });
    }

    tesultsPublicKeyDownload () {
        this.setState({loading: true});
        Request.getData("/samlPublicKeyDownload", {sso: this.state.sso.ref}, (err, data) => {
            if (err) {
                console.log(err)
                this.setState({error: "Error downloading public key.", loading: false});
            } else {
                const file = new Blob([data], {type: 'text'});
                let element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = "publickey.cer";
                element.click();
                this.setState({loading: false});
            }
        });
    }

    changeId () {
        this.setState({state: "changeId", nextState: null});
    }

    changeTesultsLogin (e) {
        this.setState({tesultsLogin: e.target.checked});
    }

    changeEmailChange (e) {
        this.setState({emailChange: e.target.checked});
    }

    changeProjectCreation (e) {
        this.setState({projectCreation: e.target.checked});
    }

    changeGoogleOAuthDomainAccess (e, index) {
        if (index < this.state.googleOAuthDomainAccess.length) {
            let domainAccess = this.state.googleOAuthDomainAccess;
            domainAccess[index] = e.target.value;
            this.setState({googleOAuthDomainAccess: domainAccess, domainChange: true});
        }
    }

    removeGoogleOAuthDomainAccess (index) {
        let domainAccess = this.state.googleOAuthDomainAccess;
        domainAccess.splice(index, 1);
        this.setState({googleOAuthDomainAccess: domainAccess, domainChange: true});
    }

    addGoogleOAuthDomainAccess () {
        let domainAccess = this.state.googleOAuthDomainAccess;
        domainAccess.push("");
        this.setState({googleOAuthDomainAccess: domainAccess, domainChange: true});
    }
    
    render () {
        if (this.state.loading === true) {
            return <Loading/>
        } else if (this.state.state === null) {
            let samlLink = <button className="btn-cancel" onClick={() => this.configId("configSAML")}>Configure SAML SSO (Currently Disabled)</button>
            let googleLink = <button className="btn-cancel" onClick={() => this.configId("configGoogleOAuth")}>Configure Google OAuth (Currently Disabled)</button>
            if (this.state.sso !== undefined) {
                if (this.state.sso.samlEnabled === true) {
                    samlLink = <button className="btn-confirm" onClick={this.configSAML}>Configure SAML SSO (Currently Enabled)</button>
                } else {
                    samlLink = <button className="btn-cancel" onClick={this.configSAML}>Configure SAML SSO (Currently Disabled)</button>
                }
                if (this.state.sso.googleOAuthEnabled === true) {
                    googleLink = <button className="btn-confirm" onClick={this.configGoogleOAuth}>Configure Google OAuth (Currently Enabled)</button>
                } else {
                    googleLink = <button className="btn-cancel" onClick={this.configGoogleOAuth}>Configure Google OAuth (Currently Disabled)</button>
                }
            }

            let options = <div className="mb-3">
                            <h4>When single sign-on is enabled:</h4>
                            <input type="checkbox" onChange={this.changeTesultsLogin} checked={this.state.tesultsLogin} aria-label="Allow tesults login"/>
                            <span className="ml-3">Allow login with Tesults</span>
                            <br/>
                            <input type="checkbox" onChange={this.changeEmailChange} checked={this.state.emailChange} aria-label="Allow email change"/>
                            <span className="ml-3">Allow email address change</span>
                            <br/>
                            <input type="checkbox" onChange={this.changeProjectCreation} checked={this.state.projectCreation} aria-label="Allow project creation"/>
                            <span className="ml-3">Allow Tesults project creation</span>
                        </div>
            let saveChanges = <span></span>
            if (this.state.sso !== undefined) {
                let savedTesultsLogin = this.state.sso.tesultsLogin === undefined ? true: this.state.sso.tesultsLogin;
                let savedEmailChange = this.state.sso.emailChange === undefined ? true: this.state.sso.emailChange;
                let savedProjectCreation = this.state.sso.projectCreation === undefined ? true: this.state.sso.projectCreation;
                if (savedTesultsLogin !== this.state.tesultsLogin || 
                    savedEmailChange !== this.state.emailChange ||
                    savedProjectCreation !== this.state.projectCreation) {
                    saveChanges = <button className="btn-confirm" onClick={this.saveOptions}>Save changes</button>
                }
            } else {
                options = <span></span>
            }

            let changeId = <span></span>
            if (this.state.sso !== undefined) {
                changeId = <div>
                    <h4>Login identfier</h4>
                    <p><b>{this.state.sso.id}</b></p>
                    <p>Login: https://www.tesults.com/login/{this.state.sso.id}</p>
                    <button type="button" className="btn btn-confirm mt-3" onClick={this.changeId}>Change</button>
                </div>
            }

            return (
                <div className="p-4 whitebg" style={{"display":"flex"}}>
                    <div style={{"flex":"2"}}>
                        <h4>Single sign-on (SSO)</h4>
                        <p className="mt-3">Enable single sign-on (SSO) for your project. Tesults supports the following options for single sign-on:</p>
                        <h5>SAML 2.0</h5>
                        <p className="mt-3">This option should be used if your organization uses an identify provider such as Microsoft's Active Directory, Azure AD, Okta, OneLogin, LastPass, Bitium, SecureAuth and other identity providers supporting SAML 2.0.</p>
                        {samlLink}
                        <h5 className="mt-5">Google OAuth</h5>
                        <p className="mt-3">If your organization uses Google Workspace you can enable sign in using Google OAuth.</p>
                        {googleLink}
                        <br/>
                        <br/>
                    </div>
                    <div style={{"flex":"1"}}>
                        {options}
                        {saveChanges}
                        {changeId}
                    </div>
                </div>
            );
        } else if (this.state.state === "changeId") {
            let error = "";
            if (this.state.error !== undefined) {
                error = this.state.error.toString();
            }
            return (
                <div className="p-4 whitebg">
                    <h4 className="neutral3">Change login identifier</h4>
                    <p><span className="bold neutral3 font15">IMPORTANT WARNING:</span> If you change your login identifier you will need to distribute your new login url to all of your members. If you are using SAML based SSO with identify provider initiated login then you will also need to make configuration changes within your identify provider, specifically the default relay state will need changing.</p>
                    <label className="neutral4 font14" htmlFor="loginIdentifier">Login Identifier</label>
                    <br/>
                    <input type="text" className="form-control tr-input" id="loginIdentifier" name="loginIdentifier" style={{"backgroundColor" : "#F5F8FA" }} onChange={this.idChange}/>
                    <br/>
                    <span className="accenta3">{error}</span>
                    <br/>
                    <button type="button" className="btn btn-confirm mt-3" onClick={() => this.idConfirm()}>Confirm</button>
                    <br/>
                    <button type="button" className="btn btn-cancel mt-5" onClick={() => this.backToStart()}>Back</button>
                </div>
            );
        } else if (this.state.state === "configId") {
            return (
                <div className="p-4 whitebg">
                    <h4 className="neutral3">Create a login identifier</h4>
                    <p>The login identifier will form a part of the unique URL used for single sign-on for Tesults: https://www.tesults.com/login/{'<'}login-identifier{'>'}</p>
                    <label className="neutral4 font14" htmlFor="loginIdentifier">Login Identifier</label>
                    <br/>
                    <input type="text" className="form-control tr-input" id="loginIdentifier" name="loginIdentifier" style={{"backgroundColor" : "#F5F8FA" }} onChange={this.idChange}/>
                    <br/>
                    <span className="accenta3">{this.state.error}</span>
                    <br/>
                    <button type="button" className="btn btn-confirm mt-3" onClick={() => this.idConfirm()}>Confirm</button>
                    <br/>
                    <button type="button" className="btn btn-cancel mt-5" onClick={() => this.backToStart()}>Back</button>
                </div>
            );
        } else if (this.state.state === "configSAML") {
            let configButton = <button type="button" className="btn btn-confirm mt-3" onClick={() => this.configureSAML()}>Save Configuration</button>
            let enableButton = <button type="button" className="btn btn-confirm mt-3" onClick={() => this.enableSAML(true)}>Enable SAML SSO</button>

            if (this.state.samlLoginEndpoint === "" || this.state.idpEntityId === "" || this.state.publicX509Certificate === "") {
                configButton = <button type="button" className="btn btn-confirm mt-3" onClick={() => this.configureSAML()} disabled>Save Configuration</button>
                enableButton = <button type="button" className="btn btn-confirm mt-3" onClick={() => this.enableSAML(true)} disabled>Enable SAML SSO</button>
            }

            if (this.state.sso !== undefined) {
                if (this.state.sso.samlEnabled === true) {
                    enableButton = <button type="button" className="btn-cancel mt-3" onClick={() => this.enableSAML(false)}>Disable SAML SSO</button>
                }
            }

            return (
                <div style={{"display":"flex"}} className="p-4 whitebg">
                    <div style={{"flex":"1"}}>
                        <h4>Configure SAML single sign-on (SSO)</h4>
                        <p className="font14 neutral4">Get the values for the three fields below from your identity provider's interface. Setup the saml assert endpoint, audience and attribute statements within your identify provider as described.</p>
                        <label className="font14 neutral4" htmlFor="samlLoginEndpoint">Login Endpoint</label>
                        <br/>
                        <input type="text" className="form-control tr-input" id="samlLoginEndpoint" name="samlLoginEndpoint" style={{"backgroundColor" : "#F5F8FA" }} onChange={this.samlLoginEndpointChange} value={this.state.samlLoginEndpoint}/>
                        <br/>
                        <label className="font14 neutral4 mt-3" htmlFor="idpEntityId">Identity Provider Entity ID</label>
                        <br/>
                        <input type="text" className="form-control tr-input" id="idpEntityId" name="idpEntityId" style={{"backgroundColor" : "#F5F8FA" }} onChange={this.idpEntityIdChange} value={this.state.idpEntityId}/>
                        <br/>
                        <label className="font14 neutral4 mt-3" htmlFor="publicX509Certificate">Public X.509 Certificate</label>
                        <br/>
                        <textarea rows="20" cols="33" type="text" style={{"white-space": "pre-wrap", "backgroundColor" : "#F5F8FA"}} className="font12 mono form-control neutral4border" id="publicX509Certificate" name="publicX509Certificate" onChange={this.publicX509CertificateChange} value={this.state.publicX509Certificate}></textarea>
                        <br/>
                        {configButton}
                        <br/>
                        {enableButton}
                        <br/>
                        <button type="button" className="btn btn-cancel mt-5" onClick={() => this.backToStart()}>Back</button>
                    </div>
                    <div style={{"flex":"1"}}>
                        <h5>Setup these attribute statements within your single sign-on identify provider's interface</h5>
                        <p className="font14"><span className="neutral4 bold">email</span> - map to email of user</p>
                        <p className="font14"><span className="neutral4 bold">firstName</span> - map to first name of user</p>
                        <p className="font14"><span className="neutral4 bold">lastName</span> - map to last name of user</p>
                        <h5>Saml assert</h5>
                        <p className="font14">Set the login endpoint (saml assert url) to <b>https://www.tesults.com/samlassert</b> within your identity provider's interface.</p>
                        <h5>Audience</h5>
                        <p className="font14">Set the 'audience' value to be the same as the identifier you have selected (<b>{this.state.sso.id}</b>) within your identity provider's interface.</p>
                        <h5>Tesults single sign-on with SAML</h5>
                        <p className="font14">Your team members must use this login link to use single sign-on: <b>https://www.tesults.com/login/{this.state.sso.id}</b></p>
                        <h5>Identify Provider Initiated</h5>
                        <p className="font14">If you want to allow users to sign in and open Tesults automatically from within your single sign-on identify provider's interface you must configure the default <b>relay state</b> to be: <b>{this.state.sso.id}</b></p>
                        <h5>Default Role</h5>
                        <p className="font14">The default role for new sign in will be level 2 (Moderator)</p>
                        <h5>Advanced</h5>
                        <p className="font14">If you require an end-to-end encryption key for your identity provider you can configure this within your identify service provider's interface and utilize Tesult's public encryption key.</p>
                        <div><button className="btn-cancel" onClick={this.tesultsPublicKeyDownload}>Download public key</button></div>
                    </div>
                </div>
            );
        } else if (this.state.state === "configGoogleOAuth") {
            //let configButton = <button type="button" className="btn btn-confirm mt-3" onClick={() => this.configureGoogleOAuth()}>Save Configuration</button>
            let enableButton = <button type="button" className="btn btn-confirm mt-3" onClick={() => this.enableGoogleOAuth(true)}>Enable Google SSO</button>
            let domainAccess = <span></span>
            let saveDomainAccessChanges = <span></span>
            if (this.state.sso !== undefined) {
                if (this.state.sso.googleOAuthEnabled === true) {
                    enableButton = <button type="button" className="btn-cancel mt-3" onClick={() => this.enableGoogleOAuth(false)}>Disable Google SSO</button>
                }

                let domains = []
                for (let i = 0; i < this.state.googleOAuthDomainAccess.length; i++) {
                    domains.push(<div><input key={"domain " + i} type="text" className="mt-3 tr-input form-control" id={"domain " + i} name={"domain " + 1} style={{"backgroundColor" : "#F5F8FA" }} value={this.state.googleOAuthDomainAccess[i]} onChange={(e) => this.changeGoogleOAuthDomainAccess(e, i)}/></div>)
                    domains.push(<button type="button" className="mt-2 btn-cancel" onClick={() => this.removeGoogleOAuthDomainAccess(i)}>Remove</button>)
                }
                if (this.state.domainChange === true) {
                    saveDomainAccessChanges = <button className="btn-confirm" onClick={this.saveGoogleOAuthDomainAccess}>Save changes</button>
                }
                domainAccess = <div>
                    {domains}
                    <br/>
                    <br/>
                    <button type="button" className="btn btn-confirm" onClick={() => this.addGoogleOAuthDomainAccess()}>Add domain</button>
                    <br/>
                    <br/>
                    {saveDomainAccessChanges}
                </div>
            }
            return (
                <div className="p-4 whitebg">
                    <h4>Configure Google OAuth</h4>
                    <p>If you enable sign-in with Google OAuth, authentication takes place by Google, but you choose to authorize access to your project in two ways:</p>
                    <ol>
                        <li>Permit only specific users access by adding them to your project in 'Team Members'.</li>
                        <li>Permit anyone with an email address matching particular domains access.</li>
                    </ol>
                    {domainAccess}
                    <br/>
                    <p>If you do not add any domains and you do not grant specific users access in the 'Team Members' then even if Google OAuth is enabled no one will have access to the project.</p>
                    <p>Note: if you remove a domain, new team members will no longer be able to login with Google OAuth on the domain, however existing team members will continue to have access, to prevent this delete these team members from 'Team Members' in the config menu or disable Google SSO entirely.</p>
                    <br/>
                    {enableButton}
                    <br/>
                    <button type="button" className="btn btn-cancel mt-5" onClick={() => this.backToStart()}>Back</button>
                    <hr/>
                    <h4>Tesults sign-on with Google OAuth</h4>
                    <p>Your team members must use this link to login: <b>https://www.tesults.com/login/{this.state.sso.id}</b></p>
                    <br/>
                    <h4>Default Role</h4>
                    <p>The default role for new sign in will be level 2 (Moderator)</p>
                </div>
            );
        } else {
            return (
                <div className="p-4 whitebg">
                    <h4>Single sign-on (SSO)</h4>
                    <p>Invalid State</p>
                </div>
            );
        }
    }
}

export default SSO;