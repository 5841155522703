/*global */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Analytics from './Analytics'
import ListGroupButton from './ListGroupButton'
import ListSelect from './ListSelect'
import Request from './Request'
import FieldEdit from './FieldEdit'
import Collapse from './Collapse'

class ListGroup extends Component {
    constructor () {
        super();
        this.addRef = React.createRef();
        this.editRef = React.createRef();
        this.state = {state: undefined, collapsed: false, newGroup: "", editGroup: "", listGroupProxy: undefined};
        this.collapseToggle = this.collapseToggle.bind(this);
        this.changeNewGroup = this.changeNewGroup.bind(this);
        this.changeEditGroup = this.changeEditGroup.bind(this);
        this.confirmNewGroup = this.confirmNewGroup.bind(this);
        this.confirmEditGroup = this.confirmEditGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.listsWithoutGroups = this.listsWithoutGroups.bind(this);
        this.addMenu = this.addMenu.bind(this)
        this.editMenu = this.editMenu.bind(this)
    }

    collapseToggle () {
        this.setState({collapsed: this.state.collapsed === true ? false: true});
    }

    changeNewGroup (e) {
        this.setState({newGroup: e.target.value});
    }

    changeEditGroup (e) {
        this.setState({editGroup: e.target.value});
    }

    confirmNewGroup (value) {
        let projectId = this.props.project.id;
        let group = [];
        if (this.props.node.path === undefined) {
            group.push(value);
        } else {
            this.props.node.path.forEach(function (g) {
                group.push(g);
            });
            group.push(value);
        }
        this.setState({loading: true}, () => this.props.overlay(undefined));
        Request.post("/listGroupCreate",  {id: projectId, group: group}, (err, data) => {
            if (err) {
                window.scrollTo(0,0);
                this.setState({loading: false});
            } else {
                Analytics.event("ListGroupCreated");
                let newList = data.list;
                this.setState({state: undefined, loading: false}, () => this.props.createdListGroup(newList));    
            }
        });
    }

    confirmEditGroup (value) {
        let projectId = this.props.project.id;
        let group = [];
        let newGroupName = value
        if (newGroupName === undefined || newGroupName === "") {
            return;
        }
        if (this.props.node.path !== undefined) {
            this.props.node.path.forEach(function (g) {
                group.push(g);
            });
        }
        this.setState({loading: true});
        Request.post("/listGroupNameChange", {id: projectId, group: group, name: newGroupName}, (err, data) => {
            if (err) {
                window.scrollTo(0,0);
                this.setState({loading: false});
            } else {
                window.scrollTo(0,0);
                Analytics.event("ListGroupNameChanged");
                let allListsUpdated = data.lists;
                this.setState({state: undefined, loading: false}, () => {this.props.allListsUpdated(allListsUpdated); this.props.overlay(undefined)});
            }
        });
    }

    deleteGroup (groupProxy) {
        this.setState({loading: true}, () => this.props.overlay(undefined));
        Request.post("/listGroupDelete", {id: groupProxy.id, created: groupProxy.created, group: groupProxy.group}, (err, data) => {
            if (err) {
                window.scrollTo(0,0);
                this.setState({loading: false});
            } else {
                window.scrollTo(0,0);
                Analytics.event("ListGroupDeleted");
                this.setState({state: undefined, loading: false}, () => this.props.deletedListGroup(groupProxy));
            }
        });
    }

    listsWithoutGroups () {
        let lists = [];
        if (this.props.lists !== undefined) {
            for (let i = 0; i < this.props.lists.length; i++) {
                let list = this.props.lists[i];
                if (list.groupProxy === false) {
                    if (list.group === undefined) {
                        lists.push (<ListSelect key={i} listChange={this.props.listChange} index={i} list={list}/>);
                    } else {
                        if (list.group.length === 0) {
                            lists.push (<ListSelect key={i} listChange={this.props.listChange} list={list}/>);
                        }
                    }
                }
            }
        }
        return lists;
    }

    addMenu (groupProxy) {
        this.props.overlay(
            <div className='p-4'>
                <div>
                    <button className='btn-transparent' onClick={() => {
                        this.props.overlay(
                            <FieldEdit
                                title="New group name"
                                defaultValue={this.state.newGroup}
                                save={this.confirmNewGroup}
                                cancel={() => this.props.overlay(undefined)}
                            />
                            ,
                            this.editRef
                        )
                    }}>
                        <div className='flex-row mb-3'>
                            <div className='mr-3'>
                                <img src="/img/add-sub-group.svg" width="16" height="16" alt="add-sub-group"/>
                            </div>
                            <div className='font14 neutral3'>
                                Add sub-group
                            </div>
                        </div>
                    </button>
                </div>

                <div>
                    <button className='btn-transparent' onClick={() => {
                        this.props.setGroupProxy(groupProxy)
                        this.props.overlay(
                            <FieldEdit
                                title="New list name"
                                defaultValue=""
                                save={this.props.createListConfirm}
                                cancel={() => this.props.overlay(undefined)}
                            />
                            ,
                            this.editRef
                        )
                    }}>
                        <div className='flex-row mb-3'>
                            <div className='mr-3'>
                                <img src="/img/add-sub-item.svg" width="16" height="16" alt="add-list"/>
                            </div>
                            <div className='font14 neutral3'>
                                Add list
                            </div>
                        </div>
                    </button>
                </div> 
            </div>, 
            this.addRef
        )
    }

    editMenu (groupProxy, deleteGroupEnabled) {
        this.props.overlay(
            <div className='p-4'>
                <div>
                <button className='btn-transparent' onClick={() => {
                    this.props.overlay(
                        <FieldEdit
                            title="Group name"
                            defaultValue={this.state.editGroup}
                            save={this.confirmEditGroup}
                            cancel={() => this.props.overlay(undefined)}
                        />
                        ,
                        this.editRef
                    )
                }}>
                    <div className='flex-row mb-3'>
                        <div className='mr-3'>
                            <img src="/img/edit-item.svg" width="16" height="16" alt="add-sub-group"/>
                        </div>
                        <div className='font14 neutral3'>
                            Edit name
                        </div>
                    </div>
                </button>
                </div>
                {
                    deleteGroupEnabled ?
                    <div>
                    <button className='btn-transparent' onClick={() => {this.deleteGroup(groupProxy)}}>
                        <div className='flex-row'>
                            <div className='mr-3'>
                                <img src="/img/delete-item.svg" width="16" height="16" alt="add-list"/>
                            </div>
                            <div className='font14 neutral3'>
                                Delete group
                            </div>
                        </div>
                    </button>
                    </div>
                    :
                    <div></div>
                }
            </div>
            , 
            this.editRef
        )
    }

    render () {
        let indentTotal = 32;
        let indentListExtra = 32;
        for (let i = 1; i < this.props.node.level; i++) {
            indentTotal += indentListExtra;
        }
        
        let groupProxy = undefined;
        let lists = [];
        if (this.props.lists !== undefined) {
            // find group proxy and populate lists under this group
            for (let l = 0; l < this.props.lists.length; l++) {
                let list = this.props.lists[l];
                if (list.group !== undefined) {
                    if (list.group.length === this.props.node.path.length) {
                        let match = true;
                        for (let i = 0; i < list.group.length; i++) {
                            if (list.group[i] !== this.props.node.path[i]) {
                                match = false;
                            }
                        }
                        if (match === true) {
                            if (list.groupProxy === true) {
                                groupProxy = list;
                            } else {
                                //let listLink = <span className="tr-link-neutral1" onClick={() => this.props.listChange(l)}>{list.label}</span>
                                lists.push(<ListSelect key={lists.length} indent={indentTotal} index={l} listChange={this.props.listChange} list={list}/>)
                            }
                        }
                    }
                }
            }
        }

        let folderImage = <img src="/img/list-folder.svg" alt="folder"/>
        let collapseImage = <Collapse collapse={this.state.collapsed}/>
        let collapse = 
            <div style={{"marginLeft": (indentTotal - 32) + "px", "display":"flex", "alignItems":"center"}} data-target={"." + this.props.node.renderId}>
                <div className='mr-3'>
                    <button className="btn-transparent" onClick={() => this.collapseToggle()}>{folderImage}</button>
                </div>
                <div className='mr-3'>
                    <button className="btn-transparent" onClick={() => this.collapseToggle()}>{collapseImage}</button>
                </div>
                <div>
                    <button className="btn-transparent mr-3" onClick={() => this.collapseToggle()}><span className='font16 bold'>{this.props.node.val}</span></button>
                </div>
            </div>
        if (this.props.node.nodes === undefined && lists.length === 0) {
            //collapse = <span></span>
        }
        if (this.props.node.nodes !== undefined) {
            if (this.props.node.nodes.length > 0 && lists.length === 0) {
                //collapse = <span></span>
            }
        }
        
        let newGroupEntry = <span></span>
        let editGroupEntry = <span></span>
        let deleteGroupEnabled = true

        if (this.state.state === "editGroup") {
            editGroupEntry = <div>
                                <h5 className="mb-1">Edit Group Name</h5>
                                <input type="text" className="mb-3 tr-input form-control" value={this.state.editGroup} onChange={(e) => this.changeEditGroup(e)} required/>
                                <div style={{"display":"flex"}}>
                                    <button className="btn-confirm" onClick={() => this.confirmEditGroup()}>Confirm</button>
                                    <button className="btn-cancel" onClick={() => this.setState({state: undefined})}>Cancel</button>
                                </div>
                            </div>
        }

        if (lists.length > 0) {
            deleteGroupEnabled = false
        } else {
            if (this.props.node.nodes !== undefined) {
                if (this.props.node.nodes.length > 0) {
                    deleteGroupEnabled = false
                }
            }
        }

        if (groupProxy === undefined) {
            deleteGroupEnabled = false
        }

        if (this.props.node.val === undefined) {
            collapse = <span></span>
        }

        if (this.state.collapsed === true) {
            lists = [];
        }
        
        let display = 
            <div>
                <div style={{"minHeight":"64px"}} className="list-actions case-group-border neutral8bg">
                    <div className="list-action">
                        {collapse}
                    </div> 
                    <div style={{"marginLeft":"auto", "display":"flex"}}>
                        <div ref={this.addRef}>
                            <button className="btn-transparent mr-1" onClick={() => this.addMenu(groupProxy)}><img src="/img/add.svg" alt="add" width="14px" height="14px"/></button>
                        </div>
                        <div ref={this.editRef}>
                            <button className="btn-transparent mr-1" onClick={() => this.editMenu(groupProxy, deleteGroupEnabled)}><img src="/img/three-dots.svg" alt="edit" width="20px" height="20px"/></button>
                        </div>
                    </div>
                </div>
                {lists}
            </div>

        if (this.props.node.val === undefined) { // top level group and list add
            display = 
            <div>
                <div className="mb-5" style={{"display":"flex", "alignItems":"center"}}>
                    <div className='mr-3' ref={this.addRef}>
                        <ListGroupButton 
                            onClick={() => 
                                {
                                    this.props.setGroupProxy(groupProxy)
                                    this.props.overlay(
                                        <FieldEdit
                                            title="New list name"
                                            defaultValue=""
                                            save={this.props.createListConfirm}
                                            cancel={() => this.props.overlay(undefined)}
                                        />
                                        ,
                                        this.addRef
                                    )
                                }
                            } 
                            label="Create List" 
                            icon={<img className="runSelector" src="/img/add-item.svg" alt="expanded" style={{"marginTop":"4px"}}  width="16" height="16"/>}
                        />
                    </div> 
                    <div className="ml-3" ref={this.editRef}>
                        <ListGroupButton 
                            onClick={() => 
                                {
                                    this.props.overlay(
                                        <FieldEdit
                                            title="New group name"
                                            defaultValue={this.state.newGroup}
                                            save={this.confirmNewGroup}
                                            cancel={() => this.props.overlay(undefined)}
                                        />
                                        ,
                                        this.editRef
                                    )
                                }
                            } 
                            label="Add Group" 
                            icon={<img className="runSelector" src="/img/add-group.svg" alt="expanded" style={{"marginTop":"4px"}} width="16" height="16"/>}
                        />
                    </div>
                    <div className='ml-4'>
                        <input className="search clickable" onChange={(e) => this.props.onSearchChange(e.target.value)} value={this.props.search} placeholder="Search for list"/>
                    </div>
                    <div style={{"marginLeft":"auto"}}>
                        {this.props.selectSide}
                    </div>
                </div>
                {this.listsWithoutGroups()}
                {lists}
            </div>
        }

        if (this.state.state === "addGroup") {
            display = 
            <div>
                <span><span>{collapse}</span></span>
                <span><span>{newGroupEntry} </span><br/></span>
                {lists}
            </div>
        }

        if (this.state.state === "editGroup") {
            display = 
            <div>
                <span><span>{collapse}</span><br/></span>
                <span><span>{editGroupEntry} </span><br/></span>
                {lists}
            </div>
        }

        if (this.state.loading === true) {
            display = 
            <div>
                <span><span>{collapse}</span><br/></span>
                <span><span className='font14 neutral4'>Updating...</span><br/></span>
                {lists}
            </div>
        }

        return (
            <div className="mt-2 mb-2">
                {display}
            </div>
        );
    }
};

export default ListGroup;