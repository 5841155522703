/*global*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Analytics from './Analytics';
import Request from './Request';

class DemoMain extends Component {
    constructor () {
        super();
        Analytics.event("Demo");
        this.state = {state: null, data: '{"results":{"cases":[{"name": "Test1", "desc": "Test 1 Description", "suite": "Suite A", "result":"pass"},{"name": "Test2", "desc": "Test 2 Description", "suite": "Suite C", "result":"pass"},{"name": "Test3", "desc": "Test 3 Description", "suite": "Suite B", "result":"fail"}]}, "target":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg5NWI2MGMyLTEwMDktNDU4ZS05ZmRhLTM0MmQ5NWEwMmU0OS0xNDc4NDAyNTc0NDE0IiwiZXhwIjo0MTAyNDQ0ODAwMDAwLCJ2ZXIiOiIwIiwic2VzIjoiZGJkM2MzNjctYTc4My00NDEyLWE3MzctZGViMjg2NGEzNGNjIiwidHlwZSI6InQifQ.9f3NNptRgV9C1VGtgSNgy5aTzsKEfd0G67ElWJ7efYQ"}', message: "", error: "", uploading: false};

        this.dataChange = this.dataChange.bind(this);
        this.submit = this.submit.bind(this);
    }
    
    componentDidMount() {
        window.scrollTo(0,0);
    }

    dataChange (e) {
        this.setState({data: e.target.value});
    }

    submit (e) {
        var dataParam;
        try {
            dataParam = JSON.parse(this.state.data);    
        } catch (ex) {
            this.setState({message:"", error: ex.message});
            return;
        }
        
        e.preventDefault();
        // validate here    
        Request.post("/results", dataParam, (err, data) => {
            if (err) {
                this.setState({message: "", error: data.error.message, uploading: false});
            } else {
                this.setState({message: <p>Uploaded successfully. <Link to="/results?demo=true">View the results.</Link></p>, error: "", uploading: false});
            }
        });
        
        this.setState({message: "Uploading - please wait.", error: "", uploading: true});
    }

    render () {
        return (
            <div>
                <div className="row">
                    <div className="col-12, col-sm-12, col-md-12, col-lg-12">
                        <p>You submit your results using either the REST API directly (<a href="https://www.tesults.com/docs/resultsapi">view API doc</a>) or alternatively you can use one of the API libraries provided for your programming language to make integration even easier (<a href="https://www.tesults.com/docs">docs</a>).</p>
                        <p>Here is an example of results data that is ready to be submitted, it is in the required JSON format already. Click the Submit button to post these results right now. You can edit it if you like, try changing the failing test to a pass or add more tests. Submit will fail if there is incorrect formatting so watch out for that.</p>
                        <p>The demo project includes the following targets: "iOS-debug", "iOS-release", "android-debug", "android-release", "windows-debug", "windows-release". The target token below is for iOS-debug.</p>
                        <br/>
                        <p><textarea rows="10" cols="40" onChange={this.dataChange} value={this.state.data}/></p>
                        {this.state.message}
                        <p className="accenta1">{this.state.error}</p>
                        <button type="button" onClick={this.submit} className="btn btn-confirm" disabled={this.state.uploading}>Submit</button>
                        <br/><br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DemoMain;