/*global*/
import React, { Component } from 'react';

class ResultsHeader6 extends Component {
    render () {
        window.scrollTo(0, 0);
        let title = "Runs"
        if (this.props.state === "view" || this.props.state === "edit") {
            if (this.props.runs !== undefined && this.props.runIndex !== undefined) {
                if (this.props.runIndex < this.props.runs.length) {
                    let run = this.props.runs[this.props.runIndex]
                    // Set title
                    title = run.label
                }
            }
        }
        return (
            <div className="results-header3 whitebg mb-4">
                <div className="results-header5-title">
                    {
                        this.props.state === "view" ?
                        <div style={{"display":"flex", "flexDirection":"column"}}>
                            <div className="header-title overflow-ellipsis-no-wrap">{title}</div>
                        </div>
                        :
                        <div className="header-title pt-4 pb-4">Runs</div>
                    }
                </div>
                <div className="results-header3-gap">
                    
                </div>
                <div className="results-header3-actions">
                    <div style={{"display": "flex"}}>
                        <div style={{"flex": "1"}}>
                            
                        </div>
                        <div style={{"flex": "1"}}>
                            
                        </div>
                        <div style={{"flex": "1"}}>
                            <button className="select" onClick={() => window.location.reload()}>
                                <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader6