/*global*/
import React, { Component } from 'react';
import Context from './Context';
import Cache from './Cache'

class ResultsViews extends Component {
    static contextType = Context;
    render () {
        let resultsUrl = "/results/rsp/view/results"
        let supplementalUrl = "/results/rsp/view/supplemental"
        let projects = this.context.projects
        let targets = this.context.targets
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        let targetIndex = Cache.getPreference(Cache.preference.targetIndex)
        if (targets !== undefined) {
            if (Array.isArray(targets)) {
                if (targetIndex >= targets.length) {
                    targetIndex = 0
                    Cache.setPreference(Cache.preference.targetIndex, 0)
                }
                if (targetIndex < targets.length) {
                    let target = targets[targetIndex]
                    resultsUrl += "/target/" + target.id + "-" + target.created
                    supplementalUrl += "/target/" + target.id + "-" + target.created
                }
            }
        } else {
            if (projects !== undefined) {
                if (Array.isArray(projects)) {
                    if (projectIndex >= projects.length) {
                        projectIndex = 0
                        Cache.setPreference(Cache.preference.projectIndex, 0)
                    }
                    if (projectIndex < projects.length) {
                        let project = projects[projectIndex]
                        resultsUrl += "/project/" + project.id
                        supplementalUrl += "/project/" + project.id
                    }
                }
            }
        }

        return (
            <div className="results-views">
                <div className="results-view">
                    <a href={resultsUrl} onClick={(e) => 
                        {
                            e.preventDefault();
                            this.props.resultsView();
                        }} 
                        className={"site-link " + ((this.props.view === "results") ? "view-active" : "view-inactive")}>
                        Results
                    </a>
                </div>
                <div className="supplemental-view">
                    <div className="results-view">
                        <a href={supplementalUrl} onClick={(e) => {
                                e.preventDefault();
                                this.props.supplementalView();
                            }} 
                            className={"site-link " + ((this.props.view === "supplemental") ? "view-active" : "view-inactive")}>
                            Smart Analysis
                        </a>
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsViews;