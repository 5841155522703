/*global */
import React, { Component } from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import { Helmet } from 'react-helmet';
import Loading from './Loading';
import Analytics from './Analytics';
import Request from './Request';
import Countries from './Countries';
import Constants from './Constants';
import EUVAT from './EUVAT';
import PaymentByInvoice from './PaymentByInvoice';

class Payment extends Component {
    constructor (props) {
        super(props);
        this.state = {
            state: null, 
            error: "", 
            plan: "", 
            card: null, 
            countryBased: "US",
            name: "", 
            addressLine1: "", 
            addressLine2: "", 
            addressCity:"", 
            addressState: "",
            addressZip: "",
            addressCountry: "US",
            uk_vat_number: "",
            vatId: "", 
            sca: undefined,
            scaApi: undefined,
            submitting: false, 
            eligible: false
        };
        this.countrySelect = this.countrySelect.bind(this);
        this.countrySelected = this.countrySelected.bind(this);
        this.payment = this.payment.bind(this);
        this.paymentNewCard = this.paymentNewCard.bind(this);
        this.paymentNewCardComplete = this.paymentNewCardComplete.bind(this);
        this.paymentExistingCardComplete = this.paymentExistingCardComplete.bind(this);
        this.paymentByInvoiceComplete = this.paymentByInvoiceComplete.bind(this)
        this.vatIdChange = this.vatIdChange.bind(this);
        this.addressLine1Change = this.addressLine1Change.bind(this);
        this.addressLine2Change = this.addressLine2Change.bind(this);
        this.addressCityChange = this.addressCityChange.bind(this);
        this.addressStateChange = this.addressStateChange.bind(this);
        this.addressZipChange = this.addressZipChange.bind(this);
        this.addressCountryChange = this.addressCountryChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.companyNameChange = this.companyNameChange.bind(this);
        this.countryBasedChange = this.countryBasedChange.bind(this);
        this.editPaymentDetails = this.editPaymentDetails.bind(this);
        this.paymentCardUpdate = this.paymentCardUpdate.bind(this);
        this.sca = this.sca.bind(this);
        this.scaComplete = this.scaComplete.bind(this);
        this.setupIntent = this.setupIntent.bind(this);
        this.cardElementChange = this.cardElementChange.bind(this);
        this.cardPaymentInputRender = this.cardPaymentInputRender.bind(this);
        this.ukVATNumberChange = this.ukVATNumberChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        if (this.props.type === "planChange" || this.props.type === "createProject") {
            if (this.props.project !== undefined) {
                if (this.props.project.customerId !== undefined) {
                    this.payment();
                } else {
                    this.countrySelect();
                }
            } else {
                this.countrySelect();
            }
        } else if (this.props.type === "paymentEdit") {
            this.editPaymentDetails();
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.paymentType !== prevProps.paymentType) {
            this.payment()
        }
    }

    countrySelect () {
        Analytics.event("CountrySelect");
        this.setState({state: "countrySelect"});
    }

    countrySelected () {
        let cb = this.state.countryBased;
        if (Constants.euCountry(cb)) {
            Analytics.event("VATIdRequired");
            this.setState({state: "vatIdRequired"});
        } /*else if (cb === "GB") {
            this.setState({state: "vatIdOptional"});
        }*/ else {
            Analytics.event("Payment");
            this.payment();   
        }
    }

    payment () {
        if (this.props.countrySelectionComplete !== undefined) {
            this.props.countrySelectionComplete();
        }
        Analytics.event("Payment");
        let customerId = undefined;
        if (this.props.project !== undefined) {
            customerId = this.props.project.customerId;
        }

        if (this.props.paymentType === "invoice" || this.props.payment === "invoice") {
            this.setState({state: "paymentByInvoice"})
        } else {
            Request.post("/getPaymentCard", {customerId: customerId}, (err, data) => {
                if (err) {
                    this.setState({error: "Unable to access payment cards."});
                } else {
                    let card = data.card;
                    if (card === undefined) {
                        Analytics.event("PayNewCard");
                        this.setState({state: "paymentNewCard"});   
                    } else {
                        Analytics.event("PayExistingCard");
                        this.setState({state: "paymentExistingCard", card: card});
                    }
                }
            });
        }
    }

    paymentNewCard () {
        Analytics.event("PayNewCard");
        this.setState({state: "paymentNewCard", card: null});  
    }

    paymentNewCardComplete (e) {
        e.preventDefault();
        this.setState({submitting:true});

        this.props.stripe.createToken({
            name: this.state.name,
            address_line1: this.state.addressLine1,
            address_line2: this.state.addressLine2,
            address_city: this.state.addressCity,
            address_state: this.state.addressState,
            address_zip: this.state.addressZip,
            address_country: this.state.addressCountry
        }).then(function(result) {
            if (result.error) {
                this.setState({error: result.error.message + " If you do not understand this message please contact help@tesults.com.", submitting: false});
            } else {
                let api = "/createProject";
                let data = {};
                data.stripeToken = result.token.id;
                data.name = this.props.name;
                data.orgName = this.props.orgName;
                data.product = this.props.product;
                console.log(data.product)
                if (this.props.plan !== undefined) {
                    data.product = this.props.plan.name;
                }
                data.interval = this.props.interval;
                data.activeUsers = this.props.activeUsers;
                data.minUsers = this.props.minUsers;
                data.selectedPlan = this.props.selectedPlan;
                data.billingCycle = this.props.billingCycle;

                data.customer_name = this.state.name;
                data.company_name = this.state.companyName;
                data.address_line1 = this.state.addressLine1;
                data.address_line2 = this.state.addressLine2;
                data.address_city = this.state.addressCity;
                data.address_state = this.state.addressState;
                data.address_zip = this.state.addressZip;
                data.address_country = this.state.addressCountry;
                data.uk_vat_number = this.state.uk_vat_number;

                data.card = this.state.card;
                data.country = this.state.countryBased;
                data.vatId = this.state.vatId;
                
                if (this.props.project !== undefined) {
                    data.id = this.props.project.id;
                    api = "/changeProject";
                }

                Request.post(api, data, (err, data) => {
                    if (err) {  
                        if (this.props.project !== undefined) {
                            this.setState({error: "Unable to change plan. Please contact us at help@tesults.com. " + err, submitting:false});
                        } else {
                            this.setState({error: "Unable to create project. Please contact us at help@tesults.com. " + err, submitting:false});
                        }
                    } else {
                        if (data.sca !== undefined) {
                            this.setState({sca: data.sca, scaApi: api + "Sca"}, () => this.sca(data.sca, api + "Sca"));
                        } else {
                            function gtag_report_conversion(url) {
                                var callback = function () {
                                  if (typeof(url) != 'undefined') {
                                    window.location = url;
                                  }
                                };
                                if (window.gtag !== undefined) {
                                    window.gtag('event', 'conversion', {
                                        'send_to': 'AW-870629148/wsUHCM_SztgCEJz-kp8D',
                                        'value': 120.0,
                                        'currency': 'GBP',
                                        'transaction_id': '',
                                        'event_callback': callback
                                    });
                                    return false;
                                } else {
                                    return false;
                                }
                            }
                            gtag_report_conversion();
                            if (this.props.project !== undefined) {
                                this.props.complete(data.message);
                            } else {
                                this.props.complete(data.token);
                            }
                        }
                    }
                });
            }
        }.bind(this));
    }

    paymentExistingCardComplete () {
        let data = {};
        data.name = this.props.name;
        data.orgName = this.props.orgName;
        data.product = this.props.product;
        if (this.props.plan !== undefined) {
            data.product = this.props.plan.name;
            data.plan = this.props.plan;
        }
        data.interval = this.props.interval;
        data.activeUsers = this.props.activeUsers;
        data.minUsers = this.props.minUsers;
        data.selectedPlan = this.props.selectedPlan;
        data.billingCycle = this.props.billingCycle;
        data.card = this.state.card;
        data.country = this.state.countryBased;
        data.vatId = this.state.vatId;

        let api = "/createProject";
        if (this.props.type === "planChange") {
            api = "/changeProject";
            data.id = this.props.project.id;
        }
        
        this.setState({submitting:true});
        Request.post(api, data, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create project. Please contact us at help@tesults.com. " + err, submitting: false});
            } else {
                if (data.sca !== undefined) {
                    this.setState({sca: data.sca, scaApi: api + "Sca"}, () => this.sca(data.sca, api + "Sca"));
                } else {
                    function gtag_report_conversion(url) {
                        var callback = function () {
                          if (typeof(url) != 'undefined') {
                            window.location = url;
                          }
                        };
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-870629148/wsUHCM_SztgCEJz-kp8D',
                            'value': 120.0,
                            'currency': 'GBP',
                            'transaction_id': '',
                            'event_callback': callback
                        });
                        return false;
                    }
                    gtag_report_conversion();
                    if (this.props.type === "planChange") {
                        this.props.complete(data.message);
                    } else {
                        this.props.complete(data.token);
                    }
                }
            }
        });
    }

    sca (scaContinuation, api) {
        this.setState({submitting:true});
        this.props.stripe.handleCardPayment(scaContinuation.paymentIntent, {}).then(function(result) {
            if (result.error) {
                this.setState({error: "Unable to create project. Please contact us at help@tesults.com.", submitting: false});
            } else {
                this.scaComplete(scaContinuation, api);
            }
        }.bind(this));
    }

    scaComplete (scaContinuation, api) {
        this.setState({submitting:true});
        Request.post(api, {sca: scaContinuation}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create project. Please contact us at help@tesults.com. " + err, submitting:false});
            } else {
                if (data.token !== undefined) {
                    this.props.complete(data.token);    
                } else if (data.message !== undefined) {
                    this.props.complete(data.message);
                } else {
                    this.props.complete();
                }
                Analytics.event(api);
                function gtag_report_conversion(url) {
                    var callback = function () {
                      if (typeof(url) != 'undefined') {
                        window.location = url;
                      }
                    };
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-870629148/wsUHCM_SztgCEJz-kp8D',
                        'value': 120.0,
                        'currency': 'GBP',
                        'transaction_id': '',
                        'event_callback': callback
                    });
                    return false;
                }
                this.setState({submitting:false});
                gtag_report_conversion();
            }
        });
    }

    paymentByInvoiceComplete (invoiceAuthorization) {
        let api = "/createProject";
        let data = {};
        data.invoiceAuthorization = invoiceAuthorization
        data.name = this.props.name;
        data.orgName = this.props.orgName;
        data.product = this.props.product;
        if (this.props.plan !== undefined) {
            data.product = this.props.plan.name;
        }
        data.interval = this.props.interval;
        data.activeUsers = this.props.activeUsers;
        data.minUsers = this.props.minUsers;
        data.selectedPlan = this.props.selectedPlan;
        data.billingCycle = this.props.billingCycle;
        data.country = this.state.countryBased;
        data.vatId = this.state.vatId;
        if (this.props.project !== undefined) {
            data.id = this.props.project.id;
            api = "/changeProject";
        }

        Request.post(api, data, (err, data) => {
            if (err) {  
                if (this.props.project !== undefined) {
                    this.setState({paymentByInvoiceError: "Unable to change plan. Please contact us at help@tesults.com. " + err, submitting:false});
                } else {
                    this.setState({paymentByInvoiceError: "Unable to create project. Please contact us at help@tesults.com. " + err, submitting:false});
                }
            } else {
                function gtag_report_conversion(url) {
                    var callback = function () {
                        if (typeof(url) != 'undefined') {
                        window.location = url;
                        }
                    };
                    if (window.gtag !== undefined) {
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-870629148/wsUHCM_SztgCEJz-kp8D',
                            'value': 120.0,
                            'currency': 'GBP',
                            'transaction_id': '',
                            'event_callback': callback
                        });
                        return false;
                    } else {
                        return false;
                    }
                }
                gtag_report_conversion();
                if (this.props.project !== undefined) {
                    this.props.complete(data.message);
                } else {
                    this.props.complete(data.token);
                }
            }
        });
    }

    editPaymentDetails () {
        Analytics.event("EditPaymentDetails");
        let customerId = undefined;
        if (this.props.project !== undefined) {
            customerId = this.props.project.customerId;
        }
        Request.post("/getPaymentCard", {customerId: customerId}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to access payment cards."});
            } else {
                let card = data.card;
                if (card === undefined) {
                    this.setState({state: "noPaymentDetails", card: null});
                } else {
                    this.setState({state: "paymentDetails", card: card}, () => this.setupIntent());
                }
            }
        });
    }
    
    setupIntent () {
        this.setState({loading: true});
        Request.post("/getPaymentCardSetupIntent", {}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to prepare update of card.", loading: false});
            } else {
                this.setState({loading: false, setupIntent: data.setupIntent});
            }
        });
    }

    paymentCardUpdate (e) {
        e.preventDefault();
        this.props.stripe.handleCardSetup(
            this.state.setupIntent, {
              payment_method_data: {
                billing_details: {
                    address: {
                        line1: this.state.addressLine1,
                        line2: this.state.addressLine2,
                        city: this.state.addressCity,
                        state: this.state.addressState,
                        postal_code: this.state.addressZip,
                        country: this.state.addressCountry
                    },
                    name: this.state.name
                }
              }
            }
          ).then(function(result) {
            if (result.error) {
                this.setState({error: "Unable to update payment card. Please contact us at help@tesults.com or use the form below."});
            } else {
                let data = {
                    customer_name: this.state.name,
                    company_name: this.state.companyName,
                    email: this.state.email,
                    address_line1: this.state.addressLine1,
                    address_line2: this.state.addressLine2,
                    address_city: this.state.addressCity,
                    address_state: this.state.addressState,
                    address_zip: this.state.addressZip,
                    address_country: this.state.addressCountry,
                    uk_vat_number: this.state.uk_vat_number
                };
                let api = "/updatePaymentCard";
                data.setupIntent = result.setupIntent;
                if (this.props.project !== undefined) {
                    data.customerId = this.props.project.customerId;
                    data.projectId = this.props.project.id;
                }
                Request.post(api, data, (err, data) => {
                    if (err) {
                        this.setState({error: "Unable to update payment card. Please contact us at help@tesults.com or use the form below."});
                    } else {
                        Analytics.event("UpdatePaymentDetails");
                        this.setState({state: "paymentCardUpdated"});
                        this.props.complete();
                    }
                });
            }
          }.bind(this));
    }

    vatIdChange (e) {
        this.setState({vatId: e.target.value});  
    }

    nameChange(e) {
        this.setState({name: e.target.value});
    }

    companyNameChange(e) {
        this.setState({companyName: e.target.value});
    }

    addressLine1Change (e) {
        this.setState({addressLine1: e.target.value});
    }

    addressLine2Change (e) {
        this.setState({addressLine2: e.target.value});
    }

    addressCityChange (e) {
        this.setState({addressCity: e.target.value});
    }

    addressStateChange (e) {
        this.setState({addressState: e.target.value});
    }
    
    addressZipChange (e) {
        this.setState({addressZip: e.target.value});
    }

    addressCountryChange (e) {
        this.setState({addressCountry: e.target.value});
    }

    countryBasedChange (e) {
        this.setState({countryBased: e.target.value, addressCountry: e.target.value});
    }

    ukVATNumberChange (e) {
        this.setState({uk_vat_number: e.target.value})
    }

    cardElementChange (element) {
        if (element.elementType === "card") {
            if (element.value.postalCode !== undefined) {
                this.setState({addressZip: element.value.postalCode});
            }
        }
    }

    cardPaymentInputRender () {
        return (
            <div>
                <h5 className="neutral4 mb-3">Credit or debit card</h5>
                <div className="whitebg p-4 mb-3">
                    <CardElement onChange={(element) => this.cardElementChange(element)}/>
                </div>
                {/*<input type="text" className="form-control" placeholder="Card Number" data-stripe="number" required/>*/}
                <h5 className="neutral4 mb-1">Cardholder Name</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" onChange={this.nameChange}/></div>
                <h5 className="neutral4 mb-1">Company Name</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" onChange={this.companyNameChange}/></div>
                <h5 className="neutral4 mb-1">Address Line 1</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" onChange={this.addressLine1Change}/></div>
                <h5 className="neutral4 mb-1">Address Line 2</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" onChange={this.addressLine2Change}/></div>
                <h5 className="neutral4 mb-1">City</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" onChange={this.addressCityChange}/></div>
                <h5 className="neutral4 mb-1">State</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" onChange={this.addressStateChange}/></div>
                <h5 className="neutral4 mb-1">Zip or Postal Code (edit in card row after CVC)</h5>
                <div className="mb-3"><input className="tr-input form-control" type="text" size="6" onChange={this.addressZipChange} value={this.state.addressZip} readOnly/></div>
                <h5 className="neutral4 mb-1">Country</h5>
                <div className="mb-3"><input type="hidden" className="tr-input form-control" placeholder="Country" data-stripe="address_country" value={this.state.addressCountry}/></div>
                <Countries change={this.addressCountryChange} value={this.state.addressCountry}/>
                {
                this.state.addressCountry === "GB" ?
                <div>
                    <span className="mt-3 mb-1 neutral4">UK VAT Number</span>
                    <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.ukVATNumberChange} value={this.state.uk_vat_number}/></div>
                </div>
                :
                <span></span>
                }
            </div>
        )
    }

    render () {
        if (this.state.state === null) {
            window.scrollTo(0, 0);
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    <Loading/>
                </div>
            );
        } else if (this.state.state === "countrySelect") {
            window.scrollTo(0, 0);
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                            <p>Select the country where the team/business for this project is based.</p>
                            <br/>
                            <Countries change={this.countryBasedChange} value={this.state.countryBased}/>
                            <br/>
                            {this.state.countryBased === "GB" ? <p>Please note that VAT will be applied to the invoice of UK based customers. Prices on the web site are exclusive of VAT.</p> : <span></span>}
                            <button type="button" className="btn btn-confirm mt-3" onClick={this.countrySelected}>Next</button>
                        </div>
                    </div>
                </div>    
            );
        } else if (this.state.state === "vatIdRequired") {
            window.scrollTo(0, 0);
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    <EUVAT next={this.payment} vatId={this.state.vatId} vatIdChange={this.vatIdChange} cancel={this.props.cancel}/>
                </div>
            );
        } else if (this.state.state === "vatIdOptional") {
            window.scrollTo(0, 0);
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    <p>You may enter your VAT Identification Number below if you have one. You may skip this step. This can also be done later by providing the number using the support form or by email at help@tesults.com.</p>
                    <br/>
                    <input type="text" className="form-control" value={this.state.vatId} onChange={this.vatIdChange} required/>
                    <br/>
                    <div className="accenta1" id="error">{this.state.error}</div>
                    <br/>
                    <button type="button" className="btn btn-confirm mt-3" onClick={this.vatIdEntered}>Next</button>
                    <br/>
                    <button type="button" className="btn btn-select mt-3" onClick={this.payment}>Skip</button>
                    <br/>
                    <button type="button" onClick={this.props.cancel} className="btn btn-cancel mt-3">Back to configuration</button>
                </div>    
            );
        } else if (this.state.state === "paymentByInvoice") {
            return (
                <div>
                    <p>Receive an invoice by email and pay by bank transfer. This method of payment is manual. We ask you request payment by invoice only if paying annually with a total greater than $2000, but if you are unable to pay by card we will try to accommodate you.</p>
                    <p>We currently accept bank transfers from:</p>
                    <ul> 
                        <li>USD accounts via ACH transfer</li> 
                        <li>EUR accounts via SEPA transfer</li>
                        <li>GBP accounts via bank transfer</li>
                    </ul>
                    <PaymentByInvoice 
                        cancel={this.props.cancel} 
                        backToProductSelect={this.props.backToProductSelect} 
                        complete={this.props.projectCreated} 
                        type={this.props.type}
                        name={this.props.name} 
                        orgName={this.props.orgName}
                        product={this.props.product}
                        interval={this.props.interval}
                        activeUsers={this.props.activeUsers}
                        minUsers={this.props.minUsers}
                        paymentByInvoiceComplete={this.paymentByInvoiceComplete}
                        paymentByInvoiceError={this.state.paymentByInvoiceError}
                        countryBased={this.state.countryBased}
                    />
                </div>
            )
        } else if (this.state.state === "paymentExistingCard") {
            window.scrollTo(0, 0);
            let buttonText = "Use Saved Card and Create Project";
            if (this.props.type === "planChange") {
                buttonText = "Use Saved Card and Change Plan";
            }
            return (
                <div>
                <Helmet>
                    <title>Tesults - Payment</title>
                    <meta name="description" content="Tesults payment."/>
                </Helmet>
                <p>Use your existing saved payment card:</p>
                <p>{this.state.card.brand} ending in {this.state.card.last4} expiring {this.state.card.exp_month} / {this.state.card.exp_year}</p>
                <button type="button" className="btn btn-confirm mt-3" onClick={this.paymentExistingCardComplete} disabled={this.state.submitting}>{buttonText}</button>
                <br/>
                <div className="accenta1" id="error">{this.state.error}</div>
                <br/>
                <p>If you want to change your payment card, cancel and click 'Payment Details' to edit your card details and then change your plan.</p>
                <button type="button" className="btn btn-cancel mt-3" onClick={this.props.cancel}>Cancel</button>
                </div>  
            );
        } else if (this.state.state === "paymentNewCard") {
            let eligible = <div></div>
            let pleaseWaitMessage = <span></span>
            if (this.state.submitting === true) {
                pleaseWaitMessage = <p className="pl-2">Please wait...</p>
            }
            let submitButton = <button type="button" className="btn btn-confirm" onClick={this.paymentNewCardComplete} disabled={this.state.submitting}>Submit Payment Details and Create Project</button>
            if (this.state.sca !== undefined) {
                submitButton = <button type="button" className="btn btn-confirm" onClick={() => this.sca(this.state.sca, this.state.scaApi)} disabled={this.state.submitting}>Submit Payment Details and Create Project</button>
            }
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    {eligible}
                    <div>
                        
                        <div style={{"display": this.state.sca === undefined ? "none" : "block"}}>
                            <h5 className="">Authentication</h5>
                            <p>Card authentication is required to complete this process</p>
                        </div>
                        <span className="payment-errors"></span>
                        <div style={{"display": this.state.sca === undefined ? "block" : "none"}}>
                            <h4 className="neutral4">Card Details</h4>
                            <p className="font14">Pay with a Visa, Mastercard or American Express credit or debit card.</p>
                            {this.cardPaymentInputRender()}
                        </div> 
                        <br/>
                        {pleaseWaitMessage}
                        <div className="accenta1" id="error">{this.state.error}</div>
                        {submitButton}
                        <br/>
                        {/*<button type="button" className="btn btn-cancel mt-3" onClick={this.props.cancel}>Cancel</button>*/}
                    </div>
                </div>
            );
        } else if (this.state.state === "paymentDetails") {
            let ownerDisplay = <span></span>
            if (this.props.owner !== undefined) {
                ownerDisplay = <span className=" font15 mb-3">The owner of this project is {this.props.owner.firstName} {this.props.owner.lastName} and emails will be received at {this.props.owner.email}</span>
            }

            let currentCard = 
            <div>
                <h5>Current payment card</h5>
                <div className="font14">{this.state.card.brand}</div> 
                <div className="font14">Last four digits: {this.state.card.last4}</div> 
                <div className="font14">Expiring {this.state.card.exp_month} / {this.state.card.exp_year}</div>
                <h5>Change to a new payment card</h5>
                <p className="font14">Replace your current payment card with a new card for use with all of your subscriptions from now on.</p>
            </div>
            let submitButton = <button type="button" className="btn btn-confirm" onClick={this.paymentCardUpdate} disabled={this.state.submitting}>Submit New Payment Details</button>
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    <div style={{"maxWidth":"600px"}}>
                        {currentCard}
                        {ownerDisplay}
                        <span className="payment-errors"></span>
                        {this.cardPaymentInputRender()}
                        <br/>
                        <div className="accenta1" id="error">{this.state.error}</div>
                        {submitButton}
                        <br/>
                        {/*<button type="button" className="btn btn-cancel mt-3" onClick={this.props.cancel}>Cancel</button>*/}
                    </div> 
                </div> 
            );
        } else if (this.state.state === "noPaymentDetails") {
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                        <meta name="description" content="Tesults payment."/>
                    </Helmet>
                    <p>There are no payment details saved for your project.</p>
                    <br/>
                    <button type="button" className="btn btn-cancel mt-3" onClick={this.props.cancel}>Back</button>
                </div>
            );
        } else if (this.state.state === "paymentCardUpdated") {
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Payment</title>
                    </Helmet>
                    <p>Payment card updated.</p>
                </div>
            );
        }
    }
}

export default injectStripe(Payment);