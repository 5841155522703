/*global */
import React, { Component } from 'react';
import Loading from './Loading';
import Request from './Request';

class ManualCaseUpdateList extends Component {
    constructor () {
        super();
        this.state = {state: null};
        this.getList = this.getList.bind(this);
    }

    componentDidMount () {
        this.getList();
    }

    getList () {
        if (this.props.importList === undefined) {
            this.setState({state: null});
            return;
        }
        this.setState({loading: true});
        Request.get("/list", {id: this.props.importList.id, created: this.props.importList.created}, (err, data) => {
            if (err) {
                this.setState({loading: false, state: null});
            } else {
                this.setState({state: "importList", list: data.list, loading: false});
            }
        });
    }

    render () {
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.state === null) {
            return <span></span>
        } else if (this.state.state === "importList") {
            return (
                <div className="p-3">
                    <div className="mb-3">
                        <input type="checkbox" onChange={() => this.props.updateListToggle()} checked={this.props.updateListEnabled} aria-label="UpdateList"/>
                        <span className="ml-3 font15">On save, update this case in the list: <b>{this.state.list.label}</b></span>
                    </div>
                    <p className='font15 neutral4'>This test case was imported from the {this.state.list.label} list. Enable the checkbox above if you would like to update the test case in the list as well as the test run. Test lists are for persistent storage and test cases from a list can be imported and used in new test runs.</p>
                </div>
            );
        } else {
            return <p>Invalid state</p>
        }
    }
}

export default ManualCaseUpdateList;