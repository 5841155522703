/*global */
import React, { Component } from 'react';

class Header extends Component {
    render() {
        if (this.props.type === "header-double") {
            return (
                <div className="header-double">
                    {this.props.header1}
                    <div className="header-dividor"/>
                    {this.props.header2}
                </div>
            );
        } else if (this.props.type === "header-triple") {
            return (
                <div className="header-triple">
                    {this.props.header1}
                    <div className="header-dividor"/>
                    {this.props.header2}
                    <div className="header-dividor"/>
                    {this.props.header3}
                </div>
            );
        } else {
            return (
                <div className="header-single">
                    {this.props.header1}
                </div>
            );
        }
        
    }
}

export default Header;