/*global*/
import React, { Component } from 'react';
import Request from './Request';
import { LazyLog } from 'react-lazylog';

class LogView extends Component {
    constructor()  {
        super();
        this.state = ({error: undefined, text: undefined});
    }

    componentDidMount () {
        Request.getData(this.props.url, {}, (err, data) => {
            if (err) {
                this.setState({text: "Unable to read file contents. Click the link to download the file for viewing instead."});
            } else {
                if (data === "" || data === undefined || data === null) {
                    data = "-";
                }
                this.setState({text: data});
            }
        });
    }

    render () {
        if (this.state.error !== undefined) {
            let error = this.state.error;
            return <p>{error}</p>
        } else if (this.state.text === undefined) {
            return <p>Loading...</p>  
        } else {
            return (
                <div style={{"height":"400px"}}>
                    <button className="btn btn-sm btn-confirm mb-2" onClick={() => this.props.close(this.props.name)}>Close</button>
                    <LazyLog text={this.state.text}/>
                    {/*<pre className="caseCode pre-wrap">{this.state.text}</pre>*/}
                </div>
            );
        }
    }
}

export default LogView;
