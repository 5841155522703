/*global */
import React, { Component } from 'react'
import CaseList from './CaseList'
import Request from './Request'
import Cache from './Cache'
import Context from './Context'
import ReleaseChecklistSide from './ReleaseChecklistSide'
import SelectSide from './SelectSide'
import ManualCaseEdit from './ManualCaseEdit'
import Analytics from './Analytics'
import Loading from './Loading'
import Submitting from './Submitting'
import Confirmation from './Confirmation'

class ReleaseChecklist extends Component {
    static contextType = Context

    constructor (props) {
        super(props)
        this.state = {
            renameChecklistText: "",
            renameChecklistView: false,
            loading: false,
            submitting: false,
            submittingMessage: "",
            items: [],
            selectedItem: undefined,
            confirmation: {success: undefined, failure: undefined},
            updatedCaseHash: undefined
        }
        this.items = this.items.bind(this)
        this.addItem = this.addItem.bind(this)
        this.saveItem = this.saveItem.bind(this)
        this.cancelEditItem = this.cancelEditItem.bind(this)
        this.displayItem = this.displayItem.bind(this)
        this.addItemToGroup = this.addItemToGroup.bind(this)
        this.renameChecklist = this.renameChecklist.bind(this)
        this.renameChecklistCancel = this.renameChecklistCancel.bind(this)
        this.renameChecklistConfirm = this.renameChecklistConfirm.bind(this)
        this.renderRenameChecklistView = this.renderRenameChecklistView.bind(this)
        this.renameChecklistChange = this.renameChecklistChange.bind(this)
        this.deleteChecklist = this.deleteChecklist.bind(this)
        this.deleteItem = this.deleteItem.bind(this)
        this.clearChecklists = this.clearChecklists.bind(this)
    }

    componentDidMount () {
        this.items()
    }

    items () {
        this.setState({loading: true})
        Request.get("/release-checklist-items", {id: this.props.projectId, team: this.props.teamId, created: this.props.checklistId}, (err, data) => {
            if (err) {
                this.setState({loading: false, teams: []})
            } else {
                this.setState({loading: false, items: data.items})
            }
        })
    }

    addItem (suite) {
        this.displayItem({
            type: "general",
            complete: false,
            name: undefined,
            suite: suite,
            desc: undefined
        })
    }

    saveItem (data) {
        this.setState({submitting: true, submittingMessage: "Saving..."})
        Request.post("/release-checklist-item-update", {
                    id: this.props.projectId,
                    team: this.props.teamId,
                    created: this.props.checklistId,
                    item: data
            }, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: err.toString()}}, () => this.displayItem(data))
            } else {
                Analytics.event("ReleaseChecklistItemSaved");
                this.setState({submitting: false, items: data.items, confirmation: {success: undefined, failure: undefined}, updatedCaseHash: data.updatedCaseHash, rev: this.state.rev + 1}, this.props.sideOverlay(undefined))
            }
        })
    }

    addItemToGroup (group) {
        this.addItem(group)
    }

    cancelEditItem () {
        this.props.sideOverlay(undefined)
    }

    deleteItem (item) {
        Request.post("/release-checklist-item-update", {
            id: this.props.projectId, 
            team: this.props.teamId, 
            created: this.props.checklistId,
            item: item,
            remove: true
        }, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}});
            } else {
                Analytics.event("ReleaseChecklistItemDeleted");
                this.setState({items: data.items, confirmation: {success: undefined, failure: undefined}, rev: this.state.rev + 1}, this.cancelEditItem)
            }
        })
    }

    displayItem (item) {
        this.props.sideOverlay(
            <ManualCaseEdit
                type="release-checklist"
                data={item}
                saveItem={this.saveItem}
                cancel={this.cancelEditItem}
                saving={this.state.saving}
                yOffset={this.state.yOffset}
                overlay={this.props.overlay}
                messageOverlay={this.props.messageOverlay}
                side={this.props.side}
                history={this.props.history}
                sideOverlay={this.props.sideOverlay}
                sideOpen={this.props.sideOpen}
                confirmation={this.state.confirmation}
                rev={this.state.rev}
                deleteItem={this.deleteItem}
            />
        )
    }

    clearChecklists () {
        this.setState({checklists: []})
    }

    deleteChecklist () {
        this.setState({submitting: true, submittingMessage: "Deleting..."})
        Request.post("/release-checklist-delete", {id: this.props.projectId, team: this.props.teamId, created: this.props.checklistId}, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: "Unable to delete checklist: " + err.toString()}})
            } else {
                this.props.history.push('/release-checklists')
            }
        })
    }

    renameChecklist () {
        this.setState({renameChecklistView: true})
    }

    renameChecklistCancel () {
        this.setState({renameChecklistView: false})
    }

    renameChecklistConfirm () {
        this.setState({submitting: true, submittingMessage: "Saving..."})
        Request.post("/release-checklist-update", {
                    id: this.props.checklist.project, 
                    team: this.props.checklist.team, 
                    created: this.props.checklist.created,
                    name: this.state.renameChecklistText
            }, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: err.toString()}})
            } else {
                Analytics.event("ReleaseChecklistNameUpdated");
                this.setState({submitting: false, confirmation: {success: undefined, failure: undefined}, rev: this.state.rev + 1}, () => { this.props.checklistUpdated(data.checklist); this.renameChecklistCancel() })
            }
        })
    }

    renameChecklistChange (e) {
        this.setState({renameChecklistText: e.target.value})
    }

    renderRenameChecklistView () {
        return (
            <div>
                <div className='mb-3'>
                    <span className='font14 neutral4'>Checklist name</span>
                    <br/>
                    <input className='tr-input' onChange={this.renameChecklistChange} value={this.state.renameChecklistText}/>
                </div>
                <div className='flex-row mb-3'>
                    <div className='mr-3'>
                        <button className='btn-confirm' onClick={this.renameChecklistConfirm}>Update</button>
                    </div>
                    <div>
                        <button className='btn-confirm' onClick={this.renameChecklistCancel}>Cancel</button>
                    </div>
                </div>
                <div>
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
            </div>
        )
    }

    render  () {
        if (this.state.renameChecklistView === true) {
            return this.renderRenameChecklistView()
        }

        let selectSide = 
        <div style={{"display":"flex"}}>
            <div style={{ "marginLeft":"auto"}}>
                <SelectSide
                    side={this.props.side}
                    sideOpen={this.props.sideOpen}
                    content={<ReleaseChecklistSide side={this.props.side} addItem={() => this.addItem()} history={this.props.history} clearChecklists={this.clearChecklists} renameChecklist={this.renameChecklist} deleteChecklist={this.deleteChecklist} confirmation={this.state.confirmation}/>}
                    image={undefined}
                    label={<button className="btn-select"><img src="/img/three-dots-white.svg" className='mr-1' width="12" height="12" alt="dots"/>Open list options</button>}
                    history={this.props.history}
                />
            </div>
        </div>

        const caseList = <CaseList 
                key={"release-checklist"}
                updatedCaseHash={this.state.updatedCaseHash}
                history={this.props.history}
                sort={"suite"}
                context={"release-checklist"}
                overlay={this.props.overlay}
                messageOverlay={this.props.messageOverlay}
                side={this.props.side}
                sideOverlay={this.props.sideOverlay}
                cases={this.state.items}
                cancelEditItem={this.cancelEditItem}
                displayItem={this.displayItem}
                addCaseToSuite={this.addItemToGroup}
            />

        return (
            <div>
                {selectSide}
                {caseList}
            </div>
        )
    }
}

export default ReleaseChecklist