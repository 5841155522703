/*global Cookies*/
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Constants from './Constants';
import Loading from './Loading';
import Analytics from './Analytics';
import Request from './Request';

class LoginMain extends Component {
    constructor () {
        super();
        Analytics.event("Login");
        const user = Cookies.getJSON("truser");
        let state = "loggedIn";
        if (user === undefined || user == null) {
            state = null;
        }
        this.state = {
            state: state, 
            email: "", 
            password: "", 
            authCode: "", 
            error: "", 
            sso: undefined, 
            submitting: false,
            display2FAInput: false,
            iref: undefined,
            loginFlowEmailComplete: false,
            ssoIds: []
        };
        this.emailChange = this.emailChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.authCodeChange = this.authCodeChange.bind(this);
        this.init = this.init.bind(this);
        this.login = this.login.bind(this);
        this.loginSaml = this.loginSaml.bind(this);
        this.loginGoogleOAuth = this.loginGoogleOAuth.bind(this); 
        this.toggleDisplay2FAInput = this.toggleDisplay2FAInput.bind(this);
        this.affiliate = this.affiliate.bind(this);
        this.loginFlowEmailCancel = this.loginFlowEmailCancel.bind(this);
        this.ssoForEmail = this.ssoForEmail.bind(this);
        this.ssoIdsClear = this.ssoIdsClear.bind(this);
        this.ssoIdRedirect = this.ssoIdRedirect.bind(this);
    }

    componentDidMount() {
        const iref = Constants.getUrlParameter("iref", this.props.location.search);
        this.setState({iref: iref});
        window.scrollTo(0,0);
        this.sso();
    }
    
    emailChange (e) {
        this.setState({email: e.target.value, loginFlowEmailComplete: false});
    }

    passwordChange (e) {
        this.setState({password: e.target.value});
    }

    authCodeChange (e) {
        this.setState({authCode: e.target.value});
    }

    results () {
        window.location.href = Constants.baseUrl + "/results";
    }

    affiliate () { // an affiliate is signing up
        window.location.href = Constants.baseUrl + "/affiliate/dashboard";
    }

    toggleDisplay2FAInput () {
        this.setState({display2FAInput: (this.state.display2FAInput === true ? false: true)});
    }

    loginFlowEmailCancel () {
        this.setState({loginFlowEmailComplete: false})
    }

    init() {
        Analytics.event("LoggedIn");
        if (this.state.iref !== "affiliate") {
            setTimeout(this.results, 2000);
        } else {
            setTimeout(this.affiliate, 2000);
        }
    }

    login (e) {
        e.preventDefault();
        this.setState({submitting: true});
        // validate here
        Request.post("/login", {email: this.state.email, password: this.state.password, authCode: this.state.authCode}, (err, data) => {
            if (err) {
                this.setState({error: err, submitting: false});
            } else {
                this.setState({state: "init", submitting: false});
            }
        });
    }

    sso () {
        if (this.props.id === undefined) {
            return;
        } else {
            this.setState({submitting: true});
            Request.get("/ssoForId", {id: this.props.id}, (err, data) => {
                if (err) {
                    this.setState({error: "Login for " + this.props.id + " is unavailable. Please contact your IT Administrator and check your network connectivity.", submitting: false});
                } else {
                    this.setState({sso: data.sso, submitting: false});
                }
            });
        }
    }

    loginSaml () {
        this.setState({submitting: true});
        Request.get("/samlLogin", {id: this.props.id}, (err, data) => {
            if (err) {
                this.setState({error: err, submitting: false});
            } else {
                window.open(data.loginUrl, "_self");
            }
        });
    } 

    loginGoogleOAuth () {
        this.setState({submitting: true});
        // validate here
        Request.get("/googleoauthLogin", {id: this.state.sso.id}, (err, data) => {
            if (err) {
                this.setState({error: err, submitting: false});
            } else {
                window.open(data.loginUrl, "_self");
            }
        });
    }

    ssoForEmail (e) {
        e.preventDefault();
        this.setState({submitting: true});
        // validate here
        Request.get("/ssoForEmail", {email: this.state.email}, (err, data) => {
            if (err) {
                this.setState({error: err, submitting: false});
            } else {
                let ssoIds = data.ssoIds
                if (ssoIds === undefined) {
                    ssoIds = []
                }
                this.setState({ssoIds: ssoIds, loginFlowEmailComplete: true, submitting: false})
            }
        });
    }

    ssoIdsClear () {
        this.setState({ssoIds: []})
    }

    ssoIdRedirect (ssoId) {
        window.open(Constants.baseUrl + "/login/" + ssoId, "_self");
    }

    render () {
        /*this.state.orgs.forEach(function (org) {
            orgs.push(<li><a href="#">{org}</a></li>);
        });*/
        if (this.state.state === "loggedIn") {
            return (
                <div style={{"maxWidth":"600px", "marginLeft":"auto", "marginRight":"auto"}} className="standardborder whitebg mt-5 p-5">
                    <p>You are logged in. View <NavLink to="/results" className="neutral4Link">results</NavLink>.</p>
                </div>  
            );
        } else if (this.state.state === "init") {
            this.init();
            return (
                <div>
                    <Loading/>
                </div>  
            );
        } else if (this.props.id === undefined) {
            if (this.state.loginFlowEmailComplete !== true) {
                let authCodeStyle= {"display":"none"};
                if (this.state.display2FAInput === true) {
                    authCodeStyle = {};
                } 
                return (
                    <div>
                        <form id="loginFlowEmail" className="text-left" onSubmit={this.ssoForEmail}>
                            <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                                <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                    <div style={{"flex":"1"}}>
                                        <h1 className="mb-5" style={{"fontWeight":"800"}}>Sign In</h1>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <div className="mb-3">
                                            <label className="neutral4 font14 mb-0" htmlFor="email">Email</label>
                                            <br/>
                                            <input type="email" className="tr-input-width-100 neutral8bg" id="email" name="email" onChange={this.emailChange} required/>
                                        </div>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <div className="">
                                            <label className="form-label" htmlFor=""></label>
                                            {this.state.submitting ? <span className="font14 neutral4">Checking...</span> : 
                                            <button type="submit" className="btn-confirm-index mt-3" disabled={this.state.submitting}>Next</button>
                                            }
                                            <br/>
                                            <br/>
                                            <label className="form-label" htmlFor=""></label>
                                            <div className="accenta1 font14" id="error">{this.state.error}</div>
                                        </div>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <div className="mb-3">
                                            <p className="mt-5 neutral4"><small>By continuing, you agree to the <NavLink to="/docs/terms" target="_blank" className="neutral4 no-break">Tesults terms of service</NavLink>.</small></p>
                                            <p className="mt-3 font14"><span>Not a member?</span> <Link className="tr-link-primary4" to="/register">Sign up</Link></p>
                                            <p className="mt-3 font14"><span>Forgot your password?</span> <Link className="tr-link-primary4" to="/forgotpassword">Reset it</Link></p>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </form>
                    </div>
                );
            } else {
                // loginFlowEmailComplete === true here
                if (this.state.ssoIds.length > 0) {
                    let ssoSignIns = []
                    for (let i = 0; i < this.state.ssoIds.length; i++) {
                        let ssoId = this.state.ssoIds[i]
                        ssoSignIns.push (
                            <div>
                                <button type="button" className="btn-confirm-index mt-3" disabled={this.state.submitting} onClick={() => this.ssoIdRedirect(ssoId)}>{ssoId}</button>
                            </div>
                        )
                    }
                    return (
                        <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                            <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                <div style={{"flex":"1"}}>
                                    <h1 className="mb-5" style={{"fontWeight":"800"}}>Sign In</h1>
                                    <p className="font14 neutral4">You are a member of a project that you can sign into without a Tesults password</p>
                                    {ssoSignIns}
                                    <hr/>
                                    <p className="font14 neutral4">Or alternatively</p>
                                    <button type="button" className="btn-cancel-index mt-3" disabled={this.state.submitting} onClick={this.ssoIdsClear}>Sign in with email and password</button>
                                </div>
                            </div>
                        </div>
                    );
                }
                

                let authCodeStyle= {"display":"none"};
                if (this.state.display2FAInput === true) {
                    authCodeStyle = {};
                }
                return (
                    <div>
                        <form id="loginFlowEmail" className="text-left" onSubmit={this.login}>
                            <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                                <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                    <div style={{"flex":"1"}}>
                                        <h1 className="mb-5" style={{"fontWeight":"800"}}>Sign In</h1>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <div className="mb-3">
                                            <label className="neutral4 font14 mb-0" htmlFor="email">Email</label>
                                            <br/>
                                            <input type="email" className="tr-input-width-100 neutral8bg" id="email" name="email" onChange={this.emailChange} value={this.state.email} disabled/>
                                        </div>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <label className="form-label neutral4 font14 mb-0" htmlFor="password">Password</label>
                                        <br/>
                                        <input type="password" className="tr-input-width-100 neutral8bg" id="password" name="password" style={{"backgroundColor" : "#F5F8FA" }} onChange={this.passwordChange} required/>
                                    </div>
                                    
                                    <div style={{"flex:":"1"}}>
                                        <div style={{"display":"flex", "alignItems":"top"}} className="mt-3">
                                            <div style={{"width": "18px"}}>
                                                <img src="/img/lock-closed.svg" width="16" height="16" alt=""/>
                                            </div>
                                            <div>
                                                <button type="button" style={{"textAlign":"left"}} className="twofactorauthLink btn-transparent font14" onClick={this.toggleDisplay2FAInput}>Two-factor authentication (2FA) enabled? <u>Input authentication code</u></button>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="neutral4 font14 mb-0" style={authCodeStyle} htmlFor="authCode">Authentication Code</label>
                                            <br/>
                                            <input type="password" className="tr-input-width-100 neutral8bg" id="authCode" name="authCode" style={authCodeStyle} onChange={this.authCodeChange}/>
                                        </div>
                                    </div>

                                    <div style={{"flex":"1"}}>
                                        <label className="form-label" htmlFor=""></label>
                                        {this.state.submitting ? <span className="font14 neutral4">Signing in...</span> : 
                                        <button type="submit" className="btn-confirm-index mt-3" disabled={this.state.submitting}>Sign in</button>
                                        }
                                        <br/>
                                        <br/>
                                        <label className="form-label" htmlFor=""></label>
                                        <div className="accenta1" id="error">{this.state.error}</div>
                                    </div>
                                        
                                    <div style={{"flex":"1"}}>
                                        <div className="mb-3">
                                            <p className="mt-5 neutral4"><small>By continuing, you agree to the <NavLink to="/docs/terms" target="_blank" className="neutral4 no-break">Tesults terms of service</NavLink>.</small></p>
                                            <p className="mt-3 font14"><span>Not a member?</span> <Link className="tr-link-primary4" to="/register">Sign up</Link></p>
                                            <p className="mt-3 font14"><span>Forgot your password?</span> <Link className="tr-link-primary4" to="/forgotpassword">Reset it</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            }
        } else {
            let samlLoginButton = <button type="button" className="btn-confirm-index mt-3" disabled={this.state.submitting} onClick={this.loginSaml}>Sign in</button>
            let googleOAuthLoginButton = <button type="button" className="btn-confirm-index mt-3" disabled={this.state.submitting} onClick={this.loginGoogleOAuth}>Sign in with Google</button>
            if (this.state.sso === undefined) {
                samlLoginButton = <span></span>
                googleOAuthLoginButton = <span></span>
            } else {
                if (this.state.sso.samlEnabled !== true) {
                    samlLoginButton = <span></span>
                }
                if (this.state.sso.googleOAuthEnabled !== true) {
                    googleOAuthLoginButton = <span></span>
                }
                if (this.state.sso.samlEnabled !== true && this.state.sso.googleOAuthEnabled !== true) {
                    samlLoginButton = <span className="accenta1 font12">{"Login for " + this.props.id + " is disabled. Please contact your IT Administrator."}</span>
                }
            }
            return (
                <div>
                    <form id="login" className="text-left" onSubmit={this.login}>
                        <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                            <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                <div style={{"flex":"1"}}>
                                    <h1 className="mb-5" style={{"fontWeight":"800"}}>Sign In</h1>
                                </div>
                                <div style={{"flex":"1"}}>
                                    <h4 className="mb-4">{this.props.id}</h4>
                                    <div>
                                        {samlLoginButton}
                                        <br/>
                                        {googleOAuthLoginButton}
                                    </div>
                                    <br/>
                                    <div className="accenta1 font12" id="error">{this.state.error}</div>
                                    <div className="accenta1 font12" id="error2">{(this.props.err !== undefined) ? this.props.err.err: ""}</div>
                                    <br/>
                                    <hr/>
                                    <div className="text-left">
                                        <label className="col-form-label" htmlFor=""></label>
                                        <p><small>This is sign in is for {this.props.id} only. <Link className="neutral4Link no-break" to="/login">Access general login here</Link>.</small></p>
                                    </div>
                                </div>
                                <div style={{"flex":"1"}}>
                                    <div className="mb-3">
                                        <p className="mt-5 neutral4"><small>By continuing, you agree to the <NavLink to="/docs/terms" target="_blank" className="neutral4 no-break">Tesults terms of service</NavLink>.</small></p>
                                        <p className="mt-3 font14"><span>Not a member?</span> <Link className="tr-link-primary4" to="/register">Sign up</Link></p>
                                        <p className="mt-3 font14"><span>Forgot your password?</span> <Link className="tr-link-primary4" to="/forgotpassword">Reset it</Link></p>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </form>
                </div>
            );
        }
    }
}

export default LoginMain;