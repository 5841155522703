/*global*/
import React, { Component } from 'react';
import App from './App';

class Site extends Component {
    render () {
        return <App history={this.props.history} location={this.props.location} match={this.props.match} site={true}/>
    }
};

export default Site;