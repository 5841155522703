/*global Cookies*/
import React, { Component } from 'react';
import AffiliateAdmin from './AffiliateAdmin';
import AffiliateAdminPayments from './AffiliateAdminPayments';
import NotFound from './NotFound';
import EngagementAdmin from './EngagementAdmin';
import ErrorsAdmin from './ErrorsAdmin';

class Administrator extends Component {
    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let loggedIn = true;
        if (user === undefined || user == null) {
            loggedIn = false;
        }
        this.state = {loggedIn: loggedIn};
    }
  
    render () {
        if (this.state.loggedIn === false) {
            return (
                <div>
                    <div style={{"minHeight": "100vh", "display":"flex"}}>
                        <div style={{"maxWidth":"600px"}}>
                            <NotFound/>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{"minHeight": "100vh", "display":"flex"}}>
                <div style={{"maxWidth":"2000px"}}>
                    <h1 className="neutral1">Tesults Administrator</h1>
                    <EngagementAdmin/>
                    <ErrorsAdmin/>
                    <AffiliateAdmin/>
                    <AffiliateAdminPayments/>
                </div>
            </div>
            
        );
    }
};

export default Administrator;