/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';

class DocsKotlin extends Component {
    render() {
        const helmet =
        <Helmet>
            <title>Kotlin test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Kotlin test reporting with Tesults."/>
        </Helmet>

        const installation =
        <div>
            <p>For Kotlin use the Tesults Java API. The Tesults Java API Library is available from the JCenter and Maven Central repositories or as a JAR you can download from here.</p>
            <h4 className="mt-5">Gradle</h4>
            <p>If you are using Gradle add this dependency snippet to your build.gradle file:</p>
            <pre className="docsCode">
            dependencies {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">compile 'com.tesults:tesults:1.0.1'</code><br/>
            {'}'}<br/>
            </pre>
            <p>Also ensure you have the JCenter or Maven Central repository referenced in your build.gradle file.</p>
            <h4 className="mt-5">Maven</h4>
            <p>If you are using Maven add this dependency snippet to your pom.xml file:</p>
            <pre className="docsCode">
            {'<'}dependency{'>'}<br/>
                &nbsp;&nbsp;{'<'}groupId{'>'}<code className="accentb4">com.tesults</code>{'<'}/groupId{'>'}<br/>
                &nbsp;&nbsp;{'<'}artifactId{'>'}<code className="accentb4">tesults</code>{'<'}/artifactId{'>'}<br/>
                &nbsp;&nbsp;{'<'}version{'>'}<code className="accentb4">1.0.1</code>{'<'}/version{'>'}<br/>
            {'<'}/dependency{'>'}<br/>
            </pre>
            <h4 className="mt-5">JAR</h4>
            <p>Alternatively a JAR is available for download directly here: <a href="https://www.tesults.com/tesults.jar">tesults.jar</a> (compatible with Java 7+)</p>
        </div>

        const configuration =
        <div>
            <p>Use one of the three methods described above to make the Tesults Java API available as a library and then import the com.tesults.tesults package in your code:</p>
            <pre className="docsCode">
                <code className="accentb4">import com.tesults.tesults.Results;</code> 
            </pre>
        </div>

        const usage =
        <div>
            <p>Upload results using the upload method in the Results class:</p>
            <pre className="docsCode">
                {'//'} Results upload - the single point of contact between your code and Tesults<br/>
                <code className="accentb4">val response = Results.upload(data)</code><br/><br/>
                {'//'} Response output:<br/>
                println("success: " + response["success"])<br/>
                println("message: " + response["message"])<br/>
                println("warnings: " + (response["warnings"] as List&lt;String&gt;).size);<br/>
                println("errors: " + (response["errors"] as List&lt;String&gt;).size);<br/><br/>
            </pre>
            <p className="mt-3">The upload method returns a response that you can use to check whether the upload was a success.</p>
            <p>Value for key "success" is a Boolean: true if results successfully uploaded, false otherwise.</p>
            <p>Value for key "message" is a String: if success is false, check message to see why upload failed.</p>
            <p>Value for key "warnings" is a List&lt;String&gt;, if size is not zero there may be issues with file uploads.</p>
            <p>Value for key "errors" is a List&lt;String&gt;, if "success" is true then this will be empty.</p>
        </div>

        const example =
        <div>
            <p>The data param passed to upload is a Hashmap&lt;String, Any&gt; containing your results data. Here is a complete example showing how to populate data with your build and test results and then complete upload to Tesults with a call to Results.upload(data):</p>
            <i><small>Complete example:</small></i>
            <br/>
            <pre className="docsCode">
                <code className="accentc6">
                {'//'} Required imports:<br/>
                import java.util.ArrayList<br/>
                import java.util.HashMap<br/>
                import java.util.List<br/>
                import com.tesults.tesults.Results&nbsp;&nbsp;
                {'//'} Tesults Java API available as library for using Results class<br/><br/>
                </code>

                fun main(args: Array&lt;String&gt;) {'{'}<br/>
                <code className="accentc6">
                &nbsp;&nbsp;{'//'} In main function as an example only, ordinarily this code would be<br/>
                &nbsp;&nbsp;{'//'} located wherever your results data is available.<br/><br/>
                </code>

                <code className="accentc6">&nbsp;&nbsp;{'//'} A list to hold your test cases.<br/></code>
                &nbsp;&nbsp;val testCases = new ArrayList&lt;Map&lt;String, Any&gt;&gt;();<br/><br/>

                <code className="accentc6">
                &nbsp;&nbsp;{'//'} Each test case is of HashMap&lt;String,Any&gt; type. We use generic types rather than<br/>
                &nbsp;&nbsp;{'//'} concrete helper classes so that if and when the Tesults services adds more fields<br/>
                &nbsp;&nbsp;{'//'} you do not have to update the library.<br/><br/>
                
                &nbsp;&nbsp;{'//'} You would usually add test cases in a loop taking the results from the data objects<br/>
                &nbsp;&nbsp;{'//'} in your build/test scripts.<br/>
                </code>
                &nbsp;&nbsp;val testCase1 = HashMap&lt;String, Any&gt;()<br/>
                &nbsp;&nbsp;testCase1["name"] = "Test 1"<br/>
                &nbsp;&nbsp;testCase1["desc"] = "Test 1 description"<br/>
                &nbsp;&nbsp;testCase1["suite"] = "Suite A"<br/>
                &nbsp;&nbsp;testCase1["result"] = "pass"<br/><br/>

                &nbsp;&nbsp;testCases.add(testCase1)<br/><br/>

                &nbsp;&nbsp;val testCase2 = HashMap&lt;String, Any&gt;()<br/>
                &nbsp;&nbsp;testCase2["name"] = "Test 2"<br/>
                &nbsp;&nbsp;testCase2["desc"] = "Test 2 description"<br/>
                &nbsp;&nbsp;testCase2["suite"] = "Suite B"<br/>
                &nbsp;&nbsp;testCase2["result"] = "pass"<br/><br/>
                
                <code className="accentc6">&nbsp;&nbsp;{'//'} (Optional) For a paramaterized test case:<br/></code>
                &nbsp;&nbsp;val params = HashMap&lt;String, String&gt;();<br/>
                &nbsp;&nbsp;params["param1"] = "value1"<br/>
                &nbsp;&nbsp;params["param2"] = "value2"<br/>
                &nbsp;&nbsp;testCase2["params"] = params<br/><br/>

                <code className="accentc6">&nbsp;&nbsp;{'//'} (Optional) Custom fields start with an underscore:<br/></code>
                &nbsp;&nbsp;testCase2["_CustomField"] = "Custom field value"<br/><br/>

                &nbsp;&nbsp;testCases.add(testCase2)<br/><br/>

                &nbsp;&nbsp;val testCase3 = HashMap&lt;String, Any&gt;();<br/>
                &nbsp;&nbsp;testCase3["name"] = "Test 3"<br/>
                &nbsp;&nbsp;testCase3["desc"] = "Test 3 description"<br/>
                &nbsp;&nbsp;testCase3["suite"] = "Suite A"<br/>
                &nbsp;&nbsp;testCase3["result"] = "fail"<br/>
                &nbsp;&nbsp;testCase3["reason"] = "Assert fail in line 203 of example.java."<br/><br/>

                &nbsp;&nbsp;<code className="accentc6">{'//'} (Optional) Add start and end time for test:</code><br/>
                &nbsp;&nbsp;<code className="accentc6">{'//'} In this example, start is offset to 60 seconds earlier</code><br/> 
                &nbsp;&nbsp;<code className="accentc6">{'//'} but it should be set to current time when the test starts</code><br/>

                &nbsp;&nbsp;testCase3["start"] = System.currentTimeMillis() - 60000;<br/>
                &nbsp;&nbsp;testCase3["end"] = System.currentTimeMillis();<br/><br/>

                <code className="accentc6">&nbsp;&nbsp;{'//'} (Optional) For uploading files:<br/></code>
                &nbsp;&nbsp;val files = ArrayList&lt;String&gt;()<br/>
                &nbsp;&nbsp;files.add("/Path/to/file/log.txt")<br/>
                &nbsp;&nbsp;files.add("/Path/to/file/screencapture.png")<br/>
                &nbsp;&nbsp;files.add("/Path/to/file/metrics.xls")<br/>
                &nbsp;&nbsp;testCase3["files"] = files<br/><br/>

                <code className="accentc6">&nbsp;&nbsp;{'//'} (Optional) For adding test steps:<br/></code>
                &nbsp;&nbsp;val steps = new ArrayList&lt;Map&lt;String, Any&gt;&gt;();<br/><br/>
                &nbsp;&nbsp;val step1 = HashMap&lt;String, Any&gt;();<br/>
                &nbsp;&nbsp;step1["name"] = "Step 1"<br/>
                &nbsp;&nbsp;step1["desc"] = "Step 1 description"<br/>
                &nbsp;&nbsp;step1["result"] = "pass"<br/>
                &nbsp;&nbsp;steps.add(step1)<br/><br/>

                &nbsp;&nbsp;val step2 = HashMap&lt;String, Any&gt;();<br/>
                &nbsp;&nbsp;step2["name"] = "Step 2"<br/>
                &nbsp;&nbsp;step2["desc"] = "Step 2 description"<br/>
                &nbsp;&nbsp;step2["result"] = "fail"<br/>
                &nbsp;&nbsp;step2["reason"] = "Assert fail in line 203 of example.java."<br/>
                &nbsp;&nbsp;steps.add(step2)<br/><br/>

                &nbsp;&nbsp;testCase3["steps"] = steps<br/><br/>

                &nbsp;&nbsp;testCases.add(testCase3)<br/><br/>

                <code className="accentc6">&nbsp;&nbsp;{'//'} HashMap&lt;String, Any&gt; to hold your test results data.<br/></code>
                <code className="accentb4">
                &nbsp;&nbsp;Map&lt;String, Object&gt; data = new HashMap&lt;String, Any&gt;()<br/>
                &nbsp;&nbsp;data["target"] = "token"<br/><br/>

                &nbsp;&nbsp;val results = HashMap&lt;String, Any&gt;()<br/>
                &nbsp;&nbsp;results["cases"] = testCases<br/>
                &nbsp;&nbsp;data["results"] = results<br/><br/>
                </code>

                <code className="accentc6">&nbsp;&nbsp;{'//'} Upload<br/></code>
                <code className="accentb4">&nbsp;&nbsp;val response = Results.upload(data)<br/></code>
                &nbsp;&nbsp;println("success: " + response["success"])<br/>
                &nbsp;&nbsp;println("message: " + response["message"])<br/>
                &nbsp;&nbsp;println("warnings: " + (response["warnings"] as List&lt;String&gt;).size)<br/>
                &nbsp;&nbsp;println("errors: " + (response["errors"] as List&lt;String&gt;).size)<br/>
                {'}'}<br/><br/>
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
            <p>Each test case added to cases must have a name and a result. The result value must be one of 'pass', 'fail' or 'unknown'. All other fields are optional: desc, suite, files, params, steps, reason and custom fields (prefixed with underscore).</p>
        </div>

        return (
            <DocsLanguage
                language="kotlin"
                helmet={helmet}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsKotlin;