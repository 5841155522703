/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsRobot extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Robot Framework test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Robot test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <p>If you are not using Robot Framework view the <NavLink to="/docs/python" target="_blank" className="site-link-primary2">Python</NavLink> docs for information about integrating with a lower level library.</p>

        const installation = 
        <div>
            <p>The <a className="site-link-primary2" href="https://pypi.python.org/pypi/tesults">Tesults Python library</a> and <a className="site-link-primary2" href="https://pypi.python.org/pypi/robot-tesults">Tesults Listener for Robot Framework</a> are hosted on PyPI and are compatible with Python 2 and 3.</p>
            <p>First install tesults:</p>
            <pre className="docsCode">
                pip install tesults
            </pre>
            <p>Then install robot-tesults, which depends on the tesults package above:</p>
            <pre className="docsCode">
                pip install robot-tesults
            </pre>
        </div>

        const configuration = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">target<span className="neutral7 ml-5">Required</span></pre>
            <p>A target token is required to push results to Tesults. If this arg is not provided robot-tesults does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <p><b>Inline method</b></p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:target=eyJ0eXAiOiJ... robot.tests</pre>
            <p>In this case, the target token is supplied directly or inline in the commandline args. This is the simplest approach.</p>
            <p>Notice that Robot Framework expects listener args to be provided separated by colons (:). That is:</p>
            <pre className="docsCode">
                robot --listener TesultsListener:arg1:arg2:arg3 robot.tests
            </pre>
            <p><b>Key method</b></p>
            <pre className="docsCode pre-wrap">robot --listener TesultsListener:target=target1:config=configFile robot.tests</pre>
            <p>In this case, robot-tesults will automatically look up the value of the token based on the property provided from a configuration file.</p>
            <p>Here is the corresponding tesults.config file:</p>
            <pre className="docsCode pre-wrap">
            [tesults]<br/>
            target1 = eyJ0eXAiOiJ...<br/>
            target2 = ...<br/>
            target3 = ...<br/>
            target4 = ...<br/>
            </pre>
            <p>Or something more descriptive about the targets:</p>
            <pre className="docsCode pre-wrap">
            [tesults]<br/>
            web-qa-env = eyJ0eXAiOiJ...<br/>
            web-staging-env = ...<br/>
            web-prod-env = ...<br/><br/>
            ios-qa-env = eyJ0eXAiOiK...<br/>
            ios-staging-env = ...<br/>
            ios-prod-env = ...<br/><br/>
            android-qa-env = eyJ0eXAiOiL...<br/>
            android-staging-env = ...<br/>
            android-prod-env = ...<br/>
            </pre>
            <pre className="primary2 docsArgsCode pl-0">config<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide the path, including the file name to a .config file. Args can be provided in a config file instead of the robot command.</p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:config=/path-to-config/tesults.config</pre>
            <Box
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={
                    <div>
                        <p>At this point robot-tesults will push results to Tesults when you run your robot command with TesultsListener. <b>The robot-tesults plugin requires the target arg be supplied to indicate which target to use</b> as described above.</p>
                        <pre className="docsArgsCode">
                            robot --listener TesultsListener:target=token robot.tests
                        </pre>
                    </div>
                }
            />
        </div>

        const files = 
        <div>
            <p>Provide the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:files=/Users/admin/Desktop/temporary</pre>
            <p>This is one area where robot-tesults is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Also be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:build-name=1.0.0</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:build-result=pass</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">build-desc<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:build-desc='added new feature'</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">robot --listener TesultsListener:build-reason='build error line 201 somefile.py'</pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                build={build}
                integration="robot"
            />
        );
    }
}

export default DocsRobot;