/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsPytest extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Pytest test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle pytest test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <p>If you are not using pytest view the <NavLink to="/docs/python" target="_blank" className="site-link-primary2">Python</NavLink> docs for information about integrating with a lower level library.</p>

        const installation = 
        <div>
            <p>First install tesults:</p>
            <pre className="docsCode">
                pip install tesults
            </pre>
            <p>Then install the pytest-tesults plugin, which depends on the tesults package above:</p>
            <pre className="docsCode">
                pip install pytest-tesults
            </pre>
        </div>

        const configuration =
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-target<span className="neutral7 ml-5">Required</span></pre>
            <p>You must provide your target token to push results to Tesults. If this arg is not provided the pytest-tesults plugin does not attempt upload, effectively disabling it. You get your target token on project or target creation and can regenerate one at anytime from the configuration menu.</p>
            <p className="neutral2"><b>Inline method (Command)</b></p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-target eyJ0eXAiOiJ...</pre>
            <p>In this case, the target token is supplied directly or inline in the commandline args. This is the simplest approach.</p>
            <p className="neutral2"><b>Key method (Configuration File)</b></p>
            <pre className="docsCode pre-wrap">pytest --tesults-target target1</pre>
            <p>In this case, the pytest-tesults plugin will automatically look up the value of the token based on the property provided from a configuration file or environment variable. Specifically the standard pytest configuration files are checked in this order: pytest.ini, pyproject.toml, tox.ini, setup.cfg and environment variable. Note that pytest.ini is always used if found, and tox.ini must contain a [pytest] section, setup.cfg must contain a [tool:pytest] section, pyproject.toml must contain a [tool.pytest.ini_options] section. This is standard <a className="site-link-primary2" href="https://docs.pytest.org/en/latest/customize.html">pytest configuration</a> behavior.</p>
            <p>Here are examples with each type of configuration file:</p>
            <h4 className="neutral4">pytest.ini</h4>
            <pre className="docsCode pre-wrap">
            [tesults]<br/>
            target1 = eyJ0eXAiOiJ...<br/>
            target2 = ...<br/>
            target3 = ...<br/>
            target4 = ...<br/>
            </pre>
            <p>You may want to be more descriptive about what each target corresponds to:</p>
            <pre className="docsCode pre-wrap">
            [tesults]<br/>
            web-qa-env = eyJ0eXAiOiJ...<br/>
            web-staging-env = ...<br/>
            web-prod-env = ...<br/><br/>
            ios-qa-env = eyJ0eXAiOiK...<br/>
            ios-staging-env = ...<br/>
            ios-prod-env = ...<br/><br/>
            android-qa-env = eyJ0eXAiOiL...<br/>
            android-staging-env = ...<br/>
            android-prod-env = ...<br/>
            </pre>
            <h4 className="neutral4">pyproject.toml</h4>
            <pre className="docsCode pre-wrap">
            # pyproject.toml<br/>
            [tool.pytest.ini_options]<br/>
            minversion = "6.0"<br/>
            addopts = "-ra -q"<br/>
            testpaths = [<br/>
            &nbsp;&nbsp;"tests",<br/>
            &nbsp;&nbsp;"integration",<br/>
            ]<br/><br/>
            [tesults]<br/>
            target1 = "eyJ0eXAiOiJ..."<br/>
            target2 = "eyJ0eXAiOiK..."<br/>
            </pre>
            <h4 className="neutral4">tox.ini</h4>
            <pre className="docsCode pre-wrap">
            [pytest]<br/><br/>
            [tesults]<br/>
            target1 = eyJ0eXAiOiJ...<br/>
            target2 = eyJ0eXAiOiK...
            </pre>
            <h4 className="neutral4">setup.cfg</h4>
            <pre className="docsCode pre-wrap">
            [tool:pytest]<br/><br/>
            [tesults]<br/>
            target1 = eyJ0eXAiOiJ...<br/>
            target2 = eyJ0eXAiOiK...
            </pre>
            <h4 className="neutral4">environment variable</h4>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-target ENV_TARGET_TOKEN</pre>
            <p>In this case, if an environment variable exists with name ENV_TARGET_TOKEN, the value of the variable will be used for the target token.</p>
            <br/><br/>
            <Box
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point the pytest-tesults plugin will have self registered and will push results to Tesults when you run your pytest command and supply the <b>tesults-target</b> arg.</p>}
            />
        </div>

        const enhancedReporting = 
        <div>
            <h4>file</h4>
            <p>Use Tesults to report additional properties for each test case. To upload files generated during a test case such as logs or screenshots, require the file function in your test file:</p>
            <pre className='docsCode'>
                from pytest_tesults import file
            </pre>
            <p>Associate a file to a test case in order to upload it for reporting. Utilize the built in pytest 'request' fixture and pass it into the file function as the first parameter. The second parameter is the full path to the generated file to upload for the test case.</p>
            <pre className='docsCode'>
            def test1(request):<br/>
            &nbsp;&nbsp;file(request, '/full/path/to/file')
            </pre>
            <p>Example:</p>
            <pre className='docsCode'>
            import pytest<br/>
            from pytest_tesults import file<br/><br/>
            pytestmark = pytest.mark.suite("test_suite_a")<br/>
            @pytest.mark.description("test 1 description")<br/>
            def test1(request):<br/>
            &nbsp;&nbsp;<code className="accentc6">file</code>(request, '/Users/admin/log.txt')<br/>
            &nbsp;&nbsp;<code className="accentc6">file</code>(request, '/Users/admin/screenshot.png')<br/>
            &nbsp;&nbsp;assert 5 == 5
            </pre>
        </div>

        const files = 
        <div>
            <pre className="docsArgsCode">tesults-files<span className="neutral7 ml-5">Optional</span></pre>
            <p className='accenta3 bold font12'>This method of uploading files is no longer recommended starting from pytest-tesults 1.6.0+. If using pytest-tesults 1.6.0 or newer, utilize the file method described above to simplify uploading files from tests.</p>
            <p>If you want to save files such as logs and screen captures, provide the absolute path to the top-level directory where this data is saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically pushed to Tesults.</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-files /Users/admin/Desktop/temporary</pre>
            <p>This is one area where the pytest-tesults plugin is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Please see the markers section for more details on how to provide a suite name and also take a look at the tesults-nosuites flag below. The default behavior of the pytest-tesults plugin is to set the module name as the test suite if a test suite is not explicitly provided using a marker. Also be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="docsArgsCode">tesults-build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-build-name 1.0.0</pre>
            <hr/>
            <pre className="docsArgsCode">tesults-build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-build-result pass</pre>
            <hr/>
            <pre className="docsArgsCode">tesults-build-description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-build-description 'added new feature'</pre>
            <hr/>
            <pre className="docsArgsCode">tesults-build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-build-reason 'build error line 201 somefile.py'</pre>
            <h4 className="mt-5">No Test Suites</h4>
            <hr/>
            <pre className="docsArgsCode">tesults-nosuites<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this flag to stop automatic setting of the module name as the test suite in the case where a suite is not explicitly supplied using @pytest.mark.suite("suite name here")</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-nosuites</pre>
            <br/>
            <pre className="docsArgsCode">tesults-save-stdout<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this flag to save any print output (using Python's print function) to stdout within test cases to a file and upload this file as part of results automatically.</p>
            <pre className="docsCode pre-wrap-break-word">pytest --tesults-save-stdout</pre>
            <br/>
            <h4 className="mt-5">Markers</h4>
            <h5 className="mt-5">suite</h5>
            <p>Set a suite name either for a specific test case or a whole module. If a suite is not provided the pytest-tesults plugin automatically sets the module name as the suite. This behavior can be disabled using the tesults-nosuites flag.</p>
            <pre className="docsCode">
                @pytest.mark.suite("suite name here")
            </pre>
            <h5 className="mt-5">description</h5>
            <p>To have a test description for reporting, decorate your test functions in this way:</p>
            <pre className="docsCode">
                @pytest.mark.description("test description")
            </pre>
            <h5 className="mt-5">parametrize</h5>
            <p>To have the test parameters picked up for reporting, decorate your test functions in this way:</p>
            <pre className="docsCode">
                @pytest.mark.parametrize(("test_input","expected"), [("3+5", 8),("2+4", 6),("6*9", 42),])
            </pre>
            <h5 className="mt-5">custom markers</h5>
            <p>If you are using pytest 4, any custom fields can be added as custom markers</p>
            <p>Example 1</p>
            <pre className="docsCode">
                @pytest.mark.custommarker("this is a custom marker")
            </pre>
            <p>Example 2</p>
            <pre className="docsCode">
                @pytest.mark.anotherone("this is another custom field")
            </pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                enhancedReporting={enhancedReporting}
                files={files}
                build={build}
                integration="pytest"
            />
        );
    }
}

export default DocsPytest;