/*global */
import React, { Component } from 'react';
import {StripeProvider, Elements} from 'react-stripe-elements';
import InfoToolTip from './InfoToolTip';
import { Link } from 'react-router-dom'
import MinUsers from './MinUsers';
import Payment from './Payment';
import TimeFormatted from './TimeFormatted';
import FairBillingPolicy from './FairBillingPolicy';
import PaymentByInvoice from './PaymentByInvoice';
//import Constants from './Constants';
//import Analytics from './Analytics';

let apiKey;
if (process.env.NODE_ENV !== "production") {
  apiKey = "pk_test_9LEG3Rq029W4Z3S167A7cxVI"; // test
} else {
  apiKey = "pk_live_AszODbEm3Y8DT612VNWGScHj"; // live (production)
}

class Checkout extends Component {
    constructor () {
        super();
        this.state = {
            phase: 0,
            activeUsers: 1,
            minUsers: 1,
            interval: 'year',
            payment: 'card',
            changeMinUsers: false,
            confirmation: {success: undefined, failure: undefined},
            width: window.innerWidth, 
            height: window.innerHeight,
            countrySelectionComplete : false
        }
        this.resizeEvent = this.resizeEvent.bind(this);
        this.product = this.product.bind(this);
        this.price = this.price.bind(this);
        this.setInterval = this.setInterval.bind(this);
        this.setMinUsers = this.setMinUsers.bind(this);
        this.setPayment = this.setPayment.bind(this);
        this.nextPhase = this.nextPhase.bind(this);
        this.currentPlan = this.currentPlan.bind(this);
        this.currentPricing = this.currentPricing.bind(this);
        this.minUsersChange = this.minUsersChange.bind(this);
        this.minUsersChangeConfirm = this.minUsersChangeConfirm.bind(this);
        this.minUsersCancelChange = this.minUsersCancelChange.bind(this);
        this.countrySelectionComplete = this.countrySelectionComplete.bind(this);
    }

    componentDidMount () {
        window.addEventListener('resize', this.resizeEvent);
        if (this.props.details === true) {
            this.setState(
                {
                    phase: -1, 
                    activeUsers: Math.min(this.props.plan.activeUsers, this.props.currentActiveUsers), 
                    minUsers: this.props.plan.minUsers, 
                    product: this.props.plan.name, 
                    interval: this.props.plan.interval,
                    countrySelectionComplete: false
                }
            );
        }
        if (this.props.price === true) {
            this.setState(
                {
                    phase: -2, 
                    activeUsers: Math.min(this.props.plan.activeUsers, this.props.currentActiveUsers), 
                    minUsers: this.props.plan.minUsers, 
                    product: this.props.plan.name, 
                    interval: this.props.plan.interval,
                    countrySelectionComplete: false
                }
            );
        }
        if (this.props.plan !== undefined) {
            console.log(this.props.plan)
            this.setState(
                {
                    activeUsers: Math.min(this.props.plan.activeUsers, this.props.currentActiveUsers), 
                    minUsers: this.props.plan.minUsers
                }
            );
        }
    }
   
    componentWillUnmount() {
       window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
        this.setState({ width: window.innerWidth, height: window.innerHeight});
    }    

    product () {
        if (this.props.product === "free-v1") {
            return (
                <div>
                    <h2>Free</h2>
                    <h4>$0</h4>
                </div>
            );
        } else if (this.props.product === "standard-v1") {
            let price = "$9.67";
            if (this.state.interval === "month") {
                price = "$11.60";
            }
            return (
                <div>
                    <h2>Standard</h2>
                    <InfoToolTip overlay={this.props.overlay} position="bottom" element={<span><h3>{price}<span className="font12 neutral4 tooltipLink">/active user/month</span></h3></span>} message={<div className="p-4 font12"><b>$9.67</b> per active user per month when paying yearly.<br/><br/><b>$11.60</b> per active user per month if paying monthly.</div>}/>
                </div>
            );
        } else if (this.props.product === "standard-20240719") {
            let price = "$19.00";
            if (this.state.interval === "month") {
                price = "$20.65";
            }
            return (
                <div>
                    <h2>Standard</h2>
                    <InfoToolTip overlay={this.props.overlay} position="bottom" element={<span><h3>{price}<span className="font12 neutral4 tooltipLink">/active user/month</span></h3></span>} message={<div className="p-4 font12"><b>$19.00</b> per active user per month when paying yearly.<br/><br/><b>$20.65</b> per active user per month if paying monthly.</div>}/>
                </div>
            );
        } else if (this.props.product === "plus-v1") {
            let price = "$19.05";
            if (this.state.interval === "month") {
                price = "$22.86";
            }
            return (
                <div>
                    <h2>Plus</h2>
                    <InfoToolTip overlay={this.props.overlay} position="bottom" element={<span><h3>{price}<span className="font12 neutral4 tooltipLink">/active user/month</span></h3></span>} message={<div className="p-4 font12"><b>$19.05</b> per active user per month when paying yearly.<br/><br/><b>$22.86</b> per active user per month if paying monthly.</div>}/>
                </div>
            );
        } else if (this.props.product === "plus-20240719") {
            let price = "$37.00";
            if (this.state.interval === "month") {
                price = "$40.22";
            }
            return (
                <div>
                    <h2>Plus</h2>
                    <InfoToolTip overlay={this.props.overlay} position="bottom" element={<span><h3>{price}<span className="font12 neutral4 tooltipLink">/active user/month</span></h3></span>} message={<div className="p-4 font12"><b>$37.00</b> per active user per month when paying yearly.<br/><br/><b>$40.22</b> per active user per month if paying monthly.</div>}/>
                </div>
            );
        }  else if (this.props.product === "enterprise") {
            return (
                <div>
                    <h2>Enterprise</h2>
                    <h4 className="neutral5">&nbsp;</h4>
                </div>
            );
        } else {
            return (
                <div>
                    <h2 className="bold">Legacy plan</h2>
                    <h4 className="neutral5">&nbsp;</h4>
                </div>
            );
        }
    }

    price () {
        let users = this.state.activeUsers;
        if (this.state.activeUsers < this.state.minUsers) {
            users = this.state.minUsers;
        }
        let price = "Invalid";

        if (this.props.product === "free-v1") {
            price = 0;
        } else if (this.props.product === "standard-v1") {
            if (this.state.interval === "month") {
                price = (users * 11.60).toFixed(2);
            } else if (this.state.interval === "year") {
                price = (users * 9.67 * 12).toFixed(2);
            }
        } else if (this.props.product === "standard-20240719") {
            if (this.state.interval === "month") {
                price = (users * 20.65).toFixed(2);
            } else if (this.state.interval === "year") {
                price = (users * 19.00 * 12).toFixed(2);
            }
        } else if (this.props.product === "plus-v1") {
            if (this.state.interval === "month") {
                price = (users * 22.86).toFixed(2);
            } else if (this.state.interval === "year") {
                price = (users * 19.05 * 12).toFixed(2);
            }
        } else if (this.props.product === "plus-20240719") {
            if (this.state.interval === "month") {
                price = (users * 40.22).toFixed(2);
            } else if (this.state.interval === "year") {
                price = (users * 37.00 * 12).toFixed(2);
            }
        }  else if (this.props.product === "enterprise") {
            if (this.state.interval === "month") {
                price = "Contact";
            } else if (this.state.interval === "year") {
                price = "Contact";
            }
        }

        return price;
    }

    setInterval (interval) {
        this.setState({interval: interval});
    }

    setMinUsers (minUsers) {
        if (minUsers < 1) {
            minUsers = 1;
        }
        if (minUsers > 100) {
            minUsers = 100;
        }
        this.setState({minUsers: minUsers});
    }

    setPayment (type) {
        this.setState({payment: type});
    }

    nextPhase () {
        const phase = this.state.phase + 1;
        this.setState({phase: phase});
    }

    minUsersChange () {
        this.setState({minUsersEdit: true});
    }

    minUsersChangeConfirm () {
        
    }
    
    minUsersCancelChange () {
        this.setState({minUsersEdit: false, minUsers: this.props.plan.minUsers});
    }

    content () {
        if (this.state.phase === 0) {
            return <FairBillingPolicy/>
        }
        if (this.state.phase === 1) {
            let paymentInfo =
                <StripeProvider apiKey={apiKey}>
                    <Elements>
                        <Payment countrySelectionComplete={this.countrySelectionComplete} paymentType={this.state.payment} project={this.props.project} product={this.props.product} interval={this.state.interval} activeUsers={this.state.activeUsers} minUsers={this.state.minUsers} cancel={this.props.cancel} backToProductSelect={this.props.backToProductSelect} complete={this.props.complete} type={this.props.type} name={this.props.name} orgName={this.props.orgName}/>
                    </Elements>
                </StripeProvider>
            return (
                <div>
                    {
                        this.state.countrySelectionComplete === true ?
                        <div className="row mb-5">
                            <div className="col-6">
                                <span onClick={() => this.setPayment ("card")} className={"clickable font15 " + (this.state.payment === "invoice" ? "neutral4" : "menubarActiveBorder")}>Pay by card</span>
                            </div>
                            <div className="col-6">
                                <span onClick={() => this.setPayment ("invoice")} className={"clickable font15 " + (this.state.payment === "card" ? "neutral4" : "menubarActiveBorder")}>Pay by invoice (bank transfer)</span>
                            </div>
                            <hr/>
                        </div>
                        :
                        <div></div>                    
                    }
                    {paymentInfo}
                </div>
            );
        }
    }

    currentPlan () {
        if (this.props.plan.name === "free-v1") {
            // Free
            return (
                <div>
                    {this.product()}
                    <div class="font15 mb-3">
                        <span>{this.props.plan.targets} targets</span>
                        <br/>
                        <span>{this.props.plan.cases} test cases per test run</span>
                        <br/>
                        <span>10 lists</span>
                        <br/>
                        <span>10 runs</span>
                        <br/>
                        <span>{this.props.plan.cases} test cases per test lists and runs</span>
                        <br/>
                        <span>{this.props.plan.data} GB data</span>
                        <br/>
                        <span>{this.props.plan.files} files</span>
                        <br/>
                        <span>{this.props.plan.users} users</span>
                        <br/>
                        <span>30 days rolling history</span>
                        <br/>
                        <br/>
                        <p>Upgrade to the Standard plan today to get <b>unlimited</b> targets, lists, runs, test cases, data and files!</p>
                    </div>
                </div>
            );
        } else if (this.props.plan.name === "standard-v1" || this.props.plan === "standard-20240719") {
            // Standard
            return (
                <div>
                    {this.product()}
                    <div className="font14 mb-3">
                        <span>Unlimited targets</span>
                        <br/>
                        <span>Unlimited test cases per test run</span>
                        <br/>
                        <span>Unlimited lists</span>
                        <br/>
                        <span>Unlimited runs</span>
                        <br/>
                        <span>Unlimited test cases per test lists and runs</span>
                        <br/>
                        <span>50 GB data per active user per month</span>
                        <br/>
                        <span>100,000 files per active user per month</span>
                        <br/>
                        <br/>
                        <p>Upgrade to the Plus plan today to double the data and files per active user per month and add priority support.</p>
                        <br/>
                        <br/>
                        <span>Billing interval: {this.props.plan.interval}</span>
                        <br/>
                        <span>Billing cycle: <TimeFormatted dt={this.props.plan.billingCycleEnd}/></span>
                        <br/>
                        <span>{this.state.activeUsers} Active users</span>
                        <br/>
                        <span>{this.props.plan.minUsers} Minimum users</span>
                    </div>
                </div>
            );
        } else if (this.props.plan.name === "plus-v1" || this.props.plan.name === "plus-20240719") {
            // Plus
            return (
                <div>
                    {this.product()}
                    <div className="font14 mb-3">
                        <span>Unlimited targets</span>
                        <br/>
                        <span>Unlimited test cases per test run</span>
                        <br/>
                        <span>Unlimited lists</span>
                        <br/>
                        <span>Unlimited runs</span>
                        <br/>
                        <span>Unlimited test cases per test lists and runs</span>
                        <br/>
                        <span>100 GB data per active user per month</span>
                        <br/>
                        <span>200,000 files per active user per month</span>
                        <br/>
                        <br/>
                        <span>Billing interval: {this.props.plan.interval}</span>
                        <br/>
                        <span>Billing cycle: <TimeFormatted dt={this.props.plan.billingCycleEnd}/></span>
                        <br/>
                        <span>{this.state.activeUsers} Active users</span>
                        <br/>
                        <span>{this.props.plan.minUsers} Minimum users</span>
                    </div>
                </div>
            );
        } else if (this.props.plan.name === "enterprise") {
            // Enterprise
            let product = this.product();
            return (
                <div className="mb-3">
                    {product}
                </div>
            );
        } else {
            // Legacy
            return (
                <div>
                    {this.product()}
                    <div className="font14 mb-3">
                        <span>{this.props.plan.targets} targets</span>
                        <br/>
                        <span>{this.props.plan.cases} test cases per test run</span>
                        <br/>
                        <span>{this.props.plan.data} GB data</span>
                        <br/>
                        <span>{this.props.plan.files} files</span>
                        <br/>
                        <span>{this.props.plan.users} users</span>
                        <br/>
                        <span>30 days rolling history</span>
                        <br/>
                        <br/>
                        <p>This project is on a legacy plan. We recommend you upgrade to a modern plan and gain <b>unlimited users</b> even on on the free plan and <b>unlimited targets</b> on any paid plan.</p>
                    </div>
                </div>
            );
        }
    }

    currentPricing () {
        if (this.props.plan.name !== "free-v1" && 
            this.props.plan.name !== "standard-v1" &&
            this.props.plan.name !== "plus-v1" && 
            this.props.plan.name !== "standard-20240719" && 
            this.props.plan.name !== "plus-20240719") {
            return <span></span>
        }
        let changeMinUsers = <button className="btn btn-transparent" onClick={this.minUsersChange}><span className='underline_dotted'>Change minimum users to bill</span></button>
        if (this.state.minUsersEdit === true) {
            changeMinUsers = 
                <div>
                    <MinUsers project={this.props.project} plan={this.props.plan} phase={this.state.phase} minUsersChange={this.setMinUsers} minUsers={this.state.minUsers}/>
                </div>
        }
        return (
            <div>
                <p className="font15 card-text">Current price: ${this.price()} {(this.state.interval === undefined) ? "" : (" per " + this.state.interval)}</p>
                <p className="font15 card-text">Based on {this.state.activeUsers} active users with minimum users billing set to {this.state.minUsers}</p>
                {changeMinUsers}    
            </div>
        );
    }

    countrySelectionComplete () {
        this.setState({countrySelectionComplete: true});
    }

    render () {
        if (this.state.phase === -1) { // Summary (existing plan details)
            return this.currentPlan();
        }
        if (this.state.phase === -2) { // Pricing (existing plan pricing)
            return this.currentPricing();
        }

        let product = this.product();
        let price = this.price();
        let buy = <div className="mt-5 mb-5"><button className="btn-confirm width100" onClick={this.nextPhase}>Buy now</button></div>
        if (this.state.phase > 0) {
            buy = <span></span>
        }
        // if (this.props.plan !== undefined) {
        //     if (this.props.plan.name === this.props.product) {
        //         if (this.props.plan.interval === this.state.interval) {
        //             buy = <div className="mt-5 mb-5"><button className="btn-confirm width100" disabled>Existing plan</button></div>
        //         }
        //     }
        // }
        let usersToBillFor = this.state.minUsers > this.state.activeUsers ? this.state.minUsers : this.state.activeUsers
        return (
            <div style={{"display":"flex"}}>
                <div style={{"flex":"1"}} className="p-4 whitebg font14">
                    {product}
                    <div style={{"display":"flex"}}>
                        <div style={{"flex":"1"}} className="mr-3">
                            <button type="button" className={"btn-transparent font14 " + ((this.state.interval === "year") ? "neutral2 buy-period-active active" : "neutral4") + (this.state.phase > 0 ? " disabled" : "")} onClick={() => this.setInterval("year")}>Bill Yearly</button>
                        </div>
                        <div style={{"flex":"1"}}>
                            <button type="button" className={"btn-transparent font14 " + ((this.state.interval === "month") ? "neutral2 buy-period-active active" : "neutral4") + (this.state.phase > 0 ? " disabled" : "")} onClick={() => this.setInterval("month")}>Bill Monthly</button>
                        </div>
                    </div>
                    <p className="card-text">Bill now for {usersToBillFor} active {usersToBillFor > 1 ? "users" : "user"}</p>
                    <p className="card-text">Total due now: <b>${price}</b></p>
                    <p className="card-text">You will be billed again next {this.state.interval} and each {this.state.interval} after that until you cancel.</p>
                    {buy}
                    <hr/>
                    <MinUsers project={this.props.project} plan={this.props.plan} phase={this.state.phase} minUsersChange={this.setMinUsers} minUsers={this.state.minUsers} newPlan={this.props.newPlan}/>
                    <hr/>
                    <div className="mt-5"><button className="btn-cancel" onClick={this.props.backToProductSelect}>Back to options</button></div>
                </div>
                <div style={{"flex":"3"}} class="p-4 font14">
                    {this.content()}
                </div>
            </div>
        );
    }
}

export default Checkout;