/*global $*/
import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Analytics from './Analytics';
import Loading from './Loading';
import CaseList from './CaseList';
import PassRate from './PassRate';
import Confirmation from './Confirmation';
import Request from './Request';
import Cache from './Cache';
import Context from './Context';
import TabularData from './TabularData';
import TimeFormatted from './TimeFormatted';

class ManualArchive extends Component {
    static contextType = Context;

    constructor (props) {
        super(props);
        Analytics.event("ManualArchive");
        this.state = {
            state: null, 
            id: undefined, 
            created: undefined, 
            runs: [],
            esk: undefined, 
            run: undefined, 
            cases: undefined,
            confirmation: {success: undefined, failure: undefined},
            label: ""
        };
        this.parseParams = this.parseParams.bind(this);
        this.run = this.run.bind(this);
        this.runs = this.runs.bind(this);
        this.select = this.select.bind(this);
        this.view = this.view.bind(this);
        this.stateControl = this.stateControl.bind(this);
        this.duplicateRun = this.duplicateRun.bind(this)
        this.duplicateRunConfirm = this.duplicateRunConfirm.bind(this)
        this.changeLabel = this.changeLabel.bind(this)
    }

    componentDidMount () {
        this.stateControl();
    }

    componentDidUpdate(prevProps, prevState) {
        this.stateControl();
    }

    stateControl () {
        let path = window.location.pathname;
        if (path === "/manual/archive" && this.state.state !== "select" && this.state.state !== "duplicate-archive-run") {
            this.setState({state: "select"}, () => this.runs());
        } else if (path.startsWith("/manual/archive/msp")  && (this.state.state !== "view" && this.state.state !== "duplicate-archive-run")) {
            this.parseParams();
        } 
    }

    parseParams () {
        // Checks if manual url includes manual specific path (msp)
        let id = undefined;
        let created = undefined;
        let msp = window.location.pathname.split("/");
        msp.reverse(); // reversed so that in order pop can occur
        msp.pop(); // pops ""
        msp.pop(); // pops "manual"
        msp.pop(); // pops "archive"
        if (msp.pop() === "msp") {
            id = msp.pop()
            created = msp.pop()
        }
        if (id !== undefined && created !== undefined) {
            this.setState({id: id, created: created, state: "view"}, () => this.run())
        } else {
            this.setState({id: undefined, created: undefined, state: "select"}, () => this.runs())
        }
    }

    run () {
        if (this.state.id === undefined || this.state.created === undefined) {
            return
        }
        this.setState({loading: true})
        Request.get("/manualArchive", {id: this.state.id, created: this.state.created}, (err, data) => {
            this.setState({loading: false})
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to fetch archived run"}})
            } else {
                this.setState({
                    run: data.run,
                    confirmation: {success: undefined, failure: undefined}
                }, () => this.cases());
            }
        })
    }
    
    runs () {
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        if (project === undefined) {
            return
        }
        this.setState({loading: true})
        Request.get("/manualArchives", {id: project.id, esk: this.state.esk}, (err, data) => {
            this.setState({loading: false})
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to fetch archived runs"}})
            } else {
                let runs = this.state.runs
                if (runs === undefined) {
                    runs = []
                }
                if (data !== undefined) {
                    if (data.runs !== undefined) {
                        for (let i = 0; i < data.runs.length; i++) {
                            runs.push(data.runs[i])
                        }
                    }
                }
                this.setState({
                    runs: runs,
                    esk: data.lek,
                    confirmation: {success: undefined, failure: undefined}
                })
            }
        });
    }

    view (index) {
        let run = this.state.runs[index]
        this.props.history.push("/manual/archive/msp/" + run.id + "/" + run.created)
    }

    cases () {
        let id = this.state.id
        let created = this.state.created
        if (this.state.run !== undefined) {
            id = this.state.run.id
            created = this.state.run.created
        }
        this.setState({loading: true})
        Request.get("/manualArchiveCases", {id: id, created: created}, (err, data) => {
            this.setState({loading: false})
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to fetch test cases"}})
            } else {
                this.setState({
                    state: "view", cases: data.cases,
                    confirmation: {success: undefined, failure: undefined}
                });
            }
        });
    }

    select () {
        this.setState({runs: [], run: [], id: undefined, created: undefined}, () => this.props.history.push("/manual/archive"))
    }

    duplicateRun (index) {
        let run = this.state.run
        if (index !== undefined) {
            if (index < this.state.runs.length) {
                run = this.state.runs[index];
            }
        }
        if (run !== undefined) {
            this.setState({state: "duplicate-archive-run", confirmation: {success: undefined, failure: undefined}, run: run});
        }
    }

    duplicateRunConfirm () {
        window.scrollTo(0,0);
        this.setState({loading: true});
        let label = this.state.label;
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        let created = this.state.run.created
        let id = project.id
        Request.post("/manualRunDuplicateFromArchive", {id: id, label: label, created: created}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create new duplicate test run.", loading: false});
            } else {
                Analytics.event("RunDuplicatedFromArchive");
                this.setState({state: "select", error: undefined, loading: false, run: undefined, cases: []}, () => {this.props.history.push("/manual"); this.props.history.go();});
            }
        });
    }

    changeLabel (e) {
        this.setState({label: e.target.value})
    }

    render () {
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.state === "select") {
            if (this.state.runs === undefined) {
                return <div>No archived test runs</div>
            }
            let data = {
                headers: [
                    {value: "Test run name", minWidth: "200px"},
                    {value: "Created", minWidth: "100px"},
                    {value: "Archived", minWidth: "100px"},
                    {value: ""},
                    {value: ""},
                ],
                rows: []
            }
            for (let i = 0; i < this.state.runs.length; i++) {
                let run = this.state.runs[i];
                data.rows.push(
                    {
                        cols: [
                            {value: run.label},
                            {value: <TimeFormatted dd={run.created}/>},
                            {value: <TimeFormatted dd={run.updated}/>},
                            {value: <button className="btn-cancel" onClick={() => {this.view(i)}}>View</button>},
                            {value: <button className="btn-cancel" onClick={() => {this.duplicateRun(i)}}>Duplicate new run</button>}
                        ]
                    }
                );
            }
            let loadMore = <span></span>
            if (this.state.esk !== undefined) {
                loadMore = <button className="btn-cancel mt-4" onClick={this.runs}>Load more</button>
            }
            return (
                <div>
                    <TabularData data={data}/>
                    {loadMore}
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
            )
        } else if (this.state.state === "view") {
            if (this.state.run === undefined || this.state.cases === undefined) {
                return <div>Run not loaded</div>
            }
            return (
                <div>
                    <button className="btn-cancel mr-3" onClick={this.select}>Close</button>
                    <button className="btn-cancel" onClick={() => {this.duplicateRun()}}>Duplicate new run</button>
                    <Confirmation confirmation={this.state.confirmation}/>
                    <h4 className="neutral4">{this.state.run.label}</h4>
                    <PassRate
                        type="group" 
                        cases={this.state.cases}
                    />
                    <CaseList 
                        context="manual-archive"
                        manualArchive={this.state.run}
                        cases={this.state.cases}
                        overlay={this.props.overlay}
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        sort={"suite"}
                        projects={this.context.projects}
                        projectIndex={Cache.getPreference(Cache.preference.projectIndex)}
                        members={this.props.members}
                    />
                </div>
            )
        } else if (this.state.state === "duplicate-archive-run") {
            return (
                <div>
                    <div>
                        <h5>Name the new duplicate test run</h5>
                        <input type="text" className="mb-3 tr-input form-control" value={this.state.label} onChange={(e) => this.changeLabel(e)} required/>
                        <p className="accenta1" id="error">{this.props.error}</p>
                    </div>
                    <div>
                        <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={() => this.duplicateRunConfirm()}>Confirm</button>
                        <button onClick={() => {this.setState({state: "select"})}} type="button" className="btn btn-cancel mt-3">Cancel</button>
                    </div>
                </div>
            );
        } else {
            return <div>Invalid state</div>
        }
        
    }
};

export default ManualArchive;