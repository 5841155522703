/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ManualAssigneeSelect from './ManualAssigneeSelect';
import ManualAssignee from './ManualAssignee';
import MultiCaseEditOptions from './MultiCaseEditOptions';
import Confirmation from './Confirmation';
import SideItem from './SideItem';

class ManualSide extends Component {
    constructor() {
        super();
        this.state = {importDisabled: false, assignSelected: false};
        this.listChange = this.listChange.bind(this);
        this.importConfirm = this.importConfirm.bind(this);
        this.importComplete = this.importComplete.bind(this);
        this.assignSelected = this.assignSelected.bind(this);
        this.cancelAssignSelected = this.cancelAssignSelected.bind(this);
        this.projectChange = this.projectChange.bind(this);
        this.checkAllDone = this.checkAllDone.bind(this);
    }

    componentDidMount () {
        this.checkAllDone();        
    }

    componentDidUpdate (prevProps, prevState) {
        this.checkAllDone();
    }

    projectChange (e) {
        this.props.projectChange(e.target.value);
    }

    listChange (e) {
        this.setState({importDisabled: false});
        this.props.listChange(e.target.value);
    }

    importConfirm () {
        this.setState({importDisabled: true});
        this.props.importConfirm();
    }

    importComplete () {
        this.setState({importDisabled: false});
        this.props.cancel();
    }

    assignSelected () {
        this.setState({assignSelected: true});
    }

    cancelAssignSelected () {
        this.setState({assignSelected: false});
    }

    checkAllDone () {
        if (this.props.manualCasesInProgress === undefined) {
            return;
        }
        if (this.props.manualCasesInProgress.length === 0) {
            return;
        }
        let allDone = true;
        this.props.manualCasesInProgress.forEach(function (c) {
            if (c.manualComplete !== undefined) {
                if (c.manualComplete === true || c.manualComplete === "true") {
                    // considered complete
                } else {
                    allDone = false;
                }
            }
        });
        let currentAllDone = this.state.allDone;
        if (allDone !== currentAllDone) {
            this.setState({allDone: allDone});
        }
    }

    render () {
        let runsLink = <SideItem image="/img/stroke-width-unselected.svg" label="Back to test runs" onClick={() => this.props.backToRuns()}/>
        if (this.props.state === "runs") {
            runsLink = <span></span>
        }

        if (this.props.loading === true) {
            return <span></span>
        } else if (this.props.loggedIn === false) {
            return (<div className="row mb-3">
                        <div className="col-12">
                            <div className="card neutral8bg noborder">
                                <div className="card-body text-center">
                                    <img width="64" height="64" className="opacity25" src="/img/runs-neutral5-128.png" alt="Runs"/>
                                    <p className="card-text small neutral6 mt-3">Use test runs to manage testing of a release. Create a test run and import test cases from your test lists. Assign test cases to team members and keep track of progress.</p>
                                </div>
                            </div>
                        </div>
                    </div>);
        } else if (this.props.role < 2) {
            return (<div className="row mb-3">
                        <div className="col-12">
                            <div className="card neutral8bg noborder">
                                <div className="card-body text-center">
                                    <img width="64" height="64" className="opacity25" src="/img/runs-neutral5-128.png" alt="Runs"/>
                                    <p className="card-text small neutral6 mt-3">Use test runs to manage testing of a release. Create a test run and import test cases from your test lists. Assign test cases to team members and keep track of progress.</p>
                                </div>
                            </div>
                        </div>
                    </div>);
        } else if (this.props.state === "runs") {
            return (
            <div>
                <p className="neutral6 mt-3 font14">Use test runs to manage testing of a release. Create a test run and import test cases from your test lists. Assign test cases to team members and keep track of progress.</p>
            </div>
            );
        } else if (this.props.state === "view" || this.props.state === "edit") {
            let submit = <span></span>
            let archive = <span></span>
            if (this.props.manualCasesInProgress.length > 0) {
                if (this.state.allDone) {
                    let manualRun = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                    let submitUrl = "/manual/msp/" + manualRun.id + "/" + manualRun.created + "/submit";
                    let archiveUrl = "/manual/msp/" + manualRun.id + "/" + manualRun.created + "/archive";
                    submit = 
                    <div className="mb-4">
                        <Link to={submitUrl}><button type="button" className="btn-success">Submit test run</button></Link>
                    </div>
                    archive = 
                    <div>
                        {
                            this.props.freeProject === true ?
                            <div className="mt-3 mb-3 font12 neutral4">Upgrade project to the standard or plus plans to be able to archive test runs.</div>
                            :
                            <Link to={archiveUrl}><button type="button" className="btn-success">Archive test run</button></Link>
                        }
                    </div>
                } else {
                    submit = 
                    <div>
                        
                    </div>
                }
            }

            let addButton = <span></span>
            let importButton = <span></span>
            if (this.props.manualRunsInProgress.length > 0) {
                let manualRun = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                let addUrl = "/manual/msp/" + manualRun.id + "/" + manualRun.created + "/add";
                let importUrl = "/manual/msp/" + manualRun.id + "/" + manualRun.created + "/import";
                addButton = <SideItem image="/img/add-outline.svg" label="Add new case" action={addUrl}/>
                importButton = <SideItem image="/img/download.svg" label="Import cases" action={importUrl}/>
            }
            
            let markAllDoneButton = <span></span>
            let markAllNotDoneButton = <span></span>

            if (this.props.manualCasesInProgress.length > 0) {
                markAllDoneButton = <SideItem image="/img/checkmark.svg" label="Mark all done" onClick={this.props.markAllDone}/>
                markAllNotDoneButton = <SideItem image="/img/close.svg" label="Mark all not done" onClick={this.props.markAllUndone}/>
            }
 
            let deleteButton = <span></span>;
            if (this.props.manualRunsInProgress.length > 0) {
                if (this.props.deleteRunConfirm === true) {
                    deleteButton = 
                    <div className="mb-3">
                        <div className="font14 neutral3">Confirm delete</div>
                        <div className="font14 neutral4">Warning: clicking delete now will immediately delete the run. This cannot be undone.</div>
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div>
                                <button type="button" className="btn-confirm mr-3" onClick={this.props.deleteRunCancel}>Cancel</button>
                            </div>
                            <div>
                                <button type="button" className="btn-cancel" onClick={this.props.deleteRun}><span className="accenta4">Delete</span></button>
                            </div>
                        </div>
                    </div>
                } else {
                    deleteButton = <SideItem image="/img/minus-solid.svg" label="Delete run" onClick={this.props.deleteRun}/>
                }
            }

            const run = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
            let editLabelUrl = "";
            let duplicateRunUrl = ""
            if (run !== undefined) {
                editLabelUrl = "/manual/msp/" + run.id + "/" + run.created + "/edit-label";
                duplicateRunUrl = "/manual/msp/" + run.id + "/" + run.created + "/duplicate-run";
            }
            let editLabel = <SideItem image="/img/edit-pencil.svg" label="Edit run label" action={editLabelUrl}/>
            let multiEdit = <SideItem image="/img/list-bullet.svg" label="Batch edit" onClick={() => this.props.multiEditFunc(true)}/>
            if (this.props.multiEdit === true) {
                multiEdit = 
                    <div>
                        <SideItem image="/img/list-bullet.svg" label="Batch edit cancel" onClick={() => this.props.multiEditFunc(false)}/>
                        <br/>
                        <MultiCaseEditOptions context="manual" cases={this.props.manualCasesInProgress} multiEditConfirm={this.props.multiEditConfirm} members={this.props.members} assignSelectedAssignee={this.props.assignSelectedAssignee} assignSelectedAssigneeChange={this.props.assignSelectedAssigneeChange} assignSelected={this.props.assignSelected}/>
                    </div>
            }
            let duplicateRun = <SideItem image="/img/duplicate.svg" label="Duplicate run" action={duplicateRunUrl}/>
            return (
                <div>
                    <div style={{"display":"flex", "flexDirection":"column"}}>
                        <div>
                            {runsLink}
                        </div>
                        <div>
                            {addButton}
                        </div>
                        <div>
                            {importButton}
                        </div>
                        <div>
                            {markAllDoneButton}
                        </div>
                        <div>
                            {markAllNotDoneButton}
                        </div>
                        <div>
                            {duplicateRun}
                        </div>
                        <div>
                            {deleteButton}
                        </div>
                        <div>
                            {editLabel}
                        </div>
                        <div>
                            {multiEdit}
                        </div>
                        <h5 className="mb-1 neutral3">View test cases assigned to:</h5>
                        <ManualAssigneeSelect all={true} members={this.props.members} assignee={this.props.assignee} assigneeChange={this.props.assigneeChange}/>
                        <div className="mt-2 mb-5">
                            <ManualAssignee assign={this.props.assign} members={this.props.members} allCases={this.props.manualCasesInProgress}/>
                        </div>
                        {submit}
                        {archive}
                    </div>
                </div>
            );
        } else if (this.props.state === "create") {
            return (
                <div>
                    
                </div>
            );
        } else if (this.props.state === "editLabel") {
            let run = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
            const doneUrl = "/manual/msp/" + run.id + "/" + run.created;
            return (
                <div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <Confirmation confirmation={this.props.confirmation}/>
                            <button type="button" className="btn btn-confirm mr-3 mb-3" onClick={this.props.editLabelConfirm}>Confirm</button>
                            <br/>
                            <Link to={doneUrl}><button type="button" className="btn btn-cancel" onClick={this.props.cancel}>Done</button></Link>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.state === "import") {
            let listOptionsValues = []
            let listOptions = []
            for (let i = 0; i < this.props.lists.length; i++) {
                let list = this.props.lists[i];
                if (list.groupProxy !== true) {
                    let option = list.label;
                    if (list.group !== undefined) {
                        if (list.group.length > 0) {
                            option += " (";
                            list.group.forEach(function (g) {
                                option += " > " + g;
                            });
                            option += " )";
                        }
                    }
                    listOptionsValues.push({value: option, index: i})
                }
            }
            listOptionsValues.sort((a, b) => {
                if(a.value.toLowerCase() < b.value.toLowerCase()) { return -1 }
                if(a.value.toLowerCase() > b.value.toLowerCase()) { return 1 }
                return 0
            })
            listOptionsValues.forEach((option) => {
                listOptions.push(<option key={option.index} value={option.index}>{option.value}</option>)
            })
            
            let listSelection =
                <div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <select className="custom-select mb-3 width100" onChange={this.listChange} value={this.props.listIndex}>
                                {listOptions}
                            </select>
                        </div>
                    </div>
                </div>

            let importMessage = <p className="neutral4 font14">All the cases in this list will be imported to your test run if you click <b>Import all</b>. Alternatively you can select a subset to import by clicking <b>Import selected</b></p>
            if (this.state.importDisabled === true) {
                importMessage = <p className="pass font14">Imported. Select another list to import or click Done if finished.</p>
            }

            let run = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
            const doneLink = "/manual/msp/" + run.id + "/" + run.created;

            return (
                <div>    
                    <h4 className="neutral4 mb-0">Import from test list</h4>
                    {listSelection}
                    {importMessage}
                    <Confirmation confirmation={this.props.confirmation}/>
                    <button type="button" className="btn btn-select mt-3 mr-3" onClick={this.importConfirm} disabled={this.state.importDisabled}>Import all</button>
                    <button type="button" className="btn btn-select mt-3 mr-3" onClick={this.props.importSelectedConfirm} disabled={this.state.importDisabled}>Import selected</button>
                    <hr/>
                    <Link to={doneLink}><button type="button" className="btn btn-confirm mt-3 mr-3" onClick={this.importComplete}>Done</button></Link>
                </div>
            );
        } else if (this.props.state === "submit") {
            if (this.props.submitted === true) {
                const doneLink = "/manual";
                return <SideItem image="/img/stroke-width-unselected.svg" label="Back to test runs" action={doneLink}/>
            } else {
                let run = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                const doneLink = "/manual/msp/" + run.id + "/" + run.created;
                return (
                    <div>
                        <p className="font14 neutral4">Ensure your results are ready to be submitted before clicking submit, in progress results will be cleared after successful submission.</p>
                        <div>
                            <div className="mb-3">
                                <button type="button" className="btn-success" onClick={this.props.submit}>Submit test run</button>
                            </div>
                            <div>
                                <Link to={doneLink}><button type="button" className="btn btn-cancel">Back</button></Link>
                            </div>
                        </div>
                    </div>
                );
            }
        } else if (this.props.state === "createArchive" || this.props.state === "confirmArchive") {
            if (this.props.archived === true) {
                const doneLink = "/manual";
                return <SideItem image="/img/stroke-width-unselected.svg" label="Back to test runs" action={doneLink}/>
            } else {
                let run = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                if (run === undefined) {
                    return <div>...</div>
                }
                const doneLink = "/manual/msp/" + run.id + "/" + run.created;
                return (
                    <div>
                        <div className="mb-3">
                            <button type="button" className="btn-success" onClick={this.props.archive}>Archive test run</button>
                        </div>
                        <div>
                            <Link to={doneLink}><button type="button" className="btn btn-cancel">Back</button></Link>
                        </div>
                    </div>
                );
            }
        } else if (this.props.state === "duplicate-run") {
            const doneLink = "/manual";
            return <SideItem image="/img/stroke-width-unselected.svg" label="Back to test runs" action={doneLink}/>
        }  else if (this.props.state === "archive") {
            const doneLink = "/manual";
            return <SideItem image="/img/stroke-width-unselected.svg" label="Back to test runs" action={doneLink}/>
        } else {
            return <p>Invalid state</p>
        }
    }
};

export default ManualSide