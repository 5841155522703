/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsJasmine extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Jasmine test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Jasmine test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <p>Using the <a className="site-link-primary2 no-break" target="_blank" href="https://jasmine.github.io/index.html" rel="noopener noreferrer">Jasmine</a> test framework? Follow the instructions below to report results. If you are not using Jasmine please see the <NavLink to="/docs/nodejs" target="_blank" className="site-link-primary2 no-break">Node.js</NavLink> docs for integrating with a lower-level library.</p>

        const installation = 
        <div>
            <p>Install the jasmine-tesults-reporter</p>
            <pre className="docsCode">
                npm install jasmine-tesults-reporter --save
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Register the reporter with Jasmine. Add this to your spec helper:</p>
            <pre className="docsCode">
                const tesultsReporter = require("jasmine-tesults-reporter")<br/>
                jasmine.getEnv().addReporter(tesultsReporter);
            </pre>
            <p>To upload results to a target belonging to your Tesults project, pass the tesults-target arg to the Jasmine process. This can be done on the commandline or added to your package.json file:</p>
            <pre className="docsCode">
            "scripts": {'{'}<br/>
            &nbsp;&nbsp;"test": "jasmine -- tesults-target=token"<br/>
            {'}'}
            </pre>
            <p>Then run jasmine as usual with the <b>npm test</b> command. Use <b>npm run script-name</b> if you named the script something other than test in the package.json. If running jasmine from the commandline run:</p>
            <pre className="docsCode">
            jasmine -- tesults-target=token
            </pre>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="site-link-primary2">configuration menu</a>.</p>
            <p>To handle multiple reporting targets for different jobs create different scrips with different target tokens in the package.json file:</p>
            <pre className="docsCode">
            "scripts": {'{'}<br/>
            &nbsp;&nbsp;"test-job-1": "jasmine -- tesults-target=token1"<br/>
            &nbsp;&nbsp;"test-job-2": "jasmine -- tesults-target=token2"<br/>
            &nbsp;&nbsp;"test-job-3": "jasmine -- tesults-target=token3"<br/>
            {'}'}
            </pre>
            
            <p>The reporter treats the <b>describe</b> as a test suite and each <b>it</b> as a test case.</p>
            <pre className="docsCode">
            describe('Test Suite A', function() {'{'}<br/>
            &nbsp;&nbsp;it("Test Case 1", function () {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;expect(2).toEqual(2);<br/>
            &nbsp;&nbsp;{'}'});<br/><br/>
            &nbsp;&nbsp;it("Test 2", function () {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;expect(1).toEqual(2);<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});<br/><br/>
            
            describe('Test Suite B', function() {'{'}<br/>
            &nbsp;&nbsp;it("Test 3", function () {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;expect(3).toEqual(3);<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});
            </pre>

            <p><b>Using Protractor?</b> The configuration instructions above do not apply. Follow the <a className="site-link-primary2" target="_blank" href="/docs/protractor" rel="noopener noreferrer">Protractor configuration instructions</a> instead and then return here to continue.</p>

            
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point the jasmine-tesults-reporter will push results to Tesults when you run your jasmine command. The tesults-target arg must be supplied to indicate which target to use.</p>}
            />

            <h4>Full list of args</h4>
            <p>To supply multiple args to the reporter use this format: </p>
            <pre className="docsCode">
                jasmine -- arg1=val1 arg2=val2 arg3=val3
            </pre>
            <p>Example:</p>
            <pre className="docsCode">
                jasmine -- tesults-target=token tesults-files=path
            </pre>
            <pre className="primary2 docsArgsCode pl-0">tesults-target<span className="neutral7 ml-5">Required</span></pre>
            <p>Required arg to upload to Tesults, if this arg is not provided the reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-target=eyJ0eXAiOiJ...</pre>
        </div>

        const files = 
        <div>
            <p className='accenta3 bold font12'>This method of uploading files is no longer recommended starting from jasmine-tesults-reporter 1.1.0+. If using jasmine-tesults-reporter 1.1.0 or newer, utilize the file method described above to simplify uploading files from tests.</p>
            <pre className="primary2 docsArgsCode pl-0">tesults-files<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide this arg to save files generated during a test run. The value for this arg is the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-files=/Users/admin/Desktop/temporary</pre>
            <p>This is one area where the jasmine-tesults-reporter is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Within this temporary directory create subdirectories matching the test suite (describe) and test case (it) name.</p>
            <p>Be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const enhancedReporting = 
        <div>
            <p>Use the Tesults reporter to report additional properties for each test case. To begin doing this, require the reporter in your test file:</p>
            <pre className='docsCode'>
                const tesultsReporter = require("jasmine-tesults-reporter")
            </pre>
            <p>Report custom fields, test steps and attach files to test cases using the Tesult reporter functions: description, custom, step, and file. <b>Call reporter functions from within your tests (test block)</b>:</p>
            <h4>description</h4>
            <p>Add a description of the test case for reporting purposes.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">description</code>("some description here")
            </pre>
            <h4>custom</h4>
            <p>Add a custom field and value to the test case for reporting purposes.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">custom</code>("Some custom field", "Some custom value")<br/>
            </pre>
            <h4>file</h4>
            <p>Associate a file to the test case in order to upload it for reporting.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">file</code>("/full/path/to/file/screenshot.png")<br/>
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h4>step</h4>
            <p>Add test steps to the test case for reporting. Each step is an object with a name and result (one of [pass, fail, unknown]). You can also add the optional fields description and reason (failure reason in case of step fail).</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">step</code>({'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "First step",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "pass"<br/>
            {'}'})<br/>
            tesultsReporter.<code className="accentc6">step</code>({'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "Second step",<br/>
            &nbsp;&nbsp;<code className="accentb4">description</code>: "Second step description",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "fail"<br/>
            &nbsp;&nbsp;<code className="accentb4">reason</code>: "Error line 203 of test.js"<br/>
            {'}'})<br/>
            </pre>
            <div>
                <p>A complete enhanced reporting example:</p>
                <pre className='docsCode'>
                const tesultsReporter = require("jasmine-tesults-reporter")<br/><br/>
                describe ('Suite A', () => {'{'}<br/>
                &nbsp;&nbsp;test('Test 1', () => {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.custom("custom field 1", "custom value 1")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.description("description here")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.step(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'{'}name: "step 1", description: "step 1 description", result: "pass"{'}'})<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.step(<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'{'}name: "step 2", description: "step 2 description", result: "pass"{'}'})<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.file("/full/path/logs/log1.txt")<br/>
                &nbsp;&nbsp;{'}'});<br/>
                {'}'});
                </pre>
            </div>
        </div>


        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-name=1.0.0</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-result=pass</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-description='added new feature'</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-reason='build error line 201 somefile.js'</pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                enhancedReporting={enhancedReporting}
                files={files}
                build={build}
                integration="jasmine"
            />
        );
    }
}

export default DocsJasmine;