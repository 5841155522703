/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';

class DocsProtractor extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Protractor test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Protractor test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>If you are using either the <a className='tr-link-primary4 no-break no-break' target="_blank" href="https://mochajs.org" rel="noopener noreferrer">Mocha</a> or <a className='tr-link-primary4 no-break no-break' target="_blank" href="https://jasmine.github.io/index.html" rel="noopener noreferrer">Jasmine</a> test framework with <a className='tr-link-primary4 no-break no-break' target="_blank" href="https://www.protractortest.org" rel="noopener noreferrer">Protractor</a>, follow the instructions below to report results.</p>
        </div>

        const installation = 
        <div>
        <h4 className='neutral4'>Mocha</h4>
        <pre className="docsCode">
            npm install mocha-tesults-reporter --save
        </pre>
        <h4 className='neutral4'>Jasmine</h4>
        <pre className="docsCode">
            npm install jasmine-tesults-reporter --save
        </pre>
        </div>

        const configuration = 
        <div>
            <p>Your Protractor conf.js file must contain the three properties below to have results upload to Tesults:</p>
            <h4 className="mt-5">1. Framework</h4>
            <p>Set framework you are using</p>
            <h4 className='neutral4'>Mocha</h4>
            <pre className="docsCode">
                framework: 'mocha'
            </pre>
            <h4 className='neutral4'>Jasmine</h4>
            <pre className="docsCode">
                framework: 'jasmine'
            </pre>
            <h4 className="mt-5">2. Reporter configuration</h4>
            <h4 className='neutral4'>Mocha</h4>
            <p>Require the mocha-tesults-reporter in your conf.js</p>
            <pre className="docsCode">
            const tesultsReporter = require("mocha-tesults-reporter")
            </pre>
            <p>Have reporter in mochaOpts in the config set to reference the mocha-tesults-reporter:</p>
            <pre className="docsCode">
            mochaOpts: {'{'}<br/>
            &nbsp;&nbsp;reporter: tesultsReporter,<br/>
            &nbsp;&nbsp;reporterOptions: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-target': 'token',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-config': '/path/tesults-config.js',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-files': '/path-to-files/files',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-name': '1.0.0',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-description': 'build description',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-reason': 'build failure reason (if failed)',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-result': 'pass'<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
            </pre>
            <br/>
            <spam className="font12 neutral4">View the Mocha reporter documentation for details on the optional tesults-config, tesults-files and tesults-build-* arguments.</spam>
            <p>The reporterOptions sets args for the mocha-tesults-reporter. The reporterOptions is <b>optional</b> because you can alternatively pass the args through the command line to Protractor. Doing this in the conf.js is convenient for values that do not change.</p>
            <p>The alternative method of passing mocha-tesults-reporter args, without reporterOptions in mochaOpts using protractor command line:</p>
            <pre className="docsCode">
                protractor conf.js --disableChecks --reporter-option tesults-target=token<br/> --reporter-option tesults-files=/path-files
            </pre>
            <h4 className='neutral4'>Jasmine</h4>
            <p>Register the jasmine-tesults-reporter with Jasmine (this is <b>required</b> if using Jasmine).</p>
            <pre className="docsCode">
            onPrepare: function(){'{'}<br/>
            &nbsp;&nbsp;const tesultsReporter = require('jasmine-tesults-reporter');<br/>
            &nbsp;&nbsp;jasmine.getEnv().addReporter(tesultsReporter);<br/>
            {'}'}<br/>
            </pre>
            <p>You would then run the tests by passing the tesults-target arg (see the Jasmine docs for more details)</p>
            <pre className="docsCode">
                protractor conf.js --  tesults-target=token
            </pre>
            <h4 className="mt-5">3. The afterLaunch function</h4>
            <h4 className='neutral4'>Mocha and Jasmine</h4>
            <p>Protractor's afterLaunch function must be added to the conf.js and set to resolve a promise in order to allow asynchronous operations in reporters to complete before Protractor exits. Upload will not occur without this function being present in the conf.js.</p>
            <pre className="docsCode">
            afterLaunch: function (exitCode) {'{'}<br/>
            &nbsp;&nbsp;return new Promise(function (resolve) {'{}'});<br/>
            {'}'}
            </pre>
            <p>Putting this altogether, a conf.js would resemble:</p>
            <h4 className='neutral4'>Mocha</h4>
            <pre className="docsCode">
            const tesultsReporter = require("mocha-tesults-reporter")<br/><br/>
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;framework: 'mocha',<br/>
            &nbsp;&nbsp;seleniumAddress: 'http://localhost:4444/wd/hub',<br/>
            &nbsp;&nbsp;specs: ['todo-spec.js', 'todo-spec2.js'],<br/>
            &nbsp;&nbsp;mochaOpts: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;reporter: tesultsReporter,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;slow: 3000,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;reporterOptions: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-target': 'token',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-config': '/path/config.js',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-files': '/path-to-files/files',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-name': '1.0.0',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-description': 'build description',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-reason': 'build failure reason (if failed)',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults-build-result': 'pass'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'},<br/>
            &nbsp;&nbsp;afterLaunch: function (exitCode) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return new Promise(function (resolve) {'{}'});<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'};
            </pre>
            <h4 className='neutral4'>Jasmine</h4>
            <pre className="docsCode">
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;framework: 'jasmine',<br/>
            &nbsp;&nbsp;seleniumAddress: 'http://localhost:4444/wd/hub',<br/>
            &nbsp;&nbsp;specs: ['todo-spec.js', 'todo-spec2.js'],<br/>
            &nbsp;&nbsp;onPrepare: function(){'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;const tesultsReporter = require('jasmine-tesults-reporter');<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;jasmine.getEnv().addReporter(tesultsReporter);<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;afterLaunch: function (exitCode) {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;return new Promise(function (resolve) {'{}'});<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'};
            </pre>
            <h4 className="mt-5">Back to Mocha or Jasmine</h4>
            <p className="mb-5">This completes Protractor specific configuration. Now continue viewing the <a target="_blank" href="/docs/mocha" rel="noopener noreferrer">Tesults Mocha</a> or <a target="_blank" href="/docs/jasmine" rel="noopener noreferrer">Tesults Jasmine</a> documentation to complete setup.</p>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                integration="protractor"
            />
        );
    }
}

export default DocsProtractor;