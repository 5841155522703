/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsPrivacy extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Privacy</title>
                    <meta name="description" content="Tesults takes privacy and security seriously. Learn about privacy at Tesults."/>
                </Helmet>
                <h2 className="">The importance of privacy</h2>
                <p>The Tesults <NavLink to="/docs/terms" target="_blank" className="neutral4">terms of service</NavLink> documentation includes details on privacy. Here we highlight privacy practices we think are important and you should know about.</p>
                <p>Tesults takes privacy and security of all users of the service seriously. The highest standards are applied to planning, implementing and maintaining privacy and security provisions. These measures are fundamental components of the service.</p>
                <h2 className=" mt-5">Our assurance to you about your data</h2>
                <p>Tesults keeps your personal and results data private and does not share data with other entities, companies, partners or anyone else. There are two exceptions to this, one is outlined in the 'payment data' section below and both are outlined on the <NavLink to="/docs/security" target="_blank" className="neutral4">security page</NavLink> which details important information about how and where data is stored in more detail. These two exceptions are trusted third party providers of payment and technology infrastructure to make the Tesults service available.</p>
                <p>Your data is accessible only to you and the team members you have chosen to provide access to (in the configuration menu). Only team members that have been added to the same project can view your first and last name and profile picture. You can edit email address, first name and last name and profile picture data at any time from the configuration menu.</p>
                <h2 className=" mt-5">Regular unrecoverable deletion</h2>
                <p>Test results data is permanently deleted regularly. The time interval for deletion is outlined in the terms for your subscribed plan. You can make a request to have your data permanently deleted at any time by contacting support. Data is permanently deleted, there is no way for Tesults to recover data.</p>
                <h2 className=" mt-5">Personal data</h2>
                <p>The Tesults service collects limited personal data on sign up. For most users this is limited to first and last names, email address and profile picture. For users that create a project and create a paid plan subscription additional information is collected relating to payment, specifically billing address and payment card details. IP address data may be collected as part of analytics data collection used to improve the Tesults service.</p>
                <p>Tesults retains personal data for the purposes of providing you with service only. If you would like to have any personal data Tesults has for your profile exported or deleted, contact support with your request and we will complete your request and respond within 30 days. This is an upper limit, response times will usually be much faster.</p>
                <h2 className=" mt-5">Payment data</h2>
                <p>Tesults uses Stripe, a PCI compliant third party payment processor certified to PCI Service Provider Level 1 in order to process payments and your payment card details are provided to and retained by this provider. If you would like to have the data held by this provider deleted please contact support and we will complete your request and respond within 30 days. This is an upper limit, response times will usually be much faster.</p>
                <h2 className=" mt-5">General Data Protection Regulation (GDPR) compliance</h2>
                <p>Tesults has measures in place to remain in compliance with the General Data Protection Regulation (GDPR). If you have any queries related to this please contact support.</p>
                <p>If you would like to have any personal data Tesults has for your profile exported or deleted, contact support with your request and we will complete your request and respond within 30 days. This is an upper limit, response times will usually be much faster.</p>
                <h2 className=" mt-5">Age Requirement</h2>
                <p>The Tesults service can be used by users aged 16 years and older only.</p>
                <h2 className=" mt-5">Contact</h2>
                <p>If you have specific questions about privacy please contact help@tesults.com</p>
            </div>
        );
    }
}

export default DocsPrivacy;