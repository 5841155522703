/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loading from './Loading';
import Constants from './Constants';
import Analytics from './Analytics';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import BlogSide from './BlogSide';
import BlogAuthor from './BlogAuthor'

class BlogMain extends Component {
    constructor () {
        super();
        this.state = {post: undefined, loading: false, initialLoad: true};
        this.getPost = this.getPost.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.setState({initialLoad: false});
        let id = this.props.match.params.id;
        if (id === undefined) {
            id = Constants.getUrlParameter("id", this.props.location.search);
        }
        if (id === undefined) {
            //this.getPost("latest");
        } else {
            this.getPost(id);
        }
    }

    getPost (id) {
        Analytics.event("Blog", {"id": id})
        this.setState({loading: true});
        if (id === "latest") {
            Request.get("/blogposts", {limit: 1}, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    let post = undefined;
                    if (data.posts !== undefined) {
                        if (data.posts.length > 0) {
                            post = data.posts[0];
                        }
                    }
                    this.setState({post: post, loading: false});
                }
            });
        } if (id === "preview") {
            Request.get("/blog-post-preview", {}, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    this.setState({post: data.post, loading: false});
                }
            });
        } else {
            Request.get("/blogpost", {id: id}, (err, data) => {
                if (err) {
                    this.setState({loading: false});
                } else {
                    this.setState({post: data.post, loading: false});
                }
            });
        }   
    }

    render () {
        window.scrollTo(0,0);
        if (this.props.loading === true) {
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Blog</title>
                        <meta name="description" content="The Tesults blog features posts about new Tesults features and updates as well as in-depth feature articles by the Tesults team."/>
                    </Helmet>
                    <Loading/>
                </div>
            );
        } else if (this.state.post !== undefined) {
            //let previousLink = <span>&nbsp;</span>
            //if (this.state.post.prev !== "null") {
                //let link = "/blog/" + this.state.post.prev;
                //previousLink = <a className="nounderline neutral1" href={link}>Previous Post</a>
            //}
            return (
                <div>
                    <Helmet>
                        <title>{"Tesults Blog: " + this.state.post.title}</title>
                        <meta name="description" content={this.state.post.desc}/>
                        <link rel="canonical" href={"https://www.tesults.com/blog/" + this.state.post.postId}/>
                        <script async src="//www.instagram.com/embed.js"></script>
                    </Helmet>
                    <h1 className="bold">{this.state.post.title}</h1>
                    <h4 className="neutral4">{this.state.post.desc}</h4>
                    <h4 className="neutral6 mb-0"><i><small><TimeFormatted dd={this.state.post.time}/></small></i></h4> 
                    <img src={"https://www.tesults.com/blogfiles" + this.state.post.titleImage} alt="blog-title-image" class="width100" style={{"maxWidth": "800px"}}/>
                    <div className='blogWidth' dangerouslySetInnerHTML={{__html: this.state.post.body}}></div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{"backgroundColor":"hsl(209, 69%, 54%)", "borderRadius":"8px", "maxWidth":"1000px"}}>
                        <div className="index-header-flex index-margin" style={{"alignItems":"center", "paddingTop":"40px", "paddingBottom":"40px"}}>
                            <div style={{"flex": "1", "minWidth":"300px", "maxWidth":"500px"}}>
                                <h2 className="mb-4 bold primary8" style={{"fontWeight":"800"}}>Tesults - Release high quality software everytime</h2>
                                <p className="primary8 font15 mb-4">Test automation reporting and test case management for quality focused tech teams. 5 minute setup time.</p>
                                <NavLink to="/" className="nounderline"><button type="button" className="btn-cancel-index mb-3 mr-3"><span className="bold">Learn more</span></button></NavLink>
                                <NavLink to="/register" className="nounderline mb-3"><button type="button" className="btn-confirm-index"><span className="bold">Try for free</span></button></NavLink>
                            </div>
                            <div style={{"flex": "1"}} className="mobile-hide-cancel">
                                {/*<img className="index-header-image" src="https://www.tesults.com/files/docs/results-2.png" alt="tesults"/>*/}
                                {/*<img src="/img/Illustrations_Tesults_006Artboard-1.png" style={{"maxHeight": "459px"}} alt=""/>*/}
                                <img src="https://www.tesults.com/files/art/2021-05-24/tesults-index-art.png" style={{"minWidth": "175px", "maxWidth":"100%"}} alt=""/>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.post.time >= 1704204559973 ? <BlogAuthor author={this.state.post.author}/> : <div></div>
                    }
                    <BlogSide/>
                </div>
            );
        } else {
            return (
                <div>
                    <Helmet>
                        <title>Tesults - Blog</title>
                        <meta name="description" content="The Tesults blog features posts about new Tesults features and updates as well as in-depth feature articles by the Tesults team."/>
                    </Helmet>
                    <h1>Tesults Blog</h1>
                    <img src="https://www.tesults.com/files/art/2021-05-24/rough_line.png" style={{"minWidth": "400px", "maxWidth":"800px"}} alt=""/>
                    <BlogSide/>
                </div>
            );
        }
    }
}

export default BlogMain;