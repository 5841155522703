/*global */
import React, { Component } from 'react';

class MultiTextList extends Component {
    render () {
        let ret = [];
        if (this.props.list === undefined) {
            ret = <div></div>
        } else {
            for (let i = 0; i < this.props.list.length; i++) {
                let text = this.props.list[i];
                ret.push(
                    <div>{text}</div>
                );
            }
        }
        return (
            <div>
                {ret}
            </div>
        )
    }
};

export default MultiTextList;