/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MultiCaseEditOptions from './MultiCaseEditOptions';
import SideItem from './SideItem';
import SelectOverlay from './SelectOverlay';

class ListsSide extends Component {
    constructor () {
        super();
        this.state = {state: ""};
        this.projectChange = this.projectChange.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.importListChange = this.importListChange.bind(this);
    }

    projectChange (e) {
        this.props.projectChange(e.target.value);
    }

    importListChange (value) {
        this.props.importListChange(value);
    }

    targetChange (e) {
        this.props.targetChange(e.target.value);
    }

    render() {
        let doneLink = "/lists";
        if (this.props.listIndex < this.props.lists.length) {
            let list = this.props.lists[this.props.listIndex];
            if (list !== undefined) {
                doneLink = "/lists/lsp/" + list.id + "/" + list.created;
            }
        }

        if (this.props.loading === true) {
            return <span></span>
        } else if (this.props.loggedIn === false) {
            return (
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="card neutral8bg noborder">
                            <div className="card-body text-center">
                                <img width="64" height="64" className="opacity25" src="/img/lists-neutral5-128.png" alt="Lists"/>
                                <p className="card-text small neutral6 mt-3">Use test lists for test case management. Create test cases and add them to a list. Test lists are persisted. Use test lists with test runs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.role < 2) {
            return (
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="card neutral8bg noborder">
                            <div className="card-body text-center">
                                <img width="64" height="64" className="opacity25" src="/img/lists-neutral5-128.png" alt="Lists"/>
                                <p className="card-text small neutral6 mt-3">Use test lists for test case management. Create test cases and add them to a list. Test lists are persisted. Use test lists with test runs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.state === "importTarget") {
            let targetOptions = [];
            let index = 0;
            this.props.targets.forEach(function (target) {
                targetOptions.push(<option key={index} value={index}>{target.name}</option>);
                index += 1;
            });
            let targetSelection =
                <div>
                    <div>
                        <select className="custom-select mb-3 width100" onChange={this.targetChange} value={this.props.targetIndex}>
                            {targetOptions}
                        </select>
                    </div>
                </div>
            return (
                <div>
                <h4 className="neutral4">Import test cases from target</h4>
                {targetSelection}
                <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={this.props.importConfirm}>Import</button>
                <Link to={doneLink}><button type="button" className="btn btn-cancel mt-3">Back to lists</button></Link>
                </div>
            );
        } else if (this.props.state === "importList") {
            let listOptions = [];
            let index = 0;
            this.props.lists.forEach(function (list) {
                if (list.groupProxy !== true) {
                    let group = "";
                    if (list.group !== undefined) {
                        list.group.forEach(function (g) {
                            if (group === "") {
                                group += g;
                            } else {
                                group += " > " + g;
                            }
                        });
                    }
                    if (group !== "") {
                        group = " (" + group + ")";
                    }
                    //listOptions.push(<option key={index} value={index}>{list.label + group}</option>);
                    listOptions.push({label: list.label + group, value: index});
                }
                index += 1;
            });
            let title = "";
            if (this.props.importListIndex !== undefined) {
                if (this.props.importListIndex < this.props.lists.length) {
                    title = this.props.lists[this.props.importListIndex].label;
                }
            }
            let listSelection =
                <div>
                    <SelectOverlay
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        type="generic"
                        title={title}
                        label="label"
                        value="value"
                        options={listOptions}
                        valueChange={this.importListChange}
                        defaultValue={this.props.importListIndex}
                    />
                    {/*
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <select className="custom-select mb-3 width100" onChange={this.importListChange} value={this.props.importListIndex}>
                                {listOptions}
                            </select>
                        </div>
                    </div>
                    */}
                </div>
            return (
                <div>
                <p className='font15'>Select a list to import cases from:</p>
                {listSelection}
                <br/>
                <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={this.props.importListConfirm}>Import</button>
                <Link to={doneLink}><button type="button" className="btn btn-cancel mt-3">Back to lists</button></Link>
                </div>
            );
        } else if (this.props.state === "importCsv") {
            return (
                <div>
                <h4 className="neutral4">Import test cases from CSV file</h4>
                <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={this.props.importCsvConfirm}>Confirm Import</button>
                <Link to={doneLink}><button type="button" className="btn btn-cancel mt-3">Back to lists</button></Link>
                </div>
            );
        } else if (this.props.state === "createList") {
            return (
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <p className="neutral1">Name the list and create</p>
                        <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={() => this.props.createListConfirm()}>Create</button>
                        <Link to="/lists"><button type="button" className="btn btn-cancel mt-3">Back to lists</button></Link>
                    </div>
                </div>
            );
        } else if (this.props.state === "duplicateList") {
            return (
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <p>Name the list and confirm</p>
                        <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={() => this.props.duplicateListConfirm()}>Confirm</button>
                        <Link to={doneLink}><button type="button" className="btn btn-cancel mt-3">Back to lists</button></Link>
                    </div>
                </div>
            );
        } else {
            let addCase = <span></span>
            let importCase = <span></span>
            let importCaseCsv = <span></span>
            let importCaseList = <span></span>
            let exportCsv = <span></span>
            let editListNameAndGroup = <span></span>
            let duplicateList = <span></span>
            let generateTestCases = <span></span>
            //let deleteSuites = <span></span>
            if (this.props.lists !== undefined) {
                if (this.props.lists.length > 0) {
                    let list =  this.props.lists[this.props.listIndex];
                    if (list !== undefined) {
                        let url = "/lists/lsp/" + list.id + "/" + list.created;
                        addCase = <SideItem image="/img/add-outline.svg" label="Add test case to list" action={url + "/add"}/>
                        editListNameAndGroup = <SideItem image="/img/edit-pencil.svg" label="Edit list name and group" action={url + "/edit-list-name"}/>
                        /*
                        let deleteSuitesButton = <span className="tr-link-neutral1 font14" onClick={this.props.listEditFunc}>Cancel delete suites</span>
                        if (this.props.listEdit !== true) {
                            deleteSuitesButton = <SideItem image="/img/close-solid.svg" label="Delete suites from list" onClick={this.props.listEditFunc}/>
                        }
                        deleteSuites = 
                        <div>
                            {deleteSuitesButton}
                        </div>*/
                        importCase = <SideItem image="/img/target.svg" label="Import tests from target" action={url + "/import-cases-from-target"}/>
                        importCaseCsv = <SideItem image="/img/download.svg" label="Import tests from CSV" action={url + "/import-cases-from-csv"}/> 
                        importCaseList = <SideItem image="/img/add-outline.svg" label="Import tests from list" action={url + "/import-cases-from-list"}/>
                        exportCsv = <SideItem image="/img/upload.svg" label="Export list to CSV" onClick={this.props.exportToCsv}/>
                        duplicateList = <SideItem image="/img/duplicate.svg" label="Duplicate list" action={url + "/duplicate-list"}/>
                        generateTestCases= <SideItem image="/img/bolt.svg" label="Generate test cases" action={url + "/generate-test-cases"}/>
                    }
                }
            }

            let deleteButton = <span></span>;
            if (this.props.lists.length > 0) {
                if (this.props.deleteListConfirm === true) {
                    deleteButton = <div className="mb-5">
                        <SideItem image="/img/minus-outline.svg" label="Delete list" onClick={() => {}}/>
                        <h5 className="neutral3">Warning</h5>
                        <p class="font14 neutral4">Clicking confirm will immediately delete the list. This cannot be undone.</p>
                        <div style={{"display":"flex"}}>
                            <button type="button" className="btn-confirm mr-3" onClick={this.props.deleteListCancel}>Cancel</button>
                            <button type="button" className="btn-cancel font14" onClick={this.props.deleteList}>Confirm Delete</button>
                        </div>
                    </div>
                } else {
                    deleteButton = <SideItem image="/img/minus-outline.svg" label="Delete list" onClick={this.props.deleteList}/>
                }
                
            }

            let multiEdit = <SideItem image="/img/list-bullet.svg" label="Batch edit" onClick={() => this.props.multiEditFunc(true)}/>
            if (this.props.multiEdit === true) {
                multiEdit = 
                    <div>
                        <SideItem image="/img/list-bullet.svg" label="Batch edit cancel" onClick={() => this.props.multiEditFunc(false)}/>
                        <br/>
                        <MultiCaseEditOptions cases={this.props.listCases} multiEditConfirm={this.props.multiEditConfirm}/>
                    </div>
            }

            let options = 
                <div>
                    {addCase}
                    {importCase}
                    {importCaseList}
                    {importCaseCsv}
                    {exportCsv}
                    {editListNameAndGroup}
                    {duplicateList}
                    {generateTestCases}
                    {/*deleteSuites*/}
                    {deleteButton}
                    {multiEdit}
                </div>

            let listTreeLink = <SideItem image="/img/show-sidebar.svg" label="Back to list selection" onClick={() => this.props.backToTreeList()}/>
            if (this.props.state === "groupTree") {
                return (
                    <div>
                        <p className="neutral6 mt-3 font14">Use test lists for test case management. Create test cases and store them for use across test runs. Test lists are persisted and are the source of truth for all of your manual test cases.</p>
                    </div>
                    );
            }

            return (
                <div>
                    <div className="list-side">
                        {listTreeLink}
                        {options}
                    </div>
                </div>
            );
        }
    }
};

export default ListsSide;