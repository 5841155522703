/*global */
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import Notice from './Notice'
import DocsResultInterpretationSupport from './DocsResultInterpretationSupport'

class DocsTesultsDataFormat extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults JSON Data Standard</title>
                    <meta name="description" content="Learn about the Tesults JSON data standard."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/z16pajMXq-M?si=rLZX5ffxp_LqJOIN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>The Tesults JSON data standard defines formatting for transmitting test results data over a network. The format is intended to be utilized bi-directionally for both recording data and for retrieval for consumption.</p>
                <Notice type="success" content={<div className="pt-2">Contact the Tesults team at <a className="tr-link-primary4" href="mailto:help@tesults.com">help@tesults.com</a> with questions and feedback.</div>}/>
                <h2>Results</h2>
                <p>The format represents test results data for a single test run. The <b>results</b> field is the only top-level entry consisting of an object with one field named <b>cases</b>. The value for cases is an array containing test cases and their results. There can be any number of test cases within a test run and all of them are contained within the cases array.</p>
                <pre className='docsCode'>
                {'{'}<br/>
                &nbsp;&nbsp;"results": {'{'} "cases": [] {'}'}<br/>
                {'}'}<br/>
                </pre>
                <h2>Test cases</h2>
                <p>Each test case object contained in the cases array has two required fields, name and result:</p>
                <pre className='docsCode'>
                {'{'}<br/>
	            &nbsp;&nbsp;"name": "Test name",<br/>
                &nbsp;&nbsp;"result": "pass"<br/>
                {'}'}<br/>
                </pre>
                <p>This is valid results data containing two test cases:</p>
                <pre className='docsCode'>
                {'{'}<br/>
                    &nbsp;&nbsp;"results": {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"cases": [<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
	                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test 1",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test 2",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "fail"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                    &nbsp;&nbsp;{'}'}<br/>
                {'}'}<br/>
                </pre>
                <h2>Test case fields</h2>
                <p>All fields besides name and result for a test case are optional, including an unlimited number of custom fields. This object shows a complete test case data object using every possible field and is followed by a section detailing each field:</p>
                <pre className='docsCode'>
                {'{'}<br/>
	            &nbsp;&nbsp;"name": "Test name",<br/>
                &nbsp;&nbsp;"result": "pass",<br/>
                &nbsp;&nbsp;"suite": "Test suite",<br/>
                &nbsp;&nbsp;"desc": "Test description",<br/>
                &nbsp;&nbsp;"reason": "Test failure reason",<br/>
                &nbsp;&nbsp;"params": {'{'} "Test param 1": "Value 1", "Test param 2": "Value 2", ... {'}'},<br/>
                &nbsp;&nbsp;"files": ["/full/path/to/file", "/full/path/screenshot.png", "/full/path/test.log"],<br/>
                &nbsp;&nbsp;"steps": [{'{'}"name": "Step 1", "result": "pass"{'}'}, {'{'}"name": "Step 2", "result": "pass"{'}'}],<br/>
                &nbsp;&nbsp;"start": {'<'}milliseconds since Unix epoch{'>'},<br/>
                &nbsp;&nbsp;"end": {'<'}milliseconds since Unix epoch{'>'},<br/>
                &nbsp;&nbsp;"duration": {'<'}milliseconds{'>'},<br/>
                &nbsp;&nbsp;"rawResult": "Raw result value to use with a result interpretation map",<br/>
                &nbsp;&nbsp;"_Custom": "A custom field. Any field starting with an underscore is a custom field.",<br/>
                {'}'}<br/>
                </pre>
                <h3>name</h3>
                <p>The name of the test case. In the context of a test framework this is the name of a single test case function or scenario.</p>
                <h3>result <span className="neutral4 font14">["pass", "fail", "unknown"]</span></h3>
                <p>The result of the test case. The value for this must be one of “pass”, “fail”, or “unknown”. Any other result status can be supplied using the optional <b>rawResult</b> field, detailed below, but in the case of the result field mapping to one of the "pass", "fail" or "unknown" status standardizes the outcome of a test from a range of test frameworks.</p>
                <h3>rawResult</h3>
                <p>Any result status value. Raw here refers to the internal test framework status value prior to being mapped to one of "pass", "fail" or "unknown" for the result field described above. As an example some test cases use the status "passed" or "success" to indicate a passing result. In this case the value for result would be "pass" and the rawResult would be "passed" or "success" depending on the test framework. Similarly a test case may use "failed" or "failure" for a failing result, in this case the value for result would be "fail" and rawResult would be "failed" or "failure" depending on the test framework. Similarly, a range of values can be mapped to an "unknown" result including rawResult values such as "skipped" and "skip".</p>
                <h3>suite</h3>
                <p>The suite the test case belongs to. In the context of a test framework this is the name of the parent container of a group of test cases.</p>
                <h3>desc</h3>
                <p>A description of a single test case. Used to provide more information about a test case and what it does beyond the name.</p>
                <h3>reason</h3>
                <p>Test case failure reason, such as the failing assertion output or stack trace.</p>
                <h3>params</h3>
                <p>For test frameworks supporting parameterized tests where the name of the test does not change when running with different parameters, the params field can be used to distinguish parameterized test case runs. For example, a test case that is run with two parameters that change change, x, y, can be specified as a params value: {'{'}x: "x value", y: "y value"{'}'}.</p>
                <h3>files</h3>
                <p>An array of files that were generated by the test case. Each value is a string to the full path of the file on the host system running the test case. Tesults language libraries supporting files will automatically upload files if specified.</p>
                <h3>start</h3>
                <p>The time the test case started, represented as a number in milliseconds since Unix epoch.</p>
                <h3>end</h3>
                <p>The time the test case ended, represented as a number in milliseconds since Unix epoch.</p>
                <h3>duration</h3>
                <p>The duration of the test case in milliseconds. If start and end are supplied, this is superflous.</p>
                <h3>_Custom</h3>
                <p>Any field beginning with an underscore character is a custom field. Test framework authors can add any number of custom fields.</p>
                <br/>
                <br/>
                <Notice type="success" content={
                    <div>
                        <p>Test framework authors can support test results transmission to Tesults easily by formatting test results data in the Tesults JSON data standard. After the data is the correct format only a single function call is required to upload results. The language library documentation explains how to do this for each language.</p>
                        <p>Tesults expects test results data in the Tesults JSON data standard as described above, with an added target field shown below. The token string is generated from the UI or from the Tesults API.</p>
                        <pre className='docsCode'>
                            {'{'}<br/>
                            &nbsp;&nbsp;"target": "token",<br/>
                            &nbsp;&nbsp;"results": {'{'} "cases": [] {'}'}<br/>
                            {'}'}<br/>
                        </pre>
                        <p>The Tesults API makes it possible to retrieve test results data too and the data format in the response matches the test cases fields defined in the Tesults JSON data standard. See the <NavLink className="no-break tr-link-primary4" to="/docs/api/results">/results API documentation</NavLink> for more details.</p>
                        <hr className='dividor-light'/>
                        <p>The Tesults team is here to support integration developers. Contact help@tesults.com for answers to any questions you may have.</p>
                    </div>
                }/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}

export default DocsTesultsDataFormat