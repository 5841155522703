/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsDiff extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Diff</title>
                    <meta name="description" content="Learn how to use the dashboard view on Tesults."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/QL4zUT_dh_s?si=leQd1zINAxOrFs37" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Click <b>Diff</b> from the menu to view the diff view.</p>
                <p>Use the Diff functionality to view the difference between two sets of test results. The Diff view displays two sets of results, one for the selected target and test run on the left and one for the selected target and test run on the right. Differences between the results are then automatically highlighted.</p>
                <p>By selecting different targets for the left and right you can view the difference between results between two different test jobs. This can be useful if your targets correspond to two different environments but contain the same set of test cases. By keeping the same target selected for both sides but changing the test run selected for one of them, you can view the differences between the results of two test runs run at different times for the same target.</p>
                <p>The diff view eliminates the need to switch back and forth between different test runs to identify differences and saves you time by automatically highlighting what the differences are so that you don't have to spend time manually identifying them yourself. You can click on a test case within the diff view just as you can in results view to view test case details.</p>
                <img src="https://www.tesults.com/files/docs/diff-1.png" alt="Diff view" className="width100"/>
            </div>
        );
    }
}

export default DocsDiff;