/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import SelectOverlay from './SelectOverlay';
import OverlayItem from './OverlayItem';
import Constants from './Constants';

class SiteHeader extends Component {
    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let loggedIn = true;
        if (user === undefined || user == null) {
            loggedIn = false;
        }
        this.state = {loggedIn: loggedIn, iref: undefined};
        this.ref = React.createRef();
        this.learnMore = this.learnMore.bind(this);
        this.learnMoreActivate = this.learnMoreActivate.bind(this);
    }

    componentDidMount () {
        let iref = Constants.getUrlParameter("iref", this.props.location.search);
        if (iref === undefined) {
            const path = window.location.pathname;
            if (path.startsWith("/affiliate")) {
                iref = "affiliate";
            }
        }
        this.setState({iref: iref});
    }

    learnMore () {
        return (
            <div className="p-4">
                <OverlayItem type="link" label="Documentation" value="/docs"/>
                <OverlayItem type="link" label="Pricing" value="/docs/pricing"/>
                <OverlayItem type="link" label="About" value="/about"/>
                <OverlayItem type="link" label="Contact" value="/contact"/>
                <OverlayItem type="link" label="Blog" value="/blog"/>
            </div>
        );
    }

    learnMoreActivate () {
        this.setState({learnMore: true});
    }

    render () {
        const learnMoreContent = this.learnMore();
        let loginLink = "/login" + ((this.state.iref === undefined) ? "" : "?iref=" + this.state.iref);
        let registerLink = "/register" + ((this.state.iref === undefined) ? "" : "?iref=" + this.state.iref);
        /*let learnMore = 
        <SelectOverlay 
            overlay={this.props.overlay} 
            type="learnmore" 
            label={<span>Learn more</span>}
            content={learnMoreContent}
            contentWidth={200}
        />
        if (this.state.iref === "affiliate") {
            learnMore = <div></div>
        }*/
        return (
            <div className="header-flex site-header">
                <div className="header-logo">
                    <a href="/" className="tr-link-neutral1">
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"marginTop":"4px"}}>
                                <div class="header-logo-item">
                                    <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                                </div>
                            </div>
                            <div className="neutral2">
                                <div class="header-logo-item">
                                    <span className="bolder font16 primary4">&nbsp; Tesults</span> 
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="header-items">
                    <div className="header-item narrow-hide">
                        {
                            this.state.loggedIn !== true ?
                            <NavLink to="/docs" className={
                                this.props.location.pathname.includes("/docs") && !this.props.location.pathname.includes("pricing") ?
                                "site-link-header-active font15"
                                :
                                "site-link font15"
                            }
                            >Documentation</NavLink>
                            :
                            <span></span>
                        }
                    </div>
                    <div className="header-item narrow-hide">
                        {
                            this.state.loggedIn !== true ?
                            <NavLink to="/docs/pricing" className={
                                this.props.location.pathname.includes("/docs/pricing") ?
                                "site-link-header-active font15"
                                :
                                "site-link font15"
                            }
                            >
                            Pricing</NavLink>
                            :
                            <span></span>
                        }
                    </div>
                    <div className="header-item">
                        {
                            this.state.loggedIn !== true ?
                            <a href={loginLink} className="site-link font15">Sign in</a>
                            :
                            <span></span>
                        }
                    </div>
                    <div className="header-item narrow-hide">
                        {
                            this.state.loggedIn !== true ?
                            <a href={registerLink} className="site-link font15">Sign up</a>
                            :
                            <span></span>
                        }
                    </div>
                    <div className="header-item">
                        {
                            this.state.loggedIn !== true ?
                            <NavLink to="/register" className="nounderline"><button type="button" className="btn-confirm"><span className="bold">Try for free</span></button></NavLink>
                            :
                            <div className="font14 neutral4">You are signed in.<br/>View <NavLink to="/results" className="tr-link-primary4">results</NavLink>.</div>
                        }
                    </div>
                    <div class="header-item-mobile">
                        &nbsp;
                    </div>
                </div>
            </div>
        );
    }
};

export default SiteHeader;