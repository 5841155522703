/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsFilesStructure from './DocsFilesStructure';
import DocsTestFramework from './DocsTestFramework';

class DocsCypress extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Cypress test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Cypress test reporting with Tesults."/>
        </Helmet>

        const introduction1 = 
        <div>
            <p>There are two ways to push test results data from Cypress to Tesults. The first way is to use the Cypress Tesults Reporter, which utilizes the Cypress Module API (<a className="site-link-primary2" target="_blank" href="https://docs.cypress.io/guides/guides/module-api.html#cypress-run" rel="noopener noreferrer">https://docs.cypress.io/guides/guides/module-api.html#cypress-run</a>), this is recommended. The second way is by using the Mocha Tesults Reporter.</p>
            <h4 className="mt-5">Cypress Tesults Reporter</h4>
            <h4 className="accentc4 bold">Recommended</h4>
        </div>

        const installation1 = 
        <pre className="docsCode">
            npm install cypress-tesults-reporter --save<br/>
        </pre>

        const configuration1 = 
        <div>
            <p>The Cypress Tesults Reporter uses the Cypress Module API and so you must start Cypress from a 'runner' as described in Cypress's guide (<a className="site-link-primary2" target="_blank" href="https://docs.cypress.io/guides/guides/module-api.html#cypress-run" rel="noopener noreferrer">https://docs.cypress.io/guides/guides/module-api.html#cypress-run</a>). Create a new file with this content:</p>
            <h4 className="neutral4 small">runner.js</h4>
            <pre className="docsCode">
            const cypress = require('cypress')<br/>
            const tesults = require('cypress-tesults-reporter');<br/><br/>

            cypress.run({'{'}<br/>
            &nbsp;&nbsp;// specs to run here<br/>
            {'}'})<br/>
            .then((results) => {'{'}<br/>
            &nbsp;&nbsp;const args = {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;target: 'token',<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;tesults.results(results, args);<br/>
            {'}'})<br/>
            .catch((err) => {'{'}<br/>
            &nbsp;console.error(err)<br/>
            {'}'})<br/>
            </pre>
            <p>Replace token with your Tesults target token, available from the configuration menu within Tesults.</p>
            <p>Now you can simply run the file. For example if you have called your file 'runner.js' then run:</p>
            <pre className="docsCode">
                node runner.js
            </pre>
            <p>After the tests have completed, results will be pushed to Tesults. That completes basic setup.</p>
            <h4>Screenshots and Videos</h4>
            <p>Any screenshots and videos taken using built-in Cypress functions (<a className="site-link-primary2" target="_blank" href="https://docs.cypress.io/guides/guides/screenshots-and-videos" rel="noopener noreferrer">https://docs.cypress.io/guides/guides/screenshots-and-videos</a>) will be automatically saved to Tesults.</p>
            <h4>Custom files</h4>
            <p>If you save files without using built-in Cypress functions (custom files) then these can also be uploaded to Tesults. Save custom files to a local temporary directory. At the end of the test run all custom files will automatically be saved to Tesults as long as you save files in the directory structure below and provide the top-level temporary directory full path in the args options alongside the target token</p>
            <pre className="docsCode">
            const args = {'{'}<br/>
            &nbsp;&nbsp;target: 'eyJ0eXAi...',<br/>
            &nbsp;&nbsp;files: '/Users/admin/temporary'<br/>
            {'}'}<br/>
            </pre>
            <p>The directory structure to save custom files in for them to be automatically saved to Tesults:</p>
            <DocsFilesStructure/>
            <h4>Build data</h4>
            <p>You can optionally include build related information to Tesults in the args with the target token, for example, if this is being run as part of a continuous integration system:</p>
            <pre className="docsCode">
            const args = {'{'}<br/>
            &nbsp;&nbsp;target: 'eyJ0eXAi...',<br/>
            &nbsp;&nbsp;build_name: '1.0.0'<br/>
            &nbsp;&nbsp;build_result: 'pass'<br/>
            &nbsp;&nbsp;build_description: 'Build description'<br/>
            &nbsp;&nbsp;build_reason: 'If failed, provide build failed reason'<br/>
            {'}'}<br/>
            </pre>
        </div>

        const introduction2 = 
        <div>
            <h4 className="mt-5">Mocha Tesults Reporter</h4>
            <h4 className="accenta4 bold">Not Recommended</h4>
            <p>The <a className="site-link-primary2" target="_blank" href="https://docs.cypress.io/guides/tooling/reporters.html#Custom-Reporters" rel="noopener noreferrer">Cypress documentation</a> explains that 'Because Cypress is built on top of Mocha, that means any reporter built for Mocha can be used with Cypress.' Unfortunately this is not quite true currently in all cases.</p>
            <p>Tesults is waiting for these two issues to be addressed by Cypress:</p>
            <ol>
                <li><a className="site-link-primary2" target="_blank" href="https://github.com/cypress-io/cypress/issues/1946" rel="noopener noreferrer">https://github.com/cypress-io/cypress/issues/1946</a></li>
                <li><a className="site-link-primary2" target="_blank" href="https://github.com/cypress-io/cypress/issues/7139" rel="noopener noreferrer">https://github.com/cypress-io/cypress/issues/7139</a></li>
            </ol>
            <p>The first issue requires that your Cypress tests are all contained with a single spec file, you can then follow the instructions below to use the mocha-tesults-reporter to report results from Cypress to Tesults. However in most cases, we understand that is not how test cases are laid out, you are likely splitting test cases across multiple files. We are paying close attention to the further developments by the Cypress team and will update documentation here once there is a better solution available.</p>
            <p>The second issue means that Cypress will have the Mocha process exit early and results may not be uploaded. A work around is to have a second spec after the first one that runs for a while but this is a bad work around and that is why we cannot recommend this approach and suggest you utilize the 'Cypress Tesults Reporter' instead, outlined at the top of this page.</p>
        </div>

        const installation2 = 
        <pre className="docsCode">
            npm install mocha-tesults-reporter --save<br/>
        </pre>

        const configuration2 = 
        <div>
            <p>There are two ways to have Cypress upload results to Tesults. One involves adding properties to the cypress.json file, the other is to use the command line. Whichever method you use is up to you.</p>
            <h4 className="mt-5">Option 1: cypress.json</h4>
            <p>Your cypress.json file must contain the properties below to have results upload to Tesults:</p>
            <pre className="docsCode">
            {'{'}<br/>
            &nbsp;&nbsp;"reporter": "mocha-tesults-reporter",<br/>
            &nbsp;&nbsp;"reporterOptions": {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-target": "token",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-config": "/path/config.js",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-files": "/path-to-files/files",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-build-name": "1.0.0",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-build-description": "build description",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-build-reason": "build failure reason (if failed)"",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tesults-build-result": "pass"<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
            </pre>
            <h4 className="mt-5">Option 2: Command line args</h4>
            <pre className="docsCode">
                cypress run --reporter mocha-tesults-reporter --reporter-options "tesults-target=token"
            </pre> 
            <h4 className="mt-5">Back to Mocha</h4>
            <p className="mb-5">This completes Cypress specific configuration. Now continue viewing the <a className="site-link-primary2" target="_blank" href="/docs/mocha" rel="noopener noreferrer">Tesults Mocha documentation</a> to complete setup.</p>
        </div>

        return (
            <div>
                <DocsTestFramework
                    helmet={helmet}
                    introduction={introduction1}
                    installation={installation1}
                    configuration={configuration1}
                    integration="cypress"
                />

                <DocsTestFramework
                    introduction={introduction2}
                    installation={installation2}
                    configuration={configuration2}
                    integration="cypress"
                />

            </div>
        );
    }
}

export default DocsCypress;