/*global */
import React, { Component } from 'react';

class RoadmapMain extends Component {
    render () {
        return (
            <div>
                
            </div>
        );
    }
};

export default RoadmapMain;