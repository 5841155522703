/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class ConfigTargetDelete extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {state: "view"}
        this.cancel = this.cancel.bind(this)
        this.delete = this.delete.bind(this)
        this.deleteConfirm = this.deleteConfirm.bind(this)
    }

    componentDidMount () {
        this.setState({state: "view"})
    }

    cancel () {
        this.setState({state: "view"})
    }

    delete () {
        this.setState({state: "delete"})
    }

    deleteConfirm () {
        this.setState({submitting: true});
        Request.post("/targetDelete", {id: this.props.target.id, created: this.props.target.created}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to delete target. Ensure that you have removed all users/members from this target before attempting to delete the target"}, submitting: false});
            } else {
                Analytics.event("DeletedTarget");
                this.setState({confirmation: {success: undefined, failure: undefined}, submitting: false}, () => {window.location.reload(true)});
            }
        });
    }

    render () {
        if (this.state.state === "view") {
            return <button className='btn-cancel' onClick={this.delete}>Delete this target</button>
        } else if (this.state.state === "delete") {
            return (
                <div style={{"maxWidth":"800px"}}>
                    <h4 className="bold">IMPORTANT WARNING</h4>
                    <p className="">Deleting this target will delete all stored test results and archived test runs for the target. You should ordinarily never need to delete a target. You can edit the name of a target if it is wrong rather than delete it. If you do not wish to delete this target click cancel. If you do continue with deletion this action is irreversible.</p>
                    <Confirmation confirmation={this.state.confirmation}/>
                    <ConfirmationButtons
                        confirm={this.deleteConfirm}
                        cancel={this.cancel}
                        confirmLabel="Confirm target deletion"
                        cancelLabel="Cancel"
                    />
                </div>
            );
        } else {
            return <div></div>
        }
    }
};

export default ConfigTargetDelete