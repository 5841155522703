/*global */
import React, { Component } from 'react';
import DocsTestData from './DocsTestData';
import DocsBuildConsolidation from './DocsBuildConsolidation';
import SetupAssistant from './SetupAssistant';
import DocsProxy from './DocsProxy';
import DocsFiles from './DocsFiles';
import DocsDynamic from './DocsDynamic';

class DocsLanguage extends Component {
    render() {
        return (
            <div>
                {this.props.helmet}
                {this.props.plugins}
                <hr className='dividor-light'/>
                <h2>Installation</h2>
                {this.props.installation}
                <h2>Configuration</h2>
                {this.props.configuration}
                <h2>Usage</h2>
                {this.props.usage}
                {this.props.example}
                <DocsTestData/>
                <DocsFiles language={this.props.language}/>
                <DocsBuildConsolidation/>
                <DocsDynamic/>
                <DocsProxy/>
                <SetupAssistant/>
            </div>
        );
    }
}

export default DocsLanguage;