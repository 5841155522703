/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsTasks extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Tasks Guide</title>
                    <meta name="description" content="Learn about tasks and how to use them to effectively manage and track failing test cases on Tesults."/>
                </Helmet> 
                <div className='docs-layout-side-column'>
                    <div>
                        <iframe className='youtube' src="https://www.youtube.com/embed/1AyRyTlpIkw?si=a7nbO9i_zDnhOWfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <h2>What is a task?</h2>
                        <p>When tests fail it is useful to know what team member is looking into the failure. With the task feature, Tesults provides a way to assign failing test cases to specific team members. This helps the team keep track of who is responsible for resolving specific failing test cases, and it lets the assignee keep track of what is assigned to them and what they should be working on.</p>
                        
                        <a className="site-link-neutral2" href="# " name="view-tasks-assigned-to-you">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>View tasks assigned to you</h2>
                        <p className="mt-3">To view tasks that are assigned to yourself and others in the team, click <b>Tasks</b> from the menu bar.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3" alt="" src="/img/index/index-6.png"/>*/}

                        <a className="site-link-neutral2" href="# " name="resolving-tasks">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Resolving issues</h2>
                        <p>If you fix an issue assigned to you, click on the test case and set it to resolved. You can provide an optional message about the resolution. After the test case is resolved it will not be removed from your issues view but it will be moved to a resolved list so that you can keep track of what you are done with and what is left to work on.</p>
                        <p>Issues will disappear automatically once subsequent test runs confirm a resolution has fixed the issue and the test is passing. You will receive an email notification when this occurs.</p>

                        <a className="site-link-neutral2" href="# " name="assigning-tasks">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Assigning issues</h2>
                        <p>Click a test case to open up test case details. Click the 'Assign' button in the Task section to assign the test to a member of your team. Ensure your team members have been added to the project by the project administrator.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3" alt="" src="/img/index/index-5.png"/>*/}

                        <a className="site-link-neutral2" href="# " name="workflow">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Workflow</h2>
                        <p>The task assignment workflow is designed to be fast and simple. Assignment to a team member is the only manual action required, automation takes care of the rest.</p>
                        <p>Once assigned the issue will appear in the assignee's view. If the assigned test passes it will be cleared from the assignee's list. Email notifications will be sent on assignment action and on completion. The test case can be reassigned to someone else at any time.
                        </p>
                    </div>
                    <div>
                        <div>On this page</div>
                        <a className="site-link-primary2" href="#what-is-a-task">What is a task?</a>
                        <br/>
                        <a className="site-link-primary2" href="#view-tasks-assigned-to-you">View tasks assigned to you</a>
                        <br/>
                        <a className="site-link-primary2" href="#resolving-tasks">Resolving tasks</a>
                        <br/>
                        <a className="site-link-primary2" href="#assigning-tasks">Assigning tasks</a>
                        <br/>
                        <a className="site-link-primary2" href="#workflow">Workflow</a>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsTasks;