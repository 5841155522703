/*global*/
import React, { Component } from 'react';
import SelectOverlay from './SelectOverlay';
import PassRate from './PassRate';
import ResultsViews from './ResultsViews';
import Context from './Context';

class ResultsHeader1 extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {ellipsisContent: "options"};
        this.resultsSideMod = this.resultsSideMod.bind(this);
    }

    resultsSideMod () {
        this.setState({ellipsisContent: "resultsSideMod"});
    }

    cancelResultsSideMod () {
        this.setState({ellipsisContent: "options"});
    }

    render () {
        return (
            <div className="results-header1">
                <div className="results-header1-target">
                    <SelectOverlay 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        valueChange={this.props.indexChange} 
                        type="target" 
                        label="name"
                        role={this.props.role}
                        options={this.props.options}
                        index={this.props.index}
                        group={this.props.group}
                        groups={this.props.groups}
                        groupChange={this.props.groupChange}
                    />
                </div>
                <div className="results-header2-passrate dividor-light-pass-rate-results">
                    <PassRate 
                        type="target"
                        cases={this.props.cases}
                        prevCases={this.props.prevCases}
                        targets={this.props.targets}
                        targetIndex={this.props.targetIndex}
                        rawResultMaps={this.props.rawResultMaps}
                        runs={this.props.runs}
                        runIndex={this.props.runIndex}
                    />
                </div>
                <div className="results-header1-views">
                    {
                        this.props.isRunArchive === true ?
                        <div></div>
                        :
                        <ResultsViews view={this.props.view} resultsView={this.props.resultsView} supplementalView={this.props.supplementalView}/>
                    }
                </div>
            </div>
        );
    }
};

export default ResultsHeader1;