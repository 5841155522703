/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Pricing from './Pricing';

class DocsPricing extends Component {
    render() {
        return  (
            <div>
                <Helmet>
                    <title>Tesults - Pricing</title>
                    <meta name="description" content="Try Tesults for free forever with the free tier and upgrade to a paid plan with fair and easy to understand pricing."/>
                </Helmet>
                <Pricing 
                    overlay={this.props.overlay}
                    signup={true} 
                    hideButtons={true}
                />
            </div>
        );
    }
}

export default DocsPricing;