/*global */
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsSSO extends Component {
    render () {
        return (
            <div className="whitebg mt-3">
                <Helmet>
                    <title>Tesults - SSO</title>
                    <meta name="description" content="Have team members sign in to Tesults in a more secure and convenient way with SAML based single sign-on or Google OAuth."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Single Sign On.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/G6wCdHXiLcE?si=i87WaYMQbpn-iAHU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Single sign-on (SSO) can make accessing Tesults more secure and convenient for team members. Tesults supports SAML 2.0 and Google OAuth. Both options are detailed below.</p>
                
                <h2 className="mt-5">SAML 2.0</h2>
                <p>This option should be used if your organization uses an identify provider such as Microsoft's Active Directory, Azure AD, Okta, OneLogin, LastPass, Bitium, SecureAuth and other identity providers supporting SAML 2.0.</p>
                <p>Setup SAML based single sign-on from the <NavLink to="/config" className="site-link-primary2">config menu</NavLink>.</p>
                <p>Ensure the selected project is the one you want to configure and then click 'Single sign-on (SSO)' and select SAML.</p>
                <h4>Select an identifier</h4>
                <p>If you have not enabled single sign-on before you will be asking to select an identifier. This identifier will be used as part of your single sign-on login url so pick something that makes sense to your team, speciifically the login link format will be: https://www.tesults.com/login/{'<identifier>'}</p>
                <h4>Input identity provider information</h4>
                <p>Three pieces of information are needed from your identify provider:</p>
                <ol>
                    <li>Login endpoint</li>
                    <li>Identity provider entity ID</li>
                    <li>Public X.509 certificate</li>
                </ol>
                <p>Your can get all of this information from your single sign-on provider. If you would like more details or help gathering this information please contact help@tesults.com.</p>
                <h4>Configure identify provider</h4>
                <p>Configure three attribute statements on your identify provider's (single sign-on provider) interface:</p>
                <ol>
                    <li><b>email</b> - mapped to the email of a user</li>
                    <li><b>firstName</b> - mapped to the first name of the user</li>
                    <li><b>lastName</b> - mapped to the last name of a user</li>
                </ol>
                <p>Set the login endpoint (saml assert) to https://www.tesults.com/samlassert and the 'audience' value to be the identifier you selected (as described above, it forms part of your login endpoint).</p>
                <p>Optionally if you want to allow team members to automatically access Tesults by following a link from within your single sign-on provider's interface (identify provider initiated requests) then ensure you set the default relay state to your identifier.</p>
                <p>Also optional, you can download Tesult's public encryption key if you want to configure end-to-end encryption. Keep in mind all requests are already made using TLS and will be encrypted so this is not required.</p>
                <h4>Enable SAML SSO</h4>
                <p>At this point you can enable single sign-on. Now your team members can sign in using your single sign-on link without creating Tesults login credentails and you get all of the benefits of SSO such as being able to revoke access from your identify provider.</p>
                <h4>Other options</h4>
                <p>Once single sign-on is enabled you can choose to:</p>
                <ul>
                    <li>Disable login with Tesults credentials</li>
                    <li>Disable email address change</li>
                    <li>Disable Tesults project creation</li>
                </ul>
                <p>We recommend disabling the first two for security reasons, but only once you have ensured the SAML configure is correct and the sign on flow has been tested. The third option should be set depending on whether you want users to be able to create their own projects.</p>
                <p>Please contact support if you encounter issuess getting SAML configured.</p>
                <div>
                    <details>
                        <summary className='underline_dotted'>Azure AD Note</summary>
                        <div>
                            <div>
                                If using Azure AD, you cannot specifiy the audience in the same way as other providers. Instead ensure you have values set as shown in these screenshots.
                            </div>
                            <div>
                                <img src="/img/azure-sso-1.png" className='width100' alt="azure-sso-1"/>
                            </div>
                            <div>
                                <img src="/img/azure-sso-2.png" className='width100' alt="azure-sso-2"/>
                            </div>
                            <div>
                                <img src="/img/azure-sso-3.png" className='width100' alt="azure-sso-3"/>
                            </div>
                            <div>
                                <img src="/img/azure-sso-4.png" className='width100' alt="azure-sso-4"/>
                            </div>
                        </div>
                    </details>
                </div>
                <h4>SCIM</h4>
                <p>SCIM is currently unsupported. Please let us know at help@tesults.com if this is something you are interested in.</p>
                
                <h2 className="neutral2 mt-5">Google OAuth</h2>
                <p>If your organization uses Google Workspace you can enable sign in using Google OAuth.</p>
                <p>Setup sign-in using Google from the <NavLink to="/config" className="site-link-primary2">config menu</NavLink>.</p>
                <p>Ensure the selected project is the one you want to configure and then click 'Single sign-on (SSO)' and then select Google OAuth.</p>
                <h4>Select an identifier</h4>
                <p>If you have not enabled single sign-on before you will be asking to select an identifier. This identifier will be used as part of your single sign-on login url so pick something that makes sense to your team, speciifically the login link format will be: https://www.tesults.com/login/{'<identifier>'}</p>
                <h4>Authorize by email domain or specific emails only</h4>
                <p>You can choose to authorize access to the project either by adding specific email addresses in the team members configuration page or by allowing email addresses matching particular domains.</p>
                <h4>Enable sign-in with Google</h4>
                <p>Click the Enable Google SSO button to enable sign-in using Google.</p>
            </div>
        );
    }
}

export default DocsSSO;