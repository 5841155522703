/*global */
import Constants from './Constants';

class Request {
    static get (url, params, callback, external, external_headers) {
        if (params !== undefined && params !== null) {
            Object.keys(params).forEach(function (k) {
                if (params[k] === undefined) {
                    delete params[k];
                } else {
                    if (typeof params[k] === 'object') {
                        try {
                            params[k] = JSON.stringify(params[k]);
                        } catch (err) {
                            console.log("Error submitting a parameter");
                        }
                    }
                }
            });
        }
        let err = false;
        let statusCode = undefined;
        let api = Constants.baseUrl + url;
        let headers = {
            'Content-Type': 'application/json',
        }
        if (external === true) {
            api = url
            headers = external_headers
        }
        if (params !== undefined) {
            api += "?" + new URLSearchParams(params);
        }
        fetch(api, {
            method: 'GET',
            headers: headers
        })
        .then(response => {
            if (!response.ok) {
                err = true;
            }
            statusCode = response.status
            return response.json();
        })
        .then(data => {
            if (callback !== undefined) {
                if (err) {
                    if (data.err !== undefined) {
                        callback(data.err);
                    } else if (data.error !== undefined) {
                        callback(data.error.message);
                    } else {
                        callback(err);
                    }
                } else {
                    if (external === true) {
                        try {
                            data.tesults_status_code = statusCode
                        } catch (err) {
                            // Do not set tesults_status_code
                        }
                    }
                    callback(undefined, data);
                }
            }
        })
        .catch((error) => {
            if (callback !== undefined) {
                callback(error);
            }
        });
    }

    static post (url, params, callback, external, external_headers) {
        let err = false;
        let api = Constants.baseUrl + url
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        }
        if (external === true) {
            api = url
            options = {
                method: 'POST',
                headers: external_headers,
                body: JSON.stringify(params)
            }
        }
        let statusCode = undefined
        fetch(api, options)
        .then(response => {
            if (!response.ok) {
                err = true;
            }
            statusCode = response.status
            return response.json();
        })
        .then(data => {
            if (callback !== undefined) {
                if (err) {
                    if (data.err !== undefined) {
                        callback(data.err);
                    } else if (data.error !== undefined) {
                        callback(data.error.message);
                    } else {
                        callback(err);
                    }
                } else {
                    if (external === true) {
                        try {
                            data.tesults_status_code = statusCode
                        } catch (err) {
                            // Do not set tesults_status_code
                        }
                    }
                    callback(undefined, data);
                }
            }
        })
        .catch((error) => {
            if (callback !== undefined) {
                callback(error);
            }
        });
    }

    static getData (url, params, callback) {
        let err = false;
        let api = Constants.baseUrl + url;
        if (params !== undefined) {
            api += "?" + new URLSearchParams(params);
        }
        fetch(api, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (!response.ok) {
                err = true;
            }
            return response.blob();
        })
        .then(response => {
            return response.text();
        })
        .then(data => {
            if (callback !== undefined) {
                if (err) {
                    callback(err);
                } else {
                    callback(undefined, data);
                }
            }
        })
        .catch((error) => {
            if (callback !== undefined) {
                callback(error);
            }
        });
    }
}

export default Request;