/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';
import Notice from './Notice';

class DocsNodeJS extends Component {
    render() {
        let title = "JavaScript and Node.js test reporting with Tesults"
        let metadescription  = "Learn how to handle JavaScript and Node.js test reporting with Tesults."
        if (this.props.alt === true) {
            title = "Tesults - Node.js and JavaScript API Library"
            metadescription = "Learn how to use the Tesults Node.js API library for effective test reporting."
        }
        const helmet = 
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metadescription}/>
        </Helmet>

        const plugins = 
        <div className='mt-5 mb-5'>
            <Notice type="information" content={
            <div>
                <b>Using Mocha?</b> If you use the <a href="https://mochajs.org/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Mocha test framework</a> no code is necessary to integrate. Use the <NavLink to="/docs/mocha" target="_blank" className="tr-link-primary4 no-break no-break">Tesults Mocha plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using Jest?</b> If you use the <a href="https://jestjs.io/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Jest test framework</a> no code is necessary to integrate. Use the <NavLink to="/docs/jest" target="_blank" className="tr-link-primary4 no-break no-break">Tesults Jest plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using Jasmine?</b> If you use the <a href="https://jasmine.github.io/index.html" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Jasmine test framework</a> no code is necessary to integrate. Use the <NavLink to="/docs/jasmine" target="_blank" className="tr-link-primary4 no-break no-break">Tesults Jasmine plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using Playwright?</b> If you use the <a href="https://playwright.dev" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Playwright test framework</a> no code is necessary to integrate. Use the <NavLink to="/docs/playwright" target="_blank" className="tr-link-primary4 no-break no-break">Tesults Playwright plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using CodeceptJS?</b> If you use the <a href="https://codecept.io/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">CodeceptJS test framework</a> no code is necessary to integrate. Use the <NavLink to="/docs/codeceptjs" target="_blank" className="tr-link-primary4 no-break no-break">Tesults CodeceptJS plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using Nightwatch?</b> If you use the <a href="https://nightwatchjs.org/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Nightwatch test framework</a> no code is necessary to integrate. Use the <NavLink to="/docs/nightwatch" target="_blank" className="tr-link-primary4 no-break no-break">Tesults Nightwatch plugin</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using Waffle?</b> If you use the <a href="https://getwaffle.io/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Waffle test framework</a> no code is necessary to integrate. See the <NavLink to="/docs/waffle" target="_blank" className="tr-link-primary4 no-break no-break">Waffle integration documentation</NavLink> and ignore the instructions below.
            </div>}/>
        </div>

        const installation =
        <div>
            <p>The tesults package is hosted on npm: <a className="tr-link-primary4 no-break" href="https://www.npmjs.com/package/tesults">https://www.npmjs.com/package/tesults</a>. Install it by running:</p>
            <pre className="docsCode">
                <code className="accentb4">npm install tesults --save</code>
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Include the tesults library by using require:</p>
            <pre className="docsCode">
                <code className="accentb4">const tesults = require('tesults');</code>
            </pre>
        </div>

        const usage =
        <div>
        <p>Maintain an array to store your test cases.</p>
        <pre className="docsCode">
        <code>let cases = [];</code>
        </pre>
        <p>A test case is an object containing test case properties.</p>
        <pre className="docsCode">
            let testCase = {'{}'};<br/>
            testCase.name = 'Test 1';<br/>
            testCase.result = 'pass';<br/>
            cases.push(testCase);
        </pre>
        <p>Once all of your test cases have been added to the cases array create a data object for uploading.</p>
        <pre className="docsCode">
            let data = {'{'}<br/>
            &nbsp;&nbsp;target: 'token'<br/>
            &nbsp;&nbsp;results: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;cases: <code className="accentb4">cases</code><br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
        </pre>
        <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
        <p>The final step is to upload results to Tesults using the results function.</p>
        <pre className="docsCode">
            <code className="accentb4">tesults.results(data, function (err, response)</code> {'{'}<br/>
            &nbsp;&nbsp;{'//'} err is undefined unless there is a library error<br/><br/>
            &nbsp;&nbsp;{'//'} response.success is a bool, true if results successfully uploaded, false otherwise<br/>
            &nbsp;&nbsp;{'//'} response.message is a string, if success is false, check message to see reason<br/>
            &nbsp;&nbsp;{'//'} response.warnings is an array of strings, if non empty then issues with file uploads<br/>
            &nbsp;&nbsp;{'//'} response.errors is an array of strings, if success is true this is empty<br/>
            {'}'});
        </pre>
        </div>

        const example = 
        <div>
        <p className="mt-3">Here is an example of a data object using various test case properties.</p>
        <pre className="docsCode">
            var data = {'{'}<br/>
            &nbsp;&nbsp;target: 'token',<br/>
            &nbsp;&nbsp;results: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;cases: [<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: 'Test 1',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;desc: 'Test 1 description.',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;suite: 'Suite A',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: 'pass'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: 'Test 2',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;desc: 'Test 2 description.',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;suite: 'Suite A',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: 'fail',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;reason: 'Assert fail in line 203, example.js',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;files: ['/full/path/to/file/log.txt', 'full/path/to/file/screencapture.png'],<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_CustomField: 'Custom field value',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;steps: [<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: 'Step 1',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;desc: 'Step 1 description.',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: 'pass'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: 'Step 2',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;desc: 'Step 2 description.',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: 'fail'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;reason: 'Assert fail in line 203, example.js'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;name: 'Test 3',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;desc: 'Test 3 description.',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;suite: 'Suite B',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;start: Date.now() - 60000,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;end: Date.now(),<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result: 'pass',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;params: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;param1: 'value1',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;param2: 'value2'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;]<br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}
        </pre>
        </div>

        return (
            <DocsLanguage
                language="nodejs"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}            
                example={example}
            />
        );
    }
}

export default DocsNodeJS;