/*global */
import React, { Component } from 'react';
import Constants from './Constants.js';
import VerifyEmailMain from './VerifyEmailMain'

class VerifyEmail extends Component {
    render () {
        const vc = Constants.getUrlParameter("vc", this.props.location.search);
        return (
            <div className="app-content-margin p-4 whitebg">
                <h4 className="mb-3 text-uppercase bold">Verify Email</h4>
                <VerifyEmailMain vc={vc}/>
            </div>
        );
    }
};

export default VerifyEmail;