import React, { Component } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

class JobResponse extends Component {
    constructor () {
        super()
        this.state = { init: false }
        this.init = this.init.bind(this)
        this.statusCodeChange = this.statusCodeChange.bind(this)
        this.statusKeyChange = this.statusKeyChange.bind(this)
        this.statusValueChange = this.statusValueChange.bind(this)
        this.diff = this.diff.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.type !== prevProps.type) {
            this.init()
        }
    }

    init () {
        try {
            const response = this.props.job[this.props.type].response
            this.setState({
                contentType: response.contentType,
                statusCode: response.statusCode,
                statusKey: response.statusKey,
                statusValue: response.statusValue,
                init: true
            })
        } catch (err) {
            this.setState({init: false})
        }
    }

    statusCodeChange (e) {
        let value = ""
        if (e.target.value !== "") {
            value = parseInt(e.target.value, 10)
        }
        this.setState({statusCode: value})
    }

    statusKeyChange (e) {
        this.setState({statusKey: e.target.value})
    }

    statusValueChange (e) {
        this.setState({statusValue: e.target.value})
    }

    diff () {
        if (this.props.job === undefined) {
            return false
        }
        let diff = false
        if (this.props.job[this.props.type].response.contentType !== this.state.contentType) {
            diff = true
        }
        if (this.props.job[this.props.type].response.statusCode !== this.state.statusCode) {
            diff = true
        }
        if (this.props.job[this.props.type].response.statusKey !== this.state.statusKey) {
            diff = true
        }
        if (this.props.job[this.props.type].response.statusValue !== this.state.statusValue) {
            diff = true
        }
        return diff
    }

    save () {
        let job = this.props.job
        job[this.props.type].response = {
            contentType: this.state.contentType,
            statusCode: this.state.statusCode,
            statusKey: this.state.statusKey,
            statusValue: this.state.statusValue
        }
        this.props.save(job)
    }

    render () {
        if (this.state.init !== true) {
            return <div></div>
        }
        return (
            <div className="width100 pl-3 pr-3 neutral8bg solidborder neutral7border rounderborder">
                <div className='mb-3'>
                    <h5 className="neutral3">{this.props.type[0].toUpperCase() + this.props.type.substring(1) + " job response"}</h5>
                </div>
                <div className="mb-4">
                    <div className='neutral4 font14'>
                        Content-Type
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title="The Content-Type of the response. The only value supported is application/json.">
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <input className="tr-input-side" type="text" value={this.state.contentType} disabled/>
                </div>
                <div className="mb-4">
                    <div className='neutral4 font14'>
                        Status code
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title="The status code to interpret the response as successful.">
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <input className="tr-input-side" type="number" value={this.state.statusCode} onChange={this.statusCodeChange}/>
                </div>
                <div className="mb-4">
                    <div className='neutral4 font14'>
                        Status key
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title={this.props.type === "status" ? "The status key in the JSON response to determine status of the job." : "The status key in the JSON response to determine success for the action. Leave empty if the status code is enough to determine success."}>
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <input className="tr-input-side" type="text" value={this.state.statusKey} onChange={this.statusKeyChange}/>
                    <div className='neutral4 font14'>
                        Status value
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title={this.props.type === "status" ? "The value for the status key to indicate a running or stopped job." : "The value for the status key to indicate success. Leave empty if the status code is enough to determine success."}>
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <input className="tr-input-side" type="text" value={this.state.statusValue} onChange={this.statusValueChange}/>
                </div>
                <div className="mb-4">
                {
                    this.diff() ?
                    <ConfirmationButtons
                        confirm={this.save}
                        cancel={this.init}
                        confirmLabel="Save changes"
                        cancelLabel="Discard"
                    />
                    :
                    <div></div>
                }
                </div>
            </div>
        )
    }

}

export default JobResponse