/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsWebhooks from './DocsWebhooks';

class DocsMattermost extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Mattermost</title>
                    <meta name="description" content="Send test results notifications to Mattermost from Tesults"/>
                </Helmet>
                <DocsWebhooks 
                    service="Mattermost" 
                    url="https://developers.mattermost.com/integrate/incoming-webhooks/"
                    img="/img/webhooks-mattermost.png"
                />
            </div>
        );
    }
}

export default DocsMattermost;

