/*global*/
import React, { Component } from 'react'
import Loading from './Loading'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'
import Cache from './Cache'
import Context from './Context'

class TargetOrder extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {targetsOrder: [], disabled: true, submitting: false, confirmation: {success: undefined, failure: undefined}};
        this.targetsOrderControl = this.targetsOrderControl.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);
        this.targetOrderSetup = this.targetOrderSetup.bind(this);
        this.save = this.save.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.swap = this.swap.bind(this);
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount () {
        this.targets()
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    targets () {
        window.scrollTo(0,0);
        let project = this.project()
        if (project === undefined) { return }
        this.setState({loading:true});
        Cache.request(this.context, Cache.data.targets, {id: project.id }, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({targets: targets, loading: false}, () => this.targetOrderSetup());
            }
        });
    }

    targetOrderSetup () {
        let targetsOrder = [];
        this.state.targets.forEach(function (target) {
            target.order = targetsOrder.length;
            targetsOrder.push(target);
        });
        this.setState({targetsOrder: targetsOrder});
    }

    cancel () {
        this.targets()
    }

    save () {
        this.setState({submitting: true, disabled: true, confirmation: {success: undefined, failure: undefined}});
        Request.post("/targetOrder", {project: this.project(), targetsOrder: this.state.targetsOrder}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to save target order"}, submitting: false, disabled: false});
            } else {
                this.setState({confirmation: {success: "Target order saved", failure: undefined}, submitting: false, disabled: true});
            }
        });
    }

    moveUp (index) {
        if (index === 0) {
            return;
        }
        
        let target1 = this.state.targetsOrder[index - 1];
        let target2 = this.state.targetsOrder[index];

        let temp = target1.order;
        target1.order = target2.order;
        target2.order = temp;

        let targetsOrder = this.state.targetsOrder.slice();
        targetsOrder[index - 1] = target2;
        targetsOrder[index] = target1;
        
        this.setState({targetsOrder: targetsOrder, disabled: false, confirmation: {success: undefined, failure: undefined}});
    }

    moveDown (index) {
        if (index === this.state.targetsOrder.length - 1) {
            return;
        }

        let target1 = this.state.targetsOrder[index];
        let target2 = this.state.targetsOrder[index + 1];

        let temp = target1.order;
        target1.order = target2.order;
        target2.order = temp;

        let targetsOrder = this.state.targetsOrder.slice();
        targetsOrder[index] = target2;
        targetsOrder[index + 1] = target1;
        
        this.setState({targetsOrder: targetsOrder, disabled: false, confirmation: {success: undefined, failure: undefined}});
    }

    swap (target1, target2) {
        let target1Index = target1.order
        let target2Index = target2.order
        let temp = target1.order
        target1.order = target2.order
        target2.order = temp
        let targetsOrder = this.state.targetsOrder.slice()
        targetsOrder[target1Index] = target2
        targetsOrder[target2Index] = target1
        this.setState({targetsOrder: targetsOrder, disabled: false, confirmation: {success: undefined, failure: undefined}})
    }

    onDragStart (e, target) {
        this.setState({draggedTarget: target})
    }

    onDragOver (e, target) {
        this.swap(this.state.draggedTarget, target)
        e.preventDefault()
    }

    onDrop (e, target) {
        e.preventDefault()
    }

    targetsOrderControl () {
        let targetsControl = [];
        this.state.targetsOrder.forEach(function (target) {
            let upLink =
            <span onClick={() => this.moveUp(target.order)} className="nounderline">
                <img src="/img/cheveron-outline-up.svg" className="scrollBtn" alt="Move Up" width="24" height="24"/>
            </span>

            let downLink =
            <span onClick={() => this.moveDown(target.order)} className="nounderline">
                <img src="/img/cheveron-outline-down.svg" className="scrollBtn" alt="Move Down" width="24" height="24"/>
            </span>

            targetsControl.push(
                <div className="caseLink" style={{"display":"flex"}}>
                    <div style={{"flex":"5"}}>
                        <div className="whitebg p-4 mb-3 roundborder caseDiv" draggable={true} onDragStart={(e) => this.onDragStart(e, target)} onDragOver={(e) => this.onDragOver(e, target)} onDrop={(e) => this.onDrop(e, target)}>
                            {target.name}
                        </div>
                    </div>
                    <div style={{"flex":"1"}}>
                        <div className="p-4 mb-3 roundborder">
                            {upLink} {downLink}
                        </div>
                    </div>
                </div>
            );
        }.bind(this));

        return targetsControl;
    }

    render() {
        if (this.state.submitting === true) {
            return <Loading/>
        } else {
            let targetsControl = this.targetsOrderControl();
            return (
                <div>
                    <h4>Target display order</h4>
                    <div className="mb-5 neutral4">Drag or use the up and down buttons to order targets for display in dropdown lists and the dashboard.</div>
                    {targetsControl}
                    <Confirmation confirmation={this.state.confirmation}/>
                    <ConfirmationButtons
                        confirm={() => this.save()}
                        cancel={this.state.disabled ? undefined : this.cancel}
                        confirmLabel="Save"
                        cancelLabel="Cancel"
                        confirmDisabled={this.state.disabled}
                    />
                </div>
            );
        }
    }
}

export default TargetOrder;