/*global*/
import React, { Component } from 'react';

/*//&#8676;
//&#8677;*/

class Side extends Component {
    render () {
        if (this.props.open === true) {
            return (
                <div>
                    {/*<div>
                        <button 
                            onClick={() => 
                                { 
                                    if (this.props.onClose !== undefined) {
                                        this.props.onClose();
                                    }
                                    this.props.side(undefined)
                                }
                            } 
                            className="btn-transparent btn-close btn-close-side-positioning"
                            >
                                &times;
                        </button>
                    </div>*/}
                    {this.props.content}
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
};

export default Side;