/*global */
import React, { Component } from 'react';
import InfoToolTip from './InfoToolTip';
import FairBillingPolicy from './FairBillingPolicy';
import PricingItem from './PricingItem'
import Tooltip from './Tooltip'
//import { Link, NavLink } from 'react-router-dom'
//import Constants from './Constants';
//import Analytics from './Analytics';

class Pricing extends Component {
    constructor () {
        super();
        this.state = {
            width: window.innerWidth, 
            height: window.innerHeight
        }
        this.resizeEvent = this.resizeEvent.bind(this);
        this.select = this.select.bind(this);
    }

    componentDidMount () {
        window.addEventListener('resize', this.resizeEvent);
    }
   
    componentWillUnmount() {
       window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
        this.setState({ width: window.innerWidth, height: window.innerHeight});
    }

    select (product) {
        if (this.props.confirm !== undefined) { // not in docs
            this.props.confirm(product);
        }
    }

    render () {
        let free_button = <button className="btn-cancel-index width100" onClick={() => this.select("free-v1")}>Try for free</button>
        let standard_button = <button className="btn btn-cancel-index width100" onClick={() => this.select("standard-20240719")}>Select</button>
        let plus_button = <button className="btn btn-cancel-index width100" onClick={() => this.select("plus-20240719")}>Select</button>
        let enterprise_button = <a href="mailto:enterprise@tesults.com" className='tr-link-primary4'><button className="btn btn-cancel-index width100">Contact</button></a>
        if (this.props.currentPlan !== undefined) {
            if (this.props.currentPlan.name === "free-v1") {
                free_button = <button className="btn-cancel-index width100">Your current plan</button>
            } else if (this.props.currentPlan.name === "standard-v1") {
                standard_button = <button className="btn btn-cancel-index width100" onClick={() => this.select("standard-20240719")}>Change billing interval</button>
            } else if (this.props.currentPlan.name === "standard-20240719") {
                standard_button = <button className="btn btn-cancel-index width100" onClick={() => this.select("standard-20240719")}>Change billing interval</button>
            } else if (this.props.currentPlan.name === "plus-v1") {
                plus_button = <button className="btn btn-cancel-index width100" onClick={() => this.select("plus-20240719")}>Change billing interval</button>
            } else if (this.props.currentPlan.name === "plus-20240719") {
                plus_button = <button className="btn btn-cancel-index width100" onClick={() => this.select("plus-20240719")}>Change billing interval</button>
            }
        }
        let empty_button = <button className="btn-empty width100">-</button>

        const unlimitedCases = <div><span className="neutral4"><Tooltip text="Unlimited" tooltipText={<div><p>Maximum test cases per run is set to 10,000 by default for performance reasons but can be increased without limit if you contact us.</p></div>}/></span></div>
        const unlimitedTargetsListsRuns = <div><span className="neutral4"><Tooltip text="Unlimited" tooltipText={<div><p>Maximum targets set to 100 limit by default but can be increased without limit if you contact us.</p></div>}/></span></div>

        const runArchiveLimitStandardV1 = <div><span className="neutral4"><Tooltip text={"12 /target"} tooltipText={<div><p>Maximum total test run archives per project is set to 480 by default but can be increased if you contact us.</p></div>}/></span></div>
        const runArchiveLimitPlusV1 = <div><span className="neutral4"><Tooltip text={"36 /target"} tooltipText={<div><p>Maximum total test run archives per project is set to 1440 by default but can be increased if you contact us.</p></div>}/></span></div>

        return (
            <div>
            <div className="pricing-items">
                <PricingItem
                    type="labels"
                    itemName="Free"
                    itemDesc="Try Tesults for free for an unlimited period of time"
                    itemPrice="$0"
                    itemUnit="For a lifetime"
                    itemSelectButton={empty_button}
                    itemShowFairBillingOnDesktop={false}
                    itemFairBilling={false}
                    itemCases="100"
                    itemTargets="5"
                    itemListsAndRuns="10"
                    itemCasesManual="100"
                    releaseChecklists="1"
                    itemData="50GB total"
                    itemFiles="100K total"
                    itemCaseEnhancedAnalysis={false}
                    itemHistory="30 days"
                    itemRunArchiveLimit={false}
                    itemManualRunsInProgress="30 days"
                    itemManualRunArchiving={false}
                    itemAuditLogs={false}
                    itemUnlimitedListRetention={true}
                    itemUsers="Unlimited"
                    item2FA={true}
                    itemGoogleOAuth={true}
                    itemSSO={true}
                    itemEncryption={true}
                    itemPrivateKey={false}
                    itemIntegrations="Unlimited"
                    itemRateLimits="Standard"
                    itemTermsOfService="Standard"
                    itemSupport="Basic"
                    itemAI={false}
                />
                <PricingItem 
                    itemName="Free"
                    itemDesc="Try Tesults for free for an unlimited period of time"
                    itemPrice="$0"
                    itemUnit="For a lifetime"
                    itemSelectButton={free_button}
                    itemShowFairBillingOnDesktop={false}
                    itemFairBilling={false}
                    itemCases="100"
                    itemTargets="5"
                    itemListsAndRuns="10"
                    itemCasesManual="100"
                    releaseChecklists="1"
                    itemData="50GB total"
                    itemFiles="100K total"
                    itemCaseEnhancedAnalysis={false}
                    itemHistory="30 days"
                    itemRunArchiveLimit={false}
                    itemManualRunsInProgress="30 days"
                    itemManualRunArchiving={false}
                    itemAuditLogs={false}
                    itemUnlimitedListRetention={true}
                    itemUsers="Unlimited"
                    item2FA={true}
                    itemGoogleOAuth={true}
                    itemSSO={true}
                    itemEncryption={true}
                    itemPrivateKey={false}
                    itemIntegrations="Unlimited"
                    itemRateLimits="Standard"
                    itemTermsOfService="Standard"
                    itemSupport="Basic"
                    itemAI={false}
                />
                <PricingItem
                    itemName="Standard"
                    itemDesc="Ideal for most teams"
                    itemPrice="$19"
                    itemUnit={<div><span className="neutral4"><Tooltip text="/active user/month" tooltipText={<div><p>$19.00 per active user per month if paying yearly</p><p>$20.65 per active user per month if paying monthly.</p></div>}/></span></div>}
                    itemSelectButton={standard_button}
                    itemShowFairBillingOnDesktop={true}
                    itemFairBilling={true}
                    itemCases={unlimitedCases}
                    itemTargets={unlimitedTargetsListsRuns}
                    itemListsAndRuns={unlimitedTargetsListsRuns}
                    itemCasesManual={unlimitedCases}
                    releaseChecklists="Unlimited"
                    itemData="50GB /user/month"
                    itemFiles="100K /user/month"
                    itemCaseEnhancedAnalysis={true}
                    itemHistory="90 days"
                    itemRunArchiveLimit={runArchiveLimitStandardV1}
                    itemManualRunsInProgress="Unlimited"
                    itemManualRunArchiving={true}
                    itemAuditLogs={true}
                    itemUnlimitedListRetention={true}
                    itemUsers="Unlimited"
                    item2FA={true}
                    itemGoogleOAuth={true}
                    itemSSO={true}
                    itemEncryption={true}
                    itemPrivateKey={false}
                    itemIntegrations="Unlimited"
                    itemRateLimits="Standard"
                    itemTermsOfService="Standard"
                    itemSupport="Standard"
                    itemAI={true}
                />
                <PricingItem
                    itemName="Plus"
                    itemDesc="Higher data throughput and priority support"
                    itemPrice="$37"
                    itemUnit={<div><span className="neutral4"><Tooltip text="/active user/month" tooltipText={<div><p>$37.00 per active user per month if paying yearly</p><p>$40.22 per active user per month if paying monthly.</p></div>}/></span></div>}
                    itemSelectButton={plus_button}
                    itemShowFairBillingOnDesktop={false}
                    itemFairBilling={true}
                    itemCases={unlimitedCases}
                    itemTargets={unlimitedTargetsListsRuns}
                    itemListsAndRuns={unlimitedTargetsListsRuns}
                    itemCasesManual={unlimitedCases}
                    releaseChecklists="Unlimited"
                    itemData="100GB /user/month"
                    itemFiles="200K /user/month"
                    itemCaseEnhancedAnalysis={true}
                    itemHistory="365 days"
                    itemRunArchiveLimit={runArchiveLimitPlusV1}
                    itemManualRunsInProgress="Unlimited"
                    itemManualRunArchiving={true}
                    itemAuditLogs={true}
                    itemUnlimitedListRetention={true}
                    itemUsers="Unlimited"
                    item2FA={true}
                    itemGoogleOAuth={true}
                    itemSSO={true}
                    itemEncryption={true}
                    itemPrivateKey={false}
                    itemIntegrations="Unlimited"
                    itemRateLimits="Standard"
                    itemTermsOfService="Standard"
                    itemSupport="Priority"
                    itemAI={true}
                />
                <PricingItem
                    itemName="Enterprise"
                    itemDesc="Contact us for customized Enterprise plans"
                    itemPrice=""
                    itemUnit={<div>&nbsp;</div>}
                    itemSelectButton={enterprise_button}
                    itemShowFairBillingOnDesktop={false}
                    itemFairBilling={false}
                    itemCases={unlimitedCases}
                    itemTargets={unlimitedTargetsListsRuns}
                    itemListsAndRuns={unlimitedTargetsListsRuns}
                    itemCasesManual={unlimitedCases}
                    releaseChecklists="Unlimited"
                    itemData="Customized"
                    itemFiles="Customized"
                    itemCaseEnhancedAnalysis={true}
                    itemHistory="Customized"
                    itemRunArchiveLimit="Customized"
                    itemManualRunsInProgress="Unlimited"
                    itemManualRunArchiving={true}
                    itemAuditLogs={true}
                    itemUnlimitedListRetention={true}
                    itemUsers="Unlimited"
                    item2FA={true}
                    itemGoogleOAuth={true}
                    itemSSO={true}
                    itemEncryption={true}
                    itemPrivateKey={true}
                    itemIntegrations="Unlimited"
                    itemRateLimits="Customized"
                    itemTermsOfService="Customized"
                    itemSupport="Customized"
                    itemAI={true}
                />
            </div>
            <div className='mb-5'>
                <p className='neutral4 font14'>Questions about vendor onboarding, security and due diligence? Please see the <a href="/docs/vendor-onboarding" className='tr-link-primary4' target="_blank">vendor onboarding resource</a>.</p>
            </div>
            </div>
        )
    }
}

export default Pricing;