/*global Cookies*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import Confirmation from './Confirmation';
import ConfirmationButtons from './ConfirmationButtons';
import Request from './Request';

class ConfigProfileLastName extends Component {
    constructor () {
        super();
        this.change = this.change.bind(this);
        this.save = this.save.bind(this);
        this.state = {value: "", error: "", confirmation: {success: undefined, failure: undefined}};
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0);
        Analytics.event("EditLastName");
        const user = Cookies.getJSON("truser");
        this.setState({value: user.lastName});
    }

    change (e) {
        this.setState({value: e.target.value, confirmation: {success: undefined, failure: undefined}});
    }

    save () {
        Request.post("/userEditName", {lastName: this.state.value}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to save"}});
            } else {
                Analytics.event("EditedLastName");
                this.setState({confirmation: {success: "Saved last name", failure: undefined}});
            }
        });
    }

    cancel () {
        const user = Cookies.getJSON("truser");
        this.setState({value: user.lastName});
    }

    render () {
        const user = Cookies.getJSON("truser");
        return (
            <div>
                <div className="mb-1">
                    <label className="neutral4 font14">Last Name</label>
                </div>
                <div className="inputButtons">
                    <div>
                        <input type="text" className="tr-input" value={this.state.value} onChange={this.change} required/>
                    </div>
                    <div>
                        {
                            user.lastName === this.state.value ? <span></span>
                            :
                            <ConfirmationButtons 
                                confirm={this.save}
                                cancel={this.cancel}
                                confirmLabel="Save"
                                cancelLabel="Cancel"
                            />
                        }
                    </div>
                </div>  
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigProfileLastName;