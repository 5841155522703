/*global*/
import React, { Component } from 'react';
import Copy from './Copy';

class Assignee extends Component {
    constructor () {
        super()
        this.state = {persist: false}
        this.ref = React.createRef();
        this.displayProfile = this.displayProfile.bind(this);
        this.cancelDisplayProfile = this.cancelDisplayProfile.bind(this);
    }

    displayProfile () {
        let fullName = this.props.user.firstName + " " + this.props.user.lastName
        let assigneeImage = <div></div>
        if (this.props.user.firstName === undefined || this.props.user.lastName === undefined) {
            assigneeImage = 
            <div style={{"width":"45px", "height":"45px", "display":"flex", "alignItems":"center", "justifyContent":"center"}} className="circle45 noborder neutral7bg neutral2">
                <div>--</div>
            </div>
        } else {
            assigneeImage = 
            <div style={{"width":"45px", "height":"45px", "display":"flex", "alignItems":"center", "justifyContent":"center"}} className="circle45 noborder neutral7bg neutral2">
                <div>{this.props.user.firstName.slice(0,1) + this.props.user.lastName.slice(0,1)}</div>
            </div>
        }
        
        if (this.props.user.aext !== undefined) {
            assigneeImage = <img alt="" src={'/user/avatar/pdla/' + this.props.user.id + '/' + this.props.user.aext} width="45" height="45" className="circle45 img45 noborder"/>
        }

        let content = 
        <div className="p-5">
            <div className="mb-3">
                {assigneeImage}
            </div>
            <div>
                {fullName}
            </div>
            <div className="neutral3">
                {this.props.user.email} <Copy text={this.props.user.email}/>
            </div>
            <div className="mt-3 font12 neutral4">
                {
                    this.props.clickToDisplay === true ? 
                    <span></span>
                    :
                    <span>Tap or click to keep this displayed</span>
                }
            </div>
        </div>

        if (this.props.overlay !== undefined) {
            this.props.overlay(content, this.ref)
        }
    }

    cancelDisplayProfile () {
        if (this.props.overlay !== undefined) {
            if (this.state.persist !== true) {
                this.props.overlay(undefined)
            }
        }
    }

    render () {
        if (this.props.user === undefined) {
            return <span></span>
        }
        let assignee = <div></div>
        if (this.props.user.firstName === undefined || this.props.user.lastName === undefined) {
            assignee = <div style={{"width":"45px", "height":"45px", "display":"flex", "alignItems":"center", "justifyContent":"center"}} className="circle45 noborder neutral7bg neutral2">
                        <div>--</div>
                    </div>
        } else {
            assignee = <div style={{"width":"45px", "height":"45px", "display":"flex", "alignItems":"center", "justifyContent":"center"}} className="circle45 noborder neutral7bg neutral2">
                        <div>{this.props.user.firstName.slice(0,1) + this.props.user.lastName.slice(0,1)}</div>
                    </div>
        }
        if (this.props.user.aext !== undefined) {
            assignee = <img alt="" src={'/user/avatar/pdla/' + this.props.user.id + '/' + this.props.user.aext} width="45" height="45" className="circle45 img45 noborder"/>
        }
        return (
            <div className="caseLink"
                ref={this.ref} 
                onMouseEnter={(e) => { 
                    if (this.props.clickToDisplay !== true) {
                        this.displayProfile()
                        this.setState({persist: false})
                        e.stopPropagation()
                    }
                }}
                onMouseLeave={(e) => { 
                    this.cancelDisplayProfile()
                    e.stopPropagation()
                }}
                onClick={(e) => { 
                    this.displayProfile()
                    this.setState({persist: true})
                    e.stopPropagation()
                }}
            >
                {assignee}
            </div>
        );
    }
};

export default Assignee;