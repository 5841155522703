/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class DemoSide extends Component {
    render () {
        return (
            <div>
                <p>Thanks for trying out this quick results posting demo. With Tesults you can store and report test automation results easily no matter what tech stack you use for your build and test framework.</p>
                <p><NavLink to="/results?demo=true" className="neutral1 nounderline">View Results For Demo</NavLink></p>
                <hr/><p><NavLink to="/" className="nounderline neutral1">Click here</NavLink> to go back to Tesults home.</p>
            </div>
        );
    }
}

export default DemoSide;