/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsPlaywright extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Playwright test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Playwright test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <p>If you are not using the <a className="site-link-primary2 no-break" target="_blank" href="https://playwright.dev" rel="noopener noreferrer">Playwright</a> test framework but are using JavaScript/Node.js view the <NavLink to="/docs/nodejs" target="_blank" className="site-link-primary2 no-break">Node.js</NavLink> docs instead for integrating with a lower-level library.</p>

        const installation = 
        <div>
            <p>If you are using Playwright with JavaScript/Node.js Tesults supplies a reporter specifically designed for Playwright to make integration quick and easy.</p>
            <p>Install the playwright-tesults-reporter</p>
            <pre className="docsCode">
                npm install playwright-tesults-reporter --save
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Specify the playwright-tesults-reporter as one or one of many reporters to use in the playwright.conf.js file:</p>
            <pre className="docsCode">
            const config = {'{'}<br/>
            &nbsp;&nbsp;reporter: [['playwright-tesults-reporter', {'{'}'tesults-target': 'token'{'}'}]]<br/>
            {'}'}
            </pre>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="site-link-primary2">configuration menu</a>.</p>
            <p>Then run playwright as usual with the <b>npx playwright test</b> command.</p>
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point the playwright-tesults-reporter will push results to Tesults when you run your playwright command. The tesults-target arg must be supplied to indicate which target to use.</p>}
            />
            <h2>Files generated by tests</h2>
            <p>Any files attached during a test using the built-in Playwright attach method (example below) will be automatically uploaded to Tesults for reporting.</p>
            <span className='font12 neutral4'>Example of Playwright test code attaching files. Any files attached in this way in your tests will be automatically uploaded to Tesults.</span>
            <br/>
            <pre className="docsCode">
                await page.screenshot({'{'}path: 'screenshot.png', fullPage: true {'}'});<br/>
                await testInfo.attach('screenshot.png', {'{'} path: 'screenshot.png' {'}'});<br/>
                await testInfo.attach('otherpic.jpg', {'{'} path: '/Users/John/Desktop/picture1.jpg'{'}'});<br/>
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h2>Handling multiple test jobs with targets</h2>
            <p>For supporting multiple test jobs Tesults suggests creating multiple targets, as outlined in the <NavLink to="/docs/structure" target="_blank" className="site-link-primary2">project structuring advice</NavLink>. There are several ways you could handle configuration for multiple targets within Playwright and we discuss one way here.</p>
            <p>Utilize a <a className="site-link-primary2" target="_blank" href="https://playwright.dev/docs/test-parameterize#env-files" rel="noopener noreferrer">.env file</a>. Store multiple target tokens in the file, for example:</p>
            <span className='neutral4 font14'>.env</span>
            <br/>
            <pre className='docsCode'>
            # .env file<br/>
            TARGET1=eyJ0eX...<br/>
            TARGET2=eyJ0eY...<br/>
            TARGET3=eyJ0eZ...<br/>
            </pre>
            <p>Then in your playwright.conf.js file you can utilize these values when you pass in the target to Tesults, for example:</p>
            <span className='neutral4 font14'>playwright.conf.js</span>
            <br/>
            <pre className='docsCode'>
            require('dotenv').config();<br/>
            ...<br/>
            const config = {'{'}<br/>
            &nbsp;&nbsp;reporter: [['playwright-tesults-reporter', {'{'}'tesults-target': <code className="accentb4">process.env.TARGET1</code>{'}'}]]<br/>
            {'}'}<br/>
            ...
            </pre>
            <p>You can create multiple playwright.conf.js files each referencing a different target and in your package.json file create scripts to run each one, for example:</p>
            <span className='neutral4 font14'>package.json</span>
            <br/>
            <pre className='docsCode'>
            ...<br/>
            "scripts": {'{'}<br/>
            &nbsp;&nbsp;"test-job-1": "npx playwright test --config=playwright.config1.js",<br/>
            &nbsp;&nbsp;"test-job-2": "npx playwright test --config=playwright.config2.js",<br/>
            &nbsp;&nbsp;"test-job-3": "npx playwright test --config=playwright.config3.js"<br/>
            {'}'},
            ...<br/>
            </pre>
            <p>Run the desired test job:</p>
            <pre className='docsCode'>
                npm run test-job-1
            </pre>
            <h2>Target</h2>
            <pre className="primary2 docsArgsCode pl-0">tesults-target<span className="neutral7 ml-5">Required</span></pre>
            <p>Required arg to upload to Tesults, if this arg is not provided the reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <pre className="docsCode pre-wrap-break-word">                
                {'{'}'tesults-target': 'token'{'}'}
            </pre>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">{'{'}'tesults-build-name': '1.0.0'{'}'}</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">{'{'}'tesults-build-result': 'pass'{'}'}</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">{'{'}'tesults-build-description': 'added new feature'{'}'}</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">{'{'}'tesults-build-reason': 'build error line 201 somefile.js'{'}'}</pre>
        </div>

        return (
            <div>
            <details>
                <summary className='underline_dotted'>Playwright (Python)</summary>
                <div className='mt-3 mb-3'>
                    <p>Playwright recommends using the official <a className="tr-link-primary4 no-break" target="_blank" href="https://playwright.dev/python/docs/intro" rel="noopener noreferrer">Playwright pytest plugin</a> to write end-to-end tests in Python. If that is how you have setup your Playwright tests then integrating Tesults is quick and easy. Utilize the <a className="tr-link-primary4 no-break" target="_blank" href="https://www.tesults.com/docs/pytest" rel="noopener noreferrer">Tesults pytest plugin</a>. Follow the instructions outlined in the <a className="tr-link-primary4 no-break" target="_blank" href="https://www.tesults.com/docs/pytest" rel="noopener noreferrer">documentation</a> and then when you run your Playwright tests using the pytest command supply the tesults target value. Test results data will then be uploaded to Tesults.</p>
                    <pre className='docsCode'>
                    pytest --tesults-target token
                    </pre>
                    <p>Replace <b>token</b> with your Tesults target token. If you encounter any issues or are using a different method of running Playwright tests with Python please contact <a className="primary4 bold" href="mailto:help@tesults.com">help@tesults.com</a> for assistance.</p>
                    <hr className='dividor-light'/>
                </div>
            </details>
            <details>
                <summary className='underline_dotted'>Playwright (Java)</summary>
                <div className='mt-3 mb-3'>
                    <p>Playwright documentation explains how to use the <a className="tr-link-primary4 no-break" target="_blank" href="https://playwright.dev/java/docs/test-runners" rel="noopener noreferrer">JUnit test runner</a> to run Playwright tests in Java. If that is how you have setup your Playwright tests then integrating Tesults is quick and easy. Utilize the <a className="tr-link-primary4 no-break" target="_blank" href="https://www.tesults.com/docs/junit5" rel="noopener noreferrer">Tesults JUnit 5 plugin</a>. Follow the instructions outlined in the <a className="tr-link-primary4 no-break" target="_blank" href="https://www.tesults.com/docs/junit5" rel="noopener noreferrer">documentation</a> and then run your Playwright tests as usual. Test results data will then be uploaded to Tesults.</p>
                    <p>If you encounter any issues or are using a different method of running Playwright tests with Python please contact <a className="primary4 bold" href="mailto:help@tesults.com">help@tesults.com</a> for assistance.</p>
                    <hr className='dividor-light'/>
                </div>
            </details>
            <details>
                <summary className='underline_dotted'>Playwright (.NET)</summary>
                <div className='mt-3 mb-3'>
                    <p>Playwright states that Playwright for .NET <i>'works best with the built-in .NET test runner, and using NUnit as the test framework'</i> in their <a className="tr-link-primary4 no-break" target="_blank" href="https://playwright.dev/dotnet/docs/test-runners" rel="noopener noreferrer">documentation</a>. If that is how you have setup your Playwright tests then integrating Tesults is quick and easy. Utilize the <a className="tr-link-primary4 no-break" target="_blank" href="https://www.tesults.com/docs/nunit3" rel="noopener noreferrer">Tesults NUnit 3 extension</a>. Follow the instructions outlined in the <a className="tr-link-primary4 no-break" target="_blank" href="https://www.tesults.com/docs/nunit3" rel="noopener noreferrer">documentation</a> (for .NET/.NET Core) and then when you run your Playwright tests using the <code>dotnet test</code> command supply the runsettings file containing your Tesults target token. Test results data will then be uploaded to Tesults.</p>
                    <pre className='docsCode'>
                    dotnet test -s test.runsettings
                    </pre>
                    <p>If you encounter any issues or are using a different method of running Playwright tests with Python please contact <a className="primary4 bold" href="mailto:help@tesults.com">help@tesults.com</a> for assistance.</p>
                    <hr className='dividor-light'/>
                </div>
            </details>
            <br/>
            <details open>
                <summary className='underline_dotted'>Playwright (Node.js)</summary>
                <div>
                    <DocsTestFramework
                        helmet={helmet}
                        introduction={introduction}
                        installation={installation}
                        configuration={configuration}
                        build={build}
                        integration="playwright"
                    />
                </div>
            </details>
            </div>
        );
    }
}

export default DocsPlaywright;