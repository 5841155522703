/*global Cookies*/
import React, { Component } from 'react';
import Constants from './Constants';

import Loading from './Loading';
import Analytics from './Analytics';;
import TargetOrder from './TargetOrder';
import ResultsConsolidation from './ResultsConsolidation';
import RawResultMaps from './RawResultMaps';
import ConfigPreferencesMenu from './ConfigPreferencesMenu';
import SSO from './SSO';

import Context from './Context';
import Request from './Request';
import Cache from './Cache';

import ConfigProjectProject from './ConfigProjectProject'
import ConfigOrg from './ConfigOrg';
import ConfigTargets from './ConfigTargets'
import ConfigNotifications from './ConfigNotifications'
import ConfigIntegrations from './ConfigIntegrations'
import ConfigAPIToken from './ConfigAPIToken'
import ConfigTeam from './ConfigTeam'
import AuditLogs from './AuditLogs'
import ConfigPlan from './ConfigPlan'
import ConfigPaymentDetails from './ConfigPaymentDetails'
import ConfigCountry from './ConfigCountry'
import ConfigInvoices from './ConfigInvoices'
import ConfigPreferencesReportingCaseDisplayAttributes from './ConfigPreferencesReportingCaseDisplayAttributeMaps';

class ConfigProject extends Component {
    static contextType = Context;
    
    constructor (props) {
        super(props);
        Analytics.event("Config");
        const user = Cookies.getJSON("truser");
        let state = null;
        if (user === undefined || user === null) {
            state = "notLoggedIn";
        }
        this.state = {state: state, confirmation: {success: undefined, failure: undefined}, org: undefined, newProjectName: "", newTargetName: "", newTargetDesc: "", newTargetToken: "", editProjectName: "", projectIndex : 0, targets: [], targetIndex: 0, editTargetName: "", editTargetDesc: "", editTargetToken: "", runs: [], runIndex: 0, moreRunsEsk: undefined, members: [], membersString: "", notify: [], notifyString: "", notifyLimit: 0, loading: true, submitting: false, product: "", card: null, countryCode: "US"};
        this.projectRole = this.projectRole.bind(this)
        this.configItem = this.configItem.bind(this)
        this.projectUpdated = this.projectUpdated.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount () {
        window.scrollTo(0,0);
        this.setState({loading: true});
        Request.get("/projectsConfig", undefined, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                this.setState({projects: data.projects, projectIndex: projectIndex, loading: false}, () => this.projectRole());
                if (data.projects.length > 0) {
                    if (this.props.select === "slack") {
                        this.slack();
                    } else if (this.props.select === "pagerduty") {
                        this.pagerduty();
                    } else if (this.props.select === "jira") {
                        this.jira();
                    }
                }
            }
        });
    }

    createProject () {
        window.location.href = Constants.baseUrl + "/config/create-project";
    }

    projectRole () {
        let projects = this.context.projects;
        if (projects === undefined) {
            return;
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
        if (projectIndex === undefined) {
            projectIndex = 0
        }
        let project = undefined;
        if (projectIndex >= projects.length) {
            this.setState({state: "configureProject", loading: false});
            return;
        } else {
            project = projects[projectIndex];
        }
        this.setState({loading: true});
        Request.get("/projectRole", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({state: "configureProject", role: 1, loading: false});
            } else {
                this.setState({state: "configureProject", loading: false, role: data.role});
            }
        });
    }

    projectUpdated(updatedProject) {
        let replacementIndex = -1;
        let projects = this.context.projects;
        for (let i = 0; i < projects; i++) {
            let project = projects[i];
            if (project.id === updatedProject.id) {
                replacementIndex = i;
            }
        }
        if (replacementIndex > -1) {
            projects[replacementIndex] = updatedProject;
            this.context.update(Constants.strings.projects, projects);
        }
    }

    cancel () {
        this.props.history.replace({ pathname: "/config/project"});
    }

    configItem () {
        if (this.props.location === undefined) {
            return <div>Location unavailable</div>
        } else {
            if (this.props.pathMap === undefined) {
                return <div>Pathmap empty (1)</div>
            }
            if (this.props.pathMap.length === 0) {
                return <div>Pathmap empty (2)</div>
            }
            let item = 
                <div style={{"maxWidth":"800px"}}>
                    <div>&larr; Select an option</div>
                </div>
            Object.keys(this.props.pathMap).forEach((key) => {
                const paths = this.props.pathMap[key]
                if (paths.includes(this.props.location.pathname)) {
                    if (key === "project") {
                        item = <ConfigProjectProject overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "targets") {
                        item = <ConfigTargets overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "target-order") {
                        item = <TargetOrder overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "team") {
                        item = <ConfigTeam overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "sso") {
                        item = <SSO role={this.state.role}/>
                    } else if (key === "notifications") {
                        item = <ConfigNotifications overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "build-consolidation") {
                        item = <ResultsConsolidation overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "result-interpretation") {
                        item = <RawResultMaps overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "preferences") {
                        item = <ConfigPreferencesMenu overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "preferences-reporting-case-display-attributes") {
                        item = <ConfigPreferencesReportingCaseDisplayAttributes overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    }  else if (key === "integrations") {
                        item = <ConfigIntegrations overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "organization") {
                        item = <ConfigOrg overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "api-token") {
                        item = <ConfigAPIToken overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "audit-logs") {
                        item = <AuditLogs overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} cancel={this.cancel} role={this.state.role}/>
                    } else if (key === "plan") {
                        item = <ConfigPlan overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} cancel={this.cancel} role={this.state.role}/>
                    } else if (key === "payment-details") {
                        item = <ConfigPaymentDetails overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "country") {
                        item = <ConfigCountry overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else if (key === "invoices") {
                        item = <ConfigInvoices overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} option={this.props.option} history={this.props.history} select={this.props.select} location={this.props.location} createProject={this.createProject} role={this.state.role}/>
                    } else {
                        item = <div>Unknown config</div>
                    }
                }            
            })
            return item
        }
    }

    render () {
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.context.projects === undefined) {
            return (
                <div style={{"minHeight":"100vh"}}>
                    <div className='text-center' style={{"width":"600px", "margin":"auto"}}>
                        <img src="/img/no-projects-icon.svg" width="150px" height="150px"/>
                        <h2 className="mb-5">Looks like you don't have any projects</h2>
                        <div className='mb-5'>You need a project to do anything in Tesults. Create one now.</div>
                        <button type="button" onClick={() => {this.props.history.push({ pathname: "/config/create-project"})}} className="mb-5 btn-confirm-index">+ New project</button>
                        <div className='neutral4'>Waiting to be invited to an existing project? No need to create one.</div> 
                    </div>
                </div>
            )
        } else {
            if (this.context.projects.length === 0) {
                return (
                    <div style={{"minHeight":"100vh"}}>
                        <div className='text-center' style={{"width":"600px", "margin":"auto"}}>
                            <img src="/img/no-projects-icon.svg" width="150px" height="150px"/>
                            <h2 className="mb-5">Looks like you don't have any projects</h2>
                            <div className='mb-5'>You need a project to do anything in Tesults. Create one now.</div>
                            <button type="button" onClick={() => {this.props.history.push({ pathname: "/config/create-project"})}} className="mb-5 btn-confirm-index">+ New project</button>
                            <div className='neutral4'>Waiting to be invited to an existing project? No need to create one.</div> 
                        </div>
                    </div>
                )
            } else {
                const project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
                if (project.status !== "active") {
                    return <div>This project is inactive. If you have questions or would like help with reinstating your project please contact help@tesults.com.</div>
                } else {
                    if (this.state.role < 3) {
                        return (
                            <div>
                                <p className='font14 neutral4'>You cannot configure the selected project. Ask for an administrator of the project to update your role to be granted permission.</p>
                                <button className="btn-confirm mt-5" onClick={this.props.createProject}>Create new project</button>
                            </div>
                        )
                    } else {
                        return this.configItem() // Happy path!
                    }
                }
            }
        }    
    } 
}

export default ConfigProject;