/*global */
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import Header from './Header'
import ResultsHeader13 from './ResultsHeader13'
import ResultsHeader14 from './ResultsHeader14'

import Request from './Request'
import Cache from './Cache'
import Context from './Context'
import Confirmation from './Confirmation'
import ReleaseChecklist from './ReleaseChecklist'
import ReleaseChecklistSelect from './ReleaseChecklistSelect'
import Loading from './Loading'
import Submitting from './Submitting'

class ReleaseChecklists extends Component {
    static contextType = Context

    constructor (props) {
        super(props)
        this.state = {
            state: "view",
            checklists: [],
            checklistIndex: 0,
            teams: [],
            teamIndex: 0,
            cursor: undefined,
            group: undefined,
            loading: false,
            teams: [],
            teamNameInput: "",
            confirmation: {success: undefined, failure: undefined},
            submitting: false,
            submittingMessage: "",
            checklistNameInput: "",
            projectsLength: 0,
            notFound: false,
            checklist: undefined,
            role: 1,
            duplicateChecklistChecked: false,
            duplicateChecklistSelectIndex: 0
        }

        this.stateControl = this.stateControl.bind(this)
        this.toggleMobileChecklistActions = this.toggleMobileChecklistActions.bind(this)
        this.toggleCollapseAll = this.toggleCollapseAll.bind(this)
        this.searchChange = this.searchChange.bind(this)
        this.project = this.project.bind(this)
        this.teams = this.teams.bind(this)
        this.checklists = this.checklists.bind(this)
        this.checklist = this.checklist.bind(this)
        this.checklistUpdated = this.checklistUpdated.bind(this)
        this.teamNameInputChange = this.teamNameInputChange.bind(this)
        this.createTeam = this.createTeam.bind(this)
        this.renderCreateTeam = this.renderCreateTeam.bind(this)
        this.renderCreateChecklist = this.renderCreateChecklist.bind(this)
        this.renderNotFound = this.renderNotFound.bind(this)
        this.checklistNameInputChange = this.checklistNameInputChange.bind(this)
        this.createChecklist = this.createChecklist.bind(this)
        this.teamChange = this.teamChange.bind(this)
        this.getProjects = this.getProjects.bind(this)
        this.renderSideContent = this.renderSideContent.bind(this)
        this.renderEditTeam = this.renderEditTeam.bind(this)
        this.projectRole = this.projectRole.bind(this)
        this.updateTeamName = this.updateTeamName.bind(this)
        this.deleteTeam = this.deleteTeam.bind(this)
        this.toggleDuplicateChecklist = this.toggleDuplicateChecklist.bind(this)
        this.duplicateChecklistSelectChange = this.duplicateChecklistSelectChange.bind(this)
        this.duplicateChecklistSelectRender = this.duplicateChecklistSelectRender.bind(this)
    }

    componentDidMount () {
        this.teams()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.projectId !== undefined) {
            if (this.props.projectId !== prevProps.projectId
                || this.props.teamId !== prevProps.teamId
                || this.props.checklistId !== prevProps.checklistId) {
                this.teams()
            }
        }
        this.stateControl()
    }

    getProjects () {
        this.setState({loading: true})
        Cache.request(this.context, Cache.data.projects, {}, (err, projects) => {
            if (err) {
                this.setState({loading: false})
            } else {
                this.setState({loading: false}, this.teams)
            }
        })
    }

    stateControl () {
        const path = window.location.pathname;
        if (path === "/release-checklists" && this.state.state !== "view") {
            this.setState({state:"view", checklists: [], cursor: undefined}, this.teams)
        }
        if (path === "/release-checklists/config/create" && this.state.state !== "create") {
            this.setState({state:"create"})
        }
        if (path === "/release-checklists/config/create-team" && this.state.state !== "create-team") {
            this.setState({state:"create-team"})
        }
        if (path === "/release-checklists/config/edit-team" && this.state.state !== "edit-team") {
            this.setState({state:"edit-team"})
        }
    }

    toggleMobileChecklistActions () {
        this.setState({mobileChecklistActions: this.state.mobileChecklistActions === true ? false: true});
    }

    toggleCollapseAll () {
        this.setState({collapseAll: this.state.collapseAll ? false : true})
    }

    searchChange (value) {
        this.setState({search: value});
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    projectRole () {
        const project = this.project()
        if (project !== undefined) {
            Request.get("/projectRole", {id: project.id}, (err, data) => {
                if (err) {
                    this.setState({role: 1})
                } else {
                    this.setState({role: data.role})
                }
            })
        }
    }

    teams () {
        let project = this.project()
        if (project === undefined) {
            this.getProjects()
            return
        }

        // rcrl project specified
        if (this.props.projectId !== undefined) {
            if (project.id !== this.props.projectId) {
                let index = -1
                for (let i = 0; i < this.context.projects.length; i++) {
                    const p = this.context.projects[i]
                    if (p.id === this.props.projectId) {
                        index = i
                        break
                    }
                }
                if (index === -1) {
                    this.setState({notFound: true})
                    return
                } else {
                    Cache.setPreference(Cache.preference.projectIndex, index)
                    project = this.context.projects[i]
                }
            }
        }

        this.setState({loading: true})
        Request.get("/teams", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, teams: []})
            } else {
                // rcrl team specified
                let teamIndex = this.state.teamIndex
                if (this.props.teamId !== undefined) {
                    let index = -1
                    for (let i = 0; i < data.teams.length; i++) {
                        const t = data.teams[i]
                        if (t.created === this.props.teamId) {
                            index = i
                            break
                        }
                    }
                    if (index === -1) {
                        this.setState({notFound: true})
                        return
                    } else {
                        teamIndex = index
                    }
                }
                this.setState({loading: false, teams: data.teams, teamIndex: teamIndex, cursor: undefined, checklists: []}, this.props.checklistId === undefined ? this.checklists : this.checklist)
                this.projectRole()
            }
        })
    }

    teamChange (e) {
        this.setState({teamIndex: e.target.value, cursor: undefined, checklists: []}, this.checklists)
    }

    checklists () {
        if (this.state.teams === undefined) {
            return
        }
        const project = this.project()
        if (project === undefined) {
            return
        }
        if (this.state.teamIndex < this.state.teams.length) {
            let team = this.state.teams[this.state.teamIndex]
            if (this.state.cursor === undefined) {
                this.setState({checklists: [], loading: true})
            } else {
                this.setState({loading: true})
            }
            
            Request.get("/get-release-checklists", {id: project.id, team: team.created, cursor: this.state.cursor}, (err, data) => {
                if (err) {
                    this.setState({loading: false, checklists: []})
                } else {
                    let checklists = this.state.checklists
                    for (let i = 0; i < data.checklists.length; i++) {
                        checklists.push(data.checklists[i])
                    }
                    this.setState({loading: false, checklists: checklists, cursor: data.cursor})
                }
            })
        }
    }

    // used if rcrl checklist supplied
    checklist () {
        this.setState({loading: true})
        Request.get("/release-checklist", {id: this.props.projectId, team: this.props.teamId, checklist: this.props.checklistId}, (err, data) => {
            if (err) {
                this.setState({loading: false, notFound: true})
            } else {
                this.setState({loading: false, state: "checklist", checklist: data.checklist})
            }
        })
    }

    checklistUpdated (checklist) {
        this.setState({checklist: checklist})
    }

    teamNameInputChange (e) {
        this.setState({teamNameInput: e.target.value})
    }

    createTeam () {
        const project = this.project()
        if (project === undefined) { return }
        this.setState({submitting: true, submittingMessage: "Creating team..."})
        Request.post("/team", {id: project.id, name: this.state.teamNameInput}, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: "Failed to create team. " + err}})
            } else {
                this.setState({submitting: false, teamIndex: 0, teams: [data.team], confirmation: {success: undefined, failure: undefined}, state:"create"}, () => {this.props.history.push("/release-checklists")})
            }
        })
    }

    checklistNameInputChange (e) {
        this.setState({checklistNameInput: e.target.value})
    }

    createChecklist () {
        const project = this.project()
        if (project === undefined) { return }
        if (this.state.teams === undefined) {
            return
        }
        if (this.state.teamIndex >= this.state.teams.length) {
            return
        }
        let duplicateChecklist = undefined
        if (this.state.duplicateChecklistChecked === true) {
            if (this.state.checklists !== undefined) {
                if (this.state.duplicateChecklistSelectIndex < this.state.checklists.length) {
                    duplicateChecklist = this.state.checklists[this.state.duplicateChecklistSelectIndex].created
                }
            }
        }
        this.setState({submitting: true, submittingMessage: "Creating release checklist..."})
        Request.post("/release-checklist-create", {id: project.id, team: this.state.teams[this.state.teamIndex].created, name: this.state.checklistNameInput, duplicateChecklist: duplicateChecklist}, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: "Failed to create checklist: " + err}})
            } else {
                let checklists = this.state.checklists
                if (checklists === undefined) { checklists = [] }
                checklists.push(data.checklist)
                this.setState({submitting: false, checklists: checklists, checklistIndex: checklists.length - 1, confirmation: {success: undefined, failure: undefined}}, () => this.props.history.push("/release-checklists"))
            }
        })
    }

    updateTeamName () {
        const project = this.project()
        if (project === undefined) { return }
        const team = this.state.teams[this.state.teamIndex]
        this.setState({submitting: true, submittingMessage: "Updating team..."})
        Request.post("/team-update", {id: project.id, created: team.created, name: this.state.teamNameInput}, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: "Failed to update team"}})
            } else {
                let teams = this.state.teams
                teams[this.state.teamIndex] = data.team
                this.setState({submitting: false, teams: teams, confirmation: {success: undefined, failure: undefined}}, () => this.props.history.push("/release-checklists"))
            }
        })
    }

    deleteTeam () {
        const project = this.project()
        if (project === undefined) { return }
        const team = this.state.teams[this.state.teamIndex]
        this.setState({submitting: true, submittingMessage: "Updating team..."})
        Request.post("/team-delete", {id: project.id, created: team.created}, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: "Failed to delete team"}})
            } else {
                let teams = this.state.teams
                teams.splice(this.state.teamIndex, 1)
                this.setState({submitting: false, teams: teams, confirmation: {success: undefined, failure: undefined}}, () => this.props.history.push("/release-checklists"))
            }
        })
    }

    toggleDuplicateChecklist () {
        if (this.state.duplicateChecklistChecked === true) {
            this.setState({duplicateChecklistChecked: false});
        } else {
            this.setState({duplicateChecklistChecked: true});
        }
    }

    duplicateChecklistSelectChange (e) {
        this.setState({duplicateChecklistSelectIndex: e.target.value})
    }

    renderSideContent () {
        if (this.state.loading === true) {
            this.props.side(<div></div>)
            return
        }
        if (this.state.state === "view") {
            this.props.side(
                <div className='font14 neutral4'>
                    <p>Checklists help keep track of items and tasks required for a successful release.</p>
                </div>
            )
        } else if (this.state.state === "create" || this.state.state === "create-team" || this.state.state === "edit-team") {
            this.props.side(
                <div className='font14 neutral4'>
                    <button className='btn-cancel' onClick={() => this.props.history.push("/release-checklists")}>Cancel</button>
                </div>
            )
        } else {
            this.props.side(<div></div>)
        }
    }

    renderCreateTeam () {
        return (
            <div>
                <p className='font15 neutral3'>Enter a team name for checklists to be assigned to, for example <i>Engineering</i>.</p>
                <div>
                    <div className='mb-3'>
                        <span className='neutral4 font14'>Team name</span>
                        <br/>
                        <input className='tr-input' value={this.state.teamNameInput} onChange={this.teamNameInputChange}/>
                    </div>
                    <div className='mb-3'>
                        <button className='btn-confirm' onClick={this.createTeam}>Confirm</button>
                    </div>
                    <div>
                        <Confirmation confirmation={this.state.confirmation}/>
                    </div>
                </div>
            </div>
        )
    }

    duplicateChecklistSelectRender () {
        let options = []
        if (this.state.checklists !== undefined) {
            for (let i = 0; i < this.state.checklists.length; i++) {
                const checklist = this.state.checklists[i]
                options.push(<option key={i} value={i}>{checklist.name}</option>)
            }
        }
        const duplicateChecklistConfirm = 
            <div>
                <span className='font14 neutral4'>Populate new checklist with items from an existing checklist?</span>
                <br/>
                <input type="checkbox" onChange={this.toggleDuplicateChecklist} checked={this.state.duplicateChecklistChecked}/>
            </div>
        const duplicateChecklistSelect = 
        <select className="custom-select mr-5 mb-3" onChange={this.duplicateChecklistSelectChange} value={this.state.duplicateChecklistSelectIndex}>
            {options}
        </select>
        let loadMore = <div></div>
        if (this.state.cursor !== undefined) {
            loadMore = <div><button className='btn-cancel mr-5' onClick={this.checklists}>Load more</button></div>
        }
        if (this.state.checklists === undefined) {
            return <div></div>
        }
        if (this.state.checklists.length === 0) {
            return <div></div>
        }
        return (
            <div>
                {duplicateChecklistConfirm}
                <span className='font14 neutral4'>Select checklist:</span>
                <br/>
                {duplicateChecklistSelect}
                {loadMore}
            </div>
        )
    }

    renderCreateChecklist () {
        return (
            <div>
                <div className='mb-3'>
                    <span className='neutral4 font14'>Checklist name (can be changed later)</span>
                    <br/>
                    <input className='tr-input' value={this.state.checklistNameInput} onChange={this.checklistNameInputChange}/>
                </div>
                <div className='mb-3'>
                    <button className='btn-confirm' onClick={this.createChecklist}>Confirm</button>
                </div>
                <div className='mb-5'>
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
                <hr className='dividor-light'/>
                {this.duplicateChecklistSelectRender()}
            </div>
        )
    }

    renderEditTeam () {
        const teams = this.state.teams
        if (teams === undefined) { return <div></div> }
        if (teams.length === 0) { return <div></div> }
        if (this.state.teamIndex >= teams.length) { return <div></div> }
        const team = teams[this.state.teamIndex]
        return (
            <div>
                <div className='mb-3'>
                    <span className='neutral4 font14'>Edit team name. Currently: {team.name}</span>
                    <br/>
                    <input className='tr-input' value={this.state.teamNameInput} onChange={this.teamNameInputChange}/>
                </div>
                <div className='mb-3'>
                    <button className='btn-confirm' onClick={this.updateTeamName}>Update</button>
                </div>
                <div>
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
            </div>
        )
    }

    renderNotFound () {
        return (
            <div>
                Not found
            </div>
        )
    }

    render () {
        this.renderSideContent()
        if (this.state.loading === true) {
            return <Loading/>
        }

        let pageTitle = "Release checklists"
        let mobileChecklistActions = <span></span>
        if (this.state.mobileChecklistActions === true) {
            mobileChecklistActions = side;
        }
        const mobileChecklistActionsButton = <button onClick={this.toggleChecklistActions} className="mt-3 btn-confirm">{this.state.mobileChecklistActions === true ? "Hide actions" : "Show actions"}</button>

        let main = <div></div>

        if (this.state.notFound === true) {
            main = this.renderNotFound()
        } else if (this.state.teams.length === 0) {
            main = this.renderCreateTeam()
        } else if (this.state.state === "create-team") {
            main = this.renderCreateTeam()
        } else if (this.state.state === "edit-team") {
            main = this.renderEditTeam()
        } else if (this.state.state === "create") {
            main = this.renderCreateChecklist()
        } else if (this.state.state === "view") {
            /*
                render: 
                - dropdownlist with teams
                - release checklists for team all displayed
            */
                

            let teamOptions = []
            if (this.state.teams !== undefined) {
                for (let i = 0; i < this.state.teams.length; i++) {
                    const team = this.state.teams[i]
                    teamOptions.push(<option key={i} value={i}>{team.name}</option>)
                }
            }
            const teamSelect = 
            <select className="custom-select mr-5" onChange={this.teamChange} value={this.state.teamIndex}>
                {teamOptions}
            </select>

            let checklistSelection = []
            
            if (this.state.checklists !== undefined) {
                for (let i = 0; i < this.state.checklists.length; i++) {
                    const checklist = this.state.checklists[i]
                    checklistSelection.push(<ReleaseChecklistSelect key={i} checklist={checklist}/>)
                }
            }

            let deleteButton = <span></span>
            if (this.state.checklists.length === 0) {
                deleteButton = <button className='btn-cancel mr-3' onClick={this.deleteTeam}>Delete team</button>
            }

            main = 
            <div>
                <span className='neutral4 mr-3'>Team</span>
                {teamSelect}
                <button className='btn-confirm mr-5' onClick={() => {this.props.history.push("/release-checklists/config/create")}}>Create new checklist</button>
                {this.state.cursor === undefined ? <span></span> : <button className='btn-cancel mr-5' onClick={this.checklists}>Load more</button>}
                {this.state.role < 2 ? <span></span> : <button className='btn-cancel mr-3' onClick={() => {this.props.history.push("/release-checklists/config/create-team")}}>Create new team</button>}
                {this.state.role < 2 ? <span></span> : <button className='btn-cancel mr-3' onClick={() => {{this.props.history.push("/release-checklists/config/edit-team")}}}>Edit team name</button>}
                {this.state.role < 2 ? <span></span> : deleteButton }
                <hr className='dividor-light'/>
                {checklistSelection.length > 0 ? checklistSelection : <p className='neutral4'>No checklists for this team</p>}
            </div>
        }

        if (this.state.state === "checklist") {
            main = <ReleaseChecklist 
                        checklist={this.state.checklist}
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        sideOpen={this.props.sideOpen}
                        location={this.props.location} 
                        history={this.props.history}
                        state={this.props.state}
                        projectId={this.props.projectId}
                        teamId={this.props.teamId}
                        checklistId={this.props.checklistId}
                        itemId={this.props.itemId}
                        checklistUpdated={this.checklistUpdated}
                    />
        }

        return (
            <div>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content="Release checklists help prepare for a successful pre-release, release and post-release process."/>
                </Helmet>
                {
                    this.state.state === "view"  ?
                    <Header
                        type="header-double"
                        header1={
                            <ResultsHeader13
                                state={this.state.state}
                                checklist={this.state.checklist}
                            />
                        }
                        header2={
                            <ResultsHeader14
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                collapseAll={this.state.collapseAll}
                                toggleCollapseAll={this.toggleCollapseAll}
                                onSearchChange={this.searchChange}
                                checklists={this.state.checklists}
                            />
                        }
                    />
                    :
                    <Header
                        type="header-single"
                        header1={
                            <ResultsHeader13
                                state={this.state.state}
                                checklist={this.state.checklist}
                            />
                        }
                    />
                }
                
                <div className="app-main-margin">
                    <div className="desktop-hide">
                        <div className="list-actions-padding">
                            {mobileChecklistActions}    
                            {mobileChecklistActionsButton}
                        </div>
                    </div>
                    <Submitting submitting={this.state.submitting} submittingMessage={this.state.submittingMessage}/>
                    {main}
                </div>
                {/*listCase*/}
            </div>
        )
    }
}

export default ReleaseChecklists