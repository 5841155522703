/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class AffiliateAbout extends Component {
    render () {        
        return (
            <div style={{"minHeight": "100vh", "display":"flex"}}>
                <div style={{"maxWidth":"600px"}}>
                    <h1 className="neutral1">Tesults Affiliate Program</h1>
                    <p>
                        The Tesults Affiliate Program helps software engineering teams meet their test results data management, 
                        analysis and reporting requirements with an introduction to Tesults via a third party, the affiliate.
                    </p>
                    <h2 className="neutral1 mt-5 pt-5">Help release high quality software faster with reduced risk</h2>
                    <p>
                        Tesults brings clarity to test results data. Software engineering teams push results data from their test 
                        systems to Tesults for powerful analysis, reporting and notification capabilities. Tesults has introduced 
                        a new standard for the management of test results data. As new engineering teams discover Tesults and 
                        integrate with it, they benefit from enhanced productivity and faster releases with assurance that risk is being 
                        managed effectively. This enables faster go-to-market leading to an increase in revenue and cost savings 
                        from risk mitigation.
                    </p>
                    <p>
                        Software engineering and test teams benefit enormously after discovering Tesults. The Tesults affiliate 
                        program is an opportunity for you, as an existing participant in the software engineering and testing space 
                        to become an affiliate and to help make the world a better place by helping more software engineering teams 
                        discover Tesults.
                    </p>
                    <h2 className="neutral1 mt-5 pt-5">How it works</h2>
                    <p>
                        The Tesults Affiliate Program is open to individuals and businesses that participate in providing informational
                        content about software development and testing online including tutorials, blogs, videos, news, articles and 
                        long form content.
                    </p>
                    <ol>
                        <li className="mb-3">
                            <NavLink to="/register?iref=affiliate" className="tr-link-primary4">Sign up</NavLink> to become an affiliate.
                        </li>
                        <li className="mb-3">
                            After signing up, generate an affiliate link. Use the link on your website or video description or social media to direct members 
                            of your audience that may be interested in learning about Tesults to the Tesults website.
                        </li>
                        <li className="mb-3">
                            <p>View affiliate reports to understand how your link is performing:</p>
                            <ol>
                                <li className="mb-3">Number of times your affiliate link has been used to visit Tesults</li>    
                                <li className="mb-3">Number of users that have signed up at Tesults using your affiliate link</li>
                                <li className="mb-3">Number of users that have created a project on Tesults having used your affiliate link</li>
                                <li className="mb-3">Number of paid projects that have been created on Tesults having used your affiliate link.</li>
                                <li className="mb-3">Payments that you are due or have been paid out.</li>
                            </ol>
                        </li>
                        <li className="mb-3">
                            A member of the Tesults team will reach out to you for bank and transfer details once you have a balance greater than zero owed to you.
                        </li>
                    </ol>
                    <h2 className="neutral1 mt-5 pt-5">How affiliates are rewarded</h2>
                    <p>
                        The Tesults affiliate program is new, having launched in July 2021, and to reward early affiliate adopters a generous reward system in place.
                    </p>
                    <p>
                        If a referral (a vist to Tesults via an affiliate link) results in a sign up (becoming a registered user) within 30 days of the referral,
                        the user account is linked to the affiliate for the lifetime of the account. From that point, a proportion of the revenue from any paid 
                        project subscription for this user account is shared witht he affiliate for the entire duration of the subscription at 10.0% of the revenue.
                        Furthermore, any subsequent subscriptions (a single user can have multiple subscriptions) for the same user account are also eligible for the 10.0%
                        revenue reward for the lifetime of the subscription. Since this is a business service, if the owner of the subscription leaves the company
                        and transfers the project ownership to another user, the affiliate continues to be rewarded as the attribution is transferred along with
                        the owner for the project subscription.
                    </p>
                    <p>
                        As an example, a user clicks on the affiliate link and signs up at Tesults.com within 30 days of referral. 
                        The user is likely to create a free project initially for evaluation purposes rather than a paid subscription immediately. 
                        If at any time in the future they upgrade the project to a paid project the affiliate will receive 10.0% of the recurring revenue from the subscription.
                    </p>
                    <p>
                        Pleae be aware that Tesults subcription revenue is based on the number of active users for the project.
                        As an example if a paid project subscription has 10 active users, at the current pricing, the affiliate earns 
                        USD $11.60 every month during the course of the subscription, from just that one referral. If the active users 
                        increase for the project, to 20, then the affiliate earnings go up to $23.20 per month from that one referral. 
                        Ten similar referrals would just multiply this and result in a $232.00 per month, one hundred would be $2320.00 
                        per month.
                    </p>
                    <h2 className="neutral1 mt-5 pt-5">Join now</h2>
                    <p>
                        Become an affiliate, receive a referral link, put it on your site and get paid while helping software 
                        engineering and test teams discover Tesults. Help them to boost their productivity, cut costs and 
                        allow innovation to take place at a faster pace.
                    </p>
                    <div>
                        {

                        }
                    </div>
                    <div className="mb-5">
                        <img src="/img/tesults-logo-pass-128.png" className="resultspulsepass" width="32" height="32" alt="pass"/>
                        <img src="/img/tesults-logo-fail-128.png" className="resultspulsefail" width="32" height="32" alt="fail"/>
                    </div> 
                </div>
                <div>
                    
                </div>
            </div>
            
        );
    }
};

export default AffiliateAbout;