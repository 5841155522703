/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';
import Notice from './Notice'

class DocsXCTest extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>XCTest test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle XCTest test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <div>
            <p>Using the <a className="site-link-primary2" target="_blank" href="https://developer.apple.com/documentation/xctest" rel="noopener noreferrer">XCTest (or XCUITest)</a> test framework? Follow the instructions below to report results. If you are not using XCTest please see the <NavLink to="/docs/swift" target="_blank" className="site-link-primary2">Swift</NavLink> docs for integrating with a lower-level library.</p>
            <Notice type="information" content={<span>An alternative way to report results if you do not want to follow the instructions below is to utilize the <NavLink to="/docs/junit-xml" target="_blank" className="tr-link-primary4 no-break">JUnit XML output from XCTest</NavLink> but the method below is recommended because you can also upload files that way.</span>}/>
        </div>

        const installation = 
        <div>
            <p>Add the tesults-xctest-observer package as a dependency to your project. Search for tesults-xctest-observer in Xcode to find the package.</p>
            <img src="/img/tesults-xctest-observer-package.png" className='width75' alt="tesults-xctest-observer-package"/>
            <p>Ensure you <b>install version 1.0.6 or higher</b>. You can verify the version you have installed by checking package dependencies.</p>
            <img src="/img/tesults-xctest-observer-package-dependency-check.png" className='width50' alt="tesults-xctests-observer-package-dependency-check"/>
            <br/>
            <p>If you see a build error related to XCTest not being found, ensure the 'Enable Testing Search Paths' option is set to Yes for your app target.</p>
            <img src="/img/tesults-xctest-observer-package-config-1.png" className='width50' alt="tesults-xctests-observer-package-config-1"/>
        </div>

        const configuration = 
        <div>
            <p>You are required to register the tesults-xctests-observer at the startup of your tests as described in the overview section for <a className="site-link-primary2 no-break" target="_blank" href="https://developer.apple.com/documentation/xctest/xctestobservationcenter" rel="noopener noreferrer">XCTestsObservationCenter</a>. Specifically this requires creating a new Swift class that at a minimum does the following:</p>
            <pre className="docsCode">
            //<br/>
            //&nbsp;&nbsp;Start.swift<br/>
            //&nbsp;&nbsp;iphone-app<br/>
            //<br/>
            //<br/><br/>

            import Foundation<br/>
            import XCTest<br/>
            @testable import tesults_xctest_observer<br/><br/>

            class Start : NSObject {'{'}<br/>
            &nbsp;&nbsp;override init () {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;super.init()<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;<code className="accentb4">XCTestObservationCenter.shared.addTestObserver(TesultsXCTestObserver(target: "token"))</code><br/>
            &nbsp;&nbsp;{'}'}<br/>
            {'}'}<br/>
            </pre>
            <p className="mt-3">The <b>'token'</b> value above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
            <p>Now set the NSPrincipalClass key in your Info.plist to the class you created to register the observer:</p>
            <img src="/img/tesults-xctest-observer-package-config-2.png" className='width50' alt="tesults-xctests-observer-package-config-2"/>
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Basic configuration complete</h4>}
                content={<p>When you run your XCTest/XCUITest test cases now the Tesults observer will be registered and upload results to the target in your project that your token value maps to.</p>}
            />
            <p>If Tesults upload does not take place check your terminal output in Xcode to see whether there is an error message related to not being able to find the NSPrincipalClass you specified. This error must be resolved. A couple of things to check. First ensure your NSPrincipalClass is in the Compile Sources list of Build Phases for your target:</p>
            <img src="/img/xctest-compile-sources.png" className="width50" alt="xctest-compile-sources"/>
            <p>If the above is correct, then you likey have an issue with the name itself. This needs to be the name of your target followed by a dot and the name of the class you are using as your NSPrincipalClass.</p>
            <img src="/img/xctest-nsprincipalclass.png" className="width50" alt="xctest-nsprincipalclass"/>
            <h2>Files generated by tests</h2>
            <p>Attach files during a test using the tesults-xctests-observer file function (example below). Files attached this way will be automatically uploaded to Tesults for reporting.</p>
            <span className='font12 neutral4'>Example of tesults-xctests-observer test code attaching files. Any files attached in this way in your tests will be automatically uploaded to Tesults.</span>
            <br/>
            <pre className="docsCode">
                import tesults_xctest_observer<br/>
                ...<br/><br/>
                <code className="accentc6">{'//'} Run this code inside a test function otherwise replace the<br/>
                {'//'} 'self' reference with a reference to the running test</code><br/>
                TesultsXCTestObserver.file(testCase: self, path: "full/path/to/file/screenshot.png")<br/>
                TesultsXCTestObserver.file(testCase: self, path: "full/path/to/file/log.txt")
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h1>TesultsXCTestObserver parameters</h1>
            <h2>Target</h2>
            <pre className="primary2 docsArgsCode pl-0">target<span className="neutral7 ml-5">Required</span></pre>
            <p>Required arg to upload to Tesults, if this arg is not provided the reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <pre className="docsCode pre-wrap-break-word">
                TesultsXCTestObserver(target: "token")
            </pre>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">buildName<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="primary2 docsArgsCode pl-0">buildResult<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="primary2 docsArgsCode pl-0">buildDescription<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="primary2 docsArgsCode pl-0">buildReason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <br/>
            <span className='neutral4 font14'>Example with all parameters supplied</span>
            <br/>
            <pre className="docsCode">
            XCTestObservationCenter.shared.addTestObserver(<br/>
            &nbsp;&nbsp;TesultsXCTestObserver(<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;target: "token",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;buildName: "1.0.0",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;buildDescription: "build description here",<br/> 
            &nbsp;&nbsp;&nbsp;&nbsp;buildResult: "fail",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;buildReason: "Failure reason here"<br/>
            &nbsp;&nbsp;)<br/>
            )
            </pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                build={build}
                integration="xctest"
            />
        );
    }
}

export default DocsXCTest