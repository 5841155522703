/*global */
import React, { Component } from 'react';

class PieChart extends Component {
    constructor() {
        super();
        this.drawPie = this.drawPie.bind(this);
        this.drawPieChart = this.drawPieChart.bind(this);
        this.initPieChart = this.initPieChart.bind(this);
        this.pointHover = this.pointHover.bind(this);
        this.pointClick = this.pointClick.bind(this);
        this.pointExit = this.pointExit.bind(this);
    }

    pointHover (event) {
        this.piechartsvg.pauseAnimations();
        let translate = {x: 0, y: 0};
        let angle = parseFloat(event.target.attributes.getNamedItem("data-angle").value);
        if (angle === 0) {
            // No translate
        } else if (angle < Math.PI / 2.0) {
            translate.x = 2;
            translate.y = -2;
        } else if (angle < Math.PI) {
            translate.x = 2;
            translate.y = 2;
        } else if (angle < (Math.PI / 2.0) + Math.PI) {
            translate.x = -2;
            translate.y = 2;
        } else if (angle < 2 * Math.PI) {
            translate.x = -2;
            translate.y = -2;
        }

        event.target.setAttribute("style","-webkit-transform:scale(1.05,1.05) translate(" + translate.x + "px," + translate.y + "px);");

        let result = event.target.attributes.getNamedItem("data-result").value;
        this.props.piechartHover(result);
    }

    pointClick (event) {
        // Currently does nothing.
    }

    pointExit (event) {
        this.piechartsvg.unpauseAnimations();
        event.target.setAttribute("style","-webkit-transform:scale(1,1) translate(0px,0px);");
        this.props.piechartExit();
    }

    drawPie(ctx, color, startAngle, endAngle) {
        ctx.fillStyle = color;
        ctx.beginPath();
        var x = 75; // x coordinate
        var y = 75; // y coordinate
        var radius = 75; // Arc radius
        ctx.arc(x, y, radius, startAngle, endAngle, false);
        ctx.lineTo(x,y);
        ctx.closePath();
        ctx.fill();
    }

    drawPieChart () {
        let svg = [];
        let key = 0;

        const passTotal = this.props.pass;
        const failTotal = this.props.fail;
        const unknownTotal = this.props.unknown;
        const total = passTotal + failTotal + unknownTotal;

        const tesultsColor = "hsl(209, 93%, 24%)";
        const passColor = "hsl(120, 70%, 31%)";
        const failColor = "hsl(344, 100%, 39%)";
        const unknownColor = "hsl(35, 97%, 31%)";

        const r = 50;

        if (total === 0) {
            svg.push(<circle cx="50" cy="50" r={r} fill="none" stroke={tesultsColor} onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick} key={key++} data-result="notests" data-angle={0}/>);
            return svg;
        }

        if (passTotal === total) {
            svg.push(<circle cx="50" cy="50" r={r} fill={passColor} stroke={passColor} onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick} key={key++} data-result="pass" data-angle={0}/>);
            return svg;
        }

        if (failTotal === total) {
            svg.push(<circle cx="50" cy="50" r={r} fill={failColor} stroke={failColor} onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick} key={key++} data-result="fail" data-angle={0}/>);
            return svg;
        }

        if (unknownTotal === total) {
            svg.push(<circle cx="50" cy="50" r={r} fill={unknownColor} stroke={unknownColor} onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick} key={key++} data-result="unknown" data-angle={0}/>);
            return svg;
        }

        const startAngle = (Math.PI/180)* 0;
        const passEndAngle = (passTotal / total) * 360 * (Math.PI/180);
        const failEndAngle = (failTotal / total) * 360 * (Math.PI/180);
        const unknownEndAngle = (unknownTotal / total) * 360 * (Math.PI/180);

        let origin = {x: 50, y: 50};
        let passCoord = {x1: 100, y1: 50, x2:0, y2:0};
        let failCoord = {x1: 100, y1: 50, x2:0, y2:0};
        let unknownCoord = {x1: 100, y1: 50, x2:0, y2:0};

        if (passTotal !== 0) {
            passCoord.x2 = origin.x + r * Math.cos(passEndAngle);
            passCoord.y2 = origin.y + r * Math.sin(passEndAngle);
        }

        if (failTotal !== 0) {
            failCoord.x2 = origin.x + r * Math.cos(failEndAngle);
            failCoord.y2 = origin.y + r * Math.sin(failEndAngle);
        }

        if (unknownTotal !== 0) {
            unknownCoord.x2 = origin.x + r * Math.cos(unknownEndAngle);
            unknownCoord.y2 = origin.y + r * Math.sin(unknownEndAngle);
        }

        if (passTotal > 0) {
            let largeArcFlagPass = passEndAngle - startAngle <= Math.PI ? "0" : "1";
            svg.push(<path d={"M " + passCoord.x1 + " " + passCoord.y1 + 
                " L " + origin.x + " " + origin.y +
                " L " + passCoord.x2 + " " + passCoord.y2 + 
                " A " + r + " " + r + " 0 " + largeArcFlagPass + " 0 " + passCoord.x1 + " " + passCoord.y1}
                stroke="white"
                fill={passColor} 
                key={key++}
                onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick}
                data-angle={passEndAngle}
                data-result="pass"
            />);
        }

        if (failTotal > 0) {
            let largeArcFlagFail = failEndAngle - startAngle <= Math.PI ? "0" : "1";
            let failRotate = passEndAngle * (180.0/Math.PI);
            let failTransform = "rotate(" + failRotate + "," + origin.x + "," + origin.y + ")";

            svg.push(<g transform={failTransform} key={key++}>
                    <path d={"M " + failCoord.x1 + " " + failCoord.y1 + 
                    " L " + origin.x + " " + origin.y +
                    " L " + failCoord.x2 + " " + failCoord.y2 + 
                    " A " + r + " " + r + " 0 " + largeArcFlagFail + " 0 " + failCoord.x1 + " " + failCoord.y1}
                    stroke="white"
                    fill={failColor}
                    key={key++}
                    onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick}
                    data-angle={failEndAngle}
                    data-result="fail"
                    />
            </g>);
        }
        

        if (unknownTotal > 0) {
            let largeArcFlagUnknown = unknownEndAngle - startAngle <= Math.PI ? "0" : "1";
            let unknownRotate = (passEndAngle * (180.0/Math.PI)) + (failEndAngle * (180.0/Math.PI));
            let unknownTransform = "rotate(" + unknownRotate + "," + origin.x + "," + origin.y + ")";
    
            svg.push(<g transform={unknownTransform} key={key++}>
                    <path d={"M " + unknownCoord.x1 + " " + unknownCoord.y1 + 
                    " L " + origin.x + " " + origin.y +
                    " L " + unknownCoord.x2 + " " + unknownCoord.y2 + 
                    " A " + r + " " + r + " 0 " + largeArcFlagUnknown + " 0 " + unknownCoord.x1 + " " + unknownCoord.y1}
                    stroke="white"
                    fill={unknownColor}
                    key={key++}
                    onMouseEnter={this.pointHover} onMouseLeave={this.pointExit} onClick={this.pointClick}
                    data-angle={unknownEndAngle}
                    data-result="unknown"
                />
                </g>);
        }
        
        return svg;
    }

    initPieChart () {
        return this.drawPieChart();
    }

    render () {
        let piechart = this.initPieChart();
        return (
            <svg width="200" height="200" viewBox="-10 -10 120 120" ref={(piechartsvg) => { this.piechartsvg = piechartsvg; }}>
                <g>
                <animateTransform attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                from="0 50 50"
                                to="360 50 50"
                                dur="60s"
                                repeatCount="indefinite"/>
                {piechart}
                </g>
            </svg>
        );
    }
}

/* Find SVG Coords:
        let pt = this.piechartsvg.createSVGPoint();
        pt.x = event.clientX;
        pt.y = event.clientY;
        let svgpt =  pt.matrixTransform(this.piechartsvg.getScreenCTM().inverse());
        
        let xTrans = "1px"
        let yTrans = "1px"
        if (svgpt.x < 50) {
            xTrans = "-1px";
        }
        if (yTrans > 50) {
            yTrans = "-1px";
        }
        */

export default PieChart;