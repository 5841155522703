/*global*/
import React, { Component } from 'react';
import PieChart from './PieChart';
import CaseDetail from './CaseDetail';
import ResultsFiles from './ResultsFiles';
import SupplementalBugs from './SupplementalBugs';
import SupplementalFlaky from './SupplementalFlaky';
import Analytics from './Analytics';
import { Line } from 'react-chartjs';
import TimeFormatted from './TimeFormatted';
import CaseGroup from './CaseGroup';
import {utilsRawResultMapMap, utilsIsPassResult, utilsIsFailResult} from './Utils'
import CaseEnhancedAnalysis from './CaseEnhancedAnalysis';

class SupplementalView extends Component {
    constructor () {
        super();
        this.state = {
                        selectedCase: null, 
                        resultHighlight: undefined, 
                        oldFailuresExpanded: true, 
                        newPassesExpanded: true, 
                        newFailuresExpanded: true,
                        flakyExpanded: true
                    };
        this.selectCase = this.selectCase.bind(this);
        this.caseHide = this.caseHide.bind(this);
        this.piechartHover = this.piechartHover.bind(this);
        this.piechartExit = this.piechartExit.bind(this);
        this.collapseIcon = this.collapseIcon.bind(this);
        this.collapseClass = this.collapseClass.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.flakyDataSetup = this.flakyDataSetup.bind(this);
        this.lineChartData = this.lineChartData.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        let selectedCase = null;
        let testCases = this.props.cases[this.props.runIndex];
        let buildCase = this.props.builds[this.props.runIndex];
        let rspCaseNum = undefined;
        if (this.props.rsp !== undefined) {
            if (this.props.rsp.caseNum !== undefined) {
                try {
                    rspCaseNum = parseInt(this.props.rsp.caseNum, 10);
                } catch (err) {
                    rspCaseNum = undefined;
                }
            }
        }
        testCases.forEach(function (c) {
            if (rspCaseNum !== undefined) {
                if (c.num === rspCaseNum) {
                    let cDetail = {};
                    cDetail.suite = c.suite;
                    cDetail.num = c.num;
                    cDetail.name = c.name;
                    cDetail.desc = c.desc;
                    cDetail.reason = c.reason;
                    cDetail.result = c.result.charAt(0).toUpperCase() + c.result.slice(1);
                    cDetail.params = c.params;
                    cDetail.hash = c.hash;
                    cDetail.files = c.files;
                    cDetail.flaky = c.flaky;
                    cDetail.steps = c.steps;
                    cDetail.priority = c.priority;
                    cDetail.resultOriginal = c.resultOriginal;
                    cDetail.resultChangeReason = c.resultChangeReason;
                    cDetail.resultChangeBy = c.resultChangeBy;
                    cDetail.resultChangeTime = c.resultChangeTime;
                    cDetail.custom = c.custom;
                    cDetail.metadata = c.metadata;
                    cDetail.bugs = c.bugs;
                    cDetail.cost = c.cost;
                    cDetail.batch = c.batch;
                    cDetail.start = c.start;
                    cDetail.end = c.end;
                    cDetail.duration = c.duration;
                    cDetail.importList = c.importList;
                    cDetail.rawResult = c.rawResult
                    cDetail.refFiles = c.refFiles

                    if (cDetail.reason !== undefined) {
                        if (cDetail.reason === Object(cDetail.reason)) {
                            cDetail.reason = JSON.stringify(cDetail.reason, null, 2);
                        }
                    }

                    // Custom fields
                    Object.keys(c).forEach(function(key) {
                        if (key.charAt(0) === "_") {
                            if (c[key] !== "" && c[key] !== undefined && c[key] !== null) {
                                cDetail[key] = c[key];
                            }
                        }
                    });
                    // Modal
                    selectedCase = cDetail;
                }
            }
        });
        if (rspCaseNum !== undefined && selectedCase === null) {
            if (buildCase !== undefined) {
                selectedCase = buildCase;
            }
        }
        this.selectCase(selectedCase);
        this.props.rspCancel();
        //this.setState({selectedCase: selectedCase}, () => this.props.rspCancel());
    }

    flakyDataSetup () {
        let passHistory = {};
        let flakyCases = {};
        if (this.props.runs.length - this.props.runIndex >= 10) {
            let limit = this.props.runIndex + 10;
            for (let runIndex = this.props.runIndex; runIndex < limit; runIndex++) {
                if (this.props.cases[runIndex] !== undefined) {
                    let cases = this.props.cases[runIndex].slice();
                    for (let caseIndex = 0; caseIndex < cases.length; caseIndex++) {
                        let c = cases[caseIndex];
                        if (passHistory[c.hash] === undefined) {
                            passHistory[c.hash] = [c.result];
                        } else {
                            let history = passHistory[c.hash];
                            history.push(c.result);
                            passHistory[c.hash] = history;
                        }
                    }
                }
            }
            Object.keys(passHistory).forEach(function (hash) {
                let passArray = passHistory[hash];
                let changes = 0;
                let previous = undefined;
                passArray.forEach(function(result) {
                    if (previous === undefined) {
                        previous = result;
                    } else {
                        if (result !== previous) {
                            changes += 1;
                        }
                        previous = result;
                    }
                });
                const flakyThreshold = 2;
                flakyCases[hash] = changes > flakyThreshold ? true : false;
            });
        }
        return flakyCases;
    }

    selectCase (c) {
        if (c === null) {
            return;
        }
        let projects = this.props.projects;
        let projectIndex = this.props.projectIndex;
        let targets = this.props.targets;
        let targetIndex = this.props.targetIndex;
        let runs = this.props.runs;
        let runIndex = this.props.runIndex;

        //let testCases = this.props.cases[this.props.runIndex].slice();
        let cDetail = {};
        cDetail.suite = c.suite;
        cDetail.num = c.num;
        cDetail.name = c.name;
        cDetail.desc = c.desc;
        cDetail.reason = c.reason;
        cDetail.result = c.result.charAt(0).toUpperCase() + c.result.slice(1);
        cDetail.params = c.params;
        cDetail.hash = c.hash;
        cDetail.files = c.files;
        cDetail.flaky = c.flaky;
        cDetail.steps = c.steps;
        cDetail.priority = c.priority;
        cDetail.resultOriginal = c.resultOriginal;
        cDetail.resultChangeReason = c.resultChangeReason;
        cDetail.resultChangeBy = c.resultChangeBy;
        cDetail.resultChangeTime = c.resultChangeTime;
        cDetail.custom = c.custom;
        cDetail.metadata = c.metadata;
        cDetail.bugs = c.bugs;
        cDetail.cost = c.cost;
        cDetail.jiraTransitions = c.jiraTransitions;
        cDetail.batch = c.batch;
        if (cDetail.reason !== undefined) {
            if (cDetail.reason === Object(cDetail.reason)) {
                cDetail.reason = JSON.stringify(cDetail.reason, null, 2);
            }
        }
        // Custom fields
        Object.keys(c).forEach(function(key) {
            if (key.charAt(0) === "_") {
                if (c[key] !== "" && c[key] !== undefined && c[key] !== null) {
                    cDetail[key] = c[key];
                }
            }
        });
        cDetail.start = c.start;
        cDetail.end = c.end;
        cDetail.duration = c.duration;
        cDetail.importList = c.importList;
        cDetail.rawResult = c.rawResult
        
        this.props.sideOverlay(
            <CaseDetail 
                view="supplemental" 
                sideOverlay={this.props.sideOverlay}
                overlay={this.props.overlay}
                projects={projects} 
                projectIndex={projectIndex} 
                targets={targets} 
                targetIndex={targetIndex} 
                runs={runs} 
                runIndex={runIndex} 
                cases={this.props.cases} 
                builds={this.props.builds} 
                selectedCase={cDetail} 
                rawResultMaps={this.props.rawResultMaps}
                role={this.props.role}
                persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                trl={this.props.trl}
                caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
            />
        );
    }

    caseHide() {
        this.setState({selectedCase: null});
    }

    piechartHover (result) {
        this.setState({resultHighlight: result});
    }

    piechartExit (result) {
        this.setState({resultHighlight: undefined});
    }
    
    collapseIcon (expanded) {
        if (expanded === true) {
            return <div className="collapseIcon"></div>
        } else {
            return <div className="expandIcon"></div>
        }
    }

    collapseClass (group) {
        if (group === "oldFailures") {
            return this.state.oldFailuresExpanded === true ? "collapse show": "collapse";
        } else if (group === "newFailures") {
            return this.state.newFailuresExpanded === true ? "collapse show": "collapse";
        } else if (group === "newPasses") {
            return this.state.newPassesExpanded === true ? "collapse show": "collapse";
        } else if (group === "flaky") {
            return this.state.flakyExpanded === true ? "collapse show": "collapse";
        } else {
            return "";
        }
    }

    toggleExpand (group) {
        if (group === "oldFailures") {
            Analytics.event('ToggleExpandOldFailures');
            this.setState({oldFailuresExpanded: this.state.oldFailuresExpanded === true ? false: true});
        } else if (group === "newFailures") {
            Analytics.event('ToggleExpandNewFailures');
            this.setState({newFailuresExpanded: this.state.newFailuresExpanded === true ? false: true});
        } else if (group === "newPasses") {
            Analytics.event('ToggleExpandNewPasses');
            this.setState({newPassesExpanded: this.state.newPassesExpanded === true ? false: true});
        } else if (group === "flaky") {
            Analytics.event('ToggleExpandFlaky');
            this.setState({flakyExpanded: this.state.flakyExpanded === true ? false: true});
        } else {
            return;
        }
    }

    lineChartData () {
        let dataLabels = [];
        let dataValues = [];
        let iteration = 0;
        const limit = 25;

        const map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)

        for (let i = this.props.runIndex; i < this.props.runs.length; i++) {
            if (iteration >= limit) {
                break;
            }
            iteration += 1;
            let pass = 0;
            let total = 0;
            let passPercentValue = 0;
            if (i < this.props.cases.length) {
                let rawCases = this.props.cases[i];
                rawCases.forEach(function (c) {
                    if (c.suite !== "[build]") {
                        if (utilsIsPassResult(c, map)) {
                            pass += 1;   
                        }
                        total += 1;
                    }
                });
            }
            if (total !== 0) {
                passPercentValue = Math.floor((pass/total) * 10000) / 100;
            } else {
                passPercentValue = 0;
            }

            dataLabels.push(TimeFormatted.chartLabelFormatted(this.props.runs[i].created));
            dataValues.push(passPercentValue);
        }

        dataLabels.reverse();
        dataValues.reverse();

        const data = {
            
            labels: dataLabels,
            datasets: [
                {
                    label: "Pass Percent",
                    backgroundColor: "hsl(344, 100%, 39%)",
                    fillColor: "hsl(120, 70%, 31%)",
                    strokeColor: "hsl(120, 70%, 31%)",
                    pointColor: "hsl(209, 93%, 65%) ",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: dataValues
                }
            ]
        };

        const options = {
            scaleOverride : true,
            scaleSteps : 5,
            scaleStepWidth : 20,
            scaleStartValue : 0,
            responsive: true,
            tooltipFillColor: "hsl(209, 93%, 24%)",
            tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value + '%' %>"
        };

        return {
            data: data,
            options: options
        }
    }

    render  () {
        let cases = [];
        let pass = 0;
        let fail = 0;
        let unknown = 0;
        let total = 0;
        //let even = true;
        let selectCase  = this.selectCase;
        let projects = this.props.projects;
        let projectIndex = this.props.projectIndex;
        let targets = this.props.targets;
        let targetIndex = this.props.targetIndex;
        let runs = this.props.runs;
        let runIndex = this.props.runIndex;

        let testCases = this.props.cases[this.props.runIndex].slice();

        let flakyCases = this.flakyDataSetup();
        testCases.forEach(function (c) {
            if (c.hash !== undefined) {
                if (flakyCases[c.hash] !== undefined) {
                    c.flaky = flakyCases[c.hash];
                }
            }
        });

        const map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)

        // previous test cases configure
        let previousTestCases = [];
        let previousTestCasesDictionary = {};
        let newFailures = [];
        let newPasses = [];
        let oldFailures = [];
        let comparable = true;
        let comparableTestCases = {};
        let duplicates = [];
        let duplicateMessage = <span></span>

        let fileCount = 0;
        let suiteCount = 0;
        let paramsCount = 0;
        let reasonCount = 0;
        let suitesObj = {};
        let timeBetweenRuns = "No previous recent results";

        let buildData = "No build information";
        let buildCase = this.props.builds[this.props.runIndex];
        if (buildCase !== undefined) {
            buildData = "Build information provided";
        }

        let haveHash = false; // this check can be removed on jan 1 2019

        if (this.props.runIndex + 1 < this.props.runs.length) {
            timeBetweenRuns = this.props.runs[this.props.runIndex].created - this.props.runs[this.props.runIndex + 1].created;
            timeBetweenRuns = timeBetweenRuns / 1000.0;
            if (timeBetweenRuns < 60) {
                let seconds = parseInt(timeBetweenRuns, 10);
                if (seconds === 1) {
                    timeBetweenRuns = seconds + " second since previous run";
                } else {
                    timeBetweenRuns = seconds + " seconds since previous run";
                }
            } else {
                timeBetweenRuns = timeBetweenRuns / 60.0;
                if (timeBetweenRuns < 60) {
                    let minutes = parseInt(timeBetweenRuns, 10);
                    if (minutes === 1) {
                        timeBetweenRuns = minutes + " minute since previous run";
                    } else {
                        timeBetweenRuns = minutes + " minutes since previous run";
                    }
                } else {
                    timeBetweenRuns = timeBetweenRuns / 60.0;
                    if (timeBetweenRuns < 24) {
                        let hours = parseInt(timeBetweenRuns, 10);
                        if (hours === 1) {
                            timeBetweenRuns = hours + " hour since previous run";
                        } else {
                            timeBetweenRuns = hours + " hours since previous run";
                        }
                    } else {
                        timeBetweenRuns = timeBetweenRuns / 24.0;
                        let days = parseInt(timeBetweenRuns, 10);
                        if (days === 1) {
                            timeBetweenRuns = days + " day since previous run";
                        } else {
                            timeBetweenRuns = days + " days since previous run";
                        }
                    }
                }
            }
            previousTestCases = this.props.cases[this.props.runIndex + 1];
            if (previousTestCases === undefined) {
                comparable = false;
            } else {
                previousTestCases.forEach(function (c) {
                    let id = c.suite + "-" + c.name + "-" + c.params;
                    if (c.hash !== undefined) {
                        id = c.hash;
                        haveHash = true;
                    }
                    if (previousTestCasesDictionary[id] !== undefined) {
                        comparableTestCases[id] = false;
                        duplicates.push(<div key={duplicates.length}>{"Name: " + c.name} {c.suite === undefined ? "" : "| Suite: " + c.suite + ""} {c.params === undefined ? "" : "| Params: " + c.params}</div>);
                    } else {
                        previousTestCasesDictionary[id] = c;
                    }
                });
            }
        } else {
            comparable = false;
        }

        if (duplicates.length > 0) {
            duplicateMessage = 
            <div className="card accentb7bg mb-3">
                <div className="card-body accentb1 p-4">
                    <h4 className="accentb1">Duplicate test cases</h4>
                    <p className="accentb1">Accurate highlights cannot be provided because there are duplicate tests in the currently selected or previous test run. A test is considered a duplicate if it has the same test case name, test suite name and test case parameters.</p>
                    {duplicates}
                </div>
                <br/>
            </div>
        }

        const context = this.props.context;

        testCases.forEach(function (c) {
            c.context = context;
            if (utilsIsPassResult(c, map)) {
                pass += 1;
            } else if (utilsIsFailResult(c, map)) {
                fail += 1;
                if (c.reason !== undefined) {
                    reasonCount += 1;
                }
            } else {
                unknown += 1;
            }
            total += 1;

            if (c.files !== undefined) {
                fileCount += c.files.length;
            }
            if (c.suite !== undefined) {
                if (suitesObj[c.suite] === undefined) {
                    suitesObj[c.suite] = true;
                    suiteCount += 1;
                }
            }
            if (c.params !== undefined) {
                paramsCount += 1;
            }

            /*
            cases.push(
                <Case 
                    key={total}
                    overlay={this.props.overlay}
                    selectCase={selectCase} 
                    projects={projects} 
                    projectIndex={projectIndex} 
                    targets={targets} 
                    targetIndex={targetIndex} 
                    runs={runs} 
                    runIndex={runIndex} 
                    case={c}
                    persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                    persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                    trl={this.props.trl}/>
            );*/

            cases.push(c);
            
            if (comparable) {
                let id = c.suite + "-" + c.name + "-" + c.params;
                if (comparableTestCases[id] !== false) {
                    if (c.hash !== undefined && haveHash) {
                        id = c.hash;
                    }
                    let previousC = previousTestCasesDictionary[id];
                    if (previousC !== undefined) {
                        if (utilsIsFailResult(previousC, map) && utilsIsFailResult(c, map)) {
                            oldFailures.push(cases[cases.length - 1]);
                        } else if (utilsIsFailResult(previousC, map) && utilsIsPassResult(c, map)) {
                            newPasses.push(cases[cases.length - 1]);
                        } else if (utilsIsPassResult(previousC, map) && utilsIsFailResult(c, map)) {
                            newFailures.push(cases[cases.length - 1]);
                        }
                    } else {
                        // No previous case
                        if (utilsIsFailResult(c, map)) {
                            newFailures.push(cases[cases.length - 1]);
                        } else if (utilsIsPassResult(c, map)) {
                            newPasses.push(cases[cases.length - 1]);
                        }
                    }
                }
            }
        });
        
        let oldFailuresTitle = "Continuing Failures (" + oldFailures.length + ")";
        let newPassesTitle = "New Passes (" + newPasses.length + ")";
        let newFailuresTitle = "New Failures (" + newFailures.length + ")";

        let failReasonMessage = "No failures";
        if (fail > 0) {
            failReasonMessage = reasonCount + " / " + fail + " failing test cases have failure reasons"
        }

        let piechartHover = this.piechartHover;

        // Result highlighting based on pie chart hover
        
        
        let highlight = <div className="font15">
                            {suiteCount} Test Suites<br/>
                            {fileCount} Files In Total<br/>
                            {paramsCount} Parameterized Test Cases<br/>
                            {buildData}<br/>
                            {failReasonMessage}<br/>
                            {timeBetweenRuns}<br/><br/>
                        </div>

        let pieOnHover = <span className="white pl-2 pr-2"><small>&nbsp;</small></span>
                        
        let pieOnHoverBg = "summaryborder whiteborder whitebg pt-1 pb-1";

        if (this.state.resultHighlight === "pass") {
            pieOnHover = <span className="white pl-2 pr-2"><small>{newPasses.length + " New Passes + " + (pass - newPasses.length) + " Continuing Passes = " + pass + " Total Passes"}</small></span>
                        
            pieOnHoverBg = "summaryborder whiteborder accentc3bg pt-1 pb-1"
        } else if (this.state.resultHighlight === "fail") {
            pieOnHover = <span className="white pl-2 pr-2"><small>{newFailures.length + " New Failures + " + oldFailures.length + " Continuing Failures = " + fail + " Total Failures"}</small></span>
                        
            pieOnHoverBg = "summaryborder whiteborder accenta3bg pt-1 pb-1"
        } else if (this.state.resultHighlight === "unknown") {
            pieOnHover = <span className="white pl-2 pr-2"><small>{unknown} Total Unknowns</small></span>
                        
            pieOnHoverBg = "summaryborder whiteborder accentb2bg pt-1 pb-1"
        } else if (this.state.resultHighlight === "notests") {
            pieOnHover = <span className="white pl-2 pr-2"><small>No Tests</small></span>
            pieOnHoverBg = "neutral1bg pt-1 pb-1"
        }

        let resultHighlight = this.state.resultHighlight;

        let chart = this.lineChartData();

        const project = (projects && projectIndex !== undefined && projectIndex < projects.length) ? projects[projectIndex] : undefined;

        return (
            <div className="mt-3">
                <div className="supplemental-charts">
                    <div className="supplemental-charts-pie">
                        <PieChart pass={pass} fail={fail} unknown={unknown} piechartHover={piechartHover} piechartExit={this.piechartExit}/>
                        <br/>
                        <div style={{"paddingLeft":"26px", "paddingRight":"26px", "paddingTop":"12px", "paddingBottom":"12px", "marginRight":"12px"}} className="whitebg elevation5">
                            <span className={resultHighlight === "pass" ? "pass font14" : "neutral2 font14"}>{pass}&nbsp;&nbsp;Pass</span>
                            <br/>
                            <span className={resultHighlight === "fail" ? "fail font14" : "neutral2 font14"}>{fail}&nbsp;&nbsp;Fail</span> 
                            <br/>
                            <span className={resultHighlight === "unknown" ? "unknown font14" : "neutral2 font14"}>{unknown}&nbsp;&nbsp;Unknown</span>
                            <hr/>
                            <span className="neutral2 font14">{total}&nbsp;&nbsp;Total</span>
                        </div>
                    </div>
                    <div className="supplemental-charts-line">
                        <Line data={chart.data} options={chart.options} width="600" height="230"/>
                    </div>
                </div>
                <div style={{"paddingLeft":"8px", "paddingRight":"8px"}}>
                    <div className={pieOnHoverBg}>{pieOnHover}</div>
                </div>
                <br/>
                <div className='mb-5'>
                    {project ?
                    <CaseEnhancedAnalysis 
                        overlay={this.props.overlay}
                        messageOverlay={this.props.messageOverlay}
                        type="supplemental"
                        project={project}
                        data={this.props.caseEnhancedAnalysis}
                        active={this.selectOverlayActive}
                        view={this.props.view}
                    />
                    : <></>
                    }
                </div>
                <div style={{"display:":"flex", "flexDirection":"column"}}>
                    <div style={{"flex":"1"}}>
                        {duplicateMessage}
                        <div className="mb-4">
                            <CaseGroup
                                cases={newFailures}
                                selectCase={selectCase} 
                                projects={projects}
                                projectIndex={projectIndex}
                                targets={targets} 
                                targetIndex={targetIndex} 
                                runs={runs} 
                                runIndex={runIndex} 
                                context={context}
                                /*assignee={assignee} */
                                persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                                persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                                darkMode={this.props.darkMode}
                                multiselect={this.state.multiselect}
                                /*multiselectGroup={multiselectGroup}*/
                                multiselectChange={this.multiselectChange}
                                multiselectEnabled={this.props.multiselectEnabled}
                                //checked={this.state.multiselect[c.num] === true ? true : false}
                                members={this.props.members}
                                trl={this.props.trl}
                                //saving={saving}
                                titleOverride={newFailuresTitle}
                                collapseAll={this.state.collapseAll}
                                invalidateCollapseAll={this.invalidateCollapseAll}
                                /*duration={duration}
                                intervalStart={start}
                                intervalEnd={end}*/
                                rawResultMaps={this.props.rawResultMaps}
                                caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                                side={this.props.side}
                                targetDetail={this.props.targetDetail}
                            />
                        </div>
                        <div className="mb-4">
                            <CaseGroup
                                cases={oldFailures}
                                selectCase={selectCase} 
                                projects={projects}
                                projectIndex={projectIndex}
                                targets={targets} 
                                targetIndex={targetIndex} 
                                runs={runs} 
                                runIndex={runIndex} 
                                context={context}
                                /*assignee={assignee} */
                                persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                                persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                                darkMode={this.props.darkMode}
                                multiselect={this.state.multiselect}
                                /*multiselectGroup={multiselectGroup}*/
                                multiselectChange={this.multiselectChange}
                                multiselectEnabled={this.props.multiselectEnabled}
                                //checked={this.state.multiselect[c.num] === true ? true : false}
                                members={this.props.members}
                                trl={this.props.trl}
                                //saving={saving}
                                titleOverride={oldFailuresTitle}
                                collapseAll={this.state.collapseAll}
                                invalidateCollapseAll={this.invalidateCollapseAll}
                                /*duration={duration}
                                intervalStart={start}
                                intervalEnd={end}*/
                                rawResultMaps={this.props.rawResultMaps}
                                caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                                side={this.props.side}
                                targetDetail={this.props.targetDetail}
                            />
                        </div>
                        <div className="mb-4">
                            <CaseGroup
                                cases={newPasses}
                                selectCase={selectCase} 
                                projects={projects}
                                projectIndex={projectIndex}
                                targets={targets} 
                                targetIndex={targetIndex} 
                                runs={runs} 
                                runIndex={runIndex}
                                context={context}
                                /*assignee={assignee} */
                                persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                                persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                                darkMode={this.props.darkMode}
                                multiselect={this.state.multiselect}
                                /*multiselectGroup={multiselectGroup}*/
                                multiselectChange={this.multiselectChange}
                                multiselectEnabled={this.props.multiselectEnabled}
                                //checked={this.state.multiselect[c.num] === true ? true : false}
                                members={this.props.members}
                                trl={this.props.trl}
                                //saving={saving}
                                titleOverride={newPassesTitle}
                                collapseAll={this.state.collapseAll}
                                invalidateCollapseAll={this.invalidateCollapseAll}
                                /*duration={duration}
                                intervalStart={start}
                                intervalEnd={end}*/
                                rawResultMaps={this.props.rawResultMaps}
                                caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                                side={this.props.side}
                                targetDetail={this.props.targetDetail}
                            />
                        </div>
                        <br/>
                        <div className="mb-4">
                            <SupplementalFlaky 
                                key={total}
                                selectCase={selectCase} 
                                projects={projects} 
                                projectIndex={projectIndex} 
                                targets={targets} 
                                targetIndex={targetIndex} 
                                runs={runs} 
                                runIndex={runIndex} 
                                cases={testCases}
                                persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                                caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                            />
                        </div>
                        {project ?
                        <CaseEnhancedAnalysis 
                            type="failures-in-period"
                            project={project}
                            context={this.props.context}
                            data={this.props.caseEnhancedAnalysis}
                            cases={testCases}
                            selectCase={selectCase}
                            projects={projects}
                            projectIndex={projectIndex}
                            targets={targets} 
                            targetIndex={targetIndex} 
                            runs={runs} 
                            runIndex={runIndex} 
                            persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
                            persistentCaseDataIteration={this.props.persistentCaseDataIteration}
                            darkMode={this.props.darkMode}
                            multiselect={this.state.multiselect}
                            multiselectChange={this.multiselectChange}
                            multiselectEnabled={this.props.multiselectEnabled}
                            members={this.props.members}
                            trl={this.props.trl}
                            titleOverride={newFailuresTitle}
                            collapseAll={this.state.collapseAll}
                            invalidateCollapseAll={this.invalidateCollapseAll}
                            rawResultMaps={this.props.rawResultMaps}
                            caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                        /> : <></>
                        }
                        <div style={{"paddingLeft": "20px", "paddingRight":"20px"}}>
                            <div className="mt-3">
                                <SupplementalBugs cases={testCases}/>
                            </div>
                            <br/>
                            <hr/>
                            <div>
                                <h4 className="neutral1 mb-3">Highlights from this test run</h4>
                                {highlight}
                                {/*LatestTestRuns runs={runs} runIndex={runIndex} cases={this.props.cases} onRunChange={this.props.onRunChange}/>*/}
                            </div>
                            <div>
                                <ResultsFiles view="supplemental" buildCase={buildCase} testCases={testCases} projects={this.props.projects} projectIndex={this.props.projectIndex} targets={this.props.targets} targetIndex={this.props.targetIndex} runs={this.props.runs} runIndex={this.props.runIndex}/>
                            </div>
                            {this.props.onboarding}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SupplementalView;