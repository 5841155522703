/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsProfile extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Profile Configuration Guide</title>
                    <meta name="description" content="Learn how to configure profile information for yourself and your team on Tesults."/>
                </Helmet>
                <p className="mt-3">Configure your profile by clicking <Link to="/config"><img height="20" width="20" alt="" src="/img/cog-128.png"/></Link> from the menu bar. Select 'Configure Profile' from the menu.</p>
                <img className="width100 indexImgBorder elevation6 mt-3" alt="" src="/img/user-account-screen.png"/>
                <h5 className="mt-5">Edit first name</h5><p>Edit the first name on your profile.</p> 
                <h5 className="mt-5">Edit last name</h5><p>Edit the last name on your profile.</p>
                <h5 className="mt-5">Edit email address</h5><p>Edit the email address associated with your profile. You will be sent a verification email for the new address you enter.</p>
                <h5 className="mt-5">Two-Factor Authentication (2FA)</h5><p>Enable or disable two-factor authentication (2FA) for the profile. Recommended as an additional authentication security measure.</p>
                <h5 className="mt-5">Logout</h5><p>Logout of Tesults by clicking this button.</p>
            </div>
        );
    }
}

export default DocsProfile;