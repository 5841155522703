/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Analytics from './Analytics';

class DocsTestData extends Component {
    render() {
        return (
            <div className="mb-5">
                <h2 className="mt-5">Test case properties</h2>
                <p className="mt-4 mb-4">This is a complete list of test case properties for reporting results. The required fields must have values otherwise upload will fail with an error message about missing fields.</p>
                <table className="table table-striped mt-3" cellPadding="10">
                    <thead>
                    <th style={{"minWidth":"120px", "textAlign":"left"}}><span className="">Property</span></th>
                    <th style={{"minWidth":"120px", "textAlign":"left"}}><span className="">Required</span></th>
                    <th style={{"minWidth":"120px", "textAlign":"left"}}><span className="">Description</span></th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>name</td>
                        <td>*</td>
                        <td height="40">Name of the test.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>result</td>
                        <td>*</td>
                        <td height="40">Result of the test. Must be one of: pass, fail, unknown. Set to 'pass' for a test that passed, 'fail' for a failure.</td>
                    </tr>
                    <tr>
                        <td>suite</td>
                        <td></td>
                        <td height="40">Suite the test belongs to. This is a way to group tests.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>desc</td>
                        <td></td>
                        <td height="40">Description of the test</td>
                    </tr>
                    <tr>
                        <td>reason</td>
                        <td></td>
                        <td height="40">Reason for the test failure. Leave this empty or do not include it if the test passed</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>params</td>
                        <td></td>
                        <td height="40">Parameters of the test if it is a parameterized test.</td>
                    </tr>
                    <tr>
                        <td>files</td>
                        <td></td>
                        <td height="40">Files that belong to the test case, such as logs, screenshots, metrics and performance data.</td>
                    </tr>
                    <tr>
                        <td>steps</td>
                        <td></td>
                        <td height="40">A list of test steps that constitute the actions of a test case.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>start</td>
                        <td></td>
                        <td height="40">Start time of the test case in milliseconds from Unix epoch.</td>
                    </tr>
                    <tr>
                        <td>end</td>
                        <td></td>
                        <td height="40">End time of the test case in milliseconds from Unix epoch.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>duration</td>
                        <td></td>
                        <td height="40">Duration of the test case running time in milliseconds. There is no need to provide this if start and end are provided, it will be calculated automatically by Tesults." : "Duration of the build time in milliseconds. There is no need to provide this if start and end are provided, it will be calculated automatically by Tesults.</td>
                    </tr>
                    <tr>
                        <td>rawResult</td>
                        <td></td>
                        <td height="40">Report a result to use with the <NavLink to="/docs/result-interpretation" target="_blank" className="tr-link-primary4 no-break no-break">result interpretation</NavLink> feature. This can give you finer control over how to report result status values beyond the three Tesults core result values of pass, fail and unknown.</td>
                    </tr>
                    <tr>
                        <td>_custom</td>
                        <td></td>
                        <td height="40">Report any number of custom fields. To report custom fields add a field name starting with an <b>underscore ( _ )</b> followed by the field name.</td>
                    </tr>
                    </tbody>
                </table>

                <h2 className="mt-5">Build properties</h2>
                <p className="mt-4 mb-4">To report build information simply add another case added to the cases array with suite set to [build]. This is a complete list of build properties for reporting results. The required fields must have values otherwise upload will fail with an error message about missing fields.</p>
                <table className="table table-striped mt-3" cellPadding="10">
                    <thead>
                    <th style={{"minWidth":"120px", "textAlign":"left"}}><span className="">Property</span></th>
                    <th style={{"minWidth":"120px", "textAlign":"left"}}><span className="">Required</span></th>
                    <th style={{"minWidth":"120px", "textAlign":"left"}}><span className="">Description</span></th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>name</td>
                        <td>*</td>
                        <td height="40">Name of the build, revision, version, or change list.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>result</td>
                        <td>*</td>
                        <td height="40">Result of the build. Must be one of: pass, fail, unknown. Use 'pass' for build success and 'fail' for build failure.</td>
                    </tr>
                    <tr>
                        <td>suite</td>
                        <td>*</td>
                        <td height="40">Must be set to value '[build]', otherwise will be registered as a test case instead.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>desc</td>
                        <td></td>
                        <td height="40">Description of the build or changes.</td>
                    </tr>
                    <tr>
                        <td>reason</td>
                        <td></td>
                        <td height="40">Reason for the build failure. Leave this empty or do not include it if the build succeeded.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>params</td>
                        <td></td>
                        <td height="40">Build parameters or inputs if there are any.</td>
                    </tr>
                    <tr>
                        <td>files</td>
                        <td></td>
                        <td height="40">Build files and artifacts such as logs.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>start</td>
                        <td></td>
                        <td height="40">Start time of the build in milliseconds from Unix epoch.</td>
                    </tr>
                    <tr>
                        <td>end</td>
                        <td></td>
                        <td height="40">End time of the build in milliseconds from Unix epoch.</td>
                    </tr>
                    <tr className="neutral8bg">
                        <td>duration</td>
                        <td></td>
                        <td height="40">Duration of the build time in milliseconds. There is no need to provide this if start and end are provided, it will be calculated automatically by Tesults.</td>
                    </tr>
                    <tr>
                        <td>_custom</td>
                        <td></td>
                        <td height="40">Report any number of custom fields. To report custom fields add a field name starting with an <b>underscore ( _ )</b> followed by the field name.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default DocsTestData;