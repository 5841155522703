
/*global */
import React, { Component } from 'react';

class OverlayItem extends Component {
    render () {
        let type = "button";
        if (this.props.type !== undefined) {
            type = this.props.type;
        }
        if (type === "button") {
            return (
                <button className="select-option" onClick={() => {this.props.value()}}>
                    <div className="menu-item-label pr-5">
                        <span className="font15">{this.props.label}</span>
                    </div>
                </button>
            );
        } else if (type === "link") {
            return (
                <div className="mb-2"><a className="tr-link-dark wrap font14" href={this.props.value}>{this.props.label}</a></div>
            );
        } else {
            return <span></span>
        }
    }
}

export default OverlayItem;

