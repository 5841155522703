/*global */
import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Loading from './Loading';
import CaseList from './CaseList';
import ListGroupTree from './ListGroupTree';
import Constants from './Constants';
import Copy from './Copy';
import SelectSide from './SelectSide';
import Notice from './Notice'
import Analytics from './Analytics';
const papa = require('papaparse');


class ListsMain extends Component {
    constructor () {
        super();
        this.state = {selectedCase: null, selectedCaseRaw: null};
        this.addCaseToSuite = this.addCaseToSuite.bind(this);
        this.editCase = this.editCase.bind(this);
        this.deleteCase = this.deleteCase.bind(this);
        this.duplicateCase = this.duplicateCase.bind(this);
        this.readCsvFile = this.readCsvFile.bind(this);
        this.errorReadCsvFile = this.errorReadCsvFile.bind(this);
        this.fileSelect = this.fileSelect.bind(this);
        this.refresh = this.refresh.bind(this);
        this.generateTestCasesImageFileSelect = this.generateTestCasesImageFileSelect.bind(this);
        this.generateTestCasesConfirm = this.generateTestCasesConfirm.bind(this);
        this.cancelGeneratedTestCases = this.cancelGeneratedTestCases.bind(this);
        this.generatedTestCasesSave = this.generatedTestCasesSave.bind(this);
        this.ref = React.createRef();
    }

    componentWillReceiveProps(props) {
        if (props.closeCase === true) {
            this.setState({editCase: undefined});
        }
    }

    addCaseToSuite (suite) {
        this.setState({selectedCase: null, selectedCaseRaw: null}, () => this.props.addCaseToSuiteFunc(suite));
    }

    editCase (c) {
        this.setState({selectedCase: null, selectedCaseRaw: null}, () => this.props.editCaseFunc(c));
    }

    deleteCase(c) {
        this.setState({selectedCase: null, selectedCaseRaw: null}, () => this.props.deleteCase(c));
    }

    duplicateCase(c) {
        this.setState({selectedCase: null, selectedCaseRaw: null}, () => this.props.duplicateCase(c));
    }

    readCsvFile (results, file) {
        let cases = [];
        results.data.forEach(function (r) {
            if (r.name !== undefined) { // required fields
                if (r.name !== "") {
                    if (r.result === "pass" || r.result === "fail" || r.result === "unknown") {
                        cases.push(r)
                    }
                    if (r.result === "" || r.result === undefined || r.result === null) {
                        r.result = "pass"
                        cases.push(r)
                    }
                }
            }
        });
        if (cases.length === 0) {
            this.setState({error: "Unable to import cases. Ensure all rows have the required name and result fields and that the value for result is one of [pass, fail, unknown]"});
        } else {
            this.props.importCsvCases(cases);    
        }
    }

    errorReadCsvFile (err, file) {
        this.setState({error: "Error reading file.", disabled: true});
    }

    fileSelect (e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = (e) => {
            if (file.type !== "text/csv") {
                this.setState({error: "The file must be a csv file."});
            } else {
                papa.parse(e.target.result, {
                    download: true,
                    header: true,
                    complete: this.readCsvFile,
                    error: this.errorReadCsvFile
                });

                this.setState({error: "", disabled: false});
            }
        };

        reader.readAsDataURL(file)
    }
    
    generateTestCasesImageFileSelect (e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = (e) => {
            let ext = undefined;
            if (file.type === "image/png") {
                ext = "png";
            }
            if (file.type === "image/jpg") {
                ext = "jpg";
            }
            if (file.type === "image/jpeg") {
                ext = "jpg";
            }
            if (ext === undefined) {
                this.setState({confirmation: {success: undefined, failure: "Your image must be a jpg or png file"}});
            } else {
                this.setState({file: file, fileDisplay: e.target.result, ext: ext, confirmation: {success: undefined, failure: undefined}});
            }
        };

        reader.readAsDataURL(file)
    }

    cancelGeneratedTestCases () {
        this.ref.current.value = "";
        this.setState({file: undefined, fileDisplay: undefined, generatedTestCases: undefined});
    }

    generateTestCasesConfirm () {
        if (this.state.file === undefined) {
            this.setState({confirmation: {success: undefined, failure: "Select an image for your project"}});
        } else {
            this.setState({submitting: true, message: "Generating..."});
            var xmlHttpRequest = new XMLHttpRequest();
            xmlHttpRequest.open("POST", "/lists/generate-test-cases/" + this.props.projects[this.props.projectIndex].id, true);
            xmlHttpRequest.setRequestHeader("Content-Type", this.state.file.type);
            xmlHttpRequest.onreadystatechange = function() {
                if(xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
                    if (xmlHttpRequest.status === 200) {
                        var jsonResponse = xmlHttpRequest.response;
                        const generatedTestCases = [];
                        if (jsonResponse !== undefined) {
                            const data = JSON.parse(jsonResponse);
                            const cases = data.cases;
                            if (cases !== undefined && cases.length > 0) {
                                cases.forEach(function (c) {
                                    generatedTestCases.push({
                                        name: c.name,
                                        result: "pass",
                                        suite: c.suite,
                                        desc: "GIVEN:\n" + c.given + "\n\nWHEN:\n" + c.when + "\n\nTHEN:\n" + c.then,
                                    });
                                });
                            }
                        }
                        if (generatedTestCases.length > 0) {
                            Analytics.event("ListsGenerateTestCases");
                            this.setState(
                                {
                                    generatedTestCases: generatedTestCases,
                                    confirmation: {success: undefined, failure: undefined},
                                    message: "",
                                    submitting: false
                                }
                            );
                        } else {
                            this.setState({generatedTestCases: [], confirmation: {success: undefined, failure: "Unable to generate test cases"}, message: "", submitting: false});    
                        }
                    } else {
                        this.setState({generatedTestCases: [], confirmation: {success: undefined, failure: "Unable to generate test cases"}, message: "", submitting: false});
                    }
                }
            }.bind(this)
            xmlHttpRequest.send(this.state.file);
        }
    }

    generatedTestCasesSave () {
        const testCases = this.state.generatedTestCases;
        this.setState({generatedTestCases: undefined, file: undefined, fileDisplay: undefined});
        this.props.generatedTestCasesSave(testCases);
    }

    refresh () {
        let list = this.props.lists[this.props.listIndex];
        let listUrl = Constants.baseUrl + "/lists/lsp/" + list.id + "/" + list.created;
        if (window.location === listUrl) {
            window.location.reload(true);
        } else {
            window.location.href = listUrl;
        }
    }

    render () {
        let selectSide = 
            <div style={{"display":"flex"}}>
                <div style={{ "marginLeft":"auto"}}>
                    <SelectSide
                        side={this.props.side}
                        sideOpen={this.props.sideOpen}
                        content={this.props.sideContent}
                        image={undefined}
                        label={<button className="btn-select"><img src="/img/three-dots-white.svg" className='mr-1' width="12" height="12" alt="dots"/>Open list options</button>}
                        /*onClick={this.props.multiEdit}*/
                        /*onClose={this.props.multiEdit}*/
                    />
                </div>
            </div>

        if (this.props.loading === true) {
            return <Loading/> 
        } else if (this.props.loggedIn === false) {
            return (
            <div>
                <br/>
                <p><NavLink to="/login" className="nounderline neutral4">Login</NavLink> to access lists for test case management and manual test results submission.</p>
                <p><NavLink to="/" className="nounderline neutral4">Return to Tesults.com</NavLink></p>
            </div>
            );
        } else if (this.props.role < 2) {
            return <p>Ask your Tesults project administrator to change your role to Moderator (Level 2) or above to access Lists for test case management.</p>
        } else if (this.props.state === "groupTree") {
            let projectOptions = [];
            let index = 0;
            this.props.projects.forEach(function (project) {
                projectOptions.push(<option key={index} value={index}>{project.name}</option>);
                index += 1;
            });
            if (this.props.projectIndex < this.props.projects.length) {
                let project = this.props.projects[this.props.projectIndex];
                return (
                    <div>
                        <ListGroupTree lists={this.props.lists} project={project} listChange={this.props.listChange} createdListGroup={this.props.createdListGroup} deletedListGroup={this.props.deletedListGroup} allListsUpdated={this.props.allListsUpdated} setGroupProxy={this.props.setGroupProxy} overlay={this.props.overlay} createListConfirm={this.props.createListConfirm} selectSide={selectSide} search={this.props.search} onSearchChange={this.props.onSearchChange}/>
                        <Notice type="information" content={<span className='neutral2 font15'>Handle test case management with lists. Use lists to create and store test cases. When ready to test a release, <NavLink to="/manual" className="tr-link-primary4 no-break bold">create a run</NavLink>, and import test cases from lists.</span>}/>
                    </div>
                );
            } else {
                return (
                    <span></span>
                )
            }
        } else if (this.props.state === "view" || this.props.state === "edit") {
            if (this.props.lists.length === 0) {
                return (
                    <div>
                        {selectSide}
                        <p>There are no saved tests lists for this project. Click the 'Create new list' button to create a list.</p>
                        <p>Test lists are the way test case management is handled with Tesults. Test lists contain test cases that are stored permanently for reuse across new releases, it is a good idea to break down test suites into different lists.</p>
                        <p>Use lists you have created with test runs. Create a test run in order to submit test results manually. You can import cases from multiple test lists into a test run.</p>
                    </div>
                );
            } else {
                let list = this.props.lists[this.props.listIndex];
                let listCases = this.props.listCases;
                let listCasesView = <p>There are no cases for this list.</p>
                let suiteSort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("suite")}>Suite</span>
                let nameSort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("name")}>Name</span>
                let resultSort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("result")}>Result</span>
                let prioritySort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("priority")}>Priority</span>

                let lspUrl = Constants.baseUrl + "/lists/lsp/" + list.id + "/" + list.created;
                let lsp = <span className="neutral4 font12">{"List link: " + lspUrl}&nbsp;&nbsp;<Copy text={lspUrl}/></span>
                    
                if (listCases.length > 0) {
                    const caseList = <CaseList 
                        key={"list-case-list"}
                        updatedCaseHash={this.props.updatedCaseHash}
                        history={this.props.history}
                        context={"list"}
                        overlay={this.props.overlay}
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        sort={this.props.sort}
                        projects={this.props.projects} 
                        projectIndex={this.props.projectIndex} 
                        targets={this.props.targets}
                        targetIndex={this.props.targetIndex}
                        run={this.props.run}
                        runIndex={this.props.runIndex}
                        cases={listCases}
                        builds={this.props.builds}
                        role={this.props.role}
                        addCaseToSuite={this.addCaseToSuite}
                        editCase={this.editCase} 
                        deleteCase={this.deleteCase}
                        duplicateCase={this.duplicateCase}
                        deleteListSuite={this.props.deleteListSuite}
                        listEdit={this.props.listEdit}
                        listEditFunc={this.props.listEditFunc}
                        list={list}
                        lspCaseNum={this.props.lspCaseNum}
                        multiselectEnabled={this.props.multiEdit}
                        multiselectClearRequest={this.props.multiselectClearRequest}
                        multiselectUpdate={this.props.multiselectUpdate}
                        setEditSuite={this.props.setEditSuite}
                        lists={this.props.lists}
                        listIndex={this.props.listIndex}
                        collapseAll={this.props.collapseAll}
                        search={this.props.search}
                        />

                    

                    let casesTotal = <span></span>
                    if (listCases !== undefined) {
                        casesTotal = <div className="neutral3 mt-3 font15">{listCases.length} Test Cases</div>
                    }
                        
                    return (
                        <div>
                            {selectSide}
                            {caseList}
                            {lsp}
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {selectSide}
                            <h4 className="neutral4">{list.label}</h4>
                            {listCasesView}
                            {lsp}
                        </div>
                    );
                }
            }
        } else if (this.props.state === "createList") {
            let project = this.props.projects[this.props.projectIndex];
            let group = <span></span>
            if (this.props.group !== undefined) {
                let groupLabel = "";
                this.props.group.forEach(function (g) {
                    groupLabel += " > " + g;
                });
                group = <div className="neutral4 font12">Group: {groupLabel}</div>
            }
            return (
                <div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-6">
                            {selectSide}
                            <h5 className="mb-1">List Name</h5>
                            <input type="text" className="mb-3 tr-input form-control" value={this.props.label} onChange={(e) => this.props.changeLabel(e)} required/>
                            {group}
                            <br/>
                            <span className="neutral4 font12">Project: {project.name}</span> 
                            <br/>
                            <p className="accenta1" id="error">{this.props.error}</p>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.state === "listNameAndGroupEdit") {
            let groupOptions = [];
            let index = 0;
            let selectedIndex = 0;
            let currentPathString = "";
            if (this.props.group !== undefined) {
                this.props.group.forEach(function (g) {
                    currentPathString += " > " + g;
                });
            }
            this.props.groupSelectPaths.forEach(function (path) {
                let pathString = "";
                path.forEach(function (p) {
                    pathString += " > " + p;
                });
                if (pathString === currentPathString) {
                    selectedIndex = index;
                }
                groupOptions.push(<option key={index} value={index}>{pathString}</option>);
                index += 1;
            });

            let groupSelection =
            <div>
                <div>
                    {selectSide}
                    <select className="custom-select mb-3 width100" onChange={(e) => this.props.groupSelected(e.target.value)} value={selectedIndex}>
                        {groupOptions}
                    </select>
                </div>
            </div>

            let list = this.props.lists[this.props.listIndex];
            let listUrl = "/lists/lsp/" + list.id + "/" + list.created;

            return (
                <div>
                    {selectSide}
                    <span className="ml-3 font12 neutral4">List Name & Group</span>
                    <div>
                        <h5 className="mb-1">Name</h5>
                        <input type="text" className="mb-3 tr-input form-control" value={this.props.label} onChange={(e) => this.props.changeLabel(e)} required/>
                        <h5 className="mb-1">Group</h5>
                        {groupSelection}
                        <button className="btn btn-confirm mt-3 mr-3" onClick={this.props.listEditNameAndGroupConfirm}>Save</button>
                        <Link to={listUrl}><button className="btn-cancel">Cancel</button></Link>
                    </div>
                </div>
            );
        } else if (this.props.state === "duplicateList") {
            return (
                <div>
                    {selectSide}
                    <div>
                        <h5>Name for new duplicate test list</h5>
                        <input type="text" className="mb-3 tr-input form-control" value={this.props.label} onChange={(e) => this.props.changeLabel(e)} required/>
                        <p className="accenta1" id="error">{this.props.error}</p>
                    </div>
                </div>
            );
        } else if (this.props.state === "generateTestCases") {
            const project = this.props.projects[this.props.projectIndex];
            if (
                project.plan.name === "standard-v1" || 
                project.plan.name === "plus-v1" ||
                project.plan.name === "standard-20240719" ||
                project.plan.name === "plus-20240719"
            ) {
                return (
                    <div>
                    {selectSide}
                    <div>
                        <h4>Generate test cases with Tesults AI</h4>
                        <div>
                            <p>Upload an image of the front-end of your web or mobile application. Tesults AI will generate test cases for you. If you're happy with them click confirm and they will be added to your test list.</p>
                        </div>
                        <div>
                            <Notice type="information" content={<span className='primary1 font14'>This feature is in beta. Select 'Feedback and Help' from the menu to submit feedback.</span>}/>        
                        </div>
                        {
                            this.state.submitting ? <Loading/> :

                            <>
                            <input type="file" id="input" className="btn-transparent mt-3" ref={this.ref} onChange={this.generateTestCasesImageFileSelect}/>
                            {this.state.fileDisplay !== undefined && (
                                <div>
                                    <div className='mb3'>
                                        {this.state.fileDisplay && <button className="btn-cancel mt-3 mb-3 mr-3" onClick={this.cancelGeneratedTestCases}>Cancel</button>}
                                        {(this.state.fileDisplay && this.state.generatedTestCases === undefined) && <button className="btn-confirm mt-3 mb-3" onClick={this.generateTestCasesConfirm}>Generate test cases for this image</button>}
                                        {this.state.generatedTestCases && <button className="btn-confirm mt-3 mb-3" onClick={this.generatedTestCasesSave}>Save generated test cases to list</button>}
                                    </div>
                                    <div>
                                        {this.state.generatedTestCases ? 
                                            <CaseList
                                                context={"import"}
                                                overlay={this.props.overlay}
                                                messageOverlay={this.props.messageOverlay}
                                                side={this.props.side}
                                                sideOverlay={this.props.sideOverlay}
                                                projects={this.props.projects} 
                                                projectIndex={this.props.projectIndex}
                                                targets={this.props.targets}
                                                targetIndex={this.props.targetIndex}
                                                run={this.props.run}
                                                runIndex={this.props.runIndex}
                                                sort={this.props.sort}
                                                cases={this.state.generatedTestCases}
                                                role={this.props.role}
                                                editCase={this.editCase} 
                                                deleteCase={this.deleteCase} 
                                                deleteListSuite={this.props.deleteListSuite}
                                                listEdit={this.props.listEdit}
                                                listEditFunc={this.props.listEditFunc}
                                                collapseAll={this.props.collapseAll}
                                                search={this.props.search}
                                            /> : 
                                            <img src={this.state.fileDisplay} className="width75" alt="generate-test-cases-image"/>
                                        }
                                    </div>
                                </div>
                                )
                            }
                            <p className="accenta1" id="error">{this.props.error}</p>
                            </>
                        }
                    </div>
                </div>
                );
            } else {
                return (
                    <div>
                        {selectSide}
                        <div>
                            <h4>Generate test cases with Tesults AI</h4>
                            <div className='flex-row'>
                                <div className='flex-1'>
                                    <p>Upload an image of the front-end of your web or mobile application. Tesults AI will generate test cases for you. If you're happy with them click confirm and they will be added to your test list.</p>
                                </div>
                            </div>
                            <Notice type="warning" content={<span className='accentb1 font15'>This feature is not available on the free tier. Upgrade to Standard or Plus.</span>}/>
                        </div>
                    </div>
                );   
            }
        } else if (this.props.state === "unused") {
            return <span>Invalid State</span>
        } else if (this.props.state === "importCase") {
            return <span></span>
        } else if (this.props.state === "importTarget") {
            let title = <p>This project has no targets to import cases from.</p>
            let list = this.props.lists[this.props.listIndex];
            if (this.props.targetIndex < this.props.targets.length) {
                let target = this.props.targets[this.props.targetIndex];
                title = <h4 className="neutral4">{target.name} cases > Import > {list.label}</h4>
            }
            let importCases = this.props.importCases;
            if (importCases.length > 0) {
                return (
                    <div>
                        {selectSide}
                        {title}
                        <CaseList
                            context={"import"}
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            projects={this.props.projects} 
                            projectIndex={this.props.projectIndex} 
                            targets={this.props.targets}
                            targetIndex={this.props.targetIndex}
                            run={this.props.run}
                            runIndex={this.props.runIndex}
                            sort={this.props.sort}
                            cases={importCases}
                            builds={this.props.builds}
                            role={this.props.role}
                            editCase={this.editCase} 
                            deleteCase={this.deleteCase} 
                            deleteListSuite={this.props.deleteListSuite}
                            listEdit={this.props.listEdit}
                            listEditFunc={this.props.listEditFunc}
                            collapseAll={this.props.collapseAll}
                            search={this.props.search}
                        />
                    </div>
                );
            } else {
                return (
                    <div>
                        {selectSide}
                        {title}
                        <p>There are no cases for this target.</p>
                    </div>
                );
            }
        } else if (this.props.state === "importCsv") {
            if (this.props.importCases.length > 0) {
                let importCases = this.props.importCases;
                if (importCases.length > 0) {
                    return (
                        <div>
                            {selectSide}
                            <CaseList
                                context={"import"}
                                overlay={this.props.overlay}
                                messageOverlay={this.props.messageOverlay}
                                side={this.props.side}
                                sideOverlay={this.props.sideOverlay}
                                projects={this.props.projects} 
                                projectIndex={this.props.projectIndex} 
                                targets={this.props.targets}
                                targetIndex={this.props.targetIndex}
                                run={this.props.run}
                                runIndex={this.props.runIndex}
                                sort={this.props.sort}
                                cases={importCases}
                                builds={this.props.builds}
                                role={this.props.role}
                                editCase={this.editCase} 
                                deleteCase={this.deleteCase} 
                                deleteListSuite={this.props.deleteListSuite}
                                listEdit={this.props.listEdit}
                                listEditFunc={this.props.listEditFunc}
                                collapseAll={this.props.collapseAll}
                                search={this.props.search}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {selectSide}
                            <p>There are no cases to display.</p>
                        </div>
                    );
                }
            } else {
                return (
                    <div>
                        {selectSide}
                        <p className="font15">Select a csv file to import test cases from to list: {this.props.lists[this.props.listIndex].label}.</p>
                        <p className="font15">The first row must be a header row with the names:</p>
                        <ul>
                            <li className="font15"><b>name</b></li>
                            <li className="font15"><b>result</b></li>
                            <li className="font15">suite</li>
                            <li className="font15">desc</li>
                            <li className="font15">reason</li>
                            <li className="font15">_Custom</li>
                        </ul>
                        <p className="font15">The name and result fields are required.</p>
                        <p className="font15">Result must be one of: [pass, fail, unknown] and is used to assign a default value to a test case.</p>
                        <p className="font15">The suite field is useful for grouping test cases into test suites, desc is a description of the test case, and reason is a failure reason. You can also have any number of custom fields, these fields must start with an underscore. For example: _customField</p>
                        <p className="font15">Example:</p>
                        <pre className="docsCode">
                        name,result,suite,desc,reason,_customField1,_customField2<br/>
                        Test 1,pass,Suite A,Test 1 description,,custom1 value,custom2 value<br/>
                        Test 2,fail,Suite A,Test 2 description,failure reason here,custom1 value,custom2 value<br/>
                        Test 3,pass,Suite B,Test 3 description,,custom1 value,custom2 value<br/>
                        </pre>
                        <p className="fail font15">{this.state.error}</p>
                        <p><input type="file" id="input" className="mt-3" onChange={this.fileSelect} accept=".csv"/></p>
                    </div>
                );
            }
        } else if (this.props.state === "importList") {
            let title = <p>This project has no lists to import cases from.</p>
            if (this.props.importListIndex < this.props.lists.length) {
                let list = this.props.lists[this.props.importListIndex];
                title = <h4 className="neutral4">{"Import from " + list.label}</h4>
            } else {
                return (
                    <div>
                        {selectSide}
                        {title}
                    </div>
                );
            }
            
            let importListCases = this.props.importListCases;
            if (importListCases.length > 0) {
                return (
                    <div>
                        {selectSide}
                        {title}
                        <CaseList
                            context={"import"}
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            projects={this.props.projects} 
                            projectIndex={this.props.projectIndex} 
                            targets={this.props.targets}
                            targetIndex={this.props.targetIndex}
                            run={this.props.run}
                            runIndex={this.props.runIndex}
                            sort={this.props.sort}
                            cases={importListCases}
                            builds={this.props.builds}
                            role={this.props.role}
                            editCase={this.editCase} 
                            deleteCase={this.deleteCase} 
                            deleteListSuite={this.props.deleteListSuite}
                            listEdit={this.props.listEdit}
                            listEditFunc={this.props.listEditFunc}
                            collapseAll={this.props.collapseAll}
                            search={this.props.search}
                        />
                    </div>
                );
            } else {
                return (
                    <div>
                        {selectSide}
                        {title}
                        <p>There are no cases for this list.</p>
                    </div>
                );
            }
        } else {
            return <p>Invalid state</p>
        }
    }
};

export default ListsMain;