import React, { Component } from 'react';

class Currency extends Component {
    render() {
        if (this.props.currency === "USD") {
            return "USD $";
        } else {
            return <span></span>
        }
    }
}

export default Currency;