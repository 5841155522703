/*global Cookies*/
import React, { Component } from 'react';
import Member from './Member';
import Loading from './Loading';
import Analytics from './Analytics';
import Request from './Request';
const papa = require('papaparse');

class Members extends Component {
    constructor() {
        super();
        this.state = {state: "members", info: "", error: "", userMember: undefined, addMemberEmails: [], members: [], transferToMember: undefined, email: "", notify: [], notifyString: "", notifyLimit: 0, confirmAdd: false, addCsvConfirm: false, loading: true, submitting: false};
        this.members = this.members.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.transferProjectOwnership = this.transferProjectOwnership.bind(this);
        this.transferSelect = this.transferSelect.bind(this);
        this.transferConfirm = this.transferConfirm.bind(this);
        this.cancelTransfer = this.cancelTransfer.bind(this);
        this.updateMembers = this.updateMembers.bind(this);
        this.addMember = this.addMember.bind(this);
        this.addMembers = this.addMembers.bind(this);
        this.removeMember = this.removeMember.bind(this);
        this.notifyEnable = this.notifyEnable.bind(this);
        this.moderatorEnable = this.moderatorEnable.bind(this);
        this.fileSelect = this.fileSelect.bind(this);
        this.readCsvFile = this.readCsvFile.bind(this);
        this.errorReadCsvFile = this.errorReadCsvFile.bind(this);
        this.sso = this.sso.bind(this);
    }

    componentDidMount() {
        this.members();
    }

    members () {
        this.setState({loading: true});
        Request.get("/members", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({state: "members", loading: false});
            } else {
                const user = Cookies.getJSON("truser");
                let userMember = undefined;
                data.members.forEach(function (member) {
                    if (member.id === user.id) {
                        userMember = member;
                    }
                });
                this.setState({state: "members", userMember: userMember, members: data.members, loading: false}, () => this.sso());
            }
        });
    }

    sso () {
        this.setState({loading: true});
        Request.get("/ssoForRef", {ref: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({sso: data.sso, loading: false});
            }
        });
    }

    emailChange (e) {
        this.setState({email: e.target.value, confirmAdd: false, info: "", error: ""});
    }

    addMember (e) {
        e.preventDefault();
        let email = this.state.email.toLowerCase().trim();

        // check if already member
        let alreadyMember = false;
        this.state.members.forEach(function (member) {
            if (member.email === email) {
                // already a member
                alreadyMember = true;
            }
        });

        if (alreadyMember === true) {
            this.setState({info: "", error: "Already a member"});
            return;
        }

        // check if different domain
        const user = Cookies.getJSON("truser");
        let userEmail = user.email;
        if (userEmail !== undefined) {
            userEmail = userEmail.toLowerCase().trim();
            const atIndex1 = userEmail.indexOf("@");
            if (atIndex1 !== -1) {
                const domain1 = userEmail.substring(atIndex1);
                const atIndex2 = email.indexOf("@");
                if (atIndex2 !== -1) {
                    const domain2 = email.substring(atIndex2);
                    if (domain1 !== domain2) {
                        if (this.state.confirmAdd !== true) {
                            this.setState({confirmAdd: true, info: "", confirmAddMessage: "This email address has a different domain to yours. Are you sure you want to use this email address to add a team member?"});
                            return;
                        }
                    }
                }
            }
        }

        // submit
        
        this.setState({submitting: true, confirmAdd: false, confirmAddMessage: ""});
        
        var data = {
            id: this.props.project.id,
            email: email
        };
        
        Request.post("/memberadd", data, (err, data) => {
            if (err) {
                this.setState({info: "", error: err, submitting: false});
            } else {
                Analytics.event("MemberAdded");
                let members = this.state.members;
                let member = data.member;
                if (member === undefined) {
                    member = {id: undefined, email: email, firstName: "", lastName: "", aext: undefined, role: 1};
                }
                members.push(member);
                this.setState({members: members, info: "Member with email address " + email + " has been added to the project and notified.", error: "", email: "", submitting: false});
            }
        });
    }

    addMembers () {
        // check if different domain
        const user = Cookies.getJSON("truser");
        let userEmail = user.email;
        if (userEmail !== undefined) {
            userEmail = userEmail.toLowerCase().trim();
            const atIndex1 = userEmail.indexOf("@");
            if (atIndex1 !== -1) {
                const domain1 = userEmail.substring(atIndex1);
                for (let i = 0; i < this.state.addMemberEmails.length; i++) {
                    let email = this.state.addMemberEmails[i];
                    const atIndex2 = email.indexOf("@");
                    if (atIndex2 !== -1) {
                        const domain2 = email.substring(atIndex2);
                        if (domain1 !== domain2) {
                            if (this.state.addCsvConfirm !== true) {
                                this.setState({addCsvConfirm: true, info: "", confirmAddMessage: "This file includes email addresses with a different domain to yours. Are you sure you want to add these email addresses to the team?"});
                                return;
                            }
                        }
                    }
                }
            }
        }

        // submit
        
        this.setState({submitting: true, addCsvConfirm: false, addCsvConfirmText: ""});
        
        var data = {
            id: this.props.project.id,
            emails: this.state.addMemberEmails
        };
        
        Request.post("/membersadd", data, (err, data) => {
            if (err) {
                this.setState({addCsvConfirm: false, info: "", error: err, submitting: false});
            } else {
                Analytics.event("MembersAdded");
                let members = this.state.members;
                let membersResponse = data.members;
                membersResponse.forEach(function (member) {
                    if (member === undefined) {
                        member = {id: undefined, email: member.email, firstName: "", lastName: "", aext: undefined, role: 1};
                    }
                    members.push(member);
                });
                this.setState({addCsvConfirm: false, members: members, info: "Members added", error: "", email: "", addMemberEmails: [], submitting: false});
            }
        });
    }

    fileSelect (e) {
        const reader = new FileReader();    
        const file = e.target.files[0];
        reader.onload = (e) => {
            if (file.type !== "text/csv") {
                this.setState({info: "", error: "The file must be a csv file."});
            } else {
                papa.parse(e.target.result, {
                    download: true,
                    header: true,
                    complete: this.readCsvFile,
                    error: this.errorReadCsvFile
                });

                this.setState({info: "", error: "", disabled: false});
            }
        };

        reader.readAsDataURL(file)
    }

    errorReadCsvFile (err, file) {
        this.setState({info: "", error: "Error reading file.", disabled: true});
    }

    readCsvFile (rows) {
        let addMembers = [];
        rows.data.forEach(function (r) {
            if (r.email !== undefined) { // required fields
                if (r.email !== "") {
                    let email = r.email.replace(/(\r\n|\n|\r)/gm, "");
                    addMembers.push(email);
                }
            }
        });
        if (addMembers.length === 0) {
            this.setState({addCsvConfirm: false, addMemberEmails: addMembers, info: "", error: "Unable to import team members. Ensure the first row has the required email field."});
        } else {
            this.setState({addCsvConfirm: false, addMemberEmails: addMembers}, () => this.addMembers());
        }
    }

    transferProjectOwnership () {
        this.setState({transferToMember: undefined, state: "transfer"});
    }

    transferSelect (member) {
        this.setState({transferToMember: member, state: "transferConfirm"});
    }

    transferConfirm () {
        this.setState({loading: true});
        Request.post("/projectOwnerTransfer", {project: this.props.project.id, user: this.state.transferToMember.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, message: "", info: "", error: "Unable to transfer project owner."});
            } else {
                this.setState({state: "members",  transferToMember: undefined, message:"", error: "", loading: false}, () => this.members());
            }
        });
    }

    cancelTransfer () {
        this.setState({state: "members", info: "", error: "", message: "", transferToMember: undefined});
    }

    removeMember (member) {
        this.setState({submitting: true});
        
        var data = {
            id: this.props.project.id,
            email: member.email
        };
        
        Request.post("/memberremove", data, (err, data) => {
            if (err) {
                this.setState({info: "", error: err, submitting: false});
            } else {
                Analytics.event("MemberRemoved");
                let members = this.state.members;
                let removeIndex = -1;
                let index = 0;
                members.forEach(function (m) {
                    if (member.email === m.email) {
                        removeIndex = index;
                    }
                    index += 1;
                });
                
                if (removeIndex !== -1) {
                    members.splice(removeIndex, 1);
                }
                this.setState({members: members, info: "", error: "", submitting: false});
            }
        });
    }

    updateMembers () {
        // Check what members to add and remove.
        this.setState({submitting: true});
        
        let editedMembers = this.state.membersString.split(",");
        
        let add = [];
        let remove = [];
        
        let edited = {};
        let original = {};
        
        this.state.members.forEach(function (m) {
            original[m] = true;
        });
        
        editedMembers.forEach(function (m) {
            edited[m.toLowerCase().trim()] = true;
        });
        
        editedMembers.forEach(function (m) {
            let me = m.toLowerCase().trim();
            if (original[me] !== undefined) {
                // no change
                delete original[me];
            } else {
                // add
                if (me !== "") {
                    add.push(me);
                }
            }
        });
        
        remove = Object.keys(original);
        
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        
        var data = {
            id: project.id,
            add: add,
            remove: remove
        };
        
        Request.post("/memberedit", data, (err, data) => {
            if (err) {
                this.setState({info: "", error: err, submitting: false});
            } else {
                Analytics.event("EditedMembers");
                this.setState({state: "configureProject", members: [], membersString: "", error: "", submitting: false});
            }
        });
    }

    notifyEnable (member, enable) {
        this.setState({submitting: true});
        
        var data = {
            id: this.props.project.id,
            user: member.id,
            created: member.created,
            enable: enable,
        };
        
        Request.post("/notifyEnable", data, (err, data) => {
            if (err) {
                this.setState({info: "", error: err, submitting: false});
            } else {
                Analytics.event("NotifyEdited");
                let members = this.state.members;
                members.forEach(function (stateMember) {
                    if (member.id === stateMember.id) {
                        stateMember.notify = enable;
                    }
                });
                this.setState({members: members, error: "", email: "", submitting: false});
            }
        });
    }

    moderatorEnable (user, created, enable) {
        this.setState({submitting: true});
        
        var data = {
            id: this.props.project.id,
            user: user,
            created: created,
            enable: enable,
        };
        
        Request.post("/moderatorEnable", data, (err, data) => {
            if (err) {
                this.setState({info: "", error: err, submitting: false});
            } else {
                Analytics.event("ModeratorEdited");
                let members = this.state.members;
                members.forEach(function (member) {
                    if (member.id === user) {
                        member.moderator = enable;
                    }
                });
                this.setState({members: members, error: "", email: "", submitting: false});
            }
        });
    }

    render() {
        if (this.state.state === "transferConfirm") {
            window.scrollTo(0, 0);
            return (
            <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <p>Project ownership transfer takes place immediately. You will be demoted to an officer role. Please be certain you want to do this before completing the transfer, this action cannot be undone. Billing emails for receipts and invoices will be sent to the new owner.</p>
                    <p>Transfer ownership to {this.state.transferToMember.firstName} {this.state.transferToMember.lastName} (email: {this.state.transferToMember.email})?</p>
                    <button type="button" className="btn btn-confirm mt-3" onClick={this.transferConfirm} disabled={this.state.submitting}>Confirm transfer</button>
                    <br/>
                    <button type="button" className="btn btn-cancel mt-3" onClick={this.cancelTransfer} disabled={this.state.submitting}>Cancel transfer</button>
                    <br/>
                    <br/>
                    {this.state.error}
                </div>
            </div>
            );
        }

        let addText = "Add";
        let addConfirmText = "";
        if (this.state.confirmAdd === true) {
            addText = "Confirm";
            addConfirmText = <div className="mb-2">
                                <img alt="Warning" src="/img/exclamation-outline.svg" width="24px" height="24px" className="mr-3"/>
                                <span className="neutral1 bold">Security alert</span>
                                <br/>
                                {this.state.confirmAddMessage}
                            </div>
        }

        let addEmail = (
            <div>
                <form id="login" className="text-left neutral1" onSubmit={this.addMember}>
                    <div>
                        <h4 className='font20'>Add a team member</h4>
                    </div>
                    <div className='flex-row' style={{"alignItems":"center"}}>
                        
                        <div>
                            <input type="email" placeholder="email address" className="tr-input form-control" id="email" name="email" value={this.state.email} placeholder="Email" onChange={this.emailChange} required/>
                        </div>
                        <div>
                            <button type="submit" className="btn btn-confirm" disabled={this.state.submitting}>{addText}</button>
                        </div>
                    </div>
                    {addConfirmText}
                </form>
            </div>
                
            );
        let addCsvConfirm = <span></span>
        let addCsvConfirmText = <span></span>
        if (this.state.addCsvConfirm === true) {
            addCsvConfirm = <div><br/><button type="button" className="btn btn-confirm" onClick={this.addMembers} disabled={this.state.submitting}>Confirm</button></div>
            addCsvConfirmText = <div className="mt-2 mb-2">
                                <img alt="Warning" src="/img/exclamation-outline.svg" width="24px" height="24px" className="mr-3"/>
                                <span className="neutral1 bold">Security alert</span>
                                <br/>
                                {this.state.confirmAddMessage}
                            </div>
        }
        let addCsv = (
            <div className="whitebg font15 p-3">
                <details>
                    <summary className='pointer' style={{"textDecoration": "underline dotted"}}>Add multiple team members by importing a CSV file.</summary>
                    <div>
                        <div className='mt-3'>The first row must be a header row with the name <b>email</b>. Example:</div>
                        <pre className="docsCode">
                        email<br/>
                        example1@example.com<br/>
                        example2@example.com<br/>
                        example3@example.com<br/>
                        </pre>
                        <br/>
                        <input type="file" id="input" onChange={this.fileSelect} accept=".csv" disabled={this.state.submitting}/>
                        {addCsvConfirmText} 
                        {addCsvConfirm}
                    </div>
                </details>
            </div>
        );

        let information = 
        <div>
            <details className='font15 mb-3'>
                <summary className='pointer' style={{"textDecoration": "underline dotted"}}>Learn about single sign-on (no need to add team members here)</summary>
                <div>
                    <p className="font15">Consider enabling Single Sign-on to make logging in more secure and convenient for your team. Your team members will not need to create Tesults credentials. Click 'Single Sign-on' from the menu.</p>
                </div>
            </details>
            <details className='font15 mb-3'>
                <summary className='pointer' style={{"textDecoration": "underline dotted"}}>Learn about public results (no need to add team members here)</summary>
                <div>
                    <p className="font15">You can share your test results with others without adding them to your team. Do this by setting your organization, project and target to be public. By default all organizations, projects and targets are set to private. This feature is only intended for open-source and other projects intended for public consumption.</p>
                </div>
            </details>
            <details className='font15 mb-3'>
                <summary className='pointer' style={{"textDecoration": "underline dotted"}}>Learn about project roles</summary>
                <div>
                    <h4 className="mb-3">Team member roles</h4>
                    <h5 className="mb-3">1. Member</h5>
                    <p>Most team members should be ordinary members. Members can view results for the project, assign failing test cases (tasks) and receive notifications.</p>
                    <h5 className="mb-3">2. Moderator</h5>
                    <p>Team members assigned as moderators can do everything a member can do. They can also submit manual test results, delete test runs, trigger notifications manually and change the results of test cases. Changing a result is useful in the case of a false negative or positive result.</p>
                    <h5 className="mb-3">3. Administrator</h5>
                    <p>Administrators can do everything a moderator can do. They can also edit team members (promote other users up to administrator level), add, edit and delete targets, edit project information.</p>
                    <h5 className="mb-3">4. Officer</h5>
                    <p>Officers can do everything an administrator can do. They can also change the project plan, edit and update payment information. They can promote other team members up to Officer level.</p>
                    <h5 className="mb-3">5. Owner</h5>
                    <p>The project owner can do everything an officer can do. They can also delete a project and receive billing emails. There can only be one project owner. Project ownership can be transferred to another team member. </p>
                </div>
            </details>
        </div>
        
        let removeMember = this.removeMember;
        let submitting = this.state.submitting;
        let error = this.state.error;
        let info = this.state.info;
        let notifyEnable = this.notifyEnable;
        if (this.state.loading === true) {
            return (
                <div>
                    <Loading/>
                </div>
            )
        } else if (this.state.members.length === 0) {
            return (
                <div>
                    <div>
                        {addEmail}
                        <span className="fail mt-3">{error}</span>
                        <span className="accentc4mt-3">{info}</span>
                        <hr/>
                        <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                    </div>
                    <hr className='dividor-light'/>
                    {information}
                </div>
            )
        } else {
            let owners = [];
            let officers = [];
            let administrators = [];
            let moderators = [];
            let members = [];
            this.state.members.forEach(function (member) {
                let memberComponent = <Member key={member.id} state={this.state.state} transferSelect={this.transferSelect} project={this.props.project} userMember={this.state.userMember} member={member} submitting={submitting} removeMember={removeMember} notifyEnable={notifyEnable}/>
                if (member.role === 5) {
                    if (owners.length === 0) {
                        let transfer = <div></div>
                        if (this.state.state === "transfer") {
                            transfer = <button key="cancelButton" type="button" className="btn btn-cancel mt-3" onClick={this.cancelTransfer}>Cancel transfer</button>
                        } else {
                            if (this.state.userMember !== undefined) {
                                if (this.state.userMember.role === 5) {
                                    transfer = <div><button key="transferOwnerButton" type="button" className="btn-transparent mt-3" onClick={this.transferProjectOwnership}><span className="underline_dotted primary3">Transfer project ownership</span></button></div>
                                }
                            }
                        }
                        owners.push(
                            <div className='flex-row mb-5' style={{"alignItems":"center"}}>
                                <div>
                                    <h4 key="title" className="font20 mb-3">Owner</h4>
                                </div>
                                <div style={{"marginLeft":"auto"}}>
                                    {transfer}
                                </div>
                            </div>
                        )
                    }
                    owners.push(memberComponent);
                } else if (member.role === 4) {
                    if (officers.length === 0) {
                        officers.push(<h4 key="title" className="font20 mb-5">Officers</h4>);
                    }
                    officers.push(memberComponent);
                } else if (member.role === 3) {
                    if (administrators.length === 0) {
                        administrators.push(<h4 key="title" className="font20 mb-5">Administrators</h4>);
                    }
                    administrators.push(memberComponent);
                } else if (member.role === 2) {
                    if (moderators.length === 0) {
                        moderators.push(<h4 key="title" className="font20 mb-5">Moderators</h4>);
                    }
                    moderators.push(memberComponent);
                } else if (member.role === 1) {
                    if (members.length === 0) {
                        members.push(<h4 key="title" className="font20 mb-5">Team Members</h4>);
                    }
                    members.push(memberComponent);
                }
            }.bind(this));
            
            let ssoMessage = <span></span>
            if (this.state.sso !== undefined) {
                let display = false;
                let samlMessage = <span></span>
                let tesultsLoginMessage = <span></span>
                if (this.state.sso.tesultsLogin !== undefined) {
                    if (this.state.sso.tesultsLogin === false) {
                        tesultsLoginMessage = 
                        <p className="mb-3">Login using Tesults is disabled. You can change this option from the configuration menu in 'single sign-on (sso)'. New members added here will receive an email with a link to your single sign-on login page for Tesults.</p>
                    } else {
                        if (this.state.sso.ssoEnabled === true || this.state.sso.googleOAuthEnabled === true) {
                            tesultsLoginMessage = 
                            <p className="mb-3">Login using single sign-on and login using Tesults is enabled. You can change this option from the configuration menu in 'single sign-on (sso)'. New members added here will receive an email with a link to your single sign-on login page for Tesults as well as a link to login with Tesults with instruction to ask their IT administrator which to use.</p>
                        }
                    }
                }
                if (this.state.sso.samlEnabled === true) {
                    display = true;
                    samlMessage = 
                    <div>
                        <p><span className="primary1 mb-3">SAML based single sign-on is enabled</span>. We do not recommend adding team members here. Add Tesults as an application to your single sign-on identity provider and add access to your team members and employees there. If login using Tesults is enabled you can add guest users here to enable access.</p>
                    </div>
                }
                let googleOAuthMessage = <span></span>
                if (this.state.sso.googleOAuthEnabled === true) {
                    display = true;
                    googleOAuthMessage = 
                    <div>
                        <p><span className="primary1 mb-3">Google sign-on is enabled</span>. If login with Tesults is disabled any team member you had here should have a Google based email. Only team members added here will be granted access to your project or anyone signing with a Google email that matches one of the domains you have specified within single sign-on options from the configuration menu.</p>
                    </div>
                }
                if (display === true) {
                    ssoMessage = 
                    <div className="card mb-4">
                        <div className="card-body">
                            {tesultsLoginMessage}
                            {samlMessage}
                            {googleOAuthMessage}
                        </div>
                    </div>
                }
            }

            return (
                <div>
                    <div>
                        {addEmail}
                    </div>
                    <div className='p-3'>
                        <span className="font15 accenta4 mt-3">{error}</span>
                        <span className="font15 neutral4 mt-3">{info}</span>
                    </div>
                    <div>
                        {addCsv}
                    </div>
                    <hr className='dividor-light'/>
                    {owners}
                    {officers}
                    {administrators}
                    {moderators}
                    {members}
                    {ssoMessage}
                    <hr className='dividor-light'/>
                    {information}
                </div>
            );
        }
    }
}

export default Members;