/*global */
import React, { Component } from 'react';
import Cache from './Cache';
import ListGroup from './ListGroup';
import Notice from './Notice'

class ListGroupTree extends Component {
    constructor() {
        super();
        this.state = {root: undefined};
        this.groupPathsFromLists = this.groupPathsFromLists.bind(this);
        this.createRootNode = this.createRootNode.bind(this);
        this.createTree = this.createTree.bind(this);
        this.addNode = this.addNode.bind(this);
        this.addPath = this.addPath.bind(this);
        this.renderTree = this.renderTree.bind(this);
        this.searchFilter = this.searchFilter.bind(this)
    }

    componentDidMount() {
        this.createTree([], this.groupPathsFromLists());
    }

    componentWillReceiveProps(props) {
        this.props = props;
        this.createTree([], this.groupPathsFromLists());
    }

    groupPathsFromLists () {
        // for testing
        /*let paths =
        [
            ['a'],
            ['a', 'd'],
            ['a', 'e'],
            ['a', 'e', 'g'],
            ['b'],
            ['b', 'f'],
            ['b', 'f', 'h'],
            ['c']
        ]
        return paths;*/

        let paths = [];
        let pathsDict = {};
        const pathsDictKey = function (group) {
            let key = '';
            group.forEach(function (g) {
                key += g + "-";
            });
            return key;
        }

        let filter = this.searchFilter()
        let lists = filter.lists

        if (lists !== undefined) {
            lists.forEach(function (list) {
                if (list.group !== undefined) {
                    let key = pathsDictKey(list.group);
                    if (pathsDict[key] === undefined) {
                        paths.push(list.group);
                        pathsDict[key] = list.group.length;
                    }
                }
            });
        }

        // sort alphabetically
        paths.sort();

        return paths;
    }

    addNode (existingNode, val, path, renderId) {
        if (existingNode.nodes === undefined) {
            existingNode.nodes = [];
        }
        let newNode = {val: val, nodes: undefined, path: path, renderId: renderId};
        existingNode.nodes.push(newNode);
        return newNode;
    }

    addPath (root, path, iteration) {
        let node = root;
        for (let i = 0; i < path.length; i++) {
            let val = path[i];
            if (node.nodes !== undefined) {
                let foundNode = undefined;
                node.nodes.forEach(function (n) {
                    if (n.val === val) {
                        foundNode = n;
                    }
                });
                if (foundNode === undefined) {
                    node = this.addNode(node, val, path.slice(0, i + 1), 'r' + iteration + i);
                } else {
                    node = foundNode;
                }
            } else {
                node = this.addNode(node, val, path.slice(0, i + 1), 'r' + iteration + i);
            }
        }
    }

    createRootNode () {
        return { val: undefined, nodes: [], path: []};
    }

    createTree (root, paths) {
        /*
        input:
        [a]
        [a, d]
        [a, e]
        [a, e, g]
        [b]
        [b, f]
        [b, f, h]
        [c]

        output:
        a
         - d
         - e
           - g
        b
         - f
           - h
        c

        tree data structure:
        {
            val: undefined, // <- only the root val can be undefined
            nodes:
            [
                { 
                    val: a, 
                    nodes:
                    [
                        {
                            val: d, 
                            nodes: undefined
                        }, 
                        {
                            val: e, 
                            nodes: 
                            [
                                {
                                    val: g, 
                                    nodes: undefined
                                }
                            ]
                        }
                    ]
                },
                { 
                    val: b,
                    nodes: 
                    [
                        {
                            val: f,
                            nodes: 
                            [
                                {
                                    val: h,
                                    nodes: undefined
                                }
                            ]
                        },
                    ]

                },
                {
                    val: c,
                    nodes: undefined
                }
            ]
        }
        */
        
        root = this.createRootNode();
        let iteration = 0;
        paths.forEach(function (path) {
            this.addPath(root, path, iteration);
            iteration += 1;
        }.bind(this));
        this.setState({root: root});
    }

    renderTree (root, nodes, renderArray, level) {
        let filter = this.searchFilter()
        let lists = filter.lists
        
        if (level === 0 && renderArray.length === 0) {
            renderArray.push(<ListGroup key={root + "-" + level} node={root} lists={lists} project={this.props.project} listChange={this.props.listChange} createdListGroup={this.props.createdListGroup} deletedListGroup={this.props.deletedListGroup} allListsUpdated={this.props.allListsUpdated} setGroupProxy={this.props.setGroupProxy} overlay={this.props.overlay} createListConfirm={this.props.createListConfirm} selectSide={this.props.selectSide} search={this.props.search} onSearchChange={this.props.onSearchChange}/>)
        }
        if (nodes === undefined) {
            return renderArray;
        }
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            node.level = level + 1;
            renderArray.push(<ListGroup key={i + "-" + level} node={node} lists={lists} project={this.props.project} listChange={this.props.listChange} createdListGroup={this.props.createdListGroup} deletedListGroup={this.props.deletedListGroup} allListsUpdated={this.props.allListsUpdated} setGroupProxy={this.props.setGroupProxy} overlay={this.props.overlay} createListConfirm={this.props.createListConfirm} selectSide={this.props.selectSide} search={this.props.search} onSearchChange={this.props.onSearchChange}/>)
            if (node.nodes !== undefined) {
                renderArray.push(
                    <div key={i + "-" + level + "-children"} className={"collapse show " + node.renderId}>
                        {this.renderTree(root, node.nodes, [], level + 1)}
                    </div>
                );
            }
        }
        return renderArray;
    }

    searchFilter () {
        let filteredLists = []
        let searchExclusionNum = 0
        for (let i = 0; i < this.props.lists.length; i++) {
            let list = this.props.lists[i]
            let include = true
            if (this.props.search !== undefined && this.props.search !== "") {
                try {
                    if (list.label.toLowerCase().indexOf(this.props.search.toLowerCase()) === -1) {
                        include = false
                        searchExclusionNum += 1;
                    }
                } catch (err) {
                    // Ignore error
                }
            }
            if (include) {
                filteredLists.push(list)
            }
        }
        return {lists: filteredLists, excluded: searchExclusionNum}
    }    

    render () {
        let filter = this.searchFilter()
        let tree = <span></span>
        if (this.state.root !== undefined) {
            tree = this.renderTree(this.state.root, this.state.root.nodes, [], 0);
        }
        return (
            <div>
                {tree}
                {
                    filter.excluded === 0 ? 
                    <span></span>
                    :
                    <Notice type="warning" content={<div className='neutral3'>Some lists are not being displayed due to the search criteria condition. Remove search value to view all lists.</div>}/>
                }
            </div>
        );
    }
};

export default ListGroupTree;