/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsAccountCountry extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Account Country Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults account country."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select the <b>Account Country</b> option.</p>
                <p>The account country is the country of the account owner or the country of the company you work for. This is used for billing purposes and ensures you are billed correctly.</p>
                <p>If you are on a paid plam you can change the account country from this page.</p>
                <p>The currently assigned country is displayed. To change the country, click the 'Change' button and then select the new country from the dropdown list. Click 'Confirm Change' to save and apply the new country.</p>
            </div>
        );
    }
}

export default DocsAccountCountry;