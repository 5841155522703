/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsTeamMembers extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Team Members Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults notifications."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Team Members.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/G6wCdHXiLcE?si=i87WaYMQbpn-iAHU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Tesults includes a built in team management system and also offers Single Sign On using SAML or Google OAuth. The <Link to="/docs/sso">Single Sign On documentation</Link> goes into more detail about Single Sign On (SSO). Whether you use SSO or not, the 'Team Members' option in the Configuration Menu provides details on all of the team members with access to your Tesults project and provides a way to change team member roles, set notification settings and remove members.</p>
                <h2>Add A Team Member</h2>
                <p>If SSO is enabled for your project then you do not need to manually add team members. As long as team members have the SSO sign in link for your project they will be able to sign in and be added to the project without manual intervention. Otherwise you will need to manually add team members to your project.</p>
                <p>Input the email address for the team member into the email input field and click the 'Add' button. An email will be sent with an invitation to the project.</p>
                <p>Multiple team members can be added at once using a CSV file. The first row must be a header row with the name <b>email</b> and all rows following should contain each email address.</p>
                <h2>Team Member Roles</h2>
                <p>When you add a new team member to a project manually their access role is set to 1 (Member) and when they are added using SSO they are set to role 2 (Moderator) by default. Roles can be changed using the dropdown list beside each team member. The person who created the project is set role 5 (Owner). The signed in user must be role 3 (Administrator) level or higher in order to change team member roles and it is only possible to promote team members to roles up to the same level as the current user.</p>
                <h4>1. Member</h4>
                <p>Most team members should be ordinary members. Members can view results for the project, assign failing test cases (tasks) and receive notifications.</p>
                <h4>2. Moderator</h4>
                <p>Team members assigned as moderators can do everything a member can do. They can also submit manual test results, delete test runs, trigger notifications manually and change the results of test cases. Changing a result is useful in the case of a false negative or positive result.</p>
                <h4>3. Administrator</h4>
                <p>Administrators can do everything a moderator can do. They can also edit team members (promote other users up to administrator level), add, edit and delete targets, edit project information.</p>
                <h4>4. Officer</h4>
                <p>Officers can do everything an administrator can do. They can also change the project plan, edit and update payment information. They can promote other team members up to Officer level.</p>
                <h4>5. Owner</h4>
                <p>The project owner can do everything an officer can do. They can also delete a project and receive billing emails. There can only be one project owner. Project ownership can be transferred to another team member.</p>
                <h2>Remove Team Members</h2>
                <p>Remove team members by clicking the 'Remove from project' button beside the team member. Tesults has a <Link to="/docs/fair-billing-policy">fair billing policy</Link> so inactive users automatically stop getting billed for and there is no need for an administrator to manually remove seats for cost reasons. However it is recommended for security reasons that team members are removed if they should no longer have access to the project.</p>
                <h2>Allow Notifications</h2>
                <p>Toggle email notifications on or off for individual team members using the 'Enable/Disable notifications' button.</p>
                <h2>Status</h2>
                <p>The status field displays information about active/inactive status for each team member.</p>
            </div>
        );
    }
}

export default DocsTeamMembers;