/*global */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import DocsTestFramework from './DocsTestFramework'
import Box from './Box'

class DocsCodeceptJS extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>CodeceptJS test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle CodeceptJS test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>Powering up your CodeceptJS tests with Tesults reporting is easy. This page explains it all. If you have any questions please reach out to the Tesults team. If you are not using CodeceptJS view the <NavLink to="/docs/javascript" target="_blank" className="site-link-primary2 no-break">Node.js and JavaScript</NavLink> docs for information about integrating with a lower level library.</p>
        </div>

        const installation = 
        <div>
            <pre className="docsCode">
                npm install codeceptjs-tesults --save
            </pre>
        </div>

        const configuration =
        <div>
            <p>In your codecept.conf.js file add codeceptjs-tesults in the plugins section:</p>
            <pre className="docsCode"> 
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;// ...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;plugins: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults': {'{'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'require': 'codeceptjs-tesults',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'enabled': true,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'target': '<code className="accentc6">token</code>'<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
            &nbsp;&nbsp;// ...<br/>
            {'}'}
            </pre>
            <pre className="primary2 docsArgsCode pl-0">target<span className="neutral7 ml-5">Required</span></pre>
            <p>You must provide your target token to push results to Tesults. Replace 'token' in the above code with the value of your target token. If this arg is not provided the plugin does not attempt upload and is disabled. You receive a target token when creating a project or new target and can regenerate one at anytime from the configuration menu. Find out more about <NavLink to="/docs/target" target="_blank" className="tr-link-primary4 no-break">targets</NavLink></p>
            <p>To support <b>multiple reporting targets</b>, you can use multiple configuration files.</p>
            <Box
                bg="neutral8bg"
                title={<h4>Basic configuration complete</h4>}
                content={
                <div>
                    <p>At this point, if you run tests, results data will be uploaded to Tesults</p>
                    <pre className='docsCode'>
                        npx codeceptjs run
                    </pre>
                </div>
                }
            />
        </div>

        const enhancedReporting = 
        <div>
            <p>Associate a file to the test case in order to upload it for reporting using codeceptjs-tesults <b>version 1.2.0+</b> using I.say followed by a string with prefix "tesults:file:" followed by the full path to the file:</p>
            <pre className='docsCode'>
            Feature('Feature A');<br/><br/>
            Scenario('Scenario 1', ({'{'} I {'}'}) => {'{'}<br/>
            &nbsp;&nbsp;I.amOnPage('https://www.google.com');<br/>
            &nbsp;&nbsp;<code className="accentc6">I.say</code>("<code className="accentb6">tesults:file:</code>/full/path/to/file/log.txt");<br/>
            &nbsp;&nbsp;<code className="accentc6">I.say</code>("<code className="accentb6">tesults:file:</code>/full/path/to/file/screenshot.png");<br/>
            &nbsp;&nbsp;I.see('does not exist');<br/>
            {'}'});
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <p>Optionally report build information by passing build data as additional data in the plugin properties</p>
            <pre className="docsCode"> 
            exports.config = {'{'}<br/>
            &nbsp;&nbsp;// ...<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;plugins: {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'tesults': {'{'},<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'require': 'codeceptjs-tesults',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'enabled': true,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'target': 'token',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'build-name': '1.0.0',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'build-description': 'build description',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'build-result': 'pass',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'build-reason': 'failure reason',<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'build-files': ['/full/path/to/file']<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
            &nbsp;&nbsp;// ...<br/>
            {'}'}
            </pre>
            <h4>Build properties</h4>
            <pre className="docsArgsCode">build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <hr/>
            <pre className="docsArgsCode">build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <hr/>
            <pre className="docsArgsCode">build-description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <hr/>
            <pre className="docsArgsCode">build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <hr/>
            <pre className="docsArgsCode">build-files<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to upload build files as an array of paths to specific files, see example above.</p>
            <br/>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                enhancedReporting={enhancedReporting}
                build={build}
                integration="codeceptjs"
            />
        );
    }
}

export default DocsCodeceptJS;
