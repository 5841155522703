import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TimeFormatted from './TimeFormatted'

class ReleaseChecklistSelect extends Component {
    render () {
        let statusIcon = <div className="dot accentb3bg mr-3"></div>
        let statusText = <span className="accentb3 mr-3"> In Progress</span>

        if (this.props.checklist.complete !== undefined && this.props.checklist.total !== undefined) {
            if (this.props.checklist.complete === this.props.checklist.total && this.props.checklist.total > 0) {
                statusIcon = <div className="dot accentc3bg mr-3"></div>
                statusText = <span className="accentc3 mr-3"> Complete</span>
            }
        }
        return (
            <Link key={this.props.key} to={"/release-checklists/rcrl/" + this.props.checklist.project + "/" + this.props.checklist.team + "/" + this.props.checklist.created} className="nounderline">
                <div className="case caseDiv mb-2" style={{"marginLeft": this.props.indent + "px"}}>
                    <div className="pl-4 pr-3" style={{"display":"flex", "alignItems":"center"}}>
                        {statusIcon}
                        <div class="site-link">
                            {statusText}
                            {this.props.checklist.name}
                        </div>
                        <div className="ml-5 site-link">
                            {<TimeFormatted dt={this.props.checklist.created} includeYear={true}/>}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default ReleaseChecklistSelect