/*global */
import React, { Component } from 'react'
import Loading from './Loading'
import Analytics from './Analytics'
import Request from './Request'
import Context from './Context'
import Cache from './Cache'
import Confirmation from './Confirmation'
import SelectOverlay from './SelectOverlay'
import Notice from './Notice'

class RawResultsMap extends Component {
    static contextType = Context;

    constructor () {
        super ();
        this.state = {loading: false, label: "", map: {}, newKey: "", newValue: "pass"}
        this.init = this.init.bind(this)
        this.project = this.project.bind(this)
        this.add = this.add.bind(this)
        this.remove = this.remove.bind(this)
        this.save = this.save.bind(this)
        this.cancel = this.cancel.bind(this)
        this.labelChange = this.labelChange.bind(this)
        this.newKeyChange = this.newKeyChange.bind(this)
        this.newValueChange = this.newValueChange.bind(this)
        this.mapKeyChange = this.mapKeyChange.bind(this)
        this.mapValueChange = this.mapValueChange.bind(this)
        this.mapChanged = this.mapChanged.bind(this)
        this.renderMap = this.renderMap.bind(this)
    }
    
    componentDidMount () {
        this.init()
    }

    init () {
        if (this.props.map) {
            let map = {}
            Object.keys(this.props.map.map).forEach((key) => {
                map[key] = this.props.map.map[key]
            })
            this.setState({label: this.props.map.label, created: this.props.map.created, map: map})
        }
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    labelChange (value) {
        this.setState({label: value})
    }

    add () {
        if (this.state.map[this.state.newKey] === undefined) {
            let map = this.state.map
            map[this.state.newKey] = this.state.newValue
            this.setState({newKey: "", newValue: "pass", map: map})
        }
    }

    remove (key) {
        let map = this.state.map
        delete map[key]
        this.setState({map: map})
    }

    mapKeyChange (keyOld, keyNew) {
        let map = this.state.map
        let value = map[keyOld]
        delete map[keyOld]
        map[keyNew] = value
        this.setState({map: map})
    }

    mapValueChange (key, value) {
        let map = this.state.map
        map[key] = value
        this.setState({map: map})
    }

    save () {
        if (this.props.map === undefined) {
            if (this.props.save !== undefined) {
                this.props.save(this.state.label, this.state.map)
            }
        } else {
            if (this.props.update !== undefined) {
                this.props.update(this.state.label, this.state.created, this.state.map)
            }
        }
        
    }

    cancel () {
        if (this.props.cancel !== undefined) {
            this.props.cancel()
        }
    }

    newKeyChange (e) {
        this.setState({newKey: e.target.value})
    }

    newValueChange (value) {
        this.setState({newValue: value})
    }

    mapChanged () {
        if (this.props.map) {
            if (this.props.map.label !== this.state.label) {
                return true
            }
        }
        let map = {}
        if (this.props.map) {
            Object.keys(this.props.map.map).forEach((key) => {
                map[key] = this.props.map.map[key]
            })
        }
        let editedMap = this.state.map
        let mapKeys = []
        let editedMapKeys = []
        Object.keys(map).forEach((key) => {
            mapKeys.push(key)
        })
        Object.keys(editedMap).forEach((key) => {
            editedMapKeys.push(key)
        })
        mapKeys.sort()
        editedMapKeys.sort()
        if (mapKeys.length === editedMapKeys.length) {
            if (mapKeys.length === 0) {
                return false
            } else {
                for (let i = 0; i < mapKeys.length; i++) {
                    if (mapKeys[i] !== editedMapKeys[i]) {
                        return true // keys are different
                    } else {
                        let key = mapKeys[i]
                        if (map[key] !== editedMap[key]) {
                            return true // values are different
                        }
                    }
                }
            }
            return false
        } else {
            return true
        }
    }

    renderMap () {
        let rows = [
            <div key="label">
                <div>
                    <div className='neutral4 font14'>Name of this map</div>
                </div>
                <div>
                    <input className='tr-input' onChange={(e) => this.labelChange(e.target.value)} value={this.state.label}/>
                </div>
                <hr key="dividor1" className='dividor-light mt-5 mb-5'/>
                <div>
                    <div className='neutral4 font14'>Result map (value &rarr; interpretation) </div>
                </div>
            </div>
        ]
        if (Object.keys(this.state.map).length === 0) {
            rows.push(<div key="no-maps" className='neutral4 font14 mb-5'>Empty</div>)
        }
        Object.keys(this.state.map).forEach((key) => {
            rows.push(
                <div key={rows.length} className="flex-row">
                    <div className='mr-3'>
                        <input className='tr-input' onChange={(e) => this.mapKeyChange(key, e.target.value)} value={key} disabled={true}/>
                    </div>
                    <div className='mr-3 pt-2'>&rarr;</div>
                    <div className='mr-3' style={{"width":"100px"}}>
                        <SelectOverlay
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            type="generic"
                            title={this.state.map[key]}
                            label="label"
                            value="value"
                            options={[
                                {label: "Pass", value: "pass"},
                                {label: "Fail", value: "fail"}
                            ]}
                            valueChange={(value) => this.mapValueChange(key, value)}
                        />
                    </div>
                    <div>
                        <button className='btn-cancel' onClick={() => this.remove(key)}>Remove</button>
                    </div>
                </div>
            )
        })
        rows.push(<hr key="dividor2" className='dividor-light mb-5'/>)
        rows.push(<div className='neutral4 font14'>New mapping (value &rarr; interpretation)</div>)
        rows.push(
            <div key={rows.length} className="flex-row">
                <div className='mr-3'>
                    <input className='tr-input' onChange={this.newKeyChange} value={this.state.newKey}/>
                </div>
                <div className='mr-3 pt-2'>&rarr;</div>
                <div className='mr-3' style={{"width":"100px"}}>
                    <SelectOverlay
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        type="generic"
                        title={this.state.newValue}
                        label="label"
                        value="value"
                        options={[
                            {label: "Pass", value: "pass"},
                            {label: "Fail", value: "fail"}
                        ]}
                        valueChange={(value) => this.newValueChange(value)}
                    />
                </div>
                <div>
                    <button className='btn-cancel' onClick={this.add}>Add</button>
                </div>
            </div>
        )
        return rows
    }
    

    render () {
        let disabled = true
        if (this.mapChanged()) { disabled = false }
        return (
            <div>
                <div className='mt-5 mb-5'>
                    {this.renderMap()}
                </div>
                <hr className='dividor-light'/>
                <div className='flex-row'>
                    <div className='mr-3'>
                        <button className='btn-confirm' onClick={this.save} disabled={disabled}>Save</button>
                    </div>
                    <div>
                        <button className='btn-cancel' onClick={this.cancel}>Cancel</button>
                    </div>
                </div>
                <div className='mb-5'>
                    <Confirmation confirmation={this.props.confirmation}/>
                </div>
                <div style={{"maxWidth":"400px"}}>
                    <Notice type="information" content={
                        <div className='font14 neutral4'>
                            Any unmapped values will be interpreted as unknown status.
                        </div>}
                    />
                </div>
            </div>
        )
    }
}

export default RawResultsMap