/*global Cookies*/
import React, { Component } from 'react';
import Loading from './Loading';
import Request from './Request';

class TaskResolve extends Component {
    constructor (props) {
        super(props);
        let resolved = false;
        if (this.props.resolved === true) {
            resolved = true;
        }
        let resolvedMessage = "";
        if (this.props.resolvedMessage !== undefined) {
            resolvedMessage = this.props.resolvedMessage;
        }
        if (resolvedMessage === "-") {
            resolvedMessage = "";
        }
        this.state = {edit: false, resolved: resolved, resolvedMessage: resolvedMessage, submitting: false};
        this.edit = this.edit.bind(this);
        this.resolvedMessageChange = this.resolvedMessageChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.taskChanged = this.taskChanged.bind(this);
    }

    edit () {
        this.setState({edit: true});
    }

    resolvedMessageChange (e) {
        this.setState({resolvedMessage: e.target.value});
    }

    toggle () {
        if (this.state.resolved === true) {
            this.setState({edit: true, resolved: false});
        } else {
            this.setState({edit: true, resolved: true});
        }
    }

    taskChanged () {
        if (this.props.taskChanged !== undefined) {
            this.props.taskChanged();
        }
    }

    save () {
        this.setState({submitting: true});
        Request.post("/taskresolve", {project: this.props.project, target: this.props.target, hash: this.props.case.hash, resolved: this.state.resolved, resolvedMessage: this.state.resolvedMessage}, (err, data) => {
            if (err) {
                this.setState({error: "Error updating resolution.", submitting: false});
            } else {
                this.setState({edit: false, submitting: false}, this.taskChanged());
            }
        });
    }

    render () {
        if (this.state.submitting === true) {
            return <div className="mt-3"><Loading/></div>
        }

        let resolveConfirm = <span></span>
        if (this.state.edit === true) {
            resolveConfirm = 
            <div>
                <button type="button" className="btn btn-confirm mt-3" onClick={() => {this.save()}}>Save</button>
                <br/>
                <small><span className="pass">{this.state.message}</span></small>
                <small><span className="fail">{this.state.error}</span></small>
            </div>
        }

        let resolvedMessage = 
            <div className="pre-wrap">
                {this.state.resolvedMessage}
            </div>
        if (this.state.edit === true) {
            resolvedMessage =
            <div>
                <div className="neutral1 mt-3">Resolved message (optional):</div>
                <div>
                    <textarea className="form-control verticalResize width100" rows="4" name="resolvedMessage" value={this.state.resolvedMessage} onChange={this.resolvedMessageChange} required>{this.state.resolvedMessage}</textarea> 
                </div>
            </div>
        }

        const user = Cookies.getJSON("truser");
        if (this.props.assignee.id !== user.id) {
            if (this.state.resolved === true) {
                return (
                    <div className="mt-3">
                        <div>Resolved</div>
                        <div>{resolvedMessage}</div>
                    </div>
                );
            } else {
                return <span></span>
            }
        } else {
            return (
                <div className="mt-3">
                    <div>This case is assigned to you.</div>
                    <div>Resolve:</div>
                    <label className="autoRefreshSwitch align-self-center">
                        <input type="checkbox" onChange={this.toggle} checked={this.state.resolved}/>
                        <span className="autoRefreshSlider round"></span>
                    </label>
                    <div>
                        {resolvedMessage}
                    </div>
                    <div>
                        {resolveConfirm}
                    </div>
                </div>
            );
        }
    }
}

export default TaskResolve;