/*global */
import React, { Component } from 'react';
//import Constants from './Constants';
//import InfoToolTip from './InfoToolTip';

class ManualAssignee extends Component {
    render () {
        let membersDictionary = {};
        if (this.props.members === undefined) {
            return <div className="mb-3"></div>
        }
        this.props.members.forEach(function (member) {
            membersDictionary[member.id] = member;
        });

        let assigneesDictionary = {};
        this.props.allCases.forEach(function (c) {
            if (c.manualAssignee === undefined) {
                if (assigneesDictionary.unassigned === undefined) {
                    assigneesDictionary['unassigned'] = {total: 1, complete: ((c.manualComplete === true || c.manualComplete === "true") ? 1: 0)};
                } else {
                    assigneesDictionary['unassigned'].total = assigneesDictionary['unassigned'].total + 1;
                    if (c.manualComplete === true || c.manualComplete === "true") {
                        assigneesDictionary['unassigned'].complete = assigneesDictionary['unassigned'].complete + 1;
                    }
                }
            } else {
                if (assigneesDictionary[c.manualAssignee] === undefined) {
                    assigneesDictionary[c.manualAssignee] = {total: 1, complete: ((c.manualComplete === true || c.manualComplete === "true") ? 1: 0)};
                } else {
                    assigneesDictionary[c.manualAssignee].total = assigneesDictionary[c.manualAssignee].total + 1;
                    if (c.manualComplete === true || c.manualComplete === "true") {
                        assigneesDictionary[c.manualAssignee].complete = assigneesDictionary[c.manualAssignee].complete + 1;
                    }
                }
            }
        });

        let assignees = [];
        Object.keys(assigneesDictionary).forEach(function (id) {
            if (id === "unassigned") {
                assignees.push(<span className="small neutral3" key={id}>{"Unassigned: " + assigneesDictionary[id].total}<br/></span>);
            } else {
                let member = membersDictionary[id];
                if (member !== undefined) {
                    assignees.push(<span className="small neutral3" key={id}>{member.firstName + " " + member.lastName + ": " + assigneesDictionary[id].total + " (" + assigneesDictionary[id].complete + " Complete)"}<br/></span>);
                }
            }
        });

        return (
            <div className="mb-3">{assignees}</div>
        );
    }   
};

export default ManualAssignee