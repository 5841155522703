/*global*/
import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Menu from './Menu';
import Side from './Side';

import Results from './Results';
import Tasks from './Tasks';
import VerifyEmail from './VerifyEmail';
import Manual from './Manual';
import Lists from './Lists';
import Design from './Design';
import Config from './Config';
import Notifications from './Notifications';
import Overlay from './Overlay';
import MessageOverlay from './MessageOverlay';

import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import EmptySide from './EmptySide';
import AuthSide from './AuthSide'

import Index from './Index';
import About from './About';
import BlogEditor from './BlogEditor';
import BlogMain from './BlogMain';
import BlogSide from './BlogSide';
import Careers from './Careers';
import CareersRole from './CareersRole';
import Consulting from './Consulting';
import Contact from './Contact';
import EmailReceive from './EmailReceive';
import FeatureRequest from './FeatureRequest';
import Roadmap from './Roadmap';
import Demo from './Demo';
import DocsMain from './DocsMain';
import DocsSide from './DocsSide';
import ExpMain from './ExpMain';
import ExpSide from './ExpSide';
import ForgotPassword from './ForgotPassword';
import InviteRegister from './InviteRegister';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import Administrator from './Administrator';
import AdministratorMenu from './AdministratorMenu';
import Affiliate from './Affiliate';
import AffiliateMenu from './AffiliateMenu';
import NotFound from './NotFound';
import SideToggle from './SideToggle'
import Feedback from './Feedback'
import Notification from './Notification';

import ReleaseChecklists from './ReleaseChecklists'
import Cache from './Cache'
import ChannelPartner from './ChannelPartner'

class App extends Component {
    constructor () {
        super();
        let menu = Cache.getPreference(Cache.preference.menu);
        if (menu === undefined) {
            menu = true;
        }
        this.state = {
            menu: menu, 
            mobileMenu: false,

            path: "",

            active: undefined, 
            
            main: undefined, 
            
            side: false, 
            sideContent: undefined,
            sideOnClose: undefined,
            
            sideOverlay: false, 
            sideOverlayContent: undefined,
            
            overlay: false,
            overlayContent: undefined,
            overlayContentHeight: undefined,
            overlayRef: undefined,

            messageOverlay: false,
            messageOverlayType: undefined,
            messageOverlayAction: undefined,

            notificationText: undefined,
            notificationIcon: undefined,

            resultsSubView: undefined
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.main = this.main.bind(this);
        this.side = this.side.bind(this);
        this.sideOverlay = this.sideOverlay.bind(this);
        this.overlay = this.overlay.bind(this);
        this.mainClick = this.mainClick.bind(this);
        this.sideClick = this.sideClick.bind(this);
        this.menuClick = this.menuClick.bind(this);
        this.cancelPath = this.cancelPath.bind(this);
        this.projectChange = this.projectChange.bind(this);
        this.messageOverlay = this.messageOverlay.bind(this);
        this.messageOverlayDismiss = this.messageOverlayDismiss.bind(this);
        this.messageOverlayAction = this.messageOverlayAction.bind(this);
        this.resultsView = this.resultsView.bind(this);
        this.supplementalView = this.supplementalView.bind(this);
        this.menuContent = this.menuContent.bind(this)
        this.renderApp = this.renderApp.bind(this)
        this.renderSite = this.renderSite.bind(this)
        this.renderSiteAuth = this.renderSiteAuth.bind(this)
        this.renderSiteIndex = this.renderSiteIndex.bind(this)
        this.ref = React.createRef()
        this.sideToggle = this.sideToggle.bind(this)
        this.displayNotification = this.displayNotification.bind(this);
        this.hideNotification = this.hideNotification.bind(this);

        this.notificationTimer = null;
    }

    componentDidMount () {
        this.main(true);
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const path = window.location.pathname;
        if (prevState.side !== this.state.side) {
            this.setState({mobileMenu:false}, () => this.main());
        } else if (prevState.path !== path) {
            this.setState({mobileMenu:false}, () => this.main());
        }
    }

    resultsView () {
        this.setState({resultsSubView:"results"}, () => {
            let path = window.location.pathname;
            path = path.replace("/view/supplemental", "/view/results")
            this.props.history.push(path);
            this.main();
        });
    }

    supplementalView () {
        this.setState({resultsSubView:"supplemental"}, () => {
            let path = window.location.pathname;
            path = path.replace("/view/results", "/view/supplemental")
            this.props.history.push(path);
            this.main();
        });
    }

    displayNotification (text, icon) {
        // if (this.timer !== null) {
        //     clearTimeout(this.timer);
        // }
        this.setState({notificationText: text, notificationIcon: icon});
        this.timer = setTimeout(this.hideNotification, 3000);
        
    }

    hideNotification () {
        this.setState({notificationText: undefined, notificationIcon: undefined}, clearTimeout(this.timer));
    }

    main (init) {
        const path = window.location.pathname;
        const prevPath = this.props.history.location.pathname;
        if (prevPath !== path) {
            this.props.history.push(path)
        }
        let active = undefined;
        if (this.props.results === true) {
            this.setState(
                { 
                    path: path,
                    side: (init === true ? true : this.state.side),
                    main: <Results 
                                results={true}
                                overlay={this.overlay}
                                messageOverlay={this.messageOverlay}
                                side={this.side}
                                sideOpen={this.state.side}
                                sideOverlay={this.sideOverlay}
                                trlOrg={this.props.trlOrg}
                                trlProject={this.props.trlProject}
                                trlTarget={this.props.trlTarget}
                                trlRun={this.props.trlRun}
                                trlCase={this.props.trlCase}
                                trlView={this.props.trlView}
                                location={this.props.location} 
                                history={this.props.history}
                                resultsSubView={this.state.resultsSubView}
                                resultsView={this.resultsView}
                                supplementalView={this.supplementalView}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/results')) {
            let sideOpen = true
            if (path.startsWith("/results/rsp/view/results")) {
                active = "results";
            }
            if (path.startsWith("/results/rsp/view/supplemental")) {
                active = "supplemental";
            }
            if (path.startsWith("/results/rsp/view/status")) {
                active = "status";
                sideOpen = false
            }
            if (path.startsWith("/results/rsp/view/diff")) {
                active = "diff";
                sideOpen = false
            }
            this.setState(
                {
                    path: path,
                    active: active,
                    side: ((init === true && sideOpen === true) ? true : this.state.side),
                    main: <Results 
                                overlay={this.overlay} 
                                messageOverlay={this.messageOverlay}
                                side={this.side}
                                sideOpen={this.state.side}
                                sideOverlay={this.sideOverlay}
                                location={this.props.location} 
                                history={this.props.history} 
                                resultsSubView={this.state.resultsSubView}
                                resultsView={this.resultsView}
                                supplementalView={this.supplementalView}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/tasks')) {
            active = "tasks";
            this.setState(
                {
                    path: path,
                    active: active,
                    side: (init === true ? true : this.state.side), 
                    main: <Tasks
                                overlay={this.overlay} 
                                messageOverlay={this.messageOverlay}
                                side={this.side}
                                sideOverlay={this.sideOverlay}
                                sideOpen={this.state.side}
                                location={this.props.location} 
                                history={this.props.history}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/verifyemail')) {
            this.setState(
                {
                    path: path,
                    main: <VerifyEmail 
                                location={this.props.location} 
                                history={this.props.history}
                            />
                }
            );
        } else if (path.startsWith('/manual')) {
            active = "manual";
            this.setState(
                {
                    path: path,
                    active: active, 
                    side: (init === true ? true : this.state.side),
                    main: <Manual
                                overlay={this.overlay} 
                                messageOverlay={this.messageOverlay}
                                side={this.side}
                                sideOverlay={this.sideOverlay}
                                sideOpen={this.state.side} 
                                location={this.props.location} 
                                history={this.props.history}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/lists')) {
            active = "lists";
            this.setState(
                {
                    path: path,
                    active: active, 
                    side: (init === true ? true : this.state.side),
                    main: <Lists 
                                overlay={this.overlay} 
                                messageOverlay={this.messageOverlay}
                                side={this.side}
                                sideOverlay={this.sideOverlay}
                                sideOpen={this.state.side}
                                location={this.props.location} 
                                history={this.props.history}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/release-checklists')) {
            active = "release-checklists";
            this.setState(
                {
                    path: path,
                    active: active, 
                    side: (init === true ? true : this.state.side),
                    main: <ReleaseChecklists 
                                overlay={this.overlay} 
                                messageOverlay={this.messageOverlay}
                                side={this.side}
                                sideOverlay={this.sideOverlay}
                                sideOpen={this.state.side}
                                location={this.props.location} 
                                history={this.props.history}
                                state={this.props.state}
                                projectId={this.props.project}
                                teamId={this.props.team}
                                checklistId={this.props.checklist}
                                itemId={this.props.item}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/createproject')) {
            active = "config";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Config 
                            overlay={this.overlay}
                            messageOverlay={this.messageOverlay}
                            area={this.props.area} 
                            option={this.props.option} 
                            location={this.props.location} 
                            history={this.props.history}
                            displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/configProject')) {
            active = "config";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Config 
                                overlay={this.overlay}
                                messageOverlay={this.messageOverlay}
                                area={this.props.area} 
                                option={this.props.option} 
                                location={this.props.location} 
                                history={this.props.history}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/configUser')) {
            active = "account";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Config 
                                title="Account"
                                overlay={this.overlay}
                                messageOverlay={this.messageOverlay}
                                area={this.props.area} 
                                option={this.props.option} 
                                location={this.props.location} 
                                history={this.props.history}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/configProfile') || path === "/config/profile") {
            active = "account";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Config 
                            title="Account"
                            overlay={this.overlay}
                            messageOverlay={this.messageOverlay}
                            area={this.props.area} 
                            option={this.props.option} 
                            location={this.props.location} 
                            history={this.props.history}
                            displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/config') && path !== "/configProfile" && path !== "/config/profile") {
            active = "config";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Config 
                                overlay={this.overlay}
                                messageOverlay={this.messageOverlay}
                                area={this.props.area} 
                                option={this.props.option} 
                                location={this.props.location} 
                                history={this.props.history}
                                displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path.startsWith('/notifications')) {
            active = "notifications";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Notifications
                            overlay={this.overlay}
                            messageOverlay={this.messageOverlay}
                            area={this.props.area} 
                            option={this.props.option} 
                            location={this.props.location} 
                            history={this.props.history}
                            displayNotification={this.displayNotification}
                            />
                }
            );
        } else if (path === '/feedback') {
            active = "feedback";
            this.setState(
                {
                    path: path,
                    active: active, 
                    main: <Feedback
                            overlay={this.overlay}
                            messageOverlay={this.messageOverlay}
                            area={this.props.area} 
                            option={this.props.option} 
                            location={this.props.location} 
                            history={this.props.history}
                            displayNotification={this.displayNotification}
                            />
                }
            );
        }  else if (path === '/design') {
            active = "config";
            this.setState(
                {
                    path: path,
                    active: active,
                    main: <Design overlay={this.overlay}/>
                }
            );
        }
        if (path === '/' || path.startsWith("/ref/")) {
            this.setState({path: path, main: <Index overlay={this.overlay} ref={this.props.ref} match={this.props.match} location={this.props.location} history={this.props.history}/>, siteContentPadding: false});
        } else if (path === "/about") {
            this.setState({path: path, main: <About overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/administrator")) {
            this.setState({path: path, main: <Administrator overlay={this.overlay} match={this.props.match} location={this.props.location} history={this.props.history}/>, menuContent: <AdministratorMenu match={this.props.match} location={this.props.location} history={this.props.history}/>, siteContentPadding: true});
        } else if (path.startsWith("/affiliate")) {
            this.setState({path: path, main: <Affiliate overlay={this.overlay} match={this.props.match} location={this.props.location} history={this.props.history}/>, menuContent: <AffiliateMenu match={this.props.match} location={this.props.location} history={this.props.history}/>, siteContentPadding: true});
        } else if (path.startsWith("/channel-partner")) {
            this.setState({path: path, main: <ChannelPartner overlay={this.overlay} match={this.props.match} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        }  else if (path === "/blog-editor") {
            this.setState({path: path, main: <BlogEditor overlay={this.overlay} location={this.props.location} history={this.props.history}/>, siteContentPadding: true});
        } else if (path.startsWith("/blog")) {
            this.setState({path: path, main: <BlogMain overlay={this.overlay} match={this.props.match} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path === "/careers") {
            this.setState({path: path, main: <Careers overlay={this.overlay} location={this.props.location} history={this.props.history} match={this.props.match}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/careers")) {
            this.setState({path: path, main: <CareersRole overlay={this.overlay} location={this.props.location} history={this.props.history} match={this.props.match}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/consulting")) {
            this.setState({path: path, main: <Consulting overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/contact")) {
            this.setState({path: path, main: <Contact overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/featurerequest")) {
            this.setState({path: path, main: <FeatureRequest overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/roadmap")) {
            this.setState({path: path, main: <Roadmap overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/demo")) {
            this.setState({path: path, main: <Demo overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/docs")) {
            this.setState({path: path, main: <DocsMain overlay={this.overlay} match={this.props.match} location={this.props.location} history={this.props.history}/>, menuContent: <DocsSide location={this.props.location}/>, siteContentPadding: true});
        } else if (path.startsWith("/exp")) {
            this.setState({path: path, main: <ExpMain overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <DocsSide location={this.props.location}/>, siteContentPadding: true});
        } else if (path.startsWith("/forgotpassword")) {
            this.setState({bg: "sitebg", path: path, main: <ForgotPassword overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <AuthSide/>, siteContentPadding: true});
        } else if (path.startsWith("/inviteregister")) {
            this.setState({bg: "sitebg", path: path, main: <InviteRegister overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <AuthSide/>, siteContentPadding: true});
        } else if (path.startsWith("/login")) {
            this.setState({bg: "sitebg", path: path, main: <Login overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <AuthSide/>, siteContentPadding: true});
        } else if (path.startsWith("/register")) {
            this.setState({bg: "sitebg", path: path, main: <Register overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <AuthSide/>, siteContentPadding: true});
        } else if (path.startsWith("/resetpassword")) {
            this.setState({bg: "sitebg", path: path, main: <ResetPassword overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <AuthSide/>, siteContentPadding: true});
        } else if (path.startsWith("/email-receive")) {
            this.setState({bg: "sitebg", path: path, main: <EmailReceive overlay={this.overlay} match={this.props.match} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        } else if (path.startsWith("/notfound")) {
            this.setState({path: path, main: <NotFound overlay={this.overlay} location={this.props.location} history={this.props.history}/>, menuContent: <EmptySide/>, siteContentPadding: true});
        }
        if (this.ref.current !== undefined && this.ref.current !== null) {
            this.ref.current.scrollIntoView();
        }
    }

    toggleMenu () {
        let menu = (this.state.menu === true) ? false : true;
        Cache.setPreference(Cache.preference.menu, menu);
        this.setState({menu: menu});
    }

    toggleMobileMenu () {
        this.setState({mobileMenu: this.state.mobileMenu === true ? false : true});
    }

    mainClick () {
        if (this.state.sideOverlay === true) {
            this.setState({sideOverlay: false, sideOverlayContent: undefined}, this.cancelPath);
        }
        if (this.state.overlay === true) {
            this.setState({overlay: false});
        }
    }

    menuClick () {
        this.mainClick();
    }

    sideClick () {
        this.mainClick();
    }

    cancelPath () {
        let path = window.location.pathname;
        if (path.startsWith("/lists") || path.startsWith("/manual")) {
            if (path.endsWith("/add")) {
                path = path.substring(0, path.indexOf("/add"));
                this.props.history.push(path);
            }   
            if (path.endsWith("/add-to-suite")) {
                path = path.substring(0, path.indexOf("/add-to-suite"));
                this.props.history.push(path);
            }
            if (path.endsWith("/edit-case")) {
                path = path.substring(0, path.indexOf("/edit-case"));
                this.props.history.push(path);
            }
        }
    }

    side (content, onClose) {
        const sideClosePreference = Cache.getPreference(Cache.preference.sideClose)
        if (content === undefined) {
            this.setState({sideContent: undefined, side: false});
        } else {
            this.setState({sideContent: content, side: (sideClosePreference ? false : true), sideOnClose: onClose});
        }        
    }

    sideToggle () {
        Cache.setPreference(Cache.preference.sideClose, this.state.side ? true: false)
        this.setState({side: this.state.side ? false : true})
    }

    sideOverlay (content) {
        if (content === undefined) {
            this.setState({sideOverlayContent: undefined, sideOverlay: false});
        } else {
            this.setState({sideOverlayContent: content, sideOverlay: true});
        }
    }

    overlay (content, ref, contentHeight, contentWidth) {
        this.setState({overlay: (content === undefined ? false : true), overlayContent: content, overlayRef: ref, overlayContentHeight: contentHeight, overlayContentWidth: contentWidth});
    }

    projectChange () {
        if (this.state.main !== undefined) {
            this.setState({
                menu: true, 
                mobileMenu: false,
    
                path: "",
    
                active: undefined, 
                
                main: undefined, 
                
                side: false, 
                sideContent: undefined,
                sideOnClose: undefined,
                
                sideOverlay: false, 
                sideOverlayContent: undefined,
                
                overlay: false,
                overlayContent: undefined,
                overlayRef: undefined
            }, () => this.main(true));
        }
    }

    messageOverlayDismiss () {
        this.setState({messageOverlay: false});
    }

    messageOverlayAction () {
        this.state.messageOverlayAction();
        this.setState({messageOverlay: false});
    }

    messageOverlay (type, action) {
        this.setState({messageOverlay: true, messageOverlayType: type, messageOverlayAction: action});
    }

    menuContent () {
        let trl = false
        if (this.props.trlOrg !== undefined) {
            trl = true
        }

        return <Menu 
                site={this.props.site}
                content={this.state.menuContent}
                active={this.state.active} 
                mobileMenu={this.state.mobileMenu === true ? true: false} 
                full={this.state.menu} 
                toggleMenu={this.toggleMenu} 
                toggleMobileMenu={this.toggleMobileMenu}
                overlay={this.overlay}
                messageOverlay={this.messageOverlay}
                projectChange={this.projectChange}
                resultsView={this.resultsView}
                supplementalView={this.supplementalView}
                history={this.props.history}
                trl={trl}
                trlOrg={this.props.trlOrg} 
                trlProject={this.props.trlProject} 
                trlTarget={this.props.trlTarget} 
                trlRun={this.props.trlRun} 
                trlCase={this.props.trlCase} 
                trlView={this.props.trlView}
                displayNotification={this.displayNotification}
            />
    }

    renderApp () {
        const sideClosePreference= Cache.getPreference(Cache.preference.sideClose)

        let layoutClass = "app-layout";
        if (sideClosePreference) {
            layoutClass = "app-layout-hide-side";
        }
        if (this.state.menu !== true && this.state.side !== true) {
            layoutClass = "app-layout-min-menu-hide-side";
        } else if (this.state.menu === true && this.state.side !== true) {
            layoutClass = "app-layout-hide-side";
        } else if (this.state.menu !== true && this.state.side === true) {
            layoutClass = "app-layout-min-menu";
            if (sideClosePreference) {
                layoutClass = "app-layout-hide-side";
            }
        }

        let main = this.state.main;
        let appSideOverlayClass = "app-side-overlay-hidden whitebg";
        let darkenClass = "";
        if (this.state.sideOverlay === true) {
            appSideOverlayClass = "app-side-overlay whitebg";
            darkenClass = "app-darken"
        }
        let appOverlayClass = "app-overlay-hidden whitebg";
        let appOverlayPosition = {left: 0, top: 0};
        if (this.state.overlay === true) {
            appOverlayClass = "app-overlay whitebg";
            if (this.state.overlayRef !== undefined) {
                if (this.state.overlayRef.current !== undefined && this.state.overlayRef.current !== null) {
                    let rect = this.state.overlayRef.current.getBoundingClientRect();
                    appOverlayPosition.left = rect.left;
                    appOverlayPosition.top = rect.bottom;
                    if (this.state.overlayContentHeight !== undefined) {
                        const bufferHeight = 60;
                        if (window.innerHeight - appOverlayPosition.top < this.state.overlayContentHeight + bufferHeight) {
                            appOverlayPosition.heightLimit = window.innerHeight - appOverlayPosition.top - bufferHeight;
                        }
                    }
                    let contentWidth = 175; // default assumption if none
                    if (this.state.overlayContentWidth !== undefined) {
                        contentWidth = this.state.overlayContentWidth;
                    }
                    if (((rect.left + contentWidth) > window.innerWidth) || 
                        ((rect.left + contentWidth) > document.documentElement.clientWidth - 100)) {
                        let adjustment1 = (rect.left + contentWidth) - window.innerWidth;
                        let adjustment2 = (rect.left + contentWidth) - document.documentElement.clientWidth;
                        let adjustment = adjustment1;
                        if (adjustment2 > adjustment1) {
                            adjustment = adjustment2;
                        }
                        appOverlayPosition.left = rect.left - (adjustment + contentWidth);
                    }
                    appOverlayPosition.widthLimit = window.innerWidth - 40;
                }
            }
        }

        const menu = this.menuContent()
        return (
            <div>
                <Helmet>
                    <title>Tesults - Bring clarity to test results data</title>
                    <meta name="description" content="Tesults helps team manage test results data, test cases and reporting. Release high quality builds faster and reduce risk."/>
                </Helmet>
                <div className={layoutClass}>
                    <div className="app-menu" onClick={this.menuClick}>
                        {this.props.site === true ? <div></div>: menu}
                    </div>
                    <div className={"app-main " + darkenClass} onClick={this.mainClick}>
                        {this.props.site === true ? 
                            <div className="whitebg" ref={this.ref}>
                                {layout === "site-auth" ? <div></div> : <SiteHeader overlay={this.overlay} path={this.state.path} location={this.props.location}/>}
                                <div className={"whitebg " + ((this.state.path === "/" || this.state.path.startsWith("/ref/")) ? "" : (layout === "site-auth" ? "auth-main" : "site-main"))}>
                                    {this.state.main}
                                    {layout === "site-auth" ? <div></div> : <SiteFooter bgcolor="whitebg" path={this.state.path}/>}
                                </div>
                            </div>
                        : this.state.main
                        }
                    </div>
                    <SideToggle
                        side={this.state.side}
                        sideOverlay={this.state.sideOverlay}
                        sideToggle={this.sideToggle}
                        sideClosePreference={sideClosePreference}
                    />
                    <div className="app-side" key={"side-" + layoutClass} onClick={this.sideClick}>
                        <Side open={true} side={this.side} content={this.state.sideContent} onClose={this.state.sideOnClose}/>
                    </div>
                </div>
                <div className={appSideOverlayClass  + " whitebg"}>
                        {this.state.sideOverlayContent}                        
                </div>
                <div className={appOverlayClass} style={
                        {
                            "left": appOverlayPosition.left + "px",
                            "top": appOverlayPosition.top + "px"
                        }
                    }
                >
                    <Overlay content={this.state.overlayContent} heightLimit={appOverlayPosition.heightLimit} widthLimit={appOverlayPosition.widthLimit}/>
                </div>
                <MessageOverlay 
                    display={this.state.messageOverlay}
                    full={this.state.menu} 
                    mobileMenu={this.state.mobileMenu === true ? true: false} 
                    type={this.state.messageOverlayType}
                    dismiss={this.messageOverlayDismiss}
                    action={this.messageOverlayAction}
                />
                <Notification menu={this.state.menu} icon={this.state.notificationIcon} text={this.state.notificationText}/>
            </div>
        );
    }

    renderSite () {
        const menu = this.menuContent()
        return (
            <div>
                <Helmet>
                    <title>Tesults - Bring clarity to test results data</title>
                    <meta name="description" content="Tesults helps team manage test results data, test cases and reporting. Release high quality builds faster and reduce risk."/>
                </Helmet>
                <div className="site-layout">
                    <SiteHeader overlay={this.overlay} path={this.state.path} location={this.props.location}/>
                    <div className="site-content-layout">
                        <div className="site-menu" onClick={this.menuClick}>
                            {menu}
                        </div>
                        <div className="app-main" onClick={this.mainClick}>
                            <div className="whitebg" ref={this.ref}>
                                <div className="whitebg site-main">
                                    <div>{this.state.main}</div>
                                </div>
                                <SiteFooter nofirstpillar={true} bgcolor="whitebg" path={this.state.path}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSiteAuth () {
        const menu = this.menuContent()
        return (
            <div>
                <Helmet>
                    <title>Tesults - Bring clarity to test results data</title>
                    <meta name="description" content="Tesults helps team manage test results data, test cases and reporting. Release high quality builds faster and reduce risk."/>
                </Helmet>
                <div className="site-layout-auth">
                    <div className="auth-menu" onClick={this.menuClick}>
                        {menu}
                    </div>
                    <div className="app-main" onClick={this.mainClick}>
                        {this.props.site === true ? 
                            <div className="whitebg" ref={this.ref}>
                                <div className="whitebg auth-main">
                                    {this.state.main}
                                </div>
                            </div>
                        : this.state.main
                        }
                    </div>
                </div>
            </div>
        );
    }

    renderSiteIndex () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Bring clarity to test results data</title>
                    <meta name="description" content="Tesults helps team manage test results data, test cases and reporting. Release high quality builds faster and reduce risk."/>
                </Helmet>
                <div className="site-layout">
                    <div className="app-main" onClick={this.mainClick}>
                        <div className="whitebg" ref={this.ref}>
                            <SiteHeader overlay={this.overlay} path={this.state.path} location={this.props.location}/>
                            <div className="whitebg">
                                {this.state.main}
                            </div>
                            <SiteFooter bgcolor="whitebg" path={this.state.path}/>
                        </div>            
                    </div>
                </div>
            </div>
        );
    }

    render () {
        if (this.props.site === true)  {
            if (this.state.path === "/" || this.state.path.startsWith("/ref/")) {
                return this.renderSiteIndex()
            } else if (this.state.path === "/register" || 
                        this.state.path.startsWith("/inviteregister") || 
                        this.state.path.startsWith("/login") || 
                        this.state.path.startsWith("/forgotpassword") ||
                        this.state.path.startsWith("/resetpassword")
                        ) {
                return this.renderSiteAuth()
            } else {
                return this.renderSite()
            }
        } else {
            return this.renderApp()
        }
    }
};

export default App;