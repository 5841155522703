/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import CaseList from './CaseList';
import Loading from './Loading';
import ResultsHeader4 from './ResultsHeader4';
import ResultsHeaderGenric from './ResultsHeaderGeneric';
import SelectSide from './SelectSide';
import Cache from './Cache';

class TasksMain extends Component {
    constructor () {
        super();
        this.state = {taskChanged: false};
        this.caseHidden = this.caseHidden.bind(this);
        this.taskChanged = this.taskChanged.bind(this);
    }

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    caseHidden() {
        if (this.state.taskChanged === true) {
            this.setState({taskChanged: false}, () => this.props.assignedTasks());
        }
    }

    taskChanged () {
        this.setState({taskChanged: true});
    }

    render () {
        if (this.props.loggedIn === false) {
            return (
                <div>
                    <br/>
                    <p><NavLink to="/login" className="nounderline neutral4">Login</NavLink> to view tasks.</p>
                    <p><NavLink to="/" className="nounderline neutral4">Return to Tesults.com</NavLink></p>
                </div>  
            );
        } else if (this.props.loading === true) {
            return (
                <Loading/>
            );
        } else if (this.props.cases === undefined) {
            return (
                <p>Unable to fetch tasks.</p>
            );
        } else if (this.props.cases === null) {
            return (
                <div>
                    <p>Unable to fetch tasks.</p>
                </div>
            );
        } else {
            let selectSide =  
                    <SelectSide
                        side={this.props.side}
                        sideOpen={this.props.sideOpen}
                        content={this.props.sideContent}
                        image={<img style={{"marginTop":"4px"}} src="/img/chevron.svg" width="24" height="24" alt=""/>}
                        label={<button className="btn-select">Task selection options</button>}
                        /*onClick={this.props.multiEdit}*/
                        /*onClose={this.props.multiEdit}*/
                    />
                
            if (this.props.cases.length === 0) {
                return (
                    <div>
                        <div>
                            <ResultsHeaderGenric title="Tasks"/>
                        </div>
                        <div className="app-main-margin">
                            <div style={{"display":"flex"}}>
                                <div style={{ "marginLeft":"auto"}} className="mb-3">
                                    {selectSide}
                                </div>
                            </div>
                            <p className="neutral4">All tasks complete. New tasks are created when test failures are assigned to a team member.</p>
                        </div>
                    </div>
                );
            } else {
                // Separate case lists for each assignee
                let casesByAssignee = {};
                this.props.cases.forEach(function (c) {
                    if (casesByAssignee[c.task.assignee] === undefined) {
                        casesByAssignee[c.task.assignee] = [c];
                    } else {
                        casesByAssignee[c.task.assignee].push(c);
                    }
                });

                let membersDict = {};
                this.props.members.forEach(function (m) {
                    membersDict[m.id] = m;
                });

                let display = [];
                Object.keys(casesByAssignee).forEach(function (assignee) {
                    let member = membersDict[assignee];
                    let src = "/img/tasks-128.png";
                    if (member.aext !== undefined) {
                        src = '/user/avatar/pdla/' + member.id + '/' + member.aext;
                    }
                    let image = <img alt="" src={src} width="45" height="45" className="circle45 img45 noborder"/>
                    let taskCaseListHeader = 
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div>
                                {image}
                            </div>
                            <div className="neutral1" key={display.length}>
                                &nbsp;&nbsp;{member.firstName + " " + member.lastName}
                            </div>
                        </div>
                    display.push(
                        <CaseList
                            key={display.length}
                            modalId={"myModal" + display.length}
                            context="tasks" 
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            caseHidden={this.caseHidden} 
                            taskChanged={this.taskChanged}
                            projects={this.props.projects} 
                            projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                            targets={this.props.targets}
                            targetIndex={this.props.targetIndex}
                            cases={casesByAssignee[assignee]}
                            taskCaseListHeader={taskCaseListHeader}
                            rawResultMaps={this.props.rawResultMaps}
                        />
                    );
                    display.push(<span key={display.length}><br/><br/></span>)
                }.bind(this));

                return (
                    <div>
                        <ResultsHeader4/>
                        <div className="app-main-margin">
                            {selectSide}
                            {display}
                        </div>
                    </div>
                );
            }
        }
    }
}

export default TasksMain;