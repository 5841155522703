import React, { Component } from 'react';
const moment = require('moment');

class TimeFormatted extends Component {
    static timeformatted (dtString, includeYear) {
        let dt = new Date(parseInt(dtString, 10));
        let dt_date = dt.getDate();
        let dt_month = dt.getMonth();
        let dt_day = dt.getDay();
        let dt_hours = dt.getHours();
        let dt_minutes = dt.getMinutes();
        let dt_seconds = dt.getSeconds();

        let dt_formatted = [];

        if (includeYear !== true) {
            if (dt_day === 0) {
                dt_formatted.push("Sun");
            } else if (dt_day === 1) {
                dt_formatted.push("Mon");
            } else if (dt_day === 2) {
                dt_formatted.push("Tue");
            } else if (dt_day === 3) {
                dt_formatted.push("Wed");
            } else if (dt_day === 4) {
                dt_formatted.push("Thu");
            } else if (dt_day === 5) {
                dt_formatted.push("Fri");
            } else if (dt_day === 6) {
                dt_formatted.push("Sat");
            }
            dt_formatted.push(" ");
        }

        dt_formatted.push(dt_date);
        dt_formatted.push(" ");

        if (dt_month === 0) {
            dt_formatted.push("Jan");
        } else if (dt_month === 1) {
            dt_formatted.push("Feb");
        } else if (dt_month === 2) {
            dt_formatted.push("Mar");
        } else if (dt_month === 3) {
            dt_formatted.push("Apr");
        } else if (dt_month === 4) {
            dt_formatted.push("May");
        } else if (dt_month === 5) {
            dt_formatted.push("Jun");
        } else if (dt_month === 6) {
            dt_formatted.push("Jul");
        } else if (dt_month === 7) {
            dt_formatted.push("Aug");
        } else if (dt_month === 8) {
            dt_formatted.push("Sep");
        } else if (dt_month === 9) {
            dt_formatted.push("Oct");
        } else if (dt_month === 10) {
            dt_formatted.push("Nov");
        } else if (dt_month === 11) {
            dt_formatted.push("Dec");
        }

        dt_formatted.push(" ");

        if (includeYear === true) {
            dt_formatted.push(TimeFormatted.year(dtString));
            dt_formatted.push(" ");
        }

        if (dt_hours === 0) {
            dt_formatted.push("12");
        } else if (dt_hours === 13) {
            dt_formatted.push("1");
        } else if (dt_hours === 14) {
            dt_formatted.push("2");
        } else if (dt_hours === 15) {
            dt_formatted.push("3");
        } else if (dt_hours === 16) {
            dt_formatted.push("4");
        } else if (dt_hours === 17) {
            dt_formatted.push("5");
        } else if (dt_hours === 18) {
            dt_formatted.push("6");
        } else if (dt_hours === 19) {
            dt_formatted.push("7");
        } else if (dt_hours === 20) {
            dt_formatted.push("8");
        } else if (dt_hours === 21) {
            dt_formatted.push("9");
        } else if (dt_hours === 22) {
            dt_formatted.push("10");
        } else if (dt_hours === 23) {
            dt_formatted.push("11");
        } else {
            dt_formatted.push(dt_hours);
        }

        dt_formatted.push(":");
        if (dt_minutes < 10) {
            dt_formatted.push("0" + dt_minutes.toString());
        } else {
            dt_formatted.push(dt_minutes);
        }
        dt_formatted.push(":");
        if (dt_seconds < 10) {
            dt_formatted.push("0" + dt_seconds.toString());
        } else {
            dt_formatted.push(dt_seconds);
        }

        if (dt_hours < 12) {
            dt_formatted.push("AM");
        } else {
            dt_formatted.push("PM");
        }

        return dt_formatted.join("");
    }

    static chartLabelFormatted (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        let dt_date = dt.getDate();
        let dt_year = dt.getFullYear();
        let dt_month = dt.getMonth();
        let dt_hours = dt.getHours();
        let dt_minutes = dt.getMinutes();

        let dt_formatted = [];

        dt_formatted.push(dt_date);
        dt_formatted.push(" ");

        if (dt_month === 0) {
            dt_formatted.push("Jan");
        } else if (dt_month === 1) {
            dt_formatted.push("Feb");
        } else if (dt_month === 2) {
            dt_formatted.push("Mar");
        } else if (dt_month === 3) {
            dt_formatted.push("Apr");
        } else if (dt_month === 4) {
            dt_formatted.push("May");
        } else if (dt_month === 5) {
            dt_formatted.push("Jun");
        } else if (dt_month === 6) {
            dt_formatted.push("Jul");
        } else if (dt_month === 7) {
            dt_formatted.push("Aug");
        } else if (dt_month === 8) {
            dt_formatted.push("Sep");
        } else if (dt_month === 9) {
            dt_formatted.push("Oct");
        } else if (dt_month === 10) {
            dt_formatted.push("Nov");
        } else if (dt_month === 11) {
            dt_formatted.push("Dec");
        }

        dt_formatted.push(" ");
        dt_formatted.push(dt_year);

        dt_formatted.push(" ");

        if (dt_hours === 0) {
            dt_formatted.push("12");
        } else if (dt_hours === 13) {
            dt_formatted.push("1");
        } else if (dt_hours === 14) {
            dt_formatted.push("2");
        } else if (dt_hours === 15) {
            dt_formatted.push("3");
        } else if (dt_hours === 16) {
            dt_formatted.push("4");
        } else if (dt_hours === 17) {
            dt_formatted.push("5");
        } else if (dt_hours === 18) {
            dt_formatted.push("6");
        } else if (dt_hours === 19) {
            dt_formatted.push("7");
        } else if (dt_hours === 20) {
            dt_formatted.push("8");
        } else if (dt_hours === 21) {
            dt_formatted.push("9");
        } else if (dt_hours === 22) {
            dt_formatted.push("10");
        } else if (dt_hours === 23) {
            dt_formatted.push("11");
        } else {
            dt_formatted.push(dt_hours);
        }

        dt_formatted.push(":");
        if (dt_minutes < 10) {
            dt_formatted.push("0" + dt_minutes.toString());
        } else {
            dt_formatted.push(dt_minutes);
        }

        if (dt_hours < 12) {
            dt_formatted.push("AM");
        } else {
            dt_formatted.push("PM");
        }

        return dt_formatted.join("");
    }

    static dateformatted (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        let dt_date = dt.getDate();
        let dt_month = dt.getMonth();
        let dt_year = dt.getFullYear();

        let dt_formatted = [];

        dt_formatted.push(dt_date);
        dt_formatted.push(" ");

        if (dt_month === 0) {
            dt_formatted.push("Jan");
        } else if (dt_month === 1) {
            dt_formatted.push("Feb");
        } else if (dt_month === 2) {
            dt_formatted.push("Mar");
        } else if (dt_month === 3) {
            dt_formatted.push("Apr");
        } else if (dt_month === 4) {
            dt_formatted.push("May");
        } else if (dt_month === 5) {
            dt_formatted.push("Jun");
        } else if (dt_month === 6) {
            dt_formatted.push("Jul");
        } else if (dt_month === 7) {
            dt_formatted.push("Aug");
        } else if (dt_month === 8) {
            dt_formatted.push("Sep");
        } else if (dt_month === 9) {
            dt_formatted.push("Oct");
        } else if (dt_month === 10) {
            dt_formatted.push("Nov");
        } else if (dt_month === 11) {
            dt_formatted.push("Dec");
        }

        dt_formatted.push(" ");
        dt_formatted.push(dt_year);

        return dt_formatted.join("");
    }

    static timeOnlyFormatted (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        let dt_hours = dt.getHours();
        let dt_minutes = dt.getMinutes();
        let dt_seconds = dt.getSeconds();

        let dt_formatted = [];

        if (dt_hours === 0) {
            dt_formatted.push("12");
        } else if (dt_hours === 13) {
            dt_formatted.push("1");
        } else if (dt_hours === 14) {
            dt_formatted.push("2");
        } else if (dt_hours === 15) {
            dt_formatted.push("3");
        } else if (dt_hours === 16) {
            dt_formatted.push("4");
        } else if (dt_hours === 17) {
            dt_formatted.push("5");
        } else if (dt_hours === 18) {
            dt_formatted.push("6");
        } else if (dt_hours === 19) {
            dt_formatted.push("7");
        } else if (dt_hours === 20) {
            dt_formatted.push("8");
        } else if (dt_hours === 21) {
            dt_formatted.push("9");
        } else if (dt_hours === 22) {
            dt_formatted.push("10");
        } else if (dt_hours === 23) {
            dt_formatted.push("11");
        } else {
            dt_formatted.push(dt_hours);
        }

        dt_formatted.push(":");
        if (dt_minutes < 10) {
            dt_formatted.push("0" + dt_minutes.toString());
        } else {
            dt_formatted.push(dt_minutes);
        }
        dt_formatted.push(":");
        if (dt_seconds < 10) {
            dt_formatted.push("0" + dt_seconds.toString());
        } else {
            dt_formatted.push(dt_seconds);
        }

        if (dt_hours < 12) {
            dt_formatted.push("AM");
        } else {
            dt_formatted.push("PM");
        }

        return dt_formatted.join("");
    }

    static monthformatted (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        let dt_month = dt.getMonth();
        let dt_year = dt.getFullYear();

        let dt_formatted = [];

        if (dt_month === 0) {
            dt_formatted.push("Jan");
        } else if (dt_month === 1) {
            dt_formatted.push("Feb");
        } else if (dt_month === 2) {
            dt_formatted.push("Mar");
        } else if (dt_month === 3) {
            dt_formatted.push("Apr");
        } else if (dt_month === 4) {
            dt_formatted.push("May");
        } else if (dt_month === 5) {
            dt_formatted.push("Jun");
        } else if (dt_month === 6) {
            dt_formatted.push("Jul");
        } else if (dt_month === 7) {
            dt_formatted.push("Aug");
        } else if (dt_month === 8) {
            dt_formatted.push("Sep");
        } else if (dt_month === 9) {
            dt_formatted.push("Oct");
        } else if (dt_month === 10) {
            dt_formatted.push("Nov");
        } else if (dt_month === 11) {
            dt_formatted.push("Dec");
        }

        dt_formatted.push(" ");
        dt_formatted.push(dt_year);

        return dt_formatted.join("");
    }

    static day (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        return dt.getDay();
    }

    static date (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        return dt.getDate();
    }

    static month (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        return dt.getMonth();
    }

    static year (dtString) {
        let dt = new Date(parseInt(dtString, 10));
        return dt.getFullYear();
    }

    static numberDaysInMonth (dtString) {
        let month = this.month(dtString);
        let year = this.year(dtString);
        return new Date(year, month + 1, 0).getDate();
    }

    static fromNowRun (dt) {
        let dtFormatted = TimeFormatted.timeformatted(dt);
        let fromNow = " - " + moment.unix(dt/1000.0).fromNow();
        return dtFormatted + fromNow;
    }

    render() {
        let fromNow = "";
        if (this.props.dt !== undefined) {
            let dt = TimeFormatted.timeformatted(this.props.dt, this.props.includeYear);
            if (this.props.fromNow === true) {
                fromNow = " - " + moment.unix(this.props.dt/1000.0).fromNow();
            }
            return (
                <span>{dt}{fromNow}</span>
            );
        } else if (this.props.dd !== undefined) {
            let dd = TimeFormatted.dateformatted(this.props.dd);
            if (this.props.fromNow === true) {
                fromNow = " - " + moment.unix(this.props.dt/1000.0).fromNow();
            }
            return (
                <span>{dd}{fromNow}</span>
            );
        } else {
            return (
                <span></span>
            );
        }
    }
}

export default TimeFormatted;