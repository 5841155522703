/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsManualTestData from './DocsManualTestData';
import Notice from './Notice';

class DocsLists extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Test Lists Guide</title>
                    <meta name="description" content="Learn how to use Tesults Lists for effective test case management."/>
                </Helmet>
                <div className='docs-layout-side-column'>
                    <div>
                        <iframe className='youtube' src="https://www.youtube.com/embed/5xgtxZyResQ?si=e7qBOxgXvkZK5hLe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <p>Test lists are at the core of test case management. Create test cases and test suites and store them as test lists. Test lists provide a way to save grouped test suites and cases for manual testing. Create as many test lists as you want.</p>
                        <p>Test lists can be imported into manual test runs. Test lists save testers time by removing the need for repeated manual data entry, a tester can simply modify the result outcome only and upload any appropriate new files as they work their way through a test list.</p>
                        <p>Lists are stored permanently and are never deleted unless a team member intentionally deletes a list or the project is deleted.</p>
                        
                        <a className="site-link-neutral2" href="# " name="creating-lists">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a> 
                        <h2>Creating lists</h2>
                        <p>Access lists by going to the results view page and clicking 'lists' from the side bar. Only team members assigned a <b>moderator (level 2)</b> role or above can upload manual test results.</p>
                        <p>If it is the first time anyone from the project is accessing lists there will be no lists:</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-1.png"/>*/}
                        <p>Click the 'Create new list' button to create a new test list.</p>
                        <p></p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-2.png"/>*/}
                        <p>Select a name for the new test list, this is the name that you will later use to look up the test list whenever you want to edit it or import the list into a test run.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-3.png"/>*/}
                        <p>The new list will now appear in the dropdown list under 'Selected list', however it will not contain any test cases yet.</p>

                        
                        <a className="site-link-neutral2" href="# " name="adding-cases-manually">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Adding cases manually</h2>                
                        <p>Ensure the list that you want to modify is selected. Click the 'Add case' button.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-4.png"/>*/}
                        <p>When adding test cases manually you can specify the following for each test case:</p>
                        <DocsManualTestData/>
                        <p>Click the 'Save case' button to add the case to the list.</p>
                        
                        

                        <a className="site-link-neutral2" href="# " name="importing-cases-from-targets">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Importing cases from targets</h2>
                        <p>Another way to add test cases is to import cases from the latest results from any of the project targets. Ensure the list you want to modify is selected.</p>
                        <p>Click the 'Import from target' button.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-6.png"/>*/}
                        <p>Select a project target from the dropdown list. The latest results will be displayed. Click 'Import' to import the tests to the list.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-5.png"/>*/}

                        

                        <a className="site-link-neutral2" href="# " name="importing-cases-from-csv-files">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Importing test cases from csv files</h2>
                        <p>Another way to add test cases is to import them from a csv file. This can be a useful way to add test cases if you already have test cases in a spreadsheet or another system.</p>
                        <p>Click the 'Import from CSV' button. </p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/lists-7.png"/>*/}
                        <p>Click the 'Choose file' button and to select a csv file. Test cases will be extracted and displayed. Click 'Confirm import' to complete import to the selected list.</p>
                        <p>You must ensure the csv file is formatted correctly.</p>
                        
                        <div className='mt-5 mb-5'>
                            <Notice type="information" content={
                            <div>
                                <p>The first row must be a header row with the field names:</p>
                                <ul>
                                    <ol><b>name</b></ol>
                                    <ol><b>result</b></ol>
                                    <ol>suite</ol>
                                    <ol>desc</ol>
                                    <ol>reason</ol>
                                    <ol>_Custom</ol>
                                </ul>
                                <p>The name and result fields are required.</p>
                                <p>Result must be one of: [pass, fail, unknown] and is used to assign a default value to a test case.</p>
                                <p>The suite field is useful for grouping test cases into test suites, desc is a description of the test case, and reason is a failure reason. You can also have any number of custom fields, these fields must start with an underscore. For example: _customField</p>
                            </div>}/>
                        </div>
                    
                        <Notice type="information" content={
                            <div>
                                <p>If you have any problems importing from a csv file please contact help@tesults.com and we will be happy to assist you.</p>
                            </div>}/>
                        
                        <p>Importing test cases with CSV files makes it easy to migrate to Tesults if you are coming from a test case management system like TestRail. Simply export your test cases from the other system, and then change the headers in the CSV file to match to what Tesults requires. For TestRail specifically, in the exported CSV from TestRail, change the 'Title' header to 'name', change 'Section' to 'suite', change 'Steps' or 'Preconditions' to 'desc', add a new column called 'result' and set every row to 'pass' and finally add an underscore (_) prefix to every other column so that all other fields are imported as custom fields. Then the updated CSV file can be imported into Tesults from the List interface. Importing from TestRail to Tesults Lists can take just a few minutes.</p>

                        <a className="site-link-neutral2" href="# " name="editing-cases">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Editing cases</h2>
                        <p>Edit cases by clicking on them to select them and then clicking the 'Edit' button. You can also choose to remove a case from the test list by clicking the 'Delete' button.</p>
                        

                        <a className="site-link-neutral2" href="# " name="exporting-lists">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Exporting lists</h2>
                        <p>Click the 'Export to CSV' button to export the selected list as a csv file. This is useful if you want to get test cases out of Tesults for use elsewhere.</p>
                        

                        <a className="site-link-neutral2" href="# " name="using-lists">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Using lists</h2>
                        <p>Test lists can be imported into <NavLink target="_blank" to="/docs/manual">manual test runs.</NavLink></p>
                        <p>By doing this, testers save time because the test plan is clearly laid out and very little manual entry is required. Tests can simply be marked pass or fail (with a failure reason) and necessary files can be added ad hoc.</p>
                        

                        <a className="site-link-neutral2" href="# " name="deleting-lists">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Deleting lists</h2>
                        <p>Click the 'Delete list' button to delete a test list. There is usually no reason to delete a test list. You can have as many test lists as you want.</p>
                    </div>
                    <div>
                        <div>On this page</div>
                        <a className="site-link-primary2 no-break" href="#test-lists">Test lists</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#creating-lists">Creating lists</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#adding-cases-manually">Adding cases manually</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#importing-cases-from-targets">Importing cases from targets</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#importing-cases-from-csv-files">Importing cases from csv files</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#editing-cases">Editing cases</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#exporting-lists">Exporting lists</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#using-lists">Using lists</a>
                        <br/>
                        <a className="site-link-primary2 no-break" href="#deleting-lists">Deleting lists</a>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsLists;