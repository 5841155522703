/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';

class DocsSelenium extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Selenium test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Selenium test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>Selenium (<a href="https://www.seleniumhq.org/" className="footerLink">https://www.seleniumhq.org/</a>) is browser automation software.</p>
            <p>Selenium can be used to automate testing of web applications. Selenium is not a test framework. Selenium is used within automated test code however test fail or pass condition outcomes are determined by the test author and recorded by the test framework. You can report test results for tests that use Selenium with Tesults but how this is done is primarily dependent on your test framework.</p>
            <p>If you want to report results with Tesults for tests that use Selenium we suggest looking at the links on this page to either the test framework documentation for your test framework or the API Library documents for the programming language your tests are written in.</p>
        </div>
        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                integration="selenium"
            />
        );
    }
}

export default DocsSelenium;