/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Index.css';
import Analytics from './Analytics';
import Box from './Box';
import Request from './Request';

class Index extends Component {
    constructor() {
        super();
        Analytics.event("Home");
        this.state = {view: "results"};
        this.refHandler = this.refHandler.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.refHandler();   
    }

    refHandler () {
        if (this.props.match.params.ref !== undefined) {
            Request.post("/affiliate-visit", {ref: this.props.match.params.ref}, (err, data) => {});
        }
    }

    render() {
        let img = <img src="/img/index/index-1.png" width="1024" className="solidborder standardborder whiteborder width100" alt=""/>
        if (this.state.view === "supplemental") {
            img = <img src="/img/index/index-3.png" width="1024" className="solidborder standardborder whiteborder width100" alt=""/>
        } else if (this.state.view === "status") {
            img = <img src="/img/index/index-2.png" width="1024" className="solidborder standardborder whiteborder width100" alt=""/>
        } else if (this.state.view === "detail") {
            img = <img src="/img/index/index-4.png" width="1024" className="solidborder standardborder whiteborder width100" alt=""/>
        }

        let helmet = 
            <Helmet>
                <title>Tesults - Test automation reporting and test case management for quality focused teams. Release high quality software every time.</title>
                <meta name="description" content="Consolidated test reporting at its best. Powerful analysis capabilities, web-based dashboard, API and notifications make test automation and manual QA review easy."/>
            </Helmet>

        let content = 
        <div>
        <div style={{/*"backgroundColor":"#002483"*/}}>
            <div className="index-header-flex index-margin" style={{"alignItems":"center", "paddingTop":"40px", "paddingBottom":"100px"}}>
                <div style={{"flex": "1", "minWidth":"300px", "maxWidth":"500px"}}>
                    <br/><br/>
                    <span className="headerlookalike mb-4 bold font53" style={{"fontWeight":"800"}}>Release high quality software every time</span>
                    <h1 className="mb-4 bold neutral3" style={{"fontWeight":"800"}}>Test automation reporting and test case management for quality focused tech teams</h1>
                    <p className="mb-4 font18">Consolidated test reporting at its best. Powerful analysis capabilities, web-based dashboard, API and notifications make test automation and manual QA review easy.</p>
                    <NavLink to="/register" className="nounderline"><button type="button" className="btn-confirm-index mb-3 mr-3"><span className="bold">Try for free</span></button></NavLink>
                    <NavLink to="/docs" className="nounderline mb-3"><button type="button" className="btn-cancel-index"><span className="bold">Documentation</span></button></NavLink>
                    <p className='font13 neutral4 mt-2'>No time limit on free plan</p>
                    <div className='neutral8bg p-3 roundestborder font20 neutral4 flex-row'>
                        <div className='mr-3' style={{"marginTop":"4px"}}>
                            <img src="/img/time.svg" width="24" height="24" alt="time"/>
                        </div>
                        <div>
                            <i>5 minute setup for popular test frameworks</i>
                        </div>
                    </div>
                </div>
                <div style={{"flex": "1"}} className="mobile-hide-cancel">
                    {/*<img className="index-header-image" src="https://www.tesults.com/files/docs/results-2.png" alt="tesults"/>*/}
                    {/*<img src="/img/Illustrations_Tesults_006Artboard-1.png" style={{"maxHeight": "459px"}} alt=""/>*/}
                    <img src="https://www.tesults.com/files/art/2021-05-24/tesults-index-art.png" style={{"minWidth": "400px", "maxWidth":"100%"}} alt=""/>
                </div>
            </div>
        </div>

        <div className="whitebg">
            <div className='font13 neutral4 index-margin' style={{"display":"flex", "alignItems":"center"}}>
                <div className='mb-3' style={{"flex":"1", "textAlign":"center"}}>
                    <h3><span className="primary4">Tesults</span> <i>is</i> <span className="primary4">tes</span>t res<span className="primary4">ults</span>. A test automation reporting and test case management application. Essential for effective test automation and manual QA.</h3>
                    Used by:
                </div>
            </div>
            <div>
                <div className="mb-5 index-flex index-margin" style={{"alignItems":"center"}}>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-nike.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-hulu.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-intel.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-hpe.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-fujitsu.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-siemens.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-target.svg"/>
                    </div>
                    <div className='mb-3' style={{"flex":"1"}}>
                        <img src="/img/index/third-party/logo-ubisoft.svg"/>
                    </div>
                </div>
            </div>

            <div className='indexFeatureBg'>
            <div className="mb-5 pb-5 pt-5 index-margin">
                <div className='index-flex' style={{"alignItems":"center"}}>
                    <div style={{"flex":"1"}}>
                        <div style={{"display":"flex"}}>
                            <div className='mobile-hide' style={{"flex":"1"}}></div>
                            <div style={{"flex":"3"}}>
                                <h2>Consolidated test reporting and case management for engineering teams</h2>
                                <p>Bring clarity to the automated tests running across your systems. Store test results and related data including logs, screenshots and files.</p>
                                <p>Know what's being tested. Improve stakeholder visibility. Easy to maintain, secure and scalable.</p>
                                <div className='text-center'>
                                    <div className='mt-3'>
                                        <img className="mr-4" src="/img/tesults-logo-pass-128.png" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt="pass"></img>
                                        <img className="mr-4" src="/img/tesults-logo-fail-128.png" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt="fail"></img>
                                    </div>
                                    <div className='mt-3'>
                                        <img className="mr-4" src="/img/index/vikram-crop.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt="vikram"></img>
                                        <img className="mr-4" src="/img/index/steve-crop.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt="steve"></img>
                                        <img className="mr-4" src="/img/index/kate-crop.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt="kate"></img>
                                    </div>
                                </div>
                            </div>
                            <div className='mobile-hide' style={{"flex":"1"}}></div>
                        </div>
                    </div>
                    <div style={{"flex":"1"}}>
                        <img src="/img/index/features/feature1.png" className='width100'/>
                    </div>
                </div>
            </div>

            <div className="mb-5 pb-5 pt-5 index-margin">
                <div className='index-flex' style={{"display":"flex", "alignItems":"center"}}>
                    <div style={{"flex":"1"}}>
                        <img src="/img/index/features/feature2.png" className='width100'/>
                    </div>
                    <div style={{"flex":"1"}}>
                        <div style={{"display":"flex"}}>
                            <div className='mobile-hide' style={{"flex":"1"}}></div>
                            <div style={{"flex":"3"}}>
                                <h2>Make informed release decisions using smart analysis</h2>
                                <p>Release with assurance and save time with automated regression analysis. Choose to see only what's changed between test runs. Quickly isolate the time an issue was introduced and relax knowing flaky tests are automatically flagged up.</p>
                                <p>Make deployment decisions and feedback failures from your continuous integration pipeline based on test results data using the Tesults API.</p>
                            </div>
                            <div className='mobile-hide' style={{"flex":"1"}}></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mb-5 pb-5 pt-5 index-margin">
                <div className='index-flex' style={{"display":"flex", "alignItems":"center"}}>
                    <div style={{"flex":"1"}}>
                        <div style={{"display":"flex"}}>
                            <div className='mobile-hide' style={{"flex":"1"}}></div>
                            <div style={{"flex":"3"}}>
                                <h2>Monitor mission critical systems and integrate with tools you already use</h2>
                                <p>Maintain uptime and monitor critical production services effectively by enabling notifications and alerts. Avoid notification fatigue by linking appropriate teams to failures for specific tests.</p>
                                <ul>
                                    <li>Push results to Slack, MS Teams, Mattermost</li>
                                    <li>Automate incidents on PagerDuty based on results</li>
                                    <li>Create Jira issues and automate status changes</li>
                                    <li>Utilize Github Actions to check results in your CI pipeline</li>
                                </ul>
                                <div style={{"alignItems":"center"}} className="index-flex">
                                    <img src="/img/github-mark.svg" alt="github-actions" height="40"/>
                                    <img src="/img/slack.svg" alt="slack" height="40"/>
                                    <img src="/img/msteams.svg" alt="msteams" height="40"/>
                                    <img src="/img/mattermost.png" alt="mattermost" height="22"/>
                                    <img src="/img/pagerduty.svg" alt="pagerduty" height="70"/>
                                    <img src="/img/jira.svg" alt="jira" height="40"/>
                                </div>
                            </div>
                            <div className='mobile-hide' style={{"flex":"1"}}></div>
                        </div>
                    </div>
                    <div style={{"flex":"1"}}>
                        <img src="/img/index/features/feature3.png" className='width100'/>
                    </div>
                </div>
            </div>
            </div>

            <div className="mb-5 pb-5 pt-5 index-margin index-flex">
                <div style={{"flex":"1", "textAlign":"center"}}>
                    <h2 className='font32'>Get setup in minutes</h2>
                    <p className='bold'>Integrations for popular test frameworks</p>
                    <div>
                        <img src="/img/junit.png" alt="junit" height="40"/>
                        &nbsp;&nbsp;
                        <img src="/img/testng.png" alt="nunit" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/nunit.png" alt="testng" height="24"/>
                        &nbsp;&nbsp;
                        <img src="/img/pytest.png" alt="pytest" height="24"/>
                        &nbsp;&nbsp;
                        <img src="/img/robot.png" alt="robot" height="40"/>
                        &nbsp;&nbsp;
                        <img src="/img/mocha.svg" alt="mocha" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/jest.svg" alt="jest" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/testcafe.png" alt="testcafe" height="40"/>
                        &nbsp;&nbsp;
                        <img src="/img/cypress.png" alt="cypress" height="20"/>
                        &nbsp;&nbsp;
                        <img src="/img/webdriverio.svg" alt="webdriverio" height="20"/>
                        &nbsp;&nbsp;
                        <img src="/img/protractor.png" alt="protractor" height="20"/>
                        &nbsp;&nbsp;
                        <img src="/img/rspec.jpg" alt="rspec" height="40"/>
                        &nbsp;&nbsp;
                        <img src="/img/jasmine.svg" alt="jasmine" height="24"/>
                        &nbsp;&nbsp;
                        <img src="/img/playwright.svg" alt="playwright" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/postman.svg" alt="postman" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/codeceptjs.svg" alt="codeceptjs" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/nightwatch.svg" alt="nightwatch" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/waffle.svg" alt="waffle" height="32"/>
                    </div>
                    <p className='bold'>Libraries for popular languages, integrate with any test framework, including custom ones</p>
                    <div>
                        <img src="/img/java.svg" alt="java" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/csharp.svg" alt="c#" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/python.svg" alt="python" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/ruby.svg" alt="ruby" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/javascript.svg" alt="javascript" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/nodejs.svg" alt="nodejs" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/go.svg" alt="go" height="24"/>
                        &nbsp;&nbsp;
                        <img src="/img/kotlin.svg" alt="kotlin" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/php.svg" alt="php" height="32"/>
                        &nbsp;&nbsp;
                        <img src="/img/swift.svg" alt="swift" height="32"/>
                        &nbsp;&nbsp;
                    </div>
                </div>
            </div>

            <div className="mb-5 pb-5 pt-5 index-margin index-flex">
                <div className='mb-5' style={{"flex":"1"}}>
                    <p className='font24'><i>"Tesults is our dashboard of choice. We've rolled it out to the team."</i></p>
                    <img src="/img/index/testimonials/storyblocks.svg"  height="42px"/>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className='mr-3'>
                            <img src="/img/index/testimonials/ryan_kenney.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt=""></img>
                        </div>
                        <div>
                            <span className='bold'>Ryan Kenney</span>
                            <br/>
                            <span>Lead Software Engineer</span>
                        </div>
                    </div>
                </div>
                <div className='mb-5' style={{"flex":"1"}}>
                    <p className='font24'><i>"This is wonderful! The APIs and integrations are very useful for us."</i></p>
                    <img src="/img/index/testimonials/everyonesocial.svg" height="42px"/>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className='mr-3'>
                            <img src="/img/index/testimonials/aaron_roberts.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt=""></img>
                        </div>
                        <div>
                            <span className='bold'>Aaron Roberts</span>
                            <br/>
                            <span>Senior Software Engineer</span>
                        </div>
                    </div>
                </div>
                <div className='mb-5' style={{"flex":"1"}}>
                    <p className='font24'><i>“Brings the fruits of test automation to life.”</i></p>
                    <img src="/img/index/testimonials/roamdigital.svg" height="42px"/>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className='mr-3'>
                            <img src="/img/index/testimonials/alex_lazaris.jpg" width="64px" height="64px" style={{"borderRadius": "50%", "boxShadow": "inset 0 0 0 4px hsl(0, 0%, 0%)"}} alt=""></img>
                        </div>
                        <div>
                            <span className='bold'>Alexander Lazaris</span>
                            <br/>
                            <span>QA Manager</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5 index-margin index-flex">
                <div style={{"flex":"1", "textAlign":"center"}}>
                    <h2 className='font32'>Customer success feedback</h2>
                    <div className='index-flex neutral3'>
                        <div className='mr-5 mb-5 font24'>
                            <i>In one incident, prevented over a million dollar loss by averting a serious flaw from entering production.</i>
                        </div>
                        <div className='mr-5 mb-5 font24'>
                            <i>On one team, saved hundreds of hours of developer time with notifications of regressions for specific builds.</i>
                        </div>
                        <div className='mr-5 mb-5 font24'>
                            <i>On a single day, minimized earnings disruption of hundreds of thousands of dollars by being alerted to a production incident as a result of a test failure.</i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5 index-margin index-flex">
                <div style={{"flex":"1", "textAlign":"center"}}>
                    <div className='index-flex neutral3'>
                        <div className='mr-5 mb-5 font24'>
                            <i>Helps us with ISO 27001 compliance.</i>
                        </div>
                        <div className='mr-5 mb-5 font24'>
                            <i>Raised awareness of testing and quality.</i>
                        </div>
                        <div className='mr-5 mb-5 font24'>
                            <i>Would never go back to being without it. Was like flying blind.</i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="index-margin index-flex mt-5 mb-5 pt-5 pb-5">
                <div>
                    <img className='width100 img-scale ' src="https://www.tesults.com/files/docs/results-2.png" alt="tesults-results-interface"/>
                </div>
                <div>
                    <img className='width100 img-scale' src="https://www.tesults.com/files/docs/supplemental-1.png" alt="tesults-supplemental-interface"/>
                </div>
                <div>
                    <img className='width100 img-scale' src="https://www.tesults.com/files/docs/dashboard-1.png" alt="tesults-dashboard-interface"/>
                </div>
            </div>
            <div className="index-margin mt-5 mb-5 pt-5 pb-5">
                <div>
                    <img className='width100' src="https://www.tesults.com/files/infographics/tesults-impact-2023-01-03.png" alt="tesults-impact"/>
                </div>
            </div>

            <div className="pt-5 index-margin auto-width text-center neutral8bg">
                <div className='width100'>
                    <h1 className='primary4'>Introducing</h1>
                    <h5 className='neutral4'>New in 2025</h5>
                </div>
                <div className='width100 index-flex'>
                    <div className='flex-1 pr-5'>
                        <h2>Case Enhanced Analysis</h2>
                        <p className='neutral3'>Superior test case analysis. Detail and granularity like never before. Most failed test cases in a given period, individual test case breakdowns and more.</p>
                        <div className='flex-row pt-5 pb-5'>
                            <div className='font14 neutral4' style={{"flex": "1"}}>
                                <div className="circle32 accentc4bg accentc4border" alt="" style={{"marginTop":"-8px"}}/>
                            </div>
                            <div className='font14 neutral4' style={{"flex": "1"}}>
                                <div className="circle32 accenta4bg accenta4border" alt="" style={{"marginTop":"-8px"}}/>
                            </div>
                            <div className='font14 neutral4' style={{"flex": "1"}}>
                                <div className="circle32 accentb4bg accentb4border" alt="" style={{"marginTop":"-8px"}}/>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 pl-5'>
                        <h2>Tesults AI</h2>
                        <p className='neutral3'>AI enhancement to a range of existing and new Tesults features. Starting with AI generated test cases for manual test lists and more coming soon.</p>
                        <img className='pt-5 pb-5' width="32" height="32" alt="Tesults AI" src="/img/bolt.svg"/>
                    </div>
                </div>
            </div>
           

            <div className="pt-5 index-margin index-flex">
                <div style={{"flex":"1", "textAlign":"center"}}>
                    <h2 className='font32'>Frequently Asked Questions</h2>
                </div>
            </div>

            <div className="pt-5 index-margin index-flex">
                <div style={{"flex":"1"}}></div>
                <div style={{"flex":"2"}}>
                    <hr className='dividor-light'/>
                    <details>
                        <summary className='font18 bold mb-3' style={{"cursor":"pointer"}}> Do you provide features for manual QA?</summary>
                        <div>Yes. To complement automated tests, it's common for teams to have some manual test case scenarios especially for front-end applications. Your team can create manual test case scenarios and lists of tests within Tesults, or import and export test cases out via CSV files. You can also create 'test runs' to assign test cases to team members and check off completed test cases to track progress.</div>
                    </details>
                </div>
                <div style={{"flex":"1"}}></div>
            </div>

            <div className="index-margin index-flex">
                <div style={{"flex":"1"}}></div>
                <div style={{"flex":"2"}}>
                    <hr className='dividor-light'/>
                    <details>
                    <summary className='font18 bold mb-3' style={{"cursor":"pointer"}}> Do I need to create test cases in Tesults prior to submitting results?</summary>
                    <div>No. Other than signing up and creating a project, which takes seconds, you do not need to configure or define anything within Tesults to submit automated test results. Simply integrate and start pumping in your data.</div>
                    </details>
                </div>
                <div style={{"flex":"1"}}></div>
            </div>

            <div className="index-margin index-flex">
                <div style={{"flex":"1"}}></div>
                <div style={{"flex":"2"}}>
                    <hr className='dividor-light'/>
                    <details>
                    <summary className='font18 bold mb-3' style={{"cursor":"pointer"}}>There's no integration for the test framework I am using, can you help?</summary>
                    <div>We are always expanding test frameworks we support with new integrations and upgrading existing integrations. Get in contact and we'll prioritize creating an integration for the test framework you are using, often with a fast turnaround time. We also have lower-level (language) libraries available to create integrations yourself and this may be necessary if you are utilizing an internal or custom test framework.</div>
                    </details>
                </div>
                <div style={{"flex":"1"}}></div>
            </div>

            <div className="index-margin index-flex">
                <div style={{"flex":"1"}}></div>
                <div style={{"flex":"2"}}>
                    <hr className='dividor-light'/>
                    <details>
                        <summary className='font18 bold mb-3' style={{"cursor":"pointer"}}>How do I get test results data and saved test lists out of Tesults?</summary>
                        <div>Export test results data and test lists to CSV with a button click. For analyzing results data outside of the Tesults interface, consider using the Tesults API. The API is useful for fetching results data for use in continuous integration or deployment systems for environmental gating decisions.</div>
                    </details>
                </div>
                <div style={{"flex":"1"}}></div>
            </div>

            <div className="index-margin index-flex">
                <div style={{"flex":"1"}}></div>
                <div style={{"flex":"2"}}>
                    <hr className='dividor-light'/>
                    <details>
                        <summary className='font18 bold' style={{"cursor":"pointer"}}>Does Tesults support SSO for managing team members?</summary>
                        <div>Tesults supports SSO (SAML 2.0), Google OAuth, and includes a built-in team management system. You can choose to utilize any of these. Tesults does not limit SSO to Enterprise plans, all plans, including the Free plan support SSO.</div>
                    </details>
                </div>
                <div style={{"flex":"1"}}></div>
            </div>

            <div className="mb-5 pb-5 index-margin index-flex">
                <div style={{"flex":"1"}}></div>
                <div style={{"flex":"2"}}>
                    <hr className='dividor-light'/>
                    <details>
                        <summary className='font18 bold' style={{"cursor":"pointer"}}>How does pricing work?</summary>
                        <div>Try Tesults for free forever. When you are ready to upgrade Tesults proudly offers a 'Fair Billing Policy'. Tesults charges per active user only. No need for an IT administrator to manage seats, Tesults automatically stops billing for an inactive user. That means peace of mind for budget conscious teams and efficiency for larger teams. See <NavLink to="/docs/pricing" target="_blank" className="tr-link-primary4">pricing page</NavLink> for more details.</div>
                    </details>
                </div>
                <div style={{"flex":"1"}}></div>
            </div>

            <div className="index-flex primary8bg">
                <div style={{"flex":"1"}}>
                    <div style={{"alignItems":"center", "textAlign":"center"}} className="mt-5 mb-5 p-5 index-margin">
                        <div style={{"margin":"auto", "maxWidth":"400px"}}>
                            <h2 className="primary4 font40">Take quality seriously and delight customers</h2>
                            <br/>
                            <NavLink to="/register" className="nounderline"><button type="button" className="btn-confirm-index"><span className="bold">Try free now</span></button></NavLink>
                            <br/>
                            <p className='font13 primary4 mt-2'>No time limit on free plan</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        return (
            <div>
                {helmet}
                {content}
            </div>
        );
    }
}

export default Index;