/*global Cookies*/
import React, { Component } from 'react';

class TasksSide extends Component {
    constructor () {
        super();
        this.state = {tasksTargetCreated: undefined, tasksMemberId: undefined}
        this.targetChange = this.targetChange.bind(this);
        this.memberChange = this.memberChange.bind(this);
    }

    componentDidMount () {
        this.setState({tasksTargetCreated: this.props.tasksTargetCreated, tasksMemberId: this.props.tasksMemberId})
    }

    targetChange (e) {
        this.props.onTargetChange(e.target.value);
        this.setState({tasksTargetCreated: e.target.value});
    }

    memberChange (e) {
        this.props.onMemberChange(e.target.value);
        this.setState({tasksMemberId: e.target.value});
    }

    render () {
        let dropdowns = <span></span>
        if (this.props.loading === true) {
            return (
                <div>
                    {dropdowns}
                </div>
            );
        }
        
        const user = Cookies.getJSON("truser");
        if (user === undefined || user === null) {
            dropdowns = <p>Assign failing test cases to team members as tasks. Quickly understand who is looking into each failure. When a previously failing test case passes, the task is automatically completed.</p>
        }

        if (this.props.projects !== undefined && this.props.targets !== undefined && this.props.members !== undefined && user !== undefined && user !== null) {
            let projectOptions = [];
            let targetOptions = [];
            let memberOptions = [];
    
            this.props.projects.forEach(function (project) {
                projectOptions.push(<option key={project.id} value={project.id}>{project.name}</option>);
            });
    
            targetOptions.push(<option key={'all'} value={'all'}>{'-- All --'}</option>);
            this.props.targets.forEach(function (target) {
                targetOptions.push(<option key={target.created} value={target.created}>{target.name}</option>);
            });

            memberOptions.push(<option key={'all'} value={'all'}>{'-- All --'}</option>);
            this.props.members.forEach(function (member) {
                memberOptions.push(<option key={member.id} value={member.id}>{member.firstName + " " + member.lastName + " (" + member.email + ")"}</option>);
            });

            return (
                <div>
                    <div className="mb-4">
                        <div className="neutral4 mb-1">Target</div>
                        <select className="custom-select mb-3 width100" onChange={this.targetChange} value={this.state.tasksTargetCreated}>
                            {targetOptions}
                        </select>
                    </div>
                    <div>
                        <div className="neutral4 mb-1">Team member</div>
                        <select className="custom-select mb-3 width100" onChange={this.memberChange} value={this.state.tasksMemberId}>
                            {memberOptions}
                        </select>
                    </div>
                    <div className="mt-5 neutral3 font14">Tasks are failing test cases that are assigned to team members</div>
                </div>
            );
        }
        
        return <span></span>
        
    }
};

export default TasksSide;