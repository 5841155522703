/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsSecurity extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Security</title>
                    <meta name="description" content="Tesults takes privacy and security seriously. Learn more about Tesults security."/>
                </Helmet>
                <h2 className="">The importance of security and data storage</h2>
                <p>The Tesults <NavLink to="/docs/terms" target="_blank" className="neutral4">terms of service</NavLink> documentation includes details on security. Here are highlights we think are important you should know about:</p>
                <h2 className=" mt-5">Treating test data with the seriousness of production data</h2>
                <p>Although the Tesults service is generally used for storing test or fake data, security across the Tesults service including data storage and transmission is taken seriously and treated with the highest priority.</p>
                <h2 className=" mt-5">Data centers</h2>
                <p>The Tesults service stores data in data centers located within the Unites States on infrastructure provided by Amazon Web Services. Industry standard technical security measures and best practices are applied and implemented throughout the entire architecture of the service. These measures are reviewed periodically to keep security measures up-to-date. Stored data never leaves the United States based data centers unless your team makes requests for your data outside of the United States in which case data may be stored temporarily (cached) on CDNs (Content Delivery Networks) around the world as well as your browser. All communications to the Tesults service are encrypted by TLS, all communication is made using the HTTPS protocol end-to-end, including viewing data and uploads of results data. This includes all results APIs and language specific API libraries.</p>
                <h2 className=" mt-5">Encryption</h2>
                <p>Data is encrypted in transit as outlined above. All data related to authentication is encrypted at rest within Tesults databases. All payment related data is stored by Stripe, a PCI compliant third party payment processor certified to PCI Service Provider Level 1.</p>
                <h2 className=" mt-5">Two-factor authentication (2FA)</h2>
                <p>Two-factor authentication (2FA) is available and can be enabled for all Tesults users.</p>
                <h2 className=" mt-5">API protection</h2>
                <p>All internal and external APIs are protected through authentication.</p>
                <h2 className=" mt-5">Roles</h2>
                <p>Roles offer customized permission levels for team members added to Tesults projects. By default all added members other than the project owner are granted level 1 access, the least permissive role (the owner is the creator of the project unless ownership is transferred manually). We recommend promoting team members to higher level roles only as necessary, especially to level 4 (Officer) role where payment details can be edited (though even level 4 users are unable to view payment details so there is never a danger of payment card details being leaked, this sensitive data is never displayed or transmitted in any API fetch, only the last 4 digits of a card and the expiry date can ever be retrieved by authenticated level 4 role users).</p>
                <h2 className=" mt-5">Contact</h2>
                <p>Contact support at help@tesults.com if you have questions about data storage and security, your request will be routed to a member of staff who can provide detailed technical responses to specific requests.</p>
            </div>
        );
    }
}

export default DocsSecurity;