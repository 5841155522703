/*global */
import React, { Component } from 'react'
import {StripeProvider, Elements} from 'react-stripe-elements'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import ConfigOrgName from './ConfigOrgName'
import Privacy from './Privacy'
import Payment from './Payment';
import Confirmation from './Confirmation'

let apiKey;
if (process.env.NODE_ENV !== "production") {
  apiKey = "pk_test_9LEG3Rq029W4Z3S167A7cxVI"; // test
} else {
  apiKey = "pk_live_AszODbEm3Y8DT612VNWGScHj"; // live (production)
}

class ConfigPaymentDetails extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {confirmation: {success: undefined, failure: undefined}}
        this.project = this.project.bind(this)
        this.projectPlan = this.projectPlan.bind(this)
        this.projectOwner = this.projectOwner.bind(this)
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    projectPlan () {
        Analytics.event("ChangePlan");
        Request.get("/plan", {id: this.project().id}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to retrieve plan details, please contact support."});
            } else {
                this.props.history.replace({ pathname: "/config/project/plan"});
                this.setState({state: "plan", plan: data.plan, confirmation: {success: undefined, failure: undefined}}, () => this.projectOwner());
            }
        });
    }
    
    projectOwner () {
        Request.get("/projectOwner", {id: this.project().id}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to retrieve project owner."});
            } else {
                this.setState({owner: data.owner});
            }
        });
    }

    paymentCardUpdated () {
        this.props.history.replace({ pathname: "/config/project/payment-card-updated"});
        this.setState({state: "paymentCardUpdated", value: "", confirmation: {success: "Your payment card details have been updated. The new payment card will be used to process payment.", failure: undefined}});
    }

    render () {
        if (this.props.role < 4) {
            return (
                <div style={{"height":"100vh"}}>
                    <Notice type="information" content="You do not have the appropriate permissions to configure this option. Ask the project owner to assign the Officer (4) role to you if you require access."/>
                    <button type="button" className="btn btn-cancel mt-5 mb-5" onClick={this.props.cancel}>Back</button>
                </div>
            )
        }
        return (
            <div>
                <StripeProvider apiKey={apiKey}>
                    <Elements>
                        <Payment plan={this.state.plan} interval={this.state.interval} owner={this.state.owner} cancel={this.cancelPaymentEdit} complete={this.paymentCardUpdated} project={this.project()} type={"paymentEdit"}/>
                    </Elements>
                </StripeProvider>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigPaymentDetails