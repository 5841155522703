/*global Cookies*/
import React, { Component } from 'react';
import Request from './Request';
import Box from './Box';
import { NavLink } from 'react-router-dom';
import Notice from './Notice';

class Feedback extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {submitting: false, submitted: false, email: "", text: "", user: user, error: undefined}
        this.textChange = this.textChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    textChange(e) {
        this.setState({text: e.target.value});
    }

    emailChange(e) {
        this.setState({email: e.target.value});
    }

    submit (e) {
        e.preventDefault();
        this.setState({submitting: true, error: undefined});
        let data = {};
        if (this.state.user === undefined) {
            data.email = this.state.email;
        }
        data.type = "general";
        if (this.state.text !== "") {
            data.text = this.state.text;
        }
        Request.post("/setupAssistant", data, (err, data) => {
            if (err) {
                this.setState({submmited: false, submitting: false, error: "Unable to submit, please email help@tesults.com instead."});
            } else {
                this.setState({submitted: true, submitting: false});
            }
        });
    }

    render() {
        if (this.state.user === undefined) {
            return <Notice type="feedback" content={
                <div className='index-margin'>
                    <h2>Feedback & Help</h2>
                    Have feedback or questions? We want to hear from you. The Tesults team is always working to make Tesults better. Your feedback will be read and you will receive a response. <NavLink to="/contact" target="_blank" className="tr-link-primary4">Contact us</NavLink>
                </div>
            }/>
        }

        let textInput = <textarea style={{"resize":"none"}} onChange={this.textChange} className="width95 font16 p-3 setupAssistantInput roundborder" placeholder="Type your message here. A Tesults team member will respond by email." rows="5"></textarea>
        let error = <p className="primary1">{this.state.error}</p>
        if (this.state.error === undefined) {
            error = <span></span>
        }
        let submit = undefined;
        if (this.state.submitted === false) {
            if (this.state.user !== undefined) {
                submit = 
                    <form id="setupAssistant" className="text-left primary1 form-horizontal" onSubmit={this.submit}>
                        <button type="submit" className="btn-confirm mt-3 mb-3" disabled={this.state.submitting}>Send</button>
                        <span className="neutral4">{error}</span>
                    </form>
            } else {
                submit = 
                <form id="setupAssistant" className="text-left primary1 form-horizontal text-center" onSubmit={this.submit}>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <input type="email" className="tr-input" id="email" name="email" placeholder="Email" onChange={this.emailChange} required/>
                            <br/>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <button type="submit" className="btn-confirm mt-3 mb-3" disabled={this.state.submitting}>Send</button>
                        </div>
                    </div>
                    <span className="neutral4">{error}</span>
                </form>
            }
        } else {
            let submitEmail = this.state.email;
            if (this.state.user !== undefined) {
                submitEmail = this.state.user.email;
            }
            textInput = <span></span>
            submit = <span className="neutral1 mt-3">Thank you. One of our team members will get back to you soon at this email address: {submitEmail}</span>
        }

        let supportImg = "/img/support-4-reverse.png"
        
        return <Notice type="feedback" content = {
                    <div className='index-margin'>    
                            <h2>Feedback & Help</h2>
                            {
                                this.state.submitted === false ?
                                <p>We want to hear from you. The Tesults team is always working to make Tesults better. Your feedback and help requests will be read and you will receive a response.</p>
                                :
                                <p></p>
                            }
                            {textInput}
                            {submit}
                    </div>
        }/>
    }
}

export default Feedback