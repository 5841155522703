/*global*/
import React, { Component } from 'react';
import Analytics from './Analytics'
import Confirmation from './Confirmation'
import SelectOverlay from './SelectOverlay'
import CaseList from './CaseList'
import PassRate from './PassRate'
import {utilsRawResultMapMap, utilsIsPassResult, utilsIsFailResult} from './Utils'

// Diff rsp format:
// project: base_url/results/rsp/view/diff/project/{project id}
// targets: base_url/results/rsp/view/diff/project/{project id}/target1/{target1 created}/target2/{target2 created}
// runs: base_url/results/rsp/view/diff/project/{project id}/target1/{target1 created}/target2/{target}/run1/{run1 created}/run2/{run2 created}

class Diff extends Component {
    constructor (props) {
        super(props);
        this.state = {
            diff1: {
                counts: {
                    total: 0,
                    pass: 0,
                    fail: 0,
                    unknown: 0
                },
                passDiff: [],
                failDiff: [],
                unknownDiff: [],
                noDiff: [],
                buildCase: undefined
            }, 
            diff2: {
                counts: {
                    total: 0,
                    pass: 0,
                    fail: 0,
                    unknown: 0
                },
                passDiff: [],
                failDiff: [],
                unknownDiff: [],
                noDiff: [],
                buildCase: undefined
            },
            rspNotFoundDismissed: false
        }
        this.computeDiff = this.computeDiff.bind(this)
    }

    componentDidMount () {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.targetIndex1 !== prevProps.targetIndex1
            || this.props.targetIndex2 !== prevProps.targetIndex2
            || this.props.runIndex1 !== prevProps.runIndex1
            || this.props.runIndex2 !== prevProps.runIndex2) {
            this.computeDiff()
        } else {
            let cases1 = this.props.cases1
            let prevCases1 = prevProps.cases1
            let cases2 = this.props.cases2
            let prevCases2 = prevProps.cases2
            const isDiff = (casesA, casesB) => {
                if (casesA === undefined && casesB === undefined) {
                    return false
                } else if (casesA === undefined || casesB === undefined) {
                    return true
                }
                if (casesA.length !== casesB.length) {
                    return true
                }
                for (let i = 0; i < casesA.length; i++) {
                    let cA = casesA[i]
                    let cB = casesB[i]
                    if (cA.hash !== cB.hash) {
                        return true
                    } else {
                        if ((cA.result !== cB.result) || (cA.rawResult !== cB.rawResult)) {
                            return true
                        }
                    }
                }
            }
            if (isDiff(cases1, prevCases1) === true || isDiff(cases2, prevCases2) === true) {
                this.computeDiff()
            }
        }
    }

    computeDiff () {
        const map1 = utilsRawResultMapMap(this.props.targets, this.props.targetIndex1, this.props.rawResultMaps)
        const map2 = utilsRawResultMapMap(this.props.targets, this.props.targetIndex2, this.props.rawResultMaps)

        let diff1 = 
        {
            counts: {
                total: 0,
                pass: 0,
                fail: 0,
                unknown: 0
            },
            passDiff: [],
            failDiff: [],
            unknownDiff: [],
            noDiff: [],
            buildCase: undefined
        }
        let diff2 = 
        {
            counts: {
                total: 0,
                pass: 0,
                fail: 0,
                unknown: 0
            },
            passDiff: [],
            failDiff: [],
            unknownDiff: [],
            noDiff: [],
            buildCase: undefined
        }
        if (this.props.cases1 === undefined || this.props.cases2 === undefined) {
            this.setState({diff1: diff1, diff2: diff2})
            return
        }
       
        let cases1Hash = {}
        let cases2Hash = {}

        this.props.cases1.forEach((c) => {
            cases1Hash[c.hash] = c
        })

        this.props.cases2.forEach((c) => {
            cases2Hash[c.hash] = c
        })

        const compute = (diffSide) => {
            let cases = (diffSide === 1) ? this.props.cases1 : this.props.cases2
            let diff = (diffSide === 1) ? diff1: diff2
            let map = (diffSide === 1) ? map1 : map2
            let casesHash = (diffSide === 1) ? cases2Hash : cases1Hash
            cases.forEach((c) => {
                if (c.suite !== "[build]") {
                    let noDiff = true
                    diff.counts.total += 1
                    if (utilsIsPassResult(c, map)) {
                        diff.counts.pass += 1
                        if (casesHash[c.hash] === undefined) {
                            diff.passDiff.push(c)
                            noDiff = false
                        } else {
                            if (utilsIsPassResult(casesHash[c.hash], map) !== true) {
                                diff.passDiff.push(c)
                                noDiff = false
                            }
                        }
                    } else if (utilsIsFailResult(c, map)) {
                        diff.counts.fail += 1
                        if (casesHash[c.hash] === undefined) {
                            diff.failDiff.push(c)
                            noDiff = false
                        } else {
                            if (utilsIsFailResult(casesHash[c.hash], map) !== true) {
                                diff.failDiff.push(c)
                                noDiff = false
                            }
                        }
                    } else {
                        diff.counts.unknown += 1
                        if (casesHash[c.hash] === undefined) {
                            diff.unknownDiff.push(c)
                            noDiff = false
                        } else {
                            if (utilsIsPassResult(casesHash[c.hash], map) || utilsIsFailResult(casesHash[c.hash], map)) {
                                diff.unknownDiff.push(c)
                                noDiff = false
                            }
                        }
                    }
                    if (noDiff === true) {
                        diff.noDiff.push(c)
                    }
                } else {
                    diff.buildCase = c
                }
            })
        }
        compute(1)
        compute(2)
        this.setState({diff1: diff1, diff2: diff2})
    }
    
    render () {
        if (window.innerWidth < 800) {
            return <div className='neutral4'>Diff is not supported on narrow width displays</div>
        }

        if (this.props.rspNotFound === true && this.state.rspNotFoundDismissed !== true) {
            return (
                <div>
                    <div className='neutral4'>The requested resource was not found. The entered url may be invalid or the test run has expired.</div>
                    <div><button className='btn-cancel' onClick={() => {this.setState({rspNotFoundDismissed: true})}}>Dismiss and start new Diff</button></div>
                </div>
            )
        } else {
            let data1 = <div>{this.state.diff1.counts.total}</div>
            let data2 = <div>{this.state.diff2.counts.total}</div>
            
            const consolidatedCases = (diff) => {
                let cases = []
                diff.passDiff.forEach((c) => {
                    c.diff = "pass"
                    cases.push(c)
                })
                diff.failDiff.forEach((c) => {
                    c.diff = "fail"
                    cases.push(c)
                })
                diff.unknownDiff.forEach((c) => {
                    c.diff = "unknown"
                    cases.push(c)
                })
                diff.noDiff.forEach((c) => {
                    c.diff = "none"
                    cases.push(c)
                })
                return cases
            }

            let builds1 = []
            let builds2= []

            if (this.state.diff1.buildCase !== undefined) {
                builds1.push(this.state.diff1.buildCase)
            }
            if (this.state.diff2.buildCase !== undefined) {
                builds2.push(this.state.diff2.buildCase)
            }

            return (
                <div>
                    <div className='diff-columns'>
                        <div className='diff-column-left'>
                            <PassRate 
                                type="diff"
                                cases={consolidatedCases(this.state.diff1)}
                                targets={this.props.targets}
                                targetIndex={this.props.targetIndex1}
                                rawResultMaps={this.props.rawResultMaps}
                                runs={this.props.runs1}
                                runIndex={this.props.runIndex1}
                            />
                            <CaseList
                                context={"diff"}
                                overlay={this.props.overlay}
                                sideOverlay={this.props.sideOverlay}
                                messageOverlay={this.props.messageOverlay}
                                side={this.props.side}
                                projects={this.props.projects}
                                projectIndex={this.props.projectIndex}
                                targets={this.props.targets} 
                                targetIndex={this.props.targetIndex1}
                                runs={this.props.runs1}
                                runIndex={this.props.runIndex1}
                                cases={consolidatedCases(this.state.diff1)}
                                builds={builds1}
                                role={this.props.role}
                                members={this.props.members}
                                rawResultMaps={this.props.rawResultMaps}
                            />
                        </div>
                        <div className='diff-column-right'>
                            <PassRate 
                                type="diff"
                                cases={consolidatedCases(this.state.diff2)}
                                targets={this.props.targets}
                                targetIndex={this.props.targetIndex2}
                                rawResultMaps={this.props.rawResultMaps}
                                runs={this.props.runs2}
                                runIndex={this.props.runIndex2}
                            />
                            <CaseList
                                context={"diff"}
                                overlay={this.props.overlay}
                                sideOverlay={this.props.sideOverlay}
                                messageOverlay={this.props.messageOverlay}
                                side={this.props.side}
                                projects={this.props.projects}
                                projectIndex={this.props.projectIndex}
                                targets={this.props.targets} 
                                targetIndex={this.props.targetIndex2}
                                runs={this.props.runs2}
                                runIndex={this.props.runIndex2}
                                cases={consolidatedCases(this.state.diff2)}
                                builds={builds2}
                                role={this.props.role}
                                members={this.props.members}
                                rawResultMaps={this.props.rawResultMaps}
                            />
                        </div>
                    </div>
                </div>
            )
        }
    }    
}

export default Diff