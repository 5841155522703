/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Request from './Request'
import Countries from './Countries'

class PaymentByInvoice extends Component {
    constructor () {
        super();
        this.state = {
            requestSent: false, 
            requestError: "", 
            completeInvoicePaymentError: "", 
            first_name: "-",
            last_name: "-",
            email: "-",
            company_name: "-",
            address_line1: "-",
            address_line2: "-",
            address_city: "-",
            address_state: "-",
            address_postal_code: "-",
            address_country: "US",
            uk_vat_number: "",
            authorization: "", 
            submitting: false
        };
        this.requestPaymentByInvoice = this.requestPaymentByInvoice.bind(this)
        this.completeInvoicePayment = this.completeInvoicePayment.bind(this)
        this.firstNameChange = this.firstNameChange.bind(this)
        this.lastNameChange = this.lastNameChange.bind(this)
        this.emailChange = this.emailChange.bind(this)
        this.companyNameChange = this.companyNameChange.bind(this)
        this.addressLine1Change = this.addressLine1Change.bind(this)
        this.addressLine2Change = this.addressLine2Change.bind(this)
        this.addressCityChange = this.addressCityChange.bind(this)
        this.addressStateChange = this.addressStateChange.bind(this)
        this.addressPostalCodeChange = this.addressPostalCodeChange.bind(this)
        this.addressCountryChange = this.addressCountryChange.bind(this)
        this.authorizationChange = this.authorizationChange.bind(this)
        this.ukVATNumberChange = this.ukVATNumberChange.bind(this)
    }

    componentDidMount () {
        if (this.props.countryBased !== undefined) {
            this.setState({address_country: this.props.countryBased});
        }
    }

    firstNameChange (e) {
        this.setState({first_name: e.target.value})
    }

    lastNameChange (e) {
        this.setState({last_name: e.target.value})
    }

    emailChange (e) {
        this.setState({email: e.target.value})
    }

    companyNameChange (e) {
        this.setState({company_name: e.target.value})
    }

    addressLine1Change (e) {
        this.setState({address_line1: e.target.value})
    }

    addressLine2Change (e) {
        this.setState({address_line2: e.target.value})
    }

    addressCityChange (e) {
        this.setState({address_city: e.target.value})
    }

    addressStateChange (e) {
        this.setState({address_state: e.target.value})
    }

    addressPostalCodeChange (e) {
        this.setState({address_postal_code: e.target.value})
    }

    addressCountryChange (e) {
        this.setState({address_country: e.target.value})
    }

    ukVATNumberChange (e) {
        this.setState({uk_vat_number: e.target.value})
    }

    authorizationChange (e) {
        this.setState({authorization: e.target.value});
    }

    passwordChange (e) {
        this.setState({password: e.target.value});
    }

    requestPaymentByInvoice (e) {
        e.preventDefault();
        Analytics.event("PaymentByInvoiceRequest")
        this.setState({submitting:true})
        let data = {
            company_name: this.state.company_name,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            product: this.props.product,
            interval: this.props.interval,
            active_users: this.props.activeUsers,
            min_users: this.props.minUsers,
            address_line1: this.state.address_line1,
            address_line2: this.state.address_line2,
            address_city: this.state.address_city,
            address_state: this.state.address_state,
            address_postal_code: this.state.address_postal_code,
            address_country: this.state.address_country,
            uk_vat_number: this.state.uk_vat_number
        }

        Request.post("/paymentByInvoiceRequest", data, (err, data) => {

            if (err) {
                this.setState({error: err, submitting:false})
            } else {
                this.setState({requestSent: true, submitting: false})
            }
        })
    }

    completeInvoicePayment (e) {
        e.preventDefault();
        Analytics.event("PaymentByInvoiceComplete");
        this.props.paymentByInvoiceComplete(this.state.authorization)
    }

    render () {
        let requestContent = 
        <div>
        <p>Please submit invoicing details. A member of the Tesults team will get back to you with an authorization number to proceed.</p>
        <form method="POST" className="neutral1" onSubmit={this.requestPaymentByInvoice}>
            <span className="mt-3 mb-1 neutral4">First Name</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.firstNameChange} value={this.state.first_name}/></div>
            <span className="mt-3 mb-1 neutral4">Last Name</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.lastNameChange} value={this.state.last_name}/></div>
            <span className="mt-3 mb-1 neutral4">Email</span>
            <div className="mb-3"><input type="email" className="tr-input form-control" placeholder="-" onChange={this.emailChange} value={this.state.email}/></div>
            <span className="mt-3 mb-1 neutral4">Company / Legal Entity</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.companyNameChange} value={this.state.company_name}/></div>
            <span className="mt-3 mb-1 neutral4">Address: Line 1</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.addressLine1Change} value={this.state.address_line1}/></div>
            <span className="mt-3 mb-1 neutral4">Address: Line 2</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.addressLine2Change} value={this.state.address_line2}/></div>
            <span className="mt-3 mb-1 neutral4">Address: City</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.addressCityChange} value={this.state.address_city}/></div>
            <span className="mt-3 mb-1 neutral4">Address: State</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.addressStateChange} value={this.state.address_state}/></div>
            <span className="mt-3 mb-1 neutral4">Address: Zip/Postal Code</span>
            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.addressPostalCodeChange} value={this.state.address_postal_code}/></div>
            <span className="mt-3 mb-1 neutral4">Address: Country</span>
            <Countries change={this.addressCountryChange} value={this.state.address_country}/>
            {
                this.state.address_country === "GB" ?
                <div>
                    <span className="mt-3 mb-1 neutral4">UK VAT Number</span>
                    <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="-" onChange={this.ukVATNumberChange} value={this.state.uk_vat_number}/></div>
                </div>
                :
                <span></span>
            }
            <div className='accenta2 font14'>{this.state.error}</div>
            <input type="submit" className="btn btn-confirm mt-3" value="Request Payment By Invoice" disabled={this.state.submitting}/>
        </form>
        </div>

        if (this.state.requestSent === true) {
            requestContent = 
            <div>
                <p>Your request for payment by invoice has been received. One of of our billing team members will get back to you soon.</p>
                <p>You will receive an authorization number once invoicing is setup and then you can complete creating or updating your project by entering the number in the field to the right.</p>
                <p>You may close this window if required. Once you receive your authorization number come back and create the project again and select the same project configuration as you have done now to complete the process.</p>
            </div>
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <h4 className="neutral1">Request Payment By Invoice</h4>
                        {requestContent}
                        <br/>
                        <div className="accenta1" id="error">{this.state.requestError}</div>
                    </div>
                    <div className="col-12 col-sm-1 col-md-1 col-lg-2">

                    </div>
                    <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                        <h4 className="neutral1">Payment By Invoice Authorized?</h4>
                        <form method="POST" className="neutral1" onSubmit={this.completeInvoicePayment}>
                            <span className="mt-3 mb-1 neutral4">Authorization Number</span>
                            <div className="mb-3"><input type="text" className="tr-input form-control" placeholder="Authorization Number" onChange={this.authorizationChange}/></div>
                            <input type="submit" className="btn btn-confirm mt-3" value={this.props.type === "planChange" ? "Submit" : "Create project"} disabled={this.state.submitting}/>
                        </form>
                        <br/>
                        <div className="accenta1" id="error">{this.props.paymentByInvoiceError}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentByInvoice;