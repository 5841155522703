/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsOthers extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Other Languages</title>
                    <meta name="description" content="Learn how to use Tesults with other languages for effective test reporting."/>
                </Helmet>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                        <p>If there is another language you would like to see an API library for, please get in contact and tell us: <a className="site-link-primary2" href="mailto:help@tesults.com">help@tesults.com</a>.</p>
                        <p>The API libraries for various programming languages are wrappers around the results <a className="site-link-primary2" href="/docs/resultsapi">REST API</a>. You can always use the <a className="site-link-primary2" href="/docs/resultsapi">REST API</a> directly to upload your test results. However if you intend to upload files along with your test results you <b>must</b> use an API Libary.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsOthers;