/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsNotifications extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Notifications Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults notifications."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Notifications.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/4917npGuAys?si=4brrsJfbfJDuJQ9H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Tesults can send out Email, Slack, Teams and Mattermost notifications when key events occur such a new test run being generated.</p>
                <p>You can toggle on and off three options for notifications and these options have a cumulative effect.</p>
                <h2>Automatic Notifications</h2>
                <p>Everytime new test results are generated, notifications are delivered automatically if this setting is enabled. This includes notifications to team members with email notifications enabled and Slack channel notifications.</p>
                <p>You can choose to disable automatic notifications and have project moderators trigger notifications manually instead from the results view.</p>
                <h2>Notifications On Changes Only</h2>
                <p>Everytime new test results are generated, Tesults delivers notifications if there are changes to previous results only, if this setting is enabled. This includes notifications to team members with email notifications enabled and Slack channel notifications.</p>
                <p>You can choose to disable this and then notifications will be delivered every time new results are generated.</p>
                <h2>Notifications On Failures Only</h2>
                <p>Deliver notifications for failures only. No notifications will be sent when all tests pass if this setting is enabled. This includes notifications to team members with email notifications enabled and Slack channel notifications.</p>
                <br/>
                <p>After toggling each option on or off by using the check box, click the Save button.</p>
            </div>
        );
    }
}

export default DocsNotifications;