import React, { Component } from 'react';
import JobRequest from './JobRequest';
import JobResponse from './JobResponse';
import Request from './Request';
import Linkify from 'linkifyjs/react';
import ReactJson from 'react-json-view';
import Confirmation from './Confirmation'

class Job extends Component {
    constructor () {
        super()
        this.state = {
            job: undefined,
            target: undefined,
            confirmation: {success: undefined, failure: undefined},
            configure: "start",
            configurationDisplay: false,
            response: undefined,
            action: undefined
        }
        this.init = this.init.bind(this)
        this.initJob = this.initJob.bind(this)
        this.configure = this.configure.bind(this)
        this.configurationDisplayToggle = this.configurationDisplayToggle.bind(this)
        this.save = this.save.bind(this)
        this.action = this.action.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.status = this.status.bind(this)
        this.response = this.response.bind(this)
    }

    componentDidMount() {
        this.init()
    }

    init () {
        if (this.props.targets !== undefined && this.props.targetIndex !== undefined) {
            if (this.props.targetIndex < this.props.targets.length) {
                let target = this.props.targets[this.props.targetIndex]
                let job = undefined
                if (target.jobs !== undefined) {
                    if (target.jobs.length > 0) {
                        job = target.jobs[0]
                    }
                }
                let statusRequest = false
                if (job === undefined) {
                    job = this.initJob()
                } else {
                    if (job.status !== undefined) {
                        if (job.status.request !== undefined) {
                            if (job.status.request.url !== undefined && job.status.request.url !== "") {
                                statusRequest = true
                            }
                        }
                    }
                }
                this.setState({target: target, job: job}, () => {
                    if (statusRequest === true) {
                        this.status()
                    }
                })
            }
        }
    }

    initJob () {
        return {
            start: {
                request: {
                    url: "",
                    method: "GET",
                    headers: [],
                    params: []
                },
                response: {
                    contentType: "application/json",
                    statusCode: 200,
                    statusKey: "status",
                    statusValue: "started"
                }
            },
            stop: {
                request: {
                    url: "",
                    method: "GET",
                    headers: [],
                    params: []
                },
                response: {
                    contentType: "application/json",
                    statusCode: 200,
                    statusKey: "status",
                    statusValue: "stopped"
                }
            },
            status: {
                request: {
                    url: "",
                    method: "GET",
                    headers: [],
                    params: []
                },
                response: {
                    contentType: "application/json",
                    statusCode: 200,
                    statusKey: "status",
                    statusValue: "running"
                }
            }
        }
    }

    configure (type) {
        this.setState({configure: type, confirmation: {success: undefined, failure: undefined}})
    }

    configurationDisplayToggle () {
        this.setState({configurationDisplay: this.state.configurationDisplay === true ? false: true, confirmation: {success: undefined, failure: undefined}})
    }

    save (job) {
        Request.post("/job", {id: this.state.target.id, created: this.state.target.created, job: job}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error updating job."}})
            } else {
                let job = undefined
                if (data.target.jobs !== undefined) {
                    if (Array.isArray(data.target.jobs)) {
                        if (data.target.jobs.length > 0) {
                            job = data.target.jobs[0]
                        }
                    }
                }
                this.setState({confirmation: {success: undefined, failure: undefined}, job: job})
            }
        })
    }

    action (type) {
        if (this.state.job === undefined) {
            this.setState({confirmation: {success: undefined, failure: "Job " + type + " is not configured"}})
            return
        }
        if (this.state.job[type].request.url === undefined || this.state.job[type].request.url === "") {
            this.setState({confirmation: {success: undefined, failure: "Job " + type + " is not configured"}})
            return
        }
        let headers = {}
        if (this.state.job[type].request.headers !== undefined) {
            if (Array.isArray(this.state.job[type].request.headers)) {
                this.state.job[type].request.headers.forEach((header) => {
                    headers[header.key] = header.value
                })
            }
        }
        let params = {}
        if (this.state.job[type].request.params !== undefined) {
            if (Array.isArray(this.state.job[type].request.params)) {
                this.state.job[type].request.params.forEach((param) => {
                    params[param.key] = param.value
                })
            }
        }
        const response_callback = (err, data) => {
            if (err) {
                let failureMessage = ""
                if (type === "start") {
                    failureMessage = "Error starting job"
                } else if (type === "stop") {
                    failureMessage = "Error stopping job"
                } else if (type === "status") {
                    failureMessage = "Error fetching status"
                }
                this.setState({confirmation: {success: undefined, failure: failureMessage}, response: {error: err.message}, action: type})
            } else {
                this.setState({confirmation: {success: undefined, failure: undefined}, response: data, action: type}, () => {
                    if (type === "status") {
                        setTimeout(this.status, 30000);
                    }
                })
            }
        }
        if (this.state.job.start.request.method === "GET") {
            Request.get(this.state.job[type].request.url, params, response_callback, true, headers)
        }
        if (this.state.job.start.request.method === "POST") {
            Request.post(this.state.job[type].request.url, params, response_callback, true, headers)
        }
    }

    start () {
        this.action("start")
    }

    stop () {
        this.action("stop")
    }

    status () {
        this.action("status")
    }

    response () {
        if (this.state.response === undefined) {
            return <Confirmation confirmation={this.state.confirmation}/>
        }
        let statusCode = this.state.response.tesults_status_code
        let statusValue = undefined
        //delete data.tesults_status_code
        let expectedStatusCode = undefined
        let expectedStatusKey = undefined
        let expectedStatusValue = undefined
        try {
            expectedStatusCode = this.state.job[this.state.action].response.statusCode
            expectedStatusKey = this.state.job[this.state.action].response.statusKey
            expectedStatusValue = this.state.job[this.state.action].response.statusValue
        }
        catch (err) {
            expectedStatusCode = undefined
            expectedStatusKey = undefined
            expectedStatusValue = undefined
        }        
        
        const summary = () => {
            if (this.state.response === undefined) {
                return <span></span>
            }
            let dotColor = "accentb3bg"
            let color = "accentb3"
            let title = ""
            let message = ""
            
            let statusCodeValid = false
            let statusValueValid = false
            if (expectedStatusCode === statusCode) {
                statusCodeValid = true
            }
            if (expectedStatusKey === undefined || expectedStatusKey === "") {
                statusValueValid = true
                statusValue = "invalid due to key being invalid"
            } else {
                if (this.state.response[expectedStatusKey] === expectedStatusValue) {
                    statusValueValid = true
                    statusValue = this.state.response[expectedStatusKey]
                } else {
                    statusValue = this.state.response[expectedStatusKey]
                }
            }

            const displayValue = (val) => {
                if (val === undefined) {
                    return ("(no value in response)")
                } else {
                    return val
                }
            }

            if (statusCodeValid === true && statusValueValid === true) {
                dotColor = "accentc3bg"
                color = "accentc3"
                if (this.state.action === "start") {
                    title = "Started"
                    message = "Job started based on status code " + displayValue(statusCode)
                    if (expectedStatusKey !== undefined && expectedStatusKey !== "") {
                        message += " and " + expectedStatusKey + " value " + displayValue(expectedStatusValue)
                    }
                }
                if (this.state.action === "stop") {
                    title = "Stopped"
                    message = "Job stopped based on status code " + displayValue(statusCode)
                    if (expectedStatusKey !== undefined && expectedStatusKey !== "") {
                        message += " and " + expectedStatusKey + " value " + displayValue(expectedStatusValue)
                    }
                }
                if (this.state.action === "status") {
                    title = "Running"
                    message = "Job running based on status code " + displayValue(statusCode)
                    if (expectedStatusKey !== undefined && expectedStatusKey !== "") {
                        message += " and " + expectedStatusKey + " value " + displayValue(expectedStatusValue)
                    }
                }
            } else if (statusCodeValid === true && statusValueValid === false) {
                dotColor = "accentb3bg"
                color = "accentb3"
                if (this.state.action === "start") {
                    title = "Failed to start"
                    message = "Job failed to start based on " + expectedStatusKey + " value " + " " + displayValue(statusValue)
                }
                if (this.state.action === "stop") {
                    title = "Failed to stop"
                    message = "Job failed to stop based on " + expectedStatusKey + " value " + " " + displayValue(statusValue)
                }
                if (this.state.action === "status") {
                    title = "Stopped"
                    message = "Based on " + expectedStatusKey + " value " + displayValue(statusValue)
                }
            } else if (statusCodeValid === false && statusValueValid === true) {
                dotColor = "accentb3bg"
                color = "accentb3"
                if (this.state.action === "start") {
                    title = "Failed to start"
                    message = "Job failed to start based on status code " + displayValue(statusCode) + ", expected " + expectedStatusCode
                }
                if (this.state.action === "stop") {
                    title = "Failed to stop"
                    message = "Job failed to stop based on status code " + displayValue(statusCode) + ", expected " + expectedStatusCode
                }
                if (this.state.action === "status") {
                    title = "Failed to fetch status"
                    message = "Unable to check status, response status code " + displayValue(statusCode) + ", expected " + expectedStatusCode
                }
            } else {
                // statusCodeValid === false, statusValueValid === false
                dotColor = "accenta3bg"
                color = "accenta3"
                if (this.state.action === "start") {
                    title = "Failed to start"
                    message = "Job failed to start based on status code " + displayValue(statusCode) + ", expected " + expectedStatusCode + " and " + expectedStatusKey + " value " + " " + displayValue(statusValue)
                }
                if (this.state.action === "stop") {
                    title = "Failed to stop"
                    message = "Job failed to stop based on status code " + displayValue(statusCode) + ", expected " + expectedStatusCode + " and " + expectedStatusKey + " value " + " " + displayValue(statusValue)
                }
                if (this.state.action === "status") {
                    title = "Failed to fetch status"
                    message = "Unable to check status, response status code " + displayValue(statusCode) + ", expected " + expectedStatusCode + " and " + expectedStatusKey + " value " + " " + displayValue(statusValue)
                }
            }
             
            return (
                <div className='mb-5' style={{"maxWidth":"200px"}}>
                    <div className="mb-4 pt-3" style={{"display":"flex", "alignItems":"center"}}>
                        <div className={"dot mr-3 " + dotColor}></div> 
                        <div class="site-link">
                            <span className={"mr-3 " + color}> {title}</span>
                        </div>
                    </div>
                    <div className="font14 neutral4">
                        {message}
                    </div>
                </div>

            )
        }

        const detail = () => {
            let data = <span></span>
            if (this.state.response !== undefined) {
                try {
                    let display = {}
                    Object.keys(this.state.response).forEach((key) => {
                        if (key !== "tesults_status_code") {
                            display[key] = this.state.response[key]
                        }
                    })
                    //display = JSON.stringify(this.state.response)
                    data = <Linkify>{<ReactJson src={display}/>}</Linkify>
                } catch (err) {
                    data = err.toString()
                }            
            }
            return data
        }
        
        return (
            <div className="width100 mb-5 pl-3 pr-3 neutral8bg solidborder neutral8border rounderborder font14">
                {summary()}
                {detail()}
            </div>
        )
    }

    render () {
        if (!(this.props.view === "results" || this.props.view === "supplemental")) {
            return <span></span>
        }
        
        if (this.state.target === undefined) {
            return <div></div>
        }

        if (this.props.trl) {
            return <div font14 neutral4>Unavailable in public view</div>
        }

        let requestInfo = <span></span>
        let responseInfo = <span></span>

        // continue -> make these messages better, actually explain what values are needed and status types supported
        // then review UI and start testing config
        // then after that's tested, code actions
        if (this.state.configure === "start") {
            requestInfo = 
            <div>
                <p>Configure an http request to start the test job for this target. Then trigger the test job by clicking the Start button.</p>
            </div>
            responseInfo = 
            <div>
                <p>Configure how to interpret a successful response from the http request to start the test job for this target. Set the status code to indicate success and an optional key/value from a JSON response. If key/value is specified, the job is considered a success only if the key/value matches, otherwise it is considered to have failed.</p>
            </div>
        } else if (this.state.configure === "stop") {
            requestInfo = 
            <div>
                <p>Configure an http request to stop the test job for this target. Then stop the test job by clicking the Stop button.</p>
            </div>
            responseInfo = 
            <div>
                <p>Configure how to interpret a successful response from the http request to stop the test job for this target. Set the status code to indicate success and an optional key/value from a JSON response. If key/value is specified, the job is considered a success only if the key/value matches, otherwise it is considered to have failed.</p>
            </div>
        } else if (this.state.configure === "status") {
            requestInfo = 
            <div>
                <p>Configure an http request to fetch status for the test job for this target. The status of the job will then be displayed (either running or stopped).</p>
            </div>
            responseInfo = 
            <div>
                <p>Configure how to interpret the status of a response from the http request to retrieve status for the test job for this target. Set the status code to indicate a successful response and a status key/value from a JSON response. If the key and value specified matches then the job is considered to be running, otherwise the job is considered to be stopped.</p>
            </div>
        }
        return (
            <div className="width100">
                <div>
                    <p className="font14 neutral4">Start and stop the test job that outputs results to the {this.state.target.name} target.</p>
                </div>
                <button onClick={this.start} className="btn-transparent mt-53 mb-3" style={{"paddingLeft": "0"}}>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className="startIcon mr-2"></div>
                        <div>Start</div>
                    </div>
                </button>
                <button onClick={this.stop} className="btn-transparent mt-3 mb-3" style={{"paddingLeft": "0"}}>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className="stopIcon mr-2"></div>
                        <div>Stop</div>
                    </div>
                </button>
                {
                    this.props.role >= 3 ?
                    <button onClick={this.configurationDisplayToggle} className="btn-transparent mt-3 mb-3" style={{"paddingLeft": "0"}}>
                        {this.state.configurationDisplay === true ?
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                <div className="collapseIcon mr-2"></div>
                                <div className="neutral4">Hide configuration</div>
                            </div>
                            :
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                <div className="expandIcon mr-2"></div>
                                <div className="neutral4">Configure job</div>
                            </div>
                        }
                    </button>
                    :
                    <div className="neutral4 font12">
                        <hr className="neutral8bg"/>
                        <p>Job configuration requires administrator role.</p>
                    </div>
                }
                
                {this.response()}
                {
                    this.state.configurationDisplay === true ?
                    <div>
                        <h4 className="neutral3">Job configuration</h4>
                        <div className={"mb-5 " + (this.state.configurationDisplay === true ? "collapse show" : "collapse")} style={{"display":"flex"}}>
                            <div className='mr-1'><button type="button" className={this.state.configure === "start" ? "btn-confirm-short" : "btn-cancel-short"} onClick={() => this.configure("start")}><span className="font12">Start</span></button></div>
                            <div className='mr-1'><button type="button" className={this.state.configure === "stop" ? "btn-confirm-short" : "btn-cancel-short"} onClick={() => this.configure("stop")}><span className="font12">Stop</span></button></div>
                            <div><button type="button" className={this.state.configure === "status" ? "btn-confirm-short" : "btn-cancel-short"} onClick={() => this.configure("status")}><span className="font12">Status</span></button></div>
                        </div>
                        <div className="neutral4 font14">
                            {requestInfo}
                        </div>
                        <JobRequest overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} type={this.state.configure} job={this.state.job} save={this.save}/>
                        <div className="neutral4 font14">
                            {responseInfo}
                        </div>
                        <JobResponse overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} type={this.state.configure} job={this.state.job} save={this.save}/>
                        <div className="neutral4 font14 mt-5 mb-5">
                            <p>Your test job server must set the <b>Access-Control-Allow-Origin</b> header for domain <b>https://www.tesults.com</b> in the response of the preflight request. This is the standard method of permitting Tesults to interact with your test server using the Cross-Origin Resource Sharing (CORS) mechanism. If this is the first time you are setting the Access-Control-Allow-Origin header we recommend you do not utilize the wildcard * value. Set the permitted domain to https://www.tesults.com specifically to maintain security. You will also need to set the <b>Access-Control-Allow-Headers</b> header and specify permitted headers if you are submitting headers in the request.</p>
                        </div>
                    </div>
                    :
                    <div></div>
                }
            </div>
        )
    }
}

export default Job;