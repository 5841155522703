/*global */
import React, { Component } from 'react';
import ScrollToTop from './ScrollToTop';

class ExpSide extends Component {
    constructor () {
        super();
        this.scrollTo = this.scrollTo.bind(this);
    }

    scrollTo (ref) {
        this.props.scrollTo(ref);
    }
    
    render () {
        return (
            <div>
                <h3 className="mb-5">Contents</h3>
                <a href="#introduction" className="tr-link-no-underline bold neutral3">Introduction</a>
                <br/>
                <br/>
                <a href="#quick-setup" className="tr-link-no-underline neutral3 bold">Quick Setup</a>
                <br/>
                <br/>
                <a href="#suite" className="tr-link-no-underline neutral3 bold">Test Suite</a>
                <br/>
                <a href="#cases" className="tr-link-no-underline neutral3 ml-3">cases</a>
                <br/>
                <a href="#suite-suite" className="tr-link-no-underline neutral3 ml-3">suite</a>
                <br/>
                <a href="#hooks" className="tr-link-no-underline neutral3 ml-3">hooks</a>
                <br/>
                <a href="#timeout" className="tr-link-no-underline neutral3 ml-3">timeout</a>
                <br/>
                <br/>
                <a href="#case" className="tr-link-no-underline neutral3 bold">Test Case</a>
                <br/>
                <a href="#name" className="tr-link-no-underline neutral3 ml-3">name</a>
                <br/>
                <a href="#test" className="tr-link-no-underline neutral3 ml-3">test</a>
                <br/>
                <a href="#case-timeout" className="tr-link-no-underline neutral3 ml-3">timeout</a>
                <br/>
                <a href="#desc" className="tr-link-no-underline neutral3 ml-3">desc</a>
                <br/>
                <a href="#case-suite" className="tr-link-no-underline neutral3 ml-3">suite</a>
                <br/>
                <a href="#custom" className="tr-link-no-underline neutral3 ml-3">_custom</a>
                <br/>
                <a href="#steps" className="tr-link-no-underline neutral3 ml-3">steps</a>
                <br/>
                <a href="#params" className="tr-link-no-underline neutral3 ml-3">paramsList and params</a>
                <br/>
                <a href="#context" className="tr-link-no-underline neutral3 ml-3">context</a>
                <br/>
                <br/>
                <a href="#synchronous" className="tr-link-no-underline neutral3 bold">Sync and Async Tests</a>
                <br/>
                <a href="#synchronous" className="tr-link-no-underline neutral3 ml-3">Synchronous Test</a>
                <br/>
                <a href="#asynchronous" className="tr-link-no-underline neutral3 ml-3">Async/Await/Promise Test</a>
                <br/>
                <a href="#callback" className="tr-link-no-underline neutral3 ml-3">Callback Test</a>
                <br/>
                <br/>
                <a href="#utility" className="tr-link-no-underline neutral3 bold">Exp utility functions</a>
                <br/>
                <a href="#log" className="tr-link-no-underline neutral3 ml-3">log</a>
                <br/>
                <a href="#file" className="tr-link-no-underline neutral3 ml-3">file</a>
                <br/>
                <a href="#wait" className="tr-link-no-underline neutral3 ml-3">wait</a>
                <br/>
                <br/>
                <a href="#advanced" className="tr-link-no-underline neutral3 bold">Setup - Advanced</a>
                <br/>
                <br/>
                <a href="#future" className="tr-link-no-underline neutral3 bold">Future</a>
                <br/>
                <ScrollToTop onLeft={true}/>
                <br/>
                <br/>
            </div>
        );
    }
};

export default ExpSide;