/*global */
import React, { Component } from 'react';
//import InfoToolTip from './InfoToolTip';

class ManualAssigneeSelect extends Component {
    constructor() {
        super();
        this.assigneeChange = this.assigneeChange.bind(this);
    }

    assigneeChange (e) {
        this.props.assigneeChange(e.target.value);
    }

    render () {
        let memberOptions = [];
        if (this.props.all === true) {
            memberOptions.push(<option key="all" value="all">All</option>)
        }
        let membersAlphabetical = this.props.members;
        if (membersAlphabetical !== undefined) {
            membersAlphabetical.sort(function (a, b) {
                var ta = a.firstName.toLowerCase();
                var tb = b.firstName.toLowerCase();
              
                return ta.localeCompare(tb);
            });
            memberOptions.push(<option key={-1} value={"unassigned"}>Unassigned</option>)
            membersAlphabetical.forEach(function (member) {
                memberOptions.push(<option key={member.id} value={member.id}>{member.firstName + " " + member.lastName + "    (" +  member.email + ")"}</option>)
            });
        }

        let value = this.props.assignSelectedAssignee;
        if (this.props.caseData !== undefined) {
            // Single case being edited
            if (this.props.caseData.manualAssignee !== undefined) {
                value = this.props.caseData.manualAssignee;
            }
        }

        let membersSelect = 
        <select className="custom-select" style={{"width":"200px"}} onChange={this.assigneeChange} value={value}>
        {memberOptions}
        </select>
       
        if (this.props.loading === true) {
            return <span></span>
        } else {
            return (
                <div>
                    {membersSelect}                
                </div>
            );
        }
    }
};

export default ManualAssigneeSelect