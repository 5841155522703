/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Invoices from './Invoices'
import Request from './Request'
import Loading from './Loading'

class ConfigInvoices extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {loading: false}
        this.project = this.project.bind(this)
        this.projectPlan = this.projectPlan.bind(this)
        this.projectOwner = this.projectOwner.bind(this)
    }

    componentDidMount () {
        if (this.props.role >= 4) {
            this.projectPlan()
        }
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    projectPlan () {
        this.setState({"loading": true})
        Request.get("/plan", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                this.setState({error: "Unable to retrieve plan details, please contact support."});
            } else {
                this.setState({plan: data.plan, confirmation: {success: undefined, failure: undefined}}, () => this.projectOwner());
            }
        });
    }
    
    projectOwner () {
        this.setState({"loading": true})
        Request.get("/projectOwner", {id: this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].id}, (err, data) => {
            this.setState({"loading": false})
            if (err) {
                this.setState({error: "Unable to retrieve project owner."});
            } else {
                this.setState({owner: data.owner});
            }
        });
    }

    render () {
        if (this.props.role < 4) {
            return (
                <div style={{"height":"100vh"}}>
                    <Notice type="information" content="You do not have the appropriate permissions to configure this option. Ask the project owner to assign the Officer (4) role to you if you require access."/>
                    <button type="button" className="btn btn-cancel mt-5 mb-5" onClick={this.props.cancel}>Back</button>
                </div>
            )
        }
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.plan === undefined) {
             return <div>No plan</div>
        }
        let owner = <span></span>
        if (this.state.owner !== undefined) {
            owner = <span className="font15">The owner of this project is {this.state.owner.firstName} {this.state.owner.lastName} and emails about project changes and billing will be sent to {this.state.owner.email}</span>
        }
        return <Invoices plan={this.state.plan} owner={this.state.owner} project={this.project()}/>
    }
};

export default ConfigInvoices