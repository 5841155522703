/*global*/
import React, { Component } from 'react'
import TargetNote from './TargetNote'
import MultiCaseEditOptions from './MultiCaseEditOptions'
import TargetDetailField from './TargetDetailField'
import Job from './Job'
import TargetMaintainers from './TargetMaintainers'
import SelectOverlay from './SelectOverlay'
import TargetRawResultMap from './TargetRawResultMap'
import SideItem from './SideItem'
import RunArchiveSelect from './RunArchiveSelect'

class TargetDetail extends Component {
    constructor() {
        super()
        
    }

    render() {
        let target = undefined
        if (this.props.targets !== undefined && this.props.targetIndex !== undefined) {
            if (this.props.targetIndex < this.props.targets.length) {
                target = this.props.targets[this.props.targetIndex]
            }
        }
        if (target === undefined) {
            target = {
                desc: ""
            }
        }
        return (
            <div>
                {/*<SideItem image="/img/add-outline.svg" label="Add test case to list" action={"url" + "/add"}/>*/}
                <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/information-outline.svg"/>} label="Description" value={target.desc}/>
                {
                    this.props.isRunArchive === true ?
                    <div></div>
                    :
                    <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/bolt.svg"/>} label="Quick Actions" value=
                        {
                            <div style={{"display": "flex", "flexDirection":"column"}}>
                            <div style={{"flex": "1"}}>
                                <button className="select" onClick={() => window.location.reload()}>
                                    <img src="/img/refresh1.svg" width="14" height="14" alt=""/> Refresh
                                </button>
                            </div>
                            <div style={{"flex": "1"}}>
                                <SelectOverlay
                                    overlay={this.props.overlay}
                                    messageOverlay={this.props.messageOverlay}
                                    type="share"
                                    image={<img src="/img/share1.svg" width="12" height="12" alt=""/>} 
                                    label="Share"
                                    content={this.props.share}
                                    contentWidth={240}
                                />
                            </div>
                            <div style={{"flex": "1"}}>
                                <button className="select" onClick={() => {this.props.exportToCsv()}}>
                                    <img src="/img/download1.svg" width="14" height="14" alt=""/> Export to CSV
                                </button>
                            </div>
                            {
                                this.props.role < 2 ?
                                <span></span>
                                :
                                <div style={{"flex": "1"}}>
                                    <SelectOverlay
                                        overlay={this.props.overlay}
                                        messageOverlay={this.props.messageOverlay}
                                        type="ellipsis"
                                        image={<img src="/img/job1.svg" width="13" height="13" alt=""/>} 
                                        label="Moderator options"
                                        content={this.props.resultsSideMod}
                                        contentWidth={240}
                                    />
                                </div>
                            }
                            
                        </div>
                        }
                    />
                }
                {
                    this.props.isRunArchive === true ?
                    <div></div>
                    :
                    <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/user.svg"/>} label="Maintainers" value={<TargetMaintainers context={this.props.view} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} targetIndex={this.props.targetIndex} targets={this.props.targets} members={this.props.members} targetUpdate={this.props.targetUpdate} role={this.props.role} trl={this.props.trl}/>}/>
                }
                <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/edit-item.svg"/>} label="Note" value={<TargetNote view={this.props.view} casesLoading={this.props.casesLoading} targets={this.props.targets} targetIndex={this.props.targetIndex} syncTargetNote={this.props.syncTargetNote} members={this.props.members} trl={this.props.trl}/>}/>
                <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/news-paper.svg"/>} label="Archived Test Runs" value={<RunArchiveSelect view={this.props.view} targets={this.props.targets} targetIndex={this.props.targetIndex} trl={this.props.trl} rev={this.props.rev}/>}/>
                {
                    this.props.isRunArchive === true ?
                    <div></div>
                    :
                    <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/cost.svg"/>} label="Cost" value={<MultiCaseEditOptions context="results" title="" iconSrc="" subtitle="Financial cost of test failure in production. This will apply to checked test cases." cases={this.props.cases} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} multiEditConfirm={this.props.multiEditConfirm} confirmation={this.props.confirm} multiEdit={this.props.multiEdit}  trl={this.props.trl}/>}/>
                }
                {
                    this.props.isRunArchive === true ?
                    <div></div>
                    :
                    <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/computer-laptop.svg"/>} label="Job" value={<Job view={this.props.view} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} targets={this.props.targets} targetIndex={this.props.targetIndex} role={this.props.role}  trl={this.props.trl}/>}/>
                }
                <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/conversation.svg"/>} label="Results Interpretation " value={<TargetRawResultMap overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} targets={this.props.targets} targetIndex={this.props.targetIndex} role={this.props.role} trl={this.props.trl}/>}/>
                {/*
                <div className="results-header1-actions">
                    
                </div>
                */}
            </div>
        )
    }
}

export default TargetDetail