/*global */
import React, { Component } from 'react';
import DocsBuildConsolidation from './DocsBuildConsolidation';
import DocsFilesStructure from './DocsFilesStructure';
import SetupAssistant from './SetupAssistant';
import DocsProxy from './DocsProxy';
import DocsDynamic from './DocsDynamic';
import DocsResultInterpretationSupport from './DocsResultInterpretationSupport';

class DocsTestFramework extends Component {
    render() {
        let filesStructure = <span></span>
        let suggestions = <span></span>
        if (this.props.files !== undefined) {
            filesStructure = <DocsFilesStructure testframework={true}/>
        }
        if (this.props.installation !== undefined) {
            suggestions = 
            <div>
                <DocsBuildConsolidation/>
                <DocsDynamic/>
                <DocsProxy/>
                <SetupAssistant/>
            </div>
        }

        let example = <span></span>
        if (this.props.example !== undefined) {
            example = this.props.example;
        }

        return (
            <div>
                {this.props.helmet}
                {this.props.introduction}
                <h2 className="mt-5">{this.props.installation !== undefined ? "Installation" : ""}</h2>
                {this.props.installation}
                <h2 className="mt-5">{this.props.configuration !== undefined ? "Configuration" : ""}</h2>
                {this.props.configuration}
                <h2 className="mt-5">{this.props.enhancedReporting !== undefined ? "Enhanced reporting" : ""}</h2>
                {this.props.enhancedReporting}
                <h2 className="mt-5">{this.props.files !== undefined ? "Files generated by tests" : ""}</h2>
                {this.props.files}
                {filesStructure}
                <h2 className="mt-5">{this.props.build !== undefined ? "Build" : ""}</h2>
                {this.props.build}
                {example}
                <h2 className="mt-5">Result Interpretation</h2>
                <DocsResultInterpretationSupport integration={this.props.integration}/>
                {suggestions}
            </div>
        );
    }
}

export default DocsTestFramework;