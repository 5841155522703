/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ForgotPasswordMain from './ForgotPasswordMain'

class ForgotPassword extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Forgot Password</title>
                    <meta name="description" content="Tesults forgot password help."/>
                </Helmet>
                <div style={{"display":"flex", "alignItems":"center", "justifyContent":"center"}}>
                    <ForgotPasswordMain/>
                </div>
            </div>
        );
    }
};

export default ForgotPassword;