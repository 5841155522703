/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import DocsGettingStarted from './DocsGettingStarted';
import DocsProfile from './DocsProfile';
import DocsTasks from './DocsTasks';
import DocsResults from './DocsResults';
import DocsSupplemental from './DocsSupplemental';
import DocsDashboard from './DocsDashboard';
import DocsDiff from './DocsDiff';
import DocsLists from './DocsLists';
import DocsManual from './DocsManual';
import DocsReleaseChecklists from './DocsReleaseChecklists';
import DocsResultsAPI from './DocsResultsAPI';
import DocsJUnitXml from './DocsJUnitXml';
import DocsCSharp from './DocsCSharp';
import DocsPython from './DocsPython';
import DocsJava from './DocsJava';
import DocsRuby from './DocsRuby';
import DocsSwift from './DocsSwift';
import DocsKotlin from './DocsKotlin';
import DocsPHP from './DocsPHP';
import DocsGo from './DocsGo';
import DocsJavaScript from './DocsJavaScript';
import DocsOthers from './DocsOthers';
import DocsStructure from './DocsStructure';
import DocsTarget from './DocsTarget';
import DocsProject from './DocsProject';
import DocsTargets from './DocsTargets';
import DocsTargetOrder from './DocsTargetOrder';
import DocsBuildConsolidation from './DocsBuildConsolidation';
import DocsResultInterpetation from './DocsResultInterpretation';
import DocsPreferences from './DocsPreferences';
import DocsNotifications from './DocsNotifications';
import DocsIntegrations from './DocsIntegrations';
import DocsAPIToken from './DocsAPIToken';
import DocsTeamMembers from './DocsTeamMembers';
import DocsSSO from './DocsSSO';
import DocsOrganization from './DocsOrganization';
import DocsAuditLogs from './DocsAuditLogs';
import DocsPlan from './DocsPlan';
import DocsPaymentDetails from './DocsPaymentDetails';
import DocsAccountCountry from './DocsAccountCountry';
import DocsInvoices from './DocsInvoices';
import DocsSupport from './DocsSupport';
import DocsRefund from './DocsRefund';
import DocsFairBilling from './FairBillingPolicy';
import DocsPrivacy from './DocsPrivacy';
import DocsSecurity from './DocsSecurity';
import DocsTerms from './DocsTerms';
import ExpMain from './ExpMain';
import DocsJUnit5 from './DocsJUnit5';
import DocsJUnit4 from './DocsJUnit4';
import DocsTestNG from './DocsTestNG';
import DocsPytest from './DocsPytest';
import DocsRobot from './DocsRobot';
import DocsVSUnitTF from './DocsVSUnitTF';
import DocsNUnit3 from './DocsNUnit3';
import DocsXUnit from './DocsXUnit';
import DocsMocha from './DocsMocha';
import DocsJasmine from './DocsJasmine';
import DocsJest from './DocsJest';
import DocsPlaywright from './DocsPlaywright';
import DocsWdio from './DocsWdio';
import DocsCodeceptJS from './DocsCodeceptJS';
import DocsNightwatch from './DocsNightwatch';
import DocsWaffle from './DocsWaffle';
import DocsProtractor from './DocsProtractor';
import DocsCypress from './DocsCypress';
import DocsTestCafe from './DocsTestCafe';
import DocsXCTest from './DocsXCTest';
import DocsEspresso from './DocsEspresso';
import DocsPostman from './DocsPostman';
import DocsRSpec from './DocsRSpec';
import DocsSlack from './DocsSlack';
import DocsMSTeams from './DocsMSTeams';
import DocsMattermost from './DocsMattermost';
import DocsPagerDuty from './DocsPagerDuty';
import DocsJira from './DocsJira';
import DocsSelenium from './DocsSelenium';
import DocsPricing from './DocsPricing';
import DocsAPIOverview from './DocsAPIOverview';
import DocsAPIAuth from './DocsAPIAuth';
import DocsAPITargets from './DocsAPITargets';
import DocsAPIResults from './DocsAPIResults';
import DocsDeveloperIntegrations from './DocsDeveloperIntegrations';
import DocsDeveloperIntegrationsUpwork from './DocsDeveloperIntegrationsUpwork';
import DocsTestFrameworkDevelopers from './DocsTestFrameworkDevelopers';
import DocsTesultsJSONDataStandard from './DocsTesultsJSONDataStandard';
import DocsTitle from './DocsTitle';
import Analytics from './Analytics';
import Constants from './Constants';
import DocsVendorOnboarding from './DocsVendorOnboarding';
import DocsGithubActions from './DocsGithubActions';

class DocsMain extends Component {
    constructor (props) {
        super(props);
        Analytics.event("Docs");
        let doc = null;
        let docGroup = null;
        let title = "Getting Started";
        this.state =  {docGroup: docGroup, doc: doc, title: title};
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        window.scrollTo(0, 0);
    }

    render () {
        let content = <span></span>
        let doc = this.props.match.params.doc;
        let docGroup = this.props.match.params.docGroup;
        if (doc === undefined) {
            doc = Constants.getUrlParameter("doc", this.props.location.search);
        }
        if (doc === null || doc === undefined || doc === "getting-started") {
            window.scrollTo(0, 0);
            Analytics.event("GettingStartedDocs");
            content = <DocsGettingStarted doc={doc}/>
        } else if (doc === "user") {
            window.scrollTo(0, 0);
            Analytics.event("UserDocs");
            content = <DocsProfile/>
        } else if (doc === "tasks") {            
            window.scrollTo(0, 0);
            Analytics.event("TasksDocs");
            content = <DocsTasks/>  
        } else if (doc === "results" && docGroup !== "api") {
            window.scrollTo(0, 0);
            Analytics.event("ResultsDocs");
            content = <DocsResults/>
        }  else if (doc === "supplemental") {
            window.scrollTo(0, 0);
            Analytics.event("SupplementalDocs");
            content = <DocsSupplemental/>
        }  else if (doc === "dashboard") {
            window.scrollTo(0, 0);
            Analytics.event("DashboardDocs");
            content = <DocsDashboard/>
        } else if (doc === "diff") {
            window.scrollTo(0, 0);
            Analytics.event("DiffDocs");
            content = <DocsDiff/>
        } else if (doc === "release-checklists") {
            window.scrollTo(0, 0);
            Analytics.event("DiffReleaseChecklists");
            content = <DocsReleaseChecklists/>
        } else if (doc === "lists") {
            window.scrollTo(0, 0);
            Analytics.event("ListsDocs");
            content = <DocsLists/>
        } else if (doc === "manual") {
            window.scrollTo(0, 0);
            Analytics.event("ManualDocs");
            content = <DocsManual/>
        } else if (doc === "resultsapi") {
            window.scrollTo(0, 0);
            Analytics.event("ResultsAPIDocs");
            content = <DocsResultsAPI/>
        } else if (doc === "junit-xml") {
            window.scrollTo(0, 0);
            Analytics.event("JUnitXMLDocs");
            content = <DocsJUnitXml/>
        } else if (doc === "xunit") {
            window.scrollTo(0, 0);
            Analytics.event("XUnitDocs");
            content = <DocsXUnit/>
        }  else if (doc === "csharp") {
            window.scrollTo(0, 0);
            Analytics.event("C#");
            content = <DocsCSharp/>
        } else if (doc === "kotlin") {
            window.scrollTo(0, 0);
            Analytics.event("Kotlin");
            content = <DocsKotlin/>
        } else if (doc === "python") {
            window.scrollTo(0, 0);
            Analytics.event("Python");
            content = <DocsPython/>  
        } else if (doc === "nodejs") {
            window.scrollTo(0, 0);
            Analytics.event("Node.js");
            content = <DocsJavaScript alt={true}/>  
        } else if (doc === "java") {
            window.scrollTo(0, 0);
            Analytics.event("Java");
            content = <DocsJava/>
        } else if (doc === "ruby") {
            window.scrollTo(0, 0);
            Analytics.event("Ruby");
            content = <DocsRuby/>
        } else if (doc === "swift") {
            window.scrollTo(0, 0);
            Analytics.event("Swift");
            content = <DocsSwift/>
        } else if (doc === "php") {
            window.scrollTo(0, 0);
            Analytics.event("PHP");
            content = <DocsPHP/>
        } else if (doc === "go") {
            window.scrollTo(0, 0);
            Analytics.event("Go");
            content = <DocsGo/>
        } else if (doc === "javascript") {
            window.scrollTo(0, 0);
            Analytics.event("JavaScript");
            content = <DocsJavaScript/>
        } else if (doc === "others") {
            window.scrollTo(0, 0);
            Analytics.event("OtherLanguages");
            content = <DocsOthers/>
        } else if (doc === "target") {
            window.scrollTo(0, 0);
            Analytics.event("TargetDocs");
            content = <DocsTarget/>
        } else if (doc === "structure") {
            window.scrollTo(0, 0);
            Analytics.event("StructureDocs");
            content = <DocsStructure/>
        } else if (doc === "project") {
            window.scrollTo(0, 0);
            Analytics.event("ProjectConfigurationDocs");
            content = <DocsProject/>
        } else if (docGroup === "api") {
            if (doc === "overview") {
                window.scrollTo(0, 0);
                Analytics.event("APIOverviewDocs");
                content = 
                    <DocsAPIOverview
                        overlay={this.props.overlay}
                    />
            } else if (doc === "auth") {
                window.scrollTo(0, 0);
                Analytics.event("APIAuthenticationDocs");
                content = 
                    <DocsAPIAuth
                        overlay={this.props.overlay}
                    />
            } else if (doc === "targets") {
                window.scrollTo(0, 0);
                Analytics.event("APITargetsDocs");
                content = 
                    <DocsAPITargets
                        overlay={this.props.overlay}
                    />
            } else if (doc === "results") {
                window.scrollTo(0, 0);
                Analytics.event("APIResultsDoc");
                content = 
                    <DocsAPIResults
                        overlay={this.props.overlay}
                    />                
            } else {
                window.scrollTo(0, 0);
                Analytics.event("APIOverviewDocs");
                content = 
                    <DocsAPIOverview
                        overlay={this.props.overlay}
                    />
            }
        } else if (doc === "targets") {
            window.scrollTo(0, 0);
            Analytics.event("TargetConfigurationDocs");
            content = <DocsTargets/>
        } else if (doc === "target-order") {
            window.scrollTo(0, 0);
            Analytics.event("TargetOrderDocs");
            content = <DocsTargetOrder/>
        } else if (doc === "build-consolidation") {
            window.scrollTo(0, 0);
            Analytics.event("BuildConsolidationDocs");
            content = <DocsBuildConsolidation/>
        } else if (doc === "result-interpretation") {
            window.scrollTo(0, 0);
            Analytics.event("ResultInterpretationDocs");
            content = <DocsResultInterpetation/>
        } else if (doc === "preferences") {
            window.scrollTo(0, 0);
            Analytics.event("PreferencesDocs");
            content = <DocsPreferences/>
        } else if (doc === "notifications") {
            window.scrollTo(0, 0);
            Analytics.event("NotificationsDocs");
            content = <DocsNotifications/>
        } else if (doc === "integrations") {
            window.scrollTo(0, 0);
            Analytics.event("IntegrationsDocs");
            content = <DocsIntegrations/>
        } else if (doc === "api-token") {
            window.scrollTo(0, 0);
            Analytics.event("APITokenDocs");
            content = <DocsAPIToken/>
        } else if (doc === "team-members") {
            window.scrollTo(0, 0);
            Analytics.event("TeamMembersDocs");
            content = <DocsTeamMembers/>
        } else if (doc === "sso") {
            window.scrollTo(0, 0);
            Analytics.event("SSODocs");
            content = <DocsSSO/>
        } else if (doc === "organization") {
            window.scrollTo(0, 0);
            Analytics.event("OrganizationDocs");
            content = <DocsOrganization/>
        } else if (doc === "audit-logs") {
            window.scrollTo(0, 0);
            Analytics.event("AuditLogsDocs");
            content = <DocsAuditLogs/>
        } else if (doc === "plan") {
            window.scrollTo(0, 0);
            Analytics.event("PlanConfigurationDocs");
            content = <DocsPlan/>
        } else if (doc === "payment-details") {
            window.scrollTo(0, 0);
            Analytics.event("PaymentDetailsDocs");
            content = <DocsPaymentDetails/>
        } else if (doc === "account-country") {
            window.scrollTo(0, 0);
            Analytics.event("AccountCountryDocs");
            content = <DocsAccountCountry/>
        } else if (doc === "invoices") {
            window.scrollTo(0, 0);
            Analytics.event("InvoicesDocs");
            content = <DocsInvoices/>
        } else if (doc === "support-policy") {
            window.scrollTo(0, 0);
            Analytics.event("SupportDocs");
            content = <DocsSupport/>
        } else if (doc === "refund-policy") {
            window.scrollTo(0, 0);
            Analytics.event("RefundDocs");
            content = <DocsRefund/>
        } else if (doc === "fair-billing-policy") {
            window.scrollTo(0, 0);
            Analytics.event("FairBillingDocs");
            content = <DocsFairBilling/>
        } else if (doc === "privacy") {
            window.scrollTo(0, 0);
            Analytics.event("PrivacyDocs");
            content = <DocsPrivacy/>
        } else if (doc === "security") {
            window.scrollTo(0, 0);
            Analytics.event("SecruityDocs");
            content = <DocsSecurity/>
        } else if (doc === "terms") {
            window.scrollTo(0, 0);
            Analytics.event("TermsDocs");
            content = <DocsTerms/>
        } else if (doc === "vendor-onboarding") {
            window.scrollTo(0, 0);
            Analytics.event("VendorOnboardingDocs");
            content = <DocsVendorOnboarding/>
        }  else if (doc === "exp") {
            window.scrollTo(0, 0);
            Analytics.event("ExpDocs");
            content = <ExpMain/>
        } else if (doc === "junit5") {
            window.scrollTo(0, 0);
            Analytics.event("JUnit5");
            content = <DocsJUnit5/>
        } else if (doc === "junit4") {
            window.scrollTo(0, 0);
            Analytics.event("JUnit4");
            content = <DocsJUnit4/>
        } else if (doc === "testng") {
            window.scrollTo(0, 0);
            Analytics.event("TestNG");
            content = <DocsTestNG/>
        } else if (doc === "pytest") {
            window.scrollTo(0, 0);
            Analytics.event("pytest");
            content = <DocsPytest/>
        } else if (doc === "robot") {
            window.scrollTo(0, 0);
            Analytics.event("robot");
            content = <DocsRobot/>
        } else if (doc === "vsunittf") {
            window.scrollTo(0, 0);
            Analytics.event("VSUnitTF");
            content = <DocsVSUnitTF/>
        } else if (doc === "nunit3") {
            window.scrollTo(0, 0);
            Analytics.event("NUnit3");
            content = <DocsNUnit3/>
        } else if (doc === "mocha") {
            window.scrollTo(0, 0);
            Analytics.event("Mocha");
            content = <DocsMocha/>
        } else if (doc === "jasmine") {
            window.scrollTo(0, 0);
            Analytics.event("Jasmine");
            content = <DocsJasmine/>
        }  else if (doc === "playwright") {
            window.scrollTo(0, 0);
            Analytics.event("playwright");
            content = <DocsPlaywright/>
        }  else if (doc === "jest") {
            window.scrollTo(0, 0);
            Analytics.event("Jest");
            content = <DocsJest/>
        } else if (doc === "wdio") {
            window.scrollTo(0, 0);
            Analytics.event("WebdriverIO");
            content = <DocsWdio/>
        } else if (doc === "codeceptjs") {
            window.scrollTo(0, 0);
            Analytics.event("CodeceptJS");
            content = <DocsCodeceptJS/>
        } else if (doc === "nightwatch") {
            window.scrollTo(0, 0);
            Analytics.event("Nightwatch");
            content = <DocsNightwatch/>
        } else if (doc === "waffle") {
            window.scrollTo(0, 0);
            Analytics.event("Waffle");
            content = <DocsWaffle/>
        } else if (doc === "protractor") {
            window.scrollTo(0, 0);
            Analytics.event("Protractor");
            content = <DocsProtractor/>
        } else if (doc === "cypress") {
            window.scrollTo(0, 0);
            Analytics.event("Cypress");
            content = <DocsCypress/>
        } else if (doc === "testcafe") {
            window.scrollTo(0, 0);
            Analytics.event("TestCafe");
            content = <DocsTestCafe/>
        } else if (doc === "xctest") {
            window.scrollTo(0, 0);
            Analytics.event("XCTest");
            content = <DocsXCTest/>
        }  else if (doc === "espresso") {
            window.scrollTo(0, 0);
            Analytics.event("Espresso");
            content = <DocsEspresso/>
        }  else if (doc === "postman") {
            window.scrollTo(0, 0);
            Analytics.event("postman");
            content = <DocsPostman/>
        }  else if (doc === "rspec") {
            window.scrollTo(0, 0);
            Analytics.event("RSpec");
            content = <DocsRSpec/>
        } else if (doc === "selenium") {
            window.scrollTo(0, 0);
            Analytics.event("Selenium");
            content = <DocsSelenium/>
        } else if (doc === "test-framework-developers") {
            window.scrollTo(0, 0);
            Analytics.event("test-framework-developers");
            content = <DocsTestFrameworkDevelopers/>
        } else if (doc === "tesults-json-data-standard") {
            window.scrollTo(0, 0);
            Analytics.event("tesults-json-data-standard");
            content = <DocsTesultsJSONDataStandard/>
        } else if (doc === "github-actions") {
            window.scrollTo(0, 0);
            Analytics.event("GithubActionsDocs");
            content = <DocsGithubActions/>
        } else if (doc === "slack") {
            window.scrollTo(0, 0);
            Analytics.event("SlackDocs");
            content = <DocsSlack/>
        } else if (doc === "jira") {
            window.scrollTo(0, 0);
            Analytics.event("JiraDocs");
            content = <DocsJira/>
        } else if (doc === "msteams") {
            window.scrollTo(0, 0);
            Analytics.event("MSTeamsDocs");
            content = <DocsMSTeams/>
        } else if (doc === "mattermost") {
            window.scrollTo(0, 0);
            Analytics.event("MattermostDocs");
            content = <DocsMattermost/>
        } else if (doc === "pagerduty") {
            window.scrollTo(0, 0);
            Analytics.event("PagerDutyDocs");
            content = <DocsPagerDuty/>
        } else if (doc === "pricing") {
            window.scrollTo(0, 0);
            Analytics.event("Pricing");
            content = 
                <DocsPricing
                    overlay={this.props.overlay}
                />
        } else if (doc === "developer-integrations") {
            window.scrollTo(0, 0);
            Analytics.event("DeveloperIntegrations");
            content = <DocsDeveloperIntegrations/>
        } else if (doc === "developer-integrations-upwork") {
            window.scrollTo(0, 0);
            Analytics.event("DeveloperIntegrationsUpwork");
            content = <DocsDeveloperIntegrationsUpwork/>
        } else {
            content = 
            <div>
                This is an invalid link. Please select an option from the menu or <NavLink to="/docs" target="_blank" className="tr-link-primary4 no-break">click here</NavLink> to go to getting started.
            </div>
        }
        return (
            <div className='docsWidth'>
                <DocsTitle docGroup={docGroup} doc={doc}/>
                {content}
            </div>
        );
    }
}

export default DocsMain;