/*global */
import React, { Component } from 'react'
import Loading from './Loading'
import Analytics from './Analytics'
import Request from './Request'
import Context from './Context'
import Cache from './Cache'

class ResultsConsolidation extends Component {
    static contextType = Context;

    constructor () {
        super ();
        this.state = {loading: false, targets: []}
        this.init = this.init.bind(this)
        this.project = this.project.bind(this)
        this.buildConsolidateEnable = this.buildConsolidateEnable.bind(this)
        this.replaceConsolidateEnable = this.replaceConsolidateEnable.bind(this)
    }
    
    componentDidMount () {
        this.init()
    }

    init () {
        let project = this.project()
        if (project === undefined) { return }
        this.setState({loading: true});
        Cache.request(this.context, Cache.data.targets, {id: project.id}, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({targets: targets, loading: false});
            }
        });
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    buildConsolidateEnable (target, enable) {
        let data = {
            id: target.id,
            created: target.created,
            consolidateBuild: enable
        };
        this.setState({loading: true});
        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({error: "Unable to edit target.", loading: false});
            } else {
                Analytics.event("EnableTargetBuildConsolidation");
                let targets = this.state.targets;
                for (let i = 0; i < targets.length; i++) {
                    let target = targets[i];
                    if (target.created === data.target.created) {
                        targets[i] = data.target;
                    }
                }
                if (enable === true) {
                    this.setState({targets: targets, loading: false});
                } else {
                    this.setState({targets: targets, loading: false}, () => this.replaceConsolidateEnable(target, false));
                }
            }
        });
    }

    replaceConsolidateEnable (target, enable) {
        let data = {
            id: target.id,
            created: target.created,
            consolidateReplace: enable
        };
        this.setState({loading: true});
        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({error: "Unable to edit target.", loading: false});
            } else {
                Analytics.event("EnableTargetReplaceConsolidation");
                let targets = this.state.targets;
                for (let i = 0; i < targets.length; i++) {
                    let target = targets[i];
                    if (target.created === data.target.created) {
                        targets[i] = data.target;
                    }
                }
                this.setState({targets: targets, loading: false});
            }
        });
    }

    render () {
        if (this.state.loading === true) {
            return (
                <div className="text-center">
                    <Loading/>
                </div>
            );
        }

        let selection = []
        this.state.targets.forEach(function (target) {
            let button = <button type="button" className="btn-confirm" onClick={() => {this.buildConsolidateEnable (target, true)}}>Enable</button>
            let replaceButton = <button type="button" className="btn-confirm" onClick={() => {this.replaceConsolidateEnable (target, true)}}>Enable</button>
            if (target.buildConsolidate !== undefined) {
                if (target.buildConsolidate === true) {
                    button = <button type="button" className="btn-cancel" onClick={() => {this.buildConsolidateEnable (target, false)}}>Disable</button>
                    replaceButton = <button type="button" className="btn-confirm" onClick={() => {this.replaceConsolidateEnable (target, true)}}>Enable</button>
                }
            }
            if (target.replaceConsolidate !== undefined) {
                if (target.replaceConsolidate === true) {
                    replaceButton = <button type="button" className="btn-cancel" onClick={() => {this.replaceConsolidateEnable (target, false)}}>Disable</button>
                }
            }
            if (target.buildConsolidate !== true) {
                replaceButton = <div></div>
            }
            
            selection.push(
            <tr key={target.created}>
                <td>{target.name}</td>
                <td className="pl-3">{button}</td>
                <td className="pl-3">{replaceButton}</td>
            </tr>);
        }.bind(this));

        return (
            <div style={{"maxWidth":"800px"}}>
                <h4>Results Consolidation By Build</h4>
                <p className="font15">Consolidate multiple test runs into a single test run for results reporting purposes. This is useful if you run multiple test runs for the same build or release, serially or in parallel, perhaps as part of your continuous integration system, and you do not want to generate separate test runs for each run.</p>
                <p className="font15">Enable consolidation for one or more targets below. Then if you submit test runs that share the same build name the results will be consolidated as one test run under that build name.</p>
                <p className="font15">Once consolidation is enabled you will also have the option to enable replacement. Enable replacement if you may be re-running a subset of test cases and rather than append these existing test cases to the consolidated test run, you want matching test cases to replace (overwrite) previous test cases for that build.</p>
                <table className="mt-5">
                <thead>
                    <tr style={{"textAlign":"left"}}>
                    <th scope="col"><span className="neutral4" style={{"fontWeight":"normal"}}>Target</span></th>
                    <th scope="col"><span className="neutral4" style={{"fontWeight":"normal"}}>Consolidation</span></th>
                    <th scope="col"><span className="neutral4" style={{"fontWeight":"normal"}}>Replacement</span></th>
                    </tr>
                </thead>
                <tbody>
                    {selection}
                </tbody>
                </table>
            </div>
        );
    }
}

export default ResultsConsolidation;