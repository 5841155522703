/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsAPIPurpose from './DocsAPIPurpose';
import Box from './Box';
import TabularData from './TabularData';

class DocsAPITargets extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Targets API</title>
                    <meta name="description" content="Learn how to use the Tesults Targets API."/>
                </Helmet>
                <DocsAPIPurpose/>
                <div>
                    <h2>Targets</h2>
                    <a className="site-link-primary2" href="#retrieve-targets">Retrieve targets</a>
                    <br/>
                    <a className="site-link-primary2" href="#create-target">Create target</a>
                    <br/>
                    <a className="site-link-primary2" href="#regenerate-target-token">Regenerate target token</a>
                    <br/>
                    <a className="site-link-primary2" href="#update-target">Update target</a>
                    <br/>
                    <a className="site-link-primary2" href="#delete-target">Delete target</a>
                    <br/>
                    <div className='mb-5' style={{"display":"flex", "flexWrap":"wrap"}}>
                        <div style={{"flex":"1"}}>
                            <a className="site-link-neutral2" href="# " name="retrieve-targets">
                                <div style={{"height":"60px"}}>&nbsp;</div>
                            </a>
                            <h3>Retrieve targets</h3>
                            <p>Retrieving <a href="/docs/target" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">targets</a> is useful for getting the id and names of all of the targets that belong to a project. When fetching <a href="/docs/api/results" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">results</a> the id of the target to fetch results for is required.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl ('token' to be replaced with API token)</div>
                            <pre className='docsCode wrap'>
                                curl https://www.tesults.com/api/targets<br/> 
                                -H "Authorization: Bearer token"
                            </pre>
                        </div>
                        <div style={{"width":"20px"}}></div>
                        <div style={{"flex":"1"}}>
                            <Box
                                bg={"neutral8bg"}
                                title={<span></span>}
                                content={
                                    <div>
                                        <h4>Endpoint</h4>
                                        <div className="mb-5 font15 neutral3 bold">https://www.tesults.com/api/targets</div>
                                        <div className="font15 neutral3">GET Method</div>
                                        <h4 className="mt-5">Headers</h4>
                                        <div>
                                            <p>The Authorization header is required for all requests to the Tesults API. See the <a href="/docs/api/auth" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">authentication</a> documentation for details.</p>
                                            <div style={{"display":"flex"}}>
                                                <div className="font15 netural3 mr-5">
                                                    <span className="neutral4">Name</span>
                                                    <br/>
                                                    Authorization
                                                </div>
                                                <div className="font15 netural3">
                                                    <span className="neutral4">Value</span>
                                                    <br/>
                                                    Bearer {'<token>'}
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-5">Parameters</h4>
                                        <TabularData
                                            data = {
                                                {
                                                    headers: [
                                                        {value: "Name", minWidth: "200px"},
                                                        {value: "Value", minWidth: "200px"},
                                                    ],
                                                    rows: [ 
                                                        {
                                                            cols: [
                                                                {value: "limit (optional)"},
                                                                {value: "A number (minimum value 1) to limit the number of targets returned. If no limit is supplied the default limit is 100."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "cursor (optional)"},
                                                                {value: "Pagination parameter. If a cursor is returned in the response data from a request that means more data is available. Add the value of the cursor returned in the response in the next request to retrieve the next set of data."}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        />
                                        <h4 className="mt-5">Response</h4>
                                        <p>Successful response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"data"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accentc6">200</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accentc6">"Success"</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"targets": [<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id": <code className="accentb4">{'<id>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": <code className="accentb4">{'<name>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;],<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"cursor": <code className="accentb4">{'<cursor>'}</code><br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Failure response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"error"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accenta6">401</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accenta6">"Unauthorized"</code>,<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Successful responses return JSON containing a <b>data</b> property. Error responses return JSON containing an <b>error</b> property. Both data and error values contain a <b>code</b> and <b>message</b> property, specifying the http status code and a message containing additional context.</p>
                                        <p>Successful responses also contain additional properties within data. The response includes an array of targets and cursor for pagination use if there is additional data available (more targets) to retrieve.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className='mb-5' style={{"display":"flex", "flexWrap":"wrap"}}>
                        <div style={{"flex":"1"}}>
                            <a className="site-link-neutral2" href="# " name="create-target">
                                <div style={{"height":"60px"}}>&nbsp;</div>
                            </a>
                            <h3>Create target</h3>
                            <p>Create a new <a href="/docs/target" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">target</a>.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl ('token' to be replaced with API token)</div>
                            <pre className='docsCode wrap'>
                                curl -X POST -d '{'{'}"name": "New target name"{'}'}'<br/>
                                -H "Content-Type: application/json"<br/>
                                -H "Authorization: Bearer token"<br/>
                                https://www.tesults.com/api/targets<br/>
                            </pre>
                            <p className='font14 neutral4'>Note that projects on the Free plan are limited to 5 targets in total and this limit cannot be increased. Projects on Standard and Plus plans are limited to 100 targets in total by default but you can have this limit raised, at no additional cost, by contacting help@tesults.com.</p>
                            <div className='neutral4 font12 mb-0'>Example with all parameters</div>
                            <pre className='docsCode wrap'>
                                curl -X POST -d '{'{'}<br/>
                                "name": "Target name",<br/>
                                "description": "Target description",<br/>
                                "group": ["Group A", "Group B"],<br/>
                                "public": false,<br/>
                                "build_consolidation": false,<br/>
                                "build_replacement": false,<br/>
                                "rawResultMap": 1664195396156<br/>
                                {'}'}'<br/>
                                -H "Content-Type: application/json"<br/>
                                -H "Authorization: Bearer token"<br/>
                                https://www.tesults.com/api/targets<br/>
                            </pre>
                        </div>
                        <div style={{"width":"20px"}}></div>
                        <div style={{"flex":"1"}}>
                            <Box
                                bg={"neutral8bg"}
                                title={<span></span>}
                                content={
                                    <div>
                                        <h4>Endpoint</h4>
                                        <div className="mb-5 font15 neutral3 bold">https://www.tesults.com/api/targets</div>
                                        <div className="font15 neutral3">POST Method</div>
                                        <h4 className="mt-5">Headers</h4>
                                        <div>
                                            <p>The Authorization header is required for all requests to the Tesults API. See the <a href="/docs/api/auth" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">authentication</a> documentation for details.</p>
                                            <div style={{"display":"flex"}}>
                                                <div className="font15 netural3 mr-5">
                                                    <span className="neutral4">Name</span>
                                                    <br/>
                                                    Authorization
                                                </div>
                                                <div className="font15 netural3">
                                                    <span className="neutral4">Value</span>
                                                    <br/>
                                                    Bearer {'<token>'}
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-5">Body</h4>
                                        <TabularData
                                            data = {
                                                {
                                                    headers: [
                                                        {value: "Name", minWidth: "200px"},
                                                        {value: "Value", minWidth: "200px"},
                                                    ],
                                                    rows: [ 
                                                        {
                                                            cols: [
                                                                {value: "name"},
                                                                {value: "Name of the new target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "description (optional)"},
                                                                {value: "A description of the new target. Useful for providing additional information about the target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "group (optional)"},
                                                                {value: "Set target group. Pass through an array of strings containing group names, a target can belong to multiple groups. Must contain at least one group."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "public (optional)"},
                                                                {value: "Set true to set privacy to public and false for private for the target. If set to public this only takes effect if both the organization and project are also set to public by an administrator."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "build_consolidation (optional)"},
                                                                {value: "Set true if want to enable build consolidation for this target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "build_replacement (optional)"},
                                                                {value: "Set true if want to enable build replacement for this target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "rawResultMap (optional)"},
                                                                {value: "Set the id of the result interpretation map to use with this target."}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        />
                                        <h4 className="mt-5">Response</h4>
                                        <p>Successful response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"data"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accentc6">200</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accentc6">"Success"</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"target": {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id": <code className="accentb4">{'<id>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": <code className="accentb4">{'<name>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"token": <code className="accentb4">{'<token>'}</code><br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Failure response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"error"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accenta6">401</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accenta6">"Unauthorized"</code>,<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Successful responses return JSON containing a <b>data</b> property. Error responses return JSON containing an <b>error</b> property. Both data and error values contain a <b>code</b> and <b>message</b> property, specifying the http status code and a message containing additional context.</p>
                                        <p>Successful responses also contain additional properties within data. The response includes the newly created target and the target token to use for uploading results data. This is the only time the token is transmitted so you must save it, if lost the token can be regenerated using the regeneration API.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className='mb-5' style={{"display":"flex", "flexWrap":"wrap"}}>
                        <div style={{"flex":"1"}}>
                            <a className="site-link-neutral2" href="# " name="regenerate-target-token">
                                <div style={{"height":"60px"}}>&nbsp;</div>
                            </a>
                            <h3>Regenerate target token</h3>
                            <p>Regenerate a <a href="/docs/target" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">target</a> token. Tokens are required for uploading results data.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl ('token' to be replaced with API token and ':target' to be replaced with target id)</div>
                            <pre className='docsCode wrap'>
                                curl -X POST<br/>
                                -H "Content-Type: application/json"<br/>
                                -H "Authorization: Bearer token"<br/>
                                https://www.tesults.com/api/<br/>
                                targets/:target/regenerate_token<br/>
                            </pre>
                        </div>
                        <div style={{"width":"20px"}}></div>
                        <div style={{"flex":"1"}}>
                            <Box
                                bg={"neutral8bg"}
                                title={<span></span>}
                                content={
                                    <div>
                                        <h4>Endpoint</h4>
                                        <div className="mb-5 font15 neutral3 bold">https://www.tesults.com/api/targets/:target/regenerate_token</div>
                                        <div className="font15 neutral3">POST Method</div>
                                        <h4 className="mt-5">Headers</h4>
                                        <div>
                                            <p>The Authorization header is required for all requests to the Tesults API. See the <a href="/docs/api/auth" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">authentication</a> documentation for details.</p>
                                            <div style={{"display":"flex"}}>
                                                <div className="font15 netural3 mr-5">
                                                    <span className="neutral4">Name</span>
                                                    <br/>
                                                    Authorization
                                                </div>
                                                <div className="font15 netural3">
                                                    <span className="neutral4">Value</span>
                                                    <br/>
                                                    Bearer {'<token>'}
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-5">Path</h4>
                                        <p>Replace :target with the target id of the target for which to regenerate a token.</p>
                                        <h4 className="mt-5">Parameters</h4>
                                        <TabularData
                                            data = {
                                                {
                                                    headers: [
                                                        {value: "Name", minWidth: "200px"},
                                                        {value: "Value", minWidth: "200px"},
                                                    ],
                                                    rows: [ 
                                                        
                                                    ]
                                                }
                                            }
                                        />
                                        <h4 className="mt-5">Response</h4>
                                        <p>Successful response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"data"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accentc6">200</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accentc6">"Success"</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"target": {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id": <code className="accentb4">{'<id>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": <code className="accentb4">{'<name>'}</code><br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"token": <code className="accentb4">{'<token>'}</code><br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Failure response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"error"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accenta6">401</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accenta6">"Unauthorized"</code>,<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Successful responses return JSON containing a <b>data</b> property. Error responses return JSON containing an <b>error</b> property. Both data and error values contain a <b>code</b> and <b>message</b> property, specifying the http status code and a message containing additional context.</p>
                                        <p>Successful responses also contain additional properties within data. The response includes the target for which the token has been regenerated and the token itself.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className='mb-5' style={{"display":"flex", "flexWrap":"wrap"}}>
                        <div style={{"flex":"1"}}>
                            <a className="site-link-neutral2" href="# " name="update-target">
                                <div style={{"height":"60px"}}>&nbsp;</div>
                            </a>
                            <h3>Update target</h3>
                            <p>Update <a href="/docs/target" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">target</a> properties.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl ('token' to be replaced with API token and ':target' to be replaced with target id)</div>
                            <pre className='docsCode wrap'>
                                curl -X POST -d '{'{'}"name": "New target name"{'}'}'<br/>
                                -H "Content-Type: application/json"<br/>
                                -H "Authorization: Bearer token"<br/>
                                https://www.tesults.com/api/targets/:target<br/>
                            </pre>
                            <div className='neutral4 font12 mb-0'>Example with all parameters</div>
                            <pre className='docsCode wrap'>
                                curl -X POST -d '{'{'}<br/>
                                "name": "Target name",<br/>
                                "description": "Target description",<br/>
                                "group": ["Group A", "Group B"],<br/>
                                "public": false,<br/>
                                "build_consolidation": false,<br/>
                                "build_replacement": false,<br/>
                                "rawResultMap": 1664195396156<br/>
                                {'}'}'<br/>
                                -H "Content-Type: application/json"<br/>
                                -H "Authorization: Bearer token"<br/>
                                https://www.tesults.com/api/targets/:target<br/>
                            </pre>
                        </div>
                        <div style={{"width":"20px"}}></div>
                        <div style={{"flex":"1"}}>
                            <Box
                                bg={"neutral8bg"}
                                title={<span></span>}
                                content={
                                    <div>
                                        <h4>Endpoint</h4>
                                        <div className="mb-5 font15 neutral3 bold">https://www.tesults.com/api/targets/:target</div>
                                        <div className="font15 neutral3">POST Method</div>
                                        <h4 className="mt-5">Headers</h4>
                                        <div>
                                            <p>The Authorization header is required for all requests to the Tesults API. See the <a href="/docs/api/auth" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">authentication</a> documentation for details.</p>
                                            <div style={{"display":"flex"}}>
                                                <div className="font15 netural3 mr-5">
                                                    <span className="neutral4">Name</span>
                                                    <br/>
                                                    Authorization
                                                </div>
                                                <div className="font15 netural3">
                                                    <span className="neutral4">Value</span>
                                                    <br/>
                                                    Bearer {'<token>'}
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-5">Body</h4>
                                        <TabularData
                                            data = {
                                                {
                                                    headers: [
                                                        {value: "Name", minWidth: "200px"},
                                                        {value: "Value", minWidth: "200px"},
                                                    ],
                                                    rows: [ 
                                                        {
                                                            cols: [
                                                                {value: "name (optional)"},
                                                                {value: "Name of the target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "description (optional)"},
                                                                {value: "A description of the target. Useful for providing additional information about the target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "group (optional)"},
                                                                {value: "Set target group. Pass through an array of strings containing group names, a target can belong to multiple groups. . Must contain at least one group."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "public (optional)"},
                                                                {value: "Set true to set privacy to public and false for private for the target. If set to public this only takes effect if both the organization and project are also set to public by an administrator."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "build_consolidation (optional)"},
                                                                {value: "Set true if want to enable build consolidation for this target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "build_replacement (optional)"},
                                                                {value: "Set true if want to enable build replacement for this target."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "rawResultMap (optional)"},
                                                                {value: "Set the id of the result interpretation map to use with this target."}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        />
                                        <h4 className="mt-5">Response</h4>
                                        <p>Successful response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"data"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accentc6">200</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accentc6">"Success"</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"target": {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"id": <code className="accentb4">{'<id>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": <code className="accentb4">{'<name>'}</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Failure response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"error"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accenta6">401</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accenta6">"Unauthorized"</code>,<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Successful responses return JSON containing a <b>data</b> property. Error responses return JSON containing an <b>error</b> property. Both data and error values contain a <b>code</b> and <b>message</b> property, specifying the http status code and a message containing additional context.</p>
                                        <p>Successful responses also contain additional properties within data. The response includes the updated target.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className='mb-5' style={{"display":"flex", "flexWrap":"wrap"}}>
                        <div style={{"flex":"1"}}>
                            <a className="site-link-neutral2" href="# " name="delete-target">
                                <div style={{"height":"60px"}}>&nbsp;</div>
                            </a>
                            <h3>Delete target</h3>
                            <p>Delete a <a href="/docs/target" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">target</a> including the test runs and all test case data for the target.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl ('token' to be replaced with API token and ':target' to be replaced with target id)</div>
                            <pre className='docsCode wrap'>
                                curl -X DELETE<br/>
                                -H "Content-Type: application/json"<br/>
                                -H "Authorization: Bearer token"<br/>
                                https://www.tesults.com/api/targets/:target
                            </pre>
                        </div>
                        <div style={{"width":"20px"}}></div>
                        <div style={{"flex":"1"}}>
                            <Box
                                bg={"neutral8bg"}
                                title={<span></span>}
                                content={
                                    <div>
                                        <h4>Endpoint</h4>
                                        <div className="mb-5 font15 neutral3 bold">https://www.tesults.com/api/targets/:target</div>
                                        <div className="font15 neutral3">DELETE Method</div>
                                        <h4 className="mt-5">Headers</h4>
                                        <div>
                                            <p>The Authorization header is required for all requests to the Tesults API. See the <a href="/docs/api/auth" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">authentication</a> documentation for details.</p>
                                            <div style={{"display":"flex"}}>
                                                <div className="font15 netural3 mr-5">
                                                    <span className="neutral4">Name</span>
                                                    <br/>
                                                    Authorization
                                                </div>
                                                <div className="font15 netural3">
                                                    <span className="neutral4">Value</span>
                                                    <br/>
                                                    Bearer {'<token>'}
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-5">Path</h4>
                                        <p>Replace :target with the target id of the target to be deleted.</p>
                                        <h4 className="mt-5">Parameters</h4>
                                        <TabularData
                                            data = {
                                                {
                                                    headers: [
                                                        {value: "Name", minWidth: "200px"},
                                                        {value: "Value", minWidth: "200px"},
                                                    ],
                                                    rows: [ 
                                                        
                                                    ]
                                                }
                                            }
                                        />
                                        <h4 className="mt-5">Response</h4>
                                        <p>Successful response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"data"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accentc6">200</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accentc6">"Success"</code>,<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Failure response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"error"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accenta6">401</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accenta6">"Unauthorized"</code>,<br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Successful responses return JSON containing a <b>data</b> property. Error responses return JSON containing an <b>error</b> property. Both data and error values contain a <b>code</b> and <b>message</b> property, specifying the http status code and a message containing additional context.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsAPITargets;