/*global */
import React, { Component } from 'react';
import Case from './Case';
import CaseGroupHeader1 from './CaseGroupHeader1';
import CaseGroupHeader2 from './CaseGroupHeader2';

class CaseGroup extends Component {
    constructor() {
        super();
        this.state = {collapse: false};
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    componentDidMount () {
        if (this.props.collapseAll !== undefined) {
            if (this.props.collapseAll === true && this.state.collapse === false) {
                this.setState({collapse: true});
            }
            if (this.props.collapseAll === false && this.state.collapse === true) {
                this.setState({collapse: false});
            }
        }
    }

    componentDidUpdate () {
        if (this.props.collapseAll !== undefined) {
            if (this.props.collapseAll === true && this.state.collapse === false) {
                this.setState({collapse: true});
            }
            if (this.props.collapseAll === false && this.state.collapse === true) {
                this.setState({collapse: false});
            }
        }
    }

    componentWillReceiveProps (props) {
        /*this.props = props;
        let bugs = [];
        if (props.case.bugs !== undefined) {
            this.props.case.bugs.forEach(function (b) {
                bugs.push(b);
            });
        }
        this.setState({state: "view", bugText: "", bugs: bugs});*/
    }

    toggleCollapse () {
        this.setState({collapse: (this.state.collapse === true ? false : true)}, () => {
            if (this.props.invalidateCollapseAll !== undefined) {
                this.props.invalidateCollapseAll();
            }
        });
    }

    render () {
        if (this.props.cases === undefined) {
            return <span></span>
        }
        if (this.props.cases.length === 0) {
            return <span></span>
        }

        let propsCases = []
        for (let i = 0; i < this.props.cases.length; i++) {
            propsCases.push(this.props.cases[i])
        }

        if (this.props.groupSort !== undefined) {
            let sortOrder = this.props.groupSort.toLowerCase()
            propsCases.sort((a, b) => {
                if (a[sortOrder] < b[sortOrder]) {
                    return a[sortOrder] - b[sortOrder];
                }
            })
        }

        let cases = [];
        for (let i = 0; i < propsCases.length ; i++) {
            let c = propsCases[i];
            let checked = false;
            if (c !== undefined && this.props.multiselect !== undefined) {
                checked = (this.props.multiselect[c.num] === true) ? true : false
            }
            let refValue = undefined
            if (this.props.updatedCaseHash !== undefined) {
                if ((this.props.updatedCaseHash === c.hash) || (this.props.updatedCaseHash === (c.num + ""))) {
                    refValue = this.props.updatedCaseRef
                }
            }
            cases.push(
                <Case 
                    key={i}
                    updatedCaseRef={refValue}
                    updated={refValue !== undefined ? true : false}
                    caseBorder={i === 0 ? false : true}
                    selectCase={this.props.selectCase}
                    case={c}
                    context={this.props.context}
                    multiselect={this.props.multiselect}
                    multiselectChange={this.props.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    checked={checked}
                    members={this.props.members}
                    overlay={this.props.overlay}
                    projects={this.props.projects}
                    projectIndex={this.props.projectIndex}
                    targets={this.props.targets}
                    targetIndex={this.props.targetIndex}
                    runs={this.props.runs} 
                    runIndex={this.props.runIndex}
                    rawResultMaps={this.props.rawResultMaps}
                    preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    messageOverlay={this.props.messageOverlay}
                    periodIndex={this.props.periodIndex}
                    side={this.props.side}
                    targetDetail={this.props.targetDetail}
                />
            );
        }
        let title = "";
        if (propsCases.length > 0) {
            title = propsCases[0].suite;
        }
        if (this.props.omitTitle === true) {
            title = "";
        }
        if (this.props.titleOverride !== undefined) {
            title = this.props.titleOverride;
        }

        if (this.state.collapse === true) {
            cases = <span></span>
        }


        //let marginBottom = "";
        //if (this.props.titleOverride === "Unresolved") {
            //marginBottom = "";
        //}

        return (
            <div className={"case-group"}>
                <CaseGroupHeader1 
                    title={title} 
                    cases={propsCases} 
                    prevCases={this.props.prevCases}
                    duration={this.props.duration}
                    intervalStart={this.props.intervalStart}
                    intervalEnd={this.props.intervalEnd}
                    collapse={this.state.collapse}
                    toggleCollapse={this.toggleCollapse}
                    context={this.props.context}
                    addCaseToSuite={this.props.addCaseToSuite}
                    totalOnly={this.props.totalOnly}
                    percentClass={this.props.percentClass}
                    multiselect={this.props.multiselect}
                    multiselectGroup={this.props.multiselectGroup}
                    multiselectChange={this.props.multiselectChange}
                    multiselectEnabled={this.props.multiselectEnabled}
                    targets={this.props.targets}
                    targetIndex={this.props.targetIndex}
                    rawResultMaps={this.props.rawResultMaps}
                    persistentSuiteDataUpdate={this.props.persistentSuiteDataUpdate}
                    persistentSuiteDataIteration={this.props.persistentSuiteDataIteration}
                    persistentSuiteData={this.props.persistentSuiteData}
                    caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
                    runs={this.props.runs}
                    runIndex={this.props.runIndex}
                />
                <div className="case-group-cases">
                    {cases}
                </div>
            </div>
        );
    }
};

export default CaseGroup;