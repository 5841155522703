/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import Loading from './Loading';
import Request from './Request';

class Webhooks extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {scope: "project", targetIndex: 0, selectedService: undefined, error: undefined, loading: false, user: user, saved: true, webhooks: undefined, webhooksModified: undefined, supportedServices: ["msteams", "mattermost"]};
        this.webhooks = this.webhooks.bind(this);
        this.serviceView = this.serviceView.bind(this);
        this.servicesView = this.servicesView.bind(this);
        this.scopeToggle = this.scopeToggle.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.save = this.save.bind(this);
        this.addUrl = this.addUrl.bind(this);
        this.urlChange = this.urlChange.bind(this);
        this.removeUrl = this.removeUrl.bind(this); 
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.webhooks();
    }

    webhooks () {
        if (this.props.project === undefined) {
            return;
        }
        let data = {project: this.props.project.id};
        if (this.state.scope !== "project") {
            if (this.state.targetIndex < this.props.targets.length) {
                data.target = this.props.targets[this.state.targetIndex].created;
            } else {
                return;
            }
        }
        this.setState({loading: true});
        Request.get("/webhooks", data, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch webhooks."});
            } else {
                let webhooks = data.webhooks;
                let webhooksModified = {};
                Object.keys(data.webhooks).forEach(function (key) {
                    if (key === "msteams" || key === "mattermost") {
                        let urls = [];
                        for (let i = 0; i < webhooks[key].length; i++) {
                            urls.push(webhooks[key][i]);
                        }
                        webhooksModified[key] = urls;
                    } else {
                        webhooksModified[key] = data.webhooks[key];
                    }
                    
                });
                this.setState({loading: false, webhooks: webhooks, webhooksModified: webhooksModified});
            }
        });
    }

    scopeToggle () {
        let scope = this.state.scope;
        if (scope === "project") {
            this.setState({scope: "target"}, () => this.webhooks());
        } else {
            this.setState({scope: "project"}, () => this.webhooks());
        }
    }

    targetChange (e) {
        this.setState({targetIndex: e.target.value}, () => this.webhooks());
    }

    urlChange (service, index, e) {
        let webhooksModified = this.state.webhooksModified;
        webhooksModified[service][index] = e.target.value;
        this.setState({webhooksModified: webhooksModified});
    }

    addUrl (service) {
        let webhooksModified = this.state.webhooksModified;
        if (webhooksModified[service].push(""));
        this.setState({webhooksModified: webhooksModified});
    }

    removeUrl (service, index) {
        let webhooksModified = this.state.webhooksModified;
        webhooksModified[service].splice(index, 1);
        this.setState({webhooksModified: webhooksModified});
    }

    save (service) {
        if (this.props.project === undefined) {
            return;
        }
        let data = {project: this.props.project.id};
        if (this.state.scope !== "project") {
            if (this.state.targetIndex < this.props.targets.length) {
                data.target = this.props.targets[this.state.targetIndex].created;
            } else {
                return;
            }
        }
        data.webhook = service;
        data.urls = this.state.webhooksModified[service];
        this.setState({loading: true});
        Request.post("/webhooks", data, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to update webhooks."});
            } else {
                let webhooksModified = this.state.webhooksModified;
                webhooksModified[service] = data.webhooks[service];
                this.setState({loading: false, webhooks: data.webhooks, webhooksModified: webhooksModified});
            }
        });
    }

    serviceView (service) {
        let title = "";
        switch (service) {
            case "msteams":
                title = "MS Teams";
                break;
            case "mattermost":
                title = "Mattermost";
                break;
            default:
                break;
        }
        let urls = [];
        if (this.state.webhooksModified !== undefined) {
            if (Array.isArray(this.state.webhooksModified[service])) {
                for (let i = 0; i < this.state.webhooksModified[service].length; i++) {
                    urls.push(<div className="mb-3" key={i}><input className="width75" value={this.state.webhooksModified[service][i]} onChange={(e) => {this.urlChange(service, i, e)}}/>&nbsp;&nbsp;<span className="tr-link-neutral4 bold small" onClick={() => this.removeUrl(service, i)}>Remove</span><br/></div>)
                }
            }
        }
        let disabled = true;
        let savedUrls = this.state.webhooks[service];
        let currentUrls = this.state.webhooksModified[service];
        if (savedUrls.length !== currentUrls.length) {
            disabled = false;
        } else {
            for (let i = 0; i < savedUrls.length; i++) {
                if (savedUrls[i] !== currentUrls[i]) {
                    disabled = false;
                }
            }
        }
        let save = <span></span>
        if (disabled !== true) {
            save = <span className="tr-link-primary1 bold small" onClick={() => {this.save(service)}}>Save changes</span>
        }
        return (
            <div>
                <h4 className="neutral3 mb-3">{title}</h4>
                {urls}
                <button type="button" className="btn btn-confirm small mt-3 mb-3" onClick={() => {this.addUrl(service)}}>Add a webhook</button><span className="ml-5">{save}</span>
                <hr/>
            </div>
        )
    }

    servicesView () {
        let views = [];
        for (let i = 0; i < this.state.supportedServices.length; i++) {
            let view = this.serviceView(this.state.supportedServices[i]);
            views.push(<div key={i}>{view}</div>) 
        }
        return views;
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        } else {
            if (this.state.webhooks === undefined) {
                return <span></span>
            }
            let scopeMessage = <div>
                                    <p className="neutral1 mb-3">The webhooks added here will receive a message from Tesults everytime new results are generated for the project.</p>
                                </div>

            let scopeButtonLabel = "Switch to target Scope";
            let targetSelect = <span></span>;
            if (this.state.scope !== "project") {
                scopeButtonLabel = "Switch to project Scope";
                let index = 0;
                let targetOptions = [];
                this.props.targets.forEach(function (target) {
                    targetOptions.push(<option key={index} value={index}>{target.name}</option>);
                    index += 1;
                });
                
                if (targetOptions.length === 0) {
                    targetSelect =
                        <div>
                            <p>There are no targets for this project. You can create them here.</p>
                            <button type="button" className="btn btn-confirm mt-3" onClick={this.createTarget}>Create New Target</button>
                            <br/>
                            <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>

                            <hr/>
                            <NavLink to="/docs/target" target="_blank" className="neutral4">What is a target?</NavLink>
                        </div>
                } else {
                    scopeMessage = <div>
                                    <p className="mb-3">The webhooks added here will receive a message from Tesults everytime new results are generated for the selected target only.</p>
                                </div>

                    targetSelect = <div className="mb-3">
                            <select className="custom-select" onChange={this.targetChange} value={this.state.targetIndex}>
                                {targetOptions}
                            </select>
                        </div>
                }
            }

            if (this.props.project === undefined) {
                return (
                    <div className="whitebg p-4 mt-3">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <p>You must <NavLink to="/createProject" className="nounderline neutral1">create a project</NavLink> first to configure webhooks.</p>
                            </div>
                        </div>
                    </div>
                );
            } else {
                let error = this.props.error;
                if (error === undefined) {
                    error = this.state.error;
                }
                if (error === undefined) {
                    error = "";
                }
                return (
                    <div className="whitebg p-4 mt-3">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h4 className="mb-3 neutral3">Webhooks</h4>
                                {scopeMessage}
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-6">
                                        {targetSelect}
                                    </div>
                                </div>
                                <p><button type="button" className="btn-cancel" onClick={() => {this.scopeToggle()}} disabled={this.state.loading}>{scopeButtonLabel}</button></p>
                                <hr/>
                                {this.servicesView()}
                                <br/>
                                <button type="button" className="btn-cancel" onClick={() => {this.props.back()}}>Back</button>
                                <br/>
                                <br/>
                                {error}
                                <br/>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default Webhooks;