/*global */
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import AutoNotify from './AutoNotify';
import ChangeNotify from './ChangeNotify';

class ConfigNotifications extends Component {
    render () {
        return (
            <div style={{"maxWidth":"800px"}}>
                <AutoNotify/>
                <ChangeNotify type="change"/>
                <ChangeNotify type="failure"/>
            </div>
        );
    }
};

export default ConfigNotifications