/*global */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import Constants from './Constants.js'

class DocsResultInterpretationSupport extends Component {
    constructor () {
        super()
        this.suggestedMinimumMapping = this.suggestedMinimumMapping.bind(this)
        this.renderSingle = this.renderSingle.bind(this)
        this.renderAll = this.renderAll.bind(this)
    }

    suggestedMinimumMapping (testFramework) {
        if (testFramework === undefined) { return [] }
        let mapping = []
        Object.keys(testFramework.min_map).forEach((key) => {
            mapping.push(
                <div key={key} className="flex-row mb-1 neutral4">
                    <div className='mr-3'>
                        {testFramework.min_map[key].key}
                    </div>
                    <div className='mr-3'>&rarr;</div>
                    <div className='mr-3' style={{"width":"100px"}}>
                        {testFramework.min_map[key].value}
                    </div>
                </div>
            )
        })
        return mapping
    }

    renderSingle (integration) {
        let testFramework = undefined
        if (integration) {
            testFramework = Constants.rawResultMapIntegrationSupport[integration]
        }
        let retValue =  
            <div>
                <p><NavLink to="/docs/result-interpretation" target="_blank" className="tr-link-primary4 no-break no-break">Result interpretation</NavLink> is not currently supported by this integration. If you are interested in support please contact help@tesults.com.</p>
            </div>
        if (testFramework) {
            retValue =
            <div>
                <p><NavLink to="/docs/result-interpretation" target="_blank" className="tr-link-primary4 no-break no-break">Result interpretation</NavLink> is supported by {testFramework.name}. Install version {testFramework.min_version} or newer. If you use result interpretation we recommend you add these minimum mapping values:</p>
                <p>{this.suggestedMinimumMapping(testFramework)}</p>
            </div>
        }
        return retValue
    }

    renderAll () {
        let mappings = []
        Object.keys(Constants.rawResultMapIntegrationSupport).forEach((testFramework) => {
            let values = Constants.rawResultMapIntegrationSupport[testFramework]
            if (values) {
                mappings.push(
                    <div key={testFramework} className='flex-row mb-3'>
                        <div style={{"width":"200px"}} className='mr-3'>
                            {values.name}
                        </div>
                        <div style={{"width":"200px"}} className='mr-3'>
                            {values.min_version}+
                        </div>
                        <div className='mr-3'>
                            {this.suggestedMinimumMapping(values)}
                        </div>
                    </div>
                )
            }
        })
        return (
            <div>
                <div key="header" className='flex-row mb-3 neutral4 bold'>
                    <div style={{"width":"200px"}} className='mr-3'>
                        Integration
                    </div>
                    <div style={{"width":"200px"}} className='mr-3'>
                        Minimum version
                    </div>
                    <div className='mr-3'>
                        Suggested minimum mapping
                    </div>
                </div>
                {mappings}
            </div>
        )
    }

    render () {
        if (this.props.integration === "all") {
            return this.renderAll()
        } else {
            return this.renderSingle(this.props.integration)
        }
    }
}

export default DocsResultInterpretationSupport