/*global Cookies*/
import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom' 
import Pricing from './Pricing';
import Analytics from './Analytics';
import Loading from './Loading';
import Copy from './Copy';
import Checkout from './Checkout';
import Request from './Request';
import Box from './Box';
import Confirmation from './Confirmation';

class CreateProject extends Component {
    constructor () {
        super();
        this.state = {state: null, error: undefined, name: "", orgName: "-", product: "", card: null, submitting: false, eligible: false, quote: null, billingCycle: null, verified: null, isAdminOnExistingProject: false,
        confirmation: {success: undefined, failure: undefined}, verifyButtonDisabled: false
        };
        this.changeName = this.changeName.bind(this);
        this.productSelect = this.productSelect.bind(this);
        this.confirmFree = this.confirmFree.bind(this);
        this.backToProductSelect = this.backToProductSelect.bind(this);
        this.projectCreated = this.projectCreated.bind(this);
        this.productSelected = this.productSelected.bind(this);
        this.cancel = this.cancel.bind(this);
        this.checkVerifiedEmail = this.checkVerifiedEmail.bind(this);
        this.resendVerification = this.resendVerification.bind(this);
        this.isAdminOnExistingProject = this.isAdminOnExistingProject.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        Analytics.event("CreateProject");
        this.checkVerifiedEmail();
        this.isAdminOnExistingProject();
    }

    checkVerifiedEmail () {
        this.setState({verifyButtonDisabled: true});
        Request.get("/verifiedEmail", undefined, (err, data) => {
            this.setState({verifyButtonDisabled: false});
            if (err) {
                this.setState({verified: false, confirmation: {success: undefined, failure: "Unable to check if email verified."}});
            } else {
                let newState = {verified: data.verified};
                if (data.verified === true) {
                    newState.confirmation = { success: "Email verified", failure: undefined};
                } else {
                    newState.confirmation = { success: undefined, failure: "Email has not been verified"};
                }
                this.setState(newState);
            }
        });
    }

    resendVerification () {
        const user = Cookies.getJSON("truser");
        let data = {};
        data.email = user.email;
        this.setState({verifyButtonDisabled: true});
        Request.post("/resendverification", data, (err, data) => {
            this.setState({verifyButtonDisabled: false});
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to resend verification email."}});
            } else {
                Analytics.event("ResentVerification");
                this.setState({confirmation: {success: "Check your inbox, a verification email has been sent to " + user.email + ". If this email address is incorrect please correct it by clicking 'Account' on the menu.", failure: undefined}});
            }
        });
    }

    isAdminOnExistingProject () {
        Request.get('/projectsAdmin', undefined, function (err, data) {
            if (err) {
                // do nothing
            } else {
                if (data.projects.length > 0) {
                    this.setState({isAdminOnExistingProject: true});
                } else {
                    // do nothing
                }
            }
        }.bind(this));
    }

    changeName (e) {
        this.setState({name: e.target.value});
    }

    productSelect () {
        if (this.state.name.length === 0) {
            this.setState({error: "Enter a name for your project."});
            return;
        }

        Analytics.event("ProductSelect");
        this.setState({state: "productselect", eligible: false, error: undefined});
    }

    productSelected (product) {
        Analytics.event("ProductSelected");
        if (product === "free-v1") {
            this.confirmFree();
        } else if (product === "enterprise") {
            this.setState({state: "enterprise"});
        } else {
            this.setState({state: "paid", product: product});
        }
    }

    confirmFree () {
        this.setState({submitting:true});
        let data = {};
        data.name = this.state.name;
        data.orgName = this.state.orgName;
        data.product = "free-v1";
        data.activeUsers = 1;
        data.minUsers = 1;
        Request.post("/createProject", data, (err, data) => {
            if (err) {
                this.setState({error: "Unable to create project. Please contact us at help@tesults.com. " + err, submitting:false});
            } else {
                Analytics.event("CreatedProject");
                this.setState({newTargetToken: data.token, state: "created", submitting: false});
                function gtag_report_conversion(url) {
                    var callback = function () {
                        if (typeof(url) != 'undefined') {
                        window.location = url;
                        }
                    };
                    if (window.gtag !== undefined) {
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-870629148/64g8CPDap9gCEJz-kp8D',
                            'event_callback': callback
                        });
                        return false;
                    } else {
                        return false;
                    }
                }
                gtag_report_conversion();
            }
        });
    }

    projectCreated (newTargetToken) {
        this.setState({newTargetToken: newTargetToken, state: "created", submitting: false});
    }

    backToProductSelect () {
        this.setState({state: "productselect"});  
    }

    cancel () {
        this.setState({state: null});
    }

    render () {
        if (this.state.state === null) {
            if (this.props.projectCreation === false) {
                return (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-7 col-lg-6">
                            <h6>Project creation unavailable</h6>
                            <p>One of the projects you are a member of has single-sign on enabled and project creation is disabled for members.</p>
                        </div>
                    </div>
                );
            } else if (this.state.verified === null) {
                return <Loading/>
            } else if (this.state.verified !== true) {
                return (
                    <div style={{"minHeight":"100vh"}}>
                        <h3 className="neutral1 mb-5">Verify your email to create a project</h3>
                        <p className="font15 mb-5">Check your inbox for an email from Tesults containing a verification link.</p>
                        <div style={{"display":"flex"}} className="mb-5">
                            <div>
                                <button type="button" className="btn btn-cancel mt-3 mb-5 mr-3" onClick={this.resendVerification} disabled={this.state.verifyButtonDisabled}>Resend verification email</button>
                            </div>
                            <div>
                                <button type="button" className="btn btn-confirm mt-3 mb-5" onClick={this.checkVerifiedEmail} disabled={this.state.verifyButtonDisabled}>I have now completed verification</button>
                            </div>
                        </div>
                        <div className="mt-3" style={{"maxWidth":"600px"}}>
                            <Confirmation confirmation={this.state.confirmation}/>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{"minHeight":"100vh"}}>
                        <div className='text-center' style={{"width":"700px", "margin":"auto"}}>
                            <img src="/img/new-project-icon.svg" width="150px" height="150px"/>
                            <h2>Create new project</h2>
                            <div className='mb-3'>Name your project. You can always change this later. We recommend naming the project the name of your company or organization. <NavLink to="/docs/structure" target="_blank" className="tr-link-primary4">Read why</NavLink>.</div>
                            <input type="text" className="tr-input mb-3" value={this.state.name} onChange={this.changeName} required/>
                            <div className="mb-3 neutral4 font15" id="error">{this.state.error}</div>
                            <button type="button" className="mb-3 btn-confirm-index" onClick={this.productSelect}>Next</button>
                            {this.state.isAdminOnExistingProject !== true ?
                            <span></span>
                            :
                            <div className='pt-5'>
                                Most teams only need one project. <NavLink to="/docs/structure" target="_blank" className="tr-link-primary4">Read why</NavLink>.
                            </div>
                            }
                        </div>
                    </div>
                );
            }
        } else if (this.state.state === "productselect") {
            return (
                <div className="row">
                    <div className="col-12">
                        {
                            this.state.error === undefined ? 
                                <span></span>
                            :
                                <Box
                                    bg={"whitebg neutral4 font15"}
                                    content={this.state.error}
                                />
                         
                        }
                        <Pricing 
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            confirm={this.productSelected}
                        />
                    </div>
                </div>
            );
        } else if (this.state.state === "free") {
            window.scrollTo(0, 0);
            return (
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <p>{this.state.name}</p>
                        <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={this.confirmFree} disabled={this.state.submitting}>Confirm</button>
                        <button type="button" className="btn btn-cancel mt-3" onClick={this.backToProductSelect} disabled={this.state.submitting}>Back to plan select</button>
                        <br/>
                        <div className="accenta1" id="error">{this.state.error}</div>
                    </div>
                </div>
            );
        }  else if (this.state.state === "enterprise") {
            window.scrollTo(0, 0);
            return (
                <div className="row">
                    <div className="col-12 col-sm-6 col-sm-6 col-lg-6">
                        <h4>Enterprise</h4>
                        <p>Thank you for considering Tesults Enterprise. Contact <a className="primary2 bold" href="mailto:enterprise@tesults.com">enterprise@tesults.com</a> and a team member will get right back to you.</p>
                        <button type="button" className="btn btn-cancel mt-3" onClick={this.backToProductSelect} disabled={this.state.submitting}>Back to plan select</button>
                        <br/>
                        <br/>
                        <div className="accenta1" id="error">{this.state.error}</div>
                    </div>
                </div>
            );
        } else if (this.state.state === "paid") {
            window.scrollTo(0, 0);
            return (
                <Checkout 
                    backToProductSelect={this.backToProductSelect}
                    cancel={this.cancel}
                    product={this.state.product}
                    complete={this.projectCreated}
                    type={"createProject"}
                    name={this.state.name}
                    orgName={this.state.orgName}
                    overlay={this.props.overlay}
                    messageOverlay={this.props.messageOverlay}
                    newPlan={true}
                />
            );
        } else if (this.state.state === "created") {
            window.scrollTo(0, 0);
            return (
                <div style={{"maxWidth":"600px"}}>
                    <h4>New project created</h4>
                    <p>To send automated test results to your new project a default target and token has been created. This is the only time the token will be displayed. Copy it now and keep it safe. You can regenerate the token from the configuration menu.</p>
                    <span className="font15">Target token </span><Copy text={this.state.newTargetToken}/>
                    <br/>
                    <div><textarea className="tr-text-area textareaNoResize p-3" rows="10" cols="40" value={this.state.newTargetToken} readOnly/></div>
                    <h4 className="mt-5">Final Step</h4>
                    <p>You are almost done setting up your project! The only thing left to do is add a small amount of code to your test code. Select the language you use and complete the instructions outlined:</p>
                    <Link target="_blank" to="/docs/java"><button type="button" className="btn btn-cancel mb-3 mr-3">Java</button></Link>
                    <Link target="_blank" to="/docs/csharp"><button type="button" className="btn btn-cancel mb-3 mr-3">C#</button></Link>
                    <Link target="_blank" to="/docs/python"><button type="button" className="btn btn-cancel mb-3 mr-3">Python</button></Link>
                    <Link target="_blank" to="/docs/ruby"><button type="button" className="btn btn-cancel mb-3 mr-3">Ruby</button></Link>
                    <Link target="_blank" to="/docs/swift"><button type="button" className="btn btn-cancel mb-3 mr-3">Swift</button></Link>
                    <Link target="_blank" to="/docs/nodejs"><button type="button" className="btn btn-cancel mb-3 mr-3">Node.js</button></Link>
                    <Link target="_blank" to="/docs/kotlin"><button type="button" className="btn btn-cancel mb-3 mr-3">Kotlin</button></Link>
                    <Link target="_blank" to="/docs/go"><button type="button" className="btn btn-cancel mb-3 mr-3">Go (Golang)</button></Link>
                    <Link target="_blank" to="/docs/php"><button type="button" className="btn btn-cancel mb-3 mr-3">PHP</button></Link>
                    <Link target="_blank" to="/docs/javascript"><button type="button" className="btn btn-cancel mb-3 mr-3">JavaScript</button></Link>
                    <Link target="_blank" to="/docs/others"><button type="button" className="btn btn-cancel mb-3">Others</button></Link>
                    <p className="mt-3 mb-3">If you need help with this final step get in contact at help@tesults.com and we will be happy to assist you.</p>
                    <Link to="/config"><button className="btn btn-confirm mb-5">Done</button></Link>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            );
        } else {
            let state = this.state.state;
            return (
                <p>Unrecognized state: {state}</p>
            );
        }
    }
}

export default CreateProject;