/*global Cookies*/
import React, { Component } from 'react'
import Linkify from 'linkifyjs/react';
import { NavLink } from 'react-router-dom'
import Request from './Request'
import ReactJson from 'react-json-view'
import SelectOverlay from './SelectOverlay'
import Loading from './Loading'
import Cache from './Cache'
import Analytics from './Analytics';

class JiraIssue extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {user: user, atlassianIntegrated: false, transitions: [], expired: undefined, selectedTransitionId: undefined, changes: false, jiraTransitions: []}
        this.atlassianIntegrated = this.atlassianIntegrated.bind(this)
        this.transitionChange = this.transitionChange.bind(this)
        this.transitions = this.transitions.bind(this)
        this.transitionsChanged = this.transitionsChanged.bind(this)
        this.checkTransitionsChanged = this.checkTransitionsChanged.bind(this)
        this.setTransitionsChanged = this.setTransitionsChanged.bind(this)
        this.issueKeyFromIssueLink = this.issueKeyFromIssueLink.bind(this)
        this.saveTransition = this.saveTransition.bind(this)
        this.setupJiraTransitions = this.setupJiraTransitions.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0)
        this.atlassianIntegrated()
        this.setupJiraTransitions()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        let prevJiraTransitions = prevProps.jiraTransitions
        let jiraTransitions = this.props.jiraTransitions
        if (prevJiraTransitions === undefined && jiraTransitions === undefined) {
            return
        } else if (prevJiraTransitions === undefined && jiraTransitions !== undefined) {
            this.setupJiraTransitions()
        } else if (prevJiraTransitions !== undefined && jiraTransitions === undefined) {
            this.setupJiraTransitions()
        } else {
            if (Array.isArray(prevJiraTransitions) !== true || Array.isArray(jiraTransitions) !== true) {
                return
            }
            if (this.checkTransitionsChanged(prevJiraTransitions, jiraTransitions) === true) {
                this.setupJiraTransitions()
            }
        }
    }

    setupJiraTransitions () {
        let selectedTransitionId = "-1"
        if (this.props.jiraTransitions !== undefined) {
            if (Array.isArray(this.props.jiraTransitions)) {
                for (let i = 0; i < this.props.jiraTransitions.length; i++) {
                    let transition = this.props.jiraTransitions[i]
                    if (transition.issueLink === this.props.issueLink) {
                        selectedTransitionId = transition.transitionId
                    }
                }
            } 
        }
        let jiraTransitions = [] 
        if (this.props.jiraTransitions !== undefined) {
            for (let i = 0; i < this.props.jiraTransitions.length; i++) {
                jiraTransitions.push(this.props.jiraTransitions[i])
            }
        }
        this.setState({jiraTransitions: jiraTransitions, selectedTransitionId: selectedTransitionId})
    }

    issueKeyFromIssueLink (issueLink) {
        let index = issueLink.lastIndexOf("/")
        return issueLink.substring(index + 1)
    }

    atlassianIntegrated () {
        if(this.props.projectId === undefined || this.props.issueLink === undefined) {
            return
        }
        this.setState({loading: true})
        Request.get("/atlassian-integrated", {id: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to check if Jira integrated"});
            } else {
                if (data.integrated !== undefined) {
                    this.setState({loading: false, atlassianIntegrated: data.integrated}, () => this.transitions())
                } else {
                    this.setState({loading:false})
                }
            }
        })
    }

    transitionsChanged (arr1, arr2) {
        let savedTransitions = []
        let transitions = []
        if (arr1 !== undefined && arr2 !== undefined) {
            savedTransitions = arr1
            transitions = arr2
        } else {
            savedTransitions = this.props.jiraTransitions
            if (savedTransitions === undefined) {
                savedTransitions = []
            }
            transitions = this.state.jiraTransitions
            if (transitions === undefined) {
                transitions = []
            }
        }
        
        const comparator = (a, b) => {
            let linkA = a.issueLink.toLowerCase()
            let linkB = b.issueLink.toLowerCase()
            if (linkA < linkB) {
                return  -1;
            } else if (linkA > linkB) {
                return 1;
            }
            return 0
        }
        savedTransitions.sort(comparator)
        transitions.sort(comparator)
        if (savedTransitions.length !== transitions.length) {
            return true
        }
        for (let i = 0; i < transitions.length; i++) {
            let transition1 = transitions[i]
            let transition2 = savedTransitions[i]
            if (transition1.issueLink !== transition2.issueLink
                || transition1.transitionId !== transition2.transitionId
                || transition1.result !== transition2.result) {
                return true
            }
        }
        return false
    }

    checkTransitionsChanged (arr1, arr2) {
        return this.transitionsChanged(arr1, arr2)
    }

    setTransitionsChanged (arr1, arr2) {
        if (this.transitionsChanged(arr1, arr2) === true) {
            this.setState({changes: true})
        } else {
            this.setState({changes: false})
        }
    }

    transitionChange (value) {
        Analytics.event("JiraTransitionChange")
        let jiraTransitions = this.state.jiraTransitions
        let index = -1
        for (let i = 0; i < jiraTransitions.length; i++) {
            let transition = jiraTransitions[i]
            if (transition.issueLink === this.props.issueLink) {
                index = i
            }
        }
        if (index === -1) { // Transition not saved which means no transition
            if (value === "-1") {
                // Done
            } else {
                jiraTransitions.push({issueLink: this.props.issueLink, transitionId: value, result: "pass"})
            }
        } else { // Transition has been saved before
            if (value === "-1") {
                jiraTransitions.splice(index, 1)
            } else {
                jiraTransitions[index] = {issueLink: this.props.issueLink, transitionId: value, result: "pass"}
            }
        }
        Cache.setPreference(Cache.preference.jiraTransitionId, value)
        let oldVale = this.props.jiraTransitions
        this.setState({jiraTransitions: jiraTransitions, selectedTransitionId: value}, this.setTransitionsChanged())
    }

    transitions () {
        this.setState({loading: true})
        Request.get("/jira-transitions", {id: this.props.projectId, issueLink: this.props.issueLink}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch Jira transitions"});
            } else {
                if (data.transitions !== undefined) {
                    let transitions = undefined
                    try {
                        transitions = JSON.parse(data.transitions)
                        if (transitions !== undefined && transitions !== null) {
                            transitions = transitions.transitions
                            this.setState({loading: false, transitions: transitions})
                        } else {
                            this.setState({loading: false})
                        }
                    } catch (err) {
                        // Unable to parse transitions
                        this.setState({loading: false})
                    }
                } else {
                    this.setState({loading: false})
                }
            }
        })
    }

    saveTransition () {
        Analytics.event("JiraTransitionSave")
        this.props.updateJiraTransitions(this.state.jiraTransitions)
        this.setState({changes: false})
    }

    render() {
        if (this.state.atlassianIntegrated === true) {
            
            let transitionOptions = [{
                label: "No transition",
                value: "-1",
                img: undefined
            }]
            let selectedTransitionIndex = -1
            if (this.state.transitions !== undefined) {
                let transitions = this.state.transitions
                for (let i = 0; i < transitions.length; i++) {
                    let transition = transitions[i]
                    if (transition.id === this.state.selectedTransitionId) {
                        selectedTransitionIndex = i + 1 // +1 to account for No transition option
                    }
                    if (transition !== undefined) {
                        let option = {
                            label: transition.name,
                            value: transition.id,
                            img: undefined
                        }
                        /*if (transition.to !== undefined) {
                            if (transition.to.iconUrl !== undefined) {
                                option.img = <img src={transition.to.iconUrl} width="16" height="16" alt=""/>
                            }
                        }*/
                        transitionOptions.push(option)
                    }
                }
            }
            if (this.state.loading === true) {
                return <div className='neutral4 font12'>Loading Jira issue...</div>
            }
            let transitionSelectTitle = ""
            if (selectedTransitionIndex !== -1) {
                if (selectedTransitionIndex < transitionOptions.length) {
                    transitionSelectTitle = transitionOptions[selectedTransitionIndex].label
                }
            } else {
                if (transitionOptions.length > 0) {
                    transitionSelectTitle = transitionOptions[0].label
                }
            }

            let issueLink = this.props.issueLink.substring(5) // remove jira: prefix

            return (
                <div className='standardborder solidborder neutral7border p-3 mb-3'>
                    <div className="pre-wrap"><Linkify>{issueLink}</Linkify></div>
                    <div className='neutral4 font12'>Transition issue on pass result:</div>
                    <SelectOverlay
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        type="generic"
                        title={transitionSelectTitle}
                        label="label"
                        value="value"
                        options={transitionOptions}
                        valueChange={this.transitionChange}
                        defaultValue={this.state.selectedTransitionId}
                        active={this.props.selectOverlayActive}
                        //moreOptions={this.state.moreOptions}
                        //getMoreOptions={this.projects}
                    />
                    <div>
                        {
                            this.state.changes === true ?
                            <button className='btn-confirm' onClick={this.saveTransition}>Save changes</button>
                            :
                            <div></div>
                        }                        
                    </div>
                </div>
            )
        } else {
            let issueLink = this.props.issueLink.substring(5) // remove jira: prefix
            return <div className="pre-wrap"><Linkify>{issueLink}</Linkify></div>
        }
    }
}

export default JiraIssue