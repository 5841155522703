/*global Cookies*/
import React, { Component } from 'react';
import AffiliateAbout from './AffiliateAbout';
import AffiliateConfig from './AffiliateConfig';
import AffiliateDashboard from './AffiliateDashboard';
import AffiliateTerms from './AffiliateTerms';

class Affiliate extends Component {
    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let loggedIn = true;
        let view = "about";
        if (user === undefined || user == null) {
            loggedIn = false;
        }
        // valid views: about, config, dashboard, terms
        this.state = {loggedIn: loggedIn, view: view};
    }

    componentDidMount () {
        const path = window.location.pathname;
        if (path.startsWith("/affiliate/dashboard/id/")) {
            this.setState({view: "dashboard", id: this.props.match.params.id});
        } else {
            let view = this.props.match.params.view;
            if (view !== undefined) {
                this.setState({view: view});
            }
        }
    }

    render () {
        if (this.state.view === "about") {
            return <AffiliateAbout loggedIn={this.state.loggedIn}/>
        } else if (this.state.view === "terms") {
            return <AffiliateTerms loggedIn={this.state.loggedIn}/>
        } else if (this.state.view === "config") {
            return <AffiliateConfig loggedIn={this.state.loggedIn}/>
        } else if (this.state.view === "dashboard") {
            return <AffiliateDashboard history={this.props.history} id={this.state.id} loggedIn={this.state.loggedIn}/>
        } else {
            return (
                <div style={{"minHeight": "100vh"}}>
                    <h1 className="neutral1">Tesults affiliate program</h1>
                    <p>Please select one of the options on the menu.</p>
                </div>
            );
        }
        
    }
};

export default Affiliate;