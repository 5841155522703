import React, { Component } from 'react';

class RSPHandler extends Component {
    static rspData () {
        let rspData = {};
        let rsp = window.location.pathname.split("/");
        rsp.reverse(); // reversed so that in order pop can occur
        rsp.pop(); // pops ""
        rsp.pop(); // pops "results"
        if (rsp.pop() === "rsp") {
            if (rsp.pop() === "view") {
                rspData.view = rsp.pop();
                if (rspData.view === "status") {
                    let key = rsp.pop();
                    if (key === "project") {
                        rspData.projectId = rsp.pop();
                        key = rsp.pop();
                        if (key === "group") {
                            rspData.group = rsp.pop();
                        }
                    }
                } else if (rspData.view === "diff") {
                    if (rsp.pop() === "project") {
                        rspData.projectId = rsp.pop()
                        if (rsp.pop() === "target1") {
                            rspData.target1Id = rsp.pop()
                            if (rsp.pop() === "target2") {
                                rspData.target2Id = rsp.pop()
                                if (rsp.pop() === "run1") {
                                    rspData.run1Id = rsp.pop()
                                    if (rsp.pop() === "run2") {
                                        rspData.run2Id = rsp.pop()
                                    }
                                }
                            }
                        }
                    }
                } else {
                    const key = rsp.pop();
                    if (key === "project") {
                        rspData.projectId = rsp.pop();
                    }
                    if (key === "target") {
                        let val = rsp.pop();
                        const splitIndex = val.lastIndexOf("-");
                        rspData.projectId = val.substring(0, splitIndex);
                        rspData.targetId = val.substring(splitIndex + 1);
                    }
                    if (key === "run" || key === "run-archive") {
                        let val = rsp.pop();
                        let splitIndex = val.lastIndexOf("-");
                        let combined = val.substring(0, splitIndex);
                        if (key === "run") {
                            rspData.runId = val.substring(splitIndex + 1);
                        }
                        if (key === "run-archive") {
                            rspData.runArchiveId = val.substring(splitIndex + 1)
                            
                        }
                        splitIndex = combined.lastIndexOf("-");
                        rspData.projectId = combined.substring(0, splitIndex);
                        rspData.targetId = combined.substring(splitIndex + 1);
                    }
                    if (key === "case" || key === "case-archive") {
                        let val = rsp.pop();
                        let splitIndex = val.lastIndexOf("-");
                        let combined = val.substring(0, splitIndex);
                        rspData.caseNum = val.substring(splitIndex + 1);
                        combined = combined.substring(0, splitIndex);
                        splitIndex = combined.lastIndexOf("-");
                        if (key === "case") {
                            rspData.runId = combined.substring(splitIndex + 1);
                        }
                        if (key === "case-archive") {
                            rspData.runArchiveId = combined.substring(splitIndex + 1);
                        }
                        combined = combined.substring(0, splitIndex);
                        splitIndex = combined.lastIndexOf("-");
                        rspData.projectId = combined.substring(0, splitIndex);
                        rspData.targetId = combined.substring(splitIndex + 1);
                    }
                }
            }
        }
        return rspData;
    }

    render () {
        return <span></span>
    }
}

export default RSPHandler;