/*global*/
import React, { Component } from 'react';
import Request from './Request';
import Loading from './Loading';
import Context from './Context'
import Cache from './Cache'

class ChangeNotify extends Component {
    static contextType = Context;
    
    constructor () {
        super();
        this.state = {
            error: undefined, 
            loading: false, 
            message: "", 
            checked: true
            
        };
        this.init = this.init.bind(this);
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.project = this.project.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init () {
        let project = this.project();
        if (project === undefined) { return; }
        this.setState({loading: true});
        if (this.props.type === "change") {
            Request.get("/changeNotify", {project: project.id}, (err, data) => {
                if (err) {
                    this.setState({loading: false, error: "Unable to check change notify."});
                } else {
                    this.setState({loading: false, checked: data.changeNotify});
                }
            });
        } else if (this.props.type === "failure") {
            Request.get("/failureNotify", {project: project.id}, (err, data) => {
                if (err) {
                    this.setState({loading: false, error: "Unable to check failure notify."});
                } else {
                    this.setState({loading: false, checked: data.failureNotify});
                }
            });
        } else {
            this.setState({loading: false});
        }
        
    }

    project () {
        if (this.context.projects === undefined) {
            return;
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex];
        } else {
            return;
        }
    }
    

    toggle () {
        let checked = this.state.checked;
        if (checked === true) {
            this.setState({checked: false});
        } else {
            this.setState({checked: true});
        }
    }    

    save () {
        let project = this.project();
        if (project === undefined) { return; }
        this.setState({loading: true});
        if (this.props.type === "change") {
            Request.post("/changeNotify", {project: project.id, changeNotify: this.state.checked}, (err, data) => {
                if (err) {
                    this.setState({loading: false, error: "Unable to update change notify"});
                } else {
                    this.setState({loading: false, message: "Change notification setting updated"});
                }
            });
        } else if (this.props.type === "failure") {
            Request.post("/failureNotify", {project: project.id, failureNotify: this.state.checked}, (err, data) => {
                if (err) {
                    this.setState({loading: false, error: "Unable to update failure notify"});
                } else {
                    this.setState({loading: false, message: "Failure notification setting updated"});
                }
            });
        } else {
            this.setState({loading: false});
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>
        } else {
            const type = this.props.type === "failure" ? "failures" : "changes";
            const description = this.props.type === "failure" ?
                <div>
                    Deliver notifications for failures only. No notifications will be sent when all tests pass if this setting is enabled. This includes notifications to team members with email notifications enabled and Slack channel notifications.
                </div>
            :
                <div>
                    <p>Everytime new test results are generated, Tesults delivers notifications if there are changes to previous results only, if this setting is enabled. This includes notifications to team members with email notifications enabled and Slack channel notifications.</p>
                    <p>You can choose to disable this and then notifications will be delivered every time new results are generated.</p>
                </div>
            return (
                <div className="whitebg p-3 mb-3">
                    <h4 className="neutral1 ">Notifications on {type} only:</h4>
                    <label className="autoRefreshSwitch">
                        <input type="checkbox" onChange={this.toggle} checked={this.state.checked}/>
                        <span className="autoRefreshSlider round"></span>
                    </label>
                    <div><span className="accentc4">{this.state.message}</span></div>
                    <div><span className="accenta4">{this.state.error}</span></div>
                    {description}
                    <button type="button" className="btn btn-confirm mt-3" onClick={() => {this.save()}}>Save</button>
                </div>
            );
        }
    }
}

export default ChangeNotify;