/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';

class DocsVSUnitTF extends Component {
    render() {
        const helmet= 
        <Helmet>
            <title>Visual Studio Unit Testing Framework (MSTest) test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Visual Studio Unit Testing Framework (MSTest) test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>Read the <NavLink to="/docs/csharp" target="_blank" className="site-link-primary2">C#</NavLink> docs for complete details about supported build and test case properties and a complete code example for how to apply values for each property.</p>
            <p>Unlike all of the other test frameworks we document here, MSTest does not support the type of extensibility that makes plugin and codeless integration possible and you will need to add code to report test results data to Tesults.</p>
        </div>

        const installation = 
        <div>
            <p>The Tesults package is hosted on The NuGet Gallery: <a className="site-link-primary2" href="https://www.nuget.org/packages/tesults/">https://www.nuget.org/packages/tesults/</a></p>
            <p>Run this command in Package Manager Console.</p>
            <pre className="docsCode">
                Install-Package tesults
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Tesults recommends using the [TestCleanup] and [AssemblyCleanup] decorators to implement methods that extract results data from TestContext to generate results data for reporting and upload.</p>
            <p>A complete example is provided below, <code>TesultsReporter.cs</code>, ready to be copied and pasted for use with your system. Use as is or edit. To upload files uncomment the optional files section and provide paths to where files are saved for each test case. To map test logs, screenshots and failure reason logging to a test case, you could consider have a temporary directory for each test case to save to during tests.</p>
            <p>Note that in order to use <code>TestReporter.cs</code> everyone of your Test classes will need to have this code added:</p>
            <h4 className="neutral4">Add to each test class:</h4>
            <pre className="docsCode">
            {'public TestContext TestContext { get; set; }'}<br/><br/>

            [TestCleanup]<br/>
            public void TestComplete()<br/>
            {'{'}<br/>
            &nbsp;&nbsp;TesultsReporter.TestComplete(TestContext);<br/>
            {'}'}<br/>
            </pre>
            <h4 class="neutral4">TesultsReporter.cs</h4>
            <pre className="docsCode">
            using System;<br/>
            using System.Collections.Generic;<br/>
            using Microsoft.VisualStudio.TestTools.UnitTesting;<br/><br/>

            namespace TesultsMSTest<br/>
            {'{'}<br/>
            &nbsp;&nbsp;[TestClass]<br/>
            &nbsp;&nbsp;public class TesultsReporter<br/>
            &nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'public static List<Dictionary<string, object>> testCases = new List<Dictionary<string, object>>();'}<br/><br/>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;public static void TestComplete (TestContext testContext)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'var testCase = new Dictionary<string, object>();'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.Add("name", testContext.TestName);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.Add("desc", testContext.TestName);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.Add("suite", testContext.FullyQualifiedTestClassName);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var result = testContext.CurrentTestOutcome.ToString();<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var properties = testContext.Properties;<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if (result == "Passed")<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result = "pass";<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else if (result == "Failed")<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result = "fail";<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.Add("reason", "Access the error from a file");<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} MSTest does not provide a way to get the failure reason from<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} TestContext unfortunately so you must save the test failure<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} reason (assert, exception etc.) to a file and then add it<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} to the reason attribute. We understand this is not ideal.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} You may want to consider using the NUnit test framework.<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;else<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;result = "unknown";<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCase.Add("result", result);<br/><br/>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} Optional for file uploading<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'// var files = new List<string>();'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} files.Add(@"C:\\path-to-file-for-this-test\\img.png");<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'//'} testCase.Add("files", files);<br/><br/>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;testCases.Add(testCase);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/><br/>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[AssemblyCleanup]<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;public static void Upload ()<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'var results = new Dictionary<string, object>();'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;results.Add("cases", testCases);<br/><br/>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'var data = new Dictionary<string, object>();'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data.Add("target", "token");<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data.Add("results", results);<br/><br/>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var response = Tesults.Results.Upload(data);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console.WriteLine("Success: " + response["success"]);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console.WriteLine("Message: " + response["message"]);<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Console.WriteLine("Warnings: " + ((List<string>)response["warnings"]).Count);'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'Console.WriteLine("Errors: " + ((List<string>)response["errors"]).Count);'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
            <p className="mt-3">The upload method returns a response of type Map&lt;String, Object&gt; that you can use to check whether the upload was a success.</p>
            <p>Value for key "success" is a Boolean: true if results successfully uploaded, false otherwise.</p>
            <p>Value for key "message" is a String: if success is false, check message to see why upload failed.</p>
            <p>Value for key "warnings" is a List&lt;String&gt;, if size is not zero there may be issues with file uploads.</p>
            <p>Value for key "errors" is a List&lt;String&gt;, if "success" is true then this will be empty.</p>
            <br/>
            <p>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</p>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                integration="mstest"
            />
        );
    }
}

export default DocsVSUnitTF;