/*global*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import RegisterMain from './RegisterMain'

class Register extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Sign Up</title>
                    <meta name="description" content="Sign up for effective test reporting and test case management."/>
                </Helmet>
                <div style={{"display":"flex", "alignItems":"center", "justifyContent":"center"}}>
                    <RegisterMain location={this.props.location}/>
                </div>
            </div>
        );
    }
};

export default Register;