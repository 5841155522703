/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsManualTestData from './DocsManualTestData';

class DocsManual extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Manual Results Guide</title>
                    <meta name="description" content="Learn how to use Tesults Runs for effective test case management, manual testing and reporting."/>
                </Helmet>
                <div className='docs-layout-side-column'>
                    <div>
                    <iframe className='youtube' src="https://www.youtube.com/embed/5xgtxZyResQ?si=e7qBOxgXvkZK5hLe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <h2>Manual tests</h2>
                        <p>Manual testing is a critical component of overall test strategy, especially in areas such as UX, usability, comfort, accessibility and exploratory testing. Manual testing is also important when a more nimble approach is required in specific situations.</p>
                        <p>Testers can add, edit and manage test cases all from within the Tesults web interface and submit results to any Tesults project target. You may want to have specific targets setup for manual test results.</p>
                        <p>Access manual test results submission from the side bar of the results page. Only team members assigned a <b>moderator (level 2)</b> role or above can upload manual test results, the manual button is not visible without this role assignment.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-1.png"/>*/}

                        <a className="site-link-neutral2" href="# " name="test-suites-cases-and-runs">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Test suites, cases and runs</h2>
                        <p>A test case is an individual test. A test suite is a group of test cases. A test run is a single run of the test cases. To submit manual test results you need to create a new test run. The test run will consist of test cases belonging to one or more suites.</p>
                        

                        <a className="site-link-neutral2" href="# " name="creating-test-runs">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Creating test runs</h2>
                        <p>Click the <b>Create test run</b> button to create a new test run. A test run can contain one or more test cases.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-2.png"/>*/}
                        <p>You can choose a temporary name for your test run. This can be useful to identify a test run again if you do not submit results in one sitting. You can come back to an in progress test run and select it to continue working on it.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-3.png"/>*/}
                        <p><b>Important</b> Manual test runs are intended to be temporary. Manual test runs are deleted 30 days after being created. The expectation is that results will be submitted within 30 days. To store test cases for the long term, use <NavLink className="site-link-underline" target="_blank" to="/docs/lists">test lists</NavLink>. Test runs are not for test case management, they are for submitting results only.</p>

                        <a className="site-link-neutral2" href="# " name="adding-test-cases-manually">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Adding test cases manually</h2>
                        <p>Add test cases to your new test run. To add each case manually click the <b>Add new case</b> button.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-5" alt="" src="/img/manual-4.png"/>*/}
                        <p>When adding test cases manually you can specify the following test case properties:</p>
                        <DocsManualTestData/> 
                        <p>Click the 'Save case' button to add the case to the run.</p>

                        <a className="site-link-neutral2" href="# " name="importing-test-cases-from-lists">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Importing test cases from lists</h2>
                        <p>Another way to add test cases is to import cases from a test list. Test lists are saved lists of test cases. Test lists can save testers a lot of time. <NavLink className="site-link-underline" target="_blank" to="/docs/lists">Read more about test lists.</NavLink></p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-5.png"/>*/}
                        <p>Click the <b>Import</b> button to import test list cases to the selected test run.</p>
                        

                        <a className="site-link-neutral2" href="# " name="editing-cases">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Editing cases</h2>
                        <p>Click a test case and click the <b>Edit</b> button to edit it. You can also remove a test case from the test run by selecting it and clicking the <b>Delete</b> button.</p>
                        

                        <a className="site-link-neutral2" href="# " name="marking-test-cases-as-done">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Marking test cases as done</h2>
                        <p>When adding test cases you have the option to mark cases as done or not done. All imported cases from a test list are initially marked as not done. Test cases that are marked incomplete in this way are neutral4 to make it easier for testers to know which test cases have yet to be looked at.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-6.png"/>*/}
                        <p>Before submitting test results all cases must be marked as done. Testers can either mark each test case as done as they go along, or they can mark them all done using the <b>Mark All Done</b> button.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-7.png"/>*/}
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-8.png"/>*/}

                        <a className="site-link-neutral2" href="# " name="assigning-tests-to-testers">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Assigning tests to testers</h2>
                        <p>Assign test cases to testers in the team. This makes it easy to share workload and keep track of what each person is testing.</p>
                        <p>View test cases assigned to each tester by selecting a team member from the 'Show Assigned Cases To Team Member' dropdown list on the side menu. If an individual is selected, an 'Assign' button is displayed. Click it to select cases for assignment to the selected team member. After assignment is complete, be sure to click the green 'Save' button if you are happy with the selections you have made.</p>
                        <p>Individual test cases or entire test suites can be selected for assignment. The number of test cases assigned to each team member are displayed in the main panel within the manual test run page.</p>

                        <a className="site-link-neutral2" href="# " name="submitting-results">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Submitting results</h2>
                        <p>Once all test cases in the test run are marked done the results can be submitted. Click the <b>Submit</b> button to submit results.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-9.png"/>*/}
                        <p>Select the appropriate target to upload results to. If results are submitted to the wrong target the run can be deleted by a team member  assigned a <b>moderator (level 2)</b> role or above. However before deleting a test run you may want to create a new test list with an import of the results for the results so that they can be re-submitted to an alternate target later. To avoid these problems just be sure to select the correct target when submitting.</p>
                        {/*<img className="width100 indexImgBorder elevation6 mt-3 mb-3" alt="" src="/img/manual-10.png"/>*/}
                        <p>After submission, results can be viewed in the results view the same way automated test results are viewed. Notifications will be delivered to team members that are setup to receive them.</p>
                        <a className="site-link-neutral2" href="# " name="deleting-test-runs">
                            <div style={{"height":"60px"}}>&nbsp;</div>
                        </a>
                        <h2>Deleting test runs</h2>
                        <p>Click the <b>Delete</b> button to delete a manual test run. Manual test runs are automatically deleted 30 days after creation. Use test lists for persistent storage.</p>
                    </div>
                    <div>
                        <div>On this page</div>
                        <a className="site-link-primary2" href="#manual-tests">Manual tests</a>
                        <br/>
                        <a className="site-link-primary2" href="#test-suites-cases-and-runs">Test suites, cases and runs</a>
                        <br/>
                        <a className="site-link-primary2" href="#creating-test-runs">Creating test runs</a>
                        <br/>
                        <a className="site-link-primary2" href="#adding-test-cases-manually">Adding test cases manually</a>
                        <br/>
                        <a className="site-link-primary2" href="#importing-test-cases-from-lists">Importing test cases from lists</a>
                        <br/>
                        <a className="site-link-primary2" href="#editing-cases">Editing cases</a>
                        <br/>
                        <a className="site-link-primary2" href="#marking-test-cases-as-done">Marking test cases as done</a>
                        <br/>
                        <a className="site-link-primary2" href="#assigning-tests-to-testers">Assigning tests to testers</a>
                        <br/>
                        <a className="site-link-primary2" href="#submitting-results">Submitting results</a>
                        <br/>
                        <a className="site-link-primary2" href="#deleting-test-runs">Deleting test runs</a>
                        
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsManual;