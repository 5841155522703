/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Box from './Box';

class DocsSupport extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Support</title>
                    <meta name="description" content="Learn about Tesults support, the Tesults team is always available to help."/>
                </Helmet>
                <p>Contact Tesults support at:<br/><a className="site-link-primary2" href="mailto:help@tesults.com">help@tesults.com</a><br/><br/>Other <Link className="site-link-primary2" to="/contact">contact methods</Link> exist but support timelines are based on initial email contact.</p>
                <div style={{"display":"flex", "flexDirection":"column"}}>
                    <div style={{"maxWidth":"400px"}} className="mb-4">
                        <Box
                            bg="neutral2 neutral8bg"
                            title={<h4>Basic</h4>}
                            content={<p>Basic support is available to all Tesults users including <b>Free</b> tier users. Emails will generally be answered within 48 hours.</p>}
                        />
                    </div>
                    <div style={{"maxWidth":"400px"}} className="mb-4">
                        <Box
                            bg="neutral2 neutral8bg"
                            title={<h4>Standard</h4>}
                            content={<p>Standard support is available for projects on the <b>Standard</b> plan. Emails are answered within 24 hours and a live support call can be requested.</p>}
                        />
                    </div>
                    <div style={{"maxWidth":"400px"}}>
                        <Box
                            bg="neutral2 neutral8bg"
                            title={<h4>Priority</h4>}
                            content={<p>Priority support is available for projects on the <b>Plus</b> plan. Emails are answered within 12 hours, a live support call can be requested and a personal account manager is assigned to your project who can be emailed directly.</p>}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsSupport;