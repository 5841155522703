/*global Cookies*/
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loading from './Loading'
import Request from './Request'
import ReactJson from 'react-json-view';
import Analytics from './Analytics';

class Jira extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {user: user, atlassianIntegrated: false, scopes: undefined, expired: undefined, clientId: undefined}
        this.atlassianIntegrated = this.atlassianIntegrated.bind(this)
        this.scopes = this.scopes.bind(this)
        this.remove = this.remove.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0)
        Analytics.event("JiraIntegration")
        this.setClientId();
        this.atlassianIntegrated()
    }

    setClientId () {
        Request.get("/client-id", {key: "ATLASSIAN_CLIENT_ID"}, (err, data) => {
            if (err) {
                this.setState({clientId: undefined, error: "Unable to proceed with Jira integrated"});
            } else {
                this.setState({clientId: data.value});
            }
        })
    }

    atlassianIntegrated () {
        if(this.props.project === undefined) {
            return
        }
        this.setState({loading: true})
        Request.get("/atlassian-integrated", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to check if Jira integrated"});
            } else {
                if (data.integrated !== undefined) {
                    this.setState({loading: false, atlassianIntegrated: data.integrated}, () => this.scopes())
                } else {
                    this.setState({loading:false})
                }
            }
        })
    }

    scopes () {
        this.setState({loading: true})
        Request.get("/atlassian-scopes", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to check Jira scopes"});
            } else {
                if (data.scopes !== undefined) {
                    this.setState({loading: false, scopes: data.scopes, expired: data.expired})
                } else {
                    this.setState({loading: false})
                }
            }
        })
    }

    remove () {
        this.setState({loading: true})
        Analytics.event("JiraIntegrationRemove")
        Request.get("/atlassian-remove", {id: this.props.project.id}, (err) => {
            if (err) {
                this.setState({loading: false, error: "Unable to remove Jira"});
            } else {
                Analytics.event("JiraIntegrationRemoved")
                this.setState({loading: false, atlassianIntegrated: false})
            }
        })
    }

    render() {
        if (this.props.project === undefined) {
            return (
                <div>
                    <p>Create at least one project on Tesults. Read the <NavLink to="/docs" className="nounderline neutral1">documentation</NavLink> for detailed steps outlining how this is done.</p>
                </div>
            )
        } else if (this.state.loading) {
            return <Loading/>  
        } else if (this.state.atlassianIntegrated !== true) {
            let id = this.props.project.id;
            return (
                <div style={{"maxWidth":"800px"}}>
                    <img src="/img/jira.png" width="95" height="40" alt="jira"/>
                    <p>If your team uses both Jira and Tesults consider adding Jira integration to your Tesults project.</p>
                    <p className="mb-3">Integration will enable you to create and update Jira issues with the click of a button from failing test cases in Tesults. Tesults can also automatically update Jira issue status based on new test results.</p>
                    <br/>
                    {
                        this.props.dev === true ?
                        <a href={"https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=" + this.state.clientId + "&scope=offline_access%20read%3Aavatar%3Ajira%20read%3Aissue-type%3Ajira%20read%3Aproject%3Ajira%20read%3Aproject.property%3Ajira%20read%3Auser%3Ajira%20read%3Aapplication-role%3Ajira%20read%3Agroup%3Ajira%20read%3Aissue-type-hierarchy%3Ajira%20read%3Aproject-category%3Ajira%20read%3Aproject-version%3Ajira%20read%3Aproject.component%3Ajira%20write%3Aissue%3Ajira%20write%3Acomment%3Ajira%20write%3Acomment.property%3Ajira%20write%3Aattachment%3Ajira%20read%3Aissue%3Ajira%20read%3Aissue-meta%3Ajira%20read%3Aissue-security-level%3Ajira%20read%3Aissue.vote%3Ajira%20read%3Aissue.changelog%3Ajira%20delete%3Aissue%3Ajira%20read%3Afield-configuration%3Ajira%20read%3Aissue.transition%3Ajira%20read%3Astatus%3Ajira%20write%3Aissue.property%3Ajira%20read%3Apriority%3Ajira&redirect_uri=https://3b97-82-44-255-156.ngrok.io%2Fatlassianauth&state=" + id + "&response_type=code&prompt=consent"}><button className='btn-confirm'>Integrate Jira</button></a>
                        
                        :
                        <a href={"https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=" + this.state.clientId + "&scope=offline_access%20read%3Aavatar%3Ajira%20read%3Aissue-type%3Ajira%20read%3Aproject%3Ajira%20read%3Aproject.property%3Ajira%20read%3Auser%3Ajira%20read%3Aapplication-role%3Ajira%20read%3Agroup%3Ajira%20read%3Aissue-type-hierarchy%3Ajira%20read%3Aproject-category%3Ajira%20read%3Aproject-version%3Ajira%20read%3Aproject.component%3Ajira%20write%3Aissue%3Ajira%20write%3Acomment%3Ajira%20write%3Acomment.property%3Ajira%20write%3Aattachment%3Ajira%20read%3Aissue%3Ajira%20read%3Aissue-meta%3Ajira%20read%3Aissue-security-level%3Ajira%20read%3Aissue.vote%3Ajira%20read%3Aissue.changelog%3Ajira%20delete%3Aissue%3Ajira%20read%3Afield-configuration%3Ajira%20read%3Aissue.transition%3Ajira%20read%3Astatus%3Ajira%20write%3Aissue.property%3Ajira%20read%3Apriority%3Ajira&redirect_uri=https://www.tesults.com%2Fatlassianauth&state=" + id + "&response_type=code&prompt=consent"}><button className='btn-confirm'>Integrate Jira</button></a>
                    }
                    <br/>
                    <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button> 
                </div>
            )
        } else if (this.state.atlassianIntegrated === true) {
            let message = <p>Jira is integrated. Site access and scope details can be seen below.</p>
            if (this.state.scopes !== undefined) {
                if (this.state.expired === true) {
                    message = <p>Jira access has expired. Click the 'Remove Jira integration' button below and then click the 'Integrate' button that appears to re-authorize Jira integration with Tesults. Jira no longer offers permanent access or refresh tokens, you must re-authorize Jira integration after the access and refresh tokens Tesults stores expire, which can range between 90-365 days depending on your Jira settings.</p>
                }
            }
            return (
                <div>
                    <div className='mb-5' style={{"maxWidth":"800px"}}>
                        {message}
                    </div>
                    <div className='mb-5'>
                        {this.state.scopes === undefined ? <div></div> : <ReactJson src={JSON.parse(this.state.scopes)}/>}
                    </div>
                    <div className='mb-5'>
                        {this.state.projects === undefined ? <div></div> : <ReactJson src={JSON.parse(this.state.projects)}/>}
                    </div>
                    <div className='mb-5'>
                        <button className='btn-cancel' onClick={this.remove}>Remove Jira integration</button>
                    </div>
                    <div className='mb-5'>
                        <button className='btn-cancel' onClick={this.props.back}>Back</button>
                    </div>
                </div>
            )
        } else {
            return <div>Invalid state</div>
        }
    }
}

export default Jira