/*global */
import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Request from './Request'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'


class ConfigProjectDelete extends Component {
    static contextType = Context;

    constructor () {
        super()
        this.state = {state: "view"}
        this.delete = this.delete.bind(this)
        this.deleteConfirm = this.deleteConfirm.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    cancel () {
        this.setState({state: "view"})
    }

    delete () {
        Analytics.event("DeleteProject");
        this.setState({state: "delete", confirmation: {success: undefined, failure: undefined}})
    }

    deleteConfirm () {
        this.setState({submitting: true});
        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        Request.post("/deleteProject", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err}, submitting: false})
            } else {
                Request.get("/projectsConfig", undefined, (err, data) => {
                    if (err) {
                        this.setState({state: "projectDeleted", submitting: false})
                    } else {
                        Analytics.event("DeletedProject")
                        this.setState({state: "view", submitting: false, confirmation: {success: undefined, failure: undefined}}, () => {window.location.reload(true)})
                    }
                })
            }
        })
    }

    render () {
        if (this.state.state === "view") {
            return (
                <div>
                    {this.props.role >= 5 ? 
                        <button className="btn-cancel mt-3" onClick={this.delete}>Delete this project and cancel plan</button>
                        :
                        <div>&nbsp;</div>
                    }
                </div>
            )
        } else if (this.state.state === "delete") {
            return (
                <div>
                    <div style={{"maxWidth":"800px"}}>
                        <h4 className="bold">IMPORTANT WARNING</h4>
                        <p className="">Deleting this project will delete all stored test results data for the project. All stored test lists, test runs and tasks and all other data associated with the project will also be deleted. If you do not wish to delete this project click cancel.</p>
                        <p className="">Delete cannot be undone. You will be deleting all of your project data and it cannot be recovered. After deletion is complete your subscription to your plan will be automatically canceled and no further payments will be taken.</p>
                        <Confirmation confirmation={this.state.confirmation}/>
                        <br/>
                        <br/>
                        <ConfirmationButtons
                            confirm={this.deleteConfirm}
                            cancel={this.cancel}
                            confirmLabel="Confirm project deletion"
                            cancelLabel="Cancel"
                            confirmDisabled={this.state.submitting}
                        />
                    </div>
                </div>
            )
        } else {
            return <div>Invalid state</div>
        }
    }
};

export default ConfigProjectDelete