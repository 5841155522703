/*global*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import TargetGroupItem from './TargetGroupItem';
import Confirmation from './Confirmation';

class TargetGroup extends Component {
    constructor (props) {
        super(props);
        if (this.props.target !== undefined) {
            let group = this.props.target.group;
            let originalGroup = [];
            if (group === undefined) {
                group = [];
            }
            for (let i = 0; i < group.length; i++) {
                originalGroup.push(group[i]);
            }
            this.state = {
                group: group,
                originalGroup: originalGroup,
                rev: this.props.target.rev,
                unsavedChanges: false,
                hideInDashboard: this.props.target.hideInDashboard ? this.props.target.hideInDashboard : false,
                groupText: ""
            };
        } else {
            this.setState({group: [], originalGroup: [], rev: -1, groupText: ""});
        }
        this.groupTextChange = this.groupTextChange.bind(this);
        this.toggleHideInDashboard = this.toggleHideInDashboard.bind(this);
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.target !== undefined && this.state.rev !== undefined) {
            if (this.props.target.rev === this.state.rev) {
                return;
            }
        } else {
            return;
        }

        let group = [];
        let prevGroup = [];
        if (this.props.target !== undefined) {
            group = this.props.target.group;
            if (group === undefined) {
                group = [];
            }
        } else {
            group = [];
        }
        
        prevGroup = this.state.originalGroup
        if (prevGroup === undefined) {
            prevGroup = [];
        }
        
        group = [...new Set(group)]; // removes duplicates
        prevGroup = [...new Set(prevGroup)]; // removes duplicates

        group = group.sort();
        prevGroup = prevGroup.sort();

        let different = false;
        if (group.length !== prevGroup.length) {
            different = true;
        } else {
            for (let i = 0; i < group.length; i++) {
                if (group[i] !== prevGroup[i]) {
                    different = true;
                }
            }   
        }

        if (different === true) {
            this.setState({originalGroup: group, rev: this.props.target.rev});
        } else {
            this.setState({rev: this.props.target.rev});
        }
    }

    groupTextChange (e) {
        this.setState({groupText: e.target.value});
    }

    toggleHideInDashboard () {
        this.setState({hideInDashboard: !this.state.hideInDashboard}, () => this.saveHideInDashboard());
    }

    add () {
        let group = this.state.group;
        if (group === undefined) {
            group = [];
        }
        let alreadyExists = false;
        // do not allow duplicates
        for (let i = 0; i < group.length; i++) {
            if (group[i] === this.state.groupText) {
                alreadyExists = true;
            }
        }
        if (alreadyExists === true || this.state.groupText === "") {
            this.setState({groupText: ""});
        } else {
            group.push(this.state.groupText);
            this.setState({group: group, groupText:""}, () => this.save());
            Analytics.event("TargetGroupAdded");
        }
    }

    remove (item) {
        let group = this.state.group;
        group = [...new Set(group)]; // removes duplicates
        let index = -1;
        for (let i = 0; i < group.length; i++) {
            if (group[i] === item) {
                index = i;
            }
        }
        if (index !== -1) {
            // remove
            group.splice(index, 1);
        } else {
            // not found
        }
        this.setState({group: group}, () => this.save());
        Analytics.event("TargetGroupRemoved");
    }

    save () {
        let target = this.props.target;
        target.group = this.state.group;
        this.props.targetGroupUpdate(target);
        Analytics.event("TargetGroupSaved");
    }

    saveHideInDashboard () {
        let target = this.props.target;
        target.hideInDashboard = this.state.hideInDashboard;
        this.props.targetHideInDashboardUpdate(target);
        Analytics.event("TargetHideInDashboardSaved");
    }

    render () {
        if (this.props.target === undefined) {
            return  <span></span>
        } else {
            let group = this.state.group;
            if (group === undefined) {
                group = [];
            }
            let groupItems = [];
            for (let i = 0; i < group.length; i++) {
                groupItems.push(<TargetGroupItem key={i} item={group[i]} remove={this.remove}/>)
            }
            return (
                <div className="mt-3" style={{"display":"flex", "flexDirection":"column"}}>
                    <div className="" style={{"display":"flex", "flexFlow":"wrap"}}>
                        {groupItems}
                    </div>
                    <div>
                        <input className="tr-input" list="groupSuggestions" onChange={this.groupTextChange} value={this.state.groupText}/>
                        <button className="btn-confirm" onClick={this.add}>Add Group</button>
                        <div className="flex-row">
                            <div className="mr-2">
                                <input type="checkbox" onChange={this.toggleHideInDashboard} checked={this.state.hideInDashboard}/> 
                            </div>
                            <div>
                                <span className="neutral4 font12">Hide this target in dashboard</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="mt-3">
                        <Confirmation confirmation={this.props.confirmation}/>
                    </div>
                    <datalist id="groupSuggestions">
                        {this.props.groupSuggestions}
                    </datalist>
                </div>
            );
        }
    }    
}

export default TargetGroup;