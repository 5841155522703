/*global*/
import React, { Component } from 'react';
import PassRate from './PassRate';
import SelectOverlay from './SelectOverlay';
import SelectSide from './SelectSide';
import Job from './Job';

class ResultsHeader11 extends Component {
    render () {
        window.scrollTo(0, 0);
        return (
            <div className="results-header2">
                <div className="results-header2-actions">
                    <div className='font14 mr-5'>
                        <PassRate
                            type="listCases"
                            cases={this.props.cases}
                        />
                    </div>
                    <div className="results-header1-search">
                        <input className="search clickable" onChange={(e) => this.props.onSearchChange(e.target.value)} placeholder="Search Test Name"/>
                    </div>
                    <div className='mr-3'>
                        <button type="button" className='btn btn-white' onClick={this.props.toggleCollapseAll}>
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div className='mr-3'>
                                <div style={{"marginTop":"4px"}}>
                                    {this.props.collapseAll === true ? <img src="/img/expand1.svg" alt="" width="18" height="18"/> : <img src="/img/collapse1.svg" alt="" width="18" height="18"/>}
                                </div>
                            </div>
                            <div>
                                {this.props.collapseAll === true ? "Expand" : "Collapse"}
                            </div>
                        </div>
                        </button>
                    </div>
                    <div className='mr-3'>                        
                        <SelectOverlay
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            type="sort"
                            title="Sort"
                            label="label"
                            value="value"
                            image={<img src="/img/sort1.svg" width="18" height="18" alt="sort"/>}
                            options={[{label: "Suite", value: "suite"}, {label: "Name", value: "name"}, {label: "Result", value: "result"}, {label: "Priority", value: "priority"}]}
                            valueChange={this.props.sortChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader11;