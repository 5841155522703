/*global */
import React, { Component } from 'react'
import Loading from './Loading'
import Request from './Request'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Countries from './Countries'
import Constants from './Constants'
import EUVAT from './EUVAT'

class Country extends Component {
    constructor() {
        super()
        this.state = {
            country: undefined, 
            newCountry: undefined, 
            message: <span></span>, 
            state: "view",
            vatId: "",
            confirmation: {success: undefined, failure: undefined}
        }
        this.getCountry = this.getCountry.bind(this)
        this.changeCountry = this.changeCountry.bind(this)
        this.countryChanged = this.countryChanged.bind(this)
        this.changeCountryConfirm = this.changeCountryConfirm.bind(this)
        this.confirm = this.confirm.bind(this)
        this.vatIdChange = this.vatIdChange.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount () {
        if (this.props.project.plan.name !== "free-v1") {
            this.getCountry();
        }
    }

    cancel () {
        this.setState({state:"view"})
    }

    getCountry () {
        Request.get("/country", {projectId: this.props.project.id, customerId: this.props.project.customerId}, (err, data) => {
            if (err) {
                this.setState({country: '', confirmation: {success: undefined, failure:  "Unable to retrieve country."}});
            } else {
                this.setState({country: data.country, newCountry: data.country, confirmation: {success: undefined, failure: undefined}});
            }
        });
    }

    changeCountry () {
        this.setState({state: "edit"})
    }

    changeCountryConfirm () {
        if (Constants.euCountry(this.state.newCountry)) {
            this.setState({state: "EUVAT"})
        } else {
            this.confirm()
        }
    }

    confirm () {
        const body = {
            projectId: this.props.project.id,
            customerId: this.props.project.customerId,
            country: this.state.newCountry
        }

        if (this.state.vatId !== undefined && this.state.vatId !== null && this.state.vatId !== "") {
            body.vatId = this.state.vatId
        }

        Request.post("/country-change", body, (err, data) => {
            if (err) {
                this.setState({country: '', confirmation: {success: undefined, failure:  "Unable to retrieve country."}});
            } else {
                this.setState({country: data.country, newCountry: data.country, confirmation: {success: undefined, failure: undefined}});
            }
        });

        // WITH THIS ON RESPONSE FROM BACK-END

        // on failure:
        this.setState({state: "view", confirmation: {success: undefined, failure: "Failed to change country"}})

        // on success:
        this.setState({state: "view", confirmation: {success: "Your account country has been changed to: " + this.state.newCountry, failure: undefined}})
    }

    countryChanged (e) {
        let message = <span></span>
        if (Constants.euCountry(e.target.value)) {
            message = <div></div>
        } else if (e.target.value === "GB") {
            message = <div>Please note that VAT will be applied to the invoice of UK based customers. Prices on the web site are exclusive of VAT.</div>
        }
        this.setState({newCountry: e.target.value, message: message});
    }

    vatIdChange (e) {
        this.setState({vatId: e.target.value});  
    }

    render() {
        if (this.props.project.plan.name === "free-v1") {
            return <div className='font14'>Country is not configurable for your project plan.</div>
        }
        let changeCountry = <span></span>
        let euvat = <span></span>
        let buttons = <ConfirmationButtons 
        confirm={this.state.state === "edit" ? this.changeCountryConfirm : this.changeCountry}
        cancel={this.state.state === "edit" ? this.cancel : undefined}
        confirmLabel={this.state.state === "edit" ? "Confirm Change" : "Change"}
        cancelLabel="Cancel"
        />
        if (this.state.state === "view") {

        } else if (this.state.state === "edit") {
            changeCountry = <Countries change={this.countryChanged} value={this.state.newCountry}/>
        } else if (this.state.state === "EUVAT") {
            euvat = <EUVAT vatId={this.state.vatId} vatIdChange={this.vatIdChange} cancel={this.props.cancel} next={this.confirm}/>
            buttons = <div></div>
        }
        return (
            <div>
                <div className="mb-1">
                    <label className="neutral4 font14">Country</label>
                </div>
                <div className="mb-4">
                    <Countries display={true} code={this.state.country}/>
                </div>
                {changeCountry}
                {euvat}
                {this.state.message}
                <Confirmation confirmation={this.state.confirmation}/>
                {buttons}
            </div>
        )
    }
};

export default Country;