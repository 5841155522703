import React, { Component } from 'react';
import KeyValue from './KeyValue'
import KeyValueArray from './KeyValueArray';
import SelectOverlay from './SelectOverlay';
import ConfirmationButtons from './ConfirmationButtons';

class JobRequest extends Component {
    constructor () {
        super()
        this.state = { init: false }
        this.init = this.init.bind(this)
        this.urlChange = this.urlChange.bind(this)
        this.methodChange = this.methodChange.bind(this)
        this.keyValueChange = this.keyValueChange.bind(this)
        this.addHeader = this.addHeader.bind(this)
        this.removeHeader = this.removeHeader.bind(this)
        this.addParam = this.addParam.bind(this)
        this.removeParam = this.removeParam.bind(this)
        this.methodRender = this.methodRender.bind(this)
        this.kvpArrayDiff = this.kvpArrayDiff.bind(this)
        this.diff = this.diff.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.type !== prevProps.type) {
            this.init()
        }
    }

    init () {
        try {
            const request = this.props.job[this.props.type].request
            this.setState({
                url: request.url,
                method: request.method,
                headers: request.headers,
                params: request.params,
                init: true
            })
        } catch (err) {
            this.setState({init: false})
        }
    }

    urlChange (e) {
        this.setState({url: e.target.value})
    }

    methodChange (value) {
        this.setState({method: value})
    }

    keyValueChange (type, index, label, value) {
        if (type === "headers") {
            let headers = this.state.headers
            let newHeaders = []
            for (let i = 0; i < headers.length; i++) {
                let header = headers[i]
                newHeaders.push({
                    key: header.key,
                    value: header.value
                })
            }
            newHeaders[index][label] = value
            this.setState({headers: newHeaders})
        }
        if (type === "params") {
            let params = this.state.params
            let newParams = []
            for (let i = 0; i < params.length; i++) {
                let param = params[i]
                newParams.push({
                    key: param.key,
                    value: param.value
                })
            }
            newParams[index][label] = value
            this.setState({params: newParams})
        }
    }

    addHeader () {
        let headers = []
        for (let i = 0; i < this.state.headers.length; i++) {
            headers.push(this.state.headers[i])
        }
        headers.push({key: "", value:""})
        this.setState({headers: headers})
    }

    removeHeader (index) {
        let headers = []
        for (let i = 0; i < this.state.headers.length; i++) {
            if (i !== index) {
                headers.push(this.state.headers[i])
            }
        }
        this.setState({headers: headers})
    }

    addParam () {
        let params = []
        for (let i = 0; i < this.state.params.length; i++) {
            params.push(this.state.params[i])
        }
        params.push({key: "", value:""})
        this.setState({params: params})
    }

    removeParam (index) {
        let params = []
        for (let i = 0; i < this.state.params.length; i++) {
            if (i !== index) {
                params.push(this.state.params[i])
            }
        }
        this.setState({params: params})
    }


    methodRender () {
        return (
            <SelectOverlay
                overlay={this.props.overlay} 
                messageOverlay={this.props.messageOverlay}
                type="generic"
                title={this.state.method}
                label="label"
                value="value"
                options={[
                    {label: "GET", value: "GET"}, 
                    {label: "POST", value: "POST"}
                ]}
                valueChange={this.methodChange}
                defaultValue={this.state.method}
                active={this.selectOverlayActive}
            />
        )
    }

    kvpArrayDiff (kvpArray1, kvpArray2) {
        let diff = false
        if (kvpArray1.length !== kvpArray2.length) {
            diff = true
        } else {
            kvpArray1.sort()
            kvpArray2.sort()
            for (let i = 0; i < kvpArray1.length; i++) {
                const kvp1 = kvpArray1[i]
                const kvp2 = kvpArray2[i]
                if (kvp1.key !== kvp2.key || kvp1.value !== kvp2.value) {
                    diff = true
                }
            }
        }
        return diff
    }

    diff () {
        if (this.props.job === undefined) {
            return false
        }
        let diff = false
        if (this.props.job[this.props.type].request.url !== this.state.url) {
            diff = true
        }
        if (this.props.job[this.props.type].request.method !== this.state.method) {
            diff = true
        }
        if (this.kvpArrayDiff(this.props.job[this.props.type].request.headers, this.state.headers)) {
            diff = true
        }
        if (this.kvpArrayDiff(this.props.job[this.props.type].request.params, this.state.params)) {
            diff = true
        }
        return diff
    }

    save () {
        let job = this.props.job
        job[this.props.type].request = {
            url: this.state.url,
            method: this.state.method,
            headers: this.state.headers,
            params: this.state.params
        }
        this.props.save(job)
    }

    render () {
        if (this.state.init !== true) {
            return <div></div>
        }
        return (
            <div className="maxSideWith width100 mb-5 pl-3 pr-3 primary8bg solidborder primary7border rounderborder">
                <div className='mb-3'>
                    <h5 className="primary1">
                        {this.props.type[0].toUpperCase() + this.props.type.substring(1) + " job request"} 
                    </h5>
                </div>
                <div className="mb-4">
                    <div className='primary1 font14'>
                        URL
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title={"The url for the request, e.g. https://www.your-company-name.com/internal/job/" + this.props.type}>
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <input className="tr-input-side" type="text" value={this.state.url} onChange={this.urlChange}/>
                </div>
                <div className="mb-4">
                    <div className='primary1 font14'>
                        Method
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title="The http method to use, either GET or POST">
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <div>
                        {this.methodRender()}
                    </div>
                    <div>

                    </div>
                </div>
                <div className="mb-4">
                    <div className='primary1 font14'>
                        Headers
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title="Any required http headers, e.g. name: 'Content-Language' and value: 'en'">
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <KeyValueArray kva={this.state.headers} fontColor="primary1" keyLabel="Header name" valueLabel="Header value" keyValueChange={this.keyValueChange} type="headers" add={this.addHeader} remove={this.removeHeader}/>
                </div>
                <div className="mb-4">
                    <div className='primary1 font14'>
                        Parameters
                        &nbsp;&nbsp;
                        <abbr className="tr-abbr clickable" title="Any required parameters. In the case of a GET request these are query parameters, in the case of a POST request these parameters will be sent in the body of the request.">
                            <img src='/img/info-128.png' widht="12px" height="12px"/>
                        </abbr>
                    </div>
                    <KeyValueArray kva={this.state.params} fontColor="primary1" keyLabel="Parameter name" valueLabel="Parameter value" keyValueChange={this.keyValueChange} type="params" add={this.addParam} remove={this.removeParam}/>
                </div>
                <div className="mb-4">
                {
                    this.diff() ?
                    <ConfirmationButtons
                        confirm={this.save}
                        cancel={this.init}
                        confirmLabel="Save changes"
                        cancelLabel="Discard"
                    />
                    :
                    <div></div>
                }
                </div>
            </div>

        )
    }

}

export default JobRequest