/*global */
import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom';
import DocsResultInterpretationSupport from './DocsResultInterpretationSupport'
import Notice from './Notice';

class DocsResultInterpretation extends Component {
    render () {
        return (
            <div>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Result Interpretation</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/DEBM6AIYSu4?si=xs5u7_NB7kw1aPEl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>When using a test framework integration to report results Tesults automatically maps test status values from a test framework to one of three Tesults result status values: pass, fail and unknown. Typically all passing results are mapped to pass, all failing results are mapped to fail, and everything else is mapped to unknown, including states such as skipped, timed out and any other test framework specific status values that are not clearly passing or failing states.</p>
                <p>To have finer control over this mapping and expose test framework specific result values in the interface, use the Result Interpretation feature.</p>
                <h4>Steps to using result interpretation</h4>
                <ol>
                    <li className='pb-5'><div className='mb-4'>Most test framework integrations do not currently support result interpretation. Over time more integrations will add support. Currently support is limited to these integrations:</div><DocsResultInterpretationSupport integration="all"/><Notice type="information" content={<div>If you are using a language library (Java, Python, JavaScript etc.), or are the author of a custom test framework or you are an integration developer use the <b>rawResult</b> property for test cases to enable support for result interpretation.</div>}/></li>
                    <li className='pb-5'><div>Go to the <NavLink to="/config" target="_blank" className="tr-link-primary4 no-break no-break">configuration menu</NavLink> and select <NavLink to="/config/project/result-interpretation" target="_blank" className="tr-link-primary4 no-break no-break">result interpretation</NavLink>. Click the 'Create new results mapping' button to create a new mapping.</div><img src="/img/result-interpretation-docs-1.png" alt="result-interpretation-docs-1" className='width75'/></li>
                    <li className='pb-5'><div>Creating a mapping of raw results values (from the test framework) to either a pass or fail result in Tesults. Anything not mapped will be mapped to unknown.</div><img src="/img/result-interpretation-docs-2.png" alt="result-interpretation-docs-2" className='width75'/></li>
                    <li className='pb-5'><div>Go back to the <NavLink to="/config" target="_blank" className="tr-link-primary4 no-break no-break">configuration menu</NavLink> and select <NavLink to="/config/project/targets" target="_blank" className="tr-link-primary4 no-break no-break">targets</NavLink>. Select the target you wish to apply the result interpretation mapping to. Look for the 'Target result interpretation map' field. By default this will be set to None. Select Edit and select the map you created in the previous step.</div><img src="/img/result-interpretation-docs-3.png" alt="result-interpretation-docs-3" className='width50'/></li>
                    <li className='pb-5'><div>Done. View results now and you will see that results are being interpreted in the way you have defined in your map. You can always edit the mapping you have created if you want to adjust what status values you treat as pass or fail.</div><img src="/img/result-interpretation-docs-4.png" alt="result-interpretation-docs-4" className='width50'/></li>
                </ol>
            </div>
        )
    }
}

export default DocsResultInterpretation