/*global */
import React, { Component } from 'react';

class ListGroupButton extends Component {
    render () {
        return (
            <button className="btn-confirm" style={{"display":"flex", "alignItems":"center"}} onClick={this.props.onClick}>
                <div className="mr-3">
                    {this.props.icon}
                </div>
                <div className="nounderline">
                    {this.props.label}
                </div>
            </button>
        );
    }
};

export default ListGroupButton;