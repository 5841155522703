/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsInvoices extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Invoices Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults invoices."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select the <b>Invoices</b> option.</p>
                <p>If you are on a paid plan you can download invoices from this page.</p>
                <p>View invoices by date and invoice number and view or download the invoice.</p>
                <p>If you have any billing questions please contact <a className='primary4' href="mailto:help@tesults.com">help@tesults.com</a>.</p>
            </div>
        );
    }
}

export default DocsInvoices;