/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsProject extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Project Configuration Guide</title>
                    <meta name="description" content="Learn how to configure a Tesults project."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Project to configure a project.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/e3D7q0a7PNA?si=SEVJxCMqBvlQ9uQy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Ensure the project you want to edit is selected from the dropdown list.</p>
                <h3>Project Name</h3>
                <p>Rename and edit the name of your Tesults project by clicking the edit button.</p>
                <p>Tesults recommends you name your project as your company name. See <Link target="_blank" to="/docs/structure">documentation</Link> for structing your project.</p>
                <h3>Project Image</h3>
                <p>Upload and edit the image for your project.</p>
                <h3>Project privacy scope</h3>
                <p>By default the project privacy scope is set to private. Edit privacy scope in the case that you want to make a subset of your results public. Setting a project public alone is not sufficient to make results public, there are privacy controls at the organization, project and target levels and all three need to be set to public in order for results for a specific target to be made available at a public link.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/j-cnId4rMXU?si=WigFTK1fn4R6qXDe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <h3>Create a new project</h3>
                <p>Click the 'Create a new project' button to create a new project.</p>
            </div>
        );
    }
}

export default DocsProject;