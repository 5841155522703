/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';
import ManualCaseSaveToList from './ManualCaseSaveToList';
import ManualAssigneeSelect from './ManualAssigneeSelect';
import ManualCaseUpdateList from './ManualCaseUpdateList';
import Confirmation from './Confirmation';
import SelectOverlay from './SelectOverlay';
import Request from './Request';
import Cache from './Cache'
import CaseDetailField from './CaseDetailField';
import IconLabel from './IconLabel';
import Context from './Context'

class ManualCaseEdit extends Component {
    static contextType = Context

    constructor(props) {
        super(props);
        let data = JSON.parse(JSON.stringify(this.props.data))
        // unwrap custom fields
        if (data !== undefined && data !== null) {
            if (data.custom !== undefined) {
                for (let i = 0; i < data.custom.length; i += 2) {
                    data[data.custom[i]] = data.custom[i+1];
                }
                delete data.custom;
            }
        }
        this.state = {
            data: data,
            error: "",
            fileInput: false,
            paramInput: false,
            paramName: "",
            paramValue: "",
            customName: "",
            customValue: "",
            saveConfirm: false,
            uptodateCase: undefined,
            selectOverlayActive: false,
            targets: [],
            targetIndex: 0,
            targetIdNotFound: undefined
        };
        this.textChange = this.textChange.bind(this);
        this.customChange = this.customChange.bind(this);
        this.resultChange = this.resultChange.bind(this);
        this.save = this.save.bind(this);
        this.saveConfirm = this.saveConfirm.bind(this);
        this.fileSelect = this.fileSelect.bind(this);
        this.fileInputToggle = this.fileInputToggle.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.addField = this.addField.bind(this);
        this.removeField = this.removeField.bind(this);
        this.paramInputToggle = this.paramInputToggle.bind(this);
        this.addParam = this.addParam.bind(this);
        this.removeParam = this.removeParam.bind(this);
        this.customInputToggle = this.customInputToggle.bind(this);
        this.addCustom = this.addCustom.bind(this);
        this.removeCustom = this.removeCustom.bind(this);
        this.markDoneChange = this.markDoneChange.bind(this);
        this.markCompleteChange = this.markCompleteChange.bind(this)
        this.setSuggestionLists = this.setSuggestionLists.bind(this);
        this.priorityChange = this.priorityChange.bind(this);
        this.assignSelectedAssigneeChange = this.assignSelectedAssigneeChange.bind(this);
        this.resultLabel = this.resultLabel.bind(this);
        this.priorityLabel = this.priorityLabel.bind(this);
        this.caseClick = this.caseClick.bind(this);
        this.selectOverlayActive = this.selectOverlayActive.bind(this);
        this.template = this.template.bind(this)
        this.project = this.project.bind(this)
        this.itemFields = this.itemFields.bind(this)
        this.itemTypeChange = this.itemTypeChange.bind(this)
        this.targets = this.targets.bind(this)
        this.targetChange = this.targetChange.bind(this)
    }

    componentDidMount () {
        this.setSuggestionLists();
        if (this.props.caseShow !== undefined) {
            this.props.caseShow();
        }
        if (this.props.type === "release-checklist") {
            this.targets()
        }
    }

    addField (name) {
        Analytics.event("ManualCaseAdd-" + name);
        let data = this.state.data;
        data[name] = "";
        this.setState({data: data});
    }

    removeField (name) {
        Analytics.event("ManualCaseRemove-" + name);
        let data = this.state.data;
        delete data[name];
        this.setState({data: data});
    }

    textChange (e) {
        if (e.target.name === "paramName") {
            this.setState({paramName: e.target.value});
        } else if (e.target.name === "paramValue") {
            this.setState({paramValue: e.target.value});
        } else if (e.target.name === "customName") {
            this.setState({customName: e.target.value});
        } else if (e.target.name === "customValue") {
            this.setState({customValue: e.target.value});
        } else {
            let data = this.state.data;
            data[e.target.name] = e.target.value;
            this.setState({data: data});
        }
    }

    customChange(e, key) {
        let data = this.state.data;
        data[key] = e.target.value;
        this.setState({data: data});
    }

    resultChange (value) {
        let data = this.state.data;
        data.result = value;
        this.setState({data: data});
    }

    resultLabel () {
        let data = this.state.data;
        if (data.result === undefined || data.result === "pass") {
            return "Pass";
        } else if (data.result === "fail") {
            return "Fail";
        } else {
            return "Unknown";
        }
    }

    priorityLabel () {
        let data = this.state.data;
        if (data.priority === -1) {
            return "  - Unassigned";
        } else if (data.priority === 0) {
            return "0 - Highest";
        } else if (data.priority === 1) {
            return "1 - High";
        } else if (data.priority === 2) {
            return "2 - Medium";
        } else if (data.priority === 3) {
            return "3 - Low";
        } else if (data.priority === 4) {
            return "4 - Lowest";
        } else {
            return "  - Unassigned";
        }
    }

    priorityChange (value) {
        let data = this.state.data;
        data.priority = value;
        this.setState({data: data});
    }

    setSuggestionLists () {
        let suiteDict = {};
        let suites = [];
        let customNameDict = {};
        let customNames = [];
        if (this.props.cases === undefined) {
            return;
        }
        this.props.cases.forEach(function (c) {
            if (c.suite !== undefined && suiteDict[c.suite] === undefined) {
                suiteDict[c.suite] = true;
                suites.push(<option key={c.suite} value={c.suite}/>)
            }

            Object.keys(c).forEach(function (key) {
                if (c.hasOwnProperty(key)) {
                    if (key.charAt(0) === "_") {
                        let customName = key.substring(1);
                        if (customNameDict[customName] === undefined) {
                            customNameDict[customName] = true;
                            customNames.push(<option key={customName} value={customName}/>)
                        }
                    }
                }
            });
        });
        this.setState({suiteSuggestions: suites, customNameSuggestions: customNames});
    }

    save () {
        // Check if case has been edited since start of edit (by another user for example)
        if (this.props.type === "list") {
            let caseData = this.state.data;
            if (caseData.suite !== undefined) {
                caseData.suite = caseData.suite.trim();
            }
            this.props.saveCase(caseData);
        } else if (this.props.type === "release-checklist") {
            this.props.saveItem(this.state.data)
        } else {
            let id = this.props.manualRunId;
            let created = this.props.manualRunCreated;
            let savingCase = this.state.data;
            Request.get("/manualCasesInProgress", {id: id, created: created}, (err, data) => {
                if (err) {
                    this.setState({error: "Unable to fetch manual cases in progress.", loading: false});
                } else {
                    let uptodateCase = undefined;
                    for (let i = 0; i < data.manualCasesInProgress.length; i++) {
                        let c = data.manualCasesInProgress[i];
                        if (c.num === savingCase.num) {
                            uptodateCase = c;
                        }
                    }
                    if (uptodateCase === undefined) {
                        this.props.saveCase(this.state.data);
                    } else {
                        let modifiedSinceEdit = false;
                        let uneditedCase = JSON.parse(JSON.stringify(this.props.data));
                        Object.keys(uneditedCase).forEach(function (key) {
                            if (uneditedCase.hasOwnProperty(key)) {
                                if (key !== "metadata" && key !== "importList") {
                                    if (uneditedCase[key] !== uptodateCase[key]) {
                                        if (Array.isArray(uneditedCase[key]) && Array.isArray(uptodateCase[key])) {
                                            let arr1 = uneditedCase[key];
                                            let arr2 = uptodateCase[key];
                                            if (arr1.length !== arr2.length) {
                                                modifiedSinceEdit = true;
                                            } else {
                                                arr1.sort();
                                                arr2.sort();
                                                for (let i = 0; i < arr1.length; i++) {
                                                    if (arr1[i] !== arr2[i]) {
                                                        modifiedSinceEdit = true;
                                                    }
                                                }
                                            }
                                        } else if (key === "params") {
                                            if (uneditedCase[key] !== undefined && uptodateCase[key] !== undefined) {
                                                Object.keys(uneditedCase[key]).forEach((paramKey) => {
                                                    if (uptodateCase[key].paramKey !== uneditedCase[key].paramKey) {
                                                        modifiedSinceEdit = true
                                                    }
                                                })
                                            }
                                        } else {
                                            modifiedSinceEdit = true;
                                        }
                                    }
                                }
                            }
                        });
                        if (modifiedSinceEdit === false) {
                            this.props.saveCase(this.state.data);
                        } else {
                            this.setState({saveConfirm: true, uptodateCase: uptodateCase});
                        }
                    }
                }
            });
        }
    }

    saveConfirm () { // Only necessary if data has changed since editing started
        this.props.saveCase(this.state.data);
    }

    fileSelect (e) {
        Analytics.event("ManualCaseFileSelected");
        const fileData = e.target.files[0];

        // Create remove dict
        let remove = {};
        let removeFiles = this.state.data.removeFiles;
        if (removeFiles !== undefined) {
            for (let i = 0; i < removeFiles.length; i++) {
                let rf = removeFiles[i];
                remove[rf] = i;
            }
        }

        // Check file with same does not exist already
        let existingFiles = this.state.data.files;
        if (existingFiles !== undefined) {
            if (existingFiles.length > 0) {
                for (let i = 0; i < existingFiles.length; i++) {
                    if (fileData.name === existingFiles[i]) {
                        if (remove[fileData.name] !== undefined) {
                            removeFiles.splice(remove[fileData.name], 1);
                            remove[fileData.name] = undefined;
                        } else {
                            this.setState({error: "A file with this name has already been added."});
                            return;
                        }
                    }
                }
            }
        }

        let newFilesData = this.state.data.newFilesData;
        if (newFilesData !== undefined) {
            let filesDict = {};
            for (let i = 0; i < newFilesData.length; i++ ) {
                filesDict[newFilesData[i].name] = i;
            }
            
            if (filesDict[newFilesData.name] !== undefined) {
                if (remove[fileData.name] !== undefined) {
                    removeFiles.splice(remove[fileData.name], 1);
                    remove[fileData.name] = undefined;
                } else {
                    this.setState({error: "A file with this name has already been added."});
                    return;
                }
            } 
        } else {
            newFilesData = [];
        }
        
        newFilesData.push(fileData);
        let data = this.state.data;
        data.newFilesData = newFilesData
        this.setState({data: data, fileInput: false});
    }

    fileInputToggle (toggle) {
        this.setState({fileInput: toggle});
    }

    removeFile (file) {
        let removeFiles = this.state.data.removeFiles;
        if (removeFiles === undefined) {
            removeFiles = [];
        }
        removeFiles.push(file);

        let newFilesData = this.state.data.newFilesData;
        let removeIndex = -1;
        if (newFilesData !== undefined) {
            for (let i = 0; i < newFilesData.length; i++) {
                if (newFilesData[i].name === file) {
                    removeIndex = i;
                }
            }
            if (removeIndex !== -1) {
                newFilesData.splice(removeIndex, 1);
            }
        }
        
        let data = this.state.data;
        data.removeFiles = removeFiles;
        data.newFilesData = newFilesData;
        this.setState({data: data});
    }

    paramInputToggle (toggle) {
        this.setState({paramInput: toggle});
    }

    addParam () {
        Analytics.event("ManualCaseAdd-Param");
        let data = this.state.data;
        let paramName = this.state.paramName;
        let paramValue = this.state.paramValue;
        if (paramName === "" || paramValue === "") {
            return;
        }
        if (data.params === undefined) {
            data.params = {};
        }
        data.params[paramName] = paramValue;
        this.setState({data: data, paramName: "", paramValue: "", paramInput: false});
    }

    removeParam(key) {
        let data = this.state.data;
        if (data.params === undefined) {
            return;
        }
        delete data.params[key];
        this.setState({data: data});
    }

    customInputToggle (toggle) {
        this.setState({customInput: toggle});
    }

    addCustom () {
        Analytics.event("ManualCaseAdd-Custom");
        let data = this.state.data;
        let customName = this.state.customName;
        let customValue = this.state.customValue;
        if (customName === "" || customValue === "") {
            return;
        }
        data["_" + customName] = customValue;
        this.setState({data: data, customName: "", customValue: "", customInput: false});
    }

    removeCustom(name) {
        let data = this.state.data;
        delete data[name];
        this.setState({data: data});
    }

    markDoneChange (e) {
        let data = this.state.data;
        data.manualComplete = e.target.value;
        this.setState({data: data});
    }

    markCompleteChange (e) {
        let data = this.state.data
        data.complete = e.target.value
        this.setState({data: data})
    }

    assignSelectedAssigneeChange (assignee) {
        let data = this.state.data;
        data.manualAssignee = assignee;
        this.setState({data: data});
    }

    caseClick () {
        if (this.state.selectOverlayActive === true) {
            this.props.overlay(undefined);
            this.setState({selectOverlayActive:false});
        }
    }

    selectOverlayActive () {
        this.setState({selectOverlayActive: true});
    }

    template (type) {
        if (type === "bdd") {
            let data = this.state.data
            data.desc = "Given:\n{context}\n\nWhen:\n\{action}\n\nThen:\n{outcome}\n"
            this.setState({data: data})
        } else if (type = "tdd") {
            let data = this.state.data
            data.desc = "Do:\n{action}\n\nAssert:\n\{expected outcome}\n"
            this.setState({data: data})
        } else {
            let data = this.state.data
            data.desc = "-"
            this.setState({data: data})
        }
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    targets () {
        let project = this.project()
        if (project === undefined) { 
            this.setState({targets: []})
            return
        }
        this.setState({loading: true});
        Cache.request(this.context, Cache.data.targets, {id: project.id, override: true}, (err, targets) => {
            if (err) {
                this.setState({targets: [], loading: false});
            } else {
                let targetIndex = Cache.getPreference(Cache.preference.targetIndex)
                let targetIdNotFound = undefined
                if (this.state.data.target !== undefined) {
                    let found = false
                    for (let i = 0; i < targets.length; i++) {
                        let target = targets[i]
                        if (target.created === this.state.data.target) {
                            targetIndex = i
                            found = true
                        }
                    } 
                    if (found !== true) {
                        targetIdNotFound = this.state.data.target
                    }
                }
                if (targetIndex === undefined) {
                    targetIndex = 0;
                }
                if (targetIndex >= targets.length) {
                    targetIndex = 0
                }
                if (targets.length > 0) {
                    let data = this.state.data
                    data.target = targets[targetIndex].created
                    this.setState({targets: targets, targetIndex: targetIndex, targetIdNotFound: targetIdNotFound, data: data, loading: false});
                } else {
                    this.setState({loading: false, targets: [], targetIndex: targetIndex});
                }
            }
        });
    }

    targetChange (index) {
        if (index !== undefined) {
            if (index < this.state.targets.length) {
                let data = this.state.data
                data.target = this.state.targets[index].created
                Cache.setPreference(Cache.preference.targetIndex, index)
                this.setState({targetIndex: index, data: data})
            }
        }
    }

    itemTypeChange (value) {
        let data = this.state.data
        data.type = value
        this.setState({data: data})
    }

    itemFields () {
        let typeOptions = 
        [
            {label: "Test results (automated)", value: "test_results_automated"},
            {label: "Test results (manual)", value: "test_results_manual"},
            {label: "General", value: "general"}
        ]

        const displayTypeValue = (value) => {
            for (let i = 0; i < typeOptions.length; i++) {
                let option = typeOptions[i]
                if (option.value === value) {
                    return option.label
                }
            }
            return ""
        }

        let type = <CaseDetailField
                        label="Type"
                        value = {
                            <div className='mb-3'>
                                <SelectOverlay
                                    overlay={this.props.overlay} 
                                    messageOverlay={this.props.messageOverlay}
                                    type="generic"
                                    title={displayTypeValue(this.state.data.type)}
                                    label="label"
                                    value="value"
                                    options={typeOptions}
                                    valueChange={this.itemTypeChange}
                                    defaultValue={"test_results_automated"}
                                    active={this.selectOverlayActive}
                                />
                                <div>

                                </div>
                            </div>
                        }
                    />

        let title = <CaseDetailField
            label="Title"
            value={
                <div className="mb-3">
                    <input type="text" className="tr-input form-control" name="name" autoComplete="off" value={this.state.data.name} onChange={this.textChange} required/>
                </div>
            }
        />

        let targetOptions = []
        for (let i = 0; i < this.state.targets.length; i++) {
            let target = this.state.targets[i]
            targetOptions.push({name: target.name, value: this.state.targetIndex, id: target.id, created: target.created, aext: target.aext})
        }

        let target = 
        <CaseDetailField
            label="Target"
            value={
                <SelectOverlay 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        type="target-preview"
                        title="Target" 
                        label="name" 
                        value="value"
                        options={targetOptions} 
                        index={this.state.targetIndex}
                        valueChange={this.targetChange} 
                        defaultValue={this.state.targetIndex}
                        active={this.selectOverlayActive}
                />
            }
        />

        let runArchive = 
        <CaseDetailField
            label="Archived test run"
            value={
                <input className='tr-input' name="runArchive" onChange={this.textChange} value={this.state.data.runArchive}/>
            }
        />

        let manualRun = 
        <CaseDetailField
            label="Manual test run"
            value={
                <input className='tr-input' name="manualRun" onChange={this.textChange} value={this.state.data.manualRun}/>
            }
        />

        let manualRunArchive = 
        <CaseDetailField
            label="Manual test run archive"
            value={
                <input className='tr-input' name="manualRunArchive" onChange={this.textChange} value={this.state.data.manualRunArchive}/>
            }
        />

        return {
            type: type,
            title: title,
            target: target,
            runArchive: runArchive,
            manualRun: manualRun,
            manualRunArchive: manualRunArchive
        }
    }

    render() {
        /*
        Attributes that can be set for a case:

        name    DONE
        result  DONE
        suite   DONE
        desc    DONE
        reason  DONE
        params  DONE
        files   DONE
        _Custom DONE

        */

        if (this.state.data === undefined || this.state.data === null) {
            return <span></span>
        }

        let removeDict = {};
        if (this.state.data.removeFiles !== undefined) {
            this.state.data.removeFiles.forEach(function (f) {
                removeDict[f] = true;
            });
        }

        let existingFiles = [];
        let existingFilesDisplay = <span></span>;
        if (this.state.data.files !== undefined) {
            if (this.state.data.files.length > 0) {
                for (let i = 0; i < this.state.data.files.length; i++) {
                    let f = this.state.data.files[i];
                    if (removeDict[f] === undefined) {
                        existingFiles.push(
                            <tr key={f}>
                                <td className="font14">{f}</td>
                                <td><button type="button" className="btn-cancel mb-3" onClick={() => {this.removeFile(f)}}>Remove</button></td>
                            </tr>
                        );
                    }
                }                
            }
        }

        if (this.state.data.newFilesData !== undefined) {
            if (this.state.data.newFilesData.length > 0) {
                for (let i = 0; i < this.state.data.newFilesData.length; i++) {
                    let f = this.state.data.newFilesData[i].name;
                    existingFiles.push(
                        <tr key={f}>
                            <td className="font14">{f}</td>
                            <td><button type="button" className="btn-cancel mb-3" onClick={() => {this.removeFile(f)}}>Remove</button></td>
                        </tr>
                    );
                } 
            }
        }

        if (existingFiles.length > 0) {
            existingFilesDisplay =
            <div>
                <table className="table table-striped mb-3">
                    <thead>
                    <tr>
                    <th></th>
                    <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {existingFiles}
                    </tbody>
                </table>
            </div>
        }

        let fileButton = <span></span>
        if (this.props.type === "manual") {
            let fileButtonValue =  
            <div className="mb-3">
                <span className="tr-link-gray mt-3 font14" onClick={() => {this.fileInputToggle(true)}}>Add File</span>
            </div>
            if (this.state.fileInput === true) {
                fileButtonValue = 
                    <div>
                        <button type="button" className="btn btn-cancel mt-1 mb-3 small" onClick={() => {this.fileInputToggle(false)}}>Cancel Add File</button>
                    </div>
            }
            fileButton = <CaseDetailField
                            label="Files"
                            value={fileButtonValue}
                        />
        }

        let assignOptions = <span></span>
        if (this.props.type === "manual") {
            assignOptions = <CaseDetailField
                                label="Assign case to a team member"
                                value={<ManualAssigneeSelect all={false} members={this.props.members} assignSelectedAssignee={this.props.assignSelectedAssignee} assigneeChange={this.assignSelectedAssigneeChange} assignee={this.props.assignee} caseData={this.state.data}/>}
                            />
        }

        let fileInput = <span></span>;
        if (this.state.fileInput === true) {
            fileInput = 
                <div>
                    <input type="file" id="input" className="mt-3" onChange={this.fileSelect}/>
                </div>
        }

        let name = <CaseDetailField
                        label="Name"
                        value={
                            <div className="mb-3">
                                <input type="text" className="tr-input form-control" name="name" autoComplete="off" value={this.state.data.name} onChange={this.textChange} required/>
                            </div>
                        }
                    />

        let paramsExisting = <span></span>;
        if (this.state.data.params !== undefined) {
            let existingParams = [];
            let paramsData = this.state.data.params;
            let removeParam = this.removeParam;
            Object.keys(paramsData).forEach(function (key) {
                if (paramsData.hasOwnProperty(key)) {
                    existingParams.push(
                        <tr key={key}>
                            <td className="font14">{key}</td>
                            <td className="font14">{paramsData[key]}</td>
                            <td><button type="button" className="btn-cancel mb-3" onClick={() => {removeParam(key)}}>Remove</button></td>
                        </tr>
                    );
                }
            });

            paramsExisting = 
                <div>
                <table className="table table-striped width100">
                <thead className="neutral7bg">
                <tr>
                <th className="font12 white">Name</th>
                <th className="font12 white">Value</th>
                <th></th>
                </tr>
                </thead>
                <tbody>
                {existingParams}
                </tbody>
                </table>
                </div>
        }

        let paramAddButton =  
            <div className="mb-3">
                <span className="tr-link-gray mt-3 font14" onClick={() => {this.paramInputToggle(true)}}>New parameter</span>
            </div>
        if (this.state.paramInput === true) {
            paramAddButton = 
                <div className="text-right">
                    <button type="button" className="btn btn-confirm mt-1 mb-3 mr-3 small" onClick={() => {this.addParam()}}>Add Parameter</button>
                    <button type="button" className="btn btn-cancel mt-1 mb-3 small" onClick={() => {this.paramInputToggle(false)}}>Cancel Parameter Add</button>
                </div>
        }

        let paramInput = <span></span>;
        if (this.state.paramInput === true) {
            paramInput = 
                <div>
                    <h5 className="neutral4 mb-1 mt-3">Parameter name</h5>
                    <input type="text" className="tr-input form-control" name="paramName" value={this.state.paramName} onChange={this.textChange} required/>
                    <h5 className="neutral4 mb-1 mt-3">Parameter value</h5>
                    <input type="text" className="tr-input form-control" name="paramValue" value={this.state.paramValue} onChange={this.textChange} required/>
                </div>
        }

        let params = <CaseDetailField
                        label="Parameters"
                        value= {
                            <div>
                                {paramsExisting}
                                {paramInput}
                                {paramAddButton}
                            </div>
                        }
                    />

        let result = <CaseDetailField 
                        label={this.props.type === "list" ? "Default Result" : "Result"} 
                        value={
                            <div>
                                <SelectOverlay
                                    overlay={this.props.overlay} 
                                    messageOverlay={this.props.messageOverlay}
                                    type="generic"
                                    title={this.resultLabel()}
                                    label="label"
                                    value="value"
                                    options={[
                                        {label: "Pass", value: "pass", img: <img src="/img/tesults-logo-pass-128.png" width="16" height="16" alt=""/>}, 
                                        {label: "Fail", value: "fail", img: <img src="/img/tesults-logo-fail-128.png" width="16" height="16" alt=""/>}, 
                                        {label: "Unknown", value: "unknown", img: <img src="/img/tesults-logo-unknown-128.png" width="16" height="16" alt=""/>}
                                    ]}
                                    valueChange={this.resultChange}
                                    defaultValue={this.state.data.result}
                                    active={this.selectOverlayActive}
                                />
                            </div>
                        }
                    />

        let priorityValue = this.state.data.priority;
        if (priorityValue === undefined) {
            priorityValue = -1;
        }

        let priority = <CaseDetailField 
                            label="Priority" 
                            value={
                                <div>
                                    <SelectOverlay
                                        overlay={this.props.overlay} 
                                        messageOverlay={this.props.messageOverlay}
                                        type="generic"
                                        title={this.priorityLabel()}
                                        label="label"
                                        value="value"
                                        options={[
                                            {label: "- - Unassigned", value: -1}, 
                                            {label: "0 - Highest", value: 0}, 
                                            {label: "1 - High", value: 1},
                                            {label: "2 - Medium", value: 2},
                                            {label: "3 - Low", value: 3},
                                            {label: "4 - Lowest", value: 4},
                                        ]}
                                        valueChange={this.priorityChange}
                                        defaultValue={this.state.data.priority}
                                        active={this.selectOverlayActive}
                                    />
                                </div>
                            }
                        />

        let suiteValue = 
            <div className="mb-3">
                <span className="tr-link-gray font14" onClick={() => {this.addField("suite")}}>Add</span>
            </div>
        if (this.state.data.suite !== undefined) {
            suiteValue = 
                <div className="mb-3">
                    <h5 className="neutral4 mb-1 mt-3 font14">{this.props.type === "release-checklist" ? "Group" : "Suite"}</h5>
                    <input type="text" list="suites" className="tr-input form-control mb-3" name="suite" value={this.state.data.suite} onChange={this.textChange} autoComplete="-off-" required/>
                    <div className="text-right">
                        <button type="button" className="btn btn-cancel mt-0 small" onClick={() => {this.removeField('suite')}}>Remove</button>
                    </div>
                </div>
        }

        let suite = <CaseDetailField
                        label={this.props.type === "release-checklist" ? "Group" : "Suite"}
                        value={suiteValue}
                    />

        let descValue = 
            <div className="mb-3">
                <span className="tr-link-gray font14 mt-3" onClick={() => {this.addField("desc")}}>Add description</span>
            </div>
        if (this.state.data.desc !== undefined) {
            let templates = <div></div>
            if (this.state.data.desc === "") {
                templates = <div><button type="button" onClick={() => this.template("bdd")} className='btn-action mr-3'>BDD template</button><button type="button" onClick={() => this.template("tdd")} className='btn-action'>TDD template</button></div>
            }
            descValue = 
                <div className="mb-3">
                    <h5 className="neutral4 mb-1 mt-3 font14">Description</h5>
                    {this.props.type === "release-checklist" ? <span></span> : templates}
                    <textarea className="tr-input-no-fixed-dim form-control " cols="80" rows="14" name="desc" value={this.state.data.desc} onChange={this.textChange} required></textarea>
                    <div className="text-right">
                        <button type="button" className="btn btn-cancel mt-1 small" onClick={() => {this.removeField('desc')}}>Remove Description</button>
                    </div>
                </div>
        }

        let desc = <CaseDetailField label="Description" value={descValue}/>

        let reasonValue = <span></span>
        if (this.state.data.result !== "pass") {
            reasonValue = 
            <div>
                <span className="tr-link-gray mt-3 font14" onClick={() => {this.addField("reason")}}>Add failure reason</span>
            </div>
            if (this.state.data.reason !== undefined) {
                reason = 
                    <div>
                        <textarea className="form-control verticalResize elevation3 mb-3" rows="6" name="reason" value={this.state.data.reason} onChange={this.textChange} required></textarea>
                        <br/>
                    </div>
            }
        }

        let reason = <CaseDetailField label="Failure reason" value={reasonValue}/>

        let customExisting = [];
        let data = this.state.data;
        let removeCustom = this.removeCustom;
        let customChange = this.customChange;
        Object.keys(data).forEach(function (key) {
            if (data.hasOwnProperty(key)) {
                if (key.charAt(0) === "_") {
                    customExisting.push(
                        <div key={key}>
                            <h5 className="neutral4 mt-3 mb-1">{key.substring(1)}</h5>
                            <textarea className="mt-0 tr-input form-control" cols="12" rows="10" name={key} value={data[key]} onChange={(e) => customChange(e, key)} required>{data[key]}</textarea>
                            <br/>
                            <button type="button" className="btn-cancel mb-3" onClick={() => {removeCustom(key)}}>Remove</button>
                        </div>
                    );
                }
            }
        });

        let customAddButton =  
            <div>
                <span className="tr-link-gray mt-3 font14" onClick={() => {this.customInputToggle(true)}}>New custom field</span>
            </div>
        if (this.state.customInput === true) {
            customAddButton = 
                <div className="text-right">
                    <button type="button" className="btn btn-confirm mt-1 mr-3 small" onClick={() => {this.addCustom()}}>Confirm Custom Field</button>
                    <button type="button" className="btn btn-cancel mt-1 small" onClick={() => {this.customInputToggle(false)}}>Cancel Custom Field Add</button>
                </div>
        }

        let customInput = <span></span>;
        if (this.state.customInput === true) {
            customInput = 
                <div>
                    <br/>
                    <h5 className="neutral4 mb-1 mt-3">Custom field name</h5>
                    <input type="text" list="customNames" className="tr-input form-control" name="customName" value={this.state.customName} onChange={this.textChange} autoComplete="-off-" required/>
                    <h5 className="neutral4 mb-1 mt-3">Custom field value</h5>
                    <textarea className="tr-input form-control" rows="10" name="customValue" value={this.state.customValue} onChange={this.textChange} required></textarea>
                </div>
        }

        let custom = <CaseDetailField
                        label="Custom Fields"
                        value={
                            <div>
                                {customExisting}
                                {customInput}
                                {customAddButton}
                            </div>
                        }
                    />

        let markDone = <span></span>
        if (this.props.type === "manual") {
            let options = [];
            options.push(<option key={0} value={true}>Done</option>);
            options.push(<option key={1} value={false}>Not Done</option>);
            markDone = <CaseDetailField
                            label="Mark done"
                            value={
                                <select className="custom-select" onChange={this.markDoneChange} defaultValue={this.state.data.complete}>
                                    {options}
                                </select>
                            }
                        />
        }
        let markComplete = <span></span>
        if (this.props.type === "release-checklist") {
            let options = []
            options.push(<option key={0} value={false}>Incomplete</option>)
            options.push(<option key={1} value={true}>Complete</option>)
            markComplete = <CaseDetailField
                label="Mark done"
                value={
                    <select className="custom-select" onChange={this.markCompleteChange} defaultValue={this.state.data.complete}>
                        {options}
                    </select>
                }
            />
        }

        let persistentMessage = <span></span>
        let persistentUpdateMessage = <span></span>
        if (this.props.type !== "list" && this.props.type !== "release-checklist") {
            let newCase =  data.num === undefined ? true : false;
            persistentMessage = <ManualCaseSaveToList
                                    saveToListEnabled={this.props.saveToListEnabled}
                                    saveToLists={this.props.saveToLists}
                                    saveToListToggle={this.props.saveToListToggle}
                                    saveToListId={this.props.saveToListId}
                                    saveToListChange={this.props.saveToListChange}
                                    newCase={newCase}
                                />
            persistentUpdateMessage = <ManualCaseUpdateList
                                        updateListEnabled={this.props.updateListEnabled}
                                        updateListToggle={this.props.updateListToggle}
                                        newCase={newCase}
                                        importList={this.props.data.importList}
                                    />
        }

        let persistent = <div>
                            {persistentMessage}
                            {persistentUpdateMessage}
                         </div>

        let saveConfirm = <span></span>
        if (this.state.saveConfirm === true) {
            saveConfirm = 
            <div className="mt-3 mb-3 font14 pl-3 pr-3">
                <b>Important</b>
                <br/>
                <span>This test case has changed since you began editing it, possibly by another team member. Please confirm you want to save these changes and override the current test case. To avoid conflicts of this sort, assign test cases to team members.</span>
                <br/>
                <button type="button" className="btn btn-white mt-1 mb-1" onClick={this.saveConfirm} disabled={this.state.data.name === "" ? true : false}><IconLabel icon="/img/edit-item.svg" label="Save Confirm"/></button>
            </div> 
        }

        let subheaderFontColor = "neutral6";
        let bgHeaderClass = "modal-header accenta3borderheader";

        if (this.state.data.result === "fail") {
            
        } else if (this.state.data.result === "unknown") {
            subheaderFontColor = "neutral6";
            bgHeaderClass = "modal-header accentb2borderheader";
        } else if (this.state.data.result === "pass") {
            bgHeaderClass = "modal-header accentc3borderheader";
        }
        if (this.state.data.manualComplete !== undefined) {
            if (this.state.data.manualComplete === false || this.state.data.manualComplete === "false") {
                bgHeaderClass = "modal-header";
            }
        }
        bgHeaderClass += this.props.darkMode === true ? " darkbg" : "";

        const { type, title, target, runArchive, manualRun, manualRunArchive } = this.itemFields()


        let caseHeading = (this.state.data.name === undefined || this.state.data.name === "") ? "Test case" : this.state.data.name
        if (this.props.type === "release-checklist") {
            caseHeading = this.state.data.name === undefined ? "Item" : this.state.data.name
        }

        return (
            <div className="case-detail" onClick={this.caseClick}>
                <div className="case-detail-header" ref={this.ref}>
                        <div className='flex-row mt-4'>
                            <div className="case-detail-title">
                                <span className={"wrapbtn neutral1"}>{caseHeading}</span>
                            </div>
                            <div className="case-detail-close">
                                <button onClick={() => { this.props.history.push(this.props.cancelUrl); this.props.sideOverlay(undefined);}} className="btn-transparent btn-close">&times;</button>
                            </div>
                        </div>
                        <div className="case-detail-project-and-target">
                            {/*<div className="case-detail-project-and-target-details">
                                <Project projects={this.props.projects} projectIndex={this.props.projectIndex}/>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {
                                    (this.props.targets !== undefined) ? 
                                    <Target targets={this.props.targets} targetIndex={this.props.targetIndex}/>
                                    :
                                    <div></div>
                                }
                            </div>
                            <div className="case-detail-share">
                                <SelectOverlay
                                    overlay={this.props.overlay}
                                    messageOverlay={this.props.messageOverlay}
                                    type="share"
                                    image={<img src="/img/share-btn.svg" width="40" height="40" alt=""/>} 
                                    content={this.props.share}
                                    contentWidth={240}
                                    active={this.selectOverlayActive}
                                />
                            </div>*/}
                        </div>
                        <div className="case-detail-icons">
                            {/*{flaky}
                            {bug}
                            {cost}*/}
                        </div>
                        <div className='flex-row'>
                            <div>
                                <div className='font14 pl-3 pr-3'>
                                    <p>Changes are not saved automatically, click the save button after making changes.</p>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-white ml-3 mt-1 mb-1" onClick={this.save} disabled={(this.state.data.name === "" || this.props.saving === true) ? true : false}><IconLabel icon="/img/edit-item.svg" label="Save"/></button>
                                    {this.props.type === "release-checklist" ?
                                        <button type="button" className="btn btn-white ml-5 mt-1 mb-1"  onClick={() => this.props.deleteItem(this.state.data)} disabled={(this.state.data.name === "" || this.props.saving === true) ? true : false}><IconLabel icon="/img/delete-item.svg" label="Delete item"/></button>
                                        :
                                        <span></span>
                                    }
                                </div>
                                <div>
                                    {saveConfirm}
                                </div>
                                <div>
                                    {this.state.error}
                                </div>
                                <div>
                                    {persistent}
                                </div>
                                <Confirmation confirmation={this.props.confirmation}/>
                                <div className="mb-3 neutral4 font12">{this.state.data.name === "" ? "A name is required to save" : " "}</div>
                            </div>
                        </div>
                </div>
                {
                    this.props.type === "release-checklist" ?
                    <div className='case-detail-body'>
                        {markComplete}
                        {type}
                        {title}
                        {suite}
                        {this.state.data.type === "test_results_automated" ? target : <span></span>}
                        {this.state.data.type === "test_results_automated" ? runArchive : <span></span>}
                        {this.state.data.type === "test_results_manual" ? manualRun : <span></span>}
                        {this.state.data.type === "test_results_manual" ? manualRunArchive : <span></span>}
                        {desc}
                        {custom}
                        <datalist id="suites">
                            {this.state.suiteSuggestions}
                        </datalist>
                        <datalist id="customNames">
                            {this.state.customNameSuggestions}
                        </datalist>
                    </div>
                    : // For list and manual (run):
                    <div className='case-detail-body'>
                        {result}
                        {priority}
                        {name}
                        {suite}
                        {desc}
                        {assignOptions}
                        {markDone}
                        {reason}
                        {params}
                        {fileButton}
                        {existingFilesDisplay}
                        {fileInput}
                        {custom}
                        <datalist id="suites">
                            {this.state.suiteSuggestions}
                        </datalist>
                        <datalist id="customNames">
                            {this.state.customNameSuggestions}
                        </datalist>
                    </div>
                }
            </div>
        );
    }
}

export default ManualCaseEdit;