/*global */
import React, { Component } from 'react';
import CaseList from './CaseList';

class ResultsView extends Component {
    render  () {
        const caseList = <CaseList 
            context={"results"}
            overlay={this.props.overlay}
            messageOverlay={this.props.messageOverlay}
            side={this.props.side}
            sideOverlay={this.props.sideOverlay}
            sort={this.props.sort}
            filter={this.props.filter}
            search={this.props.search}
            org={this.props.org} 
            projects={this.props.projects} 
            projectIndex={this.props.projectIndex} 
            targets={this.props.targets}
            targetIndex={this.props.targetIndex}
            runs={this.props.runs}
            runIndex={this.props.runIndex}
            cases={this.props.cases}
            builds={this.props.builds}
            role={this.props.role}
            modalCase={this.props.modalCase}
            persistentCaseDataUpdate={this.props.persistentCaseDataUpdate}
            persistentCaseDataIteration={this.props.persistentCaseDataIteration}
            persistentSuiteDataUpdate={this.props.persistentSuiteDataUpdate}
            persistentSuiteDataIteration={this.props.persistentSuiteDataIteration}
            persistentSuiteData={this.props.persistentSuiteData}
            darkMode={this.props.darkMode}
            trl={this.props.trl}
            rsp={this.props.rsp}
            trlCase={this.props.trlCase}
            multiEdit={this.props.multiEdit}
            multiselectUpdate={this.props.multiselectUpdate}
            multiselectEnabled={this.props.multiEdit}
            preRender={this.props.preRender}
            rspCancel={this.props.rspCancel}
            members={this.props.members}
            targetUpdate={this.props.targetUpdate}
            collapseAll={this.props.collapseAll}
            rawResultMaps={this.props.rawResultMaps}
            preferencesReportingCaseDisplayAttributeMaps={this.props.preferencesReportingCaseDisplayAttributeMaps}
            runArchive={this.props.runArchive}
            runArchiveBuildCase={this.props.runArchiveBuildCase}
            runArchiveCases={this.props.runArchiveCases}
            isRunArchive={this.props.isRunArchive}
            revUpdate={this.props.revUpdate}
            caseEnhancedAnalysis={this.props.caseEnhancedAnalysis}
            targetDetail={this.props.targetDetail}
            />

        return (
            <div>
                {caseList}
                {this.props.onboarding}
            </div>
        );
    }
}

export default ResultsView;