/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsSupplemental extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Supplemental</title>
                    <meta name="description" content="Learn how to use the supplemental view on Tesults."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/64etVk_nfQg?si=0YQYq8HsGqoqMUxa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Click <b>Supplemental</b> from the menu to view supplemental test results data.</p>
                <p>The supplemental view focuses on providing analysis on key metrics, unlike the results view which lists every test case in the test run for a comprehensive review.</p>
                <p>Use the supplemental view to understand which test cases have just started failing, started passing, and which test cases are continuing to fail. Flaky tests can be examined here too. The supplemental view is a great way to understand the trajectory of pass rates for recent test runs.</p>
                <img src="https://www.tesults.com/files/docs/supplemental-1.png" alt="" className="width100"/>
            </div>
        );
    }
}

export default DocsSupplemental;