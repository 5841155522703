/*global*/
import React, { Component } from 'react';
import SelectOverlay from './SelectOverlay';

class ResultsHeader10 extends Component {
    constructor () {
        super()
    }

    render () {
        window.scrollTo(0, 0);
        return (
            <div className="results-header10">
                <div className="results-header10-selector">
                    <SelectOverlay 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        valueChange={this.props.runIndex1Change} 
                        type="run" 
                        label="created" 
                        options={this.props.runs1} 
                        index={this.props.runIndex1}
                        moreRuns={this.props.runs1Esk === undefined ? false : true}
                        getMoreRuns={() => {this.props.getDiffRuns(1)}}
                    />
                </div>
                <div className="results-header10-selector">
                    <SelectOverlay 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        valueChange={this.props.runIndex2Change}
                        type="run" 
                        label="created" 
                        options={this.props.runs2} 
                        index={this.props.runIndex2}
                        moreRuns={this.props.runs2Esk === undefined ? false : true}
                        getMoreRuns={() => {this.props.getDiffRuns(2)}}
                    />
                </div>
            </div>
        );
    }
};

export default ResultsHeader10