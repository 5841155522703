/*global Cookies*/
import React, { Component } from 'react';
import MenuItem from './MenuItem';
import MenuHeader from './MenuHeader'
import SelectOverlay from './SelectOverlay';
import Context from './Context';
import Cache from './Cache';
import RSPHandler from './RSPHandler';
import ShareSSOLoginLink from './ShareSSOLoginLink';

class Menu extends Component {
    static contextType = Context;

    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let loggedIn = true;
        if (user === undefined || user == null) {
            loggedIn = false;
        }
        this.state = {loggedIn: loggedIn, projects: undefined, projectIndex: undefined};
        this.projectIndexChange = this.projectIndexChange.bind(this);
    }

    componentDidMount () {
        if (this.props.site !== true) {
            let rsp  = RSPHandler.rspData();
            this.setState({rsp: rsp}, () => {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                this.projectIndexChange(projectIndex, true);
            });
        }
    }

    projectIndexChange (index, directLink) {
        if (index === undefined) {
            index = 0;
        }
        let rsp = RSPHandler.rspData();
        let prevIndex = Cache.getPreference(Cache.preference.projectIndex);
        Cache.setPreference(Cache.preference.projectIndex, index);
        Cache.request(this.context, Cache.data.projects, {override: true}, (err, projects) => {
            if (err) {
                // Failed project request
            } else {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                if (rsp.projectId !== undefined) {
                    for (let i = 0; i < projects.length; i++) {
                        let project = projects[i];
                        if (rsp.projectId === project.id && directLink === true) {
                            projectIndex = i;
                        }
                    }
                }
                if (projectIndex >= projects.length) {
                    projectIndex = 0;
                    Cache.setPreference(Cache.preference.projectIndex, 0);
                }
                if (prevIndex === index) {
                    this.setState({projects: projects, projectIndex: projectIndex});
                } else {
                    let url = undefined;
                    if (window.location.pathname.startsWith("/results")) {
                        let view = rsp.view;
                        if (view === undefined) {
                            let savedView = Cache.getPreference(Cache.preference.resultsView);
                            if (savedView === undefined) {
                                view = "results";
                            }
                            if (savedView === "results" || savedView === "supplemental" || savedView === "status") {
                                view = savedView;
                            }
                        }
                        url = "/results/rsp/view/" + view + "/project/" + projects[projectIndex].id;
                        
                    }
                    this.setState({projects: projects, projectIndex: projectIndex}, () => {
                        this.props.projectChange()
                        if (url !== undefined) {
                            this.props.history.push(url);
                        }
                    });
                }
                
            }
        });
    }
    
    render () {
        let menuItemsClass = "menu-items";
        if (this.props.mobileMenu === true) {
            menuItemsClass = "menu-items-mobile";
        }
        if (this.props.site === true) {
            return (
                <div style={{"paddingLeft":"0px", "paddingRight":"21px"}}>
                    <div className="mobile-menu">
                        {this.state.loggedIn ?  
                        <div style={{"display":"flex", "alignItems":"center"}}>                            
                            <div>
                                <img alt="" src="/img/menu-menu-primary4.svg" width="24" height="12" className="clickable" onClick={this.props.toggleMobileMenu}/>
                            </div>
                            <div className="menu-header-logo">
                                <a href="/" className="tr-link-neutral1">
                                    <div style={{"display":"flex", "alignItems":"center"}}>
                                        <div style={{"marginTop":"4px"}}>
                                            <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                                        </div>
                                        <div className="neutral2">
                                            &nbsp;<span className="bolder font16 primary4">Tesults</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        :
                        <div>
                            <a href="/" className="tr-link-neutral1">
                                <div style={{"display":"flex", "alignItems":"center"}}>
                                    <div style={{"marginTop":"4px"}}>
                                        <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                                    </div>
                                    <div className="neutral2">
                                        &nbsp;<span className="bolder font16 primary4">Tesults</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        }
                    </div>
                    <div className={menuItemsClass}>
                        <div className="menu-items-top">
                            {this.props.content}
                        </div>
                        <div className="menu-items-bottom menu-items-bottom-mobile">
                            &nbsp;
                        </div>
                    </div>
                    {/*<div className="site-menu-home-link">
                        <a href="/" className="tr-link-neutral1">
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                <div className="neutral2 mb-3 mr-2">
                                    &nbsp;<span className="bold neutral4">tesults.com</span>
                                </div>
                                <div>
                                    <img style={{"marginTop":"-2px"}} src="/img/link.svg" width="16" height="16" alt=""/>
                                </div>
                            </div>
                        </a>
                    </div>*/}
                </div>
            );
        }

        // App menu if not site
        let selectedProject = undefined;
        let loginLink = <span></span>
        if (Cache.getPreference(Cache.preference.projectIndex) !== undefined && this.context.projects !== undefined) {
            if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
                selectedProject = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)];
                loginLink = <ShareSSOLoginLink projectId={selectedProject.id} full={this.props.full}/>
            }    
        }

        const menuStyle = {"display":"flex", "alignItems":"center"};
        const fullMenuStyle = {"display":"flex", "alignItems":"center", "marginLeft": "-24px"};
        
        return (
            <div>
                <div className="mobile-menu"><img alt="" src="/img/menu-menu.svg" width="24" height="12" className="clickable" onClick={this.props.toggleMobileMenu}/></div>
                <div className={menuItemsClass}>
                    <div className="menu-items-top">
                        <div className="menu-menu"> 
                            <span className="full-menu">
                                <div style={this.props.full === true ? fullMenuStyle : menuStyle}>
                                    {this.props.full === true ? <div style={{"display":"flex", "alignItems":"center", "alignContent":"center"}}><img alt="" src="/img/cheveron-left.svg" width="24" height="18" className="clickable" onClick={this.props.toggleMenu}/></div> : <div></div>}
                                    <div><img alt="" src="/img/menu-menu.svg" width="24" height="12" className="clickable" onClick={this.props.toggleMenu}/></div>
                                </div>
                            </span>
                        </div>
                        <SelectOverlay 
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            valueChange={this.projectIndexChange} 
                            full={this.props.full} 
                            type="project" 
                            label="name" 
                            options={this.context.projects} 
                            index={Cache.getPreference(Cache.preference.projectIndex)}
                        />
                        <div>&nbsp;</div>
                        <hr className={this.props.full === true ? "menu-dividor" : "menu-dividor-min"}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuHeader full={this.props.full} label="Automation"/>
                        <MenuItem type="dashboard" iconnopad={true} full={this.props.full} active={this.props.active} selectedProject={selectedProject} trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="results" full={this.props.full} active={this.props.active} resultsView={this.props.resultsView} selectedProject={selectedProject}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="supplemental" full={this.props.full} active={this.props.active} supplementalView={this.props.supplementalView} selectedProject={selectedProject}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="diff" iconnopad={true} full={this.props.full} active={this.props.active} selectedProject={selectedProject}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="tasks" iconnopad={true} full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <hr className={this.props.full === true ? "menu-dividor" : "menu-dividor-min"}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuHeader full={this.props.full} label="Manual"/>
                        <MenuItem type="lists" iconnopad={true} full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="runs" full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <hr className={this.props.full === true ? "menu-dividor" : "menu-dividor-min"}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuHeader full={this.props.full} label="Release"/>
                        <MenuItem type="release-checklists" iconnopad={true} full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <hr className={this.props.full === true ? "menu-dividor" : "menu-dividor-min"}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuHeader full={this.props.full} label="Settings"/>
                        <MenuItem type="configuration" full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="account" full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <hr className={this.props.full === true ? "menu-dividor" : "menu-dividor-min"}/>
                        <MenuHeader full={this.props.full} label="Reference"/>
                        <MenuItem type="docs" full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="feedback" full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        <MenuItem type="notifications" full={this.props.full} active={this.props.active}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                    </div>
                    <div className="menu-items-bottom menu-items-bottom-mobile">
                    <hr className={this.props.full === true ? "menu-dividor" : "menu-dividor-min"}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        
                        {loginLink}
                        <MenuItem type="tesults" full={this.props.full} active={false}  trl={this.props.trl} trlOrg={this.props.trlOrg} trlProject={this.props.trlProject} trlTarget={this.props.trlTarget} trlRun={this.props.trlRun} trlCase={this.props.trlCase} trlView={this.props.trlView}/>
                        {/*{this.props.full === true ? <span className='font12 neutral4 ml-4'>1.73</span> : <span></span>}*/}
                    </div>
                </div>
            </div>
        );
    }
};

export default Menu;