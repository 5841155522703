/*global*/
import React, { Component } from 'react';
//import { Link, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
//import WholeTemplate from './WholeTemplate';
//import HeaderNav from './HeaderNav';
//import Footer from './Footer';
//import ResultsUpdate from './ResultsUpdate'
//import Pricing from './Pricing';
//import App from './App';
//import MessageOverlay from './MessageOverlay';
import Checkmark from './Checkmark'

class Design extends Component {
    constructor () {
        super();
        this.state = {collapsedSearch: true};
    }

    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Design</title>
                    <meta name="description" content="Tesults design previews UI before publication."/>
                </Helmet>
                <Checkmark/>
            </div>
        );
    }
}

export default Design;