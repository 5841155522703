/*global */
import React, { Component } from 'react';
import SiteFooter from './SiteFooter';

class EmptySide extends Component {
    render () {
        return (
            <div style={{"display":"flex", "flex-direction":"column"}}>
                <div className="empty-side-logo">
                </div>
                <div className="empty-side-menu">
                    <SiteFooter bgcolor="white" mobileMenu={true}/>
                </div>
            </div>
        );
    }
};

export default EmptySide;