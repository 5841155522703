/*global */
import React, { Component } from 'react';
import Constants from './Constants.js';
import ResetPasswordMain from './ResetPasswordMain'

class ResetPassword extends Component {
    render () {
        const rc = Constants.getUrlParameter("rc", this.props.location.search);
        return (
            <div style={{"display":"flex", "alignItems":"center", "justifyContent":"center"}}>
                <ResetPasswordMain rc={rc}/>
            </div>
        );
    }
};

export default ResetPassword;