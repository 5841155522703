/*global */
import React, { Component } from 'react';
import Confirmation from './Confirmation';
import ConfirmationButtons from './ConfirmationButtons';
import Request from './Request';

class MinUsers extends Component {
    constructor () {
        super();
        this.state = {
            confirmation: {success: undefined, failure: undefined},
            width: window.innerWidth, 
            height: window.innerHeight
        }
        this.resizeEvent = this.resizeEvent.bind(this);
        this.minUsersChange = this.minUsersChange.bind(this);
        this.saveMinUsers = this.saveMinUsers.bind(this);
        this.getMinUsers = this.getMinUsers.bind(this);
    }

    componentDidMount () {
        window.addEventListener('resize', this.resizeEvent);
    }
   
    componentWillUnmount() {
       window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
        this.setState({ width: window.innerWidth, height: window.innerHeight});
    }

    getMinUsers () {
        this.setState({loading: true});
        Request.get("/plan", {id: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to retrieve plan details, please contact support."}});
            } else {
                this.setState({plan: data.plan, loading: false, confirmation: {success: undefined, failure: undefined}});
            }
        });
    }

    minUsersChange (e) {
        this.props.minUsersChange(parseInt(e.target.value, 10));
    }

    saveMinUsers () {
        this.setState({submitting: true});
        Request.post("/minUsers", {id: this.props.project.id, num: this.props.minUsers}, (err, data) => {
            if (err) {
                this.setState({submitting: false, confirmation: {success: undefined, failure: "Failed to update"}});
            } else {
                this.setState({submitting: false, confirmation: {success: "Minimum users updated. You will be billed for this minimum or your total active users if that number is higher.", failure: undefined}});
           }
        });
    }

    render () {
        let minUsers = <div><input className="tr-input-side" id="minUsers" name="minUsers" type="number" min="1" max="100" value={this.props.minUsers} onChange={this.minUsersChange}/></div>
        if (this.props.phase > 0) {
            minUsers = <div className='mr-3'>{this.props.minUsers}</div>
        }
        let changeMinUsersForExistingPlan = 
            <div>
                <div className='mr-3'>Minimum users</div>
                <div><span className="neutral4">See Fair billing policy, most teams set to 1</span></div>
                <div className='flex-row pt-3' style={{"alignItems":"top"}}>
                    {minUsers}
                    <div>
                        {
                            this.props.phase > 0 ?
                            <div></div>
                            :
                            <ConfirmationButtons 
                                secondary={true}
                                confirm={this.saveMinUsers}
                                confirmLabel="Update"
                                confirmDisabled={this.state.submitting}
                            />
                        }
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        if (this.props.newPlan === true) {
            return (
                <div className='flex-row pt-3' style={{"alignItems":"center"}}>
                    <div className='mr-3'>Minimum users</div>
                    {minUsers}
                </div>
            );
        } else {
            return (
                <div>
                    {changeMinUsersForExistingPlan}
                </div>
            );
        }
    }
}

export default MinUsers;