/*global */
import React, { Component } from 'react';
//import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsTestCafe extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>TestCafe test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle TestCafe test reporting with Tesults."/>
        </Helmet>

        const introduction = <span></span>

        const installation = 
        <div>
            <p>Tesults provides a reporter for TestCafe to make integrating easy. This guide assumes you already have TestCafe setup.</p>
            <p>Within your TestCafe project, install using npm:</p>
            <pre className="docsCode">
                npm install testcafe-reporter-tesults
            </pre>
            <p><b>TestCafe 1.3.0 or higher is required</b>. Please ensure you are using a compatible version.</p>
        </div>

        const configuration = 
        <div>
            <p>Given a typical TestCafe test case like this:</p>
            <pre className="docsCode">
            fixture `Getting Started`// declare the fixture<br/>
            &nbsp;&nbsp;.page `https://devexpress.github.io/testcafe/example`;<br/><br/>

            test<br/>
            &nbsp;&nbsp;.meta({'{'} description: 'test description here', key1: 'value1', key2: 'value2' {'}'})<br/>
            &nbsp;&nbsp;('My first test', async t => {'{'}<br/>
            &nbsp;&nbsp;await t<br/>
            &nbsp;&nbsp;.typeText('#developer-name', 'John Smith')<br/>
            &nbsp;&nbsp;.takeScreenshot()<br/>
            &nbsp;&nbsp;.click('#submit-button')<br/><br/>

            &nbsp;&nbsp;// Use the assertion to check if the actual header text is equal to the expected one<br/>
            &nbsp;&nbsp;.expect(Selector('#article-header').innerText).eql('Thank you, John Smith!');<br/>
            {'}'});
            </pre>
            <p>The fixture, 'Getting Started' in this case, is considered the test suite for reporting for all test cases contained with in it. 'My first test' would be the test name.</p>
            <p>Providing a field called description in the meta data as above will have Tesults report this as the description and any other custom fields can be passed through as meta data too the same way as key1 and key2 above.</p>
            
            <pre className="docsArgsCode">tesults-target</pre>

            <p>Required to upload to Tesults, if this arg is not provided the tesults reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in Tesults.</p>
            <p><b>Inline method</b></p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-target=eyJ0eXAiOiJ...</pre>
            <p>In this case, the target token is supplied directly or inline in the command line args. This is the simplest approach.</p>
            <p><b>Key method</b></p>
            <pre className="docsCode pre-wrap">testcafe -r tesults -- tesults-target=target1 tesults-config=config.json</pre>
            <p>In this case, testcafe-reporter-tesults will automatically look up the value of the token based on the property provided from a configuration file.</p>
            <p>Here is the corresponding config.json file:</p>
            <pre className="docsCode pre-wrap">
            {'{'}<br/>
            &nbsp;&nbsp;"target1": "eyJ0eXAiOiJ...",<br/>
            &nbsp;&nbsp;"target2": "eyJ0eXAiOiK...",<br/>
            &nbsp;&nbsp;"target3": "eyJ0eXAiOiL...",<br/>
            &nbsp;&nbsp;"target4": "eyJ0eXAiOiM..."<br/>
            {'}'}
            </pre>
            <p>Something more descriptive about the targets is better:</p>
            <pre className="docsCode pre-wrap">
            {'{'}<br/>
            &nbsp;&nbsp;"chrome-qa-env": "eyJ0eXAiOiJ...",<br/>
            &nbsp;&nbsp;"chrome-staging-env": "eyJ0eXAiOiK...",<br/>
            &nbsp;&nbsp;"chrome-prod-env": "eyJ0eXAiOiL...",<br/>
            &nbsp;&nbsp;"firefox-qa-env": "eyJ0eXAiOiM...",<br/>
            &nbsp;&nbsp;"firefox-staging-env": "eyJ0eXAiOiN...",<br/>
            &nbsp;&nbsp;"firefox-prod-env": "eyJ0eXAiOiO...",<br/>
            &nbsp;&nbsp;"safari-qa-env": "eyJ0eXAiOiP...",<br/>
            &nbsp;&nbsp;"safari-staging-env": "eyJ0eXAiOiQ...",<br/>
            &nbsp;&nbsp;"safari-prod-env": "eyJ0eXAiOiR..."<br/>
            {'}'}
            </pre>
            <p>Subsequently, a real command to run all tests in the tests dir on the chrome browser in the qa env might look like this:</p>
            <pre className="docsCode pre-wrap">testcafe chrome tests -r tesults -- tesults-target=chrome-qa-env tesults-config=config.json</pre>
            

            <pre className="docsArgsCode">tesults-config</pre>
            <p>Provide the path, including the file name to a .json file. All of the args below can be provided in a config file instead of the testcafe command.</p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-config=/path/config.json</pre>
            <p>An example of a config file:</p>
            <pre className="docsCode pre-wrap">
            {'{'}<br/>
            &nbsp;&nbsp;"chrome-web-qa": "eyJ0eXAiOiJ...",<br/>
            &nbsp;&nbsp;"chrome-web-staging": "eyJ0eXAiOiK...",<br/>
            &nbsp;&nbsp;"chrome-web-prod": "eyJ0eXAiOiL...",<br/>

            &nbsp;&nbsp;"tesults-files": "/paths/files",<br/>
            &nbsp;&nbsp;"tesults-build-name": "1.0.0",<br/>
            &nbsp;&nbsp;"tesults-build-desc": "Build description",<br/>
            &nbsp;&nbsp;"tesults-build-result": "pass"<br/>
            {'}'}
            </pre>
            <Box 
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={
                    <div>
                        <p>At this point reporting will push results to Tesults when you run your testcafe test command if the tesults reporter is passed through to TestCafe the same way any other reporter is passed through (with the -r option) along with your tesults target token:</p>
                        <pre className="docsCode">
                            testcafe -r tesults -- tesults-target=token
                        </pre>
                        <p>The token should be replaced with the token for your project target. You can regenerate a token at any time from the config menu. </p>
                    </div>
                }
            />
            <Box 
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Using the programming interface for TestCafe?</h4>}
                content={
                    <div>
                        <p>If you use the programming interface for TestCafe rather than the command line interface, pass in Tesults arguments using process.argv:</p>
                        <pre className="docsCode pre-wrap">
                        const createTestCafe = require('testcafe');<br/>
                        let runner           = null;<br/><br/>

                        <b>process.argv.push('tesults-target=token');</b><br/>
                        <b>process.argv.push('tesults-build-name=1.0.0')</b><br/>
                        <b>process.argv.push('tesults-build-result=pass')</b><br/><br/>

                        createTestCafe('localhost', 1337, 1338)<br/>
                        &nbsp;&nbsp;.then(testcafe => {'{'}<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;runner = testcafe.createRunner();<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;runner<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.src('tests/myFixture.js')<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.browsers([remoteConnection, 'chrome'])<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.reporter('json')<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.run()<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.then(failedCount => {'{'}<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/* ... */<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'})<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.catch(error => {'{'}<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/* ... */<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'});<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;return testcafe.createBrowserConnection();<br/>
                        &nbsp;&nbsp;{'}'})<br/>
                        &nbsp;&nbsp;.then(remoteConnection => {'{'}<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;/* ... */<br/>
                        &nbsp;&nbsp;{'}'});<br/>
                        </pre>
                    </div>
                }
            />
        </div>

        const enhancedReporting = 
        <div>
            <p>Use the Tesults reporter to report additional properties for each test case. Enhanced reporting functions are supported in testcafe-report-tesults <b>version 1.2.0 and higher</b>. To begin doing this, require the reporter in your test file:</p>
            <pre className='docsCode'>
                const tesults = require('testcafe-reporter-tesults')();<br/>
                // Note that the function execution () above is required.
            </pre>
            <p>Report custom fields, test steps and attach files to test cases using the Tesult reporter functions: description, custom, step, and file. <b>Call reporter functions from within your tests (test block)</b>. The first parameter is always the test controller to provide the reporter with context about the test:</p>
            <h4>description</h4>
            <p>Add a description of the test case for reporting purposes. We recommend using the meta description for this but you can also use the Tesults reporter as demonstrated here.</p>
            <pre className='docsCode'>
            const tesults = require('testcafe-reporter-tesults')();<br/><br/>
            fixture `Getting Started`// declare the fixture<br/>
            &nbsp;&nbsp;.page `https://devexpress.github.io/testcafe/example`;<br/><br/>
            test<br/>
            &nbsp;&nbsp;.meta({'{'} <code className="accentc6">description</code>: 'Recommended way to set description', key1: 'value1', key2: 'value2' {'}'})<br/>
            &nbsp;&nbsp;('My first test', async t => {'{'}<br/>
            &nbsp;&nbsp;tesults.<code className="accentc6">description</code>(t, "An alternative way to set description");<br/>
            &nbsp;&nbsp;await t<br/>
            &nbsp;&nbsp;.typeText('#developer-name', 'John Smith')<br/>
            &nbsp;&nbsp;.takeScreenshot()<br/>
            &nbsp;&nbsp;.click('#submit-button')<br/><br/>
            &nbsp;&nbsp;// Use the assertion to check if the actual header text is equal to the expected one<br/>
            &nbsp;&nbsp;.expect(Selector('#article-header').innerText).eql('Thank you, John Smith!');<br/>
            {'}'});
            </pre>
            <h4>custom</h4>
            <p>Add a custom field and value to the test case for reporting purposes. These fields and values can be set using meta data just as with the description. Alternatively use the Tesults reporter as demonstrated here.</p>
            <pre className='docsCode'>
            const tesults = require('testcafe-reporter-tesults')();<br/><br/>
            fixture `Getting Started`// declare the fixture<br/>
            &nbsp;&nbsp;.page `https://devexpress.github.io/testcafe/example`;<br/><br/>
            test<br/>
            &nbsp;&nbsp;.meta({'{'} description: 'Description', <code className="accentc6">custom_field_1: 'custom_value_1'</code>{'}'})<br/>
            &nbsp;&nbsp;('My first test', async t => {'{'}<br/>
            &nbsp;&nbsp;tesults.<code className="accentc6">custom</code>(t, "Another custom field", "Custom value");<br/>
            &nbsp;&nbsp;await t<br/>
            &nbsp;&nbsp;.typeText('#developer-name', 'John Smith')<br/>
            &nbsp;&nbsp;.takeScreenshot()<br/>
            &nbsp;&nbsp;.click('#submit-button')<br/><br/>
            &nbsp;&nbsp;// Use the assertion to check if the actual header text is equal to the expected one<br/>
            &nbsp;&nbsp;.expect(Selector('#article-header').innerText).eql('Thank you, John Smith!');<br/>
            {'}'});
            </pre>
            <h4>file</h4>
            <p>Associate a file to the test case in order to upload it for reporting.</p>
            <pre className='docsCode'>
            const tesults = require('testcafe-reporter-tesults')();<br/><br/>
            fixture `Getting Started`// declare the fixture<br/>
            &nbsp;&nbsp;.page `https://devexpress.github.io/testcafe/example`;<br/><br/>
            test<br/>
            &nbsp;&nbsp;.meta({'{'} description: 'Description', <code className="accentc6">custom_field_1: 'custom_value_1'</code>{'}'})<br/>
            &nbsp;&nbsp;('My first test', async t => {'{'}<br/>
            &nbsp;&nbsp;tesults.<code className="accentc6">file</code>(t, "/full/path/to/file/log.txt");<br/>
            &nbsp;&nbsp;await t<br/>
            &nbsp;&nbsp;.typeText('#developer-name', 'John Smith')<br/>
            &nbsp;&nbsp;.takeScreenshot()<br/>
            &nbsp;&nbsp;.click('#submit-button')<br/><br/>
            &nbsp;&nbsp;// Use the assertion to check if the actual header text is equal to the expected one<br/>
            &nbsp;&nbsp;.expect(Selector('#article-header').innerText).eql('Thank you, John Smith!');<br/>
            {'}'});
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h4>step</h4>
            <p>Add test steps to the test case for reporting. Each step is an object with a name and result (one of [pass, fail, unknown]). You can also add the optional fields description and reason (failure reason in case of step fail).</p>
            <pre className='docsCode'>
            const tesults = require('testcafe-reporter-tesults')();<br/><br/>
            fixture `Getting Started`// declare the fixture<br/>
            &nbsp;&nbsp;.page `https://devexpress.github.io/testcafe/example`;<br/><br/>
            test<br/>
            &nbsp;&nbsp;.meta({'{'} description: 'Description', <code className="accentc6">custom_field_1: 'custom_value_1'</code>{'}'})<br/>
            &nbsp;&nbsp;('My first test', async t => {'{'}<br/>
            tesults.<code className="accentc6">step</code>(t, {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "First step",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "pass"<br/>
            {'}'})<br/>
            tesults.<code className="accentc6">step</code>(t, {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "Second step",<br/>
            &nbsp;&nbsp;<code className="accentb4">desc</code>: "Second step description",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "fail"<br/>
            &nbsp;&nbsp;<code className="accentb4">reason</code>: "Error line 203 of test.js"<br/>
            {'}'})<br/>
            &nbsp;&nbsp;await t<br/>
            &nbsp;&nbsp;.typeText('#developer-name', 'John Smith')<br/>
            &nbsp;&nbsp;.takeScreenshot()<br/>
            &nbsp;&nbsp;.click('#submit-button')<br/><br/>
            &nbsp;&nbsp;// Use the assertion to check if the actual header text is equal to the expected one<br/>
            &nbsp;&nbsp;.expect(Selector('#article-header').innerText).eql('Thank you, John Smith!');<br/>
            {'}'});
            </pre>
        </div>

        const files = 
        <div>
            <pre className="docsArgsCode">tesults-files=path</pre>
            <p>If you save logs or other files during your test run include this option to have files upload take place. If you use the TestCafe takeScreenshot() function to take screen shots, these files will automatically be uploaded and there is no need to include this option but for all other files this option should be included.</p>
            <p>Replace path with the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-files=/path/files</pre>
            <p>This is one area where the tesults reporter is opinionated and requires that files generated during a test run be saved locally temporarily during a run within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Within this temporary directory create subdirectories matching the test suite (fixture in TestCafe) and case name</p>
            <p>Also be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="docsArgsCode">tesults-build-name=buildname</pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-build-name=1.0.0</pre>
            <hr/>
            <pre className="docsArgsCode">tesults-build-result=result</pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-build-result=pass</pre>
            <hr/>
            <pre className="docsArgsCode">tesults-build-desc=description</pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-build-desc='added new feature'</pre>
            <hr/>
            <pre className="docsArgsCode">tesults-build-reason=reason</pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">testcafe -r tesults -- tesults-build-reason='build error line 201 somefile.py'</pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                enhancedReporting={enhancedReporting}
                build={build}
                integration="testcafe"
            />
        );
    }
}

export default DocsTestCafe;