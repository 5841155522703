/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';
import Cache from './Cache'
import Context from './Context'
import Constants from './Constants'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request';

class ConfigTargetName extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {edit: false, name: "", confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.edit = this.edit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.cancel = this.cancel.bind(this)
        this.save = this.save.bind(this)
        
    }

    componentDidMount () {
        if (this.state.name === "") {
            this.init()
        }
    }

    componentDidUpdate () {
        if (this.state.name === "") {
            this.init()
        }
    }


    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.target !== undefined && prevProps.target !== undefined) {
            if (this.props.target.created !== prevProps.target.created) {
                this.init()
            }
        } else {
            if (this.state.name === "") {
                this.init()
            }   
        }
    }

    init () {
        if (this.props.target !== undefined) {
            this.setState({name: this.props.target.name})
        }
    }

    edit () {
        this.setState({edit: true})
    }

    onChange (e) {
        this.setState({name: e.target.value})
    }

    cancel () {
        let name = ""
        try {
            name = this.props.target.name
        } catch (err) {
            name = ""
        }
        this.setState({edit: false, name: name, confirmation: {success: undefined, failure: undefined}})
    }

    save () {
        this.setState({confirmation: {success: undefined, failure: undefined}});
        let data = {
            id: this.props.target.id,
            created: this.props.target.created,
            name: this.state.name
        };
        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to edit target"}});
            } else {
                Analytics.event("EditedTargetName");
                this.setState({confirmation: {success: "Target name updated", failure: undefined}}, this.props.targetUpdated(data.target));
            }
        });
    }
    
    render () {
        if (this.props.target === undefined) {
            return <div></div>
        }
        return (
            <div>
                <div className="mb-1">
                    <label className="neutral4 font14">Target name</label>
                </div>
                <div className='inputButtons'>
                    <div className="mb-4 flex-1">
                        {
                            this.state.edit ?
                            <input type="text" className="tr-input" value={this.state.name} onChange={this.onChange}/>
                            :
                            <input type="text" className="tr-input" value={this.state.name} onChange={this.onChange} disabled/>
                        }
                        
                    </div>
                    <div className="mb-4 flex-1">
                        {
                            this.state.edit ?
                            <ConfirmationButtons 
                                confirm={this.save}
                                cancel={this.cancel}
                                confirmLabel="Update"
                                cancelLabel="Cancel"
                            />
                            :
                            <button className='btn-cancel' onClick={this.edit}>Edit</button>
                        }
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigTargetName