/*global */
import React from 'react';
import { useParams } from "react-router-dom";
import App from './App';

function CRLHandler (props) {
    let { area, option } = useParams();
    return (
        <App area={area} option={option} match={props.match} history={props.history} location={props.location}/>
    );
};

export default CRLHandler;