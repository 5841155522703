/*global*/
import React, { Component } from 'react';

class ResultsHeader13 extends Component {
    render () {
        window.scrollTo(0, 0);
        let title = "Release checklists"
        let group = ""
        if (this.props.state === "checklist") {
            if (this.props.checklist !== undefined) {
                // Set title
                title = this.props.checklist.name

                // Set group
                if (this.props.checklist.group !== undefined) {
                    group = this.props.checklist.group
                }
            }
        }
        return (
            <div className="results-header13 whitebg mb-4">
                <div className="results-header13-title">
                    {
                        this.props.state === "checklist" ?
                        <div style={{"display":"flex", "flexDirection":"column"}}>
                            <div className="font12 neutral4 overflow-ellipsis-no-wrap">{group}</div>
                            <div className="header-title overflow-ellipsis-no-wrap">{title}</div>
                        </div>
                        :
                        <div className="header-title pt-4 pb-4">Release Checklists</div>
                    }
                </div>
                <div className="results-header13-gap">
                    
                </div>
                <div className="results-header13-actions">
                    <div style={{"display": "flex"}}>
                        <div style={{"flex": "1"}}>
                            <button className="select" onClick={() => window.location.reload()}>
                                <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader13;