/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';

class DocsGo extends Component {
    render() {
        let title = "Go test reporting with Tesults"
        let metadescription =  "Learn how to handle Go test reporting with Tesults"
        const helmet =
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={metadescription}/>
        </Helmet>

        const installation =
        <div>
            <pre className="docsCode">
                <code className="">go get github.com/tesults/go</code>
            </pre>
        </div>

        const configuration =
        <div>
            <p>In the file you want to use Tesults add this import:</p>
            <pre className="docsCode">
                import {'('} <br/>
                &nbsp;&nbsp;...<br/>
                &nbsp;&nbsp;<code className="accentb4">"github.com/tesults/go/tesults"</code><br/>
                &nbsp;&nbsp;...<br/>
                {')'}
            </pre>
        </div>

        const usage = 
        <div>
            <p>Upload your test results using the Results method.</p>
            <pre className="docsCode">
                <code className="accentb4">res := tesults.Results(data)</code><br/>
                {'//'} res is a map[string]interface{'{'}{'}'} type.<br/>
                {'//'} res["success"] is a bool, true if results successfully uploaded, false otherwise.<br/>
                {'//'} res["message"] is a string, if success is false, check message to see why upload failed.<br/>
                {'//'} res["warnings"] is a []string, if non empty there may be issues with file uploads.<br/>
                {'//'} res["errors"] is a []string, if success is true then this is empty.<br/>
            </pre>
        </div>
        
        const example =
        <div>
            <p className="mt-3">The data param for Results is a map[string]interface{} containing your build and test results in the form:</p>
            <pre className="docsCode">
                {'//'} Required:<br/>
                {'//'} import "./tesults"<br/>
                {'//'} import "fmt"<br/><br/>
                
                data := map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;"target": "token",<br/>
                &nbsp;&nbsp;"results": map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;"cases": []interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Test 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Suite A",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Test 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Suite B",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "fail",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"reason": "Assert fail in line 203 of example.go",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"params": map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"param1": "value1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"param2": "value2"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"_CustomField": "Custom field value",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"steps": []interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Step 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Step 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Step 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Step 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "fail",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"reason": "Assert fail in line 203 of example.go",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;map[string]interface{'{'}{'}'}{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test 3",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Test 3 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Suite A",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"start": (time.Now().UnixNano() / int64(time.Millisecond)) - 60000,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"end": time.Now().UnixNano() / int64(time.Millisecond),<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"files": []string{'{'}"full/path/to/file/log.txt", "full/path/to/file/screencapture.png"{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                &nbsp;&nbsp;{'}'},<br/>
                {'}'}<br/><br/>

                res := tesults.Results(data)<br/><br/>

                fmt.Println("Success: ", res["success"])<br/>
                fmt.Println("Message: ", res["message"])<br/>
                fmt.Println("Warnings: ", strconv.FormatInt(int64(len(res["warnings"].([]string))), 10))<br/>
                fmt.Println("Errors: ", strconv.FormatInt(int64(len(res["errors"].([]string))), 10))
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu. The cases []interface should contain your test cases. You would usually add these by looping through the test case objects you currently have in your build and test scripts.</p>
            <p>Each test case added to cases must have a name and a result. The result value must be one of 'pass', 'fail' or 'unknown'. All other fields are optional: desc, suite, files, params, steps, reason and custom fields (prefixed with underscore).</p>
            <p>The 'params' value is for use by parameterized test cases and is a map[string]interface{} containing the parameter names and values.</p>
        </div>

        return (
            <DocsLanguage
                language="go"
                helmet={helmet}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsGo;