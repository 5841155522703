/*global*/
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SideItem extends Component {
    constructor () {
        super();
        this.state = {
            active: undefined, 
            icon: {}, 
            label: undefined, 
            action: undefined
        };
        this.setup = this.setup.bind(this);
    }

    componentDidMount () {
        this.setup();
    }

    setup () {
        this.setState(
            {
                active: this.props.active === true ? true : false, 
                icon: {
                    image: this.props.image,
                    width: "16",
                    height: "16"
                },
                label: this.props.label,
                action: this.props.action,
                onClick: this.props.onClick
            }
        );
    }

    render () {
        if (this.state.action !== undefined) {
            return (
                <Link to={this.state.action} className="menu-item-link">
                <div className="side-item">
                    <div className="side-item-circle">
                        <div className={this.props.iconnopad === true ? "menu-item-icon-work-around" : "menu-item-icon"}>
                            <img alt="" src={this.state.icon.image} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
                        </div>
                    </div>
                    <div className="side-item-label neutral2 font14 wrapbtn">
                        {this.state.label}
                    </div>
                </div>
                </Link>
            );
        } else if (this.state.onClick !== undefined) {
            return (
                <button onClick={this.state.onClick} className="side-item-button">
                <div className="side-item">
                    <div className="side-item-circle">
                        <div className={this.props.iconnopad === true ? "menu-item-icon-work-around" : "menu-item-icon"}>
                            <img alt="" src={this.state.icon.image} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
                        </div>
                    </div>
                    <div className="side-item-label neutral2 font14 wrapbtn">
                        {this.state.label}
                    </div>
                </div>
                </button>
            );
        } else {
            return <span></span>
        }
    }
};

export default SideItem;