/*global */
import React, { Component } from 'react';

class Project extends Component {
    constructor () {
        super();
        this.state = 
        {
            icon: {
                image: "/img/menu-project-default.svg",
                darkImage: "/img/menu-project-default-dark.svg",
                width: "12",
                height: "21"
            }
        };
    }

    render () {
        let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
        if (this.props.projects !== undefined) {
            let projectIndex = this.props.projectIndex;
            if (projectIndex === undefined) {
                projectIndex = 0;
            }
            if (projectIndex < this.props.projects.length) {
                const project = this.props.projects[projectIndex];
                if (project.aext !== undefined) {
                    let imageSrc = '/project/avatar/pdla/' + project.id + "/" + project.aext;
                    image = <img alt="" src={imageSrc} width="25" height="25" className="circle25 img25 noborder"/>
                }
                return <span><div className="test-case-detail-circle">{image}</div>&nbsp;&nbsp;{project.name}</span>
            } else {
                return <span></span>
            }
        } else {
            return <span></span>
        }
    }
}

export default Project;