/*global*/
import React, { Component } from 'react';

class ResultsHeader7 extends Component {
    render () {
        window.scrollTo(0, 0);
        return (
            <div className="results-header7 whitebg">
                <div className="results-header3-title ">
                    <div className="pt-4 pb-4">{this.props.title === undefined ? "Configuration" : this.props.title}</div>
                </div>
                <div className="results-header3-targets">
                    <div className="target-pass-rate">
                        <div className="target-pass-rate-left">
                            
                        </div>
                        <div className="target-pass-rate-right">
                            
                        </div>
                    </div>
                </div>
                <div className="results-header3-gap">
                    
                </div>
                <div className="results-header3-actions">
                    <div style={{"display": "flex"}}>
                        <div style={{"flex": "1"}}>
                            
                        </div>
                        <div style={{"flex": "1"}}>
                            
                        </div>
                        <div style={{"flex": "1"}}>
                            <button className="select" onClick={() => window.location.reload()}>
                                <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader7;