/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsPreferences extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Preferences Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults targets."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Preferences.</p>
                <h2>Test Case Display Attributes</h2>
                <p>Customize the test case properties displayed for test cases in the results and supplemental views by creating a display attribute mapping.</p>
                <p>Click the 'Create an attribute map' button. For each field that can be modified alternative display attributes can be selected.</p>
                <p>If you select the custom field you will must specify the custom property name.</p>
                <p>Click the 'Save' button to saving the map. To apply the map, select Targets from within the Configuration menu, ensure the target you want to edit is selected and then apply the case display attribute map to the target. From now on test cases in the results and supplemental views will display the attributes specified in the map.</p>
                <p>You can choose to apply a map to an entire project instead of specific targets by clicking the 'Edit' button in the section titled 'Project test case display attribute map'.</p>
                <p>Display attribute maps that have already been created can be edited and deleted from within the same configuration screen used to create maps.</p>
            </div>
        );
    }
}

export default DocsPreferences;