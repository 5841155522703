/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsTargetOrder extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Target Order Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults target order."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Target Order</p>
                <p>All targets that have been created for the project are listed. Use the up and down arrows beside each target to change the order of the targets.</p>
                <p>Once targets are ordered as desired, click the save button at the bottom, or press cancel to cancel changes.</p>
                <p>The order of the targets set here impacts the order in which targets are listed in the dropdown lists in the Results and Supplemental views and the order in which targets appear in the Dashboard view.</p>
            </div>
        );
    }
}

export default DocsTargetOrder;