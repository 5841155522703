/*global Cookies*/
import React, { Component } from 'react';
import Request from './Request';

class TaskAssign extends Component {
    constructor () {
        super();
        this.state = {select: false, submitting: false, members: undefined, memberIndex: 0};
        this.select = this.select.bind(this);
        this.assign = this.assign.bind(this);
        this.memberSelectChange = this.memberSelectChange.bind(this);
    }

    select() {
        Request.get("/members", {id: this.props.project.id}, (err, data) => {
            if (err) {
                this.setState({members: [], select: true});
            } else {
                this.setState({members: data.members, select: true});
            }
        });
    }

    memberSelectChange (e) {
        this.setState({memberIndex: e.target.value});
    }

    assign() {
        this.setState({submitting: true});
        let assignee = Cookies.getJSON("truser"); 
        if (this.state.members === undefined) {
            return;
        }
        if (this.state.memberIndex < this.state.members.length) {
            assignee = this.state.members[this.state.memberIndex];
        }

        Request.post("/taskassign", {project: this.props.project, target: this.props.target, hash: this.props.case.hash, suite: this.props.case.suite, name: this.props.case.name, assignee: assignee.id}, (err, data) => {
            if (err) {
                // error
            } else {
                let task = data.task;
                task.assignee = {id: data.task.assignee, email: assignee.email, firstName: assignee.firstName, lastName: assignee.lastName};

                const user = Cookies.getJSON("truser");
                task.assigner = {id: data.task.assigner, email: user.email, firstName: user.firstName, lastName: user.lastName};
                this.props.taskChange(data.task);
                this.setState({select: false, submitting: false});
            }
        });
    }

    render() {
        if (this.state.select === false) {
            if (this.props.task === undefined) {
                return (
                    <button type="button" className="btn btn-select" onClick={this.select}>Assign</button>
                );
            } else {
                return (
                    <button type="button" className="btn btn-select" onClick={this.select}>Reassign</button>
                );
            }
        } else {
            // select
            let options = [];
            let index = 0;
            this.state.members.forEach(function (member) {
                options.push(<option key={index} value={index}>{member.firstName + " " + member.lastName + " (" + member.email + ")"}</option>);
                index += 1;
            });
            return (
                <div>
                <select className="custom-select mt-3 mb-3 width100" onChange={this.memberSelectChange} value={this.state.memberIndex}>
                    {options}
                </select>
                <button type="button" className="btn btn-select" onClick={this.assign} disabled={this.state.submitting}>Confirm</button>
                </div>
            );
        }
    }
}

export default TaskAssign;