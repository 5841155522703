/*global Cookies*/
import React, { Component } from 'react';

class AffiliateMenu extends Component {
    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let loggedIn = true;
        let view = "dashboard";
        if (user === undefined || user == null) {
            loggedIn = false;
            view = "about";
        }
        // views: about, config, report, terms
        this.state = {displayTerms: false, loggedIn: loggedIn, view: view};
    }

    componentDidMount () {
        let view = this.props.match.params.view;
        if (view !== undefined) {
            this.setState({view: view});
        }
    }

    render () {
        return (
            <div style={{"display":"flex", "flex-direction":"column"}}>
                <a href="/" className="tr-link-neutral1 mobile-hide">
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div style={{"marginTop":"4px"}}>
                            <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                        </div>
                        <div className="neutral2">
                            &nbsp;<span className="bolder font16 primary4">Tesults Affiliate</span>
                        </div>
                    </div>
                </a>
                <div className="mb-4 docsTopPadding">
                    <h1>&nbsp;</h1>
                    <h1>&nbsp;</h1>
                    <div style={{"display":"flex", "flexDirection":"column"}}>
                        <div className="mb-5"><a className={this.state.view === "dashboard" ? "tr-link-primary4": "tr-link-neutral6"} href="/affiliate/dashboard">View affiliate reports</a></div>
                        <div className="mb-5"><a className={this.state.view === "config" ? "tr-link-primary4": "tr-link-neutral6"} href="/affiliate/config">Configure affiliate links</a></div>
                        <div className="mb-5"><a className={this.state.view === "about" ? "tr-link-primary4": "tr-link-neutral6"} href="/affiliate/about">About the program</a></div>
                        <div className="mb-5"><a className={this.state.view === "terms" ? "tr-link-primary4": "tr-link-neutral6"} href="/affiliate/terms">Terms of service</a></div>
                    </div>
                    {/*(<div className="mt-5">
                        <img className="width100" src="/img/Illustrations_Tesults_001Artboard-1.png" alt=""/>
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default AffiliateMenu;