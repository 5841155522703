/*global Cookies*/
import React, { Component } from 'react';

class MenuHeader extends Component {
    render () {
        if (this.props.full === true) {
            return (
                <div className='font12 neutral7 ml-3'>{this.props.label}</div>
            )
        } else {
            return (
                <div className='font12 neutral7'></div>
            )
        }
    }
}

export default MenuHeader