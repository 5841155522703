/*global */
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Status from './Status';
import Loading from './Loading';
import SelectOverlay from './SelectOverlay';
import Cache from './Cache';
import Analytics from './Analytics';
import RSPHandler from './RSPHandler';
import {utilsTotals, utilsRawResultMap} from './Utils'

class StatusView extends Component {
    constructor () {
        super();
        this.state = {
            groupEdit: false, 
            group: 'All',
            groupSuggestions: [],
            aggregateData: {}
        };
        this.groupEditToggle = this.groupEditToggle.bind(this);
        this.groupSelect = this.groupSelect.bind(this);
        this.setGroupSuggestions = this.setGroupSuggestions.bind(this)
        this.aggregate = this.aggregate.bind(this)
        this.renderAggregateData = this.renderAggregateData.bind(this)
    }

    componentDidMount () {
        if (this.props.trl) {
            // public
            
        } else {
            let rsp = RSPHandler.rspData();
            if(rsp.projectId !== undefined && rsp.group !== undefined) {
                this.setState({group: decodeURI(rsp.group)});
            } else {
                if (rsp.projectId === undefined) {
                    if (this.props.projectIndex !== undefined && this.props.projects !== undefined) {
                        if (this.props.projectIndex < this.props.projects.length) {
                            this.props.history.push("/results/rsp/view/status/project/" + this.props.projects[this.props.projectIndex].id);
                        }
                    }
                }
                let group = Cache.getPreference(Cache.preference.targetGroup);
                if (group !== undefined) {
                    if (group !== this.state.group) {
                        this.setState({group: group});
                    }
                }
            }
            this.setGroupSuggestions()
        }
    }

    aggregate (target) {
        if (target === undefined) { return }
        let aggregateData = this.state.aggregateData
        aggregateData[target.created] = target
        this.setState({aggregateData: aggregateData})
    }

    groupEditToggle () {
        Analytics.event("TargetGroupEditToggle");
        this.setState({groupEdit: (this.state.groupEdit !== true ? true : false)});
    }

    groupSelect (group) {
        Cache.setPreference(Cache.preference.targetGroup, group);
        this.setState({group: group}, () => {
            if (this.props.projectIndex !== undefined && this.props.projects !== undefined) {
                if (this.props.projectIndex < this.props.projects.length) {
                    if (this.props.trl !== true) {
                        this.props.history.push("/results/rsp/view/status/project/" + this.props.projects[this.props.projectIndex].id + "/group/" + group);
                    }
                    this.setState({aggregateData: {}}, this.props.groupChange(group))
                }
            }
        });
    }

    setGroupSuggestions () {
        let groupSuggestions = {}
        if (this.props.status !== undefined) {
            if (Array.isArray(this.props.status)) {
                for (let i = 0; i < this.props.status.length; i++) {
                    let target = this.props.status[i];
                    let targetGroup = target.group
                    if (targetGroup !== undefined) {
                        if (Array.isArray(targetGroup)) {
                            targetGroup.forEach((group) => {
                                groupSuggestions[group] = group
                            })
                        }
                    }
                }
            }
        }
        let groupSuggestionsArray = []
        Object.keys(groupSuggestions).forEach((key) => {
            groupSuggestionsArray.push(<option key={key} value={key}/>)
        })
        this.setState({groupSuggestions: groupSuggestionsArray})
    }

    renderAggregateData () {
        let pass = 0
        let total = 0
        Object.keys(this.state.aggregateData).forEach((key) => {
            let target = this.state.aggregateData[key]
            if (target.cases !== undefined) {
                let runMetadata = undefined;
                if (target.run) {
                    runMetadata = {
                        integrationName: target.run.integrationName,
                        integrationVersion: target.run.integrationVersion, 
                        testFramework: target.run.testFramework
                    };
                }
                let rawResultMap = utilsRawResultMap(target.rawResultMap, this.props.rawResultMaps)
                let totals = utilsTotals(target.cases, rawResultMap, runMetadata)
                pass += totals.pass
                total += totals.total
                // console.log(target.cases)
                // console.log(target.name + ": " + totals.pass + ":" + totals.total)
            }
        })
        if (total === 0) {
            return <div></div>
        } else {
            return (
                    <div className='neutral4 font14 mr-3' style={{"minWidth":"400px"}}>Aggregate pass rate:<br/>{pass} / {total}</div>
            )
        }
    }

    render() {
        if (this.props.state === "results") {
            if (this.props.loading === true) {
                return (
                    <div className="row text-center">
                        <div className="col-12">
                            <Loading/>
                        </div>
                    </div>
                );
            } else if (this.props.status === undefined) {
                return (
                    <div>
                        <h5 className="primary1 mb-3 mt-0">You are not currently added to any projects</h5>
                        <p>Create a project by clicking <Link to="/config">config</Link>.</p>
                        <p>If you will only be viewing test results, you do not have to do anything. Wait until another member of your team has invited you to a project.</p>
                    </div>
                );
            } else if (this.props.status.length === 0) {
                return (
                    <div>
                        <h5 className="primary1 mb-3 mt-0">You are not currently added to any projects</h5>
                        <p>Create a project by clicking <Link to="/config">config</Link>.</p>
                        <p>If you will only be viewing test results, you do not have to do anything. Wait until another member of your team has invited you to a project.</p>
                    </div>
                );
            } else if (this.props.rspNotFound !== undefined) {
                return (
                    <div>
                        <h4 className="mb-3 mt-3">Check results url is correct</h4>
                        <p>{this.props.rspNotFound}</p>
                    </div>
                );
            } else {
                let status = []
                let targetIndexObj = {};
                
                for (let i = 0; i < this.props.status.length; i += 1) {
                    let target = this.props.status[i];
                    targetIndexObj[target.id + "-" + target.created] = i;
                }

                let statusTargetSelect = this.props.statusTargetSelect;
                let role = this.props.role;
                let groups = [];
                for (let i = 0; i < this.props.status.length; i++) {
                    let target = this.props.status[i];
                    if (target.group !== undefined) {
                        for (let i = 0; i < target.group.length; i++) {
                            groups.push(target.group[i]);
                        }
                    }
                }

                let selectedGroup = this.state.group;
                if (groups.includes(selectedGroup) !== true) {
                    selectedGroup = "All"; // Resets to All if the cached selected group does not exist for this project
                }

                for (let i = 0; i < this.props.status.length; i++) {
                    let target = this.props.status[i];
                    let targetGroup = target.group;
                    if (targetGroup === undefined || targetGroup === null) {
                        targetGroup = [];
                    }
                    if (targetGroup.includes(selectedGroup) || selectedGroup === "All") {
                        if (!this.state.groupEdit && target.hideInDashboard) {
                            continue;
                        }
                        status.push(
                            <Status key={i} selectedGroup={selectedGroup} target={target} aggregate={this.aggregate} statusTargetSelect={statusTargetSelect} targetIndexObj={targetIndexObj} role={role} targets={this.props.targets} loadUpdatedResults={this.props.loadUpdatedResults} groupEdit={this.state.groupEdit} groupSuggestions={this.state.groupSuggestions} targetIndex={i} rawResultMaps={this.props.rawResultMaps} trl={this.props.trl}/>
                        )
                    }
                }

                groups = [...new Set(groups)] // removes duplicates
                groups.sort();
                groups.unshift('All');

                let groupOptions = [];
                let index = 0;
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === this.state.group) {
                        index = i;
                    }
                    groupOptions.push({group: groups[i], index: i});
                }

                return (
                    <div>
                        <div className="status-edit">
                            <div className='dashboard-header' style={{"flex":"1", "alignItems":"baseline"}}>
                                <div style={{"width":"140px"}} className='neutral4 neutral8bg rounderborder pl-3 font14'>Display<br/>Group:</div>
                                <SelectOverlay 
                                    overlay={this.props.overlay} 
                                    messageOverlay={this.props.messageOverlay}
                                    valueChange={this.groupSelect} 
                                    type="target-group" 
                                    label="group"
                                    role={this.props.role}
                                    options={groupOptions}
                                    index={index}
                                />
                                {this.renderAggregateData()}
                            </div>
                            <div style={{"marginLeft":"auto"}}>
                                {this.props.role < 3 ?
                                    <div></div>
                                :
                                    <div>
                                        <button onClick={this.groupEditToggle} className="btn btn-cancel">{this.state.groupEdit !== true ? <span>Edit groups</span> : <span>Exit group editing</span>}</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <hr className='dividor-light'/>
                        <div className="status-grid">
                            {status}
                        </div>
                        <br/>
                        <br/>
                        {this.props.onboarding}
                    </div>
                );
            }
        } else {
            return (
                <div>
                    <p><NavLink to="/login" className="nounderline neutral4">Login</NavLink> to view results.</p>
                    <p><NavLink to="/" className="nounderline neutral4">Return to Tesults.com</NavLink></p>
                </div>    
            );
        }
    }
}

export default StatusView;