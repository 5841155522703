/*global*/
import React, { Component } from 'react'
import TargetDetailField from './TargetDetailField'

class CasePreview extends Component {
    constructor() {
        super()        
    }

    render() {
        return (
            <div>
                <TargetDetailField icon={<img width="16" height="16" alt="" src="/img/information-outline.svg"/>} label="Description" value={this.props.case.desc ? this.props.case.desc : ""}/>
            </div>
        );
    }
}

export default CasePreview;