/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsTerms extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Terms of Service</title>
                    <meta name="description" content="The Tesults terms of service."/>
                </Helmet>
                <p><small>Last Updated On 24 April 2024</small></p>
                <p>‘Tesults service’ refers to the Tesults service and application (software service). ‘Project’ refers to a project created on the Tesults service. ‘Plan’ refers to the billing plan for the project. ‘Tesults’ refers to the Tesults company, registered as Tesults Ltd in England and Wales (No. 13084522), 2 Leman Street, London, E1W 9US, UK.</p>

                <p>By using the Tesults service you are agreeing to the following terms of service.</p>

                <p>Tesults reserves the right to update or change the terms of service without notice.</p>
                
                <h2 className="neutral1 mt-5 ">Account</h2>
                <p>Your account and your team member accounts must be used only by the individuals for which the accounts were created and not shared.</p>

                <p>You are responsible for keeping your account safe with respect to keeping passwords safe and logging off public or shared computers appropriately. Tesults advises that you enable and use two-factor authentication for each account. Tesults cannot be held responsible for someone gaining access to your account. If someone gains access to your account we will help you regain control.</p>
                
                <h2 className="neutral1 mt-5 ">Cookies</h2>

                <p>The Tesults service sets, stores and uses cookies (web/browser cookie). By using the Tesults service you accept that cookies are set, stored and used by the Tesults service.</p>

                <h2 className="neutral1 mt-5 ">Analytics Data Collection</h2>

                <p>The Tesults service collects analytics data. This information is used to improve the Tesults service.</p>
                
                <h2 className="neutral1 mt-5 ">Privacy</h2>

                <p>Tesults takes privacy and security of all users of the service seriously. The highest standards are applied to planning, implementing and maintaining privacy and security provisions. These measures are fundamental components of the service.</p>
                <p>Tesults uses third party providers for technology infrastructure to make the Tesults service available.</p>
                <p>Tesults uses Stripe, a PCI compliant third party payment processor certified to PCI Service Provider Level 1 in order to process payments and your payment card details are provided to and retained by this provider.</p>
                <p>Tesults keeps your test results data private and does not share your test results data with other entities, companies, partners or anyone else. Your test results data is accessible only to you and the team members you have chosen to provide access to (in the configuration menu). However, if you choose to enable public access to your project's test results from the configuration menu then your results data will be accessible to the public. This option must be enabled and is by default disabled. Furthermore, access must be enabled for each project target, and the organization the project belongs to. This ensures at least three levels of access must be enabled in order for the possibility of test results data to be made public and would need to be carried out deliberately.</p>
                <p>Test results data is permanently deleted regularly. The time interval for deletion is outlined in the terms for your subscribed plan. You can make a request to have your data permanently deleted at any time by contacting support. Data is permanently deleted, there is no way for Tesults to recover data.</p>
                
                <h2 className="neutral1 mt-5 ">Data</h2>

                <p>All uploaded test results data remains your property. Tesults owns only the Tesults service platform, not the data you upload.</p>
                
                <p>Test results data is deleted on a rolling basis based on the time frame outlined in your plan.</p>
                
                <p>Your test results data is deleted when you cancel your plan.</p>
                <h2 className="neutral1 mt-5 ">Personal Data</h2>
                <p>The Tesults service collects limited personal data on sign up. For most users this is limited to first name, last name, email address and profile picture.</p>
                <p>For users that create a project and create a paid plan subscription additional information is collected relating to payment, specifically billing address and payment card details.</p>
                <p>IP address data may be collected as part of analytics data collection used to improve the Tesults service.</p>
                <p>Tesults uses Stripe, a PCI compliant third party payment processor certified to PCI Service Provider Level 1 in order to process payments and your payment card details are provided to and retained by this provider. If you would like to have the data held by this provider deleted please contact support and we will complete your request and respond within 30 days. This is an upper limit, response times will usually be much faster. </p>
                <p>Tesults keeps your personal data private and does not share this data with other entities, companies, partners or anyone else. Only team members that have been added to the same project can view your first and last name and profile picture. The only exception to this is when you have specifically enabled a project, organization and target to be public, which is not the default.</p>
                <p>You can edit email address, first name and last name and profile picture data at any time from the configuration menu.</p>
                <p>If you would like to have any personal data Tesults has for your account exported or deleted, contact support with your request and we will complete your request and respond within 30 days. This is an upper limit, response times will usually be much faster.</p>
                <p>Tesults has measures in place to remain in compliance with the General Data Protection Regulation (GDPR). If you have any queries related to this please contact support. </p>
                
                <h2 className="neutral1 mt-5 ">Age Restriction</h2>
                <p>The Tesults service can be used by users aged 16 years and older only.</p>

                <h2 className="neutral1 mt-5 ">Data Storage and Security</h2>
                <p>The Tesults service is generally used for storing test data only however Tesults ensures data storage and security measures are always implemented and maintained with highest priority.</p>
                <p>The Tesults service stores data in data centers located within the Unites States and on infrastructure provided by Amazon Web Services. Security is a fundamental and top priority for Tesults. Great care is taken to ensure industry standard technical security measures and best practices are adopted and implemented throughout the entire architecture of the service. These measures are reviewed periodically to keep security measures up-to-date. Stored data never leaves the United States based data centers unless your team makes requests for your data outside of the United States in which case data may be stored temporarily (cached) on CDNs (Content Delivery Networks) around the world as well as of course your browser. All communications to the Tesults service are encrypted by TLS, all communication is made using the HTTPS protocol end-to-end, including viewing data and uploads of results data. This includes all results APIs and language specific API libraries.</p>

                <h2 className="neutral1 mt-5 ">Encryption</h2>
                <p>Data is encrypted in transit as outlined above in <i>Data Storage and Security</i>. All data related to account authentication is encrypted at rest within Tesults databases. All payment related data is stored by Stripe, a PCI compliant third party payment processor certified to PCI Service Provider Level 1.</p>

                <h2 className="neutral1 mt-5 ">Two-factor authentication (2FA)</h2>
                <p>Two-factor authentication (2FA) is available and can be enabled for all Tesults user accounts.</p>

                <h2 className="neutral1 mt-5 ">API Protection</h2>
                <p>All internal and external APIs are protected through authentication.</p>

                <h2 className="neutral1 mt-5 ">Account Roles</h2>
                <p>Account roles offer customized permission levels for team members added to Tesults projects. By default all added members other than the project owner are granted level 1 access, the least permissive role (the owner is the creator of the project unless ownership is transferred manually). We recommend promoting team members to higher level roles only as necessary, especially to level 4 (Officer) role where payment details can be edited (though even level 4 users are unable to view payment details so there is never a danger of payment card details being leaked, this sensitive data is never displayed or transmitted in any API fetch, only the last 4 digits of a card and the expiry date can ever be retrieved by authenticated level 4 role users).</p>

                <h2 className="neutral1 mt-5 ">Support</h2>
                <p>Tesults will respond to all support requests within 30 days. This is an upper limit, response times will usually be much faster, we aim for the same day the request is made.</p>
                
                <h2 className="neutral1 mt-5 ">Product & Service</h2>
                <p>By subscribing to a plan on the Tesults service you are provided use of the Tesults service and access to your subscribed project for yourself and an additional number of users as entitled in your plan.</p>

                <p>You use the Tesults service at your own risk.</p>

                <p>Tesults takes uptime and availability seriously however Tesults does not currently provide warranty or guarantee of service and cannot be held responsible for outages, downtime or interruptions.</p>
                
                <h2 className="neutral1 mt-5 ">Payment</h2>

                <p>By subscribing to a Tesults service paid plan your payment card will be charged monthly or annually depending on the payment schedule you select. Any disputes or billing issues must be brought to the attention of Tesults.</p>

                <p>You must keep your payment card information up to date on the Tesults service. If your payment card expires and a payment failure occurs you will lose access to the Tesults service.</p>
                <h2 className="neutral1 mt-5 ">Cancel & Refund</h2>

                <p>You can cancel your plan at any time by deleting your project. When you cancel you will lose access to the Tesults service for your project immediately (for the project plan you cancel). No further payments will be taken for the canceled project plan. No further action is required for you to complete cancellation. No refunds are provided automatically. Refunds must be requested by contacting Tesults. Refunds are provided only if you contact Tesults within 14 days of the cancellation date.</p>
                
                <p>You are responsible for cancellation of your plan by self service. Cancellation takes place immediately using self service. You may request for Tesults to cancel your plan for you by email but we cannot guarantee immediate cancellation this way. There may be a delay in cancellation and you will be responsible for billing in the interim.</p>
                
                <p>You must contact Tesults for a refund only after cancellation is complete and within 14 days. Partial refunds are provided only under certain conditions as outlined:</p>

                <p><i>Monthly Plan Cancellation & Refund</i></p>

                <p>Cancel anytime. No refunds.</p>

                <p><i>Annual Plan Cancellation & Refund</i></p>

                <p>Cancel anytime. After cancellation is complete you may request a partial refund if you are an annual subscriber only if you cancel within the first month of starting the annual plan, or in the first month of a subsequent renewal for that year. Month here refers to the billing cycle month as if you had subscribed to a monthly rather than annual plan. Tesults will provide you with a partial refund by treating and billing your time already used on the annual plan as if you had been on a monthly plan and calculate a partial refund for the remaining time. Refunds are not provided automatically, you must cancel your plan and then request a refund by contacting Tesults. You must a request a refund within 14 days of cancellation otherwise no refund will be provided. Refunds will be made back to the same payment card used to pay. Tesults reserves the right to calculate and determine what the partial refund amount will be.</p>

                <p>View the Tesults Cancel and Refund Policy documentation for examples of the types of refunds provided.</p>

                <h2 className="neutral1 mt-5 ">General</h2>
                <p>Tesults takes uptime and availability seriously however Tesults does not currently provide warranty or guarantee of service and cannot be held responsible for outages, downtime, interruptions, damages, any financial or any other cost to you as a result of unavailability of the service.</p>
            </div>
        );
    }
}

export default DocsTerms;