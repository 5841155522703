/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsRSpec extends Component {
    
    render() {
        const helmet = 
        <Helmet>
            <title>RSpec test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle RSpec test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <p>This a reference guide for setting up Tesults with your RSpec tests. If you are not using RSpec, view the <NavLink to="/docs/ruby" target="_blank" className="tr-link-primary4 no-break">Ruby</NavLink> docs for information about integrating with a lower level library.</p>

        const installation = 
        <div>
            <p>RSpec Tesults Formatter makes setup easy. Add one dependecy to your project.</p>
            <pre className="docsCode">
            gem install rspec_tesults_formatter
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Once the rspec_tesults_formatter gem has been installed your results data will be pushed to Tesults if you follow the steps here to configure RSpec. <b>By default the RSpec Tesults Formatter will be disabled</b>. To enable the formatter you must provide a value for the tesults_target option. There are also other arguments that you can provide to report more detailed information, outlined below.</p>
            <p>If you have not done so already in your RSpec folder, run:</p>
            <pre className="docsCode">
                rspec --init
            </pre>
            <p>This will create a .rspec file and a spec directory containing spec_helper.rb, if they do not already exist. Open up the .rspec file and add:</p>
            <h4 className="neutral4">.rspec</h4>
            <pre className="docsCode">
                --require rspec_tesults_formatter<br/>
                --require spec_helper
            </pre>
            <p>Then in the spec_helper.rb file add:</p>
            <pre className="docsCode">
                RSpec.configure do |config|<br/>
                &nbsp;&nbsp;config.add_setting :tesults_target, :default => "token"<br/>
                &nbsp;&nbsp;config.add_setting :tesults_files, :default => "/path/to/files"<br/>
                &nbsp;&nbsp;config.add_setting :tesults_build_name, :default => "1.0.0"<br/>
                &nbsp;&nbsp;config.add_setting :tesults_build_desc, :default => "Build description"<br/>
                &nbsp;&nbsp;config.add_setting :tesults_build_result, :default => "pass"<br/>
                &nbsp;&nbsp;config.add_setting :tesults_build_reason  , :default => "Failure reason if fail"<br/>
                end
            </pre>
            <br/>
            <p>The <code>token</code> above should be replaced with the token for your Tesults target, available from the Tesults <NavLink to="/config" target="_blank" className="nounderline primary2">configuration menu</NavLink>. The other settings are explained in detail below in the 'Tesults Settings' section. Now when you run your tests, use the TesultsFormatter and your test results data will automatically be transmitted to Tesults:</p>
            <pre className="docsCode">
                rspec  --format TesultsFormatter ./*_spec.rb
            </pre>
            <p>Alternatively, you can add this line to your spec_helper.rb:</p>
            <pre className="docsCode">
                # This line MUST come after other tesults settings are set such as tesults_target<br/>
                config.formatter = "TesultsFormatter"
            </pre>
            <p>Then to use Tesults you can run your rspec command without needing --format:</p>
            <pre className="docsCode">
                rspec ./*_spec.rb
            </pre>
            <p><b>Supporting Multiple Targets</b></p>
            <p>To consolidate test results data from multiple test jobs you can create multiple <code>spec_helper.rb</code> files, name each one after a target, for example <code>spec_helper_target1.rb</code> and <code>spec_helper_target2.rb</code>. Each spec_helper can specifiy a different target token. Then remove the line <code>--require spec_helper</code> from your <code>.rspec</code> file and instead use <code>--require spec_helper</code> when executing rspec in order to choose the file containing the target you want to use. In this case you have:</p>
            <h4 className="neutral4">.rspec</h4>
            <pre className="docsCode">
                --require rspec_tesults_formatter
            </pre>
            <h4 className="neutral4">Multiple spec_helper for each target</h4>
            <pre className="docsCode">
                /project<br/>
                &nbsp;&nbsp;/spec<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;spec_helper_target1.rb<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;spec_helper_target2.rb<br/>
            </pre>
            <h4 className="neutral4">Command</h4>
            <pre className="docsCode">
                rspec --require spec_helper_target1.rb ./*_spec.rb
            </pre>
            <h4 className="mt-3">Tesults Target</h4>
            <pre className="primary2 docsArgsCode pl-0">tesults_target<span className="neutral7 ml-5">Required</span></pre>
            <p>The tesults_target is the token value for the target you wish you to upload results to. You can retrieve your token from the Tesults <NavLink to="/config" target="_blank" className="nounderline primary2">configuration menu</NavLink>. Learn more about targets <NavLink to="/docs/target" target="_blank" className="nounderline primary2">here</NavLink></p>
            <Box
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point RSpec Tesults Formatter will push results data to Tesults when your tests run as long as the tesults_target is supplied to indicate which target to use.</p>}
            />
        </div>

        const files = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults_files<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <p>This is one area where the RSpec Tesults Formatter is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>The default behavior of the RSpec Tesults Formatter is to set the <code>describe</code> value as the test suite, the <code>context</code> value as the test description and the <code>it</code> value as the test case name. Also be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults_build_name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults_build_result<span className="neutral7 ml-5">Optional</span></pre>            
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults_build_desc<span className="neutral7 ml-5">Optional</span></pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults_build_reason=reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <h4 className="mt-5">How RSpec test cases are reported in Tesults</h4>
            <hr/>
            <p>This example test file includes one <code>describe</code>, three <code>context</code> and three <code>it</code> blocks. Tesults would report the suite as <b>Test Suite A</b>, the test cases would be reported as <b>Test 1</b>, <b>Test 2</b> and <b>Test 3</b>. The context values will appear as the descriptions for each test case.</p>
            <span className="neutral4"><i>test_spec.rb</i></span>
            <br/>
            <pre className="docsCode">
            class Calculator<br/>
            &nbsp;&nbsp;def add(a, b)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;a + b<br/>
            &nbsp;&nbsp;end<br/>
            end<br/><br/>

            RSpec.describe Calculator do<br/>
            &nbsp;&nbsp;describe 'Test Suite A' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;context 'Test 1 description' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;it 'Test 1' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;expect(Calculator.new.add(1, 2)).to eq(3)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;context 'Test 2 description' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;it 'Test 2' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;expect(Calculator.new.add(2, 2)).to eq(4)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;context 'Test 3 description' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;it 'Test 3' do<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;expect(Calculator.new.add(3, 3)).to eq(6)<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;end<br/>
            &nbsp;&nbsp;end<br/>
            </pre>
            <p>Note that if you omit the <code>context</code> blocks then have at least one <code>context</code> (or an additional <code>describe</code>) around all <code>it</code> blocks in the file to correctly report test suite names.</p>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                build={build}
                integration="rspec"
            />
        );
    }
}

export default DocsRSpec;