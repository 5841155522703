/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsDashboard extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Dashboard</title>
                    <meta name="description" content="Learn how to use the dashboard view on Tesults."/>
                </Helmet>
                <iframe className='youtube' src="https://www.youtube.com/embed/BWcGUP8W1k0?si=3LXwwxnsJloAwtFa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>Click <b>Dashboard</b> from the menu to view the project dashboard.</p>
                <p>The dashboard view provides a high-level status of an entire project. The dashboard displays the latest results of all of the targets (test jobs) in a project. Get a quick look at whether the pass rate has improved or deteriorated for each target and click on a target (test job) to drill down to the results view for more detailed analysis.</p>
                <img src="https://www.tesults.com/files/docs/dashboard-1.png" alt="" className="width100"/>
                <h4 className='mt-5'>Customizing the dashboard</h4>
                <iframe className='youtube' src="https://www.youtube.com/embed/Fm65LdgNTBk?si=qfFQEEiFVlVGPaKQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>By default the dashboard displays all the targets in a project. If you have a large number of targets, you may find it useful to group targets.</p>
                <p>Click the 'Edit groups' button to begin setting up groups.</p>
                <img src="https://www.tesults.com/files/docs/dashboard-customize-1.png" alt="" className="width75"/>
                <p>Add a group name to each target you want to be grouped together. After adding a group (or multiple groups) to a target be sure to click save for each target.</p>
                <img src="https://www.tesults.com/files/docs/dashboard-customize-2.png" alt="" className="width75"/>
                <p>At this point you can click 'Exit group editing' and your groups are now setup. As an example, the groups 'iOS' and 'Android' have been applied in this example and now these groups appear in the dropdown list on the dashboard.</p>
                <img src="https://www.tesults.com/files/docs/dashboard-customize-3.png" alt="" className="width75"/>
                <p>Selecting Android now displays the Android tagged targets (test jobs) only.</p>
                <img src="https://www.tesults.com/files/docs/dashboard-customize-4.png" alt="" className="width75"/>
                <p>You can share links to custom grouped dashboards with your team members. You can group targets in multiple verticals at the same time which is powerful for multi disciplinary sub teams or squads. For example it may make sense for the iOS-debug target to show up in both an 'iOS' group and a 'Debug' group. With the dashboard that's easily accomplished.</p>
            </div>
        );
    }
}

export default DocsDashboard;