/*global Cookies*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import Confirmation from './Confirmation';
import ConfirmationButtons from './ConfirmationButtons';

class UserAvatarEdit extends Component {
    constructor() {
        super()
        this.state = {edit: false, file: undefined, confirmation: {success: undefined, failure: undefined}, submitting: false}
        this.change = this.change.bind(this)
        this.confirm = this.confirm.bind(this)
        this.cancel = this.cancel.bind(this)
        this.fileSelect = this.fileSelect.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0)
    }

    change () {
        this.setState({edit: true})
    }

    cancel () {
        this.setState({edit: false})
    }

    confirm () {
        if (this.state.file === undefined) {
            this.setState({error: "Select an image for your profile picture."})
        } else {
            this.setState({submitting: true, confirmation: {success: "Updating - please wait", failure: undefined}})
            var xmlHttpRequest = new XMLHttpRequest()
            xmlHttpRequest.open("POST", "/user/avatar", true)
            xmlHttpRequest.setRequestHeader("Content-Type", this.state.file.type)
            xmlHttpRequest.onreadystatechange = function() {
                if(xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
                    if (xmlHttpRequest.status === 200) {
                        Analytics.event("UserAvatarEdited")
                        this.setState({edit: false, confirmation: {success:"The image has been updated.", failure: undefined}, submitting: false})
                    } else {
                        this.setState({confirmation: {success: undefined, failure: "Unable to update profile picture."}, submitting: false})
                    }
                }
            }.bind(this)
            xmlHttpRequest.send(this.state.file)
        }
    }

    fileSelect (e) {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = (e) => {
            let ext = undefined
            if (file.type === "image/png") {
                ext = "png"
            }
            if (file.type === "image/jpg") {
                ext = "jpg"
            }
            if (file.type === "image/jpeg") {
                ext = "jpg"
            }
            if (ext === undefined) {
                this.setState({error: "Your profile picture must be a jpg or png file."})
            } else {
                this.setState({file: file, fileDisplay: e.target.result, ext: ext, error: ""})
            }
        };

        reader.readAsDataURL(file)
    }

    render () {
        let image = <img src="/img/tasks-128.png" className="img64" alt=""/>
        const user = Cookies.getJSON("truser")
        if (this.state.file !== undefined) {
            let src = this.state.fileDisplay
            image = <img src={src} className="img64" alt=""/>
        } else if (user.aext !== undefined) {
            let src = '/user/avatar/pdla/' + user.id + '/' + user.aext
            image = <img src={src} className="img64" alt=""/>
        } 

        let choosefile = <div></div>
        let buttonText = "Change"
        let action = this.change
        if (this.state.edit === true) {
            choosefile = <input type="file" id="input" className="btn-transparent mt-3" onChange={this.fileSelect}/>
            buttonText = "Confirm"
            action = this.confirm
        }

        return (
            <div>
                <div className="mb-3 font14 neutral4">Profile picture</div>
                <div className="mb-3 font14 neutral4">Make yourself easily identifiable to your team.</div>
                <div style={{"display":"flex"}}>
                    <div style={{"width":"140px"}}>
                        <div className="mb-3">{image}</div>
                        <div>{choosefile}</div>
                    </div>
                    <div>
                        <ConfirmationButtons 
                            confirm={action}
                            cancel={this.state.edit ? this.cancel : undefined}
                            confirmLabel={buttonText}
                            cancelLabel="Cancel"
                            confirmDisabled={this.state.submitting}
                        />
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default UserAvatarEdit;