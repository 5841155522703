/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';
import Notice from './Notice';

class DocsRuby extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Ruby test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Ruby test reporting with Tesults."/>
        </Helmet>

        const plugins =
        <div className='mt-5 mb-5'>
            <Notice type="information" content={
            <div>
                <b>Using RSpec?</b> If you use the <a href="https://rspec.info/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">RSpec test framework</a> take a look at documentation for <NavLink to="/docs/rspec" target="_blank" className="tr-link-primary4 no-break no-break">integrating with RSpec</NavLink>.
            </div>}/>
        </div>
        const installation =
        <pre className="docsCode">
            <code className="accentb4">gem install tesults</code>
        </pre>

        const configuration = 
        <pre className="docsCode">
            <code className="accentb4">require 'tesults'</code>
        </pre>

        const usage =
        <div>
        <p>Upload your test results using the upload method.</p>
        <pre className="docsCode">
            res = <code className="accentb4">Tesults.upload(data)</code><br/>
            # res[:success] - Boolean, true if results successfully uploaded, false otherwise.<br/>
            # res[:message] - String, if success if alse check message for reason<br/>
            # res[:warnings] - Array of Strings, if non empty there may be issues with file uploads.<br/>
            # res[:errors] - Array of Strings, if success is true this is empty
        </pre>
        </div>

        const example =
        <div>
            <p className="mt-3">The data param in upload is a Hash containing your build and test results in the form:</p>
            <pre className="docsCode">
                require 'tesults'<br/><br/>
                data = {'{'}<br/>
                &nbsp;&nbsp;:target => "token",<br/>
                &nbsp;&nbsp;:results => {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;:cases => [<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:name => "Test 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:desc => "Test 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:suite => "Suite A",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:result => "pass"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:name => "Test 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:desc => "Test 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:suite => "Suite B",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:result => "pass",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:params => {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:param1 => "value1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:param2 => "value2"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:_CustomField => "Custom field value"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:name => "Test 3",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:desc => "Test 3 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:suite => "Suite A",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:start => ((Time.now.to_f * 1000).to_i) - 60000,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:end => ((Time.now.to_f * 1000).to_i,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:result => "fail",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:reason => "Assert fail in line 203 of example.rb",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:files => ["full/path/to/file/log.txt", "full/path/to/file/screencapture.png"],<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:steps => [<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:name => "Step 1",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:desc => "Step 1 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:result => "pass",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:name => "Step 2",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:desc => "Step 2 description",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:result => "fail",<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:reason => "Assert fail in line 203 of example.rb"<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                &nbsp;&nbsp;{'}'}<br/>
                {'}'}<br/><br/>

                res = Tesults.upload(data)<br/>
                puts 'Success: ' + (res[:success] ? "true" : "false")<br/>
                puts 'Message: ' + res[:message]<br/>
                puts 'Warnings: ' + res[:warnings].length.to_s<br/>
                puts 'Errors: ' + res[:errors].length.to_s<br/>
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu. The cases Array should contain your test cases. You would usually add these by looping through the test case objects you currently have in your build and test scripts.</p>
            <p>Each test case added to cases must have a name and a result. The result value must be one of 'pass', 'fail' or 'unknown'. All other fields are optional: desc, suite, files, params, steps, reason and custom fields (prefixed with underscore).</p>
            <p>The 'params' value is for use by parameterized test cases and is a Hash containing the parameter names and values.</p>
        </div>

        return (
            <DocsLanguage
                language="ruby"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsRuby;