/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class NotFound extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Not Found</title>
                    <meta name="description" content="Not Found"/>
                </Helmet>
                <div className="app-content-margin whitebg" style={{"minHeight":"800px"}}>
                    <h3 className="mb-3">Not Found</h3>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <p>Check the url has been entered correctly. <NavLink to="/results" className="site-link-primary2">Go to results</NavLink>.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default NotFound;