/*global */
import React, { Component } from 'react';

class Target extends Component {
    constructor () {
        super();
        this.state = {
            icon: {
                image: "/img/menu-project-default.svg",
                darkImage: "/img/menu-target-default-dark.svg",
                width: "12",
                height: "21",
                widthLarge: "24",
                heightLarge: "42"
            }
        };
    }
    
    render () {
        let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
        if (this.props.targets !== undefined) {
            let targetIndex = this.props.targetIndex;
            if (targetIndex === undefined) {
                targetIndex = 0;
            }
            if (targetIndex < this.props.targets.length) {
                const target = this.props.targets[targetIndex];
                if (target.aext !== undefined) {
                    let imageSrc = '/target/avatar/pdla/' + target.id + '/' + target.created + '/' + target.aext;
                    image = <img alt="" src={imageSrc} width="25" height="25" className="circle25 img25 noborder"/>
                }
                return <span><div className="test-case-detail-circle">{image}</div>&nbsp;&nbsp;{target.name}</span>
            } else {
                return <span></span>
            }
        } else {
            return <span></span>
        }
    }
}

export default Target;