
/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';
import DocsFilesStructure from './DocsFilesStructure';

class DocsNightwatch extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Waffle smart contract test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Waffle smart contract test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <div>
            <p>Power up your Waffle tests for Ethereum smart contracts with Tesults reporting. This page explains how to get setup, it takes just a couple of minutes to start reporting results from Waffle. If you have any questions please reach out to the Tesults team. </p>
            <p>If you are not using the <a className="tr-link-primary4 no-break" target="_blank" href="https://getwaffle.io/" rel="noopener noreferrer">Waffle</a> test framework but are using JavaScript/Node.js view the <NavLink to="/docs/nodejs" target="_blank" className="site-link-primary2 no-break">Node.js</NavLink> docs instead for integrating with a lower-level library.</p>
        </div>
        

        const installation = 
        <div>
            <p>Tests in <a className="tr-link-primary4 no-break" target="_blank" href="https://getwaffle.io/" rel="noopener noreferrer">Waffle</a> are written using <a className="tr-link-primary4 no-break" target="_blank" href="https://mochajs.org/" rel="noopener noreferrer">Mocha</a>.</p>
            <p>Tesults provides a test reporter for Mocha and you can use it with Waffle. Install mocha-tesults-reporter:</p>
            <pre className="docsCode">
                npm install mocha-tesults-reporter --save
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Update the package.json file for your Waffle project to use the Tesults reporter for your test script:</p>
            <span className="font14 neutral4">package.json</span>
            <br/>
            <pre className='docsCode'>
            module.exports = {'{'}<br/>
            &nbsp;&nbsp;...<br/>
            &nbsp;&nbsp;"scripts": {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;"build": "waffle",<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;"test": "NODE_ENV=test mocha <code className="accentb4">--reporter mocha-tesults-reporter</code> <code className="accentc4">-- tesults-target=token"</code><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;{'}'}<br/>
            &nbsp;&nbsp;...<br/>
            {'}'}
            </pre>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="site-link-primary2">configuration menu</a>.</p>
            <p>Then run your tests as usual:</p>
            <pre className="docsCode">
            npm test
            </pre>
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point Waffle will push results to Tesults after your tests complete. The target arg must be supplied as shown above otherwise reporting will be disabled.</p>}
            />
            <h2>Next steps</h2>
            <p>See the <NavLink to="/docs/mocha" target="_blank" className="tr-link-primary4 no-break">Tesults Mocha documentation</NavLink> for advanced configuration details. Whatever the mocha-tesults-reporter supports can be applied for use with Waffle including supplying build information and file uploads.</p>
        </div>

        return (
            <div>
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                integration="waffle"
            />
            </div>
        );
    }
}

export default DocsNightwatch