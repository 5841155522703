/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsWebhooks from './DocsWebhooks';

class DocsMSTeams extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - MS Teams</title>
                    <meta name="description" content="Send test results notifications to MS Teams from Tesults"/>
                </Helmet>
                <DocsWebhooks 
                    service="MS Teams"
                    url="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
                    img="/img/webhooks-msteams.png"
                />
            </div>
        );
    }
}

export default DocsMSTeams;

