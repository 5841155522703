/*global */
import React, { Component } from 'react';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class AffiliateDashboardEvents extends Component {
    constructor() {
        super();
        this.state = {events: [], esk: undefined};
        this.events = this.events.bind(this);
    }

    componentDidMount () {
        this.events();
    }

    events () {
        if (this.props.id === undefined) {
            return;
        }
        Request.get("/affiliate-events", {id: this.props.id, esk: this.state.esk}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                let events = this.state.events;
                for (let i = 0; i < data.events.length; i++) {
                    events.push(data.events[i]);
                }
                this.setState({events: events, esk: data.esk});
            }
        });
    }

    render () {
        if (this.state.events === undefined) {
            return <div></div>
        } else {
            let data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Time", minWidth: "200px"},
                    {value: "Event", minWidth: "200px"}
                ],
                rows: []
            }
            for (let i = 0; i < this.state.events.length; i++) {
                let event = this.state.events[i];
                data.rows.push(
                    {
                        cols: [
                            {value: event.id},
                            {value: TimeFormatted.timeformatted(event.time, true)},
                            {value: event.event}
                        ]
                    }
                )
            }
            if (data.rows.length === 0) {
                return (
                    <div>
                        <h4 className="neutral3">Events</h4>
                        <p>No events yet</p>
                    </div>
                ); 
            } else {
                let oldestEvent = this.state.events[this.state.events.length - 1];
                let oldestEventTime = TimeFormatted.timeformatted(oldestEvent.time, true);
                return (
                    <div>
                        <h4 className="neutral3">Events</h4>
                        <div style={{"display":"flex"}}>
                            <div className="mr-3">
                                <span className="font14 neutral4">Since {oldestEventTime}</span>
                            </div>
                            <div>
                                {
                                    this.state.esk === undefined ?
                                    <span></span>
                                    :
                                    <button className="btn-cancel" onClick={this.events}>Load more</button>
                                }
                            </div>
                        </div>
                        <TabularData data={data}/>
                    </div>
                );
            }
        }
    }
};

export default AffiliateDashboardEvents;