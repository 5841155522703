/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsJest extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Jest test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Jest test reporting with Tesults."/>
        </Helmet>

        const introduction = 
        <p>Using the <a className="site-link-primary2" target="_blank" href="https://jestjs.io" rel="noopener noreferrer">Jest</a> test framework? Follow the instructions below to report results. If you are not using Jest please see the <NavLink to="/docs/nodejs" target="_blank" className="tr-link-primary4 no-break">Node.js</NavLink> docs for integrating with a lower-level library.</p>

        const installation = 
        <pre className="docsCode">
            npm install jest-tesults-reporter --save
        </pre>

        const configuration = 
        <div>
            <p>Add this snippet to your Jest configuration in package.json or your jest.config.js or through the --config {'<path/to/js|json>'} option (See <a className='tr-link-primary4 no-break no-break' target="_blank" href="https://jestjs.io/docs/en/configuration" rel="noopener noreferrer">https://jestjs.io/docs/en/configuration</a> for details about Jest configuration):</p>
            <pre className="docsCode">
            "jest": {'{'}<br/>
            &nbsp;&nbsp;"reporters":<br/>
            &nbsp;&nbsp;["default", ["jest-tesults-reporter", {'{'}"tesults-target": "token"{'}'}]]<br/>
            {'}'}
            </pre>
            <p>The tesults-target is an argument passed through to the jest-tesults-reporter. Multiple args can be passed to the jest-tesults-reporter and are outlined in detail below.</p>
            <p>You can now run Jest as usual with the <b>npm test</b> command, or <b>npm script-name</b> if you named the script something other than test in the package.json.</p>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="tr-link-primary4 no-break">configuration menu</a>.</p>
            <p>The reporter treats the <b>describe</b> as a test suite and each <b>test (or it)</b> as a test case.</p>
            <pre className="docsCode">
            describe('SuiteA', () => {'{'}<br/>
            &nbsp;&nbsp;test("Test1", () => {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;expect(sum(1, 2)).toBe(3);<br/>
            &nbsp;&nbsp;{'}'});<br/><br/>
            &nbsp;&nbsp;test("Test2", () => {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;expect(sum(2, 2)).toBe(4);<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});<br/><br/>
            
            describe('SuiteB', () => {'{'}<br/>
            &nbsp;&nbsp;test("Test3", () => {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;expect(sum(3, 3)).toBe(6);<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});
            </pre>
            <p>We recommend using 'describe' so that test cases can be appropriately grouped by test suite on Tesults.</p>
            <pre className="primary2 docsArgsCode pl-0">tesults-target<span className="neutral7 ml-5">Required</span></pre>
            <p>Required to upload to Tesults, if this arg is not provided the reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <pre className="docsCode pre-wrap-break-word">{'{ "tesults-target": "eyJ0eXAiOiJ..." }'}</pre>
            <div className="p-3 neutral8bg mb-5">
                <p>If you see this warning message from Jest you can safely ignore it.</p>
                <p><i>Jest did not exit one second after the test run has completed. This usually means that there are asynchronous operations that weren't stopped in your tests. Consider running Jest with `--detectOpenHandles` to troubleshoot this issue.</i></p>
                <p>Sending results data to Tesults can longer than one second, especially if there are files.</p>
            </div>
            <Box 
                bg="neutral8bg neutral1 mb-3"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point the jest-tesults-reporter will push results to Tesults when you run your tests. <b>The tesults-target arg must be supplied to indicate which target to use</b>. See above for complete details.</p>}
            />
        </div>

        const files = 
        <div>
            <p className='accenta3 bold font12'>This method of uploading files is no longer recommended starting from jest-tesults-reporter 1.2.0+. If using jest-tesults-reporter 1.2.0 or newer, utilize the file method described above to simplify uploading files from tests.</p>
            <pre className="primary2 docsArgsCode pl-0">tesults-files<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">{'{ "tesults-target": "eyJ0eXAiOiJ...", "tesults-files":"/Users/admin/Desktop/temporary" }'}</pre>
            <p>This is one area where the jest-tesults-reporter is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Within this temporary directory create subdirectories matching the test suite (describe) and test case (test/it) name.</p>
            <p>Be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const enhancedReporting = 
        <div>
            <p>Use the Tesults reporter to report additional properties for each test case. To begin doing this, require the reporter in your test file:</p>
            <pre className='docsCode'>
                const tesultsReporter = require("./node_modules/jest-tesults-reporter/tesults-reporter.js")
            </pre>
            <p>Report custom fields, test steps and attach files to test cases using the Tesult reporter functions: description, custom, step, and file. <b>Call reporter functions from within your tests (test block)</b>. The first parameter is always the test state to provide the reporter with context about the test:</p>
            <h4>description</h4>
            <p>Add a description of the test case for reporting purposes.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">description</code>(expect.getState(), "some description here")
            </pre>
            <h4>custom</h4>
            <p>Add a custom field and value to the test case for reporting purposes.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">custom</code>(expect.getState(), "Some custom field", "Some custom value")<br/>
            </pre>
            <h4>file</h4>
            <p>Associate a file to the test case in order to upload it for reporting.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">file</code>(expect.getState(), "/absolute/path/to/file/screenshot.png")<br/>
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h4>step</h4>
            <p>Add test steps to the test case for reporting. Each step is an object with a name and result (one of [pass, fail, unknown]). You can also add the optional fields description and reason (failure reason in case of step fail).</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">step</code>(expect.getState(), {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "First step",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "pass"<br/>
            {'}'})<br/>
            tesultsReporter.<code className="accentc6">step</code>(expect.getState(), {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "Second step",<br/>
            &nbsp;&nbsp;<code className="accentb4">description</code>: "Second step description",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "fail"<br/>
            &nbsp;&nbsp;<code className="accentb4">reason</code>: "Error line 203 of test.js"<br/>
            {'}'})<br/>
            </pre>
        </div>

        const example = 
        <div>
            <p>A complete example:</p>
            <pre className='docsCode'>
            const tesultsReporter = require("./node_modules/jest-tesults-reporter/tesults-reporter.js")<br/><br/>
            describe ('Suite A', () => {'{'}<br/>
            &nbsp;&nbsp;test('Test 1', () => {'{'}<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.custom(expect.getState(), "custom field 1", "custom value 1")<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.description(expect.getState(), "description here")<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.step(expect.getState(),<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'{'}name: "step 1", description: "step 1 description", result: "pass"{'}'})<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.step(expect.getState(),<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{'{'}name: "step 2", description: "step 2 description", result: "pass"{'}'})<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.file(expect.getState(), "/full/path/logs/log1.txt")<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});
            </pre>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">{'{ "tesults-build-name": "1.0.0" }'}</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">{'{ "tesults-build-result": "pass" }'}</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-desc<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">{'{ "tesults-build-desc": "added new feature" }'}</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">{'{ "tesults-build-reason": "build error line 201 somefile.js" }'}</pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                files={files}
                enhancedReporting={enhancedReporting}
                build={build}
                example={example}
                integration="jest"
            />
        );
    }
}

export default DocsJest;