/*global */
import React, { Component } from 'react';

class FileViewer extends Component {
    constructor () {
        super()
        this.state = {value: undefined, error: undefined}
    }

    componentDidMount () {
        let url = this.props.location.pathname.replace("/file-viewer/" + this.props.match.params.type + "/", "")
        if (this.props.match.params.type === "html") {
            fetch(url)
            .then(function (response) {
                return response.text()
            }.bind(this))
            .then(function (value) {
                this.setState({value: value})
            }.bind(this))
            .catch(function (err) {
                this.setState({error: err.toString()})
            }.bind(this));
        }
    }

    render() {
        if (this.state.value === undefined && this.state.error === undefined) {
            return <div></div>
        } else if (this.state.error !== undefined) {
            return <div>{this.state.error}</div>
        } else {
            if (this.props.match.params.type === "html") {
                return <div style={{"overflow":"auto", "height":"100vh"}} dangerouslySetInnerHTML={{__html: this.state.value}}/>
            } else {
                return <div>Unsupported type</div>
            }
        }
    }
}

export default FileViewer