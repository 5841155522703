/*jslint es6*/

// polyfill for support old browsers
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

// regular setup
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';


ReactDOM.render(
  <Routes/>,
  document.getElementById('root')
);