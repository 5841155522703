/*global */
import React, { Component } from 'react';
//import { Link, NavLink } from 'react-router-dom'
import Loading from './Loading';
import Confirmation from './Confirmation';
import TimeFormatted from './TimeFormatted';
import Request from './Request';

class Member extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false, confirmation: {success: undefined, failure: undefined}, member: this.props.member};
        this.roleChange = this.roleChange.bind(this);
    }

    roleChange(e) {
        this.setState({confirmation: {success: "Updating role - please wait", failure: undefined}});
        let role = parseInt(e.target.value, 10);
        Request.post("/memberRoleChange", {project: this.props.project.id, userMember: this.props.userMember, member: this.state.member, role: role}, (err, data) => {
            if (err) {
                this.setState({loading: false, confirmation: {success: undefined, failure: "Unable to update role."}});
            } else {
                let member = this.state.member;
                member.role = role;
                this.setState({member: member, confirmation: {success:"Updated role", failure: undefined}, loading: false});
            }
        });
    }

    render () {
        if (this.state.loading === true) {
            return <Loading/>
        } else {
            let avatar = <img src="/img/tasks-128.png" className="img64 circle" alt=""/>
            if (this.state.member.aext !== undefined) {
                let src = '/user/avatar/pdla/' + this.state.member.id + '/' + this.state.member.aext;
                avatar = <img src={src} className="img64 circle" alt=""/>
            }
    
            let notifyButton = <div></div>
            //let notifyMessage = <div></div>
            if (this.props.member.notify === true) {
                //notifyMessage = <span className="pass">Notifications Enabled</span>
                notifyButton = <button type="button" className="btn btn-cancel mb-2" disabled={this.props.submitting} onClick={() => {this.props.notifyEnable(this.state.member, false)}}>Disable notifications</button>
            } else {
                //notifyMessage = <span className="fail">Notifications Disabled</span>
                notifyButton = <button type="button" className="btn btn-cancel mb-2" disabled={this.props.submitting} onClick={() => {this.props.notifyEnable(this.state.member, true)}}>Enable notifications</button>
            }
            
            let remove = <div></div>
            let removeButton = false;
            if (this.props.userMember !== undefined) {
                if (this.props.userMember.role >= 3) {
                    if (this.props.userMember.role === 3 && this.props.member.role <= 2) {
                        removeButton = true;
                    }
                    if (this.props.userMember.role === 4 && this.props.member.role <= 3) {
                        removeButton = true;
                    }
                    if (this.props.userMember.role === 5 && this.props.member.role <= 4) {
                        removeButton = true;
                    }
    
                    if (removeButton === true) {
                        remove = <button type="button" className="btn btn-cancel" disabled={this.props.submitting} onClick={() => {this.props.removeMember(this.state.member)}}>Remove from project</button>
                    }
                }
            }

            if (this.props.state === "transfer" && this.props.member.role < 5) {
                remove = <button type="button" className="btn btn-confirm" disabled={this.props.submitting} onClick={() => {this.props.transferSelect(this.state.member)}}>Transfer</button>
            }
    
            let roleOptions = [];
            let disable1 = true;
            let disable2 = true;
            let disable3 = true;
            let disable4 = true;
            let disable5 = true;
            
            if (this.props.userMember !== undefined) {
                if (this.props.userMember.role === 2) {
                    if (this.state.member.role <= 1) {
                        disable1 = false;
                        disable2 = false;
                    }    
                }
                if (this.props.userMember.role === 3) {
                    if (this.state.member.role <= 2) {
                        disable1 = false;
                        disable2 = false;
                        disable3 = false;
                    }
                }
                if (this.props.userMember.role === 4) {
                    if (this.state.member.role <= 3) {
                        disable1 = false;
                        disable2 = false;
                        disable3 = false;
                        disable4 = false;
                    }
                }
                if (this.props.userMember.role === 5) {
                    disable1 = false;
                    disable2 = false;
                    disable3 = false;
                    disable4 = false;
                }
        
                if (this.state.member.role === 5) {
                    disable1 = true;
                    disable2 = true;
                    disable3 = true;
                    disable4 = true;
                }
            }
            
            roleOptions.push(<option key={1} value={1} disabled={disable1}>Member (Level 1)</option>);
            roleOptions.push(<option key={2} value={2} disabled={disable2}>Moderator (Level 2)</option>);
            roleOptions.push(<option key={3} value={3} disabled={disable3}>Administrator (Level 3)</option>);
            roleOptions.push(<option key={4} value={4} disabled={disable4}>Officer (Level 4)</option>);
            roleOptions.push(<option key={5} value={5} disabled={disable5}>Owner (Level 5)</option>);
    
            let roleSelection =
                <div>
                    <select className="custom-select width100 mb-2" onChange={this.roleChange} defaultValue={this.state.member.role}>
                        {roleOptions}
                    </select>
                </div>

            let notifications = <span></span>
            let roleSelect = <span></span>
            let removalButton = <span></span>

            notifications =  <div>
                                {notifyButton}
                            </div>

            roleSelect = <div>
                            {roleSelection}
                        </div>
        
            removalButton = <div>
                                {remove}
                            </div>

            let lastSeen = this.state.member.lastSeen;
            let active = <span className="neutral2 font 14">Inactive</span>
            if (lastSeen === undefined) {
                lastSeen = <span>{active}</span>
            } else {
                let activeStartTime = Date.now() - (86400000 * 30);
                if (lastSeen >= activeStartTime) {
                    active = <span className="accentc3 font14">Active</span>
                }
                lastSeen = <p className="font14 neutral4">{active} Last seen <TimeFormatted dt={lastSeen} includeYear={true}/></p>
            }                
            
            return (
                <div className='mb-5'>
                    <div className='flex-row'>
                        <div style={{"width": "70px"}} className="mr-3">
                            {avatar}
                        </div>
                        <div>
                            <div className="font24">{this.state.member.firstName + " " + this.state.member.lastName}</div>
                            <div className="font16">{this.state.member.email}</div>
                        </div>
                        <div style={{"marginLeft":"auto"}}>
                            {removalButton}
                        </div>
                    </div>
                    <div className='flex-row'>
                        <div style={{"width": "70px"}} className="mr-3">
                        
                        </div>
                        <div style={{"flex":"1"}}>
                            <div className='flex-row' style={{"alignItems":"center"}}>
                                <div className='bold'>
                                    Role
                                </div>
                                <div style={{"marginLeft":"auto"}}>
                                    {roleSelect}
                                </div>
                            </div>
                            <hr className='dividor-light'/>
                            <div className='flex-row' style={{"alignItems":"center"}}>
                                <div className='bold'>
                                    Allow notifications
                                </div>
                                <div style={{"marginLeft":"auto"}}>
                                    {notifications}
                                </div>
                            </div>
                            <hr className='dividor-light'/>
                            <div className='flex-row' style={{"alignItems":"center"}}>
                                <div className='bold'>
                                    Status
                                </div>
                                <div style={{"marginLeft":"auto"}}>
                                    {lastSeen}
                                </div>
                            </div>
                            <div>
                                <Confirmation confirmation={this.state.confirmation}/>
                            </div>
                            <hr className='dividor-light'/>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Member;