/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsAPIPurpose from './DocsAPIPurpose';

class DocsAuth extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - API Authentication</title>
                    <meta name="description" content="Learn how to manage Tesults API authentication tokens."/>
                </Helmet>
                <DocsAPIPurpose/>
                <div>
                    <p>The Tesults API is utilized to access results data for your project. Once you have a project created and integrated, you are ready to utilize the Tesults API.</p>
                    <p>To authenticate and retrieve any data via the API you must first generate an authentication token. This is done using the Tesults interface. To do this go to the <a href="/config" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">configuration menu</a> and click on API token under the Tesults API header. Or follow this <a href="/config/project/api-token" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">direct link</a>. Click 'Create API token'.</p>
                    <img className="pt-5 pb-5" src="https://www.tesults.com/files/docs/api-token-create.png" alt="" style={{"maxWidth":"400px"}}/>
                    <p>A token will be generated, copy this token and keep it secret.</p>
                    <img className="pt-5 pb-5" src="https://www.tesults.com/files/docs/api-token-created.png" alt="" style={{"maxWidth":"400px"}}/>
                    <p>Use this token as the <b>bearer</b> token value in the Authorization header for all of your API requests.</p>
                    <p>You are now ready to make requests to the Tesults API. Read about how to access targets and results:</p>
                    <ul>
                        <li><a href="/docs/api/targets" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">Targets</a></li>
                        <li><a href="/docs/api/results" className="tr-link-primary4 no-break" target="_blank" rel="noopener noreferrer">Results</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default DocsAuth;