/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsAPIPurpose from './DocsAPIPurpose';
import Box from './Box';
import TabularData from './TabularData';

class DocsAPIResults extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Results API</title>
                    <meta name="description" content="Learn how to use the Tesults Results API."/>
                </Helmet>
                <DocsAPIPurpose/>
                <div>
                    <h2>Results</h2>
                    <div style={{"display":"flex", "flexWrap":"wrap"}}>
                        <div style={{"flex":"1"}}>
                            <p>Retrieve test results data.</p>
                            <p>Requesting test results data via the Tesults API is useful for analysis of results data outside of the Tesults interface in situations such as deciding whether to proceed with a deployment in a continuous deployment pipeline. You can fetch results data for a specific build.</p>
                            <p>Results data is fetched for a specific target within a project. Utilize the <a href="/docs/api/targets" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">Targets</a> endpoint to fetch targets (also known as test jobs) and retrieve a target id. Then use the id for the target you are interested in to fetch results data.</p>
                            <p>A successful response contains a results object with an array of runs (test runs). Each run object contains a cases array (test cases) which you can loop through to check the name, suite, result and other properties of a test case. You may decide for example that if all the test cases are passing (result value is 'pass') that you go ahead with a deployment.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl ('token' to be replaced by API token and target_id to replaced by target id retrieved from Targets API)</div>
                            <pre className='docsCode wrap'>
                                curl https://www.tesults.com/api/results<br/>
                                ?target=target_id<br/> 
                                -H "Authorization: Bearer token"
                            </pre>
                            <h4 className='neutral4'>A note about files</h4>
                            <p>Test cases containing files have a <b>files</b> property refrencing an array of files, along with a <b>files_base_path</b> property. Join the files_base_path to each file in the array to get the full path to each test file. The file can be accessed using this path in a web browser by a signed in member of the relevant project. An API endpoint does not exist for accessing files but you can utilize the same path without a browser to retrieve the file by adding the Authorization header and API token to the request as is done for API requests, but you also have to account for redirects and enable a cookie store because file paths utilize signed urls and cookies.</p>
                            <div className='neutral4 font12 mb-0'>Request example using curl to download and output a screenshot to file. The -L option is required to enable redirect support and -b enables cookies.</div>
                            <pre className='docsCode wrap'>
                                curl -L -b cookies.txt<br/>
                                -H 'Authorization: Bearer token<br/>
                                'https://www.tesults.com/.../screenshot.jpg'<br/>
                                --output screenshot.jpg
                            </pre>
                        </div>
                        <div style={{"width":"2px"}}></div>
                        <div style={{"flex":"1"}}>
                            <Box
                                bg={"neutral8bg"}
                                title={<span></span>}
                                content={
                                    <div>
                                        <h4>Endpoint</h4>
                                        <div className="mb-5 font15 neutral3 bold">https://www.tesults.com/api/results</div>
                                        <div className="font15 neutral3">GET Method</div>
                                        <h4 className="mt-5">Headers</h4>
                                        <div>
                                            <p>The Authorization header is required for all requests to the Tesults API. See the <a href="/docs/api/auth" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">authentication</a> documentation for details.</p>
                                            <div style={{"display":"flex"}}>
                                                <div className="font15 netural3 mr-5">
                                                    <span className="neutral4">Name</span>
                                                    <br/>
                                                    Authorization
                                                </div>
                                                <div className="font15 netural3">
                                                    <span className="neutral4">Value</span>
                                                    <br/>
                                                    Bearer {'<token>'}
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="mt-5">Parameters</h4>
                                        <TabularData
                                            data = {
                                                {
                                                    headers: [
                                                        {value: "Name", minWidth: "200px"},
                                                        {value: "Value", minWidth: "200px"},
                                                    ],
                                                    rows: [ 
                                                        {
                                                            cols: [
                                                                {value: "target"},
                                                                {value: "The target id for the target for which you want to retrieve test results data."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "persistent (optional)"},
                                                                {value: "Set to 'true' to include persistent data (associated bugs/issues and financial cost data for test cases) in returned results. If this parameter is not provided or set to anything but true then persistent data will not be returned."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "analysis (optional)"},
                                                                {value: "Set to 'true' to include analysis data (flaky indicator) with test cases in returned results. If this parameter is not provided or is set to anything but true then analysis data will not be returned."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "limit (optional)"},
                                                                {value: "A number (minimum value 1) to limit the number of test runs returned. If no limit is supplied the default limit is 1, meaning that only the latest test run data is fetched. If you wanted to fetch the last two test runs you would set limit to 2. Note that if analysis is set to true the limit may be ignored and more runs may be retrieved than the limit."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "cursor (optional)"},
                                                                {value: "Pagination parameter. If a cursor is returned in the response data from a request that means more data is available. Add the value of the cursor returned in the response in the next request to retrieve the next set of data."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "build (optional)"},
                                                                {value: "Specify a build name to retreive results data for a specific build only. Use this in the case where data for a specific build is of interest rather than all test runs. Do not supply the build parameter if you are using the run parameter."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "run (optional)"},
                                                                {value: "Specify a run id to retreive results data for a specific run only. Use this in the case where data for a specific run is of interest rather than all test runs. Get a run id from the user interface or use the value for created_time as the run id as returned in the response by this api. Do not supply the run parameter if you are using the build parameter."}
                                                            ]
                                                        },
                                                        {
                                                            cols: [
                                                                {value: "cases (optional)"},
                                                                {value: "Set to 'false' to exclude test cases in response. If this parameter is not provided or is set to anything but false then cases data will be returned. If you require only run information such as the total number of test cases and number of passes then set this to false for a slightly faster response time."}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            }
                                        />
                                        <h4 className="mt-5">Response</h4>
                                        <p>Successful response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"data"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accentc6">200</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accentc6">"Success"</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"results": {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"runs": [<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"created_time": <code className="accentb4">1631398688540</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"pass": <code className="accentb4">149</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"total": <code className="accentb4">150</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"build_name": <code className="accentb4">"1.0.0"</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"build_result": "pass",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"build_raw_result": "passed",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"run_url": "https://...",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"cases": [<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Sign in successfully",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Signs in to site",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Authentication",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"duration": 11122000,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"num": 0,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"hash": "7c95f9b7-578d92c19fdd",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"_CustomField": "Custom value",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"params": {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"terrain": "land",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"climate": "warm",<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"temperature": "25C"<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"files": [<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"screenshot1.png", "test.log"<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"files_base_path": "/files/base/path"<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'{... more test cases}'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"cursor": <code className="accentb4">{'<cursor>'}</code><br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Failure response:</p>
                                        <pre className="docsCode">
                                        {'{'}<br/>
                                        &nbsp;&nbsp;<code className="primary6">"error"</code>: {'{'}<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"code": <code className="accenta6">400</code>,<br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;"message": <code className="accenta6">"Missing parameter: target"</code><br/>
                                        &nbsp;&nbsp;{'}'}<br/>
                                        {'}'}
                                        </pre>
                                        <p>Successful responses return JSON containing a <b>data</b> property. Error responses return JSON containing an <b>error</b> property. Both data and error values contain a <b>code</b> and <b>message</b> property, specifying the http status code and a message containing additional context.</p>
                                        <p>Successful responses also contain additional properties within data. The response includes results with runs (test runs) each with an array of cases (test cases). There is also a cursor for pagination to use if there is additional data (more test runs) that can be retrieved.</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocsAPIResults;