/*global */
import React, { Component } from 'react';

class ManualCaseSaveToList extends Component {
    /*  props:
        saveToListEnabled
        saveToLists
        saveToListToggle
        saveToListId
        saveToListChange
    */

    render () {
        let listOptions = [];
        for (let i = 0; i < this.props.saveToLists.length; i++) {
            let list = this.props.saveToLists[i];
            if (list.groupProxy !== true) {
                let option = list.label;
                if (list.group !== undefined) {
                    if (list.group.length > 0) {
                        option += " (";
                        list.group.forEach(function (g) {
                            option += " > " + g;
                        });
                        option += " )";
                    }
                }
                listOptions.push(<option key={list.id + "-" + list.created} value={list.id + "-" + list.created}>{option}</option>);
            }
        }
        let listSelection =
            <div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="font14 neutral3 mb-1">Select a test list</div>
                        <select className="custom-select mb-3 width100" onChange={this.props.saveToListChange} value={this.props.saveToListId}>
                            {listOptions}
                        </select>
                    </div>
                </div>
            </div>

        if (listOptions.length === 0) {
            listSelection = <p className="accenta2">You have not created any test lists. Before adding test cases to a test run we recommend you create a list. Access Lists from the menu bar.</p>
        }

        if (this.props.saveToListEnabled !== true) {
            listSelection = <span></span>
        }
        
        if (this.props.newCase !== true) {
            return <span></span>
        } else {
            return (
                <div className="mt-2 mb-2">
                    <div style={{"display":"flex", "alignItems":"center"}} className="mb-0">
                        <div className="mr-3">
                            <input type="checkbox" onChange={() => this.props.saveToListToggle()} checked={this.props.saveToListEnabled} aria-label="SaveToList"/>
                        </div>
                        <div>
                            <h5>Save to a test list</h5>
                        </div>
                    </div>
                    <div className="mt-0 mb-3  neutral4 font14">Add this new test case to a test list to store it for future use, otherwise it will not be saved beyond this test run. Test lists are for persistent storage and test cases from a list can be imported and used in new test runs.</div>
                    {listSelection}
                </div>
            );
        }
    }
}

export default ManualCaseSaveToList