/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsAuditLogs extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Audit Logs Configuration Guide</title>
                    <meta name="description" content="Learn how to configure a Tesults audit logs."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Audit Logs.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/WdPcawHxPuM?si=OLSMgEn9K-0tvObr" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <p>There is nothing to configure to collect and view audit logs. On most plans, Tesults provides audit logs.</p>
                <p>Review audit logs of major Tesults configuration events. Timestamps, usernames and message data are displayed for each event. Configuration events include most actions that can be taken from the configuraiton menu such as creating, updating and deleting targets, groups and result interpretation maps, token regeneration, manual test list and run updates, archiving, role changes, and just about everything else that can be configurated or set.</p>
                <p>With thorough audit logs you can rest assured you can meet the security, regulatory and compliance requirements your organization may have even when it comes to your test results data management.</p>
            </div>
        );
    }
}

export default DocsAuditLogs;