/*global*/
import React, { Component } from 'react';
import TaskAssign from './TaskAssign.js';
import TaskResolve from './TaskResolve';
import CaseDetailField from './CaseDetailField';
import Request from './Request';

class TaskCase extends Component {
    constructor () {
        super();
        this.state = {task: undefined};
        this.taskChange = this.taskChange.bind(this);
        this.getTask = this.getTask.bind(this);
    }

    componentDidMount() {
        this.getTask();
    }

    getTask () {
        if (this.props.case.result.toLowerCase() !== "pass") {
            Request.get("/task", {id: this.props.project.id, created: this.props.target.created, hash: this.props.case.hash}, (err, data) => {
                if (err) {
                    this.setState({task: null});
                } else {
                    this.setState({task: data.task});
                }
            });
        }
    }

    taskChange (task) {
        this.setState({task: task});

        if (this.props.taskChanged !== undefined) {
            this.props.taskChanged();
        }

        this.getTask();
    }

    render() {
        if (this.props.case.result.toLowerCase() === "pass") {
            return (<span></span>);
        } else if (this.state.task === undefined) {
            if (this.props.trl === true) {
                return <span></span>
            }
            let value = <span>
                            <p>Assign this test case failure to a team member</p>
                            <TaskAssign task={this.state.task} taskChange={this.taskChange} case={this.props.case} target={this.props.target} project={this.props.project}/>
                        </span>
            return <CaseDetailField mobile={this.props.mobile} label={<span>Task</span>} value={value} darkMode={this.props.darkMode}/>
        } else if (this.state.task === null) {
            return <CaseDetailField mobile={this.props.mobile} label={<span>Task</span>} value={<span>Unable to display task information.</span>} darkMode={this.props.darkMode}/>
        } else {
            let assigneeImage = <img src="/img/tasks-128.png" width="45" height="45" className="img45 circle45 noborder mr-2" alt=""/>
            if (this.state.task.assignee.aext !== undefined) {
                let src = '/user/avatar/pdla/' + this.state.task.assignee.id + '/' + this.state.task.assignee.aext;
                assigneeImage = <img src={src} width="45" height="45" className="img45 circle45 noborder mr-2" alt=""/>
            }
            let assignerImage = <img src="/img/tasks-128.png" width="45" height="45" className="img45 circle45 noborder mr-2"  alt=""/>
            if (this.state.task.assigner.aext !== undefined) {
                let src = '/user/avatar/pdla/' + this.state.task.assigner.id + '/' + this.state.task.assigner.aext;
                assignerImage = <img src={src} width="45" height="45" className="img45 circle45 noborder mr-2"  alt=""/>
            }

            let edit = <span></span>
            if (this.props.trl !== true) {
                edit = <div>
                            <TaskAssign task={this.state.task} taskChange={this.taskChange} case={this.props.case} target={this.props.target} project={this.props.project}/>
                            <TaskResolve taskChanged={this.props.taskChanged} assignee={this.state.task.assignee} resolved={this.state.task.resolved} resolvedMessage={this.state.task.resolvedMessage} case={this.props.case} target={this.props.target} project={this.props.project}/>
                    </div>
            }

            let value = <div>
                            <div className="neutral4 font14 mb-3">
                                Assigned to:
                            </div>
                            <div className="mb-5" style={{"display":"flex", "alignItems":"center"}}>
                                <div>{assigneeImage}</div>
                                <div>{this.state.task.assignee.firstName + " " + this.state.task.assignee.lastName + " (" + this.state.task.assignee.email + ")"}</div>
                            </div>
                            <div className="neutral4 font14 mb-3">
                                Assigned by:
                            </div>
                            <div className="mb-5" style={{"display":"flex", "alignItems":"center"}}>
                                <div>{assignerImage}</div>
                                <div>{this.state.task.assigner.firstName + " " + this.state.task.assigner.lastName + " (" + this.state.task.assigner.email + ")"}</div>
                            </div>
                            {edit}
                        </div>

            return <CaseDetailField mobile={this.props.mobile} label={<span>Task</span>} value={value} darkMode={this.props.darkMode}/>
        }
    }
}

export default TaskCase;