/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Analytics from './Analytics';
import Request from './Request';

class ResetPasswordMain extends Component {
    constructor() {
        super();
        Analytics.event("ResetPassword");
        const user = Cookies.getJSON("truser");
        let state = "loggedIn";
        if (user === undefined || user == null) {
            state = null;
        }
        this.state = {state: state, password : "", error: undefined};
        this.passwordChange = this.passwordChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    passwordChange (e) {
        this.setState({password: e.target.value});
    }

    reset (e) {
        e.preventDefault();
        // validate here
        // nothing
        Request.post("/resetpassword", {password: this.state.password, rc: this.props.rc}, (err, data) => {
            if (err) {
                this.setState({error: err});
            } else {
                Analytics.event("ResetPasswordLogin");
                this.setState({sent: true});
            }
        });
    }

    render () {
        if (this.state.state === "loggedIn") {
            return (
                <div style={{"maxWidth":"600px", "marginLeft":"auto", "marginRight":"auto"}} className="standardborder whitebg mt-5 p-5">
                    <p>You are logged in. View <NavLink to="/results" className="site-link">results</NavLink>.</p>
                </div>
            );
        } else {
            if (this.state.sent === true) {
                return (
                    <div style={{"maxWidth":"600px", "marginLeft":"auto", "marginRight":"auto"}} className="standardborder whitebg mt-5 p-5">
                        <h3 className="mb-4">Password has been reset.</h3>
                        <NavLink to="/results" className="nounderline">Get back to your <u>test results</u></NavLink>.
                    </div>
                );
            } else {
                return (
                    <form id="forgot" className="text-left neutral1" onSubmit={this.reset}>
                        <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                            <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                <div className='mb-3' style={{"flex":"1"}}>
                                    <h1 className="mb-5" style={{"fontWeight":"800"}}>Reset password</h1>
                                    <label for="password" className="neutral4 font12 mb-0">New password</label>
                                    <br/>
                                    <input type="password" className="tr-input form-control" id="password" name="password" placeholder="Password" onChange={this.passwordChange} required/>
                                </div>
                                <div style={{"flex":"1"}}>
                                    <button type="submit" className="btn btn-confirm-index">Set new password</button>
                                </div>
                                <div style={{"flex":"1"}}>
                                    <div className="accenta1 font14" id="error">{this.state.error === undefined ? <span></span>: this.state.error}</div>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }   
        }
    }
}

export default ResetPasswordMain;