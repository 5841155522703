/*global Cookies*/
import React, { Component } from 'react';

class Checkmark extends Component {
    constructor () {
        super()
        this.state = {complete: false}
        this.complete = this.complete.bind(this)
    }

    componentDidUpdate () {
        setTimeout(this.complete, 1000);
    }

    complete () {
        this.setState({complete: true})
    }

    render () {
        if (this.state.complete !== true) {
            return (
                <div>
                    <div class="circle-loader">
                        <div class="checkmark checkmark-hide draw"></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div class="circle-loader load-complete">
                        <div class="checkmark draw"></div>
                    </div>
                </div>
            )
        }
    }
}

export default Checkmark