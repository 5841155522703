/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DocsTestData from './DocsTestData';
import DocsBuildConsolidation from './DocsBuildConsolidation';
import DocsDynamic from './DocsDynamic';
import Box from './Box';

class DocsResultsAPI extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Results REST API</title>
                    <meta name="description" content="Learn how to use the Tesults REST API for effective test reporting."/>
                </Helmet>
                <p className="mt-4">You may use the REST API to upload test results data to Tesults.</p>
                <Box
                    bg="neutral3"
                    title={<h4>Tesults recommends using the Tesults API library for your language or test framework plugin instead</h4>}
                    content={
                        <div>
                            <p>A Tesults API library or test framework plugin provides greater control and is the only way to upload files (logs, screenshots etc.) along with your test results.</p>
                        </div>
                    }
                />
                <p>Use the /results API to upload results:</p>
                <h4>API</h4>
                <pre className="docsCode">
                    https://www.tesults.com/results<br/>
                    Method: 'POST'
                </pre>
                <h4>Headers</h4>
                <pre className="docsCode">
                    "Content-Type": "application/json"
                </pre>
                <br/>
                <span className='font12 neutral4'>Ensure no other headers are supplied.</span>
                <h4>Body</h4>
                <p>Submit test results data in JSON format in the request POST body:</p>
                <pre className="docsCode">
                    {'{'}<br/>
                    &nbsp;&nbsp;"target": "token",<br/>
                    &nbsp;&nbsp;"results": {'{<'}results{'>}'}<br/>
                    {'}'}
                </pre>
                <br/>
                <p>Both 'target' and 'results' values are <b>required</b>.</p>
                
                <p>The "token" placeholder should be replaced with the target token string provided to you on creation of your project. If you did not note down the token when you created your project you can regenerate a token for the target in the configuration menu.</p>
                
                <p>{'<'}results{'>'} must be replaced with your test results. Test results are formatted as an array of test case objects.</p>
                
                <br/>
                <pre className="docsCode">
                    {'"results": { "cases": ['}<br/>
                    &nbsp;&nbsp;{'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"name" : {'<name>'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"desc" : {'<desc>'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"suite" : {'<suite>'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"result" : {'<result>'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"reason" : {'<reason>'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;"params" : {'<params>'}<br/>
                    &nbsp;&nbsp;{'}'},<br/>
                    &nbsp;&nbsp;...more test cases here<br/>
                    &nbsp;&nbsp;{']'}<br/>
                    {'}'}
                </pre>
                <br/>
                
                <p>In each test case object "name" and "result" are <b>required</b>, "desc", "suite", "reason" and "params" are <b>optional</b>.</p>
                <p>{'<'}name{'>'} should be replaced with the name of the test case. (Required)</p>
                <p>{'<'}desc{'>'} should be replaced with the description of the test case. (Optional)</p>
                <p>{'<'}suite{'>'} should be replaced with the suite of the test case. (Optional)</p>
                <p>{'<'}result{'>'} should be replaced with the result of the test case. (Required)</p>
                <p>{'<'}reason{'>'} should be replaced with the reason for the failure of the test case. (Optional)</p>
                <p>{'<'}params{'>'} should be replaced with the parameters of the test case if it is a parameterized test. (Optional)</p>
                <p>To report a build pass or failure add a case with the suite value set to '[build]'. If a case has suite set to '[build]' it will be treated as build data rather than as a test case. Use the name or description to report a build number, version or revision.</p>
                
                <p>Here is a complete example:</p>
                <pre className="docsCode">{'{'}<br/>
                    &nbsp;&nbsp;"target": "0123456789",<br/>
                    &nbsp;&nbsp;"results": {'{'} "cases": [ <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test A",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Test A description",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Suite X",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test B",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Test B description",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Suite X",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "fail",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"reason": "Assert in line 203, example.cpp.",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"_CustomField": "Custom field value"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Test C",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"desc": "Test C description",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"suite": "Suite Y",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"result": "pass",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"params": {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"param1": "value1",<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"param2": "value2"<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;]<br/>
                    &nbsp;&nbsp;{'}'}<br/>
                    {'}'}<br/>
                </pre>
                <br/>
                <p>Within 'results' the 'cases' array is <b>required</b>. If you are only uploading one test result you must still send it as one element in the array.</p>
                
                <p>"0123456789" should be replaced with the target token string provided to you once you on creation of your project. If you did not note down the token when you created your project you can regenerate a token for the target in the configuration menu.</p>
                
                <p>If the the request is successful the response will have a status code of 200 and include a JSON response as shown below.</p>
                <br/>
                <pre className="docsCode">
                    status code 200 with JSON response:<br/><br/>
                    {'{'}<br/>
                    &nbsp;&nbsp;"data": {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "code": 200,<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "message": "Success"<br/>
                    &nbsp;&nbsp;{'}'}<br/>
                    {'}'}<br/>
                </pre>
                <br/>
                <p>If there is an error there will be a status code other than 200 with JSON response as shown below.</p>
                <br/>
                <pre className="docsCode">
                    status code other than 200, for example 400, 401, 403, 429, 500 with JSON response:<br/><br/>
                    {'{'}<br/>
                    &nbsp;&nbsp;"error": {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "code": 400,<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "message": "Missing required parameters - target and results are required"<br/>
                    &nbsp;&nbsp;{'}'}<br/>
                    {'}'}<br/>
                </pre>
                <br/>
                <p>The error message will provide detail as to the specific reason for the failed request.</p>
                
                <p>Fix the error displayed in the error message and try again to get a successful response.</p>
                
                <p>Your team members will be able to view your uploaded test results immediately after a successful response. Any notificiation recipients you have configured will be notified about the availability of the new results by email.</p>
                <DocsTestData/>
                <DocsBuildConsolidation/>
                <DocsDynamic/>
            </div> 
        );
    }
}

export default DocsResultsAPI;