import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import prettyMilliseconds from 'pretty-ms';

class Duration extends Component {
    render() {
        let c = this.props.c;
        if (c === undefined) {
            return <span></span>
        }
        if (this.props.context === "list" || this.props.context === "manual") {
            return <span></span>
        }
        const durationFormat = function (duration) {
            if (duration === undefined || duration === "") {
                return ""
            }
            try {
                return prettyMilliseconds(duration)
            } catch (err) {
                return ""
            }
        }

        if (this.props.durationOnly === true) {
            if (c.duration !== undefined) {
                if (isNaN(c.duration) === false) {
                    return <span>{durationFormat(c.duration)}</span>
                } else {
                    return <span></span>
                }
            } else if (c.start !== undefined && c.end !== undefined) {
                if (isNaN(c.start) === false && isNaN(c.end) === false) {
                    return <span>{durationFormat(c.end - c.start)}</span>
                } else {
                    return <span></span>
                }
            }
        }
        
        if (c.start !== undefined || c.end !== undefined || c.duration !== undefined) {
            let start = <span></span>;
            let end = <span></span>;
            let duration = <span></span>;
            
            if (c.start !== undefined) {
                if (isNaN(c.start) === false) {
                    start = <div style={{"display":"flex", "alignItems":"center"}} className=" neutral1 font14 mr-3"><img style={{"marginTop": "-2px"}} src="/img/play.svg" width="16" height="16" alt=""/> <TimeFormatted dt={c.start}/></div>
                }
            }
            
            if (c.end !== undefined) {
                if (isNaN(c.end) === false) {
                    end = <div style={{"display":"flex", "alignItems":"center"}} className=" neutral1 font14 mr-3"><img style={{"marginTop": "-2px"}} src="/img/flag.svg" width="12" height="12" alt=""/> <TimeFormatted dt={c.end}/></div>
                }
            }

            if (this.props.suite === true) {
                if (c.start !== undefined && c.end !== undefined) {
                    if (isNaN(c.start) === false && isNaN(c.end) === false) {
                        let dur = c.end - c.start;
                        if (c.duration !== undefined) {
                            if (isNaN(c.duration) === false) {
                                dur += c.duration;
                            }
                        }
                        duration = <div style={{"display":"flex", "alignItems":"center"}} className=" neutral1 font14 mr-3"><img style={{"marginTop": "-2px"}} src="/img/timer.svg" width="12" height="12" alt=""/> {durationFormat(dur)}</div>
                    }
                } else if (c.duration !== undefined) {
                    if (isNaN(c.duration) === false) {
                        duration = <div style={{"display":"flex", "alignItems":"center"}} className=" neutral1 font14 mr-3"><img style={{"marginTop": "-2px"}} src="/img/timer.svg" width="12" height="12" alt=""/> {durationFormat(c.duration)}</div>
                    }
                } 
            } else {
                if (c.duration !== undefined) {
                    if (isNaN(c.duration) === false) {
                        duration = <div style={{"display":"flex", "alignItems":"center"}} className=" neutral1 font14 mr-3"><img style={{"marginTop": "-2px"}} src="/img/timer.svg" width="12" height="12"alt=""/> {durationFormat(c.duration)}</div>
                    }
                } else if (c.start !== undefined && c.end !== undefined) {
                    if (isNaN(c.start) === false && isNaN(c.end) === false) {
                        duration = <div style={{"display":"flex", "alignItems":"center"}} className=" neutral1 font14 mr-3"><img style={{"marginTop": "-2px"}} src="/img/timer.svg" width="12" height="12" alt=""/> {durationFormat(c.end - c.start)}</div>
                    }
                }
            }

            let caseTime = <span></span>
            if (this.props.format === "case") {
                if (c.duration !== undefined) {
                    caseTime = duration;
                } else if (c.start !== undefined) {
                    caseTime = start;
                } else if (c.end !== undefined) {
                    caseTime = end;
                }
            }
            
            if (this.props.suite === true) {
                return (
                    <span>  
                        {duration}
                        {start}
                        {end}
                    </span>
                );
            } else if (this.props.format === "case") {
                return (
                    <div>
                        {caseTime}
                    </div>
                );
            } else {
                return (
                    <div>
                        {duration}
                        {start}
                        {end}
                    </div>
                );
            }
        } else {
            return <span></span>
        }
    }
}

export default Duration;