/*global*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import RoadmapMain from './RoadmapMain'
import RoadmapSide from './RoadmapSide'

class Roadmap extends Component {
    render () {
        window.scrollTo(0, 0);
        return (
            <div>
                <Helmet>
                    <title>Tesults - Roadmap</title>
                    <meta name="description" content="Learn about the Tesults roadmap. Understand what is coming to Tesults soon and make feature requests."/>
                </Helmet>
                <div className="mt-5">
                    <RoadmapMain/>
                    <RoadmapSide/>
                </div>
            </div>
        );
    }
};

export default Roadmap;