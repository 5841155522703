/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DocsTestFramework from './DocsTestFramework';
import Box from './Box';

class DocsMocha extends Component {
    render() {
        const helmet=
        <Helmet>
            <title>Mocha test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Mocha test reporting with Tesults."/>
        </Helmet>

        const introduction =
        <p>Using the <a className="site-link-primary2 no-break" target="_blank" href="https://mochajs.org" rel="noopener noreferrer">Mocha</a> test framework? Follow the instructions below to report results. If you are not using Mocha please see the <NavLink to="/docs/nodejs" target="_blank" className="site-link-primary2 no-break">Node.js</NavLink> docs for integrating with a lower-level library.</p>

        const installation = 
        <div>
            <p>Install the mocha-tesults-reporter</p>
            <pre className="docsCode">
                npm install mocha-tesults-reporter --save
            </pre>
        </div>

        const configuration = 
        <div>
            <p>Now that the mocha-tesults-reporter is installed you have to have Mocha use it. This is done using Mocha's --reporter arg. In your package.json file add this snippet:</p>
            <pre className="docsCode">
            "scripts": {'{'}<br/>
            &nbsp;&nbsp;"test": "mocha * --reporter mocha-tesults-reporter -- tesults-target=token"<br/>
            {'}'}
            </pre>
            <p>Then run Mocha as usual with the <b>npm test</b> command. Use <b>npm run script-name</b> if you named the script something other than test in the package.json. If running mocha from the commandline run:</p>
            <pre className="docsCode">
            mocha * --reporter mocha-tesults-reporter -- tesults-target=token
            </pre>
            <p>The 'token' above should be replaced with your Tesults target token, which you received when creating a project or target, and can be regenerated from the <a href="/config" className="site-link-primary2">configuration menu</a>. The * after mocha is telling Mocha to run all tests it finds, you may want to narrow this down, see Mocha docs for more details.</p>
            <p>To supply multiple args to the reporter use this format: </p>
            <pre className="docsCode">
            mocha * --reporter mocha-tesults-reporter -- arg1=val1 arg2=val2 arg3=val3<br/><br/>
            for example:<br/><br/>
            mocha * --reporter mocha-tesults-reporter<br/> -- tesults-target=token tesults-files=path tesults-config=configpath
            </pre>
            <p>The reporter treats the <b>describe</b> as a test suite and each <b>it</b> as a test case.</p>
            <pre className="docsCode">
            const assert = require('assert');<br/><br/>
            describe('SuiteA', function() {'{'}<br/>
            &nbsp;&nbsp;it("Test1", function () {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;assert.equal(2, 2);<br/>
            &nbsp;&nbsp;{'}'});<br/><br/>
            &nbsp;&nbsp;it("Test2", function () {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;assert.equal(1, 2);<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});<br/><br/>
            
            describe('SuiteB', function() {'{'}<br/>
            &nbsp;&nbsp;it("Test3", function () {'{'}<br/>
            &nbsp;&nbsp; &nbsp;&nbsp;assert.equal(3, 3);<br/>
            &nbsp;&nbsp;{'}'});<br/>
            {'}'});
            </pre>

            <p><b>Using Protractor?</b> The configuration instructions above do not apply. Follow the <a className="site-link-primary2" target="_blank" href="/docs/protractor" rel="noopener noreferrer">Protractor configuration instructions</a> instead and then return here to continue.</p>

            <pre className="primary2 docsArgsCode pl-0">tesults-target<span className="neutral7 ml-5">Required</span></pre>
            <p>Required arg to upload to Tesults, if this arg is not provided the reporter does not attempt upload, effectively disabling it. Get your target token from the configuration menu in the Tesults web interface.</p>
            <p><b>Inline method</b></p>
            <pre className="docsCode pre-wrap-break-word">tesults-target=eyJ0eXAiOiJ...</pre>
            <p>In this case, the target token is supplied directly or inline in the commandline args. This is the simplest approach.</p>
            <p><b>Key method</b></p>
            <pre className="docsCode pre-wrap">tesults-target=target1</pre>
            <p>In this case, the reporter will automatically look up the value of the token from a config.json file. See below for more details.</p>
            
            <pre className="primary2 docsArgsCode pl-0">tesults-config<span className="neutral7 ml-5">Optional</span></pre>
            <p>You can optionally provide a path to a config.json file. Configuration json files make it possible to store many targets and have one selected with the tesults-target arg (see above). All args other than tesults-target and tesults-config can be supplied in a config.json file instead of the mocha command, including the file and build related args detailed below.</p>
            <p>Example contents of a config.json:</p>
            <pre className="docsCode pre-wrap">
            {'{'}<br/>
            &nbsp;&nbsp;"target1": "eyJ0eXAiOiJKV1QiLCJhb1",<br/>
            &nbsp;&nbsp;"target2": "eyJ0eXAiOiJKV1QiLCJhb2",<br/>
            &nbsp;&nbsp;"target3": "eyJ0eXAiOiJKV1QiLCJhb3",<br/>
            &nbsp;&nbsp;"target4": "eyJ0eXAiOiJKV1QiLCJhb4",<br/>
            {'}'}
            </pre>
            <p>Or something more descriptive about the targets:</p>
            <pre className="docsCode pre-wrap">
            {'{'}<br/>
            &nbsp;&nbsp;"web-qa-env": "eyJ0eXAiOiJKV1QiLCJhb1",<br/>
            &nbsp;&nbsp;"web-staging-env": "..."<br/>
            &nbsp;&nbsp;"web-prod-env": "..."<br/>
            &nbsp;&nbsp;"ios-qa-env": "eyJ0eXAiOiJKV1QiLCJhb2",<br/>
            &nbsp;&nbsp;"ios-staging-env": "..."<br/>
            &nbsp;&nbsp;"web-prod-env": "..."<br/>
            &nbsp;&nbsp;"android-qa-env": "eyJ0eXAiOiJKV1QiLCJhb3",<br/>
            &nbsp;&nbsp;"android-staging-env": "..."<br/>
            &nbsp;&nbsp;"android-prod-env": "..."<br/>
            {'}'}
            </pre>
            <Box
                bg="neutral8bg neutral1"
                title={<h4>Basic configuration complete</h4>}
                content={<p>At this point the mocha-tesults-reporter will push results to Tesults when you run your mocha command. The tesults-target arg must be supplied to indicate which target to use.</p>}
            />
        </div>

        const files = 
        <div>
            <p className='accenta3 bold font12'>This method of uploading files is no longer recommended starting from mocha-tesults-reporter 1.3.0+. If using mocha-tesults-reporter 1.3.0 or newer, utilize the file method described above to simplify uploading files from tests.</p>
            <pre className="primary2 docsArgsCode pl-0">tesults-files<span className="neutral7 ml-5">Optional</span></pre>
            <p>Provide this arg to save files generated during a test run. The value for this arg is the top-level directory where files generated during testing are saved for the running test run. Files, including logs, screen captures and other artifacts will be automatically uploaded.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-files=/Users/admin/Desktop/temporary</pre>
            <p>This is one area where the mocha-tesults-reporter is opinionated and requires that files generated during a test run be saved locally temporarily within a specific directory structure.</p>
            <p>Store all files in a temporary directory as your tests run. After Tesults upload is complete, delete the temporary directory or just have it overwritten on the next test run.</p>
            <p>Within this temporary directory create subdirectories matching the test suite (describe) and test case (it) name.</p>
            <p>Be aware that if providing build files, the build suite is always set to [build] and files are expected to be located in temporary/[build]/buildname</p>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
        </div>

        const enhancedReporting = 
        <div>
            <p>Use the Tesults reporter to report additional properties for each test case. To begin doing this, require the reporter in your test file:</p>
            <pre className='docsCode'>
                const tesultsReporter = require("mocha-tesults-reporter")
            </pre>
            <p>Report custom fields, test steps and attach files to test cases using the Tesult reporter functions: description, custom, step, and file. <b>Call reporter functions from within your tests (test block)</b>. The first parameter is always the test state to provide the reporter with context about the test:</p>
            <h4>description</h4>
            <p>Add a description of the test case for reporting purposes.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">description</code>(this.test, "some description here")
            </pre>
            <h4>custom</h4>
            <p>Add a custom field and value to the test case for reporting purposes.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">custom</code>(this.test, "Some custom field", "Some custom value")<br/>
            </pre>
            <h4>file</h4>
            <p>Associate a file to the test case in order to upload it for reporting.</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">file</code>(this.test, "/full/path/to/file/screenshot.png")<br/>
            </pre>
            <p><small>Caution: If uploading files the time taken to upload is entirely dependent on your network speed. Typical office upload speeds of 100 - 1000 Mbps should allow upload of even hundreds of files quite quickly, just a few seconds, but if you have slower access it may take hours. We recommend uploading a reasonable number of files for each test case. The upload method blocks at the end of a test run while uploading test results and files. When starting out test without files first to ensure everything is setup correctly.</small></p>
            <h4>step</h4>
            <p>Add test steps to the test case for reporting. Each step is an object with a name and result (one of [pass, fail, unknown]). You can also add the optional fields description and reason (failure reason in case of step fail).</p>
            <pre className='docsCode'>
            tesultsReporter.<code className="accentc6">step</code>(this.test, {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "First step",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "pass"<br/>
            {'}'})<br/>
            tesultsReporter.<code className="accentc6">step</code>(this.test, {'{'}<br/>
            &nbsp;&nbsp;<code className="accentb4">name</code>: "Second step",<br/>
            &nbsp;&nbsp;<code className="accentb4">description</code>: "Second step description",<br/>
            &nbsp;&nbsp;<code className="accentb4">result</code>: "fail"<br/>
            &nbsp;&nbsp;<code className="accentb4">reason</code>: "Error line 203 of test.js"<br/>
            {'}'})<br/>
            </pre>
            <div>
                <p>A complete enhanced reporting example:</p>
                <pre className='docsCode'>
                const tesultsReporter = require("mocha-tesults-reporter")<br/><br/>
                describe ('Suite A', () => {'{'}<br/>
                &nbsp;&nbsp;test('Test 1', () => {'{'}<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.custom(this.test, "custom field 1", "custom value 1")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.description(this.test, "description here")<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.step(this.test,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'{'}name: "step 1", description: "step 1 description", result: "pass"{'}'})<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.step(this.test,<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;{'{'}name: "step 2", description: "step 2 description", result: "pass"{'}'})<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;tesultsReporter.file(this.test, "/full/path/logs/log1.txt")<br/>
                &nbsp;&nbsp;{'}'});<br/>
                {'}'});
                </pre>
            </div>
        </div>

        const build = 
        <div>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-name<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build version or name for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-name=1.0.0</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-result<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report the build result, must be one of [pass, fail, unknown].</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-result=pass</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-description<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build description for reporting purposes.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-description='added new feature'</pre>
            <hr/>
            <pre className="primary2 docsArgsCode pl-0">tesults-build-reason<span className="neutral7 ml-5">Optional</span></pre>
            <p>Use this to report a build failure reason.</p>
            <pre className="docsCode pre-wrap-break-word">tesults-build-reason='build error line 201 somefile.js'</pre>
        </div>

        return (
            <DocsTestFramework
                helmet={helmet}
                introduction={introduction}
                installation={installation}
                configuration={configuration}
                enhancedReporting={enhancedReporting}
                files={files}
                build={build}
                integration="mocha"
            />
        );
    }
}

export default DocsMocha;