/*global */
import React, { Component } from 'react';
//import TimeFormatted from './TimeFormatted';


class ScatterChartControl extends Component {
    constructor(props) {
        super(props);
        this.selectedDictionaryCreate = this.selectedDictionaryCreate.bind(this);
        this.selectedValue = this.selectedValue.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.generateRows = this.generateRows.bind(this);
        this.onChangeFormat = this.onChangeFormat.bind(this);
        this.yRangeChange = this.yRangeChange.bind(this);
        this.yRangeUpdate = this.yRangeUpdate.bind(this);
        this.yRangeRevert = this.yRangeRevert.bind(this);

        let yRanges = {};
        let propsYRanges = this.props.yRanges;
        this.props.fields.forEach(function (field) {
            let propYRange = propsYRanges[field];
            if (propsYRanges[field] !== undefined) {
                yRanges[field] = {};
                yRanges[field].min = propYRange.min;
                yRanges[field].max = propYRange.max;
                yRanges[field].minSelected = propYRange.minSelected;
                yRanges[field].maxSelected = propYRange.maxSelected;
            }
        });
        this.state = {yRanges: yRanges};
    }

    onChangeFormat (event) {
        this.props.changeFormat(event.target.name, event.target.value);
    }

    selectedDictionaryCreate () {
        let selected = {};
        if (this.props.selected.x !== undefined) {
            this.props.selected.x.forEach(function (s) {
                selected[s] = "x";
            });
            
        }
        if (this.props.selected.y !== undefined) {
            this.props.selected.y.forEach(function (s) {
                selected[s] = "y";
            });
        }
        return selected;
    }

    selectedValue (field, axis) {
        let selected = this.selectedDictionaryCreate();
        const ySelectLimit = 2;
        let ySelectLimitReached = false;
        if (this.props.selected.y.length >= ySelectLimit) {
            ySelectLimitReached = true;
        }

        if (selected[field] !== "x" && selected[field] !== "y" && axis === "x") {
            return <button className="btn btn-sm btn-select" onClick={() =>this.props.changeSelected(field, axis, true)}>Add</button>;
        } else if (selected[field] === "x" && axis === "x") {
            return <button className="btn btn-sm btn-confirm" onClick={() => this.props.changeSelected(field, axis, false)}>Remove</button>;
        } else if (selected[field] === "y" && axis === "y") {
            return <button className="btn btn-sm btn-confirm" onClick={() => this.props.changeSelected(field, axis, false)}>Remove</button>;
        } else if (selected[field] !== "y" && axis === "y") {
            if (ySelectLimitReached === true) {
                return <span></span>;
            } else  {
                if (selected[field] === "x") {
                    return <button className="btn btn-sm btn-confirm" onClick={() => this.props.changeSelected(field, axis, false)}>Add</button>;
                } else {
                    return <button className="btn btn-sm btn-select" onClick={() => this.props.changeSelected(field, axis, false)}>Add</button>;
                }
            }
        } else {
            return <span></span>;
        }
    }

    formatValue (field) {
        let index = 0;
        let formatOptions = [];
        let options = this.props.formatOptions[field];
        options.forEach(function (format) {
            formatOptions.push(<option key={index} value={format}>{format}</option>);
            index += 1;
        });
        return (
            <select className="custom-select" style={{"fontSize":"small"}} onChange={this.onChangeFormat} defaultValue={this.props.format[field]} name={field}>
                {formatOptions}
            </select>
        );
    }
    
    yRangeChange (field, minmax, e) {
        let yRanges = this.state.yRanges;
        if (minmax === "min") {
            yRanges[field].minSelected = e.target.value;
        } else if (minmax === "max") {
            yRanges[field].maxSelected = e.target.value;
        }
        
        this.setState({yRanges: yRanges});
    }

    yRangeUpdate (field) {
        this.props.changeYRange(field, this.state.yRanges[field].minSelected, this.state.yRanges[field].maxSelected);
    }

    yRangeRevert (field) {
        this.props.changeYRange(field, this.state.yRanges[field].min, this.state.yRanges[field].max);
        let yRanges = this.state.yRanges;
        yRanges[field].minSelected = yRanges[field].min;
        yRanges[field].maxSelected = yRanges[field].max;
        this.setState({yRanges: yRanges});
    }

    generateRows () {
        let rows = [];
        let key = 0;
        let selected = this.selectedDictionaryCreate();
        this.props.fields.forEach(function (field) {
            let yAxisRange = "All"
            if (selected[field] === "y" && this.props.format[field] === "number") {
                yAxisRange =
                    <div>
                        Min: <input name="min" type="text" style={{"width": "5em", "fontSize":"small"}} className="scatter-chart-control-tr" value={this.state.yRanges[field].minSelected} onChange={(e) => this.yRangeChange(field, "min", e)}/>
                        &nbsp;&nbsp;
                        Max: <input type="text" style={{"width": "5em", "fontSize":"small"}} className="scatter-chart-control-tr" value={this.state.yRanges[field].maxSelected} onChange={(e) => this.yRangeChange(field, "max", e)}/>
                        
                        <button className="btn btn-sm btn-confirm ml-3" onClick={() => this.yRangeUpdate(field)}>Update</button>
                        
                        <button className="btn btn-sm btn-confirm ml-3" onClick={() => this.yRangeRevert(field)}>Revert</button>
                    </div>
            }

            let row = undefined;
            if (selected[field] !== undefined) {
                row = <tr key={key++}>
                    <td className="neutral1bg white"><small>{field}</small></td>
                    <td className="neutral1bg white"><small>{this.formatValue(field)}</small></td>
                    <td className="neutral1bg white"><small>{this.selectedValue(field, "x")}</small></td>
                    <td className="neutral1bg white"><small>{this.selectedValue(field, "y")}</small></td>
                    <td className="neutral1bg white"><small>{yAxisRange}</small></td>
                </tr>
            } else {
                row = <tr key={key++}>
                    <td><small>{field}</small></td>
                    <td><small>{this.formatValue(field)}</small></td>
                    <td><small>{this.selectedValue(field, "x")}</small></td>
                    <td><small>{this.selectedValue(field, "y")}</small></td>
                    <td><small>{yAxisRange}</small></td>
                </tr>
            }

            rows.push(row);
        }.bind(this));
        return rows;
    }

    render () {
        return (
            <div className="table-responsive">
                <table className="table table-condensed mt-3">
                <thead>
                <tr>
                <th className="neutral1bg white"><small>Field</small></th>
                <th className="neutral1bg white"><small>Format</small></th>
                <th className="neutral1bg white"><small>X-Axis</small></th>
                <th className="neutral1bg white"><small>Y-Axis</small></th>
                <th className="neutral1bg white"><small>Y Display Range</small></th>
                </tr>
                </thead>
                <tbody>
                {this.generateRows()}
                </tbody>
                </table>
            </div>
        );
    }
}

export default ScatterChartControl;