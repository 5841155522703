/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsTargets extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Targets Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults targets."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Targets</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/Rf2L7H5AXRE?si=GZ2beBZXJfvAxDrH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <h3>Create New Target</h3>
                <p>Click the 'Create New Target' button to create a new target. <Link target="_blank" to="/docs/target">Learn about targets</Link>.</p>
                <p>Enter a name and description for the new target and click the 'Create' button. A new target will be created and a target token will be displayed that you should copy and use for results upload.</p>
                <h3>Target Name</h3>
                <p>Rename and edit the target name by clicking the edit button.</p>
                <h3>Target Description</h3>
                <p>Rename and edit the target description by clicking the edit button.</p>
                <h3>Target Image</h3>
                <p>Upload an image to use for a target. The image for the target is displayed in dropdown lists, headers and the dashboard.</p>
                <h3>Target Result Interpretation Mainp</h3>
                <p>Set a target result interpretation map to be used by the target. This is useful if you want results data to be interpreted in a custom way for specific test frameworks. Learn about <Link target="_blank" to="/docs/result-interpretation">result interpretation maps</Link>.</p>
                <h3>Target Test Case Display Attribute Map</h3>
                <p>Set a test case display attribute map to be used by the target. Attribute maps allow customization of the test case details displayed in the results and supplemental views. Attribute maps are created from within Preferences within the Configuration Menu.</p>
                <h3>Delete Target</h3>
                <p>Click the 'Delete this target' button to delete a target. Prior to deleting a target you should ensure the target is not in use. The target token will become invalidated and test jobs still using the target will begin to fail.</p>
                <h3>Delete Test Run</h3>
                <p>Click the 'Delete a test run' button to delte a test run from the target. Select the test run to delete from the dropdown list. Test runs do not usually need to be deleted but you may in rare cases want to delete a test run if there was a problem with a run and you do not want a specific test run to be used in calculations used for analysis.</p>
                <h3>Regenerate Target Token</h3>
                <p>Click the 'Regenerate target token' button to regenerate the target token. You may need to regenerate a target token if the previous token has been lost or compromised. It is important to keep the target token secret to avoid anyone else from uploading results data to your target. The target token is only displayed once, on generation.</p>
                <h3>API Enable/Disable</h3>
                <p>Click the 'API Enable/Disable' button to toggle enabling and disabling API for the selected target. By default API access is disabled for write actions. If Enabled, a target can updated and delete using the Tesults API.</p>
                <h4>Target Privacy Scope</h4>
                <p>By default the target privacy scope is set to private. Edit privacy scope in the case that you want to make a subset of your results public. Setting a target public alone is not sufficient to make results public, there are privacy controls at the organization, project and target levels and all three need to be set to public in order for results for a specific target to be made available at a public link.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/j-cnId4rMXU?si=WigFTK1fn4R6qXDe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <h3>Create New Target</h3>
                <p>Click the 'Create New Target' button to create a new target. Learn about <Link target="_blank" to="/docs/target">targets</Link> and how to <Link target="_blank" to="/docs/structure">structure your project and targets</Link></p>
            </div>
        );
    }
}

export default DocsTargets;