/*global Cookies*/
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Analytics from './Analytics';
import Request from './Request';

class ForgotPassword extends Component {
    constructor() {
        super();
        Analytics.event("ForgotPassword");
        const user = Cookies.getJSON("truser");
        let state = "loggedIn";
        if (user === undefined || user == null) {
            state = null;
        }
        this.state = {state: state, email: "", sent: false, error: ""};
        this.emailChange = this.emailChange.bind(this);
        this.forgot = this.forgot.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    emailChange (e) {
        this.setState({email: e.target.value});
    }

    forgot (e) {
        e.preventDefault();
        // validate here
        Request.post("/forgotpassword", {email: this.state.email}, (err, data) => {
            if (err) {
                this.setState({error: err});
            } else {
                this.setState({sent: true});
            }
        });
    }

    render () {
        if (this.state.state === "loggedIn") {
            return (
                <div style={{"maxWidth":"600px", "marginLeft":"auto", "marginRight":"auto"}} className="standardborder whitebg mt-5 p-5">
                    <p>You are logged in. View <NavLink to="/results" className="site-link">results</NavLink>.</p>
                </div>  
            );
        } else {
            if (this.state.sent === true) {
                return (
                    <div>
                        <div style={{"maxWidth":"600px", "marginLeft":"auto", "marginRight":"auto"}} className="standardborder whitebg mt-5 p-5">
                                <h3 className="">Check your email</h3>
                                <br/>
                                <p>A password reset link has been sent to your email.</p>
                            </div>
                        </div>
                );
            } else {
                return (
                    <div>
                        <form id="forgot" className="text-left neutral1 form-horizontal" onSubmit={this.forgot}>
                            <div style={{"display":"flex", "height":"100vh", "alignItems":"center"}}>
                                <div className='p-3' style={{"flex":"1", "display":"flex", "flexDirection":"column", "minWidth": "300px", "maxWidth":"600px"}}>
                                    <div className='mb-3' style={{"flex":"1"}}>
                                        <h1 className="mb-5" style={{"fontWeight":"800"}}>Forgot password</h1>
                                        <label className="form-label neutral4 font12 mb-0" htmlFor="email">Email</label>
                                        <br/>
                                        <input type="email" className="tr-input form-control" id="email" name="email" style={{"background-color" : "#F5F8FA" }} onChange={this.emailChange} required/>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <button type="submit" className="btn btn-confirm-index">Send reset email</button>
                                        <div className="accenta1" id="error">{this.state.error}</div>
                                    </div>
                                    <div style={{"flex":"1"}}>
                                        <hr className='dividor-light'/>
                                        <div className="text-center">
                                            <small>Back to <Link className="site-link-underline" to="/login">login</Link></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            }   
        }
    }
}

export default ForgotPassword;