/*global*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import Box from './Box';

class Notifications extends Component {
    constructor () {
        super();
        Analytics.event("Notifications");
    }
    render () {
        return (
            <div className='index-margin'>
                <Box
                    title={<h2>Notifications</h2>}
                    content={
                        <div>
                            <p>You have no new notifications</p>
                        </div>
                    } 
                /> 
            </div>
        );
    }
}

export default Notifications;