/*global $*/
import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Constants from './Constants';
import Analytics from './Analytics';
import Loading from './Loading';
import CaseList from './CaseList';
import Copy from './Copy';
import PassRate from './PassRate';
import Confirmation from './Confirmation';
import SelectSide from './SelectSide';
import ManualSelect from './ManualSelect';
import Cache from './Cache';
import Context from './Context';
import ManualArchive from './ManualArchive';
import Notice from './Notice'

class ManualMain extends Component {
    static contextType = Context;

    constructor (props) {
        super(props);
        Analytics.event("Manual");
        this.state = {label: this.props.defaultLabel, sort: "suite", targetIndex: this.props.targetIndex, selectedCase: null, selectedCaseRaw: null};
        this.changeLabel = this.changeLabel.bind(this);
        this.selectCase = this.selectCase.bind(this);
        this.caseShow = this.caseShow.bind(this);
        this.caseHide = this.caseHide.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.exit = this.exit.bind(this);
        this.deleteCase = this.deleteCase.bind(this);
        this.duplicateCase = this.duplicateCase.bind(this);
        this.markDone = this.markDone.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.closeCase === true) {
            this.setState({editCase: undefined});
        }
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevProps.defaultLabel !== this.props.defaultLabel) {
            this.setState({label: this.props.defaultLabel});
        }
    }

    targetChange (e) {
        this.props.targetChange(e.target.value);
    }

    changeLabel (e) {
        let label = e.target.value;
        this.props.labelChange(label);
        this.setState({label: label});
    }

    deleteCase(c) {
        this.props.deleteCase(c);
    }

    duplicateCase (c) {
        this.props.duplicateCase(c);
    }

    markDone (caseData, done) {
        this.props.markDone(caseData, done);
    }

    selectCase (c) {
        let cDetail = {};
        cDetail.suite = c.suite;
        cDetail.num = c.num;
        cDetail.name = c.name;
        cDetail.desc = c.desc;
        cDetail.reason = c.reason;
        cDetail.result = c.result.charAt(0).toUpperCase() + c.result.slice(1);
        cDetail.params = c.params;
        cDetail.hash = c.hash;
        cDetail.files = c.files;
        cDetail.steps = c.steps;
        cDetail.priority = c.priority;
        cDetail.resultOriginal = c.resultOriginal;
        cDetail.resultChangeReason = c.resultChangeReason;
        cDetail.resultChangeBy = c.resultChangeBy;
        cDetail.resultChangeTime = c.resultChangeTime;
        cDetail.custom = c.custom;
        cDetail.metadata = c.metadata;
        cDetail.bugs = c.bugs;
        cDetail.cost = c.cost;
        cDetail.batch = c.batch;
        cDetail.start = c.start;
        cDetail.end = c.end;
        cDetail.duration = c.duration;
        cDetail.importList = c.importList;
        cDetail.rawResult = c.rawResult
        cDetail.refFiles = c.refFiles
        
        // Custom fields
        Object.keys(c).forEach(function(key) {
            if (key.charAt(0) === "_") {
                if (c[key] !== "" && c[key] !== undefined && c[key] !== null) {
                    cDetail[key] = c[key];
                }
            }
        });
        // Modal
        this.setState({selectedCase: cDetail, selectedCaseRaw: c});
    }

    caseShow (cDetail) {
        $('#myModal').modal('show')
        $('#myModal').on('hidden.bs.modal', () => this.caseHide());
    }

    caseHide() {
        this.setState({selectedCase: null, selectedCaseRaw: null});
    }

    exit() {
        window.location.href = Constants.baseUrl + "/results";
    }

    refresh () {
        let manualRun = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
        let mspUrl = Constants.baseUrl + "/manual/msp/" + manualRun.id + "/" + manualRun.created;
        if (window.location === mspUrl) {
            window.location.reload(true);
        } else {
            window.location.href = mspUrl;
        }
    }

    render () {
        let selectSide = 
            <div style={{"display":"flex"}}>
                <div style={{ "marginLeft":"auto"}}>
                    <SelectSide
                        side={this.props.side}
                        sideOpen={this.props.sideOpen}
                        content={this.props.sideContent}
                        image={<img style={{"marginTop":"4px"}} src="/img/chevron.svg" width="24" height="24" alt=""/>}
                        label={<button className="btn-select">Run options</button>}
                        /*onClick={this.props.multiEdit}*/
                        /*onClose={this.props.multiEdit}*/
                    />
                </div>
            </div>

        if (this.props.loading === true) {
            return <Loading/>
        } else if (this.props.loggedIn === false) {
            return (
                <div>
                    <br/>
                    <p><NavLink to="/login" className="nounderline neutral4">Login</NavLink> to access lists for test case management and manual test results submission.</p>
                    <p><NavLink to="/" className="nounderline neutral4">Return to Tesults.com</NavLink></p>
                </div>
                );
        } else if (this.props.role < 2) {
            return <p>Ask your Tesults project administrator to change your role to Moderator (Level 2) or above to access manual test results submission.</p>
        } else if (this.props.state === "runs") {
            
            let message = <div className="neutral3 mt-3 mb-4">No test runs in progress for this project.</div>
            if (this.props.manualRunsInProgress.length > 0) {
                message = <span></span>;
            }

            let runOptions = [];
            for (let i = 0; i < this.props.manualRunsInProgress.length; i++) {
                let run = this.props.manualRunsInProgress[i];
                runOptions.push(
                    <ManualSelect
                        key={i}
                        run={run}
                    />
                );
            }
            runOptions.push(<Link to="/manual/create-run" className="nounderline"><div key={"create new run"}><button key={'createnew'} className="btn-confirm mt-3">Create new test run</button></div></Link>)
            return (
                <div>
                    <div style={{"display":"flex"}} className="mb-5">
                        <div style={{"flex":"9"}}>
                            {message}
                            {runOptions}
                        </div>
                        <div style={{"flex": "3"}} className="text-right neutral4">
                            {selectSide}
                        </div>
                    </div>
                    <div class>
                        <hr className="neutral4bg"/>
                        <Link to="/manual/archive" className="neutral4 font14 nounderline">View archived test runs</Link>
                        <br/>
                        <br/>
                        <Notice type="information" content={<span className='neutral2 font15'>Handle manual testing with runs. Use <NavLink to="/lists" className="tr-link-primary4 no-break bold">lists</NavLink> to create and store test cases. When ready to test a release, create a run, and import test cases from lists.</span>}/>
                    </div>
                </div>
            );
        } else if (this.props.state === "view" || this.props.state === "edit") {
            if (this.props.manualRunsInProgressIndex >= this.props.manualRunsInProgress.length) {
                return (
                    <div>
                        {selectSide}
                        <div className="neutral3 mt-3 mb-4">No test runs in progress for this project.</div>
                    </div>
                );
            } else if (this.props.manualCasesInProgress.length === 0) {
                let manual = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                return (
                    <div>
                        {selectSide}
                        <h4 className="neutral3">{manual.label}</h4>
                        <p>Add cases to this test run from the side menu manually or by importing them from a test list.</p>
                    </div>
                )
            }
            
            let manual = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
            let manualCasesInProgress = this.props.manualCasesInProgress;

            let assigneeCases = manualCasesInProgress;
            let caseListMessage = <span></span>
            if (this.props.assignee !== "all") {
                assigneeCases = [];
                let assignee = this.props.assignee;
                manualCasesInProgress.forEach(function (c) {
                    if (c.manualAssignee === assignee) {
                        assigneeCases.push(c);
                    } else if ((c.manualAssignee === undefined || c.manualAssignee === "unassigned") && this.props.assignee === "unassigned") {
                        assigneeCases.push(c);
                    }
                }.bind(this));
                if (assigneeCases.length === 0) {
                    caseListMessage = <span className="neutral3 font14">No test cases assigned. Click the 'Assign' button to assign cases or select 'All' from the dropdown list to show all cases for this test run.</span>
                }
            }
            
            if (manualCasesInProgress.length > 0) {
                let suiteSort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("suite")}>Suite</span>
                let nameSort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("name")}>Name</span>
                let resultSort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("result")}>Result</span>
                let prioritySort =  <span className="tr-link-gray" onClick={() => this.props.onSortChange("priority")}>Priority</span>
                
                let msp = <span></span>
                if (this.props.manualRunsInProgressIndex < this.props.manualRunsInProgress.length) {
                    let mrp = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                    msp = <span className="mt-5"><small>{"Run link: " + Constants.baseUrl + "/manual/msp/" + mrp.id + "/" + mrp.created}</small> <Copy text={Constants.baseUrl + "/manual/msp/" + mrp.id + "/" + mrp.created}/></span>
                }

                return (
                    <div>
                        {selectSide}
                        <CaseList 
                            history={this.props.history}
                            updatedCaseHash={this.props.updatedCaseHash}
                            context={"manual"}
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            message={caseListMessage}
                            sort={this.props.sort}
                            projects={this.props.projects} 
                            projectIndex={this.props.projectIndex} 
                            targets={this.props.targets}
                            targetIndex={this.props.targetIndex}
                            run={this.props.run}
                            runIndex={this.props.runIndex}
                            cases={assigneeCases}
                            builds={this.props.builds}
                            role={this.props.role}
                            addCaseToSuite={this.props.addCaseToSuite}
                            editCase={this.props.editCaseFunc} 
                            deleteCase={this.deleteCase} 
                            duplicateCase={this.duplicateCase}
                            manualRunsInProgress={this.props.manualRunsInProgress} 
                            manualRunsInProgressIndex={this.props.manualRunsInProgressIndex}
                            markDone={this.markDone}
                            multiselectClearRequest={this.props.multiselectClearRequest}
                            multiselectUpdate={this.props.multiselectUpdate}
                            multiselectEnabled={this.props.multiEdit}
                            members={this.props.members}
                            manual={manual}
                            savingCases={this.props.savingCases}
                            setEditSuite={this.props.setEditSuite}
                            mspCaseNum={this.props.mspCaseNum}
                            collapseAll={this.props.collapseAll}
                            search={this.props.search}
                            />
                        <br/>
                        {msp}
                    </div>
                );
            } else {
                return (
                    <div>
                        {selectSide}
                        <h4 className="neutral3">{manual.label}</h4>
                        <p>Add cases to this test run from the side menu manually or by importing them from a test list.</p>
                    </div>
                );
            }
        } else if (this.props.state === "create") {
            let runLifespanMessage = <p className="neutral4 font14">In progress runs are saved for 30 days on a free project. This means you can get started, leave and pick up where you left off for up to 30 days before needing to submit results. Upgrade this project to save test runs permanently and unlock archiving features.</p>
            let projects = this.context.projects;
            if (projects !== undefined) {
                let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
                if (projectIndex === undefined) {
                    projectIndex = 0
                }
                if (projectIndex < projects.length) {
                    let project = projects[projectIndex]
                    if (project.plan.name !== "free-v1") {
                        runLifespanMessage = <span></span>
                    }
                }
            }
            return (
                <div>
                    {selectSide}
                    <h4 className="neutral3">Test run name</h4> 
                    <p className="neutral4 font14">Name the test run. By default it is set to the current time. You may prefer something more memorable like a release version.</p>
                    {runLifespanMessage}
                    <input type="text" className="tr-input" value={this.state.label} onChange={this.changeLabel} required/>
                    <Confirmation confirmation={this.props.confirmation}/>
                    <p className="accenta1" id="error">{this.props.error}</p>
                    <button type="button" className="btn-confirm mr-3 mb-3" onClick={() => this.props.createNewConfirm()}>Confirm</button>
                    <button type="button" className="btn-cancel" onClick={this.props.backToRuns}>Back to test runs</button>
                </div>
            );
        } else if (this.props.state === "editLabel") {
            let projectName = "";
            if (this.props.projectIndex < this.props.projects.length) {
                projectName = this.props.projects[this.props.projectIndex].name;
            }
            return (
                <div>
                    {selectSide}
                    <label className="neutral4 font14">Label name</label>
                    <br/>
                    <input type="text" className="tr-input" value={this.state.label} onChange={this.changeLabel} required/>
                    <br/>
                    <span className="neutral4 font14">Project: {projectName}</span>
                    <br/>
                    <p className="accenta1" id="error">{this.props.error}</p>
                </div>
            );
        } else if (this.props.state === "import") {
            if (this.props.listIndex >= this.props.lists.length) {
                return (
                    <div>
                        {selectSide}
                        <p>No test lists for this project.</p>
                    </div>
                );
            } 
            
            let list = this.props.lists[this.props.listIndex];
            let group = <span></span>
            if (list.group !== undefined) {
                group = "";
                list.group.forEach(function (g) {
                    group += " > " + g;
                });
                group = <span className="font14 neutral3">{group}<br/></span>
            }

            if (this.props.listCases.length === 0) { 
                return (
                    <div>
                        {selectSide}
                        {group}
                        <h4 className="neutral4">{list.label}</h4>
                        <p>Add cases to this test list manually or by importing them from a target or CSV file.</p>
                    </div>
                );
            }
            
            if (this.props.listCases.length > 0) {
                let list = this.props.lists[this.props.listIndex];
                let msp = <span></span>
                if (this.props.manualRunsInProgressIndex < this.props.manualRunsInProgress.length) {
                    let mrp = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
                    msp = <span className="mt-5"><small>{"Run link: " + Constants.baseUrl + "/manual/msp/" + mrp.id + "/" + mrp.created}</small> <Copy text={Constants.baseUrl + "/manual/msp/" + mrp.id + "/" + mrp.created}/></span>
                }
                return (
                    <div>
                        {selectSide}
                        {group}
                        <h4 className="neutral4">{list.label}</h4>
                        <CaseList 
                            context={"import"}
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            sort={this.props.sort}
                            projects={this.props.projects} 
                            projectIndex={this.props.projectIndex} 
                            targets={this.props.targets}
                            targetIndex={this.props.targetIndex}
                            run={this.props.run}
                            runIndex={this.props.runIndex}
                            cases={this.props.listCases}
                            builds={this.props.builds}
                            role={this.props.role}
                            editCase={this.props.editCaseFunc} 
                            deleteCase={this.deleteCase} 
                            duplicateCase={this.duplicateCase}
                            manualRunsInProgress={this.props.manualRunsInProgress} 
                            manualRunsInProgressIndex={this.props.manualRunsInProgressIndex}
                            markDone={this.markDone}
                            multiselectClearRequest={this.props.multiselectClearRequest}
                            multiselectUpdate={this.props.multiselectUpdate}
                            multiselectEnabled={this.props.multiEdit}
                            savingCases={this.props.savingCases}
                            setEditSuite={this.props.setEditSuite}
                            members={this.props.members}
                            />
                        <br/>
                        {msp}
                    </div>
                );
            } else {
                let list = this.props.lists[this.props.listIndex];
                return (
                    <div>
                        {selectSide}
                        {group}
                        <h4 className="neutral4">{list.label}</h4>
                        <p>Add cases to this test list manually or by importing them from a target or CSV file.</p>
                    </div>
                );
            }
        } else if (this.props.state === "duplicate-run") {
            let run = this.props.manualRunsInProgress[this.props.manualRunsInProgressIndex];
            if (run === undefined) {
                return <div>...</div>
            }
            const doneLink = "/manual/msp/" + run.id + "/" + run.created;
            return (
                <div>
                    {selectSide}
                    <div>
                        <h5>Name the new duplicate test run</h5>
                        <input type="text" className="mb-3 tr-input form-control" value={this.props.label} onChange={(e) => this.changeLabel(e)} required/>
                        <p className="accenta1" id="error">{this.props.error}</p>
                    </div>
                    <div>
                        <button type="button" className="btn btn-confirm mt-3 mr-3" onClick={() => this.props.duplicateRunConfirm()}>Confirm</button>
                        <Link to={doneLink}><button type="button" className="btn btn-cancel mt-3">Cancel</button></Link>
                    </div>
                </div>
            );
        } else if (this.props.state === "submit" || this.props.state === "startSubmit") {
            if (this.props.submitted === true) {
                return (
                    <div>
                        {selectSide}
                        <p className="font14">Results have been submitted.</p>
                    </div>
                );
            } else {
                let targetOptions = [];
                let index = 0;
                this.props.targets.forEach(function (target) {
                    targetOptions.push(<option key={index} value={index}>{target.name}</option>);
                    index += 1;
                });
                return (
                    <div>
                        {selectSide}
                        <h4 className="neutral3">Submit results</h4>
                        <p className="font14">Select a target to upload results to</p>
                        <select className="custom-select mt-3 mb-3 width50" onChange={this.targetChange} value={this.props.targetIndex}>
                            {targetOptions}
                        </select>
                        <br/>
                        <div className="accenta1" id="error">{this.props.error}</div>
                    </div>
                );
            }
        } else if (this.props.state === "confirmArchive") {
            if (this.props.archived === true) {
                return (
                    <div>
                        {selectSide}
                        <p className="font14">Archived successfully.</p>
                    </div>
                );
            } else {
                return (
                    <div>
                        {selectSide}
                        <h4 className="neutral3">Archive test run</h4>
                        <p className="font14">Please ensure you are ready to archive this test run because this process cannot be undone.</p>
                        <br/>
                        <div className="accenta1" id="error">{this.props.error}</div>
                    </div>
                );
            }
        } else if (this.props.state === "archive") {
            return (
                <div>
                    <div style={{"display":"flex"}} className="mb-5">
                        <div style={{"flex": "1"}} className="text-right neutral4">
                            {selectSide}
                        </div>
                    </div>
                    <div>
                        <ManualArchive
                            history={this.props.history}
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            members={this.props.members}
                        />
                    </div>
                </div>
            );
        } else {    
            return <p>Invalid state</p>
        }
    }
};

export default ManualMain;