/*global */
import React, { Component } from 'react';
import Box from './Box';

class DocsAPIPurpose extends Component {
    render() {
        return (
        <div>
            <Box
                bg="neutral8bg neutral3 mb-3"
                title={<span></span>}
                content={<p>If you are just getting started with Tesults and have not submitted test results data to Tesults yet and cannot see any results data in the results view, read the <a href="/docs" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">integration</a> documentation instead. Once that has been completed, you can use the Tesults API to retrieve results data to make decisions within your continuous integration and deployment pipelines.</p>}
            />
        </div>
        );
    }
}

export default DocsAPIPurpose;