/*global*/
import React, { Component } from 'react';
import Request from './Request';
import Confirmation from './Confirmation';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';
import { Line } from 'react-chartjs'
import Box from './Box';

class EngagementAdmin extends Component {
    constructor () {
        super();
        this.state = {
            users:[], 
            projects: [],
            usersHistorical: undefined,
            collapseProjects: false, 
            collapseUsers: false, 
            collapseActiveUsers: false, 
            collapseSummaries: false,
            confirmation: {success: undefined, failure: undefined},
            excludeUser: "c03b908f-85f0-4517-a3a8-2172d4d3888b"
        };
        this.users = this.users.bind(this);
        this.collapseUsers = this.collapseUsers.bind(this);
        this.collapseActiveUsers = this.collapseActiveUsers.bind(this);
        this.projects = this.projects.bind(this);
        this.collapseProjects = this.collapseProjects.bind(this);
        this.collapseSummaries = this.collapseSummaries.bind(this);
        this.usersHistorical = this.usersHistorical.bind(this)
        this.usersHistoricalLineChartData = this.usersHistoricalLineChartData.bind(this)
    }

    componentDidMount() {
        this.users()
        this.usersHistorical()
        this.projects()
    }

    users () {
        Request.get('/users-admin', undefined, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}});
            } else {
                this.setState({confirmation: {success: undefined, failure: undefined}, users: data.users});
            }
        });
    }

    collapseUsers () {
        this.setState({collapseUsers: this.state.collapseUsers ? false : true})
    }

    collapseActiveUsers () {
        this.setState({collapseActiveUsers: this.state.collapseActiveUsers ? false : true})
    }

    projects () {
        Request.get('/projects-admin', undefined, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}});
            } else {
                this.setState({confirmation: {success: undefined, failure: undefined}, projects: data.projects});
            }
        });
    }

    collapseProjects () {
        this.setState({collapseProjects: this.state.collapseProjects ? false : true})
    }

    collapseSummaries () {
        this.setState({collapseSummaries: this.state.collapseSummaries ? false : true})
    }

    
    usersHistorical () {
        Request.get('/users-historical-admin', undefined, (err, data) => {
            if (err) {
                this.setState({usersHistorical: undefined})
            } else {
                this.setState({usersHistorical: data.data}, this.usersHistoricalLineChartData)
            }
        })
    }

    usersHistoricalLineChartData () {
        const options = {
            scaleOverride : true,
            scaleSteps : 10,
            scaleStepWidth : 50, // Change to increase y-axis scale
            scaleStartValue : 0,
            responsive: true,
            tooltipFillColor: "hsl(209, 93%, 24%)",
            tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value + '%' %>"
        };

        if (this.state.usersHistorical === undefined) {
            return {
                data: { labels: [], datasets: []},
                options: options
            }
        }

        let dataLabels = []
        let mauValues = []
        let wauValues = []
        let dauValues = []
        let c_mauValues = []
        let c_wauValues = []
        let c_dauValues = []

        for (let i = 0; i < this.state.usersHistorical.length; i++) {
            let data = this.state.usersHistorical[i]
            dataLabels.push(TimeFormatted.chartLabelFormatted(data.created));
            mauValues.push(data.mau);
            wauValues.push(data.wau);
            dauValues.push(data.dau);
            data.c_mau === undefined ? c_mauValues.push(0) : c_mauValues.push(data.c_mau)
            data.c_wau === undefined ? c_wauValues.push(0) : c_wauValues.push(data.c_wau)
            data.c_dau === undefined ? c_dauValues.push(0) : c_dauValues.push(data.c_dau)
        }

        dataLabels.reverse();
        mauValues.reverse();
        wauValues.reverse();
        dauValues.reverse();
        c_mauValues.reverse();
        c_wauValues.reverse();
        c_dauValues.reverse();

        const data = {
            labels: dataLabels,
            datasets: [
                {
                    label: "MAU",
                    backgroundColor: "hsl(120, 70%, 31%)",
                    fillColor: "hsl(120, 70%, 31%)",
                    strokeColor: "hsl(120, 70%, 31%)",
                    pointColor: "hsl(120, 70%, 31%)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: mauValues
                },
                {
                    label: "C_MAU",
                    backgroundColor: "hsl(120, 69%, 54%)",
                    fillColor: "hsl(120, 69%, 54%)",
                    strokeColor: "hsl(120, 69%, 54%)",
                    pointColor: "hsl(120, 69%, 54%)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: c_mauValues
                }/*,
                {
                    label: "WAU",
                    backgroundColor: "hsl(35, 97%, 40%)",
                    fillColor: "hsl(35, 97%, 40%)",
                    strokeColor: "hsl(35, 97%, 40%)",
                    pointColor: "hsl(35, 97%, 40%)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: wauValues
                },
                {
                    label: "C_WAU",
                    backgroundColor: "hsl(40, 87%, 49%)",
                    fillColor: "hsl(40, 87%, 49%)",
                    strokeColor: "hsl(40, 87%, 49%)",
                    pointColor: "hsl(40, 87%, 49%)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: c_wauValues
                },
                {
                    label: "DAU",
                    backgroundColor: "hsl(344, 100%, 39%)",
                    fillColor: "hsl(344, 100%, 39%)",
                    strokeColor: "hsl(344, 100%, 39%)",
                    pointColor: "hsl(344, 100%, 39%)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: dauValues
                },
                {
                    label: "C_DAU",
                    backgroundColor: "hsla(348, 100%, 61%)",
                    fillColor: "hsl(348, 100%, 61%)",
                    strokeColor:"hsl(348, 100%, 61%)",
                    pointColor:"hsl(348, 100%, 61%)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: c_dauValues
                }*/
            ]
        };

         return {
            data: data,
            options: options
        }
    }

    render () {
        let data = {
            headers: [
                {value: "Id", minWidth: "100px"},
                {value: "FirstN", minWidth: "100px"},
                {value: "LastN", minWidth: "100px"},
                {value: "Email", minWidth: "100px"},
                {value: "Last"},
            ],
            rows: []
        }
        let data_active = {
            headers: [
                {value: "Id", minWidth: "100px"},
                {value: "FirstN", minWidth: "100px"},
                {value: "LastN", minWidth: "100px"},
                {value: "Email", minWidth: "100px"},
                {value: "Last"},
            ],
            rows: []
        }
        let data_projects = {
            headers:[
                //{value: "Id", minWidth: "330px"},
                {value: "Name", minWidth: "80px"},
                {value: "Active", minWidth: "15px"},
                {value: "Minimum", minWidth: "15px"},
                {value: "Total", minWidth: "25px"},
                {value: "Files", minWidth: "25px"},
                {value: "Data" , minWidth: "20px"},
                {value: "Data Reset" , minWidth: "20px"},
                {value: "Last User", minWidth: "80px"},
                {value: "Last", minWidth: "100px"},
                //{value: "Created"},
                {value: "Plan"},
            ],
            rows: []
        }
        let activeStartTime = Date.now() - (86400000 * 30);
        let mauStartTime = Date.now() - (86400000 * 30);
        let dauStartTime = Date.now() - (86400000 * 1);
        let wauStartTime = Date.now() - (86400000 * 7);
        let mau = 0;
        let dau = 0;
        let wau = 0;
        let c_mau = 0
        let c_dau = 0
        let c_wau = 0
        let c_min_mau = 0
        let c_billed_mau = 0
        let c_projects = {}
        let c_users = {}
        let userLastSeenHash = {}
        for (let i = 0; i < this.state.projects.length; i++) {
            let project = this.state.projects[i]
            if (project.creator !== this.state.excludeUser) {
                if (project.plan.name === "standard-v1" || project.plan.name === "plus-v1"
                    || project.plan.name === "standard-20240719" || project.plan.name === "plus-20240719"
                ) {
                    c_projects[project.id] = true
                    if (project.members !== undefined) {
                        for (let j = 0; j < project.members.length; j++) {
                            let userId = project.members[j].user
                            c_users[userId] = true
                        }
                    }    
                }
            }
        }

        let users = this.state.users
        users.forEach((user) => {
            if (user.lastSeen === undefined) {
                user.lastSeen = 0
            }
            userLastSeenHash[user.id] = {lastSeen: user.lastSeen, email: user.email}
        })
        users.sort((a, b) => {
            return b.lastSeen - a.lastSeen
        })

        if (users.length !== 0) {
            for (let i = 0; i < users.length; i++) {
                let user = users[i];
                data.rows.push(
                    {
                        cols: [
                            {value: user.id},
                            {value: user.firstName},
                            {value: user.lastName},
                            {value: user.email},
                            {value: <TimeFormatted dt={user.lastSeen}/>}
                        ]
                    }
                );
                if (user.lastSeen !== undefined) {
                    if (user.id !== this.state.excludeUser) {
                        if (user.lastSeen >= mauStartTime) {
                            mau += 1
                            if (c_users[user.id] === true) {
                                c_mau += 1
                            }
                        }
                        if (user.lastSeen >= wauStartTime) {
                            wau += 1
                            if (c_users[user.id] === true) {
                                c_wau += 1
                            }
                        }
                        if (user.lastSeen >= dauStartTime) {
                            dau += 1
                            if (c_users[user.id] === true) {
                                c_dau += 1
                            }
                        }
                        if (user.lastSeen >= activeStartTime) {
                            data_active.rows.push(
                                {
                                    cols: [
                                        {value: user.id},
                                        {value: user.firstName},
                                        {value: user.lastName},
                                        {value: user.email},
                                        {value: <TimeFormatted dt={user.lastSeen}/>}
                                    ]
                                }
                            );
                        }
                    }
                    
                }
            }
        }
        let projects = this.state.projects
        for (let i = 0; i < projects.length; i++) {
            let project = projects[i]
            if (project.members !== undefined) {
                let lastSeen = undefined
                let email = undefined
                for (let j = 0; j < project.members.length; j++) {
                    let member = project.members[j]
                    let newLastSeenObj = userLastSeenHash[member.user]
                    if (newLastSeenObj !== undefined) {
                        let newLastSeen = newLastSeenObj.lastSeen
                        if (newLastSeen === undefined) {
                            newLastSeen = 0
                            email = newLastSeenObj.email
                        }
                        if (lastSeen === undefined) {
                            lastSeen = newLastSeenObj.lastSeen
                            email = newLastSeenObj.email
                        } else {
                            if (newLastSeenObj.lastSeen > lastSeen) {
                                lastSeen = newLastSeenObj.lastSeen
                                email = newLastSeenObj.email
                            }
                        }
                    }
                }
                if (lastSeen === undefined) {
                    lastSeen = 0
                    email = ""
                }
                project.lastSeen = lastSeen
                project.lastUserEmail = email
            } else {
                project.lastSeen = 0
                project.lastUserEmail = ""
            }
        }

        projects.sort((a,b) => {
            return b.lastSeen - a.lastSeen
        })

        if (projects.length !== 0) {
            for (let i = 0; i < projects.length; i++) {
                let project = projects[i]
                if (project.creator !== this.state.excludeUser) {
                    let filesCapacity = "--%";
                    let dataCapacity = "--%";
                    let dataReset = "--";
                    if (project.storage !== undefined) {
                        try {
                            filesCapacity = ((project.storage.files / project.plan.files) * 100).toFixed(2) + "%";
                            dataCapacity = ((project.storage.size / project.plan.data) * 100).toFixed(2) + "%";
                            dataReset = <TimeFormatted dt={project.storage.ttl * 1000}/>;
                        } catch (err) {
                            // Ignore
                        }
                    }
                    data_projects.rows.push(
                        {
                            cols: [
                                //{value: project.id},
                                {value: project.name},
                                {value: project.plan.activeUsers},
                                {value: project.plan.minUsers},
                                {value: project.members.length},
                                {value: filesCapacity},
                                {value: dataCapacity},
                                {value: dataReset},
                                {value: project.lastUserEmail},
                                {value: <TimeFormatted dt={project.lastSeen}/>},
                                //{value: <TimeFormatted dt={project.created}/>},
                                {value: project.plan.name}
                            ]
                        }
                    )
                    if (project.plan.name === "standard-v1" || project.plan.name === "plus-v1"
                        || project.plan.name === "standard-20240719" || project.plan.name === "plus-20240719"
                    ) {
                        c_min_mau += project.plan.minUsers
                        if (project.plan.minUsers > project.plan.activeUsers) {
                            c_billed_mau += project.plan.minUsers
                        } else {
                            c_billed_mau += project.plan.activeUsers
                        }
                    }
                }
            }
        }

        let dau_mau_ratio = "--"
        let dau_wau_ratio = "--"
        if (mau !== 0) {
            dau_mau_ratio = ((dau / mau) * 100).toFixed(1) + "%"
        }
        if (wau !== 0) {
            dau_wau_ratio = ((dau / wau) * 100).toFixed(1) + "%"
        }

        let c_dau_mau_ratio = "--"
        let c_dau_wau_ratio = "--"
        if (c_mau !== 0) {
            c_dau_mau_ratio = ((c_dau / c_mau) * 100).toFixed(1) + "%"
        }
        if (c_wau !== 0) {
            c_dau_wau_ratio = ((c_dau / c_wau) * 100).toFixed(1) + "%"
        }

        let highlight = {
            field: "plan",
            color: {
                "free-v1" : "accentc7bg",
                "standard-v1": "accentb7bg",
                "standard-20240719": "accentb7bg",
                "plus-v1" : "accenta7bg",
                "plus-20240719" : "accenta7bg"
            }
        }

        const usersHistoricalLineChartData = this.usersHistoricalLineChartData()
        
        return (
            <div className="mb-4 width100">
                <h4 className="neutral3">User Engagement - Summary</h4>
                <button className="select" onClick={this.collapseSummaries}>
                    {this.state.collapseSummaries === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                </button>
                {this.state.collapseSummaries ? <div>...</div> :
                <div>
                    <div className="config-flex">
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>MAU</h5>}
                                content={<div><div className="font24">{mau}</div><div className="neutral4 font15">DAU/MAU: {dau_mau_ratio}</div></div>}
                            />
                        </div>
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>WAU</h5>}
                                content={<div><div className="font24">{wau}</div><div className="neutral4 font15">DAU/WAU: {dau_wau_ratio}</div></div>}
                            />
                        </div>
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>DAU</h5>}
                                content={<div className="font24">{dau}</div>}
                            />
                        </div>
                    </div>
                    <hr className='dividor-light'/>
                    <div className="config-flex">
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>C_MAU</h5>}
                                content={<div><div className="font24">{c_mau}</div><div className="neutral4 font15">C_DAU/MAU: {c_dau_mau_ratio}</div></div>}
                            />
                        </div>
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>C_WAU</h5>}
                                content={<div><div className="font24">{c_wau}</div><div className="neutral4 font15">C_DAU/WAU: {c_dau_wau_ratio}</div></div>}
                            />
                        </div>
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>C_DAU</h5>}
                                content={<div className="font24">{c_dau}</div>}
                            />
                        </div>
                    </div>
                    <hr className='dividor-light'/>
                    <div className="config-flex">
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>C_MIN_MAU</h5>}
                                content={<div><div className="font24">{c_min_mau}</div><div className="neutral4 font15"></div></div>}
                            />
                        </div>
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5>C_BILLED_MAU</h5>}
                                content={<div><div className="font24">{c_billed_mau}</div><div className="neutral4 font15"></div></div>}
                            />
                        </div>
                        <div style={{"flex":"1"}}>
                            <Box
                                title={<h5></h5>}
                                content={<div className="font24"></div>}
                            />
                        </div>
                    </div>
                    <hr className='dividor-light'/>
                    {
                        usersHistoricalLineChartData.data.datasets.length > 0 ?
                        <Line data={usersHistoricalLineChartData.data} options={usersHistoricalLineChartData.options} width={window.innerWidth > 1000 ? 900 : window.innerWidth - 40} height="400"/>
                        :
                        <span></span>
                    }
                </div>
                }
                <h4 className="neutral3">Project Engagement - Active</h4>
                <button className="select" onClick={this.collapseProjects}>
                    {this.state.collapseProjects === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                </button>
                {this.state.collapseProjects ? <div>...</div> : <TabularData data={data_projects} highlight={highlight}/>}
                <h4 className="neutral3">User Engagement - Active</h4>
                <button className="select" onClick={this.collapseActiveUsers}>
                    {this.state.collapseActiveUsers === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                </button>
                {this.state.collapseActiveUsers ? <div>...</div> : <TabularData data={data_active} highlight={highlight}/>}
                <h4 className="neutral3">User Engagement - All</h4>
                <button className="select" onClick={this.collapseUsers}>
                    {this.state.collapseUsers === true ? <img src="/img/expand.svg" alt="" width="24" height="24"/> : <img src="/img/collapse.svg" alt="" width="24" height="24"/>}
                </button>
                {this.state.collapseUsers ? <div>...</div> : <TabularData data={data} highlight={highlight}/>}
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default EngagementAdmin;