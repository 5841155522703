/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsJira extends Component {
    render () {
        return (
            <div>
            <Helmet>
                <title>Tesults - Jira</title>
                <meta name="description" content="Create and update Jira issues from failing test results in Tesults. Have Tesults automatically transition the status of an issue based on new test results."/>
            </Helmet>
            <div className="whitebg mt-3" style={{"display":"flex"}}>
                <div style={{"flex": "1"}} className="mr-5">
                    <p>If your team uses both Jira and Tesults consider integrating Jira with your Tesults project.</p>
                </div>
                <div style={{"flex": "1", "minWidth":"120px", "maxWidth":"200px"}}>
                    <img src="/img/jira.png" width="95" height="40" alt="jira"/>
                </div>
            </div>
            <h2 className="mt-5 ">What does integrating Jira with Tesults do?</h2>
            <p className="mb-3">Integrate Jira with your Tesults project to create a Jira issue with a click of a button. The Jira issue is automatically populated with details from the test case. Tesults can also automatically update a Jira issue status based on new test results.</p>
            <h2 className="mt-5 ">How do I integrate Jira?</h2>
            <p>Create a project on Tesults. After a project has been created, go to the <NavLink to="/config" target="_blank" className="tr-link-primary4 no-break">configuration menu</NavLink> and select <NavLink to="/config/project/jira" target="_blank" className="tr-link-primary4 no-break">Jira</NavLink>. Click the <b>Integrate Jira</b> button to authorize Tesults to create and update Jira issues from failing test cases and even automatically update status of Jira issues based on test results.</p>
            <img src="https://www.tesults.com/files/docs/jira1.png" alt="" style={{"minWidth":"380px", "maxWidth":"600px"}}/>
            <h2 className="mt-5 ">Usage</h2>
            <p className="mb-3">After completing the presented authorization flow, Jira is integrated with your Tesults project. Open a test case and you will see that you now have the option to create a Jira issue.</p>
            <img src="https://www.tesults.com/files/docs/jira2.png" alt="" style={{"minWidth":"380px", "maxWidth":"600px"}}/>
            <p className="mb-3">Select a Jira project, issue type and priority for the issue. The new issue is then created in Jira with details from the test case. The Jira issue will be associated with your test case in Tesults automatically. You can now optionally choose to set a transition state for the issue. Do this to have Tesults automatically set the issue to the transition state you select when the test case passes. By default no transition is set.</p>
            <img src="https://www.tesults.com/files/docs/jira3.png" alt="" style={{"minWidth":"380px", "maxWidth":"600px"}}/>
            <p className="mb-3">That's it! Make it easy to track issues from failing test cases in Tesults with Jira integration. Have Tesults automatically transition issues based on new test results.</p>
            <img src="https://www.tesults.com/files/docs/jira4.png" alt="" style={{"minWidth":"380px", "maxWidth":"600px"}}/>
            <br/>
            <br/>
            <iframe className='youtube' src="https://www.youtube.com/embed/e-29p1NkD9c?si=9ZiDLq40aMF6jzmh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        );
    }
}

export default DocsJira;

