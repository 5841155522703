/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ContactMain from './ContactMain'
import ContactSide from './ContactSide'


class Contact extends Component {
    render () {
        window.scrollTo(0, 0);
        return (
            <div>
                <Helmet>
                    <title>Tesults - Contact</title>
                    <meta name="description" content="Contact the Tesults team."/>
                </Helmet>
                <div>
                    <ContactMain/>
                    <ContactSide/>
                </div>
            </div>
        );
    }
};

export default Contact;