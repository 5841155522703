/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Cache from './Cache'
import Context from './Context'
import Constants from './Constants'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'
import SelectOverlay from './SelectOverlay'

class ConfigReportingCaseDisplayAttributeMap extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {edit: false, maps: [], selectedMap: undefined, esk: undefined, confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.edit = this.edit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.cancel = this.cancel.bind(this)
        this.save = this.save.bind(this)
        this.maps = this.maps.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.scope === "target") {
            if (this.props.target !== undefined && prevProps.target !== undefined) {
                if (this.props.target.created !== prevProps.target.created) {
                    this.init()
                    this.setState({confirmation: {success: undefined, failure: undefined}})
                }
            }
        }
    }

    init () {
        this.maps()
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    maps () {
        let id = undefined
        if (this.props.scope === "target") {
            if (this.props.target === undefined ) { return }
            id = this.props.target.id
        }
        if (this.props.scope === "project") {
            let project = this.project()
            if (project === undefined) { return }
            id = project.id
        }
        this.setState({loading: true})
        Request.get("/preferences-reporting-case-display-attribute-maps", {id: id}, (err, data) => {
            if (err) {
                this.setState({loading: false, confirmation: {success: undefined, failure: "Error fetching data"}});
            } else {
                let maps = []
                if (data.maps !== undefined) {
                    maps = data.maps
                }
                let selectedMap = undefined
                let matchingMap = undefined
                if (this.props.scope === "target") {
                    matchingMap = this.props.target.preferencesReportingCaseDisplayAttributeMap
                }
                if (this.props.scope === "project") {
                    let project = this.project()
                    matchingMap = project.preferencesReportingCaseDisplayAttributeMap
                }
                if (matchingMap !== undefined) {
                    if (maps) {
                        for (let i = 0; i < maps.length; i++) {
                            let map = maps[i]
                            if (map.created === matchingMap) {
                                selectedMap = map
                            }
                        }
                    }
                }
                this.setState({maps: maps, selectedMap: selectedMap, esk: data.lek, loading: false});
            }
        });
    }

    edit () {
        this.setState({edit: true})
    }

    onChange (value) {
        if (this.state.maps === undefined) { return }
        let selectedMap = undefined
        for (let i = 0; i < this.state.maps.length; i++) {
            let map = this.state.maps[i]
            if (map.created === value) {
                selectedMap = map
            }
        }
        this.setState({selectedMap: selectedMap})
    }

    cancel () {
        this.setState({edit: false})
    }

    save () {
        this.setState({confirmation: {success: undefined, failure: undefined}});
        if (this.props.scope === "target") {
            let data = {
                id: this.props.target.id,
                created: this.props.target.created,
                reportingCaseDisplayAttributeMapCreated: this.state.selectedMap ? this.state.selectedMap.created : -1
            };
            Request.post("/targetedit", data, (err, data) => {
                if (err) {
                    this.setState({confirmation: {success: undefined, failure: "Unable to edit target"}});
                } else {
                    Analytics.event("EditedTargetReportingCaseDisplayAttributeMap");
                    this.setState({edit: false, confirmation: {success: "Target results map updated", failure: undefined}}, () => this.props.targetUpdated(data.target));
                }
            });
        }
        if (this.props.scope === "project") {
            let project = this.project()
            let data = {
                id: project.id,
                reportingCaseDisplayAttributeMapCreated: this.state.selectedMap ? this.state.selectedMap.created : -1
            };
            Request.post("/projectPreferencesReportingCaseDisplayAttributeMap", data, (err, data) => {
                if (err) {
                    this.setState({confirmation: {success: undefined, failure: "Unable to update test case attribute map for project"}});
                } else {
                    Analytics.event("EditedProjectReportingCaseDisplayAttributeMap");
                    this.setState({edit: false, confirmation: {success: "Project test case attribute map updated", failure: undefined}}, () => this.props.projectUpdated(data.project));
                }
            });
        }
    }
    
    render () {
        if (this.state.maps === undefined) {
            return <div></div>
        }
        if (this.props.target === undefined && this.props.scope === "target") {
            return <div></div>
        }
        if (this.props.project === undefined && this.props.scope === "project") {
            return <div></div>
        }
        let mapOptions = [{label: "- None -", created: undefined}]
        for (let i = 0; i < this.state.maps.length; i++) {
            let map = this.state.maps[i]
            let option = {
                label: map.label,
                value: map.created,
                img: undefined
            }
            mapOptions.push(option)
        }

        return (
            <div>
                <div className="mb-1">
                    {
                        this.props.scope === "project" ?
                        <label className="neutral4 font14">Project test case display attribute map</label>
                        :
                        <label className="neutral4 font14">Target test case display attribute map</label>
                    }
                </div>
                <div className='inputButtons'>
                    <div className="mb-4 mr-3 flex-1">
                        {
                            this.state.edit ?
                            <SelectOverlay
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                type="generic"
                                title={this.state.selectedMap ? this.state.selectedMap.label : "None"}
                                label="label"
                                value="value"
                                options={mapOptions}
                                valueChange={this.onChange}
                                defaultValue={this.state.selectedMap ? this.state.selectedMap.created : -1}
                                moreOptions={this.state.esk ? true: false}
                                getMoreOptions={this.maps}
                            />
                            :
                            <div>{this.state.selectedMap ? this.state.selectedMap.label : "None"}</div>
                        }
                        
                    </div>
                    <div className='flex-1'>
                        {
                            this.state.edit ?
                            <ConfirmationButtons 
                                confirm={this.save}
                                cancel={this.cancel}
                                confirmLabel="Update"
                                cancelLabel="Cancel"
                            />
                            :
                            <button className='btn-cancel' onClick={this.edit}>Edit</button>
                        }
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigReportingCaseDisplayAttributeMap