/*global */
import React, { Component } from 'react';

class FieldEdit extends Component {
    constructor () {
        super()
        this.state = {value: undefined, error: undefined}
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount () {
        this.setState({value: this.props.defaultValue})
    }

    onChange (e) {
        this.setState({value: e.target.value})
    }

    render() {
       return (
            <div className='p-4'>
                <div className="font15 bold mb-1">{this.props.title}</div>
                <input type="text" className="mb-3 tr-input form-control" value={this.state.value} onChange={(e) => this.onChange(e)} required/>
                <div style={{"display":"flex"}}>
                    <button className="btn-confirm mr-3" onClick={() => this.props.save(this.state.value)}>Confirm</button>
                    <button className="btn-cancel" onClick={() => {this.props.cancel()}}>Cancel</button>
                </div>
            </div>
       )
    }
}

export default FieldEdit