/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsPaymentDetails extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Payment Details Configuration Guide</title>
                    <meta name="description" content="Learn how to configure Tesults payment details."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Payment Details.</p>
                <p>When you select or upgrade to a paid plan such as Standard and Plus, as part of the project creation or upgrade flow you enter your payment card details or in some cases select to pay by invoice. Debit and credit card details may need to be changed ocassionally. The Payment Details panel is the place to do this.</p>
                <p>Basic information about your current payment card details is displayed upon entering. If these details are still correct you do not need to do anything but if the card details need to to be changed you can enter new card details under the 'Change to a new payment card' section.</p>
                <p>The information to enter is the same as you would have entered on creating a paid project or on upgrade and includes card number, name, company name, address, and country.</p>
                <p>Once card details have been entered click the 'Submit New Payment Details' button and your card details will be updated.</p>
            </div>
        );
    }
}

export default DocsPaymentDetails;