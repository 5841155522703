/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsSlack extends Component {
    render () {
        return (
            <div>
            <Helmet>
                <title>Tesults - Slack</title>
                <meta name="description" content="Send test results notifications to Slack from Tesults"/>
            </Helmet>
            <div className="whitebg mt-3">
            <p>If your team uses both Slack and Tesults consider adding Tesults results notification messages to your Slack channels.</p>
            <h2 className="mt-5 ">What does adding Tesults to Slack do?</h2>
            <p className="mb-3">Like Tesults email notifications, Slack notification messages contain summary details of the latest test results and build status. The messages also contain links to detailed results pages, the supplemental view and overall project status.</p>
            <img src="/img/slack-image-edited.png" className="width100 neutral1border" alt=""/>
            <p className="mt-3">If your team uses Slack throughout the day you can now be notified of new results within your channel and go directly to detailed Tesults pages by following the button links in the message.</p>
            <h2 className="mt-5 ">How do I add Tesults to Slack?</h2>
            <p>Create at least one project on Tesults. Read the <NavLink to="/docs" className="nounderline neutral1">documentation</NavLink> for detailed steps outlining how this is done.</p>
            <p>Then from inside the configure project menu, choose <b>Slack</b> and click the <b>Add to Slack</b> button to authorize Tesults to post messages to your Slack channels. You are able to configure which of your Slack channels you want to receive results notifications for.</p>
            <p>Tesults will post messages only when new results are generated and available.</p>
            <iframe className='youtube' src="https://www.youtube.com/embed/e-29p1NkD9c?si=9ZiDLq40aMF6jzmh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            </div>
        );
    }
}

export default DocsSlack;

