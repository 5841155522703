/*global */
import React, { Component } from 'react'
import SiteFooter from './SiteFooter'

class AuthSide extends Component {
    render () {
        return (
            <div style={{"display":"flex", "flex-direction":"column", "justifyContent": "center", "height":"100vh"}}>
                <div style={{"height":"40px", "display":"flex", "alignItems":"center"}}>
                    <a href="/" className="tr-link-neutral1">
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"marginTop":"4px"}}>
                                <div>
                                    <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                                </div>
                            </div>
                            <div className="neutral2">
                                <div>
                                    <span className="bolder font16 primary4">&nbsp; Tesults</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div style={{"flex": "1", "display":"flex", "alignItems":"center"}}>
                    <img style={{"marginTop":"-100px"}} src="https://www.tesults.com/files/art/2021-05-24/tesults-index-art.png" className='auth-image' alt=""/>
                </div>
            </div>
        )
    }
}

export default AuthSide