/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Cache from './Cache'
import Context from './Context'
import Constants from './Constants'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class ConfigTargetImage extends Component {
    static contextType = Context

    constructor () {
        super()
        this.state = {edit: false, removed: false, file: undefined, error: "", message: "", submitting: false, confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.change = this.change.bind(this)
        this.remove = this.remove.bind(this)
        this.save = this.save.bind(this)
        this.fileSelect = this.fileSelect.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    init () {
        
    }

    change () {
        this.setState({edit: true});
    }

    cancel () {
        this.setState({edit: false})
    }

    remove () {
        let aext = this.state.project.aext;
        if (aext === undefined) {
            return;
        }
        this.setState({submitting: true, confirmation: {success: "Updating - please wait", failure: undefined}});
        Request.post("/projectAvatarDelete", {id: this.state.project.id, aext: aext}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to remove project image"}, submitting: false});
            } else {
                Analytics.event("ProjectAvatarDeleted");
                this.setState({removed: true, submitting: false, confirmation: {success: "Deleted successfully", failure: undefined}}, () => this.state.projectUpdated(data.project));
            }
        });
    }

    save () {
        if (this.state.file === undefined) {
            this.setState({error: "Select an image for your target picture."});
        } else {
            this.setState({submitting: true, message: "Updating - please wait"});
            var xmlHttpRequest = new XMLHttpRequest();
            xmlHttpRequest.open("POST", "/target/avatar/" + this.props.target.id + "/" + this.props.target.created, true);
            xmlHttpRequest.setRequestHeader("Content-Type", this.state.file.type);
            xmlHttpRequest.onreadystatechange = function() {
                if(xmlHttpRequest.readyState === XMLHttpRequest.DONE) {
                    if (xmlHttpRequest.status === 200) {
                        Analytics.event("TargetAvatarEdited");
                        this.setState({edit: false, confirmation: {success: "Target image has been updated", failure: undefined}, submitting: false});
                    } else {
                        this.setState({confirmation: {success: undefined, failure: "Unable to update target image"}, message:"", submitting: false});
                    }
                }
            }.bind(this)
            xmlHttpRequest.send(this.state.file);
        }
    }

    fileSelect (e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onload = (e) => {
            let ext = undefined;
            if (file.type === "image/png") {
                ext = "png";
            }
            if (file.type === "image/jpg") {
                ext = "jpg";
            }
            if (file.type === "image/jpeg") {
                ext = "jpg";
            }
            if (ext === undefined) {
                this.setState({confirmation: {success: undefined, failure: "Your target image must be a jpg or png file"}});
            } else {
                this.setState({file: file, fileDisplay: e.target.result, ext: ext, confirmation: {success: undefined, failure: undefined}});
            }
        };

        reader.readAsDataURL(file)
    }

    render () {
        let image = <img src="/img/target-default-128.png" className="img64" alt=""/>
        const target = this.props.target;
        if (this.state.file !== undefined) {
            let src = this.state.fileDisplay;
            image = <img src={src} className="img64" alt=""/>
        } else if (target !== undefined && target.aext !== undefined) {
            let src = '/target/avatar/pdla/' + target.id + '/' + target.created + '/' + target.aext;
            image = <img src={src} className="img64" alt=""/>
        } 

        let choosefile = <div></div>
        let buttonText = "Edit";
        let action = this.change;
        if (this.state.edit === true) {
            choosefile = <input type="file" id="input" className="btn-transparent mt-3" onChange={this.fileSelect}/>
            buttonText = "Confirm";
            action = this.save;
        }

        return (
            <div>
                <label className="neutral4 mb-0 mt-1 small">Target image</label>
                <div style={{"display":"flex"}}>
                    <div style={{"width":"140px"}} className='flex-1'>
                        <div>
                            {image}
                        </div>
                        <div>
                            {choosefile}
                        </div>
                    </div>
                    <div className='flex-1'>
                        <ConfirmationButtons
                            confirm={action}
                            cancel={this.cancel}
                            confirmLabel={buttonText}
                            cancelLabel="Cancel"
                            confirmDisabled={this.state.submitting}
                            hideCancel={this.state.edit === false ? true : false}
                            secondary={this.state.edit === false ? true : false}
                        />
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        );
    }
};

export default ConfigTargetImage