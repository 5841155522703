/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'
import Context from './Context'
import Cache from './Cache'

class Privacy extends Component {
    static contextType = Context

    constructor () {
        super()
        this.state = {title: "", pub: false, pubSaved: false, scopeDisplay: "", project: undefined, org: undefined}
        this.getOrg = this.getOrg.bind(this)
        this.process = this.process.bind(this)
        this.toggle = this.toggle.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount () {
        this.getOrg()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.target !== undefined && prevProps.target !== undefined) {
            if (this.props.target.created !== prevProps.target.created) {
                this.process()
            }
        }
    }
    
    getOrg () {
        let project = undefined
        try {
            project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        } catch (err) {
            return
        }
        this.setState({loading: true});
        Request.get("/org", {org: project.org}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                if (this.state.futureState !== undefined) {
                    if (this.state.futureFunctionCall !== undefined) {
                        this.setState({org: data.org, project: project, futureState: undefined, loading: false}, () => this.process());
                    } else {
                        this.setState({org: data.org, project: project, state: this.state.futureState, futureState: undefined, loading: false}, () => this.process());
                    }
                } else {
                    this.setState({org: data.org, project: project, loading: false}, () => this.process());
                }
            }
        });
    }

    process () {
        if (this.props.scope === "org") {
            if (this.state.org !== undefined) {
                this.setState({title: "Organization scope privacy for " + this.state.org.nameDisplay, pub: (this.state.org.public === undefined) ? false : this.state.org.public, pubSaved: (this.state.org.public === undefined) ? false : this.state.org.public, scopeDisplay: "organization", analyticsScope: "Org"});
            }
        } else if (this.props.scope === "project") {
            if (this.state.project !== undefined) {
                this.setState({title: "Project scope privacy for " + this.state.project.name, pub: (this.state.project.public === undefined) ? false : this.state.project.public, pubSaved: (this.state.project.public === undefined) ? false : this.state.project.public, scopeDisplay: "project", analyticsScope: "Project"});
            }
        } else if (this.props.scope === "target") {
            if (this.props.target !== undefined) {
                this.setState({title: "Target scope privacy for " + this.props.target.name, pub: (this.props.target.public === undefined) ? false : this.props.target.public, pubSaved: (this.props.target.public === undefined) ? false : this.props.target.public, scopeDisplay: "target", analyticsScope: "Target"});
            }
        } else {
            if (this.state.project !== undefined) {
                this.setState({title: "Invalid scope"});
            }
        }
    }

    toggle () {
        let pub = this.state.pub;
        if (pub === true) {
            Analytics.event(this.state.analyticsScope + "PrivacyTogglePrivate");
            this.setState({pub: false});
        } else {
            Analytics.event(this.state.analyticsScope + "PrivacyTogglePublic");
            this.setState({pub: true});
        }
    }    

    save () {
        let params = {pub: this.state.pub, scope: this.props.scope};
        if (this.props.scope === "org") {
            params.org = this.state.org.id;
        } else if (this.props.scope === "project") {
            params.project = this.state.project.id;
        } else if (this.props.scope === "target") {
            params.project = this.props.target.id;
            params.target = this.props.target.created;
        }
        this.setState({loading: true});
        Request.post("/privacy", params, (err, data) => {
            if (err) {
                Analytics.event(this.state.analyticsScope + "PrivacySaveError" + ((this.state.pub === true) ? "Public" : "Private"));
                this.setState({loading: false, error: "Unable to update privacy setting."});
            } else {
                Analytics.event(this.state.analyticsScope + "PrivacySave" + ((this.state.pub === true) ? "Public" : "Private"));
                let pubSaved = this.state.pub === true ? true : false
                if (this.props.scope === "org")  {
                    this.setState({loading: false, message: "Privacy setting updated.", pubSaved: pubSaved});
                } else if (this.props.scope === "project") {
                    this.setState({loading: false, message: "Privacy setting updated.", pubSaved: pubSaved}, () => this.props.projectUpdated(data.project));
                } else if (this.props.scope === "target") {
                    this.setState({loading: false, message: "Privacy setting updated.", pubSaved: pubSaved}, () => this.props.targetUpdated(data.target));
                } else {
                    this.setState({loading: false, message: "Privacy setting updated.", pubSaved: pubSaved});
                }
            }
        });
    }

    render () {
        if (this.state.org === undefined || this.state.project === undefined) {
            return <div></div>
        }

        let pubSaved = this.state.pubSaved;
        if (pubSaved === false) {
            pubSaved = "private";
        } else {
            pubSaved = 'public';
        }

        if (this.props.scope === "org" && this.state.org.name === "-") {
            return (<div className='font14'>
                    <p>You must provide a name for your organization before being able to set privacy. Set the organization name and then refresh this page.</p>
                </div>);
        }

        let publicUrl = "https://www.tesults.com/" + this.state.org.name;
        let message = "Privacy settings are scoped to the organization level, the project level and target level. Making the organization public alone will not allow your results to be viewed publicly. The project and targets you want to be public must also be set to public.";
        if (this.props.scope === "project" || this.props.scope === "target") {
            publicUrl += "/" + this.state.project.name;
            message = "Privacy settings are scoped to the organization level, the project level and target level. Making the project public alone will not allow your results to be viewed publicly. The organization and targets you want to be public must also be set to public."
        }
        if (this.props.scope === "target") {
            if (this.props.target !== undefined) {
                publicUrl += "/" + this.props.target.name;
                message = "Privacy settings are scoped to the organization level, the project level and target level. Making the target public alone will not allow your results to be viewed publicly. The organization and project must also be set to public."
            }
        }

        return (
            <div style={{"maxWidth": "600px"}}>
                <details>
                    <summary className='font15'>{this.state.title}: <b className='primary4 pointer'>{pubSaved}</b></summary>
                    <div className='font15'>
                        <div className='mt-3'>
                            Only you, as the owner of this {this.state.scopeDisplay}, can change this privacy setting.
                        </div>
                        <div className='mt-4' style={{"display":"flex"}}>
                            <div>
                                <label className="autoRefreshSwitch">
                                    <input type="checkbox" onChange={this.toggle} checked={this.state.pub}/>
                                    <span className="autoRefreshSlider round"></span>
                                </label>
                            </div>
                            <div>
                                <label className="neutral4 mt-2">{this.state.pub === false ? "Check to make public" : "Uncheck to make private"}</label>
                            </div>
                        </div>
                        <div>
                            <small><span className="accentc4">{this.state.message}</span></small>
                        </div>
                        <div>
                            <small><span className="accenta4">{this.state.error}</span></small>
                        </div>
                        <p>The public URL will be: {publicUrl}</p>
                        <p>{message}</p>
                        <p>Results accessed via the public URL cannot be edited by the viewer.</p>
                        <p><b>Important</b>, there are serious implications of making a {this.state.scopeDisplay} public. Anyone with the URL to this resource will be able to access results and test case data publically, even without being a member of your team or even being logged into Tesults. If there are any doubts about whether the test results and test case data should remain private we strongly recommend you do not make this {this.state.scopeDisplay} public.</p>
                        <ConfirmationButtons
                            confirm={() => {this.save()}}
                            cancel={this.props.cancel}
                            confirmLabel="Save"
                            cancelLabel="Back"
                        />
                    </div>
                </details>
            </div>
        );
    }
}

export default Privacy