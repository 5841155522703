/*global */
import React, { Component } from 'react';

class SiteFooter extends Component {
    render() {
        let bg = "neutral8bg";
        if (this.props.bgcolor !== undefined) {
            bg = this.props.bgcolor;
        }
        let siteFooterClass = "site-footer";
        
        //if (this.props.path !== undefined) {
            //if (this.props.path === "/" || this.props.path.startsWith("/ref/")) {
                //siteFooterClass = "index-margin site-footer-index";
            //}
        //}
        if (this.props.mobileMenu === true) {
            siteFooterClass = "index-margin site-footer-index";
        }

        return (
            <div>
            {this.props.mobileMenu === true ? <span></span> :<hr className="dividor-light"/>}
            <div className={bg + " " + siteFooterClass} style={{"display":"flex", "flexWrap":"wrap"}}>
                {
                    this.props.nofirstpillar ? <span></span>
                    :
                    <div className="mt-4 site-footer-pillar-first">
                        <div style={{"display":"flex",  "alignItems":"center"}}>
                            <div style={{"marginTop":"4px"}}>
                                <a href="/" className="site-link">
                                    <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="48" height="48"/>
                                </a>
                            </div>
                            <div className="neutral2">
                                <a href="/" className="site-link">
                                    &nbsp;<span className="bolder font16 primary4"></span>
                                </a>
                            </div>
                        </div>
                        <div style={{"marginTop":"auto"}}>
                            
                        </div>
                    </div>
                }
                <div className="site-footer-pillar">
                    <div>
                        <h4 className="neutral3">Product</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/getting-started">Getting Started</a></span> 
                        <br/>
                        <span><a className="font14 site-link" href="/docs/results">Results</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/supplemental">Supplemental</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/dashboard">Dashboard</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/diff">Diff</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/lists">Lists</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/manual">Runs</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/tasks">Tasks</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/release-checklists">Release Checklists</a></span>
                        <br/>
                    </div>
                    <div>
                        <h4 className="neutral3">Integrations</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/github-actions">Github Actions</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/slack">Slack</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/msteams">MS Teams</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/mattermost">Mattermost</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/pagerduty">PagerDuty</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/jira">Jira</a></span>
                        <br/>
                    </div>
                </div>
                <div  className="site-footer-pillar">
                <div>
                        <h4 className="neutral3">Test Frameworks</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/exp">EXP</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/junit5">JUnit 5</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/junit4">JUnit 4</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/testng">TestNG</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/pytest">pytest</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/robot">Robot Framework</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/nunit3">NUnit 3</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/xunit">xUnit</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/vsunittf">MSTest</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/rspec">RSpec</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/mocha">Mocha</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/jasmine">Jasmine</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/jest">Jest</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/playwright">Playwright</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/wdio">WebdriverIO</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/codeceptjs">CodeceptJS</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/nightwatch">Nightwatch</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/waffle">Waffle</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/postman">Postman</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/protractor">Protractor</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/cypress">Cypress</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/testcafe">TestCafe</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/xctest">XCTest</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/espresso">Espresso</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/selenium">Selenium</a></span>
                        <br/>
                    </div>
                </div>
                <div className="site-footer-pillar">
                <div>
                        <h4 className="neutral3">Languages</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/java">Java</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/csharp">C#</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/python">Python</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/ruby">Ruby</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/javascript">JavaScript</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/kotlin">Kotlin</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/swift">Swift</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/go">Go (Golang)</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/php">PHP</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/others">Others</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/resultsapi">REST</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/junit-xml">JUnit XML</a></span>
                        <br/>
                    </div>
                    
                    <div>
                        <h4 className="neutral3">Tesults API</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/api/overview">Overview</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/api/auth">Authentication</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/api/targets">Targets</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/api/results">Results</a></span>
                        <br/>
                    </div>
                    <div>
                        <h4 className="neutral3">Test Framework Devs</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/test-framework-developers">Test framework devs</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/tesults-json-data-standard">Tesults JSON data standard</a></span>
                        <br/>
                    </div>
                </div>
                <div className="site-footer-pillar">
                    
                    <div>
                        <h4 className="neutral3">Configuration</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/structure">How to structure your project</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/target">What are targets?</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/project">Project</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/targets">Targets</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/target-order">Target Order</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/build-consolidation">Build Consolidation</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/result-interpretation">Result Interpretation</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/preferences">Preferences</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/notifications">Notifications</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/integrations">Integrations</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/api-token">API Token</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/team-members">Team Members</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/preferences">Preferences</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/sso">Single Sign On</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/organization">Organization</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/audit-logs">Audit Logs</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/plan">Plan</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/payment-details">Payment Details</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/account-country">Account Country</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/invoices">Invoices</a></span>
                        <br/>
                    </div>
                </div>
                <div  className="site-footer-pillar">
                    <div>
                        <h4 className="neutral3">Company</h4>
                    </div>
                    <div>
                        <a className="font14 site-link" href="/contact">Contact</a>
                    </div>
                    <div>
                        <a className="font14 site-link" href="/about">About</a>
                    </div>
                    <div>
                        <a className="font14 site-link" href="/careers">Careers</a>
                    </div>
                    <div>
                        <a className="font14 site-link" href="/blog">Blog</a>
                    </div>
                    {/*<div>
                        <a className="font14 site-link" href="/featurerequest">Roadmap</a>
                    </div>*/}
                    <div>
                        <a className="font14 site-link" href="/featurerequest">Feature Request</a>
                    </div>
                    <div>
                        <h4 className="neutral3">Policy</h4>
                    </div>
                    <div>
                        <span><a className="font14 site-link" href="/docs/support-policy">Support</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/pricing">Pricing</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/fair-billing-policy">Fair Billing</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/refund-policy">Cancel & Refund</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/privacy">Privacy</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/security">Security</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/terms">Terms of Service</a></span>
                        <br/>
                        <span><a className="font14 site-link" href="/docs/vendor-onboarding">Vendor Onboarding</a></span>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="mt-5 mb-3 site-footer-copyright">
                <span className="font12 neutral4">&#169; {(new Date().getFullYear())} Tesults Ltd</span>
                {/*<span className='font12 neutral4 ml-5'>1.73</span>*/}
            </div>
            </div>
        );
    }
}

export default SiteFooter;