/*global */
import React, { Component } from 'react';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class AffiliateDashboardRef extends Component {
    constructor() {
        super();
        this.state = {refs: [], esk: undefined};
        this.arefs = this.arefs.bind(this);
    }

    componentDidMount () {
        this.arefs();
    }

    arefs () {
        if (this.props.id === undefined) {
            return;
        }
        Request.get("/affiliate-refs", {id: this.props.id, esk: this.state.esk}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error fetching affiliate data"}});
            } else {
                let refs = this.state.refs;
                for (let i = 0; i < data.refs.length; i++) {
                    refs.push(data.refs[i]);
                }
                this.setState({refs: refs, esk: data.esk});
            }
        });
    }

    render () {
        if (this.state.refs === undefined) {
            return <div></div>
        } else {
            let data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Time", minWidth: "200px"}
                ],
                rows: []
            }
            for (let i = 0; i < this.state.refs.length; i++) {
                let ref = this.state.refs[i];
                data.rows.push(
                    {
                        cols: [
                            {value: ref.id},
                            {value: TimeFormatted.timeformatted(ref.time, true)}
                        ]
                    }
                );
            }
            if (data.rows.length === 0) {
                return (
                    <div>
                        <h4 className="neutral3">Sign ups</h4>
                        <p>No sign ups yet</p>
                    </div>
                ); 
            } else {
                let oldestRef = this.state.refs[this.state.refs.length - 1];
                let oldestRefTime = TimeFormatted.timeformatted(oldestRef.time, true);
                return (
                    <div>
                        <h4 className="neutral3">Sign ups</h4>
                        <div style={{"display":"flex"}}>
                            <div className="mr-3">
                                <span className="font14 neutral4">Since {oldestRefTime}</span>
                            </div>
                            <div>
                                {
                                    this.state.esk === undefined ?
                                    <span></span>
                                    :
                                    <button className="btn-cancel" onClick={this.arefs}>Load more</button>
                                }
                            </div>
                        </div>
                        <TabularData data={data}/>
                    </div>
                );
            }
        }
    }
};

export default AffiliateDashboardRef;