/*global */
import React, { Component } from 'react';
import ManualAssigneeSelect from './ManualAssigneeSelect';
import SelectOverlay from './SelectOverlay';
import Confirmation from './Confirmation';

class MultiCaseEditOptions extends Component {
    constructor() {
        super();
        this.state = {
            result: "pass", 
            priority: 2, 
            suite: "-", 
            done: true, 
            cost: {value: 0, period: 'day', currency: 'USD'}, 
            suiteSuggestions: [], 
            customNameSuggestions:[],
            targetDetailCostEdit: false
        };
        this.doneChange = this.doneChange.bind(this);
        this.resultChange = this.resultChange.bind(this);
        this.priorityChange = this.priorityChange.bind(this);
        this.suiteChange = this.suiteChange.bind(this);
        this.costValueChange = this.costValueChange.bind(this);
        this.costPeriodChange = this.costPeriodChange.bind(this);
        this.costCurrencyChange = this.costCurrencyChange.bind(this);
        this.setSuggestionLists = this.setSuggestionLists.bind(this);
    }

    componentDidMount () {
        this.setSuggestionLists();
    }

    setSuggestionLists () {
        let suiteDict = {};
        let suites = [];
        let customNameDict = {};
        let customNames = [];
        if (this.props.cases === undefined) {
            return;
        }
        this.props.cases.forEach(function (c) {
            if (c.suite !== undefined && suiteDict[c.suite] === undefined) {
                suiteDict[c.suite] = true;
                suites.push(<option key={c.suite} value={c.suite}/>)
            }

            Object.keys(c).forEach(function (key) {
                if (c.hasOwnProperty(key)) {
                    if (key.charAt(0) === "_") {
                        let customName = key.substring(1);
                        if (customNameDict[customName] === undefined) {
                            customNameDict[customName] = true;
                            customNames.push(<option key={customName} value={customName}/>)
                        }
                    }
                }
            });
        });
        this.setState({suiteSuggestions: suites, customNameSuggestions: customNames});
    }


    doneChange (e) {
        let done = this.state.done;
        done = e.target.value;
        this.setState({done: done});
    }

    resultChange (e) {
        let result = this.state.result;
        result = e.target.value;
        this.setState({result: result});
    }

    priorityChange (e) {
        let priority = this.state.priority;
        priority = e.target.value;
        this.setState({priority: priority});
    }

    suiteChange (e) {
        this.setState({suite: e.target.value});
    }

    costValueChange (valueString) {
        let cost = this.state.cost;
        try {
            cost.value = parseFloat(valueString);
        } catch (err) {
            cost.value = 0;
        }
        this.setState({cost: cost});
    }

    costPeriodChange (e) {

    }

    costCurrencyChange (e) {
        
    }

    render () {
        let done = <span></span>
        if (this.props.context !== "results") {
            done = 
            <div>
                <label className="neutral4 mb-0 mt-1 font14">Done status</label>
                <select className="custom-select width100 elevation3 mb-2" onChange={this.doneChange} defaultValue={this.state.done}>
                    <option key={true} value={true}>Done</option>
                    <option key={false} value={false}>Not Done</option>
                </select>
                <button className="btn-confirm" onClick={() => this.props.multiEditConfirm("manualComplete", this.state.done)}>Set Done Status</button>
                <hr/>
            </div>
        }
        let result = <span></span>
        if (this.props.context !== "results") {
            result = 
            <div>
                <label className="neutral4 mb-0 mt-3 font14">Result</label>
                <select className="custom-select width100 elevation3 mb-2" onChange={this.resultChange} defaultValue={this.state.result}>
                    <option key={0} value={"pass"}>Pass</option>
                    <option key={1} value={"fail"}>Fail</option>
                    <option key={2} value={"unknown"}>Unknown</option>
                </select>
                <button className="btn-confirm" onClick={() => this.props.multiEditConfirm("result", this.state.result)}>Set Result</button>
                <hr/>
            </div>
        }
        let priority = <span></span>
        if (this.props.context !== "results") {
            priority =
            <div>
                <label className="neutral4 mb-0 font14">Priority</label>
                <select className="custom-select width100 elevation3 mb-2" onChange={this.priorityChange} defaultValue={this.state.priority}>
                    <option key={-1} value={-1}>- - Unassigned</option>
                    <option key={0} value={0}>0 - Highest</option>
                    <option key={1} value={1}>1 - High</option>
                    <option key={2} value={2}>2 - Medium</option>
                    <option key={3} value={3}>3 - Low</option>
                    <option key={4} value={4}>4 - Lowest</option>
                </select>
                <button className="btn-confirm" onClick={() => this.props.multiEditConfirm("priority", this.state.priority)}>Set Priority</button>
                <hr/>
            </div>
        }
        let suite = <span></span>
        if (this.props.context !== "results") {
            suite = 
            <div>
                <label className="neutral4 mb-0 font14">Suite</label>
                <br/>
                <input type="text" list="suites" className="tr-input-side form-control mb-2" name="suite" value={this.state.suite} onChange={this.suiteChange} autoComplete="-off-" required/>
                <button className="btn-confirm" onClick={() => this.props.multiEditConfirm("suite", this.state.suite)}>Set Suite</button>
                <hr/>
            </div>
        }
        let cost = <span></span>
        if (this.props.context === "results") {
            if (this.state.targetDetailCostEdit) {
                cost = 
                <div>
                    <div className="mt-4 mb-1 neutral2 font14">Cost value</div>
                    <div className="mb-4">
                        <input className="side-input clickable" type="number" min={0} step={0.01} value={this.state.cost.value} onChange={(e) => this.costValueChange(e.target.value)} placeholder="0"/>
                    </div>
                    <div className="mb-4">
                        <div>
                            <SelectOverlay 
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                type="cost"
                                title="Cost currency" 
                                label="currency" 
                                value="value"
                                options={[{currency: "USD", value: "USD"}]} 
                                index={0}
                                image={<img src="/img/sort.svg" width="14" height="11" alt=""/>}
                                valueChange={this.costCurrencyChange} 
                                defaultValue={this.state.cost.currency}
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <div>
                            <SelectOverlay 
                                overlay={this.props.overlay} 
                                messageOverlay={this.props.messageOverlay}
                                type="cost"
                                title="Cost period" 
                                label="key" 
                                value="value"
                                options={[{key: "Day", value: "day"}]} 
                                index={0}
                                image={<img src="/img/sort.svg" width="14" height="11" alt=""/>}
                                valueChange={this.costPeriodChange} 
                                defaultValue={this.state.cost.period}
                            />
                        </div>
                    </div>
                    <div>
                        <Confirmation confirmation={this.props.confirmation}/>
                    </div>
                    <div>
                        <button type="button" className="btn btn-select mt-3 mr-3" onClick={() => this.props.multiEditConfirm("cost", this.state.cost)}>Set Cost</button>
                        <button type="button" className="btn btn-cancel mt-2" onClick={() => {this.props.multiEdit(); this.setState({targetDetailCostEdit: false});}}>Cancel</button>
                    </div>
                </div>
            } else {
                if (this.props.trl) { cost = <div></div>}
                else {
                    cost =
                    <div>
                        <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.multiEdit(); this.setState({targetDetailCostEdit: true});}}>Set Cost</button>
                    </div>
                }
            }
        }
        let assign = <span></span>
        if (this.props.context === "manual") {
            assign = 
            <div>
                <label className="neutral4 mb-0 font14">Assign cases to team member <img className="ml-2" src="/img/user-group.svg" alt="expanded" width="16" height="16"/></label>
                <div className="mt-1 mb-2">
                    <ManualAssigneeSelect all={false} members={this.props.members} assignSelectedAssignee={this.props.assignSelectedAssignee} assigneeChange={this.props.assignSelectedAssigneeChange} assignee={this.props.assignee}/>
                </div>
                <button className="btn-confirm" onClick={this.props.assignSelected}>Assign</button>
                <hr/>
            </div>
        }
        let deleteCases = <span></span>
        if (this.props.context !== "results") {
            deleteCases =
            <div>
                <label className="neutral4 mb-0 font14">Delete selected cases</label>
                <br/>
                <button className="btn-confirm" onClick={() => this.props.multiEditConfirm("delete", true)}>Delete</button>
            </div>
        }
        return (
            <div>
                {
                    this.props.context === "results" ?
                    <span></span>
                    :
                    <div>
                        <div className="multi-case-editor-title">
                            <div className="multi-case-editor-title-icon">
                                { this.props.iconSrc === undefined ?
                                    <span></span>
                                    :
                                    <img alt="" src={this.props.iconSrc} width="21" height="21"/>
                                }
                            </div>
                            <div className="multi-case-editor-title-label">
                                <span className="neutral2 font15">{this.props.title}</span>
                            </div>
                        </div>
                        <hr style={{"border": "1px solid #B7BFC6", "opacity":"0.3"}}/>
                    </div>
                }
                
                <div>
                    <span className="neutral2 font14">{this.props.subtitle}</span>
                </div>
                <div>
                    {done}
                    {result}
                    {priority}
                    {suite}
                    {cost}
                    {assign}
                    {deleteCases}
                </div>
                <datalist id="suites">
                    {this.state.suiteSuggestions}
                </datalist>
                <datalist id="customNames">
                    {this.state.customNameSuggestions}
                </datalist>
            </div>
        );
    }
}

export default MultiCaseEditOptions;