/*global */
import React from 'react';
import { useParams } from "react-router-dom";
import App from './App';

// Release checklist resource locator

function RCRLHandler (props) {
    let { state, project, team, checklist, item } = useParams();
    return (
        <App state={state} project={project} team={team} checklist={checklist} item={item} match={props.match} history={props.history} location={props.location}/>
    );
};

export default RCRLHandler;