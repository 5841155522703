/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import DocsLanguage from './DocsLanguage';
import Box from './Box';
import Notice from './Notice';

class DocsJava extends Component {
    render() {
        const helmet = 
        <Helmet>
            <title>Java test reporting with Tesults</title>
            <meta name="description" content="Learn how to handle Java test reporting with Tesults."/>
        </Helmet>

        const plugins =
        <div className='mt-5 mb-5'>
            <Notice type="information" content={
            <div>
                <b>Using JUnit 5?</b> If you use the <a href="https://junit.org/junit5/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">JUnit 5 test framework</a> no code is necessary to integrate, click <NavLink to="/docs/junit5" target="_blank" className="tr-link-primary4 no-break no-break">here</NavLink> to view documentation for the <NavLink to="/docs/junit5" target="_blank" className="tr-link-primary4 no-break no-break">Tesults JUnit 5 extension</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using JUnit 4?</b> If you use the <a href="https://junit.org/junit4/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">JUnit 4 test framework</a> take a look at documentation for <NavLink to="/docs/junit4" target="_blank" className="tr-link-primary4 no-break no-break">integrating with JUnit 4</NavLink> and ignore the instructions below.
            </div>}/>
            <Notice type="information" content={
            <div>
                <b>Using TestNG?</b> If you use the <a href="https://testng.org/doc/" className="tr-link-primary4 no-break no-break" target="_blank" rel="noopener noreferrer">TestNG test framework</a> no code is necessary to integrate. Click <NavLink to="/docs/testng" target="_blank" className="tr-link-primary4 no-break no-break">here</NavLink> to view documentation for the <NavLink to="/docs/testng" target="_blank" className="tr-link-primary4 no-break no-break">Tesults TestNG Listener</NavLink> and ignore the instructions below.
            </div>}/>
        </div>
        
        const installation =
        <div>
        <p>The Tesults Java API Library is available from the JCenter and Maven Central repositories or as a JAR.</p>
        <h4 className="mt-5">Option 1: Gradle</h4>
        <p>If you are using Gradle add this dependency snippet to your build.gradle file:</p>
        <pre className="docsCode">
        dependencies {'{'}<br/>
        &nbsp;&nbsp;compile '<code className="accentb4">com.tesults:tesults:1.0.3</code>'<br/>
        {'}'}<br/>
        </pre>
        <p>Also ensure you have the JCenter or Maven Central repository referenced in your build.gradle file.</p>
        <h4 className="mt-5">Option 2: Maven</h4>
        <p>If you are using Maven add this dependency snippet to your pom.xml file:</p>
        <pre className="docsCode">
        {'<'}dependency{'>'}<br/>
            &nbsp;&nbsp;{'<'}groupId{'>'}<code className="accentb4">com.tesults</code>{'<'}/groupId{'>'}<br/>
            &nbsp;&nbsp;{'<'}artifactId{'>'}<code className="accentb4">tesults</code>{'<'}/artifactId{'>'}<br/>
            &nbsp;&nbsp;{'<'}version{'>'}<code className="accentb4">1.0.3</code>{'<'}/version{'>'}<br/>
        {'<'}/dependency{'>'}<br/>
        </pre>
        <h4 className="mt-5">Option 3: JAR</h4>
        <p>Download <a className="tr-link-primary4 no-break" href="https://www.tesults.com/tesults.jar">here</a> and reference in your project.</p>
        </div>

        const configuration =
        <div>
            <p>Use one of the three methods described above to make the Tesults Java API available as a library and then import the com.tesults.tesults package in your code:</p>
            <pre className="docsCode">
                import <code className="accentb4">com.tesults.tesults.*</code>; 
            </pre>
        </div>

        const usage =
        <div>
            <p>Upload results using the upload method in the Results class. This is the single point of contact between your code and Tesults.</p>
            <pre className="docsCode">
                <code className="accentb4">Results.upload(data);</code>
            </pre>
            <p className="mt-3">The upload method returns a Map indicating success or failure:</p>
            <pre className="docsCode">
                Map&lt;String, Object&gt; response = <code className="accentb4">Results.upload(data);</code><br/><br/>
                {'//'} Response output:<br/>
                System.out.println("success: " + response.get("success"));<br/>
                System.out.println("message: " + response.get("message"));<br/>
                System.out.println("warnings: " + ((List&lt;String&gt;) response.get("warnings")).size());<br/>
                System.out.println("errors: " + ((List&lt;String&gt;) response.get("errors")).size());<br/><br/>
            </pre>
            <p className="mt-3">The upload method returns a response of type Map&lt;String, Object&gt; that you can use to check whether the upload was a success.</p>
            <ul>
                <li>Value for key "success" is a Boolean: true if results successfully uploaded, false otherwise.</li>
                <li>Value for key "message" is a String: if success is false, check message to see why upload failed.</li>
                <li>Value for key "warnings" is a List&lt;String&gt;, if size is not zero there may be issues with file uploads.</li>
                <li>Value for key "errors" is a List&lt;String&gt;, if "success" is true then this will be empty.</li>
            </ul>
        </div>

        const example =
        <div>
            <p>The data param passed to upload is a Map&lt;String, Object&gt; containing your results data. Here is a complete example showing how to populate data with your build and test results and then complete upload to Tesults with a call to Results.upload(data):</p>
            <i><small>Complete example:</small></i>
            <br/>
            <pre className="docsCode">
                <code className="accentc6">{'//'} Required imports:</code><br/>
                import java.util.ArrayList;<br/>
                import java.util.HashMap;<br/>
                import java.util.List;<br/>
                import java.util.Map;<br/>
                import com.tesults.tesults.*;&nbsp;&nbsp;
                {'//'} Tesults Java API<br/><br/>
                
                <code className="accentc6">{'//'} A list to hold your test cases.</code><br/>
                List&lt;Map&lt;String,Object&gt;&gt; testCases = new ArrayList&lt;Map&lt;String, Object&gt;&gt;();<br/><br/>

                <code className="accentc6">{'//'} Each test case is a Map&lt;String,Object&gt;. We use generic types rather than<br/>
                {'//'} concrete helper classes so that if and when the Tesults services adds more fields<br/>
                {'//'} you do not have to update the library.<br/><br/>
                
                {'//'} You would usually add test cases in a loop taking the results from the data objects<br/>
                {'//'} in your build/test scripts. At a minimum you must add a name and result:</code><br/>
                Map&lt;String, Object&gt; testCase1 = new HashMap&lt;String, Object&gt;();<br/>
                testCase1.put("name", "Test 1");<br/>
                testCase1.put("desc", "Test 1 description");<br/>
                testCase1.put("suite", "Suite A");<br/>
                testCase1.put("result", "pass"); <code className="accentc6">{'//'} result value must be pass, fail or unknown</code><br/><br/>

                testCases.add(testCase1);<br/><br/>

                Map&lt;String, Object&gt; testCase2 = new HashMap&lt;String, Object&gt;();<br/>
                testCase2.put("name", "Test 2");<br/>
                testCase2.put("desc", "Test 2 description");<br/>
                testCase2.put("suite", "Suite B");<br/>
                testCase2.put("result", "pass");<br/><br/>
                
                <code className="accentc6">{'//'} (Optional) Add start and end time for test:</code><br/>
                <code className="accentc6">{'//'} In this example, start is offset to 60 seconds earlier</code><br/> 
                <code className="accentc6">{'//'} but it should be set to current time when the test starts</code><br/>
                
                testCase2.put("start", java.lang.System.currentTimeMillis() - 60000);<br/>
                testCase2.put("end", java.lang.System.currentTimeMillis());<br/><br/>

                <code className="accentc6">{'//'} (Optional) For a paramaterized test case:</code><br/>
                Map&lt;String, String&gt; params = new HashMap&lt;String, String&gt;();<br/>
                params.put("param1", "value1");<br/>
                params.put("param2", "value2");<br/>
                testCase2.put("params", params);<br/><br/>

                <code className="accentc6">{'//'} (Optional) Custom fields start with an underscore:</code><br/>
                testCase2.put("_CustomField", "Custom field value");<br/><br/>

                testCases.add(testCase2);<br/><br/>

                Map&lt;String, Object&gt; testCase3 = new HashMap&lt;String, Object&gt;();<br/>
                testCase3.put("name", "Test 3");<br/>
                testCase3.put("desc", "Test 3 description");<br/>
                testCase3.put("suite", "Suite A");<br/>
                testCase3.put("result", "fail");<br/>
                testCase3.put("reason", "Assert fail in line 203 of example.java.");<br/><br/>

                <code className="accentc6">{'//'} (Optional) For uploading files:</code><br/>
                List&lt;String&gt; files = new ArrayList&lt;String&gt;();<br/>
                files.add("/Path/to/file/log.txt");<br/>
                files.add("/Path/to/file/screencapture.png");<br/>
                files.add("/Path/to/file/metrics.xls");<br/>
                testCase3.put("files", files);<br/><br/>

                <code className="accentc6">{'//'} (Optional) For providing test steps for a test case:</code><br/>
                {'List<Map<String,Object>> steps = new ArrayList<Map<String, Object>>();'}<br/>
                {'Map<String, Object> step1 = new HashMap<String, Object>();'}<br/>
                step1.put("name", "Step 1");<br/>
                step1.put("desc", "Step 1 description");<br/>
                step1.put("result", "pass"); // can be pass, fail or unknown<br/>
                step1.put("reason", "Failure reason if result is a fail");<br/>
                steps.add(step1);<br/><br/>

                {'Map<String, Object> step2 = new HashMap<String, Object>();'}<br/>
                step2.put("name", "Step 2");<br/>
                step2.put("desc", "Step 2 description");<br/>
                step2.put("result", "pass"); // <code className="accentc6">pass, fail or unknown</code><br/>
                step2.put("reason", "Failure reason if result is a fail");<br/>
                steps.add(step2);<br/><br/>

                testCase3.put("steps", steps);<br/><br/>

                testCases.add(testCase3);<br/><br/>

                <code className="accentc6">{'//'} Map&lt;String, Object&gt; to hold your test results data.</code><br/>
                <code className="accentb4">Map&lt;String, Object&gt; data = new HashMap&lt;String, Object&gt;();<br/>
                data.put("target", "token");<br/><br/>

                Map&lt;String, Object&gt; results = new HashMap&lt;String, Object&gt;();<br/>
                results.put("cases", testCases);<br/>
                data.put("results", results);<br/><br/></code>

                <code className="accentc6">{'//'} Upload</code><br/>
                <code className="accentb4">Map&lt;String, Object&gt; response = Results.upload(data);</code><br/>
                System.out.println("success: " + response.get("success"));<br/>
                System.out.println("message: " + response.get("message"));<br/>
                System.out.println("warnings: " + ((List&lt;String&gt;) response.get("warnings")).size());<br/>
                System.out.println("errors: " + ((List&lt;String&gt;) response.get("errors")).size());<br/><br/>
            </pre>
            <p className="mt-3">The target value, <b>'token'</b> above should be replaced with your Tesults target token. If you have lost your token you can regenerate one from the config menu.</p>
        </div>

        return (
            <DocsLanguage
                language="java"
                helmet={helmet}
                plugins={plugins}
                installation={installation}
                configuration={configuration}
                usage={usage}
                example={example}
            />
        );
    }
}

export default DocsJava;