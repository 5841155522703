/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class RoadmapSide extends Component {
    render () {
        return (
            <div>
                <img src="/img/Illustrations_Tesults_002Artboard-1.png" width="64" height="64" alt=""/>
                <br/>
                <h3>Submit a feature request</h3>
                <p>Submit a <NavLink to="/featurerequest" className="neutral4 nounderline neutral1">feature request here</NavLink>.</p>
            </div>
        );
    }
};

export default RoadmapSide;