/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Constants from './Constants';
import UserAvatarEdit from './UserAvatarEdit';
import Analytics from './Analytics';
import TwoFactorAuthenticationEnable from './TwoFactorAuthenticationEnable';
import ConfigProfileFirstName from './ConfigProfileFirstName';
import ConfigProfileLastName from './ConfigProfileLastName';
import ConfigProfileEmail from './ConfigProfileEmail';
import Request from './Request';

class ConfigProfile extends Component {
    constructor (props) {
        super(props);
        Analytics.event("ConfigProfile");
        const user = Cookies.getJSON("truser");
        this.state = {signedIn: ((user === undefined || user === null) ? false : true)};
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    logout () {
        Request.post("/logout", undefined, (err, data) => {
            if (err) {
                console.log(err);
                // TODO: process errors
            } else {
                Analytics.event("Logout");
                window.location.href = Constants.baseUrl + "/login";
            }
        });
    }

    render () {
        if (this.state.signedIn === false) {
            return (
                <div style={{"minHeight":"100vh"}}>
                    <div><NavLink to="/login" className="tr-link-cpblue">Login</NavLink> to view profile.</div>
                </div>
            )
        } else {
            return (
                <div style={{"minHeight":"100vh"}}>
                    <ConfigProfileEmail history={this.props.history}/>
                    <ConfigProfileFirstName history={this.props.history}/>
                    <ConfigProfileLastName history={this.props.history}/>
                    <hr className='dividor-light'/>
                    <div className='pt-3 pb-3'>
                        <UserAvatarEdit history={this.props.history}/>
                    </div>
                    <hr className='dividor-light'/>
                    <div className='pt-3 pb-3'>
                        <TwoFactorAuthenticationEnable history={this.props.history}/>
                    </div>
                    <hr className='dividor-light'/>
                    <div className='pt-3 pb-3'>
                        <button type="button" className='btn-cancel' onClick={this.logout}>Logout</button>
                    </div>
                </div>
            );
        }
    }
}

export default ConfigProfile;