/*global Cookies*/
import React, { Component } from 'react'
import Analytics from './Analytics'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class ConfigProfileEmail extends Component {
    constructor () {
        super()
        this.change = this.change.bind(this)
        this.save = this.save.bind(this)
        this.resendVerification = this.resendVerification.bind(this)
        this.state = {value: "", error: "", verifying: false, confirmation: {success: undefined, failure: undefined}}
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0)
        Analytics.event("EditEmail")
        var user = Cookies.getJSON("truser")
        this.setState({value: user.email, verifying: true})
        Request.get("/verifiedEmail", undefined, (err, data) => {
            if (err) {
                this.setState({edit: 'email', verifying: false, value: user.email, confirmation: {success: undefined, failure:  "Unable to check if email verified."}})
            } else {
                this.setState({edit: 'email', verifying: false, value: user.email, verified: data.verified, confirmation: {success: undefined, failure: undefined}})
            }
        })
    }

    change (e) {
        this.setState({value: e.target.value, confirmation: {success: undefined, failure: undefined}})
    }

    resendVerification () {
        const user = Cookies.getJSON("truser")
        let data = {}
        data.email = user.email
        Request.post("/resendverification", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to resend verification email."}})
            } else {
                Analytics.event("ResentVerification")
                this.setState({confirmation: {success: "Verification email has been sent", failure: undefined}})
            }
        })
    }

    save () {
        Request.post("/userEditEmail", {email: this.state.value}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}})
            } else {
                Analytics.event("EditedEmail")
                this.setState({confirmation: {success: "Saved email", failure: undefined}})
            }
        })
    }

    cancel () {
        const user = Cookies.getJSON("truser")
        this.setState({value: user.email, verifying: true})
        Request.get("/verifiedEmail", undefined, (err, data) => {
            if (err) {
                this.setState({edit: 'email', verifying: false, value: user.email, confirmation: {success: undefined, failure:  "Unable to check if email verified."}})
            } else {
                this.setState({edit: 'email', verifying: false, value: user.email, verified: data.verified, confirmation: {success: undefined, failure: undefined}})
            }
        })
    }

    render () {
        const user = Cookies.getJSON("truser");
        let verified = <span></span>
        if (this.state.verifying === false) {
            verified = "Verified"
            if (this.state.verified !== true) {
                verified = <span>Not verified <span className="primary4 pointer" onClick={this.resendVerification}>Resend Verification Email</span></span>
            }
        }
        return (
            <div>
                <div className="mb-1">
                <label className="neutral4 font14">Email ({verified})</label>
                </div>
                <div className="inputButtons">
                    <div>
                        <input type="email" className="tr-input" value={this.state.value} onChange={this.change} required/>
                    </div>
                    <div>
                        {
                            user.email === this.state.value ? <span></span>
                            :
                            <ConfirmationButtons 
                                confirm={this.save}
                                cancel={this.cancel}
                                confirmLabel="Save"
                                cancelLabel="Cancel"
                            />
                        }
                    </div>
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        )
    }
};

export default ConfigProfileEmail