/*global */
import React, { Component } from 'react';

class Confirmation extends Component {
    render () {
        if (this.props.confirmation === undefined) {
            return (
                <div style={{"minHeight": "16px"}} className="mb-3"></div>
            );
        } else if (this.props.confirmation.success !== undefined) {
            return (
                <div style={{"minHeight": "16px", "display":"flex", "alignItems":"top"}} className="mb-3">
                    <div className="mr-2"><img src="/img/tesults-logo-pass-128.png" style={{"marginTop":"4px"}} width="16" height="16" alt="success"/></div>
                    <div><span className="font14 accentc2"> {this.props.confirmation.success}</span></div>
                </div>
            );
        } else if (this.props.confirmation.failure !== undefined) {
            return (
                <div style={{"minHeight": "16px", "display":"flex", "alignItems":"top"}} className="mb-3">
                    <div className="mr-2"><img src="/img/tesults-logo-fail-128.png" style={{"marginTop":"4px"}} width="16" height="16" alt="failure"/></div>
                    <div><span className="font14 accenta2"> {this.props.confirmation.failure}</span></div>
                </div>
            );
        } else {
            return (
                <div style={{"minHeight": "16px"}} className="mb-3"></div>
            );
        }
    }
}

export default Confirmation;