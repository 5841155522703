/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Box from './Box';

class DocsJUnitXml extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>JUnit XUnit Ant XML format test reporting with Tesults</title>
                    <meta name="description" content="Learn how to handle JUnit XML format test reporting with Tesults."/>
                </Helmet>
                <p>You may upload results data to Tesults using a JUnit format XML file.</p>
                <Box
                    bg="neutral3"
                    title={<h4>Tesults recommends using the Tesults API library for your language or test framework plugin instead</h4>}
                    content={
                        <div>
                            <p>A Tesults API library or test framework plugin provides greater control and is the only way to upload files (logs, screenshots etc.) along with your test results.</p>
                        </div>
                    }
                />
                <p className="mt-4">To upload results using an XML file utilize the /results REST API.</p>
                <h4>API</h4>
                <pre className="docsCode">
                    https://www.tesults.com/results<br/>
                    Method: 'POST'<br/>
                </pre>
                <br/>
                <p>Set the content-type header to 'text/xml' and set the target token in the 'Authorization' http header.</p>
                <h4>Headers</h4>
                <pre className="docsCode">
                "Content-Type": "text/xml"<br/>
                "Authorization": "Bearer &lt;token&gt;"
                </pre>
                <br/>
                <p>&lt;token&gt; should be replaced with the target token string provided to you on creation of your project. If you did not note down the token when you created your project you can regenerate a token for the target in the configuration menu.</p>
                <h4>Body</h4>
                <p>Submit the xml file in the request POST body.</p>
                <h4>Examples</h4>
                <p>Example using curl with XML inline as string:</p>
                <pre className="docsCode">
                $ curl -X POST -H "Content-Type: text/xml" -H "Authorization: Bearer &lt;token&gt;"<br/>
                &nbsp;&nbsp;-d '&lt;?xml version="1.0" encoding="UTF-8"?&gt; &lt;testsuites&gt;...&lt;/testsuites&gt;'<br/>
                &nbsp;&nbsp;https://www.tesults.com/results
                </pre>
                <p>Example using curl with XML file:</p>
                <pre className="docsCode">
                $ curl -X POST -H "Content-Type: text/xml" -H "Authorization: Bearer &lt;token&gt;"<br/>
                &nbsp;&nbsp;-d @/path/to/junit-results.xml https://www.tesults.com/results
                </pre>
                <p>If the the request is successful the response will contain a status code of 200 and include a JSON response as shown below.</p>
                <br/>
                <pre className="docsCode">
                    status code 200 with JSON response:<br/><br/>
                    {'{'}<br/>
                    &nbsp;&nbsp;"data": {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "code": 200,<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "message": "Success"<br/>
                    &nbsp;&nbsp;{'}'}<br/>
                    {'}'}<br/>
                </pre>
                <p>If there is an error there will be a status code other than 200 with JSON response as shown:</p>
                <br/>
                <pre className="docsCode">
                    status code other than 200, for example 400, 401, 403, 429, 500 with JSON response:<br/><br/>
                    {'{'}<br/>
                    &nbsp;&nbsp;"error": {'{'}<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "code": 400,<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp; "message": "Missing required parameters - target and results are required"<br/>
                    &nbsp;&nbsp;{'}'}<br/>
                    {'}'}<br/>
                </pre>
                <br/>
                <p>The error message will provide detail as to the specific reason for the failed request.</p>
                
                <p>Fix the error displayed in the error message and try again to get a successful response.</p>
                
                <p>Your team members will be able to view your uploaded test results immediately after a successful response. Any notificiation recipients you have configured will be notified about the availability of the new results by email.</p>
            </div> 
        );
    }
}

export default DocsJUnitXml;