/*global*/
import React, { Component } from 'react';
import Constants from './Constants';

class ResultSideMod extends Component {
    constructor() {
        super();
        this.state = {submitting: false, deletedRun: false, sentNotifications: false, error: ""};
        this.deleteRun = this.deleteRun.bind(this);
        this.deleteRunComplete = this.deleteRunComplete.bind(this);
        this.sendNotifications = this.sendNotifications.bind(this);
        this.sendNotificationsComplete = this.sendNotificationsComplete.bind(this);
        this.clearNotifications = this.clearNotifications.bind(this);
        this.refresh = this.refresh.bind(this);
    }


    deleteRun () {
        this.setState({submitting: true});
        this.props.deleteRun(this.deleteRunComplete);
    }

    deleteRunComplete (err) {
        if (err) {
            this.setState({submitting: false, error: err});
        } else {
            this.setState({submitting: false, error: "", deletedRun: true});
        }
    }

    sendNotifications () {
        this.setState({submitting: true});
        this.props.sendNotifications(this.sendNotificationsComplete);
    }
    
    sendNotificationsComplete (err) {
        if (err) { 
            this.setState({submitting: false, error: err});
        } else {
            this.setState({submitting: false, error: "", sentNotifications: true});
        }
    }

    clearNotifications () {
        this.setState({sentNotifications: false});
    }

    refresh () {
        if (window.location === Constants.baseUrl + "/results") {
            window.location.reload(true);
        } else {
            window.location.href = Constants.baseUrl + "/results";
        }
    }

    render() {
        let error = <span className="fail mt-3">{this.state.error}</span>;
        let moderatorFunctionality = <div></div>
        if (this.props.view !== "status") {
            let deleteRunContent = <button type="button" className="btn btn-select" onClick={this.deleteRun} disabled={this.state.submitting}>Delete</button>;
            let sendNotificationsContent = <button type="button" className="btn btn-select" onClick={this.sendNotifications} disabled={this.state.submitting}>Send</button>
            if (this.state.deletedRun === true) {
                deleteRunContent = <span className="neutral4">Selected test run has been deleted: <span className="tr-link-cpblue" onClick={() => this.refresh()}>refresh view</span></span>
            }
            if (this.state.sentNotifications === true) {
                sendNotificationsContent = <span className="neutral4">Notifications sent: <span className="tr-link-cpblue" onClick={this.clearNotifications}>Acknowledge</span></span>
            }
            moderatorFunctionality =
            <div style={{"width":"320px", "whiteSpace": "normal"}} className='p-5'>
                <div className="font14 bold mb-2">Delete test run</div>
                <div className="font14 mb-2">Delete currently displayed test run. Warning: this will immediately delete the run.</div>
                {deleteRunContent}
                <br/>
                <br/>
                <div className="font14 bold mb-2">Send notification</div>
                <div className="font14 mb-2">Send a manual notification for the latest test results. This is unnecessary if automatic notifications are enabled unless results have been edited.</div>
                {sendNotificationsContent}
                <br/>
                {error}
                <div className="font14 neutral4 mb-5">These options are only visible to project moderators</div>
            </div>
        }

        if (this.props.role < 2) {
            return (
                <span></span>
            );
        } else {
            return moderatorFunctionality
        }
    }
}

export default ResultSideMod;