/*global*/
import React, { Component } from 'react';
import Comment from './Comment';
import Analytics from './Analytics';
import Request from './Request';

class Comments extends Component {
    constructor () {
        super();
        this.state = {loading: false, comments: [], esk: undefined, users: [], displayAdd: true};
        this.getComments = this.getComments.bind(this);
        this.addComment = this.addComment.bind(this);
        this.editComplete = this.editComplete.bind(this);
    }

    componentDidMount () {
        this.getComments();
    }

    editComplete () {   
        this.setState({displayAdd: true});
    }

    getComments () {
        this.setState({loading: true});
        Request.get("/comments", {id: this.props.id, created: this.props.created, hash: this.props.hash, esk: this.state.esk}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to fetch comments.", loading: false});
            } else {
                let comments = this.state.comments.slice();
                let users = this.state.users.slice();
                let usersDict = {};
                users.forEach(function (user) {
                    if (usersDict[user.id] === undefined) {
                        usersDict[user.id] = user;
                    }
                });
                data.comments.forEach(function (comment) {
                    comments.push(<Comment key={comments.length} editComplete={this.editComplete} id={this.props.id} created={this.props.created} hash={this.props.hash} users={users} comment={comment} state="view"/>)
                }.bind(this));
                data.users.forEach(function (user) {
                    if (usersDict[user.id] === undefined) {
                        users.push(user);
                    }
                });
                this.setState({comments: comments, esk: data.esk, users: users, loading: false});
            }
        });
    }

    addComment () {
        Analytics.event("CommentAdd");
        let comments = this.state.comments.slice();
        comments.unshift(<Comment key={comments.length} editComplete={this.editComplete} id={this.props.id} created={this.props.created} hash={this.props.hash} users={this.state.users} state="add"/>);
        this.setState({comments: comments, displayAdd: false});
    }

    render () {
        if (this.state.loading === true) {
            return (
                <div className="mt-3 neutral4 small">Loading comments...</div>
            )
        }

        let add = <button className="btn btn-select" onClick={this.addComment}>Add new comment</button>
        if (this.state.displayAdd !== true || this.props.trl === true || this.props.view === "manual-archive" || this.props.view === "diff") {
            add = <span></span>
        }
        let loadMore = <span></span>
        if (this.state.esk !== undefined) {
            loadMore = <button className="btn btn-select" onClick={this.getComments}>Load more</button>
        }

        let noComments = <span></span>
        if (this.state.comments.length === 0) {
            noComments = <div className="mb-3">No comments.</div>
        } else {
            noComments = <div></div>
        }

        return (
            <div>
                {this.state.comments}
                {loadMore}
                {noComments}
                {add}
            </div>
        );
    }
};

export default Comments;