/*global*/
import React, { Component } from 'react';
import {utilsRawResultMapMap} from './Utils';
import Constants from './Constants';

class PassRate extends Component {
    constructor () {
        super();
        this.state = {};
        this.calculatePassesAndTotal = this.calculatePassesAndTotal.bind(this);
        this.renderTargetPassRate = this.renderTargetPassRate.bind(this);
        this.renderGroupPassRate = this.renderGroupPassRate.bind(this);
        this.renderDashboardCount = this.renderDashboardCount.bind(this);
        this.renderDiffPassRate = this.renderDiffPassRate.bind(this)
        this.renderListCasesCount = this.renderListCasesCount.bind(this)
        this.renderRunPassRate = this.renderRunPassRate.bind(this)
    }

    calculatePassesAndTotal (cases) {
        // Check if precomputed pass rate is available, this is accurate for result interpretation map modifications applied at time of save
        if (this.props.run !== undefined) {
            return {pass: this.props.run.pass, total: this.props.run.total}
        }   

        if (this.props.runs !== undefined && this.props.runIndex !== undefined) {
            if (this.props.runIndex < this.props.runs.length) {
                const run = this.props.runs[this.props.runIndex]
                return {pass: run.pass, total: run.total}
            }
        }

        // Precomputed pass not available, calculate pass rate

        let map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)
        let pass = 0;
        let total = 0;
        let incompleteTotal = 0;
        if (cases !== undefined) {
            for (let i = 0; i < cases.length; i++) {
                let c = cases[i];
                if (c.suite === "[build]") {

                } else {
                    if (this.props.context === "manual") {
                        if (c.manualComplete !== true && c.result !== undefined) {
                            incompleteTotal += 1;
                        } else if (c.result !== undefined) {
                            total += 1;
                            if (c.result.toLowerCase() === "pass" && c.manualComplete === true) {
                                pass += 1;
                            }
                        }
                    } else if (this.props.context === "release-checklist") {
                        if (c.complete !== true && c.complete !== "true") {
                            incompleteTotal += 1
                        } else {
                            pass += 1
                        }
                        total += 1
                    } else {
                        if (c.result !== undefined) {
                            total += 1;
                            if (map !== undefined && c.rawResult !== undefined) {
                                if (map[c.rawResult] === "pass") {
                                    pass += 1
                                } else {
                                    if (this.props.runMetadata) {
                                        if (this.props.runMetadata.integrationName === "playwright-tesults-reporter" && Constants.isVersionGreaterThanOrEqualTo(this.props.runMetadata.integrationVersion, "1.2.0")) {
                                            if (c["_Expected Status"] !== "passed") {
                                                if (c["_Expected Status"] === c.rawResult) {
                                                    pass += 1
                                                }   
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (c.result.toLowerCase() === "pass") {
                                    pass += 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        return {pass: pass, total: total, incompleteTotal: incompleteTotal};
    }

    renderTargetPassRate () {
        let passPercent = "--";
        let passRate = "--/--";
        let percentClass = "target-pass-percent-red";
        let changePercent = "--";
        let changeRate = "--";
        let changeClass = "";
        let pass = 0;
        let total = 0;
        let passesAndTotals = this.calculatePassesAndTotal(this.props.cases);
        pass = passesAndTotals.pass;
        total = passesAndTotals.total;
        if (total > 0) {
            passPercent = Math.floor((pass/total) * 100) + " %"
            passRate = pass + "/" + total + " passed";
            if (pass === total) {
                percentClass = "target-pass-percent-green";
            } else if (pass/total >= 0.5) {
                percentClass = "target-pass-percent-orange";
            }
        }
        if (this.props.cases !== undefined && this.props.prevCases !== undefined) {
            if (this.props.cases.length !== this.props.prevCases.length) {
                // number of test cases has changed
            } else {
                if (this.props.cases.length > 0) {
                    let prevPassesAndTotals = this.calculatePassesAndTotal(this.props.prevCases);
                    let prevPass = prevPassesAndTotals.pass;
                    if (pass === prevPass) {
                        changePercent = "";
                        changeRate = "";
                        changeClass = "neutral4";
                    } else if (pass > prevPass) {
                        let change = pass - prevPass;
                        changePercent = "+ " + Math.floor((change/this.props.cases.length) * 100) + " %";
                        changeRate = " (" + change + ")";
                        changeClass = "accentc4";
                    } else {
                        let change = prevPass - pass;
                        changePercent = "- " + Math.floor((change/this.props.cases.length) * 100) + " %";
                        changeRate = " (" + change + ")";
                        changeClass = "accenta4";
                    }
                }
            }
        }

        return (
            <div className="target-pass-rate">
                <div className="target-pass-rate-left">
                    <div className={"target-pass-percent " + percentClass}>
                        {passPercent}
                    </div>
                </div>
                <div className="target-pass-rate-right">
                    <div className="target-pass-cases">
                        {passRate} <span className={changeClass}>{changePercent} {changeRate}</span>
                    </div>
                </div>
            </div>
        );
    }

    renderGroupPassRate () {
        let pass = 0;
        let total = 0;
        let incompleteTotal = 0;
        let incomplete = <span></span>
        let percentClass = "target-pass-percent-red";
        if (this.props.cases !== undefined) {
            let passesAndTotals = this.calculatePassesAndTotal(this.props.cases);
            pass = passesAndTotals.pass;
            total = passesAndTotals.total;
            incompleteTotal = passesAndTotals.incompleteTotal;
            if (total > 0) {
                if (pass === total) {
                    percentClass = "target-pass-percent-green";
                } else if (pass/total >= 0.5) {
                    percentClass = "target-pass-percent-orange";
                }
            }
            if (this.props.context === "manual") {
                if (incompleteTotal !== undefined) {
                    if (incompleteTotal > 0) {
                        incomplete = 
                        <div className="target-pass-rate-right">
                            <div className={"incomplete-test-cases target-pass-percent-gray overflow-ellipsis-no-wrap"}>
                                {incompleteTotal} Incomplete
                            </div>
                        </div>
                    }
                }
            }
        }
        if (this.props.totalOnly === true) {
            if (this.props.percentClass !== undefined) {
                percentClass = this.props.percentClass
            }
            return (
                <div className="target-pass-rate">
                    <div className="target-pass-rate-left mr-1">
                        <div className={"target-pass-percent " + percentClass}>
                            {total}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="target-pass-rate">
                    <div className="target-pass-rate-left mr-1">
                        <div className={"target-pass-percent " + percentClass}>
                            {pass + "/" + total}
                        </div>
                    </div>
                    {incomplete}
                </div>
            );
        }
    }

    renderDashboardCount () {
        let targets = 0;
        if (this.props.targets !== undefined) {
            targets = this.props.targets.length;
        }
        return (
            <div className="target-pass-rate">
                <div className="target-pass-rate-left">
                    <div className="target-pass-percent target-pass-percent-blue">
                        {targets}
                    </div>
                </div>
                <div className="target-pass-rate-right">
                    <div className="target-pass-cases">
                        Targets
                    </div>
                </div>
            </div>
        );
    }

    renderDiffPassRate () {
        let passPercent = "--";
        let passRate = "--/--";
        let percentClass = "target-pass-percent-red";
        let changePercent = "--";
        let changeRate = "--";
        let changeClass = "";
        let pass = 0;
        let total = 0;
        let passesAndTotals = this.calculatePassesAndTotal(this.props.cases);
        pass = passesAndTotals.pass;
        total = passesAndTotals.total;
        if (total > 0) {
            passPercent = Math.floor((pass/total) * 100) + " %"
            passRate = pass + "/" + total + " passed";
            if (pass === total) {
                percentClass = "target-pass-percent-green";
            } else if (pass/total >= 0.5) {
                percentClass = "target-pass-percent-orange";
            }
        }
        return (
            <div className="target-pass-rate">
                <div className="target-pass-rate-left">
                    <div className={"target-pass-percent " + percentClass}>
                        {passPercent}
                    </div>
                </div>
                <div className="target-pass-rate-right">
                    <div className="target-pass-cases">
                        {passRate}
                    </div>
                </div>
            </div>
        );
    }

    renderListCasesCount () {
        let count = 0;
        if (this.props.cases !== undefined) {
            count = this.props.cases.length
        }
        return (
            <div className="target-pass-rate">
                <div className="target-pass-rate-left">
                    <div className="target-pass-percent target-pass-percent-blue">
                        {count}
                    </div>
                </div>
                <div className="target-pass-rate-right">
                    <div className="target-pass-cases">
                        Test cases
                    </div>
                </div>
            </div>
        );
    }

    renderRunPassRate () {
        let passPercent = "--";
        let passRate = "--/--";
        let percentClass = "target-pass-percent-red";
        let changePercent = "--";
        let changeRate = "--";
        let changeClass = "";
        let pass = undefined
        let total = undefined
        let prevPass = undefined
        let prevTotal = undefined
        if (this.props.run !== undefined) {
            pass = this.props.run.pass
            total = this.props.run.total
        }
        if (this.props.prevRun !== undefined) {
            prevPass = this.props.prevRun.pass
            prevTotal = this.props.prevRun.total
        }
        if (pass !== undefined && total !== undefined) {
            if (total > 0) {
                passPercent = Math.floor((pass/total) * 100) + " %"
                passRate = pass + "/" + total + " passed";
                if (pass === total) {
                    percentClass = "target-pass-percent-green";
                } else if (pass/total >= 0.5) {
                    percentClass = "target-pass-percent-orange";
                }
            } else {
                percentClass = "target-pass-percent-gray";
            }
        } else {
            percentClass = "target-pass-percent-gray";
        }
        if (prevPass !== undefined && prevTotal !== undefined) {
            if (total !== prevTotal) {
                // Number of test cases has changed
            } else {
                if (total > 0) {
                    if (pass === prevPass) {
                        changePercent = "";
                        changeRate = "";
                        changeClass = "neutral4";
                    } else if (pass > prevPass) {
                        let change = pass - prevPass;
                        changePercent = "+ " + Math.floor((change/total) * 100) + " %";
                        changeRate = " (" + change + ")";
                        changeClass = "accentc4";
                    } else {
                        let change = prevPass - pass;
                        changePercent = "- " + Math.floor((change/total) * 100) + " %";
                        changeRate = " (" + change + ")";
                        changeClass = "accenta4";
                    }
                }
            }
        }

        return (
            <div className="target-pass-rate">
                <div className="target-pass-rate-left">
                    <div className={"target-pass-percent " + percentClass}>
                        {passPercent}
                    </div>
                </div>
                <div className="target-pass-rate-right">
                    <div className="target-pass-cases">
                        {passRate} <span className={changeClass}>{changePercent} {changeRate}</span>
                    </div>
                </div>
            </div>
        );
    }

    render () {
        if (this.props.type === "target") {
            return this.renderTargetPassRate();
        } else if (this.props.type === "group") {
            return this.renderGroupPassRate();
        } else if (this.props.type === "dashboard") {
            return this.renderDashboardCount();
        } else if (this.props.type === "diff") {
            return this.renderDiffPassRate();
        } else if (this.props.type === "listCases") {
            return this.renderListCasesCount();
        } else if (this.props.type === "run") {
            return this.renderRunPassRate()
        }  else {
            return <span>Invalid type</span>
        }
    }
};

export default PassRate;