/*global */
import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';
import ReactJson from 'react-json-view';
import Collapse from './Collapse';

class CaseDetailField extends Component {
    constructor () {
        super();
        this.state = {collapsed: false}
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.removeAnsiEscapeCodeArray = this.removeAnsiEscapeCodeArray.bind(this)
    }

    componentDidMount() {
        this.setState({collapsed: this.props.collapseAll})
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.collapseAll !== prevProps.collapseAll) {
            this.setState({collapsed: this.props.collapseAll})
        }
    }

    toggleCollapse () {
        if (this.state.collapsed === true) {
            this.setState({collapsed: false});
        } else {
            this.setState({collapsed: true});
        }
    }

    static isJSON (value) {
        try{
            const possible = JSON.parse(value);
            if (typeof possible == "object"){
                return true
            } else {
                return false
            }
        } catch(err) {
            return false
        }
    }

    static removeAnsiEscapeCode (originalValue) {
        const regex = /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g
        let value = originalValue
        let failed = false
        try {
            value = JSON.parse(value)
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    value[i] = CaseDetailField.removeAnsiEscapeCode(JSON.stringify(value[i]))
                }
            } else if (typeof value == "object"){
                Object.keys(value).forEach((key) => {
                    try {
                        value[key] = value[key].replace(regex, '')
                    } catch (err) {
                        // Leave as original value
                    }
                })
                value = JSON.stringify(value)
            } else {
                value = CaseDetailField.removeAnsiEscapeCode(value)
            }
        } catch (err) {
            failed = true
            value = originalValue
        }
        if (failed === true) {
            try {
                value = value.replace(regex, '')
            } catch (err) {
                value = originalValue
            }
        }
        return value
    }

    removeAnsiEscapeCodeArray (arr) {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = CaseDetailField.removeAnsiEscapeCode(arr[i])
        }
        return arr
    }

    render () {
        // Valid for both this.props.mobile !== true && this.props.mobile === true with latest UX/UI

        // Remove Ansi Escape Code
        let value = this.props.value
        if (Array.isArray(value)) {
            value = this.removeAnsiEscapeCodeArray(value)
        } else {
            value = CaseDetailField.removeAnsiEscapeCode(value)
        }

        return (
            <div className="case-detail-field">
                <div className="case-detail-label">
                    <div className="case-detail-label-content">
                        <div>
                            {this.props.label}
                        </div>
                        <div className="case-detail-toggle">
                            <span className="caseLink" onClick={this.toggleCollapse}><Collapse collapse={this.state.collapsed}/></span>
                        </div>
                    </div>
                </div>
                {
                    this.state.collapsed ?
                    <div></div>
                    :
                    <div className="case-detail-value">
                        <div className="case-detail-value-content">
                            <Linkify>{CaseDetailField.isJSON(value) ? <ReactJson src={JSON.parse(value)}/> : value}</Linkify>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default CaseDetailField;