/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Constants from './Constants.js';
import InviteRegisterMain from './InviteRegisterMain'

class InviteRegister extends Component {
    render () {
        const ic = Constants.getUrlParameter("ic", this.props.location.search);
        return (
            <div>
                <Helmet>
                    <title>Tesults - Invited Sign Up</title>
                    <meta name="description" content="Invited sign up for Tesults."/>
                </Helmet>
                <div style={{"display":"flex", "alignItems":"center", "justifyContent":"center"}}>
                    <InviteRegisterMain ic={ic}/>
                </div>
            </div>  
        );
    }
};

export default InviteRegister;