/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import Loading from './Loading';
import Request from './Request';

class Slack extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {scope: "project", targetIndex: 0, error: undefined, loading: false, originalSelected: [], selectedChannels: [], channels: [], slackAdded: true, user: user, saved: true, next_cursor: undefined, search: "", clientId: undefined, clientIdDev: undefined};
        this.slackChannels = this.slackChannels.bind(this);
        this.slackSelectedChannels = this.slackSelectedChannels.bind(this);
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.changed = this.changed.bind(this);
        this.save = this.save.bind(this);
        this.scopeToggle = this.scopeToggle.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        this.setClientId();
        this.slackChannels();
    }

    setClientId () {
        Request.get("/client-id", {key: "SLACK_CLIENT_ID"}, (err, data) => {
            if (err) {
                this.setState({clientId: undefined, error: "Unable to proceed with Slack integrated"});
            } else {
                this.setState({clientId: data.value});
                Request.get("/client-id", {key: "SLACK_CLIENT_ID_DEV"}, (err, data) => {
                    if (err) {
                        this.setState({clientIdDev: undefined, error: "Unable to proceed with Slack integrated"});
                    } else {
                        this.setState({clientIdDev: data.value});
                    }
                })
            }
        })
    }

    slackChannels (append) {
        if (this.props.project === undefined) {
            return;
        }
        let data = {project: this.props.project.id};
        if (this.state.next_cursor !== undefined) {
            data.next_cursor = this.state.next_cursor;
        }
        if (append !== true) {
            this.setState({loading: true});
        }
        Request.get("/slackchannels", data, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch Slack channels."});
            } else {
                const slackAdded = data.slackAdded;
                if (slackAdded === false) {
                    this.setState({loading: false, slackAdded: false});
                } else {
                    let channels = [];
                    let fetchedChannels = data.channels;
                    if (fetchedChannels === undefined) {
                        fetchedChannels = [];
                    }
                    if (append === true) {
                        if (this.state.channels !== undefined) {
                            for (let i = 0; i < this.state.channels.length; i++) {
                                channels.push(this.state.channels[i]);
                            }
                            for (let i = 0; i < fetchedChannels.length; i++) {
                                channels.push(fetchedChannels[i]);
                            }
                        }
                    } else {
                        channels = fetchedChannels;
                    }
                    this.setState({loading: false, slackAdded: true, channels: channels, next_cursor: data.next_cursor}, 
                        () => {
                            if (append !== true) {
                                this.slackSelectedChannels()
                            }
                        });
                }
            }
        });
    }

    slackSelectedChannels () {
        if (this.props.project === undefined) {
            return;
        }
        let data = {project: this.props.project.id};
        if (this.state.scope !== "project") {
            if (this.props.targets === undefined) {
                return
            }
            if (this.state.targetIndex < this.props.targets.length) {
                data.target = this.props.targets[this.state.targetIndex].created;
            } else {
                return;
            }
        }
        this.setState({loading: true});
        Request.get("/slackSelectedChannels", data, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch Slack channels."});
            } else {
                let channels = data.channels;
                if (channels === undefined) {
                    channels = [];
                }
                this.setState({loading: false, originalSelected: channels.slice(), selectedChannels: channels});
            }
        });
    }

    changed (selectedChannels) {
        let originalSelected = this.state.originalSelected;
        if (selectedChannels.length !== originalSelected.length) {
            return true;
        }

        originalSelected.sort();
        selectedChannels.sort();

        let changed = false;
        for (let i = 0; i < selectedChannels.length; i++) {
            if (selectedChannels[i] !== originalSelected[i]) {
                changed = true;
            }
        }

        return changed;
    }

    add (channelId) {
        let selectedChannelsObj = {};
        this.state.selectedChannels.forEach(function (channel) {
            selectedChannelsObj[channel] = channel;
        });

        if (selectedChannelsObj[channelId] === undefined) {
            let selectedChannels = this.state.selectedChannels;
            selectedChannels.push(channelId);
            let saved = !this.changed(selectedChannels);
            this.setState({selectedChannels: selectedChannels, saved: saved});
        }
    }

    remove (channelId) {
        let removeIndex = -1;
        for (let i = 0; i < this.state.selectedChannels.length; i++) {
            let channel = this.state.selectedChannels[i];
            if (channel === channelId) {
                removeIndex = i;
            }
        }

        if (removeIndex !== -1) {
            let selectedChannels = this.state.selectedChannels;
            selectedChannels.splice(removeIndex, 1);
            let saved = !this.changed(selectedChannels);
            this.setState({selectedChannels: selectedChannels, saved: saved});
        }
    }

    scopeToggle () {
        let scope = this.state.scope;
        if (scope === "project") {
            this.setState({scope: "target", search:"", next_cursor: undefined}, () => this.slackChannels());
        } else {
            this.setState({scope: "project", search:"", next_cursor: undefined}, () => this.slackChannels());
        }
    }

    targetChange (e) {
        this.setState({targetIndex: e.target.value, search:""}, () => this.slackSelectedChannels());
    }

    save () {
        window.scrollTo(0,0);
        if (this.props.project === undefined) {
            return;
        }
        let data = {project: this.props.project.id, channels: this.state.selectedChannels};
        if (this.state.scope !== "project") {
            if (this.state.targetIndex < this.props.targets.length) {
                data.target = this.props.targets[this.state.targetIndex].created;
            } else {
                return;
            }
        }
        this.setState({loading: true});
        Request.post("/slackchannels", data, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to update Slack channels."});
            } else {
                this.setState({loading: false, saved: true, originalSelected: this.state.selectedChannels.slice()});
            }
        });
    }

    onSearchChange (value) {
        this.setState({search: value})
    }

    render() {
        if (this.state.user === undefined) {
            return (
                <div>
                    <p>If your team uses both Slack and Tesults consider adding Tesults results notification messages to your Slack channels.</p>
                    <p>New to Tesults? Welcome! <NavLink to="/" className="planInfoLink primary4">This is what Tesults is</NavLink>.</p>
                    <h3 className="mt-3">What does adding Tesults to Slack do?</h3>
                    <p className="mb-3">Much like Tesults email notifications, Slack notification messages contain summary details of the latest automated test results and build status. The messages also contain links to detailed results pages, the supplemental view and overall project status.</p>
                    <img src="/img/slack-image-edited.png" className="width100 primary1border" alt=""/>
                    <p className="mt-3">If your team uses Slack throughout the day you can now be notified of new results within your channel and go directly to detailed Tesults pages by following the button links in the message.</p>
                    <h3 className="mt-3">How do I add Tesults to Slack?</h3>
                    <p><NavLink to="/login" className="planInfoLink neutral4">Login</NavLink> to add Tesults to Slack.</p>
                    <p>After logging in create at least one project on Tesults. Read the <NavLink to="/docs" className="planInfoLink primary4">documentation</NavLink> for detailed steps outlining how this is done.</p>
                    <p>Then return <NavLink to="/config/project/slack" className="planInfoLink primary4">here</NavLink> and you will find an 'Add To Slack' button has appeared. Click the button to authorize Tesults to post messages to your Slack channels. You are able to configure which of your Slack channels you want to receive results notifications for.</p>
                    <p>Tesults will post messages only when new results are generated and available.</p>
                </div>
            )
        } else if (this.state.loading) {
            return <Loading/>
        } else {
            let add = this.add;
            let remove = this.remove;
            let save = this.save;

            if (this.state.slackAdded) {
                let selectedChannelsObj = {};
                this.state.selectedChannels.forEach(function (channel) {
                    selectedChannelsObj[channel] = channel;
                });

                let selection = []
                this.state.channels.forEach(function (channel) {
                    let name = channel.name;
                    let members = channel.num_members;
                    let button = <button type="button" className="btn btn-confirm" onClick={() => {add(channel.id)}}>Add</button>
                    if (selectedChannelsObj[channel.id] !== undefined) {
                        button = <button type="button" className="btn btn-cancel" onClick={() => {remove(channel.id)}}>Remove</button>
                    }

                    let push = false
                    if (this.state.search === "") {
                        push = true
                    } else {
                        try {
                            if (name.toLowerCase().includes(this.state.search.toLowerCase())) {
                                push = true
                            }
                        } catch (err) {
                            push = true
                        }                        
                    }
                    if (push === true) {
                        selection.push(
                        <tr key={channel.id}>
                            <td>{name}</td>
                            <td>{members}</td>
                            <td>{button}</td>
                        </tr>);
                    }
                }.bind(this));

                let saved = this.state.saved;

                let scopeMessage = <div>
                                        <p className="neutral1 mb-3">The Slack channels added here will receive a message from Tesults everytime new results are generated for the project.</p>
                                    </div>

                let scopeButtonLabel = "Switch to target Scope";
                let targetSelect = <span></span>;
                if (this.state.scope !== "project") {
                    scopeButtonLabel = "Switch to project Scope";
                    let index = 0;
                    let targetOptions = [];
                    if (this.props.targets !== undefined) {
                        this.props.targets.forEach(function (target) {
                            targetOptions.push(<option key={index} value={index}>{target.name}</option>);
                            index += 1;
                        });
                    }
                    
                    if (targetOptions.length === 0) {
                        targetSelect =
                            <div>
                                <p>There are no targets for this project. You can create them here.</p>
                                <button type="button" className="btn btn-confirm mt-3" onClick={this.createTarget}>Create New Target</button>
                                <br/>
                                <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>

                                <hr/>
                                <NavLink to="/docs/target" target="_blank" className="neutral4">What is a target?</NavLink>
                            </div>
                    } else {
                        scopeMessage = <div>
                                        <p className="mb-3">The Slack channels added here will receive a message from Tesults everytime new results are generated for the selected target only.</p>
                                    </div>
    
                        targetSelect = <div className="mb-3">
                                <p>Select target to configure:&nbsp;&nbsp;</p>
                                <select className="custom-select" onChange={this.targetChange} value={this.state.targetIndex}>
                                    {targetOptions}
                                </select>
                            </div>
                    }
                }

                if (this.props.project === undefined) {
                    return (
                        <div className="whitebg mt-3">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <p>You must <NavLink to="/createProject" className="nounderline neutral1">create a project</NavLink> first to configure Slack channels.</p>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    let error = this.props.error;
                    if (error === undefined) {
                        error = this.state.error;
                    }
                    if (error === undefined) {
                        error = "";
                    }
                    return (
                        <div className="whitebg p-4 mt-3">
                            <div style={{"display":"flex"}}>
                                <div style={{"flex":"3"}}>
                                    {targetSelect}
                                    {scopeMessage}
                                    <p><button type="button" className="btn btn-cancel" onClick={() => {this.scopeToggle()}} disabled={this.state.loading}>{scopeButtonLabel}</button></p>
                                    <div style={{"display":"flex"}}>
                                        <div className='mr-3'>
                                            <input className="search clickable" onChange={(e) => this.onSearchChange(e.target.value)} placeholder="Search Channel Name"/>
                                        </div>
                                        {(this.state.next_cursor === undefined || this.state.next_cursor === "") ? <div></div> :
                                        <div className='mr-3'>
                                            <button onClick={() => this.slackChannels(true)} className="btn-load">Load more Slack channels</button>
                                        </div>}
                                        <div className='mr-3'>
                                            <button type="button" className="btn btn-confirm" onClick={() => {save()}} disabled={saved}>Save Changes</button>
                                        </div>
                                        <div>
                                            <button type="button" className="btn btn-cancel ml-3" onClick={() => {this.props.back()}}>Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='dividor-light'/>
                            <table className="table table-striped width85">
                                <thead style={{"textAlign":"left"}}>
                                <th className="neutral3">Slack Channel</th>
                                <th className="neutral3">Members</th>
                                <th className="neutral3">Tesults Notifications</th>
                                </thead>
                                <tbody>
                                {selection}
                                </tbody>
                            </table>
                            <div className='neutral4 font14'>
                                {error}
                            </div>
                            <hr className='dividor-light'/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='mt-5' style={{"maxWidth":"600px"}}>
                                <h4 className='neutral4 mb-2'>Remove Slack</h4>
                                <div className='neutral4 font15 mb-4'>If you remove the Slack workspace from your project, you will no longer receive results notifications to your Slack channels. After removing Slack you can add the same or different Slack workspace back again.</div>
                                <div>
                                    <button type="button" className="btn btn-cancel" onClick={() => {this.props.removeSlack()}}>Remove Slack</button>
                                </div>
                            </div>
                        </div>
                    );
                }
            } else {
                let id = this.props.project.id;
                return (
                    <div className="whitebg p-4 mt-3">
                        <p>If your team uses both Slack and Tesults consider adding Tesults results notification messages to your Slack channels.</p>
                        <p className="mb-5">Slack notification messages contain summary details of the latest test results and build status. The messages also contain links to detailed results pages, the supplemental view and overall project status.</p>
                        <img src="/img/slack-image-edited.png" className="width85 neutral1border" alt=""/>
                        <p className="mt-3">If your team uses Slack throughout the day you can now be notified of new results within your channel and go directly to detailed Tesults pages by following the button links in the message.</p>
                        <h4 className="neutral1 mt-3">Add Tesults to Slack</h4>
                        <p>Create at least one project on Tesults. Read the <NavLink to="/docs" className="nounderline neutral1">documentation</NavLink> for detailed steps outlining how this is done.</p>
                        <p>Click the 'Add to Slack' button to authorize Tesults to post messages to your Slack channels. You are able to configure which of your Slack channels you want to receive results notifications for.</p>
                        <p>Tesults will post messages only when new results are generated and available.</p>
                        <br/>
                        {
                            this.props.dev === true ?
                            <a href={"https://slack.com/oauth/v2/authorize?client_id=" + this.state.clientIdDev + "scope=channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,groups:read,incoming-webhook&user_scope=&state=dev-" + id}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                            :
                            <a href={"https://slack.com/oauth/v2/authorize?client_id=" + this.state.clientId + "&scope=channels:read,chat:write,chat:write.customize,chat:write.public,groups:read&state=" + id}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                        }
                        <br/>
                        <button type="button" className="btn btn-cancel mt-3" onClick={() => {this.props.back()}}>Back</button>
                        {
                            this.props.dev === true ?
                            <div className='mt-3 neutral4 font14'>Slack Dev/Test Mode</div>
                            :
                            <div></div>
                        }
                    </div>
                );
            }
        }
    }
}

export default Slack;