/*global */
import React, { Component } from 'react'
import SelectOverlay from './SelectOverlay'

class CaseDisplayAttribute extends Component {
    constructor () {
        super()
        this.displayValue = this.displayValue.bind(this)
        this.valueChange = this.valueChange.bind(this)
    }

    displayValue () {
        if (this.props.map[this.props.attribute] === undefined) {
            return "--------" 
        } else if (["name", "icons", "result", "suite", "desc", "priority", "time"].includes(this.props.map[this.props.attribute])) {
            return this.props.map[this.props.attribute]
        } else {
            return "custom"
        }
    }

    valueChange (value) {
        this.props.attributeChange(this.props.attribute, value)
    }

    render () {
        if (this.props.map === undefined) {
            return <div></div>
        }

        let options = [
            {label: "suite", value: "suite"},
            {label: "description", value: "desc"},
            {label: "priority", value: "priority"},
            {label: "time", value: "time"},
            {label: "custom", value: "custom"}
        ]

        if (this.props.attribute === "attribute0") {
            options = [{label: "name", value: "name"}]
        }
        if (this.props.attribute === "attribute4") {
            options = [{label: "icons", value: "icons"}]
        }
        if (this.props.attribute === "attribute5") {
            options = [{label: "result", value: "result"}]
        }   

        if (this.props.attribute === "label") {
            return (
                <div> 
                    <div className='neutral4 font14'>Label this attribute mapping for reference later when applying it for use with your project or targets</div>
                    <div><input className='tr-input' value={this.props.map.label === undefined ? "" : this.props.map.label} onChange={(e) => this.valueChange(e.target.value)} disabled={this.props.mode.view ? true : false}/></div>
                </div>
            )
        } else {
            let displayValue = this.displayValue()
            return (
                <div>
                    <div className='mb-3'>
                        <div className='mr-3'>
                            {(this.props.mode !== "view") ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>{this.props.map[this.props.attribute]}</span>}
                        </div>
                        {
                            (this.props.mode === "view") ?
                            <div></div>
                            :
                            <div className='mr-3'>
                                <SelectOverlay
                                    overlay={this.props.overlay} 
                                    messageOverlay={this.props.messageOverlay}
                                    type="generic"
                                    title={displayValue}
                                    label="label"
                                    value="value"
                                    options={options}
                                    valueChange={this.valueChange}
                                    defaultValue={"suite"}
                                />
                            </div>
                        }
                        {
                            displayValue === "custom" && this.props.mode !== "view" ?
                            <div className='mr-3'>
                                <input className='tr-input-side' value={this.props.map[this.props.attribute]} onChange={(e) => this.valueChange(e.target.value)}/>
                            </div>
                            :
                            <div className='mr-3'></div>
                        }
                    </div>
                </div>
            )
        }        
    }
};

export default CaseDisplayAttribute