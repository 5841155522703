/*global */
import React, { Component } from 'react';
import Constants from './Constants';

class ResultsFiles extends Component {
    constructor () {
        super();
        this.state = {files: [], index: 0, caseUrlPrefix: undefined};
        this.indexIncrement = this.indexIncrement.bind(this);
        this.indexDecrement = this.indexDecrement.bind(this);
    }

    indexIncrement () {
        let index = this.state.index;
        index += 1;
        if (index >= this.state.files.length) {
            index = 0;
        }
        this.setState({index: index});
    }

    indexDecrement () {
        let index = this.state.index;
        index -= 1;
        if (index < 0) {
            index = this.state.files.length - 1;
        }
        this.setState({index: index});
    }

    componentDidMount () {
        let files = [];
        let checkFiles = true;

        if (this.props.projects === undefined || this.props.projectIndex === undefined
            || this.props.targets === undefined || this.props.targetIndex === undefined
            || this.props.runs === undefined || this.props.runIndex === undefined) {
            checkFiles = false;
        }

        if (checkFiles === true) {
            if (this.props.projectIndex >= this.props.projects.length || this.props.targetIndex >= this.props.targets.length || this.props.runIndex >= this.props.runs.length){
                checkFiles = false;
            }
        }

        let caseUrlPrefix = Constants.baseUrl + "/results/rsp/view/" + this.props.view + "/case/" + this.props.runs[this.props.runIndex].id + "-" + this.props.runs[this.props.runIndex].created;
        
        if (checkFiles === true) {
            let target = this.props.targets[this.props.targetIndex];
            let run = this.props.runs[this.props.runIndex];
            let permission = "p" + this.props.projects[this.props.projectIndex].dl;
            let prefix = target.id + "-" + target.created + "-" + run.created;

            let buildCase = this.props.buildCase;
            if (this.props.buildCase !== undefined && this.props.buildCase !== null) {
                if (this.props.buildCase.files !== undefined) {
                    this.props.buildCase.files.forEach(function (f) {
                        let url = Constants.baseUrl + "/results/files/" + permission + "/" + prefix + (buildCase.batch !== undefined ? "-" + buildCase.batch : "") + "/" + buildCase.num + "/" + f;
                        if (Constants.hasFileExtension(url, [".jpg", ".jpeg", ".png"])) {
                            files.push({url: url, caseDetail: buildCase});
                        }
                    });
                }
            }
            if (this.props.testCases !== undefined && this.props.testCases !== null) {
                this.props.testCases.forEach(function (testCase) {
                    if (testCase.files !== undefined) {
                        testCase.files.forEach(function (f) {
                            let url = Constants.baseUrl + "/results/files/" + permission + "/" + prefix + (testCase.batch !== undefined ? "-" + testCase.batch : "") +  "/" + testCase.num + "/" + f;
                            if (Constants.hasFileExtension(url, [".jpg", ".jpeg", ".png"])) {
                                files.push({url: url, caseDetail: testCase});
                            }
                        });
                    }
                });
            }
        }

        this.setState({files: files, index: 0, caseUrlPrefix: caseUrlPrefix});
    }

    render () {
        if (this.state.files.length === 0) {
            return (
                <span></span>
            );
        } else {
            let file = this.state.files[this.state.index];
            let suite = "";
            let name = "";
            //let cUrl =  this.state.caseUrlPrefix + "-" + file.caseDetail.num;
            //let cLink = <div><a style={{"textShadow" : "none"}} className="neutral4 wrap" target="_blank" rel="noopener noreferrer" href={cUrl}><small>{cUrl}</small></a></div>

            if (file.caseDetail.suite !== undefined) {
                if (file.caseDetail.suite === "[build]") {
                    name = file.caseDetail.name;
                    suite = ", Build";
                } else {
                    name = file.caseDetail.name;
                    suite = ", " + file.caseDetail.suite
                }
            }

            let img = "/img/tesults-logo-unknown-128.png";
            if (file.caseDetail.result.toLowerCase() === "pass") {
                img = "/img/tesults-logo-pass-128.png";
            } else if (file.caseDetail.result.toLowerCase() === "fail") {
                img = "/img/tesults-logo-fail-128.png";     
            }
            let resultImg = <img height="32" width="32" alt="" src={img}/>

            return (
                <div>
                <hr/>
                <p className="neutral3">Images from this test run</p>
                <div className="row">    
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <p className="neutral1 ">Displaying image from this test case:</p>
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div className="mr-3">
                                {resultImg}
                            </div>
                            <div>
                                <small>{name} {suite}</small>
                            </div>
                        </div>
                        
                        {/*{cLink}*/}
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                        <p className="neutral3">Explore images from this test run:</p>
                        <button className="btn-light" onClick={this.indexDecrement}><img src="/img/expanded-false.png" className="horizontalMirror" alt="" width="16" height="16"/></button>
                        <button className="btn-light" onClick={this.indexIncrement}><img src="/img/expanded-false.png" alt="" width="16" height="16"/></button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <img src={file.url} className="width100" alt=""/>
                    </div>
                </div>
                <hr/>
                </div>
            );
        }
    }
}

export default ResultsFiles;