/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class ConfigTargetAPI extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {state: "view", confirm: {success: undefined, failure: undefined}}
        this.edit = this.edit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    edit () {
        this.setState({state: "edit", confirm: {success: undefined, failure: undefined}});
    }

    toggle () {
        let target = this.props.target
        let apiEdit = target.apiEdit
        if (apiEdit === undefined || apiEdit === null) {
            apiEdit = false
        }
        apiEdit = apiEdit === true ? false : true

        this.setState({confirmation: {success: undefined, failure: undefined}});
        let data = {
            id: target.id,
            created: target.created,
            apiEdit: apiEdit
        };
        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to complete target api edit"}});
            } else {
                Analytics.event("EditedTargetAPIEdit");
                this.setState({confirmation: {success: "Target API setting updated", failure: undefined}}, () => this.props.targetUpdated(data.target));
            }
        });
    }

    cancel () {
        this.setState({state: "view", confirmation: {success: undefined, failure: undefined}})
    }
    
    render () {
        if (this.state.state === "view") {
            return (
                <div>
                    <button className='btn-cancel' onClick={this.edit}>API Enable/Disable</button>
                </div>
            );
        } else if (this.state.state === "edit") {
            if (this.props.target === undefined) { return <div></div>}
            let enabled = this.props.target.apiEdit === true ? true : false
            return (
                <div style={{"maxWidth":"600px"}}>
                    <h4>Target API edit</h4>
                    <p>Decide whether Tesults API has edit access for this target. This includes the ability to regenerate the token for the target and the ability to delete the target (including all test runs with it).</p>
                    <p>Targets created using the configuration menu have API edit access disabled by default. Targets created using the API have edit access enabled by default. The API has read access to all targets and this cannot be modified.</p>
                    <hr className='dividor-light'/>
                    <h4 className='neutral3'>{this.props.target.name}</h4>
                    <div>{enabled ? "Enabled" : "Disabled"}</div>
                    <hr className='dividor-light'/>
                    <Confirmation confirmation={this.state.confirmation}/>
                    <ConfirmationButtons
                        confirm={this.toggle}
                        cancel={this.cancel}
                        confirmLabel={enabled ? "Disable" : "Enable"}
                        cancelLabel="Close"
                    />
                </div>
            )
        }
    }
};

export default ConfigTargetAPI