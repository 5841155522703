/*global */
import React, { Component } from 'react';

class AffiliateTerms extends Component {
    render () {
        return (
            <div style={{"minHeight": "100vh"}}>
                <div style={{"maxWidth":"600px"}}>
                <h1 className="neutral1">Terms of service</h1>
                <h3 className="neutral1 mt-5 pt-5">Income</h3>
                <p>
                    The Tesults Affiliate Program makes no guarantee about income. It is possible to join the program, generate a 
                    referral link and put it on your site(s) and for referrals to lead to zero conversions on Tesults. In this 
                    case you will not receive any income from the program.
                </p>
                <h3 className="neutral1 mt-5 pt-5">Ending the program</h3>
                <p>
                    Tesults reserves the right to end the Tesults Affiliate Program at any time. Notice or reason does not need to 
                    be provided for ending the program but Tesults will communicate such changes to affiliates.
                </p>
                <p>
                    Tesults reserves the right to end working with a specific affiliate. Notice or reason does not need to be 
                    provided for ending working with a specific affiliate, but Tesults will make an effort to communicate such an 
                    event to the affiliate affected.
                </p>
                <p>
                    The affiliate can at any time choose to end being an affiliate. Delete your affiliate links at
                    https://www.tesults.com/affiliate. After deletion, links will no longer be attributed to the 
                    affiliate.
                </p>
                <h3 className="neutral1 mt-5 pt-5">Referral time period</h3>
                <p>
                    When an affiliate link is used to reach Tesults, there is a 30 day time limit for a sign up to occur for the 
                    user sign up to be attributed to the affiliate. As long as the sign up occurs within 30 days the affiliate will be
                    rewarded with recurring revenue sharing for the lifetime of any paid project subscription by this user account.
                    New users rarely created a paid project immediately, be aware that after sign up, the first step is usually the
                    creation a free project to trial Tesults. The 30 day limit only applies for the sign up to occur following a referred visit. 
                    As long as the sign up has occurred the affiliate will benefit once the user moves to a paid subscription at any time
                    in the future. This progression is a reason why a breakdown of numbers by visits, sign ups, free project creation, 
                    paid subscription is made available to affiliates to view at https://www.tesults.com/affiliate.

                </p>
                <p>
                    The program currently utilizes a ‘first touch attribution’ model. This means that if a different affiliate link 
                    is used to visit Tesults within 30 days of the first affiliate link, the new link does not override attribution to 
                    the first. The first link continues to receive full attribution. Tesults reserves the right to change this model in 
                    the future without notice but Tesults will communicate such a change.
                </p>
                <h3 className="neutral1 mt-5 pt-5">Payments</h3>
                <p> 
                    The affiliate can check for payment owed to them at https://www.tesults.com/affiliate. Once the affiliate has a balance
                    greater than zero, a member of the Tesults team will reach out for bank transfer details. Depending on the method of 
                    transfer agreed there may be a fee the payment processor charges on your payment but this will be made clear prior to
                    transfer. To reduce fees it is best to receive payments in bulk and this will be recommended.
                </p>
                <h3 className="neutral1 mt-5 pt-5">General</h3>
                <p>
                    The Tesults Affiliate Program is an entirely voluntary opt in program that affiliates enter on their own accord. 
                    Tesults cannot be held responsible for any issues that may arise with respect to participating in the program. 
                    Participation in the Tesults Affiliate Program is entirely at your own risk.
                </p>
                </div>
            </div>
        );
    }
};

export default AffiliateTerms;