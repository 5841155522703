/*global */
import React, { Component } from 'react';

class ContactSide extends Component {
    render () {
        return (
            <div style={{"display":"flex", "alignItems":"center"}}>
                <div className="mr-3">
                    
                </div>
            </div>
        );
    }
};

export default ContactSide;