/*global */
import React, { Component } from 'react';
import Analytics from './Analytics';

class InfoToolTip extends Component {
    constructor () {
        super();
        this.ref = React.createRef();
        this.analytics = this.analytics.bind(this);
    }

    componentDidMount() {
        //$('[data-toggle="tooltip"]').tooltip();
    }

    componentDidUpdate() {
        //$('[data-toggle="tooltip"]').tooltip();
    }

    analytics (message) {
        Analytics.event("ToolTip", {"Message": message});
    }

    render () {
        if (this.props.element !== undefined) {
            return (
                <div 
                    ref={this.ref}
                    className="caseLink"
                    onMouseEnter={() => {
                        this.analytics(this.props.message);
                        this.props.overlay(this.props.message, this.ref);
                    }}
                    onMouseOut={() => {
                        this.props.overlay(undefined);
                    }}
                >
                        {this.props.element}
                </div>
            );
        } else {
            return (
                <div 
                    ref={this.ref}
                    className="caseLink" 
                    data-toggle="tooltip" 
                    data-container="body" 
                    data-html={true} 
                    data-placement={this.props.position} 
                    title={"<div className='neutral4'>" + this.props.message + "</div>"} 
                    onMouseEnter={() => {this.analytics(this.props.message)}}>
                        <img width="16" height="16" alt="info" src="/img/information-outline.svg"/>
                </div>
            );
        }
    }
};

export default InfoToolTip;