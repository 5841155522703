/*global */
import React, { Component } from 'react';
import Request from './Request';

class AdministratorMenu extends Component {
    constructor () {
        super();
        this.state = {isAdmin: false};
    }

    componentDidMount () {
        Request.get('/isAdministrator', undefined, (err, data) => {
            if (err) {
                this.setState({isAdmin: false});
            } else {
                this.setState({isAdmin: true});
            }
        });
    }

    render () {
        if (this.state.isAdmin !== true) {
            return <div></div>
        } else {
            return (
                <div style={{"display":"flex", "flex-direction":"column"}}>
                    <a href="/" className="tr-link-neutral1 mobile-hide">
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"marginTop":"4px"}}>
                                <img alt="" src="/img/tesults-logo-2021-05-14-128.png" width="16" height="16"/>
                            </div>
                            <div className="neutral2">
                                &nbsp;<span className="bolder font16 primary4">Tesults Admin</span>
                            </div>
                        </div>
                    </a>
                    <div className="mb-4 docsTopPadding">
                        <h1>&nbsp;</h1>
                        <h1>&nbsp;</h1>
                        <div style={{"display":"flex", "flexDirection":"column"}}>
                            <div className="mb-5"><a className={this.state.view === "dashboard" ? "tr-link-primary4": "tr-link-neutral6"} href="/administrator">Affiliate Review</a></div>
                        </div>
                        {/*(<div className="mt-5">
                            <img className="width100" src="/img/Illustrations_Tesults_001Artboard-1.png" alt=""/>
                        </div>*/}
                    </div>
                </div>
            );
        }
    }
}

export default AdministratorMenu;