/*global */
import React, { Component } from 'react';

class DocsProxy extends Component {
    render() {
        return (
            <div className="mb-5">
                <h2>Proxy servers</h2>
                <p>Does your corporate/office network run behind a proxy server? Contact us and we will supply you with a custom API Library for this case. Without this results will fail to upload to Tesults.</p>
            </div>
        );
    }
}

export default DocsProxy;