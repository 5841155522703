/*global*/
import React, { Component } from 'react';
import SelectOverlay from './SelectOverlay';

class ResultsHeader9 extends Component {
    constructor () {
        super()
    }

    render () {
        window.scrollTo(0, 0);
        return (
            <div className="results-header9">
                <div className="results-header9-selector">
                    <SelectOverlay 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        valueChange={this.props.targetIndex1Change} 
                        type="target-preview" 
                        label="name"
                        role={this.props.role}
                        options={this.props.targets}
                        index={this.props.targetIndex1}
                    />                    
                </div>
                <div className="results-header9-selector">
                    <SelectOverlay 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        valueChange={this.props.targetIndex2Change} 
                        type="target-preview" 
                        label="name"
                        role={this.props.role}
                        options={this.props.targets}
                        index={this.props.targetIndex2}
                    />
                </div>
            </div>
        );
    }
};

export default ResultsHeader9;