/*global */
import React, { Component } from 'react'

class Submitting extends Component {
    render() {
        if (this.props.submitting !== true) { return <span></span> }
        return (
            <div className='neutral8bg font14 neutral4'>
                {this.props.message}
            </div>
        )
    }
}

export default Submitting