/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'
import TimeFormatted from './TimeFormatted'
import Loading from './Loading'

class ConfigTargetDeleteRun extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {state: "view", runs: [], moreRunsEsk: undefined, runIndex: 0}
        this.cancel = this.cancel.bind(this)
        this.delete = this.delete.bind(this)
        this.deleteConfirm = this.deleteConfirm.bind(this)
        this.runChange = this.runChange.bind(this)
        this.getRuns = this.getRuns.bind(this)
        this.getMoreRuns = this.getMoreRuns.bind(this)
    }

    componentDidMount () {
        this.setState({state: "view", runs: [], moreRunsEsk: undefined, runIndex: 0}, this.getRuns())
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.target !== undefined && prevProps.target !== undefined) {
            if (this.props.target.created !== prevProps.target.created) {
                this.setState({state: "view", runs: [], moreRunsEsk: undefined, runIndex: 0}, this.getRuns())
            }
        }
    }

    cancel () {
        this.setState({state: "view"})
    }

    delete () {
        this.setState({state: "delete"})
    }

    deleteConfirm () {
        this.setState({submitting: true});
        let run = this.state.runs[this.state.runIndex];
        Request.post("/runDelete", {id: run.id, created: run.created}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to delete run"}, submitting: false});
            } else {
                Analytics.event("DeletedRun");
                this.setState({state: "view", submitting: false, confirmation: {success: undefined, failure: undefined}});
            }
        });
    }

    runChange (e) {
        this.setState({runIndex: e.target.value});
    }

    getRuns () {
        if (this.props.target === undefined) { return }
        this.setState({loading: true});
        Request.get("/runs", {id: this.props.target.id, created: this.props.target.created}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error getting runs"}, loading: false});
            } else {
                this.setState({confirmation: {success: undefined, failure: undefined}, runIndex: 0, runs: this.state.runs.concat(data.runs), moreRunsEsk: data.esk, loading: false});
            }
        });
    }

    getMoreRuns () {
        let esk = this.state.moreRunsEsk;
        if (esk === undefined) {
            return;
        }
        this.setState({loading: true});
        Request.get("/runs", {id: this.props.target.id, created: this.props.target.created, esk: esk}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({runs: this.state.runs.concat(data.runs), moreRunsEsk: data.esk, loading: false});
            }
        });
    }

    render () {
        if (this.state.loading === true) {
            return <Loading/>
        }
        if (this.state.state === "view") {
            return <button className='btn-cancel' onClick={this.delete}>Delete a test run</button>
        } else if (this.state.state === "delete") {
            let index = 0;
            let runOptions = [];
            this.state.runs.forEach(function (run) {
                let runCreated = run.created;
                let displayTime =  TimeFormatted.timeformatted(runCreated);
                runOptions.push(<option key={index} value={index}>{displayTime}</option>);
                index += 1;
            });
            
            if (runOptions.length === 0) {
                return (
                    <div>
                        <p>There are no test runs for this target.</p>
                        <br/>
                        <button type="button" className="btn btn-cancel mt-3" onClick={this.cancel}>Cancel</button>
                    </div>
                )
            } else {
                let moreRuns = "";
                if (this.state.moreRunsEsk !== undefined) {
                    moreRuns = <button type="button" onClick={this.getMoreRuns} className="btn btn-select"><small>Load More</small></button>;
                }
                return (
                    <div>
                        Select test run to delete:&nbsp;&nbsp;
                        <select className="custom-select mr-3" onChange={this.runChange} value={this.state.runIndex}>
                            {runOptions}
                        </select>
                        {moreRuns}
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-10 col-lg-9">
                                <br/>
                                <h4><b>IMPORTANT WARNING</b></h4>
                                <p>Deleting a test run cannot be undone. You should ordinarily never need to delete a test run. If you do not want to delete a test run click cancel.</p>
                                <p>If you proceed <b>ensure</b> the correct test run is selected from the dropdown list. The test run you delete may continue to appear in dropdown lists for up to a couple of minutes following deletion. There is no need to delete again, the run will automatically disappear.</p>
                                <div className="accenta1" id="error">{this.state.error}</div>
                                <br/>
                                <ConfirmationButtons
                                    confirm={this.deleteConfirm}
                                    cancel={this.cancel}
                                    confirmLabel="Delete"
                                    cancelLabel="Cancel"
                                    confirmDisabled={this.state.submitting}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return <div></div>
        }
    }
};

export default ConfigTargetDeleteRun