/*global Cookies*/
import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';
import Loading from './Loading';
import TimeFormatted from './TimeFormatted';
import Analytics from './Analytics';
import Request from './Request';
import Cache from './Cache';
import Context from './Context';

class Comment extends Component {
    static contextType = Context;

    constructor (props) {
        super(props);
        this.state = {state: this.props.state, text: "", comment: this.props.comment};
        this.textChange = this.textChange.bind(this);
        this.addCommentConfirm = this.addCommentConfirm.bind(this);
        this.editComment = this.editComment.bind(this);
        this.editConfirm = this.editConfirm.bind(this);
        this.editCancel = this.editCancel.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.deleteCancel = this.deleteCancel.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
    }

    textChange (e) {
        this.setState({text: e.target.value});
    }

    addCommentConfirm () {
        this.setState({loading: true});
        Request.post("/commentAdd", {id: this.props.id, created: this.props.created, hash: this.props.hash, body: this.state.text}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to fetch comments.", loading: false});
            } else {
                Analytics.event("CommentAdded");
                this.setState({comment: data.comment, loading: false, state: "view", text: ""}, () => this.props.editComplete ());
            }
        });
    }

    editComment () {
        Analytics.event("CommentEdit");
        this.setState({state: "edit", text: this.state.comment.body});
    }

    editConfirm () {
        this.setState({loading: true});
        Request.post("/commentEdit", {id: this.props.id, created: this.props.created, hash: this.props.hash, commentCreated: this.state.comment.created, body: this.state.text}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to delete comment.", loading: false});
            } else  {
                Analytics.event("CommentEdited");
                let comment = this.state.comment;
                comment.body = this.state.text;
                this.setState({comment: comment, text: "", loading: false, state: "view"});
            }
        });
    }

    editCancel () {
        Analytics.event("CommentEditCancel");
        this.setState({state: "view"});
    }

    deleteComment () {
        Analytics.event("CommentDelete");
        this.setState({state: "delete"});
    }

    deleteConfirm () {
        this.setState({loading: true});
        Request.post("/commentDelete", {id: this.props.id, created: this.props.created, hash: this.props.hash, commentCreated: this.state.comment.created}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to delete comment.", loading: false}, () => this.props.editComplete ());
            } else {
                Analytics.event("CommentDeleted");
                this.setState({loading: false, state: "deleted"});
            }
        });
    }

    deleteCancel () {
        Analytics.event("CommentDeleteCancel");
        this.setState({state: "view"});
    }

    render () {
        if (this.state.loading === true) {
            return (
                <div className="mb-3">
                    <Loading/>
                </div>
            );
        }

        if (this.state.state === "deleted") {
            return (
                <span></span>
            );
        }

        let commentLifespanMessage = <p className="neutral4 font12">Comments are saved for one year on a free project. Upgrade this project to persist comments permanently.</p>
        let projects = this.context.projects;
        if (projects !== undefined) {
            let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
            if (projectIndex === undefined) {
                projectIndex = 0
            }
            if (projectIndex < projects.length) {
                let project = projects[projectIndex]
                if (project.plan.name !== "free-v1") {
                    commentLifespanMessage = <span></span>
                }
            }
        }

        if (this.state.state === "add") {
            return (
                <div>
                    <div>
                        <textarea className="font15 neutral3 p-3 verticalResize width100" rows="6" value={this.state.text} onChange={this.textChange} required></textarea>
                    </div>
                    <div>
                        <button className="btn btn-confirm mt-1 mb-3" onClick={this.addCommentConfirm}>Add comment</button>
                    </div>
                    {commentLifespanMessage}
                </div>
            );
        }
        
        let deleteContent = <span></span>
        
        if (this.state.state === "edit") {
            return ( <div className="mb-3">
                <label className="neutral1 mt-3">Edit comment</label>
                <textarea className="font15 neutral3 p-3  verticalResize" rows="6" value={this.state.text} onChange={this.textChange} required></textarea>
                <button className="btn btn-confirm mt-1" onClick={this.editConfirm}>Confirm edit</button>
                <button className="ml-3 btn btn-select mt-1" onClick={this.editCancel}>Cancel edit</button>
            </div>);
        } else if (this.state.state === "delete") {
           deleteContent = <div className="mb-3">
               <button className="btn btn-confirm" onClick={this.deleteConfirm}>Confirm delete</button>
               <button className="ml-3 btn btn-select" onClick={this.deleteCancel}>Cancel delete</button>
           </div>
        }

        let created = <TimeFormatted dt={this.state.comment.created} includeYear={true}/>
        let updated = <span></span>
        if (this.state.comment.created !== this.state.comment.updated) {
            updated = <span>(Updated <TimeFormatted dt={this.state.comment.updated}/>)</span>
        }
        let user = "";
        if (this.props.users !== undefined) {
            let userData = undefined;
            this.props.users.forEach(function (user) {
                if (this.state.comment.user === user.id) {
                    userData = user;
                }
            }.bind(this));
            if (userData !== undefined) {
                user = userData.firstName + " " + userData.lastName;
            }
        }

        let editButton = <span></span>
        let deleteButton = <span></span>

        const userInfo = Cookies.getJSON("truser");
        if (this.state.comment.user === userInfo.id) {
            editButton = <button className="btn-cancel" onClick={this.editComment}>Edit</button>
            deleteButton = <button className="btn-cancel" onClick={this.deleteComment}>Delete</button>
        }

        return (
            <div>
                <div className="mb-5">
                    <div className="neutral8bg font12 mb-3">
                        {user}&nbsp;&nbsp;{created}&nbsp;&nbsp;{updated}&nbsp;&nbsp;&nbsp;&nbsp;{editButton}&nbsp;&nbsp;&nbsp;&nbsp;{deleteButton}
                    </div>
                    <div className="card-body">
                        <div className="pre-wrap"><Linkify>{this.state.comment.body}</Linkify></div>
                    </div>
                </div>
                {deleteContent}
            </div>
        );
    }
};

export default Comment;