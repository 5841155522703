/*global*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import FeatureRequestMain from './FeatureRequestMain'
import FeatureRequestSide from './FeatureRequestSide'

class FeatureRequest extends Component {
    render () {
        window.scrollTo(0, 0);
        return (
            <div>
                <Helmet>
                    <title>Tesults - Feature Request</title>
                    <meta name="description" content="Request a feature. The Tesults team reads and responds to all requests."/>
                </Helmet>
                <div>
                    <FeatureRequestMain/>
                    <FeatureRequestSide/>
                </div>
            </div>
        );
    }
};

export default FeatureRequest