/*global */
import React, { Component } from 'react';

class DocsSideItem extends Component {
    constructor() {
        super()
        this.ref = React.createRef()
        this.scrollToSelectedItem = this.scrollToSelectedItem.bind(this)
        this.linkClass = this.linkClass.bind(this)
        this.divClass = this.divClass.bind(this)
    }

    componentDidMount () {
        this.scrollToSelectedItem()
    }

    componentDidUpdate () {
        this.scrollToSelectedItem()
    }

    scrollToSelectedItem () {
        if (this.props.location.pathname === this.props.link) {
            if (this.ref.current !== undefined && this.ref.current !== null) {
                setTimeout(() => {
                    this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
               }, 100);
            }
        }
    }

    linkClass () {
        if (this.props.location.pathname === this.props.link) {
            return "tr-link-primary4 no-break"
        } else {
            return "tr-link-plain no-break"
        }
    }

    divClass () {
        if (this.props.location.pathname === this.props.link) {
            return "docs-group docs-group-active-item"
        } else {
            return "docs-group"
        }
    }

    render () {
        return <a className={this.linkClass()} href={this.props.link}><div ref={this.ref} className={this.divClass()}>{this.props.title}</div></a>
    }
}

export default DocsSideItem;