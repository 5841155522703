/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cache from './Cache'
import Context from './Context';

class ConfigMenu extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.itemClass = this.itemClass.bind(this)
        this.project = this.project.bind(this)
        this.freePlan = this.freePlan.bind(this)
    }

    componentDidMount () {
        
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    freePlan () {
        let free = false
        let project = this.project()
        if (project !== undefined) {
            if (project.plan !== undefined) {
                if (project.plan.name === "free-v1") {
                    free = true
                }
            }
        }
        return free
    }

    itemClass (option) {
        if (this.props.location === undefined) {
            return "config-menu-item-unselected"
        } else {
            if (this.props.pathMap[option] === undefined) {
                return "config-menu-item-unselected"
            } else {
                let paths = this.props.pathMap[option]
                if (paths.includes(this.props.location.pathname)) {
                    return "config-menu-item-selected"
                } else{
                    return "config-menu-item-unselected"
                }
            }
        }
    }

    render () {
        
        return (
            <div className='config-menu'>
                <NavLink to="/config/project/project" className={this.itemClass("project")}>Project</NavLink>
                <NavLink to="/config/project/targets" className={this.itemClass("targets")}>Targets</NavLink>
                <NavLink to="/config/project/target-order" className={this.itemClass("target-order")}>Target Order</NavLink>
                <NavLink to="/config/project/build-consolidation" className={this.itemClass("build-consolidation")}>Build Consolidation</NavLink>
                <NavLink to="/config/project/result-interpretation" className={this.itemClass("result-interpretation")}>Result Interpretation</NavLink>
                <NavLink to="/config/project/preferences" className={this.itemClass("preferences")}>Preferences</NavLink>
                <NavLink to="/config/project/notifications" className={this.itemClass("notifications")}>Notifications</NavLink>
                <NavLink to="/config/project/integrations" className={this.itemClass("integrations")}>Integrations</NavLink>
                <NavLink to="/config/project/api-token" className={this.itemClass("api-token")}>API Token</NavLink>
                <NavLink to="/config/project/team" className={this.itemClass("team")}>Team Members</NavLink>
                <NavLink to="/config/project/sso" className={this.itemClass("sso")}>Single Sign-on</NavLink>
                <NavLink to="/config/project/organization" className={this.itemClass("organization")}>Organization</NavLink>
                <div>
                    <hr className='dividor-light'/>
                </div>
                <NavLink to="/config/project/audit-logs" className={this.itemClass("audit-logs")}>Audit Logs</NavLink>
                <NavLink to="/config/project/plan" className={this.itemClass("plan")}>Plan {this.freePlan() ? <span className='accentb4'>(Upgrade)</span> : <span></span>}</NavLink>
                <NavLink to="/config/project/payment-details" className={this.itemClass("payment-details")}>Payment Details</NavLink>
                <NavLink to="/config/project/country" className={this.itemClass("country")}>Account Country</NavLink>
                <NavLink to="/config/project/invoices" className={this.itemClass("invoices")}>Invoices</NavLink>
            </div>
        )
    }
};

export default ConfigMenu