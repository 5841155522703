/*global*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Loading from './Loading';
import Analytics from './Analytics';
import Request from './Request';

class VerifyEmailMain extends Component {
    constructor (props) {
        super(props);
        this.state = {verified: false, error: "", verifying: false};
        this.verify = this.verify.bind(this);
    }

    componentDidMount() {
        this.verify();
        window.scrollTo(0,0);
    }

    verify() {
        this.setState({verifying: true})
        Request.post("/verifyemail", {vc: this.props.vc}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to verify. Check your verification link is active or request a new one.", verified: false, verifying: false});
            } else {
                this.setState({verified: true, verifying: false});
            }
        });
    }

    render () {
        if (this.state.verifying === true) {
            return (
                <div>
                    <p>Verifying...</p>
                </div>
            ); 
        } else if (this.state.verified === true) {
            Analytics.event("VerifiedEmail");
            return (
                <div>
                    <p>Email verified. View <NavLink to="/results" className="site-link-underline">results</NavLink></p>
                </div>
            ); 
        } else if (this.state.verified === false) {
            return (
                <div>
                    <p>Unable to verify. The verification link may have expired. You can request another email from the profile configuration menu. View <NavLink to="/results" className="site-link-underline">results</NavLink></p>
                </div>
            ); 
        } else {
            return (
                <div>
                    <Loading/>
                </div>
            )
        }
    }
}

export default VerifyEmailMain;