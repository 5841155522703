/*global*/
import React, { Component } from 'react'
import Analytics from './Analytics'
import Loading from './Loading'
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request'

class TwoFactorAuthenticationEnable extends Component {
    constructor () {
        super()
        Analytics.event("TwoFactorAuthenticationEnable")
        this.state = {state: "view", loading: false, error: "", require2fa: false, code: "", recoveryCodes: undefined}
        this.codeChange = this.codeChange.bind(this)
        this.required = this.required.bind(this)
        this.enable = this.enable.bind(this)
        this.enableConfirm = this.enableConfirm.bind(this)
        this.disable = this.disable.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0)
        this.setState({loading: true})
        this.required()
    }

    codeChange (e) {
        this.setState({code: e.target.value})
    }

    required () {
        Request.get("/require2fa", {}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to check whether two-factor authentication is required."})
            } else {
                this.setState({loading: false, require2fa: data.require2fa, error: ""})
            }
        })
    }

    enable () {
        window.scrollTo(0,0)
        this.setState({loading: true})
        Request.post("/enable2fa", {}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to enable 2FA."})
            } else {
                this.setState({state: "enabling", loading: false, dataUrl: data.dataUrl, key: data.key, error: ""})
            }
        })
    }

    enableConfirm () {
        this.setState({loading: true})
        Request.post("/enableConfirm2fa", {code: this.state.code}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to enable 2FA."})
            } else {
                if (data.verified === true)  {
                    this.setState({state: "enabled", loading: false, dataUrl: "", error: "", recoveryCodes: data.recoveryCodes})
                } else {
                    this.setState({loading: false, dataUrl: "", error: "The submitted code is incorrect, it may have expired. Try again. If this continues to occur contact help@tesults.com for help."})
                }
            }
        })
    }

    disable () {
        this.setState({loading: true})
        Request.post("/disable2fa", {code: this.state.code}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to disable 2FA."})
            } else {
                this.setState({state: "disabled", loading: false, dataUrl: "", error: ""})
            }
        })
    }

    cancel () {
        this.setState({state: "view"})
    }

    render () {
        if (this.state.loading === true) {
            return (
                <Loading/>
            );
        } else if (this.state.state === "view")  {
            if (this.state.require2fa === true) {
                return (
                    <div>
                        <div className="mb-3 font14 neutral4">Two-factor authentication (2FA)</div>
                        <p>Two-factor authentication (2FA) is currently enabled for your account.</p>
                        <small><span className="fail">{this.state.error}</span></small>
                        <hr/>
                        <p><b>IMPORTANT WARNING:</b><br/>If you click disable, two-factor authentication (2FA) will be immediately disabled and will no longer be required for login.</p>
                        <br/>
                        <ConfirmationButtons
                            confirm={() => {this.disable()}}
                            cancel={this.props.cancel}
                            confirmLabel="Disable"
                            cancelLabel="Back"
                        />
                    </div>
                )
            } else {
                return (
                    <div style={{"maxWidth":"800px"}}>
                        <div className="mb-3 font14 neutral4">Two-factor authentication (2FA)</div>
                        <div className='font14 neutral3'>Two-factor authentication (2FA) is currently disabled for your account. Consider enabling it as an additional security measure for logging into your Tesults account. You require an authenticator app on your phone to proceed.</div>
                        <small><span className="fail">{this.state.error}</span></small>
                        <ConfirmationButtons
                            confirm={() => {this.enable()}}
                            cancel={this.props.cancel}
                            confirmLabel="Enable"
                            cancelLabel="Back"
                        />
                    </div>
                )
            }
        } else if (this.state.state === "enabling") {
            return (
                <div>
                <div className="mb-3 font14 neutral4">Two-factor authentication (2FA)</div>
                <img src={this.state.dataUrl} alt=""/>
                <br/>
                <small>Key: {this.state.key}</small>
                <br/>
                <br/>
                <p>Scan the QR code or enter the key into your two-factor authentication app. Then enter the token value the app displays here:</p>
                <input type="text" className="tr-input mb-3" id="code" name="code" placeholder="Enter code" onChange={this.codeChange} required/>
                <small><span className="fail">{this.state.error}</span></small>
                <ConfirmationButtons
                    confirm={this.enableConfirm}
                    cancel={this.cancel}
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                />
                </div>
            )
        } else if (this.state.state === "enabled") {
            let recoveryCodes = this.state.recoveryCodes
            let recoveryCodesDisplay = []
            recoveryCodes.forEach(function (recoveryCode) {
                recoveryCodesDisplay.push(recoveryCode)
                recoveryCodesDisplay.push(<br/>)
            })
            return (
                <div>
                <div className="mb-3 font14 neutral4">Two-factor authentication (2FA)</div>
                <p>Verified. Two-factor authentication (2FA) is now enabled.</p>
                <p>The next time you login you will be asked to provide both your password and an authentication token.</p>
                <p>Ensure your authentication app is available on your phone from now on to avoid being locked out of your account.</p>
                <hr/>
                <h5><b>Important</b> - Save Your Recovery Codes</h5>
                <p>To avoid getting locked out of your account copy these recovery codes to a safe and secure place. There are ten codes and each one is one-time use only. These codes should be used to regain access to your account if you lose access to your authentication device. Once you have regained access it is advisable to disable and re-enable two-factor authentication for use with your new or replacement device. These codes cannot be viewed again, this is the only opportunity to copy them down. If you need to regenerate codes, disable and enable two-factor authentication, this is only possible when already logged in.</p>
                {recoveryCodesDisplay}
                <hr/>
                <br/>
                <button type="button" className="btn btn-cancel mt-3" onClick={this.cancel}>Done</button>
                </div>
            )
        } else if (this.state.state === "disabled") {
            return (
                <div>
                <div className="mb-3 font14 neutral4">Two-factor authentication (2FA)</div>
                <p>Two-factor authentication (2FA) is now disabled.</p>
                <small><span className="fail">{this.state.error}</span></small>
                <hr/>
                <br/>
                <button type="button" className="btn btn-cancel mt-3" onClick={this.cancel}>Done</button>
                </div>
            )
        }
    }
};

export default TwoFactorAuthenticationEnable