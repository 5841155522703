/*global */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Box from './Box'
import Request from './Request'
import CareersRole from './CareersRole'

class Careers extends Component {
    constructor () {
        super()
        this.state = {roles: [], loading: false}
    }

    componentDidMount () {
        this.setState({loading: true})
        Request.get("/careers-roles", {}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({roles: data.roles, loading: false});
            }
        });
    }

    render () {
        let rolesContent = []
        if (this.state.roles.length > 0) {
            for (let i = 0; i < this.state.roles.length; i++) {
                const role = this.state.roles[i]
                rolesContent.push(<CareersRole key={role.id} role={role}/>)
            }
        } else {
            rolesContent = [<p key={0}>No roles.</p>]
        }
        return (
            <div>
                <Helmet>
                    <title>Tesults - Careers</title>
                    <meta name="description" content="Tesults careers"/>
                </Helmet>
                <h1>Careers</h1>
                <p>The Tesults team is remote-first and globally distributed but primarily based in the US and UK. The team includes software engineers and leadership with experience working in the biggest tech companies in the world. We are committed to ensuring Tesults is the best way to manage test results data. We release regularly to enhance the service with better analysis technology and automation workflows to help engineering teams.</p>
                <p>Come onboard and join us, we are currently hiring for the roles below.</p>
                {rolesContent}
            </div>
        );
    }
}

export default Careers