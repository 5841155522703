/*global */
import React, { Component } from 'react';

class Embed extends Component {
    render () {
        return (
            <div className="embed-border">
                <p><img width="80" height="80" alt="" src="img/tesults-logo.png"/></p>
                <p className="embed-border-text"><strong>Tesults</strong></p>
                <hr/>
                <p className="embed-border-text"><strong>Store and report your automated test results</strong></p>
            </div>
        );
    }
}

export default Embed;