/*global*/
import React, { Component } from 'react';
import Request from './Request';
import Confirmation from './Confirmation';


class BlogEditor extends Component {
    constructor () {
        super();
        this.state = {title: "", desc: "", body:"", confirmation: {success: undefined, failure: undefined}};
        this.titleChange = this.titleChange.bind(this);
        this.descChange = this.descChange.bind(this);
        this.titleImageChange = this.titleImageChange.bind(this);
        this.bodyChange = this.bodyChange.bind(this);
        this.blogPostPreview = this.blogPostPreview.bind(this);
        this.blogPostSubmit = this.blogPostSubmit.bind(this);
    }

    titleChange(e) {
        this.setState({title: e.target.value});
    }

    descChange (e) {
        this.setState({desc: e.target.value});
    }

    titleImageChange (e) {
        this.setState({titleImage: e.target.value});
    }

    bodyChange (e) {
        this.setState({body: e.target.value});
    }

    blogPostPreview () {
        Request.post("/blog-post-preview", {title: this.state.title, desc: this.state.desc, titleImage: this.state.titleImage, body: this.state.body}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}});
            } else {
                this.setState({confirmation: {success: "Preview updated. View here: https://www.tesults.com/blog?id=preview", failure: undefined}});
            }
        });
    }

    blogPostSubmit () {
        Request.post("/blog-post", {title: this.state.title, desc: this.state.desc, titleImage: this.state.titleImage, body: this.state.body}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err.toString()}});
            } else {
                this.setState({confirmation: {success: "Blog updated. View here: https://www.tesults.com/blog", failure: undefined}});
            }
        });
    }

    render () {
        return (
            <div className='primary8bg p-3' style={{"display":"flex", "flexDirection":"column", "width":"900px"}}>
                <div className='width90'>
                    <h5>Title</h5>
                    <input type="text" className="tr-input-width-100" id="title" name="title" placeholder="Title" value={this.state.title} onChange={this.titleChange} required/>
                </div>
                <div className='width90'>
                    <h5>Description</h5>
                    <input type="text" className="tr-input-width-100" value={this.state.desc} id="desc" name="desc" placeholder="Description" onChange={this.descChange} required/>
                </div>
                <div className='width90'>
                    <h5>Title Image</h5>
                    <input type="text" className="tr-input-width-100" value={this.state.titleImage} id="titleImage" name="titleImage" placeholder="Title Image(e.g. /blog-title/title-image.jpg)" onChange={this.titleImageChange} required/>
                </div>
                <div>
                    <h5>Body</h5>
                    <textarea rows="20" cols="120" value={this.state.body} className="form-control" id="body" name="body" placeholder="Body" onChange={this.bodyChange} required/>
                </div>
                <div style={{"display":"flex"}}>
                    <button type="button" className="btn-cancel mr-3" onClick={this.blogPostPreview}>Preview</button>
                    <button type="button" className="btn-confirm" onClick={this.blogPostSubmit}>Submit</button>
                </div>
                <div className="mt-4">
                    <Confirmation confirmation={this.state.confirmation}/>
                </div>
            </div>
        );
    }
}

export default BlogEditor;