import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ManualSelect extends Component {
    render () {
        return (
            <Link key={this.props.key} to={"/manual/msp/" + this.props.run.id + "/" + this.props.run.created} className="nounderline">
                <div className="case caseDiv mb-2" style={{"marginLeft": this.props.indent + "px"}}>
                    <div className="pl-4 pr-3" style={{"display":"flex", "alignItems":"center"}}>
                        <div className="dot accentc3bg mr-3"></div> 
                        <div class="site-link">
                            <span className="accentc3 mr-3"> In Progress</span>
                            {this.props.run.label}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default ManualSelect;