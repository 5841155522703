/*global */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MultiCaseEditOptions from './MultiCaseEditOptions'
import SideItem from './SideItem'
import Confirmation from './Confirmation'

class ReleaseChecklistSide extends Component {
    constructor () {
        super()
        this.state = {deleteConfirmDisplay: false}
        this.renameChecklist = this.renameChecklist.bind(this)
        this.deleteChecklist = this.deleteChecklist.bind(this)
        this.deleteChecklistCancel = this.deleteChecklistCancel.bind(this)
        this.confirmDeleteChecklist = this.confirmDeleteChecklist.bind(this)
    }

    renameChecklist () {
        this.props.renameChecklist()
    }

    deleteChecklist () {
        this.setState({deleteConfirmDisplay: true})
    }

    deleteChecklistCancel () {
        this.setState({deleteConfirmDisplay: false})
    }

    confirmDeleteChecklist () {
        this.props.deleteChecklist()
    }

    render() {
        return (
            <div>
                <div className="list-side">
                    <SideItem image="/img/show-sidebar.svg" label="Back to checklist menu" onClick={() => {
                        this.props.clearChecklists()
                        this.props.history.push('/release-checklists')
                    }
                    }/>
                    <SideItem image="/img/add-outline.svg" label="Add item to checklist" onClick={this.props.addItem}/>
                    <SideItem image="/img/edit-item.svg" label="Rename checklist" onClick={this.renameChecklist}/>
                    <SideItem image="/img/delete-item.svg" label="Delete checklist" onClick={this.deleteChecklist}/>
                    {
                        this.state.deleteConfirmDisplay === true ?
                        <div>
                            <button className='btn-cancel mb-3' onClick={this.confirmDeleteChecklist}>Confirm checklist delete</button>
                            <br/>
                            <button className='btn-confirm mb-3' onClick={this.deleteChecklistCancel}>Cancel delete</button>
                            <br/>
                            <div className='font14 neutral4 mb-3'>Data will be permanently lost. This is irreversible.</div>
                            <div className='mb-3'>
                                <Confirmation confirmation={this.props.confirmation}/>
                            </div>
                        </div>
                        :
                        <span></span>
                    }
                </div>
            </div>
        )
    }
};

export default ReleaseChecklistSide