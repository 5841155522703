/*global */
import React, { Component } from 'react';

class FeatureRequestSide extends Component {
    render () {
        return (
            <div>
            </div>
        );
    }
};

export default FeatureRequestSide;