/*global */
import React, { Component } from 'react';
import Context from './Context'
import Confirmation from './Confirmation'
import Request from './Request';

class TargetRawResultMap extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {map: undefined, confirmation: {success: undefined, failure: undefined}}
        this.init = this.init.bind(this)
        this.map = this.map.bind(this)
    }

    componentDidMount () {
        this.init()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.targetIndex !== undefined && prevProps.targetIndex !== undefined) {
            if (this.props.targetIndex !== prevProps.targetIndex) {
                this.init()
            }
        }
    }

    init () {
        if (this.props.targets !== undefined && this.props.targetIndex !== undefined) {
            if (this.props.targetIndex < this.props.targets.length) {
                let target = this.props.targets[this.props.targetIndex]
                if (target.rawResultMap) {
                    this.map(target)
                } else {
                    this.setState({map: undefined})
                }
            }
        }
    }

    map (target) {
        this.setState({loading: true})
        Request.get("/raw-result-map-get-map", {id: target.id, created: target.rawResultMap}, (err, data) => {
            if (err) {
                this.setState({loading: false, confirmation: {success: undefined, failure: "Error fetching data"}});
            } else {
                this.setState({loading: false, map: data.map, confirmation: {success: undefined, failure: undefined}})
            }
        });
    }

    render () {
        if (this.state.map === undefined) {
            return (
                <div className='font14'>
                    <p>This target uses the default Tesults mapping for result interpretation. Administrators can change mapping in the configuration menu.</p>
                </div>
            )
        } else {
            return (
                <div className='font14'>
                    <p>This target uses the <b>{this.state.map.label}</b> mapping for result interpretation. Administrators can change mapping in the configuration menu.</p>
                </div>
            )    
        }
    }
};

export default TargetRawResultMap