/*global */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class DocsRefund extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Cancel and Refund</title>
                    <meta name="description" content="Tesults provides fair and easy to understand pricing and you cancel at any time. Learn more about the cancel and refund policy."/>
                </Helmet>
                <p>You may cancel free and paid plans at any time. When you are on a paid plan you sign up for monthly or annual billing. Monthly billing plans offer flexibility and annual plans are discounted.</p>

                <p>To cancel your plan you simply delete your project from the configuration menu. <b>This action ensures no further payments will be taken</b>. No further action is necessary to cancel your plan.</p>

                <p><b>If your project is on a monthly plan canceling from the configuration menu results in cancellation immediately and deletes the project. On a monthly plan you cannot receive a refund however you can choose to contact support to have cancellation take place at the end of the billing period and have continued access to the project. In this case do not cancel using the configuration menu.</b></p>
                
                <p><b>If you are on an annual plan you can receive a partial refund if you cancel within the first month of starting the plan or within the first month of a subsequent annual renewal for the year you cancel.</b></p>
                
                <p>To receive a refund, after you have completed the cancellation, contact us and let us know you would like a partial refund and we will take care of it. We ask that you make this request within 14 days of cancellation otherwise no refund will be provided.</p>

                <p>You can receive a partial refund only if you cancel within the first month of starting on the annual plan. Month here refers to the billing cycle month you would have had if you signed up for a monthly plan instead of an annual plan. For example if your subscription starts on 5 January, the month ends on 4 February.</p>

                <p>The partial refund is calculated by treating your first month as if you had been on a monthly plan instead of annual. You will receive a refund of the annual plan price you paid less one month paid at the monthly price.</p>

                <p>For example you start an annual plan on 10 April. You can cancel at any time but you can receive a partial refund only if you cancel up until 9 May. For example you start an annual plan on 10 April and cancel on 15 May, there is no refund. Another example, you start an annual plan on 10 April and cancel on 2 May. In this case there would have been seven days remaining in the billing cycle if you had been paying monthly instead of annual (3rd to 9th May). You will receive a partial refund of the annual payment you made less the cost of one month paid at the monthly price of the equivalent plan (charged as if you had been paying monthly).</p>
                
                <p>Review the Cancel and Refund section of the Tesults Terms of Service for complete details.</p>
            </div> 
        );
    }
}

export default DocsRefund;