
/*global */
import React, { Component } from 'react';

class DocsDynamic extends Component {
    render() {
        return (
            <div className="mb-3">
                <h2>Dynamically created test cases</h2>
                <p>If you dynamically create test cases, such as test cases with variable values, we recommend that the test suite and test case names themselves be static. Provide the variable data information in the test case description or other custom fields but try to keep the test suite and test name static. If you change your test suite or test name on every test run you will not benefit from a range of features Tesults has to offer including test case failure assignment and historical results analysis. You need not make your tests any less dynamic, variable values can still be reported within test case details.</p>
            </div>
        );
    }
}

export default DocsDynamic;