/*global */
import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';
import ReactJson from 'react-json-view';
import Collapse from './Collapse';

class TargetDetailField extends Component {
    constructor () {
        super();
        this.state = {collapsed: false}
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse () {
        if (this.state.collapsed === true) {
            this.setState({collapsed: false});
        } else {
            this.setState({collapsed: true});
        }
    }

    render () {
        return (
            <div className="target-detail-field">
                <div className="case-detail-label">
                    <div className="case-detail-label-content">
                        <div style={{"display":"flex", "alignItems":"top"}}>
                            <div className='mr-3' style={{"marginTop":"2px"}}>{this.props.icon}</div>
                            <div>{this.props.label}</div>
                        </div>
                        <div className="case-detail-toggle">
                            <span className="caseLink" onClick={this.toggleCollapse}><Collapse collapse={this.state.collapsed}/></span>
                        </div>
                    </div>
                </div>
                {
                    this.state.collapsed ?
                    <div></div>
                    :
                    <div className="case-detail-value">
                        <div className="case-detail-value-content">
                            {this.props.value}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default TargetDetailField;