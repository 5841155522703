/*global */
import React, { Component } from 'react';
import CaseDetailField from './CaseDetailField';
import Currency from './Currency';

class Cost extends Component {
    constructor() {
        super();
        this.state = {state: "view"};
        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.delete =this.delete.bind(this);
        this.costValueChange = this.costValueChange.bind(this);
        this.costCurrencyChange = this.costCurrencyChange.bind(this);
        this.costPeriodChange = this.costPeriodChange.bind(this);
    }

    componentDidMount () {
        this.setState({ cost: this.props.case.cost});
    }

    componentWillReceiveProps (props) {
        this.setState({ cost: props.case.cost});
    }

    edit () {
        if (this.state.cost === undefined) {
            this.setState({state: "edit", cost: {value: 0, currency: "USD", period: "day"}})
        } else {
            this.setState({state: "edit"});
        }
    }

    cancel () {
        this.setState({state: "view"});
    }

    delete () {
        if (this.props.persistentCaseDataUpdate !== undefined) {
            let c = this.props.case;
            c.cost = undefined;
            this.props.persistentCaseDataUpdate([c], function (err) {
                if (err) {
                    this.setState({error: err});
                } else {
                    this.setState({state:"view", cost: undefined});
                }
            }.bind(this));
        }
    }

    save () {
        if (this.props.persistentCaseDataUpdate !== undefined) {
            let cost = this.state.cost;
            let c = this.props.case;
            c.cost = cost;
            this.props.persistentCaseDataUpdate([c], function (err) {
                if (err) {
                    this.setState({error: err});
                } else {
                    this.setState({state:"view"});
                }
            }.bind(this));
        }
    }

    costValueChange (e) {
        let cost = this.state.cost;
        cost.value = parseFloat(e.target.value);
        this.setState({cost: cost});
    }

    costCurrencyChange (e) {

    }

    costPeriodChange (e) {

    }

    render() {
        let cost = <span></span>
        if (! (this.props.view === "results" || this.props.view === "supplemental")) {
            return <span></span>
        }
        if (this.props.case.suite === "[build]") {
            return <span></span>
        }
        let costLabel = <span>Cost</span>
        let costValue = <span></span>
        if (this.state.state === "view") {    
            if (this.state.cost === undefined) {
                if (this.props.trl !== true) { 
                    costValue = 
                    <div>
                        <div className="mb-3">No cost value is currently assigned.</div>
                        <button type="button" className="btn btn-select mt-2" onClick={this.edit}>Add cost</button>
                    </div>
                } else {
                    costValue = <div className="">No cost value is currently assigned.</div>
                }
            } else {
                if (this.props.trl !== true) {
                    costValue =
                    <span>
                        <span><Currency currency={this.state.cost.currency}/>{this.state.cost.value.toFixed(2)}/{this.state.cost.period}</span>
                        <br/>
                        <button type="button" className="btn btn-select mt-2" onClick={this.edit}>Edit cost</button>
                    </span>
                } else {
                    costValue =
                    <span>
                        <span><Currency currency={this.state.cost.currency}/>{this.state.cost.value.toFixed(2)}/{this.state.cost.period}</span>
                    </span>
                }
            }
            cost = <CaseDetailField collapseAll={this.props.collapseAll} mobile={this.props.mobile} label={costLabel} value={costValue} darkMode={this.props.darkMode}/>
        } else if (this.state.state === "edit") {
            costValue =
            <div>
                <div className="neutral3 mb-3">Financial cost of test failure in production.</div>
                <div className="neutral4 mb-1 font14">Cost value</div>
                <input onChange={this.costValueChange} className="tr-input form-control elevation3 mb-2" type="number" min={0} step={0.01} value={this.state.cost.currency.value}/>
                <div className="neutral4 mb-1 font14">Cost currency</div>
                <select className="custom-select width25 elevation3 mb-2" onChange={this.costCurrencyChange} defaultValue={this.state.cost.currency}>
                    <option key="USD" value={"USD"}>USD</option>
                </select>
                <div className="neutral4 mb-1 font14">Cost period</div>
                <select className="custom-select width25 elevation3 mb-2" onChange={this.costPeriodChange} defaultValue={this.state.cost.period}>
                    <option key="day" value={"day"}>Day</option>
                </select>
                <br/>
                <button type="button" className="btn btn-select mt-2" onClick={this.save}>Save</button>
                <br/>
                <button type="button" className="btn btn-select mt-2" onClick={this.cancel}>Cancel</button>
                <br/>
                <button type="button" className="btn btn-select mt-2" onClick={this.delete}>Delete</button>
            </div>
            cost = <CaseDetailField collapseAll={this.props.collapseAll} mobile={this.props.mobile} label={costLabel} value={costValue} darkMode={this.props.darkMode}/>  
        } else {
            cost = <CaseDetailField collapseAll={this.props.collapseAll} mobile={this.props.mobile} label={costLabel} value={costValue} darkMode={this.props.darkMode}/>
        }

        return cost;
    }
};

export default Cost;