/*global */
import React, { Component } from 'react';

class CaseGroupHeader2 extends Component {
    constructor(props) {
        super()
        this.sort = this.sort.bind(this)
    }

    sort (field) {
        if (this.props.groupSortFunc !== undefined) {
            this.props.groupSortFunc(field)
        }
    }

    render () {
        if (this.props.collapse === true) {
            return <span></span>
        }
        return (
            <div className="case-group-sub-header">
                <div className="case-check-box-subheader">
                    
                </div>
                <div className="case-group-sub-header-1 clickable" onClick={() => this.sort(this.props.header0)}>
                    {this.props.header0} {this.props.header0 === "" ? "" : <img src="/img/down-arrow.svg" alt="" width="9" height="9"/>}
                </div>
                <div className="case-group-sub-header-2 clickable" onClick={() => this.sort(this.props.header1)}>
                    {this.props.header1} {this.props.header1 === "" ? "" : <img src="/img/down-arrow.svg" alt="" width="9" height="9"/>}
                </div>
                <div className="case-group-sub-header-3 clickable" onClick={() => this.sort(this.props.header2)}>
                    {this.props.header2} {this.props.header2 === "" ? "" : <img src="/img/down-arrow.svg" alt="" width="9" height="9"/>}
                </div>
                <div className="case-group-sub-header-4 clickable" onClick={() => this.sort(this.props.header3)}>
                    {this.props.header3} {this.props.header3 === "" ? "" : <img src="/img/down-arrow.svg" alt="" width="9" height="9"/>}
                </div>
                <div className="case-group-sub-header-5 clickable" onClick={() => this.sort(this.props.header4)}>
                    {this.props.header4} {this.props.header4 === "" ? "" : <img src="/img/down-arrow.svg" alt="" width="9" height="9"/>}
                </div>
                <div className="case-group-sub-header-6 clickable" onClick={() => this.sort(this.props.header5)}>
                    {this.props.header5}
                </div>
            </div>
        );
    }
};

export default CaseGroupHeader2;