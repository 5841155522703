/*global*/
import React, { Component } from 'react';
import Loading from './Loading'
import Request from './Request'

class Unauthenticated extends Component {
    constructor () {
        super()
        this.state = {loading: true}
        this.ssoLoginEndpoint = this.ssoLoginEndpoint.bind(this);
    }

    componentDidMount () {
        this.ssoLoginEndpoint()
    }

    ssoLoginEndpoint () {
        Request.get("/ssoForRefLoginEndpoint", {ref: this.props.id}, (err, data) => {
            if (err) {
                this.setState({error: "Error fetching SSO data.", loading: false});
            } else {
                this.setState({"ssoLoginEndpoint": data.endpoint, ssoId: data.id, loading: false});
            }
        });
    }


    render () {
        if (this.state.loading === true) {
            return <Loading/>
        } else {
            if (this.state.ssoLoginEndpoint !== undefined) {
                this.props.history.replace({pathname: "/login/" + this.state.ssoId})
            } else {
                this.props.history.push("/login")
            }
            return <span></span>
        }
    }
};

export default Unauthenticated;