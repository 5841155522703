/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Members from './Members'

class ConfigTeam extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.project = this.project.bind(this)
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    render () {
        return (
            <div>
                <Members project={this.project()} overlay={this.props.overlay}/>
            </div>
        );
    }
};

export default ConfigTeam