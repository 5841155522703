/*global*/
import React, { Component } from 'react';

class ResultsHeader5 extends Component {
    render () {
        window.scrollTo(0, 0);
        let title = "Lists"
        let group = ""
        if (this.props.state === "view") {
            if (this.props.lists !== undefined && this.props.listIndex !== undefined) {
                if (this.props.listIndex < this.props.lists.length) {
                    let list = this.props.lists[this.props.listIndex]
                    // Set title
                    title = list.label

                    // Set group
                    if (list.group !== undefined) {
                        group = "";
                        list.group.forEach(function (g) {
                            group += " > " + g;
                        });
                    }
                }
            }
        }
        return (
            <div className="results-header3 whitebg mb-4">
                <div className="results-header5-title">
                    {
                        this.props.state === "view" ?
                        <div style={{"display":"flex", "flexDirection":"column"}}>
                            <div className="font12 neutral4 overflow-ellipsis-no-wrap">{group}</div>
                            <div className="header-title overflow-ellipsis-no-wrap">{title}</div>
                        </div>
                        :
                        <div className="header-title pt-4 pb-4">Lists</div>
                    }
                </div>
                <div className="results-header3-gap">
                    
                </div>
                <div className="results-header3-actions">
                    <div style={{"display": "flex"}}>
                        <div style={{"flex": "1"}}>
                            
                        </div>
                        <div style={{"flex": "1"}}>
                            
                        </div>
                        <div style={{"flex": "1"}}>
                            <button className="select" onClick={() => window.location.reload()}>
                                <img src="/img/refresh.svg" width="19" height="19" alt=""/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader5;