/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Request from './Request';

class ContactMain extends Component {
    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let state = "contact";
        if (user === undefined || user == null) {
            state = "email";
        }
        this.state = {state: state, error: "", subject: "", message: ""};
        this.subjectChange = this.subjectChange.bind(this);
        this.messageChange = this.messageChange.bind(this);
        this.send = this.send.bind(this);
    }
    
    subjectChange (e) {
        this.setState({subject: e.target.value});
    }
    messageChange (e) {
        this.setState({message: e.target.value});
    }
    send (e) {
        e.preventDefault();
        Request.post("/contact", {subject: this.state.subject, message: this.state.message, type: "general"}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to send message. Please send an email."});
            } else {
                this.setState({state: "sent"});
            }
        });
    }
    render () {
        let form = <div className=""><NavLink to="/login" className="">Login</NavLink> and return to this page to use a contact form.</div>
        let formAlign = "center";
        if (this.state.state === "contact") {
            formAlign = "flex-top";
            form =
            <div>
                <div className="mb-4">Submit a message</div>
                <form className="text-left neutral1 mt-3" onSubmit={this.send}>
                    <div className="form-group mb-4">
                        <div className="mb-1 font14 neutral4">Subject</div>
                        <input type="text" className="tr-input form-control" placeholder="" onChange={this.subjectChange} required/>
                    </div>
                    <div className="form-group mb-4">
                        <div className="mb-1 font14 neutral4">Message</div>
                        <textarea type="textarea" rows="5" cols="34" className="form-control neutral7border" placeholder="" onChange={this.messageChange} required></textarea>
                    </div>
                    <div className="accenta1 mb-4">{this.state.error}</div>
                    <button type="submit" className="btn btn-select">Send</button>
                </form>
            </div>
        }
        
        if (this.state.state === "email" || this.state.state === "contact") {
            return (
                <div style={{"maxWidth": "800px"}}>
                    <h1>Contact</h1>
                    <div className="mt-5" style={{"display":"flex", "flex-direction":"column"}}>
                        <div className="mb-5">
                            <p>Have questions or feedback? Get in contact. The team at Tesults will be happy to help.</p>
                        </div>
                        <div className="mb-5" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"maxWidth":"40px", "flex":"1"}}><a className="site-link" href="mailto:help@tesults.com"><img src="/img/envelope.svg" alt="" width="16" height="16"/></a></div>
                            <div style={{"flex":"5"}}>Email <a className="site-link" href="mailto:help@tesults.com">help@tesults.com</a></div>
                        </div>
                        <div className="mb-5" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"maxWidth":"40px", "flex":"1"}}><a className="site-link" href="mailto:help@tesults.com"><img src="/img/phone.svg" alt="" width="16" height="16"/></a></div>
                            <div style={{"flex":"5"}}>Request a call at a time of your choosing by email or form.</div>
                        </div>
                        <div className="mb-5" style={{"display":"flex", "alignItems": formAlign}}>
                            <div style={{"maxWidth":"40px", "flex":"1"}}><img src="/img/inbox-full.svg" alt="" width="16" height="16"/></div>
                            <div style={{"flex":"5"}}>{form}</div>
                        </div>
                        <div className="mb-5" style={{"display":"flex", "alignItems":"center"}}>
                            <div style={{"maxWidth":"40px", "flex": "1"}}><a href="https://twitter.com/Tesults" target="_blank" rel="noopener noreferrer"><img className="neutral0" src="/img/twitter.svg" alt="twitter" width="24" height="24"/></a></div>
                            <div style={{"flex":"5"}}>Tweet or send a direct message on Twitter to @Tesults</div>
                        </div>
                        <div className="mb-5">
                            {/*<hr/>
                            <div className="mt-3 neutral3 font12">Tesults is registered in England and Wales as Tesults Ltd (No. 13084522), 2 Leman Street, London, E1W 9US, UK.</div>
                            <div>
                                <img src="/img/Illustrations_Tesults_002Artboard-1.png" width="128" height="128" alt=""/>
                            </div>*/}
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.state === "sent") {
            return(
                <div style={{"maxWidth": "800px"}}>
                    <h1>Contact</h1>
                    <p>Message submitted successfully. Thank you, one of our team members will get back to you soon.</p>
                </div>
            );
        }
    }
};

export default ContactMain;