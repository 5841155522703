    /*global*/
import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import SelectOption from './SelectOption';
import PassRate from './PassRate'

class SelectOverlay extends Component {
    constructor (props) {
        super(props);
        this.ref = React.createRef();
        
        let icon =
        {
            image: "/img/menu-project-default.svg",
            darkImage: "/img/menu-project-default-dark.svg",
            width: "34",
            height: "34"
        };
        
        if (this.props.type === "target" || this.props.type === "target-preview") {
            icon = {
                    image: "/img/menu-target-default.svg",
                    darkImage: "/img/menu-target-default-dark.svg",
                    width: "21",
                    height: "21",
                    widthLarge: "42",
                    heightLarge: "42"
                }
        }

        if (this.props.type === "member") {
            icon = {
                    image: "/img/menu-target-default.svg",
                    darkImage: "/img/menu-target-default-dark.svg",
                    width: "21",
                    height: "21",
                    widthLarge: "42",
                    heightLarge: "42"
                }
        }

        this.state = {
            active: undefined, 
            label: undefined, 
            action: undefined,
            icon: icon
        };
        
        this.options = this.options.bind(this);
        this.activate = this.activate.bind(this);
        this.select = this.select.bind(this);
        this.imageSrc = this.imageSrc.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.options !== undefined) {
            if (prevProps.options !== undefined) {
                if (this.props.options.length !== prevProps.options.length && (this.props.options.length !== 0 && prevProps.options.length !== 0)) {
                    this.props.overlay(undefined)
                    this.activate()
                }
            }
        }
        if (this.props.group !== prevProps.group) {
            this.props.overlay(undefined)
            this.activate()
        }
    }

    options () {
        if (this.props.options === undefined) {
            return <span></span>
        }
        let options = [];

        if (this.props.type === "target") {
            let groupOptions = []
            if (this.props.groups !== undefined) {
                for (let i = 0; i < this.props.groups.length; i++) {
                    let group = this.props.groups[i]
                    let selected = this.props.group === group ? true : false
                    groupOptions.push(<option value={group} selected={selected}>{group}</option>)
                }
            }

            options.push(
                <SelectOption 
                    //selected={selected}
                    content = {
                        <div style={{"display":"flex", "alignItems":"center"}} key="group">
                            <div style={{"width":"140px"}} className='neutral4 neutral8bg rounderborder pl-3 pt-3 pb-3 font14'>Display Group:</div>
                            <div className="menu-item-label pr-5">
                                <select name="group" id="group-select" onChange={(e) => {this.props.groupChange(e.target.value)}}>
                                    {groupOptions}
                                </select>
                            </div>
                        </div>
                    }
                />
            );
        }

        for (let i = 0; i < this.props.options.length; i++) {
            if (this.props.type === "project") {
                let imageSrc = this.imageSrc(this.props.options[i]);
                let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
                let imageClass = "menu-item-icon";
                if (imageSrc !== undefined) {
                    image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                    imageClass = "menu-item-icon-no-padding";
                }
                options.push(
                    <button className="select-option" onClick={() => this.select(i)} key={i}>
                        <div className="menu-item-circle-project-select">
                            <div className={imageClass}>
                                {image}
                            </div>
                        </div>
                        <div className="menu-item-label pr-5">
                            <span className="font15">{this.props.options[i][this.props.label]}</span>
                        </div>
                    </button>
                );
            } else if (this.props.type === "target") {
                let imageSrc = this.imageSrc(this.props.options[i]);
                let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
                let imageClass = "menu-item-icon";
                if (imageSrc !== undefined) {
                    image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                    imageClass = "menu-item-icon-no-padding";
                }
                let selected = false
                if (this.props.index === i) {
                    selected = true
                }

                let target = this.props.options[i]
                let addOption = true
                if (this.props.group !== "All") {
                    if (target.group === undefined) {
                        addOption = false
                    } else {
                        let addOptionSub = false
                        for (let g = 0; g < target.group.length; g++) {
                            let group = target.group[g]
                            if (this.props.group === group) {
                                addOptionSub = true
                            }
                        }
                        if (addOptionSub !== true) {
                            addOption = false
                        }
                    }
                }

                if (addOption) {
                    options.push(
                        <SelectOption 
                            selected={selected}
                            content = {
                            <button className="select-option" onClick={() => this.select(i)} key={i}>
                                <div style={{"display":"flex", "alignItems":"center"}}>
                                    <div className="menu-item-circle-project-select">
                                        <div className={imageClass}>
                                            {image}
                                        </div>
                                    </div>
                                    <div className="menu-item-label pr-5">
                                        <div className="font15 select-label">{this.props.options[i][this.props.label]}</div>
                                    </div>
                                </div>
                            </button>
                            }
                        />
                    );
                }
            } else if (this.props.type === "target-group") {
                options.push(
                    <button className="select-option" onClick={() => this.select(this.props.options[i][this.props.label])} key={i}>
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div className="menu-item-label pr-5">
                                <div className="font15 select-label">{this.props.options[i][this.props.label]}</div>
                            </div>
                        </div>
                    </button>
                );
            } else if (this.props.type === "target-preview") {
                let imageSrc = this.imageSrc(this.props.options[i]);
                let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
                let imageClass = "menu-item-icon";
                if (imageSrc !== undefined) {
                    image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                    imageClass = "menu-item-icon-no-padding";
                }
                options.push(
                    <button className="select-option" onClick={() => this.select(i)} key={i}>
                        <div className="menu-item-circle-project-select">
                            <div className={imageClass}>
                                {image}
                            </div>
                        </div>
                        <div className="menu-item-label pr-5">
                            <span className="font15">{this.props.options[i][this.props.label]}</span>
                        </div>
                    </button>
                );
            } else if (this.props.type === "run") {
                const run = this.props.options[i]
                let prevRun = undefined
                if (i + 1 < this.props.options.length) {
                    prevRun = this.props.options[i + 1]
                }
                options.push(
                    <button className="select-option" onClick={() => this.select(i)} key={i}>
                        <div className="select-option-icon">
                            <img src="/img/time.svg" width="16" height="16" alt=""/>
                        </div>
                        <div className="menu-item-label pr-5">
                            <div className='flex-column'>
                                <div className="font14 mb-1">{TimeFormatted.fromNowRun(this.props.options[i][this.props.label])}</div>
                                <div>
                                    <PassRate 
                                        type="run" 
                                        run={run} 
                                        prevRun={prevRun}
                                    />
                                </div>
                                {run.buildName === undefined ? <span></span> : <div className="font14 overflow-ellipsis-no-wrap">{run.buildName}</div>}
                            </div>    
                        </div>
                    </button>
                );
            } else if (this.props.type === "sort" || this.props.type === "filter") {
                options.push(
                    <button className="select-option" onClick={() => this.select(this.props.options[i][this.props.value])} key={i}>
                        <div className="select-option-icon">
                            {this.props.image}
                        </div>
                        <div className="menu-item-label pr-5">
                            <span className="font14">{this.props.options[i][this.props.label]}</span>
                        </div>
                    </button>
                );
            } else if (this.props.type === "cost") {
                options.push(
                    <button className="select-option" onClick={() => this.select(this.props.options[i][this.props.value])} key={i}>
                        <div className="menu-item-label pr-5">
                            <span className="font14">{this.props.options[i][this.props.label]}</span>
                        </div>
                    </button>
                );
            } else if (this.props.type === "generic") {
                let labelClass = "font14"
                if (this.props.labelClass !== undefined) {
                    labelClass = this.props.labelClass
                }
                options.push(
                    <button className="select-option" onClick={() => this.select(this.props.options[i][this.props.value])} key={i}>
                        <div className="select-option-icon" style={{"width":"20px"}}>
                            {this.props.options[i]["img"]}
                        </div>
                        <div className="menu-item-label pr-5">
                            <span className={labelClass}>{this.props.options[i][this.props.label]}</span>
                        </div>
                    </button>
                );
            } else if (this.props.type === "member") {
                let imageSrc = this.imageSrc(this.props.options[i]);
                let image = 
                <div style={{"width":"34px", "height":"34px", "display":"flex", "alignItems":"center", "justifyContent":"center"}} className="circle34 neutral7border neutral7bg neutral2">
                    <div>{this.props.options[i].firstName.slice(0,1) + this.props.options[i].lastName.slice(0,1)}</div>
                </div>
                let imageClass = "menu-item-icon";
                if (imageSrc !== undefined) {
                    image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                    imageClass = "menu-item-icon-no-padding";
                    image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                }

                let selected = false
                if (this.props.index === i) {
                    selected = true
                }

                options.push(
                    <SelectOption 
                        selected={selected}
                        content = {
                        <button className="select-option" onClick={() => this.select(i)} key={i}>
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                <div className="">
                                    <div className={imageClass}>
                                        {image}
                                    </div>
                                </div>
                                <div className="menu-item-label pr-5">
                                    <div className="font15 select-label">{this.props.options[i][this.props.label]}</div>
                                </div>
                            </div>
                        </button>
                        }
                    />
                );
            } else if (this.props.type === "case-enhanced-analysis-period") {
                options.push(
                    <button className="select-option" onClick={() => this.select(this.props.options[i][this.props.value])} key={i}>
                        <div className="menu-item-label pr-5">
                            <span className="font14">{this.props.options[i][this.props.label]}</span>
                        </div>
                    </button>
                );
            }  else {
                
            }
        }

        // Additional system option
        if (this.props.type === "target") {
            const image = <img alt="" src="/img/target-default-128.png" width="34" height="34" className="circle34 img34 noborder"/>
            const imageClass = "menu-item-icon-no-padding";
            if (this.props.role >= 3) {
                options.push(
                    <a href="/config/project/create-target" className="caseLink">
                    <button className="select-option" key="newTarget">
                        <div style={{"display":"flex", "alignItems":"center"}}>
                            <div className="menu-item-circle-project-select">
                                <div className={imageClass}>
                                    {image}
                                </div>
                            </div>
                            <div className="menu-item-label pr-5">
                                <div className="font15 select-label primary4">Add a new target for reporting results for additional test jobs</div>
                            </div>
                        </div>
                    </button>
                    </a>
                );  
            }
        }
        if (this.props.type === "run") {
            if (this.props.moreRuns === true) {
                options.push(
                    <button className="select-option" onClick={() => this.props.getMoreRuns()} key={"moreRuns"}>
                        <div className="select-option-icon">
                            <img src="/img/time.svg" width="16" height="16" alt=""/>
                        </div>
                        <div className="menu-item-label pr-5">
                            <span className="font14">Load more test runs</span>
                        </div>
                    </button>
                );
            }
        }
        if (this.props.type === "generic") {
            let labelClass = "font14"
            if (this.props.labelClass !== undefined) {
                labelClass = this.props.labelClass
            }
            if (this.props.moreOptions === true) {
                options.push(
                    <button className="select-option" onClick={() => this.props.getMoreOptions()} key={"moreOptions"}>
                        <div className="select-option-icon">
                            <img src="/img/ellipsis.svg" width="16" height="16" alt=""/>
                        </div>
                        <div className="menu-item-label pr-5">
                            <span className={labelClass}>Load more</span>
                        </div>
                    </button>
                );
            }
        }
        
        return options;
    }

    activate () {
        if (this.props.options !== undefined) {
            let options = this.options();
            let heightPixels = 50 * options.length; // 50 because 'select-overlay' has height 50px
            let optionsContent = 
            <div className="pb-5">
                {options}
            </div>
            this.props.overlay(optionsContent, this.ref, heightPixels, this.props.contentWidth);
        } else {
            this.props.overlay(this.props.content, this.ref, undefined, this.props.contentWidth);
        }
        if (this.props.active !== undefined) {
            this.props.active();
        }
    }

    select (value) {
        this.props.overlay(undefined);
        this.props.valueChange(value);
    }

    imageSrc (object) {
        let imageSrc = undefined;
        if (this.props.type === "project") {
            if (object.aext !== undefined) {
                imageSrc = '/project/avatar/pdla/' + object.id + "/" + object.aext;
            }
        }
        if (this.props.type === "target" || this.props.type === "target-preview") {
            if (object.aext !== undefined) {
                imageSrc = '/target/avatar/pdla/' + object.id + '/' + object.created + '/' + object.aext;
            }
        }
        if (this.props.type === "member") {
            if (object.aext !== undefined) {
                imageSrc = '/user/avatar/pdla/' + object.id + '/' + object.aext;
            }
        }
        if (this.props.type === "run") {
            imageSrc = ""
        }
        return imageSrc;
    }

    render () {
        /*if (this.props.options === undefined && this.props.content === undefined) {
            return <span></span>
        }
        if (this.state.icon === undefined) {
            return <span></span>
        }*/

        let label = "";
        let imageSrc = undefined;
        let options = <span></span>
        if (this.props.options !== undefined) {
            let index = this.props.index;
            if (this.props.index >= this.props.options.length) {
                index = 0;
            }
            options = [];
            for (let i = 0; i < this.props.options.length; i++) {
                const labelValue = this.props.options[i][this.props.label];
                options.push(<span key={i}>{labelValue}</span>)
                if (index === i) {
                    label = labelValue;
                    imageSrc = this.imageSrc(this.props.options[i]);
                }
            }
        }
        
        if (this.props.type === "project") {
            let image = <img alt="" src={this.state.icon.image} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
            let imageClass = "menu-item-icon";
            if (imageSrc !== undefined) {
                image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                imageClass = "menu-item-icon-no-padding";
            }
            if (this.props.full === true) {
                return (
                    <button className="select" onClick={this.activate} ref={this.ref}>
                        <div className="menu-item-inactive clickable">
                            <div className="menu-item-circle-project">
                                <div className={imageClass}>
                                    {image}                        
                                </div>
                            </div>
                            <div className="menu-item-label select-label">
                                <div className="width100" style={{"display":"flex", "alignItems":"center"}}>
                                    <div className="white font15 select-label">
                                        <abbr className="tr-abbr" title={label}>{label}</abbr>
                                    </div>
                                    <div>
                                        <img alt="" src="/img/cheveron-down.svg" style={{"marginTop":"-12px"}} width="20" height="14"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </button>
                );
            } else {
                return (
                    <button className="select" onClick={this.activate} ref={this.ref}>
                        <div className="menu-item-inactive clickable">
                            <div className="menu-item-circle-project">
                                <div className={imageClass}>
                                    <abbr className="tr-abbr" title={label}>{image}</abbr>
                                </div>
                            </div>
                        </div>
                    </button>
                );
            }
        } else if (this.props.type === "target") {
            let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.widthLarge} height={this.state.icon.heightLarge} preserveAspectRatio="none"/>
            let imageClass = "menu-item-icon";
            if (imageSrc !== undefined) {
                image = <img alt="" src={imageSrc} width="45" height="45" className="circle45 img45 noborder"/>
                imageClass = "menu-item-icon-no-padding";
            }
            return (
                <button style={{"height":"50px"}}  className="select" onClick={this.activate} ref={this.ref}>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className="target-item-circle-select">
                            <div className={imageClass}>
                                {image}
                            </div>
                        </div>
                        <div className="select-label font16 bold">
                            <abbr className="tr-abbr" title={label}>{label}</abbr>
                        </div>
                        <div className="select-chevron font24">
                            <img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"-4px"}} width="40" height="20" preserveAspectRatio="none"/>
                        </div>
                    </div>
                </button>
            );
        } else if (this.props.type === "target-preview") {
            let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
            let imageClass = "menu-item-icon";
            if (imageSrc !== undefined) {
                image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                imageClass = "menu-item-icon-no-padding";
            }
            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    <div className="menu-item-inactive clickable">
                        <div className="menu-item-circle-project-select">
                            <div className={imageClass}>
                                {image}                        
                            </div>
                        </div>
                        <div className="menu-item-label">
                            <div style={{"display":"flex"}}>
                                <div className="font15 overflow-ellipsis-no-wrap">{label}</div>
                                <div><img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"-12px"}} width="40" height="20" preserveAspectRatio="none"/></div>
                            </div>
                        </div>
                    </div>
                </button>
            );
        } else if (this.props.type === "target-group") {
            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    <div className="menu-item-inactive clickable">
                        <div className="menu-item-label">
                            <div style={{"display":"flex"}}>
                                <div className="font16 bold overflow-ellipsis-no-wrap">{label}</div>
                                <div><img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"3px"}} width="40" height="20" preserveAspectRatio="none"/></div>
                            </div>
                        </div>
                    </div>
                </button>
            );
        } else if (this.props.type === "run") {
            let time = "";
            if (this.props.options === undefined) {
                return <div></div>
            }
            if (this.props.label !== undefined && this.props.index < this.props.options.length) {
                time = 
                    <div className="case-time-duration" style={{"display":"flex", "alignItems":"center", "marginTop":"-4px"}}>
                        <div className="mr-3" style={{"marginTop":"4px"}}>
                            <img src="/img/time.svg" width="16" height="16" alt=""/>
                        </div>
                        <div className="">
                            <span className="font14">{TimeFormatted.fromNowRun(this.props.options[this.props.index][this.props.label])}</span>
                        </div>
                        <div className="pr-5">
                            <img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"6px"}} width="30" height="21"/>
                        </div>
                    </div>
            }
            if (this.props.isRunArchive === true) {
                if (this.props.runArchive !== undefined) {
                    time = 
                    <div className="case-time-duration" style={{"display":"flex", "alignItems":"center", "marginTop":"-4px"}}>
                        <div className="mr-3" style={{"marginTop":"4px"}}>
                            <img src="/img/time.svg" width="16" height="16" alt=""/>
                        </div>
                        <div className="">
                            <span className="font14">{TimeFormatted.fromNowRun(this.props.runArchive.created) + " (Archived)"}</span>
                        </div>
                        <div className="pr-5">
                            <img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"6px"}} width="30" height="21"/>
                        </div>
                    </div>
                }
            }
            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    {time}
                </button>
            )
        } else if (this.props.type === "sort" || this.props.type === "filter") {
            return (
                <button type="button" className='btn btn-white' onClick={this.activate} ref={this.ref}>
                    <div style={{"display":"flex", "alignItems":"center"}}>
                        <div className='mr-3'>
                            <div style={{"marginTop":"4px"}}>
                                {this.props.image}
                            </div>
                        </div>
                        <div>
                            {this.props.title}
                        </div>
                    </div>
                </button>
            );
        } else if (this.props.type === "share" || this.props.type === "ellipsis") {
            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    {this.props.image} {this.props.label}
                </button>
            )
        } else if (this.props.type === "cost") {
            return (
                <div>
                    <div>
                        <span className="neutral2 font12">{this.props.title}</span>
                    </div>
                    <button className="select" onClick={this.activate} ref={this.ref}>
                        {this.props.options[this.props.index][this.props.label]}
                    </button>
                </div>
            );
        } else if (this.props.type === "learnmore") {
            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    <span className="font14">{this.props.label}</span>
                </button>
            );
        } else if (this.props.type === "member") {
            let backupClass = "menu-item-circle-project-select"
            let image = <img alt="" src={this.state.icon.darkImage} width={this.state.icon.width} height={this.state.icon.height} preserveAspectRatio="none"/>
            let imageClass = "menu-item-icon";
            if (imageSrc !== undefined) {
                image = <img alt="" src={imageSrc} width="34" height="34" className="circle34 img34 noborder"/>
                imageClass = "menu-item-icon-no-padding";
            }

            if (this.props.members !== undefined) {
                if (this.props.index < this.props.members.length) {
                    let member = this.props.members[this.props.index]
                    image = 
                    <div style={{"width":"34px", "height":"34px", "display":"flex", "alignItems":"center", "justifyContent":"center"}} className="circle34 neutral7border neutral7bg neutral2">
                        <div>{member.firstName.slice(0,1) + member.lastName.slice(0,1)}</div>
                    </div>
                    if (member.aext !== undefined) {
                        image = <img alt="" src={'/user/avatar/pdla/' + member.id + '/' + member.aext} width="34" height="34" className="circle34 img34 noborder"/>
                    }
                    imageClass = "menu-item-icon-no-padding";
                    backupClass = ""
                }
            }
            
            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    <div className="menu-item-inactive clickable">
                        <div className={backupClass}>
                            <div className={imageClass}>
                                {image}                        
                            </div>
                        </div>
                        <div className="menu-item-label">
                            <div style={{"display":"flex"}}>
                                <div className="font15 overflow-ellipsis-no-wrap">{label}</div>
                                <div><img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"0px"}} width="40" height="20" preserveAspectRatio="none"/></div>
                            </div>
                        </div>
                    </div>
                </button>
            );
        } else if (this.props.type === "generic") {
            /*let img = <img src="/img/tesults-logo-unknown-128.png" width="16" height="16" alt=""/>
            if (this.props.defaultValue === "pass") {
                img = <img src="/img/tesults-logo-pass-128.png" width="16" height="16" alt=""/>
            }
            if (this.props.defaultValue === "fail") {
                img = <img src="/img/tesults-logo-fail-128.png" width="16" height="16" alt=""/>
            }*/
            let img = <span></span>
            if (this.props.options !== undefined && this.props.value !== undefined) {
                let optionSelected = undefined;
                for (let i = 0; i < this.props.options.length; i++) {
                    if (this.props.options[i][this.props.value] === this.props.defaultValue) {
                        optionSelected = this.props.options[i];
                    }
                }
                if (optionSelected !== undefined) {
                    if (optionSelected.img !== undefined) {
                        img = optionSelected.img;
                    }
                }
            }

            let titleClass = "font14"
            if (this.props.titleClass !== undefined) {
                titleClass = this.props.titleClass
            }

            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    <div style={{"display":"flex", "alignItems":"center", "marginTop":"-4px"}}>
                        <div className="mr-3" style={{"marginTop":"4px"}}>
                            {img}
                        </div>
                        <div className="">
                            <span className={titleClass}>{this.props.title}</span>
                        </div>
                        <div className="pr-5">
                            <img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"-12px"}} width="20" height="14"/>
                        </div>
                    </div>
                </button>
                
            );
        } else if (this.props.type === "case-enhanced-analysis-period") {
            // if (this.props.options !== undefined && this.props.value !== undefined) {
            //     let optionSelected = undefined;
            //     for (let i = 0; i < this.props.options.length; i++) {
            //         if (this.props.options[i][this.props.value] === this.props.defaultValue) {
            //             optionSelected = this.props.options[i];
            //         }
            //     }
            // }

            let titleClass = "font14 neutral4"
            if (this.props.titleClass !== undefined) {
                titleClass = this.props.titleClass
            }

            return (
                <button className="select" onClick={this.activate} ref={this.ref}>
                    <div style={{"display":"flex", "alignItems":"center", "marginTop":"-12px"}}>
                        <div className="">
                            <span className={titleClass}>{label}</span>
                        </div>
                        <div className="pr-5">
                            <img alt="" src="/img/cheveron-down-gray.svg" style={{"marginTop":"-12px"}} width="20" height="14"/>
                        </div>
                    </div>
                </button>
            );
        }
    }
};

export default SelectOverlay;