/*global*/
import React, { Component } from 'react';
import ScatterChartControl from './ScatterChartControl';
import ScatterChart from './ScatterChart';
const papa = require('papaparse');
const moment = require('moment');
//import TimeFormatted from './TimeFormatted';


class CsvData extends Component {
    constructor()  {
        super();
        this.state = ({fields: undefined, data: [], selected: {}, format: {}, formatOptions: {}, yRanges: undefined, fieldLimitExceeded: false, error: undefined});
        this.readCsvFile = this.readCsvFile.bind(this);
        this.errorReadCsvFile = this.errorReadCsvFile.bind(this);
        this.processData = this.processData.bind(this);
        this.changeSelected = this.changeSelected.bind(this);
        this.changeFormat = this.changeFormat.bind(this);
        this.changeYRange = this.changeYRange.bind(this);
    }

    componentDidMount () {
        papa.parse(this.props.url, {
            download: true,
            header: true,
            complete: this.readCsvFile,
            error: this.errorReadCsvFile
        });
    }

    readCsvFile (results, file) {
        this.processData(results);
    }

    errorReadCsvFile (err, file) {
        this.setState({error: "Error reading file."});
    }

    changeSelected (field, axis, exclusive) {
        let editSelected = this.state.selected;
        let index = -1;
        let num = 0;
        editSelected[axis].forEach(function (s) {
            if (s === field) {
                index = num;
            }
            num += 1;
        });

        if (index === -1) {
            // not selected, so add
            if (exclusive === true) {
                editSelected[axis] = [field];
            } else {
                editSelected[axis].push(field);
            }
        } else {
            // already selected, so remove
            if (exclusive === true) {
                // in the case of exclusive just select an alternative field to remove   
            } else {
                editSelected[axis].splice(index, 1);
            }
        }

        this.setState({selected: editSelected});
    }

    changeFormat (field, format) {
        let editFormat = this.state.format;
        editFormat[field] = format;
        this.setState({format: editFormat});
    }

    changeYRange(field, minSelected, maxSelected) {
        let yRanges = this.state.yRanges;
        let valMinSelected = Number(minSelected);
        let valMaxSelected = Number(maxSelected);
        let yRange = yRanges[field];
        if (isNaN(valMinSelected) === false) {
            //if (valMinSelected >= yRange.min && valMinSelected <= yRange.max) {
                yRange.minSelected = valMinSelected;
            //}
        } else {
            return;
        }
        if (isNaN(valMaxSelected) === false) {
            //if (valMaxSelected >= yRange.min && valMaxSelected <= yRange.max) {
                yRange.maxSelected = valMaxSelected;
            //}
        } else {
            return;
        }
        if (yRange.minSelected <= yRange.maxSelected) {
            yRanges[field] = yRange;
            this.setState({yRanges: yRanges});
        }
    }

    processData (results) {
        let selected = {};
        let fields = [];
        let format = {};
        let formatOptions = {};
        let yRanges = {};
        let index = 0;
        let fieldLimitExceeded = false;
        results.meta.fields.forEach(function (field) {
            if (index > 49) {
                fieldLimitExceeded = true;
            } else {
                if (field !== "") {
                    fields.push(field);
                }
    
                if (index === 0) {
                    selected.x = [field];
                }
                if (index === 1) {
                    selected.y = [field];
                }
    
                // format
                let options = [];
                if (results.data.length > 0) {
                    let datum = results.data[0];
                    
                    let val = moment(datum[field]);
                    if (val.isValid() === true) {
                        options.push("time");
                    }
    
                    val = Number(datum[field]);
                    if (isNaN(val) === false) {
                        options.push("number");
    
                        let yRange = {};
                        yRange.min = val;
                        yRange.max = val;
                        results.data.forEach(function (datum) {
                            let val =  Number(datum[field]);
                            if (isNaN(val) === false) {
                                if (val < yRange.min) {
                                    yRange.min = val;
                                } else if (val > yRange.max) {
                                    yRange.max = val;
                                }
                            }
                        });
                        yRange.minSelected = yRange.min;
                        yRange.maxSelected = yRange.max;
                        yRanges[field] = yRange;
                    }
    
                    options.push("string");
    
                    format[field] = options[0];
                    formatOptions[field] = options;
                }
            }
            index += 1;
        });
        this.setState({fields: fields, data: results.data, selected: selected, format: format, formatOptions: formatOptions, yRanges: yRanges, fieldLimitExceeded: fieldLimitExceeded});
    }

    render () {
        if (this.state.error !== undefined) {
            let error = this.state.error;
            return <p>{error}</p>
        } else if (this.state.fields === undefined) {
            return <p>Loading ...</p>
        } else {
            let fieldLimitExceeded = <span></span>;
            if (this.state.fieldLimitExceeded === true) {
                fieldLimitExceeded = <p>Showing 50 fields only by default. To enable more please contact support and request it.</p>;
            }
            return (
                <div>
                    <h5 className="neutral1">{this.props.name}</h5>
                    <button className="btn btn-sm btn-confirm" onClick={() => this.props.close(this.props.name)}>Close</button>
                    <ScatterChart selected={this.state.selected} data={this.state.data} format={this.state.format} yRanges={this.state.yRanges}/>
                    {fieldLimitExceeded}
                    <ScatterChartControl fields={this.state.fields} data={this.state.data} selected={this.state.selected} format={this.state.format} formatOptions={this.state.formatOptions} yRanges={this.state.yRanges} changeSelected={this.changeSelected} changeFormat={this.changeFormat} changeYRange={this.changeYRange}/>
                </div>
            );
        }
    }
}

export default CsvData;