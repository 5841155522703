/*global */
import React, { Component } from 'react';
import TimeFormatted from './TimeFormatted';
import MultiTextList from './MultiTextList';
import Request from './Request';
import TabularData from './TabularData';

class AffiliateAdmin extends Component {
    constructor () {
        super();
        this.state = {affiliates: [], esk: undefined};
        this.affiliatesForReview = this.affiliatesForReview.bind(this);
        this.approve = this.approve.bind(this);
        this.reviewComplete = this.reviewComplete.bind(this);
    }

    componentDidMount () {
        this.affiliatesForReview();
    }

    affiliatesForReview () {
        Request.get("/affiliates-for-review", {esk: this.state.affiliateDataEsk}, (err, data) => {
            if (err) {
                this.setState({data: undefined, confirmation: {success: undefined, failure: "Error fetching data"}});
            } else {
                this.setState({affiliates: data.affiliates, esk: data.lek});
            }
        });
    }

    approve (index, approve) {
        let affiliate = this.state.affiliates[index];
        Request.post("/affiliate-approve", {id: affiliate.id, approve: approve}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error approving affiliate"}, disabledButtons: false});
            } else {
                let updatedAffiliate = data.affiliate;
                let affiliates = this.state.affiliates;
                affiliates[index] = updatedAffiliate;
                this.setState({affiliates: affiliates});
            }
        });
    }

    reviewComplete (index) {
        let affiliate = this.state.affiliates[index];
        Request.post("/affiliate-review-complete", {id: affiliate.id}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error completing review of affiliate"}, disabledButtons: false});
            } else {
                let affiliates = this.state.affiliates;
                affiliates.splice(index, 1);
                this.setState({affiliates: affiliates});
            }
        });
    }

    render () {
        if (this.state.affiliates === undefined) {
            return <div></div>
        } else {
            let data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Created", minWidth: "200px"},
                    {value: "Approved", minWidth: "100px"},
                    {value: "Link"},
                    {value: "Sources"},
                    {value: "Approval"},
                    {value: "Review Complete"},
                ],
                rows: []
            }
            for (let i = 0; i < this.state.affiliates.length; i++) {
                let affiliate = this.state.affiliates[i];
                data.rows.push(
                    {
                        cols: [
                            {value: affiliate.id},
                            {value: TimeFormatted.timeformatted(affiliate.created, true)},
                            {value: affiliate.approved ? "Approved" : "Unapproved"},
                            {value: <span>{"https://www.tesults.com/ref/" + affiliate.id}</span>},
                            {value: <MultiTextList list={affiliate.sources}/>},
                            {value: <button className="btn-cancel" onClick={() => {this.approve(i, affiliate.approved ? false : true)}}>{affiliate.approved ? "Set unapproved" : "Set approved"}</button>},
                            {value: <button className="btn-cancel" onClick={() => {this.reviewComplete(i)}}>Review Complete</button>}
                        ]
                    }
                );
            }
            if (data.rows.length === 0) {
                return (
                    <div>
                        <h4 className="neutral3">Affiliate link sources</h4>
                        <p>No link sources to review</p>
                    </div>
                ); 
            } else {
                return (
                    <div className="mb-4">
                        <h4 className="neutral3">Affiliate links</h4>
                        {
                            this.state.esk !== undefined ?
                            <button className="btn-cancel" onClick={this.affiliatesForReview}>Load more</button>
                            :
                            <span></span>
                        }
                        <TabularData data={data}/>
                    </div>
                );
            }
        }
    }
};

export default AffiliateAdmin;