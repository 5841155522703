/*global */
import React, { Component } from 'react';

class ScrollToTop extends Component {
    constructor () {
        super();
        this.state = {display: false};
        this.scrollEvent = this.scrollEvent.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount(){
        window.addEventListener('scroll', this.scrollEvent);
    }

    componentWillUnmount(){
         window.removeEventListener('scroll', this.scrollEvent);
    }

    scrollEvent () {
        if (window.scrollY === 0) {
            this.setState({display: false});
        } else {
            this.setState({display: true});
        }
    }

    scrollToTop () {
        window.scrollTo(0,0);
    }

    render() {
        let style = {
            'position': 'fixed', 
            'right': '10px', 
            'bottom': '40px'
        };

        if (this.props.onLeft === true) {
            style = {
                'position': 'fixed', 
                'left': '10px', 
                'bottom': '40px'
            };
        }

        if (this.state.display === true) {
            return (
                <div style={style} className="infoToolTipBtn caseLink" onClick={this.scrollToTop}><img src="img/cheveron-outline-up.svg" alt="Scroll to top" width="32" height="32"/></div>
            );
        } else {
            return (
                <span></span>
            )
        }
    }
}

export default ScrollToTop;