/*global*/
import React, { Component } from 'react';
import PassRate from './PassRate';
import SelectOverlay from './SelectOverlay';
import SelectSide from './SelectSide';
import Job from './Job';
import { utilsRawResultMapMap } from './Utils';

class ResultsHeader2 extends Component {
    render () {
        window.scrollTo(0, 0);

        const filterOptions = [
            {label: "Fail", value: "fail"}, 
            {label: "Pass", value: "pass"}, 
            {label: "Unknown", value: "unknown"},
            {label: "None", value: "none"}
        ];

        if (this.props.rawResultMaps) {
            const rawResultMap = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps);
            if (rawResultMap) {
                let optionAdded = {};
                if (this.props.cases) {
                    this.props.cases.forEach(c => {
                        if (optionAdded[c.rawResult] === undefined) {
                            optionAdded[c.rawResult] = true;
                            filterOptions.push({label: c.rawResult, value: c.rawResult});
                        }
                    });
                }
            }
        }

        filterOptions.push({label: "Associated Bugs", value: "bugs"});

        if (this.props.overlay) {
            this.props.overlay(undefined);
        }
        
        return (
            <div className="results-header2">       
                <div className="results-header2-actions">
                    <div className="run-select">
                        <SelectOverlay
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            valueChange={this.props.runIndexChange}
                            type="run"
                            label="created"
                            options={this.props.runs}
                            index={this.props.runIndex}
                            moreRuns={this.props.moreRuns}
                            getMoreRuns={this.props.getMoreRuns}
                            context={this.props.view}
                            runArchive={this.props.runArchive}
                            runArchiveBuildCase={this.props.runArchiveBuildCase}
                            runArchiveCases={this.props.runArchiveCases}
                            isRunArchive={this.props.isRunArchive}
                        />
                    </div>
                    <div className="case-time-gap">
                        &nbsp;
                    </div>
                    <div className="results-header1-search">
                        {this.props.view === "results" ? 
                        <input className="search clickable" onChange={(e) => this.props.onSearchChange(e.target.value)} placeholder="Search Test Name"/>
                        : <span></span>
                        }
                    </div>
                    <div className='mr-3'>
                        {
                            this.props.view === "results" ? 
                            <button type="button" className='btn btn-white' onClick={this.props.toggleCollapseAll}>
                            <div style={{"display":"flex", "alignItems":"center"}}>
                                <div className='mr-3'>
                                    <div style={{"marginTop":"4px"}}>
                                        {this.props.collapseAll === true ? <img src="/img/expand1.svg" alt="" width="18" height="18"/> : <img src="/img/collapse1.svg" alt="" width="18" height="18"/>}
                                    </div>
                                </div>
                                <div>
                                    {this.props.collapseAll === true ? "Expand" : "Collapse"}
                                </div>
                            </div>
                            </button>
                            :
                            <span></span>
                        }
                    </div>
                    <div className='mr-3'>
                        {this.props.view === "results" ? 
                        <SelectOverlay
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            type="filter"
                            title="Filter"
                            label="label"
                            value="value"
                            image={<img src="/img/filter1.svg" width="18" height="18" alt="filter"/>}
                            options={filterOptions}
                            valueChange={this.props.filterChange}
                        />
                        : <span></span>
                        }
                    </div>
                    <div className='mr-3'>
                        {this.props.view === "results" ? 
                        <SelectOverlay
                            overlay={this.props.overlay} 
                            messageOverlay={this.props.messageOverlay}
                            type="sort"
                            title="Sort"
                            label="label"
                            value="value"
                            image={<img src="/img/sort1.svg" width="18" height="18" alt="sort"/>}
                            options={[{label: "Suite", value: "suite"}, {label: "Name", value: "name"}, {label: "Result", value: "result"}]}
                            valueChange={this.props.sortChange}
                        />
                        : <span></span>
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default ResultsHeader2;