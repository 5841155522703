/*global Cookies*/
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Request from './Request'
import ReactJson from 'react-json-view'
import SelectOverlay from './SelectOverlay'
import Loading from './Loading'
import Constants from './Constants'
import Cache from './Cache'
import Confirmation from './Confirmation'
import Analytics from './Analytics';

class JiraCreateIssue extends Component {
    constructor () {
        super();
        let user = Cookies.getJSON("truser");
        if (user === undefined || user == null) {
            user = undefined;
        }
        this.state = {user: user, atlassianIntegrated: false, projects: [], expired: undefined, moreOptions: true, projectCursor: 0, selectedProjectId: undefined, selectedIssueTypeId: undefined, unsupportedRequiredFields: []}
        this.atlassianIntegrated = this.atlassianIntegrated.bind(this)
        this.projects = this.projects.bind(this)
        this.projectChange = this.projectChange.bind(this)
        this.issueTypesAndPriorities = this.issueTypesAndPriorities.bind(this)
        this.issueTypeChange = this.issueTypeChange.bind(this)
        this.priorityChange = this.priorityChange.bind(this)
        this.createIssue = this.createIssue.bind(this)
        this.issueDescription = this.issueDescription.bind(this)
        this.issueDescriptionTableRow = this.issueDescriptionTableRow.bind(this)
        this.setSelectedOption = this.setSelectedOption.bind(this)
        this.issueDetailStringify = this.issueDetailStringify.bind(this)
        this.unsupportedRequiredFields = this.unsupportedRequiredFields.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0,0)
        this.atlassianIntegrated()
    }

    atlassianIntegrated () {
        if(this.props.projectId === undefined) {
            return
        }
        this.setState({loading: true})
        Request.get("/atlassian-integrated", {id: this.props.projectId}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to check if Jira integrated"});
            } else {
                if (data.integrated !== undefined) {
                    this.setState({loading: false, atlassianIntegrated: data.integrated}, () => this.projects())
                } else {
                    this.setState({loading:false})
                }
            }
        })
    }

    setSelectedOption (preference, options) {
        // Initialize selected project
        let selectedId = Cache.getPreference(preference)
        if (selectedId === undefined) {
            if (options.length > 0) {
                selectedId = options[0].id
            }
        } else {
            let exists = false
            for (let i = 0; i < options.length; i++) {
                let option = options[i]
                if (option.id === selectedId) {
                    exists = true
                }
            }
            if (exists !== true) {
                if (options.length > 0) {
                    selectedId = options[0].id
                }
            }
        }
        return selectedId
    }

    projectChange (value) {
        Cache.setPreference(Cache.preference.jiraProjectId, value)
        this.setState({selectedProjectId: value}, () => this.issueTypesAndPriorities())
    }

    projects () {
        if (this.state.moreOptions === false) {
            return // already have all projects
        }
        this.props.overlay(undefined);
        this.setState({loading: true});
        Request.get("/jira-projects", {id: this.props.projectId, cursor: this.state.projectCursor}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch Jira projects"});
            } else {
                if (data.projects !== undefined) {
                    let selectedProjectId = undefined
                    let moreOptions = true
                    let projectCursor = this.state.projectCursor
                    let projects = this.state.projects
                    try {
                        let projectsObj = JSON.parse(data.projects)
                        let fetchedProjects = projectsObj.values
                        for (let i = 0; i < fetchedProjects.length; i++) {
                            projects.push(fetchedProjects[i])
                        }
                        if (projectsObj.isLast === true) {
                            moreOptions = false
                        } else {
                            projectCursor += fetchedProjects.length
                        }
                        // Initialize selected project
                        selectedProjectId = this.setSelectedOption(Cache.preference.jiraProjectId, projects)
                    } catch (err) {
                        // Unable to add projects
                    }    
                    let newState = {
                        loading: false, 
                        projectCursor: projectCursor, 
                        projects: projects, 
                        moreOptions: moreOptions, 
                        expired: data.expired
                    }
                    if (selectedProjectId !== undefined) {
                        newState.selectedProjectId = selectedProjectId
                    }
                    this.setState(newState, () => this.issueTypesAndPriorities())
                } else {
                    this.setState({loading: false})
                }
            }
        })
    }

    issueTypeChange (value) {
        Cache.setPreference(Cache.preference.jiraIssueTypeId, value)
        this.setState({selectedIssueTypeId: value}, this.unsupportedRequiredFields)
    }

    priorityChange (value) {
        Cache.setPreference(Cache.preference.jiraPriorityId, value)
        this.setState({selectedPriorityId: value})
    }

    issueTypesAndPriorities () {
        this.setState({loading: true})
        Request.get("/jira-issuetypes-and-priorities", {id: this.props.projectId, jira_id: this.state.selectedProjectId}, (err, data) => {
            if (err) {
                this.setState({loading: false, error: "Unable to fetch Jira issue types"});
            } else {
                if (data.issueTypes !== undefined) {
                    // Issue Types
                    let selectedIssueTypeId = undefined
                    let selectedPriorityId = undefined
                    let issueTypes = undefined
                    let priorities = undefined
                    try {
                        let issueTypesRaw = JSON.parse(data.issueTypes)
                        if (issueTypesRaw !== undefined) {
                            if (Array.isArray(issueTypesRaw)) {
                                issueTypes = []
                                for (let i = 0; i < issueTypesRaw.length; i++) {
                                    let issueType = issueTypesRaw[i]
                                    if (issueType.subtask !== true) {
                                        issueTypes.push(issueType)
                                    }
                                }
                                if (issueTypes.length > 0) {
                                    selectedIssueTypeId = issueTypes[0].id
                                }
                            }
                        }
                        // Initialize selected issue type
                        selectedIssueTypeId = this.setSelectedOption(Cache.preference.jiraIssueTypeId, issueTypes)
                        // Priorities
                        priorities = JSON.parse(data.priorities)
                        if (priorities !== undefined) {
                            if (Array.isArray(priorities)) {
                                if (priorities.length > 0) {
                                    selectedPriorityId = priorities[0].id
                                }
                            }
                        }
                        // Initialize selected priority
                        selectedPriorityId = this.setSelectedOption(Cache.preference.jiraPriorityId, priorities)
                        this.setState({loading: false, issueTypes: issueTypes, selectedIssueTypeId: selectedIssueTypeId, priorities: priorities, selectedPriorityId: selectedPriorityId}, this.unsupportedRequiredFields)
                    } catch (err) {
                        // Unable to parse issue types
                        this.setState({loading: false})
                    }
                } else {
                    this.setState({loading: false})
                }
            }
        })
    }

    issueDescriptionTableRow (field, value) {
        return {
            type: "tableRow",
            content: [
              {
                type: "tableCell",
                attrs: {},
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: field
                      }
                    ]
                  }
                ]
              },
              {
                type: "tableCell",
                attrs: {},
                content: [
                  {
                    type: "paragraph",
                    content: [
                      {
                        type: "text",
                        text: value
                      }
                    ]
                  }
                ]
              }
            ]
        }
    }

    issueDetailStringify (value, type) {
        if (value === undefined || value === null) {
            return "-"
        }
        try {
            if (type === "date") {
                value = new Date(value)
            } else {
                value = JSON.stringify(value)
            }
        } catch (err) {
            // ignore
        }
        return value.toString()
    }

    issueDescription () {
        let data = {
            version: 1,
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "This issue has been created as a result of a failing test case."
                  }
                ]
              },
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "Project: "
                  },
                  {
                    type: "text",
                    text: this.props.project.name,
                    marks: [
                      {
                        type: "strong"
                      }
                    ]
                  }
                ]
              },
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "Target: "
                  },
                  {
                    type: "text",
                    text: this.props.target.name,
                    marks: [
                      {
                        type: "strong"
                      }
                    ]
                  },
                  {
                    type: "hardBreak"
                  },
                  {
                    type: "hardBreak"
                  },
                  {
                    type: "text",
                    text: "Test details:"
                  }
                ]
              },
              {
                type: "table",
                attrs: {
                  isNumberColumnEnabled: false,
                  layout: "default",
                  localId: "f6f4cb64-d5e2-49d1-b48a-3b8c6fdca209"
                },
                content: [
                  {
                    type: "tableRow",
                    content: [
                      {
                        type: "tableHeader",
                        attrs: {},
                        content: [
                          {
                            type: "paragraph",
                            content: [
                              {
                                type: "text",
                                text: "Test fields",
                                marks: [
                                  {
                                    type: "strong"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        type: "tableHeader",
                        attrs: {},
                        content: [
                          {
                            type: "paragraph",
                            content: [
                              {
                                type: "text",
                                text: "Test values",
                                marks: [
                                  {
                                    type: "strong"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
        }

        data.content[3].content.push(this.issueDescriptionTableRow("Suite", this.issueDetailStringify(this.props.case.suite)))
        data.content[3].content.push(this.issueDescriptionTableRow("Name", this.issueDetailStringify(this.props.case.name)))
        data.content[3].content.push(this.issueDescriptionTableRow("Result", this.issueDetailStringify(this.props.case.result)))
        data.content[3].content.push(this.issueDescriptionTableRow("Failure reason", this.issueDetailStringify(this.props.case.reason)))
        data.content[3].content.push(this.issueDescriptionTableRow("Description", this.issueDetailStringify(this.props.case.desc)))
        data.content[3].content.push(this.issueDescriptionTableRow("Parameters", this.issueDetailStringify(this.props.case.params)))
        data.content[3].content.push(this.issueDescriptionTableRow("Start", this.issueDetailStringify(this.props.case.start, "date")))
        data.content[3].content.push(this.issueDescriptionTableRow("End", this.issueDetailStringify(this.props.case.end, "date")))
        data.content[3].content.push(this.issueDescriptionTableRow("Duration (ms)", this.issueDetailStringify(this.props.case.duration)))
        // Custom fields
        Object.keys(this.props.case).forEach((key) => {
            if (key.startsWith("_")) { // Custom
                let field = key.substring(1)
                let value = this.props.case[key]
                data.content[3].content.push(this.issueDescriptionTableRow(field, this.issueDetailStringify(value)))
            }
        })
        data.content[3].content.push(this.issueDescriptionTableRow("Steps", this.issueDetailStringify(this.props.case.steps)))
        data.content[3].content.push(this.issueDescriptionTableRow("Files", this.issueDetailStringify(this.props.case.files)))

        let purl = "-" 
        let turl = "-"
        let curl = "-"
        try {
            purl = Constants.baseUrl + "/results/rsp/view/results/project/" + this.props.project.id;
            turl = Constants.baseUrl + "/results/rsp/view/results/target/" + this.props.project.id + "-" + this.props.target.created;
            if (this.props.run !== undefined) {
                curl = Constants.baseUrl + "/results/rsp/view/results/case/" + this.props.run.id + "-" + this.props.run.created + "-" + this.props.case.num;
            }
        } catch (err) {
            // blank urls
        }

        data.content[3].content.push(this.issueDescriptionTableRow("Project link", purl))
        data.content[3].content.push(this.issueDescriptionTableRow("Target link", turl))
        data.content[3].content.push(this.issueDescriptionTableRow("Case link", curl))
        
        return data
    }
    
    unsupportedRequiredFields () {
        const setNoUnsupportedFieldsState = () => {
            this.setState({loading: false, confirmation: {success: undefined, failure: undefined}, unsupportedRequiredFields: []});
        }

        Request.get("/jira-unsupported-required-fields", {id: this.props.projectId, jira_project_id: this.state.selectedProjectId, issue_type_id: this.state.selectedIssueTypeId}, (err, data) => {
            if (err) {                
                setNoUnsupportedFieldsState();
            } else {
                if (data.unsupportedRequiredFields !== undefined) {
                    if (Array.isArray(data.unsupportedRequiredFields)) {
                        if (data.unsupportedRequiredFields.length > 0) {
                            this.setState({loading: false, confirmation: {success: undefined, failure: "Please create an issue in Jira manually. There are required fields for this project and issue type: " + data.unsupportedRequiredFields.join(",") + ". These fields must be set to optional by your Jira administrator before issues can be created with one click."}, unsupportedRequiredFields: data.unsupportedRequiredFields});
                        } else {
                            setNoUnsupportedFieldsState();
                        }
                    } else {
                        setNoUnsupportedFieldsState();
                    }
                } else {
                    setNoUnsupportedFieldsState();
                }
            }
        });
    }

    createIssue () {
        Analytics.event("JiraIssueCreate")
        this.setState({loading: true})
        let data =  {
            fields: {
                summary: this.props.case.name,
                project: {
                    id: this.state.selectedProjectId
                },
                issuetype: {
                    id: this.state.selectedIssueTypeId
                },
                priority: {
                    id: this.state.selectedPriorityId
                },
                description: this.issueDescription()
                //labels: [""]
            }  
        }
        Request.post("/jira-create-issue", {id: this.props.projectId, data: data}, (err, data) => {
            if (err) {                
                this.setState({loading: false, confirmation: {success: undefined, failure: "Error creating Jira issue " + JSON.stringify(err)}, disabledButtons: false});
            } else {
                Analytics.event("JiraIssueCreated")
                this.setState({loading: false, confirmation: {success: "Issue created", failure: undefined}})
                if (data !== undefined) {
                    if (data.issue !== undefined) {
                        this.props.saveJiraIssue("jira:" + data.issue.url)
                    }
                }
            }
        });
    }
    
    render() {
        if (this.state.atlassianIntegrated === true) {
            let jiraBugAlreadyCreated = false
            if (this.props.bugs !== undefined) {
                for (let i = 0; i < this.props.bugs.length; i++) {
                    let bug = this.props.bugs[i]
                    if (bug.startsWith("jira:") === true) {
                        jiraBugAlreadyCreated = true
                    }
                }
            }
            //if (jiraBugAlreadyCreated === true) {
                //return <div></div>
            //}
            let projectOptions = []
            let selectedProjectIndex = -1
            if (this.state.projects !== undefined) {
                let projects = this.state.projects
                for (let i = 0; i < projects.length; i++) {
                    let project = projects[i]
                    if (project.id === this.state.selectedProjectId) {
                        selectedProjectIndex = i
                    }
                    let option = {
                        label: "(" + project.key + ")" + " " + project.name,
                        value: project.id,
                        img: undefined
                    }
                    if (project.avatarUrls !== undefined) {
                        option.img = <img src={project.avatarUrls["16x16"]} width="16" height="16" alt=""/>
                    }
                    projectOptions.push(option)
                }
            }
            let issueTypeOptions = []
            let selectedIssueTypeIndex = -1
            if (this.state.issueTypes !== undefined) {
                let issueTypes = this.state.issueTypes
                for (let i = 0; i < issueTypes.length; i++) {
                    let issueType = issueTypes[i]
                    if (issueType.id === this.state.selectedIssueTypeId) {
                        selectedIssueTypeIndex = i
                    }
                    let option = {
                        label: issueType.name,
                        value: issueType.id,
                        img: undefined
                    }
                    if (issueType.iconUrl !== undefined) {
                        option.img = <img src={issueType.iconUrl} width="16" height="16" alt=""/>
                    }
                    issueTypeOptions.push(option)
                }
            }
            let priorityOptions = []
            let selectedPriorityIndex = -1
            if (this.state.priorities !== undefined) {
                let priorities = this.state.priorities
                for (let i = 0; i < priorities.length; i++) {
                    let priority = priorities[i]
                    if (priority.id === this.state.selectedPriorityId) {
                        selectedPriorityIndex = i
                    }
                    let option = {
                        label: priority.name,
                        value: priority.id,
                        img: undefined
                    }
                    if (priority.iconUrl !== undefined) {
                        option.img = <img src={priority.iconUrl} width="16" height="16" alt=""/>
                    }
                    priorityOptions.push(option)
                }
            }
            if (this.state.loading === true) {
                return <div className='neutral4 font12 mt-5'>Loading Jira...</div>
            }
            let projectSelectTitle = ""
            if (selectedProjectIndex !== -1) {
                if (selectedProjectIndex < projectOptions.length) {
                    projectSelectTitle = projectOptions[selectedProjectIndex].label
                }
            } else {
                if (projectOptions.length > 0) {
                    projectSelectTitle = projectOptions[0].label
                }
            }
            let issueTypeSelectTitle = ""
            if (selectedIssueTypeIndex !== -1) {
                if (selectedIssueTypeIndex < issueTypeOptions.length) {
                    issueTypeSelectTitle = issueTypeOptions[selectedIssueTypeIndex].label
                }
            } else {
                if (issueTypeOptions.length > 0) {
                    issueTypeSelectTitle = issueTypeOptions[0].label
                }
            }
            let prioritySelectTitle = ""
            if (selectedPriorityIndex !== -1) {
                if (selectedPriorityIndex < priorityOptions.length) {
                    prioritySelectTitle = priorityOptions[selectedPriorityIndex].label
                }
            } else {
                if (priorityOptions.length > 0) {
                    prioritySelectTitle = priorityOptions[0].label
                }
            }
            return (
                <div>
                <br/>
                <hr className='dividor-light'/>
                <h4 className='neutral4'>Create a Jira issue</h4>
                <p className='font14 neutral4'>A new issue will be created in the selected Jira project using details from this test case and associated to this test case</p>
                <div className='neutral4 font12'>Project</div>
                <SelectOverlay
                    overlay={this.props.overlay} 
                    messageOverlay={this.props.messageOverlay}
                    type="generic"
                    title={projectSelectTitle}
                    label="label"
                    value="value"
                    options={projectOptions}
                    valueChange={this.projectChange}
                    defaultValue={this.state.selectedProjectId}
                    active={this.props.selectOverlayActive}
                    moreOptions={this.state.moreOptions}
                    getMoreOptions={this.projects}
                />
                <div className='neutral4 font12'>Issue Type</div>
                <SelectOverlay
                    overlay={this.props.overlay} 
                    messageOverlay={this.props.messageOverlay}
                    type="generic"
                    title={issueTypeSelectTitle}
                    label="label"
                    value="value"
                    options={issueTypeOptions}
                    valueChange={this.issueTypeChange}
                    defaultValue={this.state.selectedIssueTypeId}
                    active={this.props.selectOverlayActive}
                    //moreOptions={this.state.moreOptions}
                    //getMoreOptions={this.projects}
                />
                <div className='neutral4 font12'>Priority</div>
                <SelectOverlay
                    overlay={this.props.overlay} 
                    messageOverlay={this.props.messageOverlay}
                    type="generic"
                    title={prioritySelectTitle}
                    label="label"
                    value="value"
                    options={priorityOptions}
                    valueChange={this.priorityChange}
                    defaultValue={this.state.selectedPriorityId}
                    active={this.props.selectOverlayActive}
                    //moreOptions={this.state.moreOptions}
                    //getMoreOptions={this.projects}
                />
                <div className="mb-3">
                    {
                        this.props.submitting === true ? 
                            <div className='font12 neutral4'>Saving...</div>
                            :
                            <button className='btn-confirm' onClick={this.createIssue}>Create Jira issue</button>
                    }
                </div>
                <Confirmation confirmation={this.state.confirmation}/>
                </div>
            )
        } else {
            return <span></span>
        }
    }
}

export default JiraCreateIssue