/*global */
import React, { Component } from 'react'
import Loading from './Loading'
import Request from './Request'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'

class Countries extends Component {
    constructor() {
        super()
        this.countries = this.countries.bind(this)
        this.countryDisplay = this.countryDisplay.bind(this)
        this.countryList = this.countryList.bind(this)
    }

    countries () {
        let countries = [];
        countries.push({name: "AFGHANISTAN", code: "AF"});
        countries.push({name: "ÅLAND ISLANDS", code: "AX"});
        countries.push({name: "ALBANIA", code: "AL"});
        countries.push({name: "ALGERIA", code: "DZ"});
        countries.push({name: "AMERICAN SAMOA", code: "AS"});
        countries.push({name: "ANDORRA", code: "AD"});
        countries.push({name: "ANGOLA", code: "AO"});
        countries.push({name: "ANGUILLA", code: "AI"});
        countries.push({name: "ANTARTICA", code: "AQ"});
        countries.push({name: "ANTIGUA AND BARBUDA", code: "AG"});
        countries.push({name: "ARGENTINA", code: "AR"});
        countries.push({name: "ARMENIA", code: "AM"});
        countries.push({name: "ARUBA", code: "AW"});
        countries.push({name: "AUSTRALIA", code: "AU"});
        countries.push({name: "AUSTRIA", code: "AT"});
        countries.push({name: "AZERBAIJAN", code: "AZ"});
        countries.push({name: "BAHAMAS", code: "BS"});
        countries.push({name: "BAHRAIN", code: "BH"});
        countries.push({name: "BANGLADESH", code: "BD"});
        countries.push({name: "BARBADOS", code: "BB"});
        countries.push({name: "BELARUS", code: "BY"});
        countries.push({name: "BELGIUM", code: "BE"});
        countries.push({name: "BELIZE", code: "BZ"});
        countries.push({name: "BENIN", code: "BJ"});
        countries.push({name: "BERMUDA", code: "BM"});
        countries.push({name: "BHUTAN", code: "BT"});
        countries.push({name: "BOLIVIA, PLURINATIONAL STATE OF", code: "BO"});
        countries.push({name: "BONAIRE, SINT EUSTATIUS AND SABA", code: "BQ"});
        countries.push({name: "BOSNIA AND HERZEGOVINA", code: "BA"});
        countries.push({name: "BOTSWANA", code: "BW"});
        countries.push({name: "BOUVET ISLAND", code: "BV"});
        countries.push({name: "BRAZIL", code: "BR"});
        countries.push({name: "BRITISH INDIAN OCEAN TERRITORY", code: "IO"});
        countries.push({name: "BRUNEI DARUSSALAM", code: "BN"});
        countries.push({name: "BULGARIA", code: "BG"});
        countries.push({name: "BURKINA FASO", code: "BF"});
        countries.push({name: "BURUNDI", code: "BI"});
        countries.push({name: "CAMBODIA", code: "KH"});
        countries.push({name: "CAMEROON", code: "CM"});
        countries.push({name: "CANADA", code: "CA"});
        countries.push({name: "CAPE VERDE", code: "CV"});
        countries.push({name: "CAYMAN ISLANDS", code: "KY"});
        countries.push({name: "CENTRAL AFRICAN REPUBLIC", code: "CF"});
        countries.push({name: "CHAD", code: "TD"});
        countries.push({name: "CHILE", code: "CL"});
        countries.push({name: "CHINA", code: "CN"});
        countries.push({name: "CHRISTMAS ISLAND", code: "CX"});
        countries.push({name: "COCOS (KEELING) ISLANDS", code: "CC"});
        countries.push({name: "COLOMBIA", code: "CO"});
        countries.push({name: "COMOROS", code: "KM"});
        countries.push({name: "CONGO", code: "CG"});
        countries.push({name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE", code: "CD"});
        countries.push({name: "COOK ISLANDS", code: "CK"});
        countries.push({name: "COSTA RICA", code: "CR"});
        countries.push({name: "CÔTE D'IVOIRE", code: "CI"});
        countries.push({name: "CROATIA", code: "HR"});
        countries.push({name: "CUBA", code: "CU"});
        countries.push({name: "CURAÇAO", code: "CW"});
        countries.push({name: "CYPRUS", code: "CY"});
        countries.push({name: "CZECH REPUBLIC", code: "CZ"});
        countries.push({name: "DENMARK", code: "DK"});
        countries.push({name: "DJIBOUTI", code: "DJ"});
        countries.push({name: "DOMINICA", code: "DM"});
        countries.push({name: "DOMINICAN REPUBLIC", code: "DO"});
        countries.push({name: "ECUADOR", code: "EC"});
        countries.push({name: "EGYPT", code: "EG"});
        countries.push({name: "EL SALVADOR", code: "SV"});
        countries.push({name: "EQUATORIAL GUINEA", code: "GQ"});
        countries.push({name: "ERITREA", code: "ER"});
        countries.push({name: "ESTONIA", code: "EE"});
        countries.push({name: "ETHIOPIA", code: "ET"});
        countries.push({name: "FALKLAND ISLANDS (MALVINAS)", code: "FK"});
        countries.push({name: "FAROE ISLANDS", code: "FO"});
        countries.push({name: "FIJI", code: "FJ"});
        countries.push({name: "FINLAND", code: "FI"});
        countries.push({name: "FRANCE", code: "FR"});
        countries.push({name: "FRENCH GUIANA", code: "GF"});
        countries.push({name: "FRENCH POLYNESIA", code: "PF"});
        countries.push({name: "FRENCH SOUTHERN TERRITORIES", code: "TF"});
        countries.push({name: "GABON", code: "GA"});
        countries.push({name: "GAMBIA", code: "GM"});
        countries.push({name: "GEORGIA", code: "GE"});
        countries.push({name: "GERMANY", code: "DE"});
        countries.push({name: "GHANA", code: "GH"});
        countries.push({name: "GIBRALTAR", code: "GI"});
        countries.push({name: "GREECE", code: "GR"});
        countries.push({name: "GREENLAND", code: "GL"});
        countries.push({name: "GRENADA", code: "GD"});
        countries.push({name: "GUADELOUPE", code: "GP"});
        countries.push({name: "GUAM", code: "GU"});
        countries.push({name: "GUATEMALA", code: "GT"});
        countries.push({name: "GUERNSEY", code: "GG"});
        countries.push({name: "GUINEA", code: "GN"});
        countries.push({name: "GUINEA-BISSAU", code: "GW"});
        countries.push({name: "GUYANA", code: "GY"});
        countries.push({name: "HAITI", code: "HT"});
        countries.push({name: "HEARD ISLAND AND MCDONALD ISLANDS", code: "HM"});
        countries.push({name: "HOLY SEE (VATICAN CITY STATE)", code: "VA"});
        countries.push({name: "HONDURAS", code: "HN"});
        countries.push({name: "HONG KONG", code: "HK"});
        countries.push({name: "HUNGARY", code: "HU"});
        countries.push({name: "ICELAND", code: "IS"});
        countries.push({name: "INDIA", code: "IN"});
        countries.push({name: "INDONESIA", code: "ID"});
        countries.push({name: "IRAN, ISLAMIC REPUBLIC OF", code: "IR"});
        countries.push({name: "IRAQ", code: "IQ"});
        countries.push({name: "IRELAND", code: "IE"});
        countries.push({name: "ISLE OF MAN", code: "IM"});
        countries.push({name: "ISRAEL", code: "IL"});
        countries.push({name: "ITALY", code: "IT"});
        countries.push({name: "JAMAICA", code: "JM"});
        countries.push({name: "JAPAN", code: "JP"});
        countries.push({name: "JERSEY", code: "JE"});
        countries.push({name: "JORDAN", code: "JO"});
        countries.push({name: "KAZAKHSTAN", code: "KZ"});
        countries.push({name: "KENYA", code: "KE"});
        countries.push({name: "KIRIBATI", code: "KI"});
        countries.push({name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF", code: "KP"});
        countries.push({name: "KOREA, REPUBLIC OF", code: "KR"});
        countries.push({name: "KUWAIT", code: "KW"});
        countries.push({name: "KYRGYZSTAN", code: "KG"});
        countries.push({name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC", code: "LA"});
        countries.push({name: "LATVIA", code: "LV"});
        countries.push({name: "LEBANON", code: "LB"});
        countries.push({name: "LESOTHO", code: "LS"});
        countries.push({name: "LIBERIA", code: "LR"});
        countries.push({name: "LIBYA", code: "LY"});
        countries.push({name: "LIECHTENSTEIN", code: "LI"});
        countries.push({name: "LITHUANIA", code: "LT"});
        countries.push({name: "LUXEMBOURG", code: "LU"});
        countries.push({name: "MACAO", code: "MO"});
        countries.push({name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF", code: "MK"});
        countries.push({name: "MADAGASCAR", code: "MG"});
        countries.push({name: "MALAWI", code: "MW"});
        countries.push({name: "MALAYSIA", code: "MY"});
        countries.push({name: "MALDIVES", code: "MV"});
        countries.push({name: "MALI", code: "ML"});
        countries.push({name: "MALTA", code: "MT"});
        countries.push({name: "MARSHALL ISLANDS", code: "MH"});
        countries.push({name: "MARTINIQUE", code: "MQ"});
        countries.push({name: "MAURITANIA", code: "MR"});
        countries.push({name: "MAURITIUS", code: "MU"});
        countries.push({name: "MAYOTTE", code: "YT"});
        countries.push({name: "MEXICO", code: "MX"});
        countries.push({name: "MICRONESIA, FEDERATED STATES OF", code: "FM"});
        countries.push({name: "MOLDOVA, REPUBLIC OF", code: "MD"});
        countries.push({name: "MONACO", code: "MC"});
        countries.push({name: "MONGOLIA", code: "MN"});
        countries.push({name: "MONTENEGRO", code: "ME"});
        countries.push({name: "MONTSERRAT", code: "MS"});
        countries.push({name: "MOROCCO", code: "MA"});
        countries.push({name: "MOZAMBIQUE", code: "MZ"});
        countries.push({name: "MYANMAR", code: "MM"});
        countries.push({name: "NAMIBIA", code: "NA"});
        countries.push({name: "NAURU", code: "NR"});
        countries.push({name: "NEPAL", code: "NP"});
        countries.push({name: "NETHERLANDS", code: "NL"});
        countries.push({name: "NEW CALEDONIA", code: "NC"});
        countries.push({name: "NEW ZEALAND", code: "NZ"});
        countries.push({name: "NICARAGUA", code: "NI"});
        countries.push({name: "NIGER", code: "NE"});
        countries.push({name: "NIGERIA", code: "NG"});
        countries.push({name: "NIUE", code: "NU"});
        countries.push({name: "NORFOLK ISLAND", code: "NF"});
        countries.push({name: "NORTHERN MARIANA ISLANDS", code: "MP"});
        countries.push({name: "NORWAY", code: "NO"});
        countries.push({name: "OMAN", code: "OM"});
        countries.push({name: "PAKISTAN", code: "PK"});
        countries.push({name: "PALAU", code: "PW"});
        countries.push({name: "PALESTINE, STATE OF", code: "PS"});
        countries.push({name: "PANAMA", code: "PA"});
        countries.push({name: "PAPUA NEW GUINEA", code: "PG"});
        countries.push({name: "PARAGUAY", code: "PY"});
        countries.push({name: "PERU", code: "PE"});
        countries.push({name: "PHILIPPINES", code: "PH"});
        countries.push({name: "PITCAIRN", code: "PN"});
        countries.push({name: "POLAND", code: "PL"});
        countries.push({name: "PORTUGAL", code: "PT"});
        countries.push({name: "PUERTO RICO", code: "PR"});
        countries.push({name: "QATAR", code: "QA"});
        countries.push({name: "RÉUNION", code: "RE"});
        countries.push({name: "ROMANIA", code: "RO"});
        countries.push({name: "RUSSIAN FEDERATION", code: "RU"});
        countries.push({name: "RWANDA", code: "RW"});
        countries.push({name: "SAINT BARTHÉLEMY", code: "BL"});
        countries.push({name: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA", code: "SH"});
        countries.push({name: "SAINT KITTS AND NEVIS", code: "KN"});
        countries.push({name: "SAINT LUCIA", code: "LC"});
        countries.push({name: "SAINT MARTIN (FRENCH PART)", code: "MF"});
        countries.push({name: "SAINT PIERRE AND MIQUELON", code: "PM"});
        countries.push({name: "SAINT VINCENT AND THE GRENADINES", code: "VC"});
        countries.push({name: "SAMOA", code: "WS"});
        countries.push({name: "SAN MARINO", code: "SM"});
        countries.push({name: "SAO TOME AND PRINCIPE", code: "ST"});
        countries.push({name: "SAUDI ARABIA", code: "SA"});
        countries.push({name: "SENEGAL", code: "SN"});
        countries.push({name: "SERBIA", code: "RS"});
        countries.push({name: "SEYCHELLES", code: "SC"});
        countries.push({name: "SIERRA LEONE", code: "SL"});
        countries.push({name: "SINGAPORE", code: "SG"});
        countries.push({name: "SINT MAARTEN (DUTCH PART)", code: "SX"});
        countries.push({name: "SLOVAKIA", code: "SK"});
        countries.push({name: "SLOVENIA", code: "SI"});
        countries.push({name: "SOLOMON ISLANDS", code: "SB"});
        countries.push({name: "SOMALIA", code: "SO"});
        countries.push({name: "SOUTH AFRICA", code: "ZA"});
        countries.push({name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS", code: "GS"});
        countries.push({name: "SOUTH SUDAN", code: "SS"});
        countries.push({name: "SPAIN", code: "ES"});
        countries.push({name: "SRI LANKA", code: "LK"});
        countries.push({name: "SUDAN", code: "SD"});
        countries.push({name: "SURINAME", code: "SR"});
        countries.push({name: "SVALBARD AND JAN MAYEN", code: "SJ"});
        countries.push({name: "SWAZILAND", code: "SZ"});
        countries.push({name: "SWEDEN", code: "SE"});
        countries.push({name: "SWITZERLAND", code: "CH"});
        countries.push({name: "SYRIAN ARAB REPUBLIC", code: "SY"});
        countries.push({name: "TAIWAN, PROVINCE OF CHINA", code: "TW"});
        countries.push({name: "TAJIKISTAN", code: "TJ"});
        countries.push({name: "TANZANIA, UNITED REPUBLIC OF", code: "TZ"});
        countries.push({name: "THAILAND", code: "TH"});
        countries.push({name: "TIMOR-LESTE", code: "TL"});
        countries.push({name: "TOGO", code: "TG"});
        countries.push({name: "TOKELAU", code: "TK"});
        countries.push({name: "TONGA", code: "TO"});
        countries.push({name: "TRINIDAD AND TOBAGO", code: "TT"});
        countries.push({name: "TUNISIA", code: "TN"});
        countries.push({name: "TURKEY", code: "TR"});
        countries.push({name: "TURKMENISTAN", code: "TM"});
        countries.push({name: "TURKS AND CAICOS ISLANDS", code: "TC"});
        countries.push({name: "TUVALU", code: "TV"});
        countries.push({name: "UGANDA", code: "UG"});
        countries.push({name: "UKRAINE", code: "UA"});
        countries.push({name: "UNITED ARAB EMIRATES", code: "AE"});
        countries.push({name: "UNITED KINGDOM", code: "GB"});
        countries.push({name: "UNITED STATES", code: "US"});
        countries.push({name: "UNITED STATES MINOR OUTLYING ISLANDS", code: "UM"});
        countries.push({name: "URUGUAY", code: "UY"});
        countries.push({name: "UZBEKISTAN", code: "UZ"});
        countries.push({name: "VANUATU", code: "VU"});
        countries.push({name: "VENEZUELA, BOLIVARIAN REPUBLIC OF", code: "VE"});
        countries.push({name: "VIET NAM", code: "VN"});
        countries.push({name: "VIRGIN ISLANDS, BRITISH", code: "VG"});
        countries.push({name: "VIRGIN ISLANDS, U.S.", code: "VI"});
        countries.push({name: "WALLIS AND FUTUNA", code: "WF"});
        countries.push({name: "WESTERN SAHARA", code: "EH"});
        countries.push({name: "YEMEN", code: "YE"});
        countries.push({name: "ZAMBIA", code: "ZM"});
        countries.push({name: "ZIMBABWE", code: "ZW"});
        return countries;
    }

    countryDisplay (code) {
        let display = ""
        this.countries().forEach((country) => {
            if (country.code === code) {
                display = country.name
            }
        })
        return display
    }

    countryList () {
        let countryOptions = [];
        this.countries().forEach(function (country) {
            countryOptions.push(<option key={country.code} value={country.code}>{country.name}</option>);
        });
        return countryOptions;
    }

    render () {
        if (this.props.display === true) {            
            return (
                this.countryDisplay(this.props.code)
            )
        } else {
            return (
                <select className="custom-select mb-3" onChange={this.props.change} value={this.props.value}>
                    {this.countryList()}
                </select>
            )
        }
    }
};

export default Countries;