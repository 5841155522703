/*global */
import React, { Component } from 'react';
import Linkify from 'linkifyjs/react';
import CaseDetailField from './CaseDetailField';
import JiraCreateIssue from './JiraCreateIssue'
import JiraIssue from './JiraIssue'

class Bugs extends Component {
    constructor() {
        super();
        this.state = {state: "view", bugText: "", bugs: []};
        this.editBugs = this.editBugs.bind(this);
        this.editBug = this.editBug.bind(this);
        this.deleteBug =this.deleteBug.bind(this);
        this.saveBugs = this.saveBugs.bind(this);
        this.cancel = this.cancel.bind(this);
        this.saveJiraIssue = this.saveJiraIssue.bind(this)
        this.updateJiraTransitions = this.updateJiraTransitions.bind(this)
    }

    componentDidMount () {
        let bugs = [];
        if (this.props.case.bugs !== undefined) {
            this.props.case.bugs.forEach(function (b) {
                bugs.push(b);
            });
            this.setState({bugs: bugs});
        }
    }

    componentWillReceiveProps (props) {
        this.props = props;
        let bugs = [];
        if (props.case.bugs !== undefined) {
            this.props.case.bugs.forEach(function (b) {
                bugs.push(b);
            });
        }
        this.setState({state: "view", bugText: "", bugs: bugs});
    }

    editBugs () {
        this.setState({state: "edit"});
    }

    editBug (e) {
        this.setState({bugText: e.target.value});
    }

    deleteBug (index) {
        if (this.state.bugs !== undefined) {
            if (index < this.state.bugs.length) {
                let bugs = this.state.bugs;
                bugs.splice(index, 1);
                this.setState({bugs: bugs});
            }
        }
    }

    saveJiraIssue (text) {
        this.setState({bugText: text}, () => this.saveBugs())
    }

    saveBugs () {
        if (this.props.persistentCaseDataUpdate !== undefined) {
            let bugs = this.state.bugs;
            if (this.state.bugText !== "") {
                bugs.push(this.state.bugText);
            }
            let c = this.props.case;
            c.bugs = bugs;
            this.props.persistentCaseDataUpdate([c], function (err) {
                if (err) {
                    // output error
                } else {
                    this.setState({state: "view", bugs: c.bugs, bugText:""});
                }
            }.bind(this));
        }
    }

    cancel () {
        let bugs = [];
        if (this.props.case.bugs !== undefined) {
            this.props.case.bugs.forEach(function (b) {
                bugs.push(b);
            });
        }
        this.setState({state: "view", bugs: bugs, bugText: ""});
    }

    updateJiraTransitions (jiraTransitions) {
        if (this.props.persistentCaseDataUpdate !== undefined) {
            let c = this.props.case;
            c.jiraTransitions = jiraTransitions;
            this.setState({submitting: true})
            this.props.persistentCaseDataUpdate([c], function (err) {
                if (err) {
                    // output error
                    this.setState({submitting: false})
                } else {
                    this.setState({submitting: false, state: "view"});
                }
            }.bind(this));
        }
    }

    render() {
        if (! (this.props.view === "results" || this.props.view === "supplemental")) {
            return <span></span>
        }
        if (this.props.case.suite === "[build]") {
            return <span></span>
        }
        let bugs = [];
        if (this.state.state === "view") {
            let bugLinks = [];
            let bugLinksHash = {}
            this.state.bugs.forEach(function (b) {
                if (bugLinksHash[b] === undefined) {
                    if (b.startsWith("jira:")) {
                        bugLinks.push(<JiraIssue updateJiraTransitions={this.updateJiraTransitions} issueLink={b} bugs={this.state.bugs} jiraTransitions={this.props.case.jiraTransitions} case={this.props.case} selectOverlayActive={this.props.selectOverlayActive} projectId={this.props.projectId} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/>);
                    } else {
                        bugLinks.push(<div key={bugLinks.length} className="pre-wrap"><Linkify>{b}</Linkify></div>);
                    }
                    bugLinksHash[b] = true
                }
            }.bind(this));

            let noBugsMessage = <span></span>
            if (this.state.bugs.length === 0) {
                noBugsMessage = <div className="mb-3">Associate a bug. Link to a bug/issue in your bug tracking software.</div>
            }
            let bugsLabel = <span>Associated Bugs ({this.state.bugs.length})</span>
            let bugsValue =  (bugLinks.length > 0) ? <div className="mb-4">{bugLinks}</div> : <span></span>
            if (this.props.trl !== true) { 
                bugsValue = <div>
                                {bugsValue}
                                {noBugsMessage}
                                <button type="button" className="btn btn-select mt-3" onClick={this.editBugs}>Edit associated bugs</button>
                                <JiraCreateIssue submitting={this.state.submitting} project={this.props.project} target={this.props.target} run={this.props.run} bugs={this.state.bugs} saveJiraIssue={this.saveJiraIssue} case={this.props.case} selectOverlayActive={this.props.selectOverlayActive} projectId={this.props.projectId} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/>
                            </div>
            }
            
            bugs = <CaseDetailField collapseAll={this.props.collapseAll} mobile={this.props.mobile} label={bugsLabel} value={bugsValue} darkMode={this.props.darkMode}/>
        } else if (this.state.state === "edit") {
            let bugLinks = [];
            if (this.state.bugs !== undefined) {
                for (let i = 0; i < this.state.bugs.length; i++) {
                    let b = this.state.bugs[i];
                    bugLinks.push(
                        <div key={bugLinks.length} className="row">
                            <div className="col-9">
                                <div key={bugLinks.length} className="pre-wrap"><Linkify>{b}</Linkify></div>
                            </div>
                            <div className="col-3">
                                <button type="button" className="btn-transparent neutral4" onClick={() => {this.deleteBug(i)}}>Remove</button>
                            </div>
                        </div>
                    )
                }
            }

            let bugsLabel = <span>Associated Bugs ({this.state.bugs.length})</span>
            let bugsValue = <div>
                                {
                                    (bugLinks.length > 0) ?
                                    <div className="mb-4">{bugLinks}</div>
                                    :
                                    <span></span>
                                }
                                <label htmlFor="buglink"><span className="mb-4">Associate a bug. Link to a bug/issue in your bug tracking software.</span></label>
                                <input type="text" className="tr-input form-control mb-3" id="buglink" name="buglink" onChange={this.editBug} required/>
                                <br/>
                                <button type="button" className="btn btn-select" onClick={this.saveBugs}>Save Changes</button> <button type="button" className="btn btn-select" onClick={this.cancel}>Cancel</button>
                                <JiraCreateIssue submitting={this.state.submitting} project={this.props.project} target={this.props.target} run={this.props.run} bugs={this.state.bugs} saveJiraIssue={this.saveJiraIssue} case={this.props.case} selectOverlayActive={this.props.selectOverlayActive} projectId={this.props.projectId} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/>
                            </div>
            bugs = <CaseDetailField collapseAll={this.props.collapseAll} mobile={this.props.mobile} label={bugsLabel} value={bugsValue} darkMode={this.props.darkMode}/>
        } else {
            let bugsLabel = <span>Associated Bugs</span>
            let bugsValue = <div>
                                <p>Invalid state</p>
                                <JiraCreateIssue submitting={this.state.submitting} project={this.props.project} target={this.props.target} run={this.props.run} bugs={this.state.bugs} saveJiraIssue={this.saveJiraIssue} case={this.props.case} selectOverlayActive={this.props.selectOverlayActive} projectId={this.props.projectId} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/>
                            </div>
            bugs = <CaseDetailField collapseAll={this.props.collapseAll} mobile={this.props.mobile} label={bugsLabel} value={bugsValue} darkMode={this.props.darkMode}/>
        }

        return bugs
    }
};

export default Bugs;