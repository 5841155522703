/*global*/
import React, { Component } from 'react';

class Overlay extends Component {
    render () {
        let widthLimit = this.props.widthLimit + "px";
        if (this.props.heightLimit !== undefined) {
            let heightLimit = this.props.heightLimit + "px";
            return (
                <div style={{"height": heightLimit, "maxWidth":widthLimit, "overflow-x":"hidden", "white-space":"nowrap"}}>
                    {this.props.content}
                </div>
            );
        } else {
            return (
                <div style={{"maxWidth":widthLimit, "overflow-x":"hidden", "white-space":"nowrap"}}>
                    {this.props.content}
                </div>
            );
        }
    }
};

export default Overlay;