/*global */
import React, { Component } from 'react';
import Request from './Request';
import TimeFormatted from './TimeFormatted';
import TabularData from './TabularData';

class AffiliateAdminPayments extends Component {
    constructor () {
        super();
        this.state = {payments: [], esk: undefined};
        this.affiliatePaymentsForReview = this.affiliatePaymentsForReview.bind(this);
        this.paymentComplete = this.paymentComplete.bind(this);
        this.reviewComplete = this.reviewComplete.bind(this);
    }

    componentDidMount () {
        this.affiliatePaymentsForReview();
    }

    affiliatePaymentsForReview () {
        Request.get("/affiliate-payments-for-review", {esk: this.state.affiliatePaymentDataEsk}, (err, data) => {
            if (err) {
                this.setState({data: undefined, confirmation: {success: undefined, failure: "Error fetching data"}});
            } else {
                this.setState({payments: data.payments, esk: data.lek});
            }
        });
    }

    paymentComplete (index) {
        if (index >= this.state.payments.length)  {
            return;
        } else {
            let payment = this.state.payments[index];
            Request.post("/affiliate-payment-complete", {id: payment.id, time: payment.time}, (err, data) => {
                if (err) {
                    this.setState({confirmation: {success: undefined, failure: "Error setting payment complete"}, disabledButtons: false});
                } else {
                    let payments = this.state.payments;
                    payments[index] = data.payment;
                    this.setState({payments: payments, confirmation: {success: undefined, failure: undefined}});
                }
            });
        }
    }

    reviewComplete (index) {
        let payment = this.state.payments[index];
        Request.post("/affiliate-payment-review-complete", {id: payment.id, time: payment.time}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Error adding affiliate"}, disabledButtons: false});
            } else {
                let payments = this.state.payments;
                payments = payments.splice(index, 1);
                this.setState({payments: payments});
            }
        });
    }

    render () {
        if (this.state.payments === undefined) {
            return <div></div>
        } else {
            let data = {
                headers: [
                    {value: "Id", minWidth: "200px"},
                    {value: "Time", minWidth: "200px"},
                    {value: "Amount", minWidth: "100px"},
                    {value: "Paid"},
                    {value: "Payment"},
                    {value: "Review"}
                ],
                rows: []
            }
            for (let i = 0; i < this.state.payments.length; i++) {
                let payment = this.state.payments[i];
                data.rows.push(
                    {
                        cols: [
                            {value: payment.id},
                            {value: TimeFormatted.timeformatted(payment.time, true)},
                            {value: (payment.amount / 100.0).toFixed(2) + " " + payment.currency},
                            {value: payment.paid ? "Paid" : "Not paid"},
                            {value: payment.paid ? <span></span>
                                :
                                <button className="btn-cancel" onClick={() => {this.paymentComplete(i)}}>Payment complete</button>},
                            {value: <button className="btn-cancel" onClick={() => this.reviewComplete(i)} disabled={payment.paid ? false: true}>Review Complete</button>}
                        ]
                    }
                );
            }
            if (data.rows.length === 0) {
                return (
                    <div>
                        <h4 className="neutral3">Affiliate payments</h4>
                        <p>No payments to review</p>
                    </div>
                ); 
            } else {
                return (
                    <div className="mb-4">
                        <h4 className="neutral3">Payments</h4>
                        {
                            this.state.esk !== undefined ?
                            <button className="btn-cancel" onClick={this.affiliatePaymentsForReview}>Load more</button>
                            :
                            <span></span>    
                        }
                        <TabularData data={data}/>
                    </div>
                );
            }
        }
    }
};

export default AffiliateAdminPayments;