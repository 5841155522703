/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import TimeFormatted from './TimeFormatted';
//import ResultsUpdate from './ResultsUpdate';
import PassRate from './PassRate';
import TargetGroup from './TargetGroup';
import Analytics from './Analytics';
import Request from './Request';
import {utilsTotals, utilsRawResultMap} from './Utils'
import Spinner from './Spinner'


class Status extends Component {
    constructor () {
        super();
        this.state = {
            width: window.innerWidth, 
            height: window.innerHeight, 
            target: undefined,
            loading: false
        };
        this.resizeEvent = this.resizeEvent.bind(this);
        this.targetGroupUpdate = this.targetGroupUpdate.bind(this);
        this.targetHideInDashboardUpdate = this.targetHideInDashboardUpdate.bind(this);
        this.targetStatus = this.targetStatus.bind(this)
    }

    componentDidMount () {
        let target = this.props.target;
        target.rev = 0;
        this.setState({target: target}, this.targetStatus);
        window.addEventListener('resize', this.resizeEvent);
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.selectedGroup !== prevProps.selectedGroup) {
            if (this.props.aggregate !== undefined) {
                this.targetStatus()
            }
        }
    }
   
    componentWillUnmount() {
       window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
       this.setState({ width: window.innerWidth, height: window.innerHeight});
    }

    targetGroupUpdate (target) {
        this.setState({confirmation: {success: undefined, failure: undefined}})
        let data = {
            id: target.id,
            created: target.created,
            group: target.group
        };

        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to update group"}});
            } else {
                Analytics.event("EditedTargetGroup");
                target.rev = target.rev + 1;
                this.setState({confirmation: {success: "Changes have been saved", failure: undefined}, target: target});
            }
        });
    }

    targetHideInDashboardUpdate (target) {
        this.setState({confirmation: {success: undefined, failure: undefined}})
        let data = {
            id: target.id,
            created: target.created,
            hideInDashboard: target.hideInDashboard
        };

        Request.post("/targetedit", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: "Unable to update hide in dashboard"}});
            } else {
                Analytics.event("EditedTargetHideInDashboard");
                target.rev = target.rev + 1;
                this.setState({confirmation: {success: "Changes have been saved", failure: undefined}, target: target});
            }
        });
    }

    targetStatus () {
        if (this.state.target === undefined) {
            return
        }
        this.setState({loading: true})
        Request.get("/target-status", {id: this.state.target.id, created: this.state.target.created}, (err, data) => {
            if (err) {
                this.setState({loading: false})
            } else {
                let target = this.state.target
                target.run = data.run
                target.prevRun = data.prevRun
                target.cases = data.cases
                target.prevCases = data.prevCases
                this.setState({loading: false, target: target}, () => {
                    if (this.props.aggregate !== undefined) {
                        this.props.aggregate(target)
                    }
                })
            }
        })
    }

    render() {
        let target = this.state.target;
        if (target === undefined) {
            return <span></span>
        }
        let rawResultMap = utilsRawResultMap(target.rawResultMap, this.props.rawResultMaps)
        let run = target.run;
        let runMetadata = undefined;
        if (run) {
            runMetadata = {
                integrationName: run.integrationName,
                integrationVersion: run.integrationVersion, 
                testFramework: run.testFramework
            };
        }
        let totals = utilsTotals(target.cases, rawResultMap, runMetadata)
        let build = <span></span>
        let time = "";
        if (run !== undefined) {
            time = <span className="font14"><TimeFormatted dt={run.created} fromNow={true}/></span>
        }

        let accentClass = "neutral4statusheader";
        if (totals.total > 0) {
            accentClass = "accenta4statusheader";
            if (totals.pass === totals.total) {
                accentClass = "accentc4statusheader";
            } else if (totals.pass/totals.total >= 0.5) {
                accentClass = "accentb4statusheader";
            }
        }

        let key = target.id + "-" + target.created;
        let image = <span className="img64">&nbsp;</span>
        if (target.aext !== undefined) {
            let src = '/target/avatar/pdla/' + target.id + '/' + target.created + '/' + target.aext;
            image = <img src={src} width="64" height="64" className="circle img64 noborder" alt=""/>
        } else {
            if (this.props.role >= 3) {
                image = <Link to={"/config/project/target-image?index=" + this.props.targetIndexObj[key]}><img width="64" height="64" src="/img/photo.svg" className="circle img64 noborder" alt=""/></Link>
            } else {
                image = <img width="64" height="64" src="/img/photo.svg" className="circle img64 noborder" alt=""/>
            }
        }

        let content =
        <div>
            <div className="neutral6"><small>&nbsp;&nbsp;</small></div>
            <div className="neutral1 status-text-width status-title overflow-ellipsis-no-wrap">{target.name}</div>
            <div className="neutral4 status-text-width font14 overflow-ellipsis-no-wrap">{target.desc}</div>
            <div className="neutral4 status-text-width overflow-ellipsis-no-wrap" style={{"display":"inline"}}>{time}</div>
            <div className="neutral4 status-text-width overflow-ellipsis-no-wrap" style={{"display":"inline"}}>{build}</div>
            {/*<ResultsUpdate targets={this.props.targets} targetIndex={this.props.targetIndexObj[key]} view={this.props.view} loadUpdatedResults={this.props.loadUpdatedResults}/>*/}
        </div>
        
        return (
            <div>
                <span className="tr-link-button" onClick={() => this.props.statusTargetSelect(this.props.targetIndexObj[key])} key={key}>
                <div className={"caseDiv whitebg status-col " + accentClass}>
                    <div className="status-row-1">
                        <div className="status-row-col-1">
                            {image}
                        </div>
                        <div className="status-row-col-2">
                            {content}    
                        </div>
                    </div>
                    <div className="status-row-2">
                        <div className="status-row-col-1">
                            
                        </div>
                        <div className="status-row-col-2">
                            {this.state.loading ?
                                <Spinner/>
                                :
                                <PassRate 
                                    type="target"
                                    cases={target.cases}
                                    prevCases={target.prevCases}
                                    targets={this.props.targets}
                                    targetIndex={this.props.targetIndex}
                                    rawResultMaps={this.props.rawResultMaps}
                                    run={run}
                                />
                            }
                        </div>
                    </div>
                </div>
                </span>
                <div>
                {
                    this.props.groupEdit !== true ?
                    <span></span>
                    :
                    <TargetGroup target={this.state.target} targetGroupUpdate={this.targetGroupUpdate} targetHideInDashboardUpdate={this.targetHideInDashboardUpdate} groupSuggestions={this.props.groupSuggestions} confirmation={this.state.confirmation}/>
                }
                </div>
            </div>
        );
    }
};

export default Status;