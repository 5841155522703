/*global */

const Constants = {
    baseUrl: (function () {
        if (process.env.NODE_ENV === "production") {
            return "https://www.tesults.com";
        } else {
            return "http://localhost:3000";
        }
    })(),
    baseFileUrl: (function () {
        if (process.env.NODE_ENV === "production") {
            return "https://www.tesults.com";
        } else {
            return "http://localhost:3001";
        }
    })(),
    getUrlParameter: (function (key, search) {
        key = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
        var results = regex.exec(search);
        var retValue = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        if (retValue === '') {
            retValue = undefined;
        }
        return retValue;
    }),
    hasFileExtension: (function (fileName, extensions) {
        let hasExtension = false;
        if (!String.prototype.endsWith) {
            extensions.forEach(function (extension) {
                if (fileName.indexOf(extension, fileName.length - extension.length) !== -1 ) {
                    hasExtension = true;
                }
            });
        } else {
            extensions.forEach(function (extension) {
                if (fileName.endsWith(extension)) {
                    hasExtension = true;
                }
            });
        }
        return hasExtension;
    }),
    isLegacyBrowser: (function () {
        if (!String.prototype.endsWith) {
            return true;
        }
        return false;
    }),
    strings: {
        projects: "projects",
        targets: "targets"
    },
    euCountry: ((code) => {
        const eu = [
            "AT", 
            "BE",
            "BG",
            "HR",
            "CY",
            "CZ",
            "DK",
            "EE",
            "FI",
            "FR",
            "DE",
            "GR",
            "HU",
            "IE",
            "IT",
            "LV",
            "LT",
            "LU",
            "MT",
            "NL",
            "PL",
            "PT",
            "RO",
            "SK",
            "SI",
            "ES",
            "SE"
        ]
        return eu.includes(code)
    }),
    rawResultMapIntegrationSupport: (function () {
        return {
            exp: undefined,
            junit5: undefined,
            junit4: undefined,
            testng: undefined,
            pytest: {name: "Pytest", min_version: "1.4.0", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            robot: undefined,
            nunit3: undefined,
            mstest: undefined,
            rspec: undefined,
            mocha: {name: "Mocha", min_version: "1.2.0", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            jasmine: {name: "Jasmine", min_version: "1.0.1", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            jest: undefined,
            playwright: {name: "Playwright", min_version: "1.0.1", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            wdio: undefined,
            codeceptjs: {name: "CodeceptJS", min_version: "1.1.0", min_map: [{key: "passed", value: "pass"}, {key: "success", value: "pass"}, {key: "failed", value: "fail"}]},
            nightwatch: {name: "Nightwatch", min_version: "1.1.0", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            waffle: {name: "Waffle (using mocha-tesults-reporter)", min_version: "1.2.0", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            postman: undefined,
            cypress: {name: "Cypress", min_version: "1.3.0", min_map: [{key: "passed", value: "pass"}, {key: "failed", value: "fail"}]},
            testcafe: undefined,
            xctest: undefined,
            espresso: undefined
        }
    })(),
    isVersionGreaterThanOrEqualTo: (function (version, equalToOrGreaterVersion) {
        const versionArray = version.split(".");
        const equalToOrGreaterVersionArray = equalToOrGreaterVersion.split(".");
        // Basic Checks
        if (versionArray.length !== 3 || equalToOrGreaterVersionArray.length !== 3) {
            return false;
        }
        const check = (index) => {
            if (parseInt(versionArray[index], 10) >= parseInt(equalToOrGreaterVersionArray[index], 10)) {
                return true;
            } else {
                return false;
            }
        }
        try {
            // Major
            if (check(0) === false) {
                return false;
            }
            // Minor
            if (check(1) === false) {
                return false;
            }
            // Patch
            if (check(2) === false) {
                return false;
            }
        } catch (err) {
            return false;
        }
        return true;
    }),
}

export default Constants;