/*global */
import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

class DocsPagerDuty extends Component {
    render () {
        return (
            <div className="whitebg mt-3">
                <Helmet>
                    <title>Tesults - PagerDuty</title>
                    <meta name="description" content="Trigger and resolve PagerDuty incidents based on test results from Tesults"/>
                </Helmet>
                <p>If your team uses <a href="https://www.pagerduty.com" className="site-link-primary2" target="_blank" rel="noopener noreferrer">PagerDuty</a>, consider enabling Tesults to trigger and resolve incidents on PagerDuty.</p>
                <h2 className="mt-5 ">What does using PagerDuty with Tesults do?</h2>
                <p className="mb-3">Tesults will automatically trigger and resolve incidents on PagerDuty based on the latest incoming test results for each of your targets on Tesults. If you are using Tesults for production environment monitoring and site reliability then triggering incidents on PagerDuty if key tests fail can be useful to notify team members that are on-call. Tesults will trigger an incident when results dip below 100% pass and resolve an incident automatically when tests are passing again.</p>
                <h2 className="mt-5 ">How do I add PagerDuty to Tesults?</h2>
                <p>Create a project on Tesults.</p>
                <p>Click <b>Configuration</b> from the menu and select <b>PagerDuty</b>.</p>
                <img src="/img/pd-1.png" className="width100 mb-5" alt="Configuration menu"/>
                <p>You will be presented with two options for integrating with PagerDuty</p>
                <ol>
                    <li>Create Pager Duty integrations yourself within PagerDuty's interface and add your integration key to Tesults</li>
                    <li>Have Tesults request authorization to create an integration on your behalf in your PagerDuty account</li>
                </ol>
                <img src="/img/pd-2.png" className="width100 mb-5" alt="PagerDuty integration options"/>
                <h2 className="mt-5 ">Option 1 - Create integrations yourself on PagerDuty and add your integration key to Tesults</h2>
                <p>If you select this option all of the targets for your project will be listed. For each target you can add an integration name and key corresponding to an integration you have setup in PagerDuty. You can choose to use the same integration across targets or have different targets trigger incidents to different integrations.</p>
                <img src="/img/pd-7.png" className="width100 mb-5" alt="PagerDuty integration key paste"/>
                <p>To complete setup paste the integration key and name from PagerDuty and click the <b>save</b> button. To do this you must first create an integration on PagerDuty. Begin by selecting a service to add the integration to and click the ‘+ New Integration’ button.</p>
                <img src="/img/pd-8.png" className="width100 mb-5" alt="Pagerduty integration key"/>
                <p>Enter a name for the Integration and then from the Integration Type dropdown list, select Tesults.</p>
                <img src="/img/pd-9.png" className="width100 mb-5" alt="Pagerduty integration key"/>
                <p>After clicking the <b>Add Integration</b> button you can view the integration you have added and the integration key is displayed. Copy this integration key and paste it in Tesults.</p>
                <img src="/img/pd-10.png" className="width100 mb-5" alt="Pagerduty integration key"/>
                <p>At this point, setup is complete. You are ready to go!</p>
                <h2 className="mt-5 ">Option 2 - Tesults creates integrations on your behalf in PagerDuty</h2>
                <p>If you select this option then you will directed to PagerDuty to authorize Tesults to use your account.</p>
                <img src="/img/pd-3.png" className="width100 mb-5" alt="PagerDuty authorization"/>
                <p>After completing authorization you return to PagerDuty configuration in Tesults</p>
                <img src="/img/pd-4.png" className="width100 mb-5" alt="Configuration add"/>
                <p>All targets for your Tesults project are listed (in the example above there is only one). Each target can be assigned an integration to trigger incidents. Click the <b>Add</b> button for your chosen target.</p>
                <img src="/img/pd-5.png" className="width100 mb-5" alt="Configuration select service"/>
                <p>Select the service you want to add an integration for from the dropdown list and click the <b>Integrate with selected service</b> button.</p>
                <img src="/img/pd-6.png" className="width100 mb-5" alt="Integration added"/>
                <p>At this point Tesults creates the integration and is now enabled to trigger and resolve incidents based on the test results for the target that you have configured. You are ready to go!</p>
                <p>PagerDuty can be removed from each target by clicking the <b>remove</b> button for a particular target or from the entire project by clicking the <b>Remove PagerDuty</b> button from the bottom of the page.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/e-29p1NkD9c?si=9ZiDLq40aMF6jzmh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        );
    }
}

export default DocsPagerDuty;

