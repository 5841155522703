/*global Cookies*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import TasksMain from './TasksMain'
import TasksSide from './TasksSide'
import Context from './Context';
import Request from './Request';
import Cache from './Cache';

class Tasks extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {loading: false, tasksTargetCreated: undefined, tasksMemberId: undefined, title: "Tesults - Tasks", projects: []};
        this.assignedTasks = this.assignedTasks.bind(this);
        this.assignedTasksAll = this.assignedTasksAll.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.getTargets = this.getTargets.bind(this);
        this.members = this.members.bind(this);
        this.onTargetChange = this.onTargetChange.bind(this);
        this.onMemberChange = this.onMemberChange.bind(this);
        this.getRawResultMaps = this.getRawResultMaps.bind(this)
    }

    componentDidMount() {
        const user = Cookies.getJSON("truser");
        if (user !== undefined && user !== null) {
            let title = "Tesults - Tasks: " + user.firstName + " " + user.lastName;
            this.setState({title: title}, () => this.getProjects());
        } else {
            let title = "Tesults - Tasks"
            this.setState({title: title, loggedIn: false});
        }   
    }

    onTargetChange (targetCreated) {
        Cache.setPreference(Cache.preference.tasksTargetCreated, targetCreated);
        this.setState({tasksTargetCreated: targetCreated}, () => this.assignedTasks());
    }

    onMemberChange (memberId) {
        Cache.setPreference(Cache.preference.tasksMemberId, memberId);
        this.setState({tasksMemberId: memberId}, () => this.assignedTasks());
    }

    assignedTasks () {
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
        let project = undefined;
        if (projectIndex < this.context.projects.length) {
            project = this.context.projects[projectIndex];
        }
        if (project === undefined) {
            return;
        }
        this.setState({loading: true, taskChanged: false});
        Request.get("/tasksassigned", {id: project.id, created: this.state.tasksTargetCreated, assignee: this.state.tasksMemberId}, (err, data) => {
            if (err) {
                this.setState({cases: null, loading: false});
            } else {
                this.setState({cases: data.tasks, loading: false}, () => this.getRawResultMaps(project));
            }
        });
    }

    assignedTasksAll() {
        this.setState({loading: true, taskChanged: false});
        Request.get("/tasksassignedAll", undefined, (err, data) => {
            if (err) {
                this.setState({cases: null, loading: false});
            } else {
                data.tasks.forEach(function (c) {
                    if (c.suite === undefined) {
                        c.suite = "";
                    }
                });
                this.setState({cases: data.tasks, loading: false});
            }
        });
    }

    getProjects () {
        this.setState({loading: true});
        Cache.request(this.context, Cache.data.projects, {}, (err, projects) => {
            if (err) {
                this.setState({loading: false});
            } else {
                if (projects.length === 0) {
                    this.setState({projects: projects, loading: false});
                    return;
                }
                this.setState({projects: projects, loading: false}, () => this.members());
            }
        });
    }

    getTargets (projectId) {
        if (projectId === undefined) {
            return;
        }
        this.setState({loading: true})
        Cache.request(this.context, Cache.data.targets, {id: projectId}, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                if (targets.length === 0) {
                    this.setState({targets: targets, tasksTargetCreated: undefined, loading: false});
                    return;
                }
                let tasksTargetCreated = Cache.getPreference(Cache.preference.tasksTargetCreated);
                if (tasksTargetCreated === undefined || tasksTargetCreated === null) {
                    tasksTargetCreated = targets[0].created;
                    Cache.setPreference(Cache.preference.tasksTargetCreated, tasksTargetCreated);
                } else if (tasksTargetCreated === 'all') {
                    // do nothing, all selected
                } else {
                    let exists = false;
                    targets.forEach(function (target) {
                        if (tasksTargetCreated === target.created) {
                            exists = true;
                        }
                    });
                    if (exists === false) {
                        tasksTargetCreated = targets[0].created;
                        Cache.setPreference(Cache.preference.tasksTargetCreated, tasksTargetCreated);
                    }
                }
                this.setState({state: "submit", targets: targets, tasksTargetCreated: tasksTargetCreated, loading: false}, () => this.assignedTasks());
            }
        });
    }

    members () {
        if (this.context.projects === undefined) {
            this.setState({members: [], loading: false});
            return;
        }
        if (this.context.projects.length === 0) {
            this.setState({members: [], loading: false});
            return;
        }
        
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
        let project = undefined;
        if (projectIndex < this.context.projects.length) {
            project = this.context.projects[projectIndex];
        }
        if (project === undefined) {
            this.setState({members: [], loading: false});
            return;
        }
        this.setState({loading: true});
        Request.get("/members", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({members: [], loading: false});
            } else {
                if (data.members.length === 0) {
                    this.setState({members: data.members, tasksMemberId: undefined, loading: false});
                    return;
                }
                const user = Cookies.getJSON("truser");
                let tasksMemberId = Cache.getPreference(Cache.preference.tasksMemberId);
                if (tasksMemberId === undefined || tasksMemberId === null) {
                    tasksMemberId = user.id;
                    Cache.setPreference(Cache.preference.tasksMemberId, tasksMemberId);
                } else if (tasksMemberId === 'all') {
                    // do nothing, all selected
                } else {
                    let exists = false;
                    data.members.forEach(function (member) {
                        if (tasksMemberId === member.id) {
                            exists = true;
                        }
                    });
                    if (exists === false) {
                        tasksMemberId = user.id;
                        Cache.setPreference(Cache.preference.tasksMemberId, tasksMemberId);
                    }
                }
                this.setState({state: "submit", members: data.members, tasksMemberId: tasksMemberId, loading: false}, () => this.getTargets(project.id));
            }
        });
    }

    getRawResultMaps (project) {
        this.setState({loading: true});
        Request.get("/raw-result-map-get-maps", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({loading: false, rawResultMaps: data.maps})
            }
        })
    }

    render () {
        let title = undefined; //"Tasks";
        //let image = undefined;
        //const user = Cookies.getJSON("truser");
        //if (user !== undefined && user !== null) {
            //image = <span></span>
        //}
        
        const side = <TasksSide
                        getProjects={this.getProjects}
                        getTargets={this.getTargets}
                        getMembers={this.members}
                        projects={this.context.projects}
                        targets={this.state.targets}
                        cases={this.state.cases}
                        members={this.state.members}
                        loggedIn={this.state.loggedIn}
                        loading={this.state.loading}
                        onTargetChange={this.onTargetChange}
                        onMemberChange={this.onMemberChange}
                        tasksTargetCreated={this.state.tasksTargetCreated}
                        tasksMemberId={this.state.tasksMemberId}
                        assignedTasks={this.assignedTasks}
                        sideOpen={this.props.sideOpen}
                    />

        const main = <TasksMain
                    getProjects={this.getProjects}
                    getTargets={this.getTargets}    
                    getMembers={this.members}
                    projects={this.context.projects}
                    targets={this.state.targets}
                    cases={this.state.cases}
                    members={this.state.members}
                    loggedIn={this.state.loggedIn}
                    loading={this.state.loading}
                    onTargetChange={this.onTargetChange}
                    onMemberChange={this.onMemberChange}
                    tasksTargetCreated={this.state.tasksTargetCreated}
                    tasksMemberId={this.state.tasksMemberId}
                    assignedTasks={this.assignedTasks}
                    sideContent={side}
                    side={this.props.side}
                    sideOverlay={this.props.sideOverlay}
                    sideOpen={this.props.sideOpen}
                    rawResultMaps={this.state.rawResultMaps}
                />

        return (
            <div>
                <Helmet>
                    <title>{this.state.title}</title>
                    <meta name="description" content="Use tasks to effectively manage and track failing test cases on Tesults."/>
                </Helmet>
                {main}
            </div>
        );
    }
};

export default Tasks;