/*global*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Analytics from './Analytics';

class Consulting extends Component {
    componentDidMount() {
        Analytics.event("Consulting");
        window.scrollTo(0,0);
    }

    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Consulting</title>
                    <meta name="description" content="Tesults consulting provides software development and product services from the makers of Tesults."/>
                </Helmet>
                <div className="whitebg">
                    <h1 className="mb-3">Consulting</h1>
                    <br/>
                    <h2 className="neutral4 mb-3">Get your product developed by the makers of Tesults</h2>
                    <p>We are a team of senior and principal level software engineers with experience working at the biggest tech companies in the world. We make sure we get the details right and offer high quality, reliable and cost effective results with friendly communication and responsiveness to your requests. We handle platform (services and APIs), front-end web and mobile development. We specialize in this technology stack:</p>
                    <ul>
                        <li><b>Node.js</b> for server and platform development</li>
                        <li><b>React</b> for front-end web development</li>
                        <li><b>React Native</b> for front-end mobile development</li>
                        <li><b>AWS</b> for hosting and site reliability</li>
                    </ul>
                    <br/>
                    <h2 className="mb-3">Get started with a quote</h2>
                    <p>Email <a className="primary2 bold" href="mailto:help@tesults.com">consulting@tesults.com</a> and tell us about what you want to build!</p>
                    <p>We will have an initial call or two and work with you to clarify our understanding of your requirements and come back to you with a time estimate and quote. From there you can decide whether you want to work with us or not. We can handle short-term fixed length projects and long term projects.</p>
                    <p>We want to make the buying process simple. We do not provide estimates at an hourly rate. For short term one-time projects we will quote you total cost along with an estimate of time in number of days or weeks. For longer term projects we will calculate a monthly cost.</p>
                    <div className="mt-5">
                        <img src="https://www.tesults.com/files/art/2021-05-24/clean_line2.png" style={{"display":"block", "marginLeft":"auto", "marginRight":"auto", "width":"50%", "minWidth":"380px"}} alt="Team"/>
                    </div>
                </div>
            </div>
        );
    }
};

export default Consulting;