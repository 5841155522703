/*global */
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import CaseDisplayAttributeMap from './CaseDisplayAttributeMap'
import Request from './Request'
import Context from './Context'
import Cache from './Cache'
import Constants from './Constants'
import ConfigReportingCaseDisplayAttributeMap from './ConfigReportingCaseDisplayAttributeMap'

class ConfigPreferencesReportingCaseDisplayAttributeMaps extends Component {
    static contextType = Context;
    
    constructor () {
        super()
        // modes: view, update, create
        this.state = {maps: [], mode: "view", mapToUpdate: undefined}
        this.project  = this.project.bind(this)
        this.create = this.create.bind(this)
        this.update = this.update.bind(this)
        this.cancel = this.cancel.bind(this)
        this.createConfirm = this.createConfirm.bind(this)
        this.updateConfirm = this.updateConfirm.bind(this)
        this.deleteConfirm = this.deleteConfirm.bind(this)
        this.validMap = this.validMap.bind(this)
        this.projectUpdated = this.projectUpdated.bind(this)
    }

    componentDidMount () {
        const project = this.project()
        if (project === undefined) { return }
        Request.get("/preferences-reporting-case-display-attribute-maps", {id: project.id}, (err, data) => {
            if (err) { this.setState({maps: undefined})}
            else {
                this.setState({maps: data.maps})
            }
        })
    }

    project () {
        if (this.context.projects === undefined) {
            return
        }
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (projectIndex < this.context.projects.length) {
            return this.context.projects[projectIndex]
        } else {
            return
        }
    }

    validMap (map) {
        let valid = true
        if (map.label === undefined) { valid = false }
        if (map.attribute0 === undefined) { valid = false }
        if (map.attribute1 === undefined) { valid = false }
        if (map.attribute2 === undefined) { valid = false }
        if (map.attribute3 === undefined) { valid = false }
        if (map.attribute4 === undefined) { valid = false }
        if (map.attribute5 === undefined) { valid = false }

        if (map.label === "") { valid = false }
        if (map.attribute0 === "") { valid = false }
        if (map.attribute1 === "") { valid = false }
        if (map.attribute2 === "") { valid = false }
        if (map.attribute3 === "") { valid = false }
        if (map.attribute4 === "") { valid = false }
        if (map.attribute5 === "") { valid = false }

        return valid
    }

    cancel () {
        this.setState({mode: "view"})
    }

    create () {
        this.setState({mode: "create"})
    }

    update (map) {
        this.setState({mode: "edit", mapToUpdate: map})
    }

    createConfirm (map) {
        const project = this.project()
        if (project === undefined) { return }
        if (this.validMap(map) !== true) {
            this.setState({confirmation: {success: undefined, failure: "Select a value for all attributes and add a label for the map"}})
            return
        }

        Request.post("/preferences-reporting-case-display-attribute-map-create", {id: project.id, map: map}, (err, data) => {
            if (err) { this.setState({confirmation: {success: undefined, failure: "Failed to create new case display attribute map"}})}
            else {
                let maps = this.state.maps
                maps.push(data.map)
                this.setState({confirmation: {success: undefined, failure: undefined}, maps: maps, mode: "view"})
            }
        })
    }

    updateConfirm (map) {
        const project = this.project()
        if (project === undefined) { return }
        if (this.validMap(map) !== true) {
            this.setState({confirmation: {success: undefined, failure: "Select a value for all attributes and add a label for the map"}})
            return
        }
        Request.post("/preferences-reporting-case-display-attribute-map-update", {id: project.id, created: map.created, map: map}, (err, data) => {
            if (err) { this.setState({confirmation: {success: undefined, failure: "Failed to update case display attribute map"}})}
            else {
                let maps = this.state.maps
                for (let i = 0; i < maps.length; i++) {
                    if (maps[i].created === data.map.created) {
                        maps[i] = data.map
                    }
                }
                this.setState({confirmation: {success: undefined, failure: undefined}, maps: maps, mapToUpdate: undefined, mode: "view"})
            }
        })
    }

    deleteConfirm (map) {
        const project = this.project()
        if (project === undefined) { return }
        Request.post("/preferences-reporting-case-display-attribute-map-delete", {id: project.id, created: map.created}, (err, data) => {
            if (err) { this.setState({confirmation: {success: undefined, failure: "Failed to delete case display attribute map"}})}
            else {
                if (data.deleted !== true) {
                    this.setState({confirmation: {success: undefined, failure: data.message}})
                } else {
                    let maps = this.state.maps
                    let index = -1
                    for (let i = 0; i < maps.length; i++) {
                        if (maps[i].created === map.created) {
                            index = i
                        }
                    }
                    if (index > -1) {
                        maps.splice(index, 1)
                    }
                    this.setState({confirmation: {success: undefined, failure: undefined}, maps: maps, mapToUpdate: undefined, mode: "view"})
                }
            }
        })
    }

    projectUpdated (project) {
        let projects = this.context.projects
        for (let i = 0; i < projects.length; i++) {
            let p = projects[i];
            if (p.id === project.id) {
                projects[i] = project;
            }
        }
        this.context.update(Constants.strings.projects, projects);
    }

    render() {
        let project = this.project()
        if (project === undefined) { return <div></div> }
        if (this.state.maps === undefined) { return <div></div> }
        if (this.state.mode === "view") {
            let rows = []
            for (let i = 0; i < this.state.maps.length; i++) {
                rows.push(<CaseDisplayAttributeMap key={i} mode={this.state.mode} map={this.state.maps[i]} update={this.update} cancel={this.cancel} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} confirmation={this.state.confirmation}/>)
            }
            return (
                <div>
                    <h3>Reporting Case Display Attribute Maps</h3>
                    <div className='mb-5'>
                        <img src="/img/preferences-reporting-case-display-attributes.png" className='width100' alt="case-display-attributes"/>
                        <p>Modify test case attributes to display in the results view. You can modify the attributes in orange. Create a custom attribute map and then enable it to modify the attributes that are displayed.</p>    
                    </div>
                    <div className='mb-5'>
                        <div className='mb-3'><b>Maps</b></div>
                        {rows.length > 0 ? <div>{rows}</div>: <span className='neutral4'>No case display attribute maps created</span>}
                    </div>
                    <hr className='dividor-light'/>
                    <div>
                        <button className='btn-confirm' onClick={this.create}>Create an attribute map</button>
                    </div>
                    {
                        rows.length > 0 ?
                        <div>
                            <hr className='dividor-light'/>
                            <div className='mt-5 mb-4'>
                                <ConfigReportingCaseDisplayAttributeMap scope="project" project={project} projectUpdated={this.projectUpdated} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay}/>
                                <div className='font15 neutral4 mt-3 mb-5'>
                                    Apply a test case display attribute map for use by the project. For finer control you can set this value at the target level instead to apply a different map for different targets, select 'Targets' to do this. If you set a map at both the target and project level the target map is used. Setting the value to None will use the default Tesults mapping.
                                </div>
                            </div>
                        </div>
                        :
                        <div></div>    
                    }
                </div>
            );
        } else if (this.state.mode === "edit") {
            return <CaseDisplayAttributeMap mode={this.state.mode} map={this.state.mapToUpdate} cancel={this.cancel} save={this.updateConfirm} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} confirmation={this.state.confirmation} deleteConfirm={this.deleteConfirm}/>
        } else if (this.state.mode === "create") {
            return <CaseDisplayAttributeMap  mode={this.state.mode} cancel={this.cancel} save={this.createConfirm} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} confirmation={this.state.confirmation}/>
        } else {
            return <div>Invalid state</div>
        }
    }
}

export default ConfigPreferencesReportingCaseDisplayAttributeMaps