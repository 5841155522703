/*global*/
import React, { Component } from 'react';
import Analytics from './Analytics';
import Loading from './Loading';
import ConfirmationButtons from './ConfirmationButtons'
import Request from './Request';

class Tooltip extends Component {
    constructor () {
        super();
        
    }

    componentDidMount() {
        
    }

    render () {
        return (
            <div class={"tooltip" + (this.props.classes === undefined ? "" : " " + this.props.classes)}>{this.props.text}
                <span class="tooltiptext tooltiptext-bottom">{this.props.tooltipText}</span>
            </div>
        )
    }
};

export default Tooltip