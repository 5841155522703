/*global */
import React, { Component } from 'react';
import Constants from './Constants';
import Loading from'./Loading';
import Request from './Request';
import Confirmation from './Confirmation';

class EmailReceive extends Component {
    constructor () {
        super();
        this.state = {loading: false, newsletterSaved: false, newsletter: false, confirmation: {success: undefined, failure: undefined}};
        this.toggleNewsLetter = this.toggleNewsLetter.bind(this);
        this.getEmailReceive = this.getEmailReceive.bind(this);
        this.setEmailReceive  = this.setEmailReceive.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount () {
        let id = this.props.match.params.id;
        if (id === undefined) {
            id = Constants.getUrlParameter("id", this.props.location.search);
        }
        this.setState({id: id}, () => this.getEmailReceive());
    }

    getEmailReceive () {
        this.setState({loading:true});
        Request.get("/emailreceive", {id: this.state.id, type: "newsletter"}, (err, data) => {
            if (err) {
                this.setState({loading: false, confirmation: {success: undefined, failure: "Unable to fetch email settings, check the link is correct."}});
            } else {
                this.setState({loading: false, newsletter: data.receive, newsletterSaved: data.receive, confirmation: {success: undefined, failure: undefined}});
            }
        });
    }


    setEmailReceive () {
        this.setState({loading: true});
        Request.post("/emailreceive", {id: this.state.id, type: "newsletter"}, (err, data) => {
            if (err) {
                this.setState({loading: false, confirmation: {success: undefined, failure: "Unable to set email settings, check the link is correct."}});
            } else {
                this.setState({loading: false, newsletter: data.receive, newsletterSaved: data.receive, confirmation: {success: "Your preferences have been updated.", failure: undefined}});
            }
        });
    }

    toggleNewsLetter () {
        this.setState({newsletter: (this.state.newsletter === true ? false: true), confirmation: {success: undefined, failure: undefined}});
    }

    save () {
        this.setEmailReceive();
    }
    
    render () {
        if (this.state.loading === true) {
            return <Loading/>
        } else {
            return (
                <div>
                    <h1>Emails</h1>
                    {
                        this.state.confirmation.failure !== undefined ?
                        <span></span>
                        :
                        <div>
                            <p>Tesults sends out an occassional newsletter. If you do not wish to receive the newsletter uncheck the box and save changes.</p>
                            <div style={{"display":"flex"}}>
                                <div>Receive newsletter</div>
                                <div>
                                    <label className="autoRefreshSwitch">
                                        <input type="checkbox" onChange={this.toggleNewsLetter} checked={this.state.newsletter}/>
                                        <span className="autoRefreshSlider round"></span>
                                    </label>
                                </div>
                            </div>
                            <p className="neutral4">This will not affect receipt of transactional emails delivered by Tesults for the purposes of managing your projects and for notifications. You can change settings for these emails from the configuration menu when you are signed in.</p>
                        </div>
                    }                    
                    <Confirmation confirmation={this.state.confirmation}/>
                    {
                        this.state.newsletter === this.state.newsletterSaved ?
                        <span></span>
                        :
                        <button className="btn-confirm" onClick={this.save}>Save changes</button>
                    }
                </div>
            )
        }
    }
};

export default EmailReceive;