/*global Cookies*/
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Request from './Request';

class FeatureRequestMain extends Component {
    constructor () {
        super();
        const user = Cookies.getJSON("truser");
        let state = "contact";
        if (user === undefined || user == null) {
            state = "email";
        }
        this.state = {state: state, error: "", subject: "", message: ""};
        this.subjectChange = this.subjectChange.bind(this);
        this.messageChange = this.messageChange.bind(this);
        this.send = this.send.bind(this);
    }
    
    subjectChange (e) {
        this.setState({subject: e.target.value});
    }
    messageChange (e) {
        this.setState({message: e.target.value});
    }
    send (e) {
        e.preventDefault();
        Request.post("/contact", {subject: this.state.subject, message: this.state.message, type: "feature"}, (err, data) => {
            if (err) {
                this.setState({error: "Unable to submit feature request. Please send an email."});
            } else {
                this.setState({state: "sent"});
            }
        });
    }
    render () {
        if (this.state.state === "contact") {
            return (
                <div style={{"maxWidth":"800px"}}>
                    <h1>Feature request</h1>
                    <p>Our product team is always busy creating new features to make Tesults even better. However if you are finding there is something missing that would make your Tesults experience better we want to hear from you.</p>
                    <p>A feature request can be big or small, it may be something entirely new or just a quirk in your workflow that does not quite fit with the way Tesult works. We want to hear from you regardless of what it is. Part of our mission is to ensure Tesults continues to be the best way to store, analyze and report test results data and we are committed to handling your feedback seriously, it is never a waste of your time to get in contact, we reply to all requests.</p>
                    <p>There are two ways to submit a feature request. Send an email to <a className="footerLink" href="mailto:help@tesults.com">help@tesults.com</a> or use the form below.</p>
                    <p>Please provide details about the feature you would like to request and we will it pass it through to our product team. A Tesults team member will contact you to follow up.</p>
                    
                    <form className="text-left neutral1 mt-5" onSubmit={this.send}>
                        <div className="form-group mb-4">
                            <div className="neutral4 font14 mb-1">Feature title</div>
                            <input type="text" className="tr-input form-control" style={{"width":"292px"}} placeholder="" onChange={this.subjectChange} required/>
                        </div>
                        <div className="form-group mb-4">
                            <div className="neutral4 font14 mb-1">Feature details</div>
                            <textarea type="textarea" rows="10" cols="40" className="form-control p-3 neutral7border" placeholder="" onChange={this.messageChange} required></textarea>
                        </div>
                        <div className="accenta1">{this.state.error}</div>
                        <button type="submit" className="btn btn-select">Send</button>
                    </form>
                </div>
            );
        } else if (this.state.state === "email") {
            return (
                <div style={{"maxWidth":"800px"}}>
                    <h1>Feature request</h1>
                    <p>Our product team is always busy creating new features to make Tesults even better. However if you are finding there is something missing that would make your Tesults experience better we want to hear from you.</p>
                    <p>A feature request can be big or small, it may be something entirely new or just a quirk in your workflow that does not quite fit with the way Tesult works. We want to hear from you regardless of what it is. Our mission includes ensuring Tesults continues to be the best way to store, analyze and report automated test results data and we are committed to handling your input seriously, it is never a waste of your time to get in contact, we reply to all requests.</p>
                    <p>There are two ways to submit a feature request.</p>
                    <p>1. Email <a className="footerLink" href="mailto:help@tesults.com">help@tesults.com</a></p>
                    <p>2. <NavLink to="/login" className="nounderline neutral4">Login</NavLink> and return to this page to submit a feature request form.</p>
                    <p>Whether sending an email or using the form, please provide details about the feature you would like to request and we will it pass it through to our product team. A Tesults team member may contact you to follow up.</p>
                </div>
            );
        } else if (this.state.state === "sent") {
            return(
                <div style={{"maxWidth":"800px"}}>
                    <h1>Feature request</h1>
                    <p>Feature request submitted successfully. Thank you, one of our team members will get back to you soon.</p>
                </div>
            );
        }
    }
};

export default FeatureRequestMain;