/*global */
import React, { Component } from 'react';

class DocsManualTestData extends Component {
    render() {
        return (
            <div className="mb-3">
                <table className="table table-striped mt-3">
                    <thead>
                    <th>Property</th>
                    <th>Required</th>
                    <th>Description</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>name</td>
                        <td>*</td>
                        <td>Name of the test.</td>
                    </tr>
                    <tr>
                        <td>result</td>
                        <td>*</td>
                        <td>Result of the test. Must be one of: pass, fail, unknown. Set to 'pass' for a test that passed, 'fail' for a failure.</td>
                    </tr>
                    <tr>
                        <td>suite</td>
                        <td></td>
                        <td>Suite the test belongs to. This is a way to group tests.</td>
                    </tr>
                    <tr>
                        <td>desc</td>
                        <td></td>
                        <td>Description of the test.</td>
                    </tr>
                    <tr>
                        <td>reason</td>
                        <td></td>
                        <td>Reason for the test failure. Leave this empty or do not include it if the test passed.</td>
                    </tr>
                    <tr>
                        <td>params</td>
                        <td></td>
                        <td>Parameters of the test if it is a parameterized test.</td>
                    </tr>
                    <tr>
                        <td>files</td>
                        <td></td>
                        <td>Files that belong to the test case, such as logs, screenshots, metrics and performance data.</td>
                    </tr>
                    <tr>
                        <td>_custom</td>
                        <td></td>
                        <td>Any number of custom fields. These can be anything you want.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default DocsManualTestData;