/*global*/
import { Component } from 'react';
import Request from './Request';
import Confirmation from './Confirmation'
import TabularData from './TabularData'
import TimeFormatted from './TimeFormatted'

class Invoices extends Component {
    constructor () {
        super();
        this.state = {confirmation: {success: undefined, failure: undefined}, invoices: [], esk: undefined}
        this.invoices = this.invoices.bind(this)
        this.tabularData = this.tabularData.bind(this)
    }

    componentDidMount () {
        this.invoices()
    }

    invoices () {
        Request.get("/invoices", {id: this.props.project.id, esk: this.state.esk}, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err}})
            } else {
                let invoices = this.state.invoices
                if (data.invoices !== undefined) {
                    data.invoices.forEach((invoice) => {
                        invoices.push(invoice)
                    })
                }
                this.setState({invoices: invoices, esk: data.lek, confirmation: {success: undefined, failure: undefined}})
            }
        })
    }

    tabularData () {
        let data = {
            headers: [
                {value: "Date", minWidth: "200px"},
                {value: "Invoice", minWidth: "200px"},
                {value: "View/Download", minWidth: "100px"}
            ],
            rows: []
        }
        for (let i = 0; i < this.state.invoices.length; i++) {
            let invoice = this.state.invoices[i]
            data.rows.push(
                {
                    cols: [
                        {value: <TimeFormatted dd={(invoice.created * 1000)}/>},
                        {value: invoice.number},
                        {value: <a href={invoice.invoice_pdf} className='tr-link-primary4' target="_blank">View/Download</a>}
                    ]
                }
            );
        }
        let loadMore = <span></span>
        if (this.state.esk !== undefined) {
            loadMore = <button className="btn-cancel mt-3 mb-3" onClick={this.invoices}>Load more</button>
        }
        return (
            <div>
                <TabularData data={data}/>
                {loadMore}
                <Confirmation confirmation={this.state.confirmation}/>
            </div>
        )
    }

    render () {
        let data = this.tabularData()
        return (
            <div>
                <p>View invoices</p>
                {data}
            </div>
        )
    }
}

export default Invoices
