/*global */
import React, { Component } from 'react'
import Analytics from './Analytics'
import Context from './Context'
import Cache from './Cache'
import Request from './Request'
import Confirmation from './Confirmation'
import ConfirmationButtons from './ConfirmationButtons'
import Copy from './Copy'


class ConfigTargetCreate extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {state: "view"}
        this.create = this.create.bind(this)
        this.changeName = this.changeName.bind(this)
        this.changeDesc = this.changeDesc.bind(this)
    }

    create () {
        this.setState({submitting: true, confirmation: {success: undefined, failure: undefined}});
        let projects = this.context.projects;
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        let project = projects[projectIndex]
        const data = {
            id: project.id,
            name: this.state.name,
            desc: this.state.desc,
        };
        Request.post("/target", data, (err, data) => {
            if (err) {
                this.setState({confirmation: {success: undefined, failure: err}, submitting: false});
            } else {
                Analytics.event("CreatedTarget");
                this.setState({state: "created", confirmation: {success: "Target created", failure: undefined}, name: data.target.name, desc: data.target.desc, token: data.token, submitting: false}, () => this.props.targetAdded(data.target));
            }
        });
    }

    changeName (e) {
        this.setState({name: e.target.value});
    }

    changeDesc (e) {
        this.setState({desc: e.target.value});
    }

    render () {
        if (this.state.state === "view") {
            return (
                <div>
                    <button onClick={() => { this.setState({state:"create"})}} className="btn-confirm">Create New Target</button>
                </div>
            )
        } else if (this.state.state === "created") {
            return (
                <div style={{"maxWidth":"600px"}}>
                    <h4 className="">Target Created</h4>
                    <br/>
                    <span className="font15">Target token </span><Copy text={this.state.token}/>
                    <br/>
                    <div><textarea className="tr-text-area textareaNoResize" rows="10" cols="40" value={this.state.token} readOnly/></div>
                    <p>The target token is required to be sent every time you post results using the results API.</p>
                    <p>This is the only time the target token will be displayed. Copy it now and keep it safe. You can regenerate the target token again from project configuration.</p>
                    <button type="button" className="btn btn-confirm mt-3" onClick={() => { this.setState({state: "view"})}}>Done</button>
                    <br/>
                </div>
            )
        } else if (this.state.state === "create") {
            return (
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-7">
                        <h4 className="mb-0">Create a new target</h4>
                        <span className="small neutral4 mt-0">The name and description can be changed later</span>
                        <br/>
                        <br/>
                        <div className="mb-1">
                            <label className="neutral4 mb-0 small">Name</label>
                        </div>
                        <div className="mb-4">
                            <input type="text" className="tr-input" value={this.state.name} onChange={this.changeName} required/>
                        </div>
                        <div className="mb-1">
                            <label className="neutral4 mb-0 small">Description</label>
                        </div>
                        <div className="mb-4">
                            <input type="text" className="tr-input" value={this.state.desc} onChange={this.changeDesc} required/>
                        </div>
                        <Confirmation confirmation={this.state.confirmation}/>
                        <ConfirmationButtons 
                            confirm={this.create}
                            cancel={() => {this.setState({state:"view"})}}
                            confirmLabel="Create"
                            cancelLabel="Cancel"
                            confirmDisabled={this.state.submitting}
                        />
                    </div>
                </div>
            )
        } 
    }
};

export default ConfigTargetCreate