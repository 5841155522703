/*global*/
import React, { Component } from 'react'
import { NavLink } from 'react-dom'
import {Helmet} from 'react-helmet'
import TesultsLtd from './TesultsLtd'

class DocsVendorOnboarding extends Component {
    render () {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Vendor Onboarding and Due Diligence</title>
                    <meta name="description" content="Learn about Tesults and find answers for your vendor onboarding and due diligence process."/>
                </Helmet>
                <div className="max-width-800px">
                    <h2 className="mt-5 mb-5">Thank you for considering Tesults</h2>
                    <p>Thank you for considering <a href="/" target="_blank" className="tr-link-primary4">Tesults</a>. If you are tasked with handling vendor onboarding, security and due diligence at your organization this is a good place to start.</p>
                    <p>Tesults handles test reporting and test case management for software engineering teams. Tesults efficiently consolidates test reporting from systems across an engineering organization and provides powerful analysis capabilities to aid software engineers working on mission critical systems.</p>
                    <p>Tesults is offered as a Software as a Service (SaaS) platform. There is no on-premises offering. This means that all data is stored in the cloud. Even though Tesults is generally only used to store test results data, rather than production customer data, it is still important to ensure Tesults meets your organization's security and data policy requirements.</p>
                    <p>Learn more about the Tesults mission and vision on the <a href="/about" target="_blank" className="tr-link-primary4">about</a> page.</p>
                    <h2 className="mt-5 mb-5">Policies</h2>
                    <ul>
                        <li><a href="/docs/terms" target="_blank" className="tr-link-primary4">Terms of service</a></li>
                        <li><a href="/docs/privacy" target="_blank" className="tr-link-primary4">Privacy policy</a></li>
                        <li><a href="/docs/fair-billing-policy" target="_blank" className="tr-link-primary4">Fair Billing Policy</a></li>
                        <li><a href="/docs/refund-policy" target="_blank" className="tr-link-primary4">Cancellation and Refunds</a></li>
                    </ul>
                    <h2 className='mt-5 mb-5'>Data and security basics</h2>
                    <ul>
                        <li><a href="/docs/security" target="_blank" className="tr-link-primary4">Security information</a></li>
                        <li>SSO with SAML 2.0 and Google OAuth is offered for all plans.</li>
                        <li>Audit logs are available for all paid plans.</li>
                        <li>Data is encrypted at rest and in transit. Tesults private keys are used to encrypt data for all self-serve plans (Free, Standard and Plus). Bring your own private keys is offered on the Enterprise Plan.</li>
                        <li>Data is stored in data centers located within the United States <img src="/img/usa-flag.png" alt="USA" height="20"/>.</li>
                        <li>See the 'Data Storage and Security' section in the <a href="/docs/terms" className="tr-link-primary4">terms of service</a> for more details.</li>
                    </ul>
                    <h2 className="mt-5 mb-5">Questions and requests related to onboarding and due diligence</h2>
                    <p>We are here to support you personally with your due diligence efforts. Contact <a className="tr-link-primary4" href="mailto:help@tesults.com">help@tesults.com</a> for all vendor onboarding and due diligence requests. If your questions are not answered in the documents linked above or you require clarfication simply get in touch and we will work with you to resolve your concerns and bring clarity where needed.</p>
                    <p>Ensuring Tesults implements up-to-date industry best practices and adheres to stringent data and security standards is critical to our business. We are confident we already meet your requirements and where necessary can work with you to remedy any concerns you have.</p>
                </div>
            </div>
        )
    }
}

export default DocsVendorOnboarding