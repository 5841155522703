/*global */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsOrganization extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Tesults - Organization Configuration Guide</title>
                    <meta name="description" content="Learn how to configure a Tesults organization."/>
                </Helmet>
                <p>Go to the <b>Configuration</b> menu.</p>
                <Link target="_blank" to="/config">
                    <img src="https://www.tesults.com/files/docs/configuration-menu-selection.png" width="200px" alt="Configuration menu" className="doc-img standardborder"/>
                </Link>
                <p>Select Organization.</p>
                <h2>Organization Name</h2>
                <p>Click the 'Edit' button to add or update the organization name. Enter the name into the input field and then click the 'Update' button.</p>
                <h2>Organization Privacy Scope</h2>
                <p>By default the organization privacy scope is set to private. Edit privacy scope in the case that you want to make a subset of your results public. Setting an organization public alone is not sufficient to make results public, there are privacy controls at the organization, project and target levels and all three need to be set to public in order for results for a specific target to be made available at a public link.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/j-cnId4rMXU?si=WigFTK1fn4R6qXDe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        );
    }
}

export default DocsOrganization;