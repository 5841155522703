/*global */
import React, { Component } from 'react';
import Result from './Result'
import {utilsRawResultMapMap} from './Utils'

class BuildCase extends Component {
    render() {
        const c = this.props.case;
        if (c === undefined || c === null) {
            return <div></div>
        }
        
        let img = "/img/tesults-logo-unknown-128.png";
        if (c.result.toLowerCase() === "pass") {
            img = "/img/tesults-logo-pass-128.png";
        } else if (c.result.toLowerCase() === "fail") {
            img = "/img/tesults-logo-fail-128.png";   
        }
        let params = "";
        if (c.params !== undefined) {
            if (c.params !== undefined) {
                let paramString = "";
                for (let param in c.params) {
                    if (c.params.hasOwnProperty(param)) {
                        paramString += param + " = " + c.params[param] + ", ";
                    }
                }
                if (paramString !== "") {
                    paramString = paramString.substr(0, paramString.length - 2);
                }
                params = <h6 className="neutral4">{paramString}</h6>
            }
        }

        let flaky = <span></span>
        if (c.flaky !== undefined) {
            if (c.flaky === true) {
                flaky = <img className="ml-1" src="/img/flaky-primary4-128.png" alt="flaky" width="16" height="16"/>
            }
        }

        let bug = <span></span>
        if (c.bugs !== undefined) {
            if (c.bugs.length > 0) {
                bug = <img className="ml-3" src="/img/bug.svg" alt="bug" width="16" height="16"/>
            }
        }

        const map = utilsRawResultMapMap(this.props.targets, this.props.targetIndex, this.props.rawResultMaps)
        
        return (
            <div style={{"display":"flex", "alignItems":"center", "padding-left":"4px"}} className="caseLink" onClick={() => this.props.selectCase(c)}>
                <div className='neutral4 font14' style={{"display":"flex", "alignItems":"center"}}>
                    <div className='mr-2'>
                        <img style={{"marginTop":"5px"}} src="/img/tag.svg" width="16" height="16" alt=""/>
                    </div>
                    <div className='mr-2'>
                        Build:
                    </div>
                </div>
                <Result result={c.result} rawResult={c.rawResult} map={map} case={c}/>
                <div style={{"flex":"1", "white-space": "nowrap", "overflow":"hidden", "text-overflow":"ellipsis"}} className="font14 ml-2 neutral3">{c.name}</div> 
                <div>{params} {flaky} {bug}</div> 
            </div>
        );
    }
};

export default BuildCase;