/*global Cookies*/
import React, { Component } from 'react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Constants from './Constants';
import ResultsView from './ResultsView';
import SupplementalView from './SupplementalView';
import StatusView from './StatusView';
import Loading from './Loading';
import Analytics from './Analytics';
import Onboarding from './Onboarding';
import TimeFormatted from './TimeFormatted';
import SetupAssistant from './SetupAssistant';
import MultiCaseEditOptions from './MultiCaseEditOptions';
import Header from './Header';
import ResultsHeader1 from './ResultsHeader1';
import ResultsHeader2 from './ResultsHeader2';
import ResultsHeader3 from './ResultsHeader3';
import ResultsHeader8 from './ResultsHeader8';
import ResultsHeader9 from './ResultsHeader9';
import ResultsHeader10 from './ResultsHeader10';
import ResultsSideMod from './ResultsSideMod';
import Share from './Share';
import Context from './Context';
import Cache from './Cache';
import Request from './Request';
import ResultsHeaderGeneric from './ResultsHeaderGeneric';
import RSPHandler from './RSPHandler';
import Unauthenticated from './Unauthenticated';
import Diff from './Diff';
import TargetDetail from './TargetDetail';
import SelectSide from './SelectSide';
import Job from './Job'
const papa = require('papaparse');

/*
getProjects 	-> getTargets 	-> getRuns	-> getCases 	-> syncPersistentCaseData
                                -> getPersistentCaseData    -> syncPersistentCaseData
                                -> getPersistentSuiteData   -> syncPersistentSuiteData
													    -> getLatestRunAndCasesCopy	-> getOldestRun	-> syncPersistentCaseData
                                                                                                    -> syncPersistentSuiteData
                                -> getCaseEnhancedAnalysisData
                -> getProjectRoleFunctionality	-> getProjectUsage
                -> getOrg
                -> getPreferencesReporting
*/

class ResultsWhole extends Component {
    static contextType = Context;

    constructor (props) {
        super(props);
        const demo = this.props.demo;
        const user = Cookies.getJSON("truser");
        let state = "results";

        let trl = false;
        let trlLoading = false;
        if (this.props.trlOrg !== undefined) {
            // trl
            trl = true;
            trlLoading = true;
        } else {
            if ((user === undefined || user == null)) {
                state = null;
            }
        }

        // Start check rsp - checks results url includes results specific path (rsp)
        let rsp = RSPHandler.rspData();
        // End check rsp

        let view = "results";
        if (demo !== undefined) {
            Analytics.event("DemoResults");
            if (demo === "true") {
                state = "results";
            }
        } else {
            let savedView = Cache.getPreference(Cache.preference.resultsView);
            if (savedView === undefined) {
                savedView = "results";
                Analytics.event("ResultsView");
            }
            if (savedView === "results" || savedView === "supplemental" || savedView === "status" || savedView === "diff") {
                view = savedView;
            }
            if (rsp.view !== undefined) {
                if (rsp.view === "results" || rsp.view === "supplemental" || rsp.view === "status" || rsp.view === "diff") {
                    view = rsp.view;
                }
            }
            if (this.props.trlView !== undefined) {
                view = this.props.trlView
            }
            if (view === "results") {
                Analytics.event("ResultsView");
            } else if (view === "supplemental") {
                Analytics.event("SupplementalView");
            } else if (view === "status") {
                Analytics.event("StatusView");
            } else if (view === "diff") {
                Analytics.event("DiffView");
            }
        }
        let darkMode = Cache.getPreference(Cache.preference.darkMode);
        this.state = {
            state: state,
            projects: [],
            targets: [],
            runs: [],
            cases: [],
            builds: [],
            projectIndex: 0,
            targetIndex: 0,
            runIndex: 0,
            sort: "suite",
            filter: "none",
            search: undefined,
            loading: false,
            runesk: undefined,
            moreRuns: false,
            demo: demo,
            view: view,
            usage: undefined,
            rsp: rsp,
            rspNotFound: undefined,
            role: 1,
            darkMode: darkMode,
            loadingValue: "",
            trl: trl,
            trlLoading: trlLoading,
            trlFound: false,
            multiselect: undefined,
            multiEdit: false,
            width: window.innerWidth, 
            height: window.innerHeight,
            persistentCaseDataIteration: 0,
            persistentSuiteDataIteration: 0,
            confirmation: {success: undefined, failure: undefined},
            resultsSubView: this.props.resultsSubView,
            members: [],
            diffTargetIndex1: 0,
            diffTargetIndex2: 0,
            diffRunIndex1: 0,
            diffRunIndex2: 0,
            diffRuns1: [],
            diffRuns2: [],
            diffRuns1Esk: undefined,
            diffRuns2Esk: undefined,
            diffCases1: [],
            diffCases2: [],
            collapseAll: false,
            group: 'All',
            rawResultMaps: [],
            org: undefined,
            preferencesReportingCaseDisplayAttributeMaps: [],
            runArchive: undefined,
            runArchiveBuildCase: undefined,
            runArchiveCases: [],
            rev: 0,
            caseEnhancedAnalysis: undefined
        };

        let group = Cache.getPreference(Cache.preference.targetGroup);
        if (group !== undefined) {
            this.state.group = group
        }

        this.resultsMonitorTimerId = null;
        this.resultsMonitorInterval = 300000; // 5 minutes
        this.statusView = this.statusView.bind(this);
        this.resultsView = this.resultsView.bind(this);
        this.supplementalView = this.supplementalView.bind(this);
        this.diffView = this.diffView.bind(this)
        this.projectChange = this.projectChange.bind(this);
        this.targetChange = this.targetChange.bind(this);
        this.runChange = this.runChange.bind(this);
        this.sortChange = this.sortChange.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.getTargets = this.getTargets.bind(this);
        this.getRuns = this.getRuns.bind(this);
        this.getMoreRunsForRsp = this.getMoreRunsForRsp.bind(this);
        this.getMoreRunsForTrl = this.getMoreRunsForTrl.bind(this);
        this.getMoreRuns = this.getMoreRuns.bind(this);
        this.getCases = this.getCases.bind(this);
        this.getProjectUsage = this.getProjectUsage.bind(this);
        this.statusTargetSelect = this.statusTargetSelect.bind(this);
        this.resultsMonitorOnLoad = this.resultsMonitorOnLoad.bind(this);
        this.resultsMonitorRequests = this.resultsMonitorRequests.bind(this);
        this.backgroundColor = this.backgroundColor.bind(this);
        this.getProjectRoleFunctionality = this.getProjectRoleFunctionality.bind(this);
        this.deleteRun = this.deleteRun.bind(this);
        this.sendNotifications = this.sendNotifications.bind(this);
        this.newManualTestRun = this.newManualTestRun.bind(this);
        this.testLists = this.testLists.bind(this);
        this.getLatestRunAndCasesCopy = this.getLatestRunAndCasesCopy.bind(this);
        this.getOldestRun = this.getOldestRun.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.getPersistentCaseData = this.getPersistentCaseData.bind(this);
        this.persistentCaseDataUpdate = this.persistentCaseDataUpdate.bind(this);
        this.syncPersistentCaseData = this.syncPersistentCaseData.bind(this);
        this.getPersistentSuiteData = this.getPersistentSuiteData.bind(this);
        this.persistentSuiteDataUpdate = this.persistentSuiteDataUpdate.bind(this);
        this.syncPersistentSuiteData = this.syncPersistentSuiteData.bind(this);
        this.syncTargetNote = this.syncTargetNote.bind(this);
        this.getCaseEnhancedAnalysis = this.getCaseEnhancedAnalysis.bind(this);
        this.titleUpdate = this.titleUpdate.bind(this);
        this.refresh = this.refresh.bind(this);
        this.loadUpdatedResults = this.loadUpdatedResults.bind(this);
        this.getTrlData = this.getTrlData.bind(this);
        this.multiEdit = this.multiEdit.bind(this);
        this.multiselectUpdate = this.multiselectUpdate.bind(this);
        this.multiEditConfirm = this.multiEditConfirm.bind(this);
        this.noProjects = this.noProjects.bind(this);
        
        this.resizeEvent = this.resizeEvent.bind(this);
        this.displayResultsUpdateMessage = this.displayResultsUpdateMessage.bind(this);
        this.rspCancel = this.rspCancel.bind(this);
        this.stateControl = this.stateControl.bind(this);

        this.members = this.members.bind(this);
        this.targetUpdate = this.targetUpdate.bind(this);
        this.diffTargetIndex1Change = this.diffTargetIndex1Change.bind(this)
        this.diffTargetIndex2Change = this.diffTargetIndex2Change.bind(this)
        this.diffRunIndex1Change = this.diffRunIndex1Change.bind(this)
        this.diffRunIndex2Change = this.diffRunIndex2Change.bind(this)
        this.getDiffRuns = this.getDiffRuns.bind(this)
        this.getDiffCases = this.getDiffCases.bind(this)
        this.getDiffLatestRunAndCasesCopy = this.getDiffLatestRunAndCasesCopy.bind(this)
        this.toggleCollapseAll = this.toggleCollapseAll.bind(this)
        this.groupChange = this.groupChange.bind(this)
        this.getPreferencesReporting = this.getPreferencesReporting.bind(this)

        this.getRunArchive = this.getRunArchive.bind(this)
        this.getRunArchiveCases = this.getRunArchiveCases.bind(this)
        this.isRunArchive = this.isRunArchive.bind(this)

        this.revUpdate = this.revUpdate.bind(this)
    }

    componentDidMount () {
        console.log(this.context)
        window.addEventListener('resize', this.resizeEvent);
        if (this.state.state === null && this.state.rsp.projectId !== undefined) {
            // not logged in but have rsp.projectId
            this.setState({unauthenticated: false})
        } else if (this.state.state === "results") {
            if (this.state.trl === false) {
                this.resultsMonitorOnLoad();
                if (this.state.rsp.projectId === undefined) {
                    //this.getResultsData(); // this only supports non rsp/trl for now
                    this.getProjects();
                } else {
                    this.getProjects();
                }
            } else {
                this.getTrlData();
            }
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.resultsSubView !== prevProps.resultsSubView) {
            this.setState({resultsSubView: this.props.resultsSubView, view: this.props.resultsSubView});
        }
        this.stateControl()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEvent);
    }

    resizeEvent () {
        this.setState({ width: window.innerWidth, height: window.innerHeight});
    }

    stateControl () {
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        let rsp = RSPHandler.rspData();
        if (rsp.projectId !== undefined) {
            if (this.context.projects !== undefined) {
                if (Array.isArray(this.context.projects)) {
                    if (projectIndex < this.context.projects.length) {
                        let project = this.context.projects[projectIndex]
                        if (project.id === rsp.projectId) {
                            // projectId matches state
                            if (rsp.targetId !== undefined) {
                                if (this.state.targets !== undefined) {
                                    if (Array.isArray(this.state.targets)) {
                                        if (this.state.targetIndex < this.state.targets.length) {
                                            let target = this.state.targets[this.state.targetIndex]
                                            if ((target.created.toString()) === rsp.targetId) {
                                                // targetId matches state
                                                if (rsp.view !== undefined) {
                                                    if (rsp.view !== this.state.view) {
                                                        // view does not match state
                                                        if (rsp.view === "status" || this.state.view === "status" || rsp.view === "diff" || this.state.view === "diff") {
                                                            window.location.reload(true)
                                                        } else {
                                                            this.setState({view: rsp.view})
                                                        }
                                                    }
                                                }
                                            } else {
                                                // targetId does not match state
                                                let targetExists = false
                                                for (let i = 0; i < this.state.targets; i++) {
                                                    let target = this.state.targets[i]
                                                    if ((target.created.toString()) === rsp.targetId) {
                                                        targetExists = true
                                                    }
                                                }
                                                if (targetExists === true) {
                                                    window.location.reload(true);
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (rsp.view !== undefined) {
                                    if (rsp.view !== this.state.view) {
                                        this.setState({view: rsp.view})
                                        //window.location.reload(true)
                                    }
                                }
                            }
                        } else {
                            // projectId does not match state
                            let projectExists = false
                            for (let i = 0; i < this.context.projects.length; i++) {
                                let project = this.context.projects[i]
                                if (project.id === rsp.projectId) {
                                    Cache.setPreference(Cache.preference.projectIndex, i)
                                    projectExists = true
                                }
                            }
                            if (projectExists === true) {
                                window.location.reload(true);
                            }
                        }
                    }
                }
            }
        } else {
            if (rsp.view !== undefined) {
                if (rsp.view !== this.state.view) {
                    window.location.reload(true)
                }
            }
        }
    }

    toggleCollapseAll () {
        this.setState({collapseAll: this.state.collapseAll ? false : true})
    }

    refresh () {
        //Analytics.event("Refresh");
        if (window.location === Constants.baseUrl + "/results") {
            window.location.reload(true);
        } else {
            window.location.href = Constants.baseUrl + "/results";
        }
    }

    rspCancel () {
        this.setState({rsp: {}});
    }

    displayResultsUpdateMessage (updatedTarget) {
        if (this.state.targetIndex === undefined || this.state.targets === undefined) {
            return;
        }
        if (this.state.targetIndex >= this.state.targets.length) {
            return;
        }
        if (this.state.view === "status") {
            let index = -1
            for (let i = 0; i < this.state.targets.length; i++) {
                let target = this.state.targets[i]
                if (target.id === updatedTarget.id && parseInt(target.created, 10) === parseInt(updatedTarget.created, 10)) {
                    index = i
                }
            }
            if (index > -1) {
                let targets = this.state.targets
                let target = this.state.targets[index]
                target.updateAvailable = false
                targets[index] = target
                this.setState({targets: targets}, () => this.loadUpdatedResults(updatedTarget))
            }
        } else {
            let targets = this.state.targets;
            let target = targets[this.state.targetIndex];
            if (target.id === updatedTarget.id && parseInt(target.created, 10) === parseInt(updatedTarget.created, 10)) {
                if (target.updateAvailable === true) {
                    target.updateAvailable = false;
                    targets[this.state.targetIndex] = target
                    this.setState({targets: targets}, () => this.props.messageOverlay("results-update", () => this.loadUpdatedResults(target)));
                }
            }
        }
    }

    resultsMonitorRequests () {
        // Call for each target. Targets have run / cases.
        const resultsMonitorRequest = (target) => {
            Analytics.event("ResultsMonitorRequest", {"Project": target.id, "Target": target.created});
            Request.get("/latestRunAndCasesCopy", {id: target.id, created: target.created}, (err, data) => {
                if (err) {
                    // Do nothing
                } else {
                    if (data.id !== undefined && data.created !== undefined) {
                        if (this.state.targets !== undefined) {
                            if (Array.isArray(this.state.targets)) {
                                let index = -1
                                let target = undefined
                                for (let i = 0; i < this.state.targets.length; i++) {
                                    target = this.state.targets[i]
                                    if (target.id === data.id && parseInt(target.created, 10) === parseInt(data.created, 10)) {
                                        index = i
                                        target.updateAvailable = false
                                        if (data.run !== undefined) {
                                            //if (target.run === undefined) {
                                                //target.updateAvailable = true
                                                //target.update = {run: data.run, cases: data.cases}
                                            //} else {
                                            if (target.run !== undefined) {
                                                if (parseInt(data.run.created, 10) > parseInt(target.run.created, 10)) {
                                                    target.updateAvailable = true
                                                    target.lastUpdateRunCreated = data.run.created
                                                    target.update = {run: data.run, cases: data.cases}
                                                }
                                            }
                                        }
                                        break
                                    }
                                }
                                if (target !== undefined) {
                                    if (index > -1 && target.updateAvailable === true) {
                                        let targets = this.state.targets
                                        targets[index] = target
                                        this.setState({targets: targets}, () => this.displayResultsUpdateMessage(target));
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }
        
        if (this.state.view === "status") {
            for (let i = 0; i < this.state.targets.length; i++) {
                resultsMonitorRequest(this.state.targets[i], i);
            }
        } else {
            if (this.state.targetIndex !== undefined && this.state.targets !== undefined) {
                if (this.state.targetIndex < this.state.targets.length) {
                    resultsMonitorRequest(this.state.targets[this.state.targetIndex], this.state.targetIndex)
                }
            }
        }
        clearTimeout(this.resultsMonitorTimerId);
        if (this.state.view !== "diff") {
            this.resultsMonitorTimerId = setTimeout(this.resultsMonitorRequests, this.resultsMonitorInterval);
        }
    }

    resultsMonitorOnLoad () {
        this.resultsMonitorTimerId = setTimeout(this.resultsMonitorRequests, this.resultsMonitorInterval);
    }

    statusView () {
        Cache.setPreference(Cache.preference.resultsView, "status");
        Analytics.event("StatusView");
        this.setState({view: "status", rsp: {}, modalCase: undefined}, this.titleUpdate);
    }

    resultsView () {
        Cache.setPreference(Cache.preference.resultsView, "results");
        Analytics.event("ResultsView");
        this.props.resultsView();
        /*this.setState({view: "results", rsp: {}, modalCase: undefined}, () => {
            this.titleUpdate();
            this.props.resultsView();
        });*/
    }

    supplementalView () {
        Cache.setPreference(Cache.preference.resultsView, "supplemental");
        Analytics.event("SupplementalView");
        this.props.supplementalView();
        /*this.setState({view: "supplemental", rsp: {}, modalCase: undefined}, () => {
            this.titleUpdate();
            this.props.supplementalView();
        });*/
    }

    diffView () {
        Cache.setPreference(Cache.preference.resultsView, "diff");
        Analytics.event("DiffView");
        this.setState({view: "diff", rsp: {}, modalCase: undefined}, () => {
            this.titleUpdate()
            let path = window.location.pathname;
            path = path.replace("/view/results", "/view/diff")
            path = path.replace("/view/supplemental", "/view/diff")
            path = path.replace("/view/status", "/view/diff")
            this.props.history.push(path);
            window.location.reload(true)
        });
    }

    diffUrlChange (level) {
        let project = undefined
        let projectIndex = Cache.getPreference(Cache.preference.projectIndex)
        if (this.context.projects !== undefined) {
            if (Array.isArray(this.context.projects)) {
                if (projectIndex < this.context.projects.length) {
                    project = this.context.projects[projectIndex]
                }
            }
        }
        if (project === undefined) {
            return
        }
        // level can be project, target, or run
        const projectPath = () => {
            return "/results/rsp/view/diff/project/" + project.id
        }
        const targetPath = () => {
            let target1 = undefined
            let target2 = undefined
            if (this.state.diffTargetIndex1 < this.state.targets.length) {
                target1 = this.state.targets[this.state.diffTargetIndex1]
            }
            if (this.state.diffTargetIndex2 < this.state.targets.length) {
                target2 = this.state.targets[this.state.diffTargetIndex2]
            }
            if (target1 === undefined || target2 === undefined) {
                return undefined
            }
            return "/target1/" + target1.created + "/target2/" + target2.created
        }
        const runPath = () => {
            let run1 = undefined
            let run2 = undefined
            if (this.state.diffRunIndex1 < this.state.diffRuns1.length) {
                run1 = this.state.diffRuns1[this.state.diffRunIndex1]
            }
            if (this.state.diffRunIndex2 < this.state.diffRuns2.length) {
                run2 = this.state.diffRuns2[this.state.diffRunIndex2]
            }
            if (run1 === undefined || run2 === undefined) {
                return undefined
            }
            return "/run1/" + run1.created + "/run2/" + run2.created
        }
        let pPath = projectPath()
        if (level === "project") {
            this.props.history.push(pPath);
        } else if (level === "target") {
            let tPath = targetPath()
            if (tPath === undefined) {
                this.props.history.push(pPath)
            } else {
                this.props.history.push(pPath + tPath)
            }
        } else if (level === "run") {
           let rPath = runPath()
           let tPath = targetPath()
           if (rPath === undefined && tPath !== undefined) {
               this.props.history.push(pPath + tPath)
           } else if (rPath !== undefined && tPath !== undefined) {
            this.props.history.push(pPath + tPath + rPath)
           } else {
               this.props.history.push(pPath)
           }
        }
    }

    projectChange (index) {
        Cache.setPreference(Cache.preference.projectIndex, index);
        this.setState({projectIndex: index, targets: [], targetIndex: 0, runs: [], runIndex: 0, rsp: {}});
        this.getTargets(this.context.projects[index]);
        this.getOrg(this.context.projects[index])
        this.getProjectRoleFunctionality(this.context.projects[index]);
        this.getRawResultMaps(this.context.projects[index])
    }

    targetChange (index) {
        if (index !== undefined) {
            Cache.setPreference(Cache.preference.targetIndex, index);
            this.setState({targetIndex: index, runIndex: 0, rsp: {}}, this.getCaseEnhancedAnalysis);
            this.getRuns(this.state.targets[index], true);
            this.syncPersistentSuiteData()
            if (this.state.trl === true) {
                this.props.history.push("/" + this.props.trlOrg + "/" + this.state.projects[this.state.projectIndex].name  + "/" + this.state.targets[index].name);
            } else {
                this.props.history.push("/results/rsp/view/results/target/" + this.state.targets[index].id + "-" + this.state.targets[index].created);
            }
        }
    }

    runChange (index) {
        let i = parseInt(index, 10);
        const numRuns = 25;
        let targetIndex = Cache.getPreference(Cache.preference.targetIndex)
        if (targetIndex === undefined) {
            targetIndex = 0
            Cache.setPreference(Cache.preference.targetIndex, 0)
        }
        const target = this.state.targets[targetIndex]
        if (this.state.trl === true) {
            this.props.history.push("/" + this.props.trlOrg + "/" + this.state.projects[this.state.projectIndex].name  + "/" + target.name + "/" + this.state.runs[index].created);
            this.getCases(target, this.state.runs, i, numRuns, this.state.cases, this.state.builds);
        } else {
            this.props.history.push("/results/rsp/view/results/run/" + target.id + "-" + target.created + "-" + this.state.runs[index].created);
            this.getCases(target, this.state.runs, i, numRuns, this.state.cases, this.state.builds);
        }
    }

    sortChange (value) {
        this.setState({sort: value});
    }

    filterChange (value) {
        this.setState({filter: value});
    }

    searchChange (value) {
        this.setState({search: value});
    }

    diffTargetIndex1Change (value) {
        Analytics.event("DiffTarget1Change")
        this.setState(
            {
                diffTargetIndex1: value, 
                diffRuns1: [], 
                diffRuns1Esk: undefined,
                diffRunIndex1: 0,
                rsp: {},
                diffRspNotFound: false
            }, 
        () => {
            this.getDiffRuns(1)
            this.diffUrlChange("target")
        })
    }

    diffTargetIndex2Change (value) {
        Analytics.event("DiffTarget2Change")
        this.setState(
            {
                diffTargetIndex2: value, 
                diffRuns2: [], 
                diffRuns2Esk: undefined,
                diffRun2Index: 0,
                rsp: {},
                diffRspNotFound: false
            }
            , 
        () => {
            this.getDiffRuns(2)
            this.diffUrlChange("target")
        })
    }

    diffRunIndex1Change (value) {
        Analytics.event("DiffRun1Change")
        let target = undefined
        if (this.state.diffTargetIndex1 < this.state.targets.length) {
            target = this.state.targets[this.state.diffTargetIndex1]
            this.setState({diffRunIndex1: value, rsp: {}, diffRspNotFound: false}, () => {
                this.getDiffCases(target, 1)
                this.diffUrlChange("run")
            })
        } else {
            this.setState({diffRunIndex1: value})
        }
    }

    diffRunIndex2Change (value) {
        Analytics.event("DiffRun2Change")
        let target = undefined
        if (this.state.diffTargetIndex2 < this.state.targets.length) {
            target = this.state.targets[this.state.diffTargetIndex2]
            this.setState({diffRunIndex2: value, rsp: {}, diffRspNotFound: false}, () => {
                this.getDiffCases(target, 2)
                this.diffUrlChange("run")
            })
        } else {
            this.setState({diffRunIndex2: value})
        }
    }

    loadUpdatedResults (updatedTarget) {
        Analytics.event("LoadUpdatedResults")
        let targets = this.state.targets
        let index = -1
        if (targets !== undefined) {
            if (Array.isArray(targets)) {
                for (let i = 0; i < targets.length; i++) {
                    let target = targets[i]
                    if (target.id === updatedTarget.id && parseInt(target.created, 10) === parseInt(updatedTarget.created, 10)) {
                        index = i
                        break
                    }
                }
            }
        }
        if (index > -1) {
            let displayLoading = this.state.view === "status" ? false : true
            let target = targets[index]
            target.updateAvailable = false;
            target.prevRun = target.run;
            target.prevCases = target.cases;
            target.run = target.update.run;
            target.cases = target.update.cases;
            targets[index] = target;
            this.setState({targets: targets}, this.getRuns(this.state.targets[index], displayLoading));
        }
    }

    statusTargetSelect(index) {
        if (index !== undefined) {
            Cache.setPreference(Cache.preference.targetIndex, index);
            Cache.setPreference(Cache.preference.resultsView, "results");
            this.setState({view: "results", targetIndex: index});
            if (this.state.trl) {
                this.props.history.push("/" + this.props.trlOrg + "/" + this.props.trlProject + "/" + this.state.targets[index].name)
            } else {
                this.props.history.push("/results/rsp/view/results/target/" + this.state.targets[index].id + "-" + this.state.targets[index].created);
            }
            if (this.state.targets.length > 0) {
                this.getRuns(this.state.targets[index]);
            } else {
                this.setState({loading: false});
            }
        }
    }

    
    getResultsData () {
        if (this.state === undefined) {
            return;
        }
        const pi = Cache.getPreference(Cache.preference.projectIndex);
        const ti = Cache.getPreference(Cache.preference.targetIndex);
        this.setState({loading: true});
        Request.get("/results-data", {pi: pi, ti: ti}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState(
                    {
                        loading: false,
                        projects: data.projects,
                        targets: data.targets,
                        role: data.role,
                        usage: data.usage,
                        persistentCaseDataByTarget: data.persistentCaseData,
                        persistentSuiteDataByTarget: data.persistentSuiteData,
                        runs: data.runs, 
                        runesk: data.esk, 
                        moreRuns: data.esk !== undefined ? true : false,
                        cases: data.cases, 
                        builds: data.builds, 
                        casesLoading: false,
                        preRender: true,
                        oldestRun: data.oldestRun,
                        runIndex: 0
                    }, () => { 
                        this.syncPersistentCaseData()
                        this.syncPersistentSuiteData()
                    }
                );
            } 
        });
    }

    getProjects () {
        this.setState({loading: true});
        if (this.state === undefined) {
            this.setState({loading: false});
            return;
        }

        const processProjects = (projects) => {
            let rspProjectFound = false;
            let projectIndex = Cache.getPreference(Cache.preference.projectIndex);
            if (this.state.rsp.projectId !== undefined) {
                for (let i = 0; i < projects.length; i++) {
                    let project = projects[i];
                    if (project.id === this.state.rsp.projectId) {
                        projectIndex = i;
                        rspProjectFound = true;
                    }
                }
            } else {
                rspProjectFound = true;
            }

            let rspNotFound = undefined;
            if (rspProjectFound === false) {
                rspNotFound = <span>The project for this link could not be found. <NavLink to="/results" target="_blank" className="tr-link-primary4">View latest results</NavLink>.</span>   
            }
            
            let loading = false;
            if (projects === undefined) {
                this.setState({loading: false});
                return;
            } else if (projects.length > 0) {
                if (projects[projectIndex].status === "active") {
                    this.getTargets(projects[projectIndex])
                    this.getOrg(projects[projectIndex])
                    this.getProjectRoleFunctionality(projects[projectIndex])
                    this.getRawResultMaps(projects[projectIndex])
                    this.getPreferencesReporting(projects[projectIndex])
                    loading = true;
                }
                
            } else {
               // this.setState({loading: false});
            }
            this.setState({projects: projects, projectIndex: projectIndex, rspNotFound: rspNotFound, loading: loading});
        }
        
        let projects = this.context.projects;
        if (projects !== undefined) {
            processProjects(projects);
        } else {
            Cache.request(this.context, Cache.data.projects, {}, (err, projects) => {
                if (err) {
                    this.setState({loading: false});
                    return;
                } else {
                    processProjects(projects);
                }
            });
        }
    }

    getTargets (project) {
        this.setState({loading: true, loadingValue: "Loading targets"});
        if (project === undefined) {
            this.setState({loading: false});
            return;
        }
        Cache.request(this.context, Cache.data.targets, {id: project.id, override: true, demo:this.state.demo}, (err, targets) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let targetIndex = Cache.getPreference(Cache.preference.targetIndex);
                if (targetIndex === undefined) {
                    targetIndex = 0;
                }
                let rspTargetFound = false;
                if (this.state.rsp.targetId !== undefined) {
                    for (let i = 0; i < targets.length; i++) {
                        let target = targets[i];
                        if (target.created.toString() === this.state.rsp.targetId) {
                            targetIndex = i;
                            rspTargetFound = true;
                        }
                    }
                } else {
                    rspTargetFound = true;
                }
                let rspNotFound = this.state.rspNotFound;
                if (rspTargetFound === false) {
                    rspNotFound = <span>The target for this link could not be found. <NavLink to="/results" target="_blank" className="tr-link-primary4">View latest results</NavLink>.</span>   
                }
                if (targets.length > 0) {
                    this.setState({targets: targets, targetIndex: targetIndex, rspNotFound: rspNotFound, loading: false}, () => this.getRuns(this.state.targets[this.state.targetIndex], true));
                } else {
                    this.setState({loading: false, targets: targets, targetIndex: targetIndex, rspNotFound: rspNotFound});
                }
                this.getPersistentCaseData(targets);
                this.getPersistentSuiteData(targets);
                this.members();
                this.getCaseEnhancedAnalysis();
            }
        });
    }

    getRawResultMaps (project) {
        this.setState({loading: true});
        Request.get("/raw-result-map-get-maps", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({loading: false, rawResultMaps: data.maps})
            }
        })
    }


    getOrg (project) {
        Request.get("/org", {org: project.org}, (err, data) => {
            this.setState({org: data.org})
        })
    }

    syncPersistentCaseData () {
        if (this.state.view === "status") {
            return
        }
        if (this.state.persistentCaseDataByTarget !== undefined) {
            if (this.state.targetIndex < this.state.targets.length) {
                let target = this.state.targets[this.state.targetIndex];
                let persistentCaseData = this.state.persistentCaseDataByTarget[target.id + "-" + target.created];
                let persistentCaseDataDict = {};
                if (persistentCaseData !== undefined) {
                    persistentCaseData.forEach(function (p) {
                        persistentCaseDataDict[p.hash] = p;
                    })
                    let cases = this.state.cases.slice();
                    cases.forEach(function (casesForRun) {
                        casesForRun.forEach(function (c) {
                            if (persistentCaseDataDict[c.hash] !== undefined) {
                                c.bugs = persistentCaseDataDict[c.hash].bugs;
                                c.cost = persistentCaseDataDict[c.hash].cost;
                                c.jiraTransitions = persistentCaseDataDict[c.hash].jiraTransitions
                                if (c.cost !== undefined) {
                                    if (c.cost.value !== undefined) {
                                        c.cost.value = parseFloat(c.cost.value);
                                    }
                                }
                            }
                        });
                    });
                    this.setState({cases: cases, persistentCaseDataIteration: this.state.persistentCaseDataIteration + 1});    
                }
            }
        }
    }

    syncPersistentSuiteData () {
        if (this.state.view === "status") {
            return
        }
        if (this.state.persistentSuiteDataByTarget !== undefined) {
            if (this.state.targetIndex < this.state.targets.length) {
                let target = this.state.targets[this.state.targetIndex];
                let persistentSuiteData = this.state.persistentSuiteDataByTarget[target.id + "-" + target.created];
                this.setState({persistentSuiteData: persistentSuiteData, persistentSuiteDataIteration: this.state.persistentSuiteDataIteration + 1});
            }
        }
    }

    getPersistentCaseData (targets) {
        if (targets === undefined) {
            return;
        }

        let targetsSend = [];
        targets.forEach(function (target) {
            targetsSend.push({id: target.id, created: target.created});
        })

        let api = "/persistentCaseData";
        if (this.state.trl === true) {
            api = "/trlPersistentCaseData";
        }

        Request.post(api, {targets: targetsSend}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({persistentCaseDataByTarget: data.persistentCaseData, loading: false}, () => this.syncPersistentCaseData());
            }
        });
    }

    getPersistentSuiteData (targets) {
        if (targets === undefined) {
            return;
        }

        let targetsSend = [];
        targets.forEach(function (target) {
            targetsSend.push({id: target.id, created: target.created});
        })

        let api = "/persistentSuiteData";
        if (this.state.trl === true) {
            api = "/trlPersistentSuiteData";
        }

        Request.post(api, {targets: targetsSend}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                this.setState({persistentSuiteDataByTarget: data.persistentSuiteData, loading: false}, () => this.syncPersistentSuiteData());
            }
        });
    }

    getRuns (target, displayLoading) {
        if (this.state.view === "diff") {
            let diffTargetIndex1 = 0
            let diffTargetIndex2 = 0
            let diffRspNotFound = false
            if (this.state.rsp.target1Id !== undefined) {
                let index = -1
                for (let i = 0; i < this.state.targets.length; i++) {
                    let target = this.state.targets[i]
                    if (target.created.toString() === this.state.rsp.target1Id) {
                        diffTargetIndex1 = i
                        index = i
                    }
                }
                if (index === -1) {
                    diffRspNotFound = true
                }
            }
            if (this.state.rsp.target2Id !== undefined) {
                let index = -1
                for (let i = 0; i < this.state.targets.length; i++) {
                    let target = this.state.targets[i]
                    if (target.created.toString() === this.state.rsp.target2Id) {
                        diffTargetIndex2 = i
                        index = i
                    }
                }
                if (index === -1) {
                    diffRspNotFound = true
                }
            }
            if (diffTargetIndex1 !== 0 || diffTargetIndex2 !== 0) {
                this.setState({diffTargetIndex1: diffTargetIndex1, diffTargetIndex2: diffTargetIndex2, diffRspNotFound: diffRspNotFound}, () => {
                    this.getDiffRuns(1)
                    this.getDiffRuns(2)
                })
            } else {
                this.setState({diffRspNotFound: diffRspNotFound}, () => {
                    this.getDiffRuns(1)
                    this.getDiffRuns(2)
                })
            }
            return
        }
        if (this.state.view !== "status") {
            this.setState({loading: true});
        }
        if (target === undefined) {
            this.setState({loading: false});
            return;
        }
        if (displayLoading === undefined) {
            displayLoading = true;
        }
        let api = "/runs";
        if (this.state.trl === true) {
            api = "/trlRuns";
        }
        this.setState({loading: displayLoading, loadingValue: "Loading runs"});
        Request.get(api, {id: target.id, created: target.created, demo: this.state.demo}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let moreRuns = false;
                let runIndex = this.state.runIndex;
                let rspRunIdFound = false;
                let trlRunIdFound = false
                if (data.esk !== undefined) {
                    moreRuns = true;
                }

                // Check if this is a request for a run-archive and divert
                if (this.state.rsp.runArchiveId !== undefined) {
                    this.setState({runs: data.runs, runIndex: runIndex, runesk: data.esk, moreRuns: moreRuns}, () => this.getRunArchive())
                    return
                }

                if (this.state.rsp.runId !== undefined) {
                    for (let i = 0; i < data.runs.length; i++) {
                        let run = data.runs[i];
                        if (run.created.toString() === this.state.rsp.runId) {
                            runIndex = i;
                            rspRunIdFound = true;
                        }
                    }
                } else {
                    rspRunIdFound = true;
                }

                if (this.props.trlRun) {
                    for (let i = 0; i < data.runs.length; i++) {
                        let run = data.runs[i];
                        if (run.created.toString() === this.props.trlRun) {
                            runIndex = i;
                            trlRunIdFound = true;
                        }
                    }
                } else {
                    trlRunIdFound = true
                }

                if (rspRunIdFound === false) {
                    if (moreRuns === true) {
                        this.getMoreRunsForRsp(data.runs, data.esk);
                    } else {
                        this.setState({runs: data.runs, runesk: data.esk, moreRuns: moreRuns, loading: false, rspNotFound: <span>The test run for this link could not be found. <NavLink to="/results" target="_blank" className="tr-link-primary4">View latest results</NavLink>.</span>});
                    }
                } else if (trlRunIdFound === false) {
                    if (moreRuns === true) {
                        this.getMoreRunsForTrl(data.runs, data.esk)
                    } else {
                        this.setState({runs: data.runs, runesk: data.esk, moreRuns: moreRuns, loading: false, rspNotFound: <span>The test run for this link could not be found. <NavLink to={"/" + this.props.trlOrg + "/" + this.props.trlProject + "/" + this.props.trlTarget} target="_blank" className="tr-link-primary4">View latest results for this target</NavLink>.</span>});
                    }
                } else {
                    this.setState({runs: data.runs, runIndex: runIndex, runesk: data.esk, moreRuns: moreRuns});
                    if (data.runs.length > 0) {
                        const numRuns = 25;
                        this.getCases(target, this.state.runs, runIndex, numRuns, this.state.cases, this.state.builds);
                    } else {
                        this.setState({loading: false}, () => this.getLatestRunAndCasesCopy(target));
                    }
                }
            }
        });
    }

    getMoreRunsForRsp (runs, esk) {
        let target = this.state.targets[this.state.targetIndex];
        Request.get("/runs", {id: target.id, created: target.created, esk: esk, demo: this.state.demo}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let moreRuns = false;
                let runIndex = this.state.runIndex;
                if (data.esk !== undefined) {
                    moreRuns = true;
                }
                let rspRunIdFound = false;
                let combinedRuns = runs.concat(data.runs);

                if (this.state.rsp.runId !== undefined) {
                    for (let i = runs.length; i < combinedRuns.length; i++) {
                        let run = combinedRuns[i];
                        if (run.created.toString() === this.state.rsp.runId) {
                            runIndex = i;
                            rspRunIdFound = true;
                        }
                    }
                } else {
                    rspRunIdFound = true;
                }

                if (rspRunIdFound === false) {
                    if (moreRuns === true) {
                        this.getMoreRunsForRsp(combinedRuns, data.esk);
                    } else {
                        this.setState({runs: combinedRuns, runesk: data.esk, moreRuns: moreRuns, loading: false, rspNotFound: <span>The test run for this link could not be found. <NavLink to="/results" target="_blank" className="tr-link-primary4">View latest results</NavLink>.</span>});
                    }
                } else {
                    this.setState({runs: combinedRuns, runIndex: runIndex, runesk: data.esk, moreRuns: moreRuns});
                    if (combinedRuns.length > 0) {
                        const numRuns = 25;
                        this.getCases(target, combinedRuns, runIndex, numRuns, this.state.cases, this.state.builds);
                    } else {
                        this.setState({loading: false});
                    }
                }
            }
        });
    }

    getMoreRunsForTrl (runs, esk) {
        let target = this.state.targets[this.state.targetIndex];
        Request.get("/trlRuns", {id: target.id, created: target.created, esk: esk}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let moreRuns = false;
                let runIndex = this.state.runIndex;
                if (data.esk !== undefined) {
                    moreRuns = true;
                }
                let trlRunIdFound = false;
                let combinedRuns = runs.concat(data.runs);

                if (this.props.trlRun !== undefined) {
                    for (let i = runs.length; i < combinedRuns.length; i++) {
                        let run = combinedRuns[i];
                        if (run.created.toString() === this.props.trlRun) {
                            runIndex = i;
                            trlRunIdFound = true;
                        }
                    }
                } else {
                    trlRunIdFound = true;
                }

                if (trlRunIdFound === false) {
                    if (moreRuns === true) {
                        this.getMoreRunsForTrl(combinedRuns, data.esk)
                    } else {
                        this.setState({runs: combinedRuns, runesk: data.esk, moreRuns: moreRuns, loading: false, rspNotFound: <span>The test run for this link could not be found. <NavLink to={"/" + this.props.trlOrg + "/" + this.props.trlProject + "/" + this.props.trlTarget} target="_blank" className="tr-link-primary4">View latest results for this target</NavLink>.</span>});
                    }
                } else {
                    this.setState({runs: combinedRuns, runIndex: runIndex, runesk: data.esk, moreRuns: moreRuns});
                    if (combinedRuns.length > 0) {
                        const numRuns = 25;
                        this.getCases(target, combinedRuns, runIndex, numRuns, this.state.cases, this.state.builds);
                    } else {
                        this.setState({loading: false});
                    }
                }
            }
        });
    }

    getMoreRuns () {
        if (this.state === undefined) {
            return;
        }
        let target = this.state.targets[this.state.targetIndex];
        Request.get("/runs", {id: target.id, created: target.created, esk: this.state.runesk, demo: this.state.demo}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let moreRuns = false;
                if (data.esk !== undefined) {
                    moreRuns = true;
                }
                this.setState({runs: this.state.runs.concat(data.runs), runesk: data.esk, moreRuns: moreRuns, loading: false});
            }
        });
    }

    getLatestRunAndCasesCopy (target) {
        if (target === undefined) {
            return;
        }
        let api = "/latestRunAndCasesCopy";
        if (this.state.trl === true) {
            api = "/trlLatestRunAndCasesCopy";
        }
        Request.get(api, {id: target.id, created: target.created}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let run = data.run;
                if (run === undefined) {
                    this.setState({loading: false});
                } else {
                    let cases = data.cases;
                    let savedBuilds = [];
                    let savedCases = [];
                    let buildCases = [];
                    let testCases = [];
                    cases.forEach(function (c) {
                        if (c.suite === undefined) {
                            c.suite = "";
                        }
                        if (c.suite === "[build]") {
                            buildCases.push(c);
                        } else {
                            testCases.push(c);
                        }
                    });
    
                    if (buildCases.length > 0) {
                        savedBuilds[0] = buildCases[0];
                    } else {
                        savedBuilds[0] = undefined;
                    }
    
                    savedCases[0] = testCases;
                    this.setState({runs: [run], runIndex: 0, cases: savedCases, builds: savedBuilds, loading: false}, () => this.getOldestRun(target));
                }
            }
        });
    }

    getOldestRun (target) {
        if (target === undefined) {
            return;
        }
        Request.get("/runoldest", {id: target.id, created: target.created}, (err, data) => {
            if (err) {
                this.setState({loading: false, oldestRun: undefined});
            } else {
                this.setState({loading: false, oldestRun: data.run}, () => {
                    this.syncPersistentCaseData()
                    this.syncPersistentSuiteData()
                });
            }
        });
    }

    getRunArchive () {
        this.setState({loading: true})
        Request.get("/run-archive", {id: this.state.rsp.projectId, created: this.state.rsp.targetId, runCreated: this.state.rsp.runArchiveId}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                if (data.data.runArchive === undefined) {
                    this.setState({loading: false, runArchive: undefined})
                } else {
                    this.setState({loading: false, runArchive: data.data.runArchive}, () => this.getRunArchiveCases())
                }
            }
        })
    }

    getRunArchiveCases () {
        this.setState({loading: true})
        Request.get("/run-archive-cases", {id: this.state.rsp.projectId, created: this.state.rsp.targetId, runCreated: this.state.rsp.runArchiveId}, (err, data) => {
            this.setState({loading: false})
            if (err) {
                
            } else {
                if (data !== undefined) {
                    if (data.data !== undefined) {
                        if (data.data.cases !== undefined) {
                            if (Array.isArray(data.data.cases)) {
                                let runArchiveCases = []
                                let runArchiveBuildCase = undefined
                                data.data.cases.forEach((c) => {
                                    if (c.suite === undefined) {
                                        c.suite = "";
                                    }
                                    if (c.suite === "[build]") {
                                        runArchiveBuildCase = c
                                    } else {
                                        runArchiveCases.push(c)
                                    }
                                });
                                this.setState({runArchiveBuildCase: runArchiveBuildCase, runArchiveCases: runArchiveCases})
                            }
                        }
                    }
                }
            }
        })
    }

    isRunArchive () {
        let rsp = RSPHandler.rspData()
        return rsp.runArchiveId === undefined ? false : true
    }

    getDiffRuns (diffSide) {
        let index = undefined
        let esk = undefined
        if (diffSide === 1) {
            index = this.state.diffTargetIndex1
            esk = this.state.diffRuns1Esk
        } else if (diffSide === 2) {
            index = this.state.diffTargetIndex2
            esk = this.state.diffRuns2Esk
        } else {
            return // invalid diffSide
        }
        let target = undefined
        if (index < this.state.targets.length) {
            target = this.state.targets[index]
        } else {
            return // invalid target index
        }
        Request.get("/runs", {
            id: target.id, 
            created: target.created,
            esk: esk
        } , (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                if (diffSide === 1) {
                    let newRuns = undefined
                    if (esk === undefined) {
                        newRuns = data.runs
                    } else {
                        // Append to existing runs in this case
                        newRuns = this.state.diffRuns1
                        data.runs.forEach((run) => {
                            newRuns.push(run)
                        })
                    }
                    if (this.state.rsp.run1Id !== undefined) {
                        let index = -1
                        for (let i = 0; i < newRuns.length; i++) {
                            let run = newRuns[i]
                            if (this.state.rsp.run1Id === run.created.toString()) {
                                index = i
                            }
                        }
                        if (index > -1) {
                            // Found
                            this.setState({diffRuns1: newRuns, diffRunIndex1: index, diffRuns1Esk: data.esk}, () => this.getDiffCases(target, diffSide))
                        } else {
                            // Not found
                            if (data.esk === undefined) {
                                // RSP run does not exist
                                this.setState({diffRuns1: newRuns, diffRunIndex1: 0, diffRuns1Esk: data.esk, diffRspNotFound: true}, () => this.getDiffCases(target, diffSide))
                            } else {
                                // More runs to search for RSP run
                                this.setState({diffRuns1: newRuns, diffRuns1Esk: data.esk}, () => this.getDiffRuns(diffSide))
                            }
                        }
                    } else {
                        this.setState({diffRuns1: data.runs, diffRuns1Esk: data.esk}, () => this.getDiffCases(target, diffSide))
                    }
                } else if (diffSide === 2) {
                    let newRuns = undefined
                    if (esk === undefined) {
                        newRuns = data.runs
                    } else {
                        // Append to existing runs in this case
                        newRuns = this.state.diffRuns2
                        data.runs.forEach((run) => {
                            newRuns.push(run)
                        })
                    }
                    if (this.state.rsp.run2Id !== undefined) {
                        let index = -1
                        for (let i = 0; i < newRuns.length; i++) {
                            let run = newRuns[i]
                            if (this.state.rsp.run2Id === run.created.toString()) {
                                index = i
                            }
                        }
                        if (index > -1) {
                            // Found
                            this.setState({diffRuns2: newRuns, diffRunIndex2: index, diffRuns2Esk: data.esk}, () => this.getDiffCases(target, diffSide))
                        } else {
                            // Not found
                            if (data.esk === undefined) {
                                // RSP run does not exist
                                this.setState({diffRuns2: newRuns, diffRunIndex2: 0, diffRuns2Esk: data.esk, diffRspNotFound: true}, () => this.getDiffCases(target, diffSide))
                            } else {
                                // More runs to search for RSP run
                                this.setState({diffRuns2: newRuns, diffRuns2Esk: data.esk}, () => this.getDiffRuns(diffSide))
                            }
                        }
                    } else {
                        this.setState({diffRuns2: data.runs, diffRuns2Esk: data.esk}, () => this.getDiffCases(target, diffSide))
                    }
                }
            }
        });
    }

    getCases (target, runs, index, numRuns, savedCases, savedBuilds) {
        if (runs === undefined) {
            return;
        }

        let modalCase = undefined;
        if (this.state.rsp.runId !== undefined && this.state.rsp.caseNum !== undefined) {
            //modalCase = this.state.rsp.caseNum;
        }

        let api = "/casesForRuns";
        if (this.state.trl === true) {
            api = "/trlCasesForRuns";
        }
        this.setState({loadingValue: "Loading cases", loading: false, casesLoading: (this.state.view === "status" ? false : true)});
        Request.post(api, {runs: runs, runIndex: index, numRuns: numRuns}, (err, data) => {
            if (err) {
                this.setState({casesLoading: false});
            } else {
                let casesForRuns = data.casesForRuns;
                for (let i = 0; i < casesForRuns.length; i++) {
                    let buildCases = [];
                    let testCases = [];
                    let casesObj = casesForRuns[i];
                    let cases = casesObj.cases;
                    let runIndex = casesObj.runIndex;

                    cases.forEach(function (c) {
                        if (c.suite === undefined) {
                            c.suite = "";
                        }
                        if (c.suite === "[build]") {
                            buildCases.push(c);
                        } else {
                            testCases.push(c);
                        }
                    });
    
                    if (buildCases.length > 0) {
                        savedBuilds[runIndex] = buildCases[0];
                    } else {
                        savedBuilds[runIndex] = undefined;
                    }
    
                    savedCases[runIndex] = testCases;
                }
                // Find the right target index. It may not be this.state.targetIndex
                // if in the status view
                let targetIndex = -1
                for (let i = 0; this.state.targets; i++) {
                    let t = this.state.targets[i]
                    if (t.id === target.id && parseInt(t.created, 10) === parseInt(target.created, 10)) {
                        targetIndex = i
                        break
                    }
                }
                if (targetIndex > -1) {
                    let targets = this.state.targets;
                    let target = targets[targetIndex];
                    if (savedCases !== undefined) {
                        if (index < savedCases.length) {
                            target.cases = savedCases[index];
                        }
                        if (index + 1 < savedCases.length) {
                            target.prevCases = savedCases[index + 1];
                        }
                    }
                    targets[targetIndex] = target;
                    this.setState({targets: targets, cases: savedCases, builds: savedBuilds, casesLoading: false, preRender: true, runIndex: index, modalCase: modalCase}, () => {
                        this.syncPersistentCaseData()
                        this.syncPersistentSuiteData()
                    });
                }
            }
        });
    }

    getDiffCases (target, diffSide) {
        let runs = []
        let runIndex = 0
        if (diffSide === 1) {
            runs = this.state.diffRuns1
            runIndex = this.state.diffRunIndex1
        } else if (diffSide === 2) {
            runs = this.state.diffRuns2
            runIndex = this.state.diffRunIndex2
        }
        if (runs.length > 0) {
            Request.post("/casesForRuns", {runs: runs, runIndex: runIndex, numRuns: 1}, (err, data) => {
                if (err) {
                    this.setState({casesLoading: false});
                } else {
                    let casesForRuns = data.casesForRuns;
                    let cases = []
                    if (casesForRuns !== undefined) {
                        cases = casesForRuns[0].cases
                    }
                    if (diffSide === 1) {
                        this.setState({diffCases1: cases})
                    } else if (diffSide === 2) {
                        this.setState({diffCases2: cases})
                    }
                }
            });
        } else {
            this.setState({loading: false}, () => this.getDiffLatestRunAndCasesCopy(target, diffSide));
        }
    }

    getDiffLatestRunAndCasesCopy (target, diffSide) {
        Request.get("/latestRunAndCasesCopy", {id: target.id, created: target.created}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let run = data.run;
                if (run === undefined) {
                    this.setState({loading: false});
                } else {
                    if (diffSide === 1) {
                        this.setState({diffRuns1: [run], diffCases1: data.cases, loading: false});
                    } else if (diffSide === 2) {
                        this.setState({diffRuns2: [run], diffCases2: data.cases, loading: false});
                    }
                }
            }
        });
    }

    getProjectRoleFunctionality(project) {
        Request.get("/projectRole", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({role: 1, usage: undefined});
            } else {
                this.setState({role: data.role, usage: undefined}, () => this.getProjectUsage(project));
            }
        });
    }

    getProjectUsage(project) {
        this.setState({usage: undefined});
        if (project === undefined) {
            return;
        }
        if (this.state.role < 3) {
            return;
        }
        Request.get("/projectUsage", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({usage: undefined});
            } else {
                let usage = {};
                usage.targets = {};
                usage.targets.used = data.targets.used;
                usage.targets.limit = data.targets.limit;
                usage.users = {};
                usage.users.used = data.users.used;
                usage.users.limit = data.users.limit;
                usage.projectIcon = data.projectIcon;
                usage.targetIcons = {};
                usage.targetIcons.used = data.targetIcons.used;
                usage.targetIcons.limit = data.targetIcons.limit;
                usage.avatarIcon = data.avatarIcon;
                usage.files = data.fileUsedPercent;
                usage.data = data.dataUsedPercent;
                usage.dataReset = data.dataReset;
                this.setState({usage: usage});
            }
        });
    }

    members () {
        if (this.context.projects === undefined || Cache.getPreference(Cache.preference.projectIndex) === undefined) {
            return;
        }
        
        if (Cache.getPreference(Cache.preference.projectIndex) >= this.context.projects.length) {
            return;
        }

        let project = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)]
        
        Request.get("/members", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({members: [], loading: false});
            } else {
                this.setState({members: data.members});
            }
        });
    }

    targetUpdate (target) {
        let targets = this.state.targets
        let index = -1
        if (targets !== undefined) {
            if (Array.isArray(targets)) {
                for (let i = 0; i < targets.length; i++) {
                    if (targets[i].created === target.created) {
                        index = i
                    }
                }
            }
        }
        if (index > -1) {
            targets[index] = target
            this.setState({targets: targets})
        }
    }

    deleteRun (callback) {
        if (this.state.runs.length === 0) {
            return;
        }
        if (this.state.view === "status") {
            return;
        }
        let run = this.state.runs[this.state.runIndex];
        Request.post("/runDelete", {id: run.id, created: run.created}, (err, data) => {
            if (err) {
                callback("Unable to delete test run. This may be because this is the only test run for this target. The last remaining test run cannot be deleted.");
            } else {
                Analytics.event("DeletedRun");
                callback(undefined);
            }
        });
    }

    exportToCsv () {
        window.scrollTo(0,0);
        if (this.state.cases === undefined 
            || this.context.projects === undefined
            || this.state.targets === undefined
            || this.state.runs === undefined) {
            return;
        }

        let name = "";
        if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
            name += this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].name;
        }
        if (this.state.targetIndex < this.state.targets.length) {
            name += " " + this.state.targets[this.state.targetIndex].name;
        }
        if (this.state.runIndex < this.state.runs.length) {
            name += " " + TimeFormatted.timeformatted(this.state.runs[this.state.runIndex].created);
        }
        if (this.state.runIndex >= this.state.cases.length) {
            return;
        }

        Analytics.event("ExportResultsCsv");

        let cases = this.state.cases[this.state.runIndex].slice();
        cases.forEach(function (c) {
            if (c.steps !== undefined) {
                try {
                    c.steps = JSON.stringify(c.steps)
                } catch (err) {
                    // ignore
                }
            }
            if (c.reason === undefined) {
                c.reason = "-"
            }
            if (this.state.view !== undefined && this.state.trl !== true) {
                try {
                    c.tesults_link = Constants.baseUrl + "/results/rsp/view/" + this.state.view + "/case/" + this.state.runs[this.state.runIndex].id + "-" + this.state.runs[this.state.runIndex].created + "-" + c.num;
                } catch (err) {
                    // ignore
                }
            }
        }.bind(this))
        const csvString = papa.unparse(cases);
        const file = new Blob([csvString], {type: 'text/csv'});
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = name + ".csv";
        element.click();
    }

    sendNotifications (callback) {
        if (this.state.targets.length === 0) {
            return;
        }
        if (this.state.view === "status") {
            return;
        }
        let target = this.state.targets[this.state.targetIndex];
        Request.post("/sendNotifications", {id: target.id, created: target.created}, (err, data) => {
            if (err) {
                callback("Unable to send notification.");
            } else {
                Analytics.event("SendNotifications");
                callback(undefined);
            }
        });
    }

    newManualTestRun () {
        window.location = "/manual";
    }

    testLists () {
        window.location = "/lists";
    }

    persistentCaseDataUpdate (updatedCases, callback) {
        if (this.state.targets === undefined) {
            return;
        }
        if (this.state.targets.length === 0) {
            return;
        }
        if (this.state.targetIndex >= this.state.targets.length) {
            return;
        }
        let target = this.state.targets[this.state.targetIndex];
        Request.post("/updatePersistentCaseData", {id: target.id, created: target.created, updatedCases: updatedCases}, (err, data) => {
            if (err) {
                callback("Unable to update.");
            } else {
                Analytics.event("UpdatedPersistentCaseData");
                let persistentCaseDataByTarget = this.state.persistentCaseDataByTarget;
                if (persistentCaseDataByTarget === undefined) {
                    persistentCaseDataByTarget = {};
                }
                persistentCaseDataByTarget[target.id + "-" + target.created] = data.persistentCaseData;
                callback(undefined);
                this.setState({persistentCaseDataByTarget: persistentCaseDataByTarget}, () => this.syncPersistentCaseData());
            }
        });
    }

    persistentSuiteDataUpdate (updatedSuites, callback) {
        if (this.state.targets === undefined)  {
            return;
        }
        if (this.state.targets.length === 0) {
            return;
        }
        if (this.state.targetIndex >= this.state.targets.length) {
            return;
        }
        let target = this.state.targets[this.state.targetIndex];
        Request.post("/updatePersistentSuiteData", {id: target.id, created: target.created, updatedSuites: updatedSuites}, (err, data) => {
            if (err) {
                callback("Unable to update.");
            } else {
                Analytics.event("UpdatedPersistentSuiteData");
                let persistentSuiteDataByTarget = this.state.persistentSuiteDataByTarget;
                if (persistentSuiteDataByTarget === undefined) {
                    persistentSuiteDataByTarget = {};
                }
                persistentSuiteDataByTarget[target.id + "-" + target.created] = data.persistentSuiteData;
                callback(undefined);
                this.setState({persistentSuiteDataByTarget: persistentSuiteDataByTarget}, () => this.syncPersistentSuiteData());
            }
        });
    }

    getCaseEnhancedAnalysis () {
        const getCaseEABatch = (projectId, targetId, batch) => {
            Request.get("/case-enhanced-analysis-batch", {project: projectId, target: targetId, batch: batch}, (err, data) => {
                let caseEnhancedAnalysis = this.state.caseEnhancedAnalysis;
                if (err) {
                    caseEnhancedAnalysis[`caseBatch${batch}`] = undefined;
                } else {
                    caseEnhancedAnalysis[`caseBatch${batch}`] = data.caseBatch;
                }
                this.setState({caseEnhancedAnalysis: caseEnhancedAnalysis});
            });
        }

        if (this.state.projects && this.state.projects.length > 0 && this.state.targets && this.state.targets.length > 0) {
            const project = this.state.projects[this.state.projectIndex];
            let targetIndex = this.state.targetIndex;
            if (targetIndex >= this.state.targets.length) {
                this.setState({caseEnhancedAnalysis: undefined});
                return;
            }
            if (project.plan.name === "free-v1") {
                this.setState({caseEnhancedAnalysis: undefined});
                return;
            }
            const targetId = this.state.targets[targetIndex].created;
            Request.get("/case-enhanced-analysis-manifest", {project: project.id, target: targetId}, (err, data) => {
                if (err) {
                    this.setState({caseEnhancedAnalysis: undefined});
                } else {
                    if (data.caseManifest === undefined) {
                        this.setState({caseEnhancedAnalysis: undefined});
                    } else {
                        this.setState({caseEnhancedAnalysis: {
                            caseManifest: data.caseManifest
                        }});
                        for (let batch = 0; batch < data.caseManifest.batches; batch++) {
                            getCaseEABatch(project.id, targetId, batch);
                        }
                    }
                }
            });
        } else {
            this.setState({caseEnhancedAnalysis: undefined});
        }
    }

    // Not optimal -> duplicating ResultsSummary.js code
    backgroundColor () {
        if (this.state.runs.length === 0) {
            return "whitebg";
        } else {
            if (this.state.cases[this.state.runIndex] === undefined) {
                return "whitebg";
            } else if (this.state.cases[this.state.runIndex].length === 0 && this.state.builds[this.state.runIndex] === undefined) {
                return "whitebg";
            } else {
                let pass = 0;
                let total = 0;
                let testCases = this.state.cases[this.state.runIndex];
                let buildCase = this.state.builds[this.state.runIndex];

                testCases.forEach(function (c) {
                    if (c.result.toLowerCase() === "pass") {
                        pass += 1;
                    } else if (c.result.toLowerCase() === "fail") {
                        //fail += 1;
                    } else {
                        //unknown += 1;
                    }
                    total += 1;
                });

                let passPercent;
                if (buildCase !== undefined) {
                    if (buildCase.result.toLowerCase() !== "pass") {
                        passPercent = "Build Fail";
                    } else {
                        passPercent = "Build Pass";
                    }
                }  
                if (total !== 0) {
                    passPercent = Math.floor((pass/total) * 100) + " %"
                }                       

                let resultClass = "accenta4bg";
                if (pass === total && passPercent !== "Build Fail") {
                    resultClass = "accentc4bg";
                } else if (pass/total >= 0.5) {
                    resultClass = "accentb4bg";
                }

                return resultClass;
            }
        }
    }

    syncTargetNote (note) {
        if (this.state.targetIndex < this.state.targets.length) {
            let target = this.state.targets[this.state.targetIndex];
            target.note = note;
        }
    }

    titleUpdate () {
        let title = "";
        if (this.state.view === "results") {
            if (this.state.targetIndex < this.state.targets.length) {
                title = this.state.targets[this.state.targetIndex].name + " Results - Tesults";
            }
        }
        if (this.state.view === "supplemental") {
            if (this.state.targetIndex < this.state.targets.length) {
                title = this.state.targets[this.state.targetIndex].name + " Supplemental - Tesults";
            }
        }
        if (this.state.view === "status") {
            if (this.context.projects !== undefined) {
                if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
                    title = title + this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].name + " Status - Tesults";
                }
            }
        }
        if (this.state.view === "diff") {
            if (this.context.projects !== undefined) {
                if (Cache.getPreference(Cache.preference.projectIndex) < this.context.projects.length) {
                    title = title + this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].name + " Diff - Tesults";
                }
            }
        }
        if (title === "") {
            title = "Tesults - Results";
        }
        return title;
    }

    getTrlData () {
        Request.get("/trlData", {org: this.props.trlOrg}, (err, data) => {
            if (err) {
                this.setState({loading: false});
            } else {
                let trlFound = false;
                let projectIndex = 0;
                let targets = [];
                let targetIndex = 0;
                let project = undefined;
                if (this.props.trlProject === undefined) {
                    trlFound = true;
                    if (data.projects.length > 0) {
                        project = data.projects[projectIndex];
                        for (let i = 0; i < data.targets.length; i++) {
                            let target = data.targets[i];
                            if (project.id === target.id) {
                                targets.push(target);
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < data.projects.length; i++) {
                        let project = data.projects[i];
                        if (project.name.toLowerCase() === this.props.trlProject) {
                            projectIndex = i;
                        }
                    }
                    project = data.projects[projectIndex];
                    for (let i = 0; i < data.targets.length; i++) {
                        let target = data.targets[i];
                        if (project.id === target.id) {
                            targets.push(target);
                        }
                    }
                    if (this.props.trlTarget === undefined) {
                        trlFound = true;   
                    } else {
                        for (let i = 0; i < targets.length; i++) {
                            let target = targets[i];
                            if (target.name.toLowerCase() === this.props.trlTarget.toLowerCase()) {
                                targetIndex = i;
                                trlFound = true;
                            }
                        }
                    }
                }
                if (data.org === undefined) {
                    trlFound = false;
                }
                this.context.update(Constants.strings.projects, data.projects)
                if (targets.length > 0) {
                    this.context.update(Constants.strings.targets, data.targets)
                    this.setState({loading: false, trlLoading: false, trlFound: trlFound, org: data.org, projects: data.projects, projectIndex: projectIndex, targets: targets, targetIndex: targetIndex}, () => this.getRuns(targets[targetIndex], true));
                } else {
                    this.context.update(Constants.strings.targets, [])
                    this.setState({loading: false, trlLoading: false, trlFound: trlFound, org: data.org, projects: data.projects, projectIndex: projectIndex, targets: targets, targetIndex: targetIndex});
                }
                this.getPersistentCaseData(targets);
                this.getPersistentSuiteData(targets)
                this.members();
                if (project !== undefined) {
                    this.getRawResultMaps(project);
                    this.getPreferencesReporting(project);
                }
            }
        });
    }

    multiEdit (enable) {
        let multiselect = this.state.multiselect;
        if (enable === undefined) { // act as a toggle
            if (this.state.multiEdit === true) {
                enable = false;
                multiselect = {};
            } else {
                enable = true;
            }
        }

        if (enable === true) {
            Analytics.event('Show Cost');
        } else {
            multiselect = {};
            Analytics.event('Hide Cost');
        }
        this.setState({multiEdit: enable, multiselect: multiselect});
    }

    multiselectUpdate (multiselect) {
        this.setState({multiselect: multiselect});
    }

    multiEditConfirm (field, value) {
        if (field === undefined || value === undefined) {
            return;
        }
        if (this.state.runIndex >= this.state.cases.length) {
            return;
        }
        Analytics.event("ResultsMultiEditConfirm" + field.charAt(0).toUpperCase() + field.substring(1));
        let multiselect = this.state.multiselect;
        if (multiselect === undefined) {
            return;
        }

        let updated = false;
        let cases = this.state.cases[this.state.runIndex];
        if (field === "delete") {
            let keep = [];
            for (let i = 0; i < cases.length; i++) {
                let c = cases[i];
                if (multiselect[c.num] !== true) {
                    keep.push(c);
                }
            }
            cases = keep;
            updated = true;
        } else {
            for (let i = 0; i < cases.length; i++) {
                let c = cases[i];
                if (multiselect[c.num] === true) {
                    c[field] = value;
                    updated = true;
                }
            }
        }
        
        if (updated === true) {
            this.setState({confirmation: {success: "Saving...", failure: undefined}});
            this.persistentCaseDataUpdate(cases, function (err) {
                if (err) {
                    this.setState({confirmation: {success: undefined, failure: "Unable to save"}});
                } else {
                    this.setState({confirmation: {success: undefined, failure: undefined}, multiEdit:false});
                    this.props.side(undefined);
                }
            }.bind(this));
        }
    }

    noProjects () {
        let main = <span></span>
        let header = <span></span>
        if (this.state.trl === true) {
            header = <Header
                        type="header-single"
                        header1={<ResultsHeaderGeneric title="Not found"/>}
                    />
            main = <div className="app-content-margin">
                        <p></p>
                    </div>
        } else {
            header = <Header
                        type="header-single"
                        header1={<ResultsHeaderGeneric title="Welcome"/>}
                    />
            main =
            <div className="app-content-margin" style={{"minHeight":"100vh"}}>
                <div className='text-center' style={{"width":"600px", "margin":"auto"}}>
                    <img src="/img/no-projects-icon.svg" width="150px" height="150px"/>
                    <h2 className="mb-5">Looks like you don't have any projects</h2>
                    <div className='mb-5'>You need a project to do anything in Tesults. Create one now.</div>
                    <button type="button" onClick={() => {this.props.history.push({ pathname: "/config/create-project"})}} className="mb-5 btn-confirm-index">+ New project</button>
                    <div className='neutral4'>Waiting to be invited to an existing project? No need to create one.</div> 
                </div>
            </div>
        }
        return (
            <div className="whitebg">
                {header}
                {main}
            </div>
        );
    }

    groupChange (group) {
        Cache.setPreference(Cache.preference.targetGroup, group)
        this.setState({group: group})
    }

    getPreferencesReporting (project) {
        if (project === undefined) { return }
        Request.get("/preferences-reporting-case-display-attribute-maps", {id: project.id}, (err, data) => {
            if (err) {
                this.setState({preferencesReportingCaseDisplayAttributeMaps: []});
            } else {
                this.setState({preferencesReportingCaseDisplayAttributeMaps: data.maps});
            }
        });
    }

    revUpdate () {
        let rev = this.state.rev
        this.setState({rev: rev + 1})
    }

    render () {
        const targetDetail = <TargetDetail
            view={this.state.view}
            casesLoading={this.state.casesLoading}
            targets={this.state.targets} 
            targetIndex={this.state.targetIndex} 
            syncTargetNote={this.syncTargetNote} 
            members={this.state.members}
            multiEdit={this.multiEdit}
            cases={this.state.cases}
            overlay={this.props.overlay} 
            messageOverlay={this.props.messageOverlay} 
            multiEditConfirm={this.multiEditConfirm} 
            confirmation={this.state.confirm}
            role={this.state.role}
            targetUpdate={this.targetUpdate}
            exportToCsv={this.exportToCsv}
            trl={this.state.trl}
            resultsSideMod={
                <ResultsSideMod 
                    view={this.state.view} 
                    role={this.state.role} 
                    deleteRun={this.deleteRun} 
                    newManualTestRun={this.newManualTestRun} 
                    testLists={this.testLists} 
                    sendNotifications={this.sendNotifications}/>
            }
            share={
                <Share
                    org={this.state.org}
                    projects={this.context.projects} 
                    projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                    targets={this.state.targets} 
                    targetIndex={this.state.targetIndex} 
                    runs={this.state.runs} 
                    runIndex={this.state.runIndex} 
                    oldestRun={this.state.oldestRun}
                    view={this.state.view}
                    members={this.state.members}
                />
            }
            runArchive={this.state.runArchive}
            runArchiveBuildCase={this.state.runArchiveBuildCase}
            runArchiveCases={this.state.runArchiveCases}
            isRunArchive={this.isRunArchive()}
            rev={this.state.rev}
        />

        if (this.state.loading === true) {
            return (
                <div className="app-content-margin text-center">
                    <Loading value={this.state.loadingValue}/>
                </div>
            );
        } else if (this.state.trl === true && this.state.trlFound === false) {
            if (this.state.trlLoading === true) {
                return <Loading/>
            } else {
                return <Redirect to="/notfound"/>
            }
        } else {
            let main = <span></span>
            //let cardbg = this.state.darkMode === true ? "darkbg" : "neutral8bg";
            let title = this.titleUpdate();
            
            let onboarding = <Onboarding 
                                role={this.state.role} 
                                projects={this.context.projects} 
                                projectIndex={Cache.getPreference(Cache.preference.projectIndex)}/>

            if (this.state.view === "status") {
                main = <StatusView 
                            state={this.state.state}
                            overlay={this.props.overlay} 
                            side={this.props.side}
                            loading={this.state.loading} 
                            status={this.state.targets} 
                            statusTargetSelect={this.statusTargetSelect} 
                            rspNotFound={this.state.rspNotFound}  
                            role={this.state.role}
                            projects={this.context.projects}
                            projectIndex={Cache.getPreference(Cache.preference.projectIndex)}
                            targets={this.state.targets}
                            loadUpdatedResults={this.loadUpdatedResults}
                            darkMode={this.state.darkMode}
                            persistentCaseDataIteration={this.state.persistentCaseDataIteration}
                            persistentSuiteDataIteration={this.state.persistentSuiteDataIteration}
                            onboarding={onboarding}
                            history={this.props.history}
                            members={this.state.members}
                            groupChange={this.groupChange}
                            rawResultMaps={this.state.rawResultMaps}
                            trl={this.state.trl}
                            />
            } else {
                if (this.state.view === "supplemental") {
                    /*context={this.props.context}*/
                    if (this.isRunArchive() === true) {
                        main = <div className='font14 neutral4'>Smart analysis not supported for archived test runs</div>
                    } else {
                        main = <SupplementalView 
                        context="results"
                        state={this.state.state} 
                        overlay={this.props.overlay}
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        projects={this.context.projects} 
                        projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                        onProjectChange={this.projectChange} 
                        targets={this.state.targets} 
                        targetIndex={this.state.targetIndex} 
                        onTargetChange={this.targetChange} 
                        runs={this.state.runs} 
                        runIndex={this.state.runIndex} 
                        oldestRun={this.state.oldestRun}
                        onRunChange={this.runChange} 
                        cases={this.state.cases} 
                        builds={this.state.builds} 
                        sort={this.state.sort} 
                        filter={this.state.filter}
                        search={this.state.search}
                        loading={this.state.loading} 
                        demo={this.state.demo} 
                        view={this.state.view} 
                        rspNotFound={this.state.rspNotFound} 
                        role={this.state.role} 
                        modalCase={this.state.modalCase} 
                        persistentCaseDataUpdate={this.persistentCaseDataUpdate}
                        persistentCaseDataIteration={this.state.persistentCaseDataIteration}
                        darkMode={this.state.darkMode}
                        trl={this.state.trl}
                        rsp={this.state.rsp}
                        onboarding={onboarding}
                        rspCancel={this.rspCancel}
                        members={this.state.members}
                        targetUpdate={this.targetUpdate}
                        rawResultMaps={this.state.rawResultMaps}
                        caseEnhancedAnalysis={this.state.caseEnhancedAnalysis}
                        targetDetail={targetDetail}
                    />
                    }
                } else if (this.state.view === "diff") {
                    main = <Diff 
                                overlay={this.props.overlay} 
                                sideOverlay={this.props.sideOverlay}
                                messageOverlay={this.props.messageOverlay} 
                                role={this.props.role} 
                                projects={this.context.projects} 
                                projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                                targets={this.state.targets} 
                                targetIndex1={this.state.diffTargetIndex1}
                                targetIndex2={this.state.diffTargetIndex2}
                                targetIndex1Change={this.diffTargetIndex1Change} 
                                targetIndex2Change={this.diffTargetIndex2Change}
                                runs1={this.state.diffRuns1}
                                runs2={this.state.diffRuns2}
                                runs1Esk={this.state.diffRuns1Esk}
                                runs2Esk={this.state.diffRuns2Esk}
                                runIndex1={this.state.diffRunIndex1}
                                runIndex2={this.state.diffRunIndex2}
                                runIndex1Change={this.diffRunIndex1Change}
                                runIndex2Change={this.diffRunIndex2Change}
                                cases1={this.state.diffCases1}
                                cases2={this.state.diffCases2}
                                rspNotFound={this.state.diffRspNotFound}
                                side={this.props.side}
                                members={this.state.members}
                                rawResultMaps={this.state.rawResultMaps}
                            />
                } else {
                    main = <ResultsView 
                            state={this.state.state} 
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay}
                            side={this.props.side}
                            sideOverlay={this.props.sideOverlay}
                            org={this.state.org}
                            projects={this.context.projects} 
                            projectIndex={Cache.getPreference(Cache.preference.projectIndex)} 
                            onProjectChange={this.projectChange} 
                            targets={this.state.targets} 
                            targetIndex={this.state.targetIndex} 
                            onTargetChange={this.targetChange} 
                            runs={this.state.runs} 
                            runIndex={this.state.runIndex} 
                            oldestRun={this.state.oldestRun}
                            onRunChange={this.runChange} 
                            cases={this.state.cases} 
                            builds={this.state.builds}
                            sort={this.state.sort} 
                            filter={this.state.filter}
                            search={this.state.search}
                            loading={this.state.loading} 
                            demo={this.state.demo} 
                            view={this.state.view} 
                            rspNotFound={this.state.rspNotFound} 
                            role={this.state.role} 
                            modalCase={this.state.modalCase} 
                            persistentCaseDataUpdate={this.persistentCaseDataUpdate}
                            persistentCaseDataIteration={this.state.persistentCaseDataIteration}
                            persistentSuiteDataUpdate={this.persistentSuiteDataUpdate}
                            persistentSuiteDataIteration={this.state.persistentSuiteDataIteration}
                            persistentSuiteData={this.state.persistentSuiteData}
                            darkMode={this.state.darkMode}
                            trl={this.state.trl}
                            rsp={this.state.rsp}
                            trlCase={this.props.trlCase}
                            multiEdit={this.state.multiEdit}
                            multiselectUpdate={this.multiselectUpdate}
                            multiselectEnabled={this.state.multiEdit}
                            preRender={this.state.preRender}
                            onboarding={onboarding}
                            rspCancel={this.rspCancel}
                            members={this.state.members}
                            targetUpdate={this.targetUpdate}
                            collapseAll={this.state.collapseAll}
                            rawResultMaps={this.state.rawResultMaps}
                            preferencesReportingCaseDisplayAttributeMaps={this.state.preferencesReportingCaseDisplayAttributeMaps}
                            runArchive={this.state.runArchive}
                            runArchiveBuildCase={this.state.runArchiveBuildCase}
                            runArchiveCases={this.state.runArchiveCases}
                            isRunArchive={this.isRunArchive()}
                            revUpdate={this.revUpdate}
                            caseEnhancedAnalysis={this.state.caseEnhancedAnalysis}
                            targetDetail={targetDetail}
                            />
                }
            }

            if (this.context.projects !== undefined) {
                if (Cache.getPreference(Cache.preference.projectIndex) >= this.context.projects.length) {
                    Cache.setPreference(Cache.preference.projectIndex, 0)
                }
            }
            
            // Main view special cases
            if (this.state.state !== "results" || this.state.state === "unauthenticated") {
                return <Unauthenticated id={this.state.rsp.projectId} history={this.props.history}/>
            } else {
                if (this.context.projects === undefined) {
                    return this.noProjects()
                }
                if (this.context.projects.length === 0) {
                    return this.noProjects()
                } else if (this.state.rspNotFound !== undefined) {
                    main = 
                        <div className="app-content-margin">
                            <h4 className="mb-3 mt-3">Check results url is correct</h4>
                            <p>{this.state.rspNotFound}</p>
                        </div>
                } else if (this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].status !== "active") {
                    let status = this.context.projects[Cache.getPreference(Cache.preference.projectIndex)].status;
                    main = 
                        <div className="app-content-margin">
                            <p className="accenta1">This project is {status}.</p>       
                        </div>
                } else if (this.state.targets.length === 0) {
                    main = 
                        <div className="app-content-margin">
                            <p className="neutral4 mt-5 mb-5">There are <b>no targets</b> for this project. Create at least one target from <NavLink to="/config" target="_blank" className="site-link-primary2">configuration</NavLink>.</p>
                        </div>
                } else if (this.state.runs.length === 0 && this.isRunArchive() === false && this.state.view !== "status" && this.state.view !== "diff") {
                    main = 
                    <div className="app-content-margin">
                        <p className="mt-5 neutral4">There are <b>no results</b> for this target yet. If setup is complete then results will appear here. <NavLink to="/docs" target="_blank" className="nounderline primary3 no-break">Learn how to get setup</NavLink>.</p>
                    </div>
                } else {
                    if (this.isRunArchive() === false && this.state.view !== "status" && this.state.view !== "diff") {
                        if (this.state.cases[this.state.runIndex] === undefined) {
                            main = <div></div>
                        } else if (this.state.cases[this.state.runIndex].length === 0 && this.state.builds[this.state.runIndex] === undefined && this.state.view !== "status") {
                            if (this.state.runs[this.state.runIndex].cases === undefined) {
                                main =
                                <div className="app-content-margin text-center">
                                    <h5>No test cases for this run</h5>
                                </div>
                            } else {
                                if (this.state.runs[this.state.runIndex].cases === 0) {
                                    main =
                                    <div className="app-content-margin text-center">
                                        <h5>No test cases for this run.</h5> 
                                    </div>
                                }
                            }
                        }
                    }
                }
            }

            if (this.state.casesLoading === true) {
                main = <Loading/>
            }

            if (this.state.preRender === true) {
                setTimeout(() => {this.setState({preRender: false})}, 100);
            }

            let target = undefined;
            let cases = [];
            let prevCases = [];
            if (this.state.targetIndex < this.state.targets.length) {
                target = this.state.targets[this.state.targetIndex];
                cases = target.cases;
                prevCases = target.prevCases;
            }
            if (this.isRunArchive() === true) {
                cases = this.state.runArchiveCases
                prevCases = undefined
            }

            // Group options - start

            let groups = [];
            for (let i = 0; i < this.state.targets.length; i++) {
                let target = this.state.targets[i];
                if (target.group !== undefined) {
                    for (let i = 0; i < target.group.length; i++) {
                        groups.push(target.group[i]);
                    }
                }
            }

            let selectedGroup = this.state.group;
            if (groups.includes(selectedGroup) !== true) {
                selectedGroup = "All"; // Resets to All if the cached selected group does not exist for this project
            }
            
            groups = [...new Set(groups)] // removes duplicates
            groups.sort();
            groups.unshift('All');

            // Groups options - end

            let header = 
            <Header 
                type="header-double" 
                header1={
                    <ResultsHeader1 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        role={this.state.role}
                        view={this.state.view}
                        indexChange={this.targetChange} 
                        options={this.state.targets} 
                        index={this.state.targetIndex}
                        resultsView={this.resultsView}
                        supplementalView={this.supplementalView}
                        cases={cases} 
                        prevCases={prevCases}
                        targets={this.state.targets}
                        targetIndex={this.state.targetIndex}
                        rawResultMaps={this.state.rawResultMaps}
                        group={selectedGroup}
                        groups={groups}
                        groupChange={this.groupChange}
                        runArchive={this.state.runArchive}
                        runArchiveBuildCase={this.state.runArchiveBuildCase}
                        runArchiveCases={this.state.runArchiveCases}
                        isRunArchive={this.isRunArchive()}
                        runs={this.state.runs} 
                        runIndex={this.state.runIndex}
                    />
                }
                header2={
                    <ResultsHeader2 
                        overlay={this.props.overlay} 
                        messageOverlay={this.props.messageOverlay}
                        side={this.props.side}
                        sideOverlay={this.props.sideOverlay}
                        role={this.state.role}
                        //costContent={<MultiCaseEditOptions context="results" title="Failure cost" iconSrc="/img/credit-card.svg" subtitle="Financial cost of test failure in production. This will apply to checked test cases." cases={this.state.cases} overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} multiEditConfirm={this.multiEditConfirm} confirmation={this.state.confirm}/>}
                        targets={this.state.targets} 
                        targetIndex={this.state.targetIndex}
                        cases={cases} 
                        prevCases={prevCases} 
                        runs={this.state.runs} 
                        runIndex={this.state.runIndex}
                        runIndexChange={this.runChange} 
                        sort={this.state.sort}
                        sortChange={this.sortChange} 
                        filter={this.state.filter}
                        filterChange={this.filterChange}
                        multiEdit={this.multiEdit}
                        view={this.state.view}
                        moreRuns={this.state.moreRuns}
                        getMoreRuns={this.getMoreRuns}
                        members={this.state.members}
                        collapseAll={this.state.collapseAll}
                        toggleCollapseAll={this.toggleCollapseAll}
                        onSearchChange={this.searchChange}
                        runArchive={this.state.runArchive}
                        runArchiveBuildCase={this.state.runArchiveBuildCase}
                        runArchiveCases={this.state.runArchiveCases}
                        isRunArchive={this.isRunArchive()}
                        rawResultMaps={this.state.rawResultMaps}
                    />
                } 
            />
            
            if (this.state.view === "status") {
                header =
                <Header
                    type="header-single"
                    header1={<ResultsHeader3 diffView={this.diffView} targets={this.state.targets} members={this.state.members} group={this.state.group}/>}
                />
            }

            if (this.state.view === "diff") {
                header = 
                <Header
                    type="header-triple"
                    header1={<ResultsHeader8 overlay={this.props.overlay} messageOverlay={this.props.messageOverlay} role={this.state.role} targets={this.state.targets}/>}
                    header2={<ResultsHeader9 
                                overlay={this.props.overlay}
                                messageOverlay={this.props.messageOverlay} 
                                role={this.state.role} 
                                targets={this.state.targets} 
                                targetIndex1={this.state.diffTargetIndex1}
                                targetIndex2={this.state.diffTargetIndex2}
                                targetIndex1Change={this.diffTargetIndex1Change} 
                                targetIndex2Change={this.diffTargetIndex2Change}
                            />}
                    header3={<ResultsHeader10
                            overlay={this.props.overlay}
                            messageOverlay={this.props.messageOverlay} 
                            role={this.state.role} 
                            runs1={this.state.diffRuns1}
                            runs2={this.state.diffRuns2}
                            runs1Esk={this.state.diffRuns1Esk}
                            runs2Esk={this.state.diffRuns2Esk}
                            runIndex1={this.state.diffRunIndex1}
                            runIndex2={this.state.diffRunIndex2}
                            runIndex1Change={this.diffRunIndex1Change}
                            runIndex2Change={this.diffRunIndex2Change}
                            getDiffRuns={this.getDiffRuns}
                        />}
                />
            }

            // RENDER RETURN
            if (this.props.sideOpen) {
                this.props.side(targetDetail);
            }
            
            return (
                <div style={{"overflow-x":"hidden"}}>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content="Tesults test reporting. View test results, analysis, files, data, bugs, financial cost of failures and track and assign failures to team members. Ship high quality builds fast and reduce risk and cost."/>
                    </Helmet>
                    {header}
                    <div className="app-main-margin">
                        {main}
                    </div>
                </div>
            )
        }
    }
}

export default ResultsWhole;