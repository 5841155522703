/*global */
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SetupAssistant from './SetupAssistant';
import Analytics from './Analytics';
import Notice from './Notice';

class DocsGettingStarted extends Component {
    constructor () {
        super();
        this.state = {state: "automation"};
        this.automation = this.automation.bind(this);
        this.manual = this.manual.bind(this);
        this.signup = this.signup.bind(this);
    }

    signup () {
        Analytics.event("GettingStartedSignUp");
    }

    automation () {
        Analytics.event("GettingStartedAutomation");
        this.setState({state: "automation"});
    }

    manual () {
        Analytics.event("GettingStartedManual");
        this.setState({state: "manual"});
    }

    render() {
        let title = "Tesults - Documentation"
        let metaDescription = "Learn how Tesults can help your team with test reporting and test case management."
        if (this.props.doc === "getting-started") {
            title = "Tesults - Getting Started"
            metaDescription = "Getting started. Use Tesults for test reporting and test case management."
        }

        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={metaDescription}/>
                </Helmet>
                <p className="mb-5">Welcome <span role="img" aria-label="welcome">&#128075;</span>. Learn how to get set up.</p>
                <h2 className="mt-3 mb-3"><span className="neutral4 mr-3">1.</span> Create a project</h2>
                <p className="mt-4 mb-5"><NavLink to="/register" target="_blank" className="tr-link-primary4 no-break">Sign up</NavLink> to create a project. A project is a workspace for your <NavLink to="/docs/target" target="_blank" className="tr-link-primary4 no-break">targets</NavLink> (test jobs) and stores all of your test results data.</p>
                <p><NavLink to="/docs/structure" target="_blank" className="tr-link-primary4 no-break">Learn more</NavLink> about how to <NavLink to="/docs/structure" target="_blank" className="tr-link-primary4 no-break">structure</NavLink> your project.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/QWG8L95pcOo?si=O3BIn02gXQqiHHK-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <h2><span className="neutral4 mr-3">2.</span> Push data to your project from your automated tests</h2>
                <p className="mt-4">Add a small amount of code to your automated test framework. Libraries are available for popular languages and test frameworks:</p>
                <div style={{"display":"flex"}}>
                    <div style={{"width": "170px"}}>
                        <ul>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/java">Java</Link></li>   
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/csharp">C#</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/nodejs">Node.js</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/python">Python</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/ruby">Ruby</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/swift">Swift</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/kotlin">Kotlin</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/go">Go (Golang)</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/php">PHP</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/others">Others</Link></li>
                        </ul>
                    </div>
                    <div style={{"width": "170px"}}>
                        <ul>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/exp">EXP</Link></li>   
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/junit5">JUnit 5 </Link></li>   
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/junit4">JUnit 4</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/testng">TestNG</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/nunit3">NUnit 3</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/vsunittf">MSTest</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/pytest">pytest</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/robot">Robot Framework</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/rspec">RSpec</Link></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/mocha">Mocha</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/jasmine">Jasmine</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/jest">Jest</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/playwright">Playwright</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/wdio">WebdriverIO</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/codeceptjs">CodeceptJS</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/nightwatch">Nightwatch</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/waffle">Waffle</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/protractor">Protractor</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/cypress">Cypress</Link></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/testcafe">TestCafe</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/xctest">XCTest</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/espresso">Espresso</Link></li>
                            <li><Link className="tr-link-primary4 no-break" target="_blank" to="/docs/postman">Postman</Link></li>
                        </ul>
                    </div>
                </div>
                <h2 className="mb-3">That's it &nbsp;&nbsp;<span role="img" aria-label="party-popper">&#127881;</span></h2>
                <p>Basic setup is complete. Complete advanced setup from the configuration menu, including:</p>
                <ul>
                    <li>Add team members so your team can collaborate</li>
                    <li>Add new targets so that you can consolidate results across a project</li>
                    <li>Use the <Link className="tr-link-primary4 no-break" target="_blank" to="/docs/api/overview">Tesults API</Link> in your CI/CD system to make pipeline gating and deployment decisions</li>
                    <li>Utilize task assignment for failing test cases</li>
                    <li>Associate bugs to a test case so you can keep track of fixes required before a test can pass</li>
                    <li>Look out for the flaky test indicator to identify tests that may have an issue or indicate a subtle bug with the app being tested</li>
                    <li>See additional guides for advanced configuration</li>
                </ul>
                <iframe className='youtube' src="https://www.youtube.com/embed/zaSI3vopRwU?si=IjXQbBiYqAaKSwN0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <hr/>
                <br/>
                <h2>Manual tests</h2>
                <p>Most teams complement automated and programmatic tests with manual tests. For handling these test cases utilize test lists and runs.</p>
                <p>Create a <NavLink to="/lists" target="_blank" className="tr-link-primary4 no-break">test list</NavLink>.</p>
                <p>Test lists function as a source of truth for all of the test cases in your project or organization. Create or import test cases and store them permanently for reference and reuse across releases. </p>
                <p>Create a <NavLink to="/manual" target="_blank" className="tr-link-primary4 no-break">test run</NavLink>.</p>
                <p>Test runs are single use list of test cases that your team wants to run for a release. You can import test cases from your test lists, assign test cases to team members and track progress through to completion and then submit results.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/5xgtxZyResQ?si=e7qBOxgXvkZK5hLe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <h2>Release checklists</h2>
                <p>Consolidate automated and manual tests, along with any other tasks for release using a release checklist.</p>
                <p>Create a <NavLink to="/release-checklists" target="_blank" className="tr-link-primary4 no-break">release checklist</NavLink>.</p>
                <p>Release checklists help keep track of all the tests and tasks that need to be completed for a release.</p>
                <iframe className='youtube' src="https://www.youtube.com/embed/WtUEFweLbv8?si=wuBTQzr9dKu0jq0z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <br/>
                <br/>
                <Notice type="general" content={<div>Announcing <a href="/exp" className="tr-link-primary1 no-break" target="_blank">EXP</a>. A new open-source test framework that has built in integration for Tesults. <a href="/exp" className="tr-link-primary1 no-break" target="_blank">Learn more about EXP</a></div>}/>
                <br/>
                <br/>
                <SetupAssistant/>
            </div>  
        );
    }
}

export default DocsGettingStarted;