/*global */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class DocsStructure extends Component {
    render() {
        return (
            <div style={{"minHeight":"100vh"}}>
                <Helmet>
                    <title>Tesults - How to structure your project</title>
                    <meta name="description" content="Learn how to structure your Tesults project."/>
                </Helmet>
                <p>This is the recommended way to structure a Tesults project for most teams. Most small and medium sized teams (10 - 250 team members) should follow this pattern. Larger teams can usually start out this way and then restructure as needed. Reach out to help@tesults for specific advice.</p>
                <h2 className='pt-4 pb-4'>Create one project</h2>
                <div className='mb-3 pt-4 pb-4'>
                    <img src="/img/factory.svg" width="150px" height="150px" alt="project"/>
                </div>
                <p>Create only one project and name it after your company or organization. You can rename an existing project if you have already named it something else.</p>
                <p>The majority of teams and organizations can and should function with just one project, consider it your organization's workspace within Tesults.</p>
                <p>This is optimal for a number of reasons including cost of administrative maintenance, cost effectiveness in pricing, single sign-on advantages, visibility and collaboration. If necessary later, restructuring with additional projects can take place later but in most cases this is not required.</p>
                <h2 className='pt-4 pb-4'>Create a lot of targets</h2>
                <div className='mb-3 pt-4 pb-4'>
                    <img src="/img/target-structure.svg" width="40px" height="40px" alt="target"/>
                    <img src="/img/target-structure.svg" width="40px" height="40px" alt="target"/>
                    <img src="/img/target-structure.svg" width="40px" height="40px" alt="target"/>
                </div>
                <p>Create a generous number of targets. Having dozens or over one hundred targets is perfectly reasonable and acceptable.</p>
                <p>Most engineering teams have a range of environments, platforms, services and clients to test, it is expected that you will have a large number of test jobs and targets that map to these requirements.</p>
                <p><NavLink to="/docs/target" target="_blank" className="tr-link-primary4 no-break">Learn about mapping test jobs to a target</NavLink>. Create a convention that makes sense for your team. For example you may name targets with a component based approach {'{product/service name}'} - {'{environment name}'}, for example 'My web app - dev' and 'My web app - staging'. Follow the link for more examples about modelling test jobs and targets.</p>
                <h2 className='pt-4 pb-4'>Use groups to organize targets</h2>
                <div className='mb-3'>
                    <img src="/img/factory.svg" width="80px" height="80px" alt="project"/>
                    <div className='mb-3'>
                        <div style={{"display":"flex"}}>
                            <div className='primary8bg p-5 mr-3 text-center'>
                                <div className='primary4 font14 mb-3 bold'>
                                    Product/Service 1
                                </div>
                                <div>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                </div>
                            </div>
                            <div className='accentb8bg p-5 mr-3 text-center'>
                                <div className='accentb4 font14 mb-3 bold'>
                                    Product/Service 2
                                </div>
                                <div>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                </div>
                            </div>
                            <div className='accentc8bg p-5 text-center'>
                                <div className='accentc4 font14 mb-3 bold'>
                                    Product/Service 3
                                </div>
                                <div>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                    <img src="/img/target-structure.svg" width="22px" height="22px" alt="target"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <p>With one project and a large number of targets, some order will be desirable to make information digestible. Use groups to bundle target together to map to product lines or specific clients or services. This will bring order and structure within a single project with a large number of targets.</p>
                <p>Currently groups are created via the Dashboard. You can filter the dashboard to show targets for groups you are interested in.</p>
                <p>Groups are going to become an increasingly important organizational structure within Tesults. Group creation interfaces will be improving, groups will appear in dropdown lists throughout the application, you will be able to assign visibility to team members to specific groups and much more. Start utilizing groups now for organizing targets for products within your team and company so that you are best positioned to leverage powerful group related features as they become available.</p>
                <div className='mb-5 pt-5 pb-5'>
                    &nbsp;
                </div>
            </div>
        );
    }
}

export default DocsStructure