import React, { Component, useState, useEffect } from 'react';

export default function Notification (props) {
    if (!props.text) {
        return null;
    }

    return (
        <div className='shadow standardborder' style={{"backgroundColor": "#23324F", "padding" : "12px 18px 12px 12px", "position": "fixed", "left": props.menu ? "280px" : "100px", "bottom": "50px"}}>
            <span className='white flex-row'>
                <div className='mr-3'>
                {props.icon ? props.icon : <img style={{"marginTop": "2px"}} src="/img/testCase-pass.svg" with="20" height="20"/>}
                </div>
                <div>
                {props.text}
                </div>
            </span>
        </div>
    )
}