import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ListSelect extends Component {
    render () {
        return (
            <Link to={"/lists/lsp/" + this.props.list.id + "/" + this.props.list.created} className="nounderline">
                <div className="case caseDiv mb-2" style={{"marginLeft": this.props.indent + "px"}}>
                    <div className="pl-4 pr-3" style={{"display":"flex", "alignItems":"center"}}>
                        {/*<div className="dot accentc3bg mr-3"></div> */}
                        <div class="site-link">{this.props.list.label}</div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default ListSelect;