/*global */
import React, { Component } from 'react';
import Box from './Box';

class DocsFilesStructure extends Component {
    render () {
        let folderOpenImg =  <img src="/img/folder-open-128.png" alt="expanded" width="24" height="24"/>
        let testframeworkInfo = <span></span>
        if (this.props.testframework === true) {
            testframeworkInfo = 
            <Box
                bg="primary8bg primary1 mb-3"
                title={""}
                content={<div>During a test run, save test generated files such as logs and screenshots to a local temporary directory. At the end of the test run all files will <b>automatically</b> be saved to Tesults as long as you save files in the directory structure below. Omit test suite folders if not using test suites.</div>}
            />
        }
        return (
            <div>
                {testframeworkInfo}
                <ul>
                    <li>{folderOpenImg} temporary folder</li>
                    <ul>
                        <li>{folderOpenImg} Test Suite A</li>
                        <ul>
                            <li>{folderOpenImg} Test 1</li>
                            <ul>
                                <li>test.log</li>
                                <li>screenshot.png</li>
                            </ul>
                            <li>{folderOpenImg} Test 2</li>
                            <ul>
                                <li>test.log</li>
                                <li>screenshot.png</li>
                            </ul>
                        </ul>
                        <li>{folderOpenImg} Test Suite B</li>
                        <ul>
                            <li>{folderOpenImg} Test 3</li>
                            <ul>
                                <li>metrics.csv</li>
                                <li>error.log</li>
                            </ul>
                            <li>{folderOpenImg} Test 4</li>
                            <ul>
                                <li>test.log</li>
                            </ul>
                        </ul>
                    </ul>
                </ul>
                {/*
                <p>If not using test suites then omit the suite directories.</p>
                <ul>
                    <li>{folderOpenImg} temporary folder</li>
                    <ul>
                        <li>{folderOpenImg} Test 1</li>
                        <ul>
                            <li>test.log</li>
                            <li>screenshot.png</li>
                        </ul>
                        <li>{folderOpenImg} Test 2</li>
                        <ul>
                            <li>test.log</li>
                            <li>screenshot.png</li>
                        </ul>
                    </ul>
                </ul>
                */}
            </div>
        );   
    }
}

export default DocsFilesStructure;