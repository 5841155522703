import React, { Component } from 'react';
import KeyValue from './KeyValue'

class KeyValueArray extends Component {
    render () {
        let display = []
        let kva = this.props.kva
        if (Array.isArray(kva)) {
            for (let i = 0; i < kva.length; i++) {
                let kv = kva[i]
                display.push(
                    <KeyValue 
                        key={i} 
                        index={i} 
                        kv={kv}
                        keyLabel={this.props.keyLabel} 
                        valueLabel={this.props.valueLabel}
                        keyValueChange={this.props.keyValueChange}
                        type={this.props.type}
                        remove={this.props.remove}
                        fontColor={this.props.fontColor}
                    />
                )
            }
        }
        return (
            <div className="width100">
                {display}
                <button type="button" className="btn-confirm" onClick={this.props.add}>Add</button>
            </div>
        )
    }

}

export default KeyValueArray