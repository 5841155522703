/*global */
import React, { Component } from 'react';

class ConfirmationButtons extends Component {
    render () {
        let cancelButton = <button type="button" className="btn btn-cancel" onClick={this.props.cancel} disabled={this.props.cancelDisabled === true ? true : false}>{this.props.cancelLabel}</button>
        if (this.props.cancel === undefined) {
            cancelButton = <span></span>
        }
        if (this.props.hideCancel === true) {
            cancelButton = <span></span>
        }

        let className = "btn-confirm";
        if (this.props.secondary === true) {
            className = "btn-cancel";
        }

        if (this.props.type === "upgradeOverride") {
            return (
                <div className="row mb-3">
                    <button type="button" className="btn-upgrade width50" onClick={this.props.confirm} disabled={this.props.confirmDisabled === true ? true : false}>Upgrade</button>
                    &nbsp;&nbsp;
                    {cancelButton}
                </div>
            );
        }

        return (
            <div className="row mb-3">
                <button type="button" className={className} onClick={this.props.confirm} disabled={this.props.confirmDisabled === true ? true : false}>{this.props.confirmLabel}</button>
                &nbsp;&nbsp;
                {cancelButton}
            </div>
        );
    }
};

export default ConfirmationButtons;