/*global*/
import React, { Component } from 'react';
import Context from './Context';

class ResultsHeader8 extends Component {
    static contextType = Context;

    constructor () {
        super();
        this.state = {ellipsisContent: "options"};
    }

    render () {
        return (
            <div className="results-header8">
                <div className="results-header8-title">
                    <span className="header-title">Diff</span>
                </div>
            </div>
        );
    }
};

export default ResultsHeader8;